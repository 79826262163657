import { useAppSelector } from 'hooks/reduxHooks'
import useLuxLoyaltyUserBenefit from './useLuxLoyaltyUserBenefit'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'

/**
 * Returns whether a user has any remaining benefits avaialble for the type passed in
 * @param benefitType The benefit type to see if the user can use
 * @returns Whether there are any remaining benefits
 */
function useUserHasLoyaltyBenefit(benefitType: App.LuxLoyaltyBenefitType): boolean {
  const enabled = useAppSelector(state => getIsLuxLoyaltyEnabled(state))
  const benefit = useLuxLoyaltyUserBenefit(benefitType)

  // loyalty must be on, they must have the benefit and they must have some remaining
  return enabled && !!benefit && (benefit.unlimited || (benefit.remaining ?? 0) > 0)
}

export default useUserHasLoyaltyBenefit
