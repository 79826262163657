import Clickable from 'components/Common/Clickable/Clickable'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import useToggle from 'hooks/useToggle'
import { rem } from 'polished'
import React, { CSSProperties, MouseEventHandler, PropsWithChildren, ReactNode, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import Divider from './Divider'
import DropdownChevron from './Dropdown/DropdownChevron'
import BodyTextBlock from './TextBlocks/BodyTextBlock'
import BodyText from './Typography/BodyText'
import Expander from 'components/utils/Expander'

const Container = styled.div`
  scroll-margin-top: var(--scroll-margin-top);
`

const Trigger = styled(Clickable)`
  width: 100%;
  min-height: ${rem(48)};
  padding-block: ${rem(8)};
  display: grid;
  gap: ${rem(16)};
  grid-template: "content side" auto / 1fr auto;
  align-items: center;

  .input-keyboard &:focus {
    outline: 2px solid ${props => props.theme.palette.neutral.default.five};
    outline-offset: 1px;
  }
`

const TriggerContentArea = styled(VerticalSpacer)`
  grid-area: content;
`

const TriggerSideArea = styled(Group)`
  grid-area: side;
  justify-self: end;
`

const Content = styled.div`
  padding-top: ${rem(4)};
  padding-bottom: ${rem(12)};
`

interface Props extends PropsWithChildren {
  /**
   * Primary label
   */
  heading: string
  /**
   * Secondary label that sits beneath the heading.
   */
  subtitle?: string
  /**
   * Complementary label that sit on the end side of the heading+subtitle stack.
   */
  endLabel?: string
  defaultOpen?: boolean
  startIcon?: ReactNode
  expanded?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  onOpen?: () => void
  onClose?: () => void
  /**
   * Accordion size, affects elements such as size of text
   * @default small
   */
  size?: 'small' | 'large'
  /**
   * Whether or not to scroll to the content when this accordion is opened
   */
  scrollOnOpen?: boolean
  scrollMarginTop?: number
  /**
   * Whether or not to automatically open if we see ourselves being
   * linked by the url hash
   */
  openOnHash?: boolean
  /**
   * Whether or not to show a divider at the bottom of the accordion
   * @default true
   */
  hideDivider?: boolean
  id?: string
  'data-testid'?: string
}

function Accordion(props: Props) {
  const {
    heading,
    subtitle,
    endLabel,
    children,
    defaultOpen,
    expanded,
    onClick,
    size = 'small',
    startIcon,
    onOpen,
    onClose,
    scrollOnOpen,
    scrollMarginTop,
    openOnHash,
    hideDivider,
    id,
    'data-testid': dataTestId,
  } = props

  const [uncontrolledOpen, toggleUncontrolledOpen, setOpen] = useToggle(defaultOpen)
  const isControlled = expanded !== undefined
  const containerRef = useRef<HTMLDivElement>(null)
  const actuallyOpen = isControlled ? expanded : uncontrolledOpen

  const onTitleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isControlled) {
      toggleUncontrolledOpen()
    }

    if (actuallyOpen) {
      onClose?.()
    } else {
      onOpen?.()
    }

    onClick?.(e)
  }

  useEffect(() => {
    if (scrollOnOpen && actuallyOpen) {
      containerRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, [actuallyOpen, scrollOnOpen])

  const location = useLocation()
  useEffect(() => {
    if (openOnHash && !!location.hash && location.hash === `#${id}`) {
      setOpen()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash])

  return <Container
    id={id}
    ref={containerRef}
    style={{ '--scroll-margin-top': scrollMarginTop ? rem(scrollMarginTop) : undefined } as CSSProperties}
    data-testid={dataTestId}
  >
    <Trigger onClick={onTitleClick}>
      <TriggerContentArea gap={4}>
        <BodyTextBlock
          startIcon={startIcon}
          variant={size === 'large' ? 'large' : 'medium'}
          weight="bold"
        >
          {heading}
        </BodyTextBlock>
        {subtitle && <BodyText
          variant={size === 'large' ? 'medium' : 'small'}
        >
          {subtitle}
        </BodyText>}
      </TriggerContentArea>
      <TriggerSideArea direction="horizontal" verticalAlign="center" gap={12}>
        {!!endLabel && <BodyText variant="medium" lineClamp={1}>{endLabel}</BodyText>}
        <DropdownChevron size="M" direction={actuallyOpen ? 'up' : 'down'} />
      </TriggerSideArea>
    </Trigger>
    <Expander expanded={actuallyOpen}>
      <Content>
        {children}
      </Content>
    </Expander>
    {!hideDivider && <Divider kind="primary"/>}
  </Container>
}

export default Accordion
