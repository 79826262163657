import FormatCurrency from 'components/Common/FormatCurrency'
import React, { ComponentProps, ReactElement } from 'react'
import BodyText from '../Typography/BodyText'
import _PriceSummaryRow from './_PriceSummaryRow'

type InheritedBasePriceSummaryRowProps = Pick<ComponentProps<typeof _PriceSummaryRow>, 'data-testid'>

interface Props extends InheritedBasePriceSummaryRowProps {
  /**
   * Textual content at the start.
   */
  label: ReactElement | string
  labelStrikethrough?: boolean
  /**
   * Formatted price at the end.
   */
  value?: number
  valueStrikethrough?: boolean
  /**
   * @default preciseDollar
   */
  valueFormat?: ComponentProps<typeof FormatCurrency>['format']
}

function PriceSummaryRowItemDescription(props: Props) {
  const {
    label,
    labelStrikethrough,
    value,
    valueStrikethrough,
    valueFormat = 'preciseDollar',
    'data-testid': dataTestId,
  } = props

  return <_PriceSummaryRow
    data-testid={dataTestId}
    className={PriceSummaryRowItemDescription.name}
    startSlot={<BodyText
      variant="medium"
      weight="normal"
      colour="neutral-two"
      lineClamp={2}
      strikeThrough={labelStrikethrough}
    >
      {label}
    </BodyText>}
    endSlot={value !== undefined && <BodyText
      variant="medium"
      weight="normal"
      colour="neutral-two"
      wrap="no-wrap"
      strikeThrough={valueStrikethrough}
    >
      <FormatCurrency value={value} format={valueFormat} />
    </BodyText>}
  />
}

export default PriceSummaryRowItemDescription
