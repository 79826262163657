import {
  ADD_OFFER_LIST_EXTRA,
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  BEDBANK_UPDATE_PRICING_DATA,
  END_OFFER_LIST_STREAM,
  INITIALISE_BNBL_MODAL,
  OFFER_PUSH_TO_LIST_BOTTOM,
  OFFER_SET_SEARCH_RESULT_METADATA,
  OFFER_SET_TOUR_SEARCH_RESULT_METADATA,
  OFFER_UPDATE_AVAILABLE_RATES,
  OFFER_UPDATE_PRICING_DATA,
  OFFERS_CLEAR,
  REMOVE_OFFER,
  SET_OFFER_LIST_ERROR,
  UPDATE_BNBL_NUMBER_OF_ROOMS,
  UPDATE_BNBL_PACKAGE_VIEW,
  UPDATE_GIFTING_BNBL_MODAL,
} from 'actions/actionConstants'
import {
  FETCH_ALTERNATIVE_OFFERS,
  FETCH_AVAILABILITY_RATES_FOR_OFFER,
  FETCH_BEDBANK_OFFER,
  FETCH_BEDBANK_OFFER_CALENDAR,
  FETCH_BEDBANK_OFFER_RATES,
  FETCH_BEDBANK_OFFER_RATES_BULK,
  FETCH_BEDBANK_OFFER_SUMMARIES,
  FETCH_BEDBANK_OFFERS,
  FETCH_BEDBANK_OFFERS_RATES,
  FETCH_BEST_OFFER_FOR_PROPERTY,
  FETCH_BEST_PRICE_FOR_OFFER,
  FETCH_OFFER,
  FETCH_OFFER_FLIGHT_PRICE,
  FETCH_OFFER_LIST,
  FETCH_OFFER_LIST_FILTERS,
  FETCH_OFFER_LOCATION_BREADCRUMBS,
  FETCH_OFFER_SUMMARIES,
  FETCH_OFFERS,
  FETCH_RELATED_TRAVEL_ITEMS,
  FETCH_SURCHARGE_MARGIN,
  FETCH_TOURV2_OFFER,
  FETCH_TRADER_INFORMATION,
  FETCH_OFFER_LIST_STREAM_SCROLL,
  FETCH_PROPERTY_OFFER_MAPPING,
  FETCH_LERE_PERSONALISED_ALTERNATIVES,
} from 'actions/apiActionConstants'
import { DEFAULT_OCCUPANCY_FOR_BNBL } from 'components/OfferPage/BnblModal/utils'
import { arrayToObject } from 'lib/array/arrayUtils'
import { omitKeys } from 'lib/object/objectUtils'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import { AnyAction } from 'redux'
import { getOfferId } from 'lib/offer/offerUtils'
import { ApiAction, ApiActionSuccess } from 'middlewares/apiMiddleware'
import { BnblRoomPackage } from 'actions/BnblModalActions'
import { determineScrollFetching } from 'lib/search/offerListUtils'
import { getAlternativesOfferListParams } from 'home/helpers'
import getOfferListKey from 'lib/offer/offerListKey'

export const initialOfferState: App.OfferState = {
  offers: {},
  tourV2Offers: {},
  bedbankOfferRates: {},
  bedbankOfferRatesLoading: {},
  bedbankOfferCalendar: {},
  bedbankOfferSummaries: {},
  bedbankOffers: {},
  bnblModal: {},
  offerRatesLoading: {},
  bedbankOfferCalendarLoading: {},
  offersLoading: {},
  offerErrors: {},
  offerSummaries: {},
  offerSummariesLoading: {},
  offerSummaryErrors: {},
  offerLists: {},
  offerListAdjustedOffers: {},
  offerListFilterOptions: {},
  offerBestPrices: {},
  offerPricesLoading: {},
  offerPricesErrors: {},
  offerAvailableRates: {},
  offerAvailableRatesByOccupancy: {},
  relatedTravelItems: {},
  alternativeOffersForProperty: {},
  searchResultMetadata: {
    distanceFromSearchTarget: {},
    offerAvailabilityFromSearchTarget: {},
    offerSuggestedDates: {},
    offerMetaData: {},
  },
  tourSearchResultMetadata: {
    tourMetadata: {},
  },
  traderInformation: {},
  bestPropertyOffer: {},
  locationBreadcrumbs: {},
  offersRatesBulkLoading: false,
  alternativeDates: {},
  alternativeDatesLoading: {},
  surchargeMargins: {},
  propertyOfferMappings: {},
}

function fetchOfferRequest(state: App.OfferState, action: AnyAction): Partial<App.OfferState> {
  const nextState: Partial<App.OfferState> = {
    offersLoading: {
      ...state.offersLoading,
      [action.offerId]: true,
    },
  }

  if (state.offerErrors[action.offerId]) {
    // reset the error state as we're fetching it again
    nextState.offerErrors = {
      ...state.offerErrors,
      [action.offerId]: undefined,
    }
  }

  return nextState
}

function fetchOffersRequest(state: App.OfferState, action: AnyAction & { offerIds: Array<string> }): Partial<App.OfferState> {
  const nextState: Partial<App.OfferState> = {
    offersLoading: {
      ...state.offersLoading,
      ...arrayToObject(action.offerIds, (id: string) => id, () => true),
    },
  }

  if (action.offerIds.some(id => !!state.offerErrors[id])) {
    // only change the offerErrors if a value actually needs to be reset
    nextState.offerErrors = {
      ...state.offerErrors,
      ...arrayToObject(action.offerIds, (id: string) => id, () => undefined),
    }
  }

  return nextState
}

function fetchOfferError(state: App.OfferState, action: AnyAction): Partial<App.OfferState> {
  return {
    offerErrors: {
      ...state.offerErrors,
      [action.offerId]: action.error,
    },
    offersLoading: {
      ...state.offersLoading,
      [action.offerId]: false,
    },
  }
}

const apiRequests = reducerSwitch<App.OfferState>({
  [FETCH_PROPERTY_OFFER_MAPPING]: (state, action) => ({
    propertyOfferMappings: {
      ...state.propertyOfferMappings,
      [action.propertyId]: {
        fetching: true,
      },
    },
  }),
  [FETCH_OFFER_LOCATION_BREADCRUMBS]: (state, action) => ({
    locationBreadcrumbs: {
      ...state.locationBreadcrumbs,
      [action.offerId]: {
        fetching: true,
      },
    },
  }),
  [FETCH_BEST_OFFER_FOR_PROPERTY]: (state, action) => ({
    bestPropertyOffer: {
      ...state.bestPropertyOffer,
      [action.propertyId]: {
        fetching: true,
      },
    },
  }),
  [FETCH_OFFER_LIST_FILTERS]: (state, action) => ({
    offerListFilterOptions: {
      ...state.offerListFilterOptions,
      [action.key]: {
        ...state.offerListFilterOptions[action.key],
        error: undefined,
        fetching: true,
      },
    },
  }),
  [FETCH_OFFER]: fetchOfferRequest,
  [FETCH_TRADER_INFORMATION]: (state, action) => ({
    traderInformation: {
      ...state.traderInformation,
      [action.offerId]: {
        data: null,
        state: 'loading',
      },
    },
  }),
  [FETCH_OFFERS]: fetchOffersRequest,
  [FETCH_OFFER_SUMMARIES]: (state, action) => ({
    offerSummariesLoading: {
      ...state.offerSummariesLoading,
      ...arrayToObject(action.offerIds, (id: string) => id, () => true),
    },
    offerSummaryErrors: {
      ...state.offerSummaryErrors,
      ...arrayToObject(action.offerIds, (id: string) => id, () => undefined),
    },
  }),
  [FETCH_OFFER_LIST]: (state, action) => {
    const prevState = state.offerLists[action.key]

    const newState: Partial<App.OfferState> = {
      offerLists: {
        ...state.offerLists,
        [action.key]: {
          ...prevState,
          offerIds: [],
          key: action.key,
          error: undefined,
          fetching: true,
          scrollFetching: true,
          searchId: action.searchId,
        },
      },
    }

    return newState
  },
  [FETCH_BEST_PRICE_FOR_OFFER]: (state, action) => ({
    offerPricesLoading: {
      ...state.offerPricesLoading,
      [action.offerId]: {
        ...state.offerPricesLoading[action.offerId],
        [action.key]: true,
      },
    },
  }),
  [FETCH_AVAILABILITY_RATES_FOR_OFFER]: (state, action) => ({
    offerAvailableRates: {
      ...state.offerAvailableRates,
      [action.offerId]: {
        [action.key]: {
          key: action.key,
          rates: [],
          error: undefined,
          fetching: true,
        },
      },
    },
    offerAvailableRatesByOccupancy: {
      ...state.offerAvailableRatesByOccupancy,
      [action.offerId]: {
        ...state.offerAvailableRatesByOccupancy[action.offerId],
        [action.key]: {
          key: action.key,
          rates: [],
          error: undefined,
          fetching: true,
        },
      },
    },
  }),
  [FETCH_BEDBANK_OFFER]: fetchOfferRequest,
  [FETCH_BEDBANK_OFFERS]: fetchOffersRequest,
  [FETCH_BEDBANK_OFFER_SUMMARIES]: (state, action) => ({
    offerSummariesLoading: {
      ...state.offerSummariesLoading,
      ...arrayToObject(action.offerIds, (id: string) => id, () => true),
    },
    offerSummaryErrors: {
      ...state.offerSummaryErrors,
      ...arrayToObject(action.offerIds, (id: string) => id, () => undefined),
    },
  }),
  [FETCH_BEDBANK_OFFER_RATES]: (state, action) => ({
    bedbankOfferRatesLoading: {
      ...state.bedbankOfferRatesLoading,
      [action.offerId]: {
        ...state.bedbankOfferRatesLoading[action.offerId],
        [action.filterKey]: true,
      },
    },
    offerRatesLoading: {
      ...state.offerRatesLoading,
      [action.offerId]: true,
    },
  }),
  [FETCH_BEDBANK_OFFER_CALENDAR]: (state, action) => ({
    bedbankOfferCalendarLoading: {
      ...state.bedbankOfferCalendarLoading,
      [action.offerId]: true,
    },
  }),
  [FETCH_BEDBANK_OFFER_RATES_BULK]: (state, action) => ({
    offerRatesLoading: {
      ...state.offerRatesLoading,
      [action.offerId]: true,
    },
    offersRatesBulkLoading: true,
  }),
  [FETCH_BEDBANK_OFFERS_RATES]: (state, action) => ({
    bedbankOfferRatesLoading: {
      ...state.bedbankOfferRatesLoading,
      ...arrayToObject(action.offerIds,
        (id: string) => id,
        (id) => ({
          ...state.bedbankOfferRatesLoading[id],
          [action.filterKey]: true,
        }),
      ),
    },
    offerRatesLoading: {
      ...state.offerRatesLoading,
      ...arrayToObject(action.offerIds, (id: string) => id, () => true),
    },
  }),
  [FETCH_TOURV2_OFFER]: fetchOfferRequest,
  [FETCH_SURCHARGE_MARGIN]: (state, action) => {
    return {
      surchargeMargins: {
        ...state.surchargeMargins,
        [action.surchargeMarginKey]: {
          status: 'pending',
        },
      },
    }
  },
  [FETCH_OFFER_LIST_STREAM_SCROLL]: (state, action) => {
    const prevState = state.offerLists[action.key]

    const newState = {
      offerLists: {
        ...state.offerLists,
        [action.key]: {
          ...prevState,
          scrollFetching: true,
        },
      },
    }

    return newState
  },
})

const apiSuccesses = reducerSwitch<App.OfferState>({
  [FETCH_PROPERTY_OFFER_MAPPING]: (state, action) => ({
    propertyOfferMappings: {
      ...state.propertyOfferMappings,
      [action.propertyId]: {
        ...state.propertyOfferMappings[action.propertyId],
        mapping: action.data,
        fetching: false,
      },
    },
  }),
  [FETCH_BEST_OFFER_FOR_PROPERTY]: (state, action) => ({
    bestPropertyOffer: {
      ...state.bestPropertyOffer,
      [action.propertyId]: {
        fetching: false,
        offerId: action.data.offerId,
        type: action.data.type,
      },
    },
  }),
  [FETCH_OFFER]: (state, action) => ({
    offers: {
      ...state.offers,
      [action.offerId]: action.data,
    },
    offersLoading: {
      ...state.offersLoading,
      [action.offerId]: false,
    },
  }),
  [FETCH_TRADER_INFORMATION]: (state, action) => ({
    traderInformation: {
      ...state.traderInformation,
      [action.offerId]: {
        data: action.data,
        state: 'done',
      },
    },
  }),
  [FETCH_OFFERS]: (state, action: ApiAction<Array<App.Offer>> & { offerIds: Array<string> }) => {
    const foundOfferIds = new Set(action.data?.map(o => o.id))
    // missing offer ids are treated as errors
    const missingOfferIds = action.offerIds.filter(id => !foundOfferIds.has(id))

    return {
      offers: {
        ...state.offers,
        ...arrayToObject(action.data, getOfferId),
      },
      offerSummaries: omitKeys(
        action.offerIds,
        state.offerSummaries,
      ),
      offerErrors: missingOfferIds.length > 0 ? {
        ...state.offerErrors,
        ...arrayToObject(
          missingOfferIds,
          (id: string) => id,
          () => true,
        ),
      } : state.offerErrors,
      offersLoading: {
        ...state.offersLoading,
        ...arrayToObject(
          action.offerIds,
          (id: string) => id,
          () => false,
        ),
      },
    }
  },
  [FETCH_OFFER_SUMMARIES]: (state, action) => ({
    offerSummaries: {
      ...state.offerSummaries,
      ...arrayToObject(action.data, getOfferId),
    },
    offerSummariesLoading: {
      ...state.offerSummariesLoading,
      ...arrayToObject(
        action.offerIds,
        (id: string) => id,
        () => false,
      ),
    },
  }),
  [FETCH_OFFER_FLIGHT_PRICE]: (state, action) => ({
    offers: {
      ...state.offers,
      [action.offerId]: {
        ...state.offers[action.offerId],
        flightPrices: {
          ...state.offers[action.offerId].flightPrices,
          [action.flightOrigin]: action.data,
        },
      },
    },
  }),
  [FETCH_OFFER_LIST]: (state, action) => {
    // from search response (non-streaming)
    const searchId = action.data.searchId
    const offerLists = state.offerLists
    const prevState = offerLists[action.key]

    // If we have received streamed offers we append them to the offerIds array
    const nextOfferIds = action.data.streamedOfferIds ? [...(prevState?.offerIds || []), ...action.data.streamedOfferIds] : action.data.mainOfferList
    // We do not update the offerCount if we have streamed offers
    const nextOfferCount = action.data.offerCount === undefined ? prevState?.offerCount : action.data.offerCount

    const scrollFetching = determineScrollFetching(prevState, action.data.streamedOfferIds)

    return {
      offerLists: {
        ...offerLists,
        [action.key]: {
          ...prevState,
          ...(searchId && { searchId }),
          offerIds: nextOfferIds,
          fetching: false,
          scrollFetching,
          offerCount: nextOfferCount,
          searchVertical: action.data.searchVertical,
          error: undefined,
        },
      },
    }
  },
  [FETCH_OFFER_LIST_FILTERS]: (state, action) => {
    const offerLists = state.offerLists
    const prevState = offerLists[action.key]

    // For streaming we add the offerCount of the list on FETCH_OFFER_LIST_FILTERS
    const nextOfferCount = action.data.offerCount === undefined ? prevState?.offerCount : action.data.offerCount

    return {
      offerLists: {
        ...offerLists,
        [action.key]: {
          ...prevState,
          offerCount: nextOfferCount,
        },
      },
      offerListFilterOptions: {
        ...state.offerListFilterOptions,
        [action.key]: {
          ...state.offerListFilterOptions[action.key],
          filters: action.data.filters,
          filterOrder: action.data.filterOrder,
          orderedFilters: action.data.orderedFilters,
          fetching: false,
        },
      },
    }
  },
  [FETCH_BEST_PRICE_FOR_OFFER]: (state, action) => ({
    offerPricesLoading: {
      ...state.offerPricesLoading,
      [action.offerId]: {
        ...state.offerPricesLoading[action.offerId],
        [action.key]: false,
      },
    },
    offerBestPrices: {
      ...state.offerBestPrices,
      [action.offerId]: {
        ...state.offerBestPrices[action.offerId],
        [action.key]: action.data,
      },
    },
    offerPricesErrors: {
      ...state.offerPricesErrors,
      [action.offerId]: {
        ...state.offerPricesErrors[action.offerId],
        [action.key]: null,
      },
    },
  }),
  [FETCH_AVAILABILITY_RATES_FOR_OFFER]: (state, action) => ({
    offerAvailableRates: {
      ...state.offerAvailableRates,
      [action.offerId]: {
        ...state.offerAvailableRates[action.offerId],
        [action.key]: {
          key: action.key,
          rates: action.data,
          error: undefined,
          fetching: false,
        },
      },
    },
    offerAvailableRatesByOccupancy: {
      ...state.offerAvailableRatesByOccupancy,
      [action.offerId]: {
        ...state.offerAvailableRatesByOccupancy[action.offerId],
        [action.key]: {
          key: action.key,
          rates: action.data,
          error: undefined,
          fetching: false,
        },
      },
    },
  }),
  [FETCH_RELATED_TRAVEL_ITEMS]: (state, action) => ({
    relatedTravelItems: {
      ...state.relatedTravelItems,
      [action.offerId]: action.data,
    },
  }),
  [FETCH_OFFER_LOCATION_BREADCRUMBS]: (state, action) => ({
    locationBreadcrumbs: {
      ...state.locationBreadcrumbs,
      [action.offerId]: {
        fetching: false,
        locations: action.data,
      },
    },
  }),
  [FETCH_ALTERNATIVE_OFFERS]: (state, action) => ({
    alternativeOffersForProperty: {
      ...state.alternativeOffersForProperty,
      [action.key]: action.data,
    },
  }),
  [FETCH_BEDBANK_OFFER]: (state, action) => ({
    bedbankOffers: {
      ...state.bedbankOffers,
      [action.offerId]: action.data,
    },
    offerSummaries: omitKeys([action.offerId], state.offerSummaries),
    offersLoading: {
      ...state.offersLoading,
      [action.offerId]: false,
    },
  }),
  [FETCH_BEDBANK_OFFERS]: (state, action: ApiActionSuccess<Array<App.BedbankOffer>> & { offerIds: Array<string> }) => ({
    bedbankOffers: {
      ...state.bedbankOffers,
      ...arrayToObject<App.BedbankOffer>(action.data, (offer) => offer.id),
    },
    offerSummaries: omitKeys(
      action.data.map((offer) => offer.id),
      state.offerSummaries,
    ),
    offersLoading: {
      ...state.offersLoading,
      ...arrayToObject(
        action.offerIds,
        (id: string) => id,
        () => false,
      ),
    },
  }),
  [FETCH_BEDBANK_OFFER_RATES]: (state, action) => ({
    bedbankOfferRates: {
      ...state.bedbankOfferRates,
      [action.offerId]: {
        ...state.bedbankOfferRates[action.offerId],
        [action.filterKey]: action.data,
      },
    },
    bedbankOfferRatesLoading: {
      ...state.bedbankOfferRatesLoading,
      [action.offerId]: {
        ...state.bedbankOfferRatesLoading[action.offerId],
        [action.filterKey]: false,
      },
    },
    offerRatesLoading: {
      ...state.offerRatesLoading,
      [action.offerId]: false,
    },
  }),
  [FETCH_BEDBANK_OFFER_CALENDAR]: (state, action) => ({
    bedbankOfferCalendar: {
      ...state.bedbankOfferCalendar,
      [action.offerId]: {
        ...state.bedbankOfferCalendar[action.offerId],
        [action.filterKey]: action.data,
      },
    },
    bedbankOfferCalendarLoading: {
      ...state.bedbankOfferCalendarLoading,
      [action.offerId]: false,
    },
  }),
  [FETCH_BEDBANK_OFFER_RATES_BULK]: (state, action) => ({
    bedbankOfferRates: {
      ...state.bedbankOfferRates,
      [action.offerId]: {
        ...state.bedbankOfferRates[action.offerId],
        ...arrayToObject<{ filterKey: string; rates: Array<App.BedbankRate> }, any>(
          action.data,
          (data) => data.filterKey,
          (data) => data.rates,
        ),
      },
    },
    offerRatesLoading: {
      ...state.offerRatesLoading,
      [action.offerId]: false,
    },
    offersRatesBulkLoading: false,
  }),
  [FETCH_BEDBANK_OFFERS_RATES]: (state, action) => ({
    bedbankOfferRates: {
      ...state.bedbankOfferRates,
      ...arrayToObject<{ offerId: string; rates: Array<App.BedbankRate> }, any>(
        action.data,
        (data) => data.offerId,
        (data) => ({
          ...state.bedbankOfferRates[data.offerId],
          [action.filterKey]: data.rates,
        }),
      ),
    },
    bedbankOfferRatesLoading: {
      ...state.bedbankOfferRatesLoading,
      ...arrayToObject(action.offerIds,
        (id: string) => id,
        (id) => ({
          ...state.bedbankOfferRatesLoading[id],
          [action.filterKey]: false,
        }),
      ),
    },
    offerRatesLoading: {
      ...state.offerRatesLoading,
      ...arrayToObject(
        action.offerIds,
        (id: string) => id,
        () => false,
      ),
    },
  }),
  [FETCH_BEDBANK_OFFER_SUMMARIES]: (state, action) => ({
    bedbankOfferSummaries: {
      ...state.bedbankOfferSummaries,
      ...arrayToObject<App.BedbankOffer>(action.data, (offer) => offer.id),
    },
    offerSummariesLoading: {
      ...state.offerSummariesLoading,
      ...arrayToObject(
        action.offerIds,
        (id: string) => id,
        () => false,
      ),
    },
  }),
  [FETCH_TOURV2_OFFER]: (state, action) => {
    return {
      tourV2Offers: {
        ...state.tourV2Offers,
        [action.offerId]: action.data,
      },
      offersLoading: {
        ...state.offersLoading,
        [action.offerId]: false,
      },
    }
  },
  [FETCH_SURCHARGE_MARGIN]: (state, action: ApiActionSuccess<{ result: Array<App.SurchargeMargin> }>) => {
    const newSurchargeMargins = (action.data.result).reduce<Record<string, App.SurchargeMargin>>((acc, ele) => {
      // For each element in action.data.result, create a new entry with a status of 'success'
      const key = `${ele.roomRateId}_${ele.checkIn}_${ele.checkOut}`
      acc[key] = {
        ...ele,
        status: 'success',
      }
      return acc
    }, {})

    return {
      surchargeMargins: {
        ...state.surchargeMargins,
        ...newSurchargeMargins,
      },
    }
  },
  [FETCH_OFFER_LIST_STREAM_SCROLL]: (state, action) => {
    const prevState = state.offerLists[action.key]

    const newState = {
      offerLists: {
        ...state.offerLists,
        [action.key]: {
          ...prevState,
          scrollFetching: false,
        },
      },
    }

    return newState
  },
  // TODO: Remove once LERE has integrated with useOfferList
  [FETCH_LERE_PERSONALISED_ALTERNATIVES]: (state, action) => {
    const params = getAlternativesOfferListParams()
    const key = getOfferListKey(params)
    const offerIds = action.data.offers.map((offer: App.RecommendationOffer) => offer.offerId)

    return {
      ...state,
      offerLists: {
        ...state.offerLists,
        [key]: {
          key,
          offerIds,
          fetching: false,
        },
      },
    }
  },
})

const apiFailures = reducerSwitch<App.OfferState>({
  [FETCH_PROPERTY_OFFER_MAPPING]: (state, action) => ({
    propertyOfferMappings: {
      ...state.propertyOfferMappings,
      [action.propertyId]: {
        ...state.propertyOfferMappings[action.propertyId],
        error: action.error,
        fetching: false,
      },
    },
  }),
  [FETCH_OFFER_LOCATION_BREADCRUMBS]: (state, action) => ({
    locationBreadcrumbs: {
      ...state.locationBreadcrumbs,
      [action.offerId]: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_BEST_OFFER_FOR_PROPERTY]: (state, action) => ({
    bestPropertyOffer: {
      ...state.bestPropertyOffer,
      [action.propertyId]: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_OFFER]: fetchOfferError,
  [FETCH_TRADER_INFORMATION]: (state, action) => ({
    traderInformation: {
      ...state.traderInformation,
      [action.offerId]: {
        data: null,
        state: 'error',
      },
    },
  }),
  [FETCH_OFFERS]: (state, action) => ({
    offerErrors: {
      ...state.offerErrors,
      ...arrayToObject(action.offerIds, (id: string) => id, () => action.error),
    },
    offersLoading: {
      ...state.offersLoading,
      ...arrayToObject(action.offerIds, (id: string) => id, () => false),
    },
  }),
  [FETCH_OFFER_SUMMARIES]: (state, action) => ({
    offerSummaryErrors: {
      ...state.offerSummaryErrors,
      ...arrayToObject(action.offerIds, (id: string) => id, () => action.error),
    },
    offerSummariesLoading: {
      ...state.offerSummariesLoading,
      ...arrayToObject(action.offerIds, (id: string) => id, () => false),
    },
  }),
  [FETCH_OFFER_LIST]: (state, action) => {
    const prevState = state.offerLists[action.key]

    const newState = {
      offerLists: {
        ...state.offerLists,
        [action.key]: {
          ...prevState,
          fetching: false,
          scrollFetching: false,
          error: action.error,
        },
      },
    }

    return newState
  },
  [FETCH_BEST_PRICE_FOR_OFFER]: (state, action) => ({
    offerPricesLoading: {
      ...state.offerPricesLoading,
      [action.offerId]: {
        ...state.offerPricesLoading[action.offerId],
        [action.key]: false,
      },
    },
    offerPricesErrors: {
      ...state.offerPricesErrors,
      [action.offerId]: {
        ...state.offerPricesErrors[action.offerId],
        [action.key]: action.error,
      },
    },
  }),
  [FETCH_AVAILABILITY_RATES_FOR_OFFER]: (state, action) => ({
    offerAvailableRates: {
      ...state.offerAvailableRates,
      [action.offerId]: {
        ...state.offerAvailableRates[action.offerId],
        [action.key]: {
          key: action.key,
          rates: [],
          error: action.error,
          fetching: false,
        },
      },
    },
    offerAvailableRatesByOccupancy: {
      ...state.offerAvailableRatesByOccupancy,
      [action.offerId]: {
        ...state.offerAvailableRatesByOccupancy[action.offerId],
        [action.key]: {
          key: action.key,
          rates: [],
          error: action.error,
          fetching: false,
        },
      },
    },
  }),
  [FETCH_OFFER_LIST_FILTERS]: (state, action) => ({
    offerListFilterOptions: {
      ...state.offerListFilterOptions,
      [action.key]: {
        ...state.offerListFilterOptions[action.key],
        error: action.error,
        fetching: false,
      },
    },
  }),
  [FETCH_BEDBANK_OFFER]: fetchOfferError,
  [FETCH_BEDBANK_OFFERS]: (state, action) => ({
    offerErrors: {
      ...state.offerErrors,
      ...arrayToObject(action.offerIds, (id: string) => id, () => action.error),
    },
    offersLoading: {
      ...state.offersLoading,
      ...arrayToObject(action.offerIds, (id: string) => id, () => false),
    },
  }),
  [FETCH_BEDBANK_OFFER_SUMMARIES]: (state, action) => ({
    offerSummaryErrors: {
      ...state.offerSummaryErrors,
      ...arrayToObject(action.offerIds, (id: string) => id, () => action.error),
    },
    offerSummariesLoading: {
      ...state.offerSummariesLoading,
      ...arrayToObject(action.offerIds, (id: string) => id, () => false),
    },
  }),
  [FETCH_BEDBANK_OFFER_RATES]: (state, action) => ({
    bedbankOfferRatesLoading: {
      ...state.bedbankOfferRatesLoading,
      [action.offerId]: {
        ...state.bedbankOfferRatesLoading[action.offerId],
        [action.filterKey]: false,
      },
    },
    offerRatesLoading: {
      ...state.offerRatesLoading,
      [action.offerId]: false,
    },
  }),
  [FETCH_BEDBANK_OFFER_CALENDAR]: (state, action) => ({
    bedbankOfferCalendarLoading: {
      ...state.bedbankOfferCalendarLoading,
      [action.offerId]: false,
    },
  }),
  [FETCH_BEDBANK_OFFER_RATES_BULK]: (state, action) => ({
    offerRatesLoading: {
      ...state.offerRatesLoading,
      [action.offerId]: false,
    },
    offersRatesBulkLoading: false,
  }),
  [FETCH_BEDBANK_OFFERS_RATES]: (state, action) => ({
    bedbankOfferRatesLoading: {
      ...state.bedbankOfferRatesLoading,
      ...arrayToObject(action.offerIds,
        (id: string) => id,
        (id) => ({
          ...state.bedbankOfferRatesLoading[id],
          [action.filterKey]: false,
        }),
      ),
    },
    offerRatesLoading: {
      ...state.offerRatesLoading,
      ...arrayToObject(action.offerIds, (id: string) => id, () => false),
    },
  }),
  [FETCH_TOURV2_OFFER]: fetchOfferError,
  [FETCH_SURCHARGE_MARGIN]: (state, action) => {
    const newSurchargeMargins = (action.data.result).reduce((acc, ele) => {
      // For each element in action.data.result, create a new entry with a status of 'success'
      const key = `${ele.roomRateId}_${ele.checkIn}_${ele.checkOut}`
      acc[key] = {
        ...ele,
        status: 'failure',
      }
      return acc
    }, {})
    return {
      surchargeMargins: {
        ...state.surchargeMargins,
        ...newSurchargeMargins,
      },
    }
  },
  [FETCH_OFFER_LIST_STREAM_SCROLL]: (state, action) => {
    const prevState = state.offerLists[action.key]

    const newState = {
      offerLists: {
        ...state.offerLists,
        [action.key]: {
          ...prevState,
          scrollFetching: false,
        },
      },
    }

    return newState
  },
  // TODO: Remove once LERE has integrated with useOfferList
  [FETCH_LERE_PERSONALISED_ALTERNATIVES]: (state, action) => {
    const params = getAlternativesOfferListParams()
    const key = getOfferListKey(params)

    return {
      ...state,
      offerLists: {
        ...state.offerLists,
        [key]: {
          key,
          offerIds: [],
          fetching: false,
          error: action.error,
        },
      },
    }
  },
})

const offersReducer = createReducer<App.OfferState>(initialOfferState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [OFFERS_CLEAR]: (state, action) => {
    const { offerIds, bedbankOfferIds, tourV2OfferIds } = action.ids
    const newState: App.OfferState = { ...state }
    if (offerIds) { newState.offers = omitKeys(offerIds, state.offers) }
    if (bedbankOfferIds) { newState.bedbankOffers = omitKeys(bedbankOfferIds, state.bedbankOffers) }
    if (tourV2OfferIds) { newState.tourV2Offers = omitKeys(tourV2OfferIds, state.tourV2Offers) }
    return newState
  },
  [OFFER_SET_SEARCH_RESULT_METADATA]: (state, action) => ({
    searchResultMetadata: {
      ...state.searchResultMetadata,
      distanceFromSearchTarget: {
        ...state.searchResultMetadata.distanceFromSearchTarget,
        [action.searchTargetId]: { ...state.searchResultMetadata.distanceFromSearchTarget[action.searchTargetId], ...action.data.resultsByDistance },
      },
      offerAvailabilityFromSearchTarget: {
        ...state.searchResultMetadata.offerAvailabilityFromSearchTarget,
        [action.availabilityKey]: { ...state.searchResultMetadata.offerAvailabilityFromSearchTarget[action.availabilityKey], ...action.data.resultsByAvailability },
      },
      offerSuggestedDates: {
        ...state.searchResultMetadata.offerSuggestedDates,
        [action.suggestedDatesKey]: { ...state.searchResultMetadata.offerSuggestedDates[action.suggestedDatesKey], ...action.data.offerSuggestedDates[action.suggestedDatesKey] },
      },
      offerMetaData: {
        ...state.searchResultMetadata.offerMetaData,
        [action.offerMetaDataKey]: { ...state.searchResultMetadata.offerMetaData[action.offerMetaDataKey], ...action.data.offerMetaData[action.offerMetaDataKey] },
      },
    },
  }),
  [OFFER_SET_TOUR_SEARCH_RESULT_METADATA]: (state, action) => ({
    tourSearchResultMetadata: {
      ...state.tourSearchResultMetadata,
      tourMetadata: {
        ...state.tourSearchResultMetadata.tourMetadata,
        [action.tourMetadataKey]: {
          ...state.tourSearchResultMetadata.tourMetadata[action.tourMetadataKey],
          ...action.data.tourMetadata[action.tourMetadataKey],
        },
      },
    },
  }),
  [INITIALISE_BNBL_MODAL]: (_state, action: AnyAction & { packages: Array<BnblRoomPackage> }) => {
    const bnblRoomPackages = action.packages.map(p => {
      const occupancies = new Array(p.numberOfRooms).fill(DEFAULT_OCCUPANCY_FOR_BNBL)

      return {
        numberOfRooms: p.numberOfRooms,
        packageView: p.packageView,
        occupancies,
        isGift: action.isGift,
      }
    })

    return {
      bnblModal: {
        [action.offerId]: bnblRoomPackages,
      },
    }
  },
  [UPDATE_GIFTING_BNBL_MODAL]: (state, action) => {
    const updatedPackages = state.bnblModal[action.offerId].map(item => ({ ...item, isGift: action.isGift }))
    return {
      bnblModal: {
        ...state.bnblModal,
        [action.offerId]: updatedPackages,
      },
    }
  },
  [UPDATE_BNBL_NUMBER_OF_ROOMS]: (state, action) => {
    const occupancies = new Array(action.numberOfRooms).fill(DEFAULT_OCCUPANCY_FOR_BNBL)
    const index = state.bnblModal[action.offerId].findIndex(p => p.packageView.package.uniqueKey === action.packageUniqueKey)

    state.bnblModal[action.offerId][index] = {
      ...state.bnblModal[action.offerId][index],
      numberOfRooms: action.numberOfRooms,
      occupancies,
    }

    return {
      bnblModal: {
        [action.offerId]: [...state.bnblModal[action.offerId]],
      },
    }
  },
  [UPDATE_BNBL_PACKAGE_VIEW]: (state, action) => {
    const index = state.bnblModal[action.offerId].findIndex(p => p.packageView.package.uniqueKey === action.packageView.package.uniqueKey)

    state.bnblModal[action.offerId][index] = {
      ...state.bnblModal[action.offerId][index],
      packageView: action.packageView,
    }

    return {
      bnblModal: {
        [action.offerId]: [...state.bnblModal[action.offerId]],
      },
    }
  },
  [OFFER_UPDATE_PRICING_DATA]: (state, action) => {
    const offer = state.offers[action.offerId]
    const itemIdx = offer.packages.findIndex(pkg => pkg.uniqueKey === action.uniqueKey)
    if (itemIdx === -1) { return state }

    const newPkg = {
      ...offer.packages[itemIdx],
      ...action.data,
      trackingPrice: action.data.price,
    }

    const newPkgList = [...offer.packages.slice(0, itemIdx), newPkg, ...offer.packages.slice(itemIdx + 1)]

    const newOffer = {
      ...offer,
      packages: newPkgList,
    }

    return {
      ...state,
      offers: {
        ...state.offers,
        [action.offerId]: newOffer,
      },
    }
  },
  [BEDBANK_UPDATE_PRICING_DATA]: (state, action) => {
    const { offerId, roomId, roomRateId, isFlightBundle, bedbankRateKey, pricing } = action.data
    const offerRate = state.bedbankOfferRates[offerId]
    if (!offerRate) { return state }

    const bedbankRateList = offerRate[bedbankRateKey]
    if (!bedbankRateList) { return state }

    const roomRateIdx = bedbankRateList.findIndex((bbRate: App.BedbankRate) => bbRate.roomId === roomId && bbRate.id === roomRateId && bbRate.isFlightBundle === isFlightBundle)
    if (roomRateIdx === -1) { return null }

    const roomRate = bedbankRateList[roomRateIdx]
    if (roomRate.occupancyPricing.length != pricing.data.length) { return state }

    const newOccupancyPricing = roomRate.occupancyPricing.map((oldOccupancyPricing, idx) => {
      const newRoomPricing = pricing.data[idx]
      // Assume taxesAndFees are constant
      // update price and/or property fees
      return {
        ...oldOccupancyPricing,
        ...((newRoomPricing.inclusive === undefined || newRoomPricing.inclusive === 0) ? {} : {
          inclusive: newRoomPricing.inclusive,
          exclusive: newRoomPricing.inclusive - oldOccupancyPricing.taxesAndFees,
        }),
        ...(newRoomPricing.propertyFees === undefined ? {} : { propertyFees: newRoomPricing.propertyFees }),
      }
    })

    const newTotals = newOccupancyPricing.reduce((acc, occupancyPricing) => ({
      inclusive: acc.inclusive + occupancyPricing.inclusive,
      exclusive: acc.exclusive + occupancyPricing.exclusive,
      taxesAndFees: acc.taxesAndFees + occupancyPricing.taxesAndFees,
      propertyFees: acc.propertyFees + (occupancyPricing.propertyFees ?? 0),
    }), { exclusive: 0, inclusive: 0, taxesAndFees: 0, propertyFees: 0 })

    const newRoomRate = {
      ...roomRate,
      occupancyPricing: newOccupancyPricing,
      totals: newTotals,
    }

    // preserve ordering
    const newBedbankRateList = [...bedbankRateList.slice(0, roomRateIdx), newRoomRate, ...bedbankRateList.slice(roomRateIdx + 1)]
    const newOfferRate = { ...offerRate, [bedbankRateKey]: newBedbankRateList }
    const newBedbankOfferRates = { ...state.bedbankOfferRates, [offerId]: newOfferRate }

    return {
      bedbankOfferRates: newBedbankOfferRates,
    }
  },
  [OFFER_UPDATE_AVAILABLE_RATES]: (state, action) => {
    if (action.key in state.offerAvailableRatesByOccupancy[action.offerId]) {
      return {
        offerAvailableRates: {
          ...state.offerAvailableRates,
          [action.offerId]: {
            ...state.offerAvailableRatesByOccupancy[action.offerId],
            [action.key]: state.offerAvailableRatesByOccupancy[action.offerId][action.key],
          },
        },
      }
    }
    return state
  },
  [OFFER_PUSH_TO_LIST_BOTTOM]: (state, action) => {
    const { offerId, listKey, reason } = action
    const offerList = state.offerLists[listKey]
    const adjustedOffers = state.offerListAdjustedOffers[listKey] || {}
    if (!offerList) { return state }

    const offerIds = offerList.offerIds.filter(id => id !== offerId)
    offerIds.push(offerId)
    adjustedOffers[offerId] = reason

    return {
      offerLists: {
        ...state.offerLists,
        [listKey]: {
          ...offerList,
          offerIds,
        },
      },
      offerListAdjustedOffers: {
        ...state.offerListAdjustedOffers,
        [listKey]: adjustedOffers,
      },
    }
  },
  [REMOVE_OFFER]: (state, action) => {
    const offerStates = [
      state.offers,
      state.bedbankOffers,
      state.tourV2Offers,
      state.offerSummaries,
      state.bedbankOfferSummaries,
    ]

    const [
      offers,
      bedbankOffers,
      tourV2Offers,
      offerSummaries,
      bedbankOfferSummaries,
    ] = offerStates.map<any>(offerState => {
      if (offerState[action.offerId]) {
        const nextState = { ...offerState }
        delete nextState[action.offerId]
        return nextState
      }
      return offerState
    })

    return {
      offers,
      bedbankOffers,
      tourV2Offers,
      offerSummaries,
      bedbankOfferSummaries,
    }
  },
  [ADD_OFFER_LIST_EXTRA]: (state, action) => {
    const { key, extra } = action
    const offerList = state.offerLists[key]

    if (!offerList) {
      return state
    }

    const newExtra: App.OfferListExtra = {
      ...extra,
      position: offerList.offerIds?.length ?? extra.position,
    }

    const nextExtras: Array<App.OfferListExtra> = [...(offerList.extras || []), newExtra]

    return {
      offerLists: {
        ...state.offerLists,
        [key]: {
          ...offerList,
          extras: nextExtras,
        },
      },
    }
  },
  [END_OFFER_LIST_STREAM]: (state, action) => {
    const { key, searchId } = action
    const offerList = state.offerLists[key]

    if (!offerList || offerList.searchId !== searchId) {
      return state
    }

    return {
      offerLists: {
        ...state.offerLists,
        [key]: {
          ...offerList,
          streamEnded: true,
          fetching: false,
          scrollFetching: false,
        },
      },
      offerListFilterOptions: {
        ...state.offerListFilterOptions,
        [key]: {
          ...state.offerListFilterOptions[key],
          fetching: false,
        },
      },
    }
  },
  [SET_OFFER_LIST_ERROR]: (state, action) => {
    const prevState = state.offerLists[action.key]

    return {
      offerLists: {
        ...state.offerLists,
        [action.key]: {
          ...prevState,
          error: action.error,
        },
      },
    }
  },
})

export default offersReducer
