import { useMemo } from 'react'
import moment from 'moment'

import { calculateNights } from 'tripPlanner/utils'
import { filterPromotionsByTravelPeriod } from 'lib/hotels/bedbankUtils'

function filterRatePromotions(
  promotions: Array<App.BedbankPromotion>,
  checkIn?: string,
  checkOut?: string,
  anytimeSearch = false,
): Record<string, App.BedbankPromotion> {
  let filteredPromotions: Array<App.BedbankPromotion>

  if (anytimeSearch) {
    filteredPromotions = promotions.filter((promo) => {
      const travelToDate = new Date(promo.travelPeriod.to)
      const currentDate = new Date()

      return travelToDate >= currentDate // filter out promotions that have ended
    })
  } else {
    if (!checkIn || !checkOut) {
      return {}
    }
    const numberOfNights = calculateNights(checkIn, checkOut)
    filteredPromotions = promotions.filter((promo) => {
      return numberOfNights && promo.los <= numberOfNights && filterPromotionsByTravelPeriod(promo, checkIn, checkOut)
    })
  }

  return filteredPromotions.reduce<Record<string, App.BedbankPromotion>>((acc, promo) => {
    for (const rateId of promo.restrictToRates) {
      acc[rateId] = promo
    }
    return acc
  }, {})
}

export function useBedbankRoomRatePromotions(
  promotions: Array<App.BedbankPromotion> | undefined,
  checkInDate?: string,
  checkOutDate?: string,
  anytimeSearch = false,
) {
  return useMemo(() => filterRatePromotions(promotions ?? [], checkInDate, checkOutDate, anytimeSearch), [promotions, checkInDate, checkOutDate, anytimeSearch])
}

export function useBedbankPromotions(promotions: Array<App.BedbankPromotion>, checkinDate?: moment.Moment, checkoutDate?: moment.Moment) {
  return useMemo(() => {
    return !(checkinDate && checkoutDate) && promotions.length > 0 ? promotions : []
  }, [promotions, checkinDate, checkoutDate])
}

export function useBedbankSearchTilePromotions(promotions: Array<App.BedbankPromotion>, rate?: { id: string }, checkinDate?: string, checkoutDate?: string) {
  return useMemo<Array<App.BedbankPromotion>>(() => {
    if (rate) {
      const ratesPromo = filterRatePromotions(promotions, checkinDate, checkoutDate)
      return ratesPromo[rate.id] ? [ratesPromo[rate.id]] : []
    }

    return promotions
  }, [promotions, rate, checkinDate, checkoutDate])
}
