import { OfferPageActions, OfferPageDispatchAction, OfferPageFilterTypes, OfferPageState } from 'contexts/OfferPage/offerPageStateReducer'
import { isFreeOrFlexibleLpcCancellation } from 'lib/offer/cancellationPolicyUtils'
import { getPkgInclusionsCount } from 'lib/offer/inclusionUtils'
import { generateRoomOccupants } from 'lib/offer/occupancyUtils'
import { buildRoomPackageOptionsViewOrder } from 'lib/offer/offerPageUtil'
import moment from 'moment'
import { createSelector } from 'reselect'
import { OfferPackageOptionView } from 'selectors/offerPage/offerPageSelectors'
import { BookmarkDatesAndOccupancy } from 'tripPlanner/types/tripItem'
import config from 'constants/config'
import { descriptionBuilder } from 'lib/offer/descriptionBuilder'

const memoizeOccupancy = createSelector(
  (offerPageState: OfferPageState) => offerPageState.rooms,
  (rooms?: Array<App.RoomOccupants>) => rooms?.map(room => ({
    adults: room.adults,
    children: room.children,
    infants: room.infants,
    childrenAge: room.childrenAge,
  })),
)

export const getDatesAndGuestsFromOfferPageState = (offerPageState: OfferPageState): BookmarkDatesAndOccupancy => {
  const checkInMoment = offerPageState.checkInDate ? moment(offerPageState.checkInDate) : undefined
  /*
   * Note: For LE offer pages, the end date is derived from `checkInDate` plus `duration`.
   * Whereas for bedbank offer page, `checkOutDate` is used explicitly and there's no `duration`.
   */
  const checkOutMoment =
    ((offerPageState.checkInDate && offerPageState.duration && !offerPageState.bedbankOffer) ?
      checkInMoment?.clone().add(offerPageState.duration, 'days') :
        (offerPageState.checkOutDate ? moment(offerPageState.checkOutDate) : undefined)
    )

  return {
    checkInDate: checkInMoment,
    checkOutDate: checkOutMoment,
    durationInDays: offerPageState.duration,
    occupancies: memoizeOccupancy(offerPageState),
  }
}

const isSameOrBetterCancellationPolicy = (policyA: App.OfferCancellationPolicy | undefined, policyB: App.OfferCancellationPolicy | undefined, checkIn: moment.Moment, propertyTimeZoneOffset: number | undefined) => {
  const typeA = isFreeOrFlexibleLpcCancellation(policyA, checkIn, propertyTimeZoneOffset)
  const typeB = isFreeOrFlexibleLpcCancellation(policyB, checkIn, propertyTimeZoneOffset)
  if (typeA === 'flexible') return !!typeB
  if (typeA === 'free') return typeB === 'free'
  return true
}

const compareUpgradeableLPCPackage = (option: OfferPackageOptionView, selectedPackageOption: OfferPackageOptionView, checkIn: moment.Moment, checkOut: moment.Moment, propertyTimeZoneOffset: number | undefined, sameRoom: boolean) => {
  const selectedPolicy = selectedPackageOption.package.roomRate?.cancellationPolicy || selectedPackageOption.package.cancellationPolicy
  const comparingPolicy = option.package.roomRate?.cancellationPolicy || option.package.cancellationPolicy
  let moreInclusionValue = false
  const inclusionsComparing = getPkgInclusionsCount(option.package, { checkIn, checkOut })
  const inclusionsSelected = getPkgInclusionsCount(selectedPackageOption.package, { checkIn, checkOut })

  const selectedHasBreakfast = selectedPackageOption.package.inclusions?.some(inclusion => inclusion.description.toLocaleLowerCase().includes('breakfast'))
  const optionHasBreakfast = option.package.inclusions?.some(inclusion => inclusion.description.toLocaleLowerCase().includes('breakfast'))
  const selectedHasTransfer = selectedPackageOption.package.inclusions?.some(inclusion => inclusion.description.toLocaleLowerCase().includes('transfer'))
  const optionHasTransfer = option.package.inclusions?.some(inclusion => inclusion.description.toLocaleLowerCase().includes('transfer'))

  // if same room, need to offer more inclusions, otherwise it's just more expensive
  if (sameRoom) {
    moreInclusionValue = inclusionsComparing > inclusionsSelected
  } else {
    moreInclusionValue = inclusionsComparing >= inclusionsSelected
  }
  return (
    // must have the same or better cancellation policy
    isSameOrBetterCancellationPolicy(selectedPolicy, comparingPolicy, checkIn, propertyTimeZoneOffset) &&
    // must have same inclusions or more if non-refundable
    moreInclusionValue &&
    // must be more expensive
    option.hotelPrice > selectedPackageOption.hotelPrice &&
    // must have breakfast inclusion if selected package has it
    (!selectedHasBreakfast || optionHasBreakfast) &&
    // must have transfer inclusion if selected package has it
    (!selectedHasTransfer || optionHasTransfer) &&
    // must be refundable if selected package is refundable
    (!selectedPackageOption.refundable || option.refundable)
  )
}

const compareUpgradeablePackage = (option: OfferPackageOptionView, selectedPackageOption: OfferPackageOptionView) => {
  const selectedHasTransfer = selectedPackageOption.package.inclusions?.some(inclusion => inclusion.parentCategory === 'Transport')
  const optionHasTransfer = option.package.inclusions?.some(inclusion => inclusion.parentCategory === 'Transport')
  const selectedHasFood = selectedPackageOption.package.inclusions?.some(inclusion => inclusion.parentCategory === 'Food')
  const optionHasFood = option.package.inclusions?.some(inclusion => inclusion.parentCategory === 'Food')

  return (
    // must have the same cancellation policy
    option.package.roomRate?.cancellationPolicy?.type === selectedPackageOption.package.roomRate?.cancellationPolicy?.type &&
    // must be more expensive
    option.hotelPrice > selectedPackageOption.hotelPrice &&
    // must have the same number of inclusions or more
    (option.package.inclusions?.length ?? 0) >= (selectedPackageOption.package.inclusions?.length ?? 0) &&
    // must have transfer inclusion if selected package has it
    (!selectedHasTransfer || optionHasTransfer) &&
    // must have food inclusion if selected package has it
    (!selectedHasFood || optionHasFood)
  )
}

export function getUpgradeablePackage(
  packageOptions: Array<OfferPackageOptionView>,
  selectedPackageOption: OfferPackageOptionView,
  checkIn: moment.Moment,
  isLPC: boolean,
  propertyTimeZoneOffset: number | undefined,
) {
  // 1. Get packages by room
  const packagesByRoom = buildRoomPackageOptionsViewOrder(packageOptions)

  // 2. Get the packages for the selected room
  const indexForSelectedRoom = packagesByRoom.findIndex(option => option.roomId === selectedPackageOption.package.roomType?.id)
  const packagesForSelectedRoom = packagesByRoom[indexForSelectedRoom].packageViews

  // 3. Exclude the selected package and any packages of the same room type that are considered a lower tier compared with the selected package
  const indexForSelectedPackage = packagesForSelectedRoom.findIndex(option => option.package.uniqueKey === selectedPackageOption.package.uniqueKey)
  const higherTierPackagesForSelectedRoom = packagesForSelectedRoom.slice(indexForSelectedPackage + 1, packagesForSelectedRoom.length)

  // 4. Create an array combining all packages, excluding lower tier rooms, and lower tier packages of the selected room
  const packagesForHigherTierRooms = packagesByRoom.slice(indexForSelectedRoom + 1, packagesByRoom.length).flatMap(room => room.packageViews)

  // 5. From the potential higher tier packages, check the cancellation policy, availability and price to find the upgradeable packages
  const allUpgradablePackages: Array<OfferPackageOptionView> = []
  // integrated this logic here as we eventually want to use the LPC logic if proven
  if (isLPC) {
    const checkOut = checkIn.clone().add(selectedPackageOption.package.duration, 'days')
    const sameRoom = higherTierPackagesForSelectedRoom.filter(option =>
      option.hotelAvailable && option.capacityValid &&
      compareUpgradeableLPCPackage(option, selectedPackageOption, checkIn, checkOut, propertyTimeZoneOffset, true),
    )
    if (!sameRoom.length) {
      const nextRoom = packagesForHigherTierRooms.filter(option =>
        option.hotelAvailable && option.capacityValid &&
        compareUpgradeableLPCPackage(option, selectedPackageOption, checkIn, checkOut, propertyTimeZoneOffset, false),
      )
      allUpgradablePackages.push(...nextRoom)
    } else {
      allUpgradablePackages.push(...sameRoom)
    }
  } else {
    const allHigherTierPackages = [
      ...higherTierPackagesForSelectedRoom,
      ...packagesForHigherTierRooms,
    ].filter(option => option.hotelAvailable && option.capacityValid)
    allUpgradablePackages.push(...allHigherTierPackages.filter(option => compareUpgradeablePackage(option, selectedPackageOption)))
  }

  // 6. Find the cheapest package (whether that is from the same tier of room or in the room tiers above)
  const minPriceAmongUpgradeablePackages = Math.min(...allUpgradablePackages.map(packageView => packageView.hotelPrice))

  /* 7. Return the upgradable package which is:
    * - Always more expensive than the selected package
    * - Has the same cancellation policy
    * - Availability / capacity requirements are met
    * - From the same room type but a higher tier package OR from a highter tier room type
    * - Off all the upgradeable packages, is the least expensive
  */
  return allUpgradablePackages.find(packageView => packageView.hotelPrice === minPriceAmongUpgradeablePackages)
}

export function getNextFilterWithCapacityCheckInMiddlware(
  state: OfferPageState,
  action: OfferPageDispatchAction,
  next: (state: OfferPageState) => OfferPageState,
) {
  if (action.type === OfferPageActions.filterContinue) {
    const hasRooms = !!state.rooms
    const hasDate = !!state.checkInDate
    const hasInvalidChildren = state.rooms?.some((room) =>
      room.childrenAge?.some((age) => age === -1),
    )
    /*
      Base filter flow is: Capacity -> Check in
      Then there's a priority list based on what data is missing
    */
    const nextState = next(state)
    let nextFilter: OfferPageFilterTypes | undefined = undefined
    if (hasInvalidChildren) {
      // invalid children, don't let them progress until dealt with
      nextFilter = 'capacity'
    } else if (state.activeFilter === 'capacity') {
      if (!hasRooms) {
        // user looked at capacity, didn't change anything and closed it
        // we consider this them applying the default rooms, so set rooms
        nextState.rooms = [generateRoomOccupants()]
      }

      if (!hasDate) {
        nextFilter = 'checkin'
      }
    } else if (state.activeFilter === 'checkin') {
      if (!hasRooms && hasDate) {
        nextFilter = 'capacity'
      }
    } else if (!hasDate && hasRooms && state.activeFilter === 'airport') {
      nextFilter = 'checkin'
    }
    nextState.activeFilter = nextFilter
    return nextState
  }

  return state
}

const getExperimentHeadData = (offer: App.Offer) => {
  const propertyName = offer.property?.name
  const locationHeading = `(${offer.locationHeading})`
  const year = new Date().getFullYear()
  const nextYear = (year + 1).toString().slice(-2)

  const description = (propertyName ? propertyName + ' ' : '') + locationHeading + ' - ' + "Today's " + year + '/' + nextYear + ' Deal'
  return `${description} - ${config.title}`
}

export const getHeadTitle = (offer: App.Offer, isExperimentOn: boolean) => {
  if (isExperimentOn) {
    return getExperimentHeadData(offer)
  }
  return [offer.name, offer.locationHeading, offer.locationSubheading].filter(t => t).join(', ')
}

const getExperimentDescription = (offer: App.Offer) => {
  const propertyName = offer.property?.name
  const year = new Date().getFullYear()
  return `Today's ${propertyName} Deal for Travel in ${year}/${year + 1} - ${offer.name}.`
}

export const getHeadDescription = (offer: App.Offer, isExperimentOn?: boolean) => {
  if (isExperimentOn) {
    return getExperimentDescription(offer)
  }
  return descriptionBuilder(
    offer.name,
    offer?.lowestPricePackage?.price ?? 0,
    offer?.lowestPricePackage?.value ?? 0,
    config.BRAND,
    config.title,
  )
}

export function isMetadataExperimentOn(url: string): boolean {
  if (config.BRAND === 'luxuryescapes') {
    return url.length % 2 === 1
  }
  return false
}
