import { getBusinessTravellerSelectEnabled } from 'businessTraveller/utils/getBusinessTravellerSelectEnabled'
import config from 'constants/config'
import { createSelector } from 'reselect'

export const selectSelectedTravellerEmployees = createSelector(
  (state: App.State) => state.businessTraveller.currentBusinessAccount,
  (state: App.State) => state.businessTraveller.selectedTravellerEmployeeIds,
  (state: App.State) => state.businessTraveller.employees,
  (
    businessAccount,
    employeeIds,
    employees,
  ): Array<App.BusinessTraveller.EmployeeFromMeEndpoint> => {
    if (!getBusinessTravellerSelectEnabled(businessAccount) || employees?.status !== 'success') {
      return []
    }

    // get the employee records for the employeeIds of the selected travellers
    // employees: {all: { [employeeId: string]: BusinessTraveller.Employee }}
    if (employeeIds && employeeIds.length > 0) {
      const employeeRecords = employeeIds.map(id => employees.all[id])
      if (employeeRecords.every(ele => ele === undefined)) {
        return []
      }
      return employeeRecords
    }

    return []
  },
)

export const selectIsBusinessTravellerCheckingOutOnBehalf = createSelector(
  (state: App.State) => state.businessTraveller.currentBusinessAccount,
  (state: App.State) => selectSelectedTravellerEmployees(state),
  (businessAccount, travellerEmployees) => {
    // This is used to show the "traveller will receive email" alert. Only supports
    // single traveller book-on-behalf of for now. We ignore multi traveller bookings
    // until support for showing orders in My Escapes for non-primary traveller employees is added
    return getBusinessTravellerSelectEnabled(businessAccount) &&
      ((travellerEmployees.length === 1 &&
        travellerEmployees[0].customerId !== businessAccount?.employee?.customerId))
  },
)

export const selectShowBusinessTravellerBookingCustomerLabel = createSelector(
  (state: App.State) => state.businessTraveller.currentBusinessAccount,
  (businessAccount) => {
    return config.businessTraveller.currentAccountMode === 'business' &&
      getBusinessTravellerSelectEnabled(businessAccount)
  },
)

export const selectTravellerCount = createSelector(
  (state: App.State) => state.businessTraveller.selectedTravellerGuestCount,
  (state: App.State) => state.businessTraveller.selectedTravellerEmployeeIds,
  (guestCount, employeeIds) => (guestCount + employeeIds.length) || 1,
)
