import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'

export const getBedbankChangeDatesTravellers = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (existingOrder, postPurchase) => {
    const bedbankItem = existingOrder?.bedbankItems[0]

    if (postPurchase === 'change-dates' && bedbankItem) {
      return {
        bookingPhone: bedbankItem.bookingPhone,
        bookingEmail: bedbankItem.bookingEmail,
        guestFirstName: bedbankItem.rooms[0]?.guestFirstName,
        guestLastName: bedbankItem.rooms[0]?.guestLastName,
      }
    }
  },
)
