import React, { ComponentProps, PropsWithChildren, forwardRef } from 'react'
import styled from 'styled-components'
import _DropdownSheet, { DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE, DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE, DROPDOWN_SHEET_MIN_HEIGHT_CSS_VARIABLE, DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE, DROPDOWN_SHEET_WIDTH_CSS_VARIABLE } from '../_DropdownSheet'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import clsx from 'clsx'

const Container = styled(_DropdownSheet)`
  ${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE}: auto;

  ${mediaQueryUp.tablet} {
    ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(280)};
  }

  &:not(.auto-height) {
    ${DROPDOWN_SHEET_MIN_HEIGHT_CSS_VARIABLE}: ${rem(200)};
    ${DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE}: ${rem(480)};

    ${mediaQueryUp.tablet} {
      ${DROPDOWN_SHEET_MIN_HEIGHT_CSS_VARIABLE}: ${rem(200)};
      ${DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE}: ${rem(584)};
    }
  }

  &.size-S {
    ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(200)};
    ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(335)};

    ${mediaQueryUp.tablet} {
      ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(220)};
      ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(335)};
    }
  }
  &.size-M {
    ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(335)};
    ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(335)};

    ${mediaQueryUp.tablet} {
      ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(400)};
      ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(600)};
    }
  }
  &.size-L {
    ${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE}: ${rem(1140)};
  }

  &.size-XL {
    ${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE}: ${rem(1140)};
    ${DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE}: ${rem(540)};

    ${mediaQueryUp.tablet} {
      ${DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE}: ${rem(640)};
    }
  }

  &.size-fill-anchor {
    ${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE}: 100%;
  }
`

type InheritedBaseSheetProps = Pick<ComponentProps<typeof _DropdownSheet>, 'height'>

interface Props extends PropsWithChildren, InheritedBaseSheetProps {
  /**
   * Controls the width and height of the sheet
   */
  size: 'S' | 'M' | 'L' | 'XL' | 'fill-anchor'
  autoHeight?: boolean
}

const DropdownSheetSheet = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    height,
    size,
    autoHeight,
  } = props

  return <Container ref={ref} className={clsx(`size-${size}`, { 'auto-height': autoHeight })} height={height}>
    {children}
  </Container>
})

export default DropdownSheetSheet
