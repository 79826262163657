/**
 * Compute cabin pricing data
 *
 * @remarks
 * Pricing data comes from 2 sources:
 *  - Cabin Pricing
 *  - Cabin Selection
 *
 *  Cabin Pricing takes priority
 */
export function computeCabinPricing(
  cabinSelectionData: App.Cruises.BookingCabinSelectionData,
  cabinPricingData: App.Cruises.BookingCabinPricingData,
): App.Checkout.ItemViewTotals {
  const { cabinSelection } = cabinSelectionData
  const { cabinPricing } = cabinPricingData

  if (cabinPricing) {
    const pricing = cabinPricing.pricing

    return {
      taxesAndFees: pricing.tax?.amount ?? 0,
      price: pricing.total.amount,
      memberPrice: 0,
      value: 0,
      memberValue: 0,
      surcharge: 0,
      extraGuestSurcharge: 0,
    }
  }
  else if (cabinSelection) {
    const { pricing } = cabinSelection

    return {
      taxesAndFees: pricing.tax?.amount ?? 0,
      price: pricing.total.amount,
      memberPrice: 0,
      value: 0,
      memberValue: 0,
      surcharge: 0,
      extraGuestSurcharge: 0,
    }
  }
  else {
    return {
      taxesAndFees: 0,
      price: 0,
      memberPrice: 0,
      value: 0,
      memberValue: 0,
      surcharge: 0,
      extraGuestSurcharge: 0,
    }
  }
}
