import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import config from 'constants/config'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { rem } from 'polished'
import React, { ComponentProps, useCallback } from 'react'
import styled from 'styled-components'
import TourStyledNonSignatureProductTypeInfoSheet from './TourStyledNonSignatureProductTypeInfoSheet'

type NonSignatureStyledProductType = Exclude<App.Tours.V2ProductTypeStyled, App.Tours.V2SignatureSeriesProductType>

const NON_SIGNATURE_STYLES: Array<NonSignatureStyledProductType> = ['deluxe_tour', 'premium_tour', 'ultra_lux_tour']

const StylesContainer = styled.div`
  display: grid;
  grid-template: repeat(3, 1fr) / 1fr;
  padding-block: ${rem(12)};

  ${mediaQueryUp.tablet} {
    grid-template: 1fr / repeat(3, 1fr);
  }
`

type InheritedModalProps = Pick<ComponentProps<typeof Modal>, 'primaryActionText' | 'primaryActionTo'>

interface Props extends InheritedModalProps {
  productType: NonSignatureStyledProductType
}

export type TourStyledNonSignatureProductTypeInfoModalResult = 'primary-action' | undefined

function TourStyledNonSignatureProductTypeInfoModal({
  productType,
  primaryActionText,
  primaryActionTo,
}: Props) {
  const modalElement = useModalElementContext<TourStyledNonSignatureProductTypeInfoModalResult>()

  const handlePrimaryClick = useCallback(() => {
    modalElement.resolve('primary-action')
  }, [modalElement])

  return <Modal
    size="L"
    title={<>{config.brandConfig.name} Tour Styles</>}
    primaryActionText={primaryActionText}
    primaryActionTo={primaryActionTo}
    onPrimaryActionClick={!primaryActionTo ? handlePrimaryClick : undefined}
    // secondaryActionText="Learn more"
  >
    <Group direction="vertical" gap={8}>
      <StylesContainer>
        {NON_SIGNATURE_STYLES.map((knownTourStyle) => <TourStyledNonSignatureProductTypeInfoSheet
          key={knownTourStyle}
          productType={knownTourStyle}
          isCurrent={knownTourStyle === productType}
        />)}
      </StylesContainer>
      <BodyText colour="neutral-two" variant="medium">
        *Max travellers do not apply for Private Tours.
      </BodyText>
    </Group>
  </Modal>
}

export default TourStyledNonSignatureProductTypeInfoModal
