import React from 'react'
import PriceRowMarginText from '../PriceRowMarginText'

interface Props {
  margin: number
  marginAud: number
  supplier: string
  type: 'exact' | 'estimate' | 'bedbank_exact' | 'bedbank_exact_accom' | 'bedbank_estimate'
}

function BedbankMarginText(props: Props) {
  const {
    margin,
    marginAud,
    supplier,
    type,
  } = props

  return <PriceRowMarginText
    size="M"
    type={type}
    marginValue={marginAud}
    margin={margin}
    supplier={supplier}
    colour="critical"
  />
}

export default BedbankMarginText
