import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { replace } from 'connected-react-router'
import { useLocation } from 'react-router'

import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import { getPlural } from 'lib/string/pluralize'
import { getCruiseSearchStateFromParams, getCruiseOfferFiltersFromParams } from 'lib/cruises/cruiseUtils'

import { useGlobalCruisesSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalCruisesSearch'
import useCruiseSearchFlashOffers from 'hooks/Cruise/useCruiseSearchFlashOffers'
import useCruiseSearchCount from 'hooks/Cruise/useCruiseSearchCount'
import { useAppDispatch } from 'hooks/reduxHooks'
import useQueryParams from 'hooks/useQueryParams'
import useOfferListTracking from 'hooks/Offers/useOfferListTracking'

import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'

import AnalyticsPageContext, { AnalyticsPage } from 'contexts/Analytics/analyticsPageContext'
import OfferList, { OfferListAction, OfferListAndAction, OfferListV2Item } from 'components/OfferList/OfferList'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LoadingAnimation from 'components/Common/Loading/LoadingAnimation'
import { OfferListEventsProvider } from 'components/OfferList/OfferListEventsContext'

import Pane from 'components/Common/Pane'
import Group from 'components/utils/Group'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import LayoutContainer from 'components/Common/LayoutContainer'

import CruiseSearchFiltersBar from './Filters/CruiseSearchFiltersBar'
import CruiseSearchFiltersSidebar from './Filters/CruiseSearchExtraFiltersSidebar'
import CruiseSearchFiltersMobile from './Filters/CruiseSearchFiltersMobile'
import CruiseSortDropdown from './Filters/Inputs/CruiseSortDropdown'
import CruiseHeadData from '../Common/CruiseHeadData'
import CruisesContact from '../Common/CruisesContact'
import { getListName } from 'analytics/enhanced-ecommerce'
import cn from 'clsx'
import FormatDecimal from 'components/utils/Formatters/FormatDecimal'
import getOfferListKey from 'lib/offer/offerListKey'
import CruiseHeaderFilterChip from 'components/Cruises/SearchPage/Filters/CruiseHeaderFilterChip'
import CruiseOfferListNoResults from 'components/Cruises/Common/CruiseOfferListNoResults'
import CruiseSelectCabinType from './Filters/CruiseSelectCabinType'
import Divider from 'components/Luxkit/Divider'

const PageContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: ${rem(20)} 0;

  ${mediaQueryUp.desktop} {
    padding: ${rem(16)} 0;
    gap: ${rem(32)};
    grid-template-columns: ${rem(250)} 1fr;
    &.no-filters {
      grid-template-columns: 1fr;
    }
  }
`

const CruiseSearchPanel = styled.div`
  position: relative;
  padding: ${rem(20)} 0;
`

const StyledLayoutContainer = styled(LayoutContainer)`
  padding-inline: 0;

  ${mediaQueryUp.tablet} {
    padding-inline: ${rem(16)};
  }

  ${mediaQueryUp.desktop} {
    && {
      max-width: ${rem(1352)};
    }
  }
`

const CountWrapper = styled(Group)`
  padding-left: ${rem(16)};

  ${mediaQueryUp.tablet} {
    padding-left: 0;
  }
`

interface Props {
  shouldHideCruiseShipsFilters?: boolean;
  initFilter?: App.OfferListFilters;
}

function CruisesSearchPage({ shouldHideCruiseShipsFilters = false, initFilter }: Props) {
  const queryParams = useQueryParams()
  const dispatch = useAppDispatch()
  const { search } = useLocation()

  const initialState = useMemo(() => ({
    ...getCruiseSearchStateFromParams(queryParams),
    ...(initFilter ? { filters: initFilter } : {}),
  }), [initFilter, queryParams])

  const { globalCruisesSearchDispatch, globalCruisesSearchState } = useGlobalCruisesSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    ...initialState,
  })

  const [filters, filtersSelected] = useMemo(() => {
    const [baseFilters, hasFilters] = getCruiseOfferFiltersFromParams(queryParams)
    if (initFilter) {
      return [{ ...baseFilters, ...initFilter }, true]
    }
    return [baseFilters, hasFilters]
  }, [initFilter, queryParams])

  const tracking = useOfferListTracking(
    getListName('OfferList', 'CRUISE', 'Search Page Offer List'),
    { ias: { list: 'cruises-search' }, key: getOfferListKey(filters) },
  )
  const flashOffers = useCruiseSearchFlashOffers({ filters, globalFilters: globalCruisesSearchState })
  const [searchCount, fetchingSearchCount] = useCruiseSearchCount(filters)

  const interstitials = useMemo((): Array<OfferListV2Item> => ([{
    id: 'heading',
    position: 'start',
    show: 'hasOffers',
  }, {
    id: 'empty',
    position: 'start',
    show: 'hasNoOffers',
    element: !flashOffers.offerCount && <CruiseOfferListNoResults/>,
  }]), [flashOffers.offerCount])

  const onSortChange = useCallback((nextSort: App.OfferListSortByOptions) => {
    const nextSearch = setSearchParamValue(search, 'sortBy', nextSort)
    dispatch(replace({ search: nextSearch }))
  }, [dispatch, search])

  const overrideFlashOfferList = useMemo<OfferListAndAction>(() => ({
    action: OfferListAction.OVERRIDE,
    offerList: flashOffers,
  }), [flashOffers])

  const count = searchCount + (flashOffers.offerCount ?? 0)

  return (
    <AnalyticsPageContext.Provider value={AnalyticsPage.search} >
      <div>
        <CruiseHeadData />

        <GlobalSearchContextProvider
          state={globalCruisesSearchState}
          dispatch={globalCruisesSearchDispatch}
        >
          <CSSBreakpoint as={Pane} min="tablet">
            <LayoutContainer>
              <CruiseSearchPanel>
                <CruiseSearchFiltersBar />
              </CruiseSearchPanel>
            </LayoutContainer>
          </CSSBreakpoint>

          <CSSBreakpoint min="desktop">
            <Divider kind="primary" />
          </CSSBreakpoint>

          {(fetchingSearchCount || !!searchCount || !!flashOffers.offerCount || filtersSelected) &&
            <CruiseSearchFiltersMobile filters={filters} />
          }

          <CSSBreakpoint only="tablet" as={Pane}>
            <Group direction="vertical" gap={16} verticalAlign="center">
              <StyledLayoutContainer>
                <CruiseHeaderFilterChip filters={filters} />
              </StyledLayoutContainer>
              <Divider kind="primary" />
              <StyledLayoutContainer>
                <CruiseSelectCabinType filters={filters} />
              </StyledLayoutContainer>
            </Group>
          </CSSBreakpoint>

          <StyledLayoutContainer>
            <PageContent className={cn({ 'no-filters': !fetchingSearchCount && !searchCount && !flashOffers.offerCount && !filtersSelected })}>
              {(fetchingSearchCount || !!searchCount || !!flashOffers.offerCount || filtersSelected) &&
                <CSSBreakpoint min="desktop">
                  <CruiseSearchFiltersSidebar filters={filters} shouldHideCruiseShips={shouldHideCruiseShipsFilters} />
                </CSSBreakpoint>
              }
              <Group direction="vertical" gap={16}>
                <CSSBreakpoint min="desktop">
                  <CruiseSelectCabinType filters={filters}/>
                </CSSBreakpoint>
                {(fetchingSearchCount || !!searchCount || filtersSelected) && <CountWrapper
                  direction="horizontal"
                  gap={8}
                  horizontalAlign="space-between"
                  desktopVerticalAlign="center"
                  tabletVerticalAlign="end"
                >
                  <Group direction="horizontal" gap={16} verticalAlign="center">
                    <BodyText variant="large">
                      {fetchingSearchCount && 'Searching for cruises'}
                      {!fetchingSearchCount && <><FormatDecimal value={count} /> {getPlural('cruise', count)} found</>}
                    </BodyText>
                    {fetchingSearchCount && <LoadingAnimation size={8} />}
                  </Group>

                  <CSSBreakpoint min="desktop">
                    <CruiseSortDropdown sort={filters.sortBy ?? 'recommended'} onChange={onSortChange} />
                  </CSSBreakpoint>
                </CountWrapper>}
                <OfferListEventsProvider tracking={tracking}>
                  <Group direction="vertical" gap={16}>
                    <OfferList
                      extraOfferListAndAction={overrideFlashOfferList}
                      tileStyle="search"
                      pageSize={30}
                    />

                    <OfferList
                      filters={filters}
                      tileStyle="search"
                      interstitials={interstitials}
                    />
                  </Group>
                </OfferListEventsProvider>
              </Group>
            </PageContent>
          </StyledLayoutContainer>
          <CruisesContact />
        </GlobalSearchContextProvider>
      </div>
    </AnalyticsPageContext.Provider>
  )
}

export default CruisesSearchPage
