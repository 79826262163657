import React from 'react'
import cn from 'clsx'
import { rem } from 'polished'
import styled from 'styled-components'
import Image from 'components/Common/Image'
import Clickable from 'components/Common/Clickable'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import { UserPreferenceDestination } from '../types'
import { usePlaceById } from 'hooks/usePlace'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import SolidBellRingIcon from 'components/Luxkit/Icons/solid/SolidBellRingIcon'
import { getDestinationImage } from '../../../../lib/search/searchUtils'
import { ANYWHERE_PLACE_ID } from '../../../../constants/search'

const Item = styled(Clickable)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${rem(4)};
`

const ImageContainer = styled.div`
  border: ${rem(2)} solid transparent;
  transition: border-color 0.2s;
  &.bordered {
    border-color: ${props => props.theme.palette.neutral.default.one};
  }
`

const FloatingCheckbox = styled(CheckboxButton)`
  position: absolute;
  top: ${rem(0)};
  right: ${rem(0)};
  opacity: 0;
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
  }
`

function DestinationDisplayItem({
  item,
  isSelected,
  onSelect,
  showLabel,
}: {
  item: UserPreferenceDestination,
  isSelected: boolean,
  onSelect: (item: UserPreferenceDestination) => void
  showLabel: boolean,
}) {
  const place = usePlaceById(item.placeId, !!item.name)
  const fallbackImage = getDestinationImage(item.placeId) || getDestinationImage(ANYWHERE_PLACE_ID)

  return (<Item onClick={() => { onSelect(item) }}>
    <ImageContainer className={cn({ bordered: isSelected })}>
      <Image fit="center" id={item.imageId || fallbackImage} alt={item.name || place?.name} width={192} height={124}/>
    </ImageContainer>

    {showLabel &&
      <BodyTextBlock endIcon={item.isAlertSelected ? <SolidBellRingIcon size="XS" /> : null} variant="medium" weight="bold" colour="neutral-two">
        {item.name || place?.name}
      </BodyTextBlock>}

    <FloatingCheckbox size="large" checked className={cn({ visible: isSelected })} />
  </Item>)
}

export default DestinationDisplayItem
