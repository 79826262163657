import requestUtils from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-business'
import { mapObject } from 'lib/object/objectUtils'

type BusinessCreditsBatchCalculateServerResponse = paths['/api/business-traveller/businesses/{businessId}/calculate-credits']['post']['responses'][200]['content']['application/json']
type BusinessCreditsBatchCalculateServerRequestPayload = paths['/api/business-traveller/businesses/{businessId}/calculate-credits']['post']['parameters']['body']['payload']

function mapCalculatedBusinessCredit(serverData: NonNullable<BusinessCreditsBatchCalculateServerResponse['result']>): Record<string, App.BusinessTraveller.OfferCredit> {
  return mapObject(serverData, (serverCredit) => {
    if (serverCredit.budgetCompliance === 'under_budget') {
      return {
        budgetCompliance: 'under_budget',
        creditValue: Number(serverCredit.creditValue),
        budgetComparison: Number(serverCredit.budgetComparison),
      }
    }

    return {
      budgetCompliance: serverCredit.budgetCompliance,
      creditValue: 0,
      budgetComparison: Number(serverCredit.budgetComparison),
    }
  })
}

export enum BUSINESS_CREDITS_BATCH_CALCULATION_ERRORS {
  NO_CALCULATION = 'Could not calculate!',
  OTHER = 'Something went wrong!',
}

interface Params {
  businessId: string
}

async function requestPostBatchCalculateBusinessCredits(
  params: Params,
  payload: Omit<BusinessCreditsBatchCalculateServerRequestPayload, 'brand'>,
) {
  const { businessId } = params

  try {
    const response = await requestUtils.post<BusinessCreditsBatchCalculateServerResponse, BusinessCreditsBatchCalculateServerRequestPayload>(
      `/api/business-traveller/businesses/${businessId}/calculate-credits`,
      payload,
      { credentials: 'include' },
    )

    if (!response.result) {
      return Promise.reject(BUSINESS_CREDITS_BATCH_CALCULATION_ERRORS.NO_CALCULATION)
    }

    return mapCalculatedBusinessCredit(response.result)
  } catch {
    return Promise.reject(BUSINESS_CREDITS_BATCH_CALCULATION_ERRORS.OTHER)
  }
}

export default requestPostBatchCalculateBusinessCredits
