import React from 'react'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'

function LuxLoyaltyGenericInfoModal() {
  return <Modal
    title="Earning points & status credits"
    subtitle="As a member of Société, you&rsquo;ll earn points and status credits on every booking."
    secondaryActionText="Find out more"
    secondaryActionTo="/societe"
  >
    <Group gap={16} tabletGap={24} direction="vertical">
      <div>
        <BodyText variant="large" weight="bold">
          Société Points
        </BodyText>
        <BodyText variant="large">
          Earn and redeem points on hotels, tours, flights cruises and more.
        </BodyText>
      </div>
      <div>
        <BodyText variant="large" weight="bold">
          Société Status Credits
        </BodyText>
        <BodyText variant="large">
          Collect status credits on every booking to move up tiers and unlock rewards including room upgrades, airport transfers, lounge passes and more.
        </BodyText>
      </div>
    </Group>
  </Modal>
}

export default LuxLoyaltyGenericInfoModal
