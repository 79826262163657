import { safeDivideAndCeil } from 'lib/maths/mathUtils'
import getValueOffPercent from './getValueOffPercent'

export function divideAndCeilPricing<P extends App.PricingWithValue>(pricing: P, divisor: number, saleUnit?: string): App.PricingWithValue
export function divideAndCeilPricing<P extends App.Pricing>(pricing: P, divisor: number, saleUnit?: string): App.Pricing
export function divideAndCeilPricing<P extends App.Pricing>(pricing: P, divisor: number, saleUnit?: string): App.Pricing {
  const newPricing: App.Pricing = {
    ...pricing,
    price: safeDivideAndCeil(pricing.price, divisor),
    fees: pricing.fees.map(fee => ({
      ...fee,
      amount: safeDivideAndCeil(fee.amount, divisor),
    })),
    saleUnit: saleUnit ?? pricing.saleUnit,
  }

  if (typeof pricing.value === 'number') {
    return {
      ...newPricing,
      value: safeDivideAndCeil(pricing.value, divisor),
      discountPercent: pricing.discountPercent, // This is already spread into newPricing, just putting it here again to keep TS happy...
    }
  }
  return newPricing
}

export function addFlightPrice<P extends App.Pricing>(pricing: P, flightPrice: number): P {
  if (pricing.value) {
    return {
      ...pricing,
      price: pricing.price + flightPrice,
      value: pricing.value + flightPrice,
      discountPercent: getValueOffPercent(pricing.value + flightPrice, pricing.price + flightPrice),
    }
  }
  return {
    ...pricing,
    price: pricing.price + flightPrice,
  }
}

function isValidAndDifferent(memberAmount: number | undefined, defaultAmount: number | undefined) {
  return !!memberAmount && memberAmount !== defaultAmount
}

export function determineMemberPricing<T extends App.Pricing>(
  pricing: T | undefined,
  memberPrice?: number,
  memberValue?: number,
): T | undefined {
  if (!pricing) {
    return undefined
  }
  const resolvedPrice = memberPrice || pricing.price
  const resolvedValue = memberValue || pricing.value
  if (resolvedValue) {
    if (isValidAndDifferent(memberPrice, pricing.price) || isValidAndDifferent(memberValue, pricing.value)) {
      return {
        ...pricing,
        price: resolvedPrice,
        value: resolvedValue,
        discountPercent: getValueOffPercent(resolvedValue, resolvedPrice),
      }
    }
    return undefined
  } else {
    if (isValidAndDifferent(memberPrice, pricing.price)) {
      return {
        ...pricing,
        price: resolvedPrice,
      }
    }
    return undefined
  }
}

export function feesToObject(fees: Array<App.PricingFee>): Partial<Record<App.PricingFee['type'], number>> {
  return fees.reduce<Partial<Record<App.PricingFee['type'], number>>>((feeObj, fee) => {
    feeObj[fee.type] = (feeObj[fee.type] ?? 0) + fee.amount
    return feeObj
  }, {})
}

export function priceWithoutPropertyFees(pricing: App.Pricing): number {
  return pricing.price - (pricing.fees.find(fee => fee.type === 'property')?.amount ?? 0)
}

export function onlyNonZeroFees(fees: Array<App.PricingFee>): Array<App.PricingFee> {
  return fees.filter(f => !!f.amount)
}

export function addPricing(a: App.PricingWithValue, b: App.PricingWithValue): App.PricingWithValue
export function addPricing(a: App.Pricing, b: App.Pricing): App.Pricing
export function addPricing(a: App.Pricing, b: App.Pricing): App.Pricing {
  const feesObj = feesToObject(a.fees.concat(b.fees))

  const newPricing: App.Pricing = {
    price: a.price + b.price,
    fees: Object.entries(feesObj).map(([type, amount]) => ({ type: type as App.PricingFee['type'], amount })),
    saleUnit: a.saleUnit,
  }
  if (a.value && b.value) {
    const value = a.value + b.value
    return {
      ...newPricing,
      value,
      discountPercent: getValueOffPercent(value, newPricing.price),
    }
  }
  return newPricing
}

export function maybeWithValue(pricing: App.Pricing, value: number | null | undefined): App.Pricing {
  if (value) {
    return {
      ...pricing,
      value,
      discountPercent: getValueOffPercent(value, pricing.price),
    }
  }
  return pricing
}
