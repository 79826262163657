import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { getImageUrl } from 'lib/image/imageUtils'

type LogoVariant = 'hero' | 'pageBanner' | 'searchBanner';

interface Props {
  variant: LogoVariant;
  daysRemaining: number;
}

const LogoContainer = styled.div`
  display: grid;
  place-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: ${rem(94)};
  height: ${rem(82)};


  &.pageBanner {
    width: ${rem(55)};
    height: ${rem(48)};
  }

  &.day-0 {
    background-image: url(${getImageUrl('kb03f3210qt34fc78ajs', { width: 200, format: 'webp', quality: 'best', dpr: 2 })});
  }

  &.day-1 {
    background-image: url(${getImageUrl('r1exk8g8r76unftdu5r', { width: 200, format: 'webp', quality: 'best', dpr: 2 })});
  }

  &.day-2 {
    background-image: url(${getImageUrl('hb8ww4t44rg0hp4z30ta', { width: 200, format: 'webp', quality: 'best', dpr: 2 })});
  }

  &.day-3 {
    background-image: url(${getImageUrl('h20y9tsz2kju8qgq2vp', { width: 200, format: 'webp', quality: 'best', dpr: 2 })});
  }

  &.day-4 {
    background-image: url(${getImageUrl('x1o3h8r4565vanl666qk', { width: 200, format: 'webp', quality: 'best', dpr: 2 })});
  }

  &.day-5 {
    background-image: url(${getImageUrl('j7ovfxiuy5y6zh226lj', { width: 200, format: 'webp', quality: 'best', dpr: 2 })});
  }
`

export function MarchTravelFrenzyLogo({ variant, daysRemaining }: Props) {
  return (
    <LogoContainer className={`${variant} day-${daysRemaining}`} />
  )
}
