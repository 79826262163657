import React, { useCallback, useEffect } from 'react'

import { useInView } from 'react-intersection-observer'
import noop from 'lib/function/noop'
import TextButton from 'components/Luxkit/Button/TextButton'
import Typography from 'components/Luxkit/Typography/Typography'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'

const SCROLL_NEAR_THRESHOLD = 500

interface Props extends Omit<React.ComponentProps<typeof TextButton>, 'kind'> {
  onScrollNear?: () => void;
  showLoadingIndicator?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onVisibilityChange?: (isVisible: boolean) => void;
}

function OfferListLoadMoreButton(props: Props) {
  const { children, onScrollNear = noop, showLoadingIndicator = false, onClick, onVisibilityChange, ...buttonProps } = props

  const [buttonRef, isInView] = useInView({
    rootMargin: `${SCROLL_NEAR_THRESHOLD}px`,
  })

  useEffect(() => {
    if (isInView) {
      onScrollNear()
    }
    onVisibilityChange?.(isInView)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView])

  const handleButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur()
    if (onClick) onClick(event)
  }, [onClick])

  return (
    <VerticalSpacer gap={16} ref={buttonRef}>
      {showLoadingIndicator && (
        <LoadingIndicator inline />
      )}
      {!showLoadingIndicator && <Typography align="center">
        <TextButton kind="primary" onClick={handleButtonClick} {...buttonProps}>
          {children}
        </TextButton>
      </Typography>}
    </VerticalSpacer>
  )
}

export default OfferListLoadMoreButton
