import React, { ComponentProps, ReactNode, forwardRef } from 'react'
import cn from 'clsx'
import Clickable from 'components/Common/Clickable'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import BodyText from './Typography/BodyText'
import Caption from './Typography/Caption'
import { SVG_ICON_SIZE_CSS_VAR, StyledSvgIcon } from './SvgIcon'
import Group from 'components/utils/Group'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import Tooltip from './Tooltip'
import SolidInfoCircleIcon from './Icons/solid/SolidInfoCircleIcon'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const HiddenBold = styled.b`
  visibility: hidden;
`

const ZStackerCSS = css`
  display: grid;
  grid-template: 1fr / 1fr;

  > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const ToggleOptionTitle = styled(BodyText)`
  ${ZStackerCSS}
`

const ToggleOptionSubtitle = styled(Caption)`
  ${ZStackerCSS}
`

/**
 * Not to be used separately!
 */
export const BaseToggleOption = styled(Clickable)`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  gap: ${rem(8)};
  align-items: center;
  justify-content: start;
  border-radius: ${props => props.theme.borderRadius.M};
  padding: ${rem(8)} ${rem(12)};
  min-height: ${rem(40)};
  min-width: ${rem(95)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  box-shadow: inset 0 0 0 ${rem(1)} ${props => props.theme.palette.neutral.default.six};
  transition: 200ms background-color, 200ms box-shadow;

  &.is-selected {
    box-shadow: inset 0 0 0 ${rem(2)} ${props => props.theme.palette.brand.primary.normal};
  }

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover {
        box-shadow: inset 0 0 0 ${rem(1)} ${props => props.theme.palette.brand.primary.normal};
      }
    }
    &:focus {
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid ${props => props.theme.palette.neutral.default.five});
      outline-offset: 2px;
    }
  }

  > ${StyledSvgIcon} {
    ${SVG_ICON_SIZE_CSS_VAR}: ${rem(24)};
  }
`

const ToggleOptionContentGroup = styled(Group)`
  flex-grow: 1;
`

const TooltipBlock = styled(Tooltip)`
  display: flex;
`

interface Props extends Pick<ComponentProps<typeof Clickable>, 'onClick' | 'to' | 'disabled'> {
  /**
   * Puts the toggle button in the selected state.
   *
   * If the `endIcon` is set to radio, it'll control that too.
   *  */
  isSelected?: boolean
  /**
   * Short succinct words to describe what to expect when selecting.
   *  */
  title: ReactNode
  /**
   * Sets the typography format of the title.
   *  */
  titleFormat?: ComponentProps<typeof BodyText>['format']
  /**
   * When provided, an info icon as the tooltip trigger will be displayed next to the title
   */
  tooltipDescription?: ReactNode
  /**
   * Provide secondary details.
   *
   */
  subtitle?: ReactNode
  /**
   * Sets the typography format of the subtitle.
   * */
  subtitleFormat?: ComponentProps<typeof Caption>['format']
  /**
   * Provide additional illustration alongside Title & Subtitle content.
   *
   * **There's no need to provide the icon size.**
   *  */
  endIcon?: ReactNode
  className?: string
}

const ToggleOption = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    isSelected,
    title,
    titleFormat,
    tooltipDescription,
    subtitle,
    subtitleFormat,
    endIcon,
    className,
    disabled,
    ...rest
  } = props

  return <BaseToggleOption
    {...rest}
    ref={ref}
    disabled={disabled}
    className={cn(
      className,
      {
        'is-selected': isSelected,
      },
    )}
  >
    <ToggleOptionContentGroup direction="vertical" gap={2} horizontalAlign="start">
      <Group direction="horizontal" gap={2} verticalAlign="center">
        <ToggleOptionTitle
          variant="medium"
          colour="neutral-one"
          weight={isSelected ? 'bold' : 'normal'}
          align="center"
          format={titleFormat}
        >
          <span>{title}</span>
          <HiddenBold aria-hidden="true">{title}</HiddenBold>
        </ToggleOptionTitle>
        {!!tooltipDescription && <TooltipBlock description={tooltipDescription} placement="top">
          <SolidInfoCircleIcon size="XS" />
        </TooltipBlock>}
      </Group>
      {!!subtitle && <ToggleOptionSubtitle
        variant="medium"
        colour="neutral-three"
        weight={isSelected ? 'bold' : 'normal'}
        align="center"
        format={subtitleFormat}
        aria-hidden="true"
      >
        <span>{subtitle}</span>
        <HiddenBold aria-hidden="true">{subtitle}</HiddenBold>
      </ToggleOptionSubtitle>}
    </ToggleOptionContentGroup>
    {endIcon}
  </BaseToggleOption>
})

export default ToggleOption
