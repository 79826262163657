import React, { ComponentProps } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import _Button from './BaseButton'

const StyledBaseButton = styled(_Button)`
  width: var(--button-size);

  > svg:first-of-type {
    flex-shrink: 0;
    height: var(--button-icon-size, auto);
    width: var(--button-icon-size, auto);
  }

  &.shape-square {
    --button-border-radius: ${props => props.theme.borderRadius.S};
  }

  &.shape-circle {
    --button-border-radius: ${props => props.theme.borderRadius.round};
  }

  &.size-small {
    --button-size: ${rem(28)};
    --button-icon-size: ${rem(20)};
    --button-outdent: ${rem(4)};
  }

  &.size-medium {
    --button-size: ${rem(40)};
    --button-icon-size: ${rem(20)};
    --button-outdent: ${rem(8)};
  }

  &.size-large {
    --button-size: ${rem(48)};
    --button-icon-size: ${rem(24)};
    --button-outdent: ${rem(12)};
  }

  &.outdented {
    transform: translate(calc(var(--button-outdent) * var(--h-outdent-multiplier, 0)), calc(var(--button-outdent) * var(--v-outdent-multiplier, 0)));
  }
`

function determineOutdentMultiplier(outdent: 'start' | 'end' | undefined): number | undefined {
  switch (outdent) {
    case 'start':
      return -1
    case 'end':
      return 1
    default:
      return undefined
  }
}

interface Props extends ComponentProps<typeof _Button> {
  /** Place the icon this button should show here */
  children: React.ReactNode
  /**
   * Horizontal outdent, or the inverse of indent, negates the inline 'padding' around the button in the chosen direction
   * so its content can visually align with the 'edge'. Mostly used for tertiary buttons that have no border/background.
   * */
  horizontalOutdent?: 'start' | 'end'
  /**
   * Vertical outdent, or the inverse of indent, negates the block 'padding' around the button in the chosen direction
   * so its content can visually align with the 'edge'. Mostly used for tertiary buttons that have no border/background.
   * */
  verticalOutdent?: 'start' | 'end'
  /**
   * The general shape of the button, square will have a minor border radius, circle will be fully round
   *
   * @default square
   *  */
  shape?: 'square' | 'circle'
  /**
   * Determines the height/width of the button as well as the size of icons if they are provided
   *
   * @default medium
   *  */
  size?: 'small' | 'medium' | 'large'
}

const IconButton = React.forwardRef<HTMLButtonElement, Props>(function IconButton(
  {
    className,
    horizontalOutdent,
    verticalOutdent,
    shape = 'square',
    size = 'medium',
    style,
    ...rest
  },
  ref,
) {
  return <StyledBaseButton
    {...rest}
    ref={ref}
    className={cn(
      className,
      `size-${size}`,
      `shape-${shape}`,
      {
        outdented: horizontalOutdent || verticalOutdent,
      },
    )}
    style={{
      ...style,
      '--h-outdent-multiplier': determineOutdentMultiplier(horizontalOutdent),
      '--v-outdent-multiplier': determineOutdentMultiplier(verticalOutdent),
    }}
  />
})

export default IconButton
