import React from 'react'
import styled, { css } from 'styled-components'
import Typography, { TYPOGRAPHY_ICON_SIZE_CSS_VAR, TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from './Typography'
import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'

export const Subtitle1Style = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 22px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(24)};
  font-size: 18px;

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 24px;
    font-size: 20px;
  }
`

export const Subtitle2Style = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 20px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(20)};
  font-size: 16px;

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 22px;
    font-size: 18px;
  }
`

export const Subtitle3Style = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 18px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(20)};
  font-size: 14px;

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 20px;
    font-size: 16px;
  }
`

/*
  Font sizes are express in px as they are not to be affected by the
  20% REM font decrease at mobile sizes. These font sizes are absolute sizes.
*/
const Element = styled(Typography)`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 1.5;
  font-family: ${props => props.theme.font.secondary.family};

  /* reset font-style */
  &, &:is(i, em), i, em {
    font-style: normal;
  }

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondary.weight.regular};
  }

  &.colour-neutral-one {
    color: ${props => props.theme.palette.neutral.default.one};
  }

  &.colour-neutral-eight {
    color: ${props => props.theme.palette.neutral.default.eight};
  }

  &.colour-exclusive-contrast {
    color: ${props => props.theme.palette.product.exclusive.contrast};
  }

  &.variant-subtitle1 {
    ${Subtitle1Style}
  }

  &.variant-subtitle2 {
    ${Subtitle2Style}
  }

  &.variant-subtitle3 {
    ${Subtitle3Style}
  }
`

interface Props extends Omit<React.ComponentProps<typeof Typography>, 'forwardedAs'> {
  variant: 'subtitle1' | 'subtitle2' | 'subtitle3';
  colour?: 'exclusive-contrast' | 'neutral-eight' | 'neutral-one';
}

function Subtitle(props: Props) {
  const {
    variant,
    as,
    className,
    colour = 'neutral-one',
    ...rest
  } = props

  return <Element
    {...rest}
    forwardedAs={as}
    className={cn(className, `variant-${variant}`, `colour-${colour}`)}
  />
}

export default Subtitle
