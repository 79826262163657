export function generateCheckoutItemViewOfferCreditKey(
  itemView:
    App.Checkout.LEAccommodationItemView |
    App.Checkout.CruiseAccommodationItemView |
    App.Checkout.BedbankAccommodationItemView |
    App.Checkout.TourV2AccommodationItemView |
    App.Checkout.FlightItemView,
) {
  // There is no consistency between the or identifying properties between views types for accommodation and flights.
  // Need to check if properties to determine if flights or accommodation.
  // Also adding cost to force a refresh of credit calculation on price change.
  let itemViewUniqueKey: string = ''
  let cost: number | undefined

  if ('uniqueKey' in itemView) {
    itemViewUniqueKey = itemView.uniqueKey
    cost = itemView.totals.price
  }

  if ('journeyId' in itemView) {
    itemViewUniqueKey = itemView.journeyId ?? ''
    cost = itemView.item.totalFare
  }

  const params = new URLSearchParams({
    itemViewUniqueKey,
    itemViewItemId: itemView.item.itemId,
    cost: String(cost),
  })

  return params.toString()
}
