import Group from '../../utils/Group'
import OfferSortControl from 'components/Common/OfferSortControl/OfferSortControl'
import TextButton from 'components/Luxkit/Button/TextButton'
import VerticalSpacer from '../../Common/Spacing/VerticalSpacer'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { OfferListSortOption, searchSortByTypes } from 'constants/offerListFilters'
import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import AssistChip from 'components/Luxkit/Chips/AssistChip'
import LineFilterAltIcon from 'components/Luxkit/Icons/line/LineFilterAltIcon'
import OfferListFilterSidebar from 'components/OfferList/OfferListFilter/OfferListFilterSidebar'
import { connect } from 'react-redux'
import MobileOfferListFilters from 'components/OfferList/OfferListFilter/MobileOfferListFilters'
import noop from 'lib/function/noop'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import config from 'constants/config'
import { searchSwitchViewClick } from 'analytics/eventDefinitions'
import { addGTMEvent } from 'api/googleTagManager'
import { pluralizeToString } from 'lib/string/pluralize'
import getOfferListKey from 'lib/offer/offerListKey'
import { EnabledFilters } from 'components/Search/type'
import HotelBroadSearchLocation from './HotelSearchBroadSearchLocation'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import useGlobalSearchURLHashFilter from 'hooks/GlobalSearch/useGlobalSearchURLHashFilter'
import { DestinationLandingPageType } from '../DestinationLandingPage/DestinationLandingPageUtils'
import BusinessTravellerHotelBudgetMessageBanner from 'businessTraveller/components/search/BusinessTravellerHotelBudgetMessageBanner'
import { selectLoggedIn } from '../../../selectors/accountSelectors'
import HotelSearchAddDestinationToggle from './HotelAddDestinationToggle/HotelSearchAddDestinationToggle'
import { useScreenSizeOnly } from '../../../hooks/useScreenSize'
import DestinationProductPageSortByCarousel
  from 'components/Pages/DestinationProductPage/DestinationProductPageSortByCarousel'
import { mediaQueryUp } from 'components/utils/breakpoint'
import IconButton from 'components/Luxkit/Button/IconButton'
import HotelExposedFiltersHeader
  from 'components/Pages/HotelSearchPage/HotelExposedFiltersHeader/HotelExposedFiltersHeader'
import cn from 'clsx'
import { ANYWHERE_PLACE_ID } from '../../../constants/search'

const Container = styled(CSSBreakpoint)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const MobileContainer = styled(CSSBreakpoint)`
  width: 100%;

  &.withGap {
    display: flex;
    flex-direction: column;
    gap: ${rem(8)};
    padding-top: ${rem(12)};
  }
`

const FilterAndCountContainer = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: ${rem(16)};
  }
`

const ToggleContainer = styled(Group)`
  padding-left: 0;

  ${mediaQueryUp.tablet} {
    padding-left: ${rem(20)};
  }

  ${mediaQueryUp.largeDesktop} {
    padding-left: 0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  > * + * {
    margin-left: ${rem(8)};
  }
`

function handleClick() {
  addGTMEvent(searchSwitchViewClick())
}
interface MappedStateProps {
  availableFilters?: App.OfferListFilterOptions;
  isLoggedIn: boolean;
  userId?: string;
}

interface Props {
  mainListTotal: number;
  sortOptions: Array<OfferListSortOption>;
  mapViewUrl?: string;
  isFlexible?: boolean
  shouldShowFilters?: boolean
  filters: App.OfferListFilters;
  windowSearch: string;
  showOfferCount?: boolean;
  showHolidayTypes?: boolean;
  searchType?: string
  enabledFilters?: EnabledFilters;
  fetching?: boolean;
  countTextOverride?: string;
  specificPlaces?: Array<App.SpecificPlace>;
  destinationName?: string;
  isDestinationLandingPage?: boolean
  urlLocationForDestinationLandingPage?: string
  pageTypeForDestinationLandingPage?: DestinationLandingPageType
  holidayTypeForDestinationLandingPage?: string
  showHeaderToggles?: boolean
  showMobileFilters?: boolean
}

function OfferCountAndSort(props: Props & MappedStateProps) {
  const {
    mainListTotal,
    sortOptions,
    mapViewUrl,
    isFlexible,
    shouldShowFilters = false,
    showOfferCount = true,
    filters,
    windowSearch,
    searchType,
    enabledFilters,
    fetching,
    countTextOverride,
    specificPlaces,
    destinationName,
    isDestinationLandingPage,
    pageTypeForDestinationLandingPage,
    holidayTypeForDestinationLandingPage,
    isLoggedIn,
    userId,
    showHeaderToggles = false,
    showMobileFilters = false,
  } = props
  const propertySearch = searchType === 'property'
  const { isFilterPanelOpen, toggleFilterPanel } = useGlobalSearchURLHashFilter()

  const showSlimMobileSearch = useSlimMobileSearch()
  const isMobile = useScreenSizeOnly('mobile')

  const countText = (() => {
    if (countTextOverride) {
      return countTextOverride
    }
    if (propertySearch) {
      return ''
    }
    if (isMobile) {
      return pluralizeToString('offer', mainListTotal)
    }
    return isFlexible ? `Showing ${pluralizeToString('offer', mainListTotal)} with cheapest availabilities for your dates` : `Showing ${pluralizeToString('offer', mainListTotal)}`
  })()

  const isLuxuryEscapes = config.BRAND === 'luxuryescapes'

  return (<>
    <div>
      {!showSlimMobileSearch && <MobileContainer max="mobile">
        <MobileOfferListFilters
          mapViewPath={mapViewUrl}
          onSortAndFilterClick={toggleFilterPanel}
          onViewSwitch={noop}
          hasSortOptions
          showFilterIcon={false}
        />
      </MobileContainer>}
      <VerticalSpacer gap={16}>
        {!!specificPlaces?.length && !!destinationName &&
          <HotelBroadSearchLocation
            specificPlaces={specificPlaces}
            destinationName={destinationName}
            isDestinationLandingPage={isDestinationLandingPage}
            pageTypeForDestinationLandingPage={pageTypeForDestinationLandingPage}
            holidayTypeForDestinationLandingPage={holidayTypeForDestinationLandingPage}
          />}
        {!showHeaderToggles && !showMobileFilters && <MobileContainer max="mobile">
          <Group direction="horizontal" horizontalAlign="space-between">
            {showOfferCount && !fetching && <BodyText variant="medium">{countText}</BodyText>}
            {isLuxuryEscapes && isLoggedIn && filters.destinationId && filters.destinationId !== ANYWHERE_PLACE_ID && userId && <HotelSearchAddDestinationToggle userId={userId} placeId={filters.destinationId} />}
          </Group>
        </MobileContainer>}
        {!showHeaderToggles && showMobileFilters &&
          <CSSBreakpoint max="mobile">
            <MobileContainer className={cn({ withGap: showMobileFilters })}>
              <HotelExposedFiltersHeader filters={filters} showSort sortOptions={searchSortByTypes} />
              <Group direction="horizontal" verticalAlign="center" horizontalAlign="space-between">
                {showOfferCount && !fetching && <BodyText variant="medium">{countText}</BodyText>}
                {mapViewUrl &&
                  <IconButton kind="tertiary" to={mapViewUrl}>
                    <LineMapIcon />
                    <BodyText variant="small">Map</BodyText>
                  </IconButton>
            }
              </Group>
            </MobileContainer>
          </CSSBreakpoint>}
        {!showHeaderToggles &&
          <Container min="tablet">
            <FilterAndCountContainer>
              {shouldShowFilters && <AssistChip size="medium" variant="filled" startIcon={<LineFilterAltIcon />} onClick={toggleFilterPanel}>
                Filters
              </AssistChip>}
              {showOfferCount && !fetching && <BodyText variant="large">{countText}</BodyText>}
            </FilterAndCountContainer>
            <ButtonContainer>
              {!showHeaderToggles && <OfferSortControl sortOptions={sortOptions} />}
              {!config.businessTraveller.isEnabled && mapViewUrl && !showHeaderToggles &&
                <TextButton
                  kind="secondary"
                  variant="dark"
                  to={mapViewUrl}
                  startIcon={<LineMapIcon />}
                  onClick={handleClick}
                >
                  Map view
                </TextButton>
              }
            </ButtonContainer>
          </Container>
        }
        {showHeaderToggles &&
          <CSSBreakpoint min="tablet">
            <ToggleContainer direction="vertical" gap={8}>
              <DestinationProductPageSortByCarousel filters={filters} search={windowSearch}/>
              {showOfferCount && !fetching && <BodyText variant="medium">{countText}</BodyText>}
            </ToggleContainer>
          </CSSBreakpoint>
        }
        {showHeaderToggles &&
          <CSSBreakpoint max="mobile">
            <ToggleContainer direction="vertical" gap={8}>
              <DestinationProductPageSortByCarousel filters={filters} search={windowSearch}/>
              <Group direction="horizontal" verticalAlign="center" horizontalAlign="space-between">
                {showOfferCount && !fetching && <BodyText variant="medium">{countText}</BodyText>}
                {mapViewUrl && showMobileFilters &&
                  <IconButton kind="tertiary" to={mapViewUrl}>
                    <LineMapIcon />
                    <BodyText variant="small">Map</BodyText>
                  </IconButton>
                }
              </Group>
            </ToggleContainer>
          </CSSBreakpoint>
        }
        <CSSBreakpoint min="tablet">
          {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerHotelBudgetMessageBanner
            windowSearch={windowSearch}
            filters={filters}
          />}
        </CSSBreakpoint>
      </VerticalSpacer>
    </div>
    {!!enabledFilters && <OfferListFilterSidebar
      open={isFilterPanelOpen}
      onClose={toggleFilterPanel}
      filters={filters}
      sortOptions={sortOptions}
      search={windowSearch}
      fetching={!!fetching}
      enabledFilters={enabledFilters}
    />}
  </>)
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState, ownProps) => {
  const offerListKey = getOfferListKey(ownProps.filters)

  return {
    availableFilters: appState.offer.offerListFilterOptions[offerListKey],
    isLoggedIn: selectLoggedIn(appState),
    userId: appState.auth.account.memberId,
  }
})(OfferCountAndSort)
