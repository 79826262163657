import moment from 'moment'
import qs from 'qs'
import { CalendarV2 } from '@luxuryescapes/contract-svc-calendar'
import { connectionBuildCancellationPolicies } from '@luxuryescapes/lib-refunds'

import request from 'api/requestUtils'
import { arrayToMap, arrayToObject, countBy, groupBy, isNonNullable, min, sortBy, splitByWeight, sum, unique, uniqueBy } from 'lib/array/arrayUtils'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { isBundleOffer } from 'lib/offer/offerTypes'
import { OFFER_TYPE_VILLA } from 'constants/offer'
import { addFlightPrice, determineMemberPricing, maybeWithValue, onlyNonZeroFees } from 'lib/offer/pricing'
import { getChannelMarkupValue } from 'lib/channelMarkup/channelMarkup'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { objectEntries } from 'lib/object/objectUtils'

const FLIGHT_MAX_DAYS_IN_FUTURE = 310
const maxFlightDate = moment().add(FLIGHT_MAX_DAYS_IN_FUTURE, 'days').toDate()

/**
 * Decodes the calendar data which is stored for effeciency in an array of values where the order of the values
 * is mapped to the keys in the map
 * @example values: [1,2,3], map: ["foo", "bar", "baz"] outputs { foo: 1, bar: 2, baz: 3 }
 */
function decodeCalendarDayRate(value: Array<any>, maps: { [key: number]: Array<CalendarV2.Maps> }): CalendarV2.RateMaps {
  return maps[value.length]?.reduce<CalendarV2.RateMaps>((acc, key, index) => {
    acc[key as keyof CalendarV2.RateMaps] = value[index]
    return acc
  }, {} as CalendarV2.RateMaps)
}

interface RequestCalendarParams {
  offer: App.Offer | App.OfferSummary,
  flightOrigin?: string;
  bundledOfferIds?: Array<string>;
  region: string;
  durations: Array<number>;
  minDate?: string;
  packages: Array<App.Package>;
  occupants: Array<App.Occupants>;
  enquiryType?: 'customer' | 'admin';
  channelMarkup?: App.ChannelMarkup;
}

/**
 * Rate map result with the date added on (it was previously the key)
 */
type RateCalendarRequestResultDate = CalendarV2.RateMaps & {
  /**
   * ISO Format for the date, e.g. 1/1/2025
   */
  date: string;
}

interface RateCalendarRequestResult {
  uniqueKey: string;
  packageId: string;
  roomRateId: string;
  duration: number;
  dates: Array<RateCalendarRequestResultDate>;
  flightCacheActive: boolean;
  flightsNotAvailable: boolean;
}

/**
 * Makes the actual request to calendar and converts the space-effecient format from svc-calendar
 * into more standard objects.
 *
 * svc-calendar returns data in the form of arrays to reduce repeated key/value pairs of many many objects
 * e.g. values: [1,2,3], map: ["foo", "bar", "baz"] represents the object { foo: 1, bar: 2, baz: 3 }
 */
function requestCalendar(params: RequestCalendarParams): Promise<Array<RateCalendarRequestResult>> {
  const query = qs.stringify({
    flightOrigin: params.flightOrigin,
    region: params.region,
    nights: params.durations,
    occupancy: params.occupants.map(occ => {
      // if hide child prices is enabled, we need to remove children and infants from the occupancy only for rates (ARI based) request
      // live availability will still show children and infants hence temporary removal
      if (params.offer?.property?.hideChildPrices && (occ.children !== 0 || occ.infants !== 0)) {
        const temp = { ...occ }
        temp.children = 0
        temp.infants = 0
        temp.childrenAge = []
        return generateOccupancyStringByRoom(temp)
      }
      return generateOccupancyStringByRoom(occ)
    }),
    minDate: params.minDate,
    packageIds: params.packages.map((pkg) => pkg.id).join(','),
    enquiryType: params.enquiryType,
    bundledOfferIds: params.bundledOfferIds ? params.bundledOfferIds.join(',') : undefined,
  })

  return request.get<App.ApiResponse<Array<CalendarV2.Package>>>(
    `/api/v2/calendar/${params.offer.id}/rates?${query}`,
    { headers: { compression: 'gzip' } },
  ).then(response => {
    return response.result.flatMap(calendar => {
      // A map of number of fields -> list of fields
      // The 'values' will have an equiv size to one of the number of fields
      const rateFieldMapping = arrayToObject(calendar.maps, map => map.length)

      return calendar.rates.map((roomRateId, index): RateCalendarRequestResult => {
        return {
          uniqueKey: getPackageUniqueKey(calendar.packageId, calendar.duration, roomRateId),
          duration: calendar.duration,
          packageId: calendar.packageId,
          roomRateId,
          dates: objectEntries(calendar.dates).map(([date, data]) => ({
            ...decodeCalendarDayRate(data[index], rateFieldMapping),
            date: date.toString(),
          })),
          flightCacheActive: calendar.flightCacheActive,
          flightsNotAvailable: calendar.flightsNotAvailable,
        }
      })
    })
  })
}

export function mapCalendar(
  serverCalendar: RateCalendarRequestResult,
  offer: App.HotelOffer | App.HotelOfferSummary,
  pkg: App.HotelPackage,
  options: {
    channelMarkup?: App.ChannelMarkup,
    ugpradeServerCalendar?: RateCalendarRequestResult,
  } = {},
): App.Calendar {
  const { channelMarkup, ugpradeServerCalendar } = options
  const { dates, uniqueKey, flightCacheActive, flightsNotAvailable, roomRateId, duration } = serverCalendar
  const markup = getChannelMarkupValue(offer.id, offer.type, channelMarkup)

  // current issue with server where returns an empty prices array if no available dates
  // so we handle this by returning an empty calendar
  if (serverCalendar.dates.length === 0) {
    return {
      uniqueKey: serverCalendar.uniqueKey,
      months: [],
      cheapestDay: undefined,
      cheapestDayWithFlights: undefined,
      flightCacheActive,
      flightsNotAvailable,
    }
  }

  const upgradeCalendarByDate = arrayToMap(ugpradeServerCalendar?.dates ?? [], date => date.date)
  const days = dates.map<App.CalendarDay>((dayRate): App.CalendarDay => {
    const currentDay = moment(dayRate.date)
    const checkOut = moment(currentDay).add(duration, 'days')
    const availableRooms = dayRate.availableRooms
    const blackout = 'blackout' in dayRate ? dayRate.blackout : false
    const prices = 'prices' in dayRate ? dayRate.prices.map(price => price * markup) : []
    const price = sum(prices)
    const memberPrices = 'luxPlusPrices' in dayRate ? dayRate.luxPlusPrices : []
    const memberPrice = memberPrices.length > 0 ? sum(memberPrices) : 0
    const hotelValue = 'value' in dayRate ? dayRate.value : 0
    const hotelMemberValue = 'luxPlusBaseValue' in dayRate ? dayRate.luxPlusBaseValue : 0
    const flightPrice = 'flightPrice' in dayRate ? dayRate.flightPrice : null
    const taxesAndFees = 'taxesAndFees' in dayRate ? dayRate.taxesAndFees * markup : 0
    const propertyFees = 'propertyFees' in dayRate ? dayRate.propertyFees : 0
    const surcharge = 'surcharge' in dayRate ? dayRate.surcharge : 0
    const extraGuestSurcharge = 'extraGuestSurcharge' in dayRate ? dayRate.extraGuestSurcharge : 0
    const dealId = 'dealId' in dayRate ? dayRate.dealId : null
    const hasTactical = 'hasTactical' in dayRate ? dayRate.hasTactical : false
    const hasFlightDeal = 'annotations' in dayRate ? dayRate.annotations.includes('FlightDeal') : false

    let hotelAvailable = !!price
    let hotelMemberAvailable = !!memberPrice

    if (offer.bundledWithFlightsOnly) {
      // bundled with flight deals *must* have a flight price if the cache is active
      // otherwise they are considered sold out (because no flight price = no flights)
      hotelAvailable = hotelAvailable && (!flightCacheActive || (flightCacheActive && !!flightPrice))
      hotelMemberAvailable = hotelMemberAvailable && (!flightCacheActive || (flightCacheActive && !!flightPrice))
    }

    const canRequestDates = !hotelAvailable && !blackout &&
          !!pkg.allowDatesRequest && checkOut.isBefore(moment(offer.bookByDate))

    const hotelBasePrice = !hotelAvailable ? 0 : price
    const hotelMemberBasePrice = !hotelMemberAvailable ? 0 : memberPrice

    const hotelTotal = hotelBasePrice + surcharge + extraGuestSurcharge + propertyFees
    const hotelMemberTotal = hotelMemberBasePrice ? hotelMemberBasePrice + surcharge + extraGuestSurcharge + propertyFees : 0

    const packagedTotal = hotelTotal + (flightPrice ?? 0)
    const packagedMemberTotal = hotelMemberTotal ? hotelMemberTotal + (flightPrice ?? 0) : 0

    const pricing: App.Pricing = maybeWithValue({
      price: hotelTotal,
      fees: onlyNonZeroFees([
        { type: 'property', amount: propertyFees },
        { type: 'taxesAndFees', amount: taxesAndFees },
        { type: 'surcharge', amount: surcharge },
        { type: 'extraGuestSurcharge', amount: extraGuestSurcharge },
      ]),
      saleUnit: offer.saleUnit,
    }, hotelValue)

    const memberPricing = determineMemberPricing(pricing, hotelMemberTotal, hotelMemberValue)
    const pricingWithFlights = flightPrice ? addFlightPrice(pricing, flightPrice) : undefined
    const memberPricingWithFlights = (memberPricing && flightPrice) ? addFlightPrice(memberPricing, flightPrice) : undefined

    let hasLoyaltyUpgrade = false
    if (pkg.luxLoyalty.targetUpgradePackageUniqueKey) {
      const upgradeDate = upgradeCalendarByDate.get(dayRate.date)
      if (upgradeDate) {
        // has a loyalty upgrade if the same date in the upgrade package is available (has prices)
        hasLoyaltyUpgrade = 'prices' in upgradeDate && sum(prices) !== 0
      }
    }

    return {
      uniqueKey,
      day: currentDay.date(),
      month: currentDay.format('MMMM'),
      year: currentDay.year().toString(),
      monthKey: `${currentDay.month()}-${currentDay.year().toString()}`,
      checkIn: dayRate.date,
      checkOut: checkOut.format('YYYY-MM-DD'),
      canRequestDates,
      taxesAndFees,
      propertyFees,
      roomPrices: prices,
      hotelPrice: hotelBasePrice,
      hotelValue,
      hotelMemberValue,
      hotelTotal,
      packagedTotal,
      extraGuestSurcharge,
      flightPrice: (flightPrice ?? 0),
      surcharge,
      hotelAvailable,
      flightCacheActive,
      roomRateId,
      roomId: pkg.roomType.id,
      flightsAvailable: checkOut.isBefore(maxFlightDate),
      availableRooms,
      dealId,
      hasTactical,
      memberPrice,
      packagedMemberTotal,
      hotelMemberTotal,
      hotelMemberPrice: hotelMemberBasePrice,
      roomMemberPrices: memberPrices,
      pricing,
      memberPricing,
      pricingWithFlights,
      memberPricingWithFlights,
      hasFlightDeal,
      cheapest: false,
      hasLoyaltyUpgrade,
    }
  })

  const months = groupBy(days, day => day.monthKey).entries().map(([monthKey, days]): App.CalendarMonth => {
    return {
      key: monthKey,
      month: days[0].month,
      year: days[0].year,
      monthIndex: new Date(days[0].checkIn).getMonth(),
      days: sortBy(days, day => day.day, 'asc'),
      cheapestDay: min(days.filter(d => d.hotelAvailable), d => d.hotelTotal),
      cheapestDayWithFlights: min(days.filter(d => d.flightsAvailable && d.hotelAvailable), d => d.packagedTotal),
      hotelAvailable: days.some(d => d.hotelAvailable),
      canRequestDates: days.some(d => d.canRequestDates),
      hasFlightDeal: days.some(d => d.hasFlightDeal),
      hasLoyaltyUpgrade: days.some(d => d.hasLoyaltyUpgrade),
      flightCacheActive,
    }
  }).toArray()

  return {
    uniqueKey,
    months,
    cheapestDay: min(months.map(m => m.cheapestDay).filter(isNonNullable), d => d.hotelTotal),
    cheapestDayWithFlights: min(months.map(m => m.cheapestDayWithFlights).filter(isNonNullable), d => d.packagedTotal),
    flightCacheActive,
    flightsNotAvailable,
  }
}

export interface CalendarParams {
  flightOrigin?: string;
  flightProvider?: string;
  minDate?: string;
  packages: Array<App.HotelPackage>;
  occupants: Array<App.Occupants>;
  enquiryType?: 'customer' | 'admin'
}

export async function getCalendars(
  offer: App.HotelOffer | App.HotelOfferSummary,
  region: string,
  params: CalendarParams = { occupants: [], packages: [], enquiryType: 'customer' },
  channelMarkup?: App.ChannelMarkup,
): Promise<Array<App.Calendar>> {
  const packagesByUniqueKey = arrayToMap(offer.packages, pkg => pkg.uniqueKey)
  if (isBundleOffer(offer)) {
    const bundledOffer = arrayToObject(params.packages, pkg => pkg.offerId, pkg => pkg.duration)

    return requestCalendar({
      offer,
      flightOrigin: params.flightOrigin,
      region,
      durations: Object.values(bundledOffer),
      minDate: params.minDate,
      packages: params.packages,
      occupants: params.occupants,
      enquiryType: params.enquiryType,
      bundledOfferIds: Object.keys(bundledOffer),
      channelMarkup,
    }).then((result) => {
      return result.map(serverCalendar => mapCalendar(
        serverCalendar,
        offer,
        packagesByUniqueKey.get(serverCalendar.uniqueKey)!,
        { channelMarkup },
      ))
    })
  } else {
    // first create groups for all packages per duration
    const queryGroups = groupBy(params.packages, pkg => pkg.duration)

    const allCalendars = await Promise.all([...queryGroups.entries()].flatMap(async([duration, pkgGroup]) => {
      // we also need to retrieve all upgrade package calendars so we can see if there's availability in them
      const roomUpgradePackages = pkgGroup.map(pkg => packagesByUniqueKey.get(pkg.luxLoyalty.targetUpgradePackageUniqueKey ?? '')).filter(isNonNullable)
      const packagesToFetch = unique([...roomUpgradePackages, ...pkgGroup])
      // next, process the duration groups into smaller parts to not overload svc-calendar
      const uniquePackages = uniqueBy(packagesToFetch, pkg => pkg.id)
      const optionCountPerPackage = countBy(packagesToFetch, pkg => pkg.id)
      // The number of options (rates) dictates how expensive the calendar fetch will be and how long it will take
      // But we can only fetch per-package at a time. So lets try batch together at max 20 option at a time
      const chunks = splitByWeight(uniquePackages, {
        limit: 20,
        weightFunc: pkg => optionCountPerPackage.get(pkg.id) ?? 1,
        overflowBehaviour: 'next-chunk',
      })

      return await Promise.all(chunks.flatMap((chunk) => {
        return requestCalendar({
          offer,
          flightOrigin: params.flightOrigin,
          region,
          durations: [duration],
          minDate: params.minDate,
          packages: chunk,
          occupants: params.occupants,
          enquiryType: params.enquiryType,
          channelMarkup,
        })
      })).then(results => results.flat())
    })).then(result => result.flat())

    // we base some data (loyalty upgrades) off of other calendars
    // so we have to have *all* calendars first before mapping them
    const calendarsByKey = arrayToMap(allCalendars, calendar => calendar.uniqueKey)
    return allCalendars.map(serverCalendar => {
      const pkg = packagesByUniqueKey.get(serverCalendar.uniqueKey)!
      const upgradePkgKey = pkg.luxLoyalty.targetUpgradePackageUniqueKey ?? ''
      return mapCalendar(
        serverCalendar,
        offer,
        pkg,
        {
          channelMarkup,
          ugpradeServerCalendar: calendarsByKey.get(upgradePkgKey),
        },
      )
    })
  }
}

interface AvailablePriceParams {
  hideExcludesFlights?: boolean;
  timezone: string;
  regionCode: string;
  currencyCode: string;
  offerType?: App.OfferType;
  saleUnit?: string;
}

function availablePriceMap(serverOfferBestPrice: CalendarV2.Availability, options: AvailablePriceParams, channelMarkup?: App.ChannelMarkup): App.OfferAvailableRate {
  const markup = getChannelMarkupValue(serverOfferBestPrice.offerId, options.offerType, channelMarkup)
  const taxesAndFees = (serverOfferBestPrice.taxesAndFees ?? 0) * markup
  const propertyFees = serverOfferBestPrice.propertyFees ?? 0
  const prices = serverOfferBestPrice.prices.map(price => price * markup)
  const basePrice = sum(serverOfferBestPrice.prices.map(price => price * markup))
  const memberPrices = serverOfferBestPrice.luxPlusPrices
  const memberPrice = sum(memberPrices)

  const hotelValue = serverOfferBestPrice.value
  const flightPrice = serverOfferBestPrice.flightPrice ?? 0
  const surcharge = serverOfferBestPrice.surcharge ?? 0
  const extraGuestSurcharge = serverOfferBestPrice.extraGuestSurcharge ?? 0

  const fees: App.Pricing['fees'] = [
    { type: 'property', amount: propertyFees },
    { type: 'taxesAndFees', amount: taxesAndFees },
    { type: 'surcharge', amount: surcharge },
    { type: 'extraGuestSurcharge', amount: extraGuestSurcharge },
  ]

  const pricing: { defaultPricing: App.PricingWithValue, memberPricing?: App.PricingWithValue, withFlightsPricing?: App.PricingWithValue, memberWithFlightsPricing?: App.PricingWithValue } = {
    defaultPricing: {
      price: basePrice + propertyFees,
      value: hotelValue + propertyFees,
      discountPercent: getValueOffPercent(hotelValue + propertyFees, basePrice + propertyFees),
      saleUnit: options.saleUnit ?? 'package',
      fees,
    },
  }

  pricing.memberPricing = determineMemberPricing(pricing.defaultPricing, memberPrice + propertyFees, hotelValue + propertyFees)

  if (flightPrice) {
    pricing.withFlightsPricing = addFlightPrice(pricing.defaultPricing, flightPrice)
    if (pricing.memberPricing) {
      pricing.memberWithFlightsPricing = addFlightPrice(pricing.memberPricing, flightPrice)
    }
  }

  return {
    offerId: serverOfferBestPrice.offerId,
    duration: serverOfferBestPrice.duration,
    roomPrices: prices,
    roomMemberPrices: memberPrices,
    hotelValue,
    extraGuestSurcharge,
    flightPrice,
    surcharge,
    taxesAndFees,
    propertyFees,
    availableRooms: serverOfferBestPrice.availableRooms,
    packageId: serverOfferBestPrice.packageId,
    // this will make it match our own unique keys
    packageUniqueKey: getPackageUniqueKey(serverOfferBestPrice.packageId, serverOfferBestPrice.duration, serverOfferBestPrice.roomRateId),
    roomRateId: serverOfferBestPrice.roomRateId,
    cancellationPolicies: serverOfferBestPrice.connection ? connectionBuildCancellationPolicies(serverOfferBestPrice.connection.cancellationPolicies, options) : undefined,
    hasTactical: serverOfferBestPrice.hasTactical,
    price: basePrice + surcharge,
    hotelPrice: basePrice,
    memberPriceWithSurcharge: memberPrice + surcharge,
    memberPrice,
    hotelMemberPrice: memberPrice,
    hotelMemberValue: serverOfferBestPrice.luxPlusBaseValue,
    ...pricing,
  }
}

interface AvailabilityQueryParams extends AvailablePriceParams {
  offerType?: App.OfferType;
  offerIds: Array<string>;
  checkIn: string;
  checkOut: string;
  rooms: Array<App.Occupants>;
  dynamic?: boolean;
  lowestPrices?: boolean;
  flightOrigin?: string;
  bundledOfferId?: string;
  channelMarkup?: App.ChannelMarkup;
  saleUnit?: string;
}

export function getAvailableRatesForOffer(params: AvailabilityQueryParams): Promise<Array<App.OfferAvailableRate>> {
  const queryParams = qs.stringify({
    offerIds: params.offerIds.join(','),
    region: params.regionCode,
    checkIn: params.checkIn,
    checkOut: params.checkOut,
    occupancy: params.rooms.map(generateOccupancyStringByRoom),
    dynamic: params.dynamic,
    lowestPrices: params.lowestPrices,
    flightOrigin: params.flightOrigin,
    bundledOfferIds: params.bundledOfferId,
  })

  // compression in header for fix the compression on backend side
  // TODO: after fix remove it and use Accept-Encoding
  try {
    return request.get<App.ApiResponse<Array<CalendarV2.Availability>>>(`/api/v2/calendar/availability?${queryParams}`, { headers: { compression: 'gzip' } })
      .then(resp => resp.result.map(result => availablePriceMap(result, {
        hideExcludesFlights: params.offerType === OFFER_TYPE_VILLA,
        timezone: params.timezone,
        regionCode: params.regionCode,
        currencyCode: params.currencyCode,
        offerType: params.offerType,
        saleUnit: params.saleUnit,
      }, params.channelMarkup)))
  } catch (error) {
    if (error && typeof error === 'object' && 'status' in error &&
        (error.status === 404 || error.status === 422)) {
      // Offer has no packages available with these parameters
      return Promise.resolve([])
    }
    throw error
  }
}

interface LowestPriceQueryParams extends AvailablePriceParams {
  offerType: App.OfferType;
  offerIds: Array<string>;
  checkIn: string;
  checkOut: string;
  rooms: Array<App.Occupants>;
  bundledOfferId?: string;
  channelMarkup?: App.ChannelMarkup;
  saleUnit?: string;
}

export type LowestPriceResponse = { available: boolean, rate?: undefined, checkIn: string, checkOut: string} | { available: boolean, rate: App.OfferAvailableRate, checkIn: string, checkOut: string }
export async function getLowestPriceForOffer(params: LowestPriceQueryParams): Promise<LowestPriceResponse> {
  const data = await getAvailableRatesForOffer({ ...params, lowestPrices: true, dynamic: false, offerType: params.offerType })

  const rate = data[0]

  if (!rate) {
    return { available: false, checkIn: params.checkIn, checkOut: params.checkOut }
  }

  return ({
    available: true,
    rate,
    checkIn: params.checkIn,
    checkOut: params.checkOut,
  })
}

type ValidCalendar = Omit<App.Calendar, 'uniqueKey'> & { uniqueKey: string }
function isValidCalendar(calendar: App.Calendar): calendar is ValidCalendar {
  return Boolean(calendar.uniqueKey)
}
export async function getCalendarsByOccupancy(
  offer: App.HotelOffer | App.HotelOfferSummary,
  region: string,
  params: CalendarParams,
  channelMarkup?: App.ChannelMarkup,
) {
  const calendars = await getCalendars(offer, region, params, channelMarkup)
  const validCalendars = calendars.filter(isValidCalendar)
  return arrayToObject(validCalendars, (pkgCalendar) => pkgCalendar.uniqueKey)
}
