import { paths } from '@luxuryescapes/contract-svc-lux-loyalty'
import { luxLoyaltyAccountActivityMapper, luxLoyaltyAccountMapper, luxLoyaltyAccountPendingPointsMapper, luxLoyaltyPendingPointsMapper } from './mappers/luxLoyaltyMap'
import requestUtils, { authOptions } from './requestUtils'
import qs from 'qs'
import { sum } from 'lib/array/arrayUtils'

type EnrolLuxLoyaltyAccountPayload = paths['/api/lux-loyalty/account']['post']['parameters']['body']['payload']
type EnrolLuxLoyaltyAccountResponse = paths['/api/lux-loyalty/account']['post']['responses']['201']['content']['application/json']
type GetLuxLoyaltyAccountResponse = paths['/api/lux-loyalty/account/me']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyOrderPendingPoints = paths['/api/lux-loyalty/customer/{customerId}/order/{orderId}/pending-points']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyAccountPendingPointResponse = paths['/api/lux-loyalty/customer/{customerId}/pending-points']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyAccountEligibilityResponse = paths['/api/lux-loyalty/customer/{customerId}/enrolment-tier']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyProgramConfigResponse = paths['/api/lux-loyalty/program-configuration']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyOrderResponse = paths['/api/lux-loyalty/customer/{customerId}/order/{orderId}']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyAccountActivityResponse = paths['/api/lux-loyalty/account/activities']['get']['responses']['200']['content']['application/json']

const BASE_PATH = '/api/lux-loyalty'

export async function enrolLuxLoyaltyAccountRequest(payload: EnrolLuxLoyaltyAccountPayload, token?: string) {
  const response = await requestUtils.post<EnrolLuxLoyaltyAccountResponse, EnrolLuxLoyaltyAccountPayload>(
    `${BASE_PATH}/account`,
    payload,
    {
      ...authOptions(token),
      excludeBrand: true,
    })

  return luxLoyaltyAccountMapper(response.result.accountSummary)
}

export async function fetchLuxLoyaltyAccountRequest(token?: string) {
  const response = await requestUtils.get<GetLuxLoyaltyAccountResponse>(`${BASE_PATH}/account/me`, authOptions(token))

  return luxLoyaltyAccountMapper(response.result.accountSummary)
}

/**
 * Endpoint to determine which tier a customer is eligible to start with for when enrolling in the program
 */
export async function fetchLuxLoyaltyAccountEligibilityRequest(customerId: string) {
  const response = await requestUtils.get<GetLuxLoyaltyAccountEligibilityResponse>(`${BASE_PATH}/customer/${customerId}/enrolment-tier`, {
    credentials: 'include',
  })

  return response.result.enrolmentTier
}

export async function fetchLuxLoyaltyAccountActivityRequest(customerId: string, startDate: string, endDate: string) {
  const params = qs.stringify({
    customerId,
    fromDate: startDate,
    toDate: endDate,
  })
  const response = await requestUtils.get<GetLuxLoyaltyAccountActivityResponse>(`${BASE_PATH}/account/activities?${params}`, {
    credentials: 'include',
    excludeBrand: true,
  })

  return luxLoyaltyAccountActivityMapper(response.result.activities)
}

export async function fetchLuxLoyaltyAccountPendingPointsRequest(customerId: string) {
  const response = await requestUtils.get<GetLuxLoyaltyAccountPendingPointResponse>(`${BASE_PATH}/customer/${customerId}/pending-points`, {
    credentials: 'include',
  })

  return luxLoyaltyAccountPendingPointsMapper(response.result.pendingPoints)
}

export async function requestLuxLoyaltyOrderPendingPoints(customerId: string, orderId: string): Promise<Array<App.OrderLoyaltyPendingPoints>> {
  const response = await requestUtils.get<GetLuxLoyaltyOrderPendingPoints>(`${BASE_PATH}/customer/${customerId}/order/${orderId}/pending-points`, {
    credentials: 'include',
  })
  return luxLoyaltyPendingPointsMapper(response.result.pendingPoints)
}

export function getLuxLoyaltyConfig(regionCode: string): Promise<App.LuxLoyaltyConfig> {
  const query = qs.stringify({
    region: regionCode,
  })

  return requestUtils.get<GetLuxLoyaltyProgramConfigResponse>(
    `${BASE_PATH}/program-configuration?${query}`,
    { excludeBrand: true },
  ).then((response): App.LuxLoyaltyConfig => {
    const loungePassBenefit = response.result.benefits.find(benefit => benefit.code === 'lounge_pass')
    const transferBenefit = response.result.benefits.find(benefit => benefit.code === 'airport_transfer')
    return {
      loungePassExperienceIds: loungePassBenefit?.offerIds ?? [],
      transferAirports: transferBenefit?.eligibleAirports ?? [],
      benefits: response.result.benefits.map(benefit => ({
        code: benefit.code,
        paymentType: benefit.lePaymentType,
      })),
    }
  })
}

/**
 * Retrieves all loyalty data about a specific order
 */
export function getLoyaltyOrderData(orderId: string, memberId: string): Promise<App.OrderLoyaltyDetails> {
  return requestUtils.get<GetLuxLoyaltyOrderResponse>(
    `/api/lux-loyalty/customer/${memberId}/order/${orderId}`,
    { credentials: 'include' },
  ).then((response): App.OrderLoyaltyDetails => {
    return {
      benefitsRedeemed: response.result.benefitsRedeemed.map((benefit): App.OrderLoyaltyBenefit => ({
        amount: benefit.currencyAmount,
        itemId: benefit.itemId,
        status: benefit.status,
        code: benefit.benefitCode as App.LuxLoyaltyBenefitType,
      })),
      pointsSpent: response.result.pointsSpent.map((points): App.OrderLoyaltyPointsSpent => ({
        amount: points.currencyAmount,
        itemId: points.itemId,
        points: points.pointsAmount,
        status: points.status,
      })),
      airportTransfer: !!(response.result.airportTransferRequest && response.result.airportTransferRequest?.status !== 'FAILED'),
      pendingPoints: {
        points: sum(response.result.pendingPoints, pending => pending.points),
        statusCredits: sum(response.result.pendingPoints, pending => pending.statusCredits),
        items: response.result.pendingPoints.map((points): App.OrderLoyaltyPendingPoints => ({
          itemId: points.itemId,
          points: points.points,
          statusCredits: points.statusCredits,
        })),
      },
    }
  })
}
