import { createSelector } from 'reselect'
import { getBaseTierOfferWithNoJoiningFee, getBaseTierOfferWithJoiningFee } from 'selectors/luxPlusSelectors'

export const getLuxPlusPointsCalculationOptions = createSelector(
  (state: App.State) => getBaseTierOfferWithJoiningFee(state),
  (state: App.State) => getBaseTierOfferWithNoJoiningFee(state),
  (
    baseTierOfferWithFee,
    baseTierOfferNoFee,
  ): {
    withJoinFee: Array<App.LuxLoyaltyPointsEarnCalculationRequest>,
    noJoinFee: Array<App.LuxLoyaltyPointsEarnCalculationRequest>,
  } => ({
    withJoinFee: [{
      price: (baseTierOfferWithFee?.price ?? 0) + (baseTierOfferWithFee?.joinFee ?? 0),
      luxLoyaltyProductType: 'lux_plus',
    }],
    noJoinFee: [{
      price: (baseTierOfferNoFee?.price ?? 0),
      luxLoyaltyProductType: 'lux_plus',
    }],
  }),
)

export const getPointsCalculationsByKeys = createSelector(
  (state: App.State) => state.luxLoyalty.pointsEarnCalculations,
  (_state: App.State, pointCalculationKeys: Array<string>) => pointCalculationKeys,
  (pointsCalculator, keys): Array<App.StatefulData<App.LuxLoyaltyPointsEarnCalculation> | undefined> => keys.map(key => pointsCalculator[key]),
)
