import FilterPanelCheckboxGroup from 'components/Common/FilterPanel/FilterPanelCheckboxGroup'
import FilterPanelFilterGroup from 'components/Common/FilterPanel/FilterPanelFilterGroup'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import LineAngleUpIcon from 'components/Luxkit/Icons/line/LineAngleUpIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import {
  generateOptionItems,
  Option,
  optionMapToOptions,
} from 'components/OfferList/OfferListFilter/OfferListFilterUtils'
import { replace } from 'connected-react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import {
  deleteSearchParams,
  toggleSearchParamValue,
} from 'lib/url/searchUrlUtils'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Modal from 'components/Luxkit/Modal/Modal'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import LayoutContainer from 'components/Common/LayoutContainer'
import { toggleSetValue } from 'lib/set/setUtils'
import LoadingAnimation from 'components/Common/Loading/LoadingAnimation'
import Group from 'components/utils/Group'

interface Props {
  availableFilters: Record<string, number> | undefined;
  filterOrder?: Record<string, number>;
  label: string;
  selectedFilters: Array<string>;
  search: string;
  searchQueryKey: string;
}

function FilterDropdown(props: Props) {
  const { availableFilters, filterOrder, selectedFilters, label, search, searchQueryKey } = props
  const isMobile = useScreenSizeOnly('mobile')
  const [isDropdownOpen, toggleDropDown, , closeDropdown] = useToggle()
  const dispatch = useAppDispatch()
  const filterChipRef = useRef<HTMLButtonElement>(null)
  const loading = !availableFilters

  const [options, setOptions] = useState<Array<Option>>([])
  const [selectedSet, setSelectedSet] = useState(new Set<string>())
  const optionItems = useMemo(() => generateOptionItems(options, selectedSet), [options, selectedSet])

  useEffect(() => {
    setSelectedSet(new Set(selectedFilters))
  }, [selectedFilters])

  useEffect(() => {
    if (!availableFilters || !filterOrder) {
      return // still loading
    }

    const hasFilters = Object.keys(availableFilters).length > 0
    if (hasFilters) {
      const newOptions = optionMapToOptions(availableFilters, filterOrder)
      setOptions(newOptions)
    }
  }, [availableFilters, filterOrder, dispatch, search, searchQueryKey, selectedSet])

  const onFilterTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSet(toggleSetValue(selectedSet, e.target.value))
    dispatch(replace({ search: toggleSearchParamValue(search, searchQueryKey, e.target.value) }))
  }, [dispatch, search, searchQueryKey, selectedSet])

  const onClose = useCallback(() => {
    closeDropdown()
  }, [closeDropdown])

  const onReset = useCallback(() => {
    setSelectedSet(new Set())
    dispatch(replace({ search: deleteSearchParams(search, searchQueryKey) }))
  }, [dispatch, search, searchQueryKey])

  const hasAvailableFilters = !!options.length

  const resetLabel = 'Reset all' + (selectedSet.size ? ` (${selectedSet.size})` : '')
  const chipLabel = label + (selectedSet.size ? ` (${selectedSet.size})` : '')

  const modalTitle = useMemo(() => {
    return <Group direction="horizontal" verticalAlign="center" gap={8} >
      {label}
      {loading ? <LoadingAnimation size={8} /> : null}
    </Group>
  }, [label, loading])

  return (
    <div>
      <FilterChip ref={filterChipRef} selected={!!selectedSet.size} size="medium" onClick={toggleDropDown} endIcon={isDropdownOpen ? <LineAngleUpIcon /> : <LineAngleDownIcon />}>
        {chipLabel}
      </FilterChip>
      <CSSBreakpoint min="tablet">
        <DropdownList
          size="M"
          triggerRef={filterChipRef}
          anchorRef={filterChipRef}
          open={isDropdownOpen}
          placement="bottom-start"
          onClose={onClose}
          secondaryActionProps={{
            kind: 'tertiary',
            children: 'Reset',
            onClick: onReset,
          }}
          primaryActionProps={{
            children: 'View Offers',
            onClick: onClose,
          }}
        >
          <LayoutContainer>
            <FilterPanelFilterGroup allowToggle={false} showDropdown={false} title={label} loading={loading} showBorder={false}>
              {!hasAvailableFilters && <BodyText variant="medium">No available filters</BodyText>}
              {hasAvailableFilters && <FilterPanelCheckboxGroup
                name={label}
                items={optionItems}
                onChange={onFilterTypeChange}
                maxItems={5}
                allowShowMore={false}
              />}
            </FilterPanelFilterGroup>
          </LayoutContainer>
        </DropdownList>
      </CSSBreakpoint>
      <CSSBreakpoint max="mobile">
        <Modal
          title={modalTitle}
          isOpen={isDropdownOpen && isMobile}
          onClose={onClose}
          primaryActionText="View Offers"
          onPrimaryActionClick={onClose}
          secondaryActionText={resetLabel}
          onSecondaryActionClick={onReset}
        >
          {!hasAvailableFilters && <BodyText variant="medium">No available filters</BodyText>}
          {hasAvailableFilters && <FilterPanelCheckboxGroup
            name={label}
            items={optionItems}
            onChange={onFilterTypeChange}
            maxItems={5}
            allowShowMore={false}
          />}
        </Modal>
      </CSSBreakpoint>
    </div>
  )
}

export default FilterDropdown
