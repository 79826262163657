import { BOOKING_PROTECTION_MAX_ORDER_CURRENCY_MAP, BOOKING_PROTECTION_DEFAULT_MAX_ORDER_VALUE } from 'constants/bookingProtection'
import { createSelector } from 'reselect'
import getCheckoutTotalsView from '../payment/getCheckoutTotalsView'
import { isBookingProtectionSelected } from '../view/bookingProtection'
import { getTravelDatesFromCart } from './bookingProtection'
import { CHECKOUT_ITEM_TYPE_LE_HOTEL } from 'constants/checkout'
import config from 'constants/config'
import { isBookingProtectionAvailableForAccommodationOffer } from 'lib/checkout/bookingProtection/utils'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { isStandaloneExperience } from 'selectors/checkoutSelectors'
import { getFlightItems } from '../view/flights'
import { getAccommodationItems } from '../view/hotels'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'

export const isInsuranceBookingProtectionAvailableForCartItems = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => getFlightItems(state),
  (state: App.State) => state.offer.offers,
  (state: App.State) => isStandaloneExperience(state),
  (accommodationItems, flightItems, offers, isStandaloneExperience) => {
    const items = [...accommodationItems, ...flightItems]
    for (const item of items) {
      if (!isEnabledForFeature(config.UNIVERSAL_CHECKOUT_INSURANCE_BOOKING_PROTECTION_ENABLED_LIST, item.itemType)) {
        return false
      }

      if (item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL) {
        const offer = offers[item.offerId]
        if (offer && !isBookingProtectionAvailableForAccommodationOffer(offer)) {
          return false
        }
      }
    }

    if (isStandaloneExperience) {
      const experienceItemType:App.Checkout.ExperienceItemType = 'experience'
      if (!isEnabledForFeature(config.UNIVERSAL_CHECKOUT_INSURANCE_BOOKING_PROTECTION_ENABLED_LIST, experienceItemType)) return false
    }

    return true
  },
)

const isBookingProtectionAvailableForCartItems = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => getFlightItems(state),
  (state: App.State) => state.offer.offers,
  (state: App.State) => isStandaloneExperience(state),
  (accommodationItems, flightItems, offers, isStandaloneExperience) => {
    const items = [...accommodationItems, ...flightItems]
    for (const item of items) {
      if (!isEnabledForFeature(config.BOOKING_PROTECTION_ENABLED_LIST, item.itemType)) {
        return false
      }

      if (item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL) {
        const offer = offers[item.offerId]
        if (offer && !isBookingProtectionAvailableForAccommodationOffer(offer)) {
          return false
        }
      }
    }

    if (isStandaloneExperience) {
      const experienceItemType:App.Checkout.ExperienceItemType = 'experience'
      if (!isEnabledForFeature(config.BOOKING_PROTECTION_ENABLED_LIST, experienceItemType)) return false
    }

    return true
  },
)

const isBookingProtectionAvailable = createSelector(
  (state: App.State) => getTravelDatesFromCart(state),
  (state: App.State) => getCheckoutTotalsView(state),
  (state: App.State) => isBookingProtectionSelected(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.geo.currentCurrency,
  (state: App.State) => isBookingProtectionAvailableForCartItems(state),
  (state: App.State) => isInsuranceBookingProtectionAvailableForCartItems(state),
  (
    travelDates,
    checkoutTotalsView,
    bookingProtectionSelected,
    postPurchase,
    currency,
    isBookingProtectionAvailableForCartItems,
    isInsuranceBookingProtectionAvailableForCartItems,
  ): boolean => {
    // If booking protection is already selected, return true
    if (bookingProtectionSelected) return true
    if (postPurchase) return false
    if (!travelDates?.startDate || (travelDates.startDate && !travelDates.startDate.isValid())) return false
    if (checkoutTotalsView.data.subTotal > (BOOKING_PROTECTION_MAX_ORDER_CURRENCY_MAP[currency] || BOOKING_PROTECTION_DEFAULT_MAX_ORDER_VALUE)) return false
    // This applies to Flash, LPC, LPP, Experiences, Flights and Bundled
    if (!isBookingProtectionAvailableForCartItems && !isInsuranceBookingProtectionAvailableForCartItems) return false
    return true
  },
)

export default isBookingProtectionAvailable
