import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView, getAccommodationItems } from 'checkout/selectors/view/accommodation'
import qs from 'qs'
import { hasFlightSessionExpired } from 'components/Flights/FlightsSessionTimer/FlightsSessionTimer'
import { countOccupantsForFlights } from 'lib/offer/occupancyUtils'
import { getFlightItems } from './view/flights'
import { FlightsClassTypes, FlightSortOptions } from 'constants/flight'
import { FlightFilters } from 'checkout/Components/InFlowFlightSearch/CheckoutFlightsFilterDrawer'
import { nonNullable } from 'lib/array/arrayUtils'

export const getCheckoutFlightFilters = createSelector(
  (state: App.State) => state.router.location.search,
  (search: string): FlightFilters => {
    const params = new URLSearchParams(search)

    return {
      originAirportCode: params.get('originAirportCode') || undefined,
      destinationAirportCode: params.get('destinationAirportCode') || undefined,
      departDate: params.get('departDate') || undefined,
      returnDate: params.get('returnDate') || undefined,
      fareClass: params.get('fareClass') as FlightsClassTypes || undefined,
      stops: params.getAll('stops'),
      stopDurations: params.getAll('stopDurations'),
      airlines: params.getAll('airlines'),
      cabinTypes: params.getAll('cabinTypes'),
      durations: params.getAll('durations'),
      minDepartureTime: params.getAll('minDepartureTime'),
      maxDepartureTime: params.getAll('maxDepartureTime'),
      minArrivalTime: params.getAll('minArrivalTime'),
      maxArrivalTime: params.getAll('maxArrivalTime'),
      recommended: params.getAll('recommended'),
      sort: params.get('sort') as FlightSortOptions ?? FlightSortOptions.Cheapest,
      bags: params.getAll('bags'),
      stopOverCities: params.getAll('stopOverCities'),
      alliances: params.getAll('alliances'),
    }
  },
)

export const getInflowFlightSearchParams = createSelector(
  (state: App.State) => getFlightItems(state),
  (state: App.State) => checkoutAccommodationOfferView(state),
  (items, offerView): string | undefined => {
    const views = offerView.data
    if (views[0] && items[0]) {
      return qs.stringify({
        departDate: views[0].startDate,
        returnDate: views[0].endDate,
        originAirportCode: items[0].originAirportCode,
        destinationAirportCode: items[0].destinationAirportCode,
      })
    }
  },
)

export const isFlightSessionExpired = createSelector(
  (state: App.State) => getFlightItems(state),
  (items) => {
    return items.length > 0 && hasFlightSessionExpired()
  },
)

export const isStandaloneFlight = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => getFlightItems(state),
  (cartItems, flightItems): boolean => flightItems.length === 1 && cartItems.length === 1,
)

export const getCheckoutFlightOccupancies = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (items): App.Occupants => {
    const occupants = nonNullable(items.map(item => (item as any).occupancy))
    return countOccupantsForFlights(occupants)
  },
)
