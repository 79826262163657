import { nonNullable } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getCheckoutInsuranceQuoteParams from '../request/getCheckoutInsuranceQuoteParams'
import getCheckoutInsuranceProducts from './getCheckoutInsuranceProducts'
import { getInsuranceQuoteKey, insuranceTypeToInsuranceProductType } from 'lib/insurance/insuranceUtils'

const getCheckoutInsuranceQuotes = createSelector(
  (state: App.State) => getCheckoutInsuranceQuoteParams(state),
  (state: App.State) => state.insurance.quotes,
  (_: App.State, type?: App.InsuranceProductType) => type,
  (state: App.State, type?: App.InsuranceProductType) => getCheckoutInsuranceProducts(state, type),
  (quoteParams, quotes, _, products): Array<App.InsuranceQuote> => {
    const checkoutQuotes = products.map(product => {
      const key = getInsuranceQuoteKey(
        product.id,
        {
          type: insuranceTypeToInsuranceProductType(product.type),
          ...quoteParams,
        },
      )
      return quotes[key]
    })

    return nonNullable(checkoutQuotes)
  },
)

export default getCheckoutInsuranceQuotes
