import request, { ObjectOptions } from 'api/requestUtils'
import { getDomainUserId } from 'analytics/snowplow/helpers/domainUserId'
import { paths, definitions } from '@luxuryescapes/contract-cart'
export type CartItem = definitions['cartItem']
export type CartItemLoyalty = definitions['instantHotelItem']['luxLoyalty']
export type CartRequestV2 = paths['/api/carts/{version}/{cartId}']['put']['parameters']['body']['payload']
export type Brand = CartRequestV2['brand']
export type UserCartRequest = paths['/api/carts/{version}/mycart']['put']['parameters']['body']['payload']
export type UserCartResponse = paths['/api/carts/{version}/mycart']['get']['responses']['200']['content']['application/json']

export type TermsAndConditionsUpdateData = {
  orderId?: string,
  accepted?: boolean,
  acceptedAt?: string,
}

export type CartLoyaltyData = {
  pointsToBurn?: number;
  grandTotal?: number;
}

export type CartItemResponse = {
  items: Array<App.Checkout.AnyItem>
  total: number | undefined
  type: 'pending' | 'completed' | 'purchased'
  luxLoyalty?: CartLoyaltyData
}
export type CartTemplateResponse = {
  id: string,
  template: {
    items: Array<CartItem>
  },
  createdAt: string
  updatedAt: string
}

export type QuoteDedupeKeys = {
  customerEmailDedupeKey: string
  multipleCustomerEmailDedupeKey?: string
}

type CartSuccessResponseV2 = paths['/api/carts/{version}/{cartId}']['put']['responses']['200']
type UserCartResponseState = Omit<App.UserCartState, 'synced' | 'fetching'>

/**
 *
 * Marks this specific cart as finalized so it cannot be purchased again
 *
 * @param transactionKey or Cart ID
 */
export function finalizeCart(transactionKey: string) {
  return request.patch(`/api/carts/${transactionKey}`, { op: 'finalize' }, { credentials: 'include' })
}

/**
 *
 * Marks this specific cart as purchased, it can still be purchased again, however the item transaction keys will be re-generated
 *
 * @param transactionKey or Cart ID
 */
export function purchaseCart(transactionKey: string) {
  return request.patch(`/api/carts/${transactionKey}`, { op: 'purchase' }, { credentials: 'include' })
}

export function putNewCheckoutCart(transactionKey: string, cart:CartRequestV2) {
  return request.put<CartSuccessResponseV2, CartRequestV2>(`/api/carts/v2/${transactionKey}`, { ...cart, domainUserId: getDomainUserId() }, { credentials: 'include' })
    .catch((err) => console.error('PUT Cart Request Failed', {
      cartPayloadItems: cart.items,
      transactionKey,
      error: err.message,
    }))
}

export function getCartItems(cartId: string): Promise<CartItemResponse> {
  return request.get<CartItemResponse>(`/api/carts/v2/${cartId}/items`, { credentials: 'include' }).then((res): CartItemResponse => ({
    items: res.items,
    total: res.total,
    luxLoyalty: res.luxLoyalty,
    type: res.type ?? 'pending',
  }))
}

export function createCartTemplate(items: Array<App.Checkout.AnyItem>): Promise<CartTemplateResponse> {
  return request.post('/api/carts/templates?without_brand=1', { items }, { credentials: 'include' })
}

export function createQuote(cartId: string, callbackDate?: string, utms?: Array<string>) {
  return request.post('/api/carts/quotes', { cartId, callbackDate, utms }, { credentials: 'include' })
}

export function saleAttribution(cartId: string, orderId: string) {
  return request.post('/api/carts/attribution?without_brand=1', { cartId, orderId }, { credentials: 'include' })
}

export function updateQuote(quoteId: string, keys: QuoteDedupeKeys, callbackDate?: string) {
  return request.patch(`/api/carts/quotes/${quoteId}?without_brand=1`, { ...keys, callbackDate }, { credentials: 'include' })
}

// TODO map contract at item level
const mapUserCartResponse = (response: UserCartResponse): UserCartResponseState => {
  const items: App.UserCartState['items'] = {}
  const itemStates: App.UserCartState['itemStates'] = {}
  response.result.items?.forEach(item => {
    if ('saveForLater' in item) {
      const { saveForLater, ...rest } = item
      items[item.itemId] = rest as App.Checkout.AnyItem
      itemStates[item.itemId] = { deleted: false, saveForLater: !!saveForLater }
    } else {
      items[item.itemId] = item as App.Checkout.AnyItem
      itemStates[item.itemId] = { deleted: false, saveForLater: false }
    }
  })

  return {
    items,
    itemStates,
    connectedRoomOfferIds: response.result.connectedRoomOfferIds,
  }
}
export async function getUserCart(accessToken?: string): Promise<UserCartResponseState> {
  let options: ObjectOptions = { credentials: 'include' }
  if (accessToken) {
    options = { headers: { authorization: `Bearer ${accessToken}` } }
  }
  return mapUserCartResponse(await request.get<UserCartResponse>('/api/carts/v2/mycart', options))
}

// TODO map contract at item level
const mapUserCartRequest = (userCartState: App.UserCartState) => {
  return {
    items: Object.values(userCartState.items)
      .filter(item => !userCartState.itemStates[item.itemId].deleted)
      .map(item => ({
        ...item,
        saveForLater: userCartState.itemStates[item.itemId].saveForLater,
      })),
    connectedRoomOfferIds: userCartState.connectedRoomOfferIds,
  }
}

export function putUserCart(userCartState: App.UserCartState) {
  return request.put('/api/carts/v2/mycart', mapUserCartRequest(userCartState), { credentials: 'include' })
}

export function syncUserCartForRecommendations(userCartState: App.UserCartState, userId?: string) {
  const domainUserId = getDomainUserId()
  if (!userId || !domainUserId) return
  const queryParams = new URLSearchParams({ without_brand: '1', le_user_id: userId, domain_user_id: domainUserId })
  const payload = [{ ...mapUserCartRequest(userCartState), isMultiItemMode: true }]

  // Have to use without_brand=1 to disable the brand preprocessor since we are sending an array
  return request.post(`/api/recommendation/high_intent/abandoned_carts?${queryParams}`, payload, {
    credentials: 'include',
  })
}

export function createTermsAndConditions(cartId: string, emailId: string) {
  return request.post('/api/carts/v1/terms-and-conditions', { cartId, emailId }, { credentials: 'include' })
}

export function updateTermsAndConditions(cartId: string, updateData: TermsAndConditionsUpdateData) {
  return request.patch(`/api/carts/v1/terms-and-conditions/${cartId}`, updateData, { credentials: 'include' })
}
