import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  opacity: 0;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s, opacity 0.3s;

  &.expanded {
    grid-template-rows: 1fr;
    opacity: 1;
  }
`

const Content = styled.div`
  overflow: hidden;
`

interface Props extends PropsWithChildren {
  expanded: boolean
  className?: string
}

function Expander({
  children,
  expanded,
  className,
}: Props) {
  return <Container className={clsx(className, { expanded })}>
    <Content>{children}</Content>
  </Container>
}

export default Expander
