import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'
import TextButton from 'components/Luxkit/Button/TextButton'
import ModalContext from 'contexts/ModalContext'
import React, { MouseEventHandler, useCallback, useContext } from 'react'

interface Props {
  isLoginForMemberSavings?: boolean
}

function BedbankSearchTileSignUpButton({ isLoginForMemberSavings }: Props) {
  const showModal = useContext(ModalContext)
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    showModal(<AccountAccessModal />)
  }, [showModal])

  if (isLoginForMemberSavings) {
    return <TextButton kind="primary" nonInteractable onClick={handleClick}>
      Login for member savings
    </TextButton>
  }

  return <TextButton kind="secondary" nonInteractable onClick={handleClick}>
    Sign up for free
  </TextButton>
}

export default BedbankSearchTileSignUpButton
