import equal from 'fast-deep-equal'

import { addPricing } from 'lib/offer/pricing'
import { pluralizeToString } from 'lib/string/pluralize'

export const getPriceBundlePackage = (bundledPackages: Array<App.Package>, saleUnit: string) => {
  const priceBundlePackage = bundledPackages.reduce<App.PackagePrice>((acc, item) => {
    return {
      price: acc.price + item.price,
      memberPrice: acc.memberPrice + item.memberPrice,
      value: acc.value + item.value,
      memberValue: acc.memberValue + item.memberValue,
      taxesAndFees: acc.taxesAndFees + item.taxesAndFees,
      propertyFees: acc.propertyFees + item.propertyFees,
      surcharge: acc.surcharge + item.surcharge,
      discountPercent: Number(Number((((acc.memberPrice || acc.price) + (acc.memberPrice || item.price)) / (acc.value + item.value)) * 100).toFixed(2)),
      bundleDiscountPercent: 0,
      durationLabel: pluralizeToString('Night', acc.duration + item.duration),
      duration: acc.duration + item.duration,
      trackingPrice: acc.trackingPrice! + (item.trackingPrice ?? 0),
      defaultPricing: acc.defaultPricing && item.defaultPricing ? addPricing(acc.defaultPricing, item.defaultPricing) : undefined,
      memberPricing: acc.memberPricing && (item.memberPricing ?? item.defaultPricing) ? addPricing(acc.memberPricing, (item.memberPricing ?? item.defaultPricing)!) : undefined,
    }
  }, {
    price: 0,
    memberPrice: 0,
    value: 0,
    memberValue: 0,
    taxesAndFees: 0,
    propertyFees: 0,
    surcharge: 0,
    discountPercent: 0,
    bundleDiscountPercent: 0,
    durationLabel: '',
    duration: 0,
    trackingPrice: 0,
    defaultPricing: {
      price: 0,
      fees: [],
      saleUnit,
    },
    memberPricing: {
      price: 0,
      fees: [],
      saleUnit,
    },
  })

  if (equal(priceBundlePackage.defaultPricing, priceBundlePackage.memberPricing)) {
    delete priceBundlePackage.memberPricing
  }

  if (priceBundlePackage.defaultPricing && priceBundlePackage.defaultPricing.price === 0) {
    delete priceBundlePackage.defaultPricing
  }

  return priceBundlePackage
}
