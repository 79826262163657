import React, { useCallback, useContext } from 'react'
import Group from 'components/utils/Group'
import CruiseSearchInputs from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchInputs'
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks/reduxHooks'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import useQueryParams from 'hooks/useQueryParams'
import { getCruiseSearchFiltersBar } from 'lib/cruises/cruiseUtils'
import { pushWithCruiseFilters } from 'actions/CruiseActions'

function CruisesTabContent() {
  const dispatch = useAppDispatch()
  const globalSearch = useContext(GlobalSearchStateContext)
  const { pathname, regionCode } = useAppSelector(state => {
    return {
      pathname: state.router.location.pathname,
      regionCode: state.geo.currentRegionCode,
    }
  }, shallowEqual)
  const queryParams = useQueryParams()

  const onChange = useCallback((values: App.CruiseGlobalFilters) => {
    const { pageName, filters } = getCruiseSearchFiltersBar(values, globalSearch, pathname, regionCode)
    dispatch(pushWithCruiseFilters(pageName, filters, queryParams))
  }, [globalSearch, pathname, regionCode, dispatch, queryParams])

  return <Group direction="vertical" gap={16}>
    <CruiseSearchInputs drawerMode onChange={onChange} />
  </Group>
}

export default CruisesTabContent
