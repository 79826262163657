import { rem } from 'polished'

import styled from 'styled-components'
import Group from 'components/utils/Group'

const CruiseCabinTypeTileWrapper = styled(Group)`
  border: 1px solid ${props => props.theme.palette.neutral.default.six};
  border-radius: ${props => props.theme.borderRadius.XS};
  padding: ${rem(16)};
  position: relative;
`

export default CruiseCabinTypeTileWrapper
