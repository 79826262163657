import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { PICKER_DATE_FORMAT } from 'constants/dateFormats'
import { FlightsFareTypes } from 'constants/flight'
import moment, { Moment } from 'moment'
import React, { useMemo } from 'react'

interface Props {
  checkInDate?: Moment;
  checkOutDate?: Moment;
  fareType?: string;
}

function FormattedFlightSearchDate(props: Props) {
  const { checkInDate, checkOutDate, fareType } = props

  const isOneWay = fareType === FlightsFareTypes.ONE_WAY

  const formattedCheckInDate = useMemo(() => {
    return checkInDate?.isValid() ? moment(checkInDate).format(PICKER_DATE_FORMAT) : undefined
  }, [checkInDate])

  const formattedCheckOutDate = useMemo(() => {
    return checkOutDate?.isValid() ? moment(checkOutDate).format(PICKER_DATE_FORMAT) : undefined
  }, [checkOutDate])

  return <Group direction="horizontal" gap={12} verticalAlign="center">
    <BodyText
      variant="medium"
      weight={formattedCheckInDate ? 'semi-bold' : 'normal' }
      colour={formattedCheckInDate ? 'neutral-one' : 'neutral-three'}
    >
      {formattedCheckInDate || 'Select date'}
    </BodyText>

    {!isOneWay && (<>
      <LineArrowRightIcon />

      <BodyText
        variant="medium"
        weight={formattedCheckOutDate ? 'semi-bold' : 'normal' }
        colour={formattedCheckOutDate ? 'neutral-one' : 'neutral-three'}
      >
        {formattedCheckOutDate || 'Select date'}
      </BodyText>
    </>)}
  </Group>
}

export default FormattedFlightSearchDate
