import { useAppSelector } from 'hooks/reduxHooks'
import useBedbankDiscount from 'hooks/useBedbankDiscount'
import useBedbankOfferSearchPricing from 'hooks/useBedbankOfferSearchPricing'
import { EmptyObject } from 'lib/object/objectUtils'
import { getHotelOfferUrl } from 'lib/offer/offerPageURL'
import { getIsLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import React, { useMemo } from 'react'
import SearchTileWalledMessage from '../shared/SearchTileWalledMessage'
import BedbankSearchTileWalledContent from './BedbankSearchTileWalledContent'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'
import BedbankSearchTileGarden from './BedbankSearchTileGarden'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  filters?: App.OfferListFilters
}

function BedbankSearchTilePriceStack({
  offer,
  filters = EmptyObject,
}: Props) {
  const {
    bundleMemberPrice,
    bundlePrice,
    bundlePropertyFees,
    bundleTaxesAndFees,
    discountPercentage,
    displayMargin,
    flightPrice,
    hotelMemberPrice,
    hotelNights,
    hotelPrice,
    hotelRate,
    isFlexibleSearch,
    margin,
    marginAud,
    nightsToDisplay,
    offerMemberPrice,
    offerPrice,
    promoteAsBundle,
    propertyFees,
    rooms,
    saleUnit,
    shownRate,
    suggestedDates,
    supplier,
    taxesAndFees,
    totalPrices,
    value,
  } = useBedbankOfferSearchPricing(offer, filters)

  const luxPlusLPPEnabled = useAppSelector(getIsLuxPlusLPPEnabled)

  const offerLocations: Array<string> = useMemo(() => {
    const locations = [
      offer.property?.address.city,
      offer.property?.address.stateProvinceName || '',
      offer.property?.address.countryName]
      .filter(Boolean)

    return locations
  }, [offer])

  const offerUrl = useMemo(() => getHotelOfferUrl(offer.id, offer.type), [offer])

  const discount = useBedbankDiscount(hotelRate?.discount, hotelRate?.memberDiscount)

  const directSearchPrices = useDirectSearchPrices({ filters, offerId: offer.id })

  return <BedbankSearchTileWalledContent
    wall={<SearchTileWalledMessage discountPercentage={discount} />}
    offer={offer}
    hidePricing={offer.hidePricing}
    filters={filters}
  >
    <BedbankSearchTileGarden
      offer={offer}
      offerPrice={offerPrice}
      offerMemberPrice={offerMemberPrice}
      isFlexibleSearch={isFlexibleSearch}
      suggestedDates={suggestedDates}
      nightsToDisplay={nightsToDisplay}
      promoteAsBundle={promoteAsBundle}
      saleUnit={saleUnit}
      rooms={rooms}
      discountPercentage={discountPercentage}
      value={value}
      hotelNights={hotelNights}
      totalPrices={totalPrices}
      offerLocations={offerLocations}
      displayMargin={displayMargin}
      margin={margin}
      marginAud={marginAud}
      supplier={supplier}
      directSearchPrices={directSearchPrices}
      shownRate={shownRate}
      bundlePropertyFees={bundlePropertyFees}
      propertyFees={propertyFees}
      bundleTaxesAndFees={bundleTaxesAndFees}
      taxesAndFees={taxesAndFees}
      bundlePrice={bundlePrice}
      hotelPrice={hotelPrice}
      flightPrice={flightPrice}
      bundleMemberPrice={bundleMemberPrice}
      hotelMemberPrice={hotelMemberPrice}
      luxPlusLPPEnabled={luxPlusLPPEnabled}
      offerUrl={offerUrl}
    />
  </BedbankSearchTileWalledContent>
}

export default BedbankSearchTilePriceStack
