/**
 *
 * @param set initial set
 * @param value value to be toggled
 * @returns new set with toggled value
 */
export const toggleSetValue = <T>(set: Set<T>, value: T): Set<T> => {
  const newSet = new Set(set)
  if (newSet.has(value)) {
    newSet.delete(value)
  } else {
    newSet.add(value)
  }
  return newSet
}
