import React, { useCallback } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import { getDestinationImage } from 'lib/search/searchUtils'
import { ANYWHERE_PLACE_ID } from 'constants/search'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import BodyText from 'components/Luxkit/Typography/BodyText'
import HeadingTextBlock from 'components/Luxkit/TextBlocks/HeadingTextBlock'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import HeroImage from 'content/components/HeroImage'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import useModalElementContext from 'hooks/Modal/useModalElementContext'

interface Props {
  placeName: string;
  placeId: string;
  onAddDestination: (e: React.MouseEvent<any>) => void;
  destinationSaved: boolean;
}

const StyledHeroImage = styled(HeroImage)`
&& {
  min-height: unset;
  height: ${rem(167)};
}
`

function AddDestinationToggleModal(props: Props) {
  const { placeName, placeId, destinationSaved, onAddDestination } = props
  const destinationImage = getDestinationImage(placeId) || getDestinationImage(ANYWHERE_PLACE_ID)
  const modalElement = useModalElementContext()

  const handleSkip = useCallback(() => {
    modalElement.resolve()
  }, [modalElement])

  const handleAddDestination = useCallback<React.MouseEventHandler<any>>((e) => {
    onAddDestination(e)
    handleSkip()
  }, [onAddDestination, handleSkip])

  return (
    <ModalBase>
      <ModalHeader title="" isOverlay/>
      <StyledHeroImage id={destinationImage!}/>
      <ModalContent>
        <ModalBody>
          <VerticalSpacer gap={4}>
            <HeadingTextBlock variant="heading4" align="center" tabletAlign="start">
              {destinationSaved ? `${placeName} is already in your favourite destination list!` : `Do you want to add ${placeName} to your travel preferences?`}
            </HeadingTextBlock>
            <BodyText variant="large" align="center" tabletAlign="start">
              {destinationSaved ? 'You\'re all set to receive tailored recommendations and offers for it' : 'Get tailored recommendations to ensure you never miss an offer in your favourite destinations'}
            </BodyText>
          </VerticalSpacer>
        </ModalBody>
        <ModalFooter>
          <Group direction="vertical" tabletDirection="horizontal-reverse">
            {destinationSaved && (
              <>
                <TextButton kind="primary" onClick={handleSkip}>Ok got it!</TextButton>
                <TextButton kind="tertiary" to="/account/travel-preferences#destinations">Edit preferences</TextButton>
              </>
            )}
            {!destinationSaved && (
              <>
                <TextButton kind="primary" onClick={handleAddDestination}>Add to my destinations</TextButton>
                <TextButton kind="tertiary" onClick={handleSkip}>Skip for now</TextButton>
              </>
            )}
          </Group>
        </ModalFooter>
      </ModalContent>
    </ModalBase>
  )
}

export default AddDestinationToggleModal
