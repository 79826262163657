import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import getOccupantsFromCheckoutCart from 'analytics/snowplow/helpers/getOccupantsFromCheckoutCart'
import { getCategoryAndProductId, getHotelOrTourOffer } from 'analytics/snowplow/helpers/itemCategorisation'
import { checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'
import { getVillaViews } from 'checkout/selectors/view/villa'
import { getHasLuxPlusOrSubscriptionInCart } from 'checkout/selectors/view/luxPlusSubscription'
import { isBedbankOffer } from 'lib/offer/offerTypes'
import { ItemContextMetadata } from 'analytics/snowplow/events'
import sanitizeItemContextMetadata from './sanitizeItemContextMetadata'

function getHotelCheckoutItem(
  state: App.State,
  item: App.Checkout.BundleAndSaveItem | App.Checkout.BedbankHotelItem | App.Checkout.InstantBookingLEHotelItem | App.Checkout.BNBLLEHotelItem | App.Checkout.VillaItem,
  options?: Partial<comluxgroup.Item_1_1_0>,
  metadataOptions?: ItemContextMetadata,
  isMultiCart?: boolean,
) {
  const offerId = item.offerId
  const hotelOrTourOffer = getHotelOrTourOffer(state, offerId)
  const luxPlusMemberPriceApplies = getHasLuxPlusOrSubscriptionInCart(state)
  const offer = hotelOrTourOffer as App.Offer
  const isFlashHotelOrVilla = item.itemType === 'hotel' || item.itemType === 'villa'
  const instantBooking = item.itemType === 'bedbankHotel' || (isFlashHotelOrVilla && item.reservationType === 'instant_booking')
  // bedbanks are instant booking only
  const reservationType = isFlashHotelOrVilla ? item.reservationType : 'instant_booking'
  const { categoryId, productId } = getCategoryAndProductId('hotel', offer)
  const offerLeadPrice = offer?.offerFlightsEnabled && offer?.lowestPriceBundlePackage ? offer?.lowestPriceBundlePackage?.price : offer?.lowestPricePackage?.price
  const geo = offer?.property?.geoData
  let destinationCity = geo?.locality
  let destinationCountry = geo?.country
  let destinationRegion = geo?.administrativeAreaLevel1
  if (!geo && isBedbankOffer(hotelOrTourOffer)) {
    destinationCity = hotelOrTourOffer.property.address.city
    destinationCountry = hotelOrTourOffer.property.address.countryName
    destinationRegion = hotelOrTourOffer.property.address.stateProvinceName
  }

  let propertyFees: number | undefined
  let price: number | undefined
  let memberPrice: number | undefined
  let nonMemberPrice: number | undefined
  let surcharge: number | undefined
  let value: number | undefined
  if (item.itemType === 'villa') {
    const villasView = getVillaViews(state)
    const villaView = villasView.data.find(villaItem => villaItem.item.itemId === item.itemId)
    propertyFees = villaView?.totals.otherFees?.propertyFees ?? undefined
    price = villaView?.totals.price
    surcharge = villaView?.totals.surcharge
    value = villaView?.totals.value
  } else {
    const accommodationView = checkoutAccommodationOfferView(state)
    if (accommodationView.data.length && accommodationView.data[0].itemViews.length) {
      const accommodationViewItem = accommodationView.data[0].itemViews.find(v => v.item.itemId === item.itemId)
      propertyFees = accommodationViewItem?.totals.otherFees?.propertyFees ?? undefined
      price = (luxPlusMemberPriceApplies && item.itemType === 'hotel' && accommodationViewItem?.totals.memberPrice && accommodationViewItem?.totals.memberPrice > 0) ? accommodationViewItem?.totals.memberPrice : accommodationViewItem?.totals.price
      surcharge = accommodationViewItem?.totals.surcharge
      value = accommodationViewItem?.totals.value
      nonMemberPrice = accommodationViewItem?.totals.price
      memberPrice = accommodationViewItem?.totals.memberPrice ? accommodationViewItem?.totals.memberPrice : accommodationViewItem?.totals.price
    }
  }
  const bundleDates = item.itemType === 'bundleAndSave' && item.dates[offerId]
  let duration: number | undefined
  let travelStart: string | undefined
  let travelEnd: string | undefined
  if (bundleDates) {
    duration = bundleDates.duration
    travelStart = bundleDates.checkIn
    travelEnd = bundleDates.checkOut
  } else if (instantBooking) {
    duration = item.duration
    travelStart = item.checkIn
    travelEnd = item.checkOut
  }

  const travellers = getOccupantsFromCheckoutCart(state.checkout.cart.items)

  return comluxgroup.createItem_1_1_0(
    {
      offerId,
      categoryId,
      productId,
      typeId: item.itemType === 'bedbankHotel' ? item.roomId : undefined,
      rateId: item.itemType === 'bedbankHotel' ? item.roomRateId : undefined,
      travelStart,
      travelEnd,
      duration,
      price,
      value,
      numberOfAdults: travellers?.adults,
      numberOfChildren: travellers?.children,
      numberOfInfants: travellers?.infants,
      childrenAges: travellers?.childrenAge,
      offerLeadPrice,
      offerLeadDuration: offer?.lowestPricePackage?.duration ? String(offer?.lowestPricePackage?.duration) : undefined,
      offerLeadUnit: offer?.saleUnit,
      propertyFees,
      surcharge,
      destinationCity,
      destinationCountry,
      destinationRegion,
      reservationType,
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
      ...options,
      metadata: sanitizeItemContextMetadata({
        isMultiCart: isMultiCart ?? state.checkout.cart.isMultiCart,
        memberPrice,
        nonMemberPrice,
        ...metadataOptions,
      }),
    },
  )
}

export default getHotelCheckoutItem
