import React from 'react'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import SearchFormTextField from 'components/SearchV2/Components/SearchFormField/SearchFormTextField'

interface Props {
  isOpen: boolean
  isChipMode?: boolean
  label: string
  placeholder: string
  inputValue: string
  testId?: string
  hasSelectedData?: boolean
  formFieldRef: React.RefObject<HTMLInputElement | HTMLButtonElement>
  selectedItems?: number
  onClick: () => void
  handleSearchTerm?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlerClearSearchTerm?: () => void
}

function CruiseFilterFooter({
  isOpen,
  isChipMode,
  label,
  placeholder,
  inputValue,
  formFieldRef,
  selectedItems,
  onClick,
  handleSearchTerm,
  handlerClearSearchTerm,
  testId,
  hasSelectedData,
}: Props) {
  const isSelected = isOpen || !!selectedItems || hasSelectedData

  return <>
    {!isChipMode && <SearchFormTextField
      data-testid={testId}
      ref={formFieldRef as React.RefObject<HTMLInputElement>}
      label={label}
      placeholder={placeholder}
      value={inputValue}
      displayValue={inputValue}
      onChange={handleSearchTerm}
      onClear={handlerClearSearchTerm}
      onClick={onClick}
    />}

    {isChipMode && <FilterChip
      size="medium"
      onClick={onClick}
      selected={isSelected}
      endIcon={<DropdownChevron open={isOpen}/>}
      ref={formFieldRef as React.RefObject<HTMLButtonElement>}
    >
      {label} {selectedItems ? `(${selectedItems})` : ''}
    </FilterChip>}
  </>
}

export default CruiseFilterFooter
