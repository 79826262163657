import { EmptyArray } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getInsuranceItemsView from 'checkout/selectors/view/getInsuranceItemsView'
import { getLuxLoyaltyInsuranceProductType } from 'luxLoyalty/lib/getLuxLoyaltyInsuranceProductType'

const getCheckoutInsurancePointsCalculationRequests = createSelector(
  (state: App.State) => getInsuranceItemsView(state),
  (insuranceItemsView): Array<App.LuxLoyaltyPointsEarnCalculationRequest> => {
    if (!insuranceItemsView.hasRequiredData) return EmptyArray

    return insuranceItemsView.data.map((itemView) => ({
      price: itemView.totals.price,
      memberPrice: itemView.totals.memberPrice,
      luxLoyaltyProductType: getLuxLoyaltyInsuranceProductType(itemView.item.insuranceType),
      itemId: itemView.item.itemId,
    }))
  },
)

export default getCheckoutInsurancePointsCalculationRequests
