import React, { useRef, RefObject, useCallback, useContext, useMemo, useState, ComponentProps } from 'react'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import CruiseDepartureSelectDesktop from './CruiseDepartureSelectDesktop'
import CruiseDepartureSelectMobile from './CruiseDepartureSelectMobile'
import { getCruiseExtraFilters, getGlobalFacetFilters } from 'lib/cruises/cruiseUtils'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import GeoContext from 'contexts/geoContext'
import { sortBy } from 'lib/array/arrayUtils'
import useGlobalSearchTypeahead from 'hooks/GlobalSearch/useGlobalSearchTypeahead'
import { TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES, TOUR_V2_SEARCH_TYPES } from 'constants/tours'
import useQueryParams from 'hooks/useQueryParams'
import { omitKeys } from 'lib/object/objectUtils'
import { CRUISE_SEARCH_DEPARTURE_REGIONS } from 'constants/config/region'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'

export interface DeparturePortMapped {
  searchItem: App.SearchItem
  isSelected: boolean
  isDisabled: boolean
  order?: number
  regionIdentifier?: string
}

interface Props {
  onChange: (values: App.CruiseGlobalFilters) => void
  dropdownAnchorRef?: RefObject<HTMLElement>
  drawerMode?: boolean
  isChipMode?: boolean
  shouldDisableRegionTabs?: boolean
  dropdownSize?: ComponentProps<typeof DropdownSheet>['size']
  initialFilters?: App.CruiseInitialFilters
  shouldIgnoreFlashOffers?: boolean
}

const SET_SECONDARY_SEARCH_ITEMS = GlobalSearchStateActions.SET_SECONDARY_SEARCH_ITEMS

function CruiseDepartureSelect({
  drawerMode,
  isChipMode,
  onChange,
  dropdownAnchorRef,
  dropdownSize,
  shouldDisableRegionTabs,
  initialFilters,
  shouldIgnoreFlashOffers,
}: Props) {
  const queryParams = useQueryParams()
  const inputRef = useRef<HTMLDivElement>(null)
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRegion, setSelectedRegion] = useState(CRUISE_SEARCH_DEPARTURE_REGIONS[0])
  const cruiseExtraFilters = getCruiseExtraFilters(queryParams)

  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const { geoCountryCode } = useContext(GeoContext)
  const { suggestedSearchItems, secondarySearchItems, searchItems, cruiseLines } = useContext(GlobalSearchStateContext)
  const globalFilters = useContext(GlobalSearchStateContext)
  const facetFilters = getGlobalFacetFilters(globalFilters)

  const [facets] = useCruiseSearchFacets({
    facetTypes: ['departure_ports'],
    ...(initialFilters || {}),
  })

  const [filteredFacets, fetching] = useCruiseSearchFacets({
    facetTypes: ['departure_ports'],
    ...omitKeys(
      ['departureId', 'departureIds', 'departureName', 'departureNames'],
      { ...(initialFilters || {}), ...cruiseExtraFilters, ...facetFilters },
    ),
  })
  useGlobalSearchTypeahead({
    search: searchTerm,
    searchTypes: TOUR_V2_SEARCH_TYPES,
    typeaheadTypes: TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES,
  })

  const checkIsSelected = useCallback((searchItem: App.SearchItem) => {
    return isAllSelected ||
      secondarySearchItems.some(({ value }) => value === searchItem.value)
  }, [isAllSelected, secondarySearchItems])

  const checkIsDisabled = useCallback((searchItem: App.SearchItem) => {
    return !filteredFacets.some(({ value }) => value === searchItem.value)
  }, [filteredFacets])

  const getRegionDeparturePort = useCallback((departurePort: string) => {
    const split = departurePort.split(',')
    const lastName = split[split.length - 1]
    return lastName.trim()
  }, [])

  // returned departure ports mapped to search items with isSelected, isDisabled and order
  const mapDeparturePort = useCallback((departurePort: App.CruiseSearchFacet) => {
    const [shortName, ...info] = departurePort.name.split(', ')
    const departureInfo = info.join(', ')

    const searchItem = {
      searchType: 'departurePort',
      value: departurePort.value as string,
      format: {
        mainText: shortName,
        secondaryText: departureInfo,
      },
    } as App.SearchItem

    const isSelected = checkIsSelected(searchItem)
    const isDisabled = checkIsDisabled(searchItem)
    const country = getRegionDeparturePort(departurePort.name)
    const region = CRUISE_SEARCH_DEPARTURE_REGIONS.find(({ countries }) => countries.includes(country))
    return {
      searchItem,
      isSelected,
      isDisabled,
      order: departurePort.order,
      regionIdentifier: region?.identifier!,
    } as DeparturePortMapped
  }, [checkIsDisabled, checkIsSelected, getRegionDeparturePort])

  // make union of filteredFacets and facets to return the correct current order considering the other filter
  // and filter by region
  const filterFacetsByRegion = useCallback((region: App.CruiseDepartureRegion) => {
    return [...filteredFacets, ...facets].filter((departurePort) => {
      const isOnTabRegion = region.countries.some((country: string) => {
        const lastName = getRegionDeparturePort(departurePort.name)
        return lastName.toLowerCase().includes(country.toLowerCase())
      })

      return (
        isOnTabRegion &&
        !region.placeIds.includes(departurePort.value!)
      )
    })
  }, [facets, filteredFacets, getRegionDeparturePort])

  // return a sort list regions by more results, using only the placeIds to sum how many results each region has
  const regionsByMoreResults = useMemo(() =>
    sortBy(CRUISE_SEARCH_DEPARTURE_REGIONS, (region) =>
      filteredFacets
        .filter(({ value }) => region.placeIds.includes(value!))
        .reduce((acc, { order }) => acc + (order! || 0), 0)
    , 'desc')
  , [filteredFacets])

  // when there are no filters selected on the page, the first tab must be the tab closest to the user's location
  // when there are filters, the first tab must be the tab with the most results
  const regionsWithoutIdentifier = useMemo(() => {
    if (geoCountryCode &&
      !searchItems.length &&
      !cruiseLines.length) {
      const region = CRUISE_SEARCH_DEPARTURE_REGIONS.find(({ countriesCodes }) => countriesCodes.includes(geoCountryCode.toUpperCase()))
      if (region) {
        const regionsWithoutRegion = regionsByMoreResults.filter(({ identifier }) => identifier !== region.identifier)
        return [region, ...regionsWithoutRegion]
      }
    }

    return regionsByMoreResults
  }, [cruiseLines.length, geoCountryCode, regionsByMoreResults, searchItems.length])

  const departurePorts = useMemo(() => {
    if (!searchTerm) {
      return filterFacetsByRegion(selectedRegion)
    }

    let filtered = filteredFacets.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
    if (!filtered.length) {
      filtered = filteredFacets.filter(({ name }) => {
        return suggestedSearchItems?.find((suggestedValue) => {
          return name.includes(suggestedValue?.format?.secondaryText!)
        })
      })
    }
    return filtered.slice(0, 100)
  }, [searchTerm, filteredFacets, filterFacetsByRegion, selectedRegion, suggestedSearchItems])

  // return a list with departure ports grouped by the first part of the name
  // e.g 'Miami, Florida' and 'Miami, Florida, USA' will be grouped as 'Miami'
  const groupedDeparturePorts = useMemo(() => {
    return departurePorts.reduce((acc: Array<App.CruiseSearchFacet>, departurePort) => {
      const departureName = departurePort.name.split(', ')[0]
      const existsInList = acc.some(({ name }) => departureName.includes(name.split(', ')[0]))
      if (!existsInList) {
        acc.push(departurePort)
      }
      return acc
    }, [])
  }, [departurePorts])

  // return a list with departure ports sorted by order and disabled ports at the end
  const searchDeparturePorts = useMemo(() =>
    sortBy(groupedDeparturePorts.map(mapDeparturePort),
      (departurePort) => {
        if (departurePort.isDisabled) {
          return -1
        }

        return departurePort.order
      }, 'desc')
  , [groupedDeparturePorts, mapDeparturePort])

  const handleSearchTerm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [setSearchTerm])

  const handleOnChange = useCallback((secondarySearchItems: Array<App.SearchItem>) => {
    searchDispatch({
      type: SET_SECONDARY_SEARCH_ITEMS,
      secondarySearchItems,
    })
    setIsAllSelected(false)
  }, [searchDispatch, setIsAllSelected])

  return <div ref={inputRef}>
    <CSSBreakpoint max="mobile">
      <CruiseDepartureSelectMobile
        onChange={onChange}
        regions={regionsWithoutIdentifier}
        setIsAllSelected={setIsAllSelected}
        isAllSelected={isAllSelected}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        handleSearchTerm={handleSearchTerm}
        handleOnChange={handleOnChange}
        fetching={fetching}
        searchDeparturePorts={searchDeparturePorts}
        drawerMode={drawerMode}
        setSelectedRegion={setSelectedRegion}
        filterFacetsByRegion={filterFacetsByRegion}
        mapDeparturePort={mapDeparturePort}
        isChipMode={isChipMode}
        initialFilters={initialFilters}
        shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
      />
    </CSSBreakpoint>

    <CSSBreakpoint min="tablet">
      {drawerMode && <CruiseDepartureSelectMobile
        onChange={onChange}
        regions={regionsWithoutIdentifier}
        setIsAllSelected={setIsAllSelected}
        isAllSelected={isAllSelected}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        handleSearchTerm={handleSearchTerm}
        handleOnChange={handleOnChange}
        fetching={fetching}
        searchDeparturePorts={searchDeparturePorts}
        drawerMode={drawerMode}
        setSelectedRegion={setSelectedRegion}
        filterFacetsByRegion={filterFacetsByRegion}
        mapDeparturePort={mapDeparturePort}
        isChipMode={isChipMode}
        initialFilters={initialFilters}
        shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
      />}

      {!drawerMode && <CruiseDepartureSelectDesktop
        regions={regionsWithoutIdentifier}
        dropdownAnchorRef={dropdownAnchorRef || inputRef}
        onChange={onChange}
        setIsAllSelected={setIsAllSelected}
        isAllSelected={isAllSelected}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        setSelectedRegion={setSelectedRegion}
        selectedRegion={selectedRegion}
        handleSearchTerm={handleSearchTerm}
        handleOnChange={handleOnChange}
        searchDeparturePorts={searchDeparturePorts}
        isChipMode={isChipMode}
        fetching={fetching}
        dropdownSize={dropdownSize}
        shouldDisableRegionTabs={shouldDisableRegionTabs}
        initialFilters={initialFilters}
        shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
      />}
    </CSSBreakpoint>
  </div>
}

export default CruiseDepartureSelect
