import config from './config'
import { EnabledFilters } from 'components/Search/type'

export const AGE_OPTIONS = [
  {
    value: 0,
    label: '0-1 year',
  }, {
    value: 1,
    label: '1 year',
  }, {
    value: 2,
    label: '2 years',
  }, {
    value: 3,
    label: '3 years',
  }, {
    value: 4,
    label: '4 years',
  }, {
    value: 5,
    label: '5 years',
  }, {
    value: 6,
    label: '6 years',
  }, {
    value: 7,
    label: '7 years',
  }, {
    value: 8,
    label: '8 years',
  }, {
    value: 9,
    label: '9 years',
  }, {
    value: 10,
    label: '10 years',
  }, {
    value: 11,
    label: '11 years',
  }, {
    value: 12,
    label: '12 years',
  }, {
    value: 13,
    label: '13 years',
  }, {
    value: 14,
    label: '14 years',
  }, {
    value: 15,
    label: '15 years',
  }, {
    value: 16,
    label: '16 years',
  }, {
    value: 17,
    label: '17 years',
  },
]

// queryNullChildren is a placeholder for no child
export const queryNullChildren = 'none'

// queryChildrenDelimiter is delineates the boundary between children age lists
export const queryChildrenDelimiter = ';'

// SEARCH_STORAGE_KEYS is the browser local storage keys under which
// recent searches are saved. The keys are versioned by appending an
// incrementing integer to mark backward incompatible changes.
export const SEARCH_STORAGE_KEYS = {
  TRAVELLERS: 'searchTravellersHistory.2',
  LAST_DATES: 'searchLastDatesHistory.1',
  LOCATIONS: 'searchLocationsHistory.3',
  LOCATION: 'searchLocationHistory.1',
  FLEXIBLE_OPTIONS: 'searchFlexibleOptions.1',
  RECENT_SEARCHES: 'searchRecentSearches.1',
  RECENT_FILTERS: 'offerListRecentFilters',

  /* Leaving this here commented out, so we have a record of it, because some people
     will still have data in their localstorage with this key.
     If we ever reinstate recent dates, increment the number to avoid conflicts. */
  // DATES: 'searchDatesHistory.2',
}

export const URGENCY_UNAVAILABILITY_THRESHOLD = 0.8

export const RECENT_SEARCHES_LIMIT = 5

export const LOCATION_SEARCH_INPUT_TITLE_LABEL = 'Where'

export const LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL = 'Search by destination or hotel'
export const HOMES_AND_VILLAS_LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL = 'Search by destination or property'

export const LOCATION_SEARCH_ERROR_MESSAGE = config.searchFormSection?.escapeSynonym ?
  `Check your spelling, try a new destination or search hotels for your next ${config.searchFormSection.escapeSynonym}.` :
  'Check your spelling, try a new destination or search hotels.'

export const LOCATION_SEARCH_ERROR_TOOLTIP_MESSAGE = 'Please enter a destination or hotel'

export const HOMES_AND_VILLAS_TYPEAHEAD_TYPES: Array<App.SearchPlaceType> = [
  'province_state',
  'neighborhood',
  'city',
  'high_level_region',
  'country',
  'multi_city_vicinity',
  'continent',
  'point_of_interest',
  'colloquial_area',
  'le_property_rental',
]

export const HOTEL_SEARCH_TYPEAHEAD_TYPES: Array<App.SearchPlaceType> = [
  'province_state',
  'neighborhood',
  'city',
  'point_of_interest',
  'high_level_region',
  'country',
  'multi_city_vicinity',
  'continent',
  'colloquial_area',
  'airport',
  'le_property',
  'le_property_unique_stays',
  'le_property_rental',
  'bedbank_property',
  'bedbank_property_unique_stays',
]

export enum SEARCH_VERTICALS {
  HOTELS = 'hotels',
  FLIGHTS = 'flights',
  TOURS = 'tours',
  EXPERIENCES = 'experiences',
  CRUISES = 'cruises',
  CARHIRE = 'car-hire',
  HOMES_AND_VILLAS = 'homes-and-villas',
}

export const DESTINATION_MODEL_ELIGIBLE_REGIONS = ['AU', 'NZ', 'US', 'GB', 'SG']

export const KNOWN_SEARCH_VERTICALS: Set<string> = new Set((Object.values(SEARCH_VERTICALS) as Array<string>).filter(v => {
  return v !== SEARCH_VERTICALS.HOMES_AND_VILLAS || (config.RENTALS_SEARCH_ENABLED)
}))

export const ANYWHERE_PLACE_ID: string = 'le_d41d8cd98f00b204e9800998ecf8427e'
export const ANYWHERE_NAME: string = 'Anywhere'
export const ANYWHERE_SEARCH_ITEM: App.SearchDestination = {
  searchType: 'destination',
  value: ANYWHERE_PLACE_ID,
  format: {
    mainText: ANYWHERE_NAME,
  },
}

export const TYPE_PAGE_LIMIT = 65

export enum DATE_SEARCH_OPTION_IDS {
  FLEXIBLE = 'search-flexible-dates',
  SPECIFIC = 'search-specific-dates',
  ANYTIME = 'search-anytime-dates',
}

export type FLEXIBLE_DURATION_RANGE = DEFAULT_FLEXIBLE_DURATION_RANGE | TOUR_FLEXIBLE_DURATION_RANGE | CRUISE_FLEXIBLE_DURATION_RANGE

export enum DEFAULT_FLEXIBLE_DURATION_RANGE {
  ANY_DURATION = '1-30',
  SHORT_STAY = '1-3',
  MEDIUM_STAY = '4-7',
  LONG_STAY = '8-30',
  EMPTY = '',
}

export enum TOUR_FLEXIBLE_DURATION_RANGE {
  ANY_DURATION = '1-30',
  SHORT_STAY = '1-7',
  MEDIUM_STAY = '8-14',
  LONG_STAY = '15-30',
}

export enum CRUISE_FLEXIBLE_DURATION_RANGE {
  ANY_DURATION = '',
  SHORT_STAY = '1-4',
  MEDIUM_STAY = '5-8',
  LONG_STAY = '9-14',
  VERY_LONG_STAY = '15',
}

export const scopedHolidayTypes = [
  'romantic', 'family friendly', 'group',
]

export const HOTEL_TRENDING_DESTINATION_LIMIT = 30

export const CUSTOMER_RATING_GTE: Record<string, { value: number; label: string; }> = {
  GOOD: {
    value: 7,
    label: 'Good: 7.0+',
  },
  VERY_GOOD: {
    value: 8,
    label: 'Very good: 8.0+',
  },
  EXCEPTIONAL: {
    value: 9,
    label: 'Exceptional: 9.0+',
  },
  ANY_RATING: {
    value: 0,
    label: 'Any rating',
  },
}

export const CUSTOMER_RATING_GTE_BY_VALUE: Record<string, { value: number; label: string; }> = {
  7: {
    value: 7,
    label: 'Good: 7.0+',
  },
  8: {
    value: 8,
    label: 'Very good: 8.0+',
  },
  9: {
    value: 9,
    label: 'Exceptional: 9.0+',
  },
}

export const CUSTOMER_RATING_TO_KEY: Record<string, string> = {
  7: 'Good',
  8: 'Very Good',
  9: 'Exceptional',
}

export const HOMEPAGE_MAX_MAP_PINS = 20

export const NON_COUNTED_FILTERS: Record<string, Array<string>> = {
  holidayTypes: ['!Cruises'],
  locations: [],
  amenities: [],
}

export const BALI_LAT_LNG = {
  lat: -8.3405,
  lng: 115.0920,
}

export const HOTELS_ENABLED_FILTERS: EnabledFilters = {
  inclusions: true,
  amenities: true,
  customerRating: true,
  priceFilter: config.ENABLE_HOTEL_SEARCH_PAGE_PRICE_FILTER,
  holidayTypes: true,
  locations: true,
  offerTypes: config.ENABLE_VILLA_RENTALS,
  popularFilters: true,
  luxPlusFeatures: false,
  agentHubFeatures: true,
}

export const LIMITED_TIME_LUX_EXCLUSIVE = 'Limited Time LUX Exclusive'

export const ALL_LUX_PLUS_RECENT_FILTER = 'All LuxPlus+ offers'

export const MAP_TILE_PADDING = 8
