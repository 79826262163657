import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import LineSightseeingIcon from 'components/Luxkit/Icons/line/LineSightseeingIcon'
import React, { ReactElement } from 'react'

interface Props {
  description: ReactElement | string
  isSpoof?: boolean
}

function SearchTileHighlightBanner({ description, isSpoof = false }: Props) {
  return <MessageBanner
    kind={isSpoof ? 'informational-primary' : 'promotional-primary'}
    icon={<LineSightseeingIcon />}
    description={<strong>{description}</strong>}
  />
}

export default SearchTileHighlightBanner
