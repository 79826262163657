export const SORT_OPTION_NEW_HOME_PAGE = {
  label: 'Recommended',
  value: 'home',
} as const

export const SORT_OPTION_HOME_PAGE = {
  label: 'Recommended',
  value: 'homepage',
} as const

export const SORT_OPTION_RECOMMENDED = {
  label: 'Recommended',
  value: 'recommended',
} as const

export const SORT_OPTION_NEWEST = {
  label: 'Latest',
  value: 'newest',
} as const

export const SORT_OPTION_POPULARITY = {
  label: 'Bestseller',
  value: 'popularity',
} as const

export const SORT_OPTION_PRICE_ASC = {
  label: 'Price (Lowest)',
  value: 'price.asc',
} as const

export const SORT_OPTION_PRICE_DESC = {
  label: 'Price (Highest)',
  value: 'price.desc',
} as const

export const SORT_OPTION_DISTANCE = {
  label: 'Distance',
  value: 'distance',
} as const

export const SORT_OPTION_VALUE = {
  label: 'Best Value',
  value: 'value',
} as const

export const SORT_OPTION_CUSTOMER_RATING = {
  label: 'Customer Rating (Highest)',
  value: 'customerRating',
} as const

export const SORT_OPTION_INCLUSIONS = {
  label: 'Most Inclusions',
  value: 'inclusions',
} as const

export const SORT_OPTION_SALES = {
  label: 'Trending',
  value: 'sales',
} as const

export const SORT_DEPARTURE_SOON = {
  label: 'Depart soon',
  value: 'departure_date.asc',
} as const

export const SORT_NEWEST = {
  label: 'Newest',
  value: 'updated_at.desc',
} as const

export type OfferListSortOption = typeof SORT_OPTION_RECOMMENDED
  | typeof SORT_OPTION_NEW_HOME_PAGE
  | typeof SORT_OPTION_HOME_PAGE
  | typeof SORT_OPTION_NEWEST
  | typeof SORT_OPTION_POPULARITY
  | typeof SORT_OPTION_PRICE_ASC
  | typeof SORT_OPTION_PRICE_DESC
  | typeof SORT_OPTION_DISTANCE
  | typeof SORT_OPTION_VALUE
  | typeof SORT_OPTION_CUSTOMER_RATING
  | typeof SORT_OPTION_SALES
  | typeof TOURS_PRICE_LOWEST
  | typeof TOURS_PRICE_HIGHEST
  | typeof TOURS_DURATION_SHORTEST
  | typeof TOURS_DURATION_LONGEST
  | typeof TOURS_NUMBER_OF_PLACES_LOWEST
  | typeof TOURS_NUMBER_OF_PLACES_HIGHEST
  | typeof SORT_DEPARTURE_SOON
  | typeof SORT_NEWEST

export const frontPageSortByTypes: Array<OfferListSortOption> = [
  SORT_OPTION_RECOMMENDED,
  SORT_OPTION_VALUE,
  SORT_OPTION_PRICE_ASC,
  SORT_OPTION_PRICE_DESC,
  SORT_OPTION_CUSTOMER_RATING,
]

export const searchSortByTypes: Array<OfferListSortOption> = [
  SORT_OPTION_RECOMMENDED,
  SORT_OPTION_PRICE_ASC,
  SORT_OPTION_PRICE_DESC,
  SORT_OPTION_VALUE,
]

export const landmarkSearchSortByTypes: Array<OfferListSortOption> = [
  SORT_OPTION_RECOMMENDED,
  SORT_OPTION_PRICE_ASC,
  SORT_OPTION_PRICE_DESC,
  SORT_OPTION_DISTANCE,
]

export const TOURS_PRICE_LOWEST = {
  label: 'Price (Lowest)',
  value: 'price.asc',
} as const

export const TOURS_PRICE_HIGHEST = {
  label: 'Price (Highest)',
  value: 'price.desc',
} as const

export const TOURS_NUMBER_OF_PLACES_LOWEST = {
  label: 'Number of places visited (Lowest)',
  value: 'numberOfPlacesVisited.asc',
} as const

export const TOURS_NUMBER_OF_PLACES_HIGHEST = {
  label: 'Number of places visited (Highest)',
  value: 'numberOfPlacesVisited.desc',
} as const

export const TOURS_DURATION_SHORTEST = {
  label: 'Duration (Shortest)',
  value: 'tourLength.asc',
} as const

export const TOURS_DURATION_LONGEST = {
  label: 'Duration (Longest)',
  value: 'tourLength.desc',
} as const

export const tourSortByTypes: Array<OfferListSortOption> = [
  SORT_OPTION_RECOMMENDED,
  TOURS_PRICE_LOWEST,
  TOURS_PRICE_HIGHEST,
  SORT_OPTION_VALUE,
  TOURS_NUMBER_OF_PLACES_LOWEST,
  TOURS_NUMBER_OF_PLACES_HIGHEST,
  TOURS_DURATION_SHORTEST,
  TOURS_DURATION_LONGEST,
]

export const homesAndVillasSortByTypes: Array<OfferListSortOption> = [
  SORT_OPTION_RECOMMENDED,
  SORT_OPTION_PRICE_ASC,
  SORT_OPTION_PRICE_DESC,
  SORT_OPTION_VALUE,
]
