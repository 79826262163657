import Caption from 'components/Luxkit/Typography/Caption'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Badge = styled(Caption)`
  padding: ${rem(4)} ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.S};

  &.tier-bronze {
    background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
  }

  &.tier-silver {
    background-color: ${props => props.theme.palette.product.luxLoyalty.silver.normalBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
  }

  &.tier-gold {
    background-color: ${props => props.theme.palette.product.luxLoyalty.gold.normalBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
  }

  &.tier-platinum {
    background-color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast};
  }
`

interface Props {
  tier: App.LuxLoyaltyTier
}

function LuxLoyaltyTierBadge({
  tier,
}: Props) {
  return <Badge className={`tier-${tier}`} variant="medium" format="uppercase" colour="neutral-eight">
    {getLuxLoyaltyTierDisplayName(tier)}
  </Badge>
}

export default LuxLoyaltyTierBadge
