import config from 'constants/config'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { createSelector } from 'reselect'
import isDomesticTrip from '../view/isDomesticTrip'
import { isFlightItem, isBookingProtectionItem, isInsuranceBookingProtectionItem, isExperienceItem } from 'lib/checkout/checkoutUtils'

const cartOnlyHasFlightsOrBookingProtection = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean =>
    items.length > 0 &&
  items.every(item => isFlightItem(item) || isBookingProtectionItem(item) || isInsuranceBookingProtectionItem(item)),
)

const cartOnlyHasExperiencesOrBookingProtection = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.length > 0 && items.every(item => isExperienceItem(item) || isBookingProtectionItem(item) || isInsuranceBookingProtectionItem(item)),
)

const isInsuranceBookingProtectionEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => isDomesticTrip(state),
  (state: App.State) => cartOnlyHasFlightsOrBookingProtection(state),
  (state: App.State) => cartOnlyHasExperiencesOrBookingProtection(state),
  (currentRegionCode, isDomesticTrip, cartOnlyHasFlightsOrBookingProtection, cartOnlyHasExperiencesOrBookingProtection) => {
    const isEnabled = isEnabledForFeature(config.INSURANCE_BOOKING_PROTECTION_ENABLED_REGIONS, currentRegionCode) && config.INSURANCE_BOOKING_PROTECTION_ENABLED
    if (!isEnabled) return false

    const isSGAndInternationalTrip = currentRegionCode === 'SG' && !isDomesticTrip
    if (isSGAndInternationalTrip && !cartOnlyHasFlightsOrBookingProtection && !cartOnlyHasExperiencesOrBookingProtection) return false

    return true
  },
)

export default isInsuranceBookingProtectionEnabled
