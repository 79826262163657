import {
  autoPlacement,
  Placement,
  Strategy,
  autoUpdate,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react-dom'
import useResizeObserver from '@react-hook/resize-observer'
import { Mask } from '@reactour/mask'
import equal from 'fast-deep-equal'
import { rem } from 'polished'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import ScrollLock from 'components/Common/ScrollLock/ScrollLock'
import IconButton from 'components/Luxkit/Button/IconButton'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import useHasMounted from 'hooks/useHasMounted'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import useStateWithRef from 'hooks/useStateWithRef'
import useToggle from 'hooks/useToggle'
import noop from 'lib/function/noop'
import zIndex from 'styles/tools/z-index'
import { createPortal } from 'react-dom'

const DismissButton = styled(IconButton)``

const DEFAULT_SKIP_LABEL = 'Skip'
const PADDING_PX = 20
const OFFSET_PX = 4
const TOP_ARROW_WIDTH = 10

const Container = styled(Group)`
  position: relative;
  z-index: ${zIndex.menu};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  box-shadow: ${props => props.theme.shadow.bottom.large};
  padding: ${rem(PADDING_PX)};
  width: ${rem(335)};

  ${DismissButton} {
    position: absolute;
    top: ${rem(2)};
    right: ${rem(2)};
  }

  &.flatShadow {
    box-shadow: ${props => props.theme.shadow.flat.large};
  }
`

const TopArrow = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  background: transparent;
  border-style: solid;
  border-top-width: 0;
  border-right-width: ${rem(TOP_ARROW_WIDTH)};
  border-bottom-width: ${rem(TOP_ARROW_WIDTH)};
  border-left-width: ${rem(TOP_ARROW_WIDTH)};
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: ${props => props.theme.palette.neutral.default.eight};
  border-left-color: transparent;
  z-index: 100001;
`

/**
 * For an SVG `rect` element, border radius is specified with the `rx`/`ry` attributes.
 * But these don't behave the same as `border-radius` in CSS, when the radius is larger
 * than the width/height of the rectangle. In this case, this function clamps the radius
 * to the maximum possible value that will look the same as the CSS radius.
 */
function calculateSafeRectBorderRadius(
  width: number,
  height: number,
  borderRadius: number,
) {
  const maxRadius = Math.min(width, height) / 2
  return Math.min(borderRadius, maxRadius)
}

const Content = Group
const Header = Group
const Buttons = Group
const CaptionContainer = styled.div`
  min-height: 16px;
  min-width: 16px;
`

type RectResult = React.ComponentProps<typeof Mask>['sizes']
function convertRect(rect: DOMRect): RectResult {
  return {
    x: rect.top,
    y: rect.left,
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    right: rect.left + rect.width,
    bottom: rect.top + rect.height,
  }
}

export type OnboardingTooltipMessage = {
  title: string
  content: string
  nextLabel?: string
}

export interface OnboardingTooltipProps {
  isOpen: boolean
  messages: Array<OnboardingTooltipMessage>
  onDismiss?: () => void
  placement?: Placement
  position?: Strategy
  anchorRef: React.RefObject<HTMLElement | null>
  skipLabel?: string
  hideDismiss?: boolean
  backgroundOpacity?: number
  isScrollLocked?: boolean
  autoDismissTimeout?: number
  showTopArrow?: boolean
  className?: string
}

function OnboardingTooltip({
  messages,
  onDismiss = noop,
  placement = 'bottom',
  position = 'fixed',
  isOpen,
  anchorRef,
  skipLabel = DEFAULT_SKIP_LABEL,
  hideDismiss = false,
  backgroundOpacity = 0.3,
  isScrollLocked = true,
  autoDismissTimeout,
  showTopArrow = false,
  className,
}: OnboardingTooltipProps) {
  const tooltipRef = useRef<HTMLDivElement | null>(null)

  const [viewIndex, setViewIndex] = useState(0)
  const hasMounted = useHasMounted()
  const { value: hasDismissed, on: dismissTooltip } = useToggle()
  const isTooltipOpen = isOpen && !hasDismissed && hasMounted

  const onNext = useCallback(() => {
    setViewIndex((i) => i + 1)
  }, [])

  const { x, y, refs, update } = useFloating({
    placement,
    strategy: position,
    middleware: [
      shift({ padding: PADDING_PX }),
      offset(OFFSET_PX),
      autoPlacement({
        rootBoundary: 'viewport',
        autoAlignment: true,
        allowedPlacements: ['bottom', 'top'],
      }),
    ],
  })

  useIsomorphicLayoutEffect(() => {
    if (isTooltipOpen) {
      refs.setReference(anchorRef.current)
      refs.setFloating(tooltipRef.current)

      if (anchorRef.current && tooltipRef.current) {
        return autoUpdate(anchorRef.current, anchorRef.current, update, {
          ancestorScroll: true,
          elementResize: true,
        })
      }
    }
  }, [isTooltipOpen, tooltipRef, anchorRef, refs])

  const dismiss = useCallback(() => {
    dismissTooltip()
    onDismiss()
  }, [onDismiss, dismissTooltip])

  const [anchorRect, setAnchorRect, anchorRectRef] =
    useStateWithRef<RectResult | null>(null)
  const [anchorBorderRadius, setAnchorBorderRadius] = useState(0)
  const topArrowRef = useRef<HTMLDivElement | null>(null)
  const updateAnchorPosition = useCallback(() => {
    if (anchorRef.current) {
      const rect = convertRect(anchorRef.current.getBoundingClientRect())
      if (!equal(rect, anchorRectRef.current)) {
        setAnchorRect(rect)
        const anchorBorderRadius = parseFloat(
          window.getComputedStyle(anchorRef.current).borderRadius,
        )
        setAnchorBorderRadius(
          calculateSafeRectBorderRadius(
            rect.width,
            rect.height,
            anchorBorderRadius,
          ),
        )
      }
      if (topArrowRef.current) {
        topArrowRef.current.style.left = `${rem(rect.width / 2)}`
        topArrowRef.current.style.top = `${rem(
          rect.height - TOP_ARROW_WIDTH / 2,
        )}`
      }
    }
  }, [anchorRef, anchorRectRef, setAnchorRect, setAnchorBorderRadius])

  useIsomorphicLayoutEffect(() => {
    updateAnchorPosition()
  }, [anchorRef.current])
  useResizeObserver(anchorRef?.current ?? null, updateAnchorPosition)
  useEffect(() => {
    window.addEventListener('resize', updateAnchorPosition)
    window.addEventListener('scroll', updateAnchorPosition)

    return () => {
      window.removeEventListener('resize', updateAnchorPosition)
      window.removeEventListener('scroll', updateAnchorPosition)
    }
  }, [updateAnchorPosition])

  useEffect(() => {
    if (autoDismissTimeout && isTooltipOpen) {
      const timeout = window.setTimeout(dismiss, autoDismissTimeout)
      return () => {
        window.clearTimeout(timeout)
      }
    }
  }, [autoDismissTimeout, dismiss, isTooltipOpen])

  if (!isTooltipOpen || typeof document === 'undefined') {
    return null
  }

  return (
    createPortal(<>
      {isTooltipOpen && anchorRect && (
        <Mask
          sizes={anchorRect}
          onClick={dismiss}
          padding={0}
          styles={{
            maskArea: (base) => ({ ...base, rx: anchorBorderRadius }),
            maskWrapper: (base) => ({ ...base, opacity: backgroundOpacity }),
          }}
        />
      )}
      {isTooltipOpen && anchorRect && showTopArrow && (
        <TopArrow ref={topArrowRef} />
      )}
      <ScrollLock
        isActive={isScrollLocked && !!(isTooltipOpen && anchorRect)}
      />
      <div
        style={{
          position,
          top: y ?? 0,
          left: x ?? 0,
          // z-index must be more than 99999 to appear over the mask
          zIndex: 100000,
        }}
        ref={tooltipRef}
        role="tooltip"
      >
        <Container
          direction="vertical"
          gap={16}
          verticalAlign="space-between"
          className={className}
        >
          <Content direction="vertical" gap={8}>
            {(messages.length > 1 || !hideDismiss) && (
              <Header direction="horizontal" horizontalAlign="space-between">
                <CaptionContainer>
                  {messages.length > 1 && (
                    <Caption variant="large" colour="neutral-two">
                      {viewIndex + 1}/{messages.length}
                    </Caption>
                  )}
                </CaptionContainer>
                <div>
                  {!hideDismiss && (
                    <DismissButton
                      size="large"
                      kind="tertiary"
                      onClick={dismiss}
                    >
                      <LineTimesIcon size="XS" />
                    </DismissButton>
                  )}
                </div>
              </Header>
            )}
            <Heading variant="heading5">{messages[viewIndex].title}</Heading>
            <BodyText variant="medium">{messages[viewIndex].content}</BodyText>
          </Content>
          <Buttons direction="horizontal" horizontalAlign="space-between">
            <div>
              {viewIndex !== messages.length - 1 && (
                <TextButton kind="secondary" onClick={onNext}>
                  Next
                </TextButton>
              )}
            </div>
            <div>
              {skipLabel.length > 0 && (
                <TextButton horizontalOutdent="end" kind="tertiary" onClick={dismiss}>
                  {skipLabel}
                </TextButton>
              )}
            </div>
          </Buttons>
        </Container>
      </div>
    </>, document.body)
  )
}

export default OnboardingTooltip
