import { useAppSelector } from 'hooks/reduxHooks'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { getInclusionsViewableLuxPlusTier } from 'selectors/luxPlusSelectors'

interface Props {
  departure?: App.CruiseDepartureDetails
  luxPlusInclusionsByTier?: App.LuxPlusInclusionsByTier
}

export function useCruiseOfferInclusions({ departure, luxPlusInclusionsByTier } : Props): {
  inclusionDetails?: App.Cruises.OfferInclusionDetails,
  standardInclusions: Array<App.PackageInclusion>,
  luxPlusInclusions: Array<App.PackageInclusion>,
} {
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const inclusionsViewableLuxPlusTier = useAppSelector(getInclusionsViewableLuxPlusTier)

  const inclusionsByTier = departure?.luxPlusInclusionsByTier || luxPlusInclusionsByTier
  const luxPlusInclusions = inclusionsByTier?.[inclusionsViewableLuxPlusTier] || []

  return {
    inclusionDetails: departure?.inclusionDetails,
    luxPlusInclusions: luxPlusEnabled ? luxPlusInclusions : [],
    standardInclusions: departure?.standardInclusions || [],
  }
}
