import React, { useCallback, useMemo } from 'react'
import Carousel from 'components/Luxkit/Carousel/Carousel'
import { decodeSearchParams, encodeSearchParams } from 'lib/search/searchUtils'
import { replaceWithRegion } from 'actions/NavigationActions'
import { useAppDispatch } from 'hooks/reduxHooks'
import ToggleOption from 'components/Luxkit/ToggleOption'

interface Props {
  filters: App.OfferListFilters
  search: string,
}

const searchSortByTypes: Array<{label: string, value: string}> = [
  {
    label: 'Recommended',
    value: 'recommended',
  },
  {
    label: 'Lowest price first',
    value: 'price.asc',
  },
  {
    label: 'Highest price first',
    value: 'price.desc',
  },
  {
    label: 'Best value',
    value: 'value',
  },
]

function DestinationProductPageSortByCarousel({ filters, search }: Props) {
  const decodedSearchParams = useMemo(() => decodeSearchParams(search), [search])
  const dispatch = useAppDispatch()

  const activeSortBy = filters.sortBy ?? 'recommended'

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.dataset.value as App.OfferListSortByOptions

    const queryParams = encodeSearchParams({
      ...decodedSearchParams,
      filters: {
        ...filters,
        sortBy: value,
      },
    }).toString()

    dispatch(replaceWithRegion('/search', queryParams))
  }, [decodedSearchParams, dispatch, filters])

  return (
    <Carousel gap={0} tabletWidth="25%" width="150px">
      {searchSortByTypes.map((searchSortByType, index) => (
        <ToggleOption
          key={index}
          isSelected={activeSortBy === searchSortByType.value}
          title={searchSortByType.label}
          data-value={searchSortByType.value}
          onClick={handleClick}
        />
      ))}
    </Carousel>
  )
}

export default DestinationProductPageSortByCarousel
