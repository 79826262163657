import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import getOccupantsFromCheckoutCart from 'analytics/snowplow/helpers/getOccupantsFromCheckoutCart'
import getInsuranceItemsView from 'checkout/selectors/view/getInsuranceItemsView'
import { selectShouldUseInsuranceMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'
import moment from 'moment'

function getInsuranceCheckoutItems(state: App.State, item: App.Checkout.InsuranceItem) {
  const insuranceView = getInsuranceItemsView(state)
  const discountedInsurance = selectShouldUseInsuranceMemberPrice(state)
  const travellers = getOccupantsFromCheckoutCart(state.checkout.cart.items)
  return insuranceView.data.map(insurance => {
    const travelStart = insurance.startDate
    const travelEnd = insurance.endDate
    const price = discountedInsurance ? insurance.totals.memberPrice : insurance.totals.price
    const memberPrice = insurance.totals.memberPrice
    const isInsuranceBookingProtection = item.insuranceType === 'cfmr'
    return comluxgroup.createItem_1_1_0(
      {
        offerId: item.productId || 'insurance',
        categoryId: 'insurance',
        productId: isInsuranceBookingProtection ? 'cfmr' : 'insurance',
        itemId: item.itemId,
        currency: state.geo.currentCurrency,
        travelStart,
        travelEnd,
        numberOfAdults: travellers?.adults,
        numberOfChildren: travellers?.children,
        numberOfInfants: travellers?.infants,
        childrenAges: travellers?.childrenAge,
        duration: travelStart && travelEnd ? moment(travelEnd).diff(travelStart, 'days') : undefined,
        price,
        destinationCountry: insurance.destinationCountries.join(','),
        metadata: price ? JSON.stringify({ memberPrice, nonMemberPrice: insurance.totals.price }) : undefined,
      },
    ) })
}

export default getInsuranceCheckoutItems
