import { definitions } from '@luxuryescapes/contract-trip'
import { Moment } from 'moment'

import {
  GetTripsResponse,
  GetTripResponse,
  GetTripMetadataResponse,
  RefreshTripResponse,
  DeleteTripResponse,
  UpdateTripRequest,
  UpdateTripResponse,
  CreateTripRequest,
  CreateTripResponse,
  GetTripCollaboratorsResponse,
  SendInviteResponse,
  SendInviteRequest,
  AcceptInviteResponse,
  EditCollaboratorResponse,
  EditCollaboratorRequest,
  RemoveCollaboratorResponse,
  DeleteInviteResponse,
  GetCuratedTripResponse,
  GetCuratedTripSummaryResponse,
  PublishTripRequest,
  PublishTripResponse,
  UnpublishTripResponse,
  UnpublishTripRequest,
  SubmitForApprovalResponse,
  SubmitForApprovalRequest,
  GetCuratedTripMetadataResponse,
  CopyTripTemplateResponse,
  CopyTripTemplateRequest,
  GetTripCuratorResponse,
  UpdateReviewResponse,
  UpdateReviewRequest,
  EditInviteResponse,
  EditInviteRequest,
  RecordTripViewResponse,
  GetLowStockTripItemsResponse,
  GetTemplateValidationResponse,
  UploadTripImageRequest,
  UploadTripImageResponse,
} from './types'
import { baseTemplatesUri, baseUri, getOptions } from './utils'

import request from 'api/requestUtils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { SYDNEY_PLACE_INFO } from 'tripPlanner/config'
import { TravellerRoom } from 'tripPlanner/types/common'

export function getTrip(
  tripId: string,
  accessToken?: string,
): Promise<definitions['fullTrip']> {
  const uri = `${baseUri}/${tripId}`
  return request
    .get<GetTripResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function getTripMetadata(
  tripId: string,
  accessToken?: string,
): Promise<definitions['tripMetadata']> {
  const uri = `${baseUri}/${tripId}/metadata`
  return request
    .get<GetTripMetadataResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function refreshTripOrders(
  tripId: string,
): Promise<definitions['refreshedTrip']> {
  const uri = `${baseUri}/${tripId}/refresh`
  return request
    .post<RefreshTripResponse, undefined>(uri, undefined, getOptions())
    .then((response) => response.result)
}

export function recordTripView(tripId: string): Promise<null> {
  const uri = `${baseUri}/${tripId}/record-view`
  return request
    .post<RecordTripViewResponse, undefined>(uri, undefined, getOptions())
    .then((response) => response.result)
}

export function getTrips(
  accessToken?: string,
): Promise<Array<definitions['basicTrip']>> {
  return request
    .get<GetTripsResponse>(baseUri, getOptions(accessToken))
    .then((response) => response.result)
}

export function getTripCollaborators(
  tripId: string,
  accessToken?: string,
): Promise<definitions['tripCollaborators']> {
  const uri = `${baseUri}/${tripId}/collaborators`
  return request
    .get<GetTripCollaboratorsResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function sendTripInvite(
  {
    tripId,
    email,
    role,
  }: {
    tripId: string
    email: string
    role: SendInviteRequest['role']
  },
  accessToken?: string,
): Promise<null> {
  const uri = `${baseUri}/${tripId}/invitation`
  return request
    .post<SendInviteResponse, SendInviteRequest>(
      uri,
      { email, role },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function acceptTripInvite(
  {
    tripId,
    inviteId,
  }: {
    tripId: string
    inviteId: string
  },
  accessToken?: string,
): Promise<definitions['fullTrip']> {
  const uri = `${baseUri}/${tripId}/invitations/${inviteId}/accept`
  return request
    .post<AcceptInviteResponse, undefined>(
      uri,
      undefined,
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function deleteTripInvite(
  {
    tripId,
    invitationId,
  }: {
    tripId: string
    invitationId: string
  },
  accessToken?: string,
): Promise<null> {
  const uri = `${baseUri}/${tripId}/invitations/${invitationId}`
  return request
    .delete<DeleteInviteResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function removeCollaborator(
  {
    tripId,
    userId,
  }: {
    tripId: string
    userId: string
  },
  accessToken?: string,
): Promise<null> {
  const uri = `${baseUri}/${tripId}/collaborators/${userId}`
  return request
    .delete<RemoveCollaboratorResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function editCollaborator(
  {
    tripId,
    userId,
    role,
  }: {
    tripId: string
    userId: string
    role: SendInviteRequest['role']
  },
  accessToken?: string,
): Promise<null> {
  const uri = `${baseUri}/${tripId}/collaborators/${userId}`
  return request
    .patch<EditCollaboratorResponse, EditCollaboratorRequest>(
      uri,
      { role },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function editTripInvite(
  {
    tripId,
    invitationId,
    role,
  }: {
    tripId: string
    invitationId: string
    role: SendInviteRequest['role']
  },
  accessToken?: string,
): Promise<null> {
  const uri = `${baseUri}/${tripId}/invitations/${invitationId}`
  return request
    .patch<EditInviteResponse, EditInviteRequest>(
      uri,
      { role },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function deleteTrip(id: string, accessToken?: string): Promise<null> {
  const uri = `${baseUri}/${id}`
  return request
    .delete<DeleteTripResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

interface CreateArgs {
  name: string
  startDate?: Moment
  endDate?: Moment
  imageId?: string
}

export function createTrip(
  { name, startDate, endDate, imageId }: CreateArgs,
  accessToken?: string,
): Promise<definitions['fullTrip']> {
  return request
    .post<CreateTripResponse, CreateTripRequest>(
      baseUri,
      {
        name,
        originPlaceId: SYDNEY_PLACE_INFO.id, // TODO: remove this param from the API
        startDate: startDate?.format(ISO_DATE_FORMAT),
        endDate: endDate?.format(ISO_DATE_FORMAT),
        imageId,
      },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

interface UpdateArgs {
  tripId: string
  name?: string
  startDate?: Moment | undefined | null
  endDate?: Moment | undefined | null
  imageId?: string
  originPlaceId?: string | null
  destinationPlaceIds?: Array<string>
  travellerRooms?: Array<TravellerRoom>
}

export function updateTrip(
  args: UpdateArgs,
  accessToken?: string,
): Promise<definitions['fullTrip']> {
  const {
    tripId,
    name,
    imageId,
    originPlaceId,
    destinationPlaceIds,
    travellerRooms,
  } = args

  const uri = `${baseUri}/${tripId}`

  let startDate: string | null | undefined = undefined
  if (Object.prototype.hasOwnProperty.call(args, 'startDate')) {
    startDate = args.startDate ? args.startDate.format(ISO_DATE_FORMAT) : null
  }

  let endDate: string | null | undefined = undefined
  if (Object.prototype.hasOwnProperty.call(args, 'endDate')) {
    endDate = args.endDate ? args.endDate.format(ISO_DATE_FORMAT) : null
  }

  return request
    .patch<UpdateTripResponse, UpdateTripRequest>(
      uri,
      {
        name,
        startDate,
        endDate,
        imageId,
        originPlaceId,
        destinationPlaceIds,
        travellerRooms,
      },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function getCuratedTrip(
  tripId: string,
  accessToken?: string,
): Promise<definitions['fullTrip']> {
  const uri = `${baseUri}/templates/${tripId}`
  return request
    .get<GetCuratedTripResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function getCuratedTripSummary(
  tripId: string,
  accessToken?: string,
): Promise<definitions['tripSummary']> {
  const uri = `${baseUri}/templates/${tripId}/summary`
  return request
    .get<GetCuratedTripSummaryResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function getCuratedTripMetadata(
  tripId: string,
  accessToken?: string,
): Promise<definitions['tripMetadata']> {
  const uri = `${baseUri}/templates/${tripId}/metadata`
  return request
    .get<GetCuratedTripMetadataResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function publishTripTemplate(
  {
    tripId,
  }: {
    tripId: string
  },
  accessToken?: string,
): Promise<null> {
  const uri = `${baseTemplatesUri}/${tripId}/publish`

  return request
    .put<PublishTripResponse, PublishTripRequest>(
      uri,
      { tripId },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function unpublishTripTemplate(
  {
    tripId,
  }: {
    tripId: string
  },
  accessToken?: string,
): Promise<null> {
  const uri = `${baseTemplatesUri}/${tripId}/unpublish`

  return request
    .put<UnpublishTripResponse, UnpublishTripRequest>(
      uri,
      { tripId },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function fetchUser(
  {
    email,
  }: {
    email: string
  },
  accessToken?: string,
): Promise<null> {
  const uri = `/api/users/${email}`
  return request
    .get<App.ApiResponse<null>>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export function uploadTripImage(
  {
    tripId,
    imageData,
  }: {
    tripId: string
    imageData: string
  },
  accessToken?: string,
): Promise<definitions['fullTrip']> {
  const uri = `${baseUri}/${tripId}/trip-image`
  return request
    .post<UploadTripImageResponse, UploadTripImageRequest>(
      uri,
      {
        imageData,
      },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function submitTripForApproval(
  {
    tripId,
    userId,
    name,
    position,
    description,
    imageData,
    showBio,
    updateAll,
    imageId,
  }: {
    tripId: string
    userId: string
    name: string
    position?: string
    imageData?: string
    description?: string
    showBio?: boolean
    updateAll?: boolean
    imageId?: string
  },
  accessToken?: string,
): Promise<null> {
  const uri = `${baseTemplatesUri}/${tripId}/review`
  return request
    .post<SubmitForApprovalResponse, SubmitForApprovalRequest>(
      uri,
      {
        reviewerId: userId,
        name,
        position,
        description,
        imageData,
        showBio,
        updateAll,
        imageId,
      },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function updateReviewData(
  {
    tripId,
    name,
    position,
    description,
    imageData,
    showBio,
    updateAll,
    imageId,
  }: {
    tripId: string
    name?: string
    position?: string
    description?: string
    imageData?: string
    showBio?: boolean
    updateAll?: boolean
    imageId?: string
  },
  accessToken?: string,
): Promise<null> {
  const uri = `${baseTemplatesUri}/${tripId}/updateReview`
  return request
    .put<UpdateReviewResponse, UpdateReviewRequest>(
      uri,
      { name, position, description, imageData, showBio, updateAll, imageId },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

interface CopyTemplateArgs {
  templateId: string
  name: string
  regionCode: string
  tripItemIds?: Array<string>
}

export function copyTripTemplate(
  { name, regionCode, templateId, tripItemIds }: CopyTemplateArgs,
  accessToken?: string,
): Promise<definitions['fullTrip']> {
  const uri = `${baseTemplatesUri}/${templateId}/copy`
  return request
    .post<CopyTripTemplateResponse, CopyTripTemplateRequest>(
      uri,
      {
        name,
        regionCode,
        tripItemIds,
      },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function fetchCurator(
  {
    tripId,
  }: {
    tripId: string
  },
  accessToken?: string,
): Promise<definitions['tripCurator'] | undefined> {
  const uri = `${baseUri}/${tripId}/curator`
  return request
    .get<GetTripCuratorResponse>(uri, getOptions(accessToken))
    .then((i) => i.result)
}

export async function getLowStockItemsForTrip(
  {
    tripId,
  }: {
    tripId: string
  },
  accessToken?: string,
): Promise<Array<definitions['lowStockItem']>> {
  const uri = `${baseUri}/${tripId}/low-stock-items`

  return request
    .get<GetLowStockTripItemsResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}

export async function validateTemplate(
  {
    tripId,
  }: {
    tripId: string
  },
  accessToken?: string,
): Promise<Array<definitions['validationError']>> {
  const uri = `${baseUri}/templates/${tripId}/validate`

  return request
    .get<GetTemplateValidationResponse>(uri, getOptions(accessToken))
    .then((response) => response.result)
}
