import React, { useMemo, useCallback, useEffect } from 'react'
import { replace } from 'connected-react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import {
  getCheckInDateFromURLSearchParams,
  getCheckOutDateFromURLSearchParams,
  propertyToDestinationSearch,
  setSearchParamValue,
} from 'lib/url/searchUrlUtils'
import useQueryParams from 'hooks/useQueryParams'
import { queryKeyPriceLte } from 'constants/url'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import { connect } from 'react-redux'
import RadioGroup from 'components/Luxkit/Radio/RadioGroup'

const SHOW_ALL_BUDGET = '10000'
const SHOULD_DEFAULT = '0'

interface MappedStateProps {
  currentBudget: App.BusinessTraveller.BusinessBudget | undefined,
}

interface Props {
  filters: App.OfferListFilters;
  search: string;
}

function BusinessTravellerWithinBudgetListFilter(props: Props & MappedStateProps) {
  const { filters, search, currentBudget } = props
  const dispatch = useAppDispatch()
  const queryParams = useQueryParams()

  const { checkInDate, checkOutDate } = useMemo(() => {
    return {
      checkInDate: getCheckInDateFromURLSearchParams(queryParams),
      checkOutDate: getCheckOutDateFromURLSearchParams(queryParams),
    }
  }, [queryParams])

  const totalNights = checkOutDate?.diff(checkInDate, 'days')
  const totalBudget = useMemo(() => (currentBudget?.budget ?? 0) * (totalNights ?? 0), [currentBudget?.budget, totalNights])
  const tenOverBudget = useMemo(() => Math.ceil(totalBudget * 1.1), [totalBudget])
  const twentyOverBudget = useMemo(() => Math.ceil(totalBudget * 1.2), [totalBudget])

  const selectedBudget = filters?.priceLte ?? SHOULD_DEFAULT

  const budgetFilterOptions = useMemo(() => [
    {
      label: 'Within budget',
      value: totalBudget.toString(),
    },
    {
      label: 'Up to 10% above budget',
      value: tenOverBudget.toString(),
    },
    {
      label: 'Up to 20% above budget',
      value: twentyOverBudget.toString(),
    },
    {
      label: 'Show all',
      value: SHOW_ALL_BUDGET,
    },
  ], [tenOverBudget, totalBudget, twentyOverBudget])

  const hasBudgetBeenSelected = !!selectedBudget && selectedBudget !== SHOULD_DEFAULT

  const isSelectedBudgetValid = useMemo(() => {
    return budgetFilterOptions.some(option => option.value === filters?.priceLte)
  }, [budgetFilterOptions, filters?.priceLte])

  useEffect(() => {
    // Default to 10% over budget if no filter is set
    if ((!hasBudgetBeenSelected || !isSelectedBudgetValid) && totalBudget > 0) {
      dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(search, queryKeyPriceLte, tenOverBudget)) }))
    }
  }, [dispatch, hasBudgetBeenSelected, isSelectedBudgetValid, search, tenOverBudget, totalBudget])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(search, queryKeyPriceLte, e.target.value)) }))
  }, [dispatch, search])

  return (
    <RadioGroup>
      {budgetFilterOptions.map(option => (
        <RadioInput
          key={option.label}
          checked={option.value === selectedBudget}
          onChange={onChange}
          name={option.label}
          value={option.value}
        >
          {option.label}
        </RadioInput>
      ))}
    </RadioGroup>
  )
}

const mapStateToProps = (state: App.State) => {
  return {
    currentBudget: state.businessTraveller.businessMapBudget,
  }
}

export default connect(mapStateToProps)(BusinessTravellerWithinBudgetListFilter)
