import { createSelector } from 'reselect'
import { parseSearchString } from 'lib/url/searchUrlUtils'
import { decodeSearchParams } from 'lib/search/searchUtils'
import { isString } from 'lib/string/stringUtils'
import { EmptyArray } from 'lib/array/arrayUtils'

export const getUrlOfferListFilters = createSelector(
  (state: App.State) => state.router.location.search,
  (search): App.OfferListFilters => {
    const params = parseSearchString(search)
    const searchParams = decodeSearchParams(search)

    return {
      locations: params.locations ? params.locations.split(',') : [],
      holidayTypes: params.holidayTypes ? params.holidayTypes.split(',') : [],
      holidayTypesScoped: params.holidayTypesScoped ? params.holidayTypesScoped.split(',') : [],
      propertyTypes: params.propertyTypes ? params.propertyTypes.split(',') : [],
      campaigns: params.campaigns ? params.campaigns.split(',') : [],
      sortBy: params.sortBy as App.OfferListSortByOptions,
      strategyApplied: params.strategyApplied,
      destinationId: params.destinationId,
      landmarkId: params.landmarkId,
      propertyId: params.propertyId,
      bounds: params.bounds,
      checkIn: searchParams.dates.checkIn,
      checkOut: searchParams.dates.checkOut,
      flexibleMonths: searchParams.flexibleSearch.flexibleMonths,
      flexibleNights: searchParams.flexibleSearch.flexibleNights,
      userSelectedFlexibleMonths: searchParams.flexibleSearch.userSelectedFlexibleMonths,
      rooms: searchParams.rooms,
      amenities: params.amenities ? params.amenities.split(',') : [],
      inclusions: params.inclusions ? params.inclusions.split(',') : [],
      limit: params.limit ? parseInt(params.limit, 10) : undefined,
      customerRatingGte: searchParams.customerRatingGte ? Number(searchParams.customerRatingGte) : undefined,
      priceGte: params.priceGte,
      priceLte: params.priceLte,
      operators: params.operators ? params.operators.split(',') : [],
      tourLengthGte: params.tourLengthGte,
      tourLengthLte: params.tourLengthLte,
      destinations: params.destinations && isString(params.destinations) ?
        params.destinations.split(',') :
        params.destinations && Array.isArray(params.destinations) ?
          params.destinations : [],
      // 'categories' is also used for experience lists and they are an -array- of string instead
      // this one is used for tour categories
      categories: typeof params.categories === 'string' ? params.categories.split(',') : [],
      offerTypes: searchParams.offerType ? searchParams.offerType.split(',') as Array<App.OfferListOfferType> : undefined,
      // @ts-expect-error
      productTypes: params.productTypes,
      bedroomsGte: params.bedroomsGte ? parseInt(params.bedroomsGte) : undefined,
      bedroomsEq: params.bedroomsEq ? parseInt(params.bedroomsEq) : undefined,
      bedsGte: params.bedsGte ? parseInt(params.bedsGte) : undefined,
      bathroomsGte: params.bathroomsGte ? parseInt(params.bathroomsGte) : undefined,
      onSale: params.onSale === 'true' ? true : undefined,
      flashOffer: params.flashOffer === 'true' ? true : undefined,
      luxPlusFeatures: params.luxPlusFeatures?.split(',') as Array<App.LuxPlusFeatureFilter> ?? [],
      agentHubFeatures: params.agentHubFeatures?.split(',') as Array<App.AgentHubFeatureFilter> ?? [],
      tourStyles: params.tourStyles?.split(',') ?? EmptyArray,
      source: params.source,
    }
  },
)
