import React, { useMemo } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import useToggle from 'hooks/useToggle'
import { skip, take } from 'lib/array/arrayUtils'
import SmallToggleButton from 'components/Luxkit/Button/SmallToggleButton'
import SlideDown from 'components/utils/SlideDown'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FilterPanelCheckboxGroupItem from './FilterPanelCheckboxGroupItem'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'

const CheckboxGroup = styled.div`
  margin-top: ${rem(16)};

  > * {
    margin-top: ${rem(12)};
  }
`

export interface FilterPanelCheckItem {
  label: React.ReactNode;
  value: number | string;
  checked?: boolean;
  hidden?: boolean;
  price?: number;
  count?: number;
}

interface Props {
  name: string;
  items: Array<FilterPanelCheckItem>;
  defaultSelected?: Set<string | number>;
  selected?: Set<string | number>;
  maxItems?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  titlecase?: boolean;
  allowShowMore?: boolean;
  allowCheckAll?: boolean;
}

function FilterPanelCheckboxGroup(props: Props) {
  const {
    onChange,
    name,
    items,
    maxItems = Infinity,
    defaultSelected,
    selected,
    titlecase = false,
    allowShowMore = true,
    allowCheckAll = false,
  } = props
  const [showMore, toggleShowMore] = useToggle(!allowShowMore)
  const visibleItems = useMemo(() => items.filter(item => !item.hidden), [items])
  const hasMore = visibleItems.length > maxItems

  // check all items from visibleItems, also uncheck all items from visibleItems
  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked

    const values = new Set(visibleItems.map(item => item.value))

    onChange?.({
      ...e,
      target: {
        ...e.target,
        name,
        value: checked ? Array.from(values).join(',') : '',
      },
    })
  }

  return (
    <CheckboxGroup>
      {allowCheckAll && <CheckboxInput
        key="checkAll"
        name="All"
        value="All"
        size="large"
        onChange={onCheckAll}
        checked={visibleItems.every(item => item.checked)}
      >
        All
      </CheckboxInput>}
      {take(visibleItems, maxItems).map(item =>
        <FilterPanelCheckboxGroupItem
          key={item.value}
          item={item}
          name={name}
          onChange={onChange}
          defaultChecked={defaultSelected?.has(item.value)}
          checked={item.checked ?? selected?.has(item.value)}
          titlecase={titlecase}
        />,
      )}
      {hasMore && <>
        <SlideDown show={showMore}>
          <VerticalSpacer gap={12}>
            {skip(visibleItems, maxItems).map(item =>
              <FilterPanelCheckboxGroupItem
                key={item.value}
                item={item}
                name={name}
                onChange={onChange}
                defaultChecked={defaultSelected?.has(item.value)}
                checked={item.checked ?? selected?.has(item.value)}
                titlecase={titlecase}
              />,
            )}
          </VerticalSpacer>
        </SlideDown>
        {allowShowMore && <SmallToggleButton
          on={showMore}
          onClick={toggleShowMore}
          size="medium"
          iconPosition="start"
        >
          {showMore ? 'Show less' : 'Show more'}
        </SmallToggleButton>}
      </>}
    </CheckboxGroup>

  )
}

export default React.memo(FilterPanelCheckboxGroup)
