import { createSelector } from 'reselect'
import getLuxLoyaltyTransferItems from './getLuxLoyaltyTransferItems'
import calculateDistance from 'lib/geo/distanceBetweenPoints'
import { getAirportsByCode } from 'selectors/flightsSelectors'

const getLuxLoyaltyTransferItemView = createSelector(
  (state: App.State) => getLuxLoyaltyTransferItems(state),
  (state: App.State) => getAirportsByCode(state),
  (state: App.State) => state.destination.googlePlaces,
  (items, airportsByCode, googlePlaces): App.WithDataStatus<Array<App.Checkout.LoyaltyTransferItemView>> => {
    const transfer = items[0]
    const place = googlePlaces[transfer?.pickUpLocation]
    const airport = airportsByCode[transfer?.dropOffLocation]
    if (!transfer || !place || !airport) {
      return {
        hasRequiredData: true,
        data: [],
      }
    }

    return {
      hasRequiredData: true,
      data: [{
        item: transfer,
        dropOffAirport: airport,
        pickUpPlace: place,
        distance: calculateDistance(
          place.latitude,
          place.longitude,
          airport.latitude,
          airport.longitude,
        ),
        totals: {
          price: 0,
          memberPrice: 0,
          value: 0,
          memberValue: 0,
          surcharge: 0,
          extraGuestSurcharge: 0,
          taxesAndFees: 0,
        },
      }],
    }
  },
)

export default getLuxLoyaltyTransferItemView
