import React from 'react'

import { useAppDispatch } from 'hooks/reduxHooks'
import CruiseSearchFiltersInputs from './Inputs/CruiseExtraFiltersInputs'
import { useLocation } from 'react-router'
import { deleteSearchParams, setManySearchParamValues } from 'lib/url/searchUrlUtils'
import { CruiseSearchFilterKeys } from 'constants/cruise'
import { replace } from 'connected-react-router'

interface Props {
  filters: App.OfferListFilters;
  shouldHidePromotionFilters?: boolean;
  shouldHideCruiseShips?: boolean;
}

function CruiseSearchExtraFiltersSidebar({
  filters,
  shouldHidePromotionFilters = false,
  shouldHideCruiseShips = false,
}: Props) {
  const dispatch = useAppDispatch()
  const { search } = useLocation()

  const onFilterChange = (nextFilters: Record<string, any>) => {
    const urlValues = Object.entries(nextFilters).map(([key, value]) => ({ paramName: key, paramValue: value }))
    // reset all our possible filter keys
    let nextSearch = deleteSearchParams(search, ...CruiseSearchFilterKeys)
    nextSearch = setManySearchParamValues(nextSearch, urlValues)
    dispatch(replace({ search: nextSearch }))
  }

  return (<CruiseSearchFiltersInputs
    listFilters={filters}
    onChange={onFilterChange}
    shouldHidePromotionFilters={shouldHidePromotionFilters}
    shouldHideCruiseShips={shouldHideCruiseShips}
  />
  )
}

export default CruiseSearchExtraFiltersSidebar
