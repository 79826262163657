import React from 'react'
import styled, { css } from 'styled-components'
import Typography, { TYPOGRAPHY_ICON_SIZE_CSS_VAR, TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from './Typography'
import cn from 'clsx'
import { rem } from 'polished'

export const BodyTextLarge = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 24px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(16)};
  font-size: 16px;
`

export const BodyTextMedium = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 20px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(20)};
  font-size: 14px;
`

export const BodyTextSmall = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 16px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(16)};
  font-size: 12px;
`

/**
 * Old to new
 * grey dark -> neutral two
 * white -> neutral eight
 * black -> neutral one
 * grey medium -> neutral four
 * grey light -> neutral six
 * urgency -> critical
 */

/*
  Font sizes are express in px as they are not to be affected by the
  20% REM font decrease at mobile sizes. These font sizes are absolute sizes.
*/
const Element = styled(Typography)`
  font-family: ${props => props.theme.font.primary.family};

  &.variant-large {
    ${BodyTextLarge}
  }

  &.variant-large:is(small),
  &.variant-large small,
  &.variant-medium {
    ${BodyTextMedium}
  }

  &.variant-medium:is(small),
  &.variant-medium small,
  &.variant-small,
  &.variant-small:is(small),
  &.variant-small small {
    ${BodyTextSmall}
  }

  &.weight-normal {
    font-weight: ${props => props.theme.font.primary.weight.regular};
  }

  &.weight-bold, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.primary.weight.superBold};
  }

  &.weight-semi-bold {
    font-weight: ${props => props.theme.font.primary.weight.semiBold};
  }

  &.italics, &:is(i, em), i, em {
    font-style: italic;
  }

  &.underline {
    text-decoration: underline;
  }

  &.strike-through, &:is(s), s {
    text-decoration: line-through;
  }

  &.colour-neutral-one {
    color: ${props => props.theme.palette.neutral.default.one};
  }

  &.colour-neutral-two {
    color: ${props => props.theme.palette.neutral.default.two};
  }

  &.colour-neutral-three {
    color: ${props => props.theme.palette.neutral.default.three};
  }

  &.colour-neutral-four {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &.colour-neutral-five {
    color: ${props => props.theme.palette.neutral.default.five};
  }

  &.colour-neutral-six {
    color: ${props => props.theme.palette.neutral.default.six};
  }

  &.colour-primary {
    color: ${props => props.theme.palette.brand.primary.normal};
  }

  &.colour-primary-dark {
    color: ${props => props.theme.palette.brand.primary.dark};
  }

  &.colour-neutral-eight {
    color: ${props => props.theme.palette.neutral.default.eight};
  }

  &.colour-critical {
    color: ${props => props.theme.palette.messaging.critical.normalForeground};
  }

  &.colour-warning {
    color: ${props => props.theme.palette.messaging.warning.normalForeground};
  }

  &.colour-highlight-primary {
    color: ${props => props.theme.palette.highlight.primary.normalForeground};
  }

  &.colour-highlight-secondary {
    color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  }

  &.colour-success {
    color: ${props => props.theme.palette.messaging.success.normalForeground};
  }

  &.colour-success-dark {
    color: ${props => props.theme.palette.messaging.success.darkForeground};
  }

  &.colour-lux-plus {
    color: ${props => props.theme.palette.product.luxPlus.foreground};
  }

  &.colour-exclusive-contrast {
    color: ${props => props.theme.palette.product.exclusive.contrast};
  }

  &.colour-urgency {
    color: ${props => props.theme.palette.messaging.urgency.normalForeground};
  }

  &.colour-tier-bronze {
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalForeground};
  }

  &.colour-tier-silver {
    color: ${props => props.theme.palette.product.luxLoyalty.silver.normalForeground};
  }

  &.colour-tier-gold {
    color: ${props => props.theme.palette.product.luxLoyalty.gold.normalForeground};
  }

  &.colour-tier-platinum {
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalForeground};
  }

  &.colour-tier-bronze-dark {
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.darkForeground};
  }

  &.colour-tier-silver-dark {
    color: ${props => props.theme.palette.product.luxLoyalty.silver.darkForeground};
  }

  &.colour-tier-gold-dark {
    color: ${props => props.theme.palette.product.luxLoyalty.gold.darkForeground};
  }

  &.colour-tier-platinum-dark {
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.darkForeground};
  }

  &.colour-tier-bronze-contrast {
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
  }

  &.colour-tier-silver-contrast {
    color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
  }

  &.colour-tier-gold-contrast {
    color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
  }

  &.colour-tier-platinum-contrast {
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast};
  }

  &.colour-tier-bronze-contrast-alt {
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.lightContrast};
  }

  &.colour-tier-silver-contrast-alt {
    color: ${props => props.theme.palette.product.luxLoyalty.silver.lightContrast};
  }

  &.colour-tier-gold-contrast-alt {
    color: ${props => props.theme.palette.product.luxLoyalty.gold.lightContrast};
  }

  &.colour-tier-platinum-contrast-alt {
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.lightContrast};
  }
`

export type BodyTextColour = 'urgency' |
'exclusive-contrast' |
'neutral-three' |
'neutral-one' |
'neutral-two' |
'neutral-four' |
'neutral-five' |
'neutral-six' |
'neutral-eight' |
'primary' |
'primary-dark' |
'critical' |
'warning' |
'highlight-secondary' |
'highlight-primary' |
'success' |
'success-dark' |
'lux-plus' |
'tier-bronze' |
'tier-silver' |
'tier-gold' |
'tier-platinum' |
'tier-bronze-contrast'|
'tier-silver-contrast'|
'tier-gold-contrast'|
'tier-platinum-contrast'|
'tier-bronze-contrast-alt'|
'tier-silver-contrast-alt'|
'tier-gold-contrast-alt'|
'tier-platinum-contrast-alt' |
'tier-bronze-dark' |
'tier-silver-dark' |
'tier-gold-dark' |
'tier-platinum-dark';

interface Props extends React.ComponentProps<typeof Typography> {
  variant: 'small' | 'medium' | 'large';
  weight?: 'normal' | 'semi-bold' | 'bold';
  italics?: boolean;
  underline?: boolean;
  strikeThrough?: boolean;
  colour?: BodyTextColour;
}

function BodyText(props: Props) {
  const {
    variant,
    weight,
    italics,
    underline,
    strikeThrough,
    as,
    className,
    colour,
    ...rest
  } = props

  return <Element
    {...rest}
    forwardedAs={as}
    className={cn(
      className,
        `variant-${variant}`,
        colour ? `colour-${colour}` : undefined,
        weight ? `weight-${weight}` : undefined,
        {
          italics,
          underline,
          'strike-through': strikeThrough,
        },
    )}
  />
}

export default BodyText
