import React, { ComponentProps, useCallback, useMemo } from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import styled from 'styled-components'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import Modal from 'components/Luxkit/Modal/Modal'
import TourV2ItemInclusions from 'components/OfferPage/TourV2/TourV2ItemInclusions'
import Group from 'components/utils/Group'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { partitionBy } from 'lib/array/arrayUtils'
import TourV2HighlightedInclusionItem from 'components/OfferPage/TourV2/TourV2HighlightedInclusionItem'
import LabeledSheet from 'components/Luxkit/LabeledSheet'

const CaptionContainer = styled(Group)`
  > * + *::before {
    content: '·';
    margin: 0 ${rem(4)};
    speak-as: bullets;
    speak: none;
  }
`

const HighlightedInclusionsContainer = styled(Group)`
  padding: ${rem(16)} ${rem(20)} ${rem(20)};
`

type InheritedModalProps = Pick<ComponentProps<typeof Modal>, 'primaryActionText' | 'primaryActionTo'>

interface Props extends InheritedModalProps {
  variation: App.Tours.TourV2OfferVariation
}

export type TourV2AllInclusionsModalResult = 'primary-action' | undefined

function TourV2AllInclusionsModal({
  variation,
  primaryActionText,
  primaryActionTo,
}: Props) {
  const modalElement = useModalElementContext<TourV2AllInclusionsModalResult>()

  const handlePrimaryClick = useCallback(() => {
    modalElement.resolve('primary-action')
  }, [modalElement])

  const {
    durationInDaysCount,
    countriesCount,
    placesCount,
    hasTheSameStartAndEndLocation,
    startLocation,
    endLocation,
  } = useTourV2VariationDetails(variation)

  const [flashInclusionItems, inclusionItems] = useMemo(() => partitionBy(variation.inclusionItems, inclusion => !!inclusion.isFlash), [variation.inclusionItems])

  return <Modal
    title={variation.name}
    subtitle={<CaptionContainer direction="horizontal" verticalAlign="center" wrap="wrap">
      <BodyText variant="medium" colour="neutral-two">
        {hasTheSameStartAndEndLocation && <span>Starts and ends in <b>{endLocation}</b></span>}
        {!hasTheSameStartAndEndLocation && <span>Starts in <b>{startLocation}</b>. Ends in <b>{endLocation}</b></span>}
      </BodyText>
      <BodyText variant="medium">{durationInDaysCount}</BodyText>
      {placesCount && <BodyText variant="medium">{placesCount}</BodyText>}
      {countriesCount && <BodyText variant="medium">{countriesCount}</BodyText>}
    </CaptionContainer>}
    primaryActionText={primaryActionText}
    primaryActionTo={primaryActionTo}
    onPrimaryActionClick={!primaryActionTo ? handlePrimaryClick : undefined}
  >
    <Group direction="vertical" gap={8}>
      {flashInclusionItems.length === 0 && <BodyText weight="bold" variant="large">Highlights included</BodyText>}
      {!!flashInclusionItems.length && <LabeledSheet kind="exclusive" label="Limited time inclusions">
        <HighlightedInclusionsContainer direction="vertical" gap={12}>
          <Group direction="vertical" gap={4}>
            {flashInclusionItems.map((inclusion) => <TourV2HighlightedInclusionItem
              key={inclusion.id}
              inclusion={inclusion}
            />)}
          </Group>
        </HighlightedInclusionsContainer>
      </LabeledSheet>}
      {inclusionItems && <TourV2ItemInclusions inclusions={inclusionItems} gap={4} />}
    </Group>
  </Modal>
}

export default TourV2AllInclusionsModal
