import React from 'react'
import styled from 'styled-components'
import ResponsiveImage from 'components/Common/ResponsiveImage/ResponsiveImage'
import useCurrentSiteTakeover, { SiteTakeoverBackground } from 'hooks/useCurrentSiteTakeover'

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #FDF2EA;
  overflow: hidden;
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;

  &.overlay-hero {
    background: linear-gradient(180deg, 
      rgba(2, 128, 179, 0.2) 0%,
      rgba(2, 102, 143, 0.9) 50%,
      rgba(2, 102, 143, 0.9) 82%,
      rgba(2, 102, 143, 0.9) 100%
    );
  }

  &.overlay-default {
    background: linear-gradient(180deg, 
      rgba(2, 128, 179, 0.2) 0%,
      rgba(2, 102, 143, 0.8) 100%
    );
  }

  &.overlay-search {
    background: linear-gradient(180deg, 
      rgba(2, 128, 179, 0.2) 0%,
      rgba(2, 102, 143, 0.8) 100%
    );
  }
`

interface Props extends Omit<React.ComponentProps<typeof ResponsiveImage>, 'id' | 'fit'> {
  background: SiteTakeoverBackground
}

function EuropeanBeachBreakTakeoverBackground(props: Props) {
  const { className, background, ...imageProps } = props

  const currentTakeover = useCurrentSiteTakeover()

  if (!currentTakeover) {
    return null
  }

  return (<Container className={className}>
    <ResponsiveImage
      {...imageProps}
      id={currentTakeover.imageId}
      fit="left"
      dpr={2}
    />
    <Gradient className={`overlay-${background}`} />
  </Container>)
}

export default EuropeanBeachBreakTakeoverBackground
