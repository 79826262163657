import { mergeOccupants } from 'lib/offer/occupancyUtils'
import { groupBy, nonNullable } from 'lib/array/arrayUtils'

export const getOccupantsFromItems = <T extends { occupancy?: App.Occupants, occupants?: App.Occupants }>(
  items: Array<T>,
  groupingKey: (item: T) => string,
  getOccupants: (item: T) => App.Occupants | undefined,
) => {
  const itemsMap = groupBy(items, groupingKey)
  return Array.from(itemsMap.values()).map(itemList => {
    const occupants = nonNullable(itemList.map(getOccupants))
    return mergeOccupants(occupants)
  })
}
