import React, { useCallback } from 'react'

import Modal from 'components/Luxkit/Modal/Modal'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextButton from 'components/Luxkit/Button/TextButton'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  proceed: () => void
}

function BusinessTravellerFlightSearchApprovalSearchModal(props: Props) {
  const { isOpen, setIsOpen, proceed } = props

  const onCancel = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const onProceed = useCallback(() => {
    setIsOpen(false)
    proceed()
  }, [proceed, setIsOpen])

  return (<Modal
    isOpen={isOpen}
    dismissible={false}
    title="Leaving approval flow"
  >
    <Group direction="vertical" gap={20}>
      <BodyText variant="large">Performing a new search will exit the approval flow and you may need to request approval for a new selection. You can return by clicking the link in your approval email.</BodyText>
      <Group direction="horizontal" gap={16} horizontalAlign="end">
        <TextButton
          kind="tertiary"
          onClick={onCancel}
        >
          Stay on page
        </TextButton>
        <TextButton
          kind="primary"
          onClick={onProceed}
        >
          Continue
        </TextButton>
      </Group>
    </Group>
  </Modal>)
}

export default BusinessTravellerFlightSearchApprovalSearchModal
