import React, { useCallback, useMemo } from 'react'
import config from 'constants/config'
import { connect } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { OfferListSortOption } from 'constants/offerListFilters'
import { replace } from 'connected-react-router'
import { deleteSearchParams, setSearchParamValue } from 'lib/url/searchUrlUtils'
import { addGTMEvent } from 'api/googleTagManager'
import { searchSwitchViewClick } from 'analytics/eventDefinitions'
import OfferListFilters, { OfferListFilterSubmit } from 'components/OfferList/OfferListFilter/OfferListFilters'
import { queryKeyAdults, queryKeyChildren, queryKeyDestinationId, queryKeyFilters, queryKeyFlexibleMonths, queryKeyFlexibleNights } from 'constants/url'
import * as InteractionStudioService from 'api/interactionStudio'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
import getOfferListKey from 'lib/offer/offerListKey'
import { getHotelEnabledFilters } from 'selectors/filters/filterSelectors'
import { EmptyArray } from 'lib/array/arrayUtils'
import { EmptyObject } from 'lib/object/objectUtils'

interface Props {
  windowSearch: string;
  availableFilters: App.OfferListFilterOptions;
  filters: App.OfferListFilters;
  mapViewPath?: string;
  switchView?: {
    label: string,
    view: 'map' | 'list'
    URL: string
  }
  sortOptions?: Array<OfferListSortOption>;
  searchSessionId: string;
  regionCode: string;
  overrides?: Partial<App.OfferListAvailableFilters>;
  showPropertyTypes?: boolean;
  heading?: React.ReactNode;
  shouldIncludeAllSearchFilters?: boolean
  shouldShowFilters?: boolean
  onPopularHolidayTypeFilterClick?: () => void
}

const filterToArray = (value?: string | Array<string>): Array<string> => value ? ([] as Array<string>).concat(value) : []

function HotelSearchPageFilters({
  windowSearch,
  sortOptions = EmptyArray,
  filters = EmptyObject,
  switchView,
  searchSessionId,
  regionCode,
  showPropertyTypes,
  heading,
  shouldIncludeAllSearchFilters,
  shouldShowFilters,
  onPopularHolidayTypeFilterClick,
  overrides,
  availableFilters: availableFiltersProp,
}: Props) {
  // exclude the location filters from the search results page
  const availableFilters: App.OfferListFilterOptions = useMemo(() => ({
    ...availableFiltersProp,
    filters: {
      ...availableFiltersProp?.filters,
      ...overrides,
    },
  }), [availableFiltersProp, overrides])

  const dispatch = useAppDispatch()

  const { globalSearchState } = useGlobalSearchContext() ?? {}

  const onFilterApply = useCallback((values: OfferListFilterSubmit) => {
    let search = windowSearch

    const locations = filterToArray(values.destinations)
    const holidayTypes = filterToArray(values.holidayTypes)
    const propertyTypes = filterToArray(values.propertyTypes)
    const amenities = filterToArray(values.amenities)
    const inclusions = filterToArray(values.inclusions)

    search = setSearchParamValue(search, 'locations', locations.join(','))
    search = setSearchParamValue(search, 'holidayTypes', holidayTypes.join(','))
    search = setSearchParamValue(search, 'propertyTypes', propertyTypes.join(','))
    search = setSearchParamValue(search, 'amenities', amenities.join(','))
    search = setSearchParamValue(search, 'inclusions', inclusions.join(','))
    search = setSearchParamValue(search, 'customerRatingGte', values.customerRatings ?? '')
    search = setSearchParamValue(search, 'offerType', values.offerTypes ?? '')

    if (values.sortBy) {
      if (values.sortBy === 'recommended') {
        search = deleteSearchParams(search, 'sortBy')
      } else {
        search = setSearchParamValue(search, 'sortBy', values.sortBy)
      }
    }

    InteractionStudioService.trackFilterApply({
      searchSessionId,
      region: regionCode,
      brand: config.BRAND,
      locations,
      holidayTypes,
      amenities,
      sortBy: values.sortBy || '',
      verticals: globalSearchState.searchVerticals,
      inclusions,
      bedroomsGte: values.bedroomsGte,
      bedsGte: values.bedsGte,
      bathroomsGte: values.bathroomsGte,
    })

    dispatch(replace({ search }))
  }, [dispatch, windowSearch, searchSessionId, regionCode, globalSearchState.searchVerticals])

  const resetFilters = () => {
    let filtersToDelete = [...queryKeyFilters]
    if (shouldIncludeAllSearchFilters) {
      filtersToDelete = filtersToDelete.concat([queryKeyDestinationId, queryKeyAdults, queryKeyChildren, queryKeyFlexibleMonths, queryKeyFlexibleNights])
    }
    const search = deleteSearchParams(
      windowSearch,
      ...filtersToDelete,
    )
    dispatch(replace({ search }))
  }

  const onViewSwitch = useCallback(() => {
    addGTMEvent(searchSwitchViewClick())
  }, [])

  const enabledFilters = useAppSelector(getHotelEnabledFilters)

  return (
    <OfferListFilters
      heading={heading}
      availableFilters={availableFilters}
      sortOptions={sortOptions}
      existingFilters={filters}
      mapViewPath={switchView?.view === 'map' ? switchView.URL : undefined}
      listViewPath={switchView?.view === 'list' ? switchView.URL : undefined}
      onFilterApply={onFilterApply}
      onFilterReset={resetFilters}
      onViewSwitch={onViewSwitch}
      isFilterTogglesShown
      showPropertyTypes={showPropertyTypes}
      onPopularHolidayTypeFilterClick={onPopularHolidayTypeFilterClick}
      shouldIncludeAllSearchFilters={shouldIncludeAllSearchFilters}
      shouldShowFilters={shouldShowFilters || false}
      enabledFilters={enabledFilters}
    />
  )
}

function mapStateToProps(state: App.State, ownProps: Partial<Props>) {
  const offerListKey = getOfferListKey(ownProps.filters ?? {})
  const availableFilters = state.offer.offerListFilterOptions[offerListKey]
  const regionCode = state.geo.currentRegionCode
  const searchSessionId = state.analytics.search.sessions[offerListKey]

  return {
    windowSearch: state.router.location.search,
    availableFilters,
    searchSessionId,
    regionCode,
  }
}

export default connect(mapStateToProps)(HotelSearchPageFilters)
