import PhoneInput from 'components/Common/Form/Input/PhoneInput'
import TextInput from 'components/Common/Form/Input/TextInput'
import { useAppSelector } from 'hooks/reduxHooks'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const SwappablePhoneInput = styled(PhoneInput)`
  && > * {
    transition: none;
  }

  &[hidden] {
    display: none;
  }
`

const SwappableEmailInput = styled(TextInput)`
  && > * {
    transition: none;
  }

  &[hidden] {
    display: none;
  }
`

interface Props {
  phoneInputName?: string;
  emailInputName?: string;
  className?: string;
  noValidationSpacing?: boolean;
  autoFocus?: boolean;
}

function AccountAccessUserInput(props: Props) {
  const { emailInputName = 'email', phoneInputName = 'phoneNumber', className, noValidationSpacing, autoFocus = true } = props

  const [value, setValue] = useState<string>('')
  const emailInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const isMobile = useScreenSizeOnly('mobile')
  const headlessMode = useAppSelector(state => state.system.headlessMode)
  const allowPhoneNumberLogin = useAppSelector(state => !!state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.allowPhoneNumberLogin])
  const [anyInputFocus, setAnyInputFocus] = useState<boolean>(false)
  const onTextChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    setValue(e.currentTarget.value)
  }, [])

  useEffect(() => {
    if (!headlessMode && !isMobile && autoFocus) {
      emailInputRef.current?.focus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFocusAnyInput = useCallback(() => {
    setAnyInputFocus(true)
  }, [])

  const isPossiblyPhoneNumber = useMemo(() => {
    return allowPhoneNumberLogin && /^(\d|\+)\d*$/.test(value)
  }, [allowPhoneNumberLogin, value])

  useLayoutEffect(() => {
    if (!anyInputFocus) return
    if (isPossiblyPhoneNumber) {
      phoneInputRef.current?.focus()
    } else {
      emailInputRef.current?.focus()
    }
  }, [anyInputFocus, isPossiblyPhoneNumber])

  if (!allowPhoneNumberLogin) {
    return <TextInput
      label="Email address"
      id="email"
      required
      type="email"
      name="email"
      autoComplete="email"
      placeholder="Email"
      requiredErrorMessage="Email is required"
      ref={emailInputRef}
      helpText="We'll check if you have an account with us or if we need to create one for you."
      className={className}
      noValidationSpacing={noValidationSpacing}
    />
  }

  return <Container className={className}>
    <SwappableEmailInput
      label="Email address or phone number"
      id="email"
      required
      type="email"
      name={emailInputName}
      autoComplete="email"
      placeholder="Email or phone number"
      requiredErrorMessage="Email or phone number is required"
      ref={emailInputRef}
      value={value}
      onChange={onTextChange}
      helpText="We'll check if you have an account with us or if we need to create one for you."
      hidden={isPossiblyPhoneNumber}
      disabled={isPossiblyPhoneNumber}
      onFocus={onFocusAnyInput}
      noValidationSpacing={noValidationSpacing}
    />
    <SwappablePhoneInput
      inputRef={phoneInputRef}
      label="Email address or phone number"
      name={phoneInputName}
      placeholder="Email or phone number"
      requiredErrorMessage="Email or phone number is required"
      required
      phoneNumber={value}
      onNumberChange={onTextChange}
      hidden={!isPossiblyPhoneNumber}
      disabled={!isPossiblyPhoneNumber}
      onFocus={onFocusAnyInput}
      noValidationSpacing={noValidationSpacing}
      helpText="We'll check if you have an account with us or if we need to create one for you."
    />
  </Container>
}

export default AccountAccessUserInput
