import { createSelector } from 'reselect'
import getInsuranceCoverAmountFromCart from './getInsuranceCoverAmountFromCart'
import { sum } from 'lib/array/arrayUtils'

export const getTotalPriceFromExistingOrder = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (order): number => {
    if (order) {
      const deposit = order.depositDetails
      const balance = (deposit && !deposit.balance_paid_date) ? deposit.balance_amount : 0
      const paidTotal = sum(order.payments, payment => payment.amount)

      const serviceFee = order.serviceFee?.total || 0

      return paidTotal + balance - serviceFee
    }

    return 0
  },
)
const getCheckoutInsuranceCoverAmount = createSelector(
  (state: App.State) => getInsuranceCoverAmountFromCart(state),
  (state: App.State) => getTotalPriceFromExistingOrder(state),
  (cartCoverAmount, orderCoverAmount): number => {
    return orderCoverAmount || cartCoverAmount || 0
  },
)

export default getCheckoutInsuranceCoverAmount
