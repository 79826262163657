import React, { useMemo } from 'react'
import cn from 'clsx'
import { rem } from 'polished'
import styled from 'styled-components'
import moment from 'moment'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Clickable from 'components/Common/Clickable/Clickable'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const MonthGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${rem(8)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  &.drawer {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const MonthGridItem = styled(Clickable)`
  display: flex;
  padding: ${rem(12)} ${rem(32)};
  width: ${rem(88)};
  justify-content: center;
  border: 1px solid ${props => props.theme.palette.neutral.default.six};
  border-radius: ${props => props.theme.borderRadius.S};

  &:not(.disabled) {
    ${mediaHoverable} {
      &:hover {
        border-color: ${props => props.theme.palette.neutral.default.one};
      }
    }

    &:focus {
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid ${props => props.theme.palette.neutral.default.five});
      outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.selected {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
  }
`

interface Props {
  year: number;
  departureMonths: Array<string>;
  onClick: (month: string) => void;
  cruiseDatesMap: Array<string>;
}

const startOfMonth = moment().startOf('month')

function CruiseWhenSelectContentMonths({ year, departureMonths, onClick, cruiseDatesMap }: Props) {
  const monthList = useMemo(() => {
    const startOfYear = moment(year, 'YYYY').startOf('year')
    const months = Array.from({ length: 12 }, (_, i) => startOfYear.clone().add(i, 'months'))

    return months.map((month) => {
      const value = month.format('YYYY-MM')
      const disabled = month.isBefore(startOfMonth) || !cruiseDatesMap.includes(value)

      return {
        name: month.format('MMM'),
        value,
        disabled,
        selected: departureMonths.includes(value),
      }
    })
  }, [year, cruiseDatesMap, departureMonths])

  return <Group direction="vertical" gap={8} horizontalAlign="center">
    <Heading variant="heading5">{year}</Heading>
    <MonthGrid data-testid="cruise-when-select-months-grid">
      {monthList.map(({ name, value, selected, disabled }) => <MonthGridItem
        key={`${year}_${value}`}
        className={cn({ selected, disabled })}
        onClick={() => !disabled && onClick(value)}
      >
        <BodyText variant="medium" weight={selected ? 'bold' : 'normal'}>
          {name}
        </BodyText>
      </MonthGridItem>)}
    </MonthGrid>
  </Group>
}

export default CruiseWhenSelectContentMonths
