import { isLuxPlusToursEnabled } from 'checkout/selectors/tourV2Selectors'
import { useAppSelector } from 'hooks/reduxHooks'
import { arrayToMap, min, nonNullable } from 'lib/array/arrayUtils'
import getOfferListKey from 'lib/offer/offerListKey'

function useSearchTourVariation(
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary,
  filters: App.OfferListFilters,
): App.Tours.TourV2OfferVariation | undefined {
  const filtersKey = getOfferListKey(filters)

  const luxPlusEnabled = useAppSelector(isLuxPlusToursEnabled)
  const metaData = useAppSelector(state => state.offer.tourSearchResultMetadata.tourMetadata[filtersKey]?.[offer.id])

  let variations: Array<App.Tours.TourV2OfferVariation> = []
  if (metaData?.tourOptionIds?.length) {
    // get the variations from what search told us
    const variationsByTourOptionId = arrayToMap(Object.values(offer.variations), variation => variation.fkTourOptionId)
    variations = nonNullable(metaData.tourOptionIds.map(id => variationsByTourOptionId.get(id)))
  } else if (offer.variations) {
    // As we need to show the tours tile in the cruise results, we need to show all variations
    variations = Object.values(offer.variations)
  }

  const allOptions = getAllOptions(variations)
  const cheapestOption = getCheapestOption(allOptions, luxPlusEnabled)

  if (cheapestOption) {
    return offer.variations[cheapestOption.fkVariationId]
  }
}

function getAllOptions(variations: Array<App.Tours.TourV2OfferVariation>): Array<App.Tours.TourV2OfferPurchasableOption> {
  return variations.flatMap(variation => variation.departures.filter(dep => dep.status === 'available').flatMap(dep => dep.options))
}

function getCheapestOption(options: Array<App.Tours.TourV2OfferPurchasableOption>, luxPlusEnabled: boolean): App.Tours.TourV2OfferPurchasableOption | undefined {
  return min(options, option => {
    if (luxPlusEnabled) {
      return option.memberPrice || option.price
    }
    return option.price
  })
}

export default useSearchTourVariation
