import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'

import {
  getOfferImageId,
  getLabelText,
  getSavedDetailsForOffer,
  SaveItemsCallbackResult,
  convertOccupancies,
} from './utils'
import BaseBookmarkButton from '../Common/BaseBookmarkButton'
import SaveModal, { TripPlannerSaveModalResult } from '../Common/SaveModal'

import * as Analytics from 'analytics/analytics'
import {
  saveToTripEvent,
  tripLoginModalSignUpDismiss,
  tripLoginModalSignUpView,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import AnalyticsComponentContext from 'contexts/Analytics/analyticsComponentContext'
import AnalyticsPageContext from 'contexts/Analytics/analyticsPageContext'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import ModalContext from 'contexts/ModalContext'
import OfferPageStateContext from 'contexts/OfferPage/offerPageStateContext'
import { RecommendationSaveClickTrackerContext } from 'contexts/RecommendationSaveClickTrackerContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import usePendingLoginHandler from 'hooks/usePendingLoginHandler'
import { EmptyArray } from 'lib/array/arrayUtils'
import {
  buildSuggestedDatesParamsKey,
  parseOccupancy,
} from 'lib/search/searchUtils'
import { parseSearchString } from 'lib/url/searchUrlUtils'
import { selectLoggedIn } from 'selectors/accountSelectors'
import {
  clearRecentlySavedTripId,
  getRecentlySavedTripId,
  setRecentlySavedTripId,
} from 'storage/recentSavedTrip'
import { HotelBookmarkPayload } from 'tripPlanner/api/bookmark/types'
import { useTripId } from 'tripPlanner/contexts/TripContext'
import { useDeleteTripItem } from 'tripPlanner/hooks/api/tripItem'
import { useTrip, useEditableTrips } from 'tripPlanner/hooks/api/trip'
import { useProcessBookmarks } from 'tripPlanner/hooks/api/bookmark'
import useBookmarkSnackbarHandlers from 'tripPlanner/hooks/bookmarks/useSavedItemSnackbarHandlers'
import useNewTripNameFromOffer from 'tripPlanner/hooks/useNewTripNameFromOffer'
import {
  setCurrentSelectionId,
  setTripItemHasJustBeenAdded,
} from 'tripPlanner/reducers/actions'
import {
  getImmersiveTripId,
  selectTripPlannerTemplateId,
  selectTripPlannerTemplateItemId,
} from 'tripPlanner/selectors'
import { BasicTrip, FullTrip } from 'tripPlanner/types/common'
import { BookmarkDatesAndOccupancy } from 'tripPlanner/types/tripItem'
import { tripItemSelectionId } from 'tripPlanner/utils/itemSelection'

export interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  datesAndGuests: BookmarkDatesAndOccupancy
  roomTypeId?: string
  testId?: string
  withLabel?: React.ComponentProps<typeof BaseBookmarkButton>['withLabel']
  size?: React.ComponentProps<typeof BaseBookmarkButton>['size']
}

interface MappedStateProps {
  suggestedDatesByOfferId: { [offerId: string]: App.OfferSuggestedDates }
}

function BedbankBaseBookmarkButton({
  offer,
  datesAndGuests,
  roomTypeId,
  testId,
  suggestedDatesByOfferId,
  withLabel,
  size,
}: Props & MappedStateProps) {
  const [isPostLoginSaveRequired, setIsPostLoginSaveRequired] =
    useState<boolean>(false)
  const suggestedDates = suggestedDatesByOfferId?.[offer.id]
  const analyticsPage = useContext(AnalyticsPageContext)
  const analyticsComponent = useContext(AnalyticsComponentContext)
  const checkIn =
    datesAndGuests.checkInDate?.format(ISO_DATE_FORMAT) ??
    suggestedDates?.checkIn
  const checkOut =
    datesAndGuests.checkOutDate?.format(ISO_DATE_FORMAT) ??
    suggestedDates?.checkOut
  const inTripId = useTripId()
  const currentTripId = useAppSelector(getImmersiveTripId) ?? inTripId
  const { data: currentTrip } = useTrip({ tripId: currentTripId })
  const { data, isFetching: tripsFetching } = useEditableTrips()
  const trips = data ?? EmptyArray

  const templateId = useAppSelector(selectTripPlannerTemplateId)
  const templateItemId = useAppSelector(selectTripPlannerTemplateItemId)

  const dispatch = useAppDispatch()
  const globalSearchState = useContext(GlobalSearchStateContext)
  const offerPageState = useContext(OfferPageStateContext)
  const didAutoSelectTrip = useRef(false)

  // A promise that can be awaited to make sure we only show snackbar messages once the modal is closed
  const modalPromise = useRef<Promise<any>>(Promise.resolve())

  const details = getSavedDetailsForOffer(trips, offer.id, roomTypeId)
  const isSaved = details.length > 0

  const recommendationSaveClickTracker = useContext(
    RecommendationSaveClickTrackerContext,
  )

  const {
    showSaveSuccessSnackbar,
    showSaveErrorSnackbar,
    showRemoveSuccessSnackbar,
    showRemoveErrorSnackbar,
  } = useBookmarkSnackbarHandlers()

  const { mutate: deleteBookmark, isLoading: isDeleting } = useDeleteTripItem({
    onSuccess: (_res, vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveSuccessSnackbar(trip.id, trip.name)
    },
    onError: (_res, _vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveErrorSnackbar(trip.name)
    },
  })

  const newTripName = useNewTripNameFromOffer(offer)

  const { mutateAsync: processBookmarks, isLoading: isCreating } =
    useProcessBookmarks({
      onError: (e, variables) => {
        console.error(e)
        showSaveErrorSnackbar(
          trips.find((t) => t.id === variables.tripIdsAdded[0])?.name,
        )

        // Clear the recently saved trip ID in case it errored because the trip was deleted
        clearRecentlySavedTripId()
      },
    })

  const createTripItems = useCallback(
    async(tripId: string): Promise<SaveItemsCallbackResult> => {
      const payload: HotelBookmarkPayload = {
        type: offer.type,
        code: offer.id,
        roomTypeId,
        startDate: checkIn,
        endDate: checkOut,
        occupancies: convertOccupancies(datesAndGuests.occupancies),
        templateId,
        templateItemId,
      }

      const bookmarkResult = await processBookmarks({
        items: [payload],
        tripIdsAdded: [tripId],
        tripIdsRemoved: [],
      })

      return {
        savedItemIds: bookmarkResult.created.map((item) => item.id),
      }
    },
    [
      checkIn,
      checkOut,
      datesAndGuests.occupancies,
      offer.id,
      offer.type,
      processBookmarks,
      roomTypeId,
      templateId,
      templateItemId,
    ],
  )

  const createTripItemsImmediate = useCallback(
    async(trip: FullTrip | BasicTrip) => {
      try {
        const res = await createTripItems(trip.id)
        const itemId = res.savedItemIds[0]
        dispatch(setCurrentSelectionId(tripItemSelectionId(itemId)))
        dispatch(setTripItemHasJustBeenAdded())
        modalPromise.current.then(() =>
          showSaveSuccessSnackbar(trip.id, trip.name, itemId, 'ACCOMMODATION', true),
        )
        setRecentlySavedTripId(trip.id)
      } catch {
        // Do nothing - failure case is handled by the hook's onError handler
      }
    },
    [createTripItems, dispatch, showSaveSuccessSnackbar],
  )

  const analyticsLabel = useMemo(() => {
    return analyticsComponent ?
      `${analyticsPage}_${analyticsComponent}` :
      analyticsPage
  }, [analyticsPage, analyticsComponent])

  const showModal = useContext(ModalContext)
  const openSaveModal = useCallback(() => {
    return showModal<TripPlannerSaveModalResult>(
      // Need to forward the contexts and modals don't have access
      // We need access in the trip settings modal
      <GlobalSearchStateContext.Provider value={globalSearchState}>
        <OfferPageStateContext.Provider value={offerPageState}>
          <SaveModal
            createTripItems={createTripItems}
            itemTypeLabel="offer"
            defaultTripName={newTripName}
            offerImageId={getOfferImageId(offer)}
            isCreatingItem={isCreating}
          />
        </OfferPageStateContext.Provider>
      </GlobalSearchStateContext.Provider>,
    )
  }, [
    createTripItems,
    globalSearchState,
    isCreating,
    newTripName,
    offer,
    offerPageState,
    showModal,
  ])

  const save = useCallback(
    async(isIntendingToRemoveBookmark: boolean) => {
      didAutoSelectTrip.current = false
      const recentlySavedTripId = getRecentlySavedTripId()
      if (isSaved) {
        if (isIntendingToRemoveBookmark) {
          Promise.all(
            details.map((detail) =>
              deleteBookmark({
                tripId: detail.trip.id,
                tripItemId: detail.itemId,
              }),
            ),
          )
        }
      } else if (currentTrip) {
        recommendationSaveClickTracker()
        fireInteractionEvent(
          saveToTripEvent('button', 'immersive', 'click', analyticsLabel),
        )
        createTripItemsImmediate(currentTrip)
      } else {
        fireInteractionEvent(
          saveToTripEvent('button', 'global', 'click', analyticsLabel),
        )
        Analytics.trackClientEvent({
          subject: 'save-to-trip',
          action: 'clicked',
          category: 'logging',
          type: 'operational',
          optimizelyEventId: '26343810012',
          optimizelyEventKey: 'click-save-to-trip',
        })
        const recentlySavedTrip = trips.find(
          (t) => t.id === recentlySavedTripId,
        )
        if (recentlySavedTrip) {
          didAutoSelectTrip.current = true
          createTripItemsImmediate(recentlySavedTrip)
        } else {
          modalPromise.current = openSaveModal()
          await modalPromise.current
          modalPromise.current = Promise.resolve()
        }
      }
    },
    [
      analyticsLabel,
      createTripItemsImmediate,
      currentTrip,
      deleteBookmark,
      details,
      isSaved,
      openSaveModal,
      recommendationSaveClickTracker,
      trips,
    ],
  )
  const loggedIn = useAppSelector(selectLoggedIn)
  const trackTripLoginModalSignupView = useCallback(() => {
    fireInteractionEvent(
      tripLoginModalSignUpView(roomTypeId === undefined ? 'save' : 'save_room'),
    )
  }, [roomTypeId])
  const trackTripLoginModalSignupDismiss = useCallback(() => {
    fireInteractionEvent(
      tripLoginModalSignUpDismiss(
        roomTypeId === undefined ? 'save' : 'save_room',
      ),
    )
  }, [roomTypeId])

  const onButtonClick = usePendingLoginHandler(
    () => {
      if (loggedIn) {
        save(isSaved)
      } else {
        setIsPostLoginSaveRequired(true)
      }
    },
    'tripPlannerLogin',
    trackTripLoginModalSignupView,
    trackTripLoginModalSignupDismiss,
  )

  useEffect(() => {
    if (loggedIn && isPostLoginSaveRequired && !tripsFetching) {
      save(false)
      setIsPostLoginSaveRequired(false)
    }
  }, [
    isPostLoginSaveRequired,
    loggedIn,
    save,
    setIsPostLoginSaveRequired,
    tripsFetching,
  ])

  return (
    <BaseBookmarkButton
      label={getLabelText(isSaved, !!roomTypeId)}
      isProcessing={isDeleting || isCreating || tripsFetching}
      isSaved={isSaved}
      onClick={onButtonClick}
      testId={testId}
      withLabel={withLabel}
      size={size}
    />
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>(
  (appState) => {
    const searchString = appState.router.location.search
    const occupancies = parseOccupancy(searchString)
    const { flexibleMonths, flexibleNights } = parseSearchString(searchString)
    const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(
      flexibleMonths,
      flexibleNights,
      occupancies,
    )

    return {
      suggestedDatesByOfferId:
        appState.offer.searchResultMetadata.offerSuggestedDates?.[
          flexibleSearchFilterKey
        ],
    }
  },
)(BedbankBaseBookmarkButton)
