import React, { useContext } from 'react'
import GeoContext from 'contexts/geoContext'
import KrisFlyerIcon from './PartnershipIcons/KrisFlyerIcon'
import VelocityIcon from './PartnershipIcons/VelocityIcon'

const prefixToIcon: Record<App.PartnershipPrefix, React.ComponentType<any> | undefined> = {
  kfp: KrisFlyerIcon,
  vff: VelocityIcon,
  qff: undefined,
  cvp: undefined,
}

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

function PartnershipIcon(props: Props) {
  const { partnership } = useContext(GeoContext)
  const Icon = partnership ? prefixToIcon[partnership.prefix] : undefined

  if (!Icon) {
    return null
  }

  return <Icon {...props} />
}

export default PartnershipIcon
