import { isAgentHubEnabled } from 'agentHub/selectors/agentHubSelectors'
import { HOTELS_ENABLED_FILTERS } from 'constants/search'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { createSelector } from 'reselect'

export const getHotelEnabledFilters = createSelector(
  (state: App.State) => isLuxPlusEnabled(state),
  (luxPlusEnabled) => {
    return {
      ...HOTELS_ENABLED_FILTERS,
      luxPlusFeatures: luxPlusEnabled,
      agentHubFeatures: isAgentHubEnabled,
    }
  },
)
