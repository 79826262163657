import { UserCartAddonItemType, USER_CART_ADDON_TYPES, USER_CART_ACCOMMODATION_TYPES, UserCartAccommodationItemType } from 'constants/userCart'
import { isBedbankItem, isBNBLLEHotelItem, isFlightItem, isLuxPlusSubscriptionItem, isSubscriptionJoinItem } from './checkoutUtils'
import {
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION,
  CHECKOUT_ITEM_TYPE_TRANSFER,
  CHECKOUT_ITEM_TYPE_VILLA,
} from 'constants/checkout'
import { generateLuxPlusSubscriptionItems } from './luxPlusSubscription/cart'
import { AppDispatch } from 'src/client/store'
import { getBedbankSessionGroupingKey, initCheckoutBedbankSession } from 'actions/CheckoutActions'
import { arrayToMap, groupBy } from 'lib/array/arrayUtils'
import { userCartItemUpdate } from 'actions/AuthActions'

const SUPPORTED_USER_CART_ITEMS = new Set([
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_TRANSFER,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION,
  CHECKOUT_ITEM_TYPE_VILLA,
])

export function isMultiCartExperienceSupported(offer?: App.ExperienceOffer): boolean {
  return !!offer?.hasCalendar
}

export function isUserCartAddonItem(item: App.Checkout.AnyItem): item is Extract<App.Checkout.AnyItem, { itemType: UserCartAddonItemType} > {
  return USER_CART_ADDON_TYPES.includes(item.itemType as UserCartAddonItemType)
}

export function isUserCartAccommodationItem(item: App.Checkout.AnyItem): item is Extract<App.Checkout.AnyItem, { itemType: UserCartAccommodationItemType} > {
  return USER_CART_ACCOMMODATION_TYPES.includes(item.itemType as UserCartAccommodationItemType)
}

export function isUserCartSupportedItem(item: App.Checkout.AnyItem): boolean {
  if (!SUPPORTED_USER_CART_ITEMS.has(item.itemType)) return false
  if (isFlightItem(item) && item.flights.length === 0) return false
  if (isBNBLLEHotelItem(item)) return false
  return true
}

/**
 * A function in which to fix any issues with user cart items that prevent them from syncing successfully to svc-cart.
 * Hopefully we can fix things up so that this is no longer needed.
 */
export function prepareItem<T extends App.Checkout.AnyItem>(item: T): T {
  if (isFlightItem(item)) {
    if (!item.otherFees) {
      return {
        ...item,
        // Without this property, the item won't successfully save into svc-cart.
        // Probably we should fix this by updating the schema but svc-cart is using the same schema for saving the user cart as for saving the checkout cart,
        // and I don't want to delve into forking the validation schemas right now...
        // For now this should fix it. The actual fees will get filled in once the item is in the checkout.
        otherFees: {
          flightAtolFee: 0,
          flightBookingFee: 0,
          flightCreditFee: 0,
          flightServiceFee: 0,
        },
      }
    }
  }

  return item
}

export function transformUserCartItemsToCheckoutItems(
  userCartItems: Array<App.Checkout.AnyItem>,
  luxPlusOffers: Array<App.SubscriptionOffer> | undefined,
  shouldWaiveSubscriptionJoinFee: boolean,
): Array<App.Checkout.AnyItem> {
  const checkoutItems: Array<App.Checkout.AnyItem> = []

  for (const item of userCartItems) {
    if (isSubscriptionJoinItem(item)) {
      // Do nothing - let the subscription item handle the joining fee as needed
    } else if (isLuxPlusSubscriptionItem(item)) {
      const offer = luxPlusOffers?.find(o => o.joinFeeWaived === shouldWaiveSubscriptionJoinFee)
      if (offer) {
        checkoutItems.push(...generateLuxPlusSubscriptionItems(offer))
      }
    } else {
      checkoutItems.push(item)
    }
  }

  return checkoutItems
}

export async function refreshBedbankItemSessions(items: Array<App.Checkout.AnyItem>, dispatch: AppDispatch): Promise<Array<App.Checkout.AnyItem>> {
  const state = dispatch((_, getState) => getState())
  const currentRegionCode = state.geo.currentRegionCode
  const isSpoofed = state.auth.account.isSpoofed

  const bedbankItems = items
    .filter(isBedbankItem)
    // Spread into new objects, since we're going to mutate them
    .map(item => ({ ...item }))
  const bedbankItemsMap = arrayToMap(bedbankItems, item => item.itemId)

  const groupedBedbankItems = Array.from(groupBy(bedbankItems, getBedbankSessionGroupingKey).values())

  for (const bedbankItemGroup of groupedBedbankItems) {
    const { sessionId, defaultBedGroupId } = await initCheckoutBedbankSession(dispatch, state, currentRegionCode, bedbankItemGroup, isSpoofed)
    for (const bedbankItem of bedbankItemGroup) {
      bedbankItem.sessionId = sessionId
      if (!bedbankItem.bedGroupId) {
        bedbankItem.bedGroupId = defaultBedGroupId
      }
      dispatch(userCartItemUpdate(bedbankItem))
    }
  }

  return items.map(item => bedbankItemsMap.get(item.itemId) ?? item)
}
