import React, { useContext } from 'react'
import OfferList from 'components/OfferList/OfferList'

import ContactContext from 'contexts/contactContext'
import { CRUISE_UNIFIED_FILTERS } from 'constants/cruise'

import BodyText from 'components/Luxkit/Typography/BodyText'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import TextLink from 'components/Luxkit/TextLink'
import TextButton from 'components/Luxkit/Button/TextButton'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'

function CruiseOfferListNoResults() {
  const currentContact = useContext(ContactContext)
  const localContact = currentContact.local

  return <Group direction="vertical" gap={32}>
    <Group direction="vertical" gap={8}>
      <Heading variant="heading2">
        Sorry, we couldn't find any cruises
      </Heading>
      <BodyText variant="large">
        Didn&apos;t find what you were looking for?
        <CSSBreakpoint min="tablet" as="span">
          {' '}Speak to one of our travel experts{' '}
          <TextLink href={`tel:${localContact.number}`} rel="nofollow">
            {localContact.humanReadable}
          </TextLink>
        </CSSBreakpoint>
      </BodyText>
      <CSSBreakpoint only="mobile">
        <TextButton
          startIcon={<LinePhoneIcon />}
          fit="mobile-full-width"
          kind="secondary"
          href={`tel:${localContact.number}`}
          rel="nofollow"
        >
          Speak to a travel expert
        </TextButton>
      </CSSBreakpoint>
    </Group>
    <Group direction="vertical" gap={16}>
      <Heading variant="heading2">
        Our top <i>exclusive</i> offers
      </Heading>
      <OfferList filters={CRUISE_UNIFIED_FILTERS} tileStyle="search" />
    </Group>
  </Group>
}

export default CruiseOfferListNoResults
