import { isCruiseOfferView } from 'checkout/lib/utils/cruises/view'
import { fareTypeLabelMap } from 'constants/flight'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import { getCruiseOfferPriceVariation } from 'lib/cruises/cruiseUtils'
import { calculateDaysBetweenDates } from 'lib/payment/payInInstalmentsUtils'
import { createSelector } from 'reselect'
import { getTourSelectablePurchasableOptionsWithPriceByOccupant } from '../tourV2Selectors'
import { checkoutAccommodationOfferView } from '../view/accommodation'
import { getFlightItemsView } from '../view/flights'
import getCheckoutTotalsView from './getCheckoutTotalsView'

const getCheckoutSignUpOfferDetails = createSelector(
  (state: App.State) => getFlightItemsView(state),
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => state.geo.airports,
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.experience.experiences,
  (state: App.State) => getTourSelectablePurchasableOptionsWithPriceByOccupant(state),
  (state: App.State) => state.flights.searchV2Flights,
  (state: App.State) => state.offer.offers,
  (state: App.State) => getCheckoutTotalsView(state),
  (
    flightViews,
    offerViews,
    airports,
    checkoutItems,
    experiences,
    tourPurchasableOptionsByOccupant,
    flightSearchV2,
    offers,
    checkoutTotals,
  ): App.AccountModalOfferDetails | undefined => {
    const item = checkoutItems[0]

    if (!item) return

    if (item.itemType === 'hotel') {
      const hotelOffer = offers[item.offerId]
      const hotelViews = offerViews.data.filter(view => view.offerType === OFFER_TYPE_HOTEL)
      // Check for required data
      if (!checkoutTotals.hasRequiredData || !hotelOffer || !offerViews.hasRequiredData || !hotelViews?.length) return

      const { grandTotal, savedPercentage } = checkoutTotals.data

      const firstHotelItemView = hotelViews[0].itemViews[0]
      if (firstHotelItemView.kind !== 'le') return

      const isBundled = hotelViews.some(view => view.isBundled)
      const hasFlightItem = checkoutItems.some(item => item.itemType === 'flight')

      return {
        title: hotelOffer.property?.name || firstHotelItemView.primaryTitle,
        duration: item.duration,
        value: grandTotal,
        image: firstHotelItemView.image,
        imageTitle: hotelOffer.property?.name || firstHotelItemView.primaryTitle,
        discountPercentage: savedPercentage > 0 ? savedPercentage : 0,
        isBundledWithFlights: isBundled && hasFlightItem,
      }
    } else if (item.itemType === 'experience') {
      const experience = experiences[item.experienceId]
      if (!checkoutTotals.hasRequiredData) return

      const { grandTotal, savedPercentage } = checkoutTotals.data

      return {
        title: experience.name,
        durationLabel: 'From',
        value: grandTotal,
        image: experience.images[0],
        imageTitle: experience.images[0].title || experience.name,
        discountPercentage: savedPercentage,
      }
    } else if (item.itemType === 'flight') {
      const flightItem = flightViews.data[0].item
      const originAirport = airports.find(airport => airport.code === flightItem.originAirportCode)
      const destinationAirport = airports.find(airport => airport.code === flightItem.destinationAirportCode)

      const journeyId = item.flights[0]?.journeyId

      const journeysV2 = Object.values(flightSearchV2).flatMap(flight => flight?.fares[0])
      const journeyv2 = journeysV2.find(journey => journey?.id === journeyId)

      return {
        title: `${originAirport?.name} to ${destinationAirport?.name}`,
        durationLabel: `${fareTypeLabelMap[flightItem.fareType]} from`,
        value: journeyv2?.price.all.totalRoundTripPrice || 0,
        image: flightItem.flights[0]?.carrierImage,
        imageTitle: flightItem.flights[0]?.carrierImage?.title || '',
        addImagePadding: true,
      }
    } else if (item.itemType === 'tourV1') {
      const tourOfferView = offerViews.data.find(view => view.offerType === OFFER_TYPE_TOUR)

      if (!tourOfferView || !checkoutTotals.hasRequiredData) return

      const { grandTotal, savedPercentage } = checkoutTotals.data

      return {
        title: tourOfferView.mainLabel,
        durationLabel: `${tourOfferView.durationLabel} from`,
        value: grandTotal,
        image: tourOfferView.image,
        imageTitle: tourOfferView.mainLabel,
        discountPercentage: savedPercentage > 0 ? savedPercentage : 0,
      }
    } else if (item.itemType === 'tourV2') {
      const tourOfferView = offerViews.data.find(view => view.offerType === OFFER_TYPE_TOUR_V2)

      if (!tourOfferView || !checkoutTotals.hasRequiredData) return

      const { grandTotal, savedPercentage } = checkoutTotals.data

      const tourPurchasableOptions = tourPurchasableOptionsByOccupant.get(item.occupancy)
      const tourOptions = tourPurchasableOptions ? Array.from(tourPurchasableOptions.values()) : []

      // Get checkout total, tourItem total price or cheapest purchasable option
      const value = grandTotal || item.total || tourOptions[0].totalPrice || 0

      return {
        title: tourOfferView.mainLabel,
        durationLabel: `${tourOfferView.durationLabel} from`,
        duration: item.duration,
        value,
        imageId: item.image.id,
        imageTitle: item.image.title || tourOfferView.mainLabel,
        discountPercentage: savedPercentage,
      }
    } else if (item.itemType === 'car-hire') {
      return {
        title: item.offer.name,
        duration: calculateDaysBetweenDates(item.dropOffDate, item.pickUpDate),
        value: item.totalPrice,
        image: item.offer.vehicle.image,
        imageTitle: item.offer.vehicle.image.title || item.offer.name,
        addImagePadding: true,
      }
    } else if (item.itemType === 'cruise') {
      const cruiseView = offerViews.data.filter(isCruiseOfferView)[0]
      const offer = cruiseView?.offer
      if (!offer || !checkoutTotals.hasRequiredData) return

      const { lowestOverallPriceDetails } = offer.mainDepartureDetails
      const { discountPills } = lowestOverallPriceDetails
      const { primaryPrice } = getCruiseOfferPriceVariation(offer, lowestOverallPriceDetails)

      const { grandTotal, savedPercentage } = checkoutTotals.data

      return {
        title: cruiseView.mainLabel,
        duration: cruiseView.duration,
        value: grandTotal || primaryPrice.total,
        image: cruiseView.image,
        imageTitle: cruiseView.image?.title || cruiseView.mainLabel,
        discountPercentage: savedPercentage || discountPills?.discountPercentage,
      }
    }
  },
)

export default getCheckoutSignUpOfferDetails
