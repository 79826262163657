import { createSelector } from 'reselect'
import { getCruiseItems } from '../cruiseSelectors'
import isDomesticTrip from './isDomesticTrip'
import { getInsuranceProductListKey } from 'lib/insurance/insuranceUtils'
import { getCruisesV1Items } from './cruise'

const getCheckoutInsuranceProductFetchStatus = createSelector(
  (state: App.State) => isDomesticTrip(state),
  (state: App.State) => getCruiseItems(state).length > 0,
  (state: App.State) => getCruisesV1Items(state).length > 0,
  (state: App.State) => state.insurance.productListErrors,
  (state: App.State) => state.insurance.fetchingProductLists,
  (_: App.State, type?: App.InsuranceProductType) => type,
  (isDomestic, hasCruiseItems, hasCruiseV1Items, productLists, productListsFetching, type): { fetching: boolean, error?: string } => {
    const key = getInsuranceProductListKey(type ?? 'travel-insurance', {
      isDomestic,
      isCruise: hasCruiseItems || hasCruiseV1Items,
    })
    return {
      fetching: productListsFetching[key],
      error: productLists[key],
    }
  },
)

export default getCheckoutInsuranceProductFetchStatus
