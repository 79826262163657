import FilterPanelCheckboxGroup, { FilterPanelCheckItem } from 'components/Common/FilterPanel/FilterPanelCheckboxGroup'
import FilterPanelFilterGroup from 'components/Common/FilterPanel/FilterPanelFilterGroup'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import LineAngleUpIcon from 'components/Luxkit/Icons/line/LineAngleUpIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { generateOptionItems, optionMapToOptions } from 'components/OfferList/OfferListFilter/OfferListFilterUtils'
import { replace } from 'connected-react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import { propertyToDestinationSearch, setSearchParamValue } from 'lib/url/searchUrlUtils'
import { rem } from 'polished'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Modal from 'components/Luxkit/Modal/Modal'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import LayoutContainer from 'components/Common/LayoutContainer'

const CategoryContainer = styled.div`
  margin-top: ${rem(16)};
`

interface Props {
  availableFilters: Record<string, Record<string, number>> | undefined;
  label: string;
  selectedFilters: Array<string>;
  search: string;
  searchQueryKey: string;
  place?: App.Place;
}

function InclusionsDropdown(props: Props) {
  const { availableFilters, selectedFilters, label, search, searchQueryKey, place } = props
  const isMobile = useScreenSizeOnly('mobile')
  const [isDropdownOpen, toggleDropDown, , closeDropdown] = useToggle()
  const [localSelectedFilters, setLocalSelectedFilters] = useState<Set<string>>(new Set(selectedFilters))
  const dispatch = useAppDispatch()
  const filterChipRef = useRef<HTMLButtonElement>(null)
  const inclusionItems = useMemo(() => {
    const inclusions = Object.entries(availableFilters ?? {}).reduce((acc, [category, inclusions]) => {
      acc[category] = optionMapToOptions(inclusions ?? {}, {})
      return acc
    }, {} as Record<string, Array<[string, number, number]>>)

    return Object.entries(inclusions).reduce((acc, [category, inclusionOptions]) => {
      acc[category] = generateOptionItems(inclusionOptions, new Set(localSelectedFilters))
      return acc
    }, {} as Record<string, Array<FilterPanelCheckItem>>)
  }, [availableFilters, localSelectedFilters])

  const onFilterTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSelectedFilters((prev) => {
      const newSet = new Set(prev)
      if (e.target.checked) {
        newSet.add(e.target.value)
      } else {
        newSet.delete(e.target.value)
      }
      return newSet
    })
  }, [])

  const onApply = useCallback(() => {
    dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(search, searchQueryKey, Array.from(localSelectedFilters).join(',')), place) }))
    closeDropdown()
  }, [closeDropdown, dispatch, localSelectedFilters, place, search, searchQueryKey])

  const onClose = useCallback(() => {
    onApply()
    closeDropdown()
  }, [closeDropdown, onApply])

  const onReset = useCallback(() => {
    setLocalSelectedFilters(new Set())
  }, [])

  useEffect(() => {
    setLocalSelectedFilters(new Set(selectedFilters))
  }, [selectedFilters])

  const hasAvailableFilters = Object.entries(availableFilters ?? {}).length > 0

  const resetLabel = 'Reset all' + (localSelectedFilters.size ? ` (${localSelectedFilters.size})` : '')
  const chipLabel = label + (localSelectedFilters.size ? ` (${localSelectedFilters.size})` : '')

  return (
    <div>
      <FilterChip selected={!!localSelectedFilters.size} ref={filterChipRef} size="medium" onClick={toggleDropDown} endIcon={isDropdownOpen ? <LineAngleUpIcon /> : <LineAngleDownIcon />}>
        {chipLabel}
      </FilterChip>
      <CSSBreakpoint min="tablet">
        <DropdownList
          size="M"
          triggerRef={filterChipRef}
          anchorRef={filterChipRef}
          open={isDropdownOpen}
          placement="bottom-start"
          onClose={onClose}
          secondaryActionProps={{
            kind: 'tertiary',
            children: 'Reset',
            onClick: onReset,
          }}
          primaryActionProps={{
            children: 'Apply',
            onClick: onApply,
          }}
        >
          <LayoutContainer>
            <FilterPanelFilterGroup allowToggle={false} showDropdown={false} title={label} showBorder={false}>
              {!hasAvailableFilters && <BodyText variant="medium">No available filters</BodyText>}
              {hasAvailableFilters && Object.entries(inclusionItems).map(([category, items]) => (
                <CategoryContainer key={category}>
                  <BodyText variant="medium" weight="bold">{category}</BodyText>
                  <FilterPanelCheckboxGroup
                    key={category}
                    name="inclusions"
                    items={items}
                    onChange={onFilterTypeChange}
                    maxItems={5}
                    allowShowMore={false}
                  />
                </CategoryContainer>
              ))}
            </FilterPanelFilterGroup>
          </LayoutContainer>
        </DropdownList>
      </CSSBreakpoint>
      <CSSBreakpoint max="mobile">
        <Modal
          title={label}
          isOpen={isDropdownOpen && isMobile}
          onClose={closeDropdown}
          primaryActionText="Apply"
          onPrimaryActionClick={onClose}
          secondaryActionText={resetLabel}
          onSecondaryActionClick={onReset}
        >
          {!hasAvailableFilters && <BodyText variant="medium">No available filters</BodyText>}
          {hasAvailableFilters && Object.entries(inclusionItems).map(([category, items]) => (
            <CategoryContainer key={category}>
              <BodyText variant="medium" weight="bold">{category}</BodyText>
              <FilterPanelCheckboxGroup
                key={category}
                name="inclusions"
                items={items}
                onChange={onFilterTypeChange}
                maxItems={5}
                allowShowMore={false}
              />
            </CategoryContainer>
          ))}
        </Modal>
      </CSSBreakpoint>
    </div>
  )
}

export default InclusionsDropdown
