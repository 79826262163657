import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { createSelector } from 'reselect'
import { getTransferMobileAppDiscountTotal } from './experience'
import getTransferItemsView from './getTransferItemsView'

const getTransfersBreakdownView = createSelector(
  (state: App.State) => getTransferItemsView(state),
  (state: App.State) => getTransferMobileAppDiscountTotal(state),
  (viewWithStatus, transferMobileAppDiscountTotals): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) { return { hasRequiredData: viewWithStatus.hasRequiredData, data: [] } }

    const price = sum(viewWithStatus.data.map(itemView => itemView.item.transfer.option!.price ?? 0))

    const transferBreakdownView:App.Checkout.PriceBreakdownView = {
      title: 'Transfers',
      price: price + transferMobileAppDiscountTotals.amount,
      memberPrice: sum(viewWithStatus.data.map(itemView => itemView.item.transfer.option!.memberPrice ?? 0)),
      additionalInfoText: [],
      mobileAppDiscount: transferMobileAppDiscountTotals,
      items: viewWithStatus.data.filter(excludeNullOrUndefined).map<App.Checkout.TransferItemBreakdownView>(itemView => {
        return {
          itemId: itemView.item.itemId,
          title: itemView.item.transfer.title ?? '',
          price: itemView.item.transfer.option!.price + (itemView.item.transfer.option!.discounts?.app?.amount ?? 0),
          memberPrice: itemView.item.transfer.option!.memberPrice,
          itemType: itemView.item.itemType,
          offerId: itemView.item.transfer.option!.id,
          additionalInfo: [
            { text: itemView.item.transfer.option!.name },
            { text: pluralizeToString('passenger', itemView.item.transfer.option!.maxPassengers) },
            { text: `Maximum ${pluralizeToString('bag', itemView.item.transfer.option!.maxCheckedBags)}` },
          ],
          additionalElements: [],
        }
      }),
    }

    return {
      hasRequiredData: true,
      data: [transferBreakdownView],
    }
  },
)

export default getTransfersBreakdownView
