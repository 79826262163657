import React, { PropsWithChildren, useContext, useMemo } from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { formatOccupantsShort } from 'lib/offer/occupancyUtils'
import { formToObject } from 'lib/forms/formToObject'
import FlightsDatesModalFooter from '../FlightsDatePicker/FlightsDatesModalFooter'

interface Props {
  title: string;
  showDates?: boolean;
  showTravellers?: boolean;
}

function FlightSearchMenuMobile(props: PropsWithChildren<Props>) {
  const {
    title,
    children,
    showDates,
    showTravellers,
  } = props

  const { resolve } = useModalElementContext<any>()
  const { occupants } = useContext(FlightSearchWidgetStateContext)
  const travellerLabel = useMemo(() => formatOccupantsShort(occupants), [occupants])

  return (
    <ModalBase mode="drawer">
      <form onSubmit={e => {
        e.preventDefault()
        const values = formToObject(e.currentTarget)
        // whatever form values our children have, send them back through
        resolve(values)
      }}
      >
        <ModalHeader title={title} />
        <ModalBody>
          <ModalContent>
            {children}
          </ModalContent>
        </ModalBody>
        <ModalFooter
          primaryActionProps={{
            children: 'Continue',
            type: 'submit',
          }}
        >
          {showDates && <FlightsDatesModalFooter />}
          {showTravellers && travellerLabel && (
            <BodyText variant="medium" weight="bold" align="center">
              {travellerLabel}
            </BodyText>
          )}
        </ModalFooter>
      </form>
    </ModalBase>
  )
}

export default FlightSearchMenuMobile
