import { getAutocompleteSuggestions, GoogleAutocompletePlaceItem } from 'api/search'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'

interface AutocompleteParams {
  latitude?: number;
  longitude?: number;
  radius?: number;
}

function usePlaceAutocomplete(textInput: string, params: AutocompleteParams) {
  const [predictions, setPredictions] = useState<Array<GoogleAutocompletePlaceItem>>([])
  const [isLoading, setIsLoading] = useState(false)

  const debouncedGetAutocompleSuggestions = useMemo(() => debounce((textInput: string, params: AutocompleteParams) => {
    const hasLatLong = !!(params.latitude && params.longitude)
    getAutocompleteSuggestions(textInput, {
      origin: hasLatLong ? `${params.latitude},${params.longitude}` : undefined,
      location: hasLatLong ? `${params.latitude},${params.longitude}` : undefined,
      radius: params.radius,
    }).then(setPredictions)
      .finally(() => setIsLoading(false))
  }, 500), [])

  useEffect(() => {
    if (!textInput) return
    setIsLoading(true)
    debouncedGetAutocompleSuggestions(textInput, params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textInput, params?.latitude, params?.longitude])

  return {
    predictions,
    isLoading,
  }
}

export default usePlaceAutocomplete
