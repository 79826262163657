import React, { useContext, useMemo } from 'react'
import LunarSiteTakeoverBackground from 'components/Common/SiteTakeover/LunarSiteTakeoverBackground'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LunarSiteTakeoverOfferUSPs from 'components/Common/SiteTakeover/LunarSiteTakeoverOfferUSPs'
import moment, { Moment } from 'moment'
import GeoContext from 'contexts/geoContext'
import config from 'constants/config'
import ValentineSiteTakeoverBackground from 'components/Common/SiteTakeover/ValentineSiteTakeoverBackground'
import Image from 'components/Common/Image'
import AccountAccessPromptIcon from 'components/Account/AccountAccessPrompt/AccountAccessPromptIcon'
import EuropeanBeachBreakTakeoverBackground from 'components/Common/SiteTakeover/EuropeanBeachBreakTakeoverBackground'
import BeachBreakLogo from 'components/Common/SiteTakeover/BeachBreakLogo'
import Group from 'components/utils/Group'
import MarchTravelFrenzyTakeoverBackground from 'components/Common/SiteTakeover/MarchTravelFrenzyTakeoverBackground'
import { MarchTravelFrenzyLogo } from 'components/Common/SiteTakeover/MarchTravelFrenzyLogo'
import Plural from 'components/utils/Formatters/Plural'
import { daysUntilDate } from 'lib/datetime/dateUtils'
import memoize from 'lib/memoize/memoize'
import { TOUR_ASIA_CAMPAIGN, TOUR_V2_SEARCH_HERO_IMAGE_ASIA_CAMPAIGN } from 'constants/tours'
import TourAsiaCampaignProfileImage from 'components/Tours/Campaign/TourAsiaCampaignProfileImage'
import TourAsiaCampaignTakeoverBackground from 'components/Common/SiteTakeover/TourAsiaCampaignTakeoverBackground'
import TourAsiaCampaignOfferBanner from 'components/Tours/Campaign/TourAsiaCampaignOfferBanner'
import TourAsiaCampaignSearchBanner from 'components/Tours/Campaign/TourAsiaCampaignSearchBanner'
import TourAsiaCampaignHeroCarouselSubtitle from 'components/Tours/Campaign/TourAsiaCampaignHeroCarouselSubtitle'
import styled from 'styled-components'
import { rem } from 'polished'

export type SiteTakeoverBackground = 'default' |'hero' | 'search'
export type SiteTakeoverContext = 'homepage' | 'tours'

/**
 * Note: This interface has been hastile made to work with one type of site takeover
 * As we get more site takovers, hopefully the general structure will reveal itself better
 * Do not take this setup as gospel.
 */
interface SiteTakeover {
  /**
   * ID uniquely identifying the take over
   */
  id: string;
  /**
   * Date/time the take over starts
   */
  startDate: Moment;
  /**
   * Date/time the take over ends
   */
  endDate: Moment;
  /**
   * An image id to use as a base background
   */
  imageId: string;
  /**
   * The primary colour for the takeover
   * Will be used in a few places
   */
  primaryColour: string;
  /**
   * A background element that accepts a background type and gravity
   */
  backgroundElement: React.FunctionComponent<{
    background: SiteTakeoverBackground;
    className?: string;
  }>;
  /**
   * The general palette this takeover requires
   */
  palette: App.Design.PaletteType;
  heroCarousel: {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    logo?: React.ReactNode;
    palette?: App.Design.PaletteType;
  };
  pageBannerContent?: React.ReactNode;
  /**
   * Whether the hero carousel filters will still be shown (default false)
   */
  showHeroFilters?: boolean;
  offerPage?: {
    content: React.ReactNode;
      /**
   * Additional USP's for the offer page
   */
    usps?: React.ReactNode;
    /**
     * List of offer types the offer page banner applies to
     */
    offerTypes: Set<App.OfferType>;
    /**
     * List of Campaign tags the offer page banner applies to
     */
    campaignTags?: Set<string>;
  }
  searchPageBannerContent: React.ReactNode;
  /**
   * List of regions this take over will run on
   */
  regions: Set<string>;
  /**
   * List of brands this take over will run on
   */
  brands: Set<App.BrandName>;
  /**
   * Retrieve specific context because it could have many at same time
   * @default homepage
   */
  context?: SiteTakeoverContext;
}

const LunarNewYearTakeover: SiteTakeover = {
  id: 'lunarnewyear',
  startDate: moment('2025-01-22T00:00:00+1100'),
  endDate: moment('2025-02-06T23:59:00+1100'),
  brands: new Set(['luxuryescapes']),
  regions: new Set(['SG', 'HK', 'CN', 'KR', 'MO', 'MY', 'TW', 'VN']),
  imageId: 'gidua9dchhephstwu3jp',
  primaryColour: '#F84916',
  palette: 'default',
  heroCarousel: {
    title: 'Happy Lunar New Year',
    subtitle: 'Enjoy exclusive offers! Valid Jan 22 - 6 Feb only',
  },
  offerPage: {
    content: <b>Enjoy Lunar New Year exclusive offers! Only valid Jan 22 - 6 Feb</b>,
    usps: <LunarSiteTakeoverOfferUSPs />,
    offerTypes: new Set(['hotel']),
  },
  backgroundElement: LunarSiteTakeoverBackground,
  pageBannerContent: <b>Enjoy Lunar New Year exclusive offers! Only valid Jan 22 - 6 Feb</b>,
  searchPageBannerContent: <VerticalSpacer gap={2}>
    <Heading variant="heading2">Happy Lunar New Year</Heading>
    <BodyText variant="large">Enjoy exclusive offers! Only valid Jan 22 - 6 Feb</BodyText>
  </VerticalSpacer>,

}

export const ValentineTakeover: SiteTakeover = {
  id: 'valentine',
  startDate: moment('2025-02-12T08:00:00+1100'),
  endDate: moment('2025-02-15T02:59:00+1100'),
  brands: new Set(['luxuryescapes']),
  regions: new Set(['SG', 'HK', 'AE', 'ZA', 'IN']),
  imageId: 'gidua9dchhephstwu3jp',
  primaryColour: '#F84916',
  palette: 'default',
  heroCarousel: {
    title: 'Happy Valentine\'s Day',
    subtitle: <>Use code <b>LOVEISINTHEAIR</b> at checkout for 5% extra love on your hotel bookings. Valid 12-14th Feb 2025</>,
  },
  offerPage: {
    content: <>Use code <b>LOVEISINTHEAIR</b> at checkout for 5% extra love on your hotel bookings. Valid 12-14th Feb 2025</>,
    offerTypes: new Set(['hotel']),
  },
  backgroundElement: ValentineSiteTakeoverBackground,
  pageBannerContent: <>Use code <b>LOVEISINTHEAIR</b> at checkout for 5% extra love on your hotel bookings. Valid 12-14th Feb 2025</>,
  searchPageBannerContent: <VerticalSpacer gap={2}>
    <Heading variant="heading2">Happy Valentine's Day</Heading>
    <BodyText variant="large">Use code <b>LOVEISINTHEAIR</b> at checkout for 5% extra love on your hotel bookings. Valid 12-14th Feb 2025</BodyText>
  </VerticalSpacer>,
}

export const EuropeanBeachBreakTakeover: SiteTakeover = {
  id: 'europeanbeachbreak',
  startDate: moment('2025-03-04T00:00:00+1100'),
  endDate: moment('2025-04-11T23:59:00+1100'),
  brands: new Set(['luxuryescapes']),
  regions: new Set(['GB', 'FR', 'DE', 'IE', 'IT', 'NL', 'ES']),
  imageId: 'jtvq880t6g71mmagdiv',
  primaryColour: '#02668FE5',
  palette: 'inverse',
  heroCarousel: {
    title: <Heading variant="heading2">Your summer break starts here</Heading>,
    subtitle: <BodyText variant="large" colour="neutral-one">LIMITED TIME ONLY</BodyText>,
    logo: <BeachBreakLogo height={79} />,
  },
  backgroundElement: EuropeanBeachBreakTakeoverBackground,
  pageBannerContent: <Group direction="horizontal" gap={16} verticalAlign="center">
    <BeachBreakLogo height={46} />
    <VerticalSpacer gap={2}>
      <BodyText variant="large" weight="bold" colour="neutral-one">Your summer break starts here</BodyText>
    </VerticalSpacer>
  </Group>,
  searchPageBannerContent: <Group direction="horizontal" gap={16} verticalAlign="center">
    <BeachBreakLogo height={79} />
    <VerticalSpacer gap={2}>
      <Heading variant="heading2">Your summer break starts here</Heading>
      <BodyText variant="large" colour="neutral-one">LIMITED TIME ONLY</BodyText>
    </VerticalSpacer>
  </Group>,
  offerPage: {
    content: <Group direction="horizontal" gap={16} verticalAlign="center" horizontalAlign="start">
      <BeachBreakLogo height={46} />
      <BodyText variant="medium" weight="bold" colour="neutral-one">Your summer break starts here</BodyText>
    </Group>,
    offerTypes: new Set([]),
  },
}

const getDaysText = memoize((days: number) => {
  if (days === 0) {
    return 'Last day of unmissable offers'
  }
  return <><Plural count={days} singular="day" withCount /> of unmissable offers</>
})

function MarchTravelFrenzyContent({ daysLeft }: { daysLeft: number }) {
  const title = 'March Travel Frenzy'

  const subtitle = (
    <BodyText variant="large" colour="neutral-one">
      {getDaysText(daysLeft)}
    </BodyText>
  )

  const heroCarousel = {
    title,
    subtitle,
    logo: <MarchTravelFrenzyLogo variant="hero" daysRemaining={daysLeft} />,
  }

  const pageBannerContent = (
    <Group direction="horizontal" gap={16} verticalAlign="center">
      <MarchTravelFrenzyLogo variant="pageBanner" daysRemaining={daysLeft} />
      <VerticalSpacer gap={2}>
        <Heading variant="heading2">{title}</Heading>
      </VerticalSpacer>
    </Group>
  )

  const searchPageBannerContent = (
    <Group direction="horizontal" gap={16} verticalAlign="center">
      <MarchTravelFrenzyLogo variant="searchBanner" daysRemaining={daysLeft} />
      <VerticalSpacer gap={2}>
        <Heading variant="heading2">{title}</Heading>
        {subtitle}
      </VerticalSpacer>
    </Group>
  )

  return {
    heroCarousel,
    pageBannerContent,
    searchPageBannerContent,
  }
}

const getMarchTravelFrenzyContent = memoize(() => {
  const daysLeft = daysUntilDate(new Date('2025-03-11T23:59:00+1100'))
  return MarchTravelFrenzyContent({ daysLeft })
})

export const MarchTravelFrenzyTakeover: SiteTakeover = {
  id: 'marchtravelfrenzy',
  startDate: moment('2025-03-06T08:00:00+1100'),
  endDate: moment('2025-03-11T23:59:00+1100'),
  brands: new Set(['luxuryescapes']),
  regions: new Set(['SG', 'HK', 'ZA']),
  imageId: '',
  primaryColour: '#F84916',
  palette: 'inverse',
  backgroundElement: MarchTravelFrenzyTakeoverBackground,
  ...getMarchTravelFrenzyContent(),
}

export const TourBestAsiaPromoTakeover: SiteTakeover = {
  id: 'tourbestasia',
  startDate: moment('2025-03-17T09:00:00+1100'),
  endDate: moment('2025-04-28T23:59:00+1100'),
  brands: new Set(['luxuryescapes']),
  regions: new Set(['AU', 'NZ', 'SG', 'HK', 'US', 'GB', 'FR', 'IT', 'ES', 'NL', 'DE', 'IE', 'CA']),
  imageId: TOUR_V2_SEARCH_HERO_IMAGE_ASIA_CAMPAIGN,
  palette: 'inverse',
  primaryColour: '',
  heroCarousel: {
    title: <Heading variant="heading2"><i>Explore</i> the best of Asia</Heading>,
    subtitle: <TourAsiaCampaignHeroCarouselSubtitle />,
    logo: <TourAsiaCampaignProfileImage />,
    palette: 'inverse',
  },
  backgroundElement: TourAsiaCampaignTakeoverBackground,
  offerPage: {
    content: <TourAsiaCampaignOfferBanner />,
    offerTypes: new Set(['direct_tour']),
    campaignTags: new Set([TOUR_ASIA_CAMPAIGN]),
  },
  searchPageBannerContent: <TourAsiaCampaignSearchBanner />,
  context: 'tours',
}

const CircularImage = styled(Image)`
  width: ${rem(68)};
  height: ${rem(68)};
  border-radius: 50%;
`

export const ShangriLaTakeover: SiteTakeover = {
  id: 'shangrila',
  startDate: moment('2025-04-04T09:00:00+1100'),
  endDate: moment('2025-12-31T23:59:00+1100'), // It presumably won't run this long, but not sure of end date yet...
  brands: new Set(['luxuryescapes']),
  regions: new Set(['SG', 'HK', 'MY', 'ZA', 'UA', 'SA']),
  palette: 'inverse',
  heroCarousel: {
    title: <Group direction="horizontal" gap={24} verticalAlign="center">
      <CircularImage id="2e9isoqqlyyqebmxc9o" quality="best" enhancedFormats={false} />
      <VerticalSpacer gap={2}>
        <Heading variant="heading2" colour="exclusive">Exclusive Shangri-La offers</Heading>
        <BodyText variant="large" colour="neutral-one">LIMITED TIME ONLY</BodyText>
      </VerticalSpacer>
    </Group>,
  },
  showHeroFilters: true,
  // Maybe the below fields should be optional?
  imageId: '',
  primaryColour: '',
  searchPageBannerContent: null,
  backgroundElement: () => null,
}

const takeovers = [
  LunarNewYearTakeover,
  ValentineTakeover,
  EuropeanBeachBreakTakeover,
  MarchTravelFrenzyTakeover,
  TourBestAsiaPromoTakeover,
  ShangriLaTakeover,
]

function useCurrentSiteTakeover(context?: string): SiteTakeover | undefined {
  const { currentRegionCode } = useContext(GeoContext)

  const currentTakeover = useMemo(() => {
    const now = moment()
    return takeovers.find(take => {
      if (typeof window !== 'undefined' && !!window.__DEBUG_TAKEOVER_ID__) {
        return take.id.toLowerCase() === window.__DEBUG_TAKEOVER_ID__.toLowerCase()
      }
      return take.brands.has(config.BRAND) &&
      take.regions.has(currentRegionCode) &&
      now.isBetween(take.startDate, take.endDate) &&
      context === take.context
    })
  }, [currentRegionCode, context])

  return currentTakeover
}

export function useHeaderIcon(currentRegion: string, promoDisplayConfig?: App.PromoDisplayConfig): React.ReactNode {
  const HeaderIcon = useMemo(() => {
    if (promoDisplayConfig?.codeName === 'LOVEISINTHEAIR' && ValentineTakeover.regions.has(currentRegion)) {
      return <Image width={90} id="ravf7iouyaoeqloomvue" enhancedFormats={false}/>
    } else {
      return <AccountAccessPromptIcon/>
    } }, [promoDisplayConfig, currentRegion])

  return HeaderIcon
}

export default useCurrentSiteTakeover
