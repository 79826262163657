import request, { authOptions } from 'api/requestUtils'
import { actions } from '@luxuryescapes/contract-svc-agent'

type SetPayoutDetailsResponse = actions['createPayoutDetails']['responses']['201']['content']['application/json']
type SetPayoutDetailsRequest = actions['createPayoutDetails']['parameters']['body']['createPayoutDetailsBody']

type PayoutDetails = {
  accountName: string
  accountNumber: string
}

 interface AUPayoutDetailsBody extends PayoutDetails {
  bsb?: string
}

 interface NZPayoutDetailsBody extends PayoutDetails {
  swift?: string
}

 interface GBPayoutDetailsBody extends PayoutDetails {
  sortCode?: string
}

export type AgentHubSetPayoutDetailsBody = AUPayoutDetailsBody | NZPayoutDetailsBody | GBPayoutDetailsBody

export async function requestSetAgentHubPayoutDetails(payoutDetails: AgentHubSetPayoutDetailsBody) {
  function getPayoutDetails(): { bsb: string } | { swift: string } {
    if ('bsb' in payoutDetails && payoutDetails.bsb) {
      return { bsb: payoutDetails.bsb }
    }
    if ('swift' in payoutDetails && payoutDetails.swift) {
      return { swift: payoutDetails.swift }
    }
    return {
      bsb: '',
    }
  }

  const requestBody: SetPayoutDetailsRequest = {
    payout: {
      accountName: payoutDetails.accountName,
      accountNumber: payoutDetails.accountNumber,
      bank: '',
      ...getPayoutDetails(),
    },
  }

  const response = await request.post<SetPayoutDetailsResponse, SetPayoutDetailsRequest>(
    '/api/agent/payout',
    requestBody,
    authOptions(),
  )
  return response.result
}
