import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchSiteBanner } from 'actions/ContentActions'
import PageBanner from 'components/Luxkit/Banners/PageBanner'
import { useAppHeaderObserver } from 'hooks/useAppLayoutObservers'
import switchFunc from 'lib/function/switchFunc'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import LineExclamationTriangleIcon from 'components/Luxkit/Icons/line/LineExclamationTriangleIcon'
import ModalContext from 'contexts/ModalContext'
import NotificationBannerV2Modal from './NotificationBannerV2Modal'
import { getDismissedBannerIds, isBannerDismissed, recordSiteBannerDismissal } from 'storage/siteBanner'
import LineCheckCircleIcon from 'components/Luxkit/Icons/line/LineCheckCircleIcon'

const getBannerKind = switchFunc<React.ComponentProps<typeof PageBanner>['kind'], App.SiteBanner['bannerColor']>({
  warning: 'warning',
  error: 'critical',
  success: 'promotional-primary',
}, 'informational-primary')

const getBannerIcon = switchFunc<React.ComponentProps<typeof PageBanner>['icon'], App.SiteBanner['bannerIcon']>({
  warning: <LineExclamationTriangleIcon />,
  error: <LineExclamationTriangleIcon />,
  check: <LineCheckCircleIcon />,
}, <LineInfoCircleIcon />)

function NotificationBannerV2() {
  const dispatch = useAppDispatch()

  const banners = useAppSelector(state => state.content.siteBanner.data)

  const dismissedBannerIds = getDismissedBannerIds()
  const banner = banners?.find(b => !dismissedBannerIds.has(b.id))

  const [isDismissed, setIsDismissed] = useState(false)

  useEffect(() => {
    dispatch(fetchSiteBanner())
  }, [dispatch])

  useEffect(() => {
    if (banner) {
      setIsDismissed(isBannerDismissed(banner.id))
    }
  }, [banner])

  useEffect(() => {
    if (banner) {
      const handleVisibilityChange = () => {
        if (!document.hidden) {
          setIsDismissed(isBannerDismissed(banner.id))
        }
      }

      document.addEventListener('visibilitychange', handleVisibilityChange)

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
  }, [banner])

  const containerRef = useRef<HTMLDivElement | null>(null)
  useAppHeaderObserver(containerRef as React.RefObject<HTMLDivElement>)

  const showModal = useContext(ModalContext)

  const openBannerModal = useCallback(() => {
    if (banner) {
      showModal(<NotificationBannerV2Modal title={banner.modalTitle} content={banner.modalDescription} />)
    }
  }, [banner, showModal])

  const dismiss = useCallback(() => {
    if (banner) {
      setIsDismissed(true)
      recordSiteBannerDismissal(banner.id, banner.endDate)
    }
  }, [banner])

  if (!banner || isDismissed) {
    return null
  }

  return <PageBanner
    ref={containerRef}
    id="notification-page-banner"
    kind={getBannerKind(banner.bannerColor)}
    icon={getBannerIcon(banner.bannerIcon)}
    description={<>
      <b>{banner.bannerTitle}</b>{banner.bannerOverview}
    </>}
    onDismiss={dismiss}
    tabletAlign="center"
    primaryActionButtonProps={banner.modalDescription ? { onClick: openBannerModal, children: 'Read more' } : undefined}
  />
}

export default NotificationBannerV2
