import { getExperienceMobileAppDiscountTotal, getTransferMobileAppDiscountTotal } from 'checkout/selectors/view/experience'
import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { isExperienceItem } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import getExperienceItemsView from '../view/getExperienceItemsView'
import getTransferItemsView from '../view/getTransferItemsView'

export const isStandaloneExperiences = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.length > 0 && items.every(isExperienceItem),
)

export const getExperienceTotals = createSelector(
  (state: App.State) => getExperienceItemsView(state),
  (state: App.State) => getExperienceMobileAppDiscountTotal(state),
  (viewWithStatus, mobileAppDiscountTotals): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)

    const taxesAndFees = sum(definedItems.flatMap(item => item.ticketViews.map(view => view.totals.taxesAndFees)))

    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => itemView.totals.price),
        memberPrice: 0,
        value: sum(definedItems, itemView => itemView.totals.value ?? 0),
        surcharge: 0,
        taxesAndFees,
        mobileAppDiscount: mobileAppDiscountTotals,
        memberValue: 0,
        extraGuestSurcharge: 0,
      },
    }
  },
)

export const getTransferTotals = createSelector(
  (state: App.State) => getTransferItemsView(state),
  (state: App.State) => getTransferMobileAppDiscountTotal(state),
  (viewWithStatus, mobileAppDiscountTotals): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)
    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => itemView.item.transfer.option?.price ?? 0),
        memberPrice: 0,
        value: sum(definedItems, itemView => itemView.item.transfer.option?.price ?? 0),
        surcharge: 0,
        taxesAndFees: sum(definedItems, itemView => itemView.item.transfer.option?.taxesAndFees ?? 0),
        mobileAppDiscount: mobileAppDiscountTotals,
        memberValue: 0,
        extraGuestSurcharge: 0,
      },
    }
  },
)
