import React, { ComponentProps, useContext } from 'react'

import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import TextLink from 'components/Luxkit/TextLink'
import ContactContext from 'contexts/contactContext'
import ModalContext from 'contexts/ModalContext'
import RequestAgentCallbackModal from 'supportAssistant/components/RequestAgentCallback/Modals/RequestAgentCallbackModal'

interface Props {
  minified: boolean
}

export default function InfantSeatNoticeMessage(props: Props) {
  const { minified } = props

  const contact = useContext(ContactContext)
  const showModal = useContext(ModalContext)

  const contactUsText = 'contact us 24/7'
  let contactUsContent = <TextLink href={`tel:${contact.local.number}`}>{contactUsText}</TextLink>

  const bannerProps: Partial<ComponentProps<typeof MessageBanner>> = {}
  if (!minified) {
    contactUsContent = <>{contactUsText}</>

    bannerProps.heading = 'Travel with infant?'
    bannerProps.primaryActionButtonProps = {
      children: 'Request a callback',
      size: 'small',
      onClick: () => showModal(<RequestAgentCallbackModal type="HOTELS_AND_VILLAS"/>),
    }

    bannerProps.secondaryActionButtonProps = {
      children: contact.local.humanReadable,
      href: `tel:${contact.local.number}`,
      size: 'small',
    }
  }

  return (
    <MessageBanner
      kind="warning"
      description={
        <>
          1 adult can travel with 1 infant on their lap. To book a seat for an
          infant, {contactUsContent}.
        </>
      }
      {...bannerProps}
    />
  )
}
