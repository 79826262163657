import { toggleCustomerView } from 'actions/AgentHubActions'
import SwitchButton from 'components/Luxkit/Switch/SwitchButton'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import React, { useCallback } from 'react'

function AgentHubCustomerViewSwitch() {
  const customerViewEnabled = useAppSelector(state => state.agentHub.customerView)
  const dispatch = useAppDispatch()

  const handleChange = useCallback(() => {
    dispatch(toggleCustomerView())
  }, [dispatch])

  return <SwitchButton
    checked={customerViewEnabled}
    onChange={handleChange}
  >
    Customer view
  </SwitchButton>
}

export default AgentHubCustomerViewSwitch
