import React, { PropsWithChildren } from 'react'
import { Route } from 'react-router-dom'

interface Props {
  code: number;
}

function HttpStatus({ code, children }: PropsWithChildren<Props>) {
  return <Route render={({ staticContext }) => {
    if (staticContext) {
      staticContext.statusCode = code
    }
    return children
  }}
  />
}

export default HttpStatus
