import React, { Fragment } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { UserPreferenceDestination, UserPreferenceHolidayOrBrands } from '../types'
import DestinationDisplayItem from './TravelPreferencesDestinationsGrid'
import UserPreferenceItem from './TravelPreferencesItemsGrid'

const StaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${rem(16)};
  grid-row-gap: ${rem(24)};
  justify-content: space-between;

  ${mediaQueryUp.tablet} {
    grid-column-gap: ${rem(12)};
    grid-template-columns: repeat(3, 1fr);
  }
  ${mediaQueryUp.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const isDestinationItem = (item: UserPreferenceHolidayOrBrands | UserPreferenceDestination): item is UserPreferenceDestination => {
  return 'placeId' in item
}

interface Props {
  showLabel?: boolean;
  items: Array<UserPreferenceHolidayOrBrands | UserPreferenceDestination>;
  onSelect: (selection: UserPreferenceHolidayOrBrands | UserPreferenceDestination) => void;
  selectedId: Set<string>;
}

function TravelPreferencesImageItemsGrid(props: Props) {
  const { showLabel = true, items, onSelect, selectedId } = props

  return (
    <StaysGrid>
      {items.map((item) => (
        <Fragment key={isDestinationItem(item) ? item.placeId : item.id}>
          {isDestinationItem(item) &&
            <DestinationDisplayItem
              key={`DestinationDisplayItem-${item.placeId}`}
              item={item}
              isSelected={selectedId.has(item.placeId)}
              onSelect={onSelect}
              showLabel={showLabel}
            />}
          {!isDestinationItem(item) &&
            <UserPreferenceItem
              key={`UserPreferenceItem-${item.id}`}
              item={item}
              isSelected={selectedId.has(item.id)}
              onSelect={onSelect}
              showLabel={showLabel}
            />}
        </Fragment>
      ))}
    </StaysGrid>
  )
}

export default TravelPreferencesImageItemsGrid
