import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getPartnershipModifierAmount from './getPartnershipModifierAmount'
import getDiscountTotal from './getDiscountTotal'
import getPayableTotal from './getPayableTotal'
import { AGENT_HUB_PAYMENT_MODE } from 'constants/payment'

const getPayableAfterModifiers = createSelector(
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => getDiscountTotal(state),
  (state: App.State) => getPartnershipModifierAmount(state),
  (state: App.State) => state.checkout.payment.agentHubPaymentMode,
  (payableTotal, promoDiscountAmount, partnershipModifierAmount, agentHubPaymentMode): number => {
    if (agentHubPaymentMode === AGENT_HUB_PAYMENT_MODE.PAY_GROSS_AMOUNT) {
      return floatify(payableTotal - partnershipModifierAmount)
    }

    if (promoDiscountAmount > (payableTotal + partnershipModifierAmount)) {
      return 0
    }
    return floatify(payableTotal - promoDiscountAmount - partnershipModifierAmount)
  },
)

export default getPayableAfterModifiers
