import { useContext } from 'react'

import { useTrips } from './api/trip'

import TripContext from 'tripPlanner/contexts/TripContext'
import { UserPermissions } from 'tripPlanner/types/common'
import { getTripPermissions } from 'tripPlanner/utils'

export const useTripPermissions = (tripId?: string): UserPermissions => {
  const tripContext = useContext(TripContext)

  const { isLoading, data: trips } = useTrips({ enabled: !tripContext })

  if (tripContext) {
    return tripContext.permissions
  }

  const trip = trips?.find((trip) => trip.id === tripId)

  if (isLoading || !trip) {
    return {
      canEdit: false,
      canAdmin: false,
      canPublish: false,
    }
  }

  return getTripPermissions(trip)
}
