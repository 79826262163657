import { useContext, useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchTourSearchFacets } from 'actions/OfferActions'
import Places, { PlacesByPlaceId } from 'constants/places'
import getObjectKey from 'lib/object/getObjectKey'
import GeoContext from 'contexts/geoContext'
import { OFFER_TYPE_TOUR_V2 } from 'constants/offer'

const MIN_OFFER_CARD_TO_SHOW = 3

interface Props {
  category?: string;
  places?: Array<App.Place>;
  flashOffer?: boolean;
  style?: string;
  onSale?: boolean;
}

export interface TourDestination {
  id: string;
  name: string;
}

interface TourFacetResult {
  locations: Map<string, TourDestination>;
  groupTypes: Map<string, number>;
  categories: Map<string, number>;
  productTypes: Map<string, number>;
  tourStyles: Map<string, number>;
}

interface Options {
  disabled?: boolean;
}

function useTourFacetLocationCategory(props: Props, options: Options = {}): App.ApiCallState<TourFacetResult> {
  const { category, places, flashOffer, style, onSale } = props
  const dispatch = useAppDispatch()

  const { currentRegionCode } = useContext(GeoContext)

  const key = useMemo(() => {
    return getObjectKey({
      placeId: Places.Anywhere.id,
      region: currentRegionCode,
      flashOffer: flashOffer ? true : undefined,
      tourStyle: style ?? undefined,
      onSale: onSale ? true : undefined,
      category,
    })
  }, [category, currentRegionCode, flashOffer, style, onSale])
  const locationsCategory = useAppSelector((state) => state.tour.tourFacets[key])

  useEffect(() => {
    if (places === undefined || places?.length > 0 && !options.disabled) {
      const categories = category ? [category] : undefined
      const tourStyles = style ? [style] : undefined
      dispatch(fetchTourSearchFacets({
        destinationId: Places.Anywhere.id,
        offerTypes: [OFFER_TYPE_TOUR_V2],
        categories,
        flashOffer,
        tourStyles,
        onSale,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, flashOffer, style, onSale])

  const toursFacetResult = useMemo(() => {
    const locations = new Map()
    const categories = new Map()
    const groupTypes = new Map()
    const productTypes = new Map()
    const tourStyles = new Map()
    if (locationsCategory?.data && !locationsCategory.fetching) {
      locationsCategory.data.locations
        ?.filter(location =>
          !!(category || flashOffer || style || onSale) && location.count >= MIN_OFFER_CARD_TO_SHOW,
        )
        ?.sort((a, b) => b.count - a.count)
        ?.forEach(location => {
          const place = PlacesByPlaceId[location.value] ?? { id: location.value, name: location.label }
          locations.set(place.id, place)
        })
      locationsCategory.data.categories?.forEach(category => {
        categories.set(category.value, category.count)
      })
      locationsCategory.data.groupTypes?.forEach(groupType => {
        groupTypes.set(groupType.value, groupType.count)
      })
      locationsCategory.data.offerTypes?.forEach(offerType => {
        productTypes.set(offerType.value, offerType.count)
      })
      locationsCategory.data.tourStyles?.forEach(tourStyle => {
        tourStyles.set(tourStyle.value, tourStyle.count)
      })
    }
    return {
      fetching: locationsCategory?.fetching,
      locations,
      categories,
      groupTypes,
      productTypes,
      tourStyles,
    }
  }, [locationsCategory, category, flashOffer, style, onSale])

  return toursFacetResult
}

export default useTourFacetLocationCategory
