import clsx from 'clsx'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import SearchFormTextField from 'components/SearchV2/Components/SearchFormField/SearchFormTextField'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import RecentSearchSuggestions from '../SearchForm/SearchLocationInput/RecentSearchSuggestions'
import SearchMenuListItem from '../SearchForm/SearchMenu/SearchMenuListItem'
import SearchPlaceSelectList from '../SearchPlaceSelect/SearchPlaceSelectList'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import { rem } from 'polished'
import Caption from 'components/Luxkit/Typography/Caption'
import { take } from 'lib/array/arrayUtils'

const NUMBER_OF_POPULAR_PLACES_DISPLAYED = 9

const PlaceControl = styled.div`
  width: 100%;

  &:not(.full-width) {
    position: relative;
  }
`

const SearchMenuListItemContainer = styled.div`
  width: ${rem(325)};
  display: flex;
  column-gap: ${rem(40)};
  flex-wrap: wrap;

  &.horizontal {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
  }
`

const SearchItemContainer = styled.div`
  display: flex;
  column-gap: ${rem(40)};
  flex-wrap: wrap;
`

const RecentSearchSuggestionsContainer = styled.div`
  margin-bottom:  ${rem(28)};
`

const StyledCaption = styled(Caption)`
  padding: 0;
`

interface Props {
  /**
   * @default fill-anchor
   */
  size?: ComponentProps<typeof DropdownList>['size']
  className?: string;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: App.SearchItem;
  required?: boolean;
  label: string;
  placeholder: string;
  localInputRef: React.RefObject<HTMLInputElement>;
  searchPhrase: string;
  onSearchPhraseChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpen: (e: any) => void;
  onClearClick: () => void;
  finalOpen: boolean;
  closeDropdown: () => void;
  showPlaceholder: boolean;
  recentSearches: Array<App.FullRecentSearch>;
  selectedDropdown: number | undefined;
  onRecentSearchDelete: (item: App.FullRecentSearch) => void;
  onRecentSearchClick: (item: App.FullRecentSearch) => void;
  placeholderTitle?: string;
  placeholdersSearches: Array<App.SearchItem>;
  onLocationSelect: (item?: App.SearchItem) => void;
  handleResultsChange: (results: Array<App.SearchItem>, searchPhrase: string) => void;
  placeTypes?: Array<App.SearchPlaceType>;
  searchTypes?: Array<App.SearchType>;
  searchVertical?: App.SearchPlaceVertical;
  overrideSearchItems?: Array<App.SearchItem>;
  controlsContainerRef?: React.RefObject<HTMLDivElement>;
}

function HotelSearchPlaceSelectDropdown(props: Props) {
  const {
    className,
    onKeyDown,
    value,
    required,
    label,
    placeholder,
    localInputRef,
    searchPhrase,
    onSearchPhraseChange,
    handleOpen,
    onClearClick,
    finalOpen,
    closeDropdown,
    showPlaceholder,
    recentSearches,
    selectedDropdown,
    onRecentSearchDelete,
    onRecentSearchClick,
    placeholderTitle,
    placeholdersSearches,
    onLocationSelect,
    handleResultsChange,
    placeTypes,
    searchTypes,
    searchVertical,
    overrideSearchItems,
    controlsContainerRef,
  } = props

  return (
    <PlaceControl className={clsx({ className })} onKeyDown={onKeyDown}>
      <HiddenInput
        required={required}
        value={value?.format.mainText}
      />
      <SearchFormTextField
        label={label}
        placeholder={placeholder}
        ref={localInputRef}
        value={searchPhrase}
        displayValue={value?.format.mainText ?? ''}
        onChange={onSearchPhraseChange}
        onFocus={handleOpen}
        onClear={onClearClick}
      />
      {controlsContainerRef && <DropdownSheet
        size="fill-anchor"
        anchorRef={controlsContainerRef}
        triggerRef={localInputRef}
        open={finalOpen}
        data-testid="place-select"
        onClose={closeDropdown}
      >
        {showPlaceholder &&
          <>
            {!!recentSearches.length && <RecentSearchSuggestionsContainer>
              <RecentSearchSuggestions
                recentSearches={recentSearches}
                onDelete={onRecentSearchDelete}
                selectedDropdown={selectedDropdown}
                iconBackground
                onRecentSearchClick={onRecentSearchClick}
                horizontal
              />
            </RecentSearchSuggestionsContainer>}
            <div>
              {placeholderTitle && <StyledCaption variant="large" weight="bold">{placeholderTitle}</StyledCaption>}
              <SearchItemContainer>
                {take(placeholdersSearches, NUMBER_OF_POPULAR_PLACES_DISPLAYED).map((item, index) => (
                  <SearchMenuListItemContainer key={item.value}>
                    <SearchMenuListItem
                      key={item.value}
                      searchItem={item}
                      onClick={onLocationSelect}
                      selected={index + recentSearches.length === selectedDropdown}
                      horizontal
                      searchVertical={searchVertical}
                      imageId={item.imageId}
                      useImage
                    />
                  </SearchMenuListItemContainer>))}
              </SearchItemContainer>
            </div>
          </>
        }
        {!showPlaceholder &&
          <SearchPlaceSelectList
            searchPhrase={searchPhrase}
            onResultsChange={handleResultsChange}
            onChange={onLocationSelect}
            placeTypes={placeTypes}
            searchTypes={searchTypes}
            vertical={searchVertical}
            selectedDropdown={selectedDropdown}
            overrideSearchItems={overrideSearchItems}
            showHorizontalLayout
            useImage
          />}
      </DropdownSheet>}
    </PlaceControl>
  )
}

export default HotelSearchPlaceSelectDropdown
