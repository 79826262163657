import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import Modal from 'components/Luxkit/Modal/Modal'
import React, { ChangeEventHandler, useCallback, useContext, useEffect, useState } from 'react'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import BusinessTravellerMultiSelectContent from './BusinessTravellerMultiSelectContent'
import { selectSelectedTravellerEmployees, selectTravellerCount } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import { resetCheckoutForm } from 'actions/CheckoutActions'
import { useAppDispatch } from 'hooks/reduxHooks'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import setSelectedBusinessTravellersAndGuests from 'actions/businessTraveller/setSelectedBusinessTravellerEmployeesAndGuests'
import { connect } from 'react-redux'
import { clearFormStateSnapshot } from 'storage/checkout'
import { saveBusinessTravellerSelectedTravellerState } from 'businessTraveller/storage/businessTravellerStorage'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import { getBusinessTravellerSelectEnabled } from 'businessTraveller/utils/getBusinessTravellerSelectEnabled'
import { BUSINESS_TRAVELLER_ACTIONS } from 'reducers/businessTravellerActionReducer'
import useQueryParams from 'hooks/useQueryParams'
import { selectBusinessTravellerCurrentApprovalRequest } from 'businessTraveller/selectors/selectBusinessTravellerCurrentApprovalRequest'
interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
  selectedTravellerEmployeeIds?: Array<string>
  selectedTravellerGuestCount?: number
  selectedTravellerCount: number
  businessAccount?: App.BusinessTraveller.CurrentBusinessAccount
  currentApprovalRequest?: App.BusinessTraveller.ApprovalRequest
}

function BusinessTravellerMultiSelectModal(props: MappedStateProps) {
  const { selectedTravellerCount, selectedTravellerEmployeeIds, selectedTravellerGuestCount, businessAccount, currentApprovalRequest } = props
  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearchTermChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const [travellerSelectState, setTravellerSelectState] = useState<{ selectedTravellers: Array<string>, guestCount: number }>({
    selectedTravellers: props.selectedTravellerEmployeeIds ?? [],
    guestCount: props.selectedTravellerGuestCount ?? 0,
  })

  const hasEmployees = props.travellerEmployees?.length ?? 0 > 1
  const approvalRequest = currentApprovalRequest?.type === 'flight' ? currentApprovalRequest : undefined

  const appDispatch = useAppDispatch()
  const queryParams = useQueryParams()
  const flightsDispatch = useContext(FlightSearchWidgetDispatchContext)
  const handleSelectTravellerApply = useCallback(() => {
    if (travellerSelectState.selectedTravellers.length === 0 && travellerSelectState.guestCount === 0) {
      showSnackbar('Please select at least one traveller or guest.', 'warning')
      return
    }
    if (approvalRequest) {
      showSnackbar('If you need to change the travellers, please create a new approval request.', 'warning')
      return
    }
    // Because we are now saving the traveller form, we need to make sure its cleared when a new traveller is selected.
    appDispatch(resetCheckoutForm())
    clearFormStateSnapshot()
    // Update employee list and guest count in redux state
    appDispatch(setSelectedBusinessTravellersAndGuests(travellerSelectState.selectedTravellers, travellerSelectState.guestCount))
    // Update App.Occupants in GlobalSearchContext
    const occupants: App.Occupants = {
      adults: selectedTravellerCount,
      children: 0,
      infants: 0,
      childrenAge: [],
    }
    flightsDispatch({ type: FlightSearchWidgetActions.SET_OCCUPANTS, occupants })
    // Save to localstorage so persist on page refresh
    saveBusinessTravellerSelectedTravellerState(travellerSelectState.selectedTravellers, travellerSelectState.guestCount)
  }, [appDispatch, flightsDispatch, travellerSelectState, selectedTravellerCount, approvalRequest])

  const modalElementContext = useModalElementContext()

  const dismiss = useCallback(() => {
    modalElementContext.resolve()
    handleSelectTravellerApply()
  }, [modalElementContext, handleSelectTravellerApply])

  // Quick fix to keep occupants in sync with the selected travellers when the component mounts
  useEffect(() => {
    if (!approvalRequest) {
      flightsDispatch({
        type: FlightSearchWidgetActions.SET_OCCUPANTS,
        occupants: { // use redux state passed in props, not the local state
          adults: (selectedTravellerEmployeeIds ?? []).length + (selectedTravellerGuestCount ?? 0) || 1,
          children: 0,
          infants: 0,
          childrenAge: [],
        },
      })
    }
  }, [flightsDispatch, selectedTravellerEmployeeIds, selectedTravellerGuestCount, approvalRequest])

  useEffect(() => {
    // Set a default traveller, only for users that are travellers, not admins or managers (OPERX-263)
    const businessTravellerSelectEnabled = getBusinessTravellerSelectEnabled(businessAccount)
    if (!businessTravellerSelectEnabled && businessAccount?.employee?.id && !selectedTravellerEmployeeIds?.length && !selectedTravellerGuestCount) {
      saveBusinessTravellerSelectedTravellerState([businessAccount.employee.id])
      setTravellerSelectState({
        selectedTravellers: [businessAccount.employee.id],
        guestCount: 0,
      })
    }
    if (approvalRequest) {
      // Clear approval request if param not in URL, which brings back into normal flow
      if (!queryParams.get('approvalRequest') || approvalRequest.status === 'DENIED' || approvalRequest.status === 'REQUESTED') {
        appDispatch({
          type: BUSINESS_TRAVELLER_ACTIONS.CLEAR_CURRENT_APPROVAL_REQUEST,
        })
      } else {
        setTravellerSelectState({
          selectedTravellers: approvalRequest.employeeIds ?? [],
          guestCount: approvalRequest.guestCount ?? 0,
        })
      }
    }
  }, [selectedTravellerEmployeeIds, selectedTravellerGuestCount, businessAccount?.employee?.id, businessAccount, approvalRequest, appDispatch, queryParams])

  return <Modal
    mode="drawer"
    title="Who is going?"
    headerExtension={hasEmployees && <TextInputOnly
      value={searchTerm}
      type="text"
      placeholder="Search traveller name"
      onChange={handleSearchTermChange}
      endIcon={<LineSearchIcon />}
      noValidationSpacing
    />}
    primaryActionText="Continue"
    onPrimaryActionClick={dismiss}
  >
    <BusinessTravellerMultiSelectContent
      searchTerm={searchTerm}
      travellerSelectState={travellerSelectState}
      setTravellerSelectState={setTravellerSelectState}
    />
  </Modal>
}

export default connect<MappedStateProps, {}, {}, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
    selectedTravellerEmployeeIds: state.businessTraveller.selectedTravellerEmployeeIds,
    selectedTravellerGuestCount: state.businessTraveller.selectedTravellerGuestCount,
    businessAccount: state.businessTraveller.currentBusinessAccount,
    selectedTravellerCount: selectTravellerCount(state),
    currentApprovalRequest: selectBusinessTravellerCurrentApprovalRequest(state),
  }
})(BusinessTravellerMultiSelectModal)
