import React, { PropsWithChildren } from 'react'
import cn from 'clsx'
import styled from 'styled-components'

export const _TooltipTriggerElement = styled.span`
  &:not(.disabled) {
    cursor: pointer;
  }
`

interface Props {
  className?: string
  disabled?: boolean
  ref?: React.Ref<HTMLSpanElement>
}

function _TooltipTrigger(props: PropsWithChildren<Props>) {
  const { children, className, disabled, ref } = props

  return <_TooltipTriggerElement
    ref={ref}
    className={cn(className, { disabled })}
  >
    {children}
  </_TooltipTriggerElement>
}

export default _TooltipTrigger
