import React, { useCallback, useMemo } from 'react'
import PopularHolidayTypesFilterToggles from './PopularHolidayTypesFilterToggles'
import { isEmpty } from 'lib/array/arrayUtils'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import { OfferListFilterSubmit } from './OfferListFilters'

interface Props {
  availableFilters?: App.OfferListAvailableFilters
  existingFilters?: App.OfferListFilters
  onApply?: (values: OfferListFilterSubmit) => void
  onSeeMore?: () => void
  showPropertyTypes?: boolean
  showPopularHolidayTypes?: boolean
  onPopularHolidayTypeFilterClick?: () => void
}

function OfferFilterToggles(props: Props) {
  const {
    availableFilters,
    existingFilters,
    onApply,
    onSeeMore,
    showPropertyTypes,
    showPopularHolidayTypes,
    onPopularHolidayTypeFilterClick,
  } = props

  const {
    toursIncluded,
    cruisesIncluded,
    hotelAndResortsIncluded,
    villasAndUniqueStaysIncluded,
  } = useMemo(() => ({
    toursIncluded: existingFilters?.holidayTypes.includes('Tours') ?? false,
    cruisesIncluded: existingFilters?.holidayTypes.includes('Cruises') ?? false,
    hotelAndResortsIncluded: existingFilters?.propertyTypes.includes('HOTEL') ?? false,
    villasAndUniqueStaysIncluded: existingFilters?.propertyTypes.includes('UNIQUE_STAYS') ?? false,
  }), [existingFilters])

  const toggleHolidayType = useCallback((holidayType: string, value: boolean) => {
    let holidayTypes = [...existingFilters.holidayTypes]

    if (value) {
      holidayTypes.push(holidayType)
    } else {
      holidayTypes = holidayTypes.filter(type => type !== holidayType)
    }

    onApply({
      destinations: existingFilters.locations,
      amenities: existingFilters.amenities,
      sortBy: existingFilters.sortBy,
      propertyTypes: existingFilters.propertyTypes,
      customerRatings: existingFilters?.customerRatingGte,
      holidayTypes,
      inclusions: existingFilters.inclusions,
    })
  }, [existingFilters, onApply])

  const toggleToursInclusion = useCallback(() => {
    toggleHolidayType('Tours', !toursIncluded)
  }, [toggleHolidayType, toursIncluded])

  const toggleCruisesInclusion = useCallback(() => {
    toggleHolidayType('Cruises', !cruisesIncluded)
  }, [toggleHolidayType, cruisesIncluded])

  const togglePropertyType = useCallback((propertyType: string, value: boolean) => {
    let propertyTypes = [...existingFilters.propertyTypes]

    if (value) {
      propertyTypes.push(propertyType)
    } else {
      propertyTypes = propertyTypes.filter(type => type !== propertyType)
    }

    onApply({
      destinations: existingFilters.locations,
      amenities: existingFilters.amenities,
      sortBy: existingFilters.sortBy,
      holidayTypes: existingFilters.holidayTypes,
      customerRatings: existingFilters.customerRatingGte,
      propertyTypes,
      inclusions: existingFilters.inclusions,
    })
  }, [existingFilters, onApply])

  const toggleHotelsAndResorts = useCallback(() => {
    togglePropertyType('HOTEL', !hotelAndResortsIncluded)
  }, [togglePropertyType, hotelAndResortsIncluded])

  const toggleVillasAndUniqueStays = useCallback(() => {
    togglePropertyType('UNIQUE_STAYS', !villasAndUniqueStaysIncluded)
  }, [togglePropertyType, villasAndUniqueStaysIncluded])

  const isPopularHolidayTypeIncluded = useCallback((holidayType: string): boolean => {
    return existingFilters.holidayTypes.includes(holidayType)
  }, [existingFilters])

  const togglePopularHolidayTypeInclusion = useCallback((holidayType: string) => {
    if (onPopularHolidayTypeFilterClick) {
      onPopularHolidayTypeFilterClick()
    }
    let holidayTypes = [...existingFilters.holidayTypes]

    const isIncluded = isPopularHolidayTypeIncluded(holidayType)
    if (isIncluded) {
      holidayTypes = holidayTypes.filter(item => item !== holidayType)
    } else {
      holidayTypes.push(holidayType)
    }

    onApply({
      destinations: existingFilters.locations,
      amenities: existingFilters.amenities,
      sortBy: existingFilters.sortBy,
      propertyTypes: existingFilters.propertyTypes,
      customerRatings: existingFilters.customerRatingGte,
      holidayTypes,
      inclusions: existingFilters.inclusions,
    })
  }, [existingFilters?.amenities,
    existingFilters?.customerRatingGte,
    existingFilters?.holidayTypes,
    existingFilters?.inclusions,
    existingFilters?.locations,
    existingFilters?.propertyTypes,
    existingFilters?.sortBy,
    isPopularHolidayTypeIncluded,
    onApply,
    onPopularHolidayTypeFilterClick])

  const availableHolidayTypesCount = isEmpty(availableFilters?.holidayTypes) ? 0 : Object.keys(availableFilters?.holidayTypes).length

  return <>
    {!showPopularHolidayTypes && !!availableFilters?.holidayTypes?.Tours && <FilterChip
      size="medium"
      selected={toursIncluded}
      onClick={toggleToursInclusion}
    >
      Tours
    </FilterChip>}
    {!showPopularHolidayTypes && !!availableFilters?.holidayTypes?.Cruises && <FilterChip
      size="medium"
      selected={cruisesIncluded}
      onClick={toggleCruisesInclusion}
    >
      Cruises
    </FilterChip>}
    {showPropertyTypes && <FilterChip
      size="medium"
      selected={hotelAndResortsIncluded}
      onClick={toggleHotelsAndResorts}
    >
      Hotels & Resorts
    </FilterChip>}
    {showPropertyTypes && <FilterChip
      size="medium"
      selected={villasAndUniqueStaysIncluded}
      onClick={toggleVillasAndUniqueStays}
    >
      Villas & Unique Stays
    </FilterChip>}
    { showPopularHolidayTypes &&
      <PopularHolidayTypesFilterToggles
        availableHolidayTypesCount={availableHolidayTypesCount}
        isToggled={isPopularHolidayTypeIncluded}
        onToggle={togglePopularHolidayTypeInclusion}
        onSeeMore={onSeeMore}
      />
    }
  </>
}

export default OfferFilterToggles
