import { getCheckoutFlightItemFinalFare } from 'actions/CheckoutFlightActions'
import { fetchFlightJourneyV2 } from 'actions/FlightActions'
import { getFlightItems } from 'checkout/selectors/view/flights'
import { expireFlightsTimer } from 'components/Flights/FlightsSessionTimer/FlightsSessionTimer'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import getObjectKey from 'lib/object/getObjectKey'
import { useEffect, useMemo } from 'react'

export function useCheckoutFlightJourney() {
  const dispatch = useAppDispatch()
  const flightItems = useAppSelector(getFlightItems)
  const journeyErrors = useAppSelector(state => state.flights.journeyErrors)

  const hasAnyJourneyErrors = useMemo(() => {
    return flightItems.some(item => {
      return item.flights.some(journey => {
        if (journey) {
          const key = getObjectKey({
            journeyId: journey.journeyId,
            searchId: item.searchId,
            fareFamilyId: journey.fareFamily?.id,
          })

          if (journeyErrors[key]) return true
        }
      })
    })
  }, [flightItems, journeyErrors])

  useEffect(() => {
    if (hasAnyJourneyErrors) {
      // Might need refinement but if any journey has an error we expire the session as it cannot be recovered at the moment
      expireFlightsTimer()
    }
  }, [hasAnyJourneyErrors])

  useEffect(() => {
    flightItems
      .forEach(item => {
        if (item.searchId) {
          item.flights.forEach((flight) => {
            if (flight.journeyId) {
              dispatch(fetchFlightJourneyV2(item.searchId!, flight.journeyId, flight.fareFamily?.id))
            }
          })
        }
      })
  }, [flightItems, dispatch])
}

function useCheckoutFlightFinalFares() {
  const dispatch = useAppDispatch()

  const flightItems = useAppSelector(getFlightItems)
  const journeys = useAppSelector(state => state.flights.journeysById)

  useEffect(() => {
    const items = flightItems
      .filter(item => {
        // first we make sure the item has all the data we need to fetch the final fares
        let hasItemData = item.flights.every((flight) => {
          // (item.fareType === 'oneWay' || !!item.returning?.journeyId)
          return flight.journeyId
        })

        hasItemData = hasItemData && !item.hasFinalFare && !item.fetchingFare && !item.fareFetchError
        return hasItemData
      })

    items.forEach((item, index) => {
      dispatch(getCheckoutFlightItemFinalFare(item, index))
    })
  }, [dispatch, flightItems, journeys])
}

export default function useCheckoutFlightData() {
  useCheckoutFlightJourney()
  useCheckoutFlightFinalFares()
}
