import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'

function getCruiseTourV2OrExperienceOffer(
  state: App.State,
  offerId: string,
  variationId?: string,
): App.Tours.TourV2Offer | App.CruiseOffer | App.ExperienceOffer | undefined {
  const tourV2Offers = state.offer.tourV2Offers
  const cruiseOffers = state.cruise.cruiseOffers
  const experienceOffers = state.experience.experiences

  if (variationId) {
    const offerIdWithVariation = [offerId, variationId].join('/')
    if (tourV2Offers[offerIdWithVariation]) return tourV2Offers[offerIdWithVariation]
    if (cruiseOffers[offerIdWithVariation]) return cruiseOffers[offerIdWithVariation]
    if (experienceOffers[offerIdWithVariation]) return experienceOffers[offerIdWithVariation]
  }

  if (tourV2Offers[offerId]) return tourV2Offers[offerId]
  if (cruiseOffers[offerId]) return cruiseOffers[offerId]
  if (experienceOffers[offerId]) return experienceOffers[offerId]
}

type CategoryId = comluxgroup.Item_1_1_0['categoryId']
type ProductId = comluxgroup.Item_1_1_0['productId']

function getCategoryAndProductId(parentType: App.OfferParentType | 'flight', offer?: App.OrderItemOffer & {source?: string} | App.OfferBase & {source?: string}): { categoryId: CategoryId, productId: ProductId } {
  switch (parentType) {
    case 'hotel': {
      if (offer?.type === 'bedbank_hotel') return { categoryId: 'hotel', productId: 'bedbank' }
      if (offer?.type === 'last_minute_hotel') return { categoryId: 'hotel', productId: 'last_minute_hotel' }
      if (offer?.type === 'tactical_ao_hotel') return { categoryId: 'hotel', productId: 'tactical_ao_hotel' }
      if (offer?.type === 'bundle_and_save') return { categoryId: 'bundle', productId: 'bundle' }
      return { categoryId: 'hotel', productId: 'hotel' }
    }

    case 'tour_v2': return { categoryId: 'tour', productId: offer?.source === 'ttc' ? 'tourbank' : 'tour' }

    case 'tour': {
      const tourOffer = offer as App.Offer
      if (tourOffer?.holidayTypes?.includes('Cruises')) return { categoryId: 'cruise', productId: 'cruise' }
      return { categoryId: 'tour', productId: 'tour' }
    }

    case 'cruise': return { categoryId: 'cruise', productId: 'cruisebank' }

    case 'experience': {
      const experienceOffer = offer as App.ExperienceOffer
      const provider = experienceOffer.provider || getExperienceProviderFromOffer(offer?.id)
      const categoryId = 'experience'
      const productId = getExperienceProductIdFromProvider(provider)
      if (productId) return { categoryId, productId }
      return { categoryId, productId: null }
    }

    case 'car_hire': return { categoryId: 'car_hire', productId: 'car_hire' }

    case 'flight': return { categoryId: 'flight', productId: 'flight' }

    default: return { categoryId: null, productId: null }
  }
}

function getExperienceProviderFromOffer(offerId?: string) {
  const offerProviderAndId = offerId?.split(':')
  if (offerProviderAndId && offerProviderAndId.length > 0) return offerProviderAndId[0]
}

function getExperienceProductIdFromProvider(provider?: string) : ProductId {
  switch (provider) {
    case 'led': return 'led_exp'
    case 'mus': return 'mus_exp'
    case 'rez': return 'rez_exp'
    case 'dby': return 'dby_exp'
    default: return null
  }
}

function getHotelOrTourOffer(
  state: App.State,
  offerId: string,
  variationId?: string,
): App.Offer | App.BedbankOffer | App.OfferSummary | undefined {
  const offers = state.offer.offers
  const bedBankOffers = state.offer.bedbankOffers
  const offerSummary = state.offer.offerSummaries

  if (variationId && offers[[offerId, variationId].join('/')]) {
    return offers[[offerId, variationId].join('/')] || bedBankOffers[[offerId, variationId].join('/')]
  } else if (variationId && offerSummary[[offerId, variationId].join('/')]) {
    return offerSummary[[offerId, variationId].join('/')]
  }
  return offers[offerId] || bedBankOffers[offerId] || offerSummary[offerId]
}

function cartIsAccommodationItem(item: App.Checkout.AnyItem): item is App.Checkout.BundleAndSaveItem | App.Checkout.BedbankHotelItem | App.Checkout.InstantBookingLEHotelItem | App.Checkout.BNBLLEHotelItem | App.Checkout.VillaItem {
  return item.itemType === 'hotel' || item.itemType === 'villa' || item.itemType === 'bundleAndSave' || item.itemType === 'bedbankHotel'
}

export {
  getCruiseTourV2OrExperienceOffer,
  getCategoryAndProductId,
  getHotelOrTourOffer,
  cartIsAccommodationItem,
  getExperienceProductIdFromProvider,
  getExperienceProviderFromOffer,
}
