import { createSelector } from 'reselect'
import {
  hasMultiplePurchasableOptionsWithPackageUpgradeOrRoomTypeUpgrade,
  hasOptionalExtras,
  isDepositBalance,
  isPostPurchaseTourChangeDates,
  isPostPurchaseTourOptionalExperience,
  selectablePurchasableOptionsHasRoomTypeUpgrade,
  shouldShowLuxPlusUpsellForTour,
} from 'checkout/selectors/tourV2Selectors'
import { isPaymentScheduleBalance } from 'checkout/selectors/payment/paymentSchedule/isPaymentScheduleBalance'
import { pushWithRegion, replaceWithRegion } from 'actions/NavigationActions'
import { CheckoutPageId } from 'checkout/constants/pages'
import { getCurrentCheckoutPage } from 'checkout/actions/navigateActions'
import { getCheckoutPathWithCartId } from 'lib/url/pathsUtils'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { AppAction } from 'actions/ActionTypes'
import { EmptyArray } from 'lib/array/arrayUtils'
import { getTourV2Items } from 'checkout/selectors/view/toursv2'
import config from 'constants/config'
import { diffInDays } from 'lib/datetime/dateUtils'
import { getAllOffers } from 'selectors/offerSelectors'
import { TOUR_POST_PRE_ACCOMMODATIONS_EXCLUDE_COUNTRY_CODES } from 'constants/config/region'

/** 14 months in days */
const AVAILABLE_ACCOMMODATION_DAYS_THRESHOLD = 425

const tourCheckoutSteps: { [key:string]: App.Checkout.CheckoutStep } = {
  package: {
    label: 'Packages',
    key: CheckoutPageId.TourV2Upgrade,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: false,
  },
  luxPlus: {
    label: LUXURY_PLUS.PROGRAM_NAME,
    key: CheckoutPageId.LuxPlus,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: false,
  },
  extras: {
    label: 'Experiences',
    key: CheckoutPageId.TourV2OptionalExperience,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: true,
  },
  purchase: {
    label: 'Review & payment',
    key: CheckoutPageId.Purchase,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: false,
  },
  departureDates: {
    label: 'Departure Dates',
    key: CheckoutPageId.TourV2Departures,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: false,
  },
  accommodation: {
    label: 'Accommodation',
    key: CheckoutPageId.TourV2Accommodation,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: true,
  },
}

export const isStepTour = (step?: string) => {
  return Object.values(tourCheckoutSteps).some(
    tourStep => tourStep.key === step && step !== tourCheckoutSteps.purchase.key,
  )
}

export const getTourCheckoutSteps = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => hasMultiplePurchasableOptionsWithPackageUpgradeOrRoomTypeUpgrade(state),
  (state: App.State) => selectablePurchasableOptionsHasRoomTypeUpgrade(state),
  (state: App.State) => hasOptionalExtras(state),
  (state: App.State) => shouldShowLuxPlusUpsellForTour(state),
  (state: App.State) => shouldShowAccommodationStep(state),
  (state: App.State) => isPostPurchaseTourOptionalExperience(state),
  (state: App.State) => isPostPurchaseTourChangeDates(state),
  (state: App.State) => isPaymentScheduleBalance(state),
  (state: App.State) => isDepositBalance(state),
  (
    tourItems,
    hasPackageUpgrade,
    hasRoomUpgrade,
    hasExtras,
    showLuxPlusUpsell,
    showAccommodationStep,
    isPostPurchaseTourOptionalExperience,
    isPostPurchaseTourChangeDates,
    isPaymentScheduleBalance,
    isDepositBalance,
  ): Array<App.Checkout.CheckoutStep> => {
    if (isDepositBalance || isPaymentScheduleBalance) {
      return [tourCheckoutSteps.purchase]
    }
    if (isPostPurchaseTourOptionalExperience) {
      return [tourCheckoutSteps.extras, tourCheckoutSteps.purchase]
    }
    if (isPostPurchaseTourChangeDates) {
      const steps = [tourCheckoutSteps.departureDates]
      if ((!!tourItems.length && tourItems[0].isOriginalItem) || hasPackageUpgrade) {
        steps.push(tourCheckoutSteps.package)
      }
      steps.push(tourCheckoutSteps.purchase)
      return steps
    }
    if (!tourItems.length) {
      return []
    }
    const steps: Array<App.Checkout.CheckoutStep> = []
    if (hasPackageUpgrade || hasRoomUpgrade) {
      tourCheckoutSteps.package.showFooter = !!hasRoomUpgrade
      steps.push(tourCheckoutSteps.package)
    }
    if (showLuxPlusUpsell) steps.push(tourCheckoutSteps.luxPlus)
    if (hasExtras) steps.push(tourCheckoutSteps.extras)
    if (showAccommodationStep) {
      steps.push(tourCheckoutSteps.accommodation)
    }
    steps.push(tourCheckoutSteps.purchase)
    return steps
  },
)

export const shouldShowAccommodationStep = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => getAllOffers(state),
  (items: Array<App.Checkout.TourV2Item>, offers): boolean => {
    if (!items.length) return false
    return config.TOUR_ADD_ACCOMMODATION_ENABLED && items.some(item => {
      const { offerId, purchasableOption: { fkVariationId } } = item
      const tourV2Offer = offers[offerId] as App.Tours.TourV2Offer
      const { startLocationPlaceId, startLocationCountryCode, endLocationPlaceId, endLocationCountryCode } = tourV2Offer?.variations?.[fkVariationId] ?? {}
      const hasJapanLocations = TOUR_POST_PRE_ACCOMMODATIONS_EXCLUDE_COUNTRY_CODES.includes(startLocationCountryCode) || TOUR_POST_PRE_ACCOMMODATIONS_EXCLUDE_COUNTRY_CODES.includes(endLocationCountryCode)
      return (!!startLocationPlaceId || !!endLocationPlaceId) && !hasJapanLocations && diffInDays(new Date(), new Date(item.startDate)) < AVAILABLE_ACCOMMODATION_DAYS_THRESHOLD
    })
  },
)

export function navigateToTourCheckoutStep(selectedStep?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const steps = getTourCheckoutSteps(state)
    const firstStep = selectedStep ?? steps?.[0]?.key

    const url = getCheckoutPathWithCartId(selectedStep ?? firstStep, state.checkout.cart.cartId)

    if (firstStep) {
      dispatch(pushWithRegion(url))
    }
  }
}

export function navigateToNextStepTourCheckout(shouldReplaceWithRegion?: boolean): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const steps = getTourCheckoutSteps(state)
    const currentStep = getCurrentCheckoutPage(state)
    const currentIndex = steps.findIndex(step => step.key === currentStep)
    const nextStep = steps?.[currentIndex + 1]
    if (!nextStep) {
      return
    }

    const url = getCheckoutPathWithCartId(nextStep.key, state.checkout.cart.cartId)
    if (shouldReplaceWithRegion) {
      dispatch(replaceWithRegion(url))
    } else {
      dispatch(pushWithRegion(url))
    }
  }
}

export const getCurrentTourCheckoutStep = createSelector(
  (state: App.State) => getTourCheckoutSteps(state),
  (state: App.State) => getCurrentCheckoutPage(state),
  (tourSteps, currentStep) => {
    const currentIndex = tourSteps.findIndex(step => step.key === currentStep)
    return tourSteps?.[currentIndex]
  },
)
