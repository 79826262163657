import { getHasLuxLoyaltyAccount } from '../luxLoyaltyFeatureToggles'

function getShouldEarnLuxLoyaltyPoints(state: App.State): boolean {
  const hasLuxLoyaltyAccount = getHasLuxLoyaltyAccount(state)
  const enrolAtCheckout = state.checkout.luxLoyalty.enrolCustomerAtCheckout

  return hasLuxLoyaltyAccount || enrolAtCheckout
}

export default getShouldEarnLuxLoyaltyPoints
