import { excludeNullOrUndefined } from 'checkout/utils'
import { min, sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import moment from 'moment'

import { EXPERIENCE_GIFT_SCHEDULE_DATE_LIMIT_BEFORE_EXPIRATION_DATE } from 'constants/experience'
import { getExperienceDatesKey, getExperienceTimesKey } from 'lib/experiences/experienceUtils'
import { convertTZ } from 'lib/datetime/dateUtils'
import getExperienceItemsView from './getExperienceItemsView'
import getTransferItemsView from './getTransferItemsView'
import getExperienceBreakdownView from './getExperienceBreakdownView'
import getExperienceItems from './getExperienceItems'

function getMobileAppDiscountTotal(viewWithStatus: App.WithDataStatus<Array<App.Checkout.ExperienceItemView | App.Checkout.TransferItemView>>) {
  const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)

  const itemsWithAppDiscount = definedItems.filter((item) => item.totals.mobileAppDiscount?.amount)
  const appDiscountAmount = itemsWithAppDiscount.length ? sum(itemsWithAppDiscount, item => item.totals.mobileAppDiscount?.amount ?? 0) : 0
  const appDiscountPercentage = itemsWithAppDiscount.length ? sum(definedItems, item => item.totals.mobileAppDiscount?.percentage ?? 0) / itemsWithAppDiscount.length : 0

  return {
    amount: appDiscountAmount,
    percentage: appDiscountPercentage,
  }
}

export const getExperienceMobileAppDiscountTotal = createSelector(
  (state: App.State) => getExperienceItemsView(state),
  (viewWithStatus): App.Checkout.MobileAppDiscount => getMobileAppDiscountTotal(viewWithStatus),
)

export const getTransferMobileAppDiscountTotal = createSelector(
  (state: App.State) => getTransferItemsView(state),
  (viewWithStatus): App.Checkout.MobileAppDiscount => getMobileAppDiscountTotal(viewWithStatus),
)

export const getExperienceTotalPrice = createSelector(
  (state: App.State) => getExperienceBreakdownView(state),
  (views) => sum(views.data, view => view.price),
)

export const getCheckoutFormFieldCustomProps = createSelector(
  (state: App.State) => state.checkout.cart.isGift,
  (state: App.State) => getExperienceItemsView(state),
  (isGift, experienceItemsView): App.Checkout.FormFieldCustomProps | undefined => {
    // the user can buy an experience gift only in standalone and is always one experience
    const experienceItem = experienceItemsView.data[0]
    const maxScheduleDate = (isGift && experienceItem?.isGift) ? (moment(experienceItem.bookByDate ?? experienceItem.expirationDate).subtract(EXPERIENCE_GIFT_SCHEDULE_DATE_LIMIT_BEFORE_EXPIRATION_DATE, 'days').toDate()) : undefined
    const customProps = {
      gift: {
        maxScheduleDate,
      },
    }

    return customProps
  },
)

export const isSoldOut = createSelector(
  (state: App.State) => getExperienceItems(state),
  (state: App.State) => state.experience.experienceTimes,
  (state: App.State) => state.experience.experienceDates,
  (state: App.State) => state.experience.experiences,
  (state: App.State) => state.geo.currentCurrency,
  (experienceItems, timeslot, experienceDates, experiences, currency): boolean => {
    const isGift = experienceItems.some(item => item.date === 'any')
    if (isGift) return false

    const isSoldOut = experienceItems.some(checkoutItem => {
      let isAvailable = true
      const { experienceId, date, time, bookingType, pickupPointId, redemptionLocationId } = checkoutItem

      if (bookingType === 'CALENDAR-TIMESLOTS' && time) {
        const key = getExperienceTimesKey(experienceId, date, { currency, redemptionLocationId, pickupPointId })
        const availableTimeslots = timeslot[experienceId]?.[key]

        if (availableTimeslots?.slots?.length && availableTimeslots.fetching === false) {
          isAvailable = availableTimeslots.slots.some((slot) => slot.time === time)
        }
      } else if (bookingType === 'CALENDAR-NO-TIMESLOTS') {
        const key = getExperienceDatesKey(experienceId, { pickupPointId, redemptionLocationId })
        const availableDates = experienceDates[experienceId]?.[key]

        if (availableDates?.dates?.length && availableDates.fetching === false) {
          const currentDate = availableDates.dates.find(availableDate => availableDate.day === date)
          isAvailable = currentDate?.soldOut === false
        }
      } else if (bookingType === 'NO-CALENDAR-FIXED-END') {
        const experience = experiences[experienceId]

        if (experience) {
          const minTicket = min(checkoutItem.tickets.filter(ticket => ticket.bookByDate), (ticket) => new Date(ticket.bookByDate!))
          const bookByDate = minTicket?.bookByDate ?? experience.bookByDate ?? experience.expirationDate

          if (!bookByDate) {
            isAvailable = experience.status === 'ONLINE'
          } else {
            const localizedBookByDate = moment(convertTZ(new Date(bookByDate), experience.location.timezone))
            const localizedNow = moment(convertTZ(new Date(), experience.location.timezone))
            isAvailable = localizedNow.isBefore(localizedBookByDate, 'minutes')
          }
        }
      }
      return !isAvailable
    })

    return isSoldOut
  },
)
