import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { getPlaceById } from 'api/search'
import TextInput from 'components/Common/Form/Input/TextInput'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import IconButton from 'components/Luxkit/Button/IconButton'
import InputChip from 'components/Luxkit/Chips/InputChip'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import SearchMenuListItem from 'components/Search/SearchForm/SearchMenu/SearchMenuListItem'
import Group from 'components/utils/Group'
import {
  GlobalSearchDispatchContext,
  GlobalSearchStateContext,
} from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useGlobalSearchTypeahead from 'hooks/GlobalSearch/useGlobalSearchTypeahead'
import useStateWithRef from 'hooks/useStateWithRef'
import { EmptyArray } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import { TRIP_PLACE_TYPEAHEAD_TYPES } from 'tripPlanner/config/search'

const ClearButton = styled(IconButton)`
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s, visibility 0s;
`

const StyledTextInput = styled(TextInput)`
  & input:placeholder-shown + div ${ClearButton} {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0s 0.2s;
  }
`

interface PlaceSelection {
  id: string
  name: string
}

interface Props {
  title?: string
  initialDestinations?: Array<PlaceSelection>
  onChange: (places: Array<PlaceSelection>) => void
  hasNewPlace?: React.MutableRefObject<boolean>
  onBack?: () => void
  onCancel: () => void
  isSubmitting?: boolean
  errorMsg?: string
  onRequestClearError?: () => void
  doneLabel?: string
}

function MobileSelectTripDestinations({
  title = 'Add destinations',
  initialDestinations = EmptyArray,
  onChange,
  onCancel,
  onBack,
  hasNewPlace,
  isSubmitting,
  errorMsg: externalErrorMsg,
  onRequestClearError = noop,
  doneLabel = 'Add destinations',
}: Props) {
  const [inputValue, setInputValue] = useState('')
  const hasInput = !!inputValue
  const [selectedPlaces, setSelectedPlaces, selectedPlacesRef] =
    useStateWithRef<Array<PlaceSelection>>(initialDestinations)
  const [showInvalidStateMessage, setShowInvalidStateMessage] = useState(false)

  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)
  const { suggestedSearchItems, popularDestinations } = useContext(
    GlobalSearchStateContext,
  )

  const inputRef = useRef<HTMLInputElement>(null)

  const onClearInput = useCallback(() => {
    globalSearchDispatch({
      type: GlobalSearchStateActions.SET_SUGGESTED_SEARCH_ITEMS,
      searchItems: [],
    })
    setInputValue('')
    onRequestClearError()
    setShowInvalidStateMessage(false)
  }, [onRequestClearError, globalSearchDispatch])

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget
      if (value.length === 0) {
        onClearInput()
      } else {
        setInputValue(value)
        onRequestClearError()
      }
    },
    [onClearInput, onRequestClearError],
  )

  const onInputFocus = useCallback(() => {
    setShowInvalidStateMessage(false)
  }, [])

  const { isLoading, isError } = useGlobalSearchTypeahead({
    search: inputValue,
    typeaheadTypes: TRIP_PLACE_TYPEAHEAD_TYPES,
  })

  const errorMsg =
    externalErrorMsg ?? (isError ? 'Failed to fetch place results' : '')

  const onClearSelectedPlace = (placeId: string) => {
    setSelectedPlaces((prev) => prev.filter((p) => p.id !== placeId))
  }

  const onLocationSelect = useCallback(
    (selectedSearchItem: App.SearchItem) => {
      const placeId = selectedSearchItem.value
      if (!selectedPlaces.find((p) => p.id === placeId)) {
        setSelectedPlaces((prev) => {
          return [
            ...prev,
            {
              id: placeId,
              name: selectedSearchItem.format.mainText,
            },
          ]
        })
        // Typeahead API doesn't include country code, so do a separate request to get that
        getPlaceById(placeId)
          .then((place) => {
            if (place.type !== 'country' && place.countryCode) {
              const name = `${place.name}, ${place.countryCode}`
              setSelectedPlaces((prev) =>
                prev.map((p) => (p.id === placeId ? { ...p, name } : p)),
              )
            }
          })
          .catch(() => {
            // do nothing
          })
        if (hasNewPlace) {
          hasNewPlace.current = true
        }
      }

      onClearInput()
    },
    [selectedPlaces, hasNewPlace, onClearInput, setSelectedPlaces],
  )

  const onFinish = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      if (hasInput) {
        setShowInvalidStateMessage(true)
      } else {
        onChange(selectedPlacesRef.current)
      }
    },
    [hasInput, onChange, selectedPlacesRef],
  )

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    return () => {
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_SUGGESTED_SEARCH_ITEMS,
        searchItems: [],
      })
    }
  }, [globalSearchDispatch])

  const isEmptyResult =
    inputValue.length > 0 && suggestedSearchItems.length === 0 && !isLoading

  return (
    <form onSubmit={onFinish}>
      <ModalHeader
        title={title}
        onCloseButtonClick={onCancel}
        onBackButtonClick={onBack}
      >
        <VerticalSpacer gap={12}>
          <StyledTextInput
            ref={inputRef}
            placeholder="Search by city"
            value={inputValue}
            onChange={onInputChange}
            onFocus={onInputFocus}
            endIcon={
              <ClearButton kind="tertiary" onClick={onClearInput}>
                <LineTimesIcon />
              </ClearButton>
            }
            manualError={showInvalidStateMessage}
            invalidErrorMessage={
              showInvalidStateMessage ?
                'Please select a location from the list' :
                undefined
            }
          />
          <Group
            direction="horizontal"
            horizontalAlign="start"
            gap={8}
            wrap="wrap"
          >
            {selectedPlaces.map((place) => (
              <InputChip
                key={place.id}
                variant="filled"
                onClick={() => onClearSelectedPlace(place.id)}
              >
                {place.name}
              </InputChip>
            ))}
          </Group>
          {errorMsg && <MessageBanner kind="critical" description={errorMsg} />}
        </VerticalSpacer>
      </ModalHeader>
      <ModalBody>
        <ModalContent>
          {!isLoading &&
            suggestedSearchItems.map((item) => (
              <SearchMenuListItem
                key={item.value}
                searchItem={item}
                onClick={onLocationSelect}
              />
            ))}
          {inputValue.length <= 1 &&
            popularDestinations.map((item) => (
              <SearchMenuListItem
                key={item.value}
                searchItem={item}
                onClick={onLocationSelect}
              />
            ))}
          {isLoading && <LoadingIndicator inline />}
          {isEmptyResult && (
            <VerticalSpacer gap={4}>
              <BodyText variant="medium" colour="neutral-two">
                Sorry, we couldn't find <b>{inputValue}</b>.
              </BodyText>
              <Caption variant="medium">
                Check your spelling, or try a new destination.
              </Caption>
            </VerticalSpacer>
          )}
        </ModalContent>
      </ModalBody>
      <ModalFooter
        primaryActionProps={{
          children: doneLabel,
          disabled: isLoading || isSubmitting,
          type: 'submit',
        }}
        secondaryActionProps={{
          children: 'Cancel',
          disabled: isLoading || isSubmitting,
          onClick: onCancel,
        }}
      />
    </form>
  )
}

export default MobileSelectTripDestinations
