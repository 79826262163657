import React, { useMemo } from 'react'
import {
  buildPanelCheckItems,
} from 'components/Cruises/SearchPage/Filters/Inputs/CruiseExtraFiltersInputs'
import FilterPanelCheckboxGroup from 'components/Common/FilterPanel/FilterPanelCheckboxGroup'
import config from 'constants/config'
import { LUXURY_ESCAPES } from 'constants/brands'

const isLuxuryEscapes = config.BRAND === LUXURY_ESCAPES

interface Props {
  isLuxExclusivePromotion?: Set<string>;
  isSpecialOffers?: Set<string>;
  onChange: (nextFilters: Record<string, any>) => void;
  facets: Array<App.CruiseSearchFacet>;
}

function CruisePromotionFilter({
  isLuxExclusivePromotion,
  isSpecialOffers,
  onChange,
  facets,
}: Props) {
  const { leExclusiveItems, hasLuxExclusiveItems } = useMemo(() => {
    const luxExclusiveFacets = facets.filter(facet => facet.category === 'lux_exclusive')

    return {
      leExclusiveItems: buildPanelCheckItems(luxExclusiveFacets),
      hasLuxExclusiveItems: luxExclusiveFacets.some(facet => !!facet.count),
    }
  }, [facets])
  const { specialOffersItems, hasSpecialOffersItems } = useMemo(() => {
    const specialOffersFacets = facets.filter(facet => facet.category === 'special_offers')
    return {
      specialOffersItems: buildPanelCheckItems(specialOffersFacets),
      hasSpecialOffersItems: specialOffersFacets.some(facet => !!facet.count),
    }
  }, [facets])

  return <div data-testid="cruise-promotion-filter">
    {isLuxuryEscapes && hasLuxExclusiveItems && <FilterPanelCheckboxGroup
      name="isLuxExclusivePromotion"
      items={leExclusiveItems}
      onChange={onChange}
      defaultSelected={isLuxExclusivePromotion}
    />}

    {hasSpecialOffersItems && <FilterPanelCheckboxGroup
      name="isSpecialOffers"
      items={specialOffersItems}
      onChange={onChange}
      defaultSelected={isSpecialOffers}
    />}
  </div>
}

export default CruisePromotionFilter
