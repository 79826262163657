import React from 'react'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import { getImageUrl } from 'lib/image/imageUtils'
import { rem } from 'polished'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryUp } from 'components/utils/breakpoint'

const TourCatalogueHeader = styled(Group)`
  background-image: url(${getImageUrl('tour-catalogue-background-header')});
  background-position: center;
  background-size: 100%;
  padding: ${rem(10)} ${rem(20)};
  height: ${rem(155)};

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)} ${rem(48)};
    height: ${rem(225)};
  }
`

const TourCatalogueTitle = styled(Heading)`
  width: ${rem(175)};
  ${mediaQueryUp.tablet} {
    width: ${rem(235)};
  }
`

function AccountAccessTourCatalogueHeader() {
  return (
    <TourCatalogueHeader direction="vertical" gap={16} horizontalAlign="start" verticalAlign="center">
      <TourCatalogueTitle colour="neutral-eight" variant="heading2" align="start">Log in to <i>unlock</i> this catalogue</TourCatalogueTitle>
    </TourCatalogueHeader>
  )
}

export default AccountAccessTourCatalogueHeader
