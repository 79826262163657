import React from 'react'

const noop = () => {
  console.warn('Attempted to open a modal without a ModalProvider')
  return Promise.resolve<any>(null)
}
export type ModalCallback = (resolve: (val?: any) => any) => React.ReactElement

type ContextType = <T = void>(modal: React.ReactNode | ModalCallback, inforce?: boolean) => Promise<T>
const ModalContext = React.createContext<ContextType>(noop)

export default ModalContext
