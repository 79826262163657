import { addQueryParamsToUrl } from 'lib/url/searchUrlUtils'

import { getImageUrl } from 'lib/image/imageUtils'
import { sendShareWithFriendRequest, sendShareWithFriendV2Request } from 'api/notification'
import config from 'constants/config'
import qs from 'qs'

const SHARE_WITH_FRIEND_V2_OFFER_TYPES = ['hotel', 'tour', 'cruise', 'tour_v2', 'bedbank_hotel']

export interface ShareModalConfig {
  utmCampaign?: string;
  pathname: string;
  customerPortalURL: string;
  offerLocation?: string;
  memberId?: string;
  offerId: string;
  region: string;
  type: string;
  image?: App.Image;
  queryString?: string;
  offerType?: 'hotel' | 'tour' | 'cruise' | 'tour_v2' | 'bedbank_hotel';
}

const titlise = (val: string) => val.charAt(0).toUpperCase() + val.slice(1)

export function getShareModalConfig({
  utmCampaign,
  pathname,
  customerPortalURL,
  offerLocation,
  memberId,
  offerId,
  region,
  type,
  image,
  queryString = '',
  offerType,
}: ShareModalConfig,
opts?: { [key: string]: any },
) {
  const utm = {
    utm_campaign: utmCampaign,
    utm_source: config.BRAND,
    utm_medium: 'web',
  }
  const holidaySynonym = config.brandConfig.holidaySynonym
  const removedRegionPathname = pathname.replace(/^\/[a-z]+/, '')
  const url = addQueryParamsToUrl(`${customerPortalURL}${removedRegionPathname}`, { ...utm, ...qs.parse(queryString) })

  let title = `Your Next ${titlise(holidaySynonym)}`
  if (offerLocation) {
    title += ` in ${offerLocation}`
  }

  const useShareWithFriendV2 = config.BRAND === 'luxuryescapes' && offerType && SHARE_WITH_FRIEND_V2_OFFER_TYPES.includes(offerType)

  return {
    url,
    utm_campaign: 'webshare',
    mailToSubject: encodeURIComponent(title),
    shareImg: image?.id && getImageUrl(image.id, { width: 650 }),
    shareTitle: title,
    shareMessage: 'Check out this amazing holiday...',
    pageId: offerId || removedRegionPathname,
    pageType: type,
    onSend: async(state: { message?: string, friend_email: string}) => useShareWithFriendV2 ? sendShareWithFriendV2Request({
      offer_type: offerType,
      friend_email: state.friend_email,
      brand: config.BRAND,
      region,
      offer_id: offerId,
    }) : sendShareWithFriendRequest({
      type,
      offerId,
      memberId,
      location: offerLocation,
      region,
      utm,
      friendMessage: state.message,
      friendEmail: state.friend_email,
      heroImageId: image?.id,
    }),
    ...opts,
  }
}
