import React from 'react'
import { connect } from 'react-redux'

import { EmptyArray, take } from 'lib/array/arrayUtils'
import FilterChip from 'components/Luxkit/Chips/FilterChip'

interface Props {
  popularHolidayTypes?: Array<string>
  availableHolidayTypesCount: number
  isToggled: (popularHolidayType: string) => boolean
  maxVisible?: number;
  onSeeMore: () => void
  onToggle: (popularHolidayType: string) => void
}

function PopularHolidayTypesFilterToggles({
  popularHolidayTypes = EmptyArray,
  availableHolidayTypesCount,
  isToggled,
  maxVisible = 6,
  onSeeMore,
  onToggle,
}: Props) {
  const showSeeMore = popularHolidayTypes.length > maxVisible || popularHolidayTypes.length < availableHolidayTypesCount

  return (
    <>
      { take(popularHolidayTypes, maxVisible).map(popularHolidayType =>
        <FilterChip
          size="medium"
          key={popularHolidayType}
          selected={isToggled(popularHolidayType)}
          onClick={() => onToggle(popularHolidayType)}
        >
          {popularHolidayType}
        </FilterChip>)
      }
      { showSeeMore &&
        <FilterChip size="medium" onClick={onSeeMore}>
          See more...
        </FilterChip>
      }
    </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    popularHolidayTypes: state.destination.hotelPopularHolidayTypes,
  }
}

export default connect(mapStateToProps)(PopularHolidayTypesFilterToggles)
