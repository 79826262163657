import { toggleShowMarginInSpoofMode } from 'actions/SystemActions'
import SwitchButton from 'components/Luxkit/Switch/SwitchButton'
import { useAppDispatch } from 'hooks/reduxHooks'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'

interface MappedProps {
  showMarginInSpoofMode: boolean;
}

function ShowMarginSpoofModeToggle({
  showMarginInSpoofMode,
}: MappedProps) {
  const dispatch = useAppDispatch()

  const handleChange = useCallback(() => {
    dispatch(toggleShowMarginInSpoofMode())
  }, [dispatch])

  return <SwitchButton
    checked={!showMarginInSpoofMode}
    onChange={handleChange}
  >
    Store mode
  </SwitchButton>
}

function mapStateToProps(state: App.State) {
  return {
    showMarginInSpoofMode: state.system.showMarginInSpoofMode,
  }
}

export default connect(mapStateToProps)(ShowMarginSpoofModeToggle)
