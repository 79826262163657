/**
 * ⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️
 * @deprecated DO NOT USE THIS COMPONENT
 * see Luxkit/Tooltip
 * ⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️⛔️
 */
import React, { CSSProperties } from 'react'
import cn from 'clsx'
import noop from 'lib/function/noop'
import styled from 'styled-components'
import { rem } from 'polished'
import Caret from 'components/Common/Caret'
import SectionMessage, { SectionMessageSize, SectionMessageType } from 'components/Common/SectionMessage'
import zIndex from 'styles/tools/z-index'

const TooltipContent = styled(SectionMessage)`
  position: absolute;
  z-index: ${zIndex.tooltip};
  opacity: 0;
  visibility: hidden;
  line-height: initial;
  /* delay makes sure it only shows if the user hovers on it, not passes through it */
  transition: opacity 0.2s 0.3s, visibility 0s 0.5s;
  max-width: ${rem(500)};
  text-align: center;

  &.above {
    left: 50%;
    top: ${rem(-16)};

    &.alignment-left {
      transform: translateY(-100%) translateX(0);
      left: calc(50% - 28px);
    }

    &.alignment-right {
      transform: translateY(-100%) translateX(-100%);
      left: calc(50% + 28px);
    }

    &.alignment-center {
      transform: translateY(-100%) translateX(-50%);
    }
  }

  &.above-left {
    left: 0;
    top: ${rem(-16)};

    &.alignment-left {
      transform: translateY(-100%);
    }
  }

  &.below {
    left: 50%;
    bottom: ${rem(-16)};

    &.alignment-left {
      transform: translateY(100%) translateX(0);
      left: calc(50% - 28px);
    }

    &.alignment-right {
      transform: translateY(100%) translateX(-100%);
      left: calc(50% + 28px);
    }

    &.alignment-center {
      transform: translateY(100%) translateX(-50%);
    }
  }

  &.below-left {
    left: 0;
    bottom: ${rem(-16)};

    &.alignment-left {
      transform: translateY(100%) translateX(0);
    }
  }

  &.left {
    left: ${rem(-16)};
    top: 50%;

    &.alignment-center {
      transform: translateY(-50%) translateX(-100%);
    }
  }

  &.right {
    right: ${rem(-16)};
    top: 50%;

    &.alignment-center {
      transform: translateY(-50%) translateX(100%);
    }
  }

  &.hover:hover, &.open {
    opacity: 1;
    transition: opacity 0.2s 0.3s;
    visibility: visible;
  }
`

const PositionedCaret = styled(Caret)`
  position: absolute;
  border-width: ${rem(10)};

  &.dark {
    border-top-color: ${props => props.theme.palette.neutral.default.one};
  }

  &.error {
    border-top-color: ${props => props.theme.palette.messaging.critical.normalForeground};
  }

  &.light {
    border-top-color: ${props => props.theme.palette.neutral.default.five};

    &::before {
      left: 50%;
      border-top-color: ${props => props.theme.palette.neutral.default.eight};
      border-width: ${rem(10)};
      transform: translateX(-50%);
    }
  }

  &.above {
    bottom: 0;
    transform: translateY(100%);

    &.alignment-left {
      left: ${rem(16)};
    }

    &.alignment-right {
      right: ${rem(16)};
    }

    &.alignment-center {
      left: 50%;
      transform: translateY(100%) translateX(-50%);
    }
  }

  &.above-left {
    bottom: 0;
    transform: translateY(100%);

    &.alignment-left {
      left: ${rem(16)};
    }
  }

  &.below {
    top: 0;
    transform: translateY(-100%) rotate(180deg);

    &.alignment-left {
      left: ${rem(16)};
    }

    &.alignment-right {
      right: ${rem(16)};
    }

    &.alignment-center {
      left: 50%;
      transform: translateX(-50%) translateY(-100%) rotate(180deg);
    }
  }

  &.below-left {
    top: 0;
    transform: translateY(-100%) rotate(180deg);

    &.alignment-left {
      left: ${rem(16)};
    }
  }

  &.left {
    right: 0;
    top: 50%;
    transform: translateX(70%) translateY(-50%) rotate(-90deg);
  }

  &.right {
    left: 0;
    top: 50%;
    transform: translateX(-70%) translateY(-50%) rotate(-270deg);
  }
`

type TooltipAlignments = 'left' | 'right' | 'center';
type TooltipPositions = 'above' | 'above-left' | 'below' | 'below-left' | 'left' | 'right';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  position?: TooltipPositions;
  alignment?: TooltipAlignments;
  open?: boolean;
  width?: CSSProperties['width'];
  type?: SectionMessageType;
  size?: SectionMessageSize;
  disableHover?: boolean;
  attachRef?: React.Ref<HTMLElement>;
}

/**
 * @deprecated use Luxkit/Tooltip instead
 */
const DeprecatedAutoTooltipContent = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    disableHover,
    onClick = noop,
    children,
    position = 'above',
    open,
    alignment = 'center',
    style,
    width,
    type = 'dark',
    attachRef,
    ...rest
  } = props

  const onClickIntercept = (e: React.MouseEvent<HTMLDivElement>) => {
    // this tooltip content is meant to be in it's own little window
    // so we don't want click events bubbling up it's tree and the tree is not meant
    // to be there. e.g. there might be a button above us!
    e.stopPropagation()
    onClick?.(e)
  }

  return (
    <TooltipContent
      {...rest}
      ref={ref}
      onClick={onClickIntercept}
      style={{ ...style, width }}
      type={type}
      className={cn(
        className,
        position,
        `alignment-${alignment}`,
        {
          open,
          hover: !disableHover,
        },
      )}
    >
      {children}
      <PositionedCaret className={cn(position, type, `alignment-${alignment}`)} />
    </TooltipContent>
  )
})

export default DeprecatedAutoTooltipContent
