import { elementsToObject } from 'lib/forms/formToObject'

export function validateSemiForm(semiFormElement: HTMLElement) {
  const allInputElements = Array.from<HTMLOutputElement>(semiFormElement.querySelectorAll('input, select, textarea'))
  // validate them
  return allInputElements.reduce((valid, input) => {
    if (valid) {
      return input.reportValidity()
    } else {
      // we still want to trigger a validity check, just not report them all
      input.checkValidity()
      return false
    }
  }, true)
}

export function semiFormToObject<T extends Record<string, any>>(semiFormElement: HTMLElement) {
  const allInputElements = Array.from<HTMLOutputElement>(semiFormElement.querySelectorAll('input, select, textarea'))
  return elementsToObject<T>(allInputElements)
}
