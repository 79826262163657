import { components } from '@luxuryescapes/contract-public-offer'
type ContractSchema = components['schemas']
type LeHotelOffer = ContractSchema['leHotelOffer']
type LeTourOffer = ContractSchema['v1TourOffer']

function offerUrgencyLabelMap(offer: LeHotelOffer | LeTourOffer): Array<App.OfferUrgencyLabel> {
  /**
   * All urgency tags from public offer are no longer used except the lux plus general release
   * and the 'left' tag
   */
  const tags: Array<App.OfferUrgencyLabel> = []
  const luxPlusTag = offer.tags.urgency.find(tag => tag.type === 'lux_plus_general_release')
  if (luxPlusTag) {
    const releaseStart = offer.schedules?.luxPlus?.start
    const releaseEnd = offer.schedules?.listVisibility?.start

    tags.push({
      start: releaseStart,
      end: releaseEnd,
      type: 'lux_plus_general_release',
      message: luxPlusTag.message ?? undefined,
    })
  }

  if (
    offer.tags.urgency.some(tag => tag.type === 'left') &&
    // always on never want to show their 'left' tags
    offer.type !== 'tactical_ao_hotel' &&
    offer.schedules?.listVisibility
  ) {
    tags.push({
      end: offer.schedules.listVisibility.end,
      type: 'left',
      message: undefined,
    })
  }

  return tags
}

export default offerUrgencyLabelMap
