import { createSelector } from 'reselect'
import { isLoyaltyLoungePassItem } from 'lib/checkout/checkoutUtils'

/**
 * Retrieves all loyalty lounge pass items in the checkout
 */
const getCheckoutLuxLoyaltyLoungePassItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items) => {
    return items.filter(isLoyaltyLoungePassItem)
  },
)

export default getCheckoutLuxLoyaltyLoungePassItems
