import { toggleMultiCartItemMode } from 'actions/CheckoutActions'
import SwitchButton from 'components/Luxkit/Switch/SwitchButton'
import { useAppDispatch } from 'hooks/reduxHooks'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'

interface MappedProps {
  isMultiItemMode: boolean;
}

function MultiCartItemModeToggle({
  isMultiItemMode,
}: MappedProps) {
  const dispatch = useAppDispatch()

  const handleChange = useCallback(() => {
    dispatch(toggleMultiCartItemMode())
  }, [dispatch])

  return (
    <SwitchButton
      checked={isMultiItemMode}
      onChange={handleChange}
    >
      Add to cart
    </SwitchButton>
  )
}

function mapStateToProps(state: App.State) {
  return {
    isMultiItemMode: state.checkout.cart.isMultiItemMode,
  }
}

export default connect(mapStateToProps)(MultiCartItemModeToggle)
