import { createSelector } from 'reselect'
import getCheckoutLuxLoyaltyLoungePassItems from './getLuxLoyaltyLoungePassItems'
import { nonNullable } from 'lib/array/arrayUtils'
import { getExperienceTimesKey } from 'lib/experiences/experienceUtils'

const getLuxLoyaltyLoungePassItemViews = createSelector(
  (state: App.State) => getCheckoutLuxLoyaltyLoungePassItems(state),
  (state: App.State) => state.experience.experiences,
  (state: App.State) => state.experience.experienceTimes,
  (state: App.State) => state.geo.currentCurrency,
  (items, experiences, experienceTimeslots, currencyCode): App.WithDataStatus<Array<App.Checkout.LoyaltyLoungePassItemView>> => {
    const views = nonNullable(items.map((item): App.Checkout.LoyaltyLoungePassItemView | undefined => {
      const experience = experiences[item.experienceId]
      if (!experience) {
        return
      }

      const key = getExperienceTimesKey(item.experienceId, item.isBookLater ? undefined : item.date, {
        currency: currencyCode,
        redemptionLocationId: experience.redemptionLocations[0].id,
        isBuyNowBookLater: item.isBookLater,
      })
      const timeslots = experienceTimeslots[item.experienceId]?.[key]?.slots

      if (!timeslots) {
        return
      }

      const ticket = timeslots.find(slot => slot.time === item.time)?.tickets.find(ticket => ticket.id === item.ticketId)

      if (!ticket) {
        return
      }

      return {
        item,
        ticket,
        experience,
        luxLoyaltyProductType: 'experience',
        totals: {
          price: item.isFree ? 0 : ticket.price,
          extraGuestSurcharge: 0,
          memberPrice: item.isFree ? 0 : ticket.price,
          memberValue: experience.pricing.value,
          surcharge: 0,
          taxesAndFees: 0,
          value: experience.pricing.value,
          businessTravellerCredits: 0,
        },
      }
    }))

    return {
      data: views,
      // views are only populated once we have all the data
      hasRequiredData: views.length === items.length,
    }
  },
)

export default getLuxLoyaltyLoungePassItemViews
