import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import LuxPlusLabelWithModal from 'luxPlus/components/LuxPlusLabelWithModal'
import React from 'react'
import SolidFireIcon from 'components/Luxkit/Icons/solid/SolidFireIcon'

interface Props {
  offer: App.TourOffer | App.TourOfferSummary | App.CruiseOffer;
  label: App.OfferUrgencyLabel;
}

function CruiseUrgencyLabel(props: Props) {
  const { offer, label } = props

  switch (label.type) {
    case 'agenthub_exclusive':
      return <PromotionLabel variant="default">Agent exclusive</PromotionLabel>
    case 'left':
      return <OfferUrgencyCountdownLabel endDate={label.end!} />
    case 'sale':
      return <PromotionLabel icon={<SolidFireIcon />} variant="premium">{label.message}</PromotionLabel>
    case 'lux_plus_member_price':
      return <LuxPlusLabelWithModal
        type="member-price-offer-tour"
        saveAmount={label.saveAmount}
        offer={offer}
      />
    default:
      return null
  }
}

export default CruiseUrgencyLabel
