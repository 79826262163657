import { adjustLuxLoyaltyPointsEarn, calculateLuxLoyaltyStatusCreditsFromPoints, LUX_LOYALTY_POINTS_PER_STATUS_CREDIT } from '@luxuryescapes/lib-lux-loyalty'
import getCheckoutLuxLoyaltyBurningPointItems from 'checkout/selectors/luxLoyalty/getCheckoutLuxLoyaltyBurningPointItems'
import getCheckoutLuxLoyaltyItemsPriceInCurrency from 'checkout/selectors/luxLoyalty/getCheckoutLuxLoyaltyItemsPriceInCurrency'
import getCheckoutLuxLoyaltyUnearnableCreditAmount from 'checkout/selectors/luxLoyalty/getCheckoutLuxLoyaltyUnearnableCreditAmount'
import getAllItemsDiscountTotals from 'checkout/selectors/payment/getAllItemsDiscountTotals'
import getCheckoutTotalsView from 'checkout/selectors/payment/getCheckoutTotalsView'
import { isUsingCredit } from 'checkout/selectors/view/generic'
import getAllItemViews from 'checkout/selectors/view/getAllItemViews'
import { EmptyMap } from 'lib/array/arrayUtils'
import buildPointsKey from 'luxLoyalty/lib/pointsCalculation/buildPointsKey'
import getCheckoutPointsCalculationRequests from 'luxLoyalty/selectors/checkout/pointsCalculation/getCheckoutPointsCalculationRequests'
import { createSelector } from 'reselect'
import getCheckoutLuxLoyaltyItemsPointsEarnRatios from './getCheckoutLuxLoyaltyItemsPointsEarnRatios'

function adjustPointsEarnAfterDeduction(pointsEarn: number, deduction: number): number {
  if (deduction <= pointsEarn) {
    return pointsEarn - deduction
  }
  return 0
}

const getCheckoutLuxLoyaltyPointsEarnItemTotals = createSelector(
  (state: App.State) => state.luxLoyalty.pointsEarnCalculations,
  (state: App.State) => state.auth.account.memberId,
  (state: App.State) => getCheckoutPointsCalculationRequests(state),
  (state: App.State) => getAllItemViews(state),
  (state: App.State) => getCheckoutLuxLoyaltyItemsPriceInCurrency(state),
  (state: App.State) => getCheckoutLuxLoyaltyItemsPointsEarnRatios(state),
  (state: App.State) => getAllItemsDiscountTotals(state),
  (state: App.State) => getCheckoutLuxLoyaltyBurningPointItems(state),
  (state: App.State) => getCheckoutTotalsView(state),
  (state: App.State) => getCheckoutLuxLoyaltyUnearnableCreditAmount(state),
  (state: App.State) => isUsingCredit(state),
  (
    pointsEarnCalculations,
    memberId,
    calculationEarnRequests,
    allItemViews,
    itemsPriceInCurrency,
    pointsEarnRatios,
    allItemsDiscountTotals,
    burningPointItems,
    checkoutTotalsView,
    unearnableCreditAmount,
    isUsingCredit,
  ): Map<string, App.Checkout.LuxLoyaltyPointsEarnItemTotals> => {
    if (!checkoutTotalsView.hasRequiredData) return EmptyMap

    const totalsPointsEarn = new Map<string, App.Checkout.LuxLoyaltyPointsEarnItemTotals>()

    calculationEarnRequests.forEach(calculationEarnRequest => {
      if (!calculationEarnRequest.itemId) return

      const itemView = allItemViews.data.get(calculationEarnRequest.itemId)?.data

      if (!itemView) return

      const itemId = itemView.item.itemId

      const itemPricing = itemsPriceInCurrency.get(itemId)
      /**
       * This always needs to be the payable price which is then used for adjusting points ratios
       */
      const defaultPayableItemPrice = itemPricing?.price ?? 0

      const pointsEarnCalculation = pointsEarnCalculations[buildPointsKey(calculationEarnRequest, memberId ?? '')]
      if (!pointsEarnCalculation?.data) return

      const basePointsEarn = pointsEarnCalculation.data.points
      const baseStatusCredits = pointsEarnCalculation.data.statusCredits

      const itemPointsEarnRatio = pointsEarnRatios.get(itemId)

      if (!itemPointsEarnRatio) return

      const itemDiscount = allItemsDiscountTotals.get(itemView.item.itemId) ?? 0

      const hasEarnableStatusCredits = baseStatusCredits > 0

      const deductedPointsEarnFromPromo = Math.round(itemDiscount * itemPointsEarnRatio)
      const deductedStatusCreditsFromPromo = hasEarnableStatusCredits ? calculateLuxLoyaltyStatusCreditsFromPoints(deductedPointsEarnFromPromo) : 0

      let adjustedItemPrice = defaultPayableItemPrice

      adjustedItemPrice -= itemDiscount

      const burningPointItem = burningPointItems.find((burningPointItem) => burningPointItem.itemId === itemId)

      const deductedPointsEarnFromBurn = burningPointItem ? Math.round(burningPointItem.pointsPaidInCurrency * itemPointsEarnRatio) : 0
      const deductedStatusCreditsFromBurn = burningPointItem && hasEarnableStatusCredits ? calculateLuxLoyaltyStatusCreditsFromPoints(deductedPointsEarnFromBurn) : 0

      adjustedItemPrice -= burningPointItem?.pointsPaidInCurrency ?? 0

      const {
        grandTotal,
        amountPayableByCredit,
        serviceFee = 0,
        merchantFee = 0,
        otherFees,
      } = checkoutTotalsView.data

      const propertyFees = otherFees?.propertyFees ?? 0
      const grandTotalPreCreditsApplied = (grandTotal - serviceFee - merchantFee - propertyFees) + amountPayableByCredit
      const itemWeightedCreditRatio = adjustedItemPrice / grandTotalPreCreditsApplied
      const itemUnearnableCreditAmount = isUsingCredit ? itemWeightedCreditRatio * unearnableCreditAmount : 0

      const deductedPointsEarnFromUnearnableCredit = Math.round(itemUnearnableCreditAmount * itemPointsEarnRatio)
      const deductedStatusCreditsFromUnearnableCredit = hasEarnableStatusCredits ? calculateLuxLoyaltyStatusCreditsFromPoints(deductedPointsEarnFromUnearnableCredit) : 0

      const adjustedPointsEarnAfterPromo = adjustPointsEarnAfterDeduction(basePointsEarn, deductedPointsEarnFromPromo)
      const adjustedStatusCreditsEarnAfterPromo = adjustPointsEarnAfterDeduction(baseStatusCredits, deductedStatusCreditsFromPromo)

      const adjustedPointsEarnAfterBurn = adjustPointsEarnAfterDeduction(adjustedPointsEarnAfterPromo, deductedPointsEarnFromBurn)
      const adjustedStatusCreditsEarnAfterBurn = adjustPointsEarnAfterDeduction(adjustedStatusCreditsEarnAfterPromo, deductedStatusCreditsFromBurn)

      const adjustedPointsEarnAfterUnearnableCredit = adjustPointsEarnAfterDeduction(adjustedPointsEarnAfterBurn, deductedPointsEarnFromUnearnableCredit)
      const adjustedStatusCreditsEarnAfterUnearnableCredit = adjustPointsEarnAfterDeduction(adjustedStatusCreditsEarnAfterBurn, deductedStatusCreditsFromUnearnableCredit)

      const totalPointsEarn = adjustLuxLoyaltyPointsEarn(adjustedPointsEarnAfterUnearnableCredit)
      // Return the originally calculated status credits if there has been no adjustment
      const totalStatusCredits = baseStatusCredits === adjustedStatusCreditsEarnAfterUnearnableCredit ? baseStatusCredits : calculateLuxLoyaltyStatusCreditsFromPoints(totalPointsEarn)

      const statusCreditsEarnRatio = hasEarnableStatusCredits ? itemPointsEarnRatio / LUX_LOYALTY_POINTS_PER_STATUS_CREDIT : 0

      totalsPointsEarn.set(itemId, {
        pointsEarnRatio: itemPointsEarnRatio,
        statusCreditsEarnRatio,
        defaultPayableItemPrice,
        basePointsEarn,
        baseStatusCredits,
        itemPromoDiscount: itemDiscount,
        deductedPointsEarnFromBurn,
        deductedStatusCreditsFromBurn,
        pointsPaidInCurrency: burningPointItem?.pointsPaidInCurrency ?? 0,
        deductedPointsEarnFromPromo,
        deductedStatusCreditsFromPromo,
        itemUnearnableCreditAmount,
        deductedPointsEarnFromUnearnableCredit,
        deductedStatusCreditsFromUnearnableCredit,
        baseTotalPointsEarn: adjustedPointsEarnAfterUnearnableCredit,
        baseTotalStatusCredits: adjustedStatusCreditsEarnAfterUnearnableCredit,
        totalPointsEarn,
        totalStatusCredits,
      })
    })

    if (!totalsPointsEarn.size) return EmptyMap

    return totalsPointsEarn
  },
)

export default getCheckoutLuxLoyaltyPointsEarnItemTotals
