import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'
import { checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'
import { createSelector } from 'reselect'
import getHotelEncryptedMargin from 'luxLoyalty/lib/margin/getHotelEncryptedMargin'
import { EmptyArray } from 'lib/array/arrayUtils'

const getCheckoutAccommodationPointsCalculationRequests = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (accommodationOfferView): Array<App.LuxLoyaltyPointsEarnCalculationRequest> => {
    if (!accommodationOfferView.hasRequiredData) return EmptyArray

    const pointsCalculations: Array<App.LuxLoyaltyPointsEarnCalculationRequest> = []

    accommodationOfferView.data.forEach((data) => {
      if (!data.offer) return

      const luxLoyaltyProductType = getLuxLoyaltyProductType(data.offer)

      if (!luxLoyaltyProductType) return

      data.itemViews.forEach((itemView: App.Checkout.AccommodationItemView) => {
        const memberPrice = itemView.totals.memberPrice

        if (itemView.kind === 'le') {
          if (luxLoyaltyProductType === 'hotel_flash' || luxLoyaltyProductType === 'hotel_lpc') {
            pointsCalculations.push({
              price: itemView.totals.price + itemView.totals.surcharge,
              memberPrice: memberPrice > 0 ? memberPrice + itemView.totals.surcharge : 0,
              luxLoyaltyProductType,
              mx: getHotelEncryptedMargin(data.offer!.type, itemView.loyaltyUpgrade?.originPackage ?? itemView.pkg),
              offerId: data.offerId,
              packageId: itemView.packageId,
              itemId: itemView.item.itemId,
            })
          } else if (luxLoyaltyProductType !== 'rental') {
            pointsCalculations.push({
              price: itemView.totals.price + itemView.totals.surcharge,
              memberPrice: memberPrice > 0 ? memberPrice + itemView.totals.surcharge : 0,
              luxLoyaltyProductType,
              mx: getHotelEncryptedMargin(data.offer!.type, itemView.loyaltyUpgrade?.originPackage ?? itemView.pkg),
              itemId: itemView.item.itemId,
            })
          }
        }

        if (luxLoyaltyProductType === 'hotel_lpp_eps' && itemView.kind === 'bedbank') {
          pointsCalculations.push({
            price: itemView.totals.price + itemView.totals.surcharge,
            memberPrice: memberPrice > 0 ? memberPrice + itemView.totals.surcharge : 0,
            luxLoyaltyProductType,
            mx: itemView.rate?.mx,
            itemId: itemView.item.itemId,
          })
        }

        if (itemView.kind === 'cruise') {
          pointsCalculations.push({
            itemId: itemView.item.itemId,
            luxLoyaltyProductType: luxLoyaltyProductType as App.CruiseLoyaltyProductType,
            memberPrice,
            price: itemView.totals.price || itemView.currentBookingStepLowestPrice || 0,
          })
        }

        if (itemView.kind === 'tourV2') {
          pointsCalculations.push({
            price: itemView.totals.price,
            memberPrice,
            luxLoyaltyProductType: itemView.luxLoyaltyProductType as App.LuxLoyaltyTourProductType,
            itemId: itemView.item.itemId,
          })
        }
      })
    })

    return pointsCalculations
  },
)

export default getCheckoutAccommodationPointsCalculationRequests
