import React, { useCallback, useMemo } from 'react'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import BookingProtectionComparisonTable from './BookingProtectionComparisonTable'
import SolidShieldCheckIcon from 'components/Luxkit/Icons/solid/SolidShieldCheckIcon'
import LineMultiplyIcon from 'components/Luxkit/Icons/line/LineMultiplyIcon'
import { useIntlDateFormatter } from 'lib/datetime/dateUtils'
import { INTL_DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import Carousel from 'components/Luxkit/Carousel/Carousel'
import CancellationEnhancedProtectionCard from './BookingEnhancedProtectionCard'
import CancellationLimitedProtectionCard from './BookingLimitedProtectionCard'
import { connect } from 'react-redux'
import {
  getBookingProtectionQuoteLuxPlusPrice,
  getBookingProtectionQuoteLuxPlusPricePerTraveller,
  getBookingProtectionQuotePrice,
  getBookingProtectionQuotePricePerTraveller,
  getQuoteFromBookingProtectionQuotes,
} from 'checkout/selectors/request/bookingProtection'
import { isBookingProtectionSelected } from 'checkout/selectors/view/bookingProtection'
import { selectShouldUseBookingProtectionMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'
import { isStandaloneExperience } from 'selectors/checkoutSelectors'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import LineCheckCircleIcon from 'components/Luxkit/Icons/line/LineCheckCircleIcon'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { generateBookingProtectionItem } from 'lib/checkout/bookingProtection/cart'
import { addItem } from 'actions/CheckoutActions'
import { useAppDispatch } from 'hooks/reduxHooks'

export interface BookingProtectionContent {
  heading: string;
  enhancedProtectionText: string;
  enhancedProtectionIcon: React.ReactNode;
  limitedProtectionText: string;
  limitedProtectionIcon: React.ReactNode;
}

interface Props {
  cancellationType?: 'free' | 'flexible';
  cancelUntilFullRefundDate?: moment.Moment;
  cancelAfterNonRefundableDate?: moment.Moment;
  bookingProtectionQuotePricePerTraveller?: number;
  bookingProtectionQuote?: App.BookingProtectionQuote;
  bookingProtectionLastEligibleRefundDate?: moment.Moment;
  bookingProtectionQuotePrice: number;
  cancellationAfterChangeOfMindPeriod?: boolean | '';
  bookingProtectionSelected?: boolean;
  checkoutWithDiscountedBookingProtection: boolean;
  bookingProtectionQuoteLuxPlusPricePerTraveller: number;
  bookingProtectionQuoteLuxPlusPrice: number;
  isStandaloneExperience: boolean;
  onProtectionSelect?: () => void;
  quote?: App.InsuranceQuote;
}

function BookingProtectionComparison(props: Props) {
  const {
    cancellationType,
    cancelUntilFullRefundDate,
    cancelAfterNonRefundableDate = cancelUntilFullRefundDate,
    bookingProtectionQuotePricePerTraveller,
    bookingProtectionQuote,
    bookingProtectionLastEligibleRefundDate,
    bookingProtectionQuotePrice,
    cancellationAfterChangeOfMindPeriod,
    bookingProtectionSelected,
    checkoutWithDiscountedBookingProtection,
    bookingProtectionQuoteLuxPlusPricePerTraveller,
    bookingProtectionQuoteLuxPlusPrice,
    isStandaloneExperience,
    onProtectionSelect,
    quote,
  } = props

  const intlDateFormatter = useIntlDateFormatter()
  const modalContext = useModalElementContext()
  const dispatch = useAppDispatch()

  const content: Array<BookingProtectionContent> = useMemo(() => {
    if (cancelUntilFullRefundDate && cancelAfterNonRefundableDate && bookingProtectionLastEligibleRefundDate) {
      const canCanAfterNonRefundable = cancellationType === 'flexible' && cancellationAfterChangeOfMindPeriod
      return [{
        heading: `Cancel until ${intlDateFormatter(cancelUntilFullRefundDate, INTL_DMY_CASUAL_SHORT_FORMAT)}`,
        enhancedProtectionText: '100% refund to original payment',
        enhancedProtectionIcon: <SolidShieldCheckIcon colour="highlight-secondary-normal" />,
        limitedProtectionText: '100% refund to original payment or credit',
        limitedProtectionIcon: <SolidShieldCheckIcon colour="highlight-secondary-normal" />,
      },
      ...(canCanAfterNonRefundable ? [{
        heading: `Cancel until ${intlDateFormatter(cancelAfterNonRefundableDate, INTL_DMY_CASUAL_SHORT_FORMAT)}`,
        enhancedProtectionText: '100% refund to original payment',
        enhancedProtectionIcon: <SolidShieldCheckIcon colour="highlight-secondary-normal" />,
        limitedProtectionText: '100% refund to credit',
        limitedProtectionIcon: <LineMultiplyIcon />,
      }] : []),
      {
        heading: `Cancel until ${intlDateFormatter(bookingProtectionLastEligibleRefundDate, INTL_DMY_CASUAL_SHORT_FORMAT)}`,
        enhancedProtectionText: '100% refund to original payment',
        enhancedProtectionIcon: <SolidShieldCheckIcon colour="highlight-secondary-normal" />,
        limitedProtectionText: 'Non-refundable',
        limitedProtectionIcon: <LineMultiplyIcon />,
      }]
    }

    return []
  }, [bookingProtectionLastEligibleRefundDate, cancelAfterNonRefundableDate, cancelUntilFullRefundDate, cancellationAfterChangeOfMindPeriod, cancellationType, intlDateFormatter])

  const handleAddCFMRBookingProtection = useCallback(() => {
    if (quote && onProtectionSelect) {
      onProtectionSelect()
      modalContext.resolve()
      showSnackbar('Cancellation Protection added.', 'success', { icon: <LineCheckCircleIcon /> })
    }
  }, [quote, onProtectionSelect, modalContext])

  const handleAddBookingProtection = useCallback(() => {
    if (bookingProtectionSelected) return
    if (!bookingProtectionQuote) return
    dispatch(addItem(generateBookingProtectionItem(bookingProtectionQuote)))
    modalContext.resolve()
    showSnackbar('Cancellation Protection added.', 'success', { icon: <LineCheckCircleIcon /> })
  }, [dispatch, modalContext, bookingProtectionSelected, bookingProtectionQuote])

  if (content.length === 0) {
    return null
  }

  return (
    <>
      <CSSBreakpoint min="tablet">
        <BookingProtectionComparisonTable
          bookingProtectionContent={content}
          bookingProtectionQuotePricePerTraveller={bookingProtectionQuotePricePerTraveller}
          bookingProtectionSelected={bookingProtectionSelected}
          checkoutWithDiscountedBookingProtection={checkoutWithDiscountedBookingProtection}
          bookingProtectionQuoteLuxPlusPricePerTraveller={bookingProtectionQuoteLuxPlusPricePerTraveller}
          bookingProtectionQuotePrice={bookingProtectionQuotePrice}
          bookingProtectionQuoteLuxPlusPrice={bookingProtectionQuoteLuxPlusPrice}
          isStandaloneExperience={isStandaloneExperience}
          onAddCFMRBookingProtection={handleAddCFMRBookingProtection}
          onAddBookingProtection={handleAddBookingProtection}
          quote={quote}
        />
      </CSSBreakpoint>
      <CSSBreakpoint max="mobile">
        <Carousel width="82%"> {/* Need to make it 82% to fix content on 1 line */}
          <CancellationEnhancedProtectionCard
            bookingProtectionContent={content}
            bookingProtectionQuotePricePerTraveller={bookingProtectionQuotePricePerTraveller}
            bookingProtectionSelected={bookingProtectionSelected}
            bookingProtectionQuotePrice={bookingProtectionQuotePrice}
            checkoutWithDiscountedBookingProtection={checkoutWithDiscountedBookingProtection}
            bookingProtectionQuoteLuxPlusPricePerTraveller={bookingProtectionQuoteLuxPlusPricePerTraveller}
            bookingProtectionQuoteLuxPlusPrice={bookingProtectionQuoteLuxPlusPrice}
            isStandaloneExperience={isStandaloneExperience}
            onAddCFMRBookingProtection={handleAddCFMRBookingProtection}
            onAddBookingProtection={handleAddBookingProtection}
            quote={quote}
          />
          <CancellationLimitedProtectionCard
            bookingProtectionContent={content}
          />
        </Carousel>
      </CSSBreakpoint>
    </>

  )
}

const mapStateToProps = (state: App.State) => ({
  bookingProtectionQuotePricePerTraveller: getBookingProtectionQuotePricePerTraveller(state),
  bookingProtectionSelected: isBookingProtectionSelected(state),
  bookingProtectionQuote: getQuoteFromBookingProtectionQuotes(state),
  bookingProtectionQuotePrice: getBookingProtectionQuotePrice(state),
  checkoutWithDiscountedBookingProtection: selectShouldUseBookingProtectionMemberPrice(state),
  bookingProtectionQuoteLuxPlusPricePerTraveller: getBookingProtectionQuoteLuxPlusPricePerTraveller(state),
  bookingProtectionQuoteLuxPlusPrice: getBookingProtectionQuoteLuxPlusPrice(state),
  isStandaloneExperience: isStandaloneExperience(state),
})

export default connect(mapStateToProps)(BookingProtectionComparison)
