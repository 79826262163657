import { fetchOrders } from 'actions/OrderActions'
import Divider from 'components/Luxkit/Divider'
import Group from 'components/utils/Group'
import ListItem from 'components/Luxkit/List/ListItem'
import config from 'constants/config'
import { useAppDispatch } from 'hooks/reduxHooks'
import React, { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { getUpcomingOrders } from 'selectors/orderSelectors'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import { getPlural } from 'lib/string/pluralize'
import fetchAccountBusiness from 'actions/businessTraveller/fetchBusinessAccount'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import LineBuildingIcon from 'components/Luxkit/Icons/line/LineBuildingIcon'
import RadioButton from 'components/Luxkit/Radio/RadioButton'
import BusinessTravellerAccountGuard from '../BusinessTravellerAccountGuard'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { authLogout } from 'actions/AuthActions'
import BusinessTravellerAccountListItemFailedAccess from './BusinessTravellerAccountListItemFailedAccess'
import ListItemLoadingSkeleton from 'components/Luxkit/List/ListItemLoadingSkeleton'
import { clearBusinessTravellerSelectedTraveller } from 'businessTraveller/storage/businessTravellerStorage'

const IS_PERSONAL_ACCOUNT = config.businessTraveller.currentAccountMode === 'personal'
const IS_BUSINESS_ACCOUNT = config.businessTraveller.currentAccountMode === 'business'

interface MappedStateProps {
  currentBusinessAccount?: App.BusinessTraveller.CurrentBusinessAccount
  currentPersonalAccount: App.AuthAccount
  numberOfUpcomingOrders: number
}

interface Props {
  onListItemClick?: () => void
}

function BusinessTravellerAccountMenuContent(props: Props & MappedStateProps) {
  const {
    currentBusinessAccount,
    currentPersonalAccount,
    numberOfUpcomingOrders,
    onListItemClick,
  } = props
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchOrders({ status: 'upcoming' }))
    dispatch(fetchAccountBusiness())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const myOrdersLabel = useMemo<string>(() => {
    let text = `My ${getPlural(config.brandConfig.purchaseSynonym, 2)}`

    if (numberOfUpcomingOrders) {
      text += ` (${numberOfUpcomingOrders} upcoming)`
    }

    return text
  }, [numberOfUpcomingOrders])

  const modalState = useModalElementContext<'item-clicked'>()

  const handleItemClick = useCallback(() => {
    modalState?.resolve('item-clicked')
    onListItemClick?.()
  }, [modalState, onListItemClick])

  const logOut = useCallback(() => {
    // stolen from `AccountLandingPage.tsx`
    config.GOOGLE_ONE_TAP_LOGIN && window.google?.accounts?.id.disableAutoSelect()
    clearBusinessTravellerSelectedTraveller()
    dispatch(authLogout())
    handleItemClick()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Group direction="vertical" gap={8}>
    <ListItem
      href={config.businessTraveller.personalPortalHref}
      target="_blank"
      startIcon={<LineUserIcon />}
      title="Personal"
      subtitle={currentBusinessAccount?.employee?.creditCustomerEmail ? currentBusinessAccount?.employee?.creditCustomerEmail : currentPersonalAccount.email}
      endIcon={<RadioButton checked={IS_PERSONAL_ACCOUNT} />}
      nonInteractable={IS_PERSONAL_ACCOUNT}
    />
    <BusinessTravellerAccountGuard
      loading={<ListItemLoadingSkeleton startIcon={<LineBuildingIcon />} subtitle="100%" />}
      fallback={<BusinessTravellerAccountListItemFailedAccess />}
    >
      <ListItem
        href={config.businessTraveller.businessPortalHref}
        target="_blank"
        startIcon={<LineBuildingIcon />}
        title={`Business · ${currentBusinessAccount?.business?.name}`}
        subtitle={currentBusinessAccount?.employee?.email}
        endIcon={<RadioButton checked={IS_BUSINESS_ACCOUNT} />}
        nonInteractable={IS_BUSINESS_ACCOUNT}
      />
    </BusinessTravellerAccountGuard>
    <Divider kind="primary" />
    <div>
      <BusinessTravellerAccountGuard
        accountMode="business"
        employeeRoles={['BUSINESS_ADMIN', 'BUSINESS_MANAGER']}
        loading={<ListItemLoadingSkeleton startIcon={<LineBuildingIcon />} subtitle="100%" />}
      >
        <ListItem
          href={config.businessTraveller.businessAdminPortalHref}
          target="_blank"
          title="Admin Dashboard"
        />
      </BusinessTravellerAccountGuard>
      <ListItem
        to="/account/my-escapes"
        title={myOrdersLabel}
        titleFormat="titlecase"
        onClick={handleItemClick}
      />
      <TripGuard>
        <ListItem type="button" title="Trips" onClick={handleItemClick} />
      </TripGuard>
      <ListItem
        to="/account"
        title="Profile"
        onClick={handleItemClick}
      />
    </div>
    <Divider kind="primary" />
    <ListItem type="button" title="Log out" onClick={logOut} />
  </Group>
}

export default connect<MappedStateProps, undefined, {}, App.State>((state) => {
  return {
    currentBusinessAccount: state.businessTraveller.currentBusinessAccount,
    currentPersonalAccount: state.auth.account,
    numberOfUpcomingOrders: getUpcomingOrders(state).length,
  }
})(BusinessTravellerAccountMenuContent)
