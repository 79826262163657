import React, { useCallback, useContext, useMemo } from 'react'
import { FlightDropdownOption } from 'components/Flights/types'
import { fareTypes, fareCabins, FlightsFareTypes } from 'constants/flight'
import { FlightSearchWidgetActions, FlightSearchWidgetFlightItem, createEmptyFlightSearchWidgetFlightItem } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FareDropdownMobile from './FareDropdown/FareDropdownMobile'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FlightSearchTravellerSelectField from './FlightTravellerSelect/FlightSearchTravellerSelectField'
import Group from 'components/utils/Group'
import config from 'constants/config'
import ModalContext from 'contexts/ModalContext'
import FlightSearchMenuMobile from './FlightSearchMenu/FlightSearchMenuMobile'
import FlightSearchTravellerSelectContent from './FlightTravellerSelect/FlightSearchTravellerSelectContent'
import FlightSearchWidgetMobileForm from './FlightSearchWidgetMobileForm'
import FlightSearchWidgetMobileMultiCityForm from './FlightSearchWidgetMobileMultiCityForm'

interface Props {
  /**
   * Sets the airports to a 'read only' mode
   * Used when defaulting airport selections such as on flight deal pages
   */
  readOnlyAirports?: boolean;
  showMultiCity?: boolean;
}

function FlightSearchWidgetMobile(props: Props) {
  const { readOnlyAirports, showMultiCity } = props

  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)
  const isBusinessTraveller = config.businessTraveller.currentAccountMode === 'business'

  const { fareType, fareCabin } = state

  const changeFareType = useCallback(
    (fareType: FlightDropdownOption) => {
      dispatch({ type: FlightSearchWidgetActions.SET_FARE_TYPE, fareType })

      // If it is multi-city or one way clear the checkout date
      if ([FlightsFareTypes.MULTI_CITY, FlightsFareTypes.ONE_WAY].includes(fareType.value as FlightsFareTypes)) {
        dispatch({ type: FlightSearchWidgetActions.SET_CHECKOUT_DATE, id: state.flights[0].id, date: null })
      }

      // If it is one-way or return we only need one flight
      if ([FlightsFareTypes.ONE_WAY, FlightsFareTypes.RETURN].includes(fareType.value as FlightsFareTypes)) {
        for (let i = 1; i < state.flights.length; i++) {
          const flight = state.flights[i]
          dispatch({ type: FlightSearchWidgetActions.REMOVE_FLIGHT, payload: flight.id })
        }
      }

      // If it is multi-city we show two flights
      if (fareType.value === FlightsFareTypes.MULTI_CITY && state.flights.length === 1) {
        const item: FlightSearchWidgetFlightItem = createEmptyFlightSearchWidgetFlightItem()
        dispatch({ type: FlightSearchWidgetActions.ADD_FLIGHT, payload: item })
      }
    },
    [dispatch, state.flights],
  )

  const changeFareCabin = useCallback(
    (fareCabin: FlightDropdownOption) => {
      dispatch({ type: FlightSearchWidgetActions.SET_FARE_CABIN, fareCabin })
    },
    [dispatch],
  )

  const showModal = useContext(ModalContext)

  const onTravellerSelect = useCallback(() => {
    showModal(
      <FlightSearchMenuMobile title="Travellers" showTravellers>
        <FlightSearchTravellerSelectContent />
      </FlightSearchMenuMobile>,
    )
  }, [showModal])

  const isMultiCity = fareType.value === FlightsFareTypes.MULTI_CITY

  const fareOptions = useMemo(() => {
    if (!showMultiCity) {
      return fareTypes.filter((fare) => fare.value !== FlightsFareTypes.MULTI_CITY)
    }
    return fareTypes
  }, [showMultiCity])

  return (
    <VerticalSpacer gap={12}>
      <Group direction="horizontal" gap={8} wrap={isBusinessTraveller ? 'wrap' : undefined}>
        <FareDropdownMobile
          options={fareOptions}
          title="Flight type"
          selectedOption={fareType}
          onOptionChange={changeFareType}
          name="type"
        />

        {!isBusinessTraveller && (
          <FlightSearchTravellerSelectField
            onClick={onTravellerSelect}
            data-testid="travellers"
            hideLabel
          />
        )}

        <FareDropdownMobile
          options={fareCabins}
          title="Class"
          selectedOption={fareCabin}
          onOptionChange={changeFareCabin}
          name="fareCabin"
        />
      </Group>

      {!isMultiCity && (
        <FlightSearchWidgetMobileForm readOnlyAirports={readOnlyAirports} />
      )}

      {isMultiCity && (
        <FlightSearchWidgetMobileMultiCityForm readOnlyAirports={readOnlyAirports} />
      )}
    </VerticalSpacer>
  )
}

export default FlightSearchWidgetMobile
