import getCreditPayableAmount from './getCreditPayableAmount'
import { createSelector } from 'reselect'
import { isUsingCredit } from '../view/generic'

const getCreditPayAmount = createSelector(
  (state: App.State) => isUsingCredit(state),
  (state: App.State) => getCreditPayableAmount(state),
  (useCredit, amount) => useCredit ? amount : 0,
)

export default getCreditPayAmount
