import React, { ComponentPropsWithRef } from 'react'

import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import LineUnlockAltIcon from 'components/Luxkit/Icons/line/LineUnlockAltIcon'
import SvgIcon from 'components/Luxkit/SvgIcon'
import LineCompassIcon from 'components/Luxkit/Icons/line/LineCompassIcon'
import LineStarIcon from 'components/Luxkit/Icons/line/LineStarIcon'
import LineShieldCheckIcon from 'components/Luxkit/Icons/line/LineShieldCheckIcon'
import LineLockAltIcon from 'components/Luxkit/Icons/line/LineLockAltIcon'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'

export const SUPPORTED_BENEFITS_ICONS: Record<App.LuxPlusBenefitType, typeof SvgIcon> = {
  discounted_flash: LineBedIcon,
  hotel_inclusions: LineStarIcon,
  exclusive_offers: LineLockAltIcon,
  priority_support: LinePhoneIcon,
  discounted_tours: LineCompassIcon,
  early_access: LineUnlockAltIcon,
  discounted_insurance: LineShieldCheckIcon,
  cruise_inclusions: LineShipIcon,
}

interface Props extends ComponentPropsWithRef<typeof SvgIcon> {
  benefitType: App.LuxPlusBenefitType
}

export default function LuxPlusBenefitIcon({ benefitType, ...rest }: Props) {
  const Icon = SUPPORTED_BENEFITS_ICONS[benefitType]
  return <Icon {...rest} />
}
