import { createSelector } from 'reselect'
import getLuxLoyaltyLoungePassItemViews from '../view/getLuxLoyaltyLoungePassItemViews'
import { sum } from 'lib/array/arrayUtils'

const getLuxLoyaltyLoungePassTotals = createSelector(
  (state: App.State) => getLuxLoyaltyLoungePassItemViews(state),
  (view):App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    return {
      hasRequiredData: view.hasRequiredData,
      data: {
        price: sum(view.data, itemView => itemView.totals.price),
        memberPrice: 0,
        value: sum(view.data, itemView => itemView.totals.value ?? 0),
        memberValue: 0,
        surcharge: 0,
        extraGuestSurcharge: 0,
        taxesAndFees: sum(view.data, itemView => itemView.totals.taxesAndFees),
      },
    }
  },
)

export default getLuxLoyaltyLoungePassTotals
