import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { useCallback } from 'react'
import { SearchContext_1_0_1 } from '@luxuryescapes/contract-data-event-schemas/lib/com.luxgroup'

export function useSearchAnalytics(
  searchContext?: SearchContext_1_0_1,
) {
  const trackSearchEvent = useCallback(() => {
    if (searchContext) Analytics.trackEvent(searchEventWithContext(searchContext))
  }, [searchContext])

  return {
    trackSearchEvent,
  }
}

export default useSearchAnalytics
