import config from 'constants/config'
import { OptimizelyExperiments } from 'constants/optimizely'
import { isValidInsuranceRegion } from 'lib/insurance/insuranceCountries'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { createSelector } from 'reselect'
import { isSpoofed } from 'selectors/featuresSelectors'
import isDomesticTrip from '../view/isDomesticTrip'
import { isBookingProtectionItem, isExperienceItem, isFlightItem, isInsuranceBookingProtectionItem } from 'lib/checkout/checkoutUtils'
import isBookingProtectionAvailable, { isInsuranceBookingProtectionAvailableForCartItems } from './isBookingProtectionAvailable'
import { getCheckoutInsuranceCountries } from './insurance'
import isInsuranceBookingProtectionEnabled from './isInsuranceBookingProtectionEnabled'

const cartOnlyHasFlightsOrBookingProtection = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean =>
    items.length > 0 &&
  items.every(item => isFlightItem(item) || isBookingProtectionItem(item) || isInsuranceBookingProtectionItem(item)),
)

const cartOnlyHasExperiencesOrBookingProtection = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.length > 0 && items.every(item => isExperienceItem(item) || isBookingProtectionItem(item) || isInsuranceBookingProtectionItem(item)),
)

const isBookingProtectionEnabled = createSelector(
  (state: App.State) => isBookingProtectionAvailable(state),
  (state: App.State) => isDomesticTrip(state),
  (state: App.State) => cartOnlyHasFlightsOrBookingProtection(state),
  (state: App.State) => cartOnlyHasExperiencesOrBookingProtection(state),
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => isSpoofed(state),
  (state: App.State) => !!getOptimizelyExperimentVariation(state, OptimizelyExperiments.refundProtectForInternationalEnabled),
  (state: App.State) => state.checkout.cart.mode,
  (state: App.State) => getCheckoutInsuranceCountries(state),
  (state: App.State) => isInsuranceBookingProtectionAvailableForCartItems(state),
  (state: App.State) => isInsuranceBookingProtectionEnabled(state),
  (isBookingProtectionAvailable, isADomesticTrip, cartOnlyHasFlightsOrBookingProtection, cartOnlyHasExperiencesOrBookingProtection, currentRegionCode, isSpoofed, bookingProtectionInternationABTest, postPurchase, insuranceCountries, insuranceBookingProtectionAvailable, insuranceBookingProtectionEnabled): boolean => {
    if (postPurchase === 'insurance') return false
    if (!config.BOOKING_PROTECTION_ENABLED && !insuranceBookingProtectionEnabled) return false
    if (!isBookingProtectionAvailable) return false
    // Booking Protection is available on all standalone flights
    if (cartOnlyHasFlightsOrBookingProtection) return true
    // Booking Protection is available on all standalone experiences
    if (cartOnlyHasExperiencesOrBookingProtection) return true
    // Booking protection is not available for international trips from AU region when not spoofed and not in ON variant of AB test
    if (!isADomesticTrip && !isSpoofed && currentRegionCode === 'AU' && !bookingProtectionInternationABTest) return false
    // Booking protection is not available for all trips within regions where Insurance is enabled except AU
    if (!insuranceBookingProtectionEnabled && isValidInsuranceRegion(currentRegionCode) && currentRegionCode !== 'AU') return false
    // If insurance booking protection is available check that the offer destination country is valid
    if (!insuranceCountries.length && insuranceBookingProtectionAvailable) return false
    // Insurance booking protection is not available for international trips when is not standalone flights or experiences
    if (!config.BOOKING_PROTECTION_ENABLED && insuranceBookingProtectionEnabled && !isADomesticTrip && !cartOnlyHasFlightsOrBookingProtection && !cartOnlyHasExperiencesOrBookingProtection) return false
    return true
  },
)

export default isBookingProtectionEnabled
