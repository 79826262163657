import config from 'constants/config'
import { initSentryClientSide, sendErrorToSentry } from '../lib/utils/sentryUtils'
import { AppStore } from '../store'
import getReportDataFromState from 'reporting/utils/getReportDataFromState'

let _store: AppStore | undefined

export function registerErrorReportStore(store: AppStore) {
  _store = store
}

let Sentry: any
export async function reportClientError(error: unknown, additional: Record<string, any> = {}) {
  if (!config.SENTRY_ID) return Promise.resolve()

  if (!Sentry) {
    Sentry = await import(/* webpackChunkName: "Sentry" */ '@sentry/browser')
    initSentryClientSide(Sentry)
  }

  const { account, meta } = getReportDataFromState(_store?.getState())

  const extras = { ...meta, ...additional }

  if (!config.APP_ENV || config.APP_ENV !== 'production') {
    console.warn('Client error reported:', error, account, extras)
  }

  return sendErrorToSentry(error, account, extras)
}
