import React, { useEffect, useCallback, useMemo, useContext } from 'react'
import { connect } from 'react-redux'
import { fetchTrendingDestinations } from 'actions/DestinationAlertsActions'
import { addGTMEvent } from 'api/googleTagManager'
import { searchDestinationFormSubmit, searchFormSubmit, trendingDestinationClick } from 'analytics/eventDefinitions'
import { encodeSearchParams } from 'lib/search/searchUtils'
import PopularButtonList from 'components/Search/SearchForm/PopularButtonList'
import GlobalSearchQuickFiltersTitle from '../GlobalSearch/QuickFilters/GlobalSearchQuickFiltersTitle'
import GlobalSearchQuickFilterButtonsContainer from '../GlobalSearch/QuickFilters/GlobalSearchQuickFilterButtonsContainer'
import GlobalSearchQuickFiltersContainer from '../GlobalSearch/QuickFilters/GlobalSearchQuickFiltersContainer'
import { take } from 'lib/array/arrayUtils'
import config from 'constants/config'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import GeoContext from 'contexts/geoContext'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { lereRecommendedDestinationsToTrendingDestinations } from 'lib/customer/recommendationUtils'
import { DESTINATION_MODEL_ELIGIBLE_REGIONS } from 'constants/search'

const SEARCH_BAR_DESTINATIONS_LIMIT = 11
interface MappedProps {
  trendingDestinations: App.DestinationState['trendingDestinations'];
  fetchingTrendingDestinations: App.DestinationState['fetchingTrendingDestinations'];
  search: string;
}

interface Props {
  onClick?: () => void;
  filters?: App.OfferListFilters;
  titleWeight?: 'bold' | 'normal'
  titleColour?: 'neutral-eight' | 'neutral-two'
  className?: string;
  noTitle?: boolean;
}

function logEvents() {
  addGTMEvent(searchFormSubmit())
  addGTMEvent(searchDestinationFormSubmit())
  addGTMEvent(trendingDestinationClick())
}

function SearchTrendingDestinations({
  fetchingTrendingDestinations,
  trendingDestinations,
  search,
  filters,
  titleColour,
  titleWeight,
  className,
  noTitle = false,
}: Props & MappedProps) {
  const dispatch = useAppDispatch()
  const getQueryParams = useCallback((optionValue: string, optionText: string): string => {
    const searchItem: App.SearchItem = {
      searchType: 'destination',
      value: optionValue,
      format: {
        mainText: optionText,
      },
    }
    const queryParams = encodeSearchParams({ urlSearch: search, searchItem, rooms: [config.search.defaultOccupants], filters, isFlexibleWithDate: true }).toString()

    return queryParams
  }, [filters, search])

  useEffect(() => {
    dispatch(fetchTrendingDestinations())
  }, [dispatch])
  const { currentRegionCode } = useContext(GeoContext)
  const domainUserId = useAppSelector(state => state.auth.domainUserId)
  const leUserId = useAppSelector(getCurrentUserId)
  const recommendedDestinations = useAppSelector(state => state.recommendations.recommendedDestinations)
  const isEligibleRegion = DESTINATION_MODEL_ELIGIBLE_REGIONS.includes(currentRegionCode)
  const hasUserId = !!domainUserId || !!leUserId
  const hasRecommendations = recommendedDestinations.state !== 'loading' && recommendedDestinations.destinations.length > 0
  const shouldShowRecommendations = isEligibleRegion && hasUserId && hasRecommendations
  const personalisedRecommendations = lereRecommendedDestinationsToTrendingDestinations(recommendedDestinations.destinations).slice(0, SEARCH_BAR_DESTINATIONS_LIMIT).map(destination => ({
    placeId: destination.destinationId ?? '',
    primaryText: destination.name,
    abbreviation: destination.name,
  }))
  const displayTrendingDestinations = useMemo(() => shouldShowRecommendations ? personalisedRecommendations : (trendingDestinations ?? []), [trendingDestinations, personalisedRecommendations, shouldShowRecommendations])
  const options = useMemo(() => {
    if (displayTrendingDestinations.length > 0) {
      return displayTrendingDestinations.map(trendingDestination => {
        const queryParams = getQueryParams(trendingDestination.placeId, trendingDestination.primaryText)
        const url = `/search?${queryParams}`

        return {
          value: trendingDestination.placeId,
          text: trendingDestination.primaryText,
          url,
        }
      })
    }
    return []
  }, [displayTrendingDestinations, getQueryParams])

  if (!options.length) {
    return null
  }

  return (
    <GlobalSearchQuickFiltersContainer className={className}>
      {!noTitle && <GlobalSearchQuickFiltersTitle
        colour={titleColour}
        weight={titleWeight}
      >
        Trending destinations
      </GlobalSearchQuickFiltersTitle>}
      <GlobalSearchQuickFilterButtonsContainer>
        <PopularButtonList
          onContextMenuHandler={logEvents}
          options={take(options, 11)}
          fetching={fetchingTrendingDestinations}
        />
      </GlobalSearchQuickFilterButtonsContainer>
    </GlobalSearchQuickFiltersContainer>
  )
}

const mapStateToProps = (state: App.State) => ({
  trendingDestinations: state.destination.trendingDestinations,
  fetchingTrendingDestinations: state.destination.fetchingTrendingDestinations,
  search: state.router.location.search,
})

export default connect(mapStateToProps)(SearchTrendingDestinations)
