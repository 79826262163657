import { excludeNullOrUndefined } from 'checkout/utils'
import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView } from './accommodation'
import { getFlightItemsView } from './flights'
import { matchPath } from 'react-router'
import getExperienceItemsView from './getExperienceItemsView'

export const isSingleDesignation = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getExperienceItemsView(state),
  (state: App.State) => getFlightItemsView(state),
  (checkoutAccommodationOfferView, getExperienceItemsView, getFlightItemsView): boolean => {
    const allItemsViews = [...checkoutAccommodationOfferView.data, ...getExperienceItemsView.data, ...getFlightItemsView.data]
    return [...new Set(allItemsViews.map(view => view?.designation).filter(excludeNullOrUndefined))].length === 1
  },
)

export const isUsingCredit = (state: App.State) => state.checkout.payment.useCredit

export const selectShowPriceChanges = createSelector(
  (state: App.State) => state.checkout.cart.restoredCartDetails?.originalTotal,
  (state: App.State) => state.router.location.pathname,
  (hasOriginalTotal, path): boolean => {
    return !!(hasOriginalTotal && matchPath(path, { path: '/:regionCode/checkout/:cartId/purchase', exact: true }))
  },
)
