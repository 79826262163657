import { paths } from '@luxuryescapes/contract-customer-communication'
import { LUXURY_ESCAPES } from 'constants/brands'

export function mapUserPreferences(res: paths['/api/customer-communication/v1/subscriptions/users/{userId}']['get']['responses']['200']['content']['application/json']['result']) {
  return res.brand === LUXURY_ESCAPES ? {
    email_subscribed: res.email_subscribed,
    todays_escapes_subscribed: res.todays_escapes_subscribed,
    my_journey_subscribed: res.my_journey_subscribed,
    curated_collection_subscribed: res.curated_collection_subscribed,
    app_push_subscribed: res.app_push_subscribed,

    todays_escapes_cadence: res.todays_escapes_cadence,
    sms_subscribed: res.sms_subscribed,

    // TODO when we enable whats app and web push
    // web_push_subscribed: res.web_push_subscribed,
    // whats_app_subscribed: res.whats_app_subscribed,
  } : {
    sms_subscribed: res.sms_subscribed,
    app_push_subscribed: res.app_push_subscribed,
    email_subscribed: res.email_subscribed,
    travel_exp_subscribed: res.travel_exp_subscribed,
    travel_exp_cadence: res.travel_exp_cadence,
  }
}

export function mapUserPreferencesV2(subscriptions: App.LESubscriptionsV2Settings) {
  return {
    settings: {
      email_subscribed: subscriptions.email_subscribed,
      todays_escapes_subscribed: subscriptions.todays_escapes_subscribed,
      my_journey_subscribed: subscriptions.my_journey_subscribed,
      curated_collection_subscribed: subscriptions.curated_collection_subscribed,
      app_push_subscribed: subscriptions.app_push_subscribed,
      todays_escapes_cadence: subscriptions.todays_escapes_cadence,
      sms_subscribed: subscriptions.sms_subscribed,

      // TODO when we enable whats app and web push
      // web_push_subscribed: subscriptions.web_push_subscribed,
      // whats_app_subscribed: subscriptions.whats_app_subscribed,
    },
    emailSubscribedUpdatedAt: subscriptions.email_subscribed_updated_at || null,
  }
}
