import React from 'react'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'

interface Props {
  className?: string;
  label?: string;
  value?: string;
}

function BusinessTravellerSelectDisabled(props: Props) {
  const { className, label, value } = props

  return (<SearchFormField
    className={className}
    label={label ?? 'Travellers'}
    value={value ?? '1 Traveller'}
    disabled
  />)
}

export default BusinessTravellerSelectDisabled
