import React from 'react'
import List from 'components/Common/List'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import Heading from 'components/Luxkit/Typography/Heading'
import ExperienceDetailsAboutLocationMap from './ExperienceDetailsAboutLocationMap'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Accordion from 'components/Luxkit/Accordion'

interface Props {
  experienceView: App.ExperienceBookingView;
  pickupPoint?: App.ExperiencePickupPoint;
  showLocation?: boolean;
  /**
   * The variant determines the order of the information displayed
   * @default inclusions-first
   */
  variant?: 'inclusions-first' | 'overview-first';
  /**
   * Provide your own title, otherwise will go with what's most relevant to the variant
   */
  title?: string;
}

function ExperienceDetailsModalAboutSection(props: Props) {
  const {
    variant = 'inclusions-first',
    experienceView,
    pickupPoint,
    showLocation = true,
    title,
  } = props

  const copy = experienceView.originalExperience.copy
  return (
    <VerticalSpacer gap={24}>
      {experienceView.type === 'channel_manager' && copy.highlights.length > 0 && (
        <VerticalSpacer gap={12}>
          <Heading variant="heading6">Why you should do it!</Heading>
          <List>
            {copy.highlights.map((highlight, idx) => <li key={idx}>{highlight}</li>)}
          </List>
        </VerticalSpacer>
      )}
      {variant === 'inclusions-first' && copy.included && <VerticalSpacer gap={20}>
        <Heading variant="heading4">{title ?? 'Inclusions'}</Heading>
        <MarkdownRender type="compact" content={copy.included} />
      </VerticalSpacer>}
      {variant === 'overview-first' && copy.dealDescription && <VerticalSpacer gap={20}>
        <Heading variant="heading4">{title ?? 'Overview'}</Heading>
        <MarkdownRender content={copy.dealDescription} />
      </VerticalSpacer>}
      <div>
        {variant !== 'overview-first' && copy.dealDescription && <Accordion heading="Overview" hideDivider>
          <MarkdownRender content={copy.dealDescription} />
        </Accordion>}
        {variant !== 'inclusions-first' && copy.included && <Accordion heading="Inclusions" hideDivider>
          <MarkdownRender type="compact" content={copy.included} />
        </Accordion>}
        {showLocation && <Accordion heading="Location" hideDivider>
          <VerticalSpacer gap={16}>
            {experienceView?.location &&
              <Heading as="h3" variant="heading3" align="center">
                {experienceView.location}
              </Heading>
          }
            <ExperienceDetailsAboutLocationMap
              experienceView={experienceView}
              pickupPoint={pickupPoint}
            />
          </VerticalSpacer>
        </Accordion>}
        {copy.thingsToKnow?.map((item, idx) => (
          <Accordion heading={item.title} key={`${item.title}-${idx}`} hideDivider>
            <MarkdownRender content={item.content} type="compact" />
          </Accordion>
        ))}
      </div>
    </VerticalSpacer>
  )
}

export default ExperienceDetailsModalAboutSection
