import { rem } from 'polished'
import cn from 'clsx'
import React from 'react'
import styled from 'styled-components'
import TextLink from '../TextLink'
import BodyText from '../Typography/BodyText'
import Heading from '../Typography/Heading'
import Image from 'components/Common/Image'
import { mediaQueryUp } from 'components/utils/breakpoint'

const ActionLink = styled(TextLink)`
  white-space: nowrap;
  margin-inline-start: auto;
`

const SnackbarElement = styled.div`
  width: 100%;
  padding: ${rem(16)};
  border-radius: ${props => props.theme.borderRadius.S};
  box-shadow: ${props => props.theme.shadow.bottom.large};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${rem(12)};

  &.wrap {
    flex-wrap: wrap;
  }

  &.kind-neutral {
    background-color: ${props => props.theme.palette.neutral.default.eight};
    border: 1px solid ${props => props.theme.palette.neutral.default.six};
  }

  &.kind-warning {
    background-color: ${props => props.theme.palette.messaging.warning.lightBackground};
  }

  &.kind-success {
    background-color: ${props => props.theme.palette.messaging.success.lightBackground};
  }

  &.kind-critical {
    background-color: ${props => props.theme.palette.messaging.critical.lightBackground};
  }
`

const MainContent = styled.div`
  display: flex;
  gap: ${rem(12)};

  &.start {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

`

const IconImage = styled(Image)`
  height: ${rem(38)};
  width: ${rem(38)};

  ${mediaQueryUp.tablet} {
    height: ${rem(48)};
    width: ${rem(48)};
  }
`

const HeadingMaxLength = 40
const ActionShortMaxLength = 8

function isImage(icon?: React.ReactNode | App.Image): icon is App.Image {
  return !!icon && typeof icon === 'object' && ('id' in icon || 'url' in icon)
}

export type SnackbarKind = 'neutral' | 'warning' | 'success' | 'critical'
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  heading?: string;
  description: string;
  action?: string;
  onAction?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  actionTo?: string;
  actionHref?: string;
  actionTestId?: string;
  icon?: React.ReactNode | App.Image;
  kind: SnackbarKind;
}

function Snackbar(props: Props) {
  const {
    description,
    kind,
    heading = '',
    icon,
    action = '',
    onAction,
    actionHref,
    actionTo,
    actionTestId,
    className,
    ...htmlProps
  } = props

  // Have to manually text overflow as there's no way to do it based on character limit that I'm aware of
  const displayHeading = heading.length > HeadingMaxLength ? `${heading.substring(0, HeadingMaxLength)}...` : heading
  const indicator = isImage(icon) ? <IconImage height={44} width={44} image={icon} /> : icon

  return <SnackbarElement
    {...htmlProps}
    role="alert"
    className={cn(
      className,
      `kind-${kind}`,
      { wrap: action.length > ActionShortMaxLength },
    )}
  >
    <MainContent className={(icon && !heading) ? 'center' : 'start'}>
      {indicator}
      <div>
        {heading && <Heading
          title={heading}
          variant="heading6"
        >
          {displayHeading}
        </Heading>}
        <BodyText
          title={description}
          colour="neutral-two"
          variant="medium"
          lineClamp={2}
        >
          {description}
        </BodyText>
      </div>
    </MainContent>
    {action && <ActionLink
      size="medium"
      variant="black"
      underline
      to={actionTo}
      href={actionHref}
      onClick={onAction}
      data-testid={actionTestId}
    >
      {action}
    </ActionLink>}
  </SnackbarElement>
}

export default Snackbar
