import React, { useCallback, useMemo } from 'react'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import { EnabledFilters } from 'components/Search/type'
import HotelSearchPageFilterPane from 'components/Pages/HotelSearchPage/HotelSearchPageFilterPane'
import TextButton from 'components/Luxkit/Button/TextButton'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import { OfferListSortOption } from 'constants/offerListFilters'
import { queryKeyFilters } from 'constants/url'
import { deleteSearchParams } from 'lib/url/searchUrlUtils'
import { replace } from 'connected-react-router'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import { useAppDispatch } from 'hooks/reduxHooks'
import { connect } from 'react-redux'
import { getAppliedFilterCount } from 'components/Search/utils'
import Group from 'components/utils/Group'
import ModalContent from 'components/Luxkit/Modal/ModalContent'

interface MappedStateProps {
  currentHash: string;
}

interface Props {
  filters: App.OfferListFilters;
  search: string;
  region?: string;
  enabledFilters: EnabledFilters;
  open?: boolean;
  onClose?: () => void;
  fetching: boolean;
  sortOptions?: Array<OfferListSortOption>;
}

function OfferListFilterSidebar({
  currentHash,
  open,
  onClose,
  fetching,
  sortOptions,
  filters,
  search,
  enabledFilters,
}: Props & MappedStateProps) {
  const dispatch = useAppDispatch()
  const isSlimMobileSearch = useSlimMobileSearch()
  const isMobile = useScreenSizeOnly('mobile') && isSlimMobileSearch

  const resetBtnText = useMemo(() => {
    const filterCount = getAppliedFilterCount(filters, enabledFilters)
    return filterCount > 0 ? `Reset all (${filterCount})` : 'Reset all'
  }, [filters, enabledFilters])

  const onReset = useCallback(() => {
    const filtersToDelete = [...queryKeyFilters]
    const newSearch = deleteSearchParams(
      search,
      ...filtersToDelete,
    )
    dispatch(replace({ search: newSearch, hash: currentHash }))
  }, [currentHash, dispatch, search])

  return (
    <ModalBase
      mode="drawer"
      isOpen={open}
      onClose={onClose}
    >
      <ModalBody>
        <ModalContent>
          <HotelSearchPageFilterPane
            fetching={fetching}
            filters={filters}
            mapViewURL=""
            search={search}
            enabledFilters={enabledFilters}
            sortOptions={sortOptions}
            onClose={onClose}
          />
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <Group direction="horizontal" horizontalAlign="stretch">
          {isMobile && <TextButton kind="tertiary" size="large" onClick={onReset}>
            {resetBtnText}
          </TextButton>}
          <TextButton kind="primary" size="large" onClick={onClose}>
            View Offers
          </TextButton>
        </Group>
      </ModalFooter>
    </ModalBase>
  )
}

function mapStateToProps(state: App.State): MappedStateProps {
  return {
    currentHash: state.router.location.hash,
  }
}

export default connect(mapStateToProps)(OfferListFilterSidebar)
