import Modal from 'components/Luxkit/Modal/Modal'
import React, { useCallback, useMemo, useRef } from 'react'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { semiFormToObject } from 'lib/forms/formUtils'
import {
  CruiseSearchShipChipProps,
} from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchShipChip'
import CruiseShipFilter
  from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruiseShipFilter'
import useQueryParams from 'hooks/useQueryParams'

function ModalCruiseSearchShipChip(props: CruiseSearchShipChipProps) {
  const {
    facets,
    filterForm,
    setFilterForm,
    fetching,
    filters,
    drawerMode,
  } = props
  const modalElement = useModalElementContext<number>()
  const containerRef = useRef<HTMLDivElement>(null)
  const queryParams = useQueryParams()

  const queryParamCruiseShips = useMemo(() => {
    return queryParams.getAll('cruiseShips')
  }, [queryParams])

  const countSelected = useMemo(() => {
    return queryParamCruiseShips.length || 0
  }, [queryParamCruiseShips.length])

  const closeModal = useCallback(() => {
    modalElement.resolve(countSelected)
  }, [countSelected, modalElement])

  const updateForm = useCallback(() => {
    if (containerRef.current) {
      const formValues = semiFormToObject<Record<string, any>>(containerRef.current)
      if (formValues && Object.keys(formValues).length > 0) {
        setFilterForm({ ...filterForm, ...formValues })
      } else {
        setFilterForm({ ...filterForm, cruiseShips: undefined })
      }
    }
  }, [filterForm, setFilterForm])

  const resetFilter = useCallback(() => {
    setFilterForm({ ...filterForm, cruiseShips: undefined })
    modalElement.resolve(0)
  }, [setFilterForm, filterForm, modalElement])

  return <Modal
    mode={drawerMode ? 'drawer' : 'default'}
    ref={containerRef}
    title="Cruise ships"
    primaryActionText="Apply"
    onPrimaryActionClick={closeModal}
    secondaryActionText={countSelected > 0 ? `Reset all (${countSelected})` : 'Reset all'}
    onSecondaryActionClick={resetFilter}
  >
    <CruiseShipFilter
      cruiseShips={filters.cruiseShips}
      facets={facets}
      onChange={updateForm}
      fetching={fetching}
    />
  </Modal>
}

export default ModalCruiseSearchShipChip
