import { ExperienceOrderCreationItem } from 'api/order'
import { ITEM_TYPE_BNBL_EXPERIENCE, ITEM_TYPE_EXPERIENCE } from 'constants/cart'
import config from 'constants/config'
import { ExperienceBookingType } from 'constants/experience'
import { getExperienceTicketDate } from 'lib/experiences/experienceUtils'
import { filterItemDiscounts } from 'lib/promo/promoMappers'
import { createSelector } from 'reselect'
import { getExperienceItemDiscounts } from '../checkout'
import getExperienceItemSourceApp from '../getExperienceItemSourceApp'
import { getFormattedTravellers } from '../orderCreationSelectors'
import formatItemDiscount from 'lib/payment/formatDiscountItem'
import getExperienceItemsView from 'checkout/selectors/view/getExperienceItemsView'

export const getFormattedExperienceItems = createSelector(
  (state: App.State) => getExperienceItemsView(state),
  (state: App.State) => state.checkout.cart.specialRequests,
  (state: App.State) => getFormattedTravellers(state),
  (state: App.State) => getExperienceItemDiscounts(state),
  (state: App.State) => getExperienceItemSourceApp(state),
  (views, specialRequestsMap, travellers, itemDiscounts, sourceApp) => {
    if (!travellers || !itemDiscounts.hasRequiredPromoCodeData) { return [] }

    return views.data.flatMap(view => {
      // order service expects to send expiration date in ticket.date if open date item
      // if it's a BNBL or GIFT, value should be undefined
      let ticketDate: string | undefined
      if (!(view.isBuyNowBookLater || view.isGift)) {
        ticketDate = ['NO-CALENDAR-FIXED-END', 'NO-CALENDAR-FIXED-VALIDITY'].includes(view.experience.bookingType) ?
          view.experience.expirationDate :
          getExperienceTicketDate(view.bookingDate, view.bookingTime)
      }

      return view.ticketViews.map((ticketView, index): ExperienceOrderCreationItem => {
        const ticketDetails = ticketView.ticket
        // try match the participant to the ticket index, first ticket = first participant, etc
        const participant = travellers.participants?.[index]
        // Store bookByDate from ticket if there's one
        const bookByDate = ticketDetails.bookByDate ?? view.experience.bookByDate
        return {
          type: view.item.isBookingBNBL ? ITEM_TYPE_BNBL_EXPERIENCE : ITEM_TYPE_EXPERIENCE,
          id_experience_items: ticketView.orderItemId,
          provider_offer_id: view.experience.id,
          brand: config.BRAND,
          transaction_key: ticketView.item.transactionKey,
          total: ticketDetails.price,
          language: view.item.languageId,
          categories: view.experience.categories,
          cancellation_policies: view.experience.cancellationPolicies.length > 0 ? {
            isFree: view.experience.copy.cancellationInfo.isFree,
            timezoneOffset: `${new Date().getTimezoneOffset()}`,
            refundPolicies: view.experience.cancellationPolicies.map(policy => ({
              id: policy.id,
              periods: policy.periods,
              periodLabel: policy.periodLabel,
              type: policy.type,
              value: policy.value,
            })),
            text: view.experience.copy.cancellationInfo.text,
          } : undefined,
          le_exclusive: view.experience.leExclusive,
          redemption_location_id: view.redemptionLocation?.id,
          redemption_location_name: view.redemptionLocation?.name,
          pickup_point_id: view.pickupPoint?.id,
          pickup_point_name: view.pickupPoint?.name,
          special_requests: specialRequestsMap[view.experienceId],
          traveller_info: {
            customer: travellers.customer,
            participants: participant ? [participant] : undefined,
          },
          ticket: {
            fareType: ticketDetails.name,
            identifier: ticketDetails.id,
            productId: ticketDetails.productId,
            type: ticketDetails.type,
            date: ticketDate,
            rateStartDate: ticketDetails.rateStartDate,
            rateEndDate: ticketDetails.rateEndDate,
            bookByDate,
            isExtra: ticketDetails.isExtra,
          },
          title: view.experience.name,
          booking_type: (view.isBuyNowBookLater || view.isGift) ? ExperienceBookingType.BUY_NOW_BOOK_LATER :
            ExperienceBookingType.INSTANT_BOOKING,
          taxes_and_fees: ticketDetails.taxesAndFees,
          ticketed: view.experience.ticketed,
          ...(ticketDetails.discounts.app.amount > 0 && {
            app_discount_amount: ticketDetails.discounts.app.amount,
            app_discount_percent: ticketDetails.discounts.app.percentage,
          }),
          item_discounts: filterItemDiscounts({
            itemDiscounts: itemDiscounts.itemDiscounts,
            lookup: {
              specificType: 'experience',
              transactionKeys: [ticketView.item.transactionKey],
              _logFilterInfo: true,
            },
          }).map(formatItemDiscount),
          source_app: sourceApp,
        }
      })
    })
  },
)
