import React, { useMemo } from 'react'
import styled from 'styled-components'
import LinePlaneFlyIcon from 'components/Luxkit/Icons/line/LinePlaneFlyIcon'
import useDefaultAirport from 'hooks/useDefaultAirport'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import CaptionTextBlock from 'components/Luxkit/TextBlocks/CaptionTextBlock'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPrice'
import { useAppSelector } from 'hooks/reduxHooks'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import { unique } from 'lib/array/arrayUtils'

const Pricing = styled.div`
  display: flex;
  flex-direction: column;
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  pkg: App.Package;
}

function HeroOfferTileBundlePricing(props: Props) {
  const { offer, pkg } = props
  const defaultAirport = useDefaultAirport()

  const flightPrice = offer.flightPrices[defaultAirport?.code]

  const {
    totalPrice,
    totalValue,
    saleUnit,
    valueOffPercent,
  } = useOfferPackagePrice(pkg, offer, { flightPrice })

  const isPerNightPricingTestEnabled = !!useAppSelector((state: App.State) => getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight))

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  if (!flightPrice) {
    return (
      <Pricing>
        <CaptionTextBlock colour="neutral-one" variant="medium" weight="bold" startIcon={<LinePlaneFlyIcon colour="neutral-one" />}>
          Hotel + Flights packages
        </CaptionTextBlock>
      </Pricing>
    )
  }

  const showValue = pkg.shouldDisplayValue && totalValue > totalPrice
  const shouldShowRate = (valueOffPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE) && !offer.isDiscountPillHidden

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end" gap={4}>
      <Group direction="vertical">
        <CaptionTextBlock colour="neutral-one" variant="medium" weight="bold" startIcon={<LinePlaneFlyIcon colour="neutral-one" />}>
          Hotel + Flights
        </CaptionTextBlock>
        <PriceRowPriceCaption>{offer.tileDurationLabel} from</PriceRowPriceCaption>
        <PriceRowPrice
          size="L"
          price={Math.ceil(totalPrice)}
          saleUnit={isPerNightPricingTestEnabled ? 'night' : saleUnit}
        />
        <PriceRowTaxesAndFees />
        {showValue && <PriceRowValueDiscountWithCaption
          size="L"
          originalValue={Math.ceil(totalValue)}
          discountPercentage={shouldShowRate ? valueOffPercent : undefined}
        />}
        <PriceRowCaption>
          2 adults
        </PriceRowCaption>
      </Group>
      <PriceRowAgentHubCommission
        size="L"
        productType={offer.productType}
        offerId={offer.id}
        offerLocations={offerLocations}
        vendorName={vendorName}
        packageId={pkg.id}
      />
      <CSSBreakpoint min="desktop">
        <TextButton kind="primary">
          View offer
        </TextButton>
      </CSSBreakpoint>
    </Group>
  )
}

export default HeroOfferTileBundlePricing
