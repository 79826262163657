import { fetchAccumulatedRatesForBedbankOffer, getBedbankRateKey } from 'actions/BedbankOfferActions'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from './reduxHooks'
import useMetasearchSourceTransition from 'hooks/OfferPage/useMetasearchSourceTransition'

const emptyRates: Array<App.BedbankRate> = []

function useBedbankRates(
  offerId: string | undefined,
  occupants?: Array<App.Occupants>,
  checkIn?: string,
  checkOut?: string,
  hookEnabled: boolean = true,
): [{ hotelOnlyRates: Array<App.BedbankRate>; hotelBundleRates: Array<App.BedbankRate> }, boolean] {
  const [waitForLoading, setWaitForLoading] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const filterKey = checkIn && checkOut && occupants ? getBedbankRateKey(occupants, checkIn, checkOut) : ''
  const hasTransitionedFromMetasearch = useMetasearchSourceTransition()

  const packages = useAppSelector(state => state.offer.bedbankOfferRates[offerId ?? '']?.[filterKey]) ?? emptyRates
  const fetching = useAppSelector(state => state.offer.offerRatesLoading[offerId ?? ''] || waitForLoading)

  useEffect(() => {
    // We can't get rates for a bedbank property without the following properties
    if (hookEnabled && checkIn && checkOut && occupants && offerId) {
      dispatch(fetchAccumulatedRatesForBedbankOffer(offerId, occupants, checkIn, checkOut, hasTransitionedFromMetasearch))
    }
    setWaitForLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey, offerId, hookEnabled, hasTransitionedFromMetasearch])

  const rates = useMemo(() => {
    const hotelOnlyRates = packages.filter(p => !p.isFlightBundle)
    const hotelBundleRates = packages.filter(p => p.isFlightBundle)

    return {
      hotelOnlyRates,
      hotelBundleRates,
    }
  }, [packages])

  return [rates, fetching]
}

export default useBedbankRates
