import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ListItemLoadingSkeleton from 'components/Luxkit/List/ListItemLoadingSkeleton'
import { rem } from 'polished'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { DeparturePortMapped } from './CruiseDepartureSelect'
import CruiseDepartureSelectListAll from './CruiseDepartureSelectListAll'
import CruiseDepartureSelectListByRegion from './CruiseDepartureSelectListByRegion'
import CruiseDepartureSelectListItem from './CruiseDepartureSelectListItem'

const CruiseDepartureSelectWrapper = styled.div`
  display: grid;
  row-gap: ${rem(12)};
  column-gap: ${rem(40)};
  grid-template-columns: repeat(auto-fit, minmax(${rem(288)}, 1fr));
`

interface Props {
  searchDeparturePorts: Array<DeparturePortMapped>;
  searchTerm: string;
  secondarySearchItems: Array<App.SearchItem>;
  isMobileMode?: boolean;
  isAllSelected: boolean;
  selectedRegions: Array<string>;
  onChange: (values: Array<App.SearchItem>) => void;
  inputSearchRef?: React.RefObject<HTMLInputElement>;
  selectedRegion: App.CruiseDepartureRegion;
  setIsAllSelected: (value: boolean) => void;
  setSelectedRegions: (value: Array<string>) => void;
  setSelectedRegion: (value: App.CruiseDepartureRegion) => void;
  fetching: boolean;
  regions: Array<App.CruiseDepartureRegion>;
  /**
   * This is a temporary for disabling long results to avoid render issues.
   */
  topLevelRegionOnly?: boolean;
  shouldDisableRegionTabs?: boolean;
}

function CruiseDepartureSelectList(props: Props) {
  const {
    searchDeparturePorts,
    searchTerm,
    secondarySearchItems,
    isMobileMode,
    isAllSelected,
    selectedRegions,
    onChange,
    inputSearchRef,
    selectedRegion,
    setIsAllSelected,
    setSelectedRegions,
    fetching,
    regions,
    topLevelRegionOnly,
    shouldDisableRegionTabs,
  } = props
  const isAllDisabled = useMemo(() => {
    return searchDeparturePorts.every?.(({ isDisabled }) => isDisabled)
  }, [searchDeparturePorts])

  useEffect(() => {
    if (inputSearchRef?.current) {
      inputSearchRef.current.focus({ preventScroll: true })
    }
  }, [inputSearchRef])

  const markRegionByUrlParams = useCallback(() => {
    if (secondarySearchItems.length) {
      const identifiers = secondarySearchItems
        .filter(({ searchType }) => ['departurePort', 'destination'].includes(searchType))
        .map(({ value }) => {
          const region = regions.find(({ placeIds }) => placeIds.includes(value))
          return region?.identifier!
        })

      if (identifiers.length) {
        setSelectedRegions([...selectedRegions, ...identifiers])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    markRegionByUrlParams()
  }, [markRegionByUrlParams, fetching])

  const handleSelectByRegion = useCallback(() => {
    setIsAllSelected(false)
    setSelectedRegions([...selectedRegions, selectedRegion.identifier])
    onChange([
      ...secondarySearchItems, {
        searchType: 'departurePort',
        value: selectedRegion.placeIds,
        format: {
          mainText: selectedRegion.name,
        },
      },
    ])
  }, [onChange, secondarySearchItems, selectedRegion, selectedRegions, setIsAllSelected, setSelectedRegions])

  const handleUnselectByRegion = useCallback(() => {
    if (isAllSelected) {
      setIsAllSelected(false)
      setSelectedRegions([selectedRegion.identifier])
      onChange([{
        searchType: 'departurePort',
        value: selectedRegion.placeIds,
        format: {
          mainText: selectedRegion.name,
        },
      }])
    } else {
      setSelectedRegions(selectedRegions.filter((region) => region !== selectedRegion.identifier))
      const newSearchItems = secondarySearchItems.filter(({ value }) => value !== selectedRegion.placeIds)
      onChange(newSearchItems)
    }
  }, [isAllSelected, onChange, secondarySearchItems, selectedRegion.identifier, selectedRegion.name, selectedRegion.placeIds, selectedRegions, setIsAllSelected, setSelectedRegions])

  const handleSelectItem = useCallback((item: App.SearchItem) => {
    const newSearchItems = [...secondarySearchItems, item]
    onChange(newSearchItems)
  }, [onChange, secondarySearchItems])

  const handleUnselectItem = useCallback((item: App.SearchItem) => {
    const allTabRegionSelected = selectedRegions.includes(selectedRegion.identifier)
    // When is all selected and click on a single item, only keep selected the clicked item
    if (isAllSelected) {
      onChange([item])
      setSelectedRegions([])
    }
    // When all tab region is selected and click on a single item, only keep selected the clicked item and the items from the other tabs
    else if (allTabRegionSelected) {
      setSelectedRegions(selectedRegions.filter((region) => region !== selectedRegion.identifier))
      onChange([
        ...secondarySearchItems.filter(({ value }) => value !== selectedRegion.placeIds),
        item,
      ])
    } else {
      onChange(secondarySearchItems.filter(({ value }) => value !== item.value))
    }
  }, [isAllSelected, onChange, secondarySearchItems, selectedRegion.identifier, selectedRegion.placeIds, selectedRegions, setSelectedRegions])

  const handleSelectAll = useCallback(() => {
    onChange([])
    setIsAllSelected(!isAllSelected)
    setSelectedRegions([])
  }, [isAllSelected, onChange, setIsAllSelected, setSelectedRegions])

  const checkItemSelected = useCallback((isSelected: boolean, regionIdentifier?: string) => {
    const localRegionSelected = isMobileMode && selectedRegions.includes(regionIdentifier!)
    const globalRegionSelected = !isMobileMode && selectedRegions.includes(selectedRegion.identifier)
    return isSelected || globalRegionSelected || localRegionSelected
  }, [isMobileMode, selectedRegion.identifier, selectedRegions])

  return <VerticalSpacer gap={16}>
    {fetching && <>
      <ListItemLoadingSkeleton subtitle/>
      <ListItemLoadingSkeleton subtitle/>
      <ListItemLoadingSkeleton subtitle/>
    </>}

    {!fetching && <CruiseDepartureSelectWrapper data-testid="cruise-departure-select-list-wrapper">
      {!isMobileMode && !searchTerm && <CruiseDepartureSelectListAll
        isSelected={isAllSelected}
        onClick={handleSelectAll}
      />}

      {!searchTerm && !shouldDisableRegionTabs && <CruiseDepartureSelectListByRegion
        isSelected={selectedRegions.includes(selectedRegion.identifier) || isAllSelected}
        onSelect={handleSelectByRegion}
        onUnselect={handleUnselectByRegion}
        departurePort={selectedRegion}
        isDisabled={isAllDisabled}
      />}

      {!topLevelRegionOnly && searchDeparturePorts.map(({
        searchItem,
        isSelected,
        isDisabled,
        regionIdentifier,
      }, index) => <CruiseDepartureSelectListItem
        key={index}
        searchItem={searchItem}
        onSelect={handleSelectItem}
        onUnselect={handleUnselectItem}
        isSelected={checkItemSelected(isSelected, regionIdentifier)}
        isDisabled={isDisabled}
        isSearching={!!searchTerm}
        searchTerm={searchTerm}
      />)}
    </CruiseDepartureSelectWrapper>}
  </VerticalSpacer>
}

export default CruiseDepartureSelectList
