import React, { useCallback, useContext, useEffect } from 'react'
import { fetchProductSubscription } from 'actions/LuxPlusActions'
import FormatCurrency from 'components/Common/FormatCurrency'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { useAppDispatch } from 'hooks/reduxHooks'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import LuxPlusSubscriptionCancellationModal from '../LuxPlusSubscriptionCancellationModal'
import TextLink from 'components/Luxkit/TextLink'
import ModalContext from 'contexts/ModalContext'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import LuxPlusBenefitsList from '../LuxPlusBenefitsList'
import LuxPlusSubscriptionTsAndCs from '../LuxPlusSubscriptionTsAndCs'
import { connect } from 'react-redux'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { generateLuxPlusSubscriptionItems } from 'lib/checkout/luxPlusSubscription/cart'
import { addToCartGTM } from 'analytics/eventDefinitions'
import { fireEcommerceGTMEvent } from 'api/googleTagManager'
import { addToCheckoutCart } from 'actions/CheckoutActions'
import { navigateToCheckout } from 'checkout/actions/navigateActions'
import CurrencyContext from 'contexts/currencyContext'

interface MappedProps {
  subscription: App.ProductSubscription;
  isLoggedIn: boolean;
}

interface Props extends MappedProps {}

function LuxPlusHiddenOfferModal(props: Props) {
  const { subscription } = props
  const { resolve } = useModalElementContext<boolean>()

  const currency = useContext(CurrencyContext)
  const showModal = useContext(ModalContext)
  const dispatch = useAppDispatch()

  const onShowCancellationPolicy = useCallback(() => {
    showModal(<LuxPlusSubscriptionCancellationModal offer={subscription.offers?.[0]} />)
  }, [showModal, subscription.offers])

  const closeModal = useCallback(() => resolve(true), [resolve])

  const handlePrimaryAction = useCallback(() => {
    const offerWithJoinFee = subscription?.offers?.find(offer => !offer.joinFeeWaived)
    if (offerWithJoinFee) {
      const cartItems = generateLuxPlusSubscriptionItems(offerWithJoinFee)
      fireEcommerceGTMEvent(addToCartGTM(offerWithJoinFee, currency, offerWithJoinFee.price + offerWithJoinFee.joinFee, undefined, 'subscription'))
      dispatch(addToCheckoutCart(cartItems))
      dispatch(navigateToCheckout())
      closeModal()
    }
  }
  , [closeModal, currency, dispatch, subscription?.offers])

  useEffect(() => {
    dispatch(fetchProductSubscription())
  }, [dispatch])

  return <Modal
    primaryActionText={`Join ${LUXURY_PLUS.PROGRAM_NAME}`}
    onPrimaryActionClick={handlePrimaryAction}
    title={`Join ${LUXURY_PLUS.PROGRAM_NAME} to view this hidden offer`}
  >
    {subscription.fetching && <LoadingIndicator />}
    {subscription.error && <BodyText variant="medium">There was an error loading the details for the program. Please try again later.</BodyText>}
    {subscription.offers &&
      <Group direction="vertical" gap={24}>
        <Group direction="vertical" gap={12}>
          <BodyText variant="medium">
            Become a member of our VIP travel club for just <FormatCurrency value={subscription.offers[0].price} />/year to unlock this hidden hotel offer today.
          </BodyText>
          <BodyText variant="medium" weight="bold">
            You’ll also enjoy these unlimited benefits all year round:
          </BodyText>
          <LuxPlusBenefitsList kind="long" benefits={subscription.offers[0].benefits} />
          <TextLink weight="bold" variant="highlight" size="medium" endIcon={<SolidInfoCircleIcon colour="neutral-one" />} onClick={onShowCancellationPolicy}>
            Cancel renewal anytime
          </TextLink>
        </Group>
        <LuxPlusSubscriptionTsAndCs onTsAndCsLinkClick={closeModal} />
      </Group>}
  </Modal>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  subscription: state.luxPlus.products.subscription,
  isLoggedIn: selectLoggedIn(state),
})

export default connect(mapStateToProps)(LuxPlusHiddenOfferModal)
