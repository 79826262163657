import React from 'react'
import styled from 'styled-components'
import ResponsiveImage, { ImageParams } from '../../Common/ResponsiveImage'
import Image from 'components/Common/Image'

export const StyledModalImageThumbnail = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
`

interface ModalImageThumbnailProps {
  /**
   * Hero image above the content
   */
  image: App.Image;
  /**
   * Parameters passed into the hero image component
   */
  imageParams?: ImageParams
  className?: string
}

/**
 * @description This component is the hero image within `ModalBody.tsx` which should always come immediately before `ModalContent.tsx`.
 * @see `Modal.tsx` for usage
 * */
function ModalImageThumbnail(props: ModalImageThumbnailProps) {
  const { className, image, imageParams } = props

  // TODO: if image is an array, use carousel
  // if (Array.isArray(image)) {
  //   return CAROUSEL
  // } else {
  //   return ONE IMAGE
  // }

  let imageElement = <ResponsiveImage
    {...imageParams}
    id={image.id ?? ''}
    alt={image.title}
  />

  if (!image.id) {
    imageElement = <Image
      {...imageParams}
      image={image}
    />
  }

  return <StyledModalImageThumbnail className={className}>
    {imageElement}
  </StyledModalImageThumbnail>
}

export default ModalImageThumbnail
