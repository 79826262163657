import React from 'react'
import BusinessTravellerWithinBudgetDropdown from './BusinessTravellerWithinBudgetDropdown'
import useBusinessTravellerBusinessBudgetFetcher from 'businessTraveller/hooks/useBusinessTravellerBusinessBudgetFetcher'

interface Props {
  label: string
  filters: App.OfferListFilters
  search: string
}

function BusinessTravellerMapBudgetChipFilter(props: Props) {
  const { label, filters, search } = props

  const { budgetData } = useBusinessTravellerBusinessBudgetFetcher({})

  const currentBudget = budgetData?.budgets ? budgetData.budgets[0] : undefined

  return <BusinessTravellerWithinBudgetDropdown
    label={label}
    filters={filters}
    search={search}
    currentBudget={currentBudget}
  />
}

export default BusinessTravellerMapBudgetChipFilter
