import { createSelector } from 'reselect'
import { sum } from 'lib/array/arrayUtils'
import { isGiftCardItem } from 'lib/checkout/checkoutUtils'

export const getGiftCardItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.GiftCardItem> => items.filter(isGiftCardItem),
)

export const isStandaloneGiftCard = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => getGiftCardItems(state),
  (items, giftCardItems): boolean => !!items.length && items.length == giftCardItems.length,
)

export const getGiftCardItemsView = createSelector(
  (state: App.State) => getGiftCardItems(state),
  (giftCardItems): App.WithDataStatus<Array<App.Checkout.GiftCardItemView>> => {
    return {
      hasRequiredData: true,
      data: giftCardItems.map((item): App.Checkout.GiftCardItemView => {
        return {
          item,
          totals: {
            price: item.amount,
            value: item.amount,
            memberPrice: 0,
            taxesAndFees: 0,
            memberValue: 0,
            surcharge: 0,
            extraGuestSurcharge: 0,
          },
        }
      }),
    }
  },
)

export const getGiftCardBreakdownView = createSelector(
  (state: App.State) => getGiftCardItemsView(state),
  (viewWithStatus): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) { return { hasRequiredData: viewWithStatus.hasRequiredData, data: [] } }

    const breakdownView: App.Checkout.PriceBreakdownView = {
      title: 'Gift Card',
      price: sum(viewWithStatus.data, item => item.totals.price || 0),
      memberPrice: 0,
      additionalInfoText: [],
      items: [],
    }

    return {
      hasRequiredData: true,
      data: [breakdownView],
    }
  },
)
