import React, { useCallback } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'

import Clickable from 'components/Common/Clickable'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Caption from 'components/Luxkit/Typography/Caption'
import FormatCurrency from 'components/Common/FormatCurrency'
import noop from 'lib/function/noop'
import SolidFireIcon from 'components/Luxkit/Icons/solid/SolidFireIcon'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import StructureLabel from 'components/Luxkit/Label/StructureLabel'

const Root = styled(Clickable)`
  height: 44px;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: background-color 0.2s;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${rem(12)};
  padding-top: ${rem(4)};

  &.selected {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
  }

  &.in-range-date {
    background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
  }

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &:not(:disabled):not(.selected) {
    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
        color: ${props => props.theme.palette.neutral.default.one};
      }
    }
    &:focus {
      background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
      color: ${props => props.theme.palette.neutral.default.one};
    }
  }
`

const FeaturedIcon = styled(SolidFireIcon)`
  position: absolute;
  top: 0;
  right: ${rem(2)};
`

const PriceCaption = styled(Caption)`
  &:empty {
    display: none;
  }
`

const Label = styled(StructureLabel)`
  position: absolute;
  top: ${rem(-18)};
  left: ${rem(10)};
  z-index: 1;
`

interface Props {
  day: Date;
  dataTestId: string;
  selected?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isInRange?: boolean;
  price?: number;
  onClick?: (day: Date) => void;
  cheapest?: boolean;
  featured?: boolean;
  label?: string;
}

function FlightsDatePickerDay(props: Props) {
  const {
    day,
    dataTestId,
    loading,
    price,
    disabled,
    selected,
    isInRange,
    featured,
    cheapest,
    onClick = noop,
    label,
  } = props

  const onSelectDay = useCallback(() => {
    onClick(day)
  }, [day, onClick])

  return (
    <Root
      onClick={onSelectDay}
      disabled={disabled}
      className={cn({
        selected: selected && !loading,
        'in-range-date': isInRange && !loading,
      })}
      data-testid={dataTestId}
    >
      {label && <Label variant="dark">
        {label}
      </Label>}
      {featured && <FeaturedIcon colour="urgency" size="XS" />}
      <BodyText variant="medium">
        {day.getDate()}
      </BodyText>
      <PriceCaption variant="medium" colour={cheapest ? 'highlight-secondary' : 'neutral-two'}>
        {loading && !disabled && <TextLoadingBox width="80%" />}
        {!loading && !!price && !disabled && <FormatCurrency value={price} />}
      </PriceCaption>
    </Root>
  )
}

export default FlightsDatePickerDay
