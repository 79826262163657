import { Bedbank } from '@luxuryescapes/contract-svc-bedbank'
import { sum } from 'lib/array/arrayUtils'
import request from 'api/requestUtils'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'

import { templates } from '@luxuryescapes/lib-uri-templates'

export interface CreateReservationEnquiryProps {
  propertyId: string;
  roomTypeId: string;
  roomRateId: string;
  bedGroupId?: string;
  rateGroupId?: string | null;
  region: string;
  rooms: Array<App.Occupants>;
  checkIn: string;
  checkOut: string;
  price: number;
  memberInclusive?: number;
  propertyFees?: number;
  csBooking: boolean;
  isFlightBundle: boolean;
  subscriber?: App.OrderSubscriber;
  source?: string;
}

const enquiryMap = (data: App.EnquiryResponse): App.BedbankEnquiry => {
  const returnData: App.BedbankEnquiry = {
    sessionId: data.sessionId,
    price: data.price,
    propertyId: data.propertyId,
    roomTypeId: data.roomTypeId,
    roomRateId: data.roomRateId,
    bedGroupId: data.bedGroupId,
    rateGroupId: data.rateGroupId,
    occupancy: data.occupancy,
    checkIn: data.checkIn,
    checkOut: data.checkOut,
    isFlightBundle: data.isFlightBundle,
    luxplusPrice: data.luxplusPrice,
    userId: data.userId,
  }

  if (data.rate) {
    returnData.occupancyPricing = data.rate.occupancyPricing.map(op => ({
      ...op,
      occupancy: op.occupancy,
      exclusive: op.exclusive,
      inclusive: op.inclusive,
      taxesAndFees: op.taxesAndFees,
      salesTax: op.salesTax,
      fees: op.fees,
      memberExclusive: op.luxPlusExclusive ?? 0,
      memberInclusive: op.luxPlusInclusive ?? 0,
      total: op.inclusive + sum(op.fees, fee => fee.amount),
      memberTotal: op.luxPlusInclusive ? op.luxPlusInclusive + sum(op.fees, fee => fee.amount) : undefined,
    }))
    returnData.totals = {
      exclusive: data.rate.totals.exclusive,
      inclusive: data.rate.totals.inclusive,
      propertyFees: data.rate.totals.propertyFees,
      taxesAndFees: data.rate.totals.taxesAndFees,
      memberExclusive: data.rate.totals.luxPlusExclusive ?? 0,
      memberInclusive: data.rate.totals.luxPlusInclusive ?? 0,
      total: data.rate.totals.inclusive + (data.rate.totals.propertyFees ?? 0),
      memberTotal: data.rate.totals.luxPlusInclusive ? data.rate.totals.luxPlusInclusive + (data.rate.totals.propertyFees ?? 0) : undefined,
    }
  }

  if (data.customerRefundPolicy?.cancellationPolicies) {
    returnData.cancellationPolicies = data.customerRefundPolicy?.cancellationPolicies
  }

  return returnData
}

export function enquiry(payload: CreateReservationEnquiryProps) {
  return request.post<
    App.ApiResponse<Bedbank.CreateReservationEnquiryResponse>,
    Omit<Bedbank.CreateReservationEnquiryRequest, 'brand'>
  >(templates.bedbank.bedbankEnquiry.expand(), {
    propertyId: payload.propertyId,
    roomTypeId: payload.roomTypeId,
    roomRateId: payload.roomRateId,
    bedGroupId: payload.bedGroupId,
    rateGroupId: payload.rateGroupId ?? undefined,
    isFlightBundle: payload.isFlightBundle,
    region: payload.region,
    occupancy: payload.rooms.map(generateOccupancyStringByRoom),
    checkIn: payload.checkIn,
    checkOut: payload.checkOut,
    price: payload.price,
    luxPlusPrice: payload.memberInclusive,
    propertyFees: payload.propertyFees,
    csBooking: payload.csBooking,
    subscriber: payload.subscriber,
    source: payload.source,
  }, { credentials: 'include' })
    .then(data => enquiryMap(data.result))
}

interface CalendarProps {
  propertyId: string;
  region: string;
  nights: number;
  roomId?: string;
}

export function getCalendar(payload: CalendarProps) {
  return request.get<App.ApiResponse<Bedbank.CalendarResponse>>(templates.bedbank.bedbankCalendar.expand(payload), { credentials: 'include' })
    .then(data => data.result)
}

export function getEnquiry(sessionId: string) {
  return request.get<
    App.ApiResponse<Bedbank.CreateReservationEnquiryResponse>
  >(templates.bedbank.bedbankGetEnquiry.expand({ sessionId }), { credentials: 'include' })
    .then(data => enquiryMap(data.result))
}
