import { createSelector } from 'reselect'
import { getTravellerFormSchemaRequestKey } from './request/travellerSchema'

const getIsCheckoutTravellerSchemaFetching = createSelector(
  (state: App.State) => state.checkout.fetchingSchemaData[getTravellerFormSchemaRequestKey(state)],
  (fetching): boolean => {
    return !!fetching
  },
)

export default getIsCheckoutTravellerSchemaFetching
