import React, { ComponentProps, forwardRef } from 'react'
import TextInputOnly from './TextInputOnly'
import InputLabelWrap from './InputLabelWrap'
import Input from './Input'
import { rem } from 'polished'
import styled from 'styled-components'
import { TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from 'components/Luxkit/Typography/Typography'
import { InputTextBase } from 'components/Luxkit/Typography/InputText'
import InputWrap from './InputWrap'

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`

const DisplayValue = styled.div`
  ${InputTextBase}
  position: absolute;
  left: ${rem(12)};
  right: ${rem(12)};
  top: ${rem(12)};
  color: ${props => props.theme.palette.neutral.default.one};
  pointer-events: none;
  background-color: ${props => props.theme.palette.neutral.default.eight};

  input:focus ~ & {
    display: none;
  }
`

const StyledInput = styled(Input)`
  ${InputTextBase}
  line-height: var(${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR});
  width: 100%;
  border: none;
  background: transparent;
  transition: color 0.2s;
  padding: ${rem(12)};
  border-radius: ${props => props.theme.borderRadius.S};
  color: ${props => props.theme.palette.neutral.default.one};

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    &:not(:focus) {
      color: ${props => props.theme.palette.neutral.default.four};
    }
    &:focus {
      display: none;
    }
  }

  &:disabled {
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.palette.neutral.default.two};
    }
  }

  &:not(:focus) {
    color: transparent;
  }

  /*
    Browser things like auto fill and validation pop ups use the
    actual input element as their target. We want this to look like
    the whole element - so we need our input to take up the whole container

    When there's icon, reserve space for them and then they will be
    absolutely positioned.

    Icons for inputs are always 24px sized
  */
  &.icon-left {
    padding-left: ${rem(48)};
  }

  &.icon-right {
    padding-right: ${rem(48)};
  }
`

interface Props extends Omit<ComponentProps<typeof TextInputOnly>, 'ref'> {
  label?: React.ReactNode;
  optionalIndicatorEnabled?: boolean;
  uppercase?: boolean;
  displayValue?: string;
}

const TextInputList = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    label,
    style,
    optionalIndicatorEnabled,
    hidden,
    displayValue,
    required,
    containerRef,
    disabled,
    noValidationSpacing,
    noValidationMessage,
    startIcon,
    endIcon,
    helpText,
    ...inputProps
  } = props

  return (
    <InputLabelWrap
      label={label}
      style={style}
      className={className}
      required={required}
      optionalIndicatorEnabled={optionalIndicatorEnabled}
      hidden={hidden}
    >
      <InputWrap
        ref={containerRef}
        disabled={disabled}
        noValidationSpacing={noValidationSpacing}
        noValidationMessage={noValidationMessage}
        startIcon={startIcon}
        endIcon={endIcon}
        helpText={helpText}
      >
        <InputContainer>
          <StyledInput
            {...inputProps}
            ref={ref}
          />
          <DisplayValue>
            {displayValue}
          </DisplayValue>
        </InputContainer>
      </InputWrap>
    </InputLabelWrap>
  )
})

export default TextInputList
