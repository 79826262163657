import { useMemo } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import offerPageURL from 'lib/offer/offerPageURL'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { getImmersiveTripId } from 'tripPlanner/selectors'
import config from 'constants/config'
import { buildSearchParamsFromFilters, buildSuggestedDatesParamsKey, convertFlexibleDatesToTravelDates } from 'lib/search/searchUtils'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import { SOURCE_TYPES } from 'constants/source'
import { queryKeySource } from 'constants/url'
import useOfferMetaData from './useOfferMetaData'
import { OfferUnavailableReason } from '../../lib/search/constants'
import { isLEHotel } from '../../lib/offer/offerTypes'

function useOfferUrl(
  offer: App.AnyOffer,
  options: { filters?: App.OfferListFilters, bundledOfferId?: string, offerLinkIncludesFilters?: boolean },
): string {
  const { filters, bundledOfferId, offerLinkIncludesFilters } = options
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(filters?.flexibleMonths, filters?.flexibleNights, filters?.rooms)
  const suggestedDates = useAppSelector(state => getSuggestedDates(state, flexibleSearchFilterKey, offer.id))
  const tripId = useAppSelector(state => getImmersiveTripId(state))
  const searchMetadata = useOfferMetaData(offer.id, options.filters)

  const isPropertySearchTile = useMemo(() => {
    return (isLEHotel(offer) && filters?.propertyId && !filters?.searchNearby && offer.property?.id === filters.propertyId.split(':')[1])
  }, [filters, offer])

  return useMemo(() => {
    let offerUrlSuggestedDates: App.OfferSuggestedDates | undefined = suggestedDates
    if (!suggestedDates?.checkIn && !suggestedDates?.checkOut && filters?.flexibleNights && filters?.flexibleMonths) {
      const { checkIn, checkOut } = convertFlexibleDatesToTravelDates(filters.flexibleNights as FLEXIBLE_DURATION_RANGE, filters.flexibleMonths)
      offerUrlSuggestedDates = {
        ...suggestedDates,
        checkIn,
        checkOut,
      }
    }

    const noDatesInUrl = !!isPropertySearchTile && searchMetadata?.unavailableReason === OfferUnavailableReason.NUM_NIGHTS_UNSUPPORTED

    const searchParams = offerLinkIncludesFilters ? buildSearchParamsFromFilters(filters, offerUrlSuggestedDates, { noDatesInUrl }) : new URLSearchParams()
    if (config.OPEN_NEW_TAB_OFFER_CLICK && tripId) {
      // Make sure immersive trip header persists when opening offer links in new tab
      searchParams.set('tripId', tripId)
    }
    if (bundledOfferId) {
      searchParams.set('bundledOfferId', bundledOfferId)
    }

    const departureMonths = filters?.departureMonths?.filter(Boolean) ?? []
    if (departureMonths.length > 0) {
      departureMonths.forEach(month => {
        searchParams.append('departureMonths', month)
      })
    }

    if (offer.type === OFFER_TYPE_BED_BANK && filters?.source === SOURCE_TYPES.METASEARCH) {
      searchParams.set(queryKeySource, filters.source)
    }

    return offerPageURL(offer, searchParams)
  }, [filters, offer, bundledOfferId, offerLinkIncludesFilters, tripId, suggestedDates, isPropertySearchTile, searchMetadata?.unavailableReason])
}

export default useOfferUrl
