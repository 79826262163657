import clsx from 'clsx'
import React, { forwardRef, HTMLAttributes, PropsWithChildren } from 'react'
import styled from 'styled-components'

const Container = styled.tbody`
`

interface Props extends PropsWithChildren, HTMLAttributes<HTMLTableSectionElement> {
}

/**
 * Must be child of `<Table>`.
 *
 * Contains `<TableBodyRow>`s.
 */
const TableBody = forwardRef<HTMLTableSectionElement, Props>(function TableBody(props, ref) {
  return <Container {...props} ref={ref} className={clsx(TableBody.name, props.className)} />
})

export default TableBody
