// possible states of the booking state machine
export const STEP_CAPACITY = 'capacity'
export const STEP_PAYMENT = 'payment'
export const STEP_CHANGE_DATES = 'change-dates'
export const STEP_BEDBANK_CHANGE_DATES = 'partner-change-dates'
export const STEP_CHANGE_PACKAGE = 'change-package'
export const STEP_BEDBANK_CHANGE_PACKAGE = 'partner-change-package'
export const INSURANCE_SELECT_DATES = 'insurance-select-dates'
export const STEP_BOOK_DATES = 'book-dates'
export const STEP_CONFIRMATION = 'confirmation'
export const STEP_DEPARTING_FLIGHT = 'departing'
export const STEP_RETURNING_FLIGHT = 'returning'
export const STEP_MONTH_CALENDAR = 'month'
export const STEP_EXPERIENCES = 'experiences'
export const STEP_DAY_CALENDAR = 'dates'
export const STEP_TOUR_DATES = 'tour-dates'
export const STEP_BALANCE_PAYMENT = 'deposit-balance-payment'
export const STEP_INSTALMENT_PAYMENT = 'instalment-payment'
export const STEP_INSTALMENT_BALANCE_PAYMENT = 'instalment-balance-payment'
export const STEP_RESERVE_FOR_ZERO_PAYMENT = 'reserve-for-zero-payment'
