import { selectDateFormattingLocaleForRegion } from 'lib/datetime/dateUtils'
import getObjectKey from 'lib/object/getObjectKey'

export interface NumberFormatterOptions {
  /**
   * @default decimal
   */
  style?: 'decimal' | 'percent' | 'unit'
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#unit_2)
   */
  unit?: Intl.NumberFormatOptions['unit']
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#unitdisplay)
   *
   * @default short
   */
  unitDisplay?: Intl.NumberFormatOptions['unitDisplay']
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#signdisplay)
   */
  signDisplay?: Intl.NumberFormatOptions['signDisplay']
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#usegrouping)
   */
  useGrouping?: Intl.NumberFormatOptions['useGrouping']
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#minimumfractiondigits)
   */
  minimumFractionDigits?: Intl.NumberFormatOptions['minimumFractionDigits']
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#maximumfractiondigits)
   */
  maximumFractionDigits?: Intl.NumberFormatOptions['maximumFractionDigits']
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#notation)
   */
  notation?: Intl.NumberFormatOptions['notation']
  /**
   * [MDN Docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#compactdisplay)
   *
   * @default short
   */
  compactDisplay?: Intl.NumberFormatOptions['compactDisplay']
  regionCode?: string
}
const cachedNumberFormatters = new Map<string, Intl.NumberFormat>()
function getNumberFormatter(options: NumberFormatterOptions): Intl.NumberFormat {
  const cacheKey = getObjectKey(options)
  const cached = cachedNumberFormatters.get(cacheKey)
  if (cached) return cached

  const formatter = new Intl.NumberFormat(
    options.regionCode ? selectDateFormattingLocaleForRegion(options.regionCode) : undefined,
    options,
  )
  cachedNumberFormatters.set(cacheKey, formatter)
  return formatter
}

export interface DecimalFormatOptions extends Pick<NumberFormatterOptions, 'regionCode' | 'signDisplay' | 'useGrouping' | 'maximumFractionDigits' | 'minimumFractionDigits' | 'notation' | 'compactDisplay'> {
}
export function formatDecimal(
  value: number,
  options: DecimalFormatOptions = {},
): string {
  const opt = { ...options, style: 'decimal' as const }
  try {
    const formatter = getNumberFormatter(opt)
    return formatter.format(value)
  } catch {
    return process.env.NODE_ENV === 'development' ? `UNSUPPORTED NUMBER FORMAT OPTION: ${JSON.stringify(opt)}` : ''
  }
}

export interface PercentFormatOptions extends Pick<NumberFormatterOptions, 'regionCode' | 'signDisplay' | 'useGrouping'| 'maximumFractionDigits' | 'minimumFractionDigits'> {
}
export function formatPercent(
  value: number,
  options: PercentFormatOptions = {},
): string {
  const opt = { ...options, style: 'percent' as const }
  try {
    const formatter = getNumberFormatter(opt)
    return formatter.format(value)
  } catch {
    return process.env.NODE_ENV === 'development' ? `UNSUPPORTED NUMBER FORMAT OPTION: ${JSON.stringify(opt)}` : ''
  }
}

export interface UnitFormatOptions extends Pick<NumberFormatterOptions, 'regionCode' | 'signDisplay' | 'useGrouping' | 'unitDisplay' | 'maximumFractionDigits' | 'minimumFractionDigits'> {
}
export function formatUnit(
  value: number,
  unit: NonNullable<NumberFormatterOptions['unit']>,
  options: UnitFormatOptions = {},
): string {
  const opt = { ...options, style: 'unit' as const, unit }
  try {
    const formatter = getNumberFormatter(opt)
    return formatter.format(value)
  } catch {
    return process.env.NODE_ENV === 'development' ? `UNSUPPORTED NUMBER FORMAT OPTION: ${JSON.stringify(opt)}` : ''
  }
}

const DIRTY_CHARS_PATTERN = /[^0-9|^.]/g
/**
 * Transform a formatted number string into a value of type `number`
 *
 * e.g.
 * $400.50 => 400.5
 * AUD400.5067 => 400.5067
 *
 * @remarks
 * In the case of an invalid number with multiple decimal points
 *
 * i.e.
 * 100.10.10
 *
 * We only consider the first `.`
 * Transformed value in this case is: 100.1
 */
export function formattedStringNumberToValue(numStr: string): number {
  if (!numStr) { return 0 }
  const cleanedStr = numStr.replaceAll(DIRTY_CHARS_PATTERN, '')

  // Handle multiple `.` chars
  const delimiterIdx = cleanedStr.indexOf('.')
  const result = (delimiterIdx === -1 || delimiterIdx === cleanedStr.length - 1) ?
    cleanedStr :
    cleanedStr.slice(0, delimiterIdx + 1).concat(cleanedStr.slice(delimiterIdx + 1).replaceAll('.', ''))

  return Number(result)
}
