import request from 'api/requestUtils'
import { tourOrderTravellerDetailsMap } from './mappers/travellerMapper'
import { definitions as SvcTravellerDefs, paths } from '@luxuryescapes/contract-traveller'
import qs from 'qs'

export interface getOrderTravellersResult {
  travellers: Array<App.OrderTraveller>;
  total_travellers: number;
  total_submitted: boolean;
  flights_submitted: boolean;
}

export function reqGetTourOrderTravellerDetails(tourOrderId: string): Promise<App.TourOrderTravellerDetails> {
  return request.get<App.ApiResponse<getOrderTravellersResult>>(`/api/traveller/tour-details/${tourOrderId}`, { credentials: 'include' })
    .then(response => tourOrderTravellerDetailsMap(response.result))
}

// TODO: add payload type from traveller contract
export interface PostOrderTravellerDetailsPayload {
  travellerFormData: Record<string, any>
  // legacy ones 👇
  customer_id: string
  group_count: number
  order_id: string
  booking_number: string
  traveller_id: string
  travellerNumber: number
  group_details_only_collected: boolean
  created_at: string
  purchased_item: App.OrderTravellerPurchaseItem
}
export interface PostOrderTravellerDetailsParams {
  isDraft?: boolean
}
export function postOrderTravellerDetails(payload: PostOrderTravellerDetailsPayload, params?: PostOrderTravellerDetailsParams): Promise<any> {
  const queryParamsString = params ? qs.stringify(params) : ''

  return request.post<App.ApiResponse<PostOrderTravellerDetailsPayload>, unknown>(
    `/api/traveller/tour-details?${queryParamsString}`,
    payload,
    { credentials: 'include' },
  )
}

export async function retrieveTravellerDetailsForm(
  payload: unknown,
): Promise<App.ApiResponse<App.Checkout.TravellerFormSchemaData> | App.Checkout.TravellerFormSchemaData> {
  return request.post<App.ApiResponse<App.Checkout.TravellerFormSchemaData>, unknown>(
    '/api/traveller/travellers-schema',
    payload,
    { credentials: 'include' }).then(response => {
    return response.result
  })
}

export async function getCustomerDetails(customerId: string): Promise<App.CustomerDetails | undefined> {
  const response = await request.get<App.ApiResponse<SvcTravellerDefs['customerDetails']>>(
    `/api/traveller/customer-details?customer_id=${customerId}`,
    { credentials: 'include' },
  )
  if (response.result) {
    return {
      customerId: response.result.customer_id,
      addressDetails: response.result.address_details ?? {},
      gender: response.result.gender,
      title: response.result.title,
      middleName: response.result.middleName,
    }
  }
}

export async function updateCustomerDetails(customerDetails: App.CustomerDetails): Promise<App.CustomerDetails> {
  const { customerId, addressDetails, ...rest } = customerDetails
  await request.patch<App.ApiResponse<void>, SvcTravellerDefs['customerDetails']>(
    '/api/traveller/customer-details',
    {
      customer_id: customerId,
      address_details: addressDetails,
      ...rest,
    },
    { credentials: 'include' },
  )
  return customerDetails
}

export async function deleteCustomerDetails(customerId: string): Promise<void> {
  await request.get<App.ApiResponse<void>>('/api/traveller/customer-details',
    {
      body: { customer_id: customerId },
      credentials: 'include',
    },
  )
}
type CustomerTravellerGetResponseBody = paths['/api/traveller/customer-travellers']['get']['responses']['200']['content']['application/json']['result']

export async function getCustomerTravellers(): Promise<Array<App.CustomerTraveller> | undefined> {
  const response = await request.get<App.ApiResponse<CustomerTravellerGetResponseBody>>(
    '/api/traveller/customer-travellers?without_brand=1',
    { credentials: 'include' },
  )

  if (!response.result) {
    return []
  }

  return response.result.map<App.CustomerTraveller>((traveller) => ({
    id: traveller.id,
    title: traveller.title,
    firstName: traveller.firstName,
    middleName: traveller.middleName,
    lastName: traveller.lastName,
    dateOfBirth: traveller.dob,
    countryOfResidence: traveller.countryOfResidence,
    relationship: traveller.relationship,
    phone: traveller.phone,
    email: traveller.email,
  }))
}

type CustomerTravellerPostRequestBody = paths['/api/traveller/customer-travellers']['post']['parameters']['body']['payload']
type CustomerTravellerPostResponse = paths['/api/traveller/customer-travellers']['post']['responses']['201']['content']['application/json']['result']
type CreateCustomerTravellerPayload = Omit<App.CustomerTraveller, 'id'>

function mapCustomerTravellerToPayload(
  customerTraveller: App.CustomerTraveller | CreateCustomerTravellerPayload,
): CustomerTravellerPostRequestBody | CustomerTravellerPatchRequestBody {
  const { dateOfBirth, ...rest } = customerTraveller
  return {
    ...rest,
    dob: dateOfBirth,
  }
}

export async function createCustomerTraveller(customerTraveller: CreateCustomerTravellerPayload): Promise<App.CustomerTraveller> {
  const payload = mapCustomerTravellerToPayload(customerTraveller)

  return request.post<App.ApiResponse<CustomerTravellerPostResponse>, CustomerTravellerPostRequestBody>(
    '/api/traveller/customer-travellers?without_brand=1',
    payload,
    { credentials: 'include' },
  ).then((response): App.CustomerTraveller => {
    const mapped: App.CustomerTraveller = {
      id: response.result.id,
      title: response.result.title,
      firstName: response.result.firstName,
      middleName: response.result.middleName,
      lastName: response.result.lastName,
      dateOfBirth: response.result.dob,
      countryOfResidence: response.result.countryOfResidence,
      relationship: response.result.relationship,
      phone: response.result.phone,
      email: response.result.email,
    }

    return mapped
  })
}

type CustomerTravellerPatchRequestBody = paths['/api/traveller/customer-travellers/{id}']['patch']['parameters']['body']['payload']

export async function updateCustomerTraveller(customerTraveller: App.CustomerTraveller): Promise<App.CustomerTraveller> {
  const { id, ...rest } = customerTraveller
  const payload = mapCustomerTravellerToPayload(rest)

  await request.patch<App.ApiResponse<void>, CustomerTravellerPatchRequestBody>(
    `/api/traveller/customer-travellers/${id}?without_brand=1`,
    payload,
    { credentials: 'include' },
  )
  return customerTraveller
}

export async function deleteCustomerTraveller(id: string): Promise<void> {
  await request.delete<App.ApiResponse<void>>(
    `/api/traveller/customer-travellers/${id}?without_brand=1`,
    {
      credentials: 'include',
    },
  )
}
