import React, { CSSProperties, useContext, useRef } from 'react'
import cn from 'clsx'
import styled, { keyframes } from 'styled-components'
import LoaderPlayStateContext from 'contexts/LoaderPlayStateContext'
import { rem } from 'polished'

const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`

const Box = styled.div`
  position: relative;
  background-color: var(--loading-box-bg-color);
  overflow: hidden;

  &.floating {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    will-change: transform;
    background: linear-gradient(90deg, transparent, var(--loading-box-wave-color), transparent);
    opacity: 0.4;
    animation: ${loading} 1.5s infinite;
  }

  &.paused::after {
    animation-play-state: paused;
  }

  &.colour-neutral {
    --loading-box-bg-color: ${props => props.theme.palette.neutral.default.six};
    --loading-box-wave-color: ${props => props.theme.palette.neutral.default.five};
  }
  &.colour-highlight-secondary {
    --loading-box-bg-color: ${props => props.theme.palette.highlight.secondary.lightForeground};
    --loading-box-wave-color: ${props => props.theme.palette.highlight.secondary.normalBackground};
  }

  &.border-radius-XS { border-radius: ${props => props.theme.borderRadius.XS}; }
  &.border-radius-S { border-radius: ${props => props.theme.borderRadius.S}; }
  &.border-radius-M { border-radius: ${props => props.theme.borderRadius.M}; }
  &.border-radius-L { border-radius: ${props => props.theme.borderRadius.L}; }
  &.border-radius-round { border-radius: ${props => props.theme.borderRadius.round}; }
`

interface Props {
  floating?: boolean;
  /**
   * Manually pause the animation being run, use this when the loader is not visible
   * as it isn't free to be running animations eternally!
   * This will overwrite any context version
   */
  paused?: boolean;
  /**
   * @default neutral
   */
  colour?: 'neutral' | 'highlight-secondary'
  className?: string
  style?: CSSProperties
  /**
   * in pixels
   */
  height?: number
  /**
   * in pixels
   */
  width?: number
  borderRadius?: App.Design.BorderRadius
}

function LoadingBox({
  className,
  style,
  floating,
  paused,
  colour = 'neutral',
  height,
  width,
  borderRadius,
}: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const playState = useContext(LoaderPlayStateContext)

  return <Box
    ref={ref}
    style={{
      ...style,
      height: height !== undefined ? rem(height) : style?.height,
      width: width !== undefined ? rem(width) : style?.width,
    }}
    className={cn(
      className,
      `colour-${colour}`,
      borderRadius ? `border-radius-${borderRadius}` : undefined,
      {
        floating,
        paused: paused || playState === 'paused',
      })}
  />
}

export default LoadingBox
