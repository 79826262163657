import clsx from 'clsx'
import Group from 'components/utils/Group'
import MaxWeightPreservedTypography from 'components/utils/MaxWeightPreservedTypography'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { rem } from 'polished'
import React, { ComponentProps, forwardRef, ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { SVG_ICON_SIZE_CSS_VAR } from '../SvgIcon'
import BodyText from '../Typography/BodyText'
import Switch from './Switch'

const ClickableLabel = styled.label`
  border-radius: ${props => props.theme.borderRadius.round};
  padding: ${rem(4)};
  height: ${rem(32)};
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${rem(12)};
  padding: ${rem(4)} ${rem(4)} ${rem(4)} ${rem(12)};
  color: ${props => props.theme.palette.neutral.default.one};
  border: ${rem(1)} solid ${props => props.theme.palette.neutral.default.four};
  cursor: pointer;
  user-select: none;
  transition: background-color 200ms, border-color 200ms, color 200ms;

  &:has(input:checked) {
    font-weight: ${props => props.theme.font.primary.weight.superBold};
    border-color: ${props => props.theme.palette.brand.primary.normal};
  }

  &.disabled,
  &.disabled:has(input:checked) {
    cursor: not-allowed;
    color: ${props => props.theme.palette.neutral.default.four};
    border-color: ${props => props.theme.palette.neutral.default.four};
  }

  &:not(.disabled) {
    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.neutral.default.seven};
        border-color: ${props => props.theme.palette.neutral.default.three};

        &:has(input:checked) {
          border-color: ${props => props.theme.palette.neutral.default.two};
        }
      }
    }

    &:focus-within {
      outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid var(--palette-neutral-default-four));

      input:focus {
        outline: none;
      }
    }
  }
`

const LabelContainer = styled(Group)`
  ${SVG_ICON_SIZE_CSS_VAR}: ${rem(16)};
`

interface Props extends ComponentProps<typeof Switch> {
  children: ReactElement | Iterable<ReactNode> | string
  icon?: ReactElement | false
}

const SwitchButton = forwardRef<HTMLLabelElement, Props>(function SwitchButton(
  {
    icon,
    children,
    disabled,
    className,
    ...switchProps
  },
  ref,
) {
  return <ClickableLabel ref={ref} className={clsx(className, { disabled })}>
    <LabelContainer direction="horizontal" verticalAlign="center" gap={4}>
      {icon}
      <MaxWeightPreservedTypography Typography={BodyText} variant="medium" wrap="truncate">
        {children}
      </MaxWeightPreservedTypography>
    </LabelContainer>
    <Switch {...switchProps} disabled={disabled} />
  </ClickableLabel>
})

export default SwitchButton
