import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import ModalContext from 'contexts/ModalContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import MasterModalContext from 'contexts/MasterModalContext'
import {
  get as getLocalStorage,

  set as setLocalStorage,
  remove as removeLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { matchPath } from 'react-router'
import { getHasLuxLoyaltyAccount, getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { clearLuxLoyaltyEnrolment, enrolLuxLoyaltyAccount, fetchLuxLoyaltyAccountEligibility } from 'actions/LuxLoyaltyActions'
import LuxLoyaltyJoinModal from 'luxLoyalty/components/LuxLoyaltyJoinModal'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import { diffInDays } from 'lib/datetime/dateUtils'

const pathsBlacklist = [
  '/:region/checkout',
  '/:region/booking/confirmation',
  `/:region/${LUX_LOYALTY.LANDING_PAGE}`,
  '/:region/offer',
]

function useLuxLoyaltyEnrolExistingUserModal() {
  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)
  const loggedIn = useAppSelector(selectLoggedIn)
  const luxLoyaltyEnabled = useAppSelector(getIsLuxLoyaltyEnabled)
  const modalState = useContext(MasterModalContext)
  const hasLuxLoyaltyAccount = useAppSelector(getHasLuxLoyaltyAccount)
  const luxLoyaltyAccount = useAppSelector(state => state.luxLoyalty.account)
  const luxLoyaltyEligibility = useAppSelector(state => state.luxLoyalty.eligibilityTier)
  const modalDismissalDate = getLocalStorage(LUX_LOYALTY.DISMISS_JOIN_MODAL_STORAGE_KEY)

  const isDismissedPeriod = useMemo(() => modalDismissalDate ? diffInDays(new Date(modalDismissalDate), new Date()) < 14 : false, [modalDismissalDate])

  const path = useAppSelector(state => state.router.location.pathname)

  const isModalPreventedPage = !!matchPath(path, {
    path: pathsBlacklist,
    exact: false,
  })

  const shouldFetchEligibility = loggedIn && luxLoyaltyEnabled && !hasLuxLoyaltyAccount

  const shouldShowModal = loggedIn &&
  luxLoyaltyEnabled &&
  !isDismissedPeriod &&
  !modalState.open &&
  !hasLuxLoyaltyAccount &&
  !luxLoyaltyAccount.fetching &&
  !luxLoyaltyAccount.error &&
  !isModalPreventedPage &&
  !luxLoyaltyEligibility.fetching &&
  !luxLoyaltyEligibility.error &&
  !!luxLoyaltyEligibility.data

  useEffect(() => {
    if (shouldFetchEligibility) {
      dispatch(fetchLuxLoyaltyAccountEligibility())
    }
  }, [shouldFetchEligibility, dispatch])

  const handleClearEnrolment = useCallback(() => {
    dispatch(clearLuxLoyaltyEnrolment())
    setLocalStorage(LUX_LOYALTY.DISMISS_JOIN_MODAL_STORAGE_KEY, new Date().toISOString())
  }, [dispatch])

  const dismissModal = useCallback((resolve: (result?: any) => void) => {
    setLocalStorage(LUX_LOYALTY.DISMISS_JOIN_MODAL_STORAGE_KEY, new Date().toISOString())
    resolve()
  }, [])

  const handleOnClickJoinLuxLoyalty = useCallback((resolve: (result?: any) => void) => {
    dismissModal(resolve)
    dispatch(enrolLuxLoyaltyAccount({ enrolment: 'existingUserRegistration' }))
  }, [dismissModal, dispatch])

  const primaryActionProps = useMemo<React.ComponentProps<typeof ModalFooter>['primaryActionProps']>(() => ({
    children: 'Activate free membership',
    onClick: (_e, resolve) => handleOnClickJoinLuxLoyalty(resolve),
  }), [handleOnClickJoinLuxLoyalty])

  const secondaryActionProps = useMemo<React.ComponentProps<typeof ModalFooter>['secondaryActionProps']>(() => ({
    children: 'Maybe later',
    onClick: (_e, resolve) => dismissModal(resolve),
  }), [dismissModal])

  useEffect(() => {
    if (modalDismissalDate && !isDismissedPeriod) {
      removeLocalStorage(LUX_LOYALTY.DISMISS_JOIN_MODAL_STORAGE_KEY)
    }
  }, [isDismissedPeriod, modalDismissalDate])

  useEffect(() => {
    if (shouldShowModal) {
      showModal(<LuxLoyaltyJoinModal
        onAfterClose={handleClearEnrolment}
        primaryActionProps={primaryActionProps}
        secondaryActionProps={secondaryActionProps}
      />)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowModal])
}

export default useLuxLoyaltyEnrolExistingUserModal
