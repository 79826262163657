import { useEffect } from 'react'

import { setImmersiveTripIdCookie } from 'cookies/immersiveTripIdCookie'
import { useAppSelector, shallowEqual } from 'hooks/reduxHooks'
import { getImmersiveTripId } from 'tripPlanner/selectors'

export function useKeepTripIdOnReload() {
  const [tripId, pathname] = useAppSelector<[string | undefined, string]>(
    (state) => [getImmersiveTripId(state), state.router.location.pathname],
    shallowEqual,
  )

  useEffect(() => {
    if (tripId) {
      const onBeforeUnload = () => {
        setImmersiveTripIdCookie(tripId, pathname)
      }

      window.addEventListener('beforeunload', onBeforeUnload)

      return () => window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [pathname, tripId])
}
