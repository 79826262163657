import clsx from 'clsx'
import { connect } from 'react-redux'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import Switch from 'components/Luxkit/Switch/Switch'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import { useAppDispatch } from 'hooks/reduxHooks'
import { updateUserPreferenceDestination, deleteUserPreferenceDestination } from 'actions/userTravelPreferencesActions'
import ModalContext from 'contexts/ModalContext'
import AddDestinationToggleModal from './AddDestinationToggleModal'
import Clickable from 'components/Common/Clickable'
import useQueryParams from 'hooks/useQueryParams'

interface MappedStateProps {
  savedDestinations?: Array<App.UserPreferenceDestination>
  fetchingTravelPreferences: boolean;
}

interface Props {
  placeId: string;
  userId: string;
  className?: string;
}

function HotelSearchAddDestinationToggle(props: Props & MappedStateProps) {
  const { savedDestinations, userId, placeId, fetchingTravelPreferences, className } = props
  const isDestinationSaved = useMemo(() => !!savedDestinations?.find(destination => destination.placeId === placeId), [savedDestinations, placeId])

  const dispatch = useAppDispatch()
  const [pendingSnackbar, setPendingSnackbar] = useState<boolean>(false)
  const queryParams = useQueryParams()

  const handleClick = useCallback<React.MouseEventHandler<HTMLInputElement>>(() => {
    if (!isDestinationSaved) {
      dispatch(updateUserPreferenceDestination(userId, placeId))
      setPendingSnackbar(true)
    } else {
      dispatch(deleteUserPreferenceDestination(userId, placeId))
    }
  }, [isDestinationSaved, dispatch, userId, placeId])

  useEffect(() => {
    if (!fetchingTravelPreferences && pendingSnackbar && isDestinationSaved) {
      showSnackbar('You can view and update your destinations.', 'success', {
        heading: 'Destination saved',
        action: {
          label: 'Edit preferences',
          to: '/account/travel-preferences#destinations',
        },
      })
      setPendingSnackbar(false)
    }
  }, [fetchingTravelPreferences, pendingSnackbar, isDestinationSaved])

  const showModal = useContext(ModalContext)
  const destinationName = queryParams.get('destinationName')
  const displayModal = useCallback(() => {
    if (destinationName) {
      showModal(<AddDestinationToggleModal placeId={placeId} placeName={destinationName} onAddDestination={handleClick} destinationSaved={isDestinationSaved} />)
    }
  }, [showModal, handleClick, placeId, isDestinationSaved, destinationName])

  return (
    <Group className={clsx(className)} direction="horizontal" horizontalAlign="space-between" verticalAlign="center" gap={8}>
      <Group direction="horizontal" gap={4} verticalAlign="center" horizontalAlign="start">
        <BodyText variant="medium">Add to my destinations</BodyText>
        <Clickable onClick={displayModal}>
          <LineInfoCircleIcon size="S" />
        </Clickable>
      </Group>
      <Switch checked={isDestinationSaved} onClick={handleClick} />
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState) => {
  return {
    fetchingTravelPreferences: !!appState.userTravelPreferences.destinations.fetching,
    savedDestinations: appState.userTravelPreferences.destinations.data,
  }
})(HotelSearchAddDestinationToggle)
