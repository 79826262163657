import { GoogleAutocompletePlaceItem } from 'api/search'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import ListSubheader from 'components/Luxkit/List/ListSubheader'
import usePlaceAutocomplete from 'hooks/usePlaceAutocomplete'
import noop from 'lib/function/noop'
import React from 'react'

interface Props {
  searchPhrase?: string;
  onSelect?: (option: GoogleAutocompletePlaceItem) => void;
  selectedPlaceId?: string;
  className?: string;
  originAirport: App.Airport;
}

function ExperienceTransferBookingHotelSelectOptions(props: Props) {
  const {
    className,
    searchPhrase = '',
    onSelect = noop,
    selectedPlaceId,
    originAirport,
  } = props

  const { isLoading, predictions } = usePlaceAutocomplete(searchPhrase, {
    latitude: originAirport.latitude,
    longitude: originAirport.longitude,
    radius: 100000,
  })

  const noResultsFound = !isLoading && predictions.length === 0 && searchPhrase.length > 1
  const onItemSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    const placeId = e.currentTarget.dataset.placeid
    const option = predictions.find(p => p.placeId === placeId)
    onSelect(option)
  }

  return <VerticalSpacer gap={12} className={className}>
    {!isLoading && predictions.length > 0 && <div>
      {predictions.map(option => (
        <ListItem
          key={option.placeId}
          selected={option.placeId === selectedPlaceId}
          data-placeid={option.placeId}
          onClick={onItemSelect}
          size="medium"
          title={option.mainText}
          subtitle={option.secondaryText}
          startIcon={<LineMapMarkerIcon />}
        />
      ))}
    </div>}
    {!isLoading && noResultsFound && <div>
      <ListSubheader colour="neutral-two">
        Sorry, we couldn’t find results for <b>'{searchPhrase}'</b>.
      </ListSubheader>
      <ListSubheader colour="neutral-two">
        Try a different search phrase
      </ListSubheader>
    </div>}
    {isLoading && <LoadingIndicator inline />}
  </VerticalSpacer>
}

export default ExperienceTransferBookingHotelSelectOptions
