import React, { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import BodyText from 'components/Luxkit/Typography/BodyText'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/BookmarkButton'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import Pane from 'components/Common/Pane'
import ResponsiveImage, { ImageParams } from 'components/Common/ResponsiveImage'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import TextButton from 'components/Luxkit/Button/TextButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2PurchasableOptionDetails from 'hooks/TourV2/useTourV2PurchasableOptionDetails'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import isTourV2DepositAllowed from 'lib/tours/isTourV2DepositAllowed'
import GeoContext from 'contexts/geoContext'
import SecureWithDepositTag from 'components/Common/SecureWithDeposit/SecureWithDepositTag'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import AspectRatio from 'components/utils/AspectRatio'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import Group from 'components/utils/Group'
import cn from 'clsx'
import TourV2UrgencyTags from 'components/Tours/TourV2UrgencyTags'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import OffsetBookmarkContainer from 'tripPlanner/components/Bookmark/Common/OffsetBookmarkContainer'
import useTourV2Details from 'hooks/TourV2/useTourV2Details'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import useTourTags from 'hooks/TourV2/useTourTags'
import PriceRowSale from 'components/Luxkit/PricePoints/PriceRowSale'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import OfferRating from 'components/Common/NumberRating/OfferRating'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { useAppSelector } from 'hooks/reduxHooks'

const HeadingContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template:
    'urgency-label urgency-label'
    'journey operator-logo'
    'review operator-logo'
    'heading operator-logo'
    / 1fr max-content;
    ;
  gap: ${rem(8)};

  ${mediaQueryUp.desktop} {
    align-items: start;
    justify-items: center;
    gap: 0;
    grid-template:
      '.' ${rem(24)}
      'operator-logo'
      '.' ${rem(24)}
      'urgency-label'
      '.' ${rem(8)}
      'journey'
      'heading' / 1fr;
    text-align: center;

    // If labels-area is empty (that means not having urgency tags), we don't want to show the grid area
    &.no-urgency-tags {
      grid-template:
        '.' ${rem(24)}
        'operator-logo'
        '.' ${rem(24)}
        'journey'
        'heading';
    }
  }

  > .labels-area {
    grid-area: urgency-label;
  }

  > .review-area {
    grid-area: review;
  }

  > .heading-area {
    grid-area: heading;
  }

  > .journey-area {
    grid-area: journey;
  }

  > .operator-logo-area {
    grid-area: operator-logo;
    max-width: ${rem(75)};
    max-height: ${rem(50)};

    ${mediaQueryUp.desktop} {
      max-width: ${rem(150)};
      max-height: ${rem(100)};
    }
  }
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueryUp.desktop} {
    align-items: center;
    gap: ${rem(4)};
  }
`

const DetailsCaptionContainer = styled(BodyText)`
  align-items: center;
  display: flex;

  > * + *::before {
    color: ${props => props.theme.palette.neutral.default.one};
    content: '·';
    margin: 0 ${rem(4)};
    speak-as: bullets;
    speak: none;
  }
`

const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueryUp.desktop} {
    align-items: center;
  }
`

const PricingAndCtaArea = styled.div`
  align-items: flex-end;
  display: grid;
  gap: ${rem(4)};
  grid-template:
    'labels labels'
    'pricing cta'
    / 1fr min-content;

  ${mediaQueryUp.desktop} {
    align-items: center;
    width: 100%;
    gap: unset;
    grid-template:
      'labels'
      '.' ${rem(4)}
      'pricing'
      '.' ${rem(16)}
      'cta'
      / 1fr;
  }

  > .labels-area {
    grid-area: labels;
  }

  > .pricing-area {
    grid-area: pricing;
  }

  > .cta-area {
    grid-area: cta;
  }
`

const TileBodyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${mediaQueryUp.desktop} {
    padding: ${rem(16)};
  }
`

const TileBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(20)};
  justify-content: space-between;

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)};
  }

  ${mediaQueryUp.desktop} {
    align-items: center;
    padding: ${rem(16)};
    gap: ${rem(24)};
    height: 100%;
  }
`

const TileMediaContainer = styled(AspectRatio)`
  background-color: ${props => props.theme.palette.neutral.default.seven};
`

const Tile = styled(Pane)`
  display: grid;
  grid-template-areas:
    'media'
    'body';
  position: relative;

  ${mediaQueryUp.desktop} {
    grid-template-areas: 'media body';
    grid-template-columns: 67% 1fr;
  }

  > .media-area {
    grid-area: media;
    min-height: ${rem(188)};
  }

  > .body-area {
    grid-area: body;

    ${mediaQueryUp.desktop} {
      max-height: ${rem(578)};
      min-height: ${rem(482)};
    }
  }
`

const OfferOverlayContainer = styled(LabelGroup)`
  position: absolute;
  top: ${rem(12)};
  left: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const OperatorLogoImage = styled(ResponsiveImage)`
  width: 100%;
  height: 100%;
`

const USDesktopRating = styled(OfferRating)`
  justify-content: center;
`

const MEDIA_IMAGE_PARAMS: ImageParams = {
  quality: 'eco',
  mobileAspectRatio: '16:9',
  tabletAspectRatio: '16:9',
  desktopAspectRatio: '16:11',
  largeDesktopAspectRatio: '16:11',
  mobileWidth: '100vw',
  tabletWidth: '100vw',
  desktopWidth: '780px',
  largeDesktopWidth: '780px',
}

interface Props {
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary
}

function TourV2OfferTile(props: Props) {
  const {
    offer,
  } = props

  const { currentRegionCode, currentCurrency } = useContext(GeoContext)
  const isLuxPlusMember = useAppSelector(checkCanViewLuxPlusBenefits)

  const {
    cheapestPurchasableOption,
    cheapestVariation,
    cheapestDeparture,
  } = useCheapestOfTourV2Offer(offer)

  const {
    hasDiscount,
    pricePerPerson,
    fullPricePerPerson,
    roomTypeName,
    valuedAt,
  } = useTourV2PurchasableOptionDetails(cheapestPurchasableOption) || {}

  const {
    startLocation,
    endLocation,
    hasTheSameStartAndEndLocation,
    placesCount,
    countriesCount,
    durationInDaysCount,
  } = useTourV2VariationDetails(cheapestVariation)

  const tileImages = useMemo(() => {
    if (cheapestVariation?.routeMapImage) {
      return [...cheapestVariation.images, cheapestVariation.routeMapImage]
    }

    return cheapestVariation?.images
  }, [cheapestVariation])

  const { hasMoreVariations, upgradesAvailable } = useTourV2Details(offer)

  const { isDepositAllowed } = useMemo(() => ({
    isDepositAllowed: !!cheapestDeparture && !!pricePerPerson && !!offer.depositType && isTourV2DepositAllowed(cheapestDeparture.startDate, pricePerPerson, currentCurrency, offer.depositType, offer.depositThresholds?.numberOfDays),
  }), [cheapestDeparture, currentCurrency, offer, pricePerPerson])

  const tags = useTourTags(offer)

  const isUSRegion = currentRegionCode === 'US'

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    dispatchOfferListEvent({
      type: OfferListEvents.offerReady,
    })
  }, [dispatchOfferListEvent])

  return <ProductPaletteProvider product={offer}>
    <Tile type="clean" axis="vertical">
      <TileMediaContainer
        className="media-area"
        ratio="16:9"
        fillHeight
      >
        {tileImages && <ImageCarousel
          images={tileImages}
          imageParams={MEDIA_IMAGE_PARAMS}
          eagerLoadFirstImage
          onImageLoad={handleImageLoaded}
        />}
        <OfferOverlayContainer>
          <ProductTypeLabel productType={offer.productType} />

        </OfferOverlayContainer>
      </TileMediaContainer>

      <TileBodyContainer className="body-area">
        <CSSBreakpoint min="desktop">
          <Group direction="horizontal" horizontalAlign="space-between">
            {!isUSRegion && isOfferRatingDisplayable(offer.rating, 0) && <OfferRating variant="medium" rating={offer.rating} />}
            <TripGuard>
              <BookmarkButton offer={offer} />
            </TripGuard>
          </Group>
        </CSSBreakpoint>
        <TileBody>
          <TripGuard>
            <CSSBreakpoint max="tablet">
              <OffsetBookmarkContainer>
                <BookmarkButton offer={offer} />
              </OffsetBookmarkContainer>
            </CSSBreakpoint>
          </TripGuard>

          <Group direction="vertical" gap={8}>
            <HeadingContainer className={cn({ 'no-urgency-tags': !tags.length })} >
              <TourV2UrgencyTags className="labels-area" offer={offer}/>
              <Subtitle variant="subtitle3" className="journey-area">
                {hasTheSameStartAndEndLocation &&
                  <span>Starts and ends in {endLocation}</span>
                }
                {!hasTheSameStartAndEndLocation &&
                  <span>Starts in {startLocation}, Ends in {endLocation}</span>
                }
              </Subtitle>

              {!isUSRegion && isOfferRatingDisplayable(offer.rating, 0) && (
                <div className="review-area">
                  <CSSBreakpoint max="tablet">
                    <OfferRating variant="xs" rating={offer.rating} />
                  </CSSBreakpoint>
                  <CSSBreakpoint min="desktop">
                    <USDesktopRating
                      inlineLabel
                      variant="xs"
                      rating={offer.rating}
                    />
                  </CSSBreakpoint>
                </div>
              )}

              <Heading variant="heading6" lineClamp={3} className="heading-area">{offer.name}</Heading>
              <div className="operator-logo-area">
                {!!offer.brand.logoImage?.id && <OperatorLogoImage
                  id={offer.brand.logoImage.id}
                  mobileWidth="75px"
                  tabletWidth="150px"
                  quality="eco"
                  alt={offer.brand.logoImage.title}
                  role="presentation"
                />}
              </div>
            </HeadingContainer>
            <DetailsContainer>
              <Caption variant="large" lineClamp={1}>
                Operated by: <b>{offer.operatedBy || offer.brand.name}</b>
              </Caption>
              <DetailsCaptionContainer variant="medium">
                <div>{durationInDaysCount}</div>
                {placesCount && <div>{placesCount}</div>}
                {countriesCount && <div>{countriesCount}</div>}
              </DetailsCaptionContainer>
              {hasMoreVariations && <BodyText underline variant="medium" colour="primary" weight="bold">
                More options available
              </BodyText>}
              {!hasMoreVariations && upgradesAvailable && <BodyText underline variant="medium" colour="primary" weight="bold">
                Upgrades available
              </BodyText>}
            </DetailsContainer>
          </Group>
          <PricingAndCtaArea>
            <LabelGroup desktopHorizontalAlign="center" className="labels-area">
              {isDepositAllowed && <SecureWithDepositTag />}
            </LabelGroup>
            <PricingContainer className="pricing-area">
              <PriceRowPriceCaption>{durationInDaysCount} from</PriceRowPriceCaption>
              <PriceRowPrice
                size="L"
                price={pricePerPerson!}
                saleUnit="person"
              />
              {hasDiscount && <PriceRowSale value={fullPricePerPerson!} />}
              <PriceRowCaption>{roomTypeName}</PriceRowCaption>
              {!!valuedAt && <PriceRowValueDiscountWithCaption
                size="M"
                originalValue={valuedAt.price}
                discountPercentage={isLuxPlusMember ? valuedAt.memberSavingsPercentage : valuedAt.savingPercentage}
              />}
              <PriceRowTaxesAndFees />
            </PricingContainer>
            <Group direction="vertical" className="cta-area">
              <TextButton kind="primary">View offer</TextButton>
            </Group>
          </PricingAndCtaArea>
        </TileBody>
      </TileBodyContainer>
    </Tile>
  </ProductPaletteProvider>
}

export default TourV2OfferTile
