import { createSelector } from 'reselect'
import { findPostPurchaseCheckout, isBedbankItem, isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import { canShowAddFlightsForHotelItems, checkoutAccommodationOfferView, isCheckoutAccommodationItemsOnly } from './view/accommodation'
import { getFlightItems } from './view/flights'
import { getVillaViews } from 'checkout/selectors/view/villa'
import { checkCanRedeemLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { getTourV1Items } from './view/tourv1'
import config from 'constants/config'

export const isFlightsPendingToAddToCart = createSelector(
  (state: App.State) => getFlightItems(state),
  (flightItems) : boolean => {
    return flightItems.length > 0 &&
    flightItems.some(item => {
      if (item.fareType === 'return') {
        return !item.flights[0] || !item.flights[1]
      } else if (item.fareType === 'oneWay') {
        return !item.flights[0]
      }
    })
  },
)

export const hasIncompleteTourItem = createSelector(
  (state: App.State) => getTourV1Items(state),
  item => item.some(item => !item.packageId || !item.startDate || !item.endDate),
)

export const hasInsuranceItem = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder?.insuranceItems,
  (items) => {
    if (!items?.length) {
      return false
    }

    return items.some(item => item.status !== 'cancelled')
  },
)

export const isExperiencesSupportedToAddToCart = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getVillaViews(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (accommodationViews, villaViews, postPurchase) : boolean => {
    if (!postPurchase || postPurchase === 'select-date') {
      if (accommodationViews.data.length > 0 || villaViews.data.length > 0) {
        return accommodationViews.data.every(view => view.itemViews.every(itemView => isInstantBookingLEHotelItem(itemView.item) || isBedbankItem(itemView.item))) ||
          villaViews.data.length > 0
      }
    }

    return false
  },
)

export const isLuxPlusAvailableToAddToCart = createSelector(
  (state: App.State) => isLuxPlusEnabled(state),
  (state: App.State) => checkCanRedeemLuxPlusBenefits(state),
  (state: App.State) => state.checkout.cart.isGift,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.auth.account.luxPlus.member.disableMembership,
  (enabled, canRedeemLuxPlusBenefits, isGift, isPostPurchase, disableMembership): boolean => {
    const shouldSeeUpsell = !canRedeemLuxPlusBenefits
    return !!(enabled && shouldSeeUpsell && !isGift && !isPostPurchase && !disableMembership)
  },
)

export const isAddFlightsStepAvailable = createSelector(
  (state: App.State) => isCheckoutAccommodationItemsOnly(state),
  (state: App.State) => canShowAddFlightsForHotelItems(state),
  (state: App.State) => state.checkout.cart.isGift,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (isHotelItemsOnly, isFlashHotelItemsValid, isGift, isPostPurchase): boolean => {
    return isHotelItemsOnly && isFlashHotelItemsValid && !isGift && !isPostPurchase && !config.businessTraveller.isEnabled
  },
)
