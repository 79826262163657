import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import sanitizeItemContextMetadata from './sanitizeItemContextMetadata'
import { getBedbankRateKey } from 'actions/BedbankOfferActions'
import { getAllOffers } from 'selectors/offerSelectors'
import { getHasLuxPlusOrSubscriptionInMultiCart } from 'selectors/userCart'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'

function getBedbankHotelCartItem(
  state: App.State,
  item: App.Checkout.BedbankHotelItem,
  isMultiCart?: boolean,
  rate?: App.BedbankRate,
) {
  const offerId = item.offerId
  const offer: App.BedbankOffer = getAllOffers(state)[offerId] as App.BedbankOffer
  const luxPlusMemberPriceApplies = getHasLuxPlusOrSubscriptionInMultiCart(state)
  // bedbanks are instant booking only
  const reservationType = 'instant_booking'
  const { categoryId, productId } = getCategoryAndProductId('hotel', offer)
  const destinationCity = offer.property.address.city
  const destinationCountry = offer.property.address.countryName
  const destinationRegion = offer.property.address.stateProvinceName

  const rateKey = getBedbankRateKey([item.occupancy], item.checkIn, item.checkOut)
  const rates = state.offer.bedbankOfferRates[offerId]?.[rateKey]
  const matchedRate = rate ?? rates?.find(rate => rate.id === item.roomRateId)
  const totals = matchedRate?.occupancyPricing.find(pricing => pricing.occupancy === generateOccupancyStringByRoom(item.occupancy))
  const memberPriceRaw = totals?.memberInclusive && totals.memberInclusive > 0 ? totals.memberInclusive : undefined
  const nonMemberPrice = totals?.inclusive ? totals.inclusive : undefined
  const propertyFees = totals?.propertyFees
  const price = (luxPlusMemberPriceApplies && memberPriceRaw && memberPriceRaw > 0) ? memberPriceRaw : nonMemberPrice
  const value = nonMemberPrice
  const memberPrice = memberPriceRaw ?? nonMemberPrice

  const duration = item.duration
  const travelStart = item.checkIn
  const travelEnd = item.checkOut

  const travellers = item.occupancy

  return comluxgroup.createItem_1_1_0(
    {
      offerId,
      categoryId,
      productId,
      typeId: item.roomId,
      rateId: item.roomRateId,
      travelStart,
      travelEnd,
      duration,
      price,
      value,
      numberOfAdults: travellers?.adults,
      numberOfChildren: travellers?.children,
      numberOfInfants: travellers?.infants,
      childrenAges: travellers?.childrenAge,
      propertyFees,
      destinationCity,
      destinationCountry,
      destinationRegion,
      reservationType,
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
      metadata: sanitizeItemContextMetadata({
        memberPrice,
        nonMemberPrice,
        isMultiCart,
      }),
    },
  )
}

export default getBedbankHotelCartItem
