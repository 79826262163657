import { INTL_2_DIGIT_MONTH, INTL_DATE_SHORT_DAY_NAME, INTL_DATE_TIME_FORMAT, INTL_DAY_LONG_MONTH_LONG_YEAR, INTL_DAY_MONTH_NAME, INTL_DAY_MONTH_NAME_SHORT, INTL_DAY_ONLY, INTL_DMY_CASUAL_FORMAT, INTL_DMY_CASUAL_SHORT_FORMAT, INTL_DMY_DATE_FORMAT, INTL_LONG_DATE, INTL_LONG_MONTH, INTL_LONG_MONTH_LONG_YEAR, INTL_LONG_WEEKDAY, INTL_PICKER_DATE_FORMAT, INTL_SHORT_DATE_TIME_FORMAT, INTL_SHORT_DAY_NAME_DAY_MONTH, INTL_SHORT_DAY_NAME_DAY_MONTH_YEAR, INTL_SHORT_MONTH, INTL_SHORT_MONTH_LONG_YEAR, INTL_SHORT_TIME_FORMAT_AM_PM, INTL_SHORT_TIME_HOUR_FORMAT_AM_PM, INTL_SHORT_WEEKDAY_COMMA_DAY_SHORT_MONTH_LONG_YEAR, INTL_TIME_DATE_FORMAT, INTL_TIME_DAY_MONTH_FORMAT, INTL_YEAR_ONLY } from 'constants/dateFormats'
import { useIntlDateFormatter } from 'lib/datetime/dateUtils'
import React, { memo } from 'react'

type Format =
  | '2-digit-month'
  | 'date-short-day-name'
  | 'date-time'
  | 'day-long-month-long-year'
  | 'day-month-name-short'
  | 'day-month-name'
  | 'day-only'
  | 'dmy-casual'
  | 'dmy-casual-short'
  | 'dmy-date'
  | 'long-date'
  | 'long-month-long-year'
  | 'long-month'
  | 'long-weekday'
  | 'picker-date'
  | 'short-date-time'
  | 'short-day-name-day-month-year'
  | 'short-day-name-day-month'
  | 'short-month-long-year'
  | 'short-month'
  | 'short-time-am-pm'
  | 'short-time-hour-am-pm'
  | 'short-weekday-comma-day-short-month-long-year'
  | 'time-date'
  | 'time-day-month'
  | 'year-only'

const FORMATS: Record<Format, Intl.DateTimeFormatOptions> = {
  '2-digit-month': INTL_2_DIGIT_MONTH,
  'date-short-day-name': INTL_DATE_SHORT_DAY_NAME,
  'date-time': INTL_DATE_TIME_FORMAT,
  'day-long-month-long-year': INTL_DAY_LONG_MONTH_LONG_YEAR,
  'day-month-name-short': INTL_DAY_MONTH_NAME_SHORT,
  'day-month-name': INTL_DAY_MONTH_NAME,
  'day-only': INTL_DAY_ONLY,
  'dmy-casual': INTL_DMY_CASUAL_FORMAT,
  'dmy-casual-short': INTL_DMY_CASUAL_SHORT_FORMAT,
  'dmy-date': INTL_DMY_DATE_FORMAT,
  'long-date': INTL_LONG_DATE,
  'long-month-long-year': INTL_LONG_MONTH_LONG_YEAR,
  'long-month': INTL_LONG_MONTH,
  'long-weekday': INTL_LONG_WEEKDAY,
  'picker-date': INTL_PICKER_DATE_FORMAT,
  'short-date-time': INTL_SHORT_DATE_TIME_FORMAT,
  'short-day-name-day-month-year': INTL_SHORT_DAY_NAME_DAY_MONTH_YEAR,
  'short-day-name-day-month': INTL_SHORT_DAY_NAME_DAY_MONTH,
  'short-month-long-year': INTL_SHORT_MONTH_LONG_YEAR,
  'short-month': INTL_SHORT_MONTH,
  'short-time-am-pm': INTL_SHORT_TIME_FORMAT_AM_PM,
  'short-time-hour-am-pm': INTL_SHORT_TIME_HOUR_FORMAT_AM_PM,
  'short-weekday-comma-day-short-month-long-year': INTL_SHORT_WEEKDAY_COMMA_DAY_SHORT_MONTH_LONG_YEAR,
  'time-date': INTL_TIME_DATE_FORMAT,
  'time-day-month': INTL_TIME_DAY_MONTH_FORMAT,
  'year-only': INTL_YEAR_ONLY,
}

interface BaseProps {
  value: Date | moment.Moment | string
}

interface PropsWithFormat extends BaseProps {
  /**
   * Predefined formats from `constants/dateFormats.ts`
   */
  format: Format
}

interface PropsWithOptions extends BaseProps, Intl.DateTimeFormatOptions {
  format?: undefined
}

function isPropsWithOptions(props: Props): props is PropsWithOptions {
  return props.format === undefined
}

type Props = PropsWithFormat | PropsWithOptions

/**
 * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#date-time_component_options)
 */
function FormatDateTime(props: Props) {
  const formatter = useIntlDateFormatter()

  if (isPropsWithOptions(props)) {
    const { value, ...options } = props
    return <>{formatter(value, options)}</>
  } else {
    const { value, format } = props
    return <>{formatter(value, FORMATS[format])}</>
  }
}

export default memo(FormatDateTime)
