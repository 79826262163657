import React, { FormEventHandler, useCallback, useEffect, useRef } from 'react'
import TextInput from 'components/Common/Form/Input/TextInput'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import TextButton from 'components/Luxkit/Button/TextButton'
import ReCAPTCHA from 'react-google-recaptcha'
import config from 'constants/config'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LogoIcon from 'components/App/Header/Logo/LogoIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import Divider from 'components/Luxkit/Divider'
import LegalText from 'components/Account/AccountAccess/AccountAccessLegalText'
import SocialButtons from 'components/Account/AccountAccess/AccountAccessSocialButtons'
import { useMutation } from '@tanstack/react-query'
import { checkUserExists } from 'api/auth'
import handleReCAPTCHA from 'businessTraveller/utils/handleReCAPTCHA'
import { CHECK_USER_EXISTS } from 'actions/apiActionConstants'
import { API_CALL_SUCCESS } from 'actions/actionConstants'
import requestCheckEmailAssociation from 'businessTraveller/api/requestCheckEmailAssociation'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import noop from 'lib/function/noop'
import AccountAccessTrustIndicatorsMapper from 'components/Account/AccountAccess/AccountAccessTrustIndicatorsMapper'

interface LoginFormData {
  email: string
}

interface MappedStateProps {
  authError?: App.ApiCallFailure
}

interface Props {
  onModeChange?: (mode: App.UiAccountModalMode) => void;
  onSubmit?: (userId: string) => void;
}

function BusinessTravellerLoginForm(props: Props & MappedStateProps) {
  const {
    authError,
    onModeChange = noop,
    onSubmit = noop,
  } = props

  const appDispatch = useAppDispatch()

  const formRef = useRef<HTMLFormElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const emailCheckMutation = useMutation <true, string, LoginFormData>({
    mutationFn: async(variables) => {
      const recaptchaResponse = await handleReCAPTCHA(recaptchaRef.current)
      try {
        const emailCheckResponse = await checkUserExists({ email: variables.email }, recaptchaResponse)
        if (!emailCheckResponse.isExist) {
          // in the context of business traveller login
          // a non-existing email is considered an error state
          // hence the rejected promise
          return Promise.reject('This email is not registered.')
        }

        // this function can only throw an error or return true
        await requestCheckEmailAssociation(variables.email)

        return true
      } catch (error) {
        return Promise.reject((error as App.ApiCallFailure).message || 'There was a problem checking this email!')
      }
    },
    onSuccess: (data, variables) => {
      // assuming the req promise has been rejected if the email doesn't exist
      appDispatch({
        type: API_CALL_SUCCESS,
        api: CHECK_USER_EXISTS,
        data: { isExist: data },
        email: variables.email,
        key: variables.email,
      })
      onSubmit(variables.email)
      onModeChange('loginPassword')
    },
  })

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault()
    const formData = formToObject<LoginFormData>(event.currentTarget)
    emailCheckMutation.mutate(formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSocialMediaLoginEnabled = !!(config.GOOGLE_APP_ID)

  useEffect(() => {
    emailCheckMutation.reset()
    formRef.current?.reset()
    emailInputRef.current?.focus()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <ModalHeader title="" />
    <ModalBody>
      <ModalContent>
        <form
          ref={formRef}
          name="businessTravellerLoginForm"
          data-testid="business-traveller-login-form"
          onSubmit={handleSubmission}
        >
          <Group direction="vertical" gap={12}>
            <Group direction="vertical" gap={24}>
              {authError?.message && <MessageBanner
                kind="critical"
                description={authError.message}
              />}
              {emailCheckMutation.error && <MessageBanner
                kind="critical"
                description={emailCheckMutation.error}
              />}

              <Group direction="horizontal" horizontalAlign="center"><LogoIcon /></Group>

              <Group direction="vertical" gap={4}>
                <Heading variant="heading4" align="center">
                  Travelling for work has never been easier
                </Heading>
                <BodyText variant="medium" align="center">
                  Manage your business bookings and expenses – all in one place.
                </BodyText>
              </Group>

              {config.LOGIN_TRUST_INDICATORS && <AccountAccessTrustIndicatorsMapper />}

              <Group direction="vertical" gap={12}>
                <TextInput
                  label="Email address"
                  required
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Email"
                  requiredErrorMessage="Email is required"
                  ref={emailInputRef}
                />
                <TextButton
                  kind="primary"
                  size="large"
                  data-testid="to-login-continue"
                  type="submit"
                  fit="flex"
                >
                  Continue
                </TextButton>
              </Group>

              {isSocialMediaLoginEnabled && <>
                <Group direction="horizontal" gap={12} verticalAlign="center">
                  <Divider kind="primary" />
                  <BodyText variant="large">or</BodyText>
                  <Divider kind="primary" />
                </Group>

                <SocialButtons mode="login" />
              </>}
            </Group>
            <LegalText />
          </Group>
          {config.RECAPTCHA_KEY && <ReCAPTCHA size="invisible" sitekey={config.RECAPTCHA_KEY} ref={recaptchaRef} />}
        </form>
      </ModalContent>
    </ModalBody>
  </>
}

export default connect<MappedStateProps, undefined, Props, App.State>((state) => {
  return {
    authError: state.auth.error,
  }
})(BusinessTravellerLoginForm)
