import clsx from 'clsx'
import moment, { Moment } from 'moment'
import { rem } from 'polished'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'

import CommonDateRangePicker from 'components/Common/Calendar/DateRangePicker2'
import Clickable from 'components/Common/Clickable'
import Tooltip from 'components/Luxkit/Tooltip'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { pluralizeToString } from 'lib/string/pluralize'
import Caption from 'components/Luxkit/Typography/Caption'
import TripContext from 'tripPlanner/contexts/TripContext'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { mediaHoverable } from 'lib/theme/mediaQueries'

export const Day = styled(Clickable)`
  min-height: ${rem(56)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: background-color 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:disabled {
    color: ${(props) => props.theme.palette.neutral.default.six};

    background-image: linear-gradient(
      to top right,
      white 50%,
      ${(props) => props.theme.palette.neutral.default.six},
      white 52%
    );
  }

  &.selected {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
  }

  &.inRange {
    background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
  }

  &.outsideTripDates:not(.selected, .inRange) {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  ${mediaHoverable} {
    &:hover {
      filter: brightness(0.95);
    }
  }
  &:focus {
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, -2px);
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid var(--palette-neutral-default-four));
  }
`

interface Props {
  startDate?: Moment
  endDate?: Moment
  initDate?: Moment
  minDate?: Moment
  maxDate?: Moment
  onDatesChange: (dates: {
    startDate: moment.Moment;
    endDate?: moment.Moment;
  }) => void
  allowSameDay: boolean
  showTripDates?: boolean
  startLabel?: string
  endLabel?: string
  type?: 'single' | 'dual'
  withNightsTooltip?: boolean
}

function DateRangePicker({
  startDate,
  endDate,
  initDate,
  onDatesChange,
  allowSameDay,
  minDate,
  maxDate,
  showTripDates,
  startLabel,
  endLabel,
  type,
  withNightsTooltip,
}: Props) {
  const { startDate: tripStartDate, endDate: tripEndDate } = useContext(TripContext) ?? {}

  const dayRender = useCallback<Exclude<React.ComponentProps<typeof CommonDateRangePicker>['dayRender'], undefined>>((day, { onDayClick }) => {
    const dayMoment = moment(day)
    const inRange = startDate && endDate && dayMoment.isBetween(startDate, endDate, 'day', '()')
    const isStart = startDate && dayMoment.isSame(startDate, 'day')
    const isEnd = endDate && dayMoment.isSame(endDate, 'day')
    const outsideTripDates = tripStartDate && tripEndDate && !dayMoment.isBetween(tripStartDate, tripEndDate, 'day', '[]')

    const daysAfterStartDate = startDate && dayMoment.diff(startDate, 'days')
    const showTooltipForThisDay = withNightsTooltip && !!daysAfterStartDate && daysAfterStartDate > 0 && !endDate

    let label: React.ReactNode | undefined
    let labelColour: 'highlight-secondary' | undefined
    if (isStart) {
      label = startLabel ?? <>&nbsp;</>
    } else if (isEnd) {
      label = endLabel ?? <>&nbsp;</>
    } else if (showTripDates && tripStartDate && dayMoment.isSame(tripStartDate, 'day')) {
      label = 'Trip starts'
      labelColour = 'highlight-secondary'
    } else if (showTripDates && tripEndDate && dayMoment.isSame(tripEndDate, 'day')) {
      label = 'Trip ends'
      labelColour = 'highlight-secondary'
    } else {
      label = <>&nbsp;</>
    }

    const dayCell = <Day
      key={day.getTime()}
      className={clsx({
        selected: isStart || isEnd,
        inRange,
        outsideTripDates: outsideTripDates && showTripDates,
      })}
      onClick={() => {
        if (isStart && !endDate && allowSameDay) {
          onDatesChange({ startDate: dayMoment, endDate: dayMoment })
        } else {
          onDayClick?.(day)
        }
      }}
      data-testid={`day-${dayMoment.format(ISO_DATE_FORMAT)}`}
    >
      <BodyText variant="medium" weight="bold" data-date={dayMoment.format(ISO_DATE_FORMAT)}>{dayMoment.date()}</BodyText>
      <Caption variant="medium" lineClamp={2} wrap="word-wrap" colour={labelColour}>{label}</Caption>
    </Day>

    if (showTooltipForThisDay) {
      return (
        <Tooltip
          key={`${day.getMonth()}-${day.getDate()}`}
          description={pluralizeToString('night', daysAfterStartDate)}
        >
          {dayCell}
        </Tooltip>
      )
    } else {
      return dayCell
    }
  }, [allowSameDay, endDate, endLabel, onDatesChange, showTripDates, startDate, startLabel, tripEndDate, tripStartDate, withNightsTooltip])

  const defaultDate = (!initDate && !startDate) ? tripStartDate : undefined

  return (
    <CommonDateRangePicker
      minDate={minDate}
      maxDate={maxDate}
      onDatesChange={onDatesChange}
      startDate={startDate}
      endDate={endDate}
      startLabel={startLabel}
      endLabel={endLabel}
      initDate={initDate ?? defaultDate}
      type={type}
      dayRender={dayRender}
    />
  )
}

export default DateRangePicker
