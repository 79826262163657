import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

const SaveBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(54)};
  height: ${rem(55)};
  background-color: ${({ theme }) => theme.palette.highlight.primary.normalBackground}; /* Adjust color as needed */
  border-radius: ${rem(2)};
  padding: ${rem(8)};
  color: ${props => props.theme.palette.neutral.default.one};
  text-align: center;
`

const DiscountText = styled.div`
  font-size: 9px;
  line-height: 8px;
  font-weight: ${(props) => props.theme.font.primary.weight.regular};
`

const DiscountValue = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${(props) => props.theme.font.primary.weight.superBold};
`

interface Props {
  discountToDisplay: number;
}

function SaveBannerLabel(props: Props) {
  const {
    discountToDisplay,
  } = props

  return (
    <SaveBanner>
      <div>
        <DiscountText>UP TO</DiscountText>
        <DiscountValue>{discountToDisplay}%</DiscountValue>
        <DiscountText>OFF</DiscountText>
      </div>
    </SaveBanner>
  )
}

export default SaveBannerLabel
