import React, { PropsWithChildren } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import InputText from 'components/Luxkit/Typography/InputText'
import zIndex from 'styles/tools/z-index'

const Wrapper = styled.span`
  position: relative;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  border-radius: ${props => props.theme.borderRadius.S};
  box-shadow: none;
  transition: border-color .2s, color 0.2s, background-color 0.2s;
  display: block;
  margin-bottom: ${rem(20)};
  color: ${props => props.theme.palette.neutral.default.one};

  &:not(.error):not(.disabled) {
    &:focus-within {
      border-color: ${props => props.theme.palette.neutral.default.two};
    }
  }

  &.focus-within {
    border-color: ${props => props.theme.palette.neutral.default.two};
    z-index: ${zIndex.min}
  }

  &.no-validation-spacing {
    margin-bottom: 0;
  }

  &:not(.disabled) {
    &.error {
      border-color: ${props => props.theme.palette.messaging.critical.normalForeground};
    }
  }

  &.disabled {
    color: ${props => props.theme.palette.neutral.default.four};
    background-color: ${props => props.theme.palette.neutral.default.six};
    cursor: not-allowed;
  }
`

const ValidationMessage = styled(InputText)`
  position: absolute;
  bottom: -4px;
  left: 0;
  transform: translateY(100%);
  pointer-events: none;
  transition: opacity 0.2s;
  opacity: 0;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  &.right {
    left: auto;
    right: 0;
  }
`

const IconWrapper = styled.div`
  display: flex;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  &.left {
    left: ${rem(12)};
  }

  &.right {
    right: ${rem(12)};
  }
`

interface Props extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  error?: string;
  disabled?: boolean;
  noValidationMessage?: boolean;
  noValidationSpacing?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  helpText?: React.ReactNode;
  helpTextPos?: 'left' | 'right';
}

const InputWrap = React.forwardRef<HTMLSpanElement | null, PropsWithChildren<Props>>((props, ref) => {
  const {
    className,
    children,
    error,
    disabled,
    noValidationMessage,
    noValidationSpacing,
    startIcon,
    helpText,
    helpTextPos = 'left',
    endIcon,
    ...otherProps
  } = props

  const helpTextTitle = typeof helpText === 'string' ? helpText : undefined
  return (
    <Wrapper
      {...otherProps}
      ref={ref}
      className={cn(
        className,
        {
          'no-validation-spacing': noValidationSpacing,
          error: !!error,
          disabled,
        })}
    >
      {startIcon && <IconWrapper className="left">{startIcon}</IconWrapper>}
      {children}
      {endIcon && <IconWrapper className="right">{endIcon}</IconWrapper>}
      {!noValidationMessage && !disabled && <ValidationMessage
        className={cn({
          visible: !!(error || helpText),
          right: helpTextPos === 'right',
        })}
        title={error || helpTextTitle}
        variant="caption"
        colour={error ? 'critical' : 'neutral-three'}
        lineClamp={2}
      >
        {error || helpText}
      </ValidationMessage>}
    </Wrapper>
  )
})

InputWrap.displayName = 'InputWrap'

export default InputWrap
