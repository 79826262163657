import { ADMIN_ROLE, EMPLOYEE_ROLE } from 'constants/auth'
import config from 'constants/config'
import { createSelector } from 'reselect'
import showGeoRdirect from 'lib/geo/geoRedirect'
import { SvgIconColour } from 'components/Luxkit/SvgIcon'

export const shouldShowGeoRedirect = createSelector(
  (state: App.State) => state.auth.account.countryCode,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.system.headlessMode,
  (accountRegion, currentRegion, headlessMode) => {
    return !headlessMode &&
      config.currencyRedirectEnabled &&
      accountRegion &&
      currentRegion !== accountRegion &&
      !!config.regions.find(region => region.code === accountRegion) &&
      showGeoRdirect()
  },
)

// Suspect this is faster without reselect
// We can convert this to reselect if this ever gets more complex
export const getCurrentUserId = (state: App.State) => state.auth.account.memberId

export const selectLoggedIn = (state: App.State) => !!getCurrentUserId(state)

export const getUserPreferences = createSelector(
  (state: App.State) => state.userTravelPreferences.destinations,
  (state: App.State) => state.userTravelPreferences.holidayTypes,
  (state: App.State) => state.userTravelPreferences.externalBrands,
  (state: App.State) => state.userTravelPreferences.occupancy,
  (destinations, holidayTypes, externalBrands, occupancy) => {
    return {
      loading: destinations.initial || destinations.fetching || holidayTypes.initial || holidayTypes.fetching || externalBrands.initial || externalBrands.fetching || occupancy?.initial || occupancy?.fetching,
      data: {
        destinations: destinations.data,
        holidayTypes: holidayTypes.data,
        externalBrands: externalBrands.data,
        occupancy: occupancy?.data,
      },
    }
  },
)

export const hasUserPreferences = createSelector(
  (state: App.State) => getUserPreferences(state),
  (preferences) => {
    if (preferences.loading) {
      return false
    }

    return !!preferences.data.destinations?.length ||
    !!preferences.data.externalBrands?.length ||
    !!preferences.data.occupancy?.groupSizes ||
    !!preferences.data.holidayTypes?.length
  },
)

export const hasUserPreferencesNotification = createSelector(
  (state: App.State) => hasUserPreferences(state),
  (state: App.State) => selectLoggedIn(state),
  (hasPreferences, isLoggedIn) => !hasPreferences && isLoggedIn && config.USER_PREFERENCES_ENABLED,
)

export const hasPassedWalledGarden = createSelector(
  (state: App.State) => selectLoggedIn(state),
  (state: App.State) => state.utm.bp,
  (state: App.State) => state.system.previewMode,
  (isLoggedIn, bypassMode, previewMode) => isLoggedIn || previewMode || bypassMode === '2',
)

export const hasPassedHidePricing = createSelector(
  (state: App.State) => selectLoggedIn(state),
  (state: App.State) => state.system.previewMode,
  (isLoggedIn, previewMode) => isLoggedIn || previewMode,
)

export const hasIncompleteInformation = createSelector(
  (state: App.State) => selectLoggedIn(state),
  (state: App.State) => !config.ENABLE_EMAIL_OPT_IN || !!state.auth.account.emailVerified,
  (state: App.State) => !!state.auth.account.givenName && !!state.auth.account.surname,
  (isLoggedIn, emailVerified, nameCompleted) => isLoggedIn && !(emailVerified && nameCompleted),
)

export const hasAccountNotification = createSelector(
  (state: App.State) => hasIncompleteInformation(state),
  (state: App.State) => hasUserPreferencesNotification(state),
  (state: App.State) => selectLoggedIn(state),
  (hasIncompleteInformation, hasUserPreferencesNotification, isLoggedIn) => (hasIncompleteInformation || hasUserPreferencesNotification) && isLoggedIn,
)

export const getNotificationType = createSelector(
  (state: App.State) => hasIncompleteInformation(state),
  (state: App.State) => hasUserPreferencesNotification(state),
  (hasIncompleteInformation, hasUserPreferencesNotification): SvgIconColour => {
    // Temporarily remove this warning while enx work on the email verification fix
    // if (hasIncompleteInformation) {
    //   return 'warning'
    // }

    if (hasUserPreferencesNotification) {
      return 'highlight-primary-normal'
    }

    return 'warning'
  },
)

export const isLEStaff = (state: App.State) => state.auth.account.roles.includes(EMPLOYEE_ROLE)
export const isLEAdmin = (state: App.State) => state.auth.account.roles.includes(ADMIN_ROLE)
export const isLEStaffOrAdmin = (state: App.State) => isLEAdmin(state) || isLEStaff(state)

export const getFirstName = createSelector(
  (state: App.State) => selectLoggedIn(state),
  (state: App.State) => state.auth.account.givenName,
  (isLoggedIn, givenName) => isLoggedIn ? givenName : '',
)

export const isMyProfileComplete = createSelector(
  (state: App.State) => state.auth.account.givenName,
  (state: App.State) => state.auth.account.surname,
  (state: App.State) => state.auth.account.email,
  (state: App.State) => state.auth.account.phone,
  (state: App.State) => state.auth.account.postcode,
  (state: App.State) => state.auth.account.countryCode,
  (state: App.State) => state.auth.account.dob,
  (
    givenName,
    surname,
    email,
    phone,
    postcode,
    countryCode,
    dob,
  ) => {
    return !!(givenName && surname && email && phone && postcode && countryCode && dob)
  },
)
