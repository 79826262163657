import React from 'react'

import GlobalSearchContainerPane, { GlobalSearchContainerPaneContent } from './GlobalSearchContainerPane'
import { SEARCH_VERTICALS } from 'constants/search'

import GlobalSearchTabContents from './GlobalSearchTabContents'
import GlobalSearchTabs from './GlobalSearchTabs'
import config from 'constants/config'

interface Props {
  tabs: Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTab>
  selectedTab: SEARCH_VERTICALS
  onTabSelect: (tab: SEARCH_VERTICALS) => void
}

function GlobalSearchTabsPane(props: Props) {
  const { tabs, selectedTab = SEARCH_VERTICALS.HOTELS, onTabSelect } = props

  return <GlobalSearchContainerPane shadow displayMobile={!config.USE_INSPIRATIONAL_SEARCH}>
    {tabs.size >= 2 && (
      <GlobalSearchTabs
        selectedTabKey={selectedTab}
        tabs={tabs}
        onTabSelect={onTabSelect}
      />
    )}
    <GlobalSearchContainerPaneContent>
      <GlobalSearchTabContents tabs={tabs} selectedTabKey={selectedTab} />
    </GlobalSearchContainerPaneContent>
  </GlobalSearchContainerPane>
}

export default GlobalSearchTabsPane
