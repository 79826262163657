import request from 'api/requestUtils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { formatDynamicAmountWithDollarType } from 'lib/format/formatCurrencyIntl'
import { isOrderItem } from 'lib/order/orderUtils'
import moment from 'moment'

export async function getGiftCardPDF(code: string) {
  const giftCard = (await request.get<App.ApiResponse<any>>(`/api/gift-card/code/${code}`, { credentials: 'include' })).result
  // This API call doesn't actually return an ApiResponse object, but the PDF as a blob
  return request.post<Blob, any>(
    '/api/pdf/gift-cards',
    giftCard,
    { credentials: 'include' },
  )
}

export function getTaxInvoicePDF(taxInvoiceDetails: App.OrderTaxInvoiceDetails): Promise<Blob> {
  return request.post('/api/pdf/tax-invoice', taxInvoiceDetails, { credentials: 'include' })
}

export function getAgentHubTaxInvoicePDF(taxInvoiceDetails: App.AgentHubOrderTaxInvoiceDetails): Promise<Blob> {
  return request.post('/api/pdf/agent-hub-tax-invoice', taxInvoiceDetails, { credentials: 'include' })
}

export async function getOrderItemInsuranceLetterPDF(
  order: App.Order,
  item: App.OrderItem | App.OrderBedbankItem,
  offer?: App.HotelOffer,
): Promise<Blob> {
  const refundRequest = order.refundRequests[item.id]
  const formattedTotal = formatDynamicAmountWithDollarType(item.total, order.currencyCode, order.regionCode)

  const letterDetails: any = {
    customerFullName: order.customerFullName,
    duration: item.duration?.toString() ?? '',
    purchaseDate: order.createdAt,
    propertyName: item.offer.name,
    sellPrice: formattedTotal,
    refundRequestDate: refundRequest?.createdAt ?? moment().format(ISO_DATE_FORMAT),
  }

  if (isOrderItem(item)) {
    letterDetails.checkIn = item.reservation?.startDate ?? ''
    letterDetails.checkOut = item.reservation?.endDate ?? ''
    letterDetails.vendorName = offer?.vendorName ?? item.offer.location ?? ''
  } else {
    letterDetails.checkIn = item.checkIn.format(ISO_DATE_FORMAT)
    letterDetails.checkOut = item.checkOut.format(ISO_DATE_FORMAT)
    letterDetails.vendorName = item.offer.property.location
  }

  return request.post('/api/pdf/cancellation-insurance-letter', letterDetails, { credentials: 'include' })
}
