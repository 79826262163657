import Modal from 'components/Luxkit/Modal/Modal'
import React, { useCallback, useMemo, useRef } from 'react'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { semiFormToObject } from 'lib/forms/formUtils'
import useQueryParams from 'hooks/useQueryParams'
import Group from 'components/utils/Group'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import CruisePromotionFilter
  from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruisePromotionFilter'
import {
  CruiseSearchPromotionChipProps,
} from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchPromotionChip'
import config from 'constants/config'
import { LUXURY_ESCAPES } from 'constants/brands'
import CruiseLuxPlusFilter
  from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruiseLuxPlusFilter'

const isLuxuryEscapes = config.BRAND === LUXURY_ESCAPES

function ModalCruiseSearchPromotionChip(props: CruiseSearchPromotionChipProps) {
  const {
    facets,
    filterForm,
    setFilterForm,
    fetching,
    filters,
    drawerMode,
  } = props
  const modalElement = useModalElementContext<number>()
  const containerRef = useRef<HTMLDivElement>(null)
  const queryParams = useQueryParams()

  const countSelected = useMemo(() => {
    return (queryParams.get('isLuxExclusivePromotion') && isLuxuryEscapes ? 1 : 0) + (queryParams.get('isSpecialOffers') ? 1 : 0)
  }, [queryParams])

  const closeModal = useCallback(() => {
    modalElement.resolve(countSelected)
  }, [countSelected, modalElement])

  const updateForm = useCallback(() => {
    const formValues = semiFormToObject<Record<string, any>>(containerRef.current!)
    if (formValues && Object.keys(formValues).length > 0) {
      setFilterForm({
        ...filterForm,
        isLuxExclusivePromotion: formValues.isLuxExclusivePromotion,
        isSpecialOffers: formValues.isSpecialOffers,
        isLuxPlus: formValues.isLuxPlus,
      })
    } else {
      setFilterForm({
        ...filterForm,
        ...{
          isLuxExclusivePromotion: undefined,
          isSpecialOffers: undefined,
          isLuxPlus: undefined,
        },
      })
    }
  }, [filterForm, setFilterForm])

  const resetFilter = useCallback(() => {
    setFilterForm({
      ...filterForm,
      ...{
        isLuxExclusivePromotion: undefined,
        isSpecialOffers: undefined,
      },
    })
    modalElement.resolve(0)
  }, [filterForm, setFilterForm, modalElement])

  return <Modal
    mode={drawerMode ? 'drawer' : 'default'}
    ref={containerRef}
    title="Promotions"
    primaryActionText="Apply"
    onPrimaryActionClick={closeModal}
    secondaryActionText={countSelected > 0 ? `Reset all (${countSelected})` : 'Reset all'}
    onSecondaryActionClick={resetFilter}
  >
    {fetching && <Group direction="vertical" gap={8}>
      <CheckboxInput size="large"><TextLoadingBox width="100%"/></CheckboxInput>
      <CheckboxInput size="large"><TextLoadingBox width="100%"/></CheckboxInput>
      <CheckboxInput size="large"><TextLoadingBox width="100%"/></CheckboxInput>
    </Group>}
    {!fetching && <>
      <CruiseLuxPlusFilter
        isLuxPlus={filters.isLuxPlus}
        onChange={updateForm}
        facets={facets}
      />
      <CruisePromotionFilter
        facets={facets}
        isLuxExclusivePromotion={filters.isLuxExclusivePromotion}
        isSpecialOffers={filters.isSpecialOffers}
        onChange={updateForm}
      />
    </>}
  </Modal>
}

export default ModalCruiseSearchPromotionChip
