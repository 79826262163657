import { createSelector } from 'reselect'
import getExperienceItemsView from 'checkout/selectors/view/getExperienceItemsView'
import { getTravellerFormSchemaRequest, isTravellerFormNeeded } from 'checkout/selectors/request/travellerSchema'
import { isCruiseItem } from 'lib/checkout/checkoutUtils'
import { getAccommodationItems } from './view/accommodation'
import { isStandaloneGiftCard } from 'checkout/selectors/view/giftCard'
import { getVillaItems } from './view/villa'
import { getBookingCabinPricingData } from 'checkout/selectors/cruiseSelectors'
import getCheckoutTravellerSchema from 'checkout/selectors/getCheckoutTravellerSchema'
import getIsCheckoutTravellerSchemaFetching from './getCheckoutTravellerSchemaFetching'

export const shouldShowTravellerForm = createSelector(
  (state: App.State) => state.checkout.isCartRestored,
  (state: App.State) => isTravellerFormNeeded(state),
  (state: App.State) => getTravellerFormSchemaRequest(state),
  (isCartRestored, isTravellerFormNeeded, formRequest): boolean => {
    return isCartRestored && isTravellerFormNeeded && formRequest.items.length > 0
  },
)

export const shouldShowArrivalDetailsForm = createSelector(
  (state: App.State) => getVillaItems(state),
  (villaItems): boolean => {
    return !!villaItems.length
  },
)

/**
 * @remarks
 * Edge case for cruise items in the checkout.
 * Due to a technical limitation we cannot correctly display deposit/payment schedule options
 * until 'cabin preferences' are selected
 */
export const shouldShowPaymentForm = createSelector(
  (state: App.State) => isTravellerFormNeeded(state),
  (state: App.State) => shouldShowTravellerForm(state),
  (state: App.State) => isStandaloneGiftCard(state),
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => getBookingCabinPricingData(state),
  (isTravellerFormNeeded, showForm, isGiftCard, items, cabinPricingData): boolean => {
    const cruiseItem = items.find(isCruiseItem)
    return cruiseItem ? !!cabinPricingData.hasCabinPricing : (!isTravellerFormNeeded || showForm || isGiftCard)
  },
)

export const hasUnpurchasableItems = createSelector(
  (state: App.State) => getExperienceItemsView(state),
  (experienceItemsView) => {
    return {
      hasRequiredData: experienceItemsView.hasRequiredData,
      data: experienceItemsView.hasRequiredData && experienceItemsView.data.some(item => (
        item.ticketViews.some(ticket => ticket.unavailable)
      )),
    }
  },
)

const getRequiredNumberOfForms = createSelector(
  (state: App.State) => getCheckoutTravellerSchema(state),
  (schemaData): number => schemaData?.accommodationFlightSchema ?
    Object.keys(schemaData.accommodationFlightSchema.properties).length :
    0,
)

export const getCheckoutSingleStep = createSelector(
  (state: App.State) => state.checkout.isCartRestored,
  (state: App.State) => getCheckoutTravellerSchema(state),
  (state: App.State) => getIsCheckoutTravellerSchemaFetching(state),
  (state: App.State) => state.checkout.form.travellerForms.length,
  (state: App.State) => state.checkout.form.editIdx,
  (state: App.State) => state.checkout.cart.isGift,
  (state: App.State) => shouldShowTravellerForm(state),
  (state: App.State) => hasUnpurchasableItems(state),
  (state: App.State) => getRequiredNumberOfForms(state),
  (isCartRestored, schemaData, isFetchingSchema, completedFormCount, editIdx,
    isGift, shouldShowForm, hasUnpurchasableItems, requiredTravellerCount): App.Checkout.Step => {
    const isFormReady = (isCartRestored && !isFetchingSchema && (schemaData || !shouldShowForm))

    const result = !!schemaData &&
      completedFormCount === requiredTravellerCount &&
      (editIdx === null || editIdx === completedFormCount) &&
      !hasUnpurchasableItems.data

    return {
      id: 'purchase',
      name: isGift ? 'Review your gift' : 'Review & Pay',
      shortName: 'purchase',
      isComplete: {
        hasRequiredData: isFormReady && hasUnpurchasableItems.hasRequiredData,
        data: result,
      },
    }
  },
)

// TODO: https://aussiecommerce.atlassian.net/browse/CRO-3597 Remove steps concept for new checkout as it's single step
export const getCheckoutSteps = createSelector(
  (state: App.State) => getCheckoutSingleStep(state),
  (checkoutSingleStep): Array<App.Checkout.Step> => {
    return [checkoutSingleStep]
  },
)
