import { definitions } from '@luxuryescapes/contract-svc-promo'
import { ANYWHERE_PLACE_ID } from 'constants/search'
import { renderMarkdown } from 'lib/string/markdownUtils'

function mapCompetitionGrandPrize(grandPrize: definitions['CompetitionConfigV2']['grandPrizeDetails']): App.MarketingGrandPrizeDetail {
  return {
    offerId: grandPrize.offerId,
    title: grandPrize.title,
    link: grandPrize.link,
    includes: grandPrize.includes.map(include => include) ?? [],
    offer: {
      description: grandPrize.offer.description,
      name: grandPrize.offer.name,
      subtitle: grandPrize.offer.subtitle,
      image: {
        id: grandPrize.offer.imageId,
      },
    },
  }
}

function mapCompetitionHeadData(headData) {
  return {
    thumbnailImage: { id: headData.thumbnailImageId },
  }
}

function mapCompetitionPayoutConfig(payout: definitions['PayoutConfigV2']): definitions['PayoutConfigV2'] {
  return {
    id: payout.id,
    weightedChance: payout.weightedChance,
    value: payout.value,
  }
}

function mapMinorPrizeDetails(prizeDetail: any): App.MarketingCompetitionMinorPrizeDetails {
  return {
    codeName: prizeDetail.codeName,
    promoId: prizeDetail.promoIde,
    promoType: prizeDetail.promoType,
    expiryDate: prizeDetail.expiryDate,
    discountValue: prizeDetail.discountValue,
    minSpend: prizeDetail.minSpend,
    maxDiscount: prizeDetail.maxDisscount,
  }
}

export function mapCompetitionConfig(data: definitions['CompetitionConfigV2']): App.MarketingCompetitionConfig {
  return {
    allowedRegions: data.allowedRegions.map(region => region) ?? [],
    episodeId: data.episodeId ?? undefined,
    prizeType: data.prizeType,
    drawFrequency: data.drawFrequency,
    startDateUtc: data.startDateUtc,
    endDateUtc: data.endDateUtc,
    drawDateUtc: data.drawDateUtc,
    heroImage: { id: data.heroImageId },
    heroTextIntro: data.heroTextIntro,
    heroTextHighlight: data.heroTextHighlight,
    promoLink: data.promo_link,
    faqSlug: data.faqSlug,
    payoutConfig: data.payoutConfig.map(mapCompetitionPayoutConfig) ?? [],
    images: data.images.map(image => ({
      id: image.imageId,
      title: image.imageTitle,
    })),
    grandPrizeDetails: mapCompetitionGrandPrize(data.grandPrizeDetails),
    prizeContactDateUtc: data.prizeContactDateUtc,
    header: data.header,
    title: data.title,
    bodyText: data.bodyText,
    footerText: data.footerText,
    breadcrumb: data.breadcrumb,
    headData: mapCompetitionHeadData(data.headData),
  }
}

export function mapCompetitionMinorPrize(data: any): App.MarketingCompetitionMinorPrize {
  return {
    prizeType: data.prizeType,
    drawFrequency: data.drawFrequency,
    prizeValue: data.prizeValue,
    prizeDetails: data.prizeDetails ? mapMinorPrizeDetails(data.prizeDetails) : undefined,
  }
}

export function mapCompetitionUserStatus(data: any): App.MarketingCompetitionUserStatus {
  if (Object.keys(data.prizeDetails).length === 0 || !data.alreadyHasReward) {
    return {
      alreadyHasReward: data.alreadyHasReward,
      prizeDetails: null,
    }
  }
  if (data.prizeDetails.competitionType === 'promo') {
    return {
      alreadyHasReward: data.alreadyHasReward,
      ...mapCompetitionStatusPromoPrize(data.prizeDetails),
    }
  }
  return {
    alreadyHasReward: data.alreadyHasReward,
    competitionType: 'raffle',
    prizeValue: data.prizeDetails.prizeValue,
  }
}

export function mapContentfulWebPageData(data: any): App.ContentfulWebPageData {
  return {
    title: data.title,
    destination: data.slug,
    seoTitle: data.seoTitle,
    seoDescription: data.seoDescription,
    components: data.components.map(mapContentfulWebPageComponent),
  }
}

export function mapContentfulNavBar(data: any): Array<App.ContentfulNavBarItem> {
  return data.navBar ? data.navBar.map(item => ({
    title: item.fields.title,
    subtitle: item.fields.subtitle,
    to: item.fields.linkTo,
  })) : []
}

export function mapContentfulWebPageComponent(data: any): App.ContentfulWebPageComponent {
  switch (data.contentTypeId) {
    case 'componentHeroImage':
      return {
        title: data.title,
        subtitle: data.subtitle,
        image: { id: data.imageId },
        contentTypeId: data.contentTypeId,
        toggle: data.toggle,
        navBar: mapContentfulNavBar(data),
      }
    case 'componentHeroCarousel':
      return {
        title: data.title,
        heroImageTitle: data.heroImageTitle,
        subtitle: data.heroImageSubtitle,
        image: { id: data.heroImageId },
        contentTypeId: data.contentTypeId,
        filters: {
          offerTypes: data.offerTypes ? data.offerTypes.map(offerType => offerType) : [],
          placeIds: data.placeIDs && data.placeIDs.length > 0 ? data.placeIDs.map(placeId => placeId) : [ANYWHERE_PLACE_ID],
          campaigns: data.campaigns ? data.campaigns.map(campaign => campaign) : [],
          limit: data.limit,
        },
      }
    case 'componentCardCarousel':
      return {
        title: data.title,
        subtitle: data.subtitle,
        cardItems: data.cardItems.map(mapContentfulWebPageCardItem),
        viewAllLink: data.viewAllLink,
        isLargeContentWrapper: data.isLargeContentWrapper,
        contentTypeId: data.contentTypeId,
      }
    case 'componentFlightDealsCarousal':
      return {
        title: data.title,
        category: data.subtitle,
        originAirportCodes: data.originAirportCodes ? data.originAirportCodes.map(originAirportCode => originAirportCode) : [],
        destinationAirportCode: data.destinationAirportCode,
        viewAllLink: data.viewAllLink,
        contentTypeId: data.contentTypeId,
      }
    case 'componentOfferListCarousel':
      return {
        title: data.title,
        filters: {
          offerTypes: data.offerTypes ? data.offerTypes.map(offerType => offerType) : [],
          campaigns: data.campaigns ? data.campaigns.map(campaign => campaign) : [],
          placeIds: data.placeIds && data.placeIds.length > 0 ? data.placeIds.map(placeId => placeId) : [ANYWHERE_PLACE_ID],
          limit: data.offersToShow,
        },
        viewAllLink: data.viewAllLink,
        contentTypeId: data.contentTypeId,
        lookingForSomethingElseOption: data.lookingForSomethingElseLink,
      }
    case 'componentSupport':
      return {
        contentTypeId: data.contentTypeId,
        title: data.title,
      }
    case 'componentValuePropositionBanner':
      return {
        contentTypeId: data.contentTypeId,
        title: data.title,
        items: data.items.map(mapComponentValuePropositionItems),
      }
    case 'componentExperienceOfferListCarousel':
      return {
        title: data.title,
        destination: data.destination,
        contentTypeId: data.contentTypeId,
        campaigns: data.campaigns,
      }
    case 'componentTileNavigation':
      return {
        title: data.title,
        tiles: data.tiles.map(tile => tile.fields),
        maxTilesVisible: data.maxTilesVisible,
        variant: data.variant,
        contentTypeId: data.contentTypeId,
      }
    case 'componentWings':
      return {
        title: data.slug,
        banners: data.placements.map(placement => ({
          name: placement.fields.name,
          bannerId: placement.fields.bannerId,
          imageId: placement.fields.desktopImageId,
          imageAlt: placement.fields.desktopImageId,
          position: 'left',
          href: placement.fields.linkTo,
          desktopImageId: placement.fields.desktopImageId,
          mobileImageId: placement.fields.mobileImageId,
        })),
        contentTypeId: data.contentTypeId,
      }
    case 'componentVideoBlock':
      return {
        caption: data.caption,
        title: data.title,
        subtitle: data.subtitle,
        bodyText: data.bodyText,
        contentAlign: data.contentAlign || 'start',
        contentPosition: data.contentPosition,
        videoId: data.videoId,
        contentTypeId: data.contentTypeId,
        image: { id: data.imageId },
        backgroundColor: data.background,
        buttonBlocks: data.buttonBlocks ? data.buttonBlocks.map(buttonBlock => ({
          title: buttonBlock.fields.title,
          linkTo: buttonBlock.fields.linkTo,
          kind: buttonBlock.fields.kind,
          size: buttonBlock.fields.size,
          fit: buttonBlock.fields.fit,
          productTheme: buttonBlock.fields.productTheme === 'loyalty' ? 'lux-plus' : buttonBlock.fields.productTheme,
        })) : [],
        buttonDirection: data.buttonDirection || 'horizontal',
      }
    case 'componentReviews':
      return {
        contentTypeId: data.contentTypeId,
        title: data.title,
      }
    case 'componentImageTileCarousel':
      return {
        contentTypeId: data.contentTypeId,
        title: data.title,
        tiles: data.tiles.map(mapContentfulWebPageImageTile),
      }
    case 'componentVerticalOfferList':
      return {
        contentTypeId: data.contentTypeId,
        title: data.title,
        showFilters: data.showFilters,
        campaigns: data.campaigns ? data.campaigns : [],
        offerTypes: data.offerTypes ? data.offerTypes : [],
        placeIds: data.placeIds ? data.placeIds : [],
        isInfiniteScroll: data.isInfiniteScroll,
      }
    case 'legalPages':
      return {
        contentTypeId: 'componentFaqs',
        content: data.pageContent.map(content => ({
          answer: content.fields.answer,
          question: content.fields.title,
        })),
        title: data.title,
      }
    case 'componentIframe':
      return {
        contentTypeId: 'componentIframe',
        title: data.title,
        url: data.url,
      }
    case 'componentLogoCarousel':
      return {
        contentTypeId: 'componentLogoCarousel',
        images: data.images.map(image => ({
          title: image.fields.title,
          id: image.fields.id,
        })),
        title: data.title,
        backgroundColor: data.backgroundColor || 'white',
        withOutline: data.withOutline,
      }
    case 'componentForm':
      return {
        contentTypeId: 'componentForm',
        title: data.title,
        subtitle: data.subtitle,
        buttonText: data.buttonText,
        successMessage: data.successMessage,
        backgroundColor: data.backgroundColor,
        slug: data.slug,
        components: data.components.map(component => ({
          type: component.fields.type,
          label: component.fields.label,
          placeholder: component.fields.placeholder,
          isRequired: component.fields.isRequired,
          options: component.fields.options,
        })),
        loginRequired: !!data.loginRequired,
        loginRequiredButtonText: data.loginRequiredButtonText,
        postSubmissionState: data.postSubmissionState?.fields ? mapContentfulWebPageComponent({ ...data.postSubmissionState.fields, contentTypeId: 'componentVideoBlock', title: renderMarkdown(data.postSubmissionState.fields.title) }) as App.ComponentVideoBlock : undefined,
      }
    default:
      return {
        title: 'Component not found',
        contentTypeId: 'componentNotFound',
      }
  }
}

function mapContentfulWebPageImageTile(data: any): App.ComponentImageTile {
  return {
    image: { id: data.fields.imageId },
    title: data.fields.title,
    subtitle: data.fields.subtitle,
    linkTo: data.fields.linkTo,
    linkText: data.fields.linkText,
  }
}

export function mapContentfulWebPageCardItem(data: any): App.ContentfulWebPageCardItem {
  switch (data.sys.contentType.sys.id) {
    case 'componentPopularRegionTile':
      return {
        title: data.fields.title,
        imageId: data.fields.imageId,
        description: data.fields.description,
        url: data.fields.linkTo,
        type: data.sys.contentType.sys.id,
      }
    case 'componentWhyWeLoveTile':
      return {
        title: data.fields.title,
        imageId: data.fields.imageId,
        description: data.fields.description,
        type: data.sys.contentType.sys.id,
      }
    case 'componentBlogCard':
      return {
        type: data.sys.contentType.sys.id,
        title: data.fields.title,
        imageId: data.fields.imageId,
        description: data.fields.description,
        slug: data.fields.slug,
        date: data.fields.date,
      }
  }
  return {
    title: data.fields.title,
    imageId: data.fields.imageId,
    description: data.fields.description,
    url: data.fields.linkTo,
    type: data.sys.contentType.sys.id,
    slug: data.fields.slug,
    date: data.fields.date,
  }
}

function mapCompetitionStatusPromoPrize(data): Omit<App.MarketingCompetitionUserStatusPromo, 'alreadyHasReward'> {
  return {
    competitionType: 'promo',
    codeName: data.codeName,
    promoId: data.promoId,
    promoType: data.promoType,
    expiryDate: data.expiryDate,
    discountValue: data.discountValue,
    minSpend: data.minSpend,
    maxDiscount: data.maxDiscount,
  }
}

function mapComponentValuePropositionItems(item:any) :App.ComponentValuePropositionBlock {
  return {
    title: item.fields.title,
    subtitle: item.fields.subtitle,
    icon: item.fields.icon,
    linkText: item.fields.linkText,
    linkTo: item.fields.linkTo,
  }
}
