import React, { useContext, useMemo } from 'react'

import FlightSearchWidgetMobile from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidgetMobile'
import ModalProvider from 'contexts/ModalProvider'
import { useAppSelector } from 'hooks/reduxHooks'
import { isMultiCityEnabled } from 'selectors/flightsSelectors'
import GeoContext from 'contexts/geoContext'
import { DISABLED_MULTI_CITY_REGION_CODES } from 'constants/flight'

function FlightsTabContent() {
  const showMultiCity = useAppSelector(isMultiCityEnabled)
  const { currentRegionCode } = useContext(GeoContext)

  const isMultiCityRegionEnabled = useMemo(() => {
    return !DISABLED_MULTI_CITY_REGION_CODES.includes(currentRegionCode)
  }, [currentRegionCode])

  const multiCityEnabled = showMultiCity && isMultiCityRegionEnabled

  return <ModalProvider>
    <FlightSearchWidgetMobile showMultiCity={multiCityEnabled} />
  </ModalProvider>
}

export default FlightsTabContent
