import React, { useCallback, useContext, useMemo, useRef } from 'react'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import { StandaloneFlightMenu } from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import ModalContext from 'contexts/ModalContext'
import { connect } from 'react-redux'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import BusinessTravellerSelectModal from './BusinessTravellerSelectModal'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'

interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
}

function BusinessTravellerSelectFlightsMobile(props: MappedStateProps) {
  const { travellerEmployees } = props

  const showModal = useContext(ModalContext)
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const { activeMenu } = state

  const setActiveMenu = useCallback((activeMenu?: StandaloneFlightMenu) => {
    dispatch({ type: FlightSearchWidgetActions.SET_ACTIVE_MENU, activeMenu })
  }, [dispatch])

  const setOccupants = useCallback((occupants: App.Occupants) => {
    dispatch({ type: FlightSearchWidgetActions.SET_OCCUPANTS, occupants })
  }, [dispatch])

  const handleTravellerSelect = useCallback((selectedTravellerEmployeeIds: Array<string>) => {
    if (selectedTravellerEmployeeIds.length > 0) {
      const occupants: App.Occupants = {
        adults: selectedTravellerEmployeeIds.length ?? 1,
        children: 0,
        infants: 0,
        childrenAge: [],
      }

      setOccupants(occupants)
    }
    setActiveMenu()
  }, [setActiveMenu, setOccupants])

  const handleSelectTravellerClick = useCallback(async() => {
    if (activeMenu !== StandaloneFlightMenu.occupants) {
      setActiveMenu(StandaloneFlightMenu.occupants)

      await showModal(<BusinessTravellerSelectModal onTravellerSelect={handleTravellerSelect} />)

      setActiveMenu()
    } else {
      setActiveMenu()
    }
  }, [activeMenu, handleTravellerSelect, setActiveMenu, showModal])

  const fieldValue = useMemo(() => {
    if (travellerEmployees?.length) {
      const firstTraveller = travellerEmployees[0]
      return `${firstTraveller.firstName} ${firstTraveller.lastName}`
    }

    return undefined
  }, [travellerEmployees])

  const localInputRef = useRef<HTMLButtonElement>(null)

  return (
    <SearchFormField
      label="Travellers"
      required
      requiredErrorMessage="Please select traveller"
      value={fieldValue}
      displayValue={fieldValue}
      placeholder="Select Traveller"
      onClick={handleSelectTravellerClick}
      ref={localInputRef}
    />
  )
}

export default connect<MappedStateProps, {}, {}, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
  }
})(BusinessTravellerSelectFlightsMobile)
