import TextButton from 'components/Luxkit/Button/TextButton'
import TileCancellationPolicy from 'components/SearchV2/SearchTileCancellationPolicy/TileCancellationPolicy'
import useOffer from 'hooks/Offers/useOffer'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import { useAppSelector } from 'hooks/reduxHooks'
import useMapSearchOfferUrl from 'hooks/Search/useMapSearchOfferUrl'
import { nonNullable } from 'lib/array/arrayUtils'
import { getVillaCapacityText } from 'lib/homesAndVillas/getVillaCapacityText'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import { buildSearchParamsKey, getMinimumDurationFromFlexibleNights, isSearchStreamingSupported } from 'lib/search/searchUtils'
import React, { useMemo } from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import SearchTile from '../SearchTile'
import SearchTileRowOverlineLocation from '../shared/SearchTileRowOverlineLocation'
import HotelSearchTilePriceStack from './HotelSearchTilePriceStack'
import VillaSearchTileFeatures from './VillaSearchTileFeatures'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import { generateLuxLoyaltyPointsCalculatorVillasOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'
import { useSearchPrices } from 'hooks/Search/useSearchPrices'
import { FLEXIBLE_DURATION_RANGE } from 'constants/search'

interface Props {
  offer: App.VillaOffer | App.VillaOfferSummary
  filters?: App.OfferListFilters
}

function VillaSearchTile({ offer, filters }: Props) {
  const metaData = useOfferMetaData(offer.id, filters)
  const checkIn = filters?.checkIn ?? metaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? metaData?.suggestedTravelDates?.checkOut
  const isSpecificSearch = !!(checkIn && checkOut && filters?.rooms)

  const searchStreamingEnabled = isSearchStreamingSupported(filters)

  const [, fetchingOffer] = useOffer<App.Offer>(offer.id, {
    requireSummaryOnly: !isSpecificSearch,
  })

  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])

  const bestMaybeAvailableRate = useAppSelector<App.OfferMaybeAvailableRate | undefined>((state) => state.offer.offerBestPrices[offer.id]?.[searchKey])
  const bestPriceError = useAppSelector<boolean>((state) => !!state.offer.offerPricesErrors[offer.id]?.[searchKey])

  const bestPackage = useMemo<App.HotelPackage | undefined>(() => {
    if (!searchStreamingEnabled) {
      if (!isSpecificSearch || bestPriceError) {
        return offer.lowestPricePackage
      }
      if (bestMaybeAvailableRate?.available && !fetchingOffer) {
        const bestPricePkg = offer.packages.find(pkg => pkg.uniqueKey === bestMaybeAvailableRate.rate.packageUniqueKey)
        return bestPricePkg ?? offer.lowestPricePackage
      }
    } else {
      // use search pricing package id by default
      const pkgId = metaData?.pricing?.lowestPricePackageId ?? offer.lowestPricePackage?.id
      const duration = metaData?.pricing?.duration ?? offer.lowestPricePackage?.duration
      if (!pkgId || !duration || !bestMaybeAvailableRate?.rate?.roomRateId) {
        return offer.lowestPricePackage
      }

      const pkg = offer.packages.find(pkg => pkg.uniqueKey === getPackageUniqueKey(pkgId, duration, bestMaybeAvailableRate.rate.roomRateId))
      return pkg ?? offer.lowestPricePackage
    }
  }, [searchStreamingEnabled, isSpecificSearch, bestPriceError, bestMaybeAvailableRate, fetchingOffer, offer, metaData])
  const cancellationPolicyType = bestPackage?.roomRate?.cancellationPolicy?.type

  const locations = useMemo<Array<string>>(() => nonNullable([offer.locationHeading, offer.locationSubheading]), [offer])
  const mapUrl = useMapSearchOfferUrl(offer)

  const capacityText = useMemo(() => getVillaCapacityText(offer), [offer])

  const bestAvailableRate = bestMaybeAvailableRate?.available ? bestMaybeAvailableRate.rate : undefined
  const duration = filters?.flexibleMonths && filters.flexibleNights ? getMinimumDurationFromFlexibleNights(filters.flexibleNights as FLEXIBLE_DURATION_RANGE) : offer.lowestPricePackage?.duration
  const {
    defaultPricing,
    memberPricing,
  } = useSearchPrices({
    offer,
    pkg: bestPackage,
    rate: bestAvailableRate,
    duration,
    filters,
    useRealSearchPrices: searchStreamingEnabled,
  })

  const pointsEarnCalculationRequests = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>>(() => {
    const propertyFees = defaultPricing.fees?.find((fee) => fee.type === 'property')?.amount ?? 0
    return [
      generateLuxLoyaltyPointsCalculatorVillasOptions(offer, {
        price: defaultPricing.price,
        memberPrice: memberPricing?.price,
        pkg: bestPackage,
        propertyFees,
      }),
    ]
  }, [bestPackage, defaultPricing.price, memberPricing?.price, offer, defaultPricing.fees])

  return <SearchTile
    className={VillaSearchTile.name}
    productType={offer.productType}
    offerType={offer.type}
    action={<TextButton nonInteractable kind="primary">View offer</TextButton>}
    bookmarkAction={<BookmarkButton offer={offer}/>}
    cancellationPolicyLabel={!!cancellationPolicyType && <TileCancellationPolicy
      cancellationPolicyType={cancellationPolicyType}
      checkInDate={filters?.checkIn}
      timezoneOffset={offer?.property?.timezoneOffset}
      offerType={offer.type}
    />}
    features={<VillaSearchTileFeatures offer={offer} pkg={bestPackage} />}
    images={offer.images}
    overline={<SearchTileRowOverlineLocation locations={locations} mapUrl={mapUrl} />}
    pricePoint={<HotelSearchTilePriceStack offer={offer} filters={filters} bestPackage={bestPackage} bestRate={bestAvailableRate} />}
    rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
    subtitle={capacityText}
    title={offer.property.name}
    meta={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
  />
}

export default VillaSearchTile
