import clsx from 'clsx'
import React, { forwardRef, HTMLAttributes } from 'react'
import styled from 'styled-components'

const Container = styled.tr`
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};

  &:last-child {
    border-bottom: none;
  }
`

interface Props extends HTMLAttributes<HTMLTableRowElement> {
}

/**
 * Must be child of `<TableHeader>`.
 *
 * Contains `<TableBodyCell>`s.
 */
const TableBodyRow = forwardRef<HTMLTableRowElement, Props>(function TableBodyRow(props, ref) {
  return <Container {...props} ref={ref} className={clsx(TableBodyRow.name, props.className)} />
})

export default TableBodyRow
