import Group from 'components/utils/Group'
import React, { ComponentProps } from 'react'
import PriceRowLuxPlusNonMemberPrice from './PriceRowLuxPlusNonMemberPrice'
import PriceRowLuxPlusPrice from './PriceRowLuxPlusPrice'

type InheritedPriceRowLuxPlusPriceProps = Pick<ComponentProps<typeof PriceRowLuxPlusPrice>, 'memberPrice' | 'saleUnit' | 'discountPercentage' | 'discountType'>
type InheritedPriceRowLuxPlusNonMemberPriceProps = Pick<ComponentProps<typeof PriceRowLuxPlusNonMemberPrice>, 'nonMemberPrice'>

interface Props extends InheritedPriceRowLuxPlusPriceProps, InheritedPriceRowLuxPlusNonMemberPriceProps {
  size: 'S' | 'M' | 'L'
  /**
   * @default start
   */
  horizontalAlign?: 'start' | 'center' | 'end',
}

const SIZES_NON_MEMBER_SIZE: Record<Props['size'], ComponentProps<typeof PriceRowLuxPlusNonMemberPrice>['size']> = {
  S: 'M',
  M: 'M',
  L: 'L',
}

function PriceStackLuxPlusCompactPrice(props: Props) {
  const {
    nonMemberPrice,
    memberPrice,
    discountPercentage,
    discountType,
    saleUnit,
    size,
    horizontalAlign = 'start',
    ...rest
  } = props

  return <Group
    className={PriceStackLuxPlusCompactPrice.name}
    direction="vertical"
    horizontalAlign={horizontalAlign}
  >
    <PriceRowLuxPlusPrice
      {...rest}
      logoPlacement="leading"
      memberPrice={memberPrice}
      discountPercentage={discountPercentage}
      discountType={discountType}
      size={size}
      saleUnit={saleUnit}
    />
    <PriceRowLuxPlusNonMemberPrice nonMemberPrice={nonMemberPrice} size={SIZES_NON_MEMBER_SIZE[size]} />
  </Group>
}

export default PriceStackLuxPlusCompactPrice
