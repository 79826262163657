import { getDestinationCountriesFromOrder } from 'lib/order/orderInsuranceUtils'
import { createSelector } from 'reselect'

const getDestinationCountriesFromExistingOrder = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.cruise.cruiseOffers,
  (state: App.State) => state.offer.offers,
  (order, cruiseOffers, offers): Array<string> => {
    if (order) {
      return getDestinationCountriesFromOrder(order, cruiseOffers, offers)
    }

    return []
  },
)

export default getDestinationCountriesFromExistingOrder
