import clsx from 'clsx'
import React, { ComponentProps, forwardRef, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { BodyTextSmall } from '../Typography/BodyText'
import _TableCell from './_TableCell'

const Container = styled(_TableCell)`
  ${BodyTextSmall}
  color: ${props => props.theme.palette.neutral.default.two};
`

type InheritedBaseTableCellProps = Pick<
  ComponentProps<typeof _TableCell>,
  'minWidth' | 'width' | 'format' | 'wrap' | 'align' | 'tabletAlign' | 'desktopAlign' | 'lineClamp' | 'tabletLineClamp' | 'desktopLineClamp'
>

interface Props extends HTMLAttributes<HTMLTableCellElement>, InheritedBaseTableCellProps {
}

/**
 * Must be child of `<TableBodyRow>`.
 *
 * Styles string children accordingly.
 */
const TableBodyCell = forwardRef<HTMLTableCellElement, Props>(function TableBodyCell(props, ref) {
  return <Container {...props} forwardedAs="td" className={clsx(TableBodyCell.name, props.className)} ref={ref} />
})

export default TableBodyCell
