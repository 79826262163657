import React, { useMemo } from 'react'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import { useGlobalCruisesSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalCruisesSearch'
import CruiseSearchFiltersBar from 'components/Cruises/SearchPage/Filters/CruiseSearchFiltersBar'
import useQueryParams from 'hooks/useQueryParams'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { getCruiseSearchStateFromParams } from 'lib/cruises/cruiseUtils'

function GlobalCruisesSearchTabContent() {
  const queryParams = useQueryParams()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSearchState = useMemo(() => getCruiseSearchStateFromParams(queryParams), [])
  const { globalCruisesSearchDispatch, globalCruisesSearchState } = useGlobalCruisesSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    ...initialSearchState,
  })

  return <GlobalSearchContextProvider
    state={globalCruisesSearchState}
    dispatch={globalCruisesSearchDispatch}
  >
    <CruiseSearchFiltersBar forceSearchPage />
  </GlobalSearchContextProvider>
}

export default GlobalCruisesSearchTabContent
