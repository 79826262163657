import { isLuxPlusToursEnabled } from 'checkout/selectors/tourV2Selectors'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import TourDiscountLabel from 'components/Tours/TourDiscountLabel'
import { useAppSelector } from 'hooks/reduxHooks'
import { groupBy } from 'lib/array/arrayUtils'
import { getUrgencyTagVariant } from 'lib/tours/tourUtils'
import React from 'react'

const positionTagsMap = new Map([
  ['sale', {
    position: 'end',
    tag: <TourDiscountLabel key="sale" />,
  }],
  ['departuresale', {
    position: 'end',
    tag: <TourDiscountLabel key="departuresale" saleOnDepartures />,
  }],
])

interface Props {
  className?: string;
  tags: Array<string>;
}

/**
 * This component is the duplicate of `<TourV2UrgencyTags>`
 * @todo @lux-group/cruises needs to adjust it to Cruises requirements
 */
function CruisesUrgencyTags(props: Props) {
  const isLuxPlusToursAvailable = useAppSelector(state => isLuxPlusToursEnabled(state))
  let tags = props.tags
  if (!isLuxPlusToursAvailable) {
    tags = props.tags.filter(tag => tag !== 'luxplus')
  }

  const tagsByPosition = groupBy(tags, (tag) => {
    const knownTag = positionTagsMap.get(tag.toLowerCase())
    return knownTag?.position ?? 'auto'
  })
  if (!tagsByPosition.has('start') && !tagsByPosition.has('auto') && !tagsByPosition.has('end')) {
    return null
  }

  return <LabelGroup className={props.className}>
    {tagsByPosition.has('start') && tagsByPosition.get('start').map((tag) => positionTagsMap.get(tag.toLowerCase()).tag)}
    {tagsByPosition.has('auto') && tagsByPosition.get('auto').map((tag) => <UrgencyLabel key={tag} variant={getUrgencyTagVariant(tag)}>{tag}</UrgencyLabel>)}
    {tagsByPosition.has('end') && tagsByPosition.get('end').map((tag) => positionTagsMap.get(tag.toLowerCase()).tag)}
  </LabelGroup>
}

export default CruisesUrgencyTags
