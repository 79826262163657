import React, { useMemo } from 'react'
import { Marker, MarkerProps } from '@react-google-maps/api'
import { Clusterer } from '@react-google-maps/marker-clusterer'

import {
  PROPERTY_MAP_MARKER_TYPE,
  CRUISE_PILL_MARKER_TYPE,
  CRUISE_PILL_ICON_MARKER_TYPE,
  MOMENTS_MAP_MARKER_TYPE,
} from 'constants/map'

import pins from './pins'
import config from 'constants/config'
import noop from 'lib/function/noop'

interface Props extends Omit<MarkerProps, 'position'> {
  active?: boolean;
  multipleMarkers?: boolean;
  marker: App.MapMarker;
  setActiveMarker?: (marker: App.MapMarker) => void;
  clusterer?: Clusterer;
  label?: string | App.MapMarkerLabel;
}

const brandedPins: Record<string, any> = {
  luxuryescapes: {
    property: pins.luxuryescapes,
    moments: pins.moments,
    'see-and-do': pins['see-and-do'],
    'eat-and-drink': pins['eat-and-drink'],
    'cruise-pill': pins['cruise-pill'],
    'cruise-pill-icon': pins['cruise-pill-icon'],
  },
  scoopontravel: {
    property: pins.scoopontravel,
  },
}

function PropertyMarker(props: Props) {
  const {
    active,
    marker,
    multipleMarkers,
    setActiveMarker = noop,
    clusterer,
    label,
    children,
    ...markerProps
  } = props

  const brandedPin = brandedPins[config.BRAND]
  const pin = brandedPin?.[marker.type] ?? pins.default
  const pinIcon = active ? pin.active : pin.normal
  const iconProps = useMemo(() => {
    if (typeof google === 'undefined') return { url: `${pinIcon}` }

    const icon: google.maps.Icon = { url: `${pinIcon}` }

    switch (marker.type) {
      case PROPERTY_MAP_MARKER_TYPE:
        icon.scaledSize = new google.maps.Size(53, 58)
        break
      case CRUISE_PILL_MARKER_TYPE:
        icon.scaledSize = new google.maps.Size(70, 30)
        break
      case CRUISE_PILL_ICON_MARKER_TYPE:
        icon.size = new google.maps.Size(120, 30)
        break
      case MOMENTS_MAP_MARKER_TYPE:
        icon.scaledSize = new google.maps.Size(48, 53)
        break
      default:
        icon.scaledSize = new google.maps.Size(48, 53)
        break
    }

    return icon
  }, [pinIcon, marker.type])

  const position = useMemo(() => ({ lat: marker.latitude, lng: marker.longitude }), [marker])

  return (
    <Marker
      {...markerProps}
      clusterer={clusterer}
      position={position}
      label={label}
      icon={iconProps}
      onClick={() => {
        if (!multipleMarkers) {
          window.open(`https://www.google.com/maps/search/?api=1&query=${position.lat},${position.lng}`)
        } else {
          setActiveMarker?.(marker)
        }
      }}
    >
      {children}
    </Marker>
  )
}

export default PropertyMarker
