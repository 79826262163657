import { createSelector } from 'reselect'
import { getBookingProtectionItemsView } from '../view/bookingProtection'
import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { selectShouldUseBookingProtectionMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'

export const getBookingProtectionTotals = createSelector(
  (state: App.State) => getBookingProtectionItemsView(state),
  (state: App.State) => selectShouldUseBookingProtectionMemberPrice(state),
  (viewWithStatus, checkoutWithDiscountedBookingProtection): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)

    const itemsWithMobileAppDiscount = definedItems.filter(item => item.totals.mobileAppDiscount?.amount)
    const mobileAppDiscountAmount = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, item => item.totals.mobileAppDiscount?.amount ?? 0) : 0
    const mobileAppDiscountPercentage = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, item => item.totals.mobileAppDiscount?.percentage ?? 0) / itemsWithMobileAppDiscount.length : 0

    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView => checkoutWithDiscountedBookingProtection ? itemView.totals.memberPrice : itemView.totals.price,
        ),
        memberPrice: 0,
        value: sum(definedItems, itemView => (itemView.totals.value)),
        surcharge: 0,
        taxesAndFees: 0,
        mobileAppDiscount: {
          amount: mobileAppDiscountAmount,
          percentage: mobileAppDiscountPercentage,
        },
        memberValue: 0,
        extraGuestSurcharge: 0,
      },
    }
  },
)
