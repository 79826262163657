import { KEYBOARD_MODE_CSS_VAR, MOUSE_MODE_CSS_VAR, TOUCH_MODE_CSS_VAR } from 'constants/app'
import { useEffect, useRef } from 'react'

enum InputModeType {
  keyboard = 'keyboard',
  mouse = 'mouse',
  touch = 'touch',
}

/**
 * Registers three event listeners on the `window`.
 * @listens keydown
 * @listens mousedown
 * @listens touchstart
 *
 * Should only be used at the root of the app.
 * @see ./src/client/components/App/App.tsx
 */
function useAppInputModeListener() {
  const inputModeRef = useRef<InputModeType | undefined>(undefined)

  useEffect(() => {
    const setKeyboardMode = () => {
      if (inputModeRef.current !== InputModeType.keyboard) {
        inputModeRef.current = InputModeType.keyboard
        document.documentElement.style.removeProperty(KEYBOARD_MODE_CSS_VAR)
        document.documentElement.style.setProperty(MOUSE_MODE_CSS_VAR, 'none')
        document.documentElement.style.setProperty(TOUCH_MODE_CSS_VAR, 'none')
        document.body.classList.add('input-keyboard')
        document.body.classList.remove('input-mouse')
        document.body.classList.remove('input-touch')
      }
    }

    const setMouseMode = () => {
      if (inputModeRef.current !== InputModeType.mouse) {
        inputModeRef.current = InputModeType.mouse
        document.documentElement.style.removeProperty(MOUSE_MODE_CSS_VAR)
        document.documentElement.style.setProperty(KEYBOARD_MODE_CSS_VAR, 'none')
        document.documentElement.style.setProperty(TOUCH_MODE_CSS_VAR, 'none')
        document.body.classList.remove('input-keyboard')
        document.body.classList.add('input-mouse')
        document.body.classList.remove('input-touch')
      }
    }

    const setTouchMode = () => {
      if (inputModeRef.current !== InputModeType.touch) {
        inputModeRef.current = InputModeType.touch
        document.documentElement.style.removeProperty(TOUCH_MODE_CSS_VAR)
        document.documentElement.style.setProperty(KEYBOARD_MODE_CSS_VAR, 'none')
        document.documentElement.style.setProperty(MOUSE_MODE_CSS_VAR, 'none')
        document.body.classList.remove('input-keyboard')
        document.body.classList.remove('input-mouse')
        document.body.classList.add('input-touch')
      }
    }

    setMouseMode()

    window.addEventListener('keydown', setKeyboardMode)
    window.addEventListener('mousedown', setMouseMode)
    window.addEventListener('touchstart', setTouchMode)

    return () => {
      window.removeEventListener('keydown', setKeyboardMode)
      window.removeEventListener('mousedown', setMouseMode)
      window.removeEventListener('touchstart', setTouchMode)
    }
  }, [])
}

export default useAppInputModeListener
