import React from 'react'

import { MAX_ROOM_LIMIT } from 'constants/offer'
import { connect } from 'react-redux'
import TextButton from 'components/Luxkit/Button/TextButton'
import TextLink from 'components/Luxkit/TextLink'
import SolidPlusIcon from 'components/Luxkit/Icons/solid/SolidPlusIcon'
import Caption from 'components/Luxkit/Typography/Caption'
import { pluralizeToString } from 'lib/string/pluralize'
import { getSupportContact } from 'selectors/supportContactSelector'

interface Props {
  className?: string;
  contactNumber?: App.GeoContact;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  roomsCount: number;
  saleUnit?: string;
  maxRooms?: number;
  noServiceContact?: boolean;
}

function AddRoomButton(props: Props) {
  const {
    className,
    contactNumber,
    onClick,
    roomsCount,
    saleUnit,
    maxRooms = MAX_ROOM_LIMIT,
    noServiceContact,
  } = props
  const unit = saleUnit || 'room'
  const pluralizeSaleUnit = pluralizeToString(unit, maxRooms)

  return (
    <div className={className} data-testid="add-room-button">
      {roomsCount < maxRooms &&
        <TextButton kind="secondary" variant="dark" startIcon={<SolidPlusIcon />} onClick={onClick}>
          Add another {unit}
        </TextButton>
      }
      {(roomsCount >= maxRooms && !noServiceContact && contactNumber) &&
        <Caption variant="medium" weight="bold">
          <>
            For bookings of more than {pluralizeSaleUnit} please contact our customer service team on{' '}
            <TextLink href={`tel:${contactNumber.number}`} rel="nofollow">
              {contactNumber.humanReadable}
            </TextLink>.
          </>
        </Caption>
      }
    </div>
  )
}

function mapStateToProps(state: App.State) {
  return {
    contactNumber: getSupportContact(state).defaultContact,
  }
}

export default connect(mapStateToProps)(AddRoomButton)
