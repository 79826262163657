import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import moment from 'moment'
import getOrderDataMemberPricingMetadata from './orderMemberPricingMetadata'
import sanitizeItemContextMetadata from './sanitizeItemContextMetadata'

function getInsuranceOrderItems(order: App.Order, isMultiCart?: boolean) {
  return order.insuranceItems.map(orderItem => {
    const travelStart = orderItem.startDate
    const travelEnd = orderItem.endDate
    const luxPlusMetaData = getOrderDataMemberPricingMetadata(orderItem)

    const isInsuranceBookingProtection = order.insuranceItems.some(item => item.insuranceType === 'cfmr')

    return comluxgroup.createItem_1_1_0(
      {
        offerId: orderItem.idProduct || orderItem.provider || 'insurance',
        categoryId: 'insurance',
        productId: isInsuranceBookingProtection ? 'cfmr' : 'insurance',
        duration: travelStart && travelEnd ? moment(travelEnd).diff(travelStart, 'days') : undefined,
        travelStart,
        travelEnd,
        numberOfAdults: orderItem.travellers.filter(traveller => !!traveller?.ageRange || traveller?.ageRange === 'adult')?.length || 0,
        numberOfChildren: orderItem.travellers.filter(traveller => traveller?.ageRange === 'child')?.length || 0,
        numberOfInfants: orderItem.travellers.filter(traveller => traveller?.ageRange === 'infant')?.length || 0,
        price: orderItem.total,
        currency: order.currencyCode,
        destinationCountry: orderItem.destinations.join(','),
        itemId: orderItem.id,
        bookingNumber: order.bookingNumber,
        orderId: order.id,
        metadata: sanitizeItemContextMetadata({
          ...luxPlusMetaData ?? {},
          isMultiCart,
        }),
      },
    )
  })
}

export default getInsuranceOrderItems
