import IconButton from 'components/Luxkit/Button/IconButton'
import LineShoppingCartIcon from 'components/Luxkit/Icons/line/LineShoppingCartIcon'
import Caption from 'components/Luxkit/Typography/Caption'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { pushWithRegion } from 'actions/NavigationActions'
import { getUserCartItemsArray } from 'selectors/userCart'

const StyledNotificationIconButton = styled(IconButton)`
  position: relative;
`

const RedDotOnSignUp = styled(Caption)`
  position: absolute;
  top: ${rem(2)};
  right: ${rem(2)};
  padding: ${rem(2)} ${rem(4)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.messaging.critical.normalBackground};
  color: ${props => props.theme.palette.messaging.critical.normalContrast};
`

function MultiUserCartHeader() {
  const dispatch = useAppDispatch()

  const onClickCart = useCallback(() => {
    dispatch(pushWithRegion('/cart'))
  }, [dispatch])

  const cartItems = useAppSelector(state => getUserCartItemsArray(state).length)

  return <StyledNotificationIconButton
    kind="tertiary"
    variant="default"
    role="menuitem"
    onClick={onClickCart}
  >
    <LineShoppingCartIcon />
    {cartItems > 0 && <RedDotOnSignUp variant="small">
      {cartItems > 9 ? '9+' : cartItems}
    </RedDotOnSignUp>}
  </StyledNotificationIconButton>
}

export default MultiUserCartHeader
