import {
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
  CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_JOINING_FEE,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION,
  CHECKOUT_ITEM_TYPE_VILLA,
} from './checkout'

export const USER_CART_ADDON_TYPES = [
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_JOINING_FEE,
  CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION,
] satisfies Array<App.Checkout.AnyItemType>

export type UserCartAddonItemType = typeof USER_CART_ADDON_TYPES[number]

export const USER_CART_ACCOMMODATION_TYPES = [
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
  CHECKOUT_ITEM_TYPE_VILLA,
] satisfies Array<App.Checkout.AnyItemType>

export type UserCartAccommodationItemType = typeof USER_CART_ACCOMMODATION_TYPES[number]

export const NON_DUPLICABLE_ITEM_TYPES = [
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_JOINING_FEE,
  CHECKOUT_ITEM_TYPE_LUXURY_PLUS_SUBSCRIPTION,
] satisfies Array<App.Checkout.AnyItemType>
