import { createSelector } from 'reselect'
import { getExperienceTotals, getTransferTotals } from './experience'
import { sum } from 'lib/array/arrayUtils'
import { getBookingProtectionTotals } from './bookingProtection'
import { getInsuranceTotals } from './insurance'

const getMobileAppDiscountTotalsView = createSelector(
  (state: App.State) => getExperienceTotals(state),
  (state: App.State) => getTransferTotals(state),
  (state: App.State) => getBookingProtectionTotals(state),
  (state: App.State) => getInsuranceTotals(state),
  (experienceTotals, transferTotals, bookingProtectionTotals, insuranceTotals): App.Checkout.MobileAppDiscountTotalsView => {
    const totalsByOfferType = {
      experiences: {
        amount: experienceTotals.data.mobileAppDiscount?.amount ?? 0,
        percentage: experienceTotals.data.mobileAppDiscount?.percentage ?? 0,
      },
      transfers: {
        amount: transferTotals.data.mobileAppDiscount?.amount ?? 0,
        percentage: transferTotals.data.mobileAppDiscount?.percentage ?? 0,
      },
      bookingProtection: {
        amount: bookingProtectionTotals.data.mobileAppDiscount?.amount ?? 0,
        percentage: bookingProtectionTotals.data.mobileAppDiscount?.percentage ?? 0,
      },
      insurance: {
        amount: insuranceTotals.data.mobileAppDiscount?.amount ?? 0,
        percentage: insuranceTotals.data.mobileAppDiscount?.percentage ?? 0,
      },
    }

    const itemsWithAppDiscount = Object.values(totalsByOfferType).filter(({ amount }) => amount > 0)

    return {
      ...totalsByOfferType,
      totalAmount: itemsWithAppDiscount.length ? sum(itemsWithAppDiscount, ({ amount }) => amount) : 0,
      totalPercentage: itemsWithAppDiscount.length ? sum(itemsWithAppDiscount, ({ percentage }) => percentage) / itemsWithAppDiscount.length : 0,
    }
  },
)

export default getMobileAppDiscountTotalsView
