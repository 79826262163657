import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import * as UserSettingsKeys from '../reactQueryKeys/userSettings'

import { useAppSelector } from 'hooks/reduxHooks'
import { EmptyObject } from 'lib/object/objectUtils'
import { getCurrentUserId } from 'selectors/accountSelectors'
import {
  getUserSettings,
  updateUserSettings,
} from 'tripPlanner/api/userSettings'
import {
  UpdateUserSettingsError,
  UpdateUserSettingsRequest,
} from 'tripPlanner/api/userSettings/types'
import { UserSettings } from 'tripPlanner/types/common'
import { EmptyArray } from 'lib/array/arrayUtils'
import { useEffect } from 'react'

const MINUTES = 60 * 1000
const STALE_TIME = 10 * MINUTES

export const useUserSettings = (options?: UseQueryOptions<UserSettings>) => {
  const isLoggedIn = !!useAppSelector(getCurrentUserId)

  return useQuery({
    queryKey: UserSettingsKeys.settings(isLoggedIn),
    queryFn: isLoggedIn ? () => getUserSettings() : () => EmptyObject,
    staleTime: STALE_TIME,
    ...options,
  })
}

export const useUpdateUserSettings = (
  options: UseMutationOptions<
    UserSettings,
    UpdateUserSettingsError,
    UpdateUserSettingsRequest,
    UserSettings
  > = {},
): UseMutationResult<
  UserSettings,
  UpdateUserSettingsError,
  UpdateUserSettingsRequest,
  UserSettings
> => {
  const isLoggedIn = !!useAppSelector(getCurrentUserId)
  const queryClient = useQueryClient()
  const queryKey = UserSettingsKeys.settings(isLoggedIn)

  return useMutation((params) => updateUserSettings(params), {
    retry: false,
    ...options,
    onMutate: (settingsUpdate) => {
      const currentData = queryClient.getQueryData<UserSettings>(queryKey)

      // Eager update
      queryClient.setQueryData<UserSettings>(
        queryKey,
        applySettingsUpdate(currentData, settingsUpdate),
      )

      options.onMutate?.(settingsUpdate)

      // Return current data to rollback on error
      return currentData
    },
    onSuccess: (data, settingsUpdate, context) => {
      queryClient.setQueryData<UserSettings>(queryKey, data)
      options.onSuccess?.(data, settingsUpdate, context)
    },
    onError(error, settingsUpdate, context) {
      // Rollback and invalidate after failed update
      queryClient.setQueryData<UserSettings>(queryKey, context)
      queryClient.invalidateQueries(queryKey)
      options.onError?.(error, settingsUpdate, context)
    },
  })
}

// Applies update to a local settings object, for eager updates
function applySettingsUpdate(
  settings: UserSettings | undefined,
  settingsUpdate: UpdateUserSettingsRequest,
): UserSettings {
  const newSettings: UserSettings = {
    ...settings,
  }

  Object.entries(settingsUpdate).forEach(([_key, value]) => {
    const key = _key as keyof UserSettings
    if (value === null) {
      delete newSettings[key]
    } else {
      (newSettings as any)[key] = value
    }
  })

  return newSettings
}

/** Clear trip IDs from the `recentlyAddedToTripIDs` user setting */
export function useClearNewlyAddedTrips(
  /** ID to clear. If omitted, entire `recentlyAddedToTripIDs` field will be nullified */
  tripId?: string,
) {
  const { data: userSettings } = useUserSettings()
  const { mutate: updateUserSettings } = useUpdateUserSettings()

  const recentlyAddedToTripIDs: Array<string> = userSettings?.recentlyAddedToTripIDs ?? EmptyArray

  useEffect(() => {
    if (tripId && recentlyAddedToTripIDs.includes(tripId)) {
      const newValue = recentlyAddedToTripIDs.filter((id) => id !== tripId)
      updateUserSettings({
        recentlyAddedToTripIDs: newValue.length > 0 ? newValue : null,
      })
    } else if (!tripId && recentlyAddedToTripIDs.length > 0) {
      updateUserSettings({
        recentlyAddedToTripIDs: null,
      })
    }
  }, [recentlyAddedToTripIDs, tripId, updateUserSettings])
}
