import React from 'react'
import Pane from 'components/Common/Pane'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import { rem } from 'polished'
import styled from 'styled-components'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import cn from 'clsx'
import LuxLoyaltyPointsListItem from 'luxLoyalty/components/LuxLoyaltyPointsListItem'
import LuxLoyaltyProductLogo from './LuxLoyaltyProductLogo'
import getLuxLoyaltyEligibleTier from 'luxLoyalty/selectors/getLuxLoyaltyEligibleTier'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import { useAppSelector } from 'hooks/reduxHooks'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import LuxLoyaltyTierBadge from './LuxLoyaltyTierBadge'

const Container = styled.div`
  &.tier-bronze {
    --tint: ${props => props.theme.palette.product.luxLoyalty.bronze.lightBackground};
    --contrast: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
    --backgroundShade: ${props => props.theme.palette.product.luxLoyalty.bronze.darkBackground};
  }

  &.tier-silver {
    --tint: ${props => props.theme.palette.product.luxLoyalty.silver.lightBackground};
    --contrast: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
    --backgroundShade: ${props => props.theme.palette.product.luxLoyalty.silver.darkBackground};
  }

  &.tier-gold {
    --tint: ${props => props.theme.palette.product.luxLoyalty.gold.lightBackground};
    --contrast: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
    --backgroundShade: ${props => props.theme.palette.product.luxLoyalty.gold.darkBackground};
  }
`

const LuxLoyaltyBannerSheet = styled(Pane)`
  padding: ${rem(32)} ${rem(28)};
  background: var(--tint);
`

const TierBar = styled.div`
  height: ${rem(8)};
  background: linear-gradient(
    275deg,
    var(--tint),
    var(--backgroundShade)
  );
`

const HeadingTier = styled.span`
  color: var(--contrast);
`

const benefits: Record<
  Exclude<App.LuxLoyaltyTier, 'platinum'>,
  Array<string>
> = {
  bronze: [
    'Gain Status Credits with every booking from day one',
    'Earn and use points across all products',
    'Double dip points with other loyalty programs',
  ],
  silver: [
    'Earn and use points across all products',
    '2x free lounge passes at select airports',
    'Double dip points with other loyalty programs',
  ],
  gold: [
    'Earn and use points across all products',
    '1x free room upgrade at select hotels',
    '2x free lounge passes at select airports',
    '2x free airport chauffeur service',
    'Save 25% on Travel & Cancellation Protection',
    '24/7 priority phone support',
  ],
}

interface Props {
  onAfterClose: React.ComponentProps<typeof ModalBase>['onAfterClose']
  primaryActionProps: React.ComponentProps<typeof ModalFooter>['primaryActionProps']
  secondaryActionProps?: React.ComponentProps<typeof ModalFooter>['secondaryActionProps']
}

function LuxLoyaltyJoinModal(props: Props) {
  const {
    onAfterClose,
    primaryActionProps,
    secondaryActionProps,
  } = props

  const accountEligibleTier = useAppSelector(getLuxLoyaltyEligibleTier)
  const givenName = useAppSelector((state: App.State) => state.auth.account.givenName)
  const tierName = getLuxLoyaltyTierDisplayName(accountEligibleTier)
  const welcomeCopy = givenName ? `${givenName},` : 'Welcome,'

  // Users will only be eligible for bronze, silver, or gold at launch
  if (accountEligibleTier === 'platinum') return null

  return <ProductPaletteProvider palette={`tier-${accountEligibleTier}`}>
    <ModalBase dismissible onAfterClose={onAfterClose}>
      <ModalBody>
        <Container className={cn(`tier-${accountEligibleTier}`)}>
          <LuxLoyaltyBannerSheet>
            <Group direction="vertical" horizontalAlign="center" gap={24} tabletGap={16}>
              <Group direction="vertical" horizontalAlign="center" gap={24}>
                <Group direction="horizontal" verticalAlign="center" gap={12}>
                  <LuxLoyaltyProductLogo height={24} />
                  <LuxLoyaltyTierBadge tier={accountEligibleTier} />
                </Group>
                <Heading variant="heading3" format="propercase" align="center" colour="neutral-one">
                  {welcomeCopy} activate your free
                  <br />
                  <HeadingTier>{tierName} membership</HeadingTier>
                </Heading>
              </Group>
            </Group>
          </LuxLoyaltyBannerSheet>
          <TierBar />
          <ModalContent>
            <VerticalSpacer gap={12}>
              <BodyText variant="large" weight="bold">
                Enjoy instant benefits as a {tierName} member
              </BodyText>
              <VerticalSpacer gap={8}>
                {benefits[accountEligibleTier].map((benefit) => (
                  <LuxLoyaltyPointsListItem key={benefit} size="S" tier={accountEligibleTier}>
                    {benefit}
                  </LuxLoyaltyPointsListItem>
                ))}
              </VerticalSpacer>
            </VerticalSpacer>
          </ModalContent>
        </Container>
      </ModalBody>
      <ModalFooter
        primaryActionProps={primaryActionProps}
        secondaryActionProps={secondaryActionProps}
      />
    </ModalBase>
  </ProductPaletteProvider>
}

export default LuxLoyaltyJoinModal
