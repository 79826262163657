import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import getOfferFeatureIcon from 'lib/offer/getOfferFeatureIcon'
import React, { useMemo } from 'react'

interface Props {
  inclusion: App.OfferInclusion
}

function TourV2HighlightedInclusionItem({ inclusion }: Props) {
  const Icon = useMemo(() => getOfferFeatureIcon(inclusion.symbol), [inclusion])
  return <BodyTextBlock
    variant="medium"
    colour="neutral-two"
    startIcon={<Icon colour={inclusion?.isFlash ? 'exclusive' : 'highlight-tertiary'} />}
  >
    {inclusion.description}
  </BodyTextBlock>
}

export default TourV2HighlightedInclusionItem
