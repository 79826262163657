import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { getDueAtProperty } from './accommodation'
import getCheckoutTotal from './getCheckoutTotal'

const getPayableTotal = createSelector(
  (state: App.State) => getCheckoutTotal(state),
  (state: App.State) => getDueAtProperty(state),
  (state: App.State) => state.checkout.cart.mode,
  (total, dueAtProperty, postPurchase): number => {
    if (postPurchase === 'deposit-balance') {
      return floatify(total)
    }
    return floatify(total - dueAtProperty)
  },
)

export default getPayableTotal
