import React, { useCallback, useContext, useMemo, useRef, useState, ComponentProps } from 'react'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import useToggle from 'hooks/useToggle'
import FormattedCruiseLineSearchSummary from '../CruiseLineSelect/FormattedCruiseLineSearchSummary'
import CruiseLocationContent from './CruiseLocationContent'
import useGlobalSearchTypeahead from 'hooks/GlobalSearch/useGlobalSearchTypeahead'
import { TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES, TOUR_V2_SEARCH_TYPES } from 'constants/tours'
import FilterSearchInput from 'components/Common/FilterPanel/FilterSearchInput'
import CruiseFilterInput from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseFilterInput'
const SET_SEARCH_ITEMS = GlobalSearchStateActions.SET_SEARCH_ITEMS

interface Props {
  dropdownAnchorRef: React.RefObject<HTMLElement>
  dropdownSize?: ComponentProps<typeof DropdownSheet>['size']
  isChipMode?: boolean
  hideImages?: boolean
  initialFilters?: App.CruiseInitialFilters
  hideTrendOptions?: boolean
  shouldIgnoreFlashOffers?: boolean
  onChange: (values: App.CruiseGlobalFilters) => void;
}

function CruiseLocationDesktopInput({
  dropdownAnchorRef,
  onChange,
  isChipMode,
  dropdownSize,
  hideImages,
  initialFilters,
  hideTrendOptions,
  shouldIgnoreFlashOffers,
}: Props) {
  const inputTriggerRef = useRef<HTMLInputElement>(null)

  const globalFilters = useContext(GlobalSearchStateContext)
  const { searchItems = [] } = globalFilters
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const { suggestedSearchItems } = useContext(GlobalSearchStateContext)

  const [searchTerm, setSearchTerm] = useState('')
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [isOpen, , show, close] = useToggle(false)

  const [facets] = useCruiseSearchFacets({ facetTypes: ['destination_ports'], ...(initialFilters || {}) })
  useGlobalSearchTypeahead({
    search: searchTerm,
    searchTypes: TOUR_V2_SEARCH_TYPES,
    typeaheadTypes: TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES,
  })

  const subtitle = searchTerm ? `Search results for "${searchTerm}"` : 'or browse by popular regions'
  const locations = useMemo(() => {
    if (!searchTerm) {
      return hideTrendOptions ? facets.filter(({ count }) => !!count) : facets.filter(({ isTrend }) => isTrend)
    }

    let filtered = facets.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
    if (!filtered.length) {
      filtered = facets.filter(({ name }) => {
        return suggestedSearchItems?.find((suggestedValue) => {
          return name.includes(suggestedValue?.format?.secondaryText!)
        })
      })
    }
    return filtered.slice(0, 100)
  }, [searchTerm, facets, suggestedSearchItems, hideTrendOptions])

  const inputValue = useMemo(() => {
    if (isOpen) return searchTerm
    if (searchItems.length > 1) {
      const names = searchItems.slice(0, 1).map((item) => item.format.mainText).join(', ')
      return `${names} + ${searchItems.length - 1}`
    }
    return searchItems.map((item) => item.format.mainText).join(', ')
  }, [searchItems, isOpen, searchTerm])

  const handleOnApply = useCallback(() => {
    onChange({ searchItems })
    close()
  }, [onChange, searchItems, close])

  const handleOnClear = useCallback(() => {
    searchDispatch({ type: SET_SEARCH_ITEMS, searchItems: [] })
    setSearchTerm('')
    setIsAllSelected(false)
  }, [searchDispatch])

  const handleOnChange = useCallback((searchItems: Array<App.SearchItem>) => {
    searchDispatch({ type: SET_SEARCH_ITEMS, searchItems })
    setIsAllSelected(false)
  }, [searchDispatch])

  const handleSelectAll = useCallback(() => {
    searchDispatch({ type: SET_SEARCH_ITEMS, searchItems: [] })
    setIsAllSelected(!isAllSelected)
  }, [searchDispatch, isAllSelected])

  const handlerSearchTerm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [])

  const handlerClearSearchTerm = useCallback(() => setSearchTerm(''), [])

  return <>
    <CruiseFilterInput
      isOpen={isOpen}
      label="Cruising to"
      placeholder={isOpen ? 'Search destinations' : 'All destinations'}
      inputValue={inputValue}
      testId="cruise-filter-footer-apply"
      formFieldRef={inputTriggerRef}
      selectedItems={searchItems.length}
      onClick={show}
      handleSearchTerm={handlerSearchTerm}
      handlerClearSearchTerm={handlerClearSearchTerm}
      isChipMode={isChipMode}
    />

    <DropdownSheet
      size={dropdownSize || 'fill-anchor'}
      anchorRef={dropdownAnchorRef}
      triggerRef={inputTriggerRef}
      open={isOpen}
      onClose={close}
      title={isChipMode ? 'Cruising to' : undefined}
      subtitle={!isChipMode ? subtitle : undefined}
      hasDismissButton={isChipMode}
      headerExtension={isChipMode && <FilterSearchInput
        placeholder="Search for destinations"
        onChange={setSearchTerm}
        value={inputValue}
      />}
      primaryActionProps={{
        'data-testid': 'cruise-filter-footer-apply',
        children: 'Apply',
        onClick: handleOnApply,
      }}
      secondaryActionProps={{
        'data-testid': 'cruise-filter-footer-clear-all',
        kind: 'tertiary',
        children: 'Reset all',
        onClick: handleOnClear,
      }}
      footerStart={!isChipMode && <FormattedCruiseLineSearchSummary
        initialFilters={initialFilters}
        shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
      />}
    >
      <CruiseLocationContent
        locations={locations}
        searchTerm={searchTerm}
        searchItems={searchItems}
        isAllSelected={isAllSelected}
        onChange={handleOnChange}
        onSelectAll={handleSelectAll}
        hideImages={hideImages}
        initialFilters={initialFilters}
      />
    </DropdownSheet>
  </>
}

export default CruiseLocationDesktopInput
