import clsx from 'clsx'
import Input from 'components/Common/Form/Input/Input'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { ProductPaletteContext } from 'contexts/ProductPaletteContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { rem } from 'polished'
import React, { forwardRef, InputHTMLAttributes, useContext } from 'react'
import styled from 'styled-components'

const KNOB_SIZE = 20
const PADDING = 2

const InputElement = styled(Input)`
  --unchecked-stroke: transparent;
  --unchecked-background: ${props => props.theme.palette.neutral.default.four};
  --unchecked-hover-background: ${props => props.theme.palette.neutral.default.five};
  --checked-background: ${props => props.theme.palette.brand.primary.normal};
  --checked-hover-background: ${props => props.theme.palette.brand.primary.hover};
  appearance: none;
  flex-shrink: 0;
  padding: ${rem(PADDING)};
  height: ${rem(24)};
  width: ${rem(KNOB_SIZE * 2)};
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadius.round};
  box-shadow: inset 0 0 0 ${rem(1)} var(--unchecked-stroke);
  background-color: var(--unchecked-background);
  transition: 200ms background-color, 200ms box-shadow;

  &:checked {
    background-color: var(--checked-background);
    box-shadow: inset 0 0 0 ${rem(1)} var(--checked-stroke);
  }

  &:not(:disabled) {
    &:invalid {
      --unchecked-background: ${props => props.theme.palette.messaging.critical.lightBackground};
      --unchecked-stroke: ${props => props.theme.palette.messaging.critical.normalBackground};
      --checked-background: ${props => props.theme.palette.messaging.critical.lightBackground};
      --checked-stroke: ${props => props.theme.palette.messaging.critical.normalBackground};
    }

    ${mediaHoverable} {
      &:hover {
        background-color: var(--unchecked-hover-background);

        &:checked {
          background-color: var(--checked-hover-background);
        }
      }
    }

    &:focus {
      outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid var(--palette-neutral-default-four));
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.palette.neutral.default.five};

    &:checked {
      background-color: ${props => props.theme.palette.neutral.default.five};
    }
  }

  &::before {
    content: "";
    display: block;
    width: ${rem(KNOB_SIZE)};
    height: ${rem(KNOB_SIZE)};
    border-radius: ${props => props.theme.borderRadius.round};
    background: ${props => props.theme.palette.neutral.default.eight};
    box-shadow: ${props => props.theme.shadow.bottom.small};
    transition: 200ms transform;
  }

  &:checked::before { transform: translateX(${rem(KNOB_SIZE - (PADDING * 2))}); }

  &.product-palette-lux-plus {
    --checked-background: ${props => props.theme.palette.product.luxPlus.background};
    --checked-hover-background: ${props => props.theme.palette.product.luxPlus.hover};
  }
  &.product-palette-tier-bronze {
    --checked-background: ${props => props.theme.palette.product.luxLoyalty.bronze.darkForeground};
    --checked-hover-background: ${props => props.theme.palette.product.luxLoyalty.bronze.darkForeground};
    --checked-stroke: ${props => props.theme.palette.product.luxLoyalty.bronze.darkBackground};
    --unchecked-background: ${props => props.theme.palette.product.luxLoyalty.bronze.normalBackground};
    --unchecked-hover-background: ${props => props.theme.palette.product.luxLoyalty.bronze.normalForeground};
    --unchecked-stroke: ${props => props.theme.palette.product.luxLoyalty.bronze.darkForeground};
  }
  &.product-palette-tier-silver {
    --checked-background: ${props => props.theme.palette.product.luxLoyalty.silver.darkForeground};
    --checked-hover-background: ${props => props.theme.palette.product.luxLoyalty.silver.darkForeground};
    --checked-stroke: ${props => props.theme.palette.product.luxLoyalty.silver.darkBackground};
    --unchecked-background: ${props => props.theme.palette.product.luxLoyalty.silver.normalBackground};
    --unchecked-hover-background: ${props => props.theme.palette.product.luxLoyalty.silver.normalForeground};
    --unchecked-stroke: ${props => props.theme.palette.product.luxLoyalty.silver.darkForeground};
  }
  &.product-palette-tier-gold {
    --checked-background: ${props => props.theme.palette.product.luxLoyalty.gold.darkForeground};
    --checked-hover-background: ${props => props.theme.palette.product.luxLoyalty.gold.darkForeground};
    --checked-stroke: ${props => props.theme.palette.product.luxLoyalty.gold.darkBackground};
    --unchecked-background: ${props => props.theme.palette.product.luxLoyalty.gold.normalBackground};
    --unchecked-hover-background: ${props => props.theme.palette.product.luxLoyalty.gold.normalForeground};
    --unchecked-stroke: ${props => props.theme.palette.product.luxLoyalty.gold.darkForeground};
  }
  &.product-palette-tier-platinum {
    --checked-background: ${props => props.theme.palette.product.luxLoyalty.platinum.darkForeground};
    --checked-hover-background: ${props => props.theme.palette.product.luxLoyalty.platinum.darkForeground};
    --checked-stroke: ${props => props.theme.palette.product.luxLoyalty.platinum.darkBackground};
    --unchecked-background: ${props => props.theme.palette.product.luxLoyalty.platinum.normalBackground};
    --unchecked-hover-background: ${props => props.theme.palette.product.luxLoyalty.platinum.normalForeground};
    --unchecked-stroke: ${props => props.theme.palette.product.luxLoyalty.platinum.darkForeground};
  }
`

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'size' | 'children' | 'value' | 'defaultValue'> {
}

const Switch = forwardRef<HTMLInputElement, Props>(function SwitchButton(
  {
    className,
    name,
    ...rest
  },
  ref,
) {
  const productPalette = useContext(ProductPaletteContext)

  return <InputElement
    {...rest}
    ref={ref}
    name={name ? `${name}::boolean` : undefined}
    type="checkbox"
    className={clsx(
      className,
      productPalette ? `product-palette-${productPalette}` : undefined,
    )}
  />
})

export default Switch
