import React, { useCallback } from 'react'

import SaveModal from './SaveModal'
import { SaveItemsCallbackResult } from '../BookmarkButton/utils'

import { useEditableTrips } from 'tripPlanner/hooks/api/trip'
import { useMoveItemToOtherTrip } from 'tripPlanner/hooks/api/tripItem'
import useBookmarkSnackbarHandlers from 'tripPlanner/hooks/bookmarks/useSavedItemSnackbarHandlers'
import { ItemType } from 'tripPlanner/types/tripItem'

interface Props {
  tripId: string
  tripItemId: string
  tripItemType: ItemType
}

function SelectDifferentTrip({ tripId, tripItemId, tripItemType }: Props) {
  const { data: trips } = useEditableTrips()

  const { showSaveErrorSnackbar } = useBookmarkSnackbarHandlers()

  const { mutateAsync: moveItem, isLoading: isMovingItem } =
    useMoveItemToOtherTrip(tripId, tripItemId, {
      onError: (_error, destinationTripId) => {
        const destinationTrip = trips?.find((t) => t.id === destinationTripId)
        if (destinationTrip) {
          showSaveErrorSnackbar(destinationTrip.name)
        }
      },
    })

  const moveTripItem = useCallback(
    async(tripId: string): Promise<SaveItemsCallbackResult> => {
      await moveItem(tripId)
      return {
        savedItemIds: [tripItemId],
      }
    },
    [moveItem, tripItemId],
  )

  return (
    <SaveModal
      createTripItems={moveTripItem}
      itemTypeLabel={tripItemType === 'FLIGHT' ? 'flight' : 'offer'}
      isCreatingItem={isMovingItem}
    />
  )
}

export default SelectDifferentTrip
