import { ITEM_STATUS_CANCELLED, ITEM_STATUS_CREATED, ITEM_STATUS_PENDING } from 'constants/cart'
import {
  DOWNGRADE_TYPE_BNBL,
  DOWNGRADE_TYPE_CHANGE_DATES_BEDBANK,
  DOWNGRADE_TYPE_CHANGE_DATES_TACTICAL_AO_HOTEL,
  PACKAGE_CHANGE_DOWNGRADE_TYPES,
} from 'constants/reservation'

const downgradeTypes = new Set([
  ...PACKAGE_CHANGE_DOWNGRADE_TYPES,
  DOWNGRADE_TYPE_CHANGE_DATES_TACTICAL_AO_HOTEL,
  DOWNGRADE_TYPE_CHANGE_DATES_BEDBANK,
  DOWNGRADE_TYPE_BNBL,
])

export function isDowngraded(item: App.OrderItem | App.OrderBedbankItem) {
  return downgradeTypes.has(item.downgradeType) && item.status === ITEM_STATUS_CANCELLED
}

const pendingStatuses = new Set([ITEM_STATUS_CREATED, ITEM_STATUS_PENDING])
export function isPendingDowngrade(item: App.OrderItem | App.OrderBedbankItem) {
  return downgradeTypes.has(item.downgradeType) && pendingStatuses.has(item.status)
}

/**
 * Hide items cancelled due to downgrade
 */
export function hideDowngrades(
  items: Array<App.OrderItem>,
): Array<App.OrderItem> {
  const nonPendingItems = items.filter((i) => !isPendingDowngrade(i))
  const nonDowngradedItems = nonPendingItems.filter((i) => !isDowngraded(i))
  // in case there's no items in the list, show 1 and make sure it's not pending downgrade
  return nonDowngradedItems.length ? nonDowngradedItems : nonPendingItems.slice(-1)
}

export function hideBedbankDowngrades(
  items: Array<App.OrderBedbankItem>,
): Array<App.OrderBedbankItem> {
  const nonPendingItems = items.filter((i) => !isPendingDowngrade(i))
  const nonDowngradedItems = nonPendingItems.filter((i) => !isDowngraded(i))
  // in case there's no items in the list, show 1 and make sure it's not pending downgrade
  return nonDowngradedItems.length ? nonDowngradedItems : nonPendingItems.slice(-1)
}
