import React, { useCallback, useContext, useRef, useMemo, ComponentProps } from 'react'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useToggle from 'hooks/useToggle'
import FormattedCruiseLineSearchSummary from '../CruiseLineSelect/FormattedCruiseLineSearchSummary'
import CruiseWhenSelectContent from './CruiseWhenSelectContent'
import CruiseFilterInput from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseFilterInput'

interface Props {
  inputValue: string
  onChange: (values: App.CruiseGlobalFilters) => void
  dropdownAnchorRef: React.RefObject<HTMLElement>
  isChipMode?: boolean
  calendarSize?: 'S' | 'M';
  dropdownSize?: ComponentProps<typeof DropdownSheet>['size']
  initialFilters?: App.CruiseInitialFilters
  shouldIgnoreFlashOffers?: boolean
}

function CruiseWhenSelectDesktop({
  inputValue,
  onChange,
  dropdownAnchorRef,
  isChipMode,
  calendarSize,
  dropdownSize,
  initialFilters,
  shouldIgnoreFlashOffers,
}: Props) {
  const [isOpen, toggleModal, , closeModal] = useToggle(false)
  const { flexibleMonths, durationRange, durationMax, durationMin, checkinDate, checkoutDate } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const onApply = useCallback(() => {
    onChange({
      flexibleMonths,
      durationMax,
      durationMin,
      checkinDate,
      checkoutDate,
      durationRange,
    })
    closeModal()
  }, [onChange, flexibleMonths, durationMax, durationMin, checkinDate, checkoutDate, durationRange, closeModal])

  const onClear = useCallback(() => {
    searchDispatch({ type: GlobalSearchStateActions.SET_DURATION_RANGE, min: undefined, max: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: '' })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION_RANGE, durationRange: [] })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: undefined })
  }, [searchDispatch])

  const hasSelectedData = useMemo(() => {
    return !!flexibleMonths?.length || !!durationMax || !!durationMin || !!checkinDate || !!checkoutDate
  }, [flexibleMonths, durationMax, durationMin, checkinDate, checkoutDate])

  const formFieldRef = useRef<HTMLButtonElement>(null)

  return <>
    <CruiseFilterInput
      isOpen={isOpen}
      label="When"
      placeholder="Anytime"
      inputValue={inputValue}
      testId="cruise-when-select-input"
      formFieldRef={formFieldRef}
      onClick={toggleModal}
      isChipMode={isChipMode}
      hasSelectedData={hasSelectedData}
    />

    <DropdownSheet
      data-testid="cruise-when-select-dropdown"
      anchorRef={dropdownAnchorRef}
      size={dropdownSize || 'fill-anchor'}
      triggerRef={formFieldRef}
      open={isOpen}
      onClose={closeModal}
      title={isChipMode ? 'When' : undefined}
      hasDismissButton={isChipMode}
      primaryActionProps={{
        'data-testid': 'cruise-filter-footer-apply',
        children: 'Apply',
        onClick: onApply,
      }}
      secondaryActionProps={{
        'data-testid': 'cruise-filter-footer-clear-all',
        kind: 'tertiary',
        children: 'Reset all',
        onClick: onClear,
      }}
      footerStart={<FormattedCruiseLineSearchSummary
        initialFilters={initialFilters}
        shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
      />}
    >
      <CruiseWhenSelectContent onChange={onChange} calendarSize={calendarSize} initialFilters={initialFilters} />
    </DropdownSheet>
  </>
}

export default CruiseWhenSelectDesktop
