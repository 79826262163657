import StickyPrompt from 'components/Common/StickyPromptCard/StickyPrompt'
import { rem } from 'polished'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import useClickOutside from 'hooks/useClickOutside'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { matchPath } from 'react-router'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import useCopyToClipboard from 'hooks/useCopyToClipboard'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import LineCopyIcon from 'components/Luxkit/Icons/line/LineCopyIcon'
import { fetchTargetedPromoDetails, removePromoDisplay } from 'actions/MarketingAction'
import TextButton from 'components/Luxkit/Button/TextButton'
import { getCookies, setCookie } from 'lib/web/cookieUtils'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { useHeaderIcon } from 'hooks/useCurrentSiteTakeover'
import * as Analytics from 'analytics/analytics'
import { promotionClickEvent, promotionViewEvent } from 'analytics/snowplow/events'

const PROMO_DISPLAY_COOKIE_NAME = 'promoDisplay'
// To ensure we delay the promo display by at least 1 second
const PROMO_DISPLAY_DELAY_MOFIDIER = 1000
const UNKNOWN_PROMO_CODE = 'UNKNOWN_PROMO_CODE'

const AccessWrapper = styled.div`
  position: relative;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${rem(20)};
  top: ${rem(20)};
`

const AccessPrompt = styled(StickyPrompt)`
  padding: ${rem(24)};

  ${mediaQueryUp.tablet} {
    width: ${rem(400)};
  }
`

const PromoCodeContainer = styled.div`
  padding: 8px 16px;
  background-color: ${props => props.theme.palette.neutral.default.six};
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const getPromoDisplayConfig = (pathname: string, promoDisplayConfig: App.PromoDisplayConfigMap | undefined, region: string) => {
  if (!promoDisplayConfig) {
    return
  }
  return Object.values(promoDisplayConfig)?.find((config) => !!matchPath(pathname, { path: `/${region}${config.urlPath}`, exact: true }))
}

function PromoDisplayPrompt() {
  const promptRef = useRef<HTMLDivElement>(null)
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const currentRegion = useAppSelector(state => state.geo.currentRegionCode)
  const isLoggedIn = useAppSelector(state => selectLoggedIn(state))
  const storedPromoDisplayConfig = useAppSelector(state => state.marketing.promoDisplayConfigs)
  const [promoDisplayConfig, setPromoDisplayConfig] = useState<App.PromoDisplayConfig | undefined>(getPromoDisplayConfig(currentPath, storedPromoDisplayConfig, currentRegion))
  const [show, setShow] = useState<boolean>(false)
  const [, copy] = useCopyToClipboard()
  const [isCopied, setIsCopied] = useState(false)
  const dispatch = useAppDispatch()
  const passAuthStateCheck = useMemo(() => {
    if (promoDisplayConfig?.useTargetedPromo && !isLoggedIn) {
      return false
    }
    if (promoDisplayConfig?.authState === 'logged_in_and_out') {
      return true
    }
    if (promoDisplayConfig?.authState === 'logged_in' && isLoggedIn) {
      return true
    }
    if (promoDisplayConfig?.authState === 'logged_out' && !isLoggedIn) {
      return true
    }
    return false
  }, [promoDisplayConfig, isLoggedIn])

  const HeaderIcon = useHeaderIcon(currentRegion, promoDisplayConfig)

  const setPromoDisplayCookie = useCallback((promoDisplayId: string) => {
    const allCookies = getCookies()
    const promoDisplayCookie = allCookies[PROMO_DISPLAY_COOKIE_NAME]
    setCookie(PROMO_DISPLAY_COOKIE_NAME, `${promoDisplayCookie ? promoDisplayCookie + ',' : ''}${promoDisplayId}`, 30 * 24 * 60 * 60, '/', 'Lax')
  }, [])

  useEffect(() => {
    if (!isLoggedIn && promoDisplayConfig?.useTargetedPromo) {
      setPromoDisplayConfig(undefined)
    } else {
      setPromoDisplayConfig(getPromoDisplayConfig(currentPath, storedPromoDisplayConfig, currentRegion))
    }
  }, [currentPath, currentRegion, storedPromoDisplayConfig, isLoggedIn, promoDisplayConfig])

  useEffect(() => {
    if (!promoDisplayConfig) {
      return
    }
    if (promoDisplayConfig.isTargetedPromo) {
      if (isLoggedIn) {
        dispatch(fetchTargetedPromoDetails(promoDisplayConfig.promoDisplayId))
      }
      return
    }
    const timer = setTimeout(() => setShow(!!promoDisplayConfig), promoDisplayConfig.delayDuration * PROMO_DISPLAY_DELAY_MOFIDIER)
    return () => clearTimeout(timer)
  }, [promoDisplayConfig, dispatch, isLoggedIn, setShow])

  const onClickCopyIcon = useCallback(() => {
    copy(promoDisplayConfig?.codeName ?? '')
    setIsCopied(true)
    Analytics.trackEvent(promotionClickEvent({
      promotionContext: { promotionName: 'promo_display', promotionType: 'prompt' },
      promotionCodeContext: { codeName: promoDisplayConfig?.codeName ?? UNKNOWN_PROMO_CODE },
    }))
  }, [promoDisplayConfig, copy])

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false)
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [isCopied])

  useEffect(() => {
    if (show) {
      Analytics.trackEvent(promotionViewEvent({
        promotionContext: { promotionName: 'promo_display', promotionType: 'prompt' },
        promotionCodeContext: { codeName: promoDisplayConfig?.codeName ?? UNKNOWN_PROMO_CODE },
      }))
    }
  }, [show, promoDisplayConfig])

  const dismissPrompt = useCallback(() => {
    setShow(false)
  }, [])

  useClickOutside(promptRef, dismissPrompt, show)
  const onClose = useCallback(() => {
    if (promoDisplayConfig?.displayMode === 'appear_once') {
      setPromoDisplayCookie(promoDisplayConfig.promoDisplayId)
      sessionStorage.setItem('promoDisplayId', promoDisplayConfig.promoDisplayId)
      dispatch(removePromoDisplay(promoDisplayConfig.promoDisplayId))
      setPromoDisplayConfig(undefined)
    }
    dismissPrompt()
  }, [promoDisplayConfig, dismissPrompt, dispatch, setPromoDisplayCookie])

  if (!passAuthStateCheck) {
    return null
  }

  return <AccessWrapper>
    <AccessPrompt show={show} sticky={false} ref={promptRef}>
      <CloseButton
        kind="tertiary"
        shape="square"
        onClick={onClose}
      >
        <LineTimesIcon />
      </CloseButton>
      <Group direction="vertical" gap={16} fullWidth>
        <Group direction="vertical" gap={16} horizontalAlign="center">
          {HeaderIcon}
          <Heading variant="heading3">{promoDisplayConfig?.headerText}</Heading>
        </Group>
        <BodyTextBlock variant="medium">{promoDisplayConfig?.bodyText}</BodyTextBlock>
        {promoDisplayConfig?.expiresAt && <BodyTextBlock variant="medium">This promotion code will expire on {(new Date(promoDisplayConfig.expiresAt)).toString()}</BodyTextBlock>}
        <PromoCodeContainer>
          <BodyTextBlock variant="medium" weight="bold">
            {promoDisplayConfig?.codeName}
          </BodyTextBlock>
          <IconButton
            kind="tertiary"
            size="small"
            shape="circle"
            onClick={onClickCopyIcon}
          >
            {isCopied ? <LineCheckIcon /> : <LineCopyIcon />}
          </IconButton>
        </PromoCodeContainer>
        <TextButton kind="primary" onClick={onClickCopyIcon}>Copy this code</TextButton>
        {promoDisplayConfig?.displayMode === 'appear_once' && <TextButton kind="secondary" onClick={onClose}>I do not want to see this again.</TextButton>}
      </Group>
    </AccessPrompt>
  </AccessWrapper>
}

export default React.memo(PromoDisplayPrompt)
