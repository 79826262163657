import AssistChip from 'components/Luxkit/Chips/AssistChip'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import Group from 'components/utils/Group'
import useToggle from 'hooks/useToggle'
import { rem } from 'polished'
import React, { useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'
import {
  SORT_DEPARTURE_SOON,
  SORT_NEWEST,
  SORT_OPTION_PRICE_ASC,
  SORT_OPTION_PRICE_DESC,
  SORT_OPTION_RECOMMENDED,
} from 'constants/offerListFilters'

const RadioGroup = styled(Group)`
  padding-inline: ${rem(16)};
`

export const CruiseSearchSortOptions = [
  SORT_OPTION_RECOMMENDED,
  SORT_OPTION_PRICE_ASC,
  SORT_OPTION_PRICE_DESC,
  SORT_DEPARTURE_SOON,
  SORT_NEWEST,
]

interface Props {
  sort: string;
  onChange: (newSort: App.OfferListSortByOptions) => void;
}

function CruiseSortDropdown(props: Props) {
  const { onChange, sort } = props

  const [open, toggleSortOpen, , closeSort] = useToggle()
  const triggerRef = useRef<HTMLButtonElement>(null)

  const onSortChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value as App.OfferListSortByOptions)
    closeSort()
  }, [closeSort, onChange])

  const selectedLabel = useMemo(() => {
    return CruiseSearchSortOptions.find(option => option.value === sort)?.label ?? 'Recommended'
  }, [sort])

  return <>
    <AssistChip
      variant="filled"
      size="medium"
      onClick={toggleSortOpen}
      endIcon={<DropdownChevron open={open}/>}
      ref={triggerRef}
    >
      Sort: {selectedLabel}
    </AssistChip>
    <DropdownList
      size="S"
      anchorRef={triggerRef}
      triggerRef={triggerRef}
      open={open}
      onClose={closeSort}
    >
      <RadioGroup direction="vertical" gap={16}>
        {CruiseSearchSortOptions.map(option => <RadioInput
          value={option.value}
          onChange={onSortChange}
          key={option.label}
          checked={option.value ? option.value === sort : !sort}
          name="sort"
        >
          {option.label}
        </RadioInput>)}
      </RadioGroup>
    </DropdownList>
  </>
}

export default React.memo(CruiseSortDropdown)
