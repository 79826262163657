import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { getAccommodationPayable, getTourV2Total } from './accommodation'
import getPayableTotal from './getPayableTotal'
import getAccommodationDepositAmount from './getAccommodationDepositAmount'
import { isTourV2Bundled } from './deposit'

const getDepositAmountBeforeCreditApplied = createSelector(
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => getAccommodationPayable(state),
  (state: App.State) => getAccommodationDepositAmount(state),
  (state: App.State) => isTourV2Bundled(state),
  (state: App.State) => getTourV2Total(state),
  (payableTotal, accommodationPayable, depositAmount, isTourV2Bundled, tourV2Total): number => {
    if (isTourV2Bundled) {
      return floatify(payableTotal - tourV2Total + depositAmount)
    }
    return floatify(payableTotal - accommodationPayable + depositAmount)
  },
)

export default getDepositAmountBeforeCreditApplied
