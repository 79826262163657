import { useMemo } from 'react'
import useQueryParams from 'hooks/useQueryParams'
import { LUXURY_ESCAPES } from 'constants/brands'
import config from 'constants/config'

export interface CruiseFacetFiltersParams {
  queryParamsFilters: CruiseQueryParamsFilters;
  facetParams: App.CruiseSearchFacetParams;
  mappedFilters: CruiseQueryParamsFiltersMapped;
}

interface CruiseQueryParamsFilters {
  price: {
    min?: number;
    max?: number;
  };
  cabinTypes?: string;
  cruiseShips?: Set<string>;
  promotions?: Set<string>;
  isSpecialOffers?: Set<string>;
  isLuxExclusivePromotion?: Set<string>;
  isLuxPlus?: Set<string>;
}

interface CruiseQueryParamsFiltersMapped {
  cruiseShips: Array<string>;
  promotions: Array<string>;
  isLuxPlus?: boolean;
  isLuxExclusivePromotion?: boolean;
  isSpecialOffers?: boolean;
  cabinTypes?: string;
  minPrice?: number;
  maxPrice?: number;
  price: {
    min?: number;
    max?: number;
  };
}

const isLuxuryEscapes = config.BRAND === LUXURY_ESCAPES
interface FiltersProps {
  filters: App.OfferListFilters;
}

export default function useCruiseFacetFiltersParams({ filters }: FiltersProps): CruiseFacetFiltersParams {
  const queryParams = useQueryParams()

  const queryParamsFilters = useMemo<CruiseQueryParamsFilters>(() => {
    const luxExclusiveValue = queryParams.get('isLuxExclusivePromotion')
    const isLuxPlus = queryParams.get('isLuxPlus')
    const priceMin = queryParams.get('minPrice')
    const priceMax = queryParams.get('maxPrice')
    const cabinTypes = queryParams.get('cabinTypes') || undefined

    return {
      price: {
        min: priceMin ? parseInt(priceMin) : undefined,
        max: priceMax ? parseInt(priceMax) : undefined,
      },
      cabinTypes,
      cruiseShips: new Set(queryParams.getAll('cruiseShips')),
      promotions: new Set(queryParams.getAll('promotions')),
      isSpecialOffers: queryParams.get('isSpecialOffers') ? new Set(['Special offers']) : undefined,
      isLuxExclusivePromotion: luxExclusiveValue && isLuxuryEscapes ? new Set(['Lux Exclusive']) : undefined,
      isLuxPlus: isLuxPlus && isLuxuryEscapes ? new Set(['Bonus inclusions']) : undefined,
    }
  }, [queryParams])

  const mappedFilters = useMemo<CruiseQueryParamsFiltersMapped>(() => {
    const isLuxPlus = queryParamsFilters?.isLuxPlus?.size! > 0 || undefined
    const isLuxExclusivePromotion = queryParamsFilters?.isLuxExclusivePromotion?.size! > 0 || undefined
    const isSpecialOffers = queryParamsFilters?.isSpecialOffers?.size! > 0 || undefined

    return {
      ...queryParamsFilters,
      cabinTypes: queryParamsFilters?.cabinTypes,
      minPrice: queryParamsFilters?.price?.min,
      maxPrice: queryParamsFilters?.price?.max,
      isSpecialOffers,
      isLuxExclusivePromotion,
      isLuxPlus,
      cruiseShips: Array.from(queryParamsFilters?.cruiseShips || []),
      promotions: Array.from(queryParamsFilters?.promotions || []),
    }
  }, [queryParamsFilters])

  const facetParams = useMemo<App.CruiseSearchFacetParams>(() => ({
    facetTypes: ['ship_names', 'cabin_types', 'special_offers', 'lux_exclusive', 'cruise_prices', 'lux_plus'],
    cruiseLines: filters.cruiseLines,
    departurePlaceId: filters.departurePlaceId,
    departureIds: filters.departureIds,
    rateCodes: filters.rateCodes,
    destinationId: filters.destinationId,
    destinationIds: filters.destinationIds,
    departureMonths: filters.departureMonths,
    durationMin: filters.durationMin,
    durationMax: filters.durationMax,
    departureStartDate: filters.departureStartDate,
    departureEndDate: filters.departureEndDate,
    durationRange: filters.durationRange,
    ...mappedFilters,
  }), [
    filters.cruiseLines,
    filters.departurePlaceId,
    filters.departureIds,
    filters.rateCodes,
    filters.destinationId,
    filters.destinationIds,
    filters.departureMonths,
    filters.durationMin,
    filters.durationMax,
    filters.departureStartDate,
    filters.departureEndDate,
    filters.durationRange,
    mappedFilters,
  ])

  return {
    queryParamsFilters,
    facetParams,
    mappedFilters,
  }
}
