import request from 'api/requestUtils'
import { getOrderById, getFullOrder } from './order'
import asyncPoll from 'lib/promise/asyncPoll'

interface ServerRefundRequest {
  id_refund_request: string;
  fk_customer_id: string;
  fk_order_id: string;
  fk_item_id: string;
  vendor_salesforce_id: string;
  status: 'approved' | 'cancelled' | 'rejected' | 'pending' | 'expired' | 'auto_rejected_pending' | 'auto_rejected';
  fk_reviewer_id: string | null;
  reason: string;
  created_at: string;
  updated_at: string;
  refund_option: 'credit' | 'letter' | 'back_to_origin' | 'insurance_claim' | null;
}

function getRefundRequestStatus(serverRequest: ServerRefundRequest): App.RefundRequestStatus {
  switch (serverRequest.status) {
    case 'auto_rejected':
      return 'rejected'
    case 'auto_rejected_pending':
      return 'pending'
    default:
      return serverRequest.status
  }
}

function mapRefundRequest(serverRequest: ServerRefundRequest): App.RefundRequest {
  return {
    id: serverRequest.id_refund_request,
    customerId: serverRequest.fk_customer_id,
    orderId: serverRequest.fk_order_id,
    itemId: serverRequest.fk_item_id,
    status: getRefundRequestStatus(serverRequest),
    reviewerId: serverRequest.fk_reviewer_id ?? undefined,
    reason: serverRequest.reason,
    createdAt: serverRequest.created_at,
    updatedAt: serverRequest.updated_at,
    refundOption: serverRequest.refund_option ?? undefined,
  }
}

export async function getRefundRequestByItem(
  orderId: string,
  itemId: string,
): Promise<App.RefundRequest | undefined> {
  return request
    .get<App.ApiResponse<Array<ServerRefundRequest>>>(
      `/api/orders/refund-request/order/${orderId}/item/${itemId}`, { credentials: 'include' },
    )
    .then((response) => {
      if (response.result.length > 0) {
        return mapRefundRequest(response.result[0])
      }
      return undefined
    })
}

export async function getRefundRequestsByOrder(
  orderId: string,
): Promise<Array<App.RefundRequest>> {
  return request
    .get<App.ApiResponse<Array<ServerRefundRequest>>>(
      `/api/orders/refund-request/order/${orderId}`, { credentials: 'include' },
    )
    .then((response) => response.result.map(mapRefundRequest))
}

export async function createRefundRequest(
  orderId: string,
  itemId: string,
  reason: string,
  reasonClassification: string,
  attachments?: Array<File>,
): Promise<App.RefundRequest> {
  const data = new FormData()
  data.append('order_id', orderId)
  data.append('item_id', itemId)
  data.append('reason', reason)
  data.append('reason_classification', reasonClassification)
  if (attachments && attachments?.length > 0) {
    attachments.forEach((file) => {
      data.append('attachments', file)
    })
  }
  return request
    .post<App.ApiResponse<ServerRefundRequest>, any>(
      '/api/orders/refund-request', data, { credentials: 'include' },
    ).then((response) => {
      return mapRefundRequest(response.result)
    })
}

export async function updateRefundRequestStatus(
  refundRequestId: string,
  status: App.RefundRequestStatus,
): Promise<App.RefundRequest> {
  const data = {
    op: 'update_status',
    status,
  }

  return request
    .patch<App.ApiResponse<ServerRefundRequest>, any>(
      `/api/orders/refund-request/${refundRequestId}`, data, { credentials: 'include' },
    )
    .then((response) => mapRefundRequest(response.result))
}

export async function updateRefundRequestRefundOption(
  refundRequestId: string,
  refundOption: App.RefundRequestRefundOption,
): Promise<App.Order> {
  const data = {
    op: 'update_refund_option',
    refund_option: refundOption,
  }

  return request
    .patch<App.ApiResponse<ServerRefundRequest>, { op: string; refund_option: string }>(
      `/api/orders/refund-request/${refundRequestId}`, data, { credentials: 'include' },
    )
    .then(async(response) => {
      const orderId = response.result.fk_order_id
      const orderItemId = response.result.fk_item_id
      await asyncPoll<App.Order>({
        validateFunction: async(order) => {
          const item = order.items.find(
            (updatedItem) => updatedItem.id === orderItemId,
          )
          return !!item && item.status === 'cancelled'
        },
        apiCall: () => getOrderById(orderId),
        maxTime: 30000,
        pollInterval: 500,
      })

      // Setting the option also cancels the item
      // so we need the latest version of the item + order
      // The order will also contain the updated refund request item
      return await getFullOrder(orderId)
    })
}
