import SolidBoltAltIcon from 'components/Luxkit/Icons/solid/SolidBoltAltIcon'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import Caption from 'components/Luxkit/Typography/Caption'
import FormatDecimal from 'components/utils/Formatters/FormatDecimal'
import { OFFER_BOOKINGS_THRESHOLD, ROOM_BOOKINGS_THRESHOLD } from 'constants/offer'
import React from 'react'

type BookingThreshold = 'offer' | 'room'

const ThresholdMap: Record<BookingThreshold, number> = {
  offer: OFFER_BOOKINGS_THRESHOLD,
  room: ROOM_BOOKINGS_THRESHOLD,
}

interface Props {
  /**
   * The number of bookings that have been made
   */
  bookings: number;
  /**
   * The threshhold to apply to the booking counts
   * Internally this label knows what they are, you just
   * pass what type of threshold you want
   */
  threshold: BookingThreshold;
}

function BookingsCountLabel(props: Props) {
  const { bookings, threshold } = props

  if (bookings < ThresholdMap[threshold]) {
    return null
  }

  return <PromotionLabel
    variant="default"
    icon={<SolidBoltAltIcon />}
  >
    <Caption variant="medium" weight="bold">
      Booked <FormatDecimal value={bookings} /> times in 3 days
    </Caption>
  </PromotionLabel>
}

export default BookingsCountLabel
