import { useAppSelector } from 'hooks/reduxHooks'
import { getOfferPalette } from 'lib/offer/offerUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import React, { PropsWithChildren, useMemo } from 'react'

export type ProductPalette = 'default' | 'ultralux' | 'lux-plus' | `tier-${App.LuxLoyaltyTier}`

export const ProductPaletteContext = React.createContext<ProductPalette>('default')

interface PaletteProps {
  /**
   * Explicitly choose the palette you wish to use
   * This will always be used if defined
   */
  palette: ProductPalette;
  /**
   * Optionally pass a 'product' to the provider and it will infer
   * the palette from this product passed
   */
  product?: App.AnyOffer;
}

interface ProductProps {
  /**
   * Explicitly choose the palette you wish to use
   * This will always be used if defined
   */
  palette?: ProductPalette;
  /**
   * Optionally pass a 'product' to the provider and it will infer
   * the palette from this product passed
   */
  product: App.AnyOffer;
}

type Props = PaletteProps | ProductProps

export default function ProductPaletteProvider(props: PropsWithChildren<Props>) {
  const { product, palette } = props
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)

  const finalPalette = useMemo(() => {
    if (palette) {
      // always prefer the explicit palette value passed in
      return palette
    } else if (product) {
      // but if we're given a product, try get the correct palette for the product given
      return getOfferPalette(product, luxPlusEnabled)
    }
    return 'default'
  }, [luxPlusEnabled, palette, product])

  return <ProductPaletteContext.Provider value={finalPalette}>
    {props.children}
  </ProductPaletteContext.Provider>
}
