import { RefundProtectQuoteRequest } from 'api/insurance'
import { FOURTEEN_DAYS_BEFORE_CHECK_IN, SEVEN_DAYS_AFTER_PURCHASE, TWENTY_ONE_DAYS_BEFORE_CHECK_IN } from 'constants/cancellationPolicies'
import { getFlexibleCancellationDays, isFlexibleCancellationPolicy, isWithinChangeOfMindPolicy } from 'lib/offer/cancellationPolicyUtils'
import moment from 'moment'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK, OFFER_TYPE_HOTEL } from 'constants/offer'
import getObjectKey from 'lib/object/getObjectKey'

interface CancellationPolicyInfo {
  cancellationType?: 'free' | 'flexible';
  cancelUntilFullRefundDate: moment.Moment;
  cancelAfterNonRefundableDate?: moment.Moment;
  cancellationAfterChangeOfMindPeriod: boolean;
}

export function getBookingProtectionQuoteKey(quotePayload: RefundProtectQuoteRequest | null): string {
  if (!quotePayload) {
    return 'default'
  }

  return getObjectKey(quotePayload)
}

function showFreeCancellation(
  checkInDate?: string | moment.Moment,
  cancellationPolicy?: App.OfferCancellationPolicy,
) {
  if (!cancellationPolicy) {
    return false
  }
  if (cancellationPolicy.type !== 'post-purchase-seven-days') {
    return false
  }
  if (!checkInDate) {
    return true
  } else {
    return moment(checkInDate).subtract(21, 'days').isAfter(moment().startOf('day'))
  }
}

function showFlexibleCancellation(
  checkInDate?: string,
  cancellationPolicy?: App.OfferCancellationPolicy,
) {
  if (!cancellationPolicy) {
    return false
  }
  if (!isFlexibleCancellationPolicy(cancellationPolicy)) {
    return false
  }
  if (!checkInDate) {
    return true
  } else {
    const days = getFlexibleCancellationDays(cancellationPolicy.type)
    const withinChangeOfMind = isWithinChangeOfMindPolicy(new Date(), checkInDate)
    return withinChangeOfMind || moment(checkInDate).subtract(days, 'days').isAfter(moment().startOf('day'))
  }
}

export function isBookingProtectionAvailableForAccommodationOffer(offer: App.AnyOffer): boolean {
  if (offer.parentType === OFFER_TYPE_HOTEL) {
    if (offer.type === OFFER_TYPE_ALWAYS_ON) return true
    if (offer.type === OFFER_TYPE_HOTEL) return true
    if (offer.type === OFFER_TYPE_BED_BANK) return true
  }

  return false
}

export function getCancellationPolicyInfo(
  checkInDate?: string,
  cancellationPolicy?: App.OfferCancellationPolicy,
): CancellationPolicyInfo {
  const freeCancellation = showFreeCancellation(checkInDate, cancellationPolicy)
  const flexibleCancellation = showFlexibleCancellation(checkInDate, cancellationPolicy)
  const cancellationType = freeCancellation ? 'free' : flexibleCancellation ? 'flexible' : undefined
  let startBasedOnToday: moment.Moment = moment().add(SEVEN_DAYS_AFTER_PURCHASE, 'days')
  let cancelUntilFullRefundDate: moment.Moment = moment()
  let cancelAfterNonRefundableDate: moment.Moment | undefined
  let cancellationAfterChangeOfMindPeriod = false

  if (freeCancellation) {
    const startBasedOnCheckIn = checkInDate && moment(checkInDate).subtract(TWENTY_ONE_DAYS_BEFORE_CHECK_IN, 'days')
    if (startBasedOnCheckIn) {
      cancelUntilFullRefundDate = moment.min(startBasedOnToday, startBasedOnCheckIn)
    }
  } else if (flexibleCancellation && cancellationPolicy && checkInDate) {
    const days = getFlexibleCancellationDays(cancellationPolicy.type)
    let startBasedOnCheckIn = moment(checkInDate).subtract(days, 'days')

    const changeOfMindDeadline = moment(checkInDate).subtract(FOURTEEN_DAYS_BEFORE_CHECK_IN, 'days')
    startBasedOnToday = startBasedOnToday.isBefore(changeOfMindDeadline) ? startBasedOnToday : changeOfMindDeadline

    cancellationAfterChangeOfMindPeriod = startBasedOnToday.isBefore(changeOfMindDeadline) && moment().add(SEVEN_DAYS_AFTER_PURCHASE, 'days').isBefore(moment(checkInDate).subtract(days, 'days'))
    startBasedOnCheckIn = cancellationAfterChangeOfMindPeriod ? startBasedOnCheckIn : startBasedOnToday

    cancelUntilFullRefundDate = startBasedOnToday
    cancelAfterNonRefundableDate = startBasedOnCheckIn
  }

  return {
    cancellationType,
    cancelUntilFullRefundDate,
    cancelAfterNonRefundableDate,
    cancellationAfterChangeOfMindPeriod,
  }
}

export const bookingProtectionCancellationReasons = [
  'Illness/injury (including Covid)',
  'Home emergency',
  'Pre-existing medical condition',
  'Flight disruption',
  'Adverse weather',
  'Theft of documents',
  'Workplace redundancy',
  'Jury service',
  'Court summons',
  'Pregnancy complications',
  'Death of immediate family member',
  'Mechanical breakdown',
  'Relocated for work',
  'Armed forces & emergency services recall',
  'Changes to examination dates',
]

export const flightsBookingProtectionCancellationReasons = [
  'Illness/injury (including Covid)',
  'Pre-existing medical condition',
  'Court summons',
  'Workplace redundancy',
  'Death of immediate family',
  'Public transport failure',
  'Relocated for work',
  'Armed forces & emergency services recall',
  'Home emergency',
  'Theft of documents',
  'Pregnancy complication',
  'Jury service',
  'Flight disruption',
  'Mechanical breakdown',
  'Adverse weather',
  'Changes to examination dates',
]
