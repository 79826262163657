import useAgentHubCommission from 'agentHub/hooks/useAgentHubCommissionV2'
import FormatCurrency from 'components/Common/FormatCurrency'
import PriceRowCommission from 'components/Luxkit/PricePoints/PriceRowCommission'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import config from 'constants/config'
import { useAppSelector } from 'hooks/reduxHooks'
import React from 'react'

type PriceRowCommissionProps = React.ComponentProps<typeof PriceRowCommission>

interface Props {
  size: PriceRowCommissionProps['size'];
  productType: App.ProductType;
  kind?: 'default' | 'plain';
  offerId: string;
  packageId?: string;
  offerLocations?: Array<string>;
  vendorName?: string;
  offerTotal?: number;
}

function PriceRowAgentHubCommission(props: Props) {
  const {
    size,
    productType,
    kind = 'default',
    offerId,
    offerTotal = 0,
    packageId,
  } = props

  const customerView = useAppSelector(state => state.agentHub.customerView)
  const { commissionPercentage, packagesCommission, greatestPackageCommission, minBaseCommission, maxBaseCommission } = useAgentHubCommission(offerId)
  const commissionLoading = useAppSelector(state => state.agentHub.offersCommissionLoading[offerId])
  const packageCommission = packageId ? packagesCommission.find(pkg => pkg.packageId === packageId) : null

  if (config.agentHub.isEnabled && commissionLoading && !customerView) {
    return <TextLoadingBox typography="body-medium" width="8ch" />
  }

  if (!config.agentHub.isEnabled || (!commissionPercentage && !greatestPackageCommission) || !productType || customerView) {
    return null
  }

  if (kind === 'plain') {
    return <>
      {offerTotal > 0 && Boolean(packageCommission?.commissionPercentage) && (
        <BodyTextBlock weight="bold" variant="small" colour="highlight-secondary">
          Earn <FormatCurrency value={offerTotal * ((packageCommission!.commissionPercentage) / 100)} /> commission ({packageCommission!.commissionPercentage}%)
        </BodyTextBlock>
      )}
      {offerTotal > 0 && !packageCommission?.commissionPercentage && (
        <BodyTextBlock weight="bold" variant="small" colour="highlight-secondary">
          Earn <FormatCurrency value={offerTotal * ((commissionPercentage) / 100)} /> commission ({commissionPercentage}%)
        </BodyTextBlock>
      )}
      {offerTotal === 0 && (
        <BodyTextBlock weight="bold" variant="small" colour="highlight-secondary">
          Earn {commissionPercentage}% commission
        </BodyTextBlock>
      )}
    </>
  }

  return <PriceRowCommission
    size={size}
    commissionPercentage={commissionPercentage}
    productType={productType}
    offerTotal={offerTotal}
    multiplePackages={Boolean(greatestPackageCommission)}
    greatestCommission={Math.max(greatestPackageCommission, commissionPercentage)}
    minBaseCommission={minBaseCommission}
    maxBaseCommission={maxBaseCommission}
  />
}

export default PriceRowAgentHubCommission
