import React, { useMemo, useState } from 'react'
import { addMonths, dateIsAfter, dateIsBefore, startOfMonth } from 'lib/datetime/dateUtils'
import BasicCalendar from './BasicCalendar'
import DatePickerDay from './DatePickerDay'
import useMountedEffect from 'hooks/useMountedEffect'
import VerticalSpacer from '../Spacing/VerticalSpacer'
import moment from 'moment'
import VerticalDatePickerHeader from './VerticalDatePickerHeader'

export type DatePickerMonthChangeHandler = (direction: 'forwards' | 'backwards', date: Date) => void;

interface Props {
  value?: Date;
  defaultValue?: Date;
  min: Date;
  max: Date;
  className?: string;
  defaultMonth?: number;
  defaultYear?: number;
  dayRender?: (day: Date, params: { disabled: boolean, selected: boolean, onDayClick?: (day: Date) => void }) => React.ReactNode;
  onDayClick?: (day: Date) => void;
  onMonthChange?: DatePickerMonthChangeHandler;
}

const now = new Date()

function VerticalDatePicker(props: Props) {
  const {
    value,
    defaultValue,
    min,
    max,
    className,
    defaultMonth = now.getMonth(),
    defaultYear = now.getFullYear(),
    dayRender,
    onDayClick,
  } = props
  const [date, setDate] = useState<Date>(value ?? defaultValue ?? new Date(defaultYear, defaultMonth, 1))

  useMountedEffect(() => {
    if (value) {
      // sync what month is on display as our value is set so the value is always visible (as it's set)
      setDate(startOfMonth(value))
    }
  }, [value])

  const monthStr = `${date.getMonth() + 1}`.padStart(2, '0')

  const defaultDayRender = (date: Date) => {
    const disabled = dateIsBefore(date, min) || dateIsAfter(date, max)
    const dateTimestamp = date.getTime()
    const selected = value?.getTime() === dateTimestamp

    if (dayRender) {
      return dayRender?.(date, { disabled, selected, onDayClick })
    } else {
      return <DatePickerDay
        key={dateTimestamp}
        selected={selected}
        date={date}
        disabled={disabled}
        onClick={onDayClick}
      />
    }
  }

  const period = useMemo(() => {
    const monthsDiffs = moment(max).diff(min, 'months')
    const dates: Array<Date> = [min]

    for (let i = 0; i <= monthsDiffs; i++) {
      const prevDate = dates[i]
      dates.push(addMonths(prevDate, 1))
    }

    return dates
  }, [min, max])

  return (
    <VerticalSpacer
      gap={32}
      data-testid="date-picker"
      data-yearmonth={`${date.getFullYear()}-${monthStr}`}
      className={className}
    >
      {period.map(d => (
        <VerticalSpacer gap={16} key={d.toString()}>
          <VerticalDatePickerHeader
            currentDate={d}
          />

          <BasicCalendar
            month={d.getMonth()}
            year={d.getFullYear()}
            dayRender={defaultDayRender}
            fixedHeight={false}
          />
        </VerticalSpacer>
      ))}
    </VerticalSpacer>
  )
}

export default React.memo(VerticalDatePicker)
