import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { getCruiseOfferSnapshot } from 'api/cruises'
import { last } from 'lib/array/arrayUtils'
import moment from 'moment'
import { getAllOffers } from 'selectors/offerSelectors'

async function getCruiseOrderItems(state: App.State, order: App.Order) {
  const { currencyCode, bookingNumber, id: orderId, cruiseItems } = order

  return await Promise.all(
    cruiseItems.map(async(orderItem) => {
      const { cruiseOfferId, bookingId, departureDate, arrivalDate, total, orderId: itemId } = orderItem
      const offers = getAllOffers(state)
      const fullOffer = offers[cruiseOfferId] as App.CruiseOffer
      const offerSnapshot = fullOffer ? undefined : await getCruiseOfferSnapshot(bookingId)

      const { categoryId, productId } = getCategoryAndProductId('cruise')

      const offerLeadPrice = fullOffer?.lowestDeparturePrice?.total
      const departurePort = offerSnapshot?.departurePortName ?? fullOffer?.departurePort
      const destinationPort = offerSnapshot?.destinationName ?? fullOffer?.returnPort

      const [originCity, originCountry] = parsePortName(departurePort)
      const [destinationCity, destinationCountry] = parsePortName(destinationPort)

      const travelDuration = departureDate && arrivalDate ?
        moment(arrivalDate).diff(departureDate, 'days') :
        undefined
      const offerLeadDuration = offerSnapshot?.duration.toString() ?? fullOffer?.duration?.toString()
      const offerLeadUnit = fullOffer?.saleUnit

      return comluxgroup.createItem_1_1_0({
        offerId: cruiseOfferId || bookingId,
        categoryId,
        productId,
        duration: travelDuration,
        travelStart: departureDate,
        travelEnd: arrivalDate,
        price: total,
        offerLeadPrice,
        offerLeadDuration,
        offerLeadUnit,
        currency: currencyCode,
        destinationCity,
        destinationCountry,
        originCity,
        originCountry,
        itemId,
        bookingNumber,
        orderId,
      })
    }),
  )
}

function parsePortName(portName?: string): [string, string] | [] {
  if (!portName) return []
  const parts = portName.split(', ')
  return [parts[0], last(parts)]
}

export default getCruiseOrderItems
