import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import TabButton from 'components/Luxkit/Tabs/TabButton'
import TabButtonsGroup from 'components/Luxkit/Tabs/TabButtonsGroup'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useToggle from 'hooks/useToggle'
import React, { RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState, ComponentProps } from 'react'
import FormattedCruiseLineSearchSummary from '../CruiseLineSelect/FormattedCruiseLineSearchSummary'
import { DeparturePortMapped } from './CruiseDepartureSelect'
import CruiseDepartureSelectList from './CruiseDepartureSelectList'
import FilterSearchInput from 'components/Common/FilterPanel/FilterSearchInput'
import CruiseFilterInput from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseFilterInput'

const SET_SECONDARY_SEARCH_ITEMS = GlobalSearchStateActions.SET_SECONDARY_SEARCH_ITEMS

interface Props {
  onChange: (values: App.CruiseGlobalFilters) => void
  dropdownAnchorRef: RefObject<HTMLElement>
  regions: Array<App.CruiseDepartureRegion>
  setIsAllSelected: (value: boolean) => void
  isAllSelected: boolean
  setSearchTerm: (value: string) => void
  searchTerm: string
  setSelectedRegion: (value: App.CruiseDepartureRegion) => void
  selectedRegion: App.CruiseDepartureRegion
  handleSearchTerm: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleOnChange: (e: Array<App.SearchItem>) => void
  searchDeparturePorts: Array<DeparturePortMapped>
  fetching: boolean
  isChipMode?: boolean
  dropdownSize?: ComponentProps<typeof DropdownSheet>['size']
  shouldDisableRegionTabs?: boolean
  initialFilters?: App.CruiseInitialFilters
  shouldIgnoreFlashOffers?: boolean
}

function CruiseDepartureSelectDesktop({
  regions,
  onChange,
  dropdownAnchorRef,
  setIsAllSelected,
  isAllSelected,
  setSearchTerm,
  searchTerm,
  setSelectedRegion,
  selectedRegion,
  handleSearchTerm,
  handleOnChange,
  searchDeparturePorts,
  fetching,
  isChipMode,
  dropdownSize,
  shouldDisableRegionTabs,
  initialFilters,
  shouldIgnoreFlashOffers,
}: Props) {
  const { secondarySearchItems } = useContext(GlobalSearchStateContext)
  const searchDispatch = useContext(GlobalSearchDispatchContext)

  const [selectedRegions, setSelectedRegions] = useState<Array<string>>([])
  const [isOpen, toggle, show, close] = useToggle(false)

  const handleOnApply = useCallback(() => {
    onChange({ secondarySearchItems })
    toggle()
  }, [onChange, secondarySearchItems, toggle])

  const handleOnClear = useCallback(() => {
    searchDispatch({
      type: SET_SECONDARY_SEARCH_ITEMS,
      secondarySearchItems: [],
    })
    onChange({ secondarySearchItems: [] })
    setIsAllSelected(false)
    setSelectedRegions([])
    setSearchTerm('')
  }, [onChange, searchDispatch, setIsAllSelected, setSearchTerm])

  useEffect(() => {
    if (!isOpen) {
      setSelectedRegion(regions[0])
    } else {
      setSelectedRegion(selectedRegion)
    }
  }, [regions, setSelectedRegion, isOpen, selectedRegion])

  const inputValue = useMemo(() => {
    if (isOpen) return searchTerm
    if (secondarySearchItems.length > 1) {
      const names = secondarySearchItems.slice(0, 1).map((item) => item.format.mainText).join(', ')
      return `${names} + ${secondarySearchItems.length - 1}`
    }
    return secondarySearchItems.map((item) => item.format.mainText).join(', ')
  }, [secondarySearchItems, isOpen, searchTerm])

  const handlerClearSearchTerm = useCallback(() => setSearchTerm(''), [setSearchTerm])
  const inputTriggerRef = useRef<HTMLInputElement>(null)

  return <div data-testid="cruise-departure-select-desktop">
    <CruiseFilterInput
      isOpen={isOpen}
      label="Departing from"
      placeholder={isOpen ? 'Search departing from' : 'All departure ports'}
      inputValue={inputValue}
      testId="cruise-departure-select-desktop-input"
      formFieldRef={inputTriggerRef}
      selectedItems={secondarySearchItems.length}
      onClick={show}
      handleSearchTerm={handleSearchTerm}
      handlerClearSearchTerm={handlerClearSearchTerm}
      isChipMode={isChipMode}
    />

    <DropdownSheet
      data-testid="cruise-departure-select-desktop-dropdown"
      size={dropdownSize || 'fill-anchor'}
      anchorRef={dropdownAnchorRef}
      triggerRef={inputTriggerRef}
      open={isOpen}
      onClose={close}
      subtitle={searchTerm && !isChipMode ? `Search results for "${searchTerm}"` : undefined}
      title={isChipMode ? 'Departing from' : undefined}
      hasDismissButton={isChipMode}
      headerExtension={<>
        {isChipMode && <FilterSearchInput
          placeholder="Search for departures"
          onChange={setSearchTerm}
          value={inputValue}
        />}

        {!searchTerm && !shouldDisableRegionTabs && <TabButtonsGroup data-testid="cruise-departure-select-content-tab-group">
          {regions.map((region) => (
            <TabButton
              key={region.identifier}
              size="medium"
              isActive={region.identifier === selectedRegion.identifier}
              onClick={() => setSelectedRegion(region)}
            >
              {region.name}
            </TabButton>
          ))}
        </TabButtonsGroup>}
      </>}
      primaryActionProps={{
        'data-testid': 'cruise-filter-footer-apply',
        children: 'Apply',
        onClick: handleOnApply,
      }}
      secondaryActionProps={{
        'data-testid': 'cruise-filter-footer-clear-all',
        kind: 'tertiary',
        children: 'Reset all',
        onClick: handleOnClear,
      }}
      footerStart={!isChipMode && <FormattedCruiseLineSearchSummary
        initialFilters={initialFilters}
        shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
      />}
    >
      <CruiseDepartureSelectList
        searchDeparturePorts={searchDeparturePorts}
        searchTerm={searchTerm}
        secondarySearchItems={secondarySearchItems}
        setIsAllSelected={setIsAllSelected}
        isAllSelected={isAllSelected}
        onChange={handleOnChange}
        setSelectedRegions={setSelectedRegions}
        fetching={fetching}
        selectedRegion={selectedRegion}
        selectedRegions={selectedRegions}
        setSelectedRegion={setSelectedRegion}
        regions={regions}
        shouldDisableRegionTabs={shouldDisableRegionTabs}
      />
    </DropdownSheet>
  </div>
}

export default CruiseDepartureSelectDesktop
