import React, { PropsWithChildren, forwardRef, useContext } from 'react'
import styled from 'styled-components'
import _DropdownBody from '../_DropdownBody'
import { rem } from 'polished'
import clsx from 'clsx'
import { DropdownBehaviourStateContext } from '../DropdownBehaviourContexts'

const Container = styled(_DropdownBody)`
  padding: ${rem(16)} 0;
  max-height: ${rem(400)};

  &.no-top-padding {
    padding-top: 0;
  }
  &.no-bottom-padding {
    padding-bottom: 0;
  }
`

interface Props extends PropsWithChildren {}

const DropdownListBody = forwardRef<HTMLDivElement | null, Props>((props, ref) => {
  const { children } = props

  const dropdownContext = useContext(DropdownBehaviourStateContext)

  return <Container
    ref={ref}
    className={clsx({
      'no-bottom-padding': dropdownContext.hasFooter,
      'no-top-padding': dropdownContext.hasHeader,
    })}
  >
    {children}
  </Container>
})

export default DropdownListBody
