import React, { RefObject, ComponentProps, useRef } from 'react'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'

import CruiseLocationDesktopInput from './CruiseLocationDesktopInput'
import CruiseLocationMobileInput from './CruiseLocationMobileInput'

interface Props {
  onChange: (values: App.CruiseGlobalFilters) => void
  isChipMode?: boolean
  /** Use when this component is inside a drawer modal */
  drawerMode?: boolean
  hideImages?: boolean
  dropdownAnchorRef?: RefObject<HTMLElement>
  dropdownSize?: ComponentProps<typeof DropdownSheet>['size']
  hideTrendOptions?: boolean
  initialFilters?: App.CruiseInitialFilters
  shouldIgnoreFlashOffers?: boolean
}

function CruiseLocationFilter({
  isChipMode,
  drawerMode,
  onChange,
  dropdownAnchorRef,
  dropdownSize,
  hideImages,
  initialFilters,
  hideTrendOptions,
  shouldIgnoreFlashOffers,
}: Props) {
  const inputRef = useRef<HTMLDivElement>(null)

  return <div ref={inputRef}>
    <CSSBreakpoint max="mobile">
      <CruiseLocationMobileInput
        isChipMode={isChipMode}
        onChange={onChange}
        hideImages={hideImages}
        hideTrendOptions={hideTrendOptions}
        initialFilters={initialFilters}
        shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
      />
    </CSSBreakpoint>

    <CSSBreakpoint min="tablet">
      {drawerMode && <CruiseLocationMobileInput
        isChipMode={isChipMode}
        onChange={onChange}
        hideImages={hideImages}
        hideTrendOptions={hideTrendOptions}
        initialFilters={initialFilters}
        shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
      />}

      {!drawerMode && <CruiseLocationDesktopInput
        dropdownAnchorRef={dropdownAnchorRef || inputRef}
        onChange={onChange}
        isChipMode={isChipMode}
        dropdownSize={dropdownSize}
        hideImages={hideImages}
        initialFilters={initialFilters}
        hideTrendOptions={hideTrendOptions}
        shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
      />}
    </CSSBreakpoint>
  </div>
}

export default CruiseLocationFilter
