import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useAppDispatch } from 'hooks/reduxHooks'
import LineFilterAltIcon from 'components/Luxkit/Icons/line/LineFilterAltIcon'
import ModalContext from 'contexts/ModalContext'
import CruiseSearchFiltersDrawer from './CruiseSearchExtraFiltersDrawer'
import { deleteSearchParams, setManySearchParamValues } from 'lib/url/searchUrlUtils'
import { CruiseSearchFilterKeys } from 'constants/cruise'
import { replace } from 'connected-react-router'
import { useLocation } from 'react-router'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import CruiseSearchChips, {
  HeaderContainer,
} from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchChips'
import { CruiseSearchSortOptions } from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSortDropdown'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import Carousel from 'components/Luxkit/Carousel/Carousel'
import useToggle from 'hooks/useToggle'

interface Props {
  filters: App.OfferListFilters;
  hideChips?: boolean;
}

const IGNORED_FILTERS = new Set(['sortBy', 'priceBy', 'minPrice', 'maxPrice'])

function CruiseHeaderFilterChip(props: Props) {
  const { filters, hideChips } = props
  const [countSelected, setCountSelected] = useState(0)
  const [isShowChips, toggleIsShowChips, showIsShowChips, hideIsShowChips] = useToggle(true)

  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)
  const { search } = useLocation()

  const facetParams = useMemo(() => ({
    facetTypes: ['ship_names', 'cabin_types', 'special_offers', 'lux_exclusive', 'cruise_prices'],
    cruiseLines: filters.cruiseLines,
    departurePlaceId: filters.departurePlaceId,
    departureIds: filters.departureIds,
    rateCodes: filters.rateCodes,
    destinationId: filters.destinationId,
    destinationIds: filters.destinationIds,
    departureMonths: filters.departureMonths,
    durationMin: filters.durationMin,
    durationMax: filters.durationMax,
    departureStartDate: filters.departureStartDate,
    departureEndDate: filters.departureEndDate,
  } as App.CruiseSearchFacetParams), [filters])

  const [facets] = useCruiseSearchFacets(facetParams)

  const countSelectedFilters = useCallback((newFilters: Record<string, any>) => {
    const countSeleted = Object.keys(newFilters).reduce((acc, key) => {
      if (IGNORED_FILTERS.has(key) || !newFilters[key]) return acc

      const value = typeof newFilters[key] === 'object' ? newFilters[key] : [newFilters[key]]
      return acc + value.length
    }, 0)
    setCountSelected(countSeleted)
  }, [])

  const onShowFilters = useCallback(async() => {
    const newFilters = await showModal<Record<string, any>>(
      <CruiseSearchFiltersDrawer listFilters={filters} onClose={toggleIsShowChips} countSelected={countSelected}/>,
    )

    if (newFilters && Object.getOwnPropertyNames(newFilters).length > 0) {
      const priceFacet = facets.find(facet => facet.category === 'Price')
      if (newFilters.price?.start) {
        newFilters.minPrice = newFilters.price?.start
      }

      if (newFilters.price?.end && newFilters.price?.end !== priceFacet?.max) {
        newFilters.maxPrice = newFilters.price?.end
      }
      const newFiltersMapped: Record<string, any> = {
        ...newFilters,
        price: undefined,
      }

      countSelectedFilters(newFiltersMapped)

      const urlValues = Object.entries(newFiltersMapped)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => ({ paramName: key, paramValue: value }))
      // reset all our possible filter keys
      let nextSearch = deleteSearchParams(search, 'sortBy', ...CruiseSearchFilterKeys)
      // then take whatever is now applicable
      nextSearch = setManySearchParamValues(nextSearch, urlValues)
      dispatch(replace({ search: nextSearch.toString() }))
    } else {
      setCountSelected(0)
      dispatch(replace({ search: undefined }))
    }
  }, [showModal, filters, toggleIsShowChips, countSelected, facets, countSelectedFilters, search, dispatch])

  return <HeaderContainer>
    {hideChips && <FilterChip
      selected={countSelected > 0}
      size="medium"
      startIcon={<LineFilterAltIcon/>}
      onClick={async() => {
        hideIsShowChips()
        await onShowFilters()
        showIsShowChips()
      }}
    >
      All filters {countSelected > 0 && `(${countSelected})`}
    </FilterChip>}

    {!hideChips && <Carousel gap={8} gutterStyle="overflow">
      <FilterChip
        selected={countSelected > 0}
        size="medium"
        startIcon={<LineFilterAltIcon/>}
        onClick={async() => {
          hideIsShowChips()
          await onShowFilters()
          showIsShowChips()
        }}
      >
        All filters {countSelected > 0 && `(${countSelected})`}
      </FilterChip>
      {isShowChips && <CruiseSearchChips
        drawerMode
        onShowFilters={onShowFilters}
        listFilters={filters}
        sortOptions={CruiseSearchSortOptions}
      />}
    </Carousel>}
  </HeaderContainer>
}

export default CruiseHeaderFilterChip
