import { rem } from 'polished'
import React, { ComponentProps, forwardRef, HTMLAttributes } from 'react'
import styled from 'styled-components'
import Typography from '../Typography/Typography'

const Container = styled(Typography)`
  padding: ${rem(12)} ${rem(8)};

  &:first-child {
    padding-inline-start: ${rem(16)};
  }
  &:last-child {
    padding-inline-end: ${rem(16)};
  }
`

type InheritedTypographyProps = Pick<ComponentProps<typeof Typography>, 'format' | 'wrap' | 'align' | 'tabletAlign' | 'desktopAlign' | 'lineClamp' | 'tabletLineClamp' | 'desktopLineClamp' | 'forwardedAs' | 'as' >

interface Props extends HTMLAttributes<HTMLTableCellElement>, InheritedTypographyProps {
  /**
   * In pixels.
   */
  minWidth?: number
  /**
   * Sets the width of the cell in pixels as a number or in percentage.
   */
  width?: number | `${number}%`
}

function determineWidth(width: Props['width']): string | undefined {
  if (width === undefined) return
  if (typeof width === 'number') return rem(width)
  return width
}

const _TableCell = forwardRef<HTMLTableCellElement, Props>(function BaseTableCell(props, ref) {
  const { minWidth, width, style, as, ...rest } = props

  return <Container
    {...rest}
    forwardedAs={as}
    style={{
      ...style,
      minWidth: minWidth !== undefined ? rem(minWidth) : undefined,
      width: determineWidth(width),
    }}
    ref={ref}
  />
})

export default _TableCell
