import React, { useCallback, useContext } from 'react'
import { maxFlightChildAge, maxFlightInfantAge, maxFlightPassengerCount } from 'constants/flight'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import OccupancyItemVertical from 'components/Common/OccupancyPicker/OccupancyItemVertical'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import InfantSeatNoticeMessage from 'components/Flights/Common/InfantSeatNoticeMessage/InfantSeatNoticeMessage'

const TravellersContainer = styled(VerticalSpacer)`
  ${mediaQueryUp.tablet} {
    width: ${rem(416)};
  }
`

const capacity = [{
  adults: maxFlightPassengerCount,
  children: maxFlightPassengerCount,
  infants: maxFlightPassengerCount,
  total: maxFlightPassengerCount,
}]

function FlightSearchTravellerSelectContent() {
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const { occupants } = state

  const onChangeOccupants = useCallback(
    (_itemId: number, nextOccupancy: App.Occupants) => {
      dispatch({
        type: FlightSearchWidgetActions.SET_OCCUPANTS,
        occupants: nextOccupancy,
      })
    },
    [dispatch],
  )

  const infants = occupants.infants || 0
  const showInfantsWarning = infants > 0

  return (
    <TravellersContainer gap={24}>
      <OccupancyItemVertical
        itemId={0}
        occupancy={occupants}
        onChange={onChangeOccupants}
        capacities={capacity}
        childVariant="infants"
        maxChildAge={maxFlightChildAge}
        maxInfantAge={maxFlightInfantAge}
        childrenAgeLabel="at time of return flight"
        childrenLabel={`Aged ${maxFlightInfantAge + 1} - ${maxFlightChildAge}`}
      />

      {showInfantsWarning && <InfantSeatNoticeMessage minified />}
    </TravellersContainer>
  )
}

export default FlightSearchTravellerSelectContent
