import request from 'api/requestUtils'
import { RequestDemoData } from 'businessTraveller/components/demo-modal/BusinessTravellerRequestDemoModal'
import { VideoChatRequestBody } from 'components/Pages/VideoChatPage'
import config from 'constants/config'
import { paths } from '@luxuryescapes/contract-svc-notification/generated'

export namespace NotificationAPI {
  export type TermsAndConditionsPayload = paths['/api/le-notify/terms-and-conditions']['post']['parameters']['body']['payload']
  export type TermsAndConditionsResponse = paths['/api/le-notify/terms-and-conditions']['post']['responses']['200']['content']['application/json']
  export type SendShareWithFriendV2Request = paths['/api/v2/le-notify/share-with-friend']['post']['parameters']['body']['payload']
}

interface EnqueryRequestData {
  issue: { option: 'purchase' | 'purchased' | 'somethingElse', text: string };
  subIssue?: { option: string, text: string };
  email: string;
  name: string;
  phone: string;
  enquiry: string;
  orderId?: string;
}

export function sendEnquiryRequest(data: EnqueryRequestData, regionCode: string) {
  const payload = {
    issue: data.issue,
    subissue: data.subIssue,
    customer_name: data.name,
    customer_email: data.email,
    customer_phone: data.phone,
    customer_country: regionCode,
    enquiry: data.enquiry,
    order_id: data.orderId,
  }

  return request.post('/api/notify/enquiry', payload)
}

export function sendShareWithFriendRequest({
  friendEmail,
  friendMessage,
  type,
  offerId,
  memberId,
  utm,
  heroImageId,
  location,
  region,
}: {
    friendEmail: string
    friendMessage?: string
    type: string
    offerId: string
    memberId?: string
    utm?: object
    heroImageId?: string
    location?: string
    region: string,
}) {
  return request.post('/api/le-notify/share-with-friend', {
    friend_email: friendEmail,
    friend_message: friendMessage,
    customer_id: memberId,
    type,
    offer_id: offerId,
    hero_image: heroImageId,
    location,
    region,
    utm,
  }, { credentials: 'include' })
}

export function sendShareWithFriendV2Request(payload: NotificationAPI.SendShareWithFriendV2Request) {
  return request.post('/api/v2/le-notify/share-with-friend', payload, { credentials: 'include' })
}

export function sendShareReferralLinkRequest({
  friendEmail,
  referralCustomerId,
  region,
  utm,
}: {
    friendEmail: string
    referralCustomerId: string
    region: string
    utm: object
}) {
  return request.post<App.ApiResponse<any>, unknown>('/api/le-notify/share-referral-link', {
    friend_email: friendEmail,
    referral_customer_id: referralCustomerId,
    region,
    utm,
  }, { credentials: 'include' })
}

export function sendQuote(body: Quotes.QuoteParams) {
  return request.post('/api/le-notify/quote-automation', body, { credentials: 'include' })
}

export function sendSkyCheckConfirmation(body: Quotes.ConfirmationParams) {
  return request.post('/api/le-notify/skycheck-request-email', body, { credentials: 'include' })
}

export function sendSkyCheckConfirmationToCS(body: any) {
  return request.post('/api/le-notify/skycheck-confirmation', body, { credentials: 'include' })
}

export function sendTripQuote(body: any) {
  return request.post('/api/le-notify/trip-quote-automation', body, { credentials: 'include' })
}

export function sendAccountDeleteRequestEmail(customer_id: string) {
  return request.post<{ message: string}, any>('/api/le-notify/account-delete-request', {
    customer_id,
    brand: config.BRAND,
  }, { credentials: 'include' })
}

export function sendVideoChatRequest(body: VideoChatRequestBody) {
  return request.post(
    '/api/le-notify/sales-video-chat-notification',
    body,
    { credentials: 'include' },
  )
}

export function sendBusinessDemoRequest(body: RequestDemoData) {
  return request.post(
    '/api/le-notify/business-request-demo',
    body,
    { credentials: 'include' },
  )
}

export function sendTermsAndConditionsEmail(body: NotificationAPI.TermsAndConditionsPayload): Promise<NotificationAPI.TermsAndConditionsResponse> {
  return request.post('/api/le-notify/terms-and-conditions', body, { credentials: 'include' })
}
