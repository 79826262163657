import React, { useContext, useEffect } from 'react'
import TextButton from 'components/Luxkit/Button/TextButton'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import BedbankTwinShare from 'components/OfferList/OfferListTiles/Support/BedbankTwinShare'
import BedbankMarginText from 'components/OfferPage/Bedbank/BedbankMargin'
import BusinessTravellerOfferCreditsTextLink from 'businessTraveller/components/offer-credits/BusinessTravellerOfferCreditsTextLink'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import OfferPriceDetailsRow from 'components/Common/PriceDetails/OfferPriceDetailsRow'
import { buildSuggestedDatesString } from 'lib/search/searchUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import config from 'constants/config'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  offerPrice: number
  offerMemberPrice: number
  isFlexibleSearch?: string
  suggestedDates: {
    checkIn: string
    checkOut: string
  }
  nightsToDisplay: number
  promoteAsBundle: boolean
  saleUnit: string
  rooms: Array<App.Occupants>
  discountPercentage: number
  value: number
  hotelNights: number
  totalPrices: {
    original: {
      flightBundlePrice: number
      price: number
    }
  }
  offerLocations: Array<string>
  displayMargin?: boolean
  margin?: number
  marginAud?: number
  supplier?: string
  directSearchPrices?: App.OfferListMetadataPricing
  shownRate?: {
    mobilePromotion?: boolean
  }
  bundlePropertyFees?: number
  propertyFees?: number
  bundleTaxesAndFees?: number
  taxesAndFees?: number
  bundlePrice: number
  hotelPrice: number
  flightPrice?: number
  bundleMemberPrice?: number
  hotelMemberPrice: number
  luxPlusLPPEnabled: boolean
  offerUrl: string
}

function BedbankSearchTileGarden({
  offer,
  offerPrice,
  offerMemberPrice,
  isFlexibleSearch,
  suggestedDates,
  nightsToDisplay,
  promoteAsBundle,
  saleUnit,
  rooms,
  discountPercentage,
  value,
  hotelNights,
  totalPrices,
  offerLocations,
  displayMargin,
  margin,
  marginAud,
  supplier,
  directSearchPrices,
  shownRate,
  bundlePropertyFees,
  propertyFees,
  bundleTaxesAndFees,
  taxesAndFees,
  bundlePrice,
  hotelPrice,
  flightPrice,
  bundleMemberPrice,
  hotelMemberPrice,
  luxPlusLPPEnabled,
  offerUrl,
}: Props) {
  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  useEffect(() => {
    dispatchOfferListEvent({
      type: OfferListEvents.pricingCalculated,
      availability: true,
      leadPrice: offerPrice,
      duration: nightsToDisplay,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!!offerPrice && <>
        <PriceRowPriceCaption>
          {isFlexibleSearch && buildSuggestedDatesString(suggestedDates.checkIn, suggestedDates.checkOut)}
          {!isFlexibleSearch && `${pluralizeToString('night', nightsToDisplay)}`}
          {promoteAsBundle && ' + flights'}
          {' '}from
        </PriceRowPriceCaption>
        <LuxPlusPriceStack
          size="L"
          price={offerPrice}
          memberPrice={offerMemberPrice}
          saleUnit={saleUnit}
        />
        {offer.displayPricingAsPerPerson && <BedbankTwinShare rooms={rooms} />}
        {discountPercentage >= 5 && <PriceRowValueDiscountWithCaption
          size="M"
          originalValue={value}
          discountPercentage={discountPercentage}
        />}
      </>}

      {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerOfferCreditsTextLink
        type="estimate"
        offer={offer}
        totalCost={promoteAsBundle ? totalPrices.original.flightBundlePrice : totalPrices.original.price}
        numberOfNights={hotelNights}
      />}

      <PriceRowAgentHubCommission
        size="L"
        productType={offer.productType}
        offerId={offer.id}
        offerLocations={offerLocations}
      />

      {displayMargin && <BedbankMarginText
        margin={margin!}
        marginAud={marginAud!}
        supplier={supplier!}
        type="bedbank_estimate"
      />}
      {(directSearchPrices || shownRate && hotelNights) && (<OfferPriceDetailsRow
        trigger="price-row"
        triggerSize="M"
        offer={offer}
        rooms={rooms}
        propertyFees={promoteAsBundle ? bundlePropertyFees : propertyFees}
        taxesAndFees={promoteAsBundle ? bundleTaxesAndFees : taxesAndFees}
        hotelPrice={promoteAsBundle ? bundlePrice : hotelPrice}
        flightPrice={promoteAsBundle ? flightPrice : undefined}
        hotelMemberPrice={promoteAsBundle ? bundleMemberPrice : hotelMemberPrice}
        showMemberPrice={luxPlusLPPEnabled && hotelMemberPrice > 0}
        duration={hotelNights}
        cta={<TextButton kind="primary" fit="flex" to={offerUrl}>
          View Offer
        </TextButton>}
        context="search"
        hasMobilePromotion={shownRate?.mobilePromotion}
      />)}
    </>
  )
}

export default BedbankSearchTileGarden
