import deepmerge from 'deepmerge'
import { shade, tint } from 'polished'
import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseBorderRadii, whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const treatMeTravelDefaultPalette = deepmerge<App.Design.Palette<string>>(whiteLabelBaseDefaultPalette, {
  brand: {
    ...whiteLabelBaseDefaultPalette.brand,
    primary: {
      normal: '#FF6F00',
      darkest: shade(0.24, '#FF6F00'),
      dark: shade(0.16, '#FF6F00'),
      light: tint(0.76, '#FF6F00'),
      lightest: tint(0.86, '#FF6F00'),
      hover: shade(0.16, '#FF6F00'),
      active: shade(0.24, '#FF6F00'),
    },
  },
})

export const treatMeTravelShadows: App.Design.Shadows<string> = { ...baseThemeShadows }
export const treatMeTravelBorderRadii: App.Design.BorderRadii<string> = { ...whiteLabelBaseBorderRadii }

const treatMeTravelTheme: App.Design.Theme = {
  ...whiteLabelBaseTheme,
}

export default treatMeTravelTheme
