import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import React, { ReactNode, useMemo } from 'react'
import useTourTags from 'hooks/TourV2/useTourTags'
import { sortBy } from 'lib/array/arrayUtils'
import TourV2UrgencyLabel from './TourV2UrgencyLabel'
import { LuxPlusLabelTagOptions } from 'lib/tours/tourUtils'
import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'

const KNOWN_TAGS_POSITIONS = new Map<App.UrgencyLabelType, { position: number, tagLabel?: ReactNode }>([
  ['takeover_promo_code', { position: 5 }],
  ['agenthub_exclusive', { position: 4 }],
  ['lux_plus_member_price', { position: 3 }],
  ['lux_plus_early_access', { position: 3 }],
  ['lux_plus_general_release', { position: 3 }],
  ['promo_spend_earn', { position: 1 }],
  ['sale', { position: 0 }],
  ['departuresale', { position: 0 }],
])

const TOUR_COUNTDOWN_TIMER_DAYS = 5

interface Props {
  className?: string;
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary;
  departureId?: string;
  /**
   * Which lux plus labels do we want to include within these labels
   * price only will return only price related labels
   * access only will return only access related (i.e. member only/early access)
   * All will return all that are relevant
   * None will show none
   *
   * @default all
   */
  luxPlusLabels?: LuxPlusLabelTagOptions
}

function TourV2UrgencyTags(props: Props) {
  const {
    className,
    offer,
    luxPlusLabels,
    departureId,
  } = props

  const tags = useTourTags(offer, luxPlusLabels, departureId)

  const tagLabels = useMemo(() => {
    return sortBy(tags, tag => {
      return KNOWN_TAGS_POSITIONS.get(tag.type)?.position ?? 2
    }, 'desc')
  }, [tags])

  return <LabelGroup className={className}>
    {tagLabels.map((label, index) => <TourV2UrgencyLabel key={index} offer={offer} label={label} />)}
    {offer.schedule?.end && <OfferUrgencyCountdownLabel
      endDate={offer.schedule?.end}
      daysToBook={TOUR_COUNTDOWN_TIMER_DAYS}
    />}
  </LabelGroup>
}

export default TourV2UrgencyTags
