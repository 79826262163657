import { PAYMENT_OPTIONS } from 'constants/payment'
import { createSelector } from 'reselect'
import getDepositServiceFee from './getDepositServiceFee'
import { getPaymentScheduleTotalServiceFee } from './paymentSchedule'

const getServiceFeeAmount = createSelector(
  (state: App.State) => getDepositServiceFee(state),
  (state: App.State) => getPaymentScheduleTotalServiceFee(state),
  (state: App.State) => state.checkout.payment.paymentSelected,
  (depositServiceFee, paymentScheduleServiceFee, paymentSelected): number => {
    if (paymentSelected === PAYMENT_OPTIONS.DEPOSIT) {
      return depositServiceFee
    } else if (paymentSelected === PAYMENT_OPTIONS.PAYMENT_SCHEDULE) {
      return paymentScheduleServiceFee
    }
    return 0
  },
)

export default getServiceFeeAmount
