import { OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE, OFFER_TYPE_BED_BANK, OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2, OFFER_TYPE_CRUISE, OFFER_TYPE_VILLA } from 'constants/offer'

export function excludeNullOrUndefined<T>(item: T | undefined | null): item is T {
  return Boolean(item)
}

export function getAccommodationDesignationWithTourBundle(
  startDate: string,
  itemBundleId?: string,
  tourV2Items?: Array<App.Checkout.TourV2Item>,
): string {
  const defaultDesignation = 'accommodation'
  if (!itemBundleId || !tourV2Items?.length) {
    return defaultDesignation
  }
  const tourV2Item = tourV2Items.find(item => item.itemId === itemBundleId)
  if (!tourV2Item) {
    return defaultDesignation
  }
  const suffixe = new Date(tourV2Item.startDate) > new Date(startDate) ? 'pre' : 'post'
  return `${suffixe} ${defaultDesignation}`
}

export function getBreakdownTitle(offer: App.Checkout.AccommodationOfferView): string {
  switch (offer.offerType) {
    case OFFER_TYPE_HOTEL:
    case OFFER_TYPE_LAST_MINUTE:
    case OFFER_TYPE_BED_BANK:
    case OFFER_TYPE_ALWAYS_ON:
    case OFFER_TYPE_VILLA:
      return 'Accommodation'
    case OFFER_TYPE_TOUR:
      return 'Cruise'
    case OFFER_TYPE_TOUR_V2:
      return 'Tour'
    case OFFER_TYPE_CRUISE:
      return 'Cruise'
    default:
      return 'Package'
  }
}

// Added extracted this from AccountTakeOverModal.tsx as needed values elsewhere - maybe can be updated later to use lib-regions flow?
export function getTollFreeSalesContactNumber(region: string, currentContact: App.GeoContactsTypes): App.GeoContacts {
  switch (region) {
    case 'AU':
      return {
        local: {
          number: '1300568529',
          humanReadable: '1300 568 529',
        },
        international: {
          number: '1300568529',
          humanReadable: '1300 568 529',
        },
        default: 'local',
        defaultContact: {
          number: '1300568529',
          humanReadable: '1300 568 529',
        },
      }
    case 'NZ':
      return {
        local: {
          number: '0800856473',
          humanReadable: '0800 856 473',
        },
        international: {
          number: '0800856473',
          humanReadable: '0800 856 473',
        },
        default: 'local',
        defaultContact: {
          number: '0800856473',
          humanReadable: '0800 856 473',
        },
      }
    case 'US':
      return {
        local: {
          number: '8888198182',
          humanReadable: '888 819 8182',
        },
        international: {
          number: '8888198182',
          humanReadable: '888 819 8182',
        },
        default: 'local',
        defaultContact: {
          number: '8888198182',
          humanReadable: '888 819 8182',
        },
      }
    case 'GB':
      return {
        local: {
          number: '08006800855',
          humanReadable: '0800 680 0855',
        },
        international: {
          number: '08006800855',
          humanReadable: '0800 680 0855',
        },
        default: 'local',
        defaultContact: {
          number: '08006800855',
          humanReadable: '0800 680 0855',
        },
      }
    default:
      return currentContact.highValue || currentContact.general
  }
}
