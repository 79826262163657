import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { inclusionTravelPeriodFilter } from './inclusionsTravelPeriodFilter'
import luxPlusInclusionFilter from './luxPlusInclusionFilter'
import { partitionBy } from 'lib/array/arrayUtils'

interface GetInclusionsOptions {
  checkIn?: moment.Moment | string;
  checkOut?: moment.Moment | string;
  luxPlusTier?: App.MembershipTier;
}

export function extractPkgInclusions(pkg: App.Package | undefined, options: GetInclusionsOptions = {}) {
  const { checkIn, checkOut, luxPlusTier } = options

  if (!pkg) {
    return [[], [], [], []]
  }

  const isoCheckIn = typeof checkIn === 'string' ? checkIn : checkIn?.format(ISO_DATE_FORMAT)
  const isoCheckOut = typeof checkOut === 'string' ? checkOut : checkOut?.format(ISO_DATE_FORMAT)
  const [bonusInclusionsRaw, inclusionsRaw] = partitionBy(pkg.inclusions ?? [], inclusion => !!inclusion?.isBonus)
  const inclusions = inclusionTravelPeriodFilter(inclusionsRaw, isoCheckIn, isoCheckOut)
  const bonusInclusions = inclusionTravelPeriodFilter([...bonusInclusionsRaw, ...pkg.bonusInclusions], isoCheckIn, isoCheckOut)
  const mainInclusions = [...inclusions, ...bonusInclusions]
  const luxPlusInclusions = luxPlusTier && pkg.luxPlusInclusionsByTier ? luxPlusInclusionFilter(pkg.luxPlusInclusionsByTier[luxPlusTier as App.MembershipSubscriptionTier] ?? [], mainInclusions) : []
  const tacticalInclusions = mainInclusions.filter(incl => incl.hasTactical)

  return [
    inclusions,
    bonusInclusions,
    luxPlusInclusions,
    tacticalInclusions,
  ]
}

export function getPkgInclusionsCount(pkg: App.Package, options: GetInclusionsOptions = {}) {
  const [inclusions, bonusInclusions, luxPlusInclusions] = extractPkgInclusions(pkg, options)

  return inclusions.length + bonusInclusions.length + luxPlusInclusions.length
}
