import useCurrentSiteTakeover, { SiteTakeoverBackground } from 'hooks/useCurrentSiteTakeover'
import React from 'react'
import styled from 'styled-components'
import cn from 'clsx'

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &.hero {
    background: linear-gradient(180deg, #5DB5AA, #003d37 75%);
  }

  &.search {
    background-image: linear-gradient(to bottom, #5DB5AA, #003d37 80%);
  }

  &.default {
    background-image: linear-gradient(to bottom, #5DB5AA, #003d37 80%);
  }
`

interface Props {
  className?: string
  background: SiteTakeoverBackground
}

function TourAsiaCampaignTakeoverBackground(props: Props) {
  const { className, background } = props

  const currentTakeover = useCurrentSiteTakeover('tours')

  if (!currentTakeover) {
    return null
  }

  return <Container className={cn(className, background)} />
}

export default TourAsiaCampaignTakeoverBackground
