import 'core-js'
import 'regenerator-runtime/runtime'
import React from 'react'
import { hydrateRoot, createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider, StyleSheetManager } from 'styled-components'
import smoothscroll from 'smoothscroll-polyfill'
import { loadableReady } from '@loadable/component'
import Routes from 'components/App/Routes/Routes'
import themes from './constants/themes'
import config from 'constants/config'
import tokenLoader from 'api/preprocessors/tokenLoader'
import brandLoader from 'api/preprocessors/brandLoader'
import browserErrorLogger from 'api/interceptors/browserErrorLogger'
import { watchAnalytics } from './watcher/analytics'
import { watchAccount } from './watcher/account'
import { ConnectedRouter as Router } from 'connected-react-router'
import Modal from 'react-modal'
import { registerRequestPreprocessor, registerRequestInterceptor } from 'api/requestUtils'
import initialiseUser from './initialiseUser'
import { GeoProvider } from 'contexts/geoContext'
import { HeaderStateContextProvider } from 'contexts/HeaderStateContext'
import { HelmetProvider } from 'react-helmet-async'
import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { watchCheckout } from './watcher/checkout'
import { CurrencyProvider } from 'contexts/currencyContext'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { watchNavigation } from './watcher/navigation'
import { GoogleMapsProvider } from 'contexts/GoogleMapsContext'
import { init as initialiseTrackers } from 'analytics/analytics'
import initialiseBroadcastListener from './initialiseBroadcastListener'
import initialiseStore from './initialiseStore'
import initialiseHistory from './initialiseHistory'
import { registerErrorReportStore } from 'services/errorReportingService'
import { registerCartTrackingStore } from 'analytics/snowplow/events'
import ModalProvider from 'contexts/ModalProvider'
import ScrollToTop from 'components/App/ScrollToTop'
import { ContactProvider } from 'contexts/contactContext'
import { MasterModalProvider } from 'contexts/MasterModalContext'
import { shouldForwardProp } from 'lib/styles/styledComponents'
import { AppLayoutContextProvider } from 'contexts/AppLayoutContext'
import initialiseSiteTakeover from './initialiseSiteTakeover'
import { watchUserCart } from './watcher/usercart'

if (config.STATIC_JS_HOST) {
  __webpack_public_path__ = `${config.STATIC_JS_HOST}/`
}

smoothscroll.polyfill()
Modal.setAppElement('#app')

const initialState = window.__INITIAL_STATE__ as App.State
delete window.__INITIAL_STATE__
// It's possible for our server router state to not match current history state
// e.g. a 3rd party script changes window location before the app finishes loading
// Remove the server generated router state to ensure our router state is up to date.
// @ts-ignore
delete initialState.router

const dehydratedState = window.__REACT_QUERY_STATE__ as DehydratedState
delete window.__REACT_QUERY_STATE__

const history = initialiseHistory(initialState.geo.currentRegionCode.toLowerCase())
const store = initialiseStore(history, initialState);

// Add store to the window for debugging purposes.
// It's available through dev tools anyway, so no harm in unearthing it.
// This is in the rare instances we a non technical user to debug a redux state
// DO NOT ACCESS THIS IN CODE
(window as any).__DEBUG_REDUX_STATE__ = store.getState

initialiseBroadcastListener(store)
registerRequestInterceptor(browserErrorLogger())
registerRequestPreprocessor(tokenLoader(store))
registerRequestPreprocessor(brandLoader(config.BRAND))

registerCartTrackingStore(store)
registerErrorReportStore(store)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 3 * 1000,
    },
  },
})

initialiseSiteTakeover()
initialiseTrackers(store)
initialiseUser(store)
watchCheckout(store)
watchAnalytics(store, window.dataLayer)
watchNavigation(store)
watchAccount(store, queryClient)
watchUserCart(store)

function renderApp() {
  const theme = themes[config.BRAND]
  const storeState = store.getState()

  const appTree = <HelmetProvider>
    <Provider store={store} serverState={storeState}>
      <ThemeProvider theme={theme}>
        <GeoProvider>
          <ContactProvider>
            <CurrencyProvider>
              <QueryClientProvider client={queryClient}>
                {config.ENABLE_REACT_QUERY_DEV_TOOLS && <ReactQueryDevtools initialIsOpen={false} />}
                <AppLayoutContextProvider>
                  <HeaderStateContextProvider>
                    <GoogleMapsProvider>
                      <Hydrate state={dehydratedState}>
                        <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
                          <Router history={history}>
                            <ScrollToTop />
                            <MasterModalProvider>
                              <ModalProvider>
                                <ModalProvider>
                                  <Routes />
                                </ModalProvider>
                              </ModalProvider>
                            </MasterModalProvider>
                          </Router>
                        </StyleSheetManager>
                      </Hydrate>
                    </GoogleMapsProvider>
                  </HeaderStateContextProvider>
                </AppLayoutContextProvider>
              </QueryClientProvider>
            </CurrencyProvider>
          </ContactProvider>
        </GeoProvider>
      </ThemeProvider>
    </Provider>
  </HelmetProvider>

  return loadableReady().then(() => {
    const appElement = document.getElementById('app')
    if (config.SSR_DISABLED) {
      const root = createRoot(appElement!)
      root.render(appTree)
    } else {
      hydrateRoot(appElement!, appTree)
    }
  })
}

// Add the ability to force a re-render of the app
// This is to support times when store data is manually manipulated
// and we want it to update on the site
// DO NOT ACCESS THIS IN CODE
(window as any).__DEBUG_RERENDER__ = renderApp

renderApp()
