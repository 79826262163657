import { createSelector } from 'reselect'
import getInsuranceItemsView from './getInsuranceItemsView'

const getLuxLoyaltyInsuranceBenefitItemViews = createSelector(
  (state: App.State) => getInsuranceItemsView(state),
  (insuranceItems) => {
    return insuranceItems.data.filter(insuranceData => insuranceData.loyaltySavingsAmount > 0)
  },
)

export default getLuxLoyaltyInsuranceBenefitItemViews
