import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import FakeInput from 'components/Common/Form/Input/FakeInput'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import TextInput from 'components/Common/Form/Input/TextInput'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import Modal from 'components/Luxkit/Modal/Modal'
import useToggle from 'hooks/useToggle'
import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import ExperienceTransferBookingHotelSelectOptions from './ExperienceTransferBookingHotelSelectOptions'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import { GoogleAutocompletePlaceItem } from 'api/search'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'

interface Props extends Omit<React.ComponentProps<typeof TextInput>, 'onChange' | 'value' | 'defaultValue'> {
  transfer: App.ExperienceTransferView;
  onChange?: (place: GoogleAutocompletePlaceItem) => void;
  label?: string;
  airport: App.Airport;
}

function ExperienceTransferBookingHotelSelect(props: Props) {
  const {
    name,
    transfer,
    onChange,
    airport,
    required,
    ...textProps
  } = props

  const triggerRef = useRef<HTMLLabelElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const mobileInputRef = useRef<HTMLInputElement>(null)
  const [open, , openDropdown, closeDropdown] = useToggle()

  const isMobileScreen = useScreenSizeOnly('mobile')

  const [searchPhrase, setSearchPhrase] = useState<string>(transfer.hotel.name ?? '')

  const searchPhraseInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchPhrase(event.currentTarget.value)
  }, [])

  const onItemSelect = useCallback((option: GoogleAutocompletePlaceItem) => {
    onChange?.(option)
    closeDropdown()
  }, [closeDropdown, onChange])

  useEffect(() => {
    if (open && !isMobileScreen) {
      triggerRef.current?.scrollIntoView()
      inputRef.current?.focus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return <div style={{ position: 'relative' }}>
    <HiddenInput required={required} name={`${name}.kmFromAirport`} value={transfer.hotel.kmFromAirport}/>
    <FakeInput
      {...textProps}
      required={required}
      value={transfer.hotel?.name}
      ref={triggerRef}
      onClick={openDropdown}
    />
    <CSSBreakpoint min="tablet">
      <DropdownList
        size="fill-anchor"
        anchorRef={triggerRef}
        triggerRef={triggerRef}
        open={!isMobileScreen && open}
        onClose={closeDropdown}
        headerExtension={<TextInput
          autoComplete="off"
          onChange={searchPhraseInputChange}
          endIcon={<LineSearchIcon />}
          defaultValue={searchPhrase}
          ref={inputRef}
          placeholder="Search by hotel or address"
          noValidationSpacing
        />}
      >
        <ExperienceTransferBookingHotelSelectOptions
          onSelect={onItemSelect}
          searchPhrase={searchPhrase}
          selectedPlaceId={transfer.hotel?.googlePlaceId}
          originAirport={airport}
        />
      </DropdownList>
    </CSSBreakpoint>
    <CSSBreakpoint max="mobile">
      <Modal
        height="max"
        title={textProps.label}
        isOpen={isMobileScreen && open}
        onClose={closeDropdown}
        onAfterOpen={() => mobileInputRef.current?.focus()}
        headerExtension={<TextInput
          autoComplete="off"
          onChange={searchPhraseInputChange}
          endIcon={<LineSearchIcon />}
          defaultValue={searchPhrase}
          ref={inputRef}
          placeholder="Search by hotel or address"
          noValidationSpacing
        />}
      >
        <ExperienceTransferBookingHotelSelectOptions
          onSelect={onItemSelect}
          searchPhrase={searchPhrase}
          selectedPlaceId={transfer.hotel?.googlePlaceId}
          originAirport={airport}
        />
      </Modal>
    </CSSBreakpoint>
  </div>
}

export default ExperienceTransferBookingHotelSelect
