import {
  FETCH_BEST_BACK_PRIZE,
  FETCH_BEST_BACK_OBJECT,
  FETCH_BEST_BACK_USER_STATUS,
  FETCH_NBL_COMPETITION_PRIZE,
  FETCH_NBL_COMPETITION_OBJECT,
  FETCH_NBL_COMPETITION_USER_STATUS,
  FETCH_UK_COMPETITION_PRIZE,
  FETCH_UK_COMPETITION_OBJECT,
  FETCH_UK_COMPETITION_USER_STATUS,
  FETCH_AU_COMPETITION_PRIZE,
  FETCH_AU_COMPETITION_OBJECT,
  FETCH_AU_COMPETITION_USER_STATUS,
  FETCH_INTL_COMPETITION_PRIZE,
  FETCH_INTL_COMPETITION_OBJECT,
  FETCH_INTL_COMPETITION_USER_STATUS,
  FETCH_PROMO_DISPLAY_CONFIGS,
  REMOVE_PROMO_DISPLAY_CONFIG,
  FETCH_PLACE_SITELINKS,
  FETCH_TARGETED_PROMO_DETAILS,
  RESET_TARGETED_PROMO_DETAILS,
} from 'actions/apiActionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import { API_CALL_REQUEST, API_CALL_FAILURE, API_CALL_SUCCESS, FETCH_CHANNEL_MARKUP } from 'actions/actionConstants'

export const initialMarketingState: App.MarketingState = {
  competition: null,
  fetching: false,
  sitelinks: {},
}

// TODO: MAPPER FUNCTION FOR REDUCER
const apiRequests = reducerSwitch<App.MarketingState>({
  [FETCH_BEST_BACK_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_BEST_BACK_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_BEST_BACK_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_NBL_COMPETITION_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_NBL_COMPETITION_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_NBL_COMPETITION_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_UK_COMPETITION_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_UK_COMPETITION_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_UK_COMPETITION_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_AU_COMPETITION_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_AU_COMPETITION_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_AU_COMPETITION_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_INTL_COMPETITION_PRIZE]: () => ({
    fetching: true,
  }),

  [FETCH_INTL_COMPETITION_OBJECT]: () => ({
    fetching: true,
  }),

  [FETCH_INTL_COMPETITION_USER_STATUS]: () => ({
    fetching: true,
  }),
  [FETCH_CHANNEL_MARKUP]: () => ({
    fetching: true,
  }),
  [FETCH_PROMO_DISPLAY_CONFIGS]: () => ({
    fetching: true,
  }),
  [FETCH_PLACE_SITELINKS]: (state, action) => ({
    sitelinks: {
      ...state.sitelinks,
      [action.placeName]: {
        fetching: true,
      },
    },
  }),
  [FETCH_TARGETED_PROMO_DETAILS]: () => ({
    fetching: true,
  }),
})

const apiSuccesses = reducerSwitch<App.MarketingState>({
  [FETCH_PLACE_SITELINKS]: (state, action) => {
    return {
      sitelinks: {
        ...state.sitelinks,
        [action.placeName]: {
          ...action,
        },
      },
    }
  },

  [FETCH_BEST_BACK_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_BEST_BACK_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_BEST_BACK_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),
  [FETCH_NBL_COMPETITION_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_NBL_COMPETITION_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_NBL_COMPETITION_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),
  [FETCH_UK_COMPETITION_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_UK_COMPETITION_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_UK_COMPETITION_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),
  [FETCH_AU_COMPETITION_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_AU_COMPETITION_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_AU_COMPETITION_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),
  [FETCH_INTL_COMPETITION_PRIZE]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, prize: action.data },
  }),

  [FETCH_INTL_COMPETITION_OBJECT]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, object: action.data },
  }),

  [FETCH_INTL_COMPETITION_USER_STATUS]: (state, action) => ({
    fetching: false,
    competition: { ...state.competition, status: action.data },
  }),

  [FETCH_CHANNEL_MARKUP]: (state, action) => ({
    fetching: false,
    channelMarkup: action.data,
  }),
  [FETCH_PROMO_DISPLAY_CONFIGS]: (state, action) => ({
    fetching: false,
    promoDisplayConfigs: action.data,
  }),
  [FETCH_TARGETED_PROMO_DETAILS]: (state, action) => {
    if (state.promoDisplayConfigs) {
      const updatedPromoDisplayConfig = { ...state.promoDisplayConfigs[action.data.promoDisplayId], expiresAt: action.data.expires_at, isTargetedPromo: false }
      return {
        fetching: false,
        promoDisplayConfigs: { ...state.promoDisplayConfigs, [action.data.promoDisplayId]: updatedPromoDisplayConfig },
      }
    }
    return state
  },
  [REMOVE_PROMO_DISPLAY_CONFIG]: (state, action) => {
    if (state.promoDisplayConfigs) {
      const { [action.data.promoDisplayId]: _, ...newConfigs } = state.promoDisplayConfigs
      return {
        promoDisplayConfigs: newConfigs,
      }
    }
    return state
  },
  [RESET_TARGETED_PROMO_DETAILS]: (state) => {
    if (!state.promoDisplayConfigs) return state
    const updatedPromoDisplayConfigs = state.promoDisplayConfigs
    Object.keys(updatedPromoDisplayConfigs).forEach((promoDisplayId) => {
      const useTargetedPromo = updatedPromoDisplayConfigs[promoDisplayId].useTargetedPromo
      updatedPromoDisplayConfigs[promoDisplayId] = { ...updatedPromoDisplayConfigs[promoDisplayId], isTargetedPromo: useTargetedPromo, expiresAt: undefined }
    })
    return {
      fetching: false,
      promoDisplayConfigs: updatedPromoDisplayConfigs,
    }
  },
})
const apiFailures = reducerSwitch<App.MarketingState>({
  [FETCH_PLACE_SITELINKS]: (state, action) => ({
    sitelinks: {
      ...state.sitelinks,
      [action.placeName]: {
        fetching: false,
      },
    },
  }),

  [FETCH_BEST_BACK_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_BEST_BACK_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_BEST_BACK_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),
  [FETCH_NBL_COMPETITION_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_NBL_COMPETITION_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_NBL_COMPETITION_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),
  [FETCH_UK_COMPETITION_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_UK_COMPETITION_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_UK_COMPETITION_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),
  [FETCH_AU_COMPETITION_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_AU_COMPETITION_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_AU_COMPETITION_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),
  [FETCH_INTL_COMPETITION_PRIZE]: (state) => ({
    fetching: false,
    competition: { ...state.competition, prize: null },
  }),

  [FETCH_INTL_COMPETITION_OBJECT]: (state) => ({
    fetching: false,
    competition: { ...state.competition, object: null },
  }),

  [FETCH_INTL_COMPETITION_USER_STATUS]: (state) => ({
    fetching: false,
    competition: { ...state.competition, status: null },
  }),

  [FETCH_CHANNEL_MARKUP]: () => ({
    fetching: false,
    channelMarkup: undefined,
  }),
  [FETCH_PROMO_DISPLAY_CONFIGS]: () => ({
    fetching: false,
    promoDisplayConfigs: undefined,
  }),
  [FETCH_TARGETED_PROMO_DETAILS]: () => ({
    fetching: false,
  }),
})

export default createReducer<App.MarketingState>(initialMarketingState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})
