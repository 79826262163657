import { createReducer } from 'lib/redux/reducerUtils'
import { FlightFilterType, FlightSortOptions, FlightsFareTypes } from 'constants/flight'
import { ValueOf } from 'type-fest'
import { removeUnusedFilters } from 'lib/flights/flightFilterUtils'
import uuidV4 from 'lib/string/uuidV4Utils'
import { fillArray } from 'lib/array/arrayUtils'

export enum FlightPriceFilterType {
  ADULT = 'adult',
  TOTAL = 'total'
}

export enum FlightPriceFilterRecommendedType {
  DIRECT_FLIGHTS = 'directFlights',
  INCLUDES_CHECKED_BAG = 'includesCheckedBag'
}

export enum StandaloneFlightsActions {
  SET_ACTIVE_SEGMENT = 'SET_ACTIVE_SEGMENT',
  RESET_FLIGHTS = 'RESET_FLIGHTS',
  SET_FLIGHT = 'SET_FLIGHT',
  SET_FLIGHT_FARE_FAMILY = 'SET_FLIGHT_FARE_FAMILY',
  SET_FLIGHTS_FILTER = 'SET_FLIGHTS_FILTER',
  SET_FLIGHTS_SORT = 'SET_FLIGHTS_SORT',
  CLEAR_FLIGHT = 'CLEAR_FLIGHT',
  CLEAR_SEGMENT_DATA = 'CLEAR_SEGMENT_DATA',
  RE_INITIALISE = 'RE_INITIALISE',
  RESET_FILTERS = 'RESET_FILTERS',
  RESET_FILTER = 'RESET_FILTER',
  UPDATE_PASSENGER_BAGGAGE= 'UPDATE_PASSENGER_BAGGAGE',
  TOGGLE_NON_EXISTENT_FILTERS = 'TOGGLE_NON_EXISTENT_FILTERS',
  SET_VIEW_TYPE = 'SET_VIEW_TYPE',
  CLEAR_FILTER_VALUE = 'CLEAR_FILTER_VALUE',
  SET_LOYALTY_POINTS = 'SET_LOYALTY_POINTS',
}
export interface StandaloneFlightsFilters {
  stops: Array<string>;
  airlines: Array<string>;
  cabinTypes: Array<string>;
  carriers: Array<string>;
  stopDurations: Array<string>;
  durations: Array<string>;
  minPrices: Array<string>;
  maxPrices: Array<string>;
  minDepartureTime: Array<string>;
  maxDepartureTime: Array<string>;
  minArrivalTime: Array<string>;
  maxArrivalTime: Array<string>;
  priceFilterType: FlightPriceFilterType;
  recommended: Array<string>;
  bags: Array<string>;
  stopOverCities: Array<string>;
  alliances: Array<string>;
}

export interface StandaloneFlightsState extends StandaloneFlightsFilters {
  isFlightSelectError: boolean;
  passengers: Array<App.Passenger>;
  sort?: FlightSortOptions;
  viewType?: string;
  fareType: FlightsFareTypes;
  segments: Array<string>;
  activeSegmentKey: string;
  flightAirports: Record<string, { origin: App.Airport, destination: App.Airport }>;
  selectedFlights: Record<string, App.JourneyV2>;
  selectedFareFamilies: Record<string, App.FlightFareFamily>;
  loyaltyPoints: Record<string, { points: number; credits: number } | undefined>;
}

export type StandaloneFlightsAction = ValueOf<Utils.FullActionMap<{
  [StandaloneFlightsActions.SET_ACTIVE_SEGMENT]: { segmentKey: string };
  [StandaloneFlightsActions.SET_FLIGHT]: { flight: App.JourneyV2, segmentKey: string };
  [StandaloneFlightsActions.SET_FLIGHT_FARE_FAMILY]: { fareFamily: App.FlightFareFamily, segmentKey: string };
  [StandaloneFlightsActions.CLEAR_FLIGHT]: { segmentKey: string };
  [StandaloneFlightsActions.RESET_FLIGHTS]: {};
  [StandaloneFlightsActions.SET_FLIGHTS_FILTER]: { filters: Partial<StandaloneFlightsState> };
  [StandaloneFlightsActions.SET_FLIGHTS_SORT]: { sort: FlightSortOptions };
  [StandaloneFlightsActions.RE_INITIALISE]: { initalState: Partial<StandaloneFlightsState> };
  [StandaloneFlightsActions.RESET_FILTERS]: {};
  [StandaloneFlightsActions.RESET_FILTER]: { filterType: FlightFilterType };
  [StandaloneFlightsActions.UPDATE_PASSENGER_BAGGAGE]: { journeyId: string, baggage: Array<Array<App.AddedBaggage>> };
  [StandaloneFlightsActions.TOGGLE_NON_EXISTENT_FILTERS]: { filters: Partial<StandaloneFlightsFilters> };
  [StandaloneFlightsActions.SET_VIEW_TYPE]: { viewType: string }
  [StandaloneFlightsActions.CLEAR_SEGMENT_DATA]: { segmentKey: string }
  [StandaloneFlightsActions.CLEAR_FILTER_VALUE]: { filterType: FlightFilterType, value: string };
  [StandaloneFlightsActions.SET_LOYALTY_POINTS]: { segmentKey: string, points: App.LuxLoyaltyPointsEarnCalculation };
}>>

export const initialState: StandaloneFlightsState = {
  passengers: [],
  isFlightSelectError: false,
  stops: [],
  airlines: [],
  cabinTypes: [],
  carriers: [],
  stopDurations: [],
  durations: [],
  minPrices: [],
  maxPrices: [],
  priceFilterType: FlightPriceFilterType.ADULT,
  sort: undefined,
  minDepartureTime: [],
  maxDepartureTime: [],
  minArrivalTime: [],
  maxArrivalTime: [],
  recommended: [],
  viewType: undefined,
  bags: [],
  stopOverCities: [],
  alliances: [],
  fareType: FlightsFareTypes.RETURN,
  segments: [],
  activeSegmentKey: '',
  flightAirports: {},
  selectedFareFamilies: {},
  selectedFlights: {},
  loyaltyPoints: {},
}

export function createStandaloneFlightSegmentsKeys(flights: number) {
  return fillArray(flights).map(() => uuidV4())
}

const standaloneStateReducer = createReducer<StandaloneFlightsState, StandaloneFlightsAction>(initialState, {
  [StandaloneFlightsActions.SET_ACTIVE_SEGMENT]: (_state, action) => ({
    activeSegmentKey: action.segmentKey,
  }),
  [StandaloneFlightsActions.SET_FLIGHT]: (state, action) => ({
    selectedFlights: {
      ...state.selectedFlights,
      [action.segmentKey]: action.flight,
    },
  }),
  [StandaloneFlightsActions.SET_FLIGHT_FARE_FAMILY]: (state, action) => ({
    selectedFareFamilies: {
      ...state.selectedFareFamilies,
      [action.segmentKey]: action.fareFamily,
    },
  }),
  [StandaloneFlightsActions.RESET_FLIGHTS]: () => ({
    selectedFareFamilies: {},
    selectedFlights: {},
    flightAirports: {},
    viewType: undefined,
  }),
  [StandaloneFlightsActions.SET_FLIGHTS_FILTER]: (_state, action) => ({
    ...action.filters,
  }),
  [StandaloneFlightsActions.CLEAR_FLIGHT]: (state, action) => {
    const selectedFlights = { ...state.selectedFlights }
    delete selectedFlights[action.segmentKey]
    const loyaltyPoints = { ...state.loyaltyPoints }
    delete loyaltyPoints[action.segmentKey]

    return {
      selectedFlights,
      loyaltyPoints,
    }
  },
  [StandaloneFlightsActions.CLEAR_SEGMENT_DATA]: (state, action) => {
    const selectedFlights = { ...state.selectedFlights }
    const selectedFareFamilies = { ...state.selectedFareFamilies }
    const loyaltyPoints = { ...state.loyaltyPoints }
    delete selectedFlights[action.segmentKey]
    delete selectedFareFamilies[action.segmentKey]
    delete loyaltyPoints[action.segmentKey]

    return {
      selectedFlights,
      selectedFareFamilies,
      loyaltyPoints,
    }
  },
  [StandaloneFlightsActions.RESET_FILTERS]: () => ({
    stops: [],
    airlines: [],
    cabinTypes: [],
    maxPrice: undefined,
    duration: undefined,
    departure: undefined,
    arrival: undefined,
    carriers: [],
    stopDurations: [],
    durations: [],
    minPrices: [],
    maxPrices: [],
    minDepartureTime: [],
    maxDepartureTime: [],
    minArrivalTime: [],
    maxArrivalTime: [],
    priceFilterType: FlightPriceFilterType.ADULT,
    bags: [],
    stopOverCities: [],
    alliances: [],
    recommended: [],
  }),
  [StandaloneFlightsActions.SET_FLIGHTS_SORT]: (_state, action) => ({
    sort: action.sort,
  }),
  [StandaloneFlightsActions.RE_INITIALISE]: (_state, action) => ({
    ...initialState,
    ...action.initalState,
  }),
  [StandaloneFlightsActions.UPDATE_PASSENGER_BAGGAGE]: (state, action) => ({
    passengers: state.passengers.map((passenger, index) => ({
      ...passenger,
      addedExtras: {
        carryOnBaggage: {},
        ...passenger.addedExtras,
        baggage: {
          ...passenger.addedExtras?.baggage,
          [action.journeyId]: action.baggage[index],
        },
      },
    })),
  }),
  [StandaloneFlightsActions.RESET_FILTER]: (_state, action) => ({
    [action.filterType]: initialState[action.filterType as keyof StandaloneFlightsFilters],
  }),
  [StandaloneFlightsActions.TOGGLE_NON_EXISTENT_FILTERS]: (state, action) => {
    const newFilters = Object.keys(action.filters).reduce((filters, filterKey) => ({
      ...filters,
      [filterKey]: removeUnusedFilters(state[filterKey as keyof StandaloneFlightsFilters], action.filters[filterKey as keyof StandaloneFlightsFilters] as Array<string>),
    }), {})

    return newFilters
  },
  [StandaloneFlightsActions.SET_VIEW_TYPE]: (_state, action) => ({
    viewType: action.viewType,
  }),
  [StandaloneFlightsActions.CLEAR_FILTER_VALUE]: (state, action) => ({
    [action.filterType]: (state[action.filterType as keyof StandaloneFlightsFilters] as Array<string>)?.filter(value => value !== action.value),
    [FlightFilterType.RECOMMENDED]: state[FlightFilterType.RECOMMENDED].filter(value => value !== action.value),
  }),
  [StandaloneFlightsActions.SET_LOYALTY_POINTS]: (state, action) => ({
    loyaltyPoints: {
      ...state.loyaltyPoints,
      [action.segmentKey]: {
        points: action.points.points,
        credits: action.points.statusCredits,
      },
    },
  }),
})

export default standaloneStateReducer
