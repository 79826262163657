import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import useListTruncator from 'hooks/useListTruncator'
import React, { ComponentProps, MouseEventHandler, ReactElement, useCallback } from 'react'
import SearchTileRowInclusionItem from './SearchTileRowInclusionItem'
import LabeledSheet from 'components/Luxkit/LabeledSheet'
import { take } from 'lib/array/arrayUtils'
import styled from 'styled-components'
import { rem } from 'polished'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'

const HighlightedInclusionsContainer = styled(Group)`
  padding: ${rem(8)};
`

type InheritedSearchTileRowInclusionItemProps = Pick<ComponentProps<typeof SearchTileRowInclusionItem>, 'lineClamp' | 'isHighlighted'>

interface Props extends InheritedSearchTileRowInclusionItemProps {
  inclusionsTitle?: string | ReactElement
  inclusions: Array<App.OfferInclusion>
  /**
   * The number of items to display before the rest are truncated.
   *
   * If set to zero, nothing will be displayed.
   */
  truncateAfter?: number
  onShowMoreClick: () => void
  showSelectPackageOnlyLabel?: boolean
}

function SearchTileStackInclusionsList({
  lineClamp,
  isHighlighted,
  inclusionsTitle,
  inclusions,
  truncateAfter,
  onShowMoreClick,
  showSelectPackageOnlyLabel,
}: Props) {
  const flashInclusionItems = inclusions.filter(inclusion => !!inclusion.isFlash)
  const [alwaysVisibleInclusions, collapsibleInclusions] = useListTruncator(truncateAfter, inclusions)

  const handleMoreClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    event.preventDefault()
    onShowMoreClick()
  }, [onShowMoreClick])

  if (!alwaysVisibleInclusions.length) return null

  return <Group className={SearchTileStackInclusionsList.name} direction="vertical" gap={4}>
    {!!inclusionsTitle && <BodyText variant="medium" weight="bold">{inclusionsTitle} {showSelectPackageOnlyLabel && <UrgencyLabel variant="positive">Select packages only</UrgencyLabel>}</BodyText>}
    {!!flashInclusionItems.length && truncateAfter && <LabeledSheet kind="exclusive" label="Limited time inclusions">
      <HighlightedInclusionsContainer direction="vertical" gap={4}>
        {take(flashInclusionItems, truncateAfter).map((inclusion) => <SearchTileRowInclusionItem
          key={inclusion.id}
          inclusion={inclusion}
          lineClamp={lineClamp}
        />)}
      </HighlightedInclusionsContainer>
    </LabeledSheet>}
    {!flashInclusionItems.length && alwaysVisibleInclusions.map((inclusion) => <SearchTileRowInclusionItem
      key={inclusion.id}
      inclusion={inclusion}
      isHighlighted={isHighlighted}
      lineClamp={lineClamp}
    />)}
    {!!collapsibleInclusions.length && <TextLink size="medium" onClick={handleMoreClick}>+{collapsibleInclusions.length} more</TextLink>}
  </Group>
}

export default SearchTileStackInclusionsList
