import { selectLoggedIn } from 'selectors/accountSelectors'

interface ReportData {
  account: App.AuthAccount | undefined
  meta: Record<string, any>
}

function getReportDataFromState(state: App.State | undefined): ReportData {
  return {
    account: state?.auth.account,
    meta: state ? {
      cartItems: state.checkout.cart.items,
      device: state.system.device,
      headlessMode: state.system.headlessMode,
      isSpoofed: state.auth.account.isSpoofed,
      loggedIn: selectLoggedIn(state),
      path: state.router.location.pathname,
      query: state.router.location.search,
      recentHistory: state.routeHistory.recentHistory,
      regionCode: state.geo.currentRegionCode,
      userAgent: state.system.rawUserAgentString,
      utm: state.utm,
    } : {},
  }
}

export default getReportDataFromState
