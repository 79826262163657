export function downloadPdf(blob: Blob, fileName: string) {
  // download by adding an anchor with download and clicking it
  const pdfURL = URL.createObjectURL(blob)
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  a.href = pdfURL
  a.download = fileName
  a.click()
  URL.revokeObjectURL(pdfURL)
}
