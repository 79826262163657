import { getUserNotifications, subscribeToUserNotifications, unsubscribeToUserNotifications } from 'api/braze'
import { API_CALL, REMOVE_USER_NOTIFICATION, SUBSCRIPTION_USER_NOTIFICATIONS_UPDATE, VIEW_USER_NOTIFICATIONS } from './actionConstants'
import { AppAction } from './ActionTypes'
import { FETCH_USER_NOTIFICATIONS } from './apiActionConstants'

export function fetchAndSubscribeToUserNotifications(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const memberId = state.auth.account.memberId
    if (
      memberId &&
      !state.auth.fetchingNotifications &&
      !state.auth.notificationsError
    ) {
      dispatch({
        type: API_CALL,
        api: FETCH_USER_NOTIFICATIONS,
        request: async() => {
          const cards = await getUserNotifications()

          subscribeToUserNotifications(newCards => {
            dispatch({
              type: SUBSCRIPTION_USER_NOTIFICATIONS_UPDATE,
              notifications: newCards,
            })
          })

          return cards
        },
      })
    }
  }
}

export function removeUserNotification(notification: App.UserNotification): AppAction {
  return {
    type: REMOVE_USER_NOTIFICATION,
    notification,
  }
}

export function viewAllUserNotifications(): AppAction {
  return {
    type: VIEW_USER_NOTIFICATIONS,
  }
}

export function unsubscribeFromUserNotifications(): AppAction {
  return () => {
    // in theory this is an action, but doesn't really do anything actiony, bit weird
    // maybe revisit later and find a way to make it an actual action
    // e.g. maybe we should be saving the subscription id in redux
    unsubscribeToUserNotifications()
  }
}
