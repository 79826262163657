import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Group from 'components/utils/Group'
import FilterPanelCheckboxGroupItem from 'components/Common/FilterPanel/FilterPanelCheckboxGroupItem'
import { buildPanelCheckItems } from 'components/Cruises/SearchPage/Filters/Inputs/CruiseExtraFiltersInputs'
import FilterSearchInput from 'components/Common/FilterPanel/FilterSearchInput'
import SmallToggleButton from 'components/Luxkit/Button/SmallToggleButton'
import useToggle from 'hooks/useToggle'
import ResponsiveImage from 'components/Common/ResponsiveImage'
import { CRUISE_LINE_CUSTOM_INFO } from 'constants/cruise'
import { sortBy, sum } from 'lib/array/arrayUtils'
import Accordion from 'components/Luxkit/Accordion'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import FilterPanelCheckboxLoader from 'components/Common/FilterPanel/FilterPanelCheckboxLoader'
import useQueryParams from 'hooks/useQueryParams'

interface Props {
  shipItems: Array<App.CruiseSearchFacet>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultSelected?: Set<string>;
  fetching?: boolean;
}

function CruiseShipCheckboxGroup({
  shipItems,
  onChange,
  defaultSelected,
  fetching,
}: Props) {
  const { cruiseLines: searchCruiseLines = [] } = useContext(GlobalSearchStateContext)
  const [cruiseLines] = useCruiseSearchFacets({ facetTypes: ['cruise_lines'] })
  const queryParams = useQueryParams()

  const cruiseLineNames = useMemo(() => {
    // If all cruise lines are selected, return an empty array
    // used to keep all cruise lines closed if all are selected
    if (searchCruiseLines.length === cruiseLines.length) {
      return []
    }

    return cruiseLines
      .filter(({ value }) => searchCruiseLines.includes(value as string))
      .map(({ name }) => name)
  }, [searchCruiseLines, cruiseLines])

  const [limit, setLimit] = useState(10)
  const [showMore, toggleShowMore] = useToggle(defaultSelected?.size! > 0)
  const [cruiseShipSearch, setCruiseShipSearch] = useState<string>()
  const [cruiseLinesOpen, setCruiseLinesOpen] = useState({} as Record<string, boolean>)

  const vendorsGroupedByVendorName = useMemo(() => {
    return shipItems.reduce((acc, shipItem) => {
      const vendorName = shipItem.vendorName || ' '
      if (cruiseShipSearch && !shipItem.name.toLowerCase().includes(cruiseShipSearch.toLowerCase())) {
        return acc
      }

      if (!acc[vendorName]) {
        acc[vendorName] = [shipItem]
      } else {
        acc[vendorName].push(shipItem)
      }
      return acc
    }, {} as Record<string, Array<App.CruiseSearchFacet>>)
  }, [shipItems, cruiseShipSearch])

  const openCruiseLine = useMemo(() => {
    return shipItems.reduce((acc, shipItem) => {
      const vendorName = shipItem.vendorName || ' '
      acc[vendorName] = false
      return acc
    }, {} as Record<string, boolean>)
  }, [shipItems])

  const setItemsCruiseLinesOpen = useCallback((items: Record<string, boolean>, open: boolean) => {
    const newCruiseLineOpen = {} as Record<string, boolean>
    Object.keys(items).forEach((key) => {
      newCruiseLineOpen[key] = open
    })
    setCruiseLinesOpen(newCruiseLineOpen)
  }, [])

  const countAvailableShips = useCallback((ships: Array<App.CruiseSearchFacet>) => {
    return sum(ships, (ship) => (ship?.count || 0) + (ship?.flashOfferCount! || 0))
  }, [])

  useEffect(() => {
    const cruiseLines = queryParams.getAll('cruiseLines')
    if (defaultSelected?.size! > 0 || cruiseLineNames.length || cruiseLines.length) {
      setItemsCruiseLinesOpen(openCruiseLine, true)
      if (!showMore) {
        toggleShowMore()
      }
    } else {
      setCruiseLinesOpen(openCruiseLine)
    }
    // eslint-disable-next-line
  }, [])

  const sortedShips = useMemo(() => {
    Object.entries(vendorsGroupedByVendorName).forEach(([key, ships]) => {
      vendorsGroupedByVendorName[key] = sortBy(ships, ({ count }) => count, 'desc')
    })
    return Object.entries(vendorsGroupedByVendorName)
  }, [vendorsGroupedByVendorName])

  const sortedVendorsByShips = useMemo(() => {
    return sortBy(sortedShips, ([, ships]) => {
      return countAvailableShips(ships)
    }, 'desc')
  }, [countAvailableShips, sortedShips])

  const mappedShips = useMemo(() => {
    return sortedVendorsByShips
      .map(([key, ships]) => {
        const vendorCode = ships.length ? ships[0].code : ''
        return {
          vendorName: key,
          show: false,
          imageId: CRUISE_LINE_CUSTOM_INFO.find(({ code }) => code === vendorCode)?.imageIdSm,
          ships: buildPanelCheckItems(ships),
          countOffers: countAvailableShips(ships),
        }
      }).filter(Boolean)
  }, [countAvailableShips, sortedVendorsByShips])

  const setItemOpen = useCallback((vendorName: string) => () => {
    setCruiseLinesOpen((prev) => {
      return {
        ...prev,
        [vendorName]: !prev[vendorName],
      }
    })
  }, [])

  const onToggleShowMore = useCallback(() => {
    if (!showMore) {
      setLimit(Number.POSITIVE_INFINITY)
    } else {
      setLimit(10)
    }
    toggleShowMore()
  }, [showMore, toggleShowMore])

  const spliceShips = useMemo(() => {
    return showMore ? mappedShips : mappedShips.slice(0, limit)
  }, [showMore, mappedShips, limit])

  const canShowMore = useMemo(() => {
    return mappedShips.length > 10
  }, [mappedShips.length])

  const findShip = useCallback((val: string) => {
    setCruiseShipSearch(val)
    setItemsCruiseLinesOpen(cruiseLinesOpen, !!val)
  }, [cruiseLinesOpen, setItemsCruiseLinesOpen])

  // If there is only one ship, open the accordion by default
  useEffect(() => {
    if (spliceShips.length === 1) {
      setCruiseLinesOpen((prev) => {
        return { ...prev, [spliceShips[0].vendorName]: true }
      })
    }
  }, [spliceShips])

  return <Group direction="vertical" gap={16} data-testid="cruise-ship-checkbox-group">
    {fetching && <>
      <FilterPanelCheckboxLoader />
      <FilterPanelCheckboxLoader />
      <FilterPanelCheckboxLoader />
    </>}
    {!fetching && <>
      <FilterSearchInput
        data-testid="cruise-ship-checkbox-group-search"
        placeholder="Search cruise ships"
        onChange={findShip}
        value={cruiseShipSearch}
      />
      <div>
        {spliceShips.map((item, index) => <Accordion
          key={index}
          data-testid="cruise-ship-checkbox-group-vendor-name"
          heading={item.vendorName}
          startIcon={<ResponsiveImage
            id={item?.imageId!}
            src={item?.imageId}
            width={25}
            quality="eco"
            role="presentation"
          />}
          endLabel={`(${item.countOffers})`}
          onClick={setItemOpen(item.vendorName)}
          hideDivider
          expanded={cruiseLinesOpen[item.vendorName]}
          size="small"
        >
          <Group direction="vertical" gap={16}>
            {!!item?.ships?.length && item?.ships?.map((ship, indexB) => <div
              key={indexB}
              data-testid="cruise-ship-checkbox-group-ship-name"
            >
              <FilterPanelCheckboxGroupItem
                item={ship}
                name="cruiseShips"
                onChange={onChange}
                defaultChecked={defaultSelected?.has(ship.value as string)}
              />
            </div>)}
          </Group>
        </Accordion>)}
      </div>
      {canShowMore && <SmallToggleButton
        data-testid="cruise-ship-checkbox-group-show-more"
        on={showMore}
        onClick={onToggleShowMore}
        size="medium"
        iconPosition="start"
      >
        {showMore ? 'Show less' : 'Show more'}
      </SmallToggleButton>}
    </>}
  </Group>
}

export default CruiseShipCheckboxGroup
