import FilterPanelCheckboxGroup, { FilterPanelCheckItem } from 'components/Common/FilterPanel/FilterPanelCheckboxGroup'
import FilterPanelFilterGroup from 'components/Common/FilterPanel/FilterPanelFilterGroup'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import {
  deleteSearchParams,
  setManySearchParamValues,
  setSearchParamValue,
  toggleSearchParamValue,
} from 'lib/url/searchUrlUtils'
import Heading from 'components/Luxkit/Typography/Heading'
import TextLink from 'components/Luxkit/TextLink'
import { queryKeyFilters, queryKeyPages } from 'constants/url'
import styled from 'styled-components'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import * as InteractionStudioService from 'api/interactionStudio'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
import config from 'constants/config'
import { EmptyArray, isNonEmptyArray, take, unique } from 'lib/array/arrayUtils'
import BusinessTravellerWithinBudgetFilter
  from 'businessTraveller/components/search/hotel-list-filter/BusinessTravellerWithinBudgetFilter'
import Group from 'components/utils/Group'
import {
  ANYWHERE_PLACE_ID,
  CUSTOMER_RATING_GTE,
  CUSTOMER_RATING_GTE_BY_VALUE,
  CUSTOMER_RATING_TO_KEY,
  LIMITED_TIME_LUX_EXCLUSIVE,
} from 'constants/search'
import MapWindow from 'components/Search/MapWindow'
import {
  generateOptionItems,
  hasNoAvailableFilters,
  optionMapToOptions,
  sortPopularItems,
  sortRecentItems,
} from 'components/OfferList/OfferListFilter/OfferListFilterUtils'
import { EnabledFilters, RecentFilters } from 'components/Search/type'
import { OFFER_TYPE_VILLA } from 'constants/offer'
import {
  getAppliedFilterCount,
  getLuxPlusFilterName,
  getRecentFilters,
  isAgentHubExclusiveFilter,
  isOfTypeLuxPlusFeatureFilter,
  saveRecentFilters,
} from 'components/Search/utils'
import { isNumber } from 'lib/maths/mathUtils'
import getOfferListKey from 'lib/offer/offerListKey'
import { NewBadge } from './HotelSearchFilterNewBadge'
import CounterInput from 'components/Common/Form/Input/CounterInput'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { OfferListSortOption, SORT_OPTION_RECOMMENDED } from 'constants/offerListFilters'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useOfferListFilters from 'hooks/Offers/useOfferListFilters'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LuxPlusLabel from 'luxPlus/components/LuxPlusLabel'
import debounce from 'lodash/debounce'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import HotelSearchFilterPaneLoadingSkeleton from './HotelSearchFilterPaneLoadingSkeleton'
import { selectLoggedIn } from '../../../selectors/accountSelectors'
import HotelSearchAddDestinationToggle from './HotelAddDestinationToggle/HotelSearchAddDestinationToggle'

type OfferListFilterType = Exclude<keyof App.OfferListAvailableFilters, 'total'>

const newFilters = new Set(['Homes & Villas'])
const DEBOUNCE_DELAY = 500

const InputGroup = styled(Group)`
  margin-top: ${rem(16)};
`

const StyledBodyText = styled(BodyTextBlock)`
  margin-top: ${rem(8)};
`

const StyledAddDestinationToggle = styled(HotelSearchAddDestinationToggle)`
  border: 1px solid ${(props) => props.theme.palette.neutral.default.five};
  border-radius: 0px 2px 2px 2px;
  padding: ${rem(12)};
`

interface MappedStateProps {
  fetchingPopularFilters: boolean;
  popularFilters: App.PopularFilters;
  pathname: string;
  currentHash: string;
  region: string;
  searchSessionId?: string;
  canViewLuxPlusBenefits: boolean;
  userId?: string;
  isLoggedIn: boolean;
}

interface Props {
  filters: App.OfferListFilters;
  mapViewURL: string;
  search: string;
  enabledFilters: EnabledFilters;
  fetching?: boolean;
  sortOptions?: Array<OfferListSortOption>;
  hotelOfferList?: App.OfferList;
  onClose?: () => void;
}

type Option = [string, number, number];

function HotelSearchPageFilterPane(props: Props & MappedStateProps) {
  const {
    filters,
    fetching,
    mapViewURL,
    sortOptions = EmptyArray,
    search,
    searchSessionId,
    region,
    enabledFilters,
    popularFilters,
    fetchingPopularFilters,
    pathname,
    currentHash,
    onClose,
    canViewLuxPlusBenefits,
    isLoggedIn,
    userId,
  } = props
  const dispatch = useAppDispatch()

  const searchParams = useMemo(() => {
    const params = new URLSearchParams(search)
    params.delete(queryKeyPages)
    return params.toString()
  }, [search])

  const [localAvailableFilters, setLocalAvailableFilters] = useState<App.OfferListFilterOptions | undefined>()
  const recentFilters = getRecentFilters()
  const { globalSearchState } = useGlobalSearchContext()
  const isMapPage = pathname.includes('/map')
  const availableFilters = useOfferListFilters(filters, { noFetch: true })

  useEffect(() => {
    setLocalAvailableFilters(availableFilters)
  }, [availableFilters, filters.propertyId])

  const isAnywhereSearch = filters.destinationId === ANYWHERE_PLACE_ID

  const customerRatingItems = useMemo(
    () => {
      const items = [
        {
          value: CUSTOMER_RATING_GTE.EXCEPTIONAL.value,
          label: <Group direction="horizontal" horizontalAlign="space-between">
            {CUSTOMER_RATING_GTE.EXCEPTIONAL.label}
          </Group>,
          checked: filters?.customerRatingGte === CUSTOMER_RATING_GTE.EXCEPTIONAL.value,
          count: localAvailableFilters?.filters?.customerRatings?.Exceptional,
        },
        {
          value: CUSTOMER_RATING_GTE.VERY_GOOD.value,
          label: <Group direction="horizontal" horizontalAlign="space-between">
            {CUSTOMER_RATING_GTE.VERY_GOOD.label}
          </Group>,
          checked: filters?.customerRatingGte === CUSTOMER_RATING_GTE.VERY_GOOD.value,
          count: localAvailableFilters?.filters?.customerRatings?.['Very Good'],
        },
        {
          value: CUSTOMER_RATING_GTE.GOOD.value,
          label: <Group direction="horizontal" horizontalAlign="space-between">
            {CUSTOMER_RATING_GTE.GOOD.label}
          </Group>,
          checked: filters?.customerRatingGte === CUSTOMER_RATING_GTE.GOOD.value,
          count: localAvailableFilters?.filters?.customerRatings?.Good,
        },
      ]
      return items.filter((item) => item.count && item.count > 0)
    }, [localAvailableFilters?.filters?.customerRatings, filters?.customerRatingGte])

  const { holidayTypeItems, amenityItems, inclusionItems, luxPlusFeatureItems, agentHubFeatureItems } = useMemo(() => {
    const luxPlusFilters = localAvailableFilters?.filters?.luxPlusFeatures ?? {}
    if (!canViewLuxPlusBenefits && 'Member exclusive' in luxPlusFilters) {
      delete luxPlusFilters['Member exclusive']
    }
    const holidayTypes = optionMapToOptions(localAvailableFilters?.filters?.holidayTypes ?? {}, localAvailableFilters?.filterOrder?.holidayTypes ?? {})
    const amenities = optionMapToOptions(localAvailableFilters?.filters?.amenities ?? {}, localAvailableFilters?.filterOrder?.amenities ?? {})
    const luxPlusFeatures = optionMapToOptions(luxPlusFilters ?? {}, {})
    const agentHubFeatures = optionMapToOptions(localAvailableFilters?.filters?.agentHubFeatures ?? {}, {})
    const inclusions = Object.entries(localAvailableFilters?.filters?.inclusions ?? {}).reduce((acc, [category, inclusions]) => {
      acc[category] = optionMapToOptions(inclusions ?? {}, {})
      return acc
    }, {} as Record<string, Array<Option>>)

    const inclusionItems = Object.entries(inclusions).reduce((acc, [category, inclusionOptions]) => {
      acc[category] = generateOptionItems(inclusionOptions, new Set(filters.inclusions), true)
      return acc
    }, {} as Record<string, Array<FilterPanelCheckItem>>)

    return {
      holidayTypeItems: generateOptionItems(holidayTypes, new Set(filters.holidayTypes), true, newFilters),
      amenityItems: generateOptionItems(amenities, new Set(filters.amenities), true),
      luxPlusFeatureItems: generateOptionItems(luxPlusFeatures, new Set(filters.luxPlusFeatures), true),
      inclusionItems,
      agentHubFeatureItems: generateOptionItems(agentHubFeatures, new Set(filters.agentHubFeatures), true),
    }
  }, [localAvailableFilters, canViewLuxPlusBenefits, filters])

  const itemifyFilterGroup = useCallback((filterGroup: App.PopularFilters | RecentFilters) => {
    const flatInclusions: Record<string, number> = {}

    if (localAvailableFilters?.filters?.inclusions) {
      Object.values(localAvailableFilters.filters.inclusions ?? {}).forEach(record => {
        for (const key in record) {
          flatInclusions[key] = record[key]
        }
      })
    }

    const isFilterChecked = (filterGroupKey: string, filter: string) => {
      if (filterGroupKey in filters) {
        const filterGroup = filters[filterGroupKey as keyof App.OfferListFilters]
        if (Array.isArray(filterGroup) && filterGroup.length > 0) {
          return (filterGroup as Array<string>).includes(filter)
        }
      }
      return false
    }

    const items = Object.entries(filterGroup).map(([key, value]: [string, Array<string> | number]) => {
      if (key === 'customerRatingGte' && 'customerRatingGte' in filterGroup && isNumber(value)) {
        const customerRating = filterGroup.customerRatingGte ? CUSTOMER_RATING_GTE_BY_VALUE[filterGroup.customerRatingGte] : undefined
        return {
          value,
          label: customerRating?.label,
          count: localAvailableFilters?.filters?.customerRatings?.[CUSTOMER_RATING_TO_KEY[value]],
          checked: filters.customerRatingGte === value,
        }
      }
      if (isNumber(value) || key === 'orderBy' || (key === 'locations' && !isAnywhereSearch)) {
        return
      }
      return unique(value).map((filter) => ({
        value: filter,
        label: <div>
          <span>{`${key === 'luxPlusFeatures' ? getLuxPlusFilterName(filter) : filter}`}</span>
          {newFilters.has(filter) && <NewBadge>NEW</NewBadge>}
        </div>,
        count: key === 'inclusions' ? flatInclusions[filter] : (localAvailableFilters?.filters?.[key as OfferListFilterType] as { [key: string]: number })?.[filter],
        checked: isFilterChecked(key, filter),
      }))
    }).flat()
    return items.filter(Boolean) as Array<FilterPanelCheckItem>
  }, [filters, isAnywhereSearch, localAvailableFilters])

  const recentFilterItems = useMemo(() => {
    const filteredSortedRecentItems = itemifyFilterGroup(recentFilters)
      .filter(item => item && ((item.count && item.count > 0) || item.checked))
      .sort((a, b) => sortRecentItems(a, b, recentFilters?.orderBy ?? []))
    return take(filteredSortedRecentItems, 10)
  }, [recentFilters, itemifyFilterGroup])

  const popularFilterItems = useMemo(() => {
    const filteredSortedPopularItems = itemifyFilterGroup(popularFilters)
      .filter(item => item?.count && item.count > 0)
      .sort((a, b) => sortPopularItems(a, b, popularFilters.orderBy, newFilters))

    if (isMapPage && localAvailableFilters?.filters?.holidayTypes[LIMITED_TIME_LUX_EXCLUSIVE]) {
      filteredSortedPopularItems.unshift({
        value: LIMITED_TIME_LUX_EXCLUSIVE,
        label: LIMITED_TIME_LUX_EXCLUSIVE,
        count: localAvailableFilters?.filters?.holidayTypes[LIMITED_TIME_LUX_EXCLUSIVE],
        checked: filters.holidayTypes?.includes(LIMITED_TIME_LUX_EXCLUSIVE),
      })
    }
    return take(filteredSortedPopularItems, 10)
  }, [itemifyFilterGroup, popularFilters, isMapPage, localAvailableFilters?.filters?.holidayTypes, filters.holidayTypes])

  useEffect(() => {
    if (!searchSessionId) return

    if (!filters.holidayTypes?.length && !filters.locations?.length && !filters.amenities?.length && !filters.inclusions?.length) return

    InteractionStudioService.trackFilterApply({
      searchSessionId,
      region: region ?? '',
      brand: config.BRAND,
      holidayTypes: filters.holidayTypes || [],
      locations: filters.locations || [],
      amenities: filters.amenities || [],
      inclusions: filters.inclusions || [],
      verticals: globalSearchState.searchVerticals,
      sortBy: filters.sortBy || '',
      bedroomsGte: filters.bedroomsGte,
      bedsGte: filters.bedsGte,
      bathroomsGte: filters.bathroomsGte,
    })
  }, [
    searchSessionId,
    region,
    filters.holidayTypes,
    filters.locations,
    filters.amenities,
    filters.inclusions,
    filters.sortBy,
    filters.bedroomsGte,
    filters.bedsGte,
    filters.bathroomsGte,
    globalSearchState.searchVerticals,
  ])

  // This filter scrolling behaviour is to be further improved in OSS-2137
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant' as any,
    })
  }, [])

  const onLuxPlusFeatureChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value.length && value.split(',').length === 1) {
      dispatch(replace({ search: toggleSearchParamValue(searchParams, 'luxPlusFeatures', value), hash: currentHash }))
      saveRecentFilters({ luxPlusFeatures: [value] })
    } else {
      dispatch(replace({ search: setManySearchParamValues(searchParams, [{ paramName: 'luxPlusFeatures', paramValue: value.length ? value : undefined }]), hash: currentHash }))
    }
    scrollToTop()
  }, [dispatch, searchParams, currentHash, scrollToTop])

  const onAgentHubFeatureChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    dispatch(replace({ search: toggleSearchParamValue(searchParams, 'agentHubFeatures', value), hash: currentHash }))
    saveRecentFilters({ agentHubFeatures: [value as App.AgentHubFeatureFilter] })
    scrollToTop()
  }, [dispatch, searchParams, currentHash, scrollToTop])

  const onHolidayTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replace({ search: toggleSearchParamValue(searchParams, 'holidayTypes', e.target.value), hash: currentHash }))
    saveRecentFilters({ holidayTypes: [e.target.value] })
    scrollToTop()
  }, [dispatch, searchParams, currentHash, scrollToTop])

  const onAmenityChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replace({ search: toggleSearchParamValue(searchParams, 'amenities', e.target.value), hash: currentHash }))
    saveRecentFilters({ amenities: [e.target.value] })
    scrollToTop()
  }, [dispatch, searchParams, currentHash, scrollToTop])

  const onInclusionsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replace({ search: toggleSearchParamValue(searchParams, 'inclusions', e.target.value), hash: currentHash }))
    saveRecentFilters({ inclusions: [e.target.value] })
    scrollToTop()
  }, [dispatch, searchParams, currentHash, scrollToTop])

  const onCustomerRatingChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (filters?.customerRatingGte === Number(e.target.value)) {
      dispatch(replace({ search: deleteSearchParams(searchParams, 'customerRatingGte'), hash: currentHash }))
    } else {
      dispatch(replace({ search: setSearchParamValue(searchParams, 'customerRatingGte', e.target.value), hash: currentHash }))
      saveRecentFilters({ customerRatingGte: Number(e.target.value) })
    }
    scrollToTop()
  }, [filters?.customerRatingGte, dispatch, searchParams, currentHash, scrollToTop])

  const onBedroomsChange = useCallback((value: number) => {
    if (value === 0) {
      dispatch(replace({ search: deleteSearchParams(searchParams, 'bedroomsGte'), hash: currentHash }))
    } else {
      dispatch(replace({ search: setSearchParamValue(searchParams, 'bedroomsGte', value), hash: currentHash }))
    }
    scrollToTop()
  }, [currentHash, dispatch, searchParams, scrollToTop])
  const debouncedBedroomsChange = useMemo(() => debounce(onBedroomsChange, DEBOUNCE_DELAY), [onBedroomsChange])

  const onBedsChange = useCallback((value: number) => {
    if (value === 0) {
      dispatch(replace({ search: deleteSearchParams(searchParams, 'bedsGte'), hash: currentHash }))
    } else {
      dispatch(replace({ search: setSearchParamValue(searchParams, 'bedsGte', value), hash: currentHash }))
    }

    scrollToTop()
  }, [currentHash, dispatch, searchParams, scrollToTop])
  const debouncedBedsChange = useMemo(() => debounce(onBedsChange, DEBOUNCE_DELAY), [onBedsChange])

  const onBathroomsChange = useCallback((value: number) => {
    if (value === 0) {
      dispatch(replace({ search: deleteSearchParams(searchParams, 'bathroomsGte'), hash: currentHash }))
    } else {
      dispatch(replace({ search: setSearchParamValue(searchParams, 'bathroomsGte', value), hash: currentHash }))
    }
    scrollToTop()
  }, [currentHash, dispatch, searchParams, scrollToTop])
  const debouncedBathroomsChange = useMemo(() => debounce(onBathroomsChange, DEBOUNCE_DELAY), [onBathroomsChange])

  const onSortChange = useCallback((value: string) => {
    if (value === SORT_OPTION_RECOMMENDED.value) {
      dispatch(replace({ search: deleteSearchParams(searchParams, 'sortBy'), hash: currentHash }))
    } else {
      dispatch(replace({ search: setSearchParamValue(searchParams, 'sortBy', value), hash: currentHash }))
    }
    scrollToTop()
  }, [currentHash, dispatch, searchParams, scrollToTop])

  const onFilterGroupChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // Find which filter group it belongs to then change the appropriate key in search
    dispatch(replace({ search: deleteSearchParams(searchParams, ...['propertyName', 'propertyId']), hash: currentHash }))
    const value = e.target.value
    let filterGroup: string | undefined
    if (value === 'villa') {
      filterGroup = 'offerType'
    } else if (isAgentHubExclusiveFilter(value)) {
      filterGroup = 'agentHubFeatures'
    } else if ((localAvailableFilters?.filters?.holidayTypes && value in localAvailableFilters.filters.holidayTypes) || filters.holidayTypes?.includes(value)) {
      filterGroup = 'holidayTypes'
    } else if ((localAvailableFilters?.filters?.amenities && value in localAvailableFilters.filters.amenities) || filters.amenities?.includes(value)) {
      filterGroup = 'amenities'
    } else if (!isNaN(parseInt(value))) {
      filterGroup = 'customerRatingGte'
    } else if (filters.inclusions?.includes(value)) {
      filterGroup = 'inclusions'
    } else if (isOfTypeLuxPlusFeatureFilter(value)) {
      filterGroup = 'luxPlusFeatures'
    } else if (localAvailableFilters?.filters?.inclusions) {
      for (const key in localAvailableFilters.filters.inclusions) {
        if (value in localAvailableFilters.filters.inclusions[key]) {
          filterGroup = 'inclusions'
          break
        }
      }
    }
    if (!filterGroup) return
    if (filterGroup !== 'offerType') {
      saveRecentFilters({ [filterGroup]: filterGroup === 'customerRatingGte' ? Number(value) : [value] })
    }
    dispatch(replace({ search: toggleSearchParamValue(searchParams, filterGroup, filterGroup === 'offerType' ? OFFER_TYPE_VILLA : value), hash: currentHash }))
    scrollToTop()
  }, [dispatch, searchParams, localAvailableFilters?.filters, filters, currentHash, scrollToTop])

  const onResetAllClick = useCallback(() => {
    const filtersToDelete = [...queryKeyFilters]
    const newSearch = deleteSearchParams(
      searchParams,
      ...filtersToDelete,
    )
    dispatch(replace({ search: newSearch, hash: currentHash }))
    scrollToTop()
  }, [currentHash, dispatch, searchParams, scrollToTop])

  const filterCount = useMemo(() => {
    return getAppliedFilterCount(filters, enabledFilters)
  }, [filters, enabledFilters])
  const hasFiltersApplied = filterCount > 0

  const noAvailableFilters = localAvailableFilters && !localAvailableFilters.fetching && hasNoAvailableFilters(localAvailableFilters?.filters)
  const showRecentFilters = isNonEmptyArray(recentFilterItems)
  const showPopularFilters = enabledFilters.popularFilters && isNonEmptyArray(popularFilterItems) && !fetchingPopularFilters && !showRecentFilters

  const hasRoomBathOrBedFilters = useMemo(() => {
    return !!(enabledFilters.bedrooms || enabledFilters.bathrooms || enabledFilters.beds)
  }, [enabledFilters.bathrooms, enabledFilters.bedrooms, enabledFilters.beds])

  const showPriceFilter = enabledFilters.priceFilter && config.businessTraveller.currentAccountMode === 'business'
  const isLuxuryEscapes = config.BRAND === 'luxuryescapes'
  return (
    <LayoutContainer>
      <Group direction="vertical" gap={32}>
        {mapViewURL &&
          <MapWindow mapViewURL={mapViewURL}/>}
        <CSSBreakpoint min="tablet">
          <Group direction="vertical" gap={24}>
            {isLuxuryEscapes && filters.destinationId && filters.destinationId !== ANYWHERE_PLACE_ID && isLoggedIn && userId && <StyledAddDestinationToggle userId={userId} placeId={filters.destinationId}/>}
            <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
              <Heading variant="heading4">Filters</Heading>
              {hasFiltersApplied && <TextLink onClick={onResetAllClick}>{`Reset all (${filterCount})`}</TextLink>}
            </Group>
          </Group>
        </CSSBreakpoint>
        <CSSBreakpoint max="mobile">
          <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
            <Heading variant="heading4">Sort & filters</Heading>
            <IconButton kind="tertiary" variant="dark" horizontalOutdent="end" onClick={onClose} aria-label="close">
              <LineTimesIcon />
            </IconButton>
          </Group>
        </CSSBreakpoint>
      </Group>
      {sortOptions.length > 0 && <CSSBreakpoint max="mobile">
        <FilterPanelFilterGroup title="Sort by">
          <InputGroup direction="vertical" gap={12}>
            {sortOptions.map(sortOption => <RadioInput
              key={`sortOption_${sortOption.value}`}
              name="sortBy"
              value={sortOption.value}
              onChange={() => onSortChange(sortOption.value)}
              checked={sortOption.value === (filters?.sortBy ?? SORT_OPTION_RECOMMENDED.value)}
            >
              {sortOption.label}
            </RadioInput>)}
          </InputGroup>
        </FilterPanelFilterGroup>
      </CSSBreakpoint>}
      {availableFilters.fetching && <HotelSearchFilterPaneLoadingSkeleton />}
      {noAvailableFilters && <StyledBodyText variant="medium">No filters available</StyledBodyText>}
      {showRecentFilters && <FilterPanelFilterGroup loading={fetching} title="Recent filters">
        <FilterPanelCheckboxGroup
          name="recentFilters"
          items={recentFilterItems}
          onChange={onFilterGroupChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}
      {showPriceFilter && <FilterPanelFilterGroup loading={fetching} title="Price">
        {config.businessTraveller.currentAccountMode === 'business' &&
          <BusinessTravellerWithinBudgetFilter
            filters={filters}
            search={searchParams}
          />
        }
      </FilterPanelFilterGroup>}
      {showPopularFilters && <FilterPanelFilterGroup loading={fetching} title="Popular filters">
        <FilterPanelCheckboxGroup
          name="popularFilters"
          items={popularFilterItems}
          onChange={onFilterGroupChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}
      {enabledFilters.luxPlusFeatures && luxPlusFeatureItems.length > 0 && <FilterPanelFilterGroup loading={fetching} title={<LuxPlusLabel type="only-logo" />}>
        <FilterPanelCheckboxGroup
          name="luxPlusFeatures"
          items={luxPlusFeatureItems}
          onChange={onLuxPlusFeatureChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}
      {enabledFilters.agentHubFeatures && agentHubFeatureItems.length > 0 && <FilterPanelFilterGroup loading={fetching} title="Agent Hub Features">
        <FilterPanelCheckboxGroup
          name="agentHubFeatures"
          items={agentHubFeatureItems}
          maxItems={5}
          onChange={onAgentHubFeatureChange}
        />
      </FilterPanelFilterGroup>}
      {enabledFilters.customerRating && isNonEmptyArray(customerRatingItems) && <FilterPanelFilterGroup loading={fetching} title="Customer rating">
        <FilterPanelCheckboxGroup
          name="customerRating"
          items={customerRatingItems}
          onChange={onCustomerRatingChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}
      {hasRoomBathOrBedFilters && <FilterPanelFilterGroup loading={fetching}
        title="Rooms"
      >
        <InputGroup direction="vertical" gap={12}>
          {enabledFilters.bedrooms && <CounterInput
            name="bedroomsGte"
            defaultValue={filters.bedroomsGte ?? 0}
            onChange={debouncedBedroomsChange}
            min={0}
            max={10}
            label="Bedrooms"
            direction="horizontal"
            tabletDirection="horizontal"
          />}
          {enabledFilters.beds && <CounterInput
            name="bedsGte"
            defaultValue={filters.bedsGte ?? 0}
            onChange={debouncedBedsChange}
            min={0}
            max={10}
            label="Beds"
            direction="horizontal"
            tabletDirection="horizontal"
          />}
          {enabledFilters.bathrooms && <CounterInput
            name="bathroomsGte"
            defaultValue={filters.bathroomsGte ?? 0}
            onChange={debouncedBathroomsChange}
            min={0}
            max={10}
            label="Bathrooms"
            direction="horizontal"
            tabletDirection="horizontal"
          />}
        </InputGroup>
      </FilterPanelFilterGroup>}
      {!config.businessTraveller.isEnabled && enabledFilters.holidayTypes && isNonEmptyArray(holidayTypeItems) && <FilterPanelFilterGroup loading={fetching} title={`Type of ${config.brandConfig.holidaySynonym}`}>
        <FilterPanelCheckboxGroup
          name="holidayTypes"
          items={holidayTypeItems}
          onChange={onHolidayTypeChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}
      {enabledFilters.inclusions && isNonEmptyArray(Object.entries(inclusionItems)) &&
        <FilterPanelFilterGroup loading={fetching} title="Inclusions">
          <VerticalSpacer gap={24}>
            {Object.entries(inclusionItems).map(([category, items]) => (
              <div key={category}>
                <BodyText variant="medium" weight="bold">{category}</BodyText>
                <FilterPanelCheckboxGroup
                  key={category}
                  name="inclusions"
                  items={items}
                  onChange={onInclusionsChange}
                  maxItems={5}
                />
              </div>
            ))}
          </VerticalSpacer>
        </FilterPanelFilterGroup>}
      {enabledFilters.amenities && isNonEmptyArray(amenityItems) && <FilterPanelFilterGroup loading={fetching} title="Amenities">
        <FilterPanelCheckboxGroup
          name="amenities"
          items={amenityItems}
          onChange={onAmenityChange}
          maxItems={5}
        />
      </FilterPanelFilterGroup>}

    </LayoutContainer>
  ) }

export default connect<MappedStateProps, undefined, Props, App.State>(
  (appState, ownProps): MappedStateProps => {
    const key = getOfferListKey(ownProps.filters)
    return {
      region: appState.geo.currentRegionCode,
      searchSessionId: appState.analytics.search.sessions[key],
      popularFilters: appState.destination.popularFilters,
      fetchingPopularFilters: appState.destination.fetchingPopularFilters,
      pathname: appState.router.location.pathname,
      currentHash: appState.router.location.hash,
      canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(appState),
      userId: appState.auth.account.memberId,
      isLoggedIn: selectLoggedIn(appState),
    }
  },
)(HotelSearchPageFilterPane)
