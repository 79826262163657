import { createSelector } from 'reselect'
import { getTourV2Items } from 'checkout/selectors/view/toursv2'
import { sortBy } from 'lib/array/arrayUtils'
import { sumUpOccupancies } from 'lib/offer/occupancyUtils'
import {
  SUPERIOR_PACKAGE_OPTIONS_AVAILABLES,
  TOUR_V2_TARGET_OCCUPANT_TYPES,
  TourOptionPackageUpgrades,
} from 'constants/tours'
import generateDepartureRoomPriceBreakdown from 'lib/tours/generateDepartureRoomPriceBreakdown'
import {
  getPackageOptionType,
  getRoomTypeCapacity,
  hasRoomTypeUpgrade,
  formatRoomType, checkPurchasableOptionHasPackageUpgradeOrRoomType,
} from 'lib/tours/tourUtils'
import { EmptyObject, isEmptyObject } from 'lib/object/objectUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import { isLuxPlusAvailableToAddToCart } from './navigationSelectors'
import { getTourV2CheckoutItems } from 'checkout/selectors/view/accommodation'
import config from 'constants/config'

export const getTourAvailablePackageDetails = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers) => {
    const item = items[0]
    return tourOffers?.[item?.offerId]?.variations?.[item?.purchasableOption?.fkVariationId]?.availablePackageUpgrades ?? []
  },
)

export const getTourV2Offer = createSelector(
  (state: App.State) => getTourV2CheckoutItems(state),
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): App.Tours.TourV2Offer => {
    const item = items[0]
    return tourOffers?.[item?.offerId]
  },
)

export const isPostPurchaseTourOptionalExperience = createSelector(
  (state: App.State) => state.checkout.cart.mode,
  (mode) => mode === 'tour-optional-experiences',
)

export const isPostPurchaseTourChangeDates = createSelector(
  (state: App.State) => state.checkout.cart.mode,
  (mode) => mode === 'tour-change-dates',
)

export const isPaymentScheduleBalance = createSelector(
  (state: App.State) => state.checkout.cart.mode,
  (mode) => mode === 'payment-schedule-balance',
)

export const isDepositBalance = createSelector(
  (state: App.State) => state.checkout.cart.mode,
  (mode) => mode === 'deposit-balance',
)

export const getTourPurchasableAndPriceOptionsByPackage = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): Map<string, Array<[App.Tours.TourV2OfferPurchasableOption, App.Tours.V2OfferDeparturePriceBreakdown]>> => {
    const purchasablePriceOptionsPackageMap = new Map<string, Array<[App.Tours.TourV2OfferPurchasableOption, App.Tours.V2OfferDeparturePriceBreakdown]>>()
    items.forEach(item => {
      tourOffers[item.offerId]?.purchasableOptions
        .filter(
          purchasableOption => purchasableOption.fkDepartureId === item.purchasableOption.fkDepartureId &&
          getRoomTypeCapacity(purchasableOption.roomType) === sumUpOccupancies([item.occupancy], TOUR_V2_TARGET_OCCUPANT_TYPES),
        )
        .forEach(purchasableOption => {
          const priceBreakdown = generateDepartureRoomPriceBreakdown(
            item.occupancy,
            purchasableOption,
            tourOffers[item.offerId],
          )
          const packageOptionType = getPackageOptionType(purchasableOption.roomType)
          const purchasableOptionsWithPriceBreakDownByOption = purchasablePriceOptionsPackageMap.get(packageOptionType) ?? []
          purchasableOptionsWithPriceBreakDownByOption.push([purchasableOption, priceBreakdown])
          purchasablePriceOptionsPackageMap.set(packageOptionType, purchasableOptionsWithPriceBreakDownByOption)
        })
    })
    purchasablePriceOptionsPackageMap.forEach((purchasableOptionsWithPriceBreakDown: Array<[App.Tours.TourV2OfferPurchasableOption, App.Tours.V2OfferDeparturePriceBreakdown]>, packageOption) => {
      purchasablePriceOptionsPackageMap.set(packageOption, sortBy(
        purchasableOptionsWithPriceBreakDown,
        ([purchasableOption, _]) => purchasableOption.price,
        'asc',
      ))
    })
    return purchasablePriceOptionsPackageMap
  },
)

export const getTourV2InventorySummaryOfCartItems = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): Map<string, number> => {
    const departurePurchasableOptions = tourOffers?.[items[0]?.offerId]?.purchasableOptions.filter(purchasableOption => {
      return purchasableOption.fkDepartureId === items[0]?.purchasableOption.fkDepartureId // All items will have same departure Id
    })
    const roomTypeInventoryMap = new Map()
    departurePurchasableOptions?.forEach(purchasableOption => {
      roomTypeInventoryMap.set(purchasableOption.roomType, purchasableOption.inventoryLeft)
    })
    return roomTypeInventoryMap
  },
)

export const isTourItineraryPDFEnabled = createSelector(
  (state: App.State) => state.auth.account.isSpoofed,
  (isSpoofed):boolean => {
    return config.TOUR_ITINERARY_PDF_DOWNLOAD || isSpoofed
  },
)

export const isTourGenericItineraryPDFEnabled = createSelector(
  (state: App.State) => state.auth.account.isSpoofed,
  (isSpoofed):boolean => {
    return config.TOUR_GENERIC_ITINERARY_DOWNLOAD || isSpoofed
  },
)

export const getTourSelectablePurchasableOptionsByOccupant = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): Map<string, Array<App.Tours.TourV2OfferPurchasableOption>> => {
    const selectablePurchasableOptionsOccupantMap = new Map()
    items.forEach(item => {
      const purchasableOptionsByDeparture = tourOffers?.[item.offerId]?.purchasableOptions.filter(purchasableOption =>
        purchasableOption.fkDepartureId === item.purchasableOption.fkDepartureId &&
        getRoomTypeCapacity(purchasableOption.roomType) === sumUpOccupancies([item.occupancy], TOUR_V2_TARGET_OCCUPANT_TYPES),
      )
      selectablePurchasableOptionsOccupantMap.set(JSON.stringify(item.occupancy), purchasableOptionsByDeparture)
    })
    return selectablePurchasableOptionsOccupantMap
  },
)

export const getTourSelectablePurchasableOptionsWithPriceByOccupant = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): Map<App.Occupants, Map<App.Tours.TourV2OfferPurchasableOption, App.Tours.V2OfferDeparturePriceBreakdown>> => {
    const selectablePurchasableOptionsOccupantMap = new Map()
    items.forEach(item => {
      const purchasableOptionsByDeparture = tourOffers?.[item.offerId]?.purchasableOptions.filter(purchasableOption =>
        purchasableOption.fkDepartureId === item.purchasableOption.fkDepartureId &&
        getRoomTypeCapacity(purchasableOption.roomType) === sumUpOccupancies([item.occupancy], TOUR_V2_TARGET_OCCUPANT_TYPES),
      ) ?? []
      const purchasableOptionsWithPriceBreakDownByOption = new Map()
      purchasableOptionsByDeparture.forEach(purchasableOption => {
        const priceBreakdown = generateDepartureRoomPriceBreakdown(item.occupancy, purchasableOption, tourOffers[item.offerId])
        purchasableOptionsWithPriceBreakDownByOption.set(purchasableOption, priceBreakdown)
      })
      selectablePurchasableOptionsOccupantMap.set(item.occupancy, purchasableOptionsWithPriceBreakDownByOption)
    })
    return selectablePurchasableOptionsOccupantMap
  },
)

export const getTourV2PackageOptionsMissingRoomType = createSelector(
  (state: App.State) => getTourPurchasableAndPriceOptionsByPackage(state),
  (purchasableOptionsByPackage): Map<string, string | undefined> => {
    const packageOptionsMissingRoomType = new Map<string, string | undefined>()

    const packageOptionsAvailableRoomsTypes = new Map<string, Array<string | undefined>>()
    purchasableOptionsByPackage.forEach((purchasableOptions, packageOption) => {
      const availableRooms = purchasableOptions.map(([purchasableOption]) => formatRoomType(purchasableOption.roomType))
      packageOptionsAvailableRoomsTypes.set(packageOption, availableRooms)
    })

    const availablePackageOptions = [...purchasableOptionsByPackage.keys()]
    packageOptionsAvailableRoomsTypes.forEach((availableRooms, packageOption) => {
      availablePackageOptions.forEach(packageOptionToCheck => {
        if (packageOptionToCheck !== packageOption) {
          const availableRoomsToCheck = packageOptionsAvailableRoomsTypes.get(packageOptionToCheck) ?? []
          const missingRooms = availableRoomsToCheck.filter(room => !availableRooms.includes(room))
          packageOptionsMissingRoomType.set(packageOption, missingRooms[0])
        }
      })
    })
    return packageOptionsMissingRoomType
  },
)

export const selectablePurchasableOptionsHasRoomTypeUpgrade = createSelector(
  (state: App.State) => getTourSelectablePurchasableOptionsByOccupant(state),
  (selectablePurchasableOptionsByOccupant: Map<string, Array<App.Tours.TourV2OfferPurchasableOption>>): boolean =>
    Array.from(selectablePurchasableOptionsByOccupant.values())
      .filter(purchasableOptions => purchasableOptions && purchasableOptions.length > 1)
      .flatMap<App.Tours.TourV2OfferPurchasableOption>(purchasableOption => purchasableOption)
      .some(purchasableOption => hasRoomTypeUpgrade(purchasableOption?.roomType)),
)

export const hasMultiplePurchasableOptionsWithPackageUpgradeOrRoomTypeUpgrade = createSelector(
  (state: App.State) => getTourSelectablePurchasableOptionsByOccupant(state),
  (selectablePurchasableOptionsByOccupant: Map<string, Array<App.Tours.TourV2OfferPurchasableOption>>): boolean => {
    const purchasbleOptions = Array.from(selectablePurchasableOptionsByOccupant.values())
      .filter(purchasableOptions => purchasableOptions && purchasableOptions.length > 1)
      .flatMap<App.Tours.TourV2OfferPurchasableOption>(purchasableOption => purchasableOption)
    const { hasPackageUpgrade, hasRoomUpgrade } = checkPurchasableOptionHasPackageUpgradeOrRoomType(purchasbleOptions)
    return hasPackageUpgrade || hasRoomUpgrade
  },
)

export const hasOptionalExtras = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): boolean => {
    return items.some(item => !isEmptyObject(tourOffers?.[item.offerId]?.purchasableExperienceOptions ?? EmptyObject))
  },
)

export const isLuxPlusToursEnabled = createSelector(
  (state: App.State) => isLuxPlusEnabled(state),
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (isLuxPlusEnabled, optimizelyFeatureFlags) => !!(isLuxPlusEnabled && optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusToursToggle]),
)

const hasMemberPrice = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): boolean => {
    return items.some(item => {
      return tourOffers?.[item.offerId]?.purchasableOptions?.some(purchasableOption => !!purchasableOption?.memberPrice) ?? false
    })
  },
)

const isEarlyAccessForMembers = createSelector(
  (state: App.State) => getTourV2Offer(state),
  (offer): boolean => {
    return offer?.luxPlus?.access === 'earlyAccess'
  },
)

export const shouldShowLuxPlusUpsellForTour = createSelector(
  (state: App.State) => isLuxPlusToursEnabled(state),
  (state: App.State) => hasMemberPrice(state),
  (state: App.State) => isLuxPlusAvailableToAddToCart(state),
  (state: App.State) => isEarlyAccessForMembers(state),
  (isLuxPlusToursEnabled, hasMemberPrice, isLuxPlusAvailableToAddToCart, isEarlyAccessForMembers): boolean => !!(isLuxPlusToursEnabled && (hasMemberPrice || isEarlyAccessForMembers) && isLuxPlusAvailableToAddToCart),
)

export const getTourPackageOptions = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder?.tourItems,
  (state: App.State) => isPostPurchaseTourChangeDates(state),
  (orderTourItems: Array<App.Tours.TourV2OrderItem> | undefined, isPostPurchaseTourChangeDates): Array<string> => {
    if (isPostPurchaseTourChangeDates && orderTourItems?.[0]?.packageOption?.packageType) {
      // Return only better package options
      const existingPackageType = orderTourItems[0].packageOption.packageType
      return SUPERIOR_PACKAGE_OPTIONS_AVAILABLES[existingPackageType]
    }
    return TourOptionPackageUpgrades
  },
)
