import { getGroupedBundledPackages } from 'lib/bundleAndSave/getGroupedBundledPackages'
import { getPriceBundlePackage } from 'lib/bundleAndSave/getPriceBundlePackage'
import { nonNullable } from 'lib/array/arrayUtils'

export function getBundlePackagesWithPrice(offer: App.BundleOffer, options?: Array<{
  offerId: string;
  duration: number;
}>) {
  const bundledPackages = getGroupedBundledPackages(offer, options)

  return nonNullable(Object.values(offer.bundlePackages).map((p) => {
    if (bundledPackages[p.id]?.length) {
      const prices = getPriceBundlePackage(bundledPackages[p.id], offer.saleUnit)
      return {
        ...p,
        ...prices,
        packages: bundledPackages[p.id],
      }
    }
  }))
}
