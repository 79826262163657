import { exceedsTicketAvailability } from 'lib/checkout/experiences/cart'

function calculateValue(price: number, discountPercentage: number) {
  const priceFraction = 1 - (discountPercentage / 100)
  if (priceFraction === 0) { return 0 }
  return price / priceFraction
}

interface TicketViewOptions {
  isBookDates?: boolean;
  orderItemId?: string;
  /**
   * Whether or not this item has a loyalty discount or not, making it free
   */
  hasLoyaltyDiscount?: boolean;
}

/**
 * Creates a ticket view for a single ticket being purchased
 * This view takes itself being a full item view (and having a backing item)
 * so it can be used in further calculations at the ticket level
 * TODO: Update experiences to store cart items per ticket instead of per experience
 */
function mapExperienceCartItemToTicketItemView(
  cartTicketItem: App.Checkout.ExperienceItemTicket,
  ticket: App.ExperienceItemTicket,
  transactionKey: string,
  itemId: string,
  options: TicketViewOptions = {},
): App.Checkout.ExperienceItemTicketItemView {
  const { hasLoyaltyDiscount, isBookDates, orderItemId } = options
  const exceededLimit = ticket ? exceedsTicketAvailability(cartTicketItem.count, ticket.purchaseLimit.max) : false
  const hasPrice = !isBookDates && !hasLoyaltyDiscount

  return {
    ticket,
    luxLoyaltyProductType: 'experience',
    item: {
      itemId,
      itemType: 'experienceTicket',
      transactionKey,
    },
    totals: {
      price: hasPrice ? ticket.price : 0,
      memberPrice: 0,
      value: isBookDates ? 0 : calculateValue(ticket.price, ticket.discounts.base.amount ?? 0),
      memberValue: 0,
      surcharge: 0,
      extraGuestSurcharge: 0,
      taxesAndFees: ticket.taxesAndFees ?? 0,
      mobileAppDiscount: {
        amount: hasPrice ? ticket.discounts.app.amount : 0,
        percentage: (ticket.discounts.app.amount > 0 && hasPrice) ? ticket.discounts.app.percentage : 0,
      },
    },
    unavailable: !ticket || exceededLimit,
    date: ticket.date,
    time: ticket.time,
    orderItemId,
  }
}

export default mapExperienceCartItemToTicketItemView
