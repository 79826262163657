import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { replace } from 'connected-react-router'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import React, { useMemo } from 'react'
import useBusinessTravellerBusinessBudgetFetcher from 'businessTraveller/hooks/useBusinessTravellerBusinessBudgetFetcher'
import { getCheckInDateFromURLSearchParams, getCheckOutDateFromURLSearchParams, propertyToDestinationSearch, setSearchParamValue } from 'lib/url/searchUrlUtils'
import { queryKeyPriceLte } from 'constants/url'
import { useAppDispatch } from 'hooks/reduxHooks'

const SHOULD_DEFAULT = '0'
const SHOW_ALL_BUDGET = '10000'

export default function BusinessTravellerHotelBudgetMessageBanner({
  windowSearch,
  filters,
}: {
  windowSearch: string
  filters: App.OfferListFilters
}) {
  const dispatch = useAppDispatch()

  const { checkInDate, checkOutDate } = useMemo(() => {
    const searchParams = new URLSearchParams(windowSearch)
    return {
      checkInDate: getCheckInDateFromURLSearchParams(searchParams),
      checkOutDate: getCheckOutDateFromURLSearchParams(searchParams),
    }
  }, [windowSearch])

  const totalNights = checkOutDate?.diff(checkInDate, 'days')
  const { budgetData } = useBusinessTravellerBusinessBudgetFetcher({})

  const currentBudget = budgetData?.budgets ? budgetData.budgets[0] : undefined
  const totalBudget = useMemo(() => (currentBudget?.budget ?? 0) * (totalNights ?? 0), [currentBudget?.budget, totalNights])
  const tenOverBudget = useMemo(() => Math.ceil(totalBudget * 1.1), [totalBudget])
  const twentyOverBudget = useMemo(() => Math.ceil(totalBudget * 1.2), [totalBudget])

  const selectedBudget = filters?.priceLte ?? SHOULD_DEFAULT

  const budgetMessage = useMemo(() => {
    if (selectedBudget === totalBudget.toString()) {
      return 'Only showing properties within budget.'
    }
    if (selectedBudget === tenOverBudget.toString()) {
      return 'Only showing properties up to 10% over budget.'
    }
    if (selectedBudget === twentyOverBudget.toString()) {
      return 'Only showing properties up to 20% over budget.'
    }
  }, [selectedBudget, tenOverBudget, twentyOverBudget, totalBudget])

  if (!budgetMessage) {
    return null
  }

  return <MessageBanner
    icon={<LineInfoCircleIcon />}
    kind="informational-primary"
    description={budgetMessage}
    density="compact"
    secondaryActionButtonProps={{
      children: 'Show all properties',
      onClick: () => {
        dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(windowSearch, queryKeyPriceLte, SHOW_ALL_BUDGET)) }))
      },
    }}
  />
}
