import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId, getHotelOrTourOffer } from 'analytics/snowplow/helpers/itemCategorisation'
import { getLEPackageItemTotals } from 'checkout/lib/utils/accommodation/view'
import { isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import { getItemUniqueKey } from 'checkout/lib/utils/accommodation/cart'
import sanitizeItemContextMetadata from './sanitizeItemContextMetadata'
import { OfferPackageOptionView } from 'selectors/offerPage/offerPageSelectors'
import { getHasLuxPlusOrSubscriptionInMultiCart } from 'selectors/userCart'

function getTotals(
  state: App.State,
  item: App.Checkout.BundleAndSaveItem | App.Checkout.BedbankHotelItem | App.Checkout.InstantBookingLEHotelItem | App.Checkout.BNBLLEHotelItem,
  offer: App.Offer,
) {
  if (isInstantBookingLEHotelItem(item)) {
    const uniqueKey = getItemUniqueKey(item)
    const pkg = offer.packages.find(pkg => pkg.uniqueKey === uniqueKey)
    if (!pkg) return
    if (!state.calendar.calendarsByOccupancy[pkg.uniqueKey]) return
    const itemTotals = getLEPackageItemTotals(
      offer,
      pkg,
      item.checkIn,
      item.checkOut,
      item.occupancy,
      undefined,
      state.calendar.calendarsByOccupancy,
      state.offer.offerAvailableRatesByOccupancy,
    )
    return itemTotals
  }
}

function getHotelCartItem(
  state: App.State,
  item: App.Checkout.BundleAndSaveItem | App.Checkout.BedbankHotelItem | App.Checkout.InstantBookingLEHotelItem | App.Checkout.BNBLLEHotelItem,
  packageView?: OfferPackageOptionView,
  isMultiCart?: boolean,
) {
  const offerId = item.offerId
  const hotelOrTourOffer = getHotelOrTourOffer(state, offerId)
  const luxPlusMemberPriceApplies = getHasLuxPlusOrSubscriptionInMultiCart(state)
  const offer = hotelOrTourOffer as App.Offer
  const isFlashHotelOrVilla = item.itemType === 'hotel' || item.itemType === 'villa'
  const instantBooking = isFlashHotelOrVilla && item.reservationType === 'instant_booking'
  const reservationType = isFlashHotelOrVilla ? item.reservationType : 'instant_booking'
  const { categoryId, productId } = getCategoryAndProductId('hotel', offer)
  const offerLeadPrice = offer?.offerFlightsEnabled && offer?.lowestPriceBundlePackage ? offer?.lowestPriceBundlePackage?.price : offer?.lowestPricePackage?.price
  const geo = offer?.property?.geoData
  const destinationCity = geo?.locality
  const destinationCountry = geo?.country
  const destinationRegion = geo?.administrativeAreaLevel1

  const accommodationTotals = getTotals(state, item, offer)
  const memberPriceRaw = packageView?.hotelMemberPrice ?? accommodationTotals?.memberPrice
  const nonMemberPrice = packageView?.basePrice ?? accommodationTotals?.price
  const propertyFees = packageView?.propertyFees ?? accommodationTotals?.otherFees?.propertyFees ?? 0
  const surcharge = packageView?.surcharge ?? accommodationTotals?.surcharge

  const price = (luxPlusMemberPriceApplies && item.itemType === 'hotel' && memberPriceRaw && memberPriceRaw > 0) ? memberPriceRaw : nonMemberPrice
  const value = packageView?.hotelValue ?? accommodationTotals?.value
  const memberPrice = memberPriceRaw && memberPriceRaw > 0 ? memberPriceRaw : nonMemberPrice

  const bundleDates = item.itemType === 'bundleAndSave' && item.dates[offerId]
  let duration: number | undefined
  let travelStart: string | undefined
  let travelEnd: string | undefined
  if (bundleDates) {
    duration = bundleDates.duration
    travelStart = bundleDates.checkIn
    travelEnd = bundleDates.checkOut
  } else if (instantBooking) {
    duration = item.duration
    travelStart = item.checkIn
    travelEnd = item.checkOut
  }

  const travellers = item.occupancy

  return comluxgroup.createItem_1_1_0(
    {
      offerId,
      categoryId,
      productId,
      rateId: item.itemType !== 'bundleAndSave' ? item.roomRateId : undefined,
      travelStart,
      travelEnd,
      duration,
      price,
      value,
      numberOfAdults: travellers?.adults,
      numberOfChildren: travellers?.children,
      numberOfInfants: travellers?.infants,
      childrenAges: travellers?.childrenAge,
      offerLeadPrice,
      offerLeadDuration: offer?.lowestPricePackage?.duration ? String(offer?.lowestPricePackage?.duration) : undefined,
      offerLeadUnit: offer?.saleUnit,
      propertyFees,
      surcharge,
      destinationCity,
      destinationCountry,
      destinationRegion,
      reservationType,
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
      metadata: sanitizeItemContextMetadata({
        memberPrice,
        nonMemberPrice,
        isMultiCart,
      }),
    },
  )
}

export default getHotelCartItem
