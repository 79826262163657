import { io, Socket } from 'socket.io-client'
import config from 'constants/config'
import { SocketEvents } from '@luxuryescapes/contract-search'

export let globalSearchSocket: Socket<SocketEvents> | undefined = undefined
export const SEARCH_STREAMING_PAGE_SIZE = 4

export function getSearchSocket() {
  // If the connection is connected, use it
  if (globalSearchSocket?.connected) {
    return globalSearchSocket
  }
  // If the connection is active but disconnected, re-connect it
  if (globalSearchSocket?.active && globalSearchSocket?.disconnected) {
    globalSearchSocket.connect()
    return globalSearchSocket
  }
  // If the connection is non-existent, not active or not connected
  // Force close it in case, and make a new one
  if (!globalSearchSocket?.active || !globalSearchSocket?.connected) {
    disconnectSearchSocket()

    const protocol = config.SCHEME === 'http' ? 'ws' : 'wss'
    const uri = `${protocol}://${config.API_HOST}`

    globalSearchSocket = io(uri, {
      transports: ['websocket'],
      path: '/api/search/stream',
    })
  }
  return globalSearchSocket
}

export function disconnectSearchSocket() {
  if (!globalSearchSocket) return

  globalSearchSocket.disconnect()
  globalSearchSocket = undefined
}
