import { createSelector } from 'reselect'
import { EmptyArray } from 'lib/array/arrayUtils'
import { getCarHireItemsView } from 'checkout/selectors/view/carHire'

const getCheckoutCarHirePointsCalculationRequests = createSelector(
  (state: App.State) => getCarHireItemsView(state),
  (carHireViews): Array<App.LuxLoyaltyDefaultPointsEarnCalculationRequest> => {
    if (!carHireViews.hasRequiredData) return EmptyArray

    return carHireViews.data.map((itemView) => {
      const selectedInsurance = itemView.item.selectedInsurance
      const price = (itemView.totals.carHirePayOnArrival ? 0 : itemView.totals.price) + (selectedInsurance ? selectedInsurance.total : 0)
      // Taxes and Fees are due at Pickup, so are not accounted for points
      const taxesAndFees = itemView.totals.taxesAndFees ? itemView.totals.taxesAndFees : 0
      const totalPrice = price - taxesAndFees
      return {
        price: totalPrice,
        luxLoyaltyProductType: 'car_hire',
        itemId: itemView.item.itemId,
      }
    })
  },
)

export default getCheckoutCarHirePointsCalculationRequests
