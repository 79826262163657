import { USER_LOGOUT, USER_LOGIN, USER_REGISTRATION } from 'actions/apiActionConstants'
import { canBroadcast, LUX_BROADCAST_CHANNEL } from 'lib/web/broadcastChannelUtils'
import { AppStore } from './store'
import { SYNC_USER_CART } from 'actions/actionConstants'

function initialiseBroadcastListener(store: AppStore) {
  if (canBroadcast()) {
    const channel = new BroadcastChannel(LUX_BROADCAST_CHANNEL)
    channel.onmessage = e => {
      if (e.data.action) {
        switch (e.data.action.api) {
          case USER_LOGOUT:
            if (store.getState().auth.account.memberId) {
              store.dispatch({
                ...e.data.action,
                supressBroadcast: true,
              })
            }
            break
          case USER_LOGIN:
          case USER_REGISTRATION:
            if (!store.getState().auth.account.memberId) {
              store.dispatch({
                ...e.data.action,
                supressBroadcast: true,
              })
            }
            break
        }
        switch (e.data.action.type) {
          case SYNC_USER_CART:
            store.dispatch(e.data.action)
            break
        }
      }
    }
  }
}

export default initialiseBroadcastListener
