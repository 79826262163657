import { createSelector } from 'reselect'

export const selectBusinessTravellerCurrentApprovalRequest = createSelector(
  (state: App.State) => state.businessTraveller.approvalRequestId,
  (state: App.State) => state.businessTraveller.approvalRequests,
  (approvalRequestId, approvalRequests) => {
    if (!approvalRequestId) {
      return undefined
    }

    return approvalRequests?.request?.find(request => request.id === approvalRequestId)
  },
)
