import { EmptyArray, sortBy } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import { EmptyObject } from 'lib/object/objectUtils'
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { InView, useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import ResponsiveImage, { ImageParams } from 'components/Common/ResponsiveImage'
import { mod } from 'lib/maths/mathUtils'

const Carousel = styled.div`
  display: flex;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 1 0 100%;
    scroll-snap-align: center;
  }
`

const CarouselTrack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
`

const ExternalImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

const InternalImage = styled(ResponsiveImage)`
  height: 100%;
`

export interface ImageCarouselRefInterface {
  /**
   * Returns the current index the carousel is on
   * Note: This may not always be an image as the index includes any provided interstitials
   */
  getCurrentImageIndex: () => number;
  /**
   * Moves the carousel to the next image
   */
  nextImage: () => void;
  /**
   * Moves the carousel to the previous image
   */
  previousImage: () => void;
  /**
   * Moves the carousel to a specific image
   * Can choose if it should animate to the image or appear there instantly
   */
  goToImage: (image: App.Image, method?: 'smooth' | 'instant') => void;
}

export interface ImageCarouselInterstitial {
  id: string;
  position: number | 'start' | 'end';
  element: React.ReactNode;
}

interface ImageCarouselItem {
  id: string;
  position: number | 'start' | 'end';
  type: 'interstital' | 'image';
  element?: React.ReactNode;
  image?: App.Image;
}

export type ImageChangeHandler = (index: number, image?: App.Image) => void;
export type ImageLoadHandler = (index: number, image?: App.Image) => void;

interface Props {
  /** The index of the image to start the carousel on, defaults to first image */
  defaultIndex?: number;
  images: Array<App.Image>;
  interstitials?: Array<ImageCarouselInterstitial>;
  className?: string;
  imageParams?: ImageParams;
  eagerLoadFirstImage?: boolean;
  disablePlaceholders?: boolean;
  carouselRef?: React.RefObject<ImageCarouselRefInterface | null>;
  onImageChange?: ImageChangeHandler;
  onImageLoad?: ImageLoadHandler
}

function BaseImageCarousel({
  images,
  className,
  imageParams = EmptyObject,
  eagerLoadFirstImage,
  carouselRef,
  disablePlaceholders,
  onImageChange = noop,
  onImageLoad = noop,
  interstitials = EmptyArray,
  defaultIndex = 0,
}: Props) {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(defaultIndex)
  const [seenImages, setSeenImages] = useState<Set<string>>(new Set())
  const localCarouselRef = useRef<HTMLDivElement>(null)

  const imageItems = useMemo((): Array<ImageCarouselItem> => {
    const allItems = [
      ...interstitials.map((interstitial): ImageCarouselItem => ({
        id: interstitial.id,
        position: interstitial.position,
        element: interstitial.element,
        type: 'interstital',
      })),
      ...images.map((image, index): ImageCarouselItem => ({
        id: image.id ?? image.url ?? index.toString(),
        position: index + 1,
        image,
        type: 'image',
      })),
    ]

    return sortBy(allItems, item => {
      if (item.position === 'end') {
        return 10000
      } else if (item.position === 'start') {
        return -1
      } else {
        return item.position
      }
    }, 'asc')
  }, [interstitials, images])

  const goToImage = useCallback((index: number, method: 'smooth' | 'instant' = 'instant') => {
    if (localCarouselRef.current) {
      const offset = localCarouselRef.current.offsetWidth
      const nextLeft = offset * index

      localCarouselRef.current.scrollTo({
        left: nextLeft,
        // @ts-ignore 'instant' is still supported by browsers and works as we intend it.
        // Auto would still sometimes smooth scroll it
        behavior: method,
      })
    }
  }, [])

  const moveSlide = useCallback((direction: 'previous' | 'next') => {
    const nextIndex = direction === 'previous' ? currentImageIndex - 1 : currentImageIndex + 1
    const swapsEnds = (direction === 'previous' && currentImageIndex === 0) ||
    (direction === 'next' && currentImageIndex === (imageItems.length - 1))
    goToImage(mod(nextIndex, imageItems.length), swapsEnds ? 'instant' : 'smooth')
  }, [currentImageIndex, goToImage, imageItems.length])

  useEffect(() => {
    if (defaultIndex > 0) {
      goToImage(defaultIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeImage = useCallback((index: number) => {
    const image = imageItems[index].image
    if (index !== currentImageIndex) {
      setCurrentImageIndex(index)
      onImageChange(index, image)
    }
  }, [currentImageIndex, imageItems, onImageChange])

  const loadImage = useCallback((event: React.SyntheticEvent<HTMLImageElement>) => {
    const index = parseInt(event.currentTarget.dataset.index ?? '0')
    const image = imageItems[index].image
    onImageLoad(index, image)
  }, [imageItems, onImageLoad])

  useImperativeHandle(carouselRef, () => ({
    getCurrentImageIndex: () => currentImageIndex,
    previousImage: () => moveSlide('previous'),
    nextImage: () => moveSlide('next'),
    goToImage: (image, method) => {
      const imageIndex = Math.max(imageItems.findIndex(item => item.image === image), 0)
      goToImage(imageIndex, method)
      changeImage(imageIndex)
    },
  }))

  const onImageInViewChange = useCallback((inView: boolean, entry: IntersectionObserverEntry) => {
    if (inView) {
      const index = parseInt((entry.target as HTMLDivElement).dataset.index ?? '0')
      if (index !== currentImageIndex) {
        changeImage(index)
      }
      const image = imageItems[index].image
      setSeenImages((seenImages) => new Set([image?.id ?? image?.url ?? '', ...seenImages]))
    }
  }, [changeImage, currentImageIndex, imageItems])

  const [preloadIndexs, preloadImageIndex] = useMemo(() => {
    return [
      // allow preloading of images 2 behind/2 in front
      new Set([
        mod(currentImageIndex - 2, imageItems.length),
        mod(currentImageIndex - 1, imageItems.length),
        currentImageIndex,
        mod(currentImageIndex + 1, imageItems.length),
        mod(currentImageIndex + 2, imageItems.length),
      ]),
      // we preload the full image only one behind/in front
      new Set([
        mod(currentImageIndex - 1, imageItems.length),
        currentImageIndex,
        mod(currentImageIndex + 1, imageItems.length),
      ]),
    ]
  }, [currentImageIndex, imageItems.length])

  // turn off item intersection observers when the carousel itself isn't in view
  const [carouselInViewRef, carouselInView] = useInView({
    skip: imageItems.length === 0,
    initialInView: imageItems.length === 0,
    rootMargin: '100px 100px 100px 100px',
  })

  return <CarouselTrack ref={carouselInViewRef} className={className}>
    <Carousel ref={localCarouselRef}>
      {imageItems.map((item, index) => <InView
        onChange={onImageInViewChange}
        threshold={0.5}
        key={item.id}
        skip={
          // can't be seen, don't need to watch
          !carouselInView ||
          // not in preload bounds, turn it off
          !preloadIndexs.has(index)
        }
        data-index={index}
      >
        {item.type === 'interstital' && item.element}
        {item.type === 'image' && item.image && <>
          {(item.image.url && !item.image.id) && <ExternalImage
            src={item.image.url}
            alt={item.image.title}
            data-index={index}
            onLoad={loadImage}
          />}
          {item.image.id && <InternalImage
            {...imageParams}
            fit={imageParams.fit ?? 'center'}
            id={item.image.id}
            alt={item.image.title}
            data-index={index}
            loading={(eagerLoadFirstImage && index === 0) ? 'eager' : 'lazy-managed'}
            showImage={seenImages.has(item.image.id) || (carouselInView && preloadImageIndex.has(index))}
            disablePlaceholder={!!disablePlaceholders || !preloadIndexs.has(index)}
            onLoad={loadImage}
          />}
        </>}
      </InView>)
      }
    </Carousel>
  </CarouselTrack>
}

export default React.memo(BaseImageCarousel)
