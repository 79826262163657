import BodyText from 'components/Luxkit/Typography/BodyText'
import Modal from 'components/Luxkit/Modal/Modal'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Heading from 'components/Luxkit/Typography/Heading'
import React from 'react'
import TextButton from 'components/Luxkit/Button/TextButton'
import { CABIN_CATEGORIES_SORT_ORDER, CABIN_CATEGORY_DESCRIPTIONS } from 'constants/cruise'
import { CabinTypeMap } from '../CruiseSelectCabinType'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import CruiseCabinTypeGrid from 'components/Cruises/Common/CruiseCabinTypeGrid'
import CruiseCabinTypeTileWrapper from 'components/Cruises/Common/CruiseCabinTypeTileWrapper'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import PriceRowPriceWithCaption from 'components/Luxkit/PricePoints/PriceRowPriceWithCaption'

interface Props {
  cabinTypeMap: CabinTypeMap
}

function CruiseCabinTypeInfoModal({ cabinTypeMap }: Props) {
  const modalState = useModalElementContext<string>()
  const isPricePerNight = useCruisePriceByNight()
  const priceProperties = isPricePerNight ? 'minimumPricePerNight' : 'minimumPrice'

  return <Modal
    size="L"
    title="Which cabin type is right for me?"
    subtitle="Find the perfect cruise cabin to match your needs."
  >
    <Group gap={24} direction="vertical">
      <CruiseCabinTypeGrid>
        {CABIN_CATEGORIES_SORT_ORDER.map((cabinType) => (
          <CruiseCabinTypeTileWrapper
            key={cabinType}
            direction="vertical"
            gap={12}
          >
            <VerticalSpacer gap={16}>
              <Heading variant="heading4">{cabinType}</Heading>
              <BodyText variant="small">
                {CABIN_CATEGORY_DESCRIPTIONS[cabinType]}
              </BodyText>
            </VerticalSpacer>
            <Group direction="horizontal" verticalAlign="center" horizontalAlign="space-between">
              <Group direction="vertical" verticalAlign="center" gap={2}>
                <Group direction="horizontal" verticalAlign="center" gap={2}>
                  <PriceRowPriceWithCaption
                    price={cabinTypeMap[cabinType]?.[priceProperties]}
                    size="S"
                    caption="From"
                    saleUnit={isPricePerNight ? '/night' : '/person'}
                  />
                </Group>
              </Group>

              <TextButton
                kind="secondary"
                onClick={() => modalState.resolve(cabinType)}
              >
                View
              </TextButton>
            </Group>
          </CruiseCabinTypeTileWrapper>
        ))}
      </CruiseCabinTypeGrid>
    </Group>
  </Modal>
}

export default CruiseCabinTypeInfoModal
