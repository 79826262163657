import { isEmptyObject } from 'lib/object/objectUtils'

type ArrayOrObject<T> = Array<T> | { [key: string]: T }

/**
 * Returns a new array, sorted by the value returned by `valueFunc` for each element.
 * Does not modify the source array.
 * @param {Array} values The array to sort
 * @param {Function} valueFunc A function that returns the value to sort by
 * @param {'desc' | 'asc'} [direction]
 */
export function sortBy<T, V>(
  values: Array<T> | IterableIterator<T>,
  valueFunc: (val: T) => V,
  direction: 'desc' | 'asc',
) {
  return [...values].sort((v1, v2) => {
    const a = valueFunc(v1)
    const b = valueFunc(v2)
    if (a > b) {
      return direction === 'desc' ? -1 : 1
    } else if (a < b) {
      return direction === 'desc' ? 1 : -1
    } else {
      return 0
    }
  })
}

const returnValFunc = (val: any) => val

/**
 * Converts the array given to a Map keys by the key selector function given
 * @param array The array to convert to a map
 * @param keySelector A function whose return value will be used as key of the map
 * @param valueSelector A function whose return value will be used as value of the map
 */
export function arrayToMap<K, T, R = T>(
  array: Array<T> | IterableIterator<T>,
  keySelector: (val: T, index: number) => K,
  valueSelector: (val: T, index: number) => R = returnValFunc,
): Map<K, R> {
  if (Array.isArray(array)) {
    return array.reduce((map, val, index) => {
      map.set(keySelector(val, index), valueSelector(val, index))
      return map
    }, new Map())
  } else {
    // must be an iterator
    const map = new Map<any, any>()
    let index = 0
    for (const val of array) {
      map.set(keySelector(val, index), valueSelector(val, index))
      index++
    }
    return map
  }
}

/**
 * Converts the array given to a object by the key selector function given
 * @param array The array to convert to a object
 * @param keySelector A function whose return value will be used as key of the object
 * @param valueSelector A function whose return value will be used as value of the object
 */
export function arrayToObject<T, R = T, K extends string | number = string | number>(
  array: Readonly<Array<T>> | IterableIterator<T> = [],
  keySelector: (val: T, index: number) => K,
  valueSelector: (val: T, index: number) => R = returnValFunc,
): { [key in K] : R } {
  const values = Array.isArray(array) ? array : Array.from(array)
  return values.reduce<{ [key in K] : R }>((map, val, index) => {
    map[keySelector(val, index)] = valueSelector(val, index)
    return map
  }, {} as { [key in K]: R; })
}

export interface SortField<T> {
  selector: (val: T) => any
  direction: 'desc' | 'asc'
}

function sortBySingle<T>(v1: T, v2: T, sortFields: Array<SortField<T>>, sortFieldIndex: number) {
  const sortField = sortFields[sortFieldIndex]
  const a = sortField.selector(v1)
  const b = sortField.selector(v2)
  if (a > b) {
    return sortField.direction === 'asc' ? 1 : -1
  } else if (a === b) {
    const nextSortField = sortFields[sortFieldIndex + 1]
    if (nextSortField) {
      return sortBySingle(v1, v2, sortFields, sortFieldIndex + 1)
    }
    return 0
  } else if (a < b) {
    return sortField.direction === 'asc' ? -1 : 1
  } else {
    // certain invalid values are not equal, less or greater!
    // So we'll treat them as though they should be left in place - or equal
    return 0
  }
}

/**
 * Sorts by all the sort fields given
 * Defaults to descending order (highest to lowest)
 */
export function sortByAll<T>(
  values: Array<T>,
  sortFields: Array<SortField<T>>,
): Array<T> {
  return [...values].sort((v1, v2) => sortBySingle(v1, v2, sortFields, 0))
}

export function max<T>(
  values: Utils.NonEmptyArray<T>,
  valueFunc?: (val: T) => number | Date,
): T
export function max<T>(
  values: Array<T>,
  valueFunc?: (val: T) => number | Date,
): T | undefined
export function max<T>(
  values: Array<T> | Utils.NonEmptyArray<T>,
  valueFunc: (val: T) => number | Date = returnValFunc,
): T | undefined {
  if (values.length === 0) return undefined

  return values.reduce((highest, val) => {
    if (valueFunc(val) > valueFunc(highest)) {
      return val
    }

    return highest
  })
}

export function min<T>(
  values: Utils.NonEmptyArray<T>,
  valueFunc?: (val: T) => number | Date,
): T
export function min<T>(
  values: Array<T>,
  valueFunc?: (val: T) => number | Date,
): T | undefined
export function min<T>(
  values: Array<T> | Utils.NonEmptyArray<T>,
  valueFunc: (val: T) => number | Date = returnValFunc,
): T | undefined {
  if (values.length === 0) return undefined

  return values.reduce((lowest, val) => {
    if (valueFunc(val) < valueFunc(lowest)) {
      return val
    }

    return lowest
  })
}

/**
 * Take the number of values specified from the array
 * If no enough values are in the array, will take as many as it can
 */
export function take<T>(
  iterable: Array<T> | IterableIterator<T>,
  vals: number,
  startIndex = 0,
): Array<T> {
  if (Array.isArray(iterable)) {
    return iterable.slice(startIndex, startIndex + vals)
  } else {
    const newList = new Array<T>(vals)
    let result: IteratorResult<T>
    for (let i = 0; i < (startIndex + vals); i++) {
      result = iterable.next()
      if (result.done) {
        break
      }
      if (i >= startIndex) {
        newList[i - startIndex] = result.value
      }
    }
    return newList
  }
}

/**
 * Take the number of values specified from the array that match the condition passed
 * If no enough values are in the array, will take as many as it can
 */
export function takeWhile<T>(
  iterable: Array<T> | IterableIterator<T>,
  predicate: (item: T) => boolean,
  vals: number,
  startIndex = 0,
): Array<T> {
  const newList: Array<T> = []
  let index = 0
  for (const value of iterable) {
    if (index >= startIndex) {
      if (predicate(value)) {
        newList.push(value)
      }

      if (newList.length === vals) {
        break
      }
    }
    index++
  }

  return newList
}

/**
 * Skips the given number of values in an iterable and returns
 * a new array with only the values after the skipped
 * @param iterable An iterable object to skip values in
 * @param skip The number of values to be skipped (from the start)
 */
export function skip<T>(
  iterable: Array<T> | IterableIterator<T>,
  skip: number,
): Array<T> {
  if (Array.isArray(iterable)) {
    return iterable.slice(Math.max(skip, 0))
  } else {
    const newList: Array<T> = []
    let index = 0
    for (const val of iterable) {
      if (index >= skip) {
        newList[index - skip] = val
      }
      index++
    }
    return newList
  }
}

/**
 * Retrieves the last value of an array
 * @param array The array
 */
export function last<T>(array: Array<T>): T {
  return array[array.length - 1]
}

/**
 * Takes an array (or object) and moves them into a map, keyed (grouped) by the
 * returned value of the selector
 */
export function groupBy<K, T, R = T>(
  vals: ArrayOrObject<T> = [],
  keySelector: (val: T, index: number) => K,
  valueSelector: (val: T) => R = returnValFunc,
): Map<K, Array<R>> {
  const valsAsArray = Array.isArray(vals) ? vals : Object.values(vals)

  return valsAsArray.reduce((grouped, val, index) => {
    const groupKey = keySelector(val, index)
    const newValue = valueSelector(val)
    let groupValues = grouped.get(groupKey)
    if (!groupValues) {
      groupValues = []
      grouped.set(groupKey, groupValues)
    }
    groupValues.push(newValue)
    return grouped
  }, new Map())
}

/**
 * Takes an array (or object) and counts the values, keyed (grouped) by the
 * returned value of the selector
 */
export function countBy<K, T>(
  vals: ArrayOrObject<T> = [],
  keySelector: (val: T, index: number) => K,
  valueSelector?: (val: T, index: number) => number,
): Map<K, number> {
  const valsAsArray = Array.isArray(vals) ? vals : Object.values(vals)

  return valsAsArray.reduce((grouped, val, index) => {
    const groupKey = keySelector(val, index)
    const groupCount = grouped.get(groupKey) ?? 0
    const increment = valueSelector?.(val, index) ?? 1
    grouped.set(groupKey, groupCount + increment)
    return grouped
  }, new Map())
}

/**
 * Takes an array (or object) and counts the values, keyed (grouped) by the
 * returned value of the selector. Returns as record instead of a map
 */
export function countByAsRecord<K extends keyof any, T>(
  vals: ArrayOrObject<T> = [],
  keySelector: (val: T, index: number) => K,
  valueSelector?: (val: T, index: number) => number,
): Record<K, number> {
  const valsAsArray = Array.isArray(vals) ? vals : Object.values(vals)

  return valsAsArray.reduce<Record<K, number>>((grouped, val, index) => {
    const groupKey = keySelector(val, index)
    const groupCount = grouped[groupKey] ?? 0
    const increment = valueSelector?.(val, index) ?? 1
    grouped[groupKey] = groupCount + increment
    return grouped
  }, {} as any)
}

function numberIdentity(value: any) {
  return value as number
}

/**
 * Iterates over the value given and adds all the numbers returned by the selector up
 * returning the result. If no selector is specified, will assume the values
 * are a list of numbers and add them together
 * @param {Array | Object} values A list or object of values
 * @param {Function} selector Used to determine what to add, if not specified
 */
export function sum<T>(
  values: ArrayOrObject<T>,
  selector: (val: T, index: number) => number = numberIdentity,
): number {
  const valsAsArray = Array.isArray(values) ? values : Object.values(values)
  return valsAsArray.reduce((sum, value, i) => sum + selector(value, i), 0)
}

/**
 * Calculates the average for the given items.
 * @param {Array | Object} values A list or object of values
 * @param {Function} selector Used to determine what value to use for each item
 */
export function average<T>(
  values: ArrayOrObject<T>,
  selector: (val: T, index: number) => number = numberIdentity,
): number {
  const count = Array.isArray(values) ? values.length : Object.values(values).length
  return sum(values, selector) / count
}

/**
 * Returns a new array without the values passed included.
 * This is checked as a whole difference, no selector on how to remove here
 * (use filter for that)
 */
export function without<T>(array: Array<T>, ...values: Array<T>): Array<T> {
  const toRemove = new Set(values)
  return array.filter(val => !toRemove.has(val))
}

/**
 * Inspired by lodash partition.
 * Creates an array of elements split into two groups, the first of which contains elements predicate returns
 * truthy for, the second of which contains elements predicate returns falsey for.
 * The predicate is invoked with one argument: (value).
 * If the predicate is a type guard, the returned array types are narrowed accordingly.
 * @param {*} vals
 * @param {*} predicate
 */
export function partitionBy<T, T1 extends T>(vals: ArrayOrObject<T>, predicate: (val: T, index: number) => val is T1): [Array<T1>, Array<Exclude<T, T1>>];
export function partitionBy<T>(vals: ArrayOrObject<T>, predicate: (val: T, index: number) => boolean): [Array<T>, Array<T>];
export function partitionBy<T>(vals: ArrayOrObject<T>, predicate: (val: T, index: number) => boolean) {
  const valsAsArray = Array.isArray(vals) ? vals : Object.values(vals)

  return valsAsArray.reduce<[Array<T>, Array<T>]>((partitioned, val, index) => {
    if (predicate(val, index)) {
      partitioned[0].push(val)
    } else {
      partitioned[1].push(val)
    }
    return partitioned
  }, [[], []])
}

const defaultFillSelector = <T>(index: number) => index as T
/**
 * Create a new array of the length given filled with the values
 * returned by the function given
 */
export function fillArray<T = number>(
  size: number,
  selector: (index: number) => T = defaultFillSelector,
): Array<T> {
  if (size >= 0) {
    const newArray = Array<T>(size)
    for (let i = 0; i < size; i++) {
      newArray[i] = selector(i)
    }
    return newArray
  }

  return []
}

/**
 * Filters the values down to only the unique values in the array given
 * @param array The array of items to return unique values of
 * @returns A new array of only the unique values
 */
export function unique<T>(array: Array<T>): Array<T> {
  return [...new Set<T>(array)]
}

/**
 * This function is like `unique` except that it accepts iteratee which is
 * invoked for each element in array to generate the criterion by which
 * uniqueness is computed.
 * @param array The array of items to return unique values of
 * @returns A new array of only the unique values
 */
export function uniqueBy<T, Element>(array: Array<Element>, iteratee: (t: Element) => T): Array<Element> {
  const memo = new Map<T, Element>()

  for (const el of array) {
    const u = iteratee(el)
    if (!memo.has(u)) memo.set(u, el)
  }

  return Array.from(memo.values())
}

/**
 * A helper function to count values in an iterable
 * @param iterable The iterable to count
 * @param predicate A function that returns true or false on whether it should be counted
 * @returns The total count
 */
export function countWhere<T>(
  iterable: Array<T> | IterableIterator<T> = [],
  predicate?: (val: T) => boolean,
): number {
  const arrayed = Array.isArray(iterable) ? iterable : Array.from(iterable)
  if (predicate) {
    return arrayed.filter(predicate).length
  }
  return arrayed.length
}

/**
 * Trim an array on trailing, leading, or both ends looking for a specific value
 * @export
 * @template T - Array elements' type
 * @template K - Targeted value type
 * @param {T[]} values - Array of elements to be trimmed
 * @param {(value: K) => boolean} valueTestFunc - The func to test the to-be-trimmed element's value
 * @param {('both-ends' | 'leading' | 'trailing')} [trimDirection='both-ends'] - Direction target of the trim
 * @param {(value: T) => K} [valueFunc=returnValFunc] - The func to access the targeted property in each element
 * @return {*}  {T[]} Trimmed array
 */
export function trimArray<T>(
  values: Array<T> | null | undefined,
  valueTestFunc: (value: T) => boolean,
  trimDirection: 'both-ends' | 'leading' | 'trailing' = 'both-ends',
): Array<T> {
  if (!(values && values.length > 0)) { return [] }

  const array = [...values]

  if (trimDirection === 'leading' || trimDirection === 'both-ends') {
    while (array.length && valueTestFunc(array[0])) {
      array.shift()
    }
  }
  if (trimDirection === 'trailing' || trimDirection === 'both-ends') {
    while (array.length && valueTestFunc(last(array))) {
      array.pop()
    }
  }

  return array
}

export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}

export function nonNullable<T>(values: Array<T | undefined>): Array<NonNullable<T>> {
  return values.filter(isNonNullable)
}

export function isEmpty(value: any) {
  if (Array.isArray(value) || typeof value === 'string') {
    return value.length === 0
  }

  if (value === null || typeof value === 'undefined') {
    return true
  }

  if (typeof value === 'object') {
    return isEmptyObject(value)
  }

  return false
}

export function isShallowEqual<T>(a: Array<T>, b: Array<T>): boolean {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length &&
    a.every((val, idx) => val === b[idx])
}

/**
 * Helper function to remove non-truthy values from an array in a single pass
 * @param array The array to map
 * @param mapFunc Function that applies to map and returns the new value
 * @returns An array of mapped functions that are considered truthy
 */
export function truthyMap<T, R>(
  array: Array<T>,
  mapFunc: (val: T, index: number) => R,
): Array<R> {
  return array.reduce((accumulator, current, index) => {
    const mappedVal = mapFunc(current, index)

    if (mappedVal) {
      accumulator.push(mappedVal)
    }

    return accumulator
  }, [] as Array<R>)
}

/**
 * Helper function to split an array by chunks
 * @param array The array to map
 * @param chunkSize chunk size
 * @returns An array is divided by chunks
 */
export function split<T>(array: Array<T>, chunkSize: number): Array<Array<T>> {
  return array
    .map((_, i) => i % chunkSize ? [] : array.slice(i, i + chunkSize))
    .reduce((prev: Array<Array<T>>, curr: Array<T>) => {
      if (curr.length) {
        return [...prev, curr]
      }
      return prev
    }, [])
}

/**
 * Splits the array into parts up to the limit provided, where the number added to the limit
 * is based upon the `weightFunc` selector provided
 * @param values An array of values
 * @param limit The "limit" (number) to reach before moving to the next chunk
 * @param weightFunc A function that returns a number that is the "weight" of the chunk coming through
 * @returns An array of chunks of the values given
 */
export function splitByWeight<T>(
  values: Array<T>,
  options: {
    limit: number,
    weightFunc?: (value: T, index: number, count: number) => number,
    overflowBehaviour?: 'next-chunk' | 'current-chunk',
  },
): Array<Array<T>> {
  const {
    limit,
    weightFunc = () => 1,
    overflowBehaviour = 'current-chunk',
  } = options

  // The current chunk index
  let currentChunk = 0
  // The weight score for the current chunk
  let weight = 0
  return values.reduce<Array<Array<T>>>((chunks, value, index) => {
    // get our current chunk (or default it to a new one)
    const chunk = chunks[currentChunk] ?? []
    if (!chunks[currentChunk]) {
      chunks.push(chunk)
    }

    if (overflowBehaviour === 'current-chunk') {
      // current chunk always puts it into the current one (funnily enough)
      chunk.push(value)
    }

    // test if this would fill out chunk
    const nextWeight = weightFunc(value, index, weight)
    weight = weight + nextWeight
    if (weight >= limit) {
      if (overflowBehaviour === 'next-chunk') {
        if (chunk.length === 0) {
          // weight overflows an emtpy chunks, can't have it empty so it has to go into this chunk
          chunk.push(value)
          currentChunk++
          weight = 0
        } else {
          // would overflow, move it to the next chunk
          const nextChunk = [value]
          chunks.push(nextChunk)
          currentChunk++
          weight = nextWeight
        }
      } else {
        // This chunk is full, reset the weight and move onto the next chunk
        currentChunk++
        weight = 0
      }
    } else if (overflowBehaviour === 'next-chunk') {
      // have to check before we can add it for next chunk
      chunk.push(value)
    }

    return chunks
  }, [])
}

export const EmptySet = new Set<any>()
// 'add' is the only way for values to get into a set, don't allow this to happen for empty set
EmptySet.add = () => {
  if (process.env.NODE_ENV !== 'production') {
    throw new Error('Cannot modify EmptySet')
  }

  return EmptySet
}

export const EmptyArray = new Proxy<Array<any>>([], {
  set() {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error('Cannot modify EmptyArray')
    }
    return true
  },
})

export const EmptyMap = new Map<any, any>()
// 'set' is the only way for values to get into a set, don't allow this to happen for empty map
EmptyMap.set = () => {
  if (process.env.NODE_ENV !== 'production') {
    throw new Error('Cannot modify EmptyMap')
  }

  return EmptyMap
}

/**
 * Helper for updating specific item(s) in an array according to a predicate.
 * i.e. a conditional `Array.map()`
 * You can also omit the array argument to get a partially applied function.
 * @param predicate A function that returns true or false on whether an element should be updated
 * @param updater A function that returns the updated element
 * @param array The array to update
 * @returns A new array with the updated elements
 */
export function updateWhere<T>(predicate: (val: T) => boolean, updater: (val: T) => T, array: Array<T>): Array<T>;
export function updateWhere<T>(predicate: (val: T) => boolean, updater: (val: T) => T, array: undefined): undefined;
export function updateWhere<T>(predicate: (val: T) => boolean, updater: (val: T) => T): <A extends Array<T> | undefined>(array: A) => A;
export function updateWhere<T>(predicate: (val: T) => boolean, updater: (val: T) => T, array?: Array<T>): any {
  if (arguments.length === 2) {
    return (arr: Array<T>) => updateWhere(predicate, updater, arr)
  }
  if (!array) {
    return undefined
  }
  return array.map((val) => {
    if (predicate(val)) {
      return updater(val)
    }
    return val
  })
}

export function isNonEmptyArray<T>(value: any): value is Array<T> {
  return Array.isArray(value) && value.length > 0
}

export function intersectArrays<T>(array1: Array<T>, array2: Array<T>): Array<T> {
  const set2 = new Set(array2)
  return array1.filter(element => set2.has(element))
}

export function conformToArray<T>(value: T | Array<T> | undefined): Array<T> {
  if (Array.isArray(value)) {
    return value
  } else if (value !== undefined) {
    return [value]
  }
  return []
}

export function forceElementToFirstPosition<T>(array: Array<T>, element?: T): Array<T> {
  if (element) {
    return [element, ...array.filter((current) => current !== element)]
  } else {
    return array
  }
}

export function arrayRange(start: number, stop: number) {
  return Array.from(
    { length: (stop - start) + 1 },
    (value, index) => start + index,
  )
}

export function isIterable<T>(value: any): value is Iterable<T> {
  return value && typeof value[Symbol.iterator] === 'function'
}

/**
 * Removes consecutive duplicates from an array element,
 * keeping the first occurrence.
 *
 * @example
 * // returns [1, 2, 3]
 * removeConsecutiveDuplicates([1, 1, 2, 3, 3, 3])
 */
export function removeConsecutiveDuplicates<Element>(
  value: Array<Element>,
  /**
   * Callback used when selecting the value from the element.
   *
   * @default (el) => el
   */
  valueSelector: (element: Element) => any = (el) => el,
): Array<Element> {
  return value.reduce<Array<Element>>((acc, curr, currIndex, array) => {
    if (
      currIndex === 0 ||
      valueSelector(curr) !== valueSelector(array[currIndex - 1])
    ) acc.push(curr)
    return acc
  }, [])
}
