import { createSelector } from 'reselect'
import isInsuranceSupported from '../request/isInsuranceSupported'
import getCheckoutInsuranceProductFetchStatus from './getCheckoutInsuranceProductFetchStatus'
import getCheckoutInsuranceQuoteFetchStatus from './getCheckoutInsuranceQuoteFetchStatus'

const getIsCheckoutInsuranceFetching = createSelector(
  (state: App.State) => isInsuranceSupported(state),
  (state: App.State) => getCheckoutInsuranceProductFetchStatus(state),
  (state: App.State) => getCheckoutInsuranceQuoteFetchStatus(state),
  (insuranceSupported, productStatus, quoteStatus): boolean => {
    if (!insuranceSupported) {
      return false
    }
    const quoteFetching = quoteStatus.some(status => status.fetching)
    const productFetching = productStatus.fetching
    return quoteFetching || productFetching
  },
)

export default getIsCheckoutInsuranceFetching
