/**
 * Picks the relevant price value based on the given LuxPlus membership state.
 */
function getItemViewTotalsPrice(
  totals: App.Checkout.ItemViewTotals,
  isMember: boolean,
): number {
  return ((isMember && totals.memberPrice) || totals.price) + totals.extraGuestSurcharge
}

export default getItemViewTotalsPrice
