import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import sanitizeItemContextMetadata from './sanitizeItemContextMetadata'

function getLuxPlusCartItem(state: App.State, item: App.Checkout.LuxPlusSubscriptionItem, isMultiCart?: boolean) {
  return comluxgroup.createItem_1_1_0(
    {
      offerId: item.offerId,
      categoryId: 'luxury_plus',
      productId: 'luxury_plus',
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
      price: item.amount,
      value: item.amount,
      offerLeadPrice: item.amount,
      metadata: sanitizeItemContextMetadata({
        isMultiCart,
      }),
    },
  )
}
export default getLuxPlusCartItem
