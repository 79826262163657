import { createSelector } from 'reselect'
import getDepositCalculations from './getDepositCalculations'

const getDepositServiceFee = createSelector(
  (state: App.State) => getDepositCalculations(state),
  (calculations): number => {
    return calculations.serviceFee
  },
)

export default getDepositServiceFee
