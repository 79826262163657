import luxuryEscapesTheme, { luxuryEscapesBorderRadii, luxuryEscapesDefaultPalette, luxuryEscapesInversePalette } from './luxuryescapes'
import { baseThemeShadows } from './shadows'

export const leBusinessTravellerDefaultPalette: App.Design.Palette<string> = {
  ...luxuryEscapesDefaultPalette,
}

export const leBusinessTravellerInversePalette: App.Design.Palette<string> = {
  ...luxuryEscapesInversePalette,
}

export const leBusinessTravellerShadows: App.Design.Shadows<string> = { ...baseThemeShadows }
export const leBusinessTravellerBorderRadii: App.Design.BorderRadii<string> = { ...luxuryEscapesBorderRadii }

const leBusinessTravellerTheme: App.Design.Theme = {
  ...luxuryEscapesTheme,
}

export default leBusinessTravellerTheme
