import React, { useMemo } from 'react'
import styled from 'styled-components'
import useToggle from 'hooks/useToggle'
import { skip, take } from 'lib/array/arrayUtils'
import SmallToggleButton from 'components/Luxkit/Button/SmallToggleButton'
import SlideDown from 'components/utils/SlideDown'
import Group from 'components/utils/Group'
import Typography from 'components/Luxkit/Typography/Typography'
import RadioGroup from 'components/Luxkit/Radio/RadioGroup'
import RadioInput from 'components/Luxkit/Radio/RadioInput'

const WhiteRadioButtonInput = styled(RadioInput)`
  > input[type="radio"] {
    background: ${(props) => props.theme.palette.neutral.default.eight};
  }
`

export interface FilterPanelRadioItem {
  label: React.ReactNode;
  value: number | string;
  checked?: boolean;
  hidden?: boolean;
  count?: number;
}

interface Props {
  name: string;
  items: Array<FilterPanelRadioItem>;
  selected?: string | number;
  maxItems?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  titlecase?: boolean;
  allowShowMore?: boolean;
}

function FilterPanelRadioGroup(props: Props) {
  const {
    onChange,
    name,
    items,
    maxItems = Infinity,
    titlecase = false,
    allowShowMore = true,
    selected,
  } = props
  const [showMore, toggleShowMore] = useToggle(!allowShowMore)
  const visibleItems = useMemo(() => items.filter(item => !item.hidden), [items])
  const hasMore = visibleItems.length > maxItems

  return <>
    <RadioGroup>
      {take(visibleItems, maxItems).map(item =>
        <WhiteRadioButtonInput
          key={item.value}
          name={name}
          value={item.value}
          onChange={onChange}
          hidden={item.hidden}
          checked={selected == item.value}
          data-carrier={item.value}
        >
          {!!item.count && <Group direction="horizontal" horizontalAlign="space-between">
            {item.label}
            <span>({item.count})</span>
          </Group>}
          {!item.count &&
            <Typography format={titlecase ? 'titlecase' : undefined} as="span">{item.label}</Typography>
          }
        </WhiteRadioButtonInput>,
      )}
    </RadioGroup>
    {hasMore && <>
      <SlideDown show={showMore}>
        <RadioGroup>
          {skip(visibleItems, maxItems).map(item =>
            <WhiteRadioButtonInput
              key={item.value}
              name={name}
              value={item.value}
              onChange={onChange}
              hidden={item.hidden}
              checked={selected == item.value}
              data-carrier={item.value}
            >
              {!!item.count && <Group direction="horizontal" horizontalAlign="space-between">
                {item.label}
                <span>({item.count})</span>
              </Group>}
              {!item.count &&
                <Typography format={titlecase ? 'titlecase' : undefined} as="span">{item.label}</Typography>
              }
            </WhiteRadioButtonInput>,
          )}
        </RadioGroup>
      </SlideDown>
      {allowShowMore && <SmallToggleButton
        on={showMore}
        onClick={toggleShowMore}
        size="medium"
        iconPosition="start"
      >
        {showMore ? 'Show less' : 'Show more'}
      </SmallToggleButton>}
    </>}
  </>
}

export default React.memo(FilterPanelRadioGroup)
