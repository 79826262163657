import FilterChip from 'components/Luxkit/Chips/FilterChip'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import React, { useCallback, useContext } from 'react'
import { Form } from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchChips'
import ModalContext from 'contexts/ModalContext'
import ModalCruiseSearchCabinTypeChip
  from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/Modal/ModalCruiseSearchCabinTypeChip'

export interface CruiseSearchCabinTypeChipProps {
  facets: Array<App.CruiseSearchFacet>;
  filters: {
    cabinTypes?: string;
  };
  filterForm: Form;
  setFilterForm: (form: Form) => void;
  fetching?: boolean;
  drawerMode?: boolean;
}

function CruiseSearchCabinTypeChip(props: CruiseSearchCabinTypeChipProps) {
  const { filters, facets, filterForm, setFilterForm, fetching, drawerMode } = props
  const showModal = useContext(ModalContext)

  const openModal = useCallback(async() => {
    await showModal<boolean>(<ModalCruiseSearchCabinTypeChip
      drawerMode={drawerMode}
      facets={facets}
      filters={filters}
      filterForm={filterForm}
      setFilterForm={setFilterForm}
      fetching={fetching}
    />)
  }, [drawerMode, facets, fetching, filterForm, filters, setFilterForm, showModal])

  return <FilterChip
    selected={!!filters.cabinTypes}
    size="medium"
    onClick={openModal}
    endIcon={<LineAngleDownIcon />}
  >
    Cabin types {!!filters.cabinTypes && '(1)'}
  </FilterChip>
}

export default CruiseSearchCabinTypeChip
