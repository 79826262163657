import LineTagAltIcon from 'components/Luxkit/Icons/line/LineTagAltIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import FormatPercent from 'components/utils/Formatters/FormatPercent'
import React, { useContext, useEffect } from 'react'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

interface Props {
  /**
   * 0-1
   *
   * @example 0.2 => 20%
   */
  discountPercentage?: number
}

function SearchTileWalledMessage({ discountPercentage }: Props) {
  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  useEffect(() => {
    dispatchOfferListEvent({
      type: OfferListEvents.pricingCalculated,
      availability: true,
      leadPrice: undefined,
      duration: undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <BodyTextBlock variant="medium" weight="bold" colour="neutral-two" startIcon={<LineTagAltIcon />}>
    {!!discountPercentage && <>Get <FormatPercent value={discountPercentage} /> off with member prices</>}
    {!discountPercentage && 'Sign up to access exclusive rates'}
  </BodyTextBlock>
}

export default SearchTileWalledMessage
