import React, { useMemo } from 'react'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import WalledContent from 'components/Common/WalledContent'
import OfferTileLoggedInButtons from 'components/OfferList/OfferListTiles/Support/OfferTileLoggedInButtons'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import LEOfferForceBundlePricing from './LEOfferPricing/LEOfferForceBundlePricing'
import LEOfferPricing from './LEOfferPricing/LEOfferPricing'
import { unique } from 'lib/array/arrayUtils'

interface Props {
  offer: App.Offer | App.OfferSummary;
  variant?: 'default' | 'condensed';
  selectedDatesAndRoomsFilter?: App.OfferListFilters;
  isUrgencySavingUpsPricingHomepageSearch?: boolean;
}

function LEOfferPriceDetails(props: Props) {
  const {
    offer,
    variant = 'default',
    selectedDatesAndRoomsFilter,
    isUrgencySavingUpsPricingHomepageSearch,
  } = props

  const wall = <OfferTileLoggedInButtons
    signUpText="Sign up for free"
    align="right"
    fullWidth={false}
    discountLabelAlign="end"
  />

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName
  return (
    <WalledContent enforced={offer.walledGarden} wall={wall}>
      <Group direction="vertical" gap={8}>
        <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
          <VerticalSpacer gap={8}>
            <div>
              {offer.bundledWithFlightsOnly && <LEOfferForceBundlePricing variant={variant} offer={offer} selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter}/>}
              {!offer.bundledWithFlightsOnly && <LEOfferPricing variant={variant} offer={offer} selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter} isUrgencySavingUpsPricingHomepageSearch={isUrgencySavingUpsPricingHomepageSearch}/>}
            </div>
            <PriceRowAgentHubCommission
              offerId={offer.id}
              offerLocations={offerLocations}
              vendorName={vendorName}
              size="L"
              productType={offer.productType}
            />
          </VerticalSpacer>
          <ProductPaletteProvider product={offer}>
            {variant === 'default' && <CSSBreakpoint min="desktop">
              <TextButton kind="primary" nonInteractable>
                View
              </TextButton>
            </CSSBreakpoint>}
            {variant === 'condensed' && <TextButton kind="secondary" nonInteractable>
              View <CSSBreakpoint as="span" min="desktop">&nbsp;offer</CSSBreakpoint>
            </TextButton> }
          </ProductPaletteProvider>
        </Group>
      </Group>
    </WalledContent>
  )
}

export default LEOfferPriceDetails
