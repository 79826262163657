import React, { ComponentProps } from 'react'
import Label from './Label'

type Variant = 'standard' | 'information' | 'premium' | 'dark' | 'promotion' | 'light' | 'exclusive'

const configMap: Record<Variant, React.ComponentProps<typeof Label>> = {
  premium: {
    kind: 'tertiary',
    variant: 'highlight',
  },
  standard: {
    kind: 'tertiary',
    variant: 'ghost',
  },
  dark: {
    kind: 'primary',
    variant: 'dark',
  },
  information: {
    kind: 'tertiary',
    variant: 'ghost',
  },
  promotion: {
    kind: 'primary',
    variant: 'highlight',
  },
  light: {
    kind: 'secondary',
    variant: 'ghost',
  },
  exclusive: {
    kind: 'primary',
    variant: 'exclusive',
  },
}

type InheritedLabelProps = Pick<ComponentProps<typeof Label>, 'format'>

interface Props extends InheritedLabelProps {
  children: React.ReactNode;
  variant: Variant;
  className?: string;
  startIcon?: React.ReactNode
}

function StructureLabel(props: Props) {
  const { variant, ...rest } = props

  return <Label
    {...rest}
    {...configMap[variant]}
  />
}

export default StructureLabel
