import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { getAirportsSearch } from 'api/flights'
import { last } from 'lib/array/arrayUtils'
import { MappedPlace, mapCityLevelPlace } from 'lib/search/mappingUtils'
import { getPlaceByLatLong } from 'api/search'
import moment from 'moment'
import sanitizeItemContextMetadata from './sanitizeItemContextMetadata'
import { getFlightsTotal } from 'lib/flights/flightUtils'
import { floatify } from 'lib/maths/mathUtils'
import { reportClientError } from 'services/errorReportingService'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { diffInDays } from 'lib/datetime/dateUtils'
import { FlightsFareTypes } from 'constants/flight'

async function cartFlightsContextForUserCart(state: App.State, item: App.Checkout.FlightItem) {
  const firstFlight = item.flights[0]
  const lastFlight = last(item.flights)
  const isReturnOrOneWay = item.fareType === FlightsFareTypes.RETURN || item.fareType === FlightsFareTypes.ONE_WAY

  const price = floatify(getFlightsTotal(item))
  const { categoryId, productId } = getCategoryAndProductId(item.itemType)

  const duration = diffInDays(new Date(firstFlight.departingDate), new Date(moment(lastFlight.arrivalDateTime).format(ISO_DATE_FORMAT)))

  let cities = { originCity: '', destinationCity: '' }
  try {
    const cityResponse = await getCityByAirportName(
      firstFlight.departingAirportName,
      isReturnOrOneWay ? firstFlight.arrivalAirportName : lastFlight.arrivalAirportName,
    )
    cities = {
      originCity: cityResponse.originCity ?? '',
      destinationCity: cityResponse.destinationCity ?? '',
    }
  } catch (e) {
    reportClientError(e, { message: 'Failed to get cities by airport name' })
  }

  return [comluxgroup.createItem_1_1_0({
    offerId: 'flight',
    categoryId,
    productId,
    duration: item.fareType === FlightsFareTypes.ONE_WAY ? 1 : duration,
    travelStart: firstFlight.departingDate,
    travelEnd: moment(lastFlight.arrivalDateTime).format(ISO_DATE_FORMAT),
    numberOfAdults: item.occupants.adults,
    numberOfChildren: item.occupants.children,
    numberOfInfants: item.occupants.infants,
    childrenAges: item.occupants.childrenAge,
    currency: state.geo.currentCurrency,
    originCity: cities.originCity,
    destinationCity: cities.destinationCity,
    typeId: item.fareType.toLowerCase(),
    itemId: item.itemId,
    price,
    metadata: sanitizeItemContextMetadata({
      isMultiCart: true,
      originAirportCode: firstFlight.departingAirportCode,
      arrivalAirportCode: lastFlight.arrivalAirportCode,
    }),
  })]
}

async function getCityByAirportName(departingAirportName: string, arrivalAirportName: string) {
  const [departingAirportData, arrivalAirportData] = await Promise.all([getAirportsSearch(departingAirportName), getAirportsSearch(arrivalAirportName)])

  const departingLongitude = departingAirportData[0]?.lg
  const departingLatitude = departingAirportData[0]?.lt
  const arrivalLongitude = arrivalAirportData[0]?.lg
  const arrivalLatitude = arrivalAirportData[0]?.lt

  const getCitiesPromises: Array<Promise<MappedPlace | undefined> | undefined> = [undefined, undefined]

  if (departingLongitude && departingLatitude) {
    getCitiesPromises[0] = getPlaceByLatLong(departingLatitude, departingLongitude).then(mapCityLevelPlace)
  }

  if (arrivalLongitude && arrivalLatitude) {
    getCitiesPromises[1] = getPlaceByLatLong(arrivalLatitude, arrivalLongitude).then(mapCityLevelPlace)
  }

  const places = await Promise.all(getCitiesPromises)

  return {
    originCity: places[0]?.city,
    destinationCity: places[1]?.city,
  }
}

export default cartFlightsContextForUserCart
