import LineBuildingIcon from 'components/Luxkit/Icons/line/LineBuildingIcon'
import LineClockDollarIcon from 'components/Luxkit/Icons/line/LineClockDollarIcon'
import LineRefundIcon from 'components/Luxkit/Icons/line/LineRefundIcon'
import LineSunsetIcon from 'components/Luxkit/Icons/line/LineSunsetIcon'
import { OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from './offer'
import { TOUR_FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { DurationOption } from 'components/Search/SearchForm/SearchDateInput/FlexibleDatePicker/DurationPicker'
import { OfferContextMap } from 'analytics/snowplow/context'
import { formatCurrency } from 'lib/format/formatCurrencyIntl'
import Places from 'constants/places'

export const TOUR_CHILD_MAX_AGE = 15
export const TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD = 105

export const DIRECT_TOUR = 'direct_tour'
export const PARTNER_TOUR = 'partner_tour'
export const CONNECTION_TOUR = 'connection_tour'
export const CRUISE_TOUR = 'cruise_tour'

export const ULTRA_LUX_TOUR = 'ultra_lux_tour'

export const TOUR_V2_OFFER_LIST_TYPES: Array<App.Tours.V2ParentOfferType> = [OFFER_TYPE_TOUR_V2]
export const TOUR_V1_OFFER_LIST_TYPES: Array<App.OfferType> = [OFFER_TYPE_TOUR]
export const TOUR_V1_OFFER_LIST_FILTER_TYPES: Array<App.OfferType> = [OFFER_TYPE_TOUR]
export const TOURS_OFFER_LIST_EXCLUDED_PARTNERSHIP_CODES = new Set([
  'qantas',
  'krisFlyer',
  'velocity',
])

export const TOUR_V2_BOOK_WITH_CONFIDENCE_ITEMS: Array<App.Tours.V2OfferBookWithConfidenceItem> = [
  {
    title: 'Book everything in one place',
    description: 'From hotels to flights, experiences and more – we’ve got your trip covered from start to finish.',
    Icon: LineSunsetIcon,
  },
  {
    title: 'Secure dates with a deposit',
    description: `Reserve your space now with a deposit, pay the rest ${TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD} days before departure`,
    Icon: LineClockDollarIcon,
  },
  {
    title: '7-day change of mind refund',
    description: 'Get a full refund on your booking (excludes cruises, flights, travel protection and the service fee paid on deposit bookings)',
    Icon: LineRefundIcon,
  },
  {
    title: 'Handpicked partners',
    description: 'We work with the world’s most loved brands in travel',
    Icon: LineBuildingIcon,
  },
]

export const TOUR_ORDER_TRAVELLER_DETAILS_TTL: number = 300 // in seconds

export enum TourV2ContentSections {
  GALLERY = 'gallery',
  OVERVIEW = 'overview',
  ITINERARY = 'itinerary',
  VARIATIONS = 'variations',
  INCLUSIONS = 'inclusions',
  DEPARTURES = 'dates-and-pricing',
  DEPARTURE_DATES = 'departure_dates',
  FINE_PRINT = 'things-to-know',
  FAQ = 'frequently-asked-questions',
  YOU_MAY_ALSO_LIKE = 'you-may-also-like',
  REVIEWS = 'reviews',
  ADDITIONAL_WAYS = 'additional-ways'
}

export const TOUR_V2_CONTENT_SECTION_LABELS: Record<TourV2ContentSections, string> = {
  [TourV2ContentSections.OVERVIEW]: 'Overview',
  [TourV2ContentSections.ITINERARY]: 'Itinerary',
  [TourV2ContentSections.VARIATIONS]: 'Variations',
  [TourV2ContentSections.INCLUSIONS]: 'Highlights & inclusions',
  [TourV2ContentSections.DEPARTURES]: 'Dates & pricing',
  [TourV2ContentSections.FINE_PRINT]: 'Things to know',
  [TourV2ContentSections.FAQ]: 'Frequently Asked Questions',
  [TourV2ContentSections.YOU_MAY_ALSO_LIKE]: 'You may also like',
  [TourV2ContentSections.REVIEWS]: 'Reviews',
  [TourV2ContentSections.ADDITIONAL_WAYS]: 'Additional ways to experience this tour',
  [TourV2ContentSections.DEPARTURE_DATES]: '',
  [TourV2ContentSections.GALLERY]: '',
}

export const TOUR_V2_STICKY_HEADER_ENABLED_SECTIONS = new Set<TourV2ContentSections>([
  TourV2ContentSections.OVERVIEW,
  TourV2ContentSections.ITINERARY,
  TourV2ContentSections.VARIATIONS,
  TourV2ContentSections.INCLUSIONS,
  TourV2ContentSections.DEPARTURES,
  TourV2ContentSections.FINE_PRINT,
  TourV2ContentSections.FAQ,
  TourV2ContentSections.REVIEWS,
])

export const TOUR_V2_TARGET_OCCUPANT_TYPES: Set<App.OccupantType> = new Set(['adults', 'children'])

export const TOUR_V2_DEFAULT_OCCUPANCIES: Array<App.Occupants> = [
  {
    adults: 2,
    children: 0,
    infants: 0,
    childrenAge: [],
  },
]

export const TOUR_V2_SEARCH_TYPES: Array<App.SearchType> = ['destination', 'tour']

export const TOUR_V2_SEARCH_HERO_IMAGE_ID = 'adkvxdgnii1kvb4s970c'
export const TOUR_V2_CATALOGUE_HERO_IMAGE_ID = 'tour-catalog-header'
export const TOUR_V2_SEARCH_HERO_IMAGE_ASIA_CAMPAIGN = 'tour-hero-asia-campaign-background-v2'
export const TOUR_V2_HERO_IMAGE_ASIA_MOBILE_BACKGROUND = 'tour-hero-asia-campaign-mobile-background-v2'
export const TOUR_V2_PROFILE_ASIA_CAMPAIGN = 'profile-asia-campaign'
export const TOUR_ASIA_CAMPAIGN = 'TOURASIA'
export const TOUR_V2_LOCATION_SEARCH_TITLE_LABEL = 'Where'

export const TOUR_V2_LOCATION_SEARCH_PLACEHOLDER_LABEL = 'Search destination or place'

export const TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES: Array<App.SearchPlaceType> = [
  'city',
  'colloquial_area',
  'continent',
  'country',
  'high_level_region',
  'multi_city_vicinity',
  'province_state',
]

export const TOUR_MINIMUM_VALUED_AT_PERCENTAGE_TO_SHOW = 5

export const DEFAULT_TOUR_V2_OFFER_MAX_CHILD_AGE = 17

export const DEFAULT_TOUR_V2_PRIVATE_MAX_CHILD_AGE = 15

export const VendorsThatRequireTravellerDetails = new Set(['00128000018BZkIAAW', '0010I00001ba7JdQAI', '0010I00001f6bHvQAI', '0010I000028DrsxQAC', '0012y00000LHHZoAAP'])

export const TOUR_V2_MALE_TRAVELLER_TITLES = ['Mr', 'Master']

export const TOUR_V2_FEMALE_TRAVELLER_TITLES = ['Ms', 'Mrs', 'Miss']

export const TOUR_V2_LE_SOURCE = 'salesforce'

export const tourDurationOptions: Array<DurationOption> = [
  {
    title: 'Any duration',
    caption: 'I’ll decide later',
    id: TOUR_FLEXIBLE_DURATION_RANGE.ANY_DURATION,
  },
  {
    title: 'Short tour',
    caption: '1 to 7 days',
    id: TOUR_FLEXIBLE_DURATION_RANGE.SHORT_STAY,
  },
  {
    title: 'Medium tour',
    caption: '8 to 14 days',
    id: TOUR_FLEXIBLE_DURATION_RANGE.MEDIUM_STAY,
  },
  {
    title: 'Long tour',
    caption: '15+ days',
    id: TOUR_FLEXIBLE_DURATION_RANGE.LONG_STAY,
  },
]

export const TOUR_V2_PACKAGE_OPTION_BASE = 'Base'
export const TOUR_V2_PACKAGE_OPTION_EXTENDED = 'Extended'
export const TOUR_V2_PACKAGE_OPTION_EXTENDED_ALTERNATIVE = 'Extended Alternative'

export const tourOptionPackages = [
  TOUR_V2_PACKAGE_OPTION_BASE,
  TOUR_V2_PACKAGE_OPTION_EXTENDED,
  TOUR_V2_PACKAGE_OPTION_EXTENDED_ALTERNATIVE,
]

export const tourOptionPackagesEvent = {
  [TOUR_V2_PACKAGE_OPTION_BASE]: OfferContextMap.VIEW_TOUR_OPTIONS_PRESSED,
  [TOUR_V2_PACKAGE_OPTION_EXTENDED]: OfferContextMap.VIEW_TOUR_OPTIONS_EXTENDED_PRESSED,
  [TOUR_V2_PACKAGE_OPTION_EXTENDED_ALTERNATIVE]: OfferContextMap.VIEW_TOUR_OPTIONS_EXTENDED_ALT_PRESSED,
}

export enum PACKAGE_UPGRADE_VALUES {
  REGULAR = 'regular',
  VIP = 'vip',
  PLATINUM = 'platinum',
}

export const TourOptionPackageUpgrades = [PACKAGE_UPGRADE_VALUES.REGULAR, PACKAGE_UPGRADE_VALUES.VIP, PACKAGE_UPGRADE_VALUES.PLATINUM]

export const SUPERIOR_PACKAGE_OPTIONS_AVAILABLES: Record<string, Array<PACKAGE_UPGRADE_VALUES>> = {
  [PACKAGE_UPGRADE_VALUES.REGULAR]: TourOptionPackageUpgrades,
  [PACKAGE_UPGRADE_VALUES.VIP]: [PACKAGE_UPGRADE_VALUES.VIP, PACKAGE_UPGRADE_VALUES.PLATINUM],
  [PACKAGE_UPGRADE_VALUES.PLATINUM]: [PACKAGE_UPGRADE_VALUES.PLATINUM],
}

export const ALL_TOUR_PACKAGE_TYPE = 'all'

export enum PACKAGE_UPGRADE_DISPLAY_TEXT {
  REGULAR = 'Standard Package',
  VIP = 'Upgraded Package',
  PLATINUM = 'Deluxe Package',
}

export enum PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT {
  REGULAR = 'Economy Package',
  VIP = 'Business Package',
  PLATINUM = 'First Class Package',
}

export enum PACKAGE_UPGRADE_DISPLAY_VIEW_TEXT {
  REGULAR = 'King or Twin Package',
  VIP = 'Ocean View w. Club Access Upgrade',
  PLATINUM = 'Regency Suite w. Club Access Upgrade',
}

export enum PACKAGE_UPGRADE_DISPLAY_RANGE_VIEW_TEXT {
  REGULAR = 'Classic to King or Twin Package',
  VIP = 'Superior to Ocean View w. Club Access Upgrade',
  PLATINUM = 'Deluxe to Regency Suite w. Club Access Upgrade',
}

export const getOfferPackageUpgradeDisplayTextMap = (offerId : string) => {
  switch (offerId?.replace('tour-', '')) {
    case 'bbf97caa-d857-4517-b768-465f5ceac8db':
    case '15275af7-48cb-4a5e-ad0c-eb9b3efc1619':
    case '6f77e732-d5e1-4747-ae47-901022e36fc3':
      return PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT_MAP
    case '6909c91b-cc92-4c0b-86d4-d663263436be':
      return PACKAGE_UPGRADE_DISPLAY_VIEW_TEXT_MAP
    case '1ea35824-8354-4656-9d76-c24d717ee700':
      return PACKAGE_UPGRADE_DISPLAY_RANGE_VIEW_TEXT_MAP
    default:
      return PACKAGE_UPGRADE_DISPLAY_TEXT_MAP
  }
}

const PACKAGE_UPGRADE_DISPLAY_TEXT_MAP = new Map([
  [PACKAGE_UPGRADE_VALUES.REGULAR, PACKAGE_UPGRADE_DISPLAY_TEXT.REGULAR],
  [PACKAGE_UPGRADE_VALUES.VIP, PACKAGE_UPGRADE_DISPLAY_TEXT.VIP],
  [PACKAGE_UPGRADE_VALUES.PLATINUM, PACKAGE_UPGRADE_DISPLAY_TEXT.PLATINUM],
])
const PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT_MAP = new Map([
  [PACKAGE_UPGRADE_VALUES.REGULAR, PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT.REGULAR],
  [PACKAGE_UPGRADE_VALUES.VIP, PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT.VIP],
  [PACKAGE_UPGRADE_VALUES.PLATINUM, PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT.PLATINUM],
])
const PACKAGE_UPGRADE_DISPLAY_VIEW_TEXT_MAP = new Map([
  [PACKAGE_UPGRADE_VALUES.REGULAR, PACKAGE_UPGRADE_DISPLAY_VIEW_TEXT.REGULAR],
  [PACKAGE_UPGRADE_VALUES.VIP, PACKAGE_UPGRADE_DISPLAY_VIEW_TEXT.VIP],
  [PACKAGE_UPGRADE_VALUES.PLATINUM, PACKAGE_UPGRADE_DISPLAY_VIEW_TEXT.PLATINUM],
])
const PACKAGE_UPGRADE_DISPLAY_RANGE_VIEW_TEXT_MAP = new Map([
  [PACKAGE_UPGRADE_VALUES.REGULAR, PACKAGE_UPGRADE_DISPLAY_RANGE_VIEW_TEXT.REGULAR],
  [PACKAGE_UPGRADE_VALUES.VIP, PACKAGE_UPGRADE_DISPLAY_RANGE_VIEW_TEXT.VIP],
  [PACKAGE_UPGRADE_VALUES.PLATINUM, PACKAGE_UPGRADE_DISPLAY_RANGE_VIEW_TEXT.PLATINUM],
])

export enum TOURS_GROUP_TYPE {
  isPrivateRequest = 'isPrivateRequest',
  isSmallGroup = 'isSmallGroup',
  isLargeGroup = 'isLargeGroup',
  isFamilyFriendly = 'isFamilyFriendly',
}

export const TOURS_GROUP_TYPES_LIST = Object.values(TOURS_GROUP_TYPE)

export enum TOUR_OFFER_TAG {
  onSale = 'onSale',
  flashOffer = 'flashOffer',
}

export const TOUR_OFFER_TAG_LIST = Object.values(TOUR_OFFER_TAG)

export const TOUR_MAX_ROOM_LIMIT = 5

export const TOUR_OPTIONAL_EXPERIENCE_BOOKING_DAYS_BEFORE_DEPARTURE = 60

export const TOUR_OPTIONAL_EXPERIENCE_RESERVATION_STATUS_CONFIRMED = 'confirmed'
export const TOUR_OPTIONAL_EXPERIENCE_ITEM_STATUS_COMPLETED = 'completed'

export const BRAND_SIGNATURE_SERIES = 'lesignaturetours'

export const TOUR_STYLED_PRODUCT_TYPE_TITLES: Record<App.Tours.V2ProductTypeStyled, string> = {
  deluxe_tour: 'Deluxe',
  premium_tour: 'Premium',
  ultra_lux_tour: 'Ultra Lux',
  signature_series_tour: 'Signature Series',
}

export const TOUR_STYLED_PRODUCT_TYPE_HOTEL_BENEFITS: Record<App.Tours.V2ProductTypeStyled, string> = {
  deluxe_tour: '4 & 4.5 star hotels',
  premium_tour: '5 star hotels',
  ultra_lux_tour: '5 star hotels & iconic stays',
  signature_series_tour: '5 star hotels',
}

export const PROMO_CODE_MAX_DISCOUNT_REGION: Record<string, string> = {
  AU: formatCurrency(1000, 'AUD', 'AU', { format: 'dynamic' }),
  NZ: formatCurrency(1000, 'NZD', 'NZ', { format: 'dynamic' }),
  SG: formatCurrency(1000, 'SGD', 'SG', { format: 'dynamic' }),
  US: formatCurrency(600, 'USD', 'US', { format: 'dynamic' }),
  GB: formatCurrency(500, 'GBP', 'GB', { format: 'dynamic' }),
  HK: formatCurrency(5000, 'HKD', 'HK', { format: 'dynamic' }),
}

export const PROMO_SPEND_EARN_REGION: Record<string, Array<{ spend: number, earn: number }>> = {
  AU: [{ spend: 4999, earn: 250 }, { spend: 6999, earn: 300 }, { spend: 9999, earn: 500 }, { spend: 19999, earn: 750 }],
  NZ: [{ spend: 5499, earn: 250 }, { spend: 7499, earn: 300 }, { spend: 10499, earn: 500 }, { spend: 15999, earn: 750 }],
  SG: [{ spend: 4499, earn: 250 }, { spend: 6499, earn: 300 }, { spend: 9499, earn: 500 }, { spend: 13999, earn: 750 }],
  HK: [{ spend: 24999, earn: 1250 }, { spend: 33999, earn: 1500 }, { spend: 49999, earn: 2500 }, { spend: 74999, earn: 3750 }],
  GB: [{ spend: 2499, earn: 120 }, { spend: 3499, earn: 149 }, { spend: 4999, earn: 249 }, { spend: 9999, earn: 349 }],
  US: [{ spend: 2299, earn: 150 }, { spend: 4499, earn: 200 }, { spend: 6499, earn: 300 }, { spend: 12999, earn: 500 }],
  CA: [{ spend: 2299, earn: 150 }, { spend: 4499, earn: 200 }, { spend: 6499, earn: 300 }, { spend: 12999, earn: 500 }],
  FR: [{ spend: 2499, earn: 120 }, { spend: 3499, earn: 169 }, { spend: 4999, earn: 249 }, { spend: 9999, earn: 349 }],
  IT: [{ spend: 2499, earn: 120 }, { spend: 3499, earn: 169 }, { spend: 4999, earn: 249 }, { spend: 9999, earn: 349 }],
  ES: [{ spend: 2499, earn: 120 }, { spend: 3499, earn: 169 }, { spend: 4999, earn: 249 }, { spend: 9999, earn: 349 }],
  NL: [{ spend: 2499, earn: 120 }, { spend: 3499, earn: 169 }, { spend: 4999, earn: 249 }, { spend: 9999, earn: 349 }],
  DE: [{ spend: 2499, earn: 120 }, { spend: 3499, earn: 169 }, { spend: 4999, earn: 249 }, { spend: 9999, earn: 349 }],
  IE: [{ spend: 2499, earn: 120 }, { spend: 3499, earn: 169 }, { spend: 4999, earn: 249 }, { spend: 9999, earn: 349 }],
}

export const EXCLUDE_OFFER_PROMO_SPEND_EARN = ['tour-b9fcebec-2e68-42d5-a5b5-b09c69930d87']
export const END_SPEND_EARN_PROMO_TAG_DATE = new Date('2025-03-10')

export const TOUR_ASIA_TAKEOVER_SEARCH_PLACE_IDS = [
  Places.India.id,
  Places.SriLanka.id,
  Places.Thailand.id,
  Places.Vietnam.id,
  Places.Japan.id,
  Places.China.id,
  Places.Malaysia.id,
  Places.Nepal.id,
]
export const TOUR_ASIA_TAKEOVER_SHOW_SEARCH_BANNER_PLACE_IDS = [
  ...TOUR_ASIA_TAKEOVER_SEARCH_PLACE_IDS,
  Places.Anywhere.id,
  Places.Asia.id,
  Places.SouthEastAsia.id,
]

export const TOUR_HERO_IMAGES_IDS_ROTATION: Array<{ id: string, gravity: 'north' | 'center' }> = [
  { id: 'bxwkiui0zplo7rexp1sp', gravity: 'center' },
  { id: 'buxxor3j5kmy5vqo9klc', gravity: 'center' },
  { id: 'byfoq3zduoearfn090g', gravity: 'north' },
  { id: 'l0clydv7291tl39ud6', gravity: 'center' },
  { id: 'wp4rzuxk8fzq899bpd', gravity: 'center' },
]

export const TOUR_ASIA_PROMO_REGIONS: Record<string, number> = {
  AU: 849,
  NZ: 920,
  US: 520,
  GB: 430,
  SG: 710,
  HK: 4090,
  CA: 760,
  FR: 490,
  IT: 490,
  ES: 490,
  NL: 490,
  DE: 490,
  IE: 490,
}
