import request from 'api/requestUtils'
import { SEARCH_VERTICALS } from 'constants/search'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'

export function touch(data: any, path: string, tid?: string) {
  const headers: HeadersInit = {}

  // tid is legacy
  if (tid) {
    headers['Interaction-Studio-TID'] = tid
  }
  return request.post<App.ApiResponse<any>, any>(
    `/api/interaction-studio/${path}`,
    data,
    {
      credentials: 'include',
      headers,
    },
  ).catch(() => {
    // noop, don't care about an error
  })
}

// SearchResultBucket is all possible buckets for a deal in the search result
export enum SearchResultBucket {
  LE_HOTEL = 'LE',
  LE_HOTEL_NEARBY = 'LE Nearby',
  BEDBANK_HOTEL = 'Bedbank',
  BEDBANK_HOTEL_NEARBY = 'Bedbank Nearby',
  LE_TOUR = 'LE Tour',
  LE_TOUR_NEARBY = 'LE Tour Nearby',
  TTC_TOUR = 'TTC Tour',
  TTC_TOUR_NEARBY = 'TTC TOUR Nearby',
  LED_EXPERIENCE = 'LED Experience',
  REZDY_EXPERIENCE = 'Rezdy Experience',
  CRUISE_TRIP = 'Cruise Trip',
}

export interface SearchResult {
  id: string
  kind: SearchResultBucket
}

// getGAClientId reads the current client's id assigned by Google Analytics
function getGAClientId() {
  const ga = (window as any).ga
  // ga might be undefined or modified by ad blockers.
  const trackers = ga?.getAll?.()

  if (trackers?.length) {
    return trackers[0].get('clientId')
  }

  return ''
}

interface SearchFilterParams {
  searchSessionId: string
  brand: string
  region: string
  verticals?: Set<SEARCH_VERTICALS>;
  locations: Array<string>
  holidayTypes: Array<string>
  amenities: Array<string>
  sortBy: string
  inclusions: Array<string>
  bedroomsGte?: number
  bedroomsEq?: number
  bedsGte?: number
  bathroomsGte?: number
}

function buildSearchFiltersPayload(data: SearchFilterParams) {
  const { verticals } = data

  return {
    ...data,
    verticals: verticals ? [...verticals] : undefined,
  }
}

export function trackFilterApply(params: SearchFilterParams) {
  const payload = buildSearchFiltersPayload(params)
  return touch(payload, 'search-filters')
}

interface TrackAutosuggestionsParams {
  region: string;
  verticals?: Set<SEARCH_VERTICALS>;
  searchSuggestionSessionId: string;
  searchPhrase: string;
  suggestions: Array<App.SearchItem>;
}

function formatName(searchItem: App.SearchItem) {
  return searchItem.format.secondaryText ?
    `${searchItem.format.mainText}, ${searchItem.format.secondaryText}` :
    searchItem.format.mainText
}

function buildAutosuggestionsPayload(params: TrackAutosuggestionsParams) {
  const { verticals } = params
  return {
    region: params.region,
    searchSuggestionSessionId: params.searchSuggestionSessionId,
    searchPhrase: params.searchPhrase,
    gaClientId: getGAClientId(),
    suggestions: params.suggestions.map((option, index) => ({
      index,
      type: option.searchType,
      id: option.value,
      name: formatName(option),
    })),
    verticals: verticals ? [...verticals] : undefined,
  }
}

export function trackAutosuggestionsResult(params: TrackAutosuggestionsParams) {
  const payload = buildAutosuggestionsPayload(params)
  return touch(payload, 'auto-suggestion-session')
}

interface TrackAutosuggestionClickParams {
  region: string;
  verticals?: Set<SEARCH_VERTICALS>;
  searchSuggestionSessionId: string;
  searchPhrase: string;
  clickedSuggestionIndex: number;
  clickedSuggestion: App.SearchItem;
}

function buildAutosuggestionClickPayload(params: TrackAutosuggestionClickParams) {
  const { verticals } = params
  return {
    region: params.region,
    searchSuggestionSessionId: params.searchSuggestionSessionId,
    searchPhrase: params.searchPhrase,
    gaClientId: getGAClientId(),
    clickedSuggestion: {
      index: params.clickedSuggestionIndex,
      type: params.clickedSuggestion.searchType,
      id: params.clickedSuggestion.value,
      name: formatName(params.clickedSuggestion),
    },
    verticals: verticals ? [...verticals] : undefined,
  }
}

export function trackAutosuggestionClick(params: TrackAutosuggestionClickParams) {
  const payload = buildAutosuggestionClickPayload(params)
  return touch(payload, 'auto-suggestion-session-click')
}

interface TrackOfferListClickParams {
  offerId: string;
  index: number;
  list: 'homepage' | 'hotels' | 'tours' | 'holiday-homes' | 'last-minute-escapes' | 'hotels-search' | 'hotels-search-map' | 'homes-and-villas' | 'ultralux' | 'cruises-search' | 'cruises' | 'bundle_and_save' | 'homepage-offers' | 'destination-offers' | 'experiences' | 'cruises-deal-page';
  listId?: string;
}

export interface TrackMapPinClickParams {
  offerId: string,
  offerType: string,
  pinType: string,
  price?: number,
  listId?: string,
  index: number,
}

function buildOfferListClickPayload(params: TrackOfferListClickParams) {
  return {
    ...params,
    gaClientId: getGAClientId(),
  }
}

export function trackOfferListClick(params: TrackOfferListClickParams) {
  const payload = buildOfferListClickPayload(params)
  return touch(payload, 'offer-click')
}

function buildMapPinClickPayload(params: TrackMapPinClickParams) {
  return {
    ...params,
    gaClientId: getGAClientId(),
  }
}

export function trackMapPinClick(params: TrackMapPinClickParams) {
  const payload = buildMapPinClickPayload(params)
  return touch(payload, 'map-pin-click')
}

function serialiseSearchState(searchState: Partial<GlobalSearchState>) {
  const { searchVerticals } = searchState
  return {
    searchVerticals: searchVerticals ? [...searchVerticals] : undefined,
    searchItem: searchState.searchItem,
    occupancies: searchState.occupancies,
    checkinDate: searchState.checkinDate?.format(ISO_DATE_FORMAT),
    checkoutDate: searchState.checkoutDate?.format(ISO_DATE_FORMAT),
    flexibleMonths: searchState.flexibleMonths,
    flexibleNights: searchState.flexibleNights,
    isFlexibleDateSelected: searchState.isFlexibleDateSelected,
  }
}

export function trackSearchSession(params: {
  isLoggedIn: boolean,
  listId: string,
  listKey: string,
  regionCode: string
  searchId?: string,
  searchResults: Array<SearchResult>,
  searchState: Partial<GlobalSearchState>,
  numberOfResultIgnoreAvailability?: number
}) {
  const payload = {
    gaClientId: getGAClientId(),
    isLoggedIn: params.isLoggedIn,
    listId: params.listId,
    listKey: params.listKey,
    region: params.regionCode,
    searchId: params.searchId,
    searchResults: params.searchResults,
    searchState: serialiseSearchState(params.searchState),
    numberOfResultIgnoreAvailability: params.numberOfResultIgnoreAvailability,
  }

  return touch(payload, 'search-session')
}
