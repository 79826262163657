import config from 'constants/config'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import { createSelector } from 'reselect'
import { isEnabledForFeature, isFeatureFlagIncluded } from 'lib/config/featureFlagUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import { removeRegionFromPath } from 'lib/url/searchUrlUtils'
import { isHeadlessModeSel, isCurrentDeviceMobile, isMobileAppSel } from './configSelectors'
import memoize from 'lib/memoize/memoize'
import { getFlightItems } from 'checkout/selectors/view/flights'
import { isVillaOffer, isLEOffer, isBedbankOffer, isLEHotel } from 'lib/offer/offerTypes'
import { MERCHANT_FEE_ENABLED_REGIONS, TRAVEL_PROTECTION_VALID_COUNTRIES } from 'constants/config/region'
import { matchPath } from 'react-router'
import { isBedbankRefundable } from './bedbankSelectors'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'
import { isLEStaffOrAdmin } from './accountSelectors'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { isLebtMerchantFeesEnabled } from 'businessTraveller/selectors/businessTravellerMerchantFinanceSelectors'

export function shouldEnableCalendarRecommendations(offer: App.Offer | App.BedbankOffer | undefined) {
  return !isVillaOffer(offer) && (isLEOffer(offer) || isBedbankOffer(offer))
}

export const isSpoofed = (state: App.State) => state.auth.account.isSpoofed

export const isCustomerSupportMode = createSelector(
  (state: App.State) => isSpoofed(state),
  (state: App.State) => state.system.previewMode,
  (state: App.State) => state.system.storeMode,
  (isSpoofed, isPreviewMode, isStoreMode) => isSpoofed || isPreviewMode || isStoreMode,
)

export const shouldShowSalesUSP = createSelector(
  (_state: App.State, lowestPricedPackageTrackingPrice: number, threshold: number) => lowestPricedPackageTrackingPrice >= threshold,
  (state: App.State) => state.geo.currentRegionCode,
  (isPriceOverThreshold, currentRegion): boolean => isEnabledForFeature('ALL/IN', currentRegion) && isPriceOverThreshold,
)

export const multiRoomEnabled = createSelector(
  (_state: App.State, offer: App.Offer) => offer?.type,
  (state: App.State, offer: App.Offer) => isLEHotel(offer) ? !!state.offer.propertyOfferMappings[offer.property.id]?.mapping : undefined,
  (offerType, hasPropertyMapping) => {
    const isEnabled = config.MULTIPLE_ROOM_TYPE_BOOKING_ENABLED && isEnabledForFeature(config.MULTIPLE_ROOM_TYPE_BOOKING_ENABLED_LIST, offerType)
    if (config.ACCOMM_USE_LPC_PROPERTY_PAGE && offerType === 'tactical_ao_hotel') {
      // We don't want the complexity of multi room booking on property pages (TAO offers that have a mapping)
      return isEnabled && !hasPropertyMapping
    }
    return isEnabled
  },
)

export const shouldShowContinueToApp = createSelector(
  (state: App.State) => isCurrentDeviceMobile(state),
  (state: App.State) => isHeadlessModeSel(state),
  (state: App.State) => state.system.appBannerCookie,
  (state: App.State) => state.utm.medium,
  (state: App.State) => !!state.router.location.query.gclid,
  (state: App.State) => state.system.originalCanonicalURL,
  (state: App.State) => state.router.location.pathname,
  (state: App.State) => state.utm.content,
  (
    isMobileDevice,
    isHeadlessMode,
    appBannerCookie,
    utmMedium,
    hasGoogleClickIdentifier,
    url,
    pathname,
    utmContent,
  ): boolean => {
    return config.CONTINUE_TO_APP_BANNER &&
      isMobileDevice &&
      !isHeadlessMode &&
      !appBannerCookie &&
      !(utmMedium === 'cpc' || hasGoogleClickIdentifier) &&
      (utmMedium !== 'cpc' && !(utmContent === 'gha-lpp' || utmContent === 'gha-lpc')) &&
      !url.includes('top-picks-for-me') &&
      !matchPath(pathname, { path: '/:regionCode/checkout', exact: false }) &&
      !matchPath(pathname, { path: '/:regionCode/campaign/early-bird-2025', exact: true }) &&
      !matchPath(pathname, { path: '/:regionCode/pay/:paymentLinkId', exact: false }) &&
      !matchPath(pathname, { path: '/:regionCode/tour', exact: false }) &&
      !matchPath(pathname, { path: '/:regionCode/tours', exact: false }) &&
      !matchPath(pathname, { path: '/:regionCode/search/tours', exact: false }) &&
      !matchPath(pathname, { path: '/:regionCode/cruises', exact: false }) &&
      !matchPath(pathname, { path: '/:regionCode/search/cruises', exact: false })
  },
)

export const isVelocityEnabled = createSelector(
  (state: App.State) => state.geo.partnership?.code,
  (partnershipCode): boolean => partnershipCode === VIRGIN_VELOCITY_PARTNERSHIP_CODE && !!config.VELOCITY_SSO_URL,
)

export const isManualSortingEnabledFor = memoize((listName: string) => {
  return isEnabledForFeature(config.MANUAL_SORTING_ENABLED, listName)
})

export const isSupportAssistantEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.optimizely.optimizelyExperiments?.[OptimizelyExperiments?.isSupportAssistantEnabled],
  (currentRegionCode, supportAssistantFlag) => {
    return supportAssistantFlag?.variationKey === 'on' &&
      config.SUPPORT_ASSISTANT_ENABLED &&
      isFeatureFlagIncluded(config.SUPPORT_ASSISTANT_REGIONS, currentRegionCode)
  },
)

export const isDynamicSearchFooterBoxContent = createSelector(
  (state:App.State) => state.router.location.pathname,
  (state: App.State) => state.content,
  (path, content) => {
    const pathWithoutRegion = removeRegionFromPath(path)
    return config.searchFooterBoxType === 'from-contentful' &&
        'searchFooterLinkBox' in content &&
        content.searchFooterLinkBox[pathWithoutRegion] !== undefined &&
        Object.keys(content.searchFooterLinkBox[pathWithoutRegion] || {}).length > 0
  },
)

export const isMerchantFeeEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => isLebtMerchantFeesEnabled(state),
  (currentRegionCode, lebtMerchantFeesEnabled) => {
    if (!config.MERCHANT_FEE_ENABLED) {
      return false
    }

    // Enable for Agent Hub in all regions
    if (config.agentHub.isEnabled) {
      return true
    }

    if (config.businessTraveller.currentAccountMode === 'business' &&
      !lebtMerchantFeesEnabled) {
      return false
    }

    if (!MERCHANT_FEE_ENABLED_REGIONS.includes(currentRegionCode)) {
      return false
    }

    return true
  },
)

export const isMerchantFeeFlightsEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => isSpoofed(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => getFlightItems(state),
  (state: App.State) => isLebtMerchantFeesEnabled(state),
  (currentRegionCode, isSpoofed, postPurchase, flightItems, lebtMerchantFeesEnabled) => {
    if (!config.MERCHANT_FEE_ENABLED) {
      return false
    }

    if (config.businessTraveller.currentAccountMode === 'business' &&
      !lebtMerchantFeesEnabled) {
      return false
    }

    if (!MERCHANT_FEE_ENABLED_REGIONS.includes(currentRegionCode)) {
      return false
    }

    // Disable fees for postpurchase
    if (postPurchase) {
      return false
    }

    if (isSpoofed) {
      return true
    }

    if (config.BRAND !== 'luxuryescapes') {
      return true
    }

    // enable merchant fee for bundled and standalone flights
    return !!(flightItems?.length)
  },
)

export const isMerchantFeeAvailable = createSelector(
  isMerchantFeeEnabled,
  getFlightItems,
  isBedbankRefundable,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (isMerchantFeeEnabled, flightItems, isBedbankRefundable, postPurchase) =>
  {
    // Disable fees for postpurchase
    if (postPurchase) {
      return false
    }

    // Disable fees if LPP if room is refundable
    if (isBedbankRefundable) {
      return false
    }

    // Disable if flight items
    if (flightItems?.length) {
      return false
    }

    return isMerchantFeeEnabled
  },
)

export const isTravelProtectionAvailableInRegion = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (currentRegionCode): boolean => {
    // New travel protection policies are only available in AU/NZ for now
    return TRAVEL_PROTECTION_VALID_COUNTRIES.includes(currentRegionCode)
  },
)

export const isUserAMultiCartAudience = (state: App.State) => isSpoofed(state) || isLEStaffOrAdmin(state)

export const isMultiCartEnabled = createSelector(
  (state: App.State) => getOptimizelyExperimentVariation(state, OptimizelyExperiments.croMultiItemCart),
  (state: App.State) => isUserAMultiCartAudience(state),
  (state: App.State) => isMobileAppSel(state),
  (state: App.State) => isHeadlessModeSel(state),
  (isMultiItemCartExperiment, isMultiCartAudience, isMobileApp, isHeadless): boolean => {
    return !!(isMultiItemCartExperiment || isMultiCartAudience) && !isMobileApp && !isHeadless
  },
)
