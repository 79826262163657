import { createSelector } from 'reselect'
import { EmptyArray } from 'lib/array/arrayUtils'
import getLuxLoyaltyLoungePassItemViews from 'checkout/selectors/view/getLuxLoyaltyLoungePassItemViews'

const getCheckoutLoungePassPointsCalculationRequest = createSelector(
  (state: App.State) => getLuxLoyaltyLoungePassItemViews(state),
  (loungePassViews): Array<App.LuxLoyaltyPointsEarnCalculationRequest> => {
    if (!loungePassViews.hasRequiredData) return EmptyArray

    return loungePassViews.data.map((itemView) => ({
      price: itemView.totals.price,
      luxLoyaltyProductType: 'experience',
      itemId: itemView.item.itemId,
    }))
  },
)

export default getCheckoutLoungePassPointsCalculationRequest
