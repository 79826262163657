import { Action, Reducer } from 'redux'
import switchFunc from 'lib/function/switchFunc'
import { EmptyArray } from 'lib/array/arrayUtils'

type ReducerFunc<S, A> = (state: S, action: A) => Partial<S>;

type ActionMap<S, A extends Action> =
  { [type in A['type']]?: ReducerFunc<S, Utils.Simplify<A & { type: type }>> } &
  { [type: string]: ReducerFunc<S, any> }

/**
 * Helper function for replacing large 'switch' functions
 * with an object map so often used in reducers.
 * Will provide typing assistance for state and return values
 * @param actions List of actions to respond to
 * @param defaultCase Case to run if no specific actions matched, is defaulted
 */
export function reducerSwitch<S, A extends Action = Action>(
  actions: ActionMap<S, A>,
  // super important this returns undefined as that tells our reducer "nothing changed"
  defaultCase: ReducerFunc<S, A> = ((): undefined => undefined) as any,
) {
  return switchFunc(actions, defaultCase)
}

export type ReducerMiddleware<S, A> = (state: S, action: A, next: (nextState: S) => S) => S

/*
* Helper function to create the base reducer
* Automatically merges current state into return value of reducer
* Inspired by http://redux.js.org/docs/recipes/ReducingBoilerplate.html#generating-reducers
*/
export function createReducer<S extends object, A extends Action = Action>(
  initialState: S,
  handlers: ActionMap<S, A>,
  middlewares: Array<ReducerMiddleware<S, A>> = EmptyArray,
  log?: boolean,
): Reducer<S, A> {
  return (state = initialState, action: A): S => {
    if (log) {
      /* eslint-disable no-console */
      console.info(`Reducer called with ${action.type}`, action)
    }

    let nextCalled = false
    const next = (funcState: S) => {
      nextCalled = true

      if (typeof handlers[action.type] === 'function') {
        const newState = handlers[action.type](funcState, action)
        // if it returned nothing or returned the exact same state, it didn't update
        if (!newState || newState === funcState) return funcState
        const nextState = {
          ...funcState,
          ...newState,
        }

        if (log) {
          /* eslint-disable no-console */
          console.info(`Reducer Old state, new state for ${action.type}`, state, nextState)
        }

        return nextState
      }

      return funcState
    }

    const afterMiddlewareState = middlewares.reduce((nextState, middleware) => {
      return middleware(nextState, action, next) ?? nextState
    }, state)

    if (!nextCalled) {
      return next(afterMiddlewareState)
    }

    if (log) {
      /* eslint-disable no-console */
      console.info('Reducer action without change called')
    }

    return afterMiddlewareState
  }
}
