import React from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'

export type SectionMessageType = 'success' | 'warning' | 'error' | 'light' | 'dark' | 'success-no-border';
export type SectionMessageSize = 'zero' | 'md' | 'lg'

const Root = styled.div`
  white-space: normal;
  box-shadow: 0 1px 10px 0 rgba(92, 119, 131, 0.15);
  border-radius: ${props => props.theme.borderRadius.S};

  &.zero {
    padding: 0;
  }

  &.md {
    padding: ${rem(12)};
  }

  &.lg {
    padding: ${rem(24)};
  }

  &.dark {
    background-color: ${props => props.theme.palette.neutral.default.one};
    color: ${props => props.theme.palette.neutral.default.eight};
  }

  &.error {
    background-color: ${props => props.theme.palette.messaging.critical.normalBackground};
    color: ${props => props.theme.palette.neutral.default.eight};
  }

  &.light {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.10);
    border: 1px solid ${props => props.theme.palette.neutral.default.five};
    background-color: ${props => props.theme.palette.neutral.default.eight};
  }

  &.success {
    border: 1px solid ${props => props.theme.palette.messaging.success.normalForeground};
    background-color: ${props => props.theme.palette.messaging.success.lightBackground};
  }

  &.warning {
    background-color: ${props => props.theme.palette.messaging.warning.lightBackground};
    border: 1px solid ${props => props.theme.palette.messaging.warning.normalForeground};
  }

  &.success-no-border {
    background-color: ${props => props.theme.palette.messaging.success.lightBackground};
  }
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type?: SectionMessageType;
  size?: SectionMessageSize;
}

const SectionMessage = React.forwardRef<HTMLDivElement, Props>(
  ({ type = 'dark', size = 'md', className, ...rest }, ref) => (
    <Root {...rest} ref={ref} className={cn(className, type, size)} />
  ),
)

SectionMessage.displayName = 'SectionMessage'

export default SectionMessage
