import { CHECKOUT_ITEM_TYPE_EXPERIENCE, CHECKOUT_ITEM_TYPE_TRANSFER } from 'constants/checkout'
import { isExperienceTicket } from 'lib/experiences/experienceUtils'
import uuidV4 from 'lib/string/uuidV4Utils'

export interface ExperienceTicketCount {
  ticket: App.ExperienceItemTicket | App.PackageAddonItem,
  count: number;
}

export interface ExperienceDetails {
  date?: string;
  time?: string;
  language?: string;
  pickupPoint?: string;
  redemptionLocation?: string;
  ticketMode?: string;
}

export function exceedsTicketAvailability(count: number, availability: number) {
  if (availability === -1) { return false } // -1 means infinite availability
  return count > availability
}

export function generateExperienceCheckoutTicket(item: ExperienceTicketCount): App.Checkout.ExperienceItemTicket {
  const ticket = item.ticket
  if (isExperienceTicket(ticket)) {
    return {
      ticketId: ticket.id,
      name: ticket.name,
      count: item.count,
      date: ticket.date,
      orderItemIds: ticket.orderItemIds,
      unavailable: exceedsTicketAvailability(item.count, ticket.purchaseLimit.max),
    }
  } else {
    return {
      ticketId: ticket.idSalesforceExternal,
      name: ticket.fareTypeText,
      count: item.count,
    }
  }
}

interface ExperienceItemOptions {
  isBookingBNBL?: boolean;
  cartItem?: App.Checkout.ExperienceItem,
  isBuyNowBookLater?: boolean;
  isGift?: boolean;
}

export function generateExperienceCheckoutItem(
  experience: App.ExperienceOffer,
  details: ExperienceDetails,
  tickets: Array<ExperienceTicketCount>,
  options: ExperienceItemOptions = {},
): App.Checkout.ExperienceItem {
  const { cartItem, isBookingBNBL, isBuyNowBookLater, isGift } = options
  const item: App.Checkout.ExperienceItem = {
    itemId: cartItem?.itemId || uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_EXPERIENCE,
    bookingType: experience.bookingType,
    tickets: tickets.map(generateExperienceCheckoutTicket),
    experienceId: experience.id,
    leExclusive: experience.leExclusive,
    date: details.date,
    isBuyNowBookLater: isBuyNowBookLater ?? false,
    isGift: !!isGift,
    isBookingBNBL,
    time: details.time,
    languageId: details.language,
    pickupPointId: details.pickupPoint,
    redemptionLocationId: details.redemptionLocation,
    ticketModeKey: details.ticketMode,
  }

  return item
}

export function generateTransferCheckoutItem(
  experience: App.ExperienceOffer,
  transfer: App.ExperienceTransferView,
  cartItem?: App.Checkout.TransferItem,
): App.Checkout.TransferItem {
  const item: App.Checkout.TransferItem = {
    itemId: cartItem?.itemId || uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_TRANSFER,
    transfer,
    experienceId: experience.id,
    offerType: experience.productType,
  }

  return item
}

export function generateLoyaltyLoungePassItem(
  experience: App.ExperienceOffer,
  ticket: App.ExperienceItemTicket,
  options: {
    date?: string,
    time?: string;
    isBookLater?: boolean;
    isFree?: boolean;
  } = {},
): App.Checkout.LoyaltyLoungePassItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: 'loungePassItem',
    experienceId: experience.id,
    ticketId: ticket.id,
    isBookLater: !!options.isBookLater,
    isFree: !!options.isFree,
    date: options.date,
    time: options.time,
    redemptionLocation: experience.redemptionLocations[0].id,
  }
}
