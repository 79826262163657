import { useEffect, useRef } from 'react'
import useToggle from './useToggle'

interface Delays {
  onDelay?: number;
  offDelay?: number;
}

export default function useDelayedTogglingBoolean(value: boolean, delays: Delays): boolean {
  const delayedValueRef = useRef(value)
  const delayRef = useRef<number | undefined>(undefined)
  const { toggle: triggerRerender } = useToggle()

  const currentDelay = value ? delays.onDelay : delays.offDelay

  let currentDelayedValue = delayedValueRef.current
  if (value !== delayedValueRef.current && typeof currentDelay !== 'number') {
    currentDelayedValue = value
  }

  useEffect(() => {
    delayRef.current = currentDelay
    if (typeof currentDelay !== 'number') {
      delayedValueRef.current = currentDelayedValue
    }
  })

  useEffect(() => {
    if (value !== delayedValueRef.current && typeof delayRef.current === 'number') {
      const timeout = setTimeout(() => {
        delayedValueRef.current = value
        triggerRerender()
      }, delayRef.current)

      return () => clearTimeout(timeout)
    }
  }, [triggerRerender, value])

  return currentDelayedValue
}
