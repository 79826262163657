import getObjectKey from 'lib/object/getObjectKey'
import { omitKeys } from 'lib/object/objectUtils'

/**
 * @returns formatted points key e.g. "price:100;productType:hotel;offerId:123;packageId:456"
 * itemId is excluded to not retrigger the points calculation request at checkout.
 */
function buildPointsKey(
  calculationRequest: App.LuxLoyaltyPointsEarnCalculationRequest,
  customerId: string,
): string {
  return getObjectKey({
    ...(omitKeys(['itemId'], calculationRequest)),
    customerId,
  })
}

export default buildPointsKey
