import { groupBy, nonNullable } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import offerPageURL from 'lib/offer/offerPageURL'
import { getLabels } from 'checkout/lib/utils/accommodation/label'
import { getLocationString } from 'checkout/lib/utils/accommodation/location'
import { excludeNullOrUndefined, getAccommodationDesignationWithTourBundle } from 'checkout/utils'
import { getBedbankRateKey } from 'actions/BedbankOfferActions'
import { getBedbankItemView } from 'checkout/lib/utils/bedbank/view'

import { getFullOffers } from 'selectors/offerSelectors'
import { getIsLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import getBedbankItems from 'checkout/selectors/view/getBedbankItems'
import { isBedbankOffer } from 'lib/offer/offerTypes'
import getFlightBundledItemIds from './getFlightBundledItemIds'
import { diffInDays } from 'lib/datetime/dateUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'
import { getTourV2Items } from 'checkout/selectors/view/toursv2'

const getBedbankItemViews = createSelector(
  (state: App.State) => getBedbankItems(state),
  (state: App.State) => getFullOffers(state),
  (state: App.State) => state.offer.bedbankOfferRates,
  (state: App.State) => state.businessTraveller.offersCredits,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.checkout.sessions,
  (state: App.State) => getFlightBundledItemIds(state),
  // This is temporary until LPP member pricing is fully released
  (state: App.State) => getIsLuxPlusLPPEnabled(state),
  (state: App.State) => getTourV2Items(state),
  (
    cartItems,
    offers,
    bedbankRatesByOffer,
    offerCredits,
    postPurchase,
    existingOrder,
    sessions,
    bundledItemIds,
    luxPlusLPPEnabled,
    tourV2Items,
  ): App.WithDataStatus<Array<App.Checkout.BedbankAccommodationOfferView>> => {
    // Each item = 1 room, but we want to process the same dates/offer as a single view
    const itemsByBookingKey = groupBy(cartItems, item => `${item.offerId}-${item.checkIn}-${item.checkOut}`)
    const itemGroups = itemsByBookingKey.values().map(items => ({
      checkIn: items[0].checkIn,
      checkOut: items[0].checkOut,
      offerId: items[0].offerId,
      items,
    })).toArray()

    const views = itemGroups.map((itemGroup): App.WithDataStatus<App.Checkout.BedbankAccommodationOfferView | undefined> => {
      const { offerId, checkIn, checkOut, items } = itemGroup
      const offer = offers[offerId]

      if (!isBedbankOffer(offer)) {
        return {
          hasRequiredData: false,
          data: undefined,
        }
      }

      const itemsBySessionId = groupBy(items, (item) => item.sessionId ?? 'none')

      let bedbankOfferRate: App.BedbankRate | undefined
      const itemViewsWithStatusesAndBedGroups = items.map(item => {
        const groupData = itemsBySessionId.get(item.sessionId ?? 'none') ?? []
        const occupancy = groupData.length > 0 ? groupData.map(item => item.occupancy) : [item.occupancy]
        const rateKey = getBedbankRateKey(occupancy, checkIn, checkOut)
        const bedbankOfferRates = bedbankRatesByOffer[offerId]?.[rateKey]
        const bedbankRatesFetched = !!bedbankRatesByOffer[offerId]
        bedbankOfferRate = bedbankOfferRates?.find(rate => rate.id === item.roomRateId && rate.isFlightBundle === item.isFlightBundle)

        return {
          bedGroups: bedbankOfferRate?.bedGroups ?? [],
          itemViewsWithStatuses: getBedbankItemView(
            item,
            luxPlusLPPEnabled,
            sessions[item.sessionId ?? 'none']?.data ?? null,
            offer,
            bedbankOfferRate,
            offerCredits,
            postPurchase,
            existingOrder,
            bedbankRatesFetched,
          ),
        }
      })

      const duration = diffInDays(new Date(checkIn), new Date(checkOut))

      const bundledWithTour = items.some(item => !!item.bundledItemIds?.length)
      const itemBundleId = items[0]?.bundledItemIds?.[0]
      const designation = bundledWithTour ? getAccommodationDesignationWithTourBundle(checkIn, itemBundleId, tourV2Items) : 'accommodation'

      const view: App.Checkout.BedbankAccommodationOfferView = {
        offerId,
        offer,
        offerPageUrl: offerPageURL(offer),
        ...getLabels(offer),
        bedGroups: itemViewsWithStatusesAndBedGroups.flatMap(i => i.bedGroups),
        confidenceLabels: [],
        duration,
        startDate: checkIn,
        endDate: checkOut,
        image: offer?.image,
        location: getLocationString(offer),
        locationCountry: offer.property.address.countryName,
        locationCountryCode: offer.property.address.countryCode,
        occupancy: items.map(item => item.occupancy).filter(excludeNullOrUndefined),
        offerType: offer?.type,
        reservationType: 'instant_booking',
        urgencyLabels: [],
        saleUnit: offer?.saleUnitLong,
        durationLabel: pluralizeToString('night', duration),
        offerLoaded: true,
        itemViews: itemViewsWithStatusesAndBedGroups.map(i => i.itemViewsWithStatuses.data),
        propertyTimezone: offer.property.timezone,
        bundledWithFlightsOnly: false,
        bundledWithTour,
        designation,
        isBundled: items.some(item => bundledItemIds.has(item.itemId)),
        hasMobilePromotion: !!bedbankOfferRate?.mobilePromotion,
      }

      return {
        data: view,
        hasRequiredData: itemViewsWithStatusesAndBedGroups.every(itemView => itemView.itemViewsWithStatuses.hasRequiredData),
      }
    })

    return {
      data: nonNullable(views.map(view => view.data)),
      hasRequiredData: views.every(view => view.hasRequiredData),
    }
  },
)

export default getBedbankItemViews
