import { formatCurrency } from 'lib/format/formatCurrencyIntl'
import { dateDifference } from 'lib/datetime/dateUtils'
import { CruisesContract } from '@luxuryescapes/contract-svc-cruise'

export function cruiseInclusionsMap(
  inclusions: Array<App.Cruises.OfferInclusion>,
  departureId: string,
  rateCode: string,
  ratePriceAmount: number,
  regionCode: string,
  isMainDeparture?: boolean,
): {
  luxPlusInclusionsByTier: App.LuxPlusInclusionsByTier | undefined,
  standardInclusions: Array<App.PackageInclusion>,
  inclusionDetails: App.Cruises.OfferInclusionDetails,
} {
  // We want to consider all offer inclusions if it is the main departure (displayed in the offer tile)
  const filteredInclusions = isMainDeparture ? inclusions : inclusions
    .filter(({ departureIds, rateCodes }) => (
      (!departureIds.length || departureIds.includes(departureId)) &&
      (!rateCodes.length || rateCodes.includes(rateCode))
    ))

  return {
    luxPlusInclusionsByTier: luxPlusInclusionsByTierMap(filteredInclusions, ratePriceAmount, regionCode),
    standardInclusions: standardInclusionsMap(filteredInclusions, ratePriceAmount, regionCode),
    inclusionDetails: cruiseInclusionDetailsMap(filteredInclusions),
  }
}

function luxPlusInclusionsByTierMap(
  inclusions: Array<App.Cruises.OfferInclusion>,
  ratePriceAmount: number,
  regionCode: string,
): App.LuxPlusInclusionsByTier | undefined {
  // There should not be more than one group of LuxPlus inclusions, but if there is, we will use only the first one.
  const luxPlusInclusionGroup = inclusions.find(({ type }) => type === 'LUX_PLUS')
  if (!luxPlusInclusionGroup?.items?.length) return

  const packageInclusions: Array<App.PackageInclusion> = luxPlusInclusionGroup.items.map((item) => {
    const formattedTotalAmount = calculateInclusionTotalAmount(item, regionCode)
    const isOnboardCredit = item.type === 'ONBOARD_CREDIT'
    const inclusionDescription = isOnboardCredit && formattedTotalAmount ? `${formattedTotalAmount} Onboard Credit` : item.description

    return {
      id: item.id,
      description: inclusionDescription!,
      type: luxPlusInclusionGroup.type,
      parentCategory: item.type,
      isHighlighted: luxPlusInclusionGroup.type === 'LUX_PLUS',
      symbol: isOnboardCredit ? 'dollar_sign_in_circle' : undefined,
      inclusionGroupId: luxPlusInclusionGroup.id,
    }
  })

  if (!packageInclusions.length) return

  return {
    // Cruise only has one tier of luxplus inclusions for now
    base: packageInclusions,
  }
}

function standardInclusionsMap(
  inclusions: Array<App.Cruises.OfferInclusion>,
  ratePriceAmount: number,
  regionCode: string,
): Array<App.PackageInclusion> {
  // There should not be more than one group of Standard inclusions, but if there is, we will use only the first one.
  const standardInclusionGroup = inclusions.find(({ type }) => type !== 'LUX_PLUS')
  if (!standardInclusionGroup) return []

  const packageInclusions: Array<App.PackageInclusion> = standardInclusionGroup.items.map((item) => {
    const formattedTotalAmount = calculateInclusionTotalAmount(item, regionCode)
    const isOnboardCredit = item.type === 'ONBOARD_CREDIT'
    const inclusionDescription = isOnboardCredit && formattedTotalAmount ? `${formattedTotalAmount} Onboard Credit` : item.description

    return {
      id: item.id,
      description: inclusionDescription!,
      type: standardInclusionGroup.type,
      parentCategory: item.type,
      symbol: isOnboardCredit ? 'dollar_sign_in_circle' : undefined,
      inclusionGroupId: standardInclusionGroup.id,
    }
  })

  return packageInclusions
}

function cruiseInclusionDetailsMap(inclusions: Array<App.Cruises.OfferInclusion>): App.Cruises.OfferInclusionDetails {
  const hasNewInclusion = inclusions.some(({ startDate }) => dateDifference(new Date(), new Date(startDate)).days < 7)
  const endingInInclusion = Math.min(
    ...inclusions.map(({ endDate }) => dateDifference(new Date(endDate), new Date()).days),
  )

  return {
    hasNewInclusion,
    endingInInclusion,
  }
}

function calculateInclusionTotalAmount(item: App.Cruises.OfferInclusionItem, regionCode: string): string | null {
  if (item.type === 'ONBOARD_CREDIT' && item.creditAmount && item.creditCurrency) {
    return formatCurrency(item.creditAmount, item.creditCurrency, regionCode) ?? null
  }

  return null
}

export function cruiseBookingLuxPlusInclusionsMap(inclusions: Array<CruisesContract.BookingInclusion>, regionCode: string): Array<App.CruiseBookingLuxPlusInclusion> {
  const luxPlusInclusions = inclusions.filter((inclusion) => inclusion.inclusionType === 'LUX_PLUS')

  return luxPlusInclusions.map((inclusion) => {
    let { description } = inclusion

    if (inclusion.inclusionItemType === 'ONBOARD_CREDIT' && inclusion.amount && inclusion.currency) {
      description = `${formatCurrency(inclusion.amount, inclusion.currency, regionCode)} Onboard Credit`
    }

    return {
      id: inclusion.id,
      description: description!,
      amount: inclusion.amount ?? undefined,
      currency: inclusion.currency ?? undefined,
      type: inclusion.inclusionType,
      category: inclusion.inclusionItemType,
      unit: 'per_cabin',
      luxPlusTier: 'base',
    }
  })
}
