import React, { useContext } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import Clickable from '../../Common/Clickable'
import { ProductPaletteContext } from 'contexts/ProductPaletteContext'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'

export const ButtonPadding = {
  small: rem(4),
  medium: rem(12),
  large: rem(16),
}

const Button = styled(Clickable)`
  height: var(--button-size, auto);
  display: inline-flex;
  align-items: center;
  text-align: center;
  border-radius: var(--button-border-radius, 0);
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
  border: 1px solid var(--button-border-color, transparent);
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  color: var(--button-color, unset);
  background-color: var(--button-background-color, transparent);
  font-family: ${props => props.theme.font.secondary.family};
  font-weight: ${props => props.theme.font.secondary.weight.regular};
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  padding: 0 var(--button-padding, 0);
  flex-shrink: 0;

  &:disabled {
    background-color: var(--button-disabled-background-color, transparent);
    border-color: var(--button-disabled-border-color, transparent);
    color: var(--button-disabled-color, unset);
  }

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover {
        background-color: var(--button-hover-background-color, transparent);
        border-color: var(--button-hover-border-color, transparent);
        color: var(--button-hover-color, unset);
      }
    }

    &:focus {
      --button-focus-ring-width: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      outline: var(--button-focus-ring-width, 0) solid var(--button-focus-ring-color, transparent);
      outline-offset: 1px;
      background-color: var(--button-focus-background-color, transparent);
      border-color: var(--button-focus-border-color, transparent);
      color: var(--button-focus-color, transparent);
    }

    &:active {
      background-color: var(--button-active-background-color, transparent);
      border-color: var(--button-active-border-color, transparent);
      color: var(--button-active-color, unset);
    }
  }


  &:hover {
    text-decoration: none;
  }

  --color-primary-bg: ${props => props.theme.palette.brand.primary.normal};
  --color-primary-fg: ${props => props.theme.palette.neutral.default.eight};
  --color-primary-hover: ${props => props.theme.palette.brand.primary.hover};
  --color-primary-active: ${props => props.theme.palette.brand.primary.active};


  &.kind-primary {
    /* Common colour settings across all variants */
    --button-focus-ring-color: ${props => props.theme.palette.neutral.default.five};

    &.product-palette-ultralux {
      --color-primary-bg: ${props => props.theme.palette.product.ultralux.background};
      --color-primary-fg: ${props => props.theme.palette.product.ultralux.contrast};
      --color-primary-hover: ${props => props.theme.palette.product.ultralux.hover};
      --color-primary-active: ${props => props.theme.palette.product.ultralux.active};
    }

    &.product-palette-lux-plus {
      --color-primary-bg: ${props => props.theme.palette.product.luxPlus.background};
      --color-primary-fg: ${props => props.theme.palette.product.luxPlus.contrast};
      --color-primary-hover: ${props => props.theme.palette.product.luxPlus.hover};
      --color-primary-active: ${props => props.theme.palette.product.luxPlus.active};
    }

    &.variant-default, &.variant-dark {
      --button-color: var(--color-primary-fg);
      --button-background-color: var(--color-primary-bg);
      --button-border-color: var(--color-primary-bg);

      --button-disabled-color: var(--color-primary-fg);
      --button-disabled-background-color: ${props => props.theme.palette.neutral.default.four};
      --button-disabled-border-color: ${props => props.theme.palette.neutral.default.five};

      --button-hover-color: var(--color-primary-fg);
      --button-hover-background-color: var(--color-primary-hover);
      --button-hover-border-color: var(--color-primary-hover);

      --button-active-color: var(--color-primary-fg);
      --button-active-background-color: var(--color-primary-active);
      --button-active-border-color: var(--color-primary-active);

      --button-focus-color: var(--color-primary-fg);
      --button-focus-border-color: var(--color-primary-bg);
      --button-focus-background-color: var(--color-primary-bg);

      &.is-selected {
        /* This variant looks the same when selected, often the content will change instead */
      }
    }

    &.variant-ghost {
      --button-color: var(--color-primary-bg);
      --button-background-color: ${props => props.theme.palette.neutral.default.eight};
      --button-border-color: ${props => props.theme.palette.neutral.default.eight};

      --button-disabled-color: ${props => props.theme.palette.neutral.default.two};
      --button-disabled-background-color: ${props => props.theme.palette.neutral.default.four};
      --button-disabled-border-color: ${props => props.theme.palette.neutral.default.five};

      --button-hover-color: var(--color-primary-hover);
      --button-hover-background-color: ${props => props.theme.palette.neutral.default.six};
      --button-hover-border-color: ${props => props.theme.palette.neutral.default.six};

      --button-active-color: var(--color-primary-active);
      --button-active-background-color: ${props => props.theme.palette.neutral.default.eight};
      --button-active-border-color: ${props => props.theme.palette.neutral.default.eight} ;

      --button-focus-color: var(--color-primary-bg);
      --button-focus-background-color: ${props => props.theme.palette.neutral.default.eight};

      &.is-selected {
        --button-color: ${props => props.theme.palette.neutral.default.eight};
        --button-background-color: var(--color-primary-bg);
        --button-border-color: var(--color-primary-bg);

        --button-disabled-color: ${props => props.theme.palette.neutral.default.seven};

        --button-hover-color: ${props => props.theme.palette.neutral.default.eight};
        --button-hover-background-color: var(--color-primary-hover);
        --button-hover-border-color: var(--color-primary-hover);

        --button-active-color: ${props => props.theme.palette.neutral.default.eight};
        --button-active-background-color: var(--color-primary-active);
        --button-active-border-color: var(--color-primary-active);

        --button-focus-color: ${props => props.theme.palette.neutral.default.eight};
        --button-focus-background-color: var(--color-primary-bg);
        --button-focus-border-color: var(--color-primary-bg);

      }
    }
  }

  &.kind-secondary {
    &.product-palette-lux-plus {
      --color-primary-bg: ${props => props.theme.palette.product.luxPlus.altForeground};
      --color-primary-hover: ${props => props.theme.palette.product.luxPlus.altHover};
      --color-primary-active: ${props => props.theme.palette.product.luxPlus.altActive};
    }

    &.product-palette-ultralux {
      --color-primary-bg: ${props => props.theme.palette.product.ultralux.foreground};
      --color-primary-hover: ${props => props.theme.palette.product.ultralux.hover};
      --color-primary-active: ${props => props.theme.palette.product.ultralux.active};
    }

    &.variant-default {
      --button-color: var(--color-primary-bg);
      --button-background-color: transparent;
      --button-border-color: var(--color-primary-bg);

      --button-disabled-color: ${props => props.theme.palette.neutral.default.four};
      --button-disabled-border-color: ${props => props.theme.palette.neutral.default.five};

      --button-hover-color: ${props => props.theme.palette.neutral.default.eight};
      --button-hover-background-color: var(--color-primary-bg);
      --button-hover-border-color: var(--color-primary-bg);

      --button-active-color: ${props => props.theme.palette.neutral.default.eight};
      --button-active-background-color: var(--color-primary-bg);
      --button-active-border-color: var(--color-primary-bg );

      --button-focus-color: var(--color-primary-bg);
      --button-focus-border-color: var(--color-primary-bg);
      --button-focus-ring-color: ${props => props.theme.palette.neutral.default.five};

      &.is-selected {
        --button-color: ${props => props.theme.palette.neutral.default.eight};
        --button-background-color: var(--color-primary-bg);
        --button-border-color: var(--color-primary-bg);

        --button-disabled-color: ${props => props.theme.palette.neutral.default.eight};
        --button-disabled-background-color: ${props => props.theme.palette.neutral.default.four};
        --button-disabled-border-color: ${props => props.theme.palette.neutral.default.five};

        --button-hover-color: var(--color-primary-bg);
        --button-hover-background-color: transparent;
        --button-hover-border-color: var(--color-primary-bg);

        --button-focus-color: ${props => props.theme.palette.neutral.default.eight};
        --button-focus-background-color: var(--color-primary-bg);
        --button-focus-border-color: var(--color-primary-bg);

        --button-active-color: ${props => props.theme.palette.neutral.default.eight};
        --button-active-background-color: var(--color-primary-active);
        --button-active-border-color: var(--color-primary-active);
      }
    }

    &.variant-ghost {
      --button-color: ${props => props.theme.palette.neutral.default.eight};
      --button-background-color: transparent;
      --button-border-color: ${props => props.theme.palette.neutral.default.eight};

      --button-disabled-color: ${props => props.theme.palette.neutral.default.four};
      --button-disabled-border-color: ${props => props.theme.palette.neutral.default.five};

      --button-hover-color: var(--color-primary-bg);
      --button-hover-background-color: ${props => props.theme.palette.neutral.default.eight};
      --button-hover-border-color: ${props => props.theme.palette.neutral.default.eight};

      --button-active-color: var(--color-primary-bg);
      --button-active-background-color: ${props => props.theme.palette.neutral.default.eight};
      --button-active-border-color: ${props => props.theme.palette.neutral.default.eight};

      --button-focus-color: ${props => props.theme.palette.neutral.default.eight};
      --button-focus-border-color: ${props => props.theme.palette.neutral.default.eight};
      --button-focus-ring-color: ${props => props.theme.palette.neutral.default.eight};

      &.is-selected {
        --button-color: var(--color-primary-bg);
        --button-background-color: ${props => props.theme.palette.neutral.default.eight};
        --button-border-color: ${props => props.theme.palette.neutral.default.eight};

        --button-disabled-color: ${props => props.theme.palette.neutral.default.seven};
        --button-disabled-background-color: ${props => props.theme.palette.neutral.default.four};
        --button-disabled-border-color: ${props => props.theme.palette.neutral.default.five};

        --button-hover-color: ${props => props.theme.palette.neutral.default.eight};
        --button-hover-background-color: transparent;
        --button-hover-border-color: ${props => props.theme.palette.neutral.default.eight};

        --button-active-color: ${props => props.theme.palette.neutral.default.eight};
        --button-active-background-color: transparent;
        --button-active-border-color: ${props => props.theme.palette.neutral.default.eight};

        --button-focus-color: var(--color-primary-bg);
        --button-focus-background-color: ${props => props.theme.palette.neutral.default.eight};
        --button-focus-border-color: ${props => props.theme.palette.neutral.default.eight};
        --button-focus-ring-color: ${props => props.theme.palette.neutral.default.five};
      }
    }

    &.variant-dark {
      --button-color: ${props => props.theme.palette.neutral.default.one};
      --button-background-color: ${props => props.theme.palette.neutral.default.eight};
      --button-border-color: ${props => props.theme.palette.neutral.default.five};

      --button-disabled-color: ${props => props.theme.palette.neutral.default.four};
      --button-disabled-border-color: ${props => props.theme.palette.neutral.default.four};

      --button-hover-color: ${props => props.theme.palette.neutral.default.two};
      --button-hover-background-color: ${props => props.theme.palette.neutral.default.seven};
      --button-hover-border-color: ${props => props.theme.palette.neutral.default.five};

      --button-active-color: ${props => props.theme.palette.neutral.default.one};
      --button-active-background-color: ${props => props.theme.palette.neutral.default.six};
      --button-active-border-color: ${props => props.theme.palette.neutral.default.five};

      --button-focus-color: ${props => props.theme.palette.neutral.default.one};
      --button-focus-background-color: ${props => props.theme.palette.neutral.default.eight};
      --button-focus-border-color: ${props => props.theme.palette.neutral.default.five};
      --button-focus-ring-color: ${props => props.theme.palette.neutral.default.five};

      &.is-selected {
        --button-color: ${props => props.theme.palette.neutral.default.eight};
        --button-background-color: ${props => props.theme.palette.neutral.default.one};
        --button-border-color: ${props => props.theme.palette.neutral.default.one};

        --button-disabled-color: ${props => props.theme.palette.neutral.default.eight};
        --button-disabled-background-color: ${props => props.theme.palette.neutral.default.four};
        --button-disabled-border-color: ${props => props.theme.palette.neutral.default.four};

        --button-hover-color: ${props => props.theme.palette.neutral.default.eight};
        --button-hover-background-color: ${props => props.theme.palette.neutral.default.three};
        --button-hover-border-color: ${props => props.theme.palette.neutral.default.three};

        --button-focus-color: ${props => props.theme.palette.neutral.default.eight};
        --button-focus-background-color: ${props => props.theme.palette.neutral.default.one};
        --button-focus-border-color: ${props => props.theme.palette.neutral.default.one};
        --button-focus-ring-color: ${props => props.theme.palette.neutral.default.five};

        --button-active-color: ${props => props.theme.palette.neutral.default.eight};
        --button-active-background-color: ${props => props.theme.palette.neutral.default.two};
        --button-active-border-color: ${props => props.theme.palette.neutral.default.two};
      }
    }
  }

  &.kind-tertiary {
    /* Common colour settings across all variants */
    --button-focus-ring-color: ${props => props.theme.palette.neutral.default.five};

    &.variant-default {
      --button-color: ${props => props.theme.palette.brand.primary.normal};
      --button-background-color: transparent;
      --button-border-color: transparent;

      --button-disabled-color: ${props => props.theme.palette.neutral.default.four};

      --button-hover-color: ${props => props.theme.palette.brand.primary.hover};
      --button-hover-background-color: ${props => props.theme.palette.neutral.default.seven};

      --button-active-color: ${props => props.theme.palette.brand.primary.active};

      --button-focus-color: ${props => props.theme.palette.brand.primary.normal};

      &.is-selected {
        --button-color: ${props => props.theme.palette.brand.primary.normal};
        --button-background-color: ${props => props.theme.palette.brand.primary.lightest};

        --button-disabled-color: ${props => props.theme.palette.neutral.default.seven};
        --button-disabled-background-color: ${props => props.theme.palette.neutral.default.four};

        --button-hover-color: ${props => props.theme.palette.brand.primary.hover};
        --button-hover-background-color: ${props => props.theme.palette.brand.primary.lightest};

        --button-active-color: ${props => props.theme.palette.brand.primary.active};
        --button-active-background-color: ${props => props.theme.palette.brand.primary.lightest};

        --button-focus-color: ${props => props.theme.palette.brand.primary.normal};
        --button-focus-background-color: ${props => props.theme.palette.brand.primary.lightest};
      }
    }

    &.variant-ghost {
      --button-color: ${props => props.theme.palette.neutral.default.eight};
      --button-background-color: transparent;
      --button-border-color: transparent;

      --button-disabled-color: ${props => props.theme.palette.neutral.default.four};

      --button-hover-color: ${props => props.theme.palette.neutral.default.eight};
      --button-hover-background-color: ${props => props.theme.palette.neutral.default.clear};

      --button-active-color: ${props => props.theme.palette.neutral.default.five};

      --button-focus-color: ${props => props.theme.palette.neutral.default.eight};

      &.is-selected {
        --button-background-color: ${props => props.theme.palette.neutral.default.clear};

        --button-disabled-color: ${props => props.theme.palette.neutral.default.four};
        --button-disabled-background-color: ${props => props.theme.palette.neutral.default.clear};

        --button-hover-color: ${props => props.theme.palette.neutral.default.five};
        --button-hover-background-color: ${props => props.theme.palette.neutral.default.clear};

        --button-focus-background-color: ${props => props.theme.palette.neutral.default.clear};

        --button-active-color: ${props => props.theme.palette.neutral.default.five};
        --button-active-background-color: ${props => props.theme.palette.neutral.default.clear};
      }
    }

    &.variant-dark {
      --button-color: ${props => props.theme.palette.neutral.default.one};
      --button-background-color: transparent;
      --button-border-color: transparent;

      --button-disabled-color: ${props => props.theme.palette.neutral.default.four};

      --button-hover-color: ${props => props.theme.palette.neutral.default.two};
      --button-hover-background-color: ${props => props.theme.palette.neutral.default.seven};

      --button-active-color: ${props => props.theme.palette.neutral.default.three};

      --button-focus-color: ${props => props.theme.palette.neutral.default.one};

      &.is-selected {
        --button-color: ${props => props.theme.palette.neutral.default.one};
        --button-background-color: ${props => props.theme.palette.neutral.default.seven};

        --button-disabled-color: ${props => props.theme.palette.neutral.default.four};
        --button-disabled-background-color: ${props => props.theme.palette.neutral.default.seven};

        --button-hover-color: ${props => props.theme.palette.neutral.default.two};
        --button-hover-background-color: ${props => props.theme.palette.neutral.default.six};

        --button-active-color: ${props => props.theme.palette.neutral.default.two};
        --button-active-background-color: ${props => props.theme.palette.neutral.default.seven};

        --button-focus-color: ${props => props.theme.palette.neutral.default.one};
        --button-focus-background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }
  }
`

type ButtonKind = 'primary' | 'secondary' | 'tertiary'
type ButtonVariant = 'default' | 'ghost' | 'dark'
export const BASE_BUTTON_KINDS: Readonly<Array<ButtonKind>> = ['primary', 'secondary', 'tertiary'] as const
export const BASE_BUTTON_VARIANTS: Readonly<Array<ButtonVariant>> = ['default', 'ghost', 'dark'] as const
export const BASE_BUTTON_DEFAULT_VARIANT: ButtonVariant = 'default'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, Omit<React.AnchorHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>, 'type'> {
  /** Will disable the button (make it non-interactable) and change it's visual state to look disabled  */
  disabled?: boolean
  /** Whether or not the button should show itself in the 'selected' visual state  */
  isSelected?: boolean
  /** The overall kind of button this is, determines whether it has borders/backgrounds */
  kind: ButtonKind
  /** The variant of the 'kind' of button it is. This determines the colours the various aspects of the button */
  variant?: ButtonVariant
  /**
   * Makes the button not a 'clickable' (anchor or button) tag.
   * Use this when this is nested in a larger clickable element (such as a card) that would do the same
   * behaviour as the button. If you need it to do something different, use the `<NestedClickable />` component instead.
   * */
  nonInteractable?: boolean
  /** Used for internal linking to another page/url */
  to?: string
}

/**
 * This is the 'base' button that other buttons should compose
 * This is effectively an 'abstract' component and should never be used on it's own
 */
const _Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    kind,
    variant = BASE_BUTTON_DEFAULT_VARIANT,
    children,
    className,
    nonInteractable,
    isSelected,
    type = 'button',
    ...rest
  } = props

  const productPalette = useContext(ProductPaletteContext)

  let component: any = null
  if (nonInteractable) {
    component = 'div'
  }

  return <Button
    {...rest}
    ref={ref}
    as={component}
    tabIndex={nonInteractable ? 0 : undefined}
    type={type}
    className={cn(
      className,
      `product-palette-${productPalette}`,
      `kind-${kind}`,
      `variant-${variant}`,
      {
        'is-selected': isSelected,
      },
    )}
  >
    {children}
  </Button>
})

_Button.displayName = 'BaseButton'

export default _Button
