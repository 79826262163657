import cn from 'clsx'
import React, { useRef } from 'react'
import styled from 'styled-components'
import useToggle from 'hooks/useToggle'
import Clickable from 'components/Common/Clickable'
import SlideDown from 'components/utils/SlideDown'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import { rem } from 'polished'
import Heading from 'components/Luxkit/Typography/Heading'
import TextLink from '../../Luxkit/TextLink'
import noop from 'lib/function/noop'
import LoadingAnimation from '../Loading/LoadingAnimation'
import Group from 'components/utils/Group'

const Wrapper = styled.div`
  &.border {
    border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};
  }

  &.is-open {
    padding-bottom: ${rem(16)};
    transition: padding-bottom .2 ease;
  }
`

const FilterGroup = styled(Clickable)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: ${rem(16)} 0;
`

const FadingReset = styled(TextLink)`
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;

  &.visible {
    pointer-events: auto;
    opacity: 1;
  }
`

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  canReset?: boolean;
  onReset?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  showBorder?: boolean;
  showDropdown?: boolean;
  allowToggle?: boolean;
  loading?: boolean;
  opened?: boolean;
}

function FilterPanelFilterGroup(props: Props) {
  const { children, title, onReset = noop, canReset, showBorder = true, showDropdown = true, allowToggle = true, loading = false, opened = true } = props
  const [open, toggle] = useToggle(opened)
  const resetRef = useRef(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const onGroupClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.target === resetRef.current) {
      contentRef.current?.querySelectorAll('input').forEach(input => {
        if (input.type === 'checkbox') {
          input.checked = false
        } else if (input.type === 'range') {
          if (input.name.includes('start')) {
            input.value = input.min
          } else {
            input.value = input.max
          }
        } else {
          input.value = ''
        }
      })
      onReset(e)
    } else if (allowToggle) {
      toggle()
    }
  }

  return (
    <Wrapper ref={contentRef} className={cn({ 'is-open': open, border: showBorder })}>
      <FilterGroup onClick={onGroupClick}>
        <Group direction="horizontal" verticalAlign="center" gap={8}>
          {typeof title === 'string' && <Heading variant="heading5">{title}</Heading>}
          {typeof title !== 'string' && title}
          {loading && <LoadingAnimation size={8} />}
        </Group>
        <Group direction="horizontal" verticalAlign="center" gap={8}>
          <FadingReset
            aria-hidden={!canReset}
            ref={resetRef}
            className={cn({ visible: canReset })}
            size="small"
          >
            Reset
          </FadingReset>
          {showDropdown && <DropdownChevron open={open} />}
        </Group>
      </FilterGroup>
      <SlideDown show={open}>
        {children}
      </SlideDown>
    </Wrapper>
  )
}

export default React.memo(FilterPanelFilterGroup)
