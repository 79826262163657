import { TOUR_V2_PROFILE_ASIA_CAMPAIGN } from 'constants/tours'
import React from 'react'
import styled from 'styled-components'
import Image from 'components/Common/Image'
import cn from 'clsx'
import { rem } from 'polished'

const ImageRounded = styled(Image)`
  border-radius: ${(props) => props.theme.borderRadius.round};

  &.size-medium {
    width: ${rem(45)};
  }

  &.size-large {
    width: ${rem(80)};
  }
`

interface Props {
  size?: 'medium' | 'large';
}

function TourAsiaCampaignProfileImage({ size = 'large' }: Props) {
  return (
    <ImageRounded
      id={TOUR_V2_PROFILE_ASIA_CAMPAIGN}
      className={cn(`size-${size}`)}
      aspectRatio="1:1"
      gravity="center"
      quality="best"
      highRes
      dpr={3}
    />
  )
}

export default React.memo(TourAsiaCampaignProfileImage)
