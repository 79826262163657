import { css } from 'styled-components'
import { Interpolation, Styles } from 'styled-components/dist/types'

export function InvalidElementStyle(message: string) {
  return css`
  @keyframes MoveBackground {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  background-color: red;
  background-image: repeating-linear-gradient(
    45deg,
    red,
    red 10px,
    orange 10px,
    orange 20px
  );
  color: white;
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 2px red;
  paint-order: stroke;
  font-weight: bold;
  font-family: monospace;
  background-size: calc(100% + 100px);
  animation: MoveBackground 1s ease infinite;
  outline: 4px solid red;
  outline-offset: 2px;
  &::before,
  &::after {
    content: "🙅‍♂️ ${message} 🙅‍♂️";
  }
`
}

const devOnlyCss = <Props extends object>(styles: Styles<NoInfer<Props>>, ...interpolations: Array<Interpolation<NoInfer<Props>>>) => {
  if (process.env.NODE_ENV === 'development') {
    return css<NoInfer<Props>>(styles, ...interpolations)
  } else {
    return []
  }
}

export default devOnlyCss
