import React, { ComponentProps, MouseEventHandler, ReactElement, ReactNode, forwardRef, memo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import TextButton from '../Button/TextButton'
import BodyTextBlock from '../TextBlocks/BodyTextBlock'
import IconButton from '../Button/IconButton'
import LineTimesIcon from '../Icons/line/LineTimesIcon'
import shallowEqualWithNestedProps from 'lib/memoize/shallowEqualWithNestedProps'

const BannerSheet = styled.div`
  &.kind-informational-primary {
    background-color: ${props => props.theme.palette.neutral.default.seven};
    color: ${props => props.theme.palette.neutral.default.one};
  }
  &.kind-promotional-primary {
    background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
    color: ${props => props.theme.palette.highlight.primary.lightContrast};
  }
  &.kind-promotional-primary-accent {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
    color: ${props => props.theme.palette.highlight.primary.normalContrast};
  }
  &.kind-promotional-secondary {
    background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
    color: ${props => props.theme.palette.highlight.tertiary.lightContrast};
  }
  &.kind-success {
    background-color: ${props => props.theme.palette.messaging.success.lightBackground};
    color: ${props => props.theme.palette.messaging.success.lightContrast};
  }
  &.kind-warning {
    background-color: ${props => props.theme.palette.messaging.warning.lightBackground};
    color: ${props => props.theme.palette.messaging.warning.lightContrast};
  }
  &.kind-critical {
    background-color: ${props => props.theme.palette.messaging.critical.lightBackground};
    color: ${props => props.theme.palette.messaging.critical.lightContrast};
  }
  &.kind-lux-plus {
    background-color: ${props => props.theme.palette.product.luxPlus.background};
    color: ${props => props.theme.palette.product.luxPlus.contrast};
  }
`

const BannerContainer = styled(LayoutContainer)`
  display: grid;

  &.density-comfortable { padding: ${rem(16)}; }
  &.density-compact { padding: ${rem(12)}; }

  &.align-start {
    grid-template:
      "content . dismiss" auto
      "actions actions actions" auto / auto 1fr min-content;
  }
  &.align-center {
    grid-template:
      ". content . dismiss" auto
      "actions actions actions actions" auto / 1fr auto 1fr min-content;
  }

  ${mediaQueryUp.tablet} {
    align-items: center;

    &.density-comfortable { padding: ${rem(16)} ${rem(20)}; }
    &.density-compact { padding: ${rem(12)} ${rem(20)}; }

    &.align-on-tablet-start {
      grid-template: "content actions . dismiss" min-content / auto auto 1fr min-content;
    }
    &.align-on-tablet-center {
      grid-template: ". content actions . dismiss" min-content / 1fr auto auto 1fr min-content;
    }
  }
`

const BannerContent = styled(BodyTextBlock)`
  grid-area: content;
`

const BannerTrailingContent = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-left: ${rem(4)};
  margin-inline-start: ${rem(4)};

  ${mediaQueryUp.tablet} {
    margin-left: ${rem(8)};
    margin-inline-start: ${rem(8)};
  }
`

const BannerActionsGroup = styled(Group)`
  grid-area: actions;
  margin: ${rem(8)} 0 0 0;

  ${mediaQueryUp.tablet} {
    margin: 0 0 0 ${rem(12)};
  }
`

const BannerDismissButton = styled(IconButton)`
  grid-area: dismiss;
  margin: ${rem(-4)} ${rem(-4)} 0 ${rem(8)};

  ${mediaQueryUp.tablet} {
    margin: 0 0 0 ${rem(24)};
  }
`

type Props = {
  /** sets the colour of the banner */
  kind:
    'informational-primary' |
    'promotional-primary' |
    'promotional-primary-accent' |
    'promotional-secondary' |
    'success' |
    'warning' |
    'critical' |
    'lux-plus'
  /**
   * sets the horizontal alignment of the content
   * @default 'start'
   * */
  align?: 'start' | 'center'
  /**
   * @default comfortable
   */
  density?: 'comfortable' | 'compact'
  /**
   * sets the horizontal alignment of the content on tablet breakpoint and higher
   * @default falls back to `align`
   * */
  tabletAlign?: 'start' | 'center'
  /** sets the icon at the start of the description */
  icon?: ReactElement
  /**
   * sets the textual content of the banner
   * can also be a compound node, but will still be affected by the typography styles
   *  */
  description: string | ReactElement
  className?: string
  id?: string
  /** invoked when the dismiss button is clicked */
  onDismiss?: MouseEventHandler<HTMLButtonElement>
} & (
  {
    /**
     * sets the props passed into the primary action
     *
     * cannot define a primary action and trailing content at the same time
     *  */
    primaryActionButtonProps?: Omit<ComponentProps<typeof TextButton>, 'kind' | 'variant'>
    /**
     * sets the props passed into the secondary action
     *
     * cannot define a secondary action and trailing content at the same time
     *  */
    secondaryActionButtonProps?: Omit<ComponentProps<typeof TextButton>, 'kind' | 'variant'>
    trailingContent?: undefined
  } | {
    /**
     * sets the node trailing the description
     *
     * cannot define trailing content and actions at the same time
     *  */
    trailingContent?: ReactNode
    primaryActionButtonProps?: undefined
    secondaryActionButtonProps?: undefined
  }
)

const PageBanner = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    kind,
    align = 'start',
    density = 'comfortable',
    tabletAlign,
    description,
    icon,
    trailingContent,
    className,
    id,
    primaryActionButtonProps,
    secondaryActionButtonProps,
    onDismiss,
  } = props

  return <BannerSheet
    ref={ref}
    id={id}
    className={cn(
      className,
      `kind-${kind}`,
    )}
  >
    <BannerContainer
      className={cn(
      `align-${align}`,
        `align-on-tablet-${tabletAlign ?? align}`,
        `density-${density}`,
      )}
    >
      <BannerContent
        startIcon={icon}
        variant="medium"
        align={align}
        tabletAlign={tabletAlign}
      >
        {description}
        {!!trailingContent && <BannerTrailingContent>{trailingContent}</BannerTrailingContent>}
      </BannerContent>

      {(primaryActionButtonProps || secondaryActionButtonProps) && <BannerActionsGroup
        direction="horizontal"
        horizontalAlign="end"
        gap={8}
      >
        {secondaryActionButtonProps && <TextButton {...secondaryActionButtonProps} kind="tertiary" />}
        {primaryActionButtonProps && <TextButton {...primaryActionButtonProps} kind="secondary" />}
      </BannerActionsGroup>}

      {onDismiss && <BannerDismissButton
        kind="tertiary"
        size="small"
        aria-controls={id}
        onClick={onDismiss}
      >
        <LineTimesIcon />
      </BannerDismissButton>}
    </BannerContainer>
  </BannerSheet>
})

export default memo(PageBanner, shallowEqualWithNestedProps(['primaryActionButtonProps', 'secondaryActionButtonProps']))
