import React, { useCallback, useMemo } from 'react'
import config from 'constants/config'
import ButtonGroupToggle from 'components/Common/ButtonGroupToggle'
import { DATE_SEARCH_OPTION_IDS } from 'constants/search'
import { connect } from 'react-redux'
import { ButtonToggleItem } from 'components/Common/ButtonGroupToggle/ButtonGroupToggleItem'

interface Props {
  onSelectSpecific: () => void;
  onSelectAnytime: () => void;
  onSelectFlexible?: () => void;
  disableFlexible?: boolean; // this needs to be negative logic to avoid impacting existing code
  anytimeDateSelected: boolean;
  dateSearchOptionId?: DATE_SEARCH_OPTION_IDS;
  className?: string;
  isSpecificSearchEnabled?: boolean
}

interface MappedStateProps {
  isFlexibleSearchEnabled: boolean
}

const flexibleDatesToggle = {
  id: DATE_SEARCH_OPTION_IDS.FLEXIBLE,
  label: 'Flexible dates',
}

/**
 * UI component to allow user to select/click Specific dates, Flexible dates or Anytime in button format
 */
function SearchDateToggle({
  className,
  onSelectSpecific,
  onSelectFlexible,
  onSelectAnytime,
  anytimeDateSelected,
  dateSearchOptionId,
  isFlexibleSearchEnabled,
  isSpecificSearchEnabled = true,
}: Props & MappedStateProps) {
  const specificDatesToggle = useMemo(() => ({
    id: DATE_SEARCH_OPTION_IDS.SPECIFIC,
    label: isFlexibleSearchEnabled ? 'Specific dates' : 'I’ve got dates',
  }), [isFlexibleSearchEnabled])

  const anytimeDatesToggle = useMemo(() => ({
    id: DATE_SEARCH_OPTION_IDS.ANYTIME,
    label: isFlexibleSearchEnabled ? 'Anytime' : 'I’m flexible',
  }), [isFlexibleSearchEnabled])

  const items = useMemo(
    () => {
      const items = []
      if (isSpecificSearchEnabled) items.push(specificDatesToggle)
      if (isFlexibleSearchEnabled) items.push(flexibleDatesToggle)
      items.push(anytimeDatesToggle)
      return items
    },
    [isSpecificSearchEnabled, isFlexibleSearchEnabled, specificDatesToggle, anytimeDatesToggle],
  )
  const activeItemByOptionId = useMemo(
    () =>
      items.find((toggle) => {
        return toggle.id === dateSearchOptionId
      }),
    [items, dateSearchOptionId],
  )

  const activeItemByAnytimeDateSelected = anytimeDateSelected ?
    anytimeDatesToggle :
    specificDatesToggle
  const activeItem =
    isFlexibleSearchEnabled && dateSearchOptionId ?
      activeItemByOptionId :
      activeItemByAnytimeDateSelected

  const onItemClick = useCallback(
    (item: ButtonToggleItem, e: React.MouseEvent) => {
      // prevent the search form submit
      e.preventDefault()
      if (item === specificDatesToggle) {
        onSelectSpecific()
      } else if (item === flexibleDatesToggle) {
        onSelectFlexible?.()
      } else if (item === anytimeDatesToggle) {
        onSelectAnytime()
      }
    },
    [onSelectFlexible, onSelectSpecific, onSelectAnytime, specificDatesToggle, anytimeDatesToggle],
  )

  return (
    <ButtonGroupToggle
      className={className}
      items={items}
      activeItem={activeItem}
      onItemClick={onItemClick}
      data-testid="search-toggle"
    />
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((_state, ownProps) => ({
  isFlexibleSearchEnabled: !ownProps.disableFlexible && config.ENABLE_FLEXIBLE_SEARCH,
}))(SearchDateToggle)
