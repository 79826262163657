import React, { useContext } from 'react'
import CruisesSearchPage from '../SearchPage/CruiseSearchPage'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import HeroImage from 'content/components/HeroImage'
import LayoutContainer from 'components/Common/LayoutContainer'
import styled from 'styled-components'
import { rem } from 'polished'
import useCruiseSearchCount from 'hooks/Cruise/useCruiseSearchCount'
import RedirectWithStatus from 'components/Common/RedirectWithStatus'
import GeoContext from 'contexts/geoContext'
import { decodeFromUrlString } from './CruiseSeoPageUtil'

const StyledHeroImage = styled(HeroImage)`
  min-height: ${rem(180)};
`
interface Props extends RouteComponentProps<{ departureLocation?: string, destinationLocation?: string}> {
  locationToPlace: { [location: string]: App.Place }
  placeLookUp: { [id: string]: App.Place }
}

function CruiseLocationLandingPage(props: Props) {
  const pageType = props.match.path.includes('departures') ? 'departures' : 'destinations'
  const isDeparture = pageType === 'departures'
  const isDestination = pageType === 'destinations'
  const { locationToPlace, placeLookUp } = props
  const { departureLocation, destinationLocation } = props.match.params
  const location = decodeFromUrlString((isDeparture ? departureLocation : destinationLocation) as string)
  const place = locationToPlace[location.charAt(0).toUpperCase() + location.slice(1)]
  const imageId = placeLookUp[place?.id]?.imageId || '8d2cc64monviv73whp4'
  const title = isDeparture ? `Find Cruises from ${place?.name}` : `Find Cruises to ${place?.name}`
  const subtitle = `Escape with our luxury cruises deals in ${place?.name}`
  const filters : App.OfferListFilters = {
    departureNames: isDeparture ? [place?.name] : [],
    departureIds: isDeparture ? [place?.id] : [],
    destinationNames: isDestination ? [place?.name] : [],
    destinationIds: isDestination ? [place?.id] : [],
  }

  const [searchCount, fetchingSearchCount] = useCruiseSearchCount(filters)
  const { currentRegionCode } = useContext(GeoContext)

  if (!fetchingSearchCount && searchCount === 0) {
    return <RedirectWithStatus code={302} to={`/${currentRegionCode.toLowerCase()}/cruises`} withSearchAndHash={false} />
  }

  return (
    <>
      <StyledHeroImage
        id={imageId}
        brightness={-25}
        fit="center"
        gravity="center"
      >
        <LayoutContainer relative>
          <Heading variant="heading2" align="center" as="h1" colour="neutral-eight">{title}</Heading>
          <BodyText variant="large" align="center" as="h2" colour="neutral-eight">{subtitle}</BodyText>
        </LayoutContainer>
      </StyledHeroImage>
      <CruisesSearchPage initFilter={filters} />
    </>
  )
}

const mapStateToProps = (state: App.State) => ({
  placeLookUp: state.destination.places,
  locationToPlace: state.destination.locationToPlace,
})

export default connect(mapStateToProps)(CruiseLocationLandingPage)
