import { operations } from '@luxuryescapes/contract-support/dist/server'
import request from 'api/requestUtils'

export function getCancellationModalSettings(): Promise<App.CancellationModalSettings> {
  return request.get<App.ApiResponse<any>>('/api/support/v1/cancellation-modal-settings', { credentials: 'include' })
    .then(response => {
      return {
        id: response.result.id,
        retentionContactDetails: response.result.retentionContactDetails,
        rules: response.result.rules.map((rule: any) => ({
          offerTypes: rule.offerTypes,
          orderTotalValue: rule.orderTotalValue,
          regions: rule.regions,
          startTime: rule.startTime,
          endTime: rule.endTime,
          daysUntilCancellationExpiry: rule.daysUntilCancellationExpiry,
        })),
      }
    })
}

type APIGetBannerCampaignResult =
  operations['get-targeted-banners-for-user']['responses']['200']['content']['application/json']['result'];

export function getSiteBanner(): Promise<Array<App.SiteBanner> | null> {
  return request.get<App.ApiResponse<APIGetBannerCampaignResult>>('/api/support/v1/targeted-banners/user', { credentials: 'include' })
    .then(response => {
      if (!response.result) {
        return null
      }

      return response.result.map((banner) => ({
        id: banner.id,
        bannerTitle: banner.bannerTitle,
        bannerOverview: banner.bannerOverview,
        modalTitle: banner.modalTitle,
        modalDescription: banner.modalDescription,
        bannerColor: banner.settings.bannerColor as App.SiteBanner['bannerColor'],
        bannerIcon: banner.settings.bannerIcon as App.SiteBanner['bannerIcon'],
        endDate: banner.endDate,
      }))
    })
}
