import React, { useMemo, useCallback, ChangeEventHandler } from 'react'
import { replace } from 'connected-react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import {
  getCheckInDateFromURLSearchParams,
  getCheckOutDateFromURLSearchParams,
  getPriceLteFromURLSearchParams,
  propertyToDestinationSearch,
  setSearchParamValue,
} from 'lib/url/searchUrlUtils'
import useQueryParams from 'hooks/useQueryParams'
import { queryKeyPriceLte } from 'constants/url'
import RadioGroup from 'components/Luxkit/Radio/RadioGroup'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import { connect } from 'react-redux'

interface MappedStateProps {
  currentBudget: App.BusinessTraveller.BusinessBudget | undefined,
}

interface Props {
  search: string;
}

function BusinessTravellerWithinBudgetMapFilter(props: Props & MappedStateProps) {
  const { search, currentBudget } = props
  const dispatch = useAppDispatch()
  const queryParams = useQueryParams()

  const { checkInDate, checkOutDate, priceLte } = useMemo(() => {
    return {
      checkInDate: getCheckInDateFromURLSearchParams(queryParams),
      checkOutDate: getCheckOutDateFromURLSearchParams(queryParams),
      priceLte: getPriceLteFromURLSearchParams(queryParams),
    }
  }, [queryParams])

  const totalNights = checkOutDate?.diff(checkInDate, 'days')
  const totalBudget = useMemo(() => currentBudget?.budget && totalNights ? currentBudget.budget * totalNights : undefined, [currentBudget?.budget, totalNights])
  const tenOverBudget = useMemo(() => totalBudget ? Math.ceil(totalBudget * 1.1) : undefined, [totalBudget])
  const twentyOverBudget = useMemo(() => totalBudget ? Math.ceil(totalBudget * 1.2) : undefined, [totalBudget])

  const hasBudgets = totalBudget && tenOverBudget && twentyOverBudget

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    if (!hasBudgets) {
      return
    }

    let newPriceLte: number

    if (event.currentTarget.value === '0' && totalBudget) {
      newPriceLte = totalBudget
    } else if (event.currentTarget.value === '10') {
      newPriceLte = tenOverBudget
    } else if (event.currentTarget.value === '20') {
      newPriceLte = twentyOverBudget
    } else if (event.currentTarget.value === '10000') {
      // Setting price to 10000 for "Show all" option as removing the priceLte searchParam
      // will cause the useEffect logic to default priceLte to "10% above budget"
      newPriceLte = 10000
    } else {
      newPriceLte = tenOverBudget
    }

    dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(search, queryKeyPriceLte, newPriceLte)) }))
  }, [hasBudgets, totalBudget, dispatch, search, tenOverBudget, twentyOverBudget])

  return (
    <RadioGroup>
      <RadioInput
        name="businessTravellerWithinBudget"
        value={0}
        onChange={handleChange}
        checked={totalBudget === priceLte}
        disabled={!hasBudgets}
      >
        Within budget
      </RadioInput>
      <RadioInput
        name="businessTravellerWithinBudget"
        value={10}
        onChange={handleChange}
        checked={tenOverBudget === priceLte}
        disabled={!hasBudgets}
      >
        Up to 10% above budget
      </RadioInput>
      <RadioInput
        name="businessTravellerWithinBudget"
        value={20}
        onChange={handleChange}
        checked={twentyOverBudget === priceLte}
        disabled={!hasBudgets}
      >
        Up to 20% above budget
      </RadioInput>
      <RadioInput
        name="businessTravellerWithinBudget"
        value={10000}
        onChange={handleChange}
        checked={priceLte === 10000}
        disabled={!hasBudgets}
      >
        Show all
      </RadioInput>
    </RadioGroup>
  )
}

const mapStateToProps = (state: App.State) => {
  return {
    currentBudget: state.businessTraveller.businessMapBudget,
  }
}

export default connect(mapStateToProps)(BusinessTravellerWithinBudgetMapFilter)
