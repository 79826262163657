import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Group from 'components/utils/Group'
import DualSliderInput from 'components/Luxkit/Slider/DualSliderInput'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import { ButtonToggleItem } from 'components/Common/ButtonGroupToggle/ButtonGroupToggleItem'
import useQueryParams from 'hooks/useQueryParams'
import debounce from 'lodash/debounce'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import styled from 'styled-components'
import ButtonGroupToggle from 'components/Common/ButtonGroupToggle/ButtonGroupToggle'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'

const TabGroupToggle = styled(ButtonGroupToggle)`
  width: 100%;

  ${mediaQueryUp.tablet} {
    width: ${rem(340)};
  }

  &.drawer {
    width: ${rem(300)};
  }
`

const TabButtons: Array<ButtonToggleItem> = [
  { id: 'fullprice', label: 'Full price' },
  { id: 'night', label: 'Per night' },
]

export interface CruisePriceFilterProps {
  minPrice?: number;
  maxPrice?: number;
  price?: {
    min?: number;
    max?: number;
  };
}

export interface CruisePriceFilterChangeProps {
  priceBy?: string;
  minPrice?: number;
  maxPrice?: number;
}

interface Props {
  filters: CruisePriceFilterProps;
  onChange: (nextFilters: CruisePriceFilterChangeProps) => void;
  facets: Array<App.CruiseSearchFacet>;
  fetching?: boolean;
}

function CruisePriceFilter({
  filters,
  onChange,
  facets,
  fetching,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const queryParams = useQueryParams()
  const cruisePriceByNight = useCruisePriceByNight()
  const defaultTab = !queryParams?.get('priceBy') || !cruisePriceByNight ? TabButtons[0] : TabButtons[1]
  const [tab, setTab] = useState<ButtonToggleItem>(defaultTab)

  const filterPrice = useMemo(() => {
    const min = filters.price?.min! ?? filters.minPrice
    const max = filters.price?.max! ?? filters.maxPrice
    return { min, max }
  }, [filters])

  // set tab based on query params
  useEffect(() => {
    setTab(!queryParams?.get('priceBy') || !cruisePriceByNight ? TabButtons[0] : TabButtons[1])
  }, [queryParams, cruisePriceByNight])

  const onTabSelect = useCallback((nextTab: ButtonToggleItem) => {
    setTab(nextTab)
    onChange({ priceBy: nextTab.id, minPrice: undefined, maxPrice: undefined })
  }, [onChange])

  const priceFacet = useMemo(() => {
    const facet = facets.find(facet => facet.category === 'cruise_prices')
    const min = cruisePriceByNight ? facet?.minPerNight : facet?.min
    const max = cruisePriceByNight ? facet?.maxPerNight : facet?.max
    return {
      min: min ?? 0,
      max: Math.ceil(max ?? 0),
    }
  }, [cruisePriceByNight, facets])

  // we have separate actions for prices because we shouldn't apply the filter unless it is actually selected by the user
  const onDelayedMinPriceChange = useMemo(() => debounce((e) => {
    onChange({ minPrice: Number(e.target.value ?? 0) })
  }, 500), [onChange])

  const onDelayedMaxPriceChange = useMemo(() => debounce((e) => {
    onChange({ maxPrice: Number(e.target.value ?? 0) })
  }, 500), [onChange])

  return <Group direction="vertical" gap={16} ref={containerRef}>
    <Group direction="horizontal" horizontalAlign="center" data-testid="cruise-extra-filters-price-tab">
      <TabGroupToggle
        items={TabButtons}
        activeItem={tab}
        onItemClick={onTabSelect}
      />
    </Group>
    {fetching && <TextLoadingBox width="100%" />}
    {!fetching && <DualSliderInput
      data-testid="cruise-extra-filters-price-slider"
      key={`${priceFacet?.min}-${priceFacet?.max}-${filterPrice?.min}-${filterPrice?.max}`}
      min={Math.ceil(priceFacet?.min ?? 0)}
      max={Math.ceil(priceFacet?.max ?? 0)}
      defaultStartValue={filterPrice?.min}
      defaultEndValue={filterPrice?.max}
      onStartChange={onDelayedMinPriceChange}
      onEndChange={onDelayedMaxPriceChange}
      name="price"
      variant="label"
      format="currency"
      unit={cruisePriceByNight ? '/night' : '/person'}
    />}
  </Group>
}

export default CruisePriceFilter
