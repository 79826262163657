import Plural from 'components/utils/Formatters/Plural'
import config from 'constants/config'
import React from 'react'

interface Props {
  statusCredits: number
}

function FormatLuxLoyaltyStatusCredits({ statusCredits }: Props) {
  return <Plural
    singular={config.luxLoyalty.statusCreditsUnit}
    count={statusCredits}
    withCount
  />
}

export default FormatLuxLoyaltyStatusCredits
