import { createSelector } from 'reselect'
import { getIsLuxLoyaltyEnabled } from './luxLoyaltyFeatureToggles'

const getIsEnabledLuxLoyaltyPrioritySupportNumber = createSelector(
  (state: App.State) => getIsLuxLoyaltyEnabled(state),
  (state: App.State) => state.luxLoyalty.account,
  (isLuxLoyaltyEnabled, account) => {
    if (!isLuxLoyaltyEnabled) return false
    const accountBenefits = account?.data?.benefits
    return !!accountBenefits?.find((benefit) => benefit.code === 'phone_support')
  },
)

export default getIsEnabledLuxLoyaltyPrioritySupportNumber
