import cn from 'clsx'
import OfferSortControl from 'components/Common/OfferSortControl'
import TextButton from 'components/Luxkit/Button/TextButton'
import AssistChip from 'components/Luxkit/Chips/AssistChip'
import LineFilterAltIcon from 'components/Luxkit/Icons/line/LineFilterAltIcon'
import LineListUlIcon from 'components/Luxkit/Icons/line/LineListUlIcon'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import TextLink from 'components/Luxkit/TextLink'
import Heading from 'components/Luxkit/Typography/Heading'
import { EnabledFilters } from 'components/Search/type'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { OfferListSortOption } from 'constants/offerListFilters'
import { ANYWHERE_PLACE_ID } from 'constants/search'
import { EmptyArray, isNonEmptyArray } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import MobileOfferListFilters from './MobileOfferListFilters'
import OfferFilterToggles from './OfferFilterToggles'
import OfferListFilterSidebar from './OfferListFilterSidebar'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import { mediaQueryUp } from 'components/utils/breakpoint'
import useGlobalSearchURLHashFilter from 'hooks/GlobalSearch/useGlobalSearchURLHashFilter'
import Group from 'components/utils/Group'
import { EmptyObject } from 'lib/object/objectUtils'

const ResetLink = styled(TextLink)`
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  margin-left: ${rem(4)};
  white-space: nowrap;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
`

export const FilterPill = styled(FilterChip)`
  position: absolute;
  top: ${rem(72)};
  left: ${rem(16)};
  z-index: 1;

  ${mediaQueryUp.tablet} {
    top: ${rem(80)};
  }

  ${mediaQueryUp.desktop} {
    top: ${rem(92)};
  }
`

const FilterToggleControls = styled.div`
  align-items: center;
  display: grid;
  gap: ${rem(8)};
  grid-auto-flow: column;
`

const StyledCSSBreakpoint = styled(CSSBreakpoint)`
  display: flex;
  flex-direction: column;
  row-gap: ${rem(22)};
`

const StyledHeadingButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${rem(8)};
`

const StyledGroup = styled(Group)`
  &.scrollable {
    overflow-x: auto;
    overflow-y: hidden;
  }
`

export type OfferListFilterSubmit = {
  destinations?: Array<string> | string;
  holidayTypes?: Array<string>| string;
  propertyTypes?: Array<string> | string;
  amenities?: Array<string> | string;
  inclusions?: Array<string> | string;
  sortBy: App.OfferListSortByOptions;
  bedroomsGte?: number;
  bedroomsEq?: number;
  bathroomsGte?: number;
  bedsGte?: number;
  customerRatings?: number | undefined;
  offerTypes?: Array<string> | string;
  recent?: Array<string>;
}

interface MappedStateProps {
  hasPopularHolidayTypes: boolean,
  windowSearch: string,
}

interface Props {
  availableFilters: App.OfferListFilterOptions;
  existingFilters: App.OfferListFilters;
  mapViewPath?: string;
  listViewPath?: string;
  sortOptions?: Array<OfferListSortOption>;
  isFilterTogglesShown?: boolean;
  onFilterApply: (values: OfferListFilterSubmit) => void;
  onFilterReset: () => void;
  shouldIncludeAllSearchFilters?: boolean
  onSortOpen?: () => void;
  onSortSelect?: (sortOption: OfferListSortOption) => void;
  onViewSwitch?: () => void;
  showPropertyTypes?: boolean;
  hasPopularHolidayTypes?: boolean;
  heading?: React.ReactNode;
  shouldShowFilters?: boolean;
  onPopularHolidayTypeFilterClick?: () => void;
  enabledFilters: EnabledFilters
  windowSearch?: string
  isTours?: boolean
}

function OfferListFilters({
  sortOptions = EmptyArray,
  availableFilters,
  existingFilters = EmptyObject,
  mapViewPath,
  listViewPath,
  isFilterTogglesShown,
  onSortOpen = noop,
  onSortSelect = noop,
  onFilterReset,
  onViewSwitch = noop,
  onFilterApply: onFilterApplyProp,
  showPropertyTypes,
  hasPopularHolidayTypes,
  heading,
  shouldIncludeAllSearchFilters,
  shouldShowFilters = true,
  onPopularHolidayTypeFilterClick,
  enabledFilters,
  windowSearch,
  isTours = false,
}: Props & MappedStateProps) {
  const { isFilterPanelOpen, toggleFilterPanel } = useGlobalSearchURLHashFilter()

  const onFilterApply = useCallback((value: OfferListFilterSubmit) => {
    toggleFilterPanel?.()
    onFilterApplyProp(value)
    // https://github.com/w3c/csswg-drafts/issues/3497
    window.scrollTo({ top: 0, behavior: 'instant' as any })
  }, [onFilterApplyProp, toggleFilterPanel])

  const onCloseFilters = useCallback(() => {
    toggleFilterPanel?.()
  }, [toggleFilterPanel])

  const filterCount = useMemo(() => {
    // users can't specify "not" filters, these are usually defaults passed from parent
    // we should ignore them in the count
    const destinationFilterCount =
      existingFilters.destinationId
        ?.split(',')
        .filter((destination) => destination !== ANYWHERE_PLACE_ID)?.length ??
      0
    const additionalFiltersCount = shouldIncludeAllSearchFilters ?
      destinationFilterCount +
        (existingFilters.flexibleMonths?.split(',').length ?? 0) :
      0

    return (existingFilters.locations?.filter(loc => !loc.startsWith('!')).length ?? 0) +
      (enabledFilters.holidayTypes ? existingFilters.holidayTypes?.filter(loc => !loc.startsWith('!')).length ?? 0 : 0) +
      (existingFilters.inclusions?.filter(loc => !loc.startsWith('!')).length ?? 0) +
      (existingFilters.bedroomsGte && existingFilters.bedroomsGte > 0 ? 1 : 0) +
      (existingFilters.bedroomsEq && existingFilters.bedroomsEq > 0 ? 1 : 0) +
      (existingFilters.bathroomsGte && existingFilters.bathroomsGte > 0 ? 1 : 0) +
      (existingFilters.bedsGte && existingFilters.bedsGte > 0 ? 1 : 0) +
      (showPropertyTypes ? existingFilters.propertyTypes?.filter(loc => !loc.startsWith('!')).length ?? 0 : 0) +
      (existingFilters.amenities?.filter(loc => !loc.startsWith('!')).length ?? 0) + additionalFiltersCount
  }, [existingFilters.destinationId, existingFilters.flexibleMonths, existingFilters.locations, existingFilters.holidayTypes,
    existingFilters.inclusions, existingFilters.bedroomsGte, existingFilters.bedsGte, existingFilters.bedroomsEq, existingFilters.bathroomsGte,
    existingFilters.propertyTypes, existingFilters.amenities, shouldIncludeAllSearchFilters, enabledFilters.holidayTypes, showPropertyTypes])

  const hasSortOptions = isNonEmptyArray(sortOptions)

  const isFilterToggleRowScrollable = enabledFilters.holidayTypes && hasPopularHolidayTypes

  return (
    <>
      <MobileOfferListFilters
        heading={heading}
        mapViewPath={mapViewPath}
        listViewPath={listViewPath}
        onSortAndFilterClick={toggleFilterPanel}
        onViewSwitch={onViewSwitch}
        hasSortOptions={hasSortOptions}
        isTours={isTours}
      />
      {(isTours || mapViewPath) && <StyledCSSBreakpoint min="tablet">
        { heading &&
          <Group direction="horizontal" gap={8} verticalAlign="center" horizontalAlign="space-between">
            <Heading variant="heading2" align="start">
              {heading}
            </Heading>
            <StyledHeadingButtonsDiv>
              {hasSortOptions && <OfferSortControl
                sortOptions={sortOptions}
                onOpen={onSortOpen}
                onSelect={onSortSelect}
                roundedButton={isTours}
              />}
              {mapViewPath && <TextButton
                kind="secondary"
                variant="dark"
                to={mapViewPath}
                startIcon={<LineMapIcon />}
                onClick={onViewSwitch}
              >
                Map view
              </TextButton>}
              {listViewPath && <TextButton
                kind="secondary"
                variant="dark"
                to={listViewPath}
                startIcon={<LineListUlIcon />}
                onClick={onViewSwitch}
                data-testid="list-view-button"
              >
                List view
              </TextButton>}
            </StyledHeadingButtonsDiv>
          </Group>
        }
        <StyledGroup direction="horizontal" horizontalAlign="space-between" gap={12} className={cn({ scrollable: isFilterToggleRowScrollable })}>
          <FilterToggleControls>
            {shouldShowFilters && <AssistChip size="medium" variant="filled" startIcon={<LineFilterAltIcon />} onClick={toggleFilterPanel}>
              Filter offers
            </AssistChip>}
            {isFilterTogglesShown && <OfferFilterToggles
              availableFilters={availableFilters?.filters}
              existingFilters={existingFilters}
              onApply={onFilterApply}
              onSeeMore={toggleFilterPanel}
              showPropertyTypes={showPropertyTypes}
              onPopularHolidayTypeFilterClick={onPopularHolidayTypeFilterClick}
            />}
            { filterCount !== 0 &&
              <ResetLink
                aria-hidden={filterCount === 0}
                className={cn({ visible: filterCount > 0 })}
                size="medium"
                onClick={onFilterReset}
              >
                Reset all {filterCount}
              </ResetLink>
            }
          </FilterToggleControls>
          { !heading &&
            <StyledHeadingButtonsDiv>
              {hasSortOptions && <OfferSortControl
                sortOptions={sortOptions}
                onOpen={onSortOpen}
                onSelect={onSortSelect}
                roundedButton={isTours}
              />}
              {mapViewPath && <TextButton
                kind="secondary"
                variant="dark"
                to={mapViewPath}
                startIcon={<LineMapIcon />}
                onClick={onViewSwitch}
              >
                Map view
              </TextButton>}
              {listViewPath && <TextButton
                kind="secondary"
                variant="dark"
                to={listViewPath}
                startIcon={<LineListUlIcon />}
                onClick={onViewSwitch}
                data-testid="list-view-button"
              >
                List view
              </TextButton>}
            </StyledHeadingButtonsDiv>
        }
        </StyledGroup>
      </StyledCSSBreakpoint>}
      <OfferListFilterSidebar
        open={isFilterPanelOpen}
        onClose={onCloseFilters}
        filters={existingFilters}
        search={windowSearch ?? ''}
        enabledFilters={enabledFilters}
        fetching={false}
      />
      {listViewPath &&
        <CSSBreakpoint max="desktop">
          <FilterPill size="medium" startIcon={<LineFilterAltIcon />} onClick={toggleFilterPanel}>
            Filter
          </FilterPill>
        </CSSBreakpoint>
      }
    </>
  )
}

function mapStateToProps(state: App.State): MappedStateProps {
  return {
    hasPopularHolidayTypes: state.destination.hotelPopularHolidayTypes.length > 0,
    windowSearch: state.router.location.search,
  }
}

export default connect(mapStateToProps)(OfferListFilters)
