import { useEffect, useState } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { getSource } from 'selectors/offerSelectors'
import usePrevious from 'hooks/usePrevious'
import { SOURCE_TYPES } from 'constants/source'

/**
 * This hook monitors the transition from a metasearch source to a non-metasearch source.
 * If such a transition occurs, use the result of this hook to refetch CUG rates
 * instead of displaying B2C rates for bedbanks.
*/
const useMetasearchSourceTransition = () => {
  const [hasTransitionedFromMetasearch, setHasTransitionedFromMetasearch] = useState(false)
  const currentSource = useAppSelector(getSource)
  const previousSource = usePrevious(currentSource)

  useEffect(() => {
    const wasMetasearch = previousSource?.includes(SOURCE_TYPES.METASEARCH)
    const isNoLongerMetasearch = !currentSource?.includes(SOURCE_TYPES.METASEARCH)
    const isMetasearch = currentSource.includes(SOURCE_TYPES.METASEARCH)

    if (wasMetasearch && isNoLongerMetasearch) {
      setHasTransitionedFromMetasearch(true)
    } else if (isMetasearch) {
      setHasTransitionedFromMetasearch(false)
    }
  }, [previousSource, currentSource])

  return hasTransitionedFromMetasearch
}

export default useMetasearchSourceTransition
