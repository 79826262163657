import { createSelector } from 'reselect'
import { getShareModalConfig } from 'lib/customer/getShareModalConfig'
import config from 'constants/config'

export const getCruiseDealShareModalConfig = createSelector(
  (appState: App.State) => appState.system.customerPortalURL,
  (appState: App.State) => appState.auth.account.memberId,
  (appState: App.State) => appState.router.location.pathname,
  (appState: App.State) => appState.geo.currentRegionCode,
  (appState: App.State, currentCruiseDeal: App.Cruises.CruiseDeal) => currentCruiseDeal,
  (
    customerPortalURL,
    memberId,
    pathname,
    region,
    cruiseDeal,
  ) => {
    return cruiseDeal ?
      getShareModalConfig({
        customerPortalURL,
        memberId: memberId || '',
        offerId: cruiseDeal.id,
        offerLocation: cruiseDeal.name,
        pathname,
        region,
        type: 'page',
        utmCampaign: 'webshare',
        offerType: 'cruise',
      }) :
      undefined
  },
)

export const isStandaloneCruiseEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (regionCode) => config.CRUISES_STANDLONE_ENABLED && config.CRUISES_REGIONS?.split(',')?.includes(regionCode),
)
