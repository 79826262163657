import { createSelector } from 'reselect'
import { EmptyArray } from 'lib/array/arrayUtils'
import { getFlightItemsView } from 'checkout/selectors/view/flights'
import { FlightLoyaltyProductType } from 'lib/flights/flightUtils'

const getCheckoutFlightsPointsCalculationRequests = createSelector(
  (state: App.State) => getFlightItemsView(state),
  (flightsViews): Array<App.LuxLoyaltyDefaultPointsEarnCalculationRequest> => {
    if (!flightsViews.hasRequiredData) return EmptyArray

    return flightsViews.data.map((itemView) => ({
      price: itemView.totals.price,
      luxLoyaltyProductType: itemView.luxLoyaltyProductType as FlightLoyaltyProductType,
      itemId: itemView.item.itemId,
    }))
  },
)

export default getCheckoutFlightsPointsCalculationRequests
