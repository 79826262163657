import React, { useCallback, useContext, useMemo } from 'react'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import config from 'constants/config'
import FlightSearchAirportModal from './FlightAirportSelect/FlightSearchAirportModal'
import ModalContext from 'contexts/ModalContext'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import FlightSearchMenuMobile from './FlightSearchMenu/FlightSearchMenuMobile'
import { addDays } from '@luxuryescapes/lib-refunds/build/lib/date'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import FlightSearchTravellerSelectContent from './FlightTravellerSelect/FlightSearchTravellerSelectContent'
import { getAirportDisplayText } from 'lib/flights/flightUtils'
import useFlightDeal from 'hooks/Flights/useFlightDeal'
import useFlightDeals from 'hooks/Flights/useFlightDeals'
import FlightDealDuration from 'components/Flights/FlightMerchandising/FlightDealDuration/FlightDealDuration'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { FlightsFareTypes } from 'constants/flight'
import styled from 'styled-components'
import { rem } from 'polished'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineArrowsSwapVerticalIcon from 'components/Luxkit/Icons/line/LineArrowsSwapVerticalIcon'
import { mediaQueryDown } from 'components/utils/breakpoint'
import BusinessTravellerSelectLoadingSkeleton from 'businessTraveller/components/select-traveller/BusinessTravellerSelectLoadingSkeleton'
import BusinessTravellerMultiSelectFlightsMobile from 'businessTraveller/components/select-traveller/BusinessTravellerMultiSelectFlightsMobile'
import BusinessTravellerSelectFlightsMobile from 'businessTraveller/components/select-traveller/BusinessTravellerSelectFlightsMobile'
import BusinessTravellerAccountGuard from 'businessTraveller/components/BusinessTravellerAccountGuard'
import { useAppSelector } from 'hooks/reduxHooks'
import { getIsMultiTravellerEnabled } from 'businessTraveller/utils/getIsMultiTravellerEnabled'
import BusinessTravellerSelectDisabled from 'businessTraveller/components/select-traveller/BusinessTravellerSelectDisabled'
import FlightsVerticalDatePicker from './FlightsDatePicker/FlightsVerticalDatePicker'

const ButtonsContainer = styled(Group)`
  & > button:not(:last-child) {
    border-bottom: 0;
  }
`

const AirportsContainer = styled(Group)`
  position: relative;

  ${mediaQueryDown.mobile} {
    .airport-select{
      border-bottom: 0;
    }
  }

  & > button:not(.switch){
    border-bottom: 0;
  }
`

const ExchangeButtonContainer = styled.div`
  position: absolute;
  right: ${rem(16)};
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border-radius: 50%;
`

interface Props {
  /**
   * Sets the airports to a 'read only' mode
   * Used when defaulting airport selections such as on flight deal pages
   */
  readOnlyAirports?: boolean;
}

function FlightSearchWidgetMobileForm(props: Props) {
  const { readOnlyAirports } = props
  const state = useContext(FlightSearchWidgetStateContext)
  const isBusinessTravellerSelectEnabled = config.businessTraveller.currentAccountMode === 'business'

  const {
    fareType,
    flightDealId,
    flights,
  } = state

  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const flight = flights[0]
  const [flightDeal] = useFlightDeal(flightDealId)
  const [flightDeals] = useFlightDeals()

  const showModal = useContext(ModalContext)

  const onTravellerSelect = useCallback(() => {
    showModal(<FlightSearchMenuMobile
      title="Travellers"
      showTravellers
    >
      <FlightSearchTravellerSelectContent />
    </FlightSearchMenuMobile>)
  }, [showModal])

  const earliestBookingDate = useMemo(() => addDays(new Date(), 1), [])
  const finalBookingDate = useMemo(() => addDays(new Date(), 330), [])
  const flightDealsMemoized = useMemo(() => (flightDeals || []), [flightDeals])

  const featuredRanges = useMemo(() => {
    return flightDeal?.travelPeriods.map(period => ({
      start: new Date(period.departureDate),
      end: new Date(period.arrivalDate),
    }))
  }, [flightDeal])

  const onDateSelect = useCallback(async() => {
    const dates = await showModal<any>(<FlightSearchMenuMobile
      title="When do you want to go?"
      showDates
    >
      <Group direction="vertical" verticalAlign="space-between" gap={12} fullHeight>
        <VerticalSpacer gap={12}>
          {flightDeal && <FlightDealDuration flightDeal={flightDeal} />}
          <BodyText variant="small" colour="neutral-two">
            Based on recent return prices (per adult) found
          </BodyText>
        </VerticalSpacer>

        <FlightsVerticalDatePicker
          minDate={earliestBookingDate}
          maxDate={finalBookingDate}
          id={flight.id}
          required
          featuredRanges={featuredRanges}
          flightDeals={flightDealsMemoized}
        />
      </Group>
    </FlightSearchMenuMobile>,
    )

    // disable traveller selection for LEBT
    if (!isBusinessTravellerSelectEnabled && dates) {
      onTravellerSelect()
    }
  }, [showModal, earliestBookingDate, finalBookingDate, flight.id, featuredRanges, flightDeal, isBusinessTravellerSelectEnabled, onTravellerSelect, flightDealsMemoized])

  const onArrivalSelect = useCallback(async() => {
    const airport = await showModal<App.AirportLocation | undefined>(
      <FlightSearchAirportModal direction="arrival" id={flight.id} />,
    )
    if (airport) {
      onDateSelect()
    }
  }, [onDateSelect, showModal, flight.id])

  const onDepartureSelect = useCallback(async() => {
    const airport = await showModal<App.AirportLocation | undefined>(
      <FlightSearchAirportModal direction="departure" id={flight.id} />,
    )
    if (airport) {
      onArrivalSelect()
    }
  }, [onArrivalSelect, showModal, flight.id])

  const onSwapAirports = useCallback((flightId: string) => {
    const currentFlight = flights.find(flight => flight.id === flightId)!
    const departure = currentFlight.departureAirport
    dispatch({ type: FlightSearchWidgetActions.SET_DEPARTURE_AIRPORT, id: flightId, airport: currentFlight.arrivalAirport })
    dispatch({ type: FlightSearchWidgetActions.SET_ARRIVAL_AIRPORT, id: flightId, airport: departure })
  }, [flights, dispatch])

  const businessAccount = useAppSelector((state) => state.businessTraveller.currentBusinessAccount)
  const isLEBTMultiSelectEnabled = businessAccount && getIsMultiTravellerEnabled(businessAccount)

  return (
    <ButtonsContainer direction="vertical">
      <AirportsContainer direction="vertical">
        <SearchFormField
          value={getAirportDisplayText(flight.departureAirport)}
          label="From"
          required
          placeholder="Search by city or airport"
          onClick={readOnlyAirports ? undefined : onDepartureSelect}
          requiredErrorMessage="Please enter a departure"
          hasError={flight.showDepartureError}
          className="airport-select"
        />

        <SearchFormField
          value={getAirportDisplayText(flight.arrivalAirport)}
          label="To"
          required
          placeholder="Search by city or airport"
          onClick={readOnlyAirports ? undefined : onArrivalSelect}
          requiredErrorMessage="Please enter a destination"
          hasError={flight.showDestinationError}
          className="airport-select"
        />

        <ExchangeButtonContainer>
          <IconButton variant="default" kind="tertiary" size="medium" shape="circle" className="switch" onClick={() => onSwapAirports(flight.id)} disabled={readOnlyAirports}>
            <LineArrowsSwapVerticalIcon size="M" />
          </IconButton>
        </ExchangeButtonContainer>
      </AirportsContainer>

      <SearchFormFieldGroup>
        <SearchFormField
          label="Departing"
          placeholder="Select date"
          required
          requiredErrorMessage="Please select travel dates"
          value={flight.checkinDate?.format(DMY_CASUAL_FORMAT)}
          onClick={onDateSelect}
          hasError={flight.showTravelDatesError}
        />

        {fareType.value === FlightsFareTypes.RETURN && <SearchFormField
          label="Returning"
          placeholder="Select date"
          required
          requiredErrorMessage="Please select travel dates"
          value={flight.checkoutDate?.format(DMY_CASUAL_FORMAT)}
          onClick={onDateSelect}
          hasError={flight.showTravelDatesError}
        />}
      </SearchFormFieldGroup>

      <SearchFormFieldGroup>
        {isBusinessTravellerSelectEnabled && (
          <BusinessTravellerAccountGuard
            accountMode="business"
            employeeRoles={['BUSINESS_ADMIN', 'BUSINESS_MANAGER']}
            loading={<BusinessTravellerSelectLoadingSkeleton />}
            fallback={<BusinessTravellerSelectDisabled className="traveller-input" />}
          >
            {isLEBTMultiSelectEnabled && (
              <BusinessTravellerMultiSelectFlightsMobile/>
            )}
            {!isLEBTMultiSelectEnabled && (
              <BusinessTravellerSelectFlightsMobile/>
            )}
          </BusinessTravellerAccountGuard>
        )}
      </SearchFormFieldGroup>
    </ButtonsContainer>
  )
}

export default FlightSearchWidgetMobileForm
