import AdditionalGuestsPopup from 'components/Common/AdditionalGuestsPopup/AdditionalGuestsPopup'
import { getSumExtraGuestsSurcharge, hasIncludedGuestsExceeded } from 'lib/checkout/cartReservationUtils'
import { formatOccupantsShort } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import React, { useMemo } from 'react'
import useDefaultAirport from 'hooks/useDefaultAirport'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import TextButton from 'components/Luxkit/Button/TextButton'
import BusinessTravellerOfferCreditsTextLink from 'businessTraveller/components/offer-credits/BusinessTravellerOfferCreditsTextLink'
import config from 'constants/config'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { useOfferPriceWithMember } from 'hooks/Offers/useOfferPrice'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'
import { EmptyArray, unique } from 'lib/array/arrayUtils'
import OfferPriceDetailsRow from 'components/Common/PriceDetails/OfferPriceDetailsRow'
import { useDirectSearchPrices } from '../../../../hooks/Search/useSearchPrices'
import offerPageURL from 'lib/offer/offerPageURL'

interface MappedProps {
  canViewLuxPlusBenefits: boolean;
}

interface Props extends MappedProps {
  offer: App.Offer | App.OfferSummary;
  rooms?: Array<App.Occupants>;
  pkg: App.Package;
  pricing?: App.OfferAvailableRate;
  hasDates?: boolean;
  offerUrl?: string;
}

function SearchTileFlightOnlyPricing(props: Props) {
  const {
    offer,
    pkg,
    pricing,
    hasDates,
    rooms = EmptyArray,
    offerUrl,
    canViewLuxPlusBenefits,
  }: Props = props
  const directSearchPrices = useDirectSearchPrices({ filters: {}, offerId: offer.id })

  const defaultAirport = useDefaultAirport()
  const flightPrice = (offer.flightPrices[defaultAirport?.code ?? ''] || 0)

  const priceMultiplier = rooms?.length || 1
  const extraGuestSurcharges = useMemo(() => getSumExtraGuestsSurcharge(rooms || [], offer, pkg), [pkg, rooms, offer])

  const {
    propertyFees = directSearchPrices?.lowestPricePropertyFees ?? 0,
    price = directSearchPrices?.lowestPrice ?? 0,
    memberPrice = directSearchPrices?.lowestMemberPrice ?? 0,
    taxesAndFees = directSearchPrices?.lowestPriceTaxes ?? 0,
  } = pricing ?? pkg

  const showPriceDetails = taxesAndFees > 0 || propertyFees > 0 || config.agentHub.isEnabled
  let hotelPrice = price * priceMultiplier + extraGuestSurcharges + flightPrice
  const showMemberPrice = memberPrice > 0
  const rateOrPkgMemberPrice = pricing?.memberPriceWithSurcharge ?? pkg.memberPrice
  const hotelMemberPrice = showMemberPrice ? rateOrPkgMemberPrice * priceMultiplier + extraGuestSurcharges + flightPrice : 0
  let totalPrice = hotelPrice + propertyFees
  let totalMemberPrice = showMemberPrice ? hotelMemberPrice + propertyFees : 0
  const pkgValueOrMemberValue = canViewLuxPlusBenefits ? pkg.memberValue : pkg.value
  let totalValue = pkgValueOrMemberValue * priceMultiplier + extraGuestSurcharges + flightPrice
  let shouldShowValue = pkg.shouldDisplayValue && pkgValueOrMemberValue > 0 && totalValue >= (showMemberPrice ? totalMemberPrice : totalPrice)

  if (directSearchPrices) {
    // search does not know anything about flight prices, so we just add them in to our pricing
    hotelPrice = (directSearchPrices.lowestPrice ?? 0) + flightPrice
    totalPrice = hotelPrice // we already include property fees in the direct search prices
    totalMemberPrice = showMemberPrice ? (directSearchPrices.lowestMemberPrice ?? 0) + flightPrice : 0
    totalValue = (directSearchPrices.lowestPriceValue ?? 0) + flightPrice
    shouldShowValue = pkg.shouldDisplayValue && pkgValueOrMemberValue > 0 && totalValue >= (showMemberPrice ? totalMemberPrice : totalPrice)
  }

  const valueOffPercent = getValueOffPercent(totalValue, showMemberPrice ? hotelMemberPrice : hotelPrice)

  // https://aussiecommerce.atlassian.net/browse/CONLT-863
  // Only show discount pill for forced bundles
  const shouldShowRate = !offer.forceBundleId && offer.bundledWithFlightsOnly && valueOffPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE

  const showIncludedGuestsExceeded = useMemo(() => {
    if (!pkg.roomType || (pkg.roomRate?.extraGuestSurcharges?.length || 0) > 0) {
      return false
    }

    return rooms?.some(room => hasIncludedGuestsExceeded(room, pkg, offer))
  }, [pkg, rooms, offer])

  const {
    total: displayPrice,
    memberTotal: displayMemberPrice,
    saleUnit: displaySaleUnit,
  } = useOfferPriceWithMember({
    value: undefined,
    saleUnit: 'total',
    duration: pkg.duration,
    total: totalPrice,
    memberTotal: totalMemberPrice,
  })

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  return <div className={SearchTileFlightOnlyPricing.name}>
    <PriceRowPriceCaption>
      {hasDates && <>
        {pluralizeToString('night', pkg.duration)} + flights
        {!!rooms?.length && `, ${formatOccupantsShort(rooms)}`}
        {' '}from
      </>}
      {!hasDates && offer.tileDurationLabel && `${offer.tileDurationLabel.toLocaleLowerCase()} + flights from`}
      {!hasDates && !offer.tileDurationLabel && 'Hotel + flights from'}
    </PriceRowPriceCaption>
    <LuxPlusPriceStack
      price={displayPrice}
      memberPrice={displayMemberPrice}
      size="L"
      saleUnit={displaySaleUnit}
      testid="search-price"
      offerConfig={offer.luxPlus}
    />
    <PriceRowTaxesAndFees />
    {showIncludedGuestsExceeded && (
      <AdditionalGuestsPopup
        complex={false}
        description="+ Extra guest surcharge"
        modalContent={pkg.roomPolicyDescription}
      />
    )}
    {shouldShowValue && <PriceRowValueDiscountWithCaption
      data-testid="value-box"
      size="M"
      originalValue={totalValue}
      discountPercentage={shouldShowRate ? valueOffPercent : undefined}
    />}
    {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerOfferCreditsTextLink
      type="estimate"
      offer={offer}
      totalCost={totalPrice}
      numberOfNights={pkg.duration}
    />}
    <PriceRowAgentHubCommission
      size="L"
      productType={offer.productType}
      offerId={offer.id}
      vendorName={vendorName}
      offerLocations={offerLocations}
      packageId={pkg.id}
    />
    {showPriceDetails && <OfferPriceDetailsRow
      trigger="price-row"
      triggerSize="M"
      offer={offer}
      duration={pkg.duration}
      propertyFees={propertyFees}
      hotelPrice={hotelPrice}
      rooms={rooms}
      extraGuestMessage={showIncludedGuestsExceeded ? pkg.roomPolicyDescription : ''}
      dueAtPropertyMessage={offer.property?.taxesAndFeesContent ?? ''}
      hotelMemberPrice={hotelMemberPrice}
      showMemberPrice={showMemberPrice}
      context="search"
      cta={
        <TextButton kind="primary" fit="flex" to={offerUrl ?? offerPageURL(offer)}>
          View Offer
        </TextButton>
      }
    />}
  </div>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
})

export default connect(mapStateToProps)(SearchTileFlightOnlyPricing)
