import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getHasLuxLoyaltyAccount } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'

export function loyaltyContext(state: App.State) {
  return comluxgroup.createLoyalty_1_0_0({
    isMember: getHasLuxLoyaltyAccount(state),
    tier: state.luxLoyalty.account.data?.tier,
    membershipNumber: state.luxLoyalty.account.data?.accountNumber,
    points: state.luxLoyalty.account.data?.points,
    statusCredits: state.luxLoyalty.account.data?.statusCredits,
  })
}
