import styled, { css } from 'styled-components'
import { rem } from 'polished'
import React from 'react'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'

const thumbStyle = css`
  appearance: none;
  pointer-events: auto;
  height: ${rem(24)};
  width: ${rem(24)};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border-radius: ${props => props.theme.borderRadius.round};
  transition: background-color 0.2s;
`

const thumbFocusStyle = css`
  outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid ${props => props.theme.palette.neutral.default.five});
  outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
`

const thumbHoverActiveSTyle = css`
  background-color: ${props => props.theme.palette.neutral.default.seven};
`

const Range = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  appearance: none;
  background-color: transparent;
  pointer-events: none;
  margin: 0;

  &::-webkit-slider-thumb {
    ${thumbStyle}
  }

  &::-moz-range-thumb {
    ${thumbStyle}
  }

  &::-ms-thumb {
    ${thumbStyle}
  }

  &:focus {
    &::-webkit-slider-thumb {
      ${thumbFocusStyle}
    }

    &::-moz-range-thumb {
      ${thumbFocusStyle}
    }

    &::-ms-thumb {
      ${thumbFocusStyle}
    }
  }

  &:hover, &:active {
    &::-webkit-slider-thumb {
      ${thumbHoverActiveSTyle}
    }

    &::-moz-range-thumb {
      ${thumbHoverActiveSTyle}
    }

    &::-ms-thumb {
      ${thumbHoverActiveSTyle}
    }
  }
`

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {}

const RangeInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <Range {...props} ref={ref} type="range" />
})

RangeInput.displayName = 'RangeInput'

export default RangeInput
