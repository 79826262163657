import React from 'react'
import Modal from 'components/Luxkit/Modal/Modal'
import getLuxLoyaltyProgramDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyProgramDisplayName'
import BodyText from 'components/Luxkit/Typography/BodyText'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import { useAppSelector } from 'hooks/reduxHooks'
import getLuxLoyaltyDisplayTier from 'luxLoyalty/selectors/getLuxLoyaltyDisplayTier'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import styled from 'styled-components'
import { rem } from 'polished'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LineRoomUpgradeIcon from 'components/Luxkit/Icons/line/LineRoomUpgradeIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import useLuxLoyaltyUserBenefit from 'luxLoyalty/hooks/useLuxLoyaltyUserBenefit'
import FormatDateTime from 'components/utils/Formatters/FormatDateTime'
import LuxLoyaltyPointsListItem from './LuxLoyaltyPointsListItem'
import Group from 'components/utils/Group'

const BenefitDetailsContainer = styled.div`
  border: 1px solid ${props => props.theme.palette.neutral.default.seven};
`

const UpgradeTitle = styled(BodyTextBlock)`
  padding: ${rem(12)} ${rem(20)};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.seven};
`

const UpgradesAvailableContainer = styled(Group)`
  padding: ${rem(12)} ${rem(20)};
`

const ExpiryDetail = styled(BodyText)`
  padding: ${rem(12)} ${rem(20)};
  background-color: ${props => props.theme.palette.neutral.default.seven};
`

function LuxLoyaltyRoomUpgradeBenefitModal() {
  const displayTier = useAppSelector(getLuxLoyaltyDisplayTier)
  const loyaltyUpgradeBenefit = useLuxLoyaltyUserBenefit('hotel_upgrade')
  const luxLoyaltyAccount = useAppSelector(state => state.luxLoyalty.account)
  const statusReviewDate = luxLoyaltyAccount.data?.statusReviewDate

  return <Modal title={`${getLuxLoyaltyProgramDisplayName()} free room upgrades`}>
    <VerticalSpacer gap={24}>
      <BodyText variant="large">
        As a {getLuxLoyaltyProgramDisplayName()} {getLuxLoyaltyTierDisplayName(displayTier)} member you have free room{' '}
        upgrades available on select hotel offers to use in your membership year.
      </BodyText>
      <BenefitDetailsContainer>
        <UpgradeTitle variant="medium" weight="bold" startIcon={<LineRoomUpgradeIcon size="M" />} colour="neutral-two">
          Free room upgrades
        </UpgradeTitle>
        <UpgradesAvailableContainer direction="horizontal" horizontalAlign="space-between">
          <LuxLoyaltyPointsListItem tier={displayTier} size="M" colour="neutral-two">
            Available upgrades
          </LuxLoyaltyPointsListItem>
          <Heading variant="heading6" colour="neutral-two">
            {loyaltyUpgradeBenefit?.unlimited ? 'unlimited' : `x${loyaltyUpgradeBenefit?.remaining}`}
          </Heading>
        </UpgradesAvailableContainer>
        {/* Lowest ranked tier that has room upgrades */}
        {displayTier === 'gold' && !!statusReviewDate && <ExpiryDetail variant="small" colour="neutral-two">
          Benefit expires <b><FormatDateTime value={statusReviewDate} format="day-long-month-long-year" /></b>
        </ExpiryDetail>}
      </BenefitDetailsContainer>
    </VerticalSpacer>
  </Modal>
}

export default LuxLoyaltyRoomUpgradeBenefitModal
