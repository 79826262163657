export const UTM_CONFIGS = {
  basic: {
    storageKey: 'utmsV1',
    utms: [
      'campaign',
      'medium',
      'source',
    ],
  },
  advanced: {
    storageKey: 'utmClickID',
    utms: [
      'gclid',
      'fbclid',
      'irclickid',
    ],
  },
}
