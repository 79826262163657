import useCurrentSiteTakeover, { SiteTakeoverBackground, SiteTakeoverContext } from 'hooks/useCurrentSiteTakeover'
import React from 'react'

interface Props {
  background: SiteTakeoverBackground;
  className?: string;
  context?: SiteTakeoverContext;
}

function SiteTakeoverBackgroundImage(props: Props) {
  const { className, background, context } = props

  const currentTakeover = useCurrentSiteTakeover(context)

  if (!currentTakeover) {
    return null
  }

  const Background = currentTakeover.backgroundElement

  return <Background
    className={className}
    background={background}
  />
}

export default SiteTakeoverBackgroundImage
