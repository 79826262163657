import styled from 'styled-components'
import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { rem } from 'polished'
import FlightSearchAirportInputs from './FlightSearchAirportInputs'
import TextButton from 'components/Luxkit/Button/TextButton'
import { mediaQueryUp } from 'components/utils/breakpoint'
import FlightSearchDateSelect from './FlightSearchDateSelect'
import FlightSearchDateSelectSheet from './FlightSearchDateSelectSheet'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import { StandaloneFlightMenu } from './FlightSearchWidget'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import BusinessTravellerMultiSelectFlightsDesktop from 'businessTraveller/components/select-traveller/BusinessTravellerMultiSelectFlightsDesktop'
import BusinessTravellerSelectFlightsDesktop from 'businessTraveller/components/select-traveller/BusinessTravellerSelectFlightsDesktop'
import BusinessTravellerAccountGuard from 'businessTraveller/components/BusinessTravellerAccountGuard'
import BusinessTravellerSelectLoadingSkeleton from 'businessTraveller/components/select-traveller/BusinessTravellerSelectLoadingSkeleton'
import { useAppSelector } from 'hooks/reduxHooks'
import useFlightDeals from 'hooks/Flights/useFlightDeals'
import { getIsMultiTravellerEnabled } from 'businessTraveller/utils/getIsMultiTravellerEnabled'
import config from 'constants/config'
import BusinessTravellerSelectDisabled from 'businessTraveller/components/select-traveller/BusinessTravellerSelectDisabled'

const InputsWrapper = styled.form`
  position: relative;
  display: none;

  ${mediaQueryUp.tablet} {
    display: grid;
    gap: ${rem(8)};
    grid-template-areas:
      "location-input location-input location-input"
      "date-input traveller-input actions";
    grid-template-columns: 1fr ${rem(178)} ${rem(112)};
  }

  ${mediaQueryUp.desktop} {
    grid-template-areas: "location-input date-input traveller-input actions";
    grid-template-columns: 1fr minmax(${rem(230)}, ${rem(304)}) auto ${rem(112)};
  }

  > .location-input-area {
    grid-area: location-input;
  }
  > .date-input-area {
    grid-area: date-input;
  }
  > .actions-area {
    grid-area: actions;
  }
  > .traveller-input {
    grid-area: traveller-input;
    min-width: ${rem(178)};
  }
`

const SearchButton = styled(TextButton)`
  height: auto;
`

interface Props {
  readOnlyAirports?: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

function FlightSearchWidgetDesktopForm(props: Props) {
  const { onSubmit, readOnlyAirports } = props
  const arrivalAirportField = useRef<HTMLInputElement>(null)
  const departingDateField = useRef<HTMLButtonElement>(null)
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)
  const formRef = useRef<HTMLFormElement>(null)
  const [flightDeals] = useFlightDeals()

  const id = useMemo(() => {
    return state.flights[0].id
  }, [state.flights])

  const onDepartureChange = useCallback(() => {
    arrivalAirportField.current?.focus()
  }, [arrivalAirportField])

  const onArrivalChange = useCallback(() => {
    departingDateField.current?.click()
  }, [])

  const handleDateClose = useCallback(() => {
    dispatch({ type: FlightSearchWidgetActions.SET_ACTIVE_MENU })
  }, [dispatch])

  const isBusinessTraveller = config.businessTraveller.currentAccountMode === 'business'
  const businessAccount = useAppSelector((state) => state.businessTraveller.currentBusinessAccount)
  const isLEBTMultiSelectEnabled = businessAccount && getIsMultiTravellerEnabled(businessAccount)

  return (
    <InputsWrapper onSubmit={onSubmit} ref={formRef}>
      <FlightSearchAirportInputs
        id={id}
        className="location-input-area"
        readOnly={readOnlyAirports}
        onDepartureChange={onDepartureChange}
        onArrivalChange={onArrivalChange}
        arrivalFieldRef={arrivalAirportField}
      />

      <FlightSearchDateSelect
        id={id}
        className="date-input-area"
        departingFieldRef={departingDateField}
      />

      <FlightSearchDateSelectSheet
        id={id}
        anchorRef={formRef}
        isOpen={state.activeMenu === StandaloneFlightMenu.date}
        onClose={handleDateClose}
        flightDeals={flightDeals}
      />

      {isBusinessTraveller && (
        <BusinessTravellerAccountGuard
          accountMode="business"
          employeeRoles={['BUSINESS_ADMIN', 'BUSINESS_MANAGER']}
          loading={<BusinessTravellerSelectLoadingSkeleton />}
          fallback={<BusinessTravellerSelectDisabled className="traveller-input" />}
        >
          {isLEBTMultiSelectEnabled && (
            <BusinessTravellerMultiSelectFlightsDesktop
              className="traveller-input"
            />
          )}
          {!isLEBTMultiSelectEnabled && (
            <BusinessTravellerSelectFlightsDesktop
              className="traveller-input"
            />
          )}
        </BusinessTravellerAccountGuard>
      )}

      <SearchButton
        className="actions-area"
        kind="primary"
        size="large"
        data-testid="airportSearchButton"
        type="submit"
      >
        Search
      </SearchButton>
    </InputsWrapper>
  )
}

export default FlightSearchWidgetDesktopForm
