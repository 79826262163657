import React, { HTMLAttributes, PropsWithChildren, useReducer } from 'react'
import styled, { css, keyframes } from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { MODAL_TRANSITION_DURATION } from './modalConstants'
import { luxkitModalBehaviourStateReducer, MODAL_BEHAVIOUR_INITIAL_STATE } from './context/LuxkitModalBehaviourState'
import { LuxkitModalBehaviourDispatchContext, LuxkitModalBehaviourStateContext } from './context/LuxkitModalBehaviourContexts'
import { StyledModalHeader } from './ModalHeader'
import { StyledModalFooter } from './ModalFooter'
import { StyledModalBody } from './ModalBody'
import { StyledModalContent } from './ModalContent'

export const MODAL_HEADER_GRID_AREA: string = 'modal-header'
export const MODAL_BODY_GRID_AREA: string = 'modal-body'
export const MODAL_FOOTER_GRID_AREA: string = 'modal-footer'

const ModalAppearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const ModalDisappearAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`
export const DrawerSlideDownAnimation = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`
export const DrawerSlideUpAnimation = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`
const DrawerLeftSlideRightAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`
const DrawerLeftSlideLeftAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`

const DrawerRightSlideRightAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`

const DrawerRightSlideLeftAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }

`

const TopRoundedRadius = css`
  border-radius: ${props => props.theme.borderRadius.M} ${props => props.theme.borderRadius.M} 0 0;
`

const BottomRoundedRadius = css`
  border-radius: 0 0 ${props => props.theme.borderRadius.M} ${props => props.theme.borderRadius.M} ;
`

const Sheet = styled.div`
  position: relative;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  color: ${props => props.theme.palette.neutral.default.one};
  width: 100%;
  animation-iteration-count: 1;
  animation-duration: ${MODAL_TRANSITION_DURATION}ms;
  animation-fill-mode: forwards;

  &.has-header,
  &.has-header > form,
  &:has(${StyledModalHeader}),
  &:has(${StyledModalHeader}) > form {
    > ${StyledModalBody} > ${StyledModalContent}:first-child {
      padding-top: 0;
    }
  }

  &:not(.has-header),
  &:not(.has-header) > form,
  &:not(:has(${StyledModalHeader})),
  &:not(:has(${StyledModalHeader})) > form {
    > ${StyledModalBody} > ${StyledModalContent}:first-child {
      ${mediaQueryUp.tablet} {
        padding-top: ${rem(40)};
      }
    }
  }

  &.has-footer,
  &.has-footer > form,
  &:has(${StyledModalFooter}),
  &:has(${StyledModalFooter}) > form {
    > ${StyledModalBody} > ${StyledModalContent}:last-child {
      ${mediaQueryUp.tablet} {
        padding-bottom: ${rem(12)};
      }
    }
  }

  &.mode-default {
    box-shadow: ${props => props.theme.shadow.top.medium};
    max-height: calc(100% - ${rem(72)});

    &.transition-in {
      animation-name: ${DrawerSlideUpAnimation}
    }
    &.transition-out {
      animation-name: ${DrawerSlideDownAnimation}
    }

    &.height-auto, &.height-max {
      ${TopRoundedRadius}

      ${StyledModalHeader}, ${StyledModalBody}:first-child > :first-child {
        ${TopRoundedRadius}
      }
    }

    &.height-auto {
      height: auto;
    }

    &.height-max, &.height-full {
      height: 100%;
    }

    &.height-full {
      max-height: 100%;
      border-radius: 0;

      &.transition-in {
        animation-name: ${ModalAppearAnimation}
      }
      &.transition-out {
        animation-name: ${ModalDisappearAnimation}
      }
    }


    ${mediaQueryUp.tablet} {
      box-shadow: ${props => props.theme.shadow.bottom.medium};

      &.transition-in {
        animation-name: ${ModalAppearAnimation}
      }
      &.transition-out {
        animation-name: ${ModalDisappearAnimation}
      }

      /** This is the maximum size with a 20px padding around the edges */
      --safe-size: calc(100% - ${rem(40)});

      &.size-S {
        max-width: min(var(--safe-size), ${rem(560)});
      }

      &.size-L {
        max-width: min(var(--safe-size), ${rem(720)});
      }

      &.size-XL {
        max-width: min(var(--safe-size), ${rem(1200)});
      }

      &.size-max {
        max-width: var(--safe-size);

        &.height-auto, &.height-max, &.height-full {
          max-height: var(--safe-size);
        }
      }

      &.size-auto {
        width: auto;
        min-width: ${rem(560)};
        max-width: min(var(--safe-size), ${rem(1200)});
      }

      &.height-auto, &.height-max, &.height-full {
        max-height: 90%;
        border-radius: ${props => props.theme.borderRadius.M};

        ${StyledModalHeader}, ${StyledModalBody}:first-child > :first-child {
          ${TopRoundedRadius}
        }

        ${StyledModalFooter}, ${StyledModalBody}:last-child > :last-child {
          ${BottomRoundedRadius}
        }
      }

      &.height-on-tablet-auto {
        height: auto;
      }

      &.height-on-tablet-max, &.height-on-tablet-full {
        height: 100%;
      }
    }
  }

  &.mode-drawer, &.mode-drawer-starboard {
    box-shadow: ${props => props.theme.shadow.flat.small};
    height: 100%;

    &.transition-in {
      animation-name: ${DrawerSlideUpAnimation}
    }
    &.transition-out {
      animation-name: ${DrawerSlideDownAnimation}
    }

    &.mode-drawer-starboard {
      &.transition-in {
        animation-name: ${DrawerRightSlideLeftAnimation}
      }
      &.transition-out {
        animation-name: ${DrawerRightSlideRightAnimation}
      }
    }

    ${mediaQueryUp.tablet} {
      &.size-S {
        max-width: ${rem(400)};
      }

      &.size-L, &.size-XL, &.size-max {
        max-width: min(${rem(720)}, 80vw);
      }

      &.mode-drawer {
        &.transition-in {
          animation-name: ${DrawerLeftSlideRightAnimation}
        }
        &.transition-out {
          animation-name: ${DrawerLeftSlideLeftAnimation}
        }
      }
    }
  }

  &.mode-menu {
    box-shadow: ${props => props.theme.shadow.flat.small};
    height: 100%;

    &.transition-in {
      animation-name: ${DrawerLeftSlideRightAnimation}
    }
    &.transition-out {
      animation-name: ${DrawerLeftSlideLeftAnimation}
    }

    ${mediaQueryUp.tablet} {
      max-width: ${rem(400)};
    }
  }

  &, > form {
    display: grid;
    grid-template-areas:
      '${MODAL_HEADER_GRID_AREA}'
      '${MODAL_BODY_GRID_AREA}'
      '${MODAL_FOOTER_GRID_AREA}';
    grid-template-rows:
      [first]
      min-content
      1fr
      min-content
      [last];
    grid-template-columns: [first] 1fr [last];

    > ${StyledModalHeader} {
      grid-area: ${MODAL_HEADER_GRID_AREA};
    }

    > ${StyledModalBody} {
      grid-area: ${MODAL_BODY_GRID_AREA};
    }

    > ${StyledModalFooter} {
      grid-area: ${MODAL_FOOTER_GRID_AREA};
    }
  }

  > form {
    /* stretch the form over the grid */
    grid-column: first/last;
    grid-row: first/last;
  }
`

type Props = HTMLAttributes<HTMLDivElement> & {
  transition?: 'in' | 'out'
} & (
  {
    /**
     * Sets the mode of the modal.
     *
     * @example
     * `default` => drawer from bottom on mobile and modal card in the centre of the screen on tablet
     *
     * `drawer` => drawer from left side and with max width on tablet
     *
     * `drawer-starboard` => drawer from right side and with max width on tablet
     *
     * @default default
     */
    mode?: 'default'
    /**
     * Sets the width of the modal on when in `default` mode
     *
     * S - static 560px width
     * L - static 720px width
     * XL - static 1200px width
     * Max - Max height/width of screen
     * Auto - dynamic between S (560px) and XL (1200px) based on content
     *
     * @default large
     */
    size?: 'S' | 'L' | 'XL' | 'max' | 'auto'
    /**
     * Sets the height of the modal when in `default` mode
     *
     * @example
     * `auto` => hug the content vertically
     *
     * `max` => expand the container vertically while respecting the margins
     *
     * `full` => take the whole screen when on mobile
     *
     * @default auto
     */
    height?: 'auto' | 'max' | 'full'
    /**
     * @default fall back to `height`
     */
    tabletHeight?: 'auto' | 'max'

    direction?: undefined
  } | {
    mode?: 'drawer' | 'menu' | 'drawer-starboard'
    size?: undefined
    height?: undefined
    tabletHeight?: undefined
  }
)

const ModalSheet = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    children,
    transition,
    mode = 'default',
    size = 'small',
    height = 'auto',
    tabletHeight,
    style, // coming from react-modal and we don't want it!
    /** @todo define used props explicitly */
    ...rest // a11y props coming from react-modal
  } = props

  const [state, dispatch] = useReducer(luxkitModalBehaviourStateReducer, MODAL_BEHAVIOUR_INITIAL_STATE)

  return <Sheet
    {...rest}
    ref={ref}
    className={cn(
      `mode-${mode}`,
      `size-${size}`,
      `height-${height}`,
      `height-on-tablet-${tabletHeight ?? height}`,
      `transition-${transition}`,
      {
        'has-header': state.hasHeader,
        'has-footer': state.hasFooter,
      },
    )}
  >
    <LuxkitModalBehaviourStateContext.Provider value={state}>
      <LuxkitModalBehaviourDispatchContext.Provider value={dispatch}>
        {children}
      </LuxkitModalBehaviourDispatchContext.Provider>
    </LuxkitModalBehaviourStateContext.Provider>
  </Sheet>
})

ModalSheet.displayName = 'ModalSheet'

export default ModalSheet
