import { isValidInsuranceRegion } from 'lib/insurance/insuranceCountries'
import { createSelector } from 'reselect'
import { isSpoofed } from 'selectors/featuresSelectors'
import isBookingProtectionEnabled from './isBookingProtectionEnabled'
import { getInsuranceEnabledCheckoutItems } from '../view/accommodation'
import getDestinationCountriesFromCart from '../view/getDestinationCountriesFromCart'
import getDestinationCountriesFromExistingOrder from '../view/getDestinationCountriesFromExistingOrder'
import { getInsuranceDatesFromExistingOrder } from './insurance'
import getInsuranceDatesFromCart from './getInsuranceDatesFromCart'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'
import { TRAVEL_PROTECTION_VALID_COUNTRIES } from 'constants/config/region'
import moment from 'moment'

const validateInsuranceDates = (startDate:string, regionCode: string): boolean => {
  if (TRAVEL_PROTECTION_VALID_COUNTRIES.includes(regionCode)) return true
  return moment(startDate).diff(moment(), 'days') <= 365
}
/**
 * Get the insurance parameters based on the checkout cart items
 *
 * @remarks
 * - Currently only supports "LE Hotels" (instant booking), "Bedbank" and "TourV2"
 */
const isInsuranceSupportedForCartItems = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => getInsuranceEnabledCheckoutItems(state),
  (state: App.State) => getInsuranceDatesFromCart(state),
  (state: App.State) => getDestinationCountriesFromCart(state),
  (regionCode, postPurchase, items, dateRange, destinationCountries): boolean => {
    return !!(
      items.length &&
      dateRange &&
      destinationCountries.length &&
      (!postPurchase || postPurchase === 'select-date') &&
      validateInsuranceDates(dateRange.startDate, regionCode)
    )
  },
)

const isInsuranceSupportedForExistingOrder = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => getInsuranceDatesFromExistingOrder(state),
  (state: App.State) => getDestinationCountriesFromExistingOrder(state),
  (regionCode, postPurchase, insuranceDates, destCountries): boolean => {
    return !!(
      postPurchase == 'insurance' &&
      insuranceDates &&
      destCountries.length &&
      validateInsuranceDates(insuranceDates.startDate, regionCode)
    )
  },
)

const isInsuranceSupported = createSelector(
  (state: App.State) => isInsuranceSupportedForCartItems(state),
  (state: App.State) => isInsuranceSupportedForExistingOrder(state),
  (state: App.State) => isBookingProtectionEnabled(state),
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => isSpoofed(state),
  (isInsuranceAvailableForCartItems,
    isInsuranceAvailableForExistingOrder,
    isBookingProtectionEnabled,
    currentRegionCode,
    isSpoofed): boolean => {
    if (!isValidInsuranceRegion(currentRegionCode)) return false
    if (!isInsuranceAvailableForCartItems && !isInsuranceAvailableForExistingOrder) return false
    if (isBookingProtectionEnabled && !isSpoofed) return false
    return true
  },
)

export default isInsuranceSupported
