import React from 'react'
import { connect } from 'react-redux'
import BusinessTravellerWithinBudgetDropdown from './BusinessTravellerWithinBudgetDropdown'

interface MappedStateProps {
  currentBudget: App.BusinessTraveller.BusinessBudget | undefined,
}

interface Props {
  label: string
  filters: App.OfferListFilters
  search: string
}

function BusinessTravellerMapBudgetChipFilter(props: Props & MappedStateProps) {
  const { label, filters, search, currentBudget } = props

  return <BusinessTravellerWithinBudgetDropdown
    label={label}
    filters={filters}
    search={search}
    currentBudget={currentBudget}
  />
}

const mapStateToProps = (state: App.State) => {
  return {
    currentBudget: state.businessTraveller.businessMapBudget,
  }
}

export default connect(mapStateToProps)(BusinessTravellerMapBudgetChipFilter)
