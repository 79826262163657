import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_TOUR_V2,
  PRODUCT_TYPE_CRUISE,
  PRODUCT_TYPE_CRUISE_GROUP_1,
  PRODUCT_TYPE_CRUISE_GROUP_2,
  PRODUCT_TYPE_CRUISE_GROUP_3,
  OFFER_TYPE_HOTEL,
  PRODUCT_TYPE_FLIGHT,
  PRODUCT_TYPE_INSURANCE,
  PRODUCT_TYPE_LUX_PLUS,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_EXPERIENCE_ADDON,
  OFFER_TYPE_EXPERIENCE_ALWAYS_ON,
  OFFER_TYPE_EXPERIENCE_CHANNEL_MANAGER,
  OFFER_TYPE_EXPERIENCE_FLASH,
  OFFER_TYPE_EXPERIENCE_TRANSFER,
  OFFER_TYPE_VILLA,
  PRODUCT_TYPE_CAR_HIRE,
  PRODUCT_TYPE_CORPORATE,
  PRODUCT_TYPE_CRUISE_EXCLUSIVE,
  PRODUCT_TYPE_CRUISE_FLASH,
  PRODUCT_TYPE_CRUISE_TACTICAL,
  PRODUCT_TYPE_CRUISE_TOUR,
  PRODUCT_TYPE_EXPERIENCE,
  PRODUCT_TYPE_LIMITED_TIME_SPECIAL,
  PRODUCT_TYPE_TOUR,
  PRODUCT_TYPE_TOUR_V2,
  PRODUCT_TYPE_TOUR_V2_CONNECTION,
  PRODUCT_TYPE_TOUR_V2_PARTNER,
  PRODUCT_TYPE_ULTRALUX,
  PRODUCT_TYPE_TOUR_ULTRA_LUX,
  PRODUCT_TYPE_TOUR_DELUXE,
  PRODUCT_TYPE_TOUR_PREMIUM,
  PRODUCT_TYPE_TOUR_SIGNATURE_SERIES,
  PRODUCT_TYPE_BOOKING_PROTECTION,
} from 'constants/offer'

const luxLoyaltyProductTypeMap: Record<App.ProductType | App.OfferType, App.LuxLoyaltyProductType | undefined> = {
  [OFFER_TYPE_HOTEL]: 'hotel_flash',
  [OFFER_TYPE_ALWAYS_ON]: 'hotel_lpc',
  [OFFER_TYPE_BED_BANK]: 'hotel_lpp_eps',
  [PRODUCT_TYPE_CRUISE]: undefined,
  [PRODUCT_TYPE_CRUISE_GROUP_1]: 'cruise_group_1',
  [PRODUCT_TYPE_CRUISE_GROUP_2]: 'cruise_group_2',
  [PRODUCT_TYPE_CRUISE_GROUP_3]: 'cruise_group_3',
  [PRODUCT_TYPE_EXPERIENCE]: 'experience',
  [PRODUCT_TYPE_LUX_PLUS]: 'lux_plus',
  /**
   * @dev use getFlightLoyaltyProductType
   */
  [PRODUCT_TYPE_FLIGHT]: undefined,
  /**
   * @dev use getLuxLoyaltyInsuranceProductType
   **/
  [PRODUCT_TYPE_INSURANCE]: undefined,
  [PRODUCT_TYPE_BOOKING_PROTECTION]: 'cfmr',
  [PRODUCT_TYPE_ULTRALUX]: undefined,
  [PRODUCT_TYPE_TOUR_ULTRA_LUX]: 'le_tour',
  [PRODUCT_TYPE_TOUR]: undefined,
  [PRODUCT_TYPE_TOUR_V2]: 'le_tour',
  [PRODUCT_TYPE_TOUR_V2_CONNECTION]: 'tour_bank',
  [PRODUCT_TYPE_TOUR_V2_PARTNER]: 'trusted_partner_tour',
  [PRODUCT_TYPE_TOUR_DELUXE]: 'le_tour',
  [PRODUCT_TYPE_TOUR_PREMIUM]: 'le_tour',
  [PRODUCT_TYPE_TOUR_SIGNATURE_SERIES]: 'le_tour',
  [OFFER_TYPE_LAST_MINUTE]: undefined,
  [OFFER_TYPE_BUNDLE_AND_SAVE]: undefined,
  [OFFER_TYPE_VILLA]: 'rental',
  [PRODUCT_TYPE_CAR_HIRE]: 'car_hire',
  [PRODUCT_TYPE_CRUISE_TOUR]: undefined,
  [PRODUCT_TYPE_LIMITED_TIME_SPECIAL]: 'hotel_lpp_eps',
  [PRODUCT_TYPE_CORPORATE]: undefined,
  /**
   * Cruises are mapped from their loyaltyProductType field
   */
  [PRODUCT_TYPE_CRUISE_FLASH]: undefined,
  [PRODUCT_TYPE_CRUISE_TACTICAL]: undefined,
  [PRODUCT_TYPE_CRUISE_EXCLUSIVE]: undefined,
  [OFFER_TYPE_EXPERIENCE_CHANNEL_MANAGER]: 'experience',
  [OFFER_TYPE_EXPERIENCE_ALWAYS_ON]: 'experience',
  [OFFER_TYPE_EXPERIENCE_FLASH]: 'experience',
  [OFFER_TYPE_EXPERIENCE_ADDON]: 'experience',
  [OFFER_TYPE_EXPERIENCE_TRANSFER]: 'experience',
}

function getLuxLoyaltyProductType(
  offer: App.AnyOffer | App.CarHireOffer | undefined,
): App.LuxLoyaltyProductType | undefined {
  if (!offer) return

  switch (offer.type) {
    case 'hotel':
    case 'bedbank_hotel':
    case 'tactical_ao_hotel':
    case 'last_minute_hotel':
    case 'experience':
    case 'partner_tour':
      if (offer.parentType === OFFER_TYPE_TOUR_V2 && offer.productType === PRODUCT_TYPE_CRUISE_TOUR) {
        return getCruiseTourProductTypeByCruiseLine(offer)
      }

      return luxLoyaltyProductTypeMap[offer.type]
    case 'cruise':
      return luxLoyaltyProductTypeMap[offer.loyaltyProductType]
    default:
      return luxLoyaltyProductTypeMap[offer.productType]
  }
}

// This logic is repeated in the cruise API, we need to keep it in sync
// https://github.com/lux-group/svc-cruise/blob/main/src/context/v1/cruise-offer/get-loyalty-product-type-context.ts
// TODO: use this logic from cruise API - https://aussiecommerce.atlassian.net/browse/CRUZ-2958
function getCruiseTourProductTypeByCruiseLine(
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary,
): App.LuxLoyaltyProductType {
  const group1 = 'cruise_group_1'
  const group2 = 'cruise_group_2'
  const group3 = 'cruise_group_3'

  const cruiseTierByCruiseLine: Record<string, App.LuxLoyaltyProductType> = {
    'Royal Caribbean International': group1,
    'Disney Cruise Line': group1,
    'Carnival Cruise Line': group1,
    'Princess Cruises': group1,
    Azamara: group2,
    'Celebrity Cruises': group2,
    'P&O Cruises': group2,
    'Silversea Cruises': group2,
    Cunard: group2,
    'Norwegian Cruise Line': group2,
    'Holland America Line': group2,
  }

  return cruiseTierByCruiseLine[offer.operatedBy] || group3
}

export default getLuxLoyaltyProductType
