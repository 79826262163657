import { excludeNullOrUndefined } from 'checkout/utils'
import { OFFER_TYPE_BED_BANK, OFFER_TYPE_TOUR } from 'constants/offer'
import { isCruiseOffer, isTourV2Offer, isCruiseV1Offer } from 'lib/offer/offerTypes'

export function getLocationString(
  offer: App.Offer | App.OfferSummary | App.BedbankOffer | App.Tours.TourV2Offer | App.CruiseOffer,
  variationId?: string,
): string {
  if (!offer) return ''

  if (isTourV2Offer(offer)) {
    const variations = Object.values(offer.variations)
    if (variations.length === 0) return ''

    const variation = (!!variationId && offer?.variations[variationId]) || variations[0]

    return variation.startLocation === variation.endLocation ?
      `Starts and ends in ${variation.startLocation}` :
      `Starts in ${variation.startLocation}, Ends in ${variation.endLocation}`
  }

  if (offer.type === OFFER_TYPE_BED_BANK) {
    return offer.property.location
  }

  if (isCruiseOffer(offer) || isCruiseV1Offer(offer)) {
    const departureCity = isCruiseOffer(offer) ? offer.departurePort.split(',')[0] : offer.startLocation?.split(',')?.[0]
    const destinationCity = isCruiseOffer(offer) ? offer.returnPort.split(',')[0] : offer.endLocation?.split(',')?.[0]
    return departureCity === destinationCity ?
      `Starts and ends in ${departureCity}` :
      `Starts in ${departureCity}, Ends in ${destinationCity}`
  }

  if (offer.type === OFFER_TYPE_TOUR) {
    return offer.location
  }

  else {
    return [offer.locationHeading, offer.locationSubheading].filter(excludeNullOrUndefined).join(', ')
  }
}
