import { excludeNullOrUndefined } from 'checkout/utils'
import { NO_BOOKING_PROTECTION_QUOTE_ID } from 'constants/bookingProtection'
import { sum } from 'lib/array/arrayUtils'
import {
  isBedbankItem,
  isBookingProtectionItem,
  isExperienceItem,
  isFlightItem,
  isInstantBookingLEHotelItem,
} from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import { selectShouldUseBookingProtectionMemberPrice } from './luxPlusSubscription'

function emptyItemView(item: App.Checkout.BookingProtectionItem): App.Checkout.BookingProtectionItemView {
  return {
    item,
    luxLoyaltyProductType: 'insurance',
    totals: {
      price: 0,
      memberPrice: 0,
      value: 0,
      surcharge: 0,
      taxesAndFees: 0,
      mobileAppDiscount: undefined,
      memberValue: 0,
      extraGuestSurcharge: 0,
    },
  }
}

export const getBookingProtectionEnabledCheckoutItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InstantBookingLEHotelItem | App.Checkout.BedbankHotelItem | App.Checkout.FlightItem | App.Checkout.ExperienceItem> => {
    return items.filter((item) => {
      return isInstantBookingLEHotelItem(item) ||
        isBedbankItem(item) ||
        isFlightItem(item) ||
        isExperienceItem(item)
    })
  },
)

/**
 * Returns all the booking protection cart items that would actually purchase something
 * i.e. no "no booking" id
 */
export const getBookingProtectionItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.BookingProtectionItem> => {
    // double filter is due to lacklust typescript narrowing
    return items.filter(isBookingProtectionItem).filter(item => item.quoteId !== NO_BOOKING_PROTECTION_QUOTE_ID)
  },
)

/**
 * Returns **all** the booking protection cart items, "no booking" id incldued
 */
export const getAllBookingProtectionItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.BookingProtectionItem> => {
    return items.filter(isBookingProtectionItem)
  },
)

export const isBookingProtectionSelected = createSelector(
  (state: App.State) => getBookingProtectionItems(state),
  items => items.length > 0,
)

export const getBookingProtectionItemsView = createSelector(
  (state: App.State) => getBookingProtectionItems(state),
  (state: App.State) => state.bookingProtection.quotes,
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  (state: App.State) => state.bookingProtection.fetchingQuotes,
  (state: App.State) => selectShouldUseBookingProtectionMemberPrice(state),
  (
    bookingProtectionItems,
    quotes,
    existingBookingProtectItems,
    fetchingQuotes,
    checkoutWithDiscountedBookingProtection,
  ): App.WithDataStatus<Array<App.Checkout.BookingProtectionItemView>> => {
    let hasRequiredData = true

    if (!quotes) {
      return {
        hasRequiredData,
        data: [],
      }
    }

    const itemViews = bookingProtectionItems.map((item): App.Checkout.BookingProtectionItemView | undefined => {
      let quote: App.BookingProtectionQuote | undefined
      if (item.amended) {
        const quoteKey = Object.keys(quotes).find((key: string) => key.includes(item.quoteId))
        quote = quoteKey ? quotes[quoteKey] : undefined
      } else {
        quote = Object.values(quotes).find((quote: App.BookingProtectionQuote) => quote.quoteId === item.quoteId)
      }

      if (!quote) {
        hasRequiredData = !Object.values(fetchingQuotes).length
        return emptyItemView(item)
      }

      let oldPublicQuotePrice = 0
      let oldLuxPlusQuotePrice = 0
      if (item.amended && existingBookingProtectItems) {
        const completedItem = existingBookingProtectItems.find(bookingProtectItem => bookingProtectItem.status === 'completed')
        oldPublicQuotePrice = completedItem?.publicPrice || 0
        oldLuxPlusQuotePrice = completedItem?.memberPrice || 0
      }

      const quotePublicPriceDifference = Math.round(quote.price - oldPublicQuotePrice + Number.EPSILON) * 100 / 100
      const quotePrice = quote.mobileAppOnlyPrice > 0 ? quote.mobileAppOnlyPrice : quote.price
      const quotePriceDifference = Math.round((quotePrice - oldPublicQuotePrice + Number.EPSILON) * 100) / 100
      const quoteLuxPlusPrice = quote.luxPlusPrice ?? 0
      const quoteLuxPlusPriceDifference = Math.round((quoteLuxPlusPrice - oldLuxPlusQuotePrice + Number.EPSILON) * 100) / 100
      // For now, we don't want to refund when negative difference and so we don't want to show the item
      if (quotePriceDifference < 0 ||
          (checkoutWithDiscountedBookingProtection && quoteLuxPlusPriceDifference < 0)
      ) {
        hasRequiredData = true
        return undefined
      }
      const mobileAppOnlyDiscount = quote.mobileAppOnlyPrice > 0 ? quotePublicPriceDifference - quotePrice : 0

      return {
        ...emptyItemView(item),
        totals: {
          price: quotePriceDifference,
          ...(mobileAppOnlyDiscount > 0 && {
            mobileAppDiscount: {
              amount: mobileAppOnlyDiscount,
              percentage: 20,
            },
          }),
          memberPrice: quoteLuxPlusPriceDifference,
          taxesAndFees: 0,
          value: quotePriceDifference,
          memberValue: 0,
          surcharge: 0,
          extraGuestSurcharge: 0,
        },
      }
    }).filter(excludeNullOrUndefined)

    return {
      hasRequiredData,
      data: itemViews,
    }
  },
)

function getBookingProtectionBreakdownItem(item: App.Checkout.BookingProtectionItemView): App.Checkout.BookingProtectionItemBreakdownView {
  return {
    itemId: item.item.itemId,
    title: 'Cancellation Protection',
    price: item.totals.price,
    memberPrice: item.totals.memberPrice,
    itemType: 'bookingProtection',
    additionalInfo: [],
    additionalElements: [],
    taxesAndFees: item.totals.taxesAndFees,
  }
}

export const getBookingProtectionBreakdownView = createSelector(
  (state: App.State) => getBookingProtectionItemsView(state),
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  (viewWithStatus, existingItem): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) { return { hasRequiredData: viewWithStatus.hasRequiredData, data: [] } }

    const breakdownItems = viewWithStatus.data.map(getBookingProtectionBreakdownItem)

    const price = sum(breakdownItems, item => item.price || 0)
    const memberPrice = sum(breakdownItems, item => item.memberPrice || 0)

    const itemsWithMobileAppDiscount = viewWithStatus.data.filter(item => item.totals.mobileAppDiscount?.amount)
    const mobileAppDiscountAmount = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, item => item.totals.mobileAppDiscount?.amount ?? 0) : 0
    const mobileAppDiscountPercentage = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, item => item.totals.mobileAppDiscount?.percentage ?? 0) / itemsWithMobileAppDiscount.length : 0

    const breakdownView: App.Checkout.PriceBreakdownView = {
      title: 'Cancellation Protection',
      price: price + mobileAppDiscountAmount,
      memberPrice,
      additionalInfoText: [],
      items: [],
      alwaysShowItemPrice: !!existingItem || !!price,
      breakdownItemType: 'bookingProtection' as const,
      ...(mobileAppDiscountAmount > 0 && {
        mobileAppDiscount: {
          amount: mobileAppDiscountAmount,
          percentage: mobileAppDiscountPercentage,
        },
      }),
    }

    return {
      hasRequiredData: true,
      data: [breakdownView],
    }
  },
)
