import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import React, { MouseEventHandler, useCallback, useContext } from 'react'
import TourDiscountLabel from './TourDiscountLabel'
import TourV2LuxPlusUrgencyTag from './TourV2LuxPlusUrgencyTag'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'
import { useAppSelector } from 'hooks/reduxHooks'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import config from 'constants/config'
import Typography from 'components/Luxkit/Typography/Typography'
import TourPromoSpendEarnLabel from 'components/Tours/TourPromoSpendEarnLabel'
import useCurrentSiteTakeover from 'hooks/useCurrentSiteTakeover'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import TourAsiaCampaignModal from 'components/Tours/Campaign/TourAsiaCampaignModal'
import ModalContext from 'contexts/ModalContext'
import Clickable from 'components/Common/Clickable'

type UrgencyLabelVariant = React.ComponentProps<typeof UrgencyLabel>['variant']
const urgencyLabelTypeToVariant: Utils.PartialRecord<App.UrgencyLabelType, UrgencyLabelVariant> = {
  left: 'critical2',
}

interface Props {
  label: App.OfferUrgencyLabel;
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary;
}

function TourV2UrgencyLabel(props: Props) {
  const { label, offer } = props

  const showModal = useContext(ModalContext)
  const showPromoTag = useAppSelector(state => config.TOUR_PROMO_CODE_ENABLED && isEnabledForFeature(config.TOUR_PROMO_CODE_REGIONS, state.geo.currentRegionCode))
  const showPromoSpendEarnTag = useAppSelector(state => config.TOUR_SPEND_EARN_BANNER && isEnabledForFeature(config.TOUR_SPEND_EARN_BANNER_REGIONS, state.geo.currentRegionCode))
  const currentSiteTakeover = useCurrentSiteTakeover('tours')
  const showAsiaCampaignModal = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    showModal(<TourAsiaCampaignModal />)
  }, [showModal])

  switch (label.type) {
    case 'agenthub_exclusive':
      return <PromotionLabel variant="default">Agent exclusive</PromotionLabel>
    case 'sale':
      return <TourDiscountLabel key="sale" />
    case 'departuresale':
      return <TourDiscountLabel saleOnDepartures />
    case 'lux_plus_member_price':
      return <TourV2LuxPlusUrgencyTag offer={offer} variant="memberPrice" />
    case 'lux_plus_early_access':
      return <TourV2LuxPlusUrgencyTag offer={offer} variant="earlyAccess" />
    case 'lux_plus_general_release':
      if (!label.end) {
        return null
      }

      return <OfferUrgencyCountdownLabel
        endDate={label.end}
        isLuxPlusEarlyAccess
      />
    case 'promo_code':
      return showPromoTag ? <UrgencyLabel variant="critical2">
        <Typography format="propercase">
          {label.message}
        </Typography>
      </UrgencyLabel> : null
    case 'takeover_promo_code':
      if (currentSiteTakeover?.id === 'tourbestasia') {
        return <Clickable onClick={showAsiaCampaignModal}>
          <PromotionLabel {...{ endIcon: <LineInfoCircleIcon /> }} variant="default">Save more with code: TOURASIA</PromotionLabel>
        </Clickable>
      }
      return null
    case 'promo_spend_earn':
      return showPromoSpendEarnTag ? <TourPromoSpendEarnLabel /> : null
    default:
      const variant = urgencyLabelTypeToVariant[label.type] ?? 'positive'
      return <UrgencyLabel variant={variant}>
        {label.message}
      </UrgencyLabel>
  }
}

export default TourV2UrgencyLabel
