import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import getOccupantsFromCheckoutCart from 'analytics/snowplow/helpers/getOccupantsFromCheckoutCart'
import { getBookingProtectionItemsView } from 'checkout/selectors/view/bookingProtection'
import { selectShouldUseBookingProtectionMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'
import { max, min } from 'lib/array/arrayUtils'
import { isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import moment from 'moment'

function getBookingProtectionCheckoutItems(state: App.State, item: App.Checkout.BookingProtectionItem) {
  const bookingProtectionView = getBookingProtectionItemsView(state)
  const discountedBookingProtection = selectShouldUseBookingProtectionMemberPrice(state)
  const travellers = getOccupantsFromCheckoutCart(state.checkout.cart.items)
  const accommodationCartItems = state.checkout.cart.items.filter(isInstantBookingLEHotelItem)
  const travelStart = min(accommodationCartItems, (val) => moment(val.checkIn).unix())?.checkIn
  const travelEnd = max(accommodationCartItems, (val) => moment(val.checkOut).unix())?.checkOut
  return bookingProtectionView.data.map(bookingProtection => {
    const price = discountedBookingProtection ? bookingProtection.totals.memberPrice : bookingProtection.totals.price
    const memberPrice = bookingProtection.totals.memberPrice
    return comluxgroup.createItem_1_1_0(
      {
        offerId: item.quoteId || 'insurance',
        categoryId: 'insurance',
        productId: 'insurance',
        itemId: item.itemId,
        currency: state.geo.currentCurrency,
        travelStart,
        travelEnd,
        numberOfAdults: travellers?.adults,
        numberOfChildren: travellers?.children,
        numberOfInfants: travellers?.infants,
        childrenAges: travellers?.childrenAge,
        duration: travelStart && travelEnd ? moment(travelEnd).diff(travelStart, 'days') : undefined,
        price,
        metadata: price ? JSON.stringify({ memberPrice, nonMemberPrice: bookingProtection.totals.price }) : undefined,
      },
    ) })
}

export default getBookingProtectionCheckoutItems
