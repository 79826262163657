import { createSelector } from '@reduxjs/toolkit'
import { OptimizelyFeatureFlags } from 'constants/optimizely'

export const isLebtMerchantFeesEnabled = createSelector(
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (state: App.State) => state.businessTraveller.currentBusinessAccount,
  (optimizelyFeatureFlags, currentBusinessAccount): boolean => {
    return !!(currentBusinessAccount?.business?.enableMerchantFees &&
      optimizelyFeatureFlags[OptimizelyFeatureFlags.lebtMerchantFeesEnabled])
  },
)
