import React from 'react'
import ListItem from 'components/Luxkit/List/ListItem'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'
import LineGlobeAltIcon from 'components/Luxkit/Icons/line/LineGlobeAltIcon'
import styled from 'styled-components'
import { rem } from 'polished'

const IconContainer = styled.div`
  width: ${rem(48)};
  height: ${rem(48)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
  color: ${props => props.theme.palette.highlight.tertiary.normalForeground};
  border-radius: ${props => props.theme.borderRadius.S};
`

interface Props {
  isSelected: boolean;
  hideImages?: boolean;
  onSelectAll: () => void;
}

function CruiseAllLocationsItem({ isSelected, onSelectAll, hideImages }: Props) {
  return <ListItem
    onClick={onSelectAll}
    startIcon={!hideImages && <IconContainer>
      <LineGlobeAltIcon />
    </IconContainer>}
    title={<b>All destinations</b>}
    subtitle="Cruises all around the world"
    endIcon={<CheckboxButton size="large" checked={isSelected} />}
  />
}

export default CruiseAllLocationsItem
