import { nonNullable } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { getCruiseItems } from '../cruiseSelectors'
import isDomesticTrip from './isDomesticTrip'
import { getInsuranceProductListKey } from 'lib/insurance/insuranceUtils'
import { getCruisesV1Items } from './cruise'

const getCheckoutInsuranceProducts = createSelector(
  (state: App.State) => isDomesticTrip(state),
  (state: App.State) => getCruiseItems(state).length > 0,
  (state: App.State) => getCruisesV1Items(state).length > 0,
  (state: App.State) => state.insurance.productLists,
  (state: App.State) => state.insurance.products,
  (_: App.State, type?: App.InsuranceProductType) => type,
  (isDomestic, hasCruiseItems, hasCruiseV1Items, productLists, products, type): Array<App.InsuranceProduct> => {
    const key = getInsuranceProductListKey(type ?? 'travel-insurance', {
      isDomestic,
      isCruise: hasCruiseItems && hasCruiseV1Items,
    })

    const list = productLists[key]
    return nonNullable(list?.map(id => products[id]) ?? [])
  },
)

export default getCheckoutInsuranceProducts
