import { Models as FlightModels } from '@luxuryescapes/lib-flights-types'

/** We want to stop using the types from lib-flight-types and use our own types
 * defined on CP even for the response from the server.
 * This is a temporary solution until we migrate to the new types.
 */
export interface ServerUpsellFareFamily extends FlightModels.UpsellFare.IUpsellFare {
  styling?: {
    highlight: boolean
    highlightText: string
  }
}

export interface ServerFareFamily extends FlightModels.Search.IFareFamily {}

export function mapFlightFareFamily(serverFareFamily: ServerUpsellFareFamily): App.FlightFareFamily {
  return {
    fareFamily: {
      name: serverFareFamily.fareFamily.name,
      carrier: serverFareFamily.fareFamily.carrier,
      description: serverFareFamily.fareFamily.description,
      fareFeatures: serverFareFamily.fareFamily.fareFeatures.map(feature => ({
        code: feature.code,
        group: (feature as any).groupId,
        description: feature.description,
        status: feature.status,
        type: feature.type as App.FareFamilyFeatureType,
        category: feature.category as App.FareFamilyFeatureCategory,
      })),
    },
    cost: {
      adult: serverFareFamily.cost.adult?.amount ?? 0,
      children: serverFareFamily.cost.children?.amount ?? 0,
      infant: serverFareFamily.cost.infant?.amount ?? 0,
      all: serverFareFamily.cost.all?.amount ?? 0,
    },
    order: serverFareFamily.hierarchy,
    id: serverFareFamily.id,
    cabin: serverFareFamily.cabin,
    fareBasis: serverFareFamily.fareBasis,
    bookingClass: serverFareFamily.bookingClass,
    fareRulesUrl: (serverFareFamily as any).links?.find((link: any) => link.key === 'fareRules')?.value!,
    pairedUpsellIds: serverFareFamily.pairedUpsellIds,
    styling: serverFareFamily.styling ? {
      highlight: serverFareFamily.styling?.highlight,
      highlightText: serverFareFamily.styling?.highlightText,
    } : undefined,
  }
}

export function mapFareFamily(serverFareFamily: ServerFareFamily): App.FareFamily {
  return {
    name: serverFareFamily.name,
    carrier: serverFareFamily.carrier,
    description: serverFareFamily.description,
    fareFeatures: serverFareFamily.fareFeatures.map(feature => ({
      code: feature.code,
      group: (feature as any).groupId,
      description: feature.description,
      status: feature.status,
      type: feature.type as App.FareFamilyFeatureType,
      category: feature.category as App.FareFamilyFeatureCategory,
    })),
  }
}
