import Modal from 'components/Luxkit/Modal/Modal'
import React, { useCallback, useMemo } from 'react'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import useQueryParams from 'hooks/useQueryParams'
import Group from 'components/utils/Group'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import CruiseCabinTypeFilter
  from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruiseCabinTypeFilter'
import {
  CruiseSearchCabinTypeChipProps,
} from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchCabinTypeChip'

function ModalCruiseSearchCabinTypeChip(props: CruiseSearchCabinTypeChipProps) {
  const {
    facets,
    filterForm,
    setFilterForm,
    fetching,
    filters,
    drawerMode,
  } = props
  const modalElement = useModalElementContext<boolean>()
  const queryParams = useQueryParams()

  const queryParamCabinTypes = useMemo(() => {
    return queryParams.get('cabinTypes')
  }, [queryParams])

  const selected = useMemo(() => {
    return !!queryParamCabinTypes
  }, [queryParamCabinTypes])

  const closeModal = useCallback(() => {
    modalElement.resolve(selected)
  }, [selected, modalElement])

  const updateForm = useCallback((nextFilters?: { cabinTypes: string }) => {
    if (nextFilters && Object.keys(nextFilters).length > 0) {
      setFilterForm({
        ...filterForm,
        ...nextFilters,
      })
    } else {
      setFilterForm({ ...filterForm, ...{ cabinTypes: undefined } })
    }
    closeModal()
  }, [filterForm, setFilterForm, closeModal])

  const resetFilter = useCallback(() => {
    setFilterForm({ ...filterForm, cabinTypes: undefined })
    modalElement.resolve(false)
  }, [filterForm, setFilterForm, modalElement])

  return <Modal
    mode={drawerMode ? 'drawer' : 'default'}
    title="Cabin types"
    primaryActionText="Apply"
    onPrimaryActionClick={closeModal}
    secondaryActionText={!selected ? 'Reset all' : 'Reset all (1)'}
    onSecondaryActionClick={resetFilter}
  >
    {fetching && <Group direction="vertical" gap={8}>
      <RadioInput><TextLoadingBox width="100%" /></RadioInput>
      <RadioInput><TextLoadingBox width="100%" /></RadioInput>
      <RadioInput><TextLoadingBox width="100%" /></RadioInput>
      <RadioInput><TextLoadingBox width="100%" /></RadioInput>
    </Group>}
    {!fetching && <CruiseCabinTypeFilter
      facets={facets}
      onChange={updateForm}
      cabinTypes={filters.cabinTypes}
    />}
  </Modal>
}

export default ModalCruiseSearchCabinTypeChip
