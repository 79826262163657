import React, { useEffect, useState } from 'react'
import SessionTimeoutModal from './SessionTimeoutModal'
import moment from 'moment'
import config from 'constants/config'
import { getCookies, setCookie } from 'lib/web/cookieUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import { FLIGHTS_SESSION_EXPIRATION_TIME_IN_MINUTES } from 'constants/flight'

export const DEFAULT_FLIGHTS_TIMER_KEY = 'flights-timer-expiration-timestamp'

const COOKIE_THIRTY_MINUTE_TTL = 3600
const COOKIE_PATH = '/'
const COOKIE_SAME_SITE = 'Lax'

// TODO: Stop using a cookie for this

export function resetFlightsTimer(expiry?: number) {
  const expirationTimestamp = expiry ?? moment().add(FLIGHTS_SESSION_EXPIRATION_TIME_IN_MINUTES, 'minutes').toDate().getTime()
  setCookie(DEFAULT_FLIGHTS_TIMER_KEY, expirationTimestamp.toString(), COOKIE_THIRTY_MINUTE_TTL, COOKIE_PATH, COOKIE_SAME_SITE)
}

export function expireFlightsTimer() {
  setCookie(DEFAULT_FLIGHTS_TIMER_KEY, '0', COOKIE_THIRTY_MINUTE_TTL, COOKIE_PATH, COOKIE_SAME_SITE)
}

export function getFlightsTimerExpirationTimestamp() {
  const cookies = getCookies()
  const timestamp = Number(cookies[DEFAULT_FLIGHTS_TIMER_KEY])
  return isNaN(timestamp) ? 0 : timestamp
}

interface Props {
  title?: string;
  primaryActionText?: string;
  message?: string;
  backBtnTitle?: string;
  onReload: () => void;
  onGoBack?: () => void;
  startFlightsTimer: boolean;
}

export function hasFlightSessionExpired() {
  if (!config.ENABLE_FLIGHTS_SESSION_TIMER) {
    // no timer, not expired
    return false
  }
  const timestamp = getFlightsTimerExpirationTimestamp()
  return !timestamp || moment(timestamp).isBefore()
}

function FlightsSessionTimer(props: Props) {
  const { startFlightsTimer } = props
  const [expired, setExpired] = useState<boolean>(false)

  const restoreState = useAppSelector((state) => state.checkout.restoreCart.status)
  const restoreProps = restoreState === 'restored' ? {
    title: 'Reselect your flights to secure the best prices',
    primaryActionText: 'Select flights',
    message: 'Flight prices can change rapidly due to demand and availability. To ensure you get the most up-to-date and competitive prices, please reselect your flights.',
  } : undefined

  useEffect(() => {
    if (startFlightsTimer) {
      const interval = setInterval(() => {
        setExpired(hasFlightSessionExpired())
      }, 1000)
      return () => clearInterval(interval)
    } else {
      setExpired(false)
    }
  }, [startFlightsTimer])

  return (
    <SessionTimeoutModal
      {...props}
      {...restoreProps}
      isOpen={startFlightsTimer && expired}
    />
  )
}

export default FlightsSessionTimer
