import { createSelector } from 'reselect'
import { EmptyArray } from 'lib/array/arrayUtils'
import getExperienceItemsView from 'checkout/selectors/view/getExperienceItemsView'

const getCheckoutExperiencePointsCalculationRequests = createSelector(
  (state: App.State) => getExperienceItemsView(state),
  (experienceViews): Array<App.LuxLoyaltyDefaultPointsEarnCalculationRequest> => {
    if (!experienceViews.hasRequiredData) return EmptyArray

    return experienceViews.data.flatMap((itemView) => itemView.ticketViews.map(ticketView => ({
      price: ticketView.totals.price,
      luxLoyaltyProductType: 'experience',
      itemId: ticketView.item.itemId,
    })))
  },
)

export default getCheckoutExperiencePointsCalculationRequests
