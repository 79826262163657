import { useAppSelector } from 'hooks/reduxHooks'
import * as React from 'react'
import { useContext } from 'react'

function getReduxState(state: App.State) {
  return state
}

export function createWithContextStateHOC<ContextState>(
  context: React.Context<ContextState>,
  displayName: `with${string}`,
) {
  return function<R>(
    mapContextStateToProps: (state: ContextState, props: any) => R,
    forwardRef?: boolean,
  ) {
    return function<Props>(WrappedComponent: React.ComponentType<React.PropsWithChildren<Props>>): React.ComponentType<React.PropsWithChildren<Utils.Simplify<Omit<Props, keyof R>>>> {
      const componentName = WrappedComponent.displayName || WrappedComponent.name

      let HOCWrapper: any
      if (forwardRef) {
        const Component = React.memo(function StateMemo(props: Props & any) {
          const { ref, ...rest } = props

          return <WrappedComponent
            {...(rest as Props)}
            ref={ref}
          />
        })

        HOCWrapper = function WithState(props: Omit<Props, keyof R> & { ref?: React.Ref<any> }) {
          const contextState = useContext(context)
          const propsFromState = mapContextStateToProps(contextState, props)

          return <Component {...props} {...propsFromState} />
        }
      } else {
        const Component = React.memo(function StateMemo(props: any) {
          return <WrappedComponent {...props} />
        })

        HOCWrapper = function WithState(props: Omit<Props, keyof R>) {
          const contextState = useContext(context)
          const propsFromState = mapContextStateToProps(contextState, props)

          return <Component {...props} {...propsFromState} />
        }
      }

      HOCWrapper.displayName = `${displayName}(${componentName})`
      return HOCWrapper
    }
  }
}

export function createWithContextAndReduxStateHOC<ContextState>(
  context: React.Context<ContextState>,
  displayName: `with${string}`,
) {
  return function<R>(
    mapContextAndAppStateToProps: (state: ContextState, appState: App.State, props: any) => R,
    forwardRef?: boolean,
  ) {
    return function<Props>(WrappedComponent: React.ComponentType<React.PropsWithChildren<Props>>): React.ComponentType<React.PropsWithChildren<Utils.Simplify<Omit<Props, keyof R>>>> {
      const componentName = WrappedComponent.displayName || WrappedComponent.name

      let HOCWrapper: any
      if (forwardRef) {
        const Component = React.memo(function StateMemo(props: Props & any) {
          const { ref, ...rest } = props

          return <WrappedComponent
            {...(rest as Props)}
            ref={ref}
          />
        })

        HOCWrapper = function WithState(props: Omit<Props, keyof R> & { ref?: React.Ref<any> }) {
          const contextState = useContext(context)
          const appState = useAppSelector(getReduxState)
          const propsFromState = mapContextAndAppStateToProps(contextState, appState, props)

          return <Component {...props} {...propsFromState} />
        }
      } else {
        const Component = React.memo(function StateMemo(props: any) {
          return <WrappedComponent {...props} />
        })

        HOCWrapper = function WithState(props: Omit<Props, keyof R>) {
          const contextState = useContext(context)
          const appState = useAppSelector(getReduxState)
          const propsFromState = mapContextAndAppStateToProps(contextState, appState, props)

          return <Component {...props} {...propsFromState} />
        }
      }

      HOCWrapper.displayName = `${displayName}(${componentName})`
      return HOCWrapper
    }
  }
}
