import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchUserCart } from 'actions/AuthActions'
import { selectLoggedIn } from 'selectors/accountSelectors'

export function useRestoreUserCart() {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectLoggedIn)
  useEffect(() => {
    if (!isLoggedIn) return
    dispatch(fetchUserCart())
  }, [dispatch, isLoggedIn])
}
