import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux'

import { accountAccessShowLogin } from 'actions/UiActions'
import { accountAddMobilePrompted, removeAddMobilePrompted } from 'storage/account'
import { API_CALL_SUCCESS } from 'actions/actionConstants'
import { canBroadcast, LUX_BROADCAST_CHANNEL } from 'lib/web/broadcastChannelUtils'
import { fetchAccountAgentHub as fetchAgentHubAccount } from 'actions/AgentHubActions'
import { fetchHighIntentOffers, fetchPersonalisedAlternativeOffers } from 'actions/RecommendationActions'
import { fetchPaymentCards, fetchStripePaymentCards, authLogout, fetchAvailableCredit, updateAddPhonePromptedAt } from 'actions/AuthActions'
import { matchPath } from 'react-router'
import { registerRequestInterceptor, unregisterRequestInterceptor, Interceptor } from 'api/requestUtils'
import { resetContext } from 'analytics/analytics'
import { signinSuccessEvent, signupSuccessEvent } from 'analytics/snowplow/events'
import * as Analytics from 'analytics/analytics'
import { USER_LOGIN, USER_REGISTRATION, USER_LOGOUT } from 'actions/apiActionConstants'
import config from 'constants/config'
import unauthorisedInterceptor from 'api/interceptors/unauthorisedInterceptor'
import moment from 'moment'
import { fetchLuxLoyaltyAccount } from 'actions/LuxLoyaltyActions'
import { changeBrazeUser } from 'api/braze'
import { resetTargetedPromo } from 'actions/MarketingAction'
import { getWhiteLabelAppConfig, postMessageToWhiteLabelApp } from 'lib/whitelabels/whitelabels'

function setSSRAuthToken(accessToken?: string) {
  let expires = '2018'

  if (accessToken) {
    expires = '2040'
  }

  document.cookie = `ssr_access_token=${accessToken}; expires=Wed, 11 Jul ${expires} 00:00:00 GMT; path=/; domain=${document.domain}`
}

function resetSSRAuthToken() {
  document.cookie = `ssr_access_token=''; expires=Wed, 11 Jul 2018 00:00:00 GMT; path=/; domain=${document.domain}`
}

const ADD_PHONE_MODAL_VALID_ROUTES = [
  '/:regionCode',
  '/:regionCode/search',
  '/:regionCode/search/homes-and-villas',
  '/:regionCode/search/tours',
  '/:regionCode/search/cruises',
  '/:regionCode/search/car-hire',
  '/:regionCode/search/experiences',
  '/:regionCode/flights-search-results',
]
function isAddPhoneValidPage(currentUrl: string): boolean {
  return ADD_PHONE_MODAL_VALID_ROUTES.some(path => matchPath(currentUrl, { path, exact: true }))
}

/**
 * Returns whether we should re prompt the user to add their phone number
 */
function shouldReshowAddPhonePrompt(addPhonePromptedAt?: string): boolean {
  if (!addPhonePromptedAt) return true
  // 60 days, or ~2 months for now
  return moment(addPhonePromptedAt).isBefore(moment().subtract(60, 'days'))
}

function promptAddPhone(action: AnyAction, api: MiddlewareAPI<Dispatch<any>, App.State>) {
  const appState:App.State = api.getState()
  const basicExclusions = appState.system.headlessMode ||
    config.agentHub.isEnabled ||
    action.data.account.isSpoofed ||
    action.data.isSignUp ||
    action.data.account.phone ||
    !isAddPhoneValidPage(appState.router.location.pathname)

  const promptedBeforeLocal = accountAddMobilePrompted()

  if (promptedBeforeLocal &&
      !action.data.account.addPhonePromptedAt &&
      !action.data.account.phone
  ) {
    // Looks like we've been prompted before but never registered it with auth
    // so lets make sure we register it with auth
    api.dispatch(updateAddPhonePromptedAt(new Date()))
    removeAddMobilePrompted()
  }

  if (basicExclusions) return false
  if (!action.data.account.addPhonePromptedAt && promptedBeforeLocal) return false

  const shouldReshowPrompt = shouldReshowAddPhonePrompt(action.data.account.addPhonePromptedAt)
  return shouldReshowPrompt
}

function isLoginOrRegisterSuccess(action: unknown): action is {
  type: string
  api: string
  data: any
  supressBroadcast: boolean
  source: App.SignUpAuthSource
} {
  return !!action &&
    typeof action === 'object' &&
    'api' in action &&
    'type' in action &&
    (action.api === USER_LOGIN || action.api === USER_REGISTRATION) &&
    action.type === API_CALL_SUCCESS
}

let interceptor: Interceptor
const userMiddleware: Middleware<{}, App.State, Dispatch<any>> = (api) => next => (action) => {
  if (
    !!action &&
    typeof action === 'object' &&
    'api' in action &&
    'type' in action
  ) {
    if (isLoginOrRegisterSuccess(action)) {
    // by running next, the rest of the reducer flow goes through
    // and we now have the state "after" the dispatch is done
      next(action)

      const state = api.getState()
      // The fetching below relies on the account being populated in the reducer
      api.dispatch(fetchAvailableCredit())
      api.dispatch(fetchPaymentCards())
      api.dispatch(fetchStripePaymentCards())
      api.dispatch(fetchHighIntentOffers())
      api.dispatch(fetchPersonalisedAlternativeOffers())
      api.dispatch(fetchLuxLoyaltyAccount())

      if (config.agentHub.isEnabled) {
        api.dispatch(fetchAgentHubAccount())
      }

      setSSRAuthToken(action.data.accessToken)
      // always clean up any previous interceptors
      unregisterRequestInterceptor(interceptor)
      // log them out if an api returns a 401, probably means their connection is no longer authorised!
      // when we unregister need it to be the same function, so save the one we registered with here
      interceptor = unauthorisedInterceptor(() => api.dispatch(authLogout()))
      registerRequestInterceptor(interceptor)

      changeBrazeUser(state.auth.account.memberId)

      if (getWhiteLabelAppConfig().isApp) {
        const user = {
          memberId: action.data.account.memberId,
          email: action.data.account.email,
          firstName: action.data.account.givenName,
          lastName: action.data.account.surname,
          countryCode: action.data.account.countryCode,
          phone: action.data.account.phone,
          phonePrefix: action.data.account.phonePrefix,
        }
        postMessageToWhiteLabelApp({ user })
      }

      if (canBroadcast() && !action.supressBroadcast) {
        const channel = new BroadcastChannel(LUX_BROADCAST_CHANNEL)
        // we just logged in, let other tabs/windows know about it
        channel.postMessage({ action })
        channel.close()
      }
      // If it's a signup and not an action from another tab/window
      if (action.data.isSignUp && !action.supressBroadcast) {
        Analytics.trackEvent(signupSuccessEvent(action.source))
      }

      // If it's a signin and not an action from another tab/window
      if (action.data.isSignIn && !action.supressBroadcast) {
        Analytics.trackEvent(signinSuccessEvent(action.source))
      }

      // prompt for adding mobile if they don't have one
      // added here so it also picks up social logins
      if (promptAddPhone(action, api)) {
        api.dispatch(accountAccessShowLogin('promptAddPhone'))
      }
      return
    } else if (action.api === USER_LOGOUT && action.type === API_CALL_SUCCESS) {
      unregisterRequestInterceptor(interceptor)
      resetSSRAuthToken()
      api.dispatch(resetTargetedPromo())

      if (canBroadcast() && 'supressBroadcast' in action && !action.supressBroadcast) {
        const channel = new BroadcastChannel(LUX_BROADCAST_CHANNEL)
        // we just logged out, let other tabs/windows know about it
        channel.postMessage({ action })
        channel.close()
      }
      changeBrazeUser()
      resetContext()
    }
  }

  return next(action)
}

export default userMiddleware
