import { createSelector } from 'reselect'
import { EmptyArray } from 'lib/array/arrayUtils'
import getTransferItemsView from 'checkout/selectors/view/getTransferItemsView'

const getCheckoutTransfersPointsCalculationRequest = createSelector(
  (state: App.State) => getTransferItemsView(state),
  (transferViews): Array<App.LuxLoyaltyPointsEarnCalculationRequest> => {
    if (!transferViews.hasRequiredData) return EmptyArray

    return transferViews.data.map((itemView) => ({
      price: itemView.totals.price,
      luxLoyaltyProductType: 'experience',
      itemId: itemView.item.itemId,
    }))
  },
)

export default getCheckoutTransfersPointsCalculationRequest
