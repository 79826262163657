import { createSelector } from 'reselect'
import { getFlightItems } from './flights'

/**
 * Gets a Set of item ids that are also bundled with any flight item
 */
const getFlightBundledItemIds = createSelector(
  (state: App.State) => getFlightItems(state),
  (items): Set<string> => {
    const allBundledIds = items.flatMap(item => item.bundledItemIds ?? [])
    return new Set(allBundledIds)
  },
)

export default getFlightBundledItemIds
