import TextLink from 'components/Luxkit/TextLink'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import ModalContext from 'contexts/ModalContext'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import CruiseCabinTypeInfoModal from './Modals/CruiseCabinTypeInfoModal'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import { CABIN_CATEGORIES_SORT_ORDER, CABIN_CATEGORY_TYPE, DEFAULT_CABIN_CATEGORY_TYPE } from 'constants/cruise'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import { replace } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from 'hooks/reduxHooks'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import ToggleOptionsGroup from 'components/Luxkit/ToggleOptionsGroup'
import ToggleOption from 'components/Luxkit/ToggleOption'
import useCruiseSelectedCabinType from 'hooks/useCruiseSelectedCabinType'
import useCruiseFacetFiltersParams from 'hooks/useCruiseFacetFiltersParams'
import styled from 'styled-components'
import PriceRowPriceWithCaption from 'components/Luxkit/PricePoints/PriceRowPriceWithCaption'

const ToggleOptionContainer = styled.div`
  overflow-x: scroll;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export type CabinTypeMap = {
  [key in CABIN_CATEGORY_TYPE]: {
    offerId: string
    minimumPrice: number
    minimumPricePerNight: number
  }
}

interface CruiseSelectCabinTypeProps {
  filters: App.OfferListFilters
  isMobile?: boolean
}

function CruiseSelectCabinType({ filters, isMobile }: CruiseSelectCabinTypeProps) {
  const dispatch = useAppDispatch()
  const isPricePerNight = useCruisePriceByNight()
  const showModal = useContext(ModalContext)
  const { search } = useLocation()
  const { facetParams } = useCruiseFacetFiltersParams({ filters })
  const cabinType = useCruiseSelectedCabinType()
  const [selectedCabinType, setSelectedCabinType] = useState<string>(cabinType ?? DEFAULT_CABIN_CATEGORY_TYPE)
  const priceProperties = isPricePerNight ? 'minimumPricePerNight' : 'minimumPrice'

  const [facets] = useCruiseSearchFacets(facetParams)

  const mappedCabinTypes = useMemo(() => {
    return facets.reduce((cabinTypes, facet) => {
      if (CABIN_CATEGORIES_SORT_ORDER.includes(facet.name as CABIN_CATEGORY_TYPE)) {
        cabinTypes[facet.name as CABIN_CATEGORY_TYPE] = {
          offerId: facet.value ?? '',
          minimumPrice: facet.min ?? 0,
          minimumPricePerNight: facet.minPerNight ?? 0,
        }
      }
      return cabinTypes
    }, {} as CabinTypeMap)
  }, [facets])

  const handleSelectedCabinType = useCallback((cabinType: string) => {
    let nextCabinType = cabinType
    // if the cabin type is the same as the selected cabin type, set it to the default cabin type
    if (cabinType === selectedCabinType) {
      nextCabinType = DEFAULT_CABIN_CATEGORY_TYPE
    }

    setSelectedCabinType(nextCabinType)
    const nextSearch = setSearchParamValue(search, 'cabinTypes', nextCabinType)
    dispatch(replace({ search: nextSearch }))
  }, [dispatch, search, selectedCabinType])

  const openModal = useCallback(async() => {
    const selectedCabinType = await showModal<string | undefined>(<CruiseCabinTypeInfoModal cabinTypeMap={mappedCabinTypes} />)
    if (selectedCabinType) {
      handleSelectedCabinType(selectedCabinType)
    }
  }, [showModal, mappedCabinTypes, handleSelectedCabinType])

  return <Group direction="vertical" gap={16}>
    <Group direction="horizontal" gap={16} horizontalAlign="space-between">
      <Heading variant="heading6">
        Select a cabin type
      </Heading>
      <TextLink size="small" weight="regular" onClick={openModal}>
        Which cabin type is right for me?
      </TextLink>
    </Group>
    <ToggleOptionContainer>
      <ToggleOptionsGroup horizontalAlign="start" desktopHorizontalAlign="stretch">
        {CABIN_CATEGORIES_SORT_ORDER.map((cabinType) => (
          <ToggleOption
            key={cabinType}
            isSelected={selectedCabinType === cabinType}
            onClick={() => handleSelectedCabinType(cabinType)}
            subtitle={!isMobile && <Group direction="horizontal" verticalAlign="center" gap={2}>
              <PriceRowPriceWithCaption
                price={mappedCabinTypes[cabinType]?.[priceProperties]}
                size="S"
                caption="From"
                saleUnit={isPricePerNight ? '/night' : '/person'}
              />
            </Group>}
            title={cabinType}
          />
        ))}
      </ToggleOptionsGroup>
    </ToggleOptionContainer>
  </Group>
}

export default CruiseSelectCabinType
