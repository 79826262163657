import { arrayToObject } from 'lib/array/arrayUtils'
import { UndefinedToOptional } from 'type-fest/source/internal'
import { Entries, PartialDeep } from 'type-fest'

type ObjectKey = string | number | symbol

const returnValFunc = (val: any) => val

type ExtractSetType<A> = A extends Set<infer T> ? T : never
/**
 * Turns a const array or set of strings into a union type of the strings in the array/set.
 * If that can't be done, returns void.
 */
type StringArrayOrSetToUnion<A> =
  A extends Readonly<Array<string>> ? (A[number] extends `${infer ValuesUnion}` ? ValuesUnion : void) :
  A extends Set<string> ? (ExtractSetType<A> extends `${infer ValuesUnion}` ? ValuesUnion : void) :
  void

type KeysOmitted<T, K> = StringArrayOrSetToUnion<K> extends void ? T : { [key in Exclude<keyof T, StringArrayOrSetToUnion<K>>]: T[key] }

/**
  * Converts an object to a new object with the given keys omitted.
  *
  * Can be used in curried form, e.g. omitKeys(['a'])(obj)
  */
export function omitKeys<T extends { [key: string]: any }, const K extends Readonly<Array<keyof T>> | Set<keyof T>>(
  keysToOmit: K,
): (obj: T) => KeysOmitted<T, K>
export function omitKeys<T extends { [key: string]: any }, const K extends Readonly<Array<keyof T>> | Set<keyof T>>(
  keysToOmit: K,
  obj: T,
): KeysOmitted<T, K>
export function omitKeys<T extends { [key: string]: any }, const K extends Readonly<Array<keyof T>> | Set<keyof T>>(
  keysToOmit: K,
  obj?: T,
): KeysOmitted<T, K> | ((obj: T) => KeysOmitted<T, K>) {
  if (!obj) {
    return (obj: T) => omitKeys(keysToOmit, obj)
  }
  const newObject = { ...obj }
  keysToOmit.forEach((key: keyof T) => {
    delete newObject[key]
  })
  return newObject
}

/**
 * Omits the given keys from the given object
 */
export function deepOmitKeys<T extends Object>(obj: T, keys: Array<string>): T {
  if (obj == null || typeof obj !== 'object') return obj

  const objCopy = structuredClone(obj)
  const visited = new Set()
  return deepOmitKeysRecursive(objCopy, keys, visited)
}

function deepOmitKeysRecursive<T extends Object>(obj: T, keys: Array<string>, visited: Set<any>): T {
  if (obj == null || typeof obj !== 'object') return obj

  visited.add(obj)
  Object.entries(obj).forEach(([key, val]) => {
    if (keys.some((k) => key.includes(k))) {
      delete obj[key as keyof T]
    } else if (typeof val === 'object' && !visited.has(val)) {
      deepOmitKeysRecursive(val, keys, visited)
    }
  })
  return obj
}

type KeysPicked<T, K> = StringArrayOrSetToUnion<K> extends void ? T : { [key in keyof T & StringArrayOrSetToUnion<K>]: T[key] }

/**
  * Converts an object to a new object with the given keys kept and any others omitted.
  *
  * Can be used in curried form, e.g. pickKeys(['a'])(obj)
  */
export function pickKeys<T extends { [key: string]: any }, const K extends Readonly<Array<keyof T>> | Set<keyof T>>(
  keysToPick: K,
): (obj: T) => KeysPicked<T, K>
export function pickKeys<T extends { [key: string]: any }, const K extends Readonly<Array<keyof T>> | Set<keyof T>>(
  keysToPick: K,
  obj: T,
): KeysPicked<T, K>
export function pickKeys<T extends { [key: string]: any }, const K extends Readonly<Array<keyof T>> | Set<keyof T>>(
  keysToPick: K,
  obj?: T,
): KeysPicked<T, K> | ((obj: T) => KeysPicked<T, K>) {
  if (!obj) {
    return (obj: T) => pickKeys(keysToPick, obj)
  }
  const keys = Object.keys(obj)
  const pickSet = new Set(keysToPick)
  const newObject = { ...obj }
  keys.forEach((key: keyof T) => {
    if (!pickSet.has(key)) {
      delete newObject[key]
    }
  })
  return newObject
}

/**
  * Filter an object based on a test function.
  */
export function filterObject<
  T extends { [key: string | number | symbol]: any },
>(
  obj: T,
  test: (key: keyof T, value: T[keyof T]) => boolean,
): Partial<T> {
  const newObject = { ...obj } as Partial<T>
  Object.entries(obj).forEach(([key, value]) => {
    if (!test(key, value)) {
      delete newObject[key]
    }
  })
  return newObject
}

export function mergeObjects<T>(
  objects: Array<{ [key: string]: T }>,
): { [key: string]: T } {
  const initialValue: { [key: string]: T } = {}
  return objects.reduce((merged, current) => ({ ...merged, ...current }), initialValue)
}

export function mapObject<T, R>(
  object: Record<ObjectKey, T>,
  valueSelector: (val: T, key?: ObjectKey, index?: number) => R = returnValFunc,
): Record<ObjectKey, R> {
  return Object.entries(object).reduce<Record<ObjectKey, R>>(
    (obj, [k, v], i) => {
      obj[k] = valueSelector(v, k, i)
      return obj
    },
    {},
  )
}

function isObject(value: any): value is object {
  return value !== undefined && value !== null && typeof value === 'object'
}

/**
 * Given an (possibly) nested object, flatten the keys.
 * Will generate the resulting flattened object with the path separated by the
 * provided separator (defaulted to .)
 * e.g. { foo: { baz: 'hello' } } will come out as { 'foo.baz': 1 }
 */
export function flattenObjectKeys(
  obj: { [key: string]: string | Object },
  separator: string = '.',
  excludeKeys: Array<string> = [],
  maxFieldValueLength: number = 99999,
): Record<string, string> {
  return Object.entries(obj).reduce<Record<string, string>>((flattenedObj, [key, value]) => {
    if (excludeKeys.includes(key)) {
      return flattenedObj
    }
    if (typeof value === 'string' && value.length > maxFieldValueLength) {
      return flattenedObj
    }
    if (isObject(value)) {
      const childObj = flattenObjectKeys(value as any, separator, excludeKeys, maxFieldValueLength)
      Object.entries(childObj).forEach(([childKey, childValue]) => {
        const fullPath = `${key}${separator}${childKey}`
        if (!excludeKeys.some(excludeKey => fullPath === excludeKey || fullPath.startsWith(`${excludeKey}${separator}`))) {
          flattenedObj[fullPath] = childValue
        }
      })
    } else {
      flattenedObj[key] = value
    }

    return flattenedObj
  }, {} as Record<string, string>)
}

type ArrayMergeMode = 'array-overwrite' | 'array-concat' // Could also add an 'array-concat-dedupe' option if we ever need it

/**
 * Deeply merges two objects. Values from the second object will override values from the first.
 *
 * Designed for plain objects containing primitives, arrays and other plain objects. Not tested with Sets, Maps, etc.
 */
export function deepMerge<T>(val1: T, val2: PartialDeep<T>, arrayMergeMode?: ArrayMergeMode): T;
export function deepMerge<T>(val1: PartialDeep<T>, val2: PartialDeep<T>, arrayMergeMode?: ArrayMergeMode): PartialDeep<T>;
export function deepMerge(val1: any, val2: any, arrayMergeMode: ArrayMergeMode = 'array-overwrite'): any {
  if (typeof val1 === 'undefined') { return val2 }
  if (typeof val2 === 'undefined') { return val1 }
  if (typeof val1 !== typeof val2) { return val2 }
  if (Array.isArray(val1)) {
    return arrayMergeMode === 'array-concat' ? val1.concat(val2) : val2
  }
  if (typeof val1 === 'object') {
    const keys = [...new Set([...Object.keys(val1), ...Object.keys(val2)])]
    return arrayToObject(keys, returnValFunc, k => deepMerge(val1[k], val2[k], arrayMergeMode))
  }
  return val2
}

export function setAttribute(obj: any, path: Array<string> | string, value: any) {
  const pathItems = typeof path == 'string' ? path.split('.') : path
  const [field, ...rest] = pathItems
  if (rest.length) {
    if (obj[field] === undefined) {
      obj[field] = {}
    } else if (typeof obj[field] != 'object') {
      throw Error('unexpected non-object type in the path')
    }
    setAttribute(obj[field], rest, value)
  } else {
    obj[field] = value
  }
  return obj
}

export function getAttribute(obj: any, path: Array<string> | string): any {
  const pathItems = typeof path == 'string' ? path.split('.') : path
  const [field, ...rest] = pathItems
  if (rest.length) {
    if (typeof obj[field] != 'object') {
      return undefined
    }
    return getAttribute(obj[field], rest)
  } else {
    return obj[field]
  }
}

export function isEmptyObject(obj: any) {
  return Object.keys(obj).every(key => obj[key] === undefined)
}

export function onlyDefinedProperties<T extends {}>(obj: T): Utils.UndefinedToOptional<T> {
  const newObject: Utils.UndefinedToOptional<T> = { ...obj } as any
  const keys = objectKeys(newObject)
  keys.forEach((key) => {
    if (typeof newObject[key] === 'undefined') {
      delete newObject[key]
    }
  })
  return newObject
}

export const EmptyObject = new Proxy<{ [key: string | number | symbol]: any }>({}, {
  set() {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error('Cannot modify EmptyObject')
    }
    return true
  },
})

export function removeUndefinedAndNullProperties<T extends {}>(object: T): UndefinedToOptional<T> {
  const objClone: T = { ...object }
  const keys = objectKeys(objClone)
  keys.forEach(key => {
    if (objClone[key] === undefined || objClone[key] === null) {
      delete objClone[key]
    }
  })
  return objClone
}

/**
 * Helper method to correctly type the set of keys returned from the object passed
 * There is no other internal typescript way to do this unfortunately
 */
export function objectEntries<T extends object>(object: T): Entries<T> {
  return Object.entries(object) as Entries<T>
}

/**
 * Helper method to correctly type the set of keys returned from the object passed
 * There is no other internal typescript way to do this unfortunately
 */
export function objectKeys<T extends object>(object: T): Array<keyof T> {
  return Object.keys(object) as Array<keyof T>
}
