import React, { useMemo } from 'react'
import SearchTileHighlightBanner from '../shared/SearchTileHighlightBanner'
import { useAppSelector } from 'hooks/reduxHooks'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import { hasPassedWalledGarden } from 'selectors/accountSelectors'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'
import { EmptyObject } from 'lib/object/objectUtils'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import {
  HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES,
  HIGHLIGHT_MESSAGE_STAY_MIN_NIGHTS,
  HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES,
} from 'components/OfferList/OfferListTiles/constants'
import { buildSearchParamsKey, isSearchStreamingSupported } from 'lib/search/searchUtils'
import { OfferUnavailableReason } from 'lib/search/constants'
import { dateDifference } from 'lib/datetime/dateUtils'

const bookablePropertyResultMessage = {
  [OFFER_TYPE_LAST_MINUTE]: 'We found a last minute deal for your hotel. Book now before it ends!',
  [OFFER_TYPE_ALWAYS_ON]: 'We found a great deal for your hotel. Book now to secure your room!',
  [OFFER_TYPE_HOTEL]: 'We found a limited time deal for your hotel!',
}

const bookablePropertyResultChooseDatesMessage = {
  [OFFER_TYPE_LAST_MINUTE]: 'We found a last minute deal for your hotel. Choose dates and book now before it ends!',
  [OFFER_TYPE_ALWAYS_ON]: 'We found a great deal for your hotel. Choose dates and book now to secure your room!',
  [OFFER_TYPE_HOTEL]: 'We found a limited time deal for your hotel. Choose dates and book now before it ends!',
}

interface Props {
  offer: App.HotelOffer | App.HotelOfferSummary
  offerMetaData?: App.OfferListMetaData
  filters?: App.OfferListFilters
}

function HotelSearchTileHighlightBanner({ offer, offerMetaData, filters = EmptyObject }: Props) {
  const isSpoofed = useAppSelector((state) => state.auth.account.isSpoofed)
  const passedWalledGarden = useAppSelector(hasPassedWalledGarden)
  const checkIn = filters?.checkIn ?? offerMetaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? offerMetaData?.suggestedTravelDates?.checkOut
  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])
  const bestRate = useAppSelector<App.OfferMaybeAvailableRate | undefined>((state) => state.offer.offerBestPrices[offer.id]?.[searchKey])
  const bestPriceError = useAppSelector<boolean>((state) => !!state.offer.offerPricesErrors[offer.id]?.[searchKey])

  const searchStreamingEnabled = isSearchStreamingSupported(filters)

  const spoofMessage = useMemo<string | undefined>(() => {
    if (isSpoofed && !scheduleIsCurrent(offer.visibilitySchedule)) {
      return 'This hotel is only visible to staff and available for purchase.'
    }
  }, [isSpoofed, offer])

  const directSearchPrices = useDirectSearchPrices({ filters, offerId: offer.id })
  const shouldUseBestPrice = (passedWalledGarden || !offer.walledGarden) && !!(checkIn && checkOut && filters?.rooms)
  const isSearchAvailable = directSearchPrices && offerMetaData?.available
  const fetchingPrice = shouldUseBestPrice && !directSearchPrices && (!bestRate && !bestPriceError)
  const available = bestRate?.available || offerMetaData?.available

  const nights = useMemo(() => {
    if (checkIn && checkOut) {
      return dateDifference(new Date(checkOut), new Date(checkIn)).days
    }
    return null
  }, [checkIn, checkOut])

  const description = useMemo<string | undefined>(() => {
    if (spoofMessage) return spoofMessage

    if (shouldUseBestPrice) {
      if (isSearchAvailable) {
        // search pricing is available, and the offer is available
        return bookablePropertyResultMessage[offer.type]
      } else if (
        directSearchPrices &&
        !offerMetaData?.available &&
        offerMetaData?.unavailableReason === OfferUnavailableReason.NUM_NIGHTS_UNSUPPORTED &&
        nights &&
        offer.minDuration &&
        offer.minDuration > nights
      ) {
        // search has found the offer to be unavailable due to min nights not satisfied
        return HIGHLIGHT_MESSAGE_STAY_MIN_NIGHTS(offer.minDuration)
      } else if (directSearchPrices && !offerMetaData?.available) {
        // search has found the offer to be unavailable
        return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
      } else if (fetchingPrice) {
        return undefined
      } else if (bestRate?.available && !bestPriceError) {
        return bookablePropertyResultMessage[offer.type]
      } else {
        return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
      }
    }
    if (offerMetaData?.unavailableReason === OfferUnavailableReason.CAPACITY_EXCEEDED) { // capacity exceeded
      return `The number of guests exceeds the ${offer.saleUnit}'s maximum capacity.`
    }
    if (offerMetaData?.unavailableReason === OfferUnavailableReason.DATE_UNAVAILABLE) { // date unavailable
      return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
    }
    if (checkIn && checkOut && filters?.rooms) { // has enough filters
      if (searchStreamingEnabled && !offerMetaData?.available) { // search has checked dates and found no availability
        return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
      }
      if (searchStreamingEnabled && offerMetaData?.available) { // search has checked dates and found availability
        return HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES()
      }
      if (available || bestPriceError) {
        return HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES()
      }
      if (!offer.isSoldOut && offer.minDuration && nights && offer.minDuration > nights) {
        return `Stay for at least ${offer.minDuration} nights to unlock a limited time deal on this hotel.`
      } else {
        return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
      }
    }
    // else {
    //   return 'We found a limited time deal for your hotel. Choose dates and book now before it ends!'
    // }

    return passedWalledGarden ?
      bookablePropertyResultChooseDatesMessage[offer.type] :
      bookablePropertyResultMessage[offer.type]
  }, [available, bestPriceError, bestRate, checkIn, checkOut, directSearchPrices, fetchingPrice, filters, isSearchAvailable, nights, offer.isSoldOut, offer.minDuration, offer.saleUnit, offer.type, offerMetaData?.available, offerMetaData?.unavailableReason, passedWalledGarden, searchStreamingEnabled, shouldUseBestPrice, spoofMessage])

  if (!description) return null

  return <SearchTileHighlightBanner description={description} isSpoof={!!spoofMessage}/>
}

export default HotelSearchTileHighlightBanner
