import * as Analytics from 'analytics/analytics'
import IconButton from 'components/Luxkit/Button/IconButton'
import TextButton from 'components/Luxkit/Button/TextButton'
import SolidGoogleIcon from 'components/Luxkit/Icons/solid/SolidGoogleIcon'
import { useAppSelector } from 'hooks/reduxHooks'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SOCIAL_LOGIN_ICON_BUTTON_PROPS, SOCIAL_LOGIN_TEXT_BUTTON_PROPS } from './SocialLoginButton'

const RelativeTextButton = styled(TextButton)`
  position: relative; /** so that the absolutely positioned span can sit properly */
  overflow: hidden;
`

const RelativeIconButton = styled(IconButton)`
  position: relative; /** so that the absolutely positioned span can sit properly */
  overflow: hidden;
`

const StylesSpan = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.01;
`

interface Props {
  iconOnly?: boolean;
}

function GoogleLoginButton({ iconOnly }: Props) {
  const gsiLoaded = useAppSelector(state => state.auth.gsiLoaded)
  const [element, setElement] = useState<HTMLElement | null>(null)
  const [hasInitialized, setHasInitialized] = useState<boolean>(false)

  const clickHandler = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'sign_up_sso_click',
      action: 'google_auth',
      category: 'logging',
      type: 'operational',
    })
  }, [])

  useEffect(() => {
    if (!hasInitialized && gsiLoaded && element) {
      window.google.accounts.id.renderButton(
        element,
        {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          text: 'continue_with',
          logo_alignment: 'center',
          click_listener: clickHandler,
          width: iconOnly ? 100 : 600,
        },
      )
      setHasInitialized(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gsiLoaded, element])

  return <>
    {!iconOnly && <RelativeTextButton
      {...SOCIAL_LOGIN_TEXT_BUTTON_PROPS}
      startIcon={<SolidGoogleIcon />}
    >
      Google
      <StylesSpan ref={setElement} />
    </RelativeTextButton>}
    {iconOnly && <RelativeIconButton
      {...SOCIAL_LOGIN_ICON_BUTTON_PROPS}
    >
      <StylesSpan ref={setElement}/>
      <SolidGoogleIcon />
    </RelativeIconButton>}
  </>
}

export default GoogleLoginButton
