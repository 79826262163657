import { PAYMENT_OPTIONS } from 'constants/payment'
import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { isSpoofed } from 'selectors/featuresSelectors'
import getPayableAfterModifiers from './getPayableAfterModifiers'
import getCreditBalanceForCheckoutCurrency from 'checkout/selectors/payment/getCreditBalanceForCheckoutCurrency'
import getCreditPayAmount from './getCreditPayAmount'
import getDepositAmountBeforeCreditApplied from './getDepositAmountBeforeCreditApplied'
import { getLuxPlusSubscriptionsTotalPrice } from '../view/luxPlusSubscription'
import { getSubscriptionJoinFeeTotals } from './luxPlusSubscription'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'
import getPaymentScheduleMerchantFeeEligibleAmount from './paymentSchedule/getPaymentScheduleMerchantFeeEligibleAmount'
import { getFormattedLuxPlusSubscriptionItems } from './orderCreationSelectors'
import { sum } from 'lib/array/arrayUtils'
import { getCheckoutLuxLoyaltyBurnTotalInCurrency } from '../luxLoyalty/getCheckoutLuxLoyaltyBurnTotals'

const getExcludedMerchantFeeTotal = createSelector(
  (state: App.State) => getLuxPlusSubscriptionsTotalPrice(state),
  (state: App.State) => getSubscriptionJoinFeeTotals(state),
  (state: App.State) => getFormattedLuxPlusSubscriptionItems(state),
  (state: App.State) => getCheckoutLuxLoyaltyBurnTotalInCurrency(state),
  (
    luxPlusTotal,
    joinFeeTotals,
    formattedLuxPlusSubscriptionItems,
    luxLoyaltyBurnTotalInCurrency,
  ) => {
    const luxPlusPromoAmount = sum(formattedLuxPlusSubscriptionItems, item => sum(item.item_discounts ?? [], discount => discount.discount_amount))
    return floatify(luxPlusTotal + joinFeeTotals.data.price - luxPlusPromoAmount + luxLoyaltyBurnTotalInCurrency)
  },
)

const getMerchantFeeAmount = createSelector(
  (state: App.State) => getDepositAmountBeforeCreditApplied(state),
  (state: App.State) => getCreditPayAmount(state),
  (state: App.State) => getPayableAfterModifiers(state),
  (state: App.State) => state.system.merchantFeeConfigs,
  (state: App.State) => state.checkout.merchantFeePaymentType,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (_state: App.State, paymentType: App.PaymentOption) => paymentType,
  (state: App.State) => getExcludedMerchantFeeTotal(state),
  (state: App.State) => isSpoofed(state),
  (state: App.State) => state.checkout.payment.rebookingID,
  (state: App.State) => state.checkout.payment.useCredit,
  (state: App.State) => getCreditBalanceForCheckoutCurrency(state),
  (state: App.State) => getPaymentScheduleMerchantFeeEligibleAmount(state),
  (
    depositAmount,
    creditAmount,
    payableAfterModifiers,
    merchantFeeConfigs,
    merchantFeePaymentType,
    postPurchase,
    paymentType,
    excludedTotals,
    isSpoofed,
    rebookingID,
    useLECredits,
    creditBalance,
    paymentScheduleMerchantFeeEligibleAmount,
  ) => {
    // Don't show merchant fees for post purchases
    if (postPurchase) return 0
    if (paymentType === PAYMENT_OPTIONS.RESERVE_FOR_FREE) return 0
    if (!merchantFeePaymentType || merchantFeeConfigs.length === 0) return 0

    const merchantFeePercentage = merchantFeeConfigs.find((config) => config.id === merchantFeePaymentType)?.percentage
    if (!merchantFeePercentage) return 0

    let payableStripeTotal = 0
    switch (paymentType) {
      case PAYMENT_OPTIONS.FULL:
        payableStripeTotal = floatify(payableAfterModifiers - creditAmount - excludedTotals)
        if (payableStripeTotal < 0) {
          payableStripeTotal = 0
        }
        break
      case PAYMENT_OPTIONS.DEPOSIT:
        payableStripeTotal = (isSpoofed && rebookingID && useLECredits) ?
          Math.max(0, floatify(depositAmount - creditBalance - excludedTotals)) : floatify(depositAmount - excludedTotals)
        break
      case PAYMENT_OPTIONS.PAYMENT_SCHEDULE:
        payableStripeTotal = floatify(paymentScheduleMerchantFeeEligibleAmount - excludedTotals)
        break
    }

    const merchantFee = floatify(payableStripeTotal * (merchantFeePercentage / 100))
    return merchantFee
  },
)

export default getMerchantFeeAmount
