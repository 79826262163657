import config from 'constants/config'
import { arrayToObject } from 'lib/array/arrayUtils'
import { isBedbankItem, isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { getAge } from 'lib/datetime/dateUtils'
import { isLpcHotelOffer } from 'lib/offer/offerTypes'
import { createSelector } from 'reselect'

export const getDefaultUserDriverCategory = createSelector(
  (state: App.State) => state.auth.account.dob,
  (dateOfBirth): [App.CarHireDriverAgeCategory, number | undefined] => {
    if (dateOfBirth) {
      const years = getAge(dateOfBirth)
      if (years <= 29) {
        return [1, years]
      } else if (years >= 70) {
        return [3, years]
      } else {
        return [2, years]
      }
    }
    // default category
    return [2, undefined]
  },
)

export const isCarHireInsuranceAvailable = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (currentRegionCode) => config.CARHIRE_INSURANCE_ENABLED &&
  config.CARHIRE_INSURANCE_REGIONS.split(',').includes(currentRegionCode),
)

export const isBundledCarHireEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (_: App.State) => true, // TODO: Add optimizely feature flag
  (currentRegionCode, optimizelyFeatureEnabled) =>
    config.CARHIRE_ENABLED &&
    isEnabledForFeature(config.CARHIRE_REGIONS, currentRegionCode) &&
    config.BUNDLED_CAR_HIRE_ENABLED &&
    optimizelyFeatureEnabled,
)

export const getCartItemsEligibleForBundledCarHire = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.bedbankOffers,
  (cartItems, offers, bedbankOffers): Array<App.Checkout.InstantBookingLEHotelItem | App.Checkout.BedbankHotelItem> => {
    // We currently only support bundled car hire for LPC and Bedbank
    const itemsCountries = arrayToObject(
      cartItems,
      (item) => item.itemId,
      (item) => (isInstantBookingLEHotelItem(item) && isLpcHotelOffer(offers[item.offerId]) && offers[item.offerId]?.property?.geoData.countryCode) || (isBedbankItem(item) && bedbankOffers[item.offerId]?.property?.address.countryCode) || null,
    )

    return cartItems
      .filter(item => isInstantBookingLEHotelItem(item) || isBedbankItem(item))
      .filter(item => {
        const countryCode = itemsCountries[item.itemId]
        return countryCode != null && isEnabledForFeature(config.BUNDLED_CAR_HIRE_REGIONS, countryCode) && !!item.checkIn && !!item.checkOut
      })
  },
)

export const isCartEligibleForBundledCarHire = createSelector(
  (state: App.State) => getCartItemsEligibleForBundledCarHire(state),
  (cartItemsEligibleForBundledCarHire) => cartItemsEligibleForBundledCarHire.length > 0,
)
