import React, { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextButton from 'components/Luxkit/Button/TextButton'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { rem } from 'polished'
import moment from 'moment'
import { mediaQueryUp } from 'components/utils/breakpoint'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import { SEARCH_VERTICALS } from 'constants/search'
import { CruiseDurationOptions } from './Inputs/CruiseWhenSelect/CruiseWhenSelectHowLong'
import TextLink from 'components/Luxkit/TextLink'
import Group from 'components/utils/Group'
import LineFilterAltIcon from 'components/Luxkit/Icons/line/LineFilterAltIcon'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import { take } from 'lib/array/arrayUtils'
import Heading from 'components/Luxkit/Typography/Heading'
import Caption from 'components/Luxkit/Typography/Caption'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import Clickable from 'components/Common/Clickable'
import zIndex from 'styles/tools/z-index'
import cn from 'clsx'
import { SearchMenuStates } from 'components/Search/type'
import useScrollDirection from 'hooks/useScrollDirection'
import { useInView } from 'react-intersection-observer'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import CruiseHeaderFilterChip from 'components/Cruises/SearchPage/Filters/CruiseHeaderFilterChip'
import CruiseSelectCabinType from './CruiseSelectCabinType'
import Divider from 'components/Luxkit/Divider'
import PaneBody from 'components/Common/Pane/PaneBody'

const SectionContainer = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.eight};
  position: sticky;
  top: 0;
  margin-top: 0;
  transition: top 1s ease-in-out;
  z-index: ${zIndex.searchMenu};

  &.offScreen {
    width: 100%;
    top: -50%;
    padding: ${rem(12)};
    box-shadow: ${props => props.theme.shadow.bottom.small};
    transition: top 1s ease-in-out;
  }
  &.lifted {
    top: 0;
    padding: ${rem(12)};
    transition: top 0.5s ease-out;
    z-index: ${zIndex.stickySubHeader};
    box-shadow: ${props => props.theme.shadow.bottom.small};
  }

  ${mediaQueryUp.tablet} {
    display: none;
  }
`

const SearchContainer = styled(VerticalSpacer)`
  padding: ${rem(20)} 0;

  ${mediaQueryUp.tablet} {
    padding: ${rem(16)} 0;
  }
`

const EditGroup = styled.div`
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  padding: ${rem(8)};
  width: 100%;
`

interface Props {
  filters: App.OfferListFilters;
  className?: string;
}

function getDestinationName(locationsNames?: Array<string>) {
  if (!locationsNames?.length) return ''

  if (locationsNames.length > 1) {
    return `${locationsNames[0]} +${locationsNames.length - 1}`
  }

  return locationsNames[0]
}

function CruiseSearchFiltersMobile(props: Props) {
  const { filters } = props
  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()
  const openCruisesSearch = useCallback(() => { toggleSearchVertical(SEARCH_VERTICALS.CRUISES) }, [toggleSearchVertical])
  const [cruiseLines] = useCruiseSearchFacets({ facetTypes: ['cruise_lines'] })

  const {
    departureMonths: flexibleMonths,
    durationRange,
  } = filters

  const firstLineFilters = useMemo(() => {
    const destinationName = getDestinationName(filters.destinationNames)
    const departureName = getDestinationName(filters.departureNames)
    const data : Array<string> = []

    data.push(departureName ? `Starts in ${departureName}` : 'All departure ports')
    data.push(destinationName ? `Cruising to ${destinationName}` : 'All destinations')

    return data.join('. ')
  }, [filters.destinationNames, filters.departureNames])

  const secondLineFilters = useMemo(() => {
    const data: Array<string> = []

    const allCruiseLineNames = cruiseLines?.filter(cruiseLine => filters.cruiseLines?.includes(cruiseLine.value ?? ''))
      .map(cruiseLine => cruiseLine.name)

    if (allCruiseLineNames.length > 0) {
      const [firstName] = allCruiseLineNames
      const more = allCruiseLineNames.length > 1 ? `+${allCruiseLineNames.length - 1}` : ''
      let cruiseLineNames = firstName && `${firstName}`

      if (more) {
        cruiseLineNames = `${cruiseLineNames} ${more}`
      }

      data.push(cruiseLineNames)
    } else {
      data.push('All cruise lines')
    }

    const durations = CruiseDurationOptions.filter(opt => durationRange?.includes(opt.duration))
    const valueDuration = durations?.map(value => value.name).join(', ')
    data.push(valueDuration || 'Any duration')

    const departureMonths = flexibleMonths?.filter(Boolean) ?? []
    if (departureMonths.length) {
      const limitVisible = 1
      const formattedMonths = departureMonths?.map(month => moment(month, 'YYYY-MM').format('MMM YYYY'))
      const more = formattedMonths.length > limitVisible ? `+${formattedMonths.length - limitVisible}` : ''
      const dates = take(formattedMonths, limitVisible)

      const dateValues = more ? `${dates.join(', ')} ${more}` : dates.join(', ')

      if (dateValues) {
        data.push(dateValues)
      }
    }

    return data.join('. ')
  }, [cruiseLines, flexibleMonths, filters.cruiseLines, durationRange])

  const {
    activeMenu,
  } = useContext(GlobalSearchStateContext)
  const scrollDirection = useScrollDirection({ tolerance: 20 })
  const [toLiftRef, liftInView] = useInView()
  const isLifted = !liftInView && scrollDirection === 'up'
  const isOffScreen = !liftInView && scrollDirection !== 'up'

  return <>
    <SectionContainer
      className={cn({
        lifted: isLifted || activeMenu !== SearchMenuStates.Closed,
        offScreen: isOffScreen && activeMenu === SearchMenuStates.Closed,
      })}
    >
      <CSSBreakpoint max="mobile">
        <Group direction="vertical" gap={12}>
          <PaneBody size="xs-x">
            <Group direction="vertical" gap={12}>
              <Clickable onClick={openCruisesSearch}>
                <EditGroup>
                  <Group direction="horizontal" gap={8} verticalAlign="center" horizontalAlign="space-between">
                    <Group direction="vertical" verticalAlign="center">
                      <Heading variant="heading6">{firstLineFilters}</Heading>
                      <Caption variant="large">
                        {secondLineFilters}
                      </Caption>
                    </Group>
                    <LineSearchIcon size={20}/>
                  </Group>
                </EditGroup>
              </Clickable>

              <CruiseHeaderFilterChip
                filters={filters}
              />
            </Group>
          </PaneBody>
          <Divider kind="primary" className="divider"/>
          <PaneBody size="xs-x">
            <CruiseSelectCabinType filters={filters} isMobile />
          </PaneBody>
        </Group>
      </CSSBreakpoint>
      <CSSBreakpoint min="desktop">
        <SearchContainer gap={12}>
          <Group direction="horizontal" horizontalAlign="space-between" gap={12} verticalAlign="center">
            <VerticalSpacer gap={4}>
              <BodyText variant="medium" weight="bold">
                {firstLineFilters}
              </BodyText>
              <BodyText variant="medium" colour="neutral-two">
                {secondLineFilters}
              </BodyText>
            </VerticalSpacer>
            <TextLink size="medium" onClick={openCruisesSearch}>Edit</TextLink>
          </Group>
          <TextButton
            kind="secondary"
            variant="dark"
            startIcon={<LineFilterAltIcon/>}
            fit="full-width"
            size="small"
          >
            Sort & filter
          </TextButton>
        </SearchContainer>
      </CSSBreakpoint>
    </SectionContainer>
    <div ref={toLiftRef}/>
  </>
}

export default CruiseSearchFiltersMobile
