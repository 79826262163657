import { API_CALL } from 'actions/actionConstants'
import { AppAction } from 'actions/ActionTypes'
import { FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION } from 'actions/apiActionConstants'
import requestLuxLoyaltyEarnPointsCalculation from 'api/luxLoyalty/requestLuxLoyaltyEarnPointsCalculation'
import buildPointsKey from 'luxLoyalty/lib/pointsCalculation/buildPointsKey'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { getHasLuxPlusOrSubscriptionInCart } from 'checkout/selectors/view/luxPlusSubscription'

function submitLuxLoyaltyPointsEarnCalculationRequest(
  calculationRequest: App.LuxLoyaltyPointsEarnCalculationRequest,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const customerId = state.auth.account.memberId ?? ''
    const currency = state.geo.currentCurrency
    const pointsEarnCalculationKey = buildPointsKey(calculationRequest, customerId)
    const existingCalculation = state.luxLoyalty.pointsEarnCalculations[pointsEarnCalculationKey]
    const hasLuxPlusOrSubscriptionInCart = getHasLuxPlusOrSubscriptionInCart(state)

    if (
      !getIsLuxLoyaltyEnabled(state) ||
      existingCalculation?.data ||
      existingCalculation?.fetching ||
      existingCalculation?.error
    ) return

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_POINTS_EARN_CALCULATION,
      pointsEarnCalculationKey,
      request: () => requestLuxLoyaltyEarnPointsCalculation(calculationRequest, {
        customerId,
        currency,
        luxPlusMember: hasLuxPlusOrSubscriptionInCart,
      }),
    })
  }
}

export default submitLuxLoyaltyPointsEarnCalculationRequest
