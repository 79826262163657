import { useMemo, useEffect } from 'react'
import moment from 'moment'
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks'
import { getBundlePackagesFilteredByRate, getBundlePackagesFilteredBySearchPrices } from 'lib/bundleAndSave/getBundlePackagesFilteredByRate'
import { min } from 'lib/array/arrayUtils'
import { FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { fetchBestPriceForOffer } from 'actions/OfferActions'
import { isReservationAvailableForRoom } from 'lib/checkout/cartReservationUtils'
import { getBundlePackagesWithPrice } from 'lib/bundleAndSave/getBundlePackagesWithPrice'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { buildSuggestedDatesParamsKey, convertFlexibleDatesToTravelDates, buildSearchParamsKey } from 'lib/search/searchUtils'
import { EmptyObject } from 'lib/object/objectUtils'

function useBestPriceBundleAndSavePackage(
  offer: App.BundleOffer,
  bundledOfferId: string,
  filters?: App.OfferListFilters,
  directSearchPrices?: App.OfferListMetadataPricing,
  offerMetaData?: App.OfferListMetaData,
): {
  bestPricePackage: App.BundlePackageWithPrice | undefined,
  bestPriceError: any,
  fetching: boolean,
  soldOut: boolean,
  available: boolean,
  useBestPrice: boolean,
} {
  const dispatch = useAppDispatch()
  const bestPrices = useAppSelector(state => state.offer.offerBestPrices[offer?.id]) ?? EmptyObject
  const pricesErrors = useAppSelector(state => state.offer.offerPricesErrors[offer?.id]) ?? EmptyObject

  const rooms = useMemo(() => filters?.rooms ?? [], [filters])

  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(filters?.flexibleMonths, filters?.flexibleNights, rooms)
  const suggestedDates = useAppSelector(state => getSuggestedDates(state, flexibleSearchFilterKey, offer.id))

  let checkIn: string | undefined = undefined
  let checkOut: string | undefined = undefined
  let duration: number | undefined = undefined

  if (suggestedDates?.checkIn && suggestedDates?.checkOut) {
    checkIn = suggestedDates.checkIn
    checkOut = suggestedDates.checkOut
  } else if (filters?.flexibleNights && filters?.flexibleMonths) {
    const data = convertFlexibleDatesToTravelDates(filters.flexibleNights as FLEXIBLE_DURATION_RANGE, filters.flexibleMonths)
    checkIn = data.checkIn
    checkOut = data.checkOut
  } else if (filters?.checkIn && filters?.checkOut) {
    checkIn = filters.checkIn
    checkOut = filters.checkOut
  }

  const canUseSearchPrices = !!directSearchPrices && !!offerMetaData
  const isSearchAvailable = !!offerMetaData?.available
  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, rooms, bundledOfferId), [checkIn, checkOut, rooms, bundledOfferId])
  const useBestPrice = !!(checkIn && checkOut && rooms)
  const bestPrice = bestPrices[searchKey]
  const bestPriceError = pricesErrors[searchKey]
  const available = bestPrice?.available || isSearchAvailable
  const rate = available ? bestPrice?.rate : undefined
  const fetching = !bestPrice && !bestPriceError && useBestPrice && !canUseSearchPrices
  const soldOut = bestPrice && !available

  if (checkIn && checkOut) {
    duration = moment(checkOut).diff(checkIn, 'days')
  }

  let packages = offer?.packages?.filter((pkg) => !rooms.length || rooms.every(room => pkg.roomRate && isReservationAvailableForRoom(room, pkg.roomRate.capacities, offer?.bundledOffers[pkg.offerId])))

  const occupanciesInvalid = !rooms.length || rooms.some(item => item.childrenAge?.some(age => age < 0))

  if (checkIn && checkOut && duration) {
    const allPackagesBySearchOfferId = offer?.packages?.filter(x => x.offerId === bundledOfferId && x.duration === duration) ?? []
    const allOtherPackages = offer?.packages?.filter(x => x.offerId !== bundledOfferId) ?? []

    packages = [...allPackagesBySearchOfferId, ...allOtherPackages]
  }

  const bestPricePackage = useMemo(() => {
    if (packages && (fetching || bestPriceError || soldOut || (!rate && !canUseSearchPrices))) {
      const bundlePackagesWithPrice = getBundlePackagesWithPrice({
        ...offer,
        packages: [...packages],
      })

      return min(bundlePackagesWithPrice, pkg => pkg.price || Number.MAX_SAFE_INTEGER)
    }

    if (available && packages && (rate || canUseSearchPrices)) {
      let bundlePackagesWithPrice: Array<App.BundlePackageWithPrice> = []

      if (rate) {
        bundlePackagesWithPrice = getBundlePackagesFilteredByRate({
          ...offer,
          packages: [...packages],
        }, rate)
      } else if (canUseSearchPrices) {
        bundlePackagesWithPrice = getBundlePackagesFilteredBySearchPrices({
          ...offer,
          packages: [...packages],
        }, offerMetaData?.pricing)
      }

      return min(bundlePackagesWithPrice, pkg => pkg.price || Number.MAX_SAFE_INTEGER)
    }
  }, [offer, packages, available, rate, fetching, soldOut, bestPriceError, canUseSearchPrices, offerMetaData?.pricing])

  useEffect(() => {
    if (useBestPrice && bundledOfferId && offer && checkIn && checkOut && !occupanciesInvalid && !canUseSearchPrices) {
      dispatch(fetchBestPriceForOffer(offer, {
        checkIn,
        checkOut,
        occupants: rooms,
        bundledOfferId,
      }))
    }
  }, [dispatch, checkIn, checkOut, rooms, occupanciesInvalid, offer, useBestPrice, bundledOfferId, canUseSearchPrices])

  return {
    bestPricePackage,
    bestPriceError,
    fetching,
    soldOut,
    available,
    useBestPrice,
  }
}

export default useBestPriceBundleAndSavePackage
