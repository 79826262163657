import React, { useCallback, useContext, useDeferredValue, useEffect, useId, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { allCountries } from 'country-telephone-data'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import Flag from 'components/Luxkit/Flag'
import { getPrefixCountry } from 'lib/geo/getPrefixCountry'
import noop from 'lib/function/noop'
import PhonePrefixItem from './PhonePrefixItem'
import TextInputOnly from '../TextInputOnly'
import InputLabelWrap from '../InputLabelWrap'
import config from 'constants/config'
import useToggle from 'hooks/useToggle'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Clickable from 'components/Common/Clickable'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import ModalContext from 'contexts/ModalContext'
import InputText from 'components/Luxkit/Typography/InputText'
import HiddenInput from '../HiddenInput'
import PhoneInputPrefixModal from './PhoneInputPrefixModal'
import TextInput from '../TextInput'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { sortBy } from 'lib/array/arrayUtils'
import { ISO2_START_ORDER } from 'constants/config/region'

const PhoneNumberInput = styled(TextInputOnly)`
  && > input {
    padding-left: ${rem(104)};
  }
`

const PrefixDropdown = styled(Clickable)`
  display: flex;
  align-items: center;
  gap: ${rem(4)};

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }
`

export interface PhonePrefixCountry {
  dialCode: string;
  format: string;
  iso2: string;
  name: string;
  priority: number;
}

interface Props {
  defaultPhoneNumber?: string;
  defaultPhonePrefix?: string;
  phoneNumber?: string;
  onNumberChange?: (e : React.ChangeEvent<HTMLInputElement>) => void;
  onPrefixChange?: (data: { phonePrefix: string, code: string}) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  prefixCountry?: string;
  phonePrefix?: string;
  placeholder?: string;
  inputRef?: any;
  name?: string;
  required?: boolean;
  requiredErrorMessage?: string;
  inputTestId?: string;
  id?: string;
  noValidationSpacing?: boolean;
  label?: string;
  className?: string;
  autoComplete?: string;
  phonePrefixOptions?: Array<PhonePrefixCountry>;
  disabled?: boolean;
  /**
   * By default the name will assume a nested object.
   * In VERY RARE circumstances you might need to change this, this prop allows
   * changing the prefix name to a flat string
   */
  prefixNameOverride?: string;
    /**
   * By default the name will assume a nested object.
   * In VERY RARE circumstances you might need to change this, this prop allows
   * changing the phone name to a flat string
   */
  phoneNameOverride?: string;
  helpText?: string;
  hidden?: boolean;
}

function PhoneInput(props: Props) {
  const {
    autoComplete = 'tel-national',
    defaultPhoneNumber,
    defaultPhonePrefix,
    phoneNumber,
    onBlur = noop,
    onFocus = noop,
    prefixCountry,
    phonePrefix,
    placeholder = 'Phone',
    inputRef,
    required,
    requiredErrorMessage,
    name,
    onNumberChange = noop,
    inputTestId,
    id: propsId,
    onPrefixChange = noop,
    label,
    noValidationSpacing,
    className,
    phonePrefixOptions,
    disabled,
    prefixNameOverride,
    phoneNameOverride,
    helpText,
    hidden,
  } = props

  const prefixTriggerRef = useRef<HTMLButtonElement>(null)
  const prefixSearchRef = useRef<HTMLInputElement>(null)
  const [isPrefixDropdownOpen, togglePrefixDropdown, , closePrefixDropdown] = useToggle()
  const [localPrefix, setLocalPrefix] = useState<string | undefined>(phonePrefix ?? defaultPhonePrefix)
  const [localCountryCode, setLocalCountryCode] = useState<string | undefined>(prefixCountry)
  const [prefixTypeahead, setPrefixTypeahead] = useState<string | undefined>()
  const [localNumber, setLocalNumber] = useState<string | undefined>(phoneNumber ?? defaultPhoneNumber ?? '')

  const formId = useId()
  const id = propsId ?? formId

  const isMobile = useScreenSizeOnly('mobile')
  const showModal = useContext(ModalContext)

  const onPrefixSelect = useCallback((country: PhonePrefixCountry) => {
    setLocalPrefix(country.dialCode)
    setLocalCountryCode(country.iso2.toUpperCase())
    setPrefixTypeahead(undefined)
    setLocalNumber('')
    onPrefixChange({
      phonePrefix: country.dialCode,
      code: country.iso2.toUpperCase(),
    })
    closePrefixDropdown()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPrefixChange])

  const allowedCountries = useMemo<Array<PhonePrefixCountry>>(() => {
    if (phonePrefixOptions) {
      return phonePrefixOptions
    }
    if (config.restrictPhoneNumbersToSupportedRegions) {
      const allowedCodes = new Set(config.regions.map(region => region.code))
      return allCountries.filter((country: PhonePrefixCountry) => (allowedCodes.has(country.iso2.toUpperCase())))
    }
    return allCountries
  }, [phonePrefixOptions])

  const onPrefixToggle = useCallback(async() => {
    if (isMobile) {
      const nextCountry = await showModal<PhonePrefixCountry | undefined>(<PhoneInputPrefixModal
        countries={allowedCountries}
        selectedCountryCode={localCountryCode || prefixCountry}
      />,
      )
      if (nextCountry) {
        onPrefixSelect(nextCountry)
      }
    } else {
      togglePrefixDropdown()
    }
  }, [allowedCountries, isMobile, localCountryCode, onPrefixSelect, prefixCountry, showModal, togglePrefixDropdown])

  const country = useMemo(() => {
    const effectiveCountryCode = localCountryCode ?? prefixCountry ?? ''
    const effectivePrefix = localPrefix ?? phonePrefix ?? ''
    return getPrefixCountry(effectiveCountryCode, effectivePrefix)
  }, [localCountryCode, localPrefix, phonePrefix, prefixCountry])

  const deferredTypehead = useDeferredValue(prefixTypeahead)
  const hiddenPrefixes = useMemo(() => {
    if (deferredTypehead) {
      const sanitizedPrefix = deferredTypehead.replace('+', '')
      const hiddenCountries = allowedCountries.filter(option =>
        !option.name.toLowerCase().includes(sanitizedPrefix.toLowerCase()) &&
        !option.dialCode.startsWith(sanitizedPrefix),
      )
      return new Set(hiddenCountries)
    }
    return new Set()
  }, [allowedCountries, deferredTypehead])

  const allowedCountriesSorted = useMemo(() => {
    return sortBy(allowedCountries, (country) => {
      const regionOrder = ISO2_START_ORDER.indexOf(country.iso2)
      if (regionOrder === -1) {
        // Put it at the bottom of the list if it's not in the desired order
        return 999
      }
      return regionOrder
    }, 'asc')
  }, [allowedCountries])

  const onPrefixDropdownClose = useCallback(() => {
    setPrefixTypeahead(undefined)
    closePrefixDropdown()
  }, [closePrefixDropdown])

  useEffect(() => {
    if (isPrefixDropdownOpen) {
      prefixSearchRef.current?.focus()
    }
  }, [isPrefixDropdownOpen])

  const onPhoneNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onNumberChange(e)
    const currentNumber = e.currentTarget.value

    if (/^[0-9\s]*$/.test(currentNumber)) {
      setLocalNumber(currentNumber)
    } else {
      e.preventDefault()
    }

    // Extra custom validation for AU mobile numbers needs to start with 04, 05, 4 or 5 and be 9 or 10 digits long
    if (
      country.prefixCountry === 'AU' && e.currentTarget.value.length > 1 &&
      ((e.currentTarget.value.length === 10 && (!e.currentTarget.value.startsWith('04') && !e.currentTarget.value.startsWith('05'))) ||
      (e.currentTarget.value.length === 9 && (!e.currentTarget.value.startsWith('4') && !e.currentTarget.value.startsWith('5'))) ||
      (e.currentTarget.value.length !== 9 && e.currentTarget.value.length !== 10))
    ) {
      e.currentTarget.setCustomValidity('Please enter an Australian mobile phone number or change to your region.')
    } else {
      e.currentTarget.setCustomValidity('')
    }
  }, [setLocalNumber, country, onNumberChange])

  const onHiddenCountryCodeChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    // while users can't type into this hidden field - auto complete can fill it in
    // so we get a change from auto-complete, make sure we handle it
    const dialCode = e.currentTarget.value.toLowerCase()
    const country = allowedCountries.find(country => country.dialCode === dialCode)
    if (country) {
      onPrefixSelect(country)
    }
  }, [allowedCountries, onPrefixSelect])

  return (<>
    <InputLabelWrap
      label={label}
      htmlFor={id}
      className={className}
      required={required}
      hidden={hidden}
    >
      <PhoneNumberInput
        startIcon={
          <PrefixDropdown
            ref={prefixTriggerRef}
            onClick={onPrefixToggle}
            disabled={disabled}
          >
            <HiddenInput
              type="text"
              disabled={disabled}
              name={prefixNameOverride ?? `${name}.prefix`}
              value={country.phonePrefix}
              autoComplete="tel-country-code"
              onChange={onHiddenCountryCodeChange}
              required={required}
            />
            <Flag data-testid={`flag-${country?.prefixCountry}`} countryCode={country?.prefixCountry}/>
            <InputText variant="text-regular">{`+${country.phonePrefix}`}</InputText>
            <LineAngleDownIcon size="XS" />
          </PrefixDropdown>
        }
        disabled={disabled}
        maxLength={15}
        minLength={7}
        id={id}
        type="tel"
        placeholder={placeholder}
        name={phoneNameOverride ?? `${name}.phone`}
        onChange={onPhoneNumberChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={phoneNumber ?? localNumber}
        ref={inputRef}
        required={required}
        requiredErrorMessage={requiredErrorMessage}
        data-testid={inputTestId}
        noValidationSpacing={noValidationSpacing}
        pattern="^[0-9\s]*$"
        autoComplete={autoComplete}
        helpText={helpText}
      />

    </InputLabelWrap>
    <DropdownList
      size="S"
      open={isPrefixDropdownOpen}
      onClose={onPrefixDropdownClose}
      anchorRef={prefixTriggerRef}
      triggerRef={prefixTriggerRef}
      placement="bottom-start"
      headerExtension={<TextInput
        autoComplete="off"
        onChange={(e) => setPrefixTypeahead(e.currentTarget.value)}
        defaultValue={prefixTypeahead}
        endIcon={<LineSearchIcon />}
        placeholder="Search by country or code"
        ref={prefixSearchRef}
        noValidationSpacing
      />}
    >
      {hiddenPrefixes.size === allowedCountries.length && <BodyText variant="medium">No matching dial codes</BodyText>}
      {allowedCountriesSorted.map(country => <PhonePrefixItem
        size="medium"
        key={country.iso2}
        selected={localCountryCode === country.iso2.toLocaleUpperCase()}
        country={country}
        onSelect={onPrefixSelect}
        hidden={hiddenPrefixes.has(country)}
      />)}
    </DropdownList>
  </>)
}

export default PhoneInput
