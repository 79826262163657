import React, { useCallback, useContext, useMemo } from 'react'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import LEOfferCard from './LEOfferCard'
import TourOfferCard from './TourOfferCard'
import TourV2OfferCard from './TourV2OfferCard'
import CruiseOfferCard from './CruiseOfferCard'
import BedbankOfferCard from './BedbankOfferCard'
import BundleAndSaveOfferCard from './BundleAndSaveOfferCard'
import offerPageURL from 'lib/offer/offerPageURL'
import VillaOfferCard from './VillaOfferCard'
import { isBedbankOffer, isBundleOffer, isCruiseOffer, isExperienceOffer, isLEHotel, isTourV1Offer, isTourV2Offer, isVillaOffer } from 'lib/offer/offerTypes'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import ExperienceOfferCard from './ExperienceOfferCard'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'

interface Props {
  idx?: number;
  className?: string;
  offer: App.AnyOffer;
  additionalOfferUrlParams?: string;
  size: 'small' | 'medium';
  selectedDatesAndRoomsFilter?: App.OfferListFilters;
}

function OfferCardMapper(props: Props) {
  const { offer, size, idx, className, additionalOfferUrlParams, selectedDatesAndRoomsFilter } = props
  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  const onClick = useCallback(() => {
    if (idx !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productClick,
        offer,
        position: idx,
        key: EventDataKey.ProductClick,
      })
    }
  }, [idx, offer, dispatchOfferListEvent])

  const offerUrl = useMemo(() => {
    return offerPageURL(offer) + (additionalOfferUrlParams ? `?${additionalOfferUrlParams}` : '')
  }, [offer, additionalOfferUrlParams])
  const isUrgencySavingUpsPricingHomepageSearch = !!useOptimizelyExperiment(OptimizelyExperiments.croUrgencySavingUpsPricingHomepageSearch)
  if (isLEHotel(offer)) {
    return <LEOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
      selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter}
      isUrgencySavingUpsPricingHomepageSearch={isUrgencySavingUpsPricingHomepageSearch}
    />
  } else if (isBundleOffer(offer)) {
    return <BundleAndSaveOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isBedbankOffer(offer)) {
    return <BedbankOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
      selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter}
    />
  } else if (isTourV1Offer(offer)) {
    return <TourOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isTourV2Offer(offer)) {
    return <TourV2OfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isCruiseOffer(offer)) {
    return <CruiseOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isVillaOffer(offer)) {
    return <VillaOfferCard
      offer={offer}
      onClick={onClick}
      className={className}
      offerUrl={offerUrl}
      size={size}
    />
  } else if (isExperienceOffer(offer)) {
    return <ExperienceOfferCard
      id={offer.id}
      index={idx}
      size={size}
      className={className}
      onClick={onClick}
    />
  }

  return null
}

export default OfferCardMapper
