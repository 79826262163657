import { isLPCTacticalEnabled } from 'lib/offer/offerUtils'
import {
  OfferPackageOptionView,
  SortOrderDefinedOfferPackageOptionView,
} from 'selectors/offerPage/offerPageSelectors'
import { partitionBy, sortBy, groupBy } from 'lib/array/arrayUtils'

export interface PackageOptionsByRoom {
  roomId: string,
  packageViews: Array<OfferPackageOptionView>
}

const isSortOrderDefined = (opt: OfferPackageOptionView): opt is SortOrderDefinedOfferPackageOptionView => !!opt.package.sortOrder
const isAvailable = (opt: OfferPackageOptionView) => opt.hotelAvailable && opt.capacityValid

const sortOrderDefinedOptions = (options: Array<SortOrderDefinedOfferPackageOptionView>) => {
  return options.toSorted((a, b) => {
    // Sort by sortOrder first
    if (a.package.sortOrder < b.package.sortOrder) return -1
    if (a.package.sortOrder > b.package.sortOrder) return 1
    // Sort by price if sortOrder is the same
    return a.hotelPrice - b.hotelPrice
  })
}

/**
 * Sort list of package options based on 'sortOrder' logic
 */
export function sortPackageOptions(packageOptions: Array<OfferPackageOptionView>) {
  const hasTactical = packageOptions.some(p => p.package.hasTactical)
  const [availablePackageOptions, unavailablePackageOptions] = partitionBy(packageOptions, isAvailable)
  const [definedOrderPackageOptions, undefinedOrderPackageOptions] = partitionBy<OfferPackageOptionView, SortOrderDefinedOfferPackageOptionView>(availablePackageOptions, isSortOrderDefined)
  const [definedOrderUnavailablePackageOptions, undefinedOrderUnavailablePackageOptions] = partitionBy<OfferPackageOptionView, SortOrderDefinedOfferPackageOptionView>(unavailablePackageOptions, isSortOrderDefined)

  let primaryPackageOptions = sortOrderDefinedOptions(definedOrderPackageOptions)
  let secondaryPackageOptions = sortBy(undefinedOrderPackageOptions, opt => opt.hotelPrice, 'asc')
  let tertiaryPackageOptions = sortBy(undefinedOrderUnavailablePackageOptions, opt => opt.hotelPrice, 'asc')
  let quaternaryPackageOptions = sortOrderDefinedOptions(definedOrderUnavailablePackageOptions)

  if (isLPCTacticalEnabled() && hasTactical) {
    secondaryPackageOptions = sortBy(secondaryPackageOptions, opt => opt.package.hasTactical ? 1 : 2, 'asc')
    tertiaryPackageOptions = sortBy(tertiaryPackageOptions, opt => opt.package.hasTactical ? 1 : 2, 'asc')
    quaternaryPackageOptions = sortBy(quaternaryPackageOptions, opt => opt.package.hasTactical ? 1 : 2, 'asc')
    primaryPackageOptions = sortBy(primaryPackageOptions, opt => opt.package.hasTactical ? 1 : 2, 'asc')
  }

  return [...primaryPackageOptions, ...secondaryPackageOptions, ...tertiaryPackageOptions, ...quaternaryPackageOptions]
}

/**
 * The logic for organising the order of rooms and the package options for that room.
 *
 * @remarks
 * - 'sortOrder' is an optional package option field
 * - By sorting the packages first, we take advantage of 'Map' insertion order
 *   This means we can guarantee the order of rooms is also sorted
 *
 * @param packageOptions - List of package options to be organised
 * @returns organised list of PackageOptionByRoom
 */
export function buildRoomPackageOptionsViewOrder(packageOptions: Array<OfferPackageOptionView>) {
  const sortedPackageOptions = sortPackageOptions(packageOptions)
  const sortedPackageOptionsByRoomMap = groupBy(sortedPackageOptions, option => option.package.roomType?.id ?? '')
  return Array.from(
    sortedPackageOptionsByRoomMap.entries()).reduce<Array<PackageOptionsByRoom>>((acc, [key, val]) => {
      return [...acc, { roomId: key, packageViews: val }]
    }, [])
}

/**
 * Using the same logic as buildRoomPackageOptionsViewOrder just with additional sorting step:
 * Options in LPC should go in the next way:
 * - the cheapest options
 * - options with bonus inclusions
 * - options without bonus inclusions
 *
 * @param packageOptions - List of package options to be organised
 * @param LPCAdvancedSortingEnabled
 * @returns organised list of PackageOptionByRoom
 */
export function buildRoomPackageOptionsViewOrderLPC(packageOptions: Array<OfferPackageOptionView>, LPCAdvancedSortingEnabled: boolean) {
  const sortedPackageOptions = sortPackageOptions(packageOptions)
  const sortedPackageOptionsByRoomMap = groupBy(sortedPackageOptions, option => option.package.roomType?.id ?? '')
  return Array.from(
    sortedPackageOptionsByRoomMap.entries()).reduce<Array<PackageOptionsByRoom>>((acc, [key, val]) => {
      let options = val
      if (LPCAdvancedSortingEnabled && options.length > 1) {
        const optionsWithBonusInclusions: Array<OfferPackageOptionView> = []
        const optionsWithoutBonusInclusions: Array<OfferPackageOptionView> = []

        for (const option of options) {
          if (option.package?.bonusInclusions.length || option.package?.luxPlusInclusionsByTier !== undefined) {
            optionsWithBonusInclusions.push(option)
          } else {
            optionsWithoutBonusInclusions.push(option)
          }
        }
        options = [...optionsWithBonusInclusions, ...optionsWithoutBonusInclusions]
      }

      return [...acc, { roomId: key, packageViews: options }]
    }, [])
}
