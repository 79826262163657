/**
 * This experience id matches the current ultra lux airport transfer
 * This is the transfer agents will purchase for users when they redeem
 * a airport transfer loyalty benefit
 *
 * Note: this is the prod ID, there is no equiv in test
 */
export const magicLoyaltyTransferExperienceId = 'led:006Ii000002ItIXIA0'
/**
 * This matches the exact ticket ID of the experience above that is to be made 'free'
 * when redeeming an aiport loyalty benefit
 *
 * NOTE: Ticket ids are stable across location/date changes, at least for this experience
 */
export const magicLoyaltyTransferExperienceTicketId = 'a0sIi000000c46kIAA'
