import { createReducer } from 'lib/redux/reducerUtils'
import { ValueOf } from 'type-fest'

export enum ExperienceOfferPageActions {
  setDate = 'setDate',
  setTime = 'setTime',
  setPickupPoint = 'setPickupPoint',
  setTicketCounts = 'setTicketCounts',
  setRedemptionLocation = 'setRedemptionLocation',
  setLanguage = 'setLanguage',
  setTicketMode = 'setTicketMode'
}

export interface ExperienceOfferPageState {
  date: string | undefined
  time: string | undefined
  pickupPoint: App.ExperiencePickupPoint | undefined
  ticketCounts: Record<string, number>
  redemptionLocation: App.ExperienceRedemptionLocation | undefined
  language: App.ExperienceLanguage | undefined
  ticketMode: App.ExperienceTicketMode | undefined
}

export const DEFAULT_MAX_TOUR_V2_VARIATIONS_TO_SHOW = 4

export const INITIAL_EXPERIENCE_PAGE_STATE: ExperienceOfferPageState = {
  date: undefined,
  time: undefined,
  pickupPoint: undefined,
  ticketCounts: {},
  redemptionLocation: undefined,
  language: undefined,
  ticketMode: undefined,
}

export type ExperienceOfferPageDispatchAction = ValueOf<Utils.FullActionMap<{
  [ExperienceOfferPageActions.setDate]: { date?: string },
  [ExperienceOfferPageActions.setTime]: { time: string },
  [ExperienceOfferPageActions.setPickupPoint]: { pickupPoint?: App.ExperiencePickupPoint },
  [ExperienceOfferPageActions.setTicketCounts]: { ticketCounts: Record<string, number> },
  [ExperienceOfferPageActions.setRedemptionLocation]: { redemptionLocation?: App.ExperienceRedemptionLocation },
  [ExperienceOfferPageActions.setLanguage]: { language: App.ExperienceLanguage },
  [ExperienceOfferPageActions.setTicketMode]: { ticketMode?: App.ExperienceTicketMode }
}>>

const experienceOfferPageStateReducer = createReducer<ExperienceOfferPageState, ExperienceOfferPageDispatchAction>(INITIAL_EXPERIENCE_PAGE_STATE, {
  [ExperienceOfferPageActions.setDate]: (_state, action) => ({
    date: action.date,
    time: undefined,
    ticketCounts: {},
  }),
  [ExperienceOfferPageActions.setTime]: (_state, action) => ({
    time: action.time,
    ticketCounts: {},
  }),
  [ExperienceOfferPageActions.setPickupPoint]: (_state, action) => ({
    pickupPoint: action.pickupPoint,
  }),
  [ExperienceOfferPageActions.setTicketCounts]: (_state, action) => ({
    ticketCounts: action.ticketCounts,
  }),
  [ExperienceOfferPageActions.setRedemptionLocation]: (_state, action) => ({
    redemptionLocation: action.redemptionLocation,
  }),
  [ExperienceOfferPageActions.setLanguage]: (_state, action) => ({
    language: action.language,
  }),
  [ExperienceOfferPageActions.setTicketMode]: (_state, action) => ({
    ticketMode: action.ticketMode,
  }),
})

export default experienceOfferPageStateReducer
