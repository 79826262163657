import { createSelector } from 'reselect'
import { getVillaViews } from '../view/villa'
import { nonNullable, sum } from 'lib/array/arrayUtils'
import { mergeFees } from 'checkout/lib/utils/payments/fees'

export const getVillaTotals = createSelector(
  (state: App.State) => getVillaViews(state),
  (views): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    return {
      hasRequiredData: views.hasRequiredData,
      data: {
        price: sum(views.data, data => data.totals.price),
        memberPrice: 0,
        value: sum(views.data, data => data.totals.value),
        surcharge: sum(views.data, data => data.totals.surcharge),
        taxesAndFees: sum(views.data, data => data.totals.taxesAndFees),
        propertiesFees: nonNullable(views.data.flatMap(data => data.totals.propertiesFees)),
        otherFees: mergeFees(nonNullable(views.data.flatMap(data => data.totals.otherFees))),
        memberValue: 0,
        extraGuestSurcharge: 0,
      },
    }
  },
)
