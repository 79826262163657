import { createSelector } from 'reselect'
import {
  getTourV2IdForCheckoutItem,
  isLuxPlusSubscriptionItem,
  isTourV2ExperienceItem,
  isSubscriptionJoinItem,
  isTourV2Item, isInsuranceItem,
  findPostPurchaseCheckout,
  isBedbankItem,
} from 'lib/checkout/checkoutUtils'
import config from 'constants/config'
import { PAYMENT_SCHEDULE_ENABLED_REGIONS } from 'constants/config/region'
import { getTourV2CheckoutItems } from 'checkout/selectors/view/accommodation'
import { sum } from 'lib/array/arrayUtils'
import { getAllOffers } from 'selectors/offerSelectors'

export const isPaymentScheduleEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (_state: App.State) => config.PAYMENT_SCHEDULE_ENABLED,
  (currentRegionCode, isEnabled) => {
    if (!isEnabled) {
      return false
    }

    if (!PAYMENT_SCHEDULE_ENABLED_REGIONS.includes(currentRegionCode)) {
      return false
    }

    return true
  },
)

const isItemAllowedWithPaymentSchedules = (item: App.Checkout.AnyItem): boolean => {
  return isTourV2Item(item) ||
  isTourV2ExperienceItem(item) ||
  isLuxPlusSubscriptionItem(item) ||
  isSubscriptionJoinItem(item) ||
  isInsuranceItem(item) ||
  (isBedbankItem(item) && !!item.bundledItemIds?.length)
}

const canGetItemsPaymentScheduleInfo = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => getAllOffers(state),
  (state: App.State) => state.checkout.cart.isGift,
  (items, offers, isGift): Array<boolean> | false => {
    if (isGift || (!items.every(isItemAllowedWithPaymentSchedules))) {
      return false
    }

    const tourV2Items = items.filter(isTourV2Item)

    return tourV2Items.map((item) => {
      const tourV2Offer = offers[getTourV2IdForCheckoutItem(item)] as App.Tours.TourV2Offer | undefined
      const templateId = tourV2Offer?.paymentScheduleInfo?.templateId
      return !!templateId
    })
  },
)

const isAnyItemDisabledForPaymentSchedule = createSelector(
  canGetItemsPaymentScheduleInfo,
  (itemsPaymentScheduleInfo): boolean => !itemsPaymentScheduleInfo || itemsPaymentScheduleInfo.some(item => !item),
)

export const isPaymentScheduleAvailable = createSelector(
  (state: App.State) => isPaymentScheduleEnabled(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => isAnyItemDisabledForPaymentSchedule(state),
  (
    isPaymentScheduleEnabled,
    postPurchase,
    isAnyItemDisabledForPaymentSchedule,
  ): boolean => {
    const result = (
      isPaymentScheduleEnabled &&
      !postPurchase &&
      !isAnyItemDisabledForPaymentSchedule
    )
    return result
  },
)

export const getPaymentScheduleTemplateIdFromTourV2Offer = createSelector(
  (state: App.State) => state.offer.tourV2Offers,
  getTourV2CheckoutItems,
  (tourV2Offers, tourV2Items): string | undefined | null => {
    const item = tourV2Items.find(item => {
      const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)]
      return offer?.paymentScheduleInfo?.templateId
    })

    if (!item) {
      return undefined
    }

    return tourV2Offers[getTourV2IdForCheckoutItem(item)]?.paymentScheduleInfo?.templateId
  },
)

export const getServiceFeeForInitialPayment = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): number => {
    if (!paymentSchedule.eligible || !paymentSchedule.payment_schedules) {
      return 0
    }
    return paymentSchedule.payment_schedules[0].feeAmount || 0
  },
)

export const getPaymentScheduleTotalServiceFee = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): number => {
    if (!paymentSchedule.eligible || !paymentSchedule.payment_schedules) {
      return 0
    }
    return sum(paymentSchedule.payment_schedules, (schedule) => schedule.feeAmount)
  },
)

export const getServiceFeeForLaterPayments = createSelector(
  getPaymentScheduleTotalServiceFee,
  getServiceFeeForInitialPayment,
  (totalServiceFee, initialServiceFee): number => {
    return totalServiceFee - initialServiceFee
  },
)

export const getServiceFeePaymentScheduleData = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): App.PaymentScheduleServiceFeeData | undefined => {
    const chargeIndex = paymentSchedule.payment_schedules.findIndex(schedule => schedule.feeAmount > 0)

    if (chargeIndex === -1) {
      return undefined
    }

    return {
      chargeIndex,
      feeAmount: paymentSchedule.payment_schedules[chargeIndex].feeAmount,
      paymentType: paymentSchedule.payment_schedules[chargeIndex].paymentType,
    }
  },
)
