import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'

const getCanRedeemInsuranceLoyaltyBenefit = createSelector(
  (state: App.State) => state.luxLoyalty.account.data?.benefits,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (userBenefits, isPostPurchase): boolean => {
    const insuranceBenefit = userBenefits?.find(benefit => benefit.code === 'discounted_insurance')
    return !isPostPurchase && !!insuranceBenefit && (insuranceBenefit.unlimited || !!insuranceBenefit.remaining)
  },
)

export default getCanRedeemInsuranceLoyaltyBenefit
