import React from 'react'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import { rem } from 'polished'
import styled from 'styled-components'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import FormatCurrency from 'components/Common/FormatCurrency'
import Heading from 'components/Luxkit/Typography/Heading'
import Caption from 'components/Luxkit/Typography/Caption'
import TextButton from 'components/Luxkit/Button/TextButton'
import { BookingProtectionContent } from './BookingProtectionComparison'
import Divider from 'components/Luxkit/Divider'
import PriceRowLuxPlusPrice from 'components/Luxkit/PricePoints/LuxPlus/PriceRowLuxPlusPrice'

const TableHeader = styled(Group)`
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  padding-top: ${rem(20)};
  padding-bottom: ${rem(8)};
  height: ${rem(70)};
`
const LimitedProtectionColumn = styled(Group)`
  min-width: ${rem(205)};
  max-width: ${rem(252)};
`

const EnhancedProtectionColumn = styled(Group)`
  border: 1px solid ${(props) => props.theme.palette.highlight.secondary.normalForeground};
  margin-top: -1px;
  box-shadow: ${(props) => props.theme.shadow.top.small};
  min-width: ${rem(205)};
  max-width: ${rem(256)};
`

const EnhancedProtectionBottom = styled.div`
  padding-top: ${rem(12)};
  padding-bottom: ${rem(20)};
`

const StyledPromotionLabel = styled(PromotionLabel)`
  position: absolute;
  top: -${rem(12)};
`

const EnhancedProtectionButton = styled(Group)`
  padding: ${rem(8)} ${rem(20)};
`

const TableCellGroup = styled(Group)`
  padding: ${rem(8)} ${rem(20)};
  height: ${rem(50)};
`

const HeadingCellGroup = styled(Group)`
  height: ${rem(50)};
`

const HeadingGroup = styled(Group)`
  min-width: ${rem(115)};
  max-width: ${rem(165)};
`

interface Props {
  bookingProtectionContent: Array<BookingProtectionContent>;
  bookingProtectionQuotePricePerTraveller?: number;
  bookingProtectionSelected?: boolean;
  checkoutWithDiscountedBookingProtection: boolean;
  bookingProtectionQuoteLuxPlusPricePerTraveller: number;
  bookingProtectionQuotePrice: number;
  bookingProtectionQuoteLuxPlusPrice: number;
  isStandaloneExperience: boolean;
  quote?: App.InsuranceQuote;
  onAddCFMRBookingProtection: () => void;
  onAddBookingProtection: () => void;
}

function BookingProtectionComparisonTable(props: Props) {
  const {
    bookingProtectionContent,
    bookingProtectionQuotePricePerTraveller = 0,
    bookingProtectionSelected,
    checkoutWithDiscountedBookingProtection,
    bookingProtectionQuoteLuxPlusPricePerTraveller,
    bookingProtectionQuotePrice,
    bookingProtectionQuoteLuxPlusPrice,
    isStandaloneExperience,
    quote,
    onAddCFMRBookingProtection,
    onAddBookingProtection,
  } = props

  const bookingProtectionContentLast = bookingProtectionContent.length - 1

  return (
    <Group direction="horizontal" horizontalAlign="stretch">
      <HeadingGroup direction="vertical">
        <TableHeader direction="vertical"></TableHeader>
        {bookingProtectionContent.map((item, index) => (
          <Group
            direction="vertical"
            key={index}
          >
            {index === 0 && <Divider kind="primary" />}
            <HeadingCellGroup direction="vertical" verticalAlign="center" horizontalAlign="start">
              <BodyText variant="small" weight="bold">
                {item.heading}
              </BodyText>
            </HeadingCellGroup>
            {index !== bookingProtectionContentLast && <Divider kind="primary" />}
          </Group>
        ))}
      </HeadingGroup>

      <LimitedProtectionColumn direction="vertical">
        <TableHeader direction="vertical">
          <BodyText align="center" variant="medium" weight="bold">
            Luxury Escapes policy
          </BodyText>
          <BodyText align="center" variant="medium">
            Included
          </BodyText>
        </TableHeader>

        {bookingProtectionContent.map((item, index) => (
          <Group
            direction="vertical"
            key={index}
          >
            {index === 0 && <Divider kind="primary" />}
            <TableCellGroup direction="horizontal" gap={8} verticalAlign="center" horizontalAlign="start">
              {item.limitedProtectionIcon}
              <BodyText variant="small">
                {item.limitedProtectionText}
              </BodyText>
            </TableCellGroup>
            {index !== bookingProtectionContentLast && <Divider kind="primary" />}
          </Group>
        ))}
      </LimitedProtectionColumn>

      <EnhancedProtectionColumn direction="vertical">
        <TableHeader direction="vertical">
          <StyledPromotionLabel variant="premium">
            Recommended
          </StyledPromotionLabel>
          <Group direction="vertical" horizontalAlign="center">
            <BodyText
              colour="highlight-secondary"
              align="center"
              variant="medium"
              weight="bold"
            >
              Cancellation Protection terms
            </BodyText>
            {checkoutWithDiscountedBookingProtection && (
              <>
                {quote?.productId === 'CFMR' && (
                  <PriceRowLuxPlusPrice
                    memberPrice={quote.luxPlusTotalPerPerson}
                    saleUnit="person"
                    size="M"
                    priceFormat="dynamicDollar"
                  />
                )}
                {!isStandaloneExperience && quote?.productId !== 'CFMR' && (
                  <PriceRowLuxPlusPrice
                    memberPrice={bookingProtectionQuoteLuxPlusPricePerTraveller}
                    saleUnit="person"
                    size="M"
                    priceFormat="dynamicDollar"
                  />
                )}

                {isStandaloneExperience && quote?.productId !== 'CFMR' && (
                  <PriceRowLuxPlusPrice
                    memberPrice={bookingProtectionQuoteLuxPlusPrice}
                    saleUnit="total"
                    size="M"
                    priceFormat="dynamicDollar"
                  />
                )}
              </>
            )}
            {!checkoutWithDiscountedBookingProtection && (
              <>
                {quote?.productId === 'CFMR' && (
                  <Heading variant="heading5">
                    <FormatCurrency value={quote.totalPerPerson} />
                    <Caption variant="large" as="span">{' '}/person</Caption>
                  </Heading>
                )}
                {!isStandaloneExperience && quote?.productId !== 'CFMR' && (
                  <Heading variant="heading5">
                    <FormatCurrency value={bookingProtectionQuotePricePerTraveller} />
                    <Caption variant="large" as="span">{' '}/person</Caption>
                  </Heading>
                )}
                {isStandaloneExperience && quote?.productId !== 'CFMR' && (
                  <Heading variant="heading5">
                    <FormatCurrency value={bookingProtectionQuotePrice} />
                    <Caption variant="large" as="span">{' '}/total</Caption>
                  </Heading>
                )}
              </>
            )}
          </Group>
        </TableHeader>
        {bookingProtectionContent.map((item, index) => (
          <Group
            direction="vertical"
            key={index}
          >
            {index === 0 && <Divider kind="primary" />}
            <TableCellGroup direction="horizontal" gap={8} verticalAlign="center" horizontalAlign="start">
              {item.enhancedProtectionIcon}
              <BodyText variant="small">
                {item.enhancedProtectionText}
              </BodyText>
            </TableCellGroup>
            {index !== bookingProtectionContentLast && <Divider kind="primary" />}
          </Group>
        ))}
        <EnhancedProtectionBottom>
          <EnhancedProtectionButton gap={20} horizontalAlign="stretch" direction="vertical">
            <TextButton kind="secondary" onClick={quote?.productId === 'CFMR' ? onAddCFMRBookingProtection : onAddBookingProtection} isSelected={!!bookingProtectionSelected}>
              {bookingProtectionSelected ? 'Added' : 'Add protection'}
            </TextButton>
          </EnhancedProtectionButton>
        </EnhancedProtectionBottom>
      </EnhancedProtectionColumn>
    </Group>
  )
}

export default BookingProtectionComparisonTable
