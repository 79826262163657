import FilterChip from 'components/Luxkit/Chips/FilterChip'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import LineAngleUpIcon from 'components/Luxkit/Icons/line/LineAngleUpIcon'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import Group from 'components/utils/Group'
import { replace } from 'connected-react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import { getCheckInDateFromURLSearchParams, getCheckOutDateFromURLSearchParams, propertyToDestinationSearch, setSearchParamValue } from 'lib/url/searchUrlUtils'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Modal from 'components/Luxkit/Modal/Modal'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import { queryKeyPriceLte } from 'constants/url'
import { formatCurrency } from 'lib/format/formatCurrencyIntl'
import GeoContext from 'contexts/geoContext'
import LayoutContainer from 'components/Common/LayoutContainer'

const SHOW_ALL_BUDGET = '10000'
const SHOULD_DEFAULT = '0'

interface Props {
  filters: App.OfferListFilters
  search: string
  label: string
  currentBudget: App.BusinessTraveller.BusinessBudget | undefined
}

function BusinessTravellerWithinBudgetDropdown(props: Props) {
  const { filters, search, label, currentBudget } = props
  const geo = useContext(GeoContext)
  const dispatch = useAppDispatch()
  const isMobile = useScreenSizeOnly('mobile')
  const [isDropdownOpen, toggleDropDown, , closeDropdown] = useToggle()
  const filterChipRef = React.useRef<HTMLButtonElement>(null)

  const { checkInDate, checkOutDate } = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return {
      checkInDate: getCheckInDateFromURLSearchParams(searchParams),
      checkOutDate: getCheckOutDateFromURLSearchParams(searchParams),
    }
  }, [search])

  const totalNights = checkOutDate?.diff(checkInDate, 'days')
  const totalBudget = useMemo(() => (currentBudget?.budget ?? 0) * (totalNights ?? 0), [currentBudget?.budget, totalNights])
  const tenOverBudget = useMemo(() => Math.ceil(totalBudget * 1.1), [totalBudget])
  const twentyOverBudget = useMemo(() => Math.ceil(totalBudget * 1.2), [totalBudget])

  const selectedBudget = filters?.priceLte ?? SHOULD_DEFAULT

  const budgetFilterOptions = useMemo(() => [
    {
      label: 'Within budget',
      value: totalBudget.toString(),
    },
    {
      label: 'Up to 10% above budget',
      value: tenOverBudget.toString(),
    },
    {
      label: 'Up to 20% above budget',
      value: twentyOverBudget.toString(),
    },
    {
      label: 'Show all',
      value: SHOW_ALL_BUDGET,
    },
  ], [tenOverBudget, totalBudget, twentyOverBudget])

  const hasBudgetBeenSelected = !!selectedBudget && selectedBudget !== SHOULD_DEFAULT
  const isShowAll = selectedBudget === SHOW_ALL_BUDGET

  const isSelectedBudgetValid = useMemo(() => {
    return budgetFilterOptions.some(option => option.value === filters?.priceLte)
  }, [budgetFilterOptions, filters?.priceLte])

  useEffect(() => {
    // Default to 10% over budget if no filter is set
    if ((!hasBudgetBeenSelected || !isSelectedBudgetValid) && totalBudget > 0) {
      dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(search, queryKeyPriceLte, tenOverBudget)) }))
    }
  }, [dispatch, hasBudgetBeenSelected, isSelectedBudgetValid, search, tenOverBudget, totalBudget])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(search, queryKeyPriceLte, e.target.value)) }))
    closeDropdown()
  }, [closeDropdown, dispatch, search])

  const formattedBudget = useMemo(() => formatCurrency(Number(selectedBudget), geo.currentCurrency, geo.currentRegionCode), [geo.currentCurrency, geo.currentRegionCode, selectedBudget])

  const chipLabel = label + (hasBudgetBeenSelected && formattedBudget && !isShowAll ? ` (${formattedBudget})` : '')

  if (totalBudget === 0 || !isSelectedBudgetValid) {
    return null
  }

  return (
    <div>
      <FilterChip
        selected={hasBudgetBeenSelected && !isShowAll}
        ref={filterChipRef}
        size="medium"
        onClick={toggleDropDown}
        endIcon={isDropdownOpen ? <LineAngleUpIcon /> : <LineAngleDownIcon />}
      >
        {chipLabel}
      </FilterChip>
      <CSSBreakpoint min="tablet">
        <DropdownList
          size="S"
          triggerRef={filterChipRef}
          anchorRef={filterChipRef}
          open={isDropdownOpen}
          placement="bottom-start"
          onClose={closeDropdown}
        >
          <LayoutContainer>
            <Group direction="vertical" gap={16}>
              {budgetFilterOptions.map(option => (
                <RadioInput
                  key={option.label}
                  checked={option.value === selectedBudget}
                  onChange={onChange}
                  name={option.label}
                  value={option.value}
                >
                  {option.label}
                </RadioInput>
              ))}
            </Group>
          </LayoutContainer>
        </DropdownList>
      </CSSBreakpoint>
      <CSSBreakpoint max="mobile">
        <Modal
          title={label}
          isOpen={isDropdownOpen && isMobile}
          onClose={closeDropdown}
          primaryActionText="Apply"
          onPrimaryActionClick={closeDropdown}
        >
          <Group direction="vertical" gap={16}>
            {budgetFilterOptions.map(option => (
              <RadioInput
                key={option.label}
                checked={option.value == selectedBudget}
                onChange={onChange}
                name={option.label}
                value={option.value}
              >
                {option.label}
              </RadioInput>
            ))}
          </Group>
        </Modal>
      </CSSBreakpoint>
    </div>
  )
}

export default BusinessTravellerWithinBudgetDropdown
