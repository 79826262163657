import Clickable from 'components/Common/Clickable'
import LuxLoyaltyTierIcon from 'components/Luxkit/Icons/luxLoyalty/LuxLoyaltyTierIcon'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import { BodyTextColour } from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import InlineIcon from 'components/utils/InlineIcon'
import FormatLuxLoyaltyPoints from 'luxLoyalty/components/Formatters/FormatLuxLoyaltyPoints'
import FormatLuxLoyaltyStatusCredits from 'luxLoyalty/components/Formatters/FormatLuxLoyaltyStatusCredits'
import React, { ComponentProps, MouseEventHandler, useCallback } from 'react'
import styled from 'styled-components'

const ClickableIcon = styled(Clickable)`
  // Vertically align the icon with text
  display: flex;
`

interface Props {
  tier: App.LuxLoyaltyTier
  earnablePoints: number
  statusCredits?: number
  size: 'S' | 'M'
  onInfoIconClick?: MouseEventHandler<HTMLButtonElement>
  calculationType: 'estimate' | 'exact' | 'additional'
}

const SIZES_BODY_TEXT_VARIANTS: Record<Props['size'], ComponentProps<typeof BodyTextBlock>['variant']> = {
  S: 'small',
  M: 'medium',
}

const TIER_BODY_TEXT_COLOURS: Record<App.LuxLoyaltyTier, BodyTextColour> = {
  bronze: 'tier-bronze-dark',
  silver: 'tier-silver-dark',
  gold: 'tier-gold-dark',
  platinum: 'tier-platinum-dark',
}

function PriceRowLuxLoyaltyPoints({
  tier,
  earnablePoints,
  statusCredits,
  size,
  onInfoIconClick,
  calculationType,
}: Props) {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()

    onInfoIconClick?.(event)
  }, [onInfoIconClick])

  return <BodyTextBlock
    variant={SIZES_BODY_TEXT_VARIANTS[size]}
    colour={TIER_BODY_TEXT_COLOURS[tier]}
    weight="bold"
    startIcon={<LuxLoyaltyTierIcon size="S" tier={tier} />}
  >
    <Group direction="horizontal" wrap="wrap" gap={4}>
      {calculationType === 'estimate' && <>Earn from <FormatLuxLoyaltyPoints abbreviated points={earnablePoints} /></>}
      {calculationType === 'exact' && <>Earn <FormatLuxLoyaltyPoints abbreviated points={earnablePoints} /></>}
      {calculationType === 'additional' && <>Earn an additional <FormatLuxLoyaltyPoints abbreviated points={earnablePoints} /></>}
      <Group direction="horizontal" verticalAlign="center" gap={4} as="span" noDisplayWhenEmpty>
        {!!statusCredits && <> + <FormatLuxLoyaltyStatusCredits statusCredits={statusCredits} /></> }
        {onInfoIconClick && <ClickableIcon onClick={handleClick}>
          <InlineIcon Icon={LineInfoCircleIcon} colour="neutral-one" />
        </ClickableIcon>}
      </Group>
    </Group>
  </BodyTextBlock>
}

export default PriceRowLuxLoyaltyPoints
