import { pushResetEcommerce } from 'analytics/enhanced-ecommerce'

/**
 * Adds an event into google tag manager,
 * could also send the event to UA only in case the event name already exists in GTM,
 * so in case of a new event it needs to be created and mapped in GTM
 * @param event Any event object
 */
export function addGTMEvent(event: any) {
  (window as any).dataLayer?.push(event)
}

export function fireEcommerceGTMEvent(event: any) {
  pushResetEcommerce();
  (window as any).dataLayer?.push(event)
}

/**
 * @typedef {Object} UAEventDef
 * @property {string} category A category is a name that you supply as a way to group objects that you want to analyze.
 * @property {string} action An action parameter is to name the type of event or interaction you want to measure.
 * @property {string} label A Label can provide additional information for events that you want to analyze. (optional, but recommended)
 * @property {number} value The value is an integer, and is used to assign a numerical value to a page object (optional)
 */

export interface UAEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

interface Event extends UAEvent {
  nonInteraction: boolean;
}

function fireGAEvent(event: Event) {
  if (typeof window !== 'undefined') {
    (window as any).dataLayer?.push({
      event: 'ua-event',
      eventCategory: event.category,
      eventAction: event.action,
      eventLabel: event.label,
      eventValue: event.value,
      nonInteraction: event.nonInteraction,
    })
  }
}

/**
   * fireNonInteractionEvent will fire a fireGAEvent without being interactive.
   * We use non interaction events when the user is not performing an action.
   * Use examples of this method are: impression events or loading events.
   * @param {UAEventDef}
**/
export function fireNonInteractionEvent(event: UAEvent) {
  fireGAEvent({
    ...event,
    nonInteraction: true,
  })
}

/**
   * fireInteractionEvent will fire an interactive fireGAEvent.
   * We use interaction events when the user is performing and action or there is a sign of interest.
   * Use example of this method are: click events.
   * @param {UAEventDef}
**/
export function fireInteractionEvent(event: UAEvent) {
  fireGAEvent({
    ...event,
    nonInteraction: false,
  })
}
