import { getAccommodationDepositSettings } from 'checkout/lib/utils/accommodation/deposit'
import { createSelector } from 'reselect'
import { getDefaultDepositAmountPercentage } from '../featureConfig/deposit'
import { getOfferViewWithStatusForDeposit } from 'checkout/selectors/payment/deposit'

const getDepositSettings = createSelector(
  (state: App.State) => getOfferViewWithStatusForDeposit(state),
  (state: App.State) => getDefaultDepositAmountPercentage(state),
  (offerViewWithStatus, defaultDepositAmountPercentage): App.Checkout.DepositSettings | undefined => {
    if (offerViewWithStatus.hasRequiredData && offerViewWithStatus.data) {
      return getAccommodationDepositSettings(offerViewWithStatus.data, defaultDepositAmountPercentage)
    }
  },
)

export default getDepositSettings
