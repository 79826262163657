import moment from 'moment'
import config from 'constants/config'
import { THREE_DAYS_BEFORE_CHECK_IN } from 'constants/cancellationPolicies'
import { ITEM_STATUS_COMPLETED } from 'constants/cart'
import { PACKAGE_CHANGE_DOWNGRADE_TYPES, DOWNGRADE_TYPE_CHANGE_DATES_TACTICAL_AO_HOTEL, DOWNGRADE_TYPE_BNBL } from 'constants/reservation'

export function canBeUpgraded(item: App.OrderItem) {
  if (!config.PACKAGE_CHANGE_UPGRADE_ENABLED) {
    return false
  }

  if (item.status === ITEM_STATUS_COMPLETED) {
    const checkInDate = item?.reservation?.startDate
    const finalDateAllowed = moment(checkInDate).subtract(THREE_DAYS_BEFORE_CHECK_IN, 'days')
    return moment().startOf('day').isSameOrBefore(finalDateAllowed)
  }
  return false
}

const bookingPreference = [...PACKAGE_CHANGE_DOWNGRADE_TYPES, DOWNGRADE_TYPE_CHANGE_DATES_TACTICAL_AO_HOTEL, DOWNGRADE_TYPE_BNBL]
export function bookingsPriority(a: App.OrderItem, b: App.OrderItem) {
  const index1 = bookingPreference.indexOf(a.downgradeType)
  const index2 = bookingPreference.indexOf(b.downgradeType)
  return index1 == -1 ? 1 : index2 == -1 ? -1 : index1 - index2
}
