import React from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'
import ClickableLink from '../Common/Clickable/ClickableLink'
import Clickable from '../Common/Clickable'
import { CaptionMedium } from './Typography/Caption'
import { BodyTextLarge, BodyTextMedium, BodyTextSmall } from './Typography/BodyText'
import { TypographyElement } from './Typography/Typography'
import { SVG_ICON_SIZE_CSS_VAR } from './SvgIcon'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const LinkElement = styled(TypographyElement)`
  display: inline;
  font-family: ${props => props.theme.font.primary.family};
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, opacity 0.2s, outline 0.2s;

  &.block {
    display: block;
  }

  &.has-icon-start {
    > :first-child {
      margin-right: ${rem(8)};
    }
  }

  &.has-icon-end {
    > :last-child {
      margin-left: ${rem(8)};
    }
  }

  &.underline {
    text-decoration: underline currentcolor;
  }

  &.wrap-no-wrap {
    white-space: nowrap;
  }

  &.wrap-word-wrap {
    word-break: break-word;
  }

  &.wrap-letter-wrap {
    word-break: break-all;
  }

  &.wrap-truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.wrap-pre-wrap {
    white-space: pre-wrap;
  }

  &.variant-default {
    color: ${props => props.theme.palette.brand.primary.normal};
    outline: 0px solid transparent;

    &:disabled {
      color:  ${props => props.theme.palette.neutral.default.four};
    }

    &:not(:disabled) {
      ${mediaHoverable} {
        &:hover {
          color: ${props => props.theme.palette.brand.primary.dark};
          text-decoration: underline currentcolor;
        }
      }

      &:focus {
        outline: var(${KEYBOARD_MODE_CSS_VAR}, 1px solid ${props => props.theme.palette.neutral.default.five});
        outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      }

      .input-keyboard &:focus {
        text-decoration: underline currentcolor;
      }

      &:active, &.toggled {
        color: ${props => props.theme.palette.brand.primary.darkest};
      }
    }
  }

  &.variant-ghost {
    color: ${props => props.theme.palette.neutral.default.eight};
    outline: 0px solid transparent;

    &:disabled {
      color:  ${props => props.theme.palette.neutral.default.four};
    }

    &:not(:disabled) {
      ${mediaHoverable} {
        &:hover {
          text-decoration: underline ${props => props.theme.palette.neutral.default.eight};
        }
      }

      &:focus {
        outline: var(${KEYBOARD_MODE_CSS_VAR}, 1px solid ${props => props.theme.palette.neutral.default.five});
        outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      }

      .input-keyboard &:focus {
        text-decoration: underline currentcolor;
      }

      &:active, &.toggled {
        opacity: 0.56;
      }
    }
  }

  &.variant-black {
    color: ${props => props.theme.palette.neutral.default.one};
    outline: 0px solid transparent;

    &:disabled {
      color:  ${props => props.theme.palette.neutral.default.four};
    }

    &:not(:disabled) {
      ${mediaHoverable} {
        &:hover {
          color: ${props => props.theme.palette.neutral.default.two};
          text-decoration: underline currentcolor;
        }
      }

    &:focus {
        outline: var(${KEYBOARD_MODE_CSS_VAR}, 1px solid ${props => props.theme.palette.neutral.default.five});
        outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      }

      .input-keyboard &:focus {
        text-decoration: underline currentcolor;
      }

      &:active, &.toggled {
        color: ${props => props.theme.palette.neutral.default.three};
      }
    }
  }

  &.variant-highlight {
    color: ${props => props.theme.palette.highlight.secondary.normalForeground};
    outline: 0px solid transparent;

    &:disabled {
      color:  ${props => props.theme.palette.neutral.default.four};
    }

    &:not(:disabled) {
      &:hover {
        text-decoration: underline currentcolor;
      }

      &:focus {
        outline: var(${KEYBOARD_MODE_CSS_VAR}, 1px solid ${props => props.theme.palette.neutral.default.five});
        outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      }

      .input-keyboard &:focus {
        text-decoration: underline currentcolor;
      }

      &:active, &.toggled {
        color: ${props => props.theme.palette.highlight.secondary.normalActive};
      }
    }
  }

  &.size-caption {
    ${CaptionMedium}
  }

  &.size-small {
    ${BodyTextSmall}
  }

  &.size-medium {
    ${BodyTextMedium}
  }

  &.size-large {
    ${BodyTextLarge}
    --link-icon-size: ${rem(20)};
  }

  &.weight-regular {
    font-weight: ${props => props.theme.font.primary.weight.regular};
  }

  &.weight-bold {
    font-weight: ${props => props.theme.font.primary.weight.superBold};
  }

  > img {
    height: var(${SVG_ICON_SIZE_CSS_VAR}, ${rem(16)});
    width: var(${SVG_ICON_SIZE_CSS_VAR}, ${rem(16)});
  }

  @media print {
    display: none;
  }
`

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * @default default
   */
  variant?: 'default' | 'ghost' | 'black' | 'highlight';
  /**
   * @default bold
   */
  weight?: 'regular' | 'bold';
  size?: 'caption' | 'small' | 'medium' | 'large';
  wrap?: 'no-wrap' | 'word-wrap' | 'letter-wrap' | 'truncate' | 'pre-wrap';
  to?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  /**
   * @default true
   */
  underline?: boolean;
  disabled?: boolean;
  /**
   * Sets the text link as a 'block' element
   * this will allow things like margin to space it
   * By default, this element is an inline element
   */
  block?: boolean;
}

const TextLink = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>((props: Props, ref) => {
  const {
    variant = 'default',
    className,
    size,
    weight = 'bold',
    startIcon,
    endIcon,
    children,
    underline = true,
    wrap,
    block,
    ...rest
  } = props

  let component: any = null
  if (rest.href || rest.to) {
    component = ClickableLink
  } else if (rest.onClick) {
    component = Clickable
  } else {
    component = 'span'
  }

  return <LinkElement
    {...rest}
    as={component}
    ref={ref}
    className={cn(
      className,
      `variant-${variant}`,
      `weight-${weight}`,
      size ? `size-${size}` : undefined,
      wrap ? `wrap-${wrap}` : undefined,
      {
        block,
        underline,
        'has-icon-start': !!startIcon,
        'has-icon-end': !!endIcon,
      },
    )}
  >
    {startIcon}
    {children}
    {endIcon}
  </LinkElement>
})

TextLink.displayName = 'TextLink'

export default TextLink
