import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import Typography, { TYPOGRAPHY_ICON_SIZE_CSS_VAR, TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from './Typography'
import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'

export const CaptionLarge = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 18px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(16)};
  font-size: 12px;

  ${mediaQueryUp.tablet} {
    font-size: 13px;
  }
`

export const CaptionMedium = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 14px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(16)};
  font-size: 11px;

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 16px;
    font-size: 12px;
  }
`

export const CaptionSmall = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 11px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(16)};
  font-size: 10px;
  font-weight: ${props => props.theme.font.primary.weight.bold};
  letter-spacing: 0.7px;
`

/**
 * Old to new
 * grey dark -> neutral three
 * grey medium -> neutral four
 * white -> neutral eight
 * error -> critical
 * black -> neutral one
 *
 */

/*
  Font sizes are express in px as they are not to be affected by the
  20% REM font decrease at mobile sizes. These font sizes are absolute sizes.
*/
const Element = styled(Typography)`
  font-family: ${props => props.theme.font.primary.family};

  &.variant-large {
    ${CaptionLarge}
  }

  &.variant-large:is(small),
  &.variant-large small,
  &.variant-medium {
    ${CaptionMedium}
  }

  &.variant-medium:is(small),
  &.variant-medium small,
  &.variant-small,
  &.variant-small:is(small),
  &.variant-small small {
    ${CaptionSmall}
  }

  &.weight-normal {
    font-weight: ${props => props.theme.font.primary.weight.regular};
  }

  &.weight-bold, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.primary.weight.bold};
  }

  &.italics, &:is(i, em), i, em {
    font-style: italic;
  }

  &.underline {
    text-decoration: underline;
  }

  &.strike-through, &:is(s), s {
    text-decoration: line-through;
  }

  &.colour-neutral-two {
    color: ${props => props.theme.palette.neutral.default.two};
  }

  &.colour-neutral-three {
    color: ${props => props.theme.palette.neutral.default.three};
  }

  &.colour-success {
    color: ${props => props.theme.palette.messaging.success.normalForeground};
  }

  &.colour-highlight-secondary {
    color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  }

  &.colour-neutral-one {
    color: ${props => props.theme.palette.neutral.default.one};
  }

  &.colour-neutral-eight {
    color: ${props => props.theme.palette.neutral.default.eight};
  }

  &.colour-neutral-four {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &.colour-primary {
    color: ${props => props.theme.palette.brand.primary.normal};
  }

  &.colour-primary-dark {
    color: ${props => props.theme.palette.brand.primary.dark};
  }

  &.colour-critical {
    color: ${props => props.theme.palette.messaging.critical.normalForeground};
  }

  &.colour-lux-plus {
    color: ${props => props.theme.palette.product.luxPlus.foreground};
  }

  &.colour-exclusive-contrast {
    color: ${props => props.theme.palette.product.exclusive.contrast};
  }

  &.colour-exclusive {
    color: ${props => props.theme.palette.product.exclusive.foreground};
  }

  &.colour-urgency {
    color: ${props => props.theme.palette.messaging.urgency.normalForeground};
  }

  &.colour-tier-bronze {
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalForeground};
  }

  &.colour-tier-silver {
    color: ${props => props.theme.palette.product.luxLoyalty.silver.normalForeground};
  }

  &.colour-tier-gold {
    color: ${props => props.theme.palette.product.luxLoyalty.gold.normalForeground};
  }

  &.colour-tier-platinum {
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalForeground};
  }

  &.colour-tier-bronze-contrast {
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
  }

  &.colour-tier-silver-contrast {
    color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
  }

  &.colour-tier-gold-contrast {
    color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
  }

  &.colour-tier-platinum-contrast {
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast};
  }
`

type CaptionColour = 'exclusive-contrast' |
  'exclusive' |
  'neutral-four' |
  'neutral-one' |
  'neutral-three' |
  'neutral-two' |
  'highlight-secondary' |
  'neutral-eight' |
  'primary' |
  'critical' |
  'dark' |
  'primary-dark' |
  'lux-plus' |
  'urgency'|
  'tier-bronze' |
  'tier-silver' |
  'tier-gold' |
  'tier-platinum' |
  'tier-bronze-contrast' |
  'tier-silver-contrast' |
  'tier-gold-contrast' |
  'tier-platinum-contrast';

interface Props extends Omit<React.ComponentProps<typeof Typography>, 'forwardedAs'> {
  variant: 'small' | 'medium' | 'large';
  weight?: 'normal' | 'bold';
  italics?: boolean;
  underline?: boolean;
  strikeThrough?: boolean;
  colour?: CaptionColour;
}

const Caption = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    variant,
    weight,
    italics,
    underline,
    strikeThrough,
    as,
    colour,
    className,
    ...rest
  } = props

  return <Element
    {...rest}
    ref={ref}
    forwardedAs={as}
    className={cn(
      className,
        `variant-${variant}`,
        colour ? `colour-${colour}` : undefined,
        weight ? `weight-${weight}` : undefined,
        {
          italics,
          underline,
          'strike-through': strikeThrough,
        },
    )}
  />
})

Caption.displayName = 'Caption'

export default Caption
