import React, { useMemo, useState } from 'react'
import CounterInput from 'components/Common/Form/Input/CounterInput'
import BodyText from 'components/Luxkit/Typography/BodyText'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import { getValidTransferOptions } from './ExperienceTransferUtils'

const FormGroup = styled(Group)`
  max-width: ${rem(422)};
`

interface Props {
  transfer: App.ExperienceTransferView;
  options: Array<App.ExperienceTransferOption>;
  onChange?: (transfer: App.ExperienceTransferView) => void;
  name?: string;
}

function ExperienceDetailsTransferBookingBaggage(props: Props) {
  const { transfer, onChange = noop, name, options } = props

  const { baggage } = transfer

  const [hasOversized, setHasOversized] = useState<boolean>(!!baggage.oversizedBags)

  const maxBaggage = useMemo(() => {
    const transfers = getValidTransferOptions(transfer, options)
    return Math.max(...transfers.map(option => option.maxCheckedBags))
  }, [options, transfer])

  const onCounterChange = (value: number, input: HTMLInputElement) => {
    const field = input.dataset.field as keyof App.OrderTransferItemBaggage
    onChange({
      ...transfer,
      baggage: {
        ...baggage,
        [field]: value,
      },
    })
  }

  const onOversizedBagsChange = (value: boolean) => {
    setHasOversized(value)

    // Reset oversized amount if user chooses "No"
    if (!value) {
      onChange({
        ...transfer,
        baggage: {
          ...baggage,
          oversizedBags: 0,
        },
      })
    }
  }

  return (
    <FormGroup direction="vertical" gap={24}>
      <Group
        direction="vertical"
        tabletDirection="horizontal"
        verticalAlign="center"
        horizontalAlign="start"
        tabletHorizontalAlign="space-between"
        gap={8}
      >
        <BodyText variant="medium" weight="bold">
          How many bags do you have?
        </BodyText>
        <CounterInput
          direction="vertical"
          min={0}
          value={baggage.bags}
          onChange={onCounterChange}
          data-field="bags"
          name={`${name}.baggage.bags`}
          max={maxBaggage}
        />
      </Group>

      <Group
        direction="vertical"
        tabletDirection="horizontal"
        verticalAlign="center"
        horizontalAlign="space-between"
        gap={8}
      >
        <BodyText variant="medium" weight="bold">
          Do you have any oversized bags?
        </BodyText>
        <Group direction="horizontal" gap={16}>
          <RadioInput
            checked={hasOversized}
            onChange={() => onOversizedBagsChange(true)}
          >
            Yes
          </RadioInput>
          <RadioInput
            checked={!hasOversized}
            onChange={() => onOversizedBagsChange(false)}
          >
            No
          </RadioInput>
        </Group>
      </Group>

      {hasOversized && (
        <Group
          direction="vertical"
          tabletDirection="horizontal"
          verticalAlign="center"
          horizontalAlign="start"
          tabletHorizontalAlign="space-between"
          gap={8}
        >
          <BodyText variant="medium" weight="bold">
            How many oversized bags do you have?
          </BodyText>
          <CounterInput
            direction="vertical"
            min={1}
            value={baggage.oversizedBags}
            onChange={onCounterChange}
            data-field="oversizedBags"
            name={`${name}.baggage.oversizedBags`}
            max={baggage.bags}
          />
        </Group>
      )}
    </FormGroup>
  )
}

export default ExperienceDetailsTransferBookingBaggage
