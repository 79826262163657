import React, { ComponentProps, forwardRef, MouseEventHandler, PropsWithChildren, ReactNode } from 'react'
import ModalBase from './ModalBase'
import ModalBody from './ModalBody'
import ModalContent from './ModalContent'
import ModalFooter, { ModalFooterOnClickHandler } from './ModalFooter'
import ModalHeader from './ModalHeader'
import ModalImageThumbnail from './ModalImageThumbnail'

type InheritedModalHeaderProps = Pick<ComponentProps<typeof ModalHeader>, 'title' | 'subtitle'>
type InheritedModalFooterProps = Pick<ComponentProps<typeof ModalFooter>, 'isPrimaryActionHidden' | 'isSecondaryActionHidden' | 'actionsLayout'>
type InheritedModalImageThumbnailProps = Partial<Pick<ComponentProps<typeof ModalImageThumbnail>, 'image' | 'imageParams'>>

interface Props extends ComponentProps<typeof ModalBase>, InheritedModalHeaderProps, InheritedModalFooterProps, InheritedModalImageThumbnailProps {
  isPrimaryActionDisabled?: boolean
  isSecondaryActionDisabled?: boolean
  /**
   * If provided, the primary action will be displayed.
   */
  primaryActionText?: string
  /**
   * If provided, the secondary action will be displayed.
   */
  secondaryActionText?: string
  primaryActionHref?: string
  primaryActionTo?: string
  secondaryActionTo?: string
  /**
   * Extra node at the bottom of the header.
   */
  headerExtension?: ReactNode
  /**
   * Callback on back button click.
   *
   * If provided, enables the back button to be displayed.
   */
  onBack?: MouseEventHandler<HTMLButtonElement>
  onPrimaryActionClick?: ModalFooterOnClickHandler
  onSecondaryActionClick?: ModalFooterOnClickHandler
  'data-testid'?: string;
}

/**
 * @see `ModalBase.tsx`
 * @see `ModalHeader.tsx`
 * @see `ModalImageThumbnail.tsx`
 * @see `ModalBody.tsx`
 * @see `ModalContent.tsx`
 * @see `ModalFooter.tsx`
 * */
const Modal = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    children,
    className,
    image,
    imageParams,
    height,
    tabletHeight,
    isOpen,
    actionsLayout,
    isPrimaryActionDisabled,
    isPrimaryActionHidden,
    isSecondaryActionDisabled,
    isSecondaryActionHidden,
    primaryActionText,
    primaryActionHref,
    primaryActionTo,
    secondaryActionTo,
    secondaryActionText,
    size,
    subtitle,
    title,
    headerExtension,
    dismissible,
    onAfterClose,
    onAfterOpen,
    onBack,
    onClose,
    onPrimaryActionClick,
    onSecondaryActionClick,
    'data-testid': testId,
    mode,
  } = props

  return <ModalBase
    mode={mode}
    className={className}
    height={height}
    tabletHeight={tabletHeight}
    isOpen={isOpen}
    size={size}
    dismissible={dismissible}
    onAfterClose={onAfterClose}
    onAfterOpen={onAfterOpen}
    onClose={onClose}
  >
    <ModalHeader
      title={title}
      subtitle={subtitle}
      onBackButtonClick={onBack}
      onCloseButtonClick={onClose}
      dismissible={dismissible}
      data-testid={testId}
    >
      {headerExtension}
    </ModalHeader>
    <ModalBody ref={ref}>
      {image && <ModalImageThumbnail image={image} imageParams={imageParams} />}
      {!!children && <ModalContent>
        {children}
      </ModalContent>}
    </ModalBody>
    {(primaryActionText || secondaryActionText) && <ModalFooter
      actionsLayout={actionsLayout}
      isPrimaryActionHidden={isPrimaryActionHidden}
      isSecondaryActionHidden={isSecondaryActionHidden}
      primaryActionProps={{
        children: primaryActionText,
        disabled: isPrimaryActionDisabled,
        onClick: onPrimaryActionClick,
        href: primaryActionHref,
        to: primaryActionTo,
      }}
      secondaryActionProps={{
        children: secondaryActionText,
        disabled: isSecondaryActionDisabled,
        onClick: onSecondaryActionClick,
        to: secondaryActionTo,
      }}
    />}
  </ModalBase>
})

export default Modal
