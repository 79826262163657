import LineGiftIcon from 'components/Luxkit/Icons/line/LineGiftIcon'
import LineUsdCircleIcon from 'components/Luxkit/Icons/line/LineUsdCircleIcon'
import LabeledSheet from 'components/Luxkit/LabeledSheet'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import React from 'react'
import FormatCurrency from '../FormatCurrency'
import useCurrentSiteTakeover from 'hooks/useCurrentSiteTakeover'
import styled from 'styled-components'
import { rem } from 'polished'
import Label from 'components/Luxkit/Label'

const TakeoverSheet = styled(LabeledSheet)`
  && > * {
    border-color: var(--takeover-primary);
  }
`

const Content = styled.div`
  padding: ${rem(20)};
`

function LunarSiteTakeoverOfferUSPs() {
  const siteTakeover = useCurrentSiteTakeover()

  if (!siteTakeover) {
    return null
  }

  return <TakeoverSheet
    kind="highlight"
    label={<Label
      kind="primary"
      variant="critical"
      style={{ backgroundColor: siteTakeover.primaryColour }}
    >
      Lunar new year 2025
    </Label>}
    style={{ '--takeover-primary': siteTakeover.primaryColour }}
  >
    <Content>
      <BodyTextBlock variant="medium" colour="neutral-two" startIcon={<LineUsdCircleIcon />}>
        Enjoy exclusive discounted prices
      </BodyTextBlock>
      <BodyTextBlock variant="medium" colour="neutral-two" startIcon={<LineGiftIcon />}>
        Join the draw to win! 3 lucky winners get a free getaway,{' '}
        and 3 more win <FormatCurrency currency="USD" value={350} /> credit
      </BodyTextBlock>
    </Content>
  </TakeoverSheet>
}

export default LunarSiteTakeoverOfferUSPs
