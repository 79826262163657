import React from 'react'
import { rem } from 'polished'

import { mediaQueryUp } from 'components/utils/breakpoint'

import styled from 'styled-components'

const CabinTypesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${rem(16)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`

interface CruiseCabinTypeGridProps {
  children: React.ReactNode
}

function CruiseCabinTypeGrid({ children }: CruiseCabinTypeGridProps) {
  return <CabinTypesGrid>{children}</CabinTypesGrid>
}

export default CruiseCabinTypeGrid
