import { excludeNullOrUndefined } from 'checkout/utils'
import { isCarHireItem } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import { getCarHireAddonPriceBreakdown, getCarHireInsurancePriceBreakdown, getCarHireItemPriceBreakdown, getCarHireItemView, getSelectedCarHireAddonsTotal } from 'checkout/lib/utils/carHire/view'
import { isCarHireInsuranceAvailable } from 'selectors/carHireSelectors'
import config from 'constants/config'
import uuidV4 from 'lib/string/uuidV4Utils'
import { getRateOptionByRateType, getRateOptionType } from 'lib/carHire/carHireUtils'

export const getCarHireItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.CarHireItem> => items.filter(isCarHireItem),
)

export const getCarHireItemsView = createSelector(
  (state: App.State) => getCarHireItems(state),
  (
    carHireItems,
  ): App.WithDataStatus<Array<App.Checkout.CarHireItemView>> => {
    const itemViews = carHireItems.map(item => {
      const itemView = getCarHireItemView(item)

      if (config.businessTraveller.currentAccountMode === 'business' && itemView) {
        // Exclude child addons for LE Business Traveller
        // 7: Baby seat
        // 8: Child seat
        // 9: Child booster seat
        const childAddons = ['7', '8', '9']
        itemView.addons = itemView.addons.filter(addon => addon.type !== undefined && !childAddons.includes(addon.type))
      }

      return itemView
    })

    return {
      hasRequiredData: itemViews.every(Boolean),
      data: itemViews.filter(excludeNullOrUndefined),
    }
  },
)

export const getCarHireAddonTotal = createSelector(
  (state: App.State) => getCarHireItemsView(state),
  (viewWithStatus) => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)
    const addons = definedItems.map(itemView => itemView.item.selectedAddons).flat()
    return getSelectedCarHireAddonsTotal(addons)
  },
)

export const getCarHireBreakdownView = createSelector(
  (state: App.State) => getCarHireItemsView(state),
  (state: App.State) => getCarHireAddonTotal(state),
  (state: App.State) => isCarHireInsuranceAvailable(state),
  (viewWithStatus, selectedCarHireAddonTotal, isCarHireInsuranceAvailable)
  : App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) {
      return {
        hasRequiredData: viewWithStatus.hasRequiredData,
        data: [],
      }
    }
    const carViews = viewWithStatus.data.filter(excludeNullOrUndefined)
    const carBreakdownViews: Array<App.Checkout.PriceBreakdownView> = carViews.map(carView => {
      return [
        getCarHireItemPriceBreakdown(carView),
        ...(isCarHireInsuranceAvailable && carView.item.selectedInsurance ?
            [getCarHireInsurancePriceBreakdown(carView)] :
            []),
        ...(selectedCarHireAddonTotal ?
            [getCarHireAddonPriceBreakdown(carView)] :
            []),
      ]
    }).flat()

    return {
      hasRequiredData: true,
      data: carBreakdownViews,
    }
  },
)

export const getCarHireCheckoutPayOnArrivalItems = createSelector(
  (state: App.State) => getCarHireItems(state),
  (carHireItems) => {
    const payOnArrivalItems = carHireItems.flatMap((carHireItem) => {
      return carHireItem.offer.payOnArrivalItems
    })

    return Array.from(new Set(payOnArrivalItems))
  },
)

export const isStandaloneCarHire = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.length > 0 && items.every(isCarHireItem),
)

/**
 * Gets the combination of rate options
 * It will prioritize the combination of same options e.g:
 *  - payNow's
 *  - payOnArrival's
 *  - payPartially's
 *
 * If it can't find equal options for each offer, it will find options
 * that can work with others, e.g:
 *  - payNow's with payPartially's
 *  - payOnArrival's with payPartially's
 *
 * If it can't find the "perfect match", it selects the first option
 */
export const getCarHireOptionsGroups = createSelector(
  (state: App.State) => getCarHireItemsView(state),
  (carHireItems) => {
    const groupedRateOptions: Array<Array<App.CarHireOffer['rateOptions'][number] & { itemId: string }>> = []

    carHireItems.data.forEach((cartItemSource) => {
      cartItemSource.item.offer.rateOptions.forEach((sourceRateOption) => {
        const sourceRateOptionType = getRateOptionType(sourceRateOption)
        if (!groupedRateOptions.some((group) =>
          group.find((option) =>
            option.itemId === cartItemSource.item.itemId &&
            option.id === sourceRateOption.id &&
            option.idContext === sourceRateOption.idContext,
          ),
        )) {
          const optionGroup = [{ ...sourceRateOption, itemId: cartItemSource.item.itemId }]

          carHireItems.data.forEach((cartItemCompare) => {
            if (cartItemCompare.item.itemId !== cartItemSource.item.itemId) {
              let compatibleOption = getRateOptionByRateType(cartItemCompare.item.offer, sourceRateOptionType)

              if (!compatibleOption && ['payFullyNow', 'payFullyOnArrival'].includes(sourceRateOptionType)) {
                compatibleOption = getRateOptionByRateType(cartItemCompare.item.offer, 'payPartiallyNow')
              }

              if (!compatibleOption) compatibleOption = cartItemCompare.item.offer.rateOptions[0]

              optionGroup.push({
                ...compatibleOption,
                itemId: cartItemCompare.item.itemId,
              })
            }
          })

          groupedRateOptions.push(optionGroup)
        }
      })
    })

    return {
      combinationId: uuidV4(),
      groupedRateOptions,
    }
  },
)
