import { useContext, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { canShowPushPermissionPrompt } from 'api/braze'
import MasterModalContext from 'contexts/MasterModalContext'
import { selectLoggedIn } from 'selectors/accountSelectors'
import {
  get as getLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { USER_PUSH_NOTIFICATIONS_DISMISSED_KEY } from 'constants/auth'
import { matchPath } from 'react-router'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import config from 'constants/config'
import { LUXURY_ESCAPES } from 'constants/brands'

const EnablePushNotificationsPromptRoutes = [
  '/:regionCode/hotels',
  '/:regionCode/tours',
  '/:regionCode/cruises',
  '/:regionCode/car-hire',
  '/:regionCode/experiences',
  '/:regionCode/flights',
  '/:regionCode/homes-and-villas',
  '/:regionCode/search',
  '/:regionCode/lux-plus',
  '/:regionCode/ultra-lux',
]

function usePushNotificationPermission() {
  const loggedIn = useAppSelector(selectLoggedIn)
  const headlessMode = useAppSelector(state => state.system.headlessMode)
  const isDismissed = getLocalStorage(USER_PUSH_NOTIFICATIONS_DISMISSED_KEY)
  const modalState = useContext(MasterModalContext)
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const isSpoofed = useAppSelector(state => state.auth.account.isSpoofed)
  const [canShow, setCanShow] = useState(false)

  const isRouteValid = useMemo(() => (
    matchPath(currentPath, { path: '/:regionCode', exact: true }) || matchPath(currentPath, { path: EnablePushNotificationsPromptRoutes, exact: false })
  ), [currentPath])

  const isUserEnabled = loggedIn && !headlessMode && !modalState.open && !isDismissed && !!isRouteValid && !isSpoofed
  const isWebPushNotificationPromptEnabled = !!useOptimizelyExperiment(OptimizelyFeatureFlags.croWebPushNotificationPrompt, isUserEnabled)

  const isPromptEnabled = isWebPushNotificationPromptEnabled && isUserEnabled

  useEffect(() => {
    const checkCanShow = async() => {
      const canShowPushPermission = await canShowPushPermissionPrompt()
      setCanShow(canShowPushPermission)
    }
    if (isPromptEnabled) {
      checkCanShow()
    }
  }, [isPromptEnabled])

  return isPromptEnabled && canShow && config.BRAND === LUXURY_ESCAPES
}

export default usePushNotificationPermission
