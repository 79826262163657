import React, { useMemo } from 'react'
import CruiseShipCheckboxGroup
  from 'components/Cruises/SearchPage/Filters/Inputs/CruiseShipCheckboxGroup/CruiseShipCheckboxGroup'

interface Props {
  cruiseShips?: Set<string>;
  onChange: (nextFilters: React.ChangeEvent<HTMLInputElement>) => void;
  facets: Array<App.CruiseSearchFacet>;
  fetching?: boolean;
}

function CruiseShipFilter({
  cruiseShips,
  onChange,
  facets,
  fetching,
}: Props) {
  const shipItems = useMemo(() => {
    return facets.filter(facet => facet.category === 'ship_names')
  }, [facets])

  return <CruiseShipCheckboxGroup
    shipItems={shipItems}
    onChange={onChange}
    defaultSelected={cruiseShips}
    fetching={fetching}
  />
}

export default CruiseShipFilter
