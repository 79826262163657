import React, { useCallback, useContext, useEffect, useState } from 'react'

import AirportDropdownOption from './AirportDropdownOption'
import NoAirportsOption from './NoAirportsOption'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import styled from 'styled-components'
import { rem } from 'polished'
import Pane from 'components/Common/Pane'
import { LuxkitModalBehaviourStateContext } from 'components/Luxkit/Modal/context/LuxkitModalBehaviourContexts'
import Heading from 'components/Luxkit/Typography/Heading'
import SearchFormModalTextField from 'components/SearchV2/Components/SearchFormField/SearchFormModalTextField'
import useAirportSearch from 'hooks/Flights/useAirportSearch'
import usePopularAirports from 'hooks/Flights/usePopularAirports'
import noop from 'lib/function/noop'
import { getAirportDisplayText } from 'lib/flights/flightUtils'

const Results = styled.div`
  position: relative;
  min-height: ${rem(80)};
`

const InputArea = styled(Pane)`
  position: sticky;
  top: ${rem(0)};
  padding: ${rem(12)} ${rem(16)};
  margin: 0 ${rem(-16)};
  z-index: 1;
`

interface Props {
  className?: string;
  onSelectAirport: (airport?: App.AirportLocation) => void;
  onChange?: (text?: string) => void;
  required?: boolean;
  requiredErrorMessage?: string;
  placeholder?: string;
  /**
   * The selected airport for this control
   */
  airport?: App.AirportLocation;
  /**
   * The airport going the *other direction*. Will be excluded from search results
   */
  alternateAirport?: App.AirportLocation;
  fallbackHeading?: string;
  direction: 'departure' | 'arrival';
  fallbackAirports?: Array<App.AirportLocation>;
}

function FlightSearchAirportModalSelect(props: Props) {
  const {
    airport,
    alternateAirport,
    placeholder = 'Search by city or airport',
    className,
    onSelectAirport,
    onChange = noop,
    required,
    requiredErrorMessage,
    fallbackHeading = 'Popular airports',
    direction,
    fallbackAirports,
  } = props

  const popularAirports = usePopularAirports(direction, { excludeAirport: alternateAirport })

  const [searchPhrase, setSearchPhrase] = useState<string | undefined>()
  const [airports, loading] = useAirportSearch(searchPhrase, { excludedAirport: alternateAirport })

  useEffect(() => {
    if (airport) {
      setSearchPhrase(getAirportDisplayText(airport))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTextChange = useCallback((text?: string) => {
    setSearchPhrase(text)
    onChange(text)
  }, [onChange])

  const onClear = useCallback(() => {
    setSearchPhrase(undefined)
    onSelectAirport(undefined)
  }, [onSelectAirport])

  const showSearch = !!(searchPhrase && searchPhrase.length > 2)
  const noAirportOptions = !loading && airports.length === 0 && showSearch
  const airportList = showSearch ? airports : fallbackAirports || popularAirports
  const { bodyHasReachedStart } = useContext(LuxkitModalBehaviourStateContext)

  const errorMessage = requiredErrorMessage ?? (direction === 'arrival' ? 'Please enter a destination' : 'Please enter a departure')

  return (
    <div className={className}>
      <InputArea shadow={!bodyHasReachedStart}>
        <SearchFormModalTextField
          value={getAirportDisplayText(airport)}
          placeholder={placeholder}
          required={required}
          searchPhrase={searchPhrase}
          requiredErrorMessage={errorMessage}
          onChange={onTextChange}
          onClear={onClear}
          autoFocus
          autoSelect
        />
      </InputArea>
      <Results>
        <LoadingIndicator visible={loading} floating position="top">
          Loading
        </LoadingIndicator>
        {!showSearch && <Heading variant="heading6">{fallbackHeading}</Heading>}
        {!loading && airportList.map(airport =>
          <AirportDropdownOption
            key={airport.id}
            subRecord={airport.isCityAirport}
            airport={airport}
            onSelectAirport={onSelectAirport}
          />,
        )}
        {noAirportOptions && <NoAirportsOption inputValue={searchPhrase} />}
      </Results>
    </div>
  )
}

export default FlightSearchAirportModalSelect
