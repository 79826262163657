import MarkdownRender from 'components/Luxkit/MarkdownRender'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import React, { useCallback } from 'react'

interface Props {
  title?: string
  content: string
}

function NotificationBannerV2Modal({ title, content }: Props) {
  const { resolve } = useModalElementContext()

  const close = useCallback(() => {
    resolve()
  }, [resolve])

  return (
    <ModalBase>
      {title && <ModalHeader title={title} />}
      <ModalContent>
        <MarkdownRender content={content} />
      </ModalContent>
      <ModalFooter
        primaryActionProps={{
          children: 'OK',
          onClick: close,
        }}
      />
    </ModalBase>
  )
}

export default NotificationBannerV2Modal
