import { useEffect, useMemo } from 'react'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
import { getHotelsTrendingDestinations, getIsFetchingTrendingDestinations } from 'selectors/destinationSelectors'
import GlobalSearchState, { GlobalSearchStateActions, GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { SEARCH_VERTICALS } from 'constants/search'
import { useGlobalSearchResultsTracking } from '../useGlobalSearchTracking'
import { getUrlOfferListFilters } from 'selectors/routerSelectors'
import { EXPERIENCE_PRODUCT_TYPES, EXPERIENCE_SEARCH_TYPES } from 'constants/experience'
import { SearchResult, SearchResultBucket } from 'api/interactionStudio'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { getSearchItemFromURLSearchParams } from 'lib/url/searchUrlUtils'
import { fetchTrendingDestinations } from 'actions/DestinationAlertsActions'
import getOfferListKey from 'lib/offer/offerListKey'

export function useGlobalExperiencesSearchContext(
  initialState: GlobalSearchState = GLOBAL_SEARCH_INITIAL_STATE,
  syncWithURLSearchParams?: URLSearchParams,
) {
  const queryParams = useMemo(() => {
    if (syncWithURLSearchParams) {
      return {
        searchItem: getSearchItemFromURLSearchParams(syncWithURLSearchParams),
      }
    }
    return undefined
  }, [syncWithURLSearchParams])

  const { globalSearchState, globalSearchDispatch } = useGlobalSearchContext({
    ...initialState,
    searchVerticals: new Set([SEARCH_VERTICALS.EXPERIENCES]),
  })

  // sync search item
  useEffect(() => {
    if (queryParams) {
      const { searchItem } = queryParams
      if (EXPERIENCE_SEARCH_TYPES.includes(searchItem?.searchType as App.SearchType)) {
        globalSearchDispatch({ type: GlobalSearchStateActions.SET_SEARCH_ITEM, searchItem })
      } else {
        globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_SEARCH_ITEM })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.searchItem])

  const dispatch = useAppDispatch()

  const isFetchingFeaturedDestinations = useAppSelector(getIsFetchingTrendingDestinations)
  const trendingDestinations = useAppSelector(getHotelsTrendingDestinations)

  useEffect(() => {
    globalSearchDispatch({ type: GlobalSearchStateActions.UNPOPULATE_POPULAR_DESTINATIONS })
    dispatch(fetchTrendingDestinations())
  }, [dispatch, globalSearchDispatch])

  useEffect(() => {
    if (!isFetchingFeaturedDestinations) {
      globalSearchDispatch({
        type: GlobalSearchStateActions.POPULATE_POPULAR_DESTINATIONS,
        popularDestinations: trendingDestinations,
      })
    }
  }, [isFetchingFeaturedDestinations, trendingDestinations, globalSearchDispatch])

  return {
    globalExperiencesSearchDispatch: globalSearchDispatch,
    globalExperiencesSearchState: globalSearchState,
  }
}

export function useGlobalExperiencesSearchResultsTracking(
  globalExperiencesSearchState: GlobalSearchState,
  experiencesIds: Array<string>,
  areResultsFetching: boolean,
) {
  const filters = useAppSelector(getUrlOfferListFilters)
  const searchParams = { ...filters, offerRypes: EXPERIENCE_PRODUCT_TYPES }
  const offerListKey = getOfferListKey(searchParams)

  const trackableResults = useMemo<Array<SearchResult>>(() => {
    const results: Array<SearchResult> = experiencesIds.map((id) => {
      const kind = id.startsWith('led:') ? SearchResultBucket.LED_EXPERIENCE : SearchResultBucket.REZDY_EXPERIENCE

      return { id, kind }
    })

    return results
  }, [experiencesIds])

  useGlobalSearchResultsTracking(
    { ...globalExperiencesSearchState, occupancies: undefined, isFlexibleDateSelected: undefined },
    offerListKey,
    trackableResults,
    areResultsFetching,
  )
}
