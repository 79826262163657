import React, { useContext } from 'react'
import TextLink from 'components/Luxkit/TextLink'
import TourAsiaCampaignProfileImage from 'components/Tours/Campaign/TourAsiaCampaignProfileImage'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import ModalContext from 'contexts/ModalContext'
import TourAsiaCampaignModal from 'components/Tours/Campaign/TourAsiaCampaignModal'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import GeoContext from 'contexts/geoContext'
import FormatCurrency from 'components/Common/FormatCurrency/FormatCurrency'
import { TOUR_ASIA_PROMO_REGIONS } from 'constants/tours'

function TourAsiaCampaignSearchBanner() {
  const { currentRegionCode, currentCurrency } = useContext(GeoContext)
  const showModal = useContext(ModalContext)
  const showTourAsiaModal = () => { showModal(<TourAsiaCampaignModal />) }
  return <Group fullWidth direction="horizontal" verticalAlign="center" gap={20} horizontalAlign="center">
    <TourAsiaCampaignProfileImage />
    <VerticalSpacer gap={4}>
      <Heading variant="heading2"><i>Explore</i> the best of Asia on tour</Heading>
      <Group direction="vertical" tabletDirection="horizontal" tabletGap={12}>
        <BodyText variant="large" colour="neutral-one">Save up to <FormatCurrency format="dynamic" currency={currentCurrency} value={TOUR_ASIA_PROMO_REGIONS[currentRegionCode]} /> with code: <b>TOURASIA</b>.<CSSBreakpoint min="tablet" as="span"> Limited time only.</CSSBreakpoint></BodyText>
        <TextLink onClick={showTourAsiaModal}>Learn more</TextLink>
      </Group>
    </VerticalSpacer>
  </Group>
}

export default TourAsiaCampaignSearchBanner
