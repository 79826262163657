import FilterChip from 'components/Luxkit/Chips/FilterChip'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import React, { useCallback, useContext, useState } from 'react'
import { Form } from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchChips'
import ModalContext from 'contexts/ModalContext'
import ModalCruiseSearchShipChip
  from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/Modal/ModalCruiseSearchShipChip'

export interface CruiseSearchShipChipProps {
  facets: Array<App.CruiseSearchFacet>;
  filters: {
    cruiseShips?: Set<string>;
  };
  filterForm: Form;
  setFilterForm: (form: Form) => void;
  fetching?: boolean;
  drawerMode?: boolean;
}

function CruiseSearchShipChip(props: CruiseSearchShipChipProps) {
  const { filters, facets, filterForm, setFilterForm, fetching, drawerMode } = props
  const showModal = useContext(ModalContext)
  const [countSelected, setCountSelected] = useState<number>(filters.cruiseShips?.size || 0)

  const openModal = useCallback(async() => {
    const countSelected = await showModal<number>(
      <ModalCruiseSearchShipChip
        drawerMode={drawerMode}
        facets={facets}
        filterForm={filterForm}
        setFilterForm={setFilterForm}
        fetching={fetching}
        filters={filters}
      />,
    )
    setCountSelected(countSelected)
  }, [drawerMode, facets, fetching, filterForm, filters, setFilterForm, showModal])

  return <FilterChip
    selected={!!filters.cruiseShips?.size}
    size="medium"
    onClick={openModal}
    endIcon={<LineAngleDownIcon />}
  >
    Cruise ships {countSelected > 0 && `(${countSelected})`}
  </FilterChip>
}

export default CruiseSearchShipChip
