import { FetchQuoteParams } from 'actions/InsuranceActions'
import { QuoteVerticals } from 'api/insurance'
import { CustomisableProductIds } from 'constants/insurance'
import { isBedbankItem, isCruiseItem, isExperienceItem, isFlightItem, isLEHotelItem, isTourItem } from 'lib/checkout/checkoutUtils'
import getObjectKey from 'lib/object/getObjectKey'

interface InsuranceProductKeyOptions {
  isDomestic?: boolean,
  isCruise?: boolean,
  subscriptionId?: string,
}

export function getInsuranceProductListKey(type: App.InsuranceProductType, options: InsuranceProductKeyOptions = {}) {
  return getObjectKey({
    type,
    isDomestic: options.isDomestic,
    isCruise: options.isCruise,
    subscriptionid: options.subscriptionId,
  })
}

export function getInsuranceQuoteKey(
  productId: string,
  options: Partial<FetchQuoteParams>,
) {
  return getObjectKey({
    productId,
    ...options,
  })
}

export function isCustomisableInsuranceProduct(productId: string) {
  return CustomisableProductIds.has(productId)
}

export function insuranceTypeToInsuranceProductType(type: App.InsuranceType): App.InsuranceProductType {
  if (type === 'cfmr') return 'booking-protection'
  return 'travel-insurance'
}

const verticalChecks = [
  { check: isExperienceItem, vertical: 'experiences' },
  { check: isFlightItem, vertical: 'flights' },
  { check: (item: App.Checkout.AnyItem) => isLEHotelItem(item) || isBedbankItem(item), vertical: 'hotels' },
  { check: isTourItem, vertical: 'tours' },
  { check: isCruiseItem, vertical: 'cruises' },
] as const
export function getLuxVerticals(items: Array<App.Checkout.AnyItem>): QuoteVerticals {
  return verticalChecks
    .filter(({ check }) => items.some(check))
    .map(({ vertical }) => vertical)
}
