import { isFlightItem } from 'checkout/lib/utils/flights/cart'
import { getCheckoutFlightV2SearchUrl, getStandaloneFlightsV2SearchUrl } from 'checkout/lib/utils/flights/links'
import { arrayToMap, arrayToObject, nonNullable, sum } from 'lib/array/arrayUtils'
import {
  buildFlightItemBreakdownView,
  formatFareType,
  formatFlightPassengersShorthand, formatJourneySummaryTitle,
} from 'lib/checkout/flights/format'
import { createSelector } from 'reselect'

import { getFlightLoyaltyProductType, getFlightLoyaltyProductTypeRank, getJourneyV2IdKey } from 'lib/flights/flightUtils'
import { convertJourneyV2ToJourneyFlight } from 'api/lib/convertJourneyV2'
import config from 'constants/config'
import { generateCheckoutItemViewOfferCreditKey } from 'checkout/lib/utils/businessTraveller/cart'
import { FlightsFareTypes, FlightViewTypes } from 'constants/flight'
import { isBookingProtectionItem, isInsuranceItem, isLuxPlusSubscriptionItem } from 'lib/checkout/checkoutUtils'

export const getFlightItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.FlightItem> => items.filter(isFlightItem),

)

export const flightSearchInProgress = createSelector(
  (state: App.State) => state.flights.searchFlights,
  (state: App.State) => state.flights.searchV2Flights,
  (flightsV1, flightsV2): boolean => {
    const journeysV1Fetching = Object.values(flightsV1).flatMap(flight => flight?.fetching).filter(Boolean)
    const journeysV2Fetching = Object.values(flightsV2).flatMap(flight => flight?.fetching).filter(Boolean)
    return !!(journeysV1Fetching.length || journeysV2Fetching.length)
  },
)

export const getBundledFlightItems = createSelector(
  (state: App.State) => getFlightItems(state),
  (items): Array<App.Checkout.FlightItem> => items.filter(item => !!item.bundledItemIds?.length),

)

export const cartIncludesFlights = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.some(isFlightItem),
)

function mapFlightV2ToView(
  item: App.Checkout.FlightItem,
  airports: Array<App.Airport>,
  journeys: Record<string, App.JourneyV2> = {},
): App.Checkout.FlightItemView {
  const fares = sum(item.flights.map(flight => flight.cost ?? 0))
  const surcharge = sum(item.flights.map(flight => journeys[flight.journeyId]?.price.all.totalFees ?? 0))

  const isBundled = (item.bundledItemIds?.length ?? 0) > 0

  const journeysFareTypes = item.flights.map(flight => journeys[flight.journeyId]?.fareType)

  const totalFare = item.totalFare ?? 0
  const isReturnFares = journeysFareTypes.includes(FlightViewTypes.RETURN)
  let price = totalFare > 0 ? totalFare : fares

  const departing = item.flights[0] ? journeys[item.flights[0].journeyId] : undefined
  const returning = item.flights[1] ? journeys[item.flights[1].journeyId] : undefined

  if (isReturnFares) {
    // For return fares, if departing and returning is defined, we must use the sum of departingFare and returning fare
    // This happens because after updating the fare with an upsell fare family, each segment total fare is updated individually
    // If the only departing is defined, we can use the totalRoundTripPrice since it represents the price considering for
    // the selected departing flight and the cheapest returning flight paired within it
    price = returning ? fares : (departing?.price.all.totalRoundTripPrice ?? 0)
  } else if (departing && !returning && item.viewType !== FlightViewTypes.ONE_WAY) {
    // For V2 two one-ways, if the returning fare is not defined, we must use the departing.price.all.totalFare because
    // it has the updated departing flight price after selecting the fare family. The item.departing.cost is only updated
    // after the select endpoint call. We must also add the item.quotedFare price because the cheapest flight price
    // is already included in the total Hotel + flights package. In order to have correct increase in price based on the relative price
    // displayed in the flight tile, we must add the quotedFare to the total price
    price = departing.price.all.totalFare + (item.quotedFare ?? 0) / 2
  }

  const flightsWithJourneys = item.flights.filter(flight => journeys[flight.journeyId])

  const airportsByKey = arrayToMap(airports, airport => airport.code)

  const usedAirports: Array<{ departing?: App.Airport, arrival?: App.Airport }> = flightsWithJourneys.map(journey => {
    const departing = airportsByKey.get(journey.departingAirportCode)
    const arrival = airportsByKey.get(journey.arrivalAirportCode)

    return {
      departing,
      arrival,
    }
  })

  const firstDepartingCountryCode = usedAirports[0]?.departing?.countryCode

  const isDomestic = usedAirports.every(airport => {
    return airport.arrival?.countryCode === airport.departing?.countryCode && airport.departing?.countryCode === firstDepartingCountryCode
  })

  const luxProductTypes = flightsWithJourneys.map(journey => {
    return getFlightLoyaltyProductType(journey.fareFamily?.cabin?.toLowerCase() || item.selectedCabinClass || '', isDomestic)
  })

  const rankedProductTypes = luxProductTypes.sort((a, b) => {
    return getFlightLoyaltyProductTypeRank(a) - getFlightLoyaltyProductTypeRank(b)
  })

  const result: App.Checkout.FlightItemView = {
    luxLoyaltyProductType: rankedProductTypes[0] || 'dom_economy',
    item,
    journeyId: item.searchId,
    provider: departing?.provider,
    passengers: item.passengers,
    flights: flightsWithJourneys.map(flight => ({
      journeyFlight: convertJourneyV2ToJourneyFlight(journeys[flight.journeyId]),
      extras: flight.extras,
      cost: flight.cost,
      fareFamily: flight.fareFamily,
    })),
    designation: 'Flights',
    validatingCarrier: departing?.carrier,
    validatingCarrierName: departing?.carrierName ?? '',
    validatingCarrierLogo: departing?.carrierLogo ?? '',
    returningCarrierName: returning?.carrierName ?? '',
    returningCarrierLogo: returning?.carrierLogo ?? '',
    fareType: item.fareType,
    totals: {
      price,
      memberPrice: 0, // No data on this
      value: price,
      surcharge,
      memberValue: 0,
      extraGuestSurcharge: 0,
      taxesAndFees: 0,
      otherFees: item.otherFees,
    },
    seatSelectionPolicy: departing?.flightGroup.flights[0].seatSummary.selectionPolicy,
    isBundled,
    quotedFare: item.quotedFare,
    searchFlightsUrl: isBundled ?
      getCheckoutFlightV2SearchUrl(flightsWithJourneys.map(flight => journeys[flight.journeyId])) :
      getStandaloneFlightsV2SearchUrl(item, airports, journeys),
    viewType: item.viewType,
  }

  return result
}

export const getFlightItemsView = createSelector(
  (state: App.State) => getFlightItems(state),
  (state: App.State) => state.flights.journeysById,
  (state: App.State) => state.businessTraveller.offersCredits,
  (state: App.State) => state.geo.airports,
  (flightItems, journeys, offerCredits, airports): App.WithDataStatus<Array<App.Checkout.FlightItemView>> => {
    const anyItemsFetching = flightItems.some(item => item.fetchingFare)

    const flightViews = nonNullable(
      flightItems.map<{ hasRequiredData: boolean, view: App.Checkout.FlightItemView | undefined }>((item) => {
        const journeyKeys = item.flights.map(flight => getJourneyV2IdKey(flight?.journeyId, item.searchId!, flight?.fareFamily?.id))
        const flightJourneys = journeyKeys.map(key => journeys[key] as App.JourneyV2)
        const journeysByFlightJourneyId = arrayToObject(item.flights, flight => flight.journeyId, (_, index) => flightJourneys[index])

        return {
          view: mapFlightV2ToView(
            item,
            airports,
            journeysByFlightJourneyId,
          ),
          hasRequiredData: flightJourneys.filter(Boolean).length === item.flights.length,
        }
      }),
    )

    const hasAllRequiredData = flightViews.every(fv => fv.hasRequiredData) && !anyItemsFetching && flightViews.length === flightItems.length

    // inject business credits into flightItemView
    if (config.businessTraveller.currentAccountMode === 'business' &&
      offerCredits
    ) {
      for (const flightView of flightViews) {
        if (flightView.view) {
          const offerCreditKey = generateCheckoutItemViewOfferCreditKey(flightView.view)
          const offerCredit = offerCredits[offerCreditKey]

          if (offerCredit?.status === 'success') {
            flightView.view.businessTravellerCredits = offerCredit.creditValue
          }
        }
      }
    }

    return {
      hasRequiredData: hasAllRequiredData,
      data: nonNullable(flightViews.map(view => view.view)),
    }
  },
)

export const getFlightBreakdownView = createSelector(
  (state: App.State) => getFlightItemsView(state),
  (state: App.State) => state.businessTraveller.offersCredits,
  (flightViewsWithStatus, offerCredits): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    const flightBreakdownViews = flightViewsWithStatus.data.map<App.Checkout.PriceBreakdownView>(view => {
      const supplementaryData = {
        validatingCarrierName: view.validatingCarrierName,
        validatingCarrierLogo: view.validatingCarrierLogo,
      }
      const breakdownView: App.Checkout.PriceBreakdownView = {
        title: formatFareType(view.fareType),
        additionalInfoText: [
          ...(view.flights[0] ? [formatJourneySummaryTitle(view.flights, view.fareType as FlightsFareTypes)] : []),
          formatFlightPassengersShorthand(view.passengers),
        ],
        price: view.isBundled ? 0 : (view.totals.price ?? 0),
        memberPrice: view.isBundled ? 0 : (view.totals.memberPrice ?? 0),
        items: view.flights.map(flight => buildFlightItemBreakdownView(view, flight, supplementaryData)),
      }

      if (config.businessTraveller.currentAccountMode === 'business' &&
        offerCredits
      ) {
        const offerCreditKey = generateCheckoutItemViewOfferCreditKey(view)
        const offerCredit = offerCredits[offerCreditKey]
        if (offerCredit?.status === 'success') {
          breakdownView.businessTravellerCredits = offerCredit.creditValue
        }
      }

      return breakdownView
    })

    return {
      hasRequiredData: flightViewsWithStatus.hasRequiredData,
      data: flightBreakdownViews,
    }
  },
)

export const selectEarnableFlightBusinessTravellerCreditsTotal = createSelector(
  (state: App.State) => getFlightItemsView(state),
  (
    flightViewsWithStatus,
  ): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    let price = 0
    for (const flightBreakdownView of flightViewsWithStatus.data) {
      price += flightBreakdownView.businessTravellerCredits ?? 0
    }

    return {
      hasRequiredData: flightViewsWithStatus.hasRequiredData,
      data: {
        price,
        taxesAndFees: 0,
        memberPrice: 0,
        value: 0,
        memberValue: 0,
        surcharge: 0,
        extraGuestSurcharge: 0,
      },
    }
  },
)

export const isStandaloneFlights = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => getFlightItems(state),
  (allItems, flightItems): boolean => {
    // a standalone flight can still have insurance/booking protection and/or a luxplus subscription
    return flightItems.length > 0 && allItems.every(item => isFlightItem(item) || isInsuranceItem(item) || isBookingProtectionItem(item) || isLuxPlusSubscriptionItem(item))
  },
)

export const checkoutContainsFlighsWithEmbeddedInsurance = createSelector(
  (state: App.State) => getFlightItems(state),
  (flightItems) => flightItems.some(item => item.flights.some(flight => flight.fareFamily?.withEmbeddedInsurance)),
)
