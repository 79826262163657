import { min } from 'lib/array/arrayUtils'

interface Props {
  offer: App.TourOffer | App.TourOfferSummary
  cabinType: string
}

export function getCheapestCruiseFlashOfferByCabin({ offer, cabinType }: Props) {
  const packages = offer.packages
  const cabinPackages = packages.filter((pkg) => pkg.name?.toLowerCase().includes(cabinType.toLowerCase()))
  return min(cabinPackages, pkg => (pkg.memberPrice || pkg.price) + (pkg.surcharge ?? 0) || Number.MAX_SAFE_INTEGER)
}
