import { createSelector } from 'reselect'
import { getAllOffers } from 'selectors/offerSelectors'
import { getLeAccommodationItems } from './view/accommodation'
import getBedbankItems from 'checkout/selectors/view/getBedbankItems'

export const hasFijiHotelInCart = createSelector(
  (state: App.State) => getAllOffers(state),
  (state: App.State) => getLeAccommodationItems(state),
  (state: App.State) => getBedbankItems(state),
  (offers, bedbankItems, leHotelItem) => {
    const isFijiBedbank = bedbankItems.some(item => (offers[item.offerId] as App.BedbankOffer)?.property?.timezone === 'Pacific/Fiji') ?? false
    const isFijiHotel = leHotelItem.some(item => (offers[item.offerId] as App.HotelOffer)?.property?.timezone === 'Pacific/Fiji') ?? false

    return isFijiBedbank || isFijiHotel
  },
)
