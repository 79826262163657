import { VideoData } from 'components/Common/Video/VimeoVideo'
import {
  PRODUCT_CLICK_VIEW_DETAILS, MAP_PROPERTY_PIN_CLICK,
  MAP_OFFER_INFO_CLICK,
  MAP_PIN_OFFER_VIEW,
  DEAL_VIDEO_PLAYED,
  DEAL_VIDEO_PAUSED,
  OFFER_PRODUCT_DETAIL_VIEW,
  BEDBANK_OFFER_PRODUCT_DETAIL_VIEW,
  TOUR_V2_OFFER_PRODUCT_DETAIL_VIEW,
  TOUR_V2_OFFER_VARIATION_VIEW,
  PACKAGE_VIEW_MORE_DETAILS_CLICK,
  INITIALISE_SEARCH_SESSION_ID,
  GENESYS_CUSTOM_EVENT,
  PAGE_VIEW,
} from './actionConstants'
import uuidV4 from 'lib/string/uuidV4Utils'
import { AppAction } from './ActionTypes'
import { TrackableProduct } from 'components/OfferList/OfferListEventsContext'

export interface AnalyticEventPlacement {
  bannerId: string,
  name?: string,
  creative?: string,
  position?: string,
}

export interface AnalyticEvent {
  bannerId: string;
  placements?: Array<AnalyticEventPlacement>;
  hitType: string;
}

export function productClickViewDetails(data: TrackableProduct, position?: number, listId?: string, listName?: string) {
  return {
    type: PRODUCT_CLICK_VIEW_DETAILS,
    data,
    tracking: { position, listId, listName },
  }
}

export function mapPropertyPinClick(data: App.MapPropertyItem) {
  return {
    type: MAP_PROPERTY_PIN_CLICK,
    data,
  }
}

export function mapOfferInfoClick(data: App.Offer) {
  return {
    type: MAP_OFFER_INFO_CLICK,
    data,
  }
}

export function mapPinOfferView(data: App.MapPropertyItem) {
  return {
    type: MAP_PIN_OFFER_VIEW,
    data,
  }
}

export function dealVideoPlayed(data: VideoData) {
  return {
    type: DEAL_VIDEO_PLAYED,
    data,
  }
}

export function dealVideoPaused(data: VideoData) {
  return {
    type: DEAL_VIDEO_PAUSED,
    data,
  }
}

export function offerProductDetailView(data: App.Offer) {
  return {
    type: OFFER_PRODUCT_DETAIL_VIEW,
    data,
  }
}

export function bedbankOfferProductDetailView(data: App.BedbankOffer) {
  return {
    type: BEDBANK_OFFER_PRODUCT_DETAIL_VIEW,
    data,
  }
}

export function tourV2OfferProductDetailView(data: App.Tours.TourV2Offer) {
  return {
    type: TOUR_V2_OFFER_PRODUCT_DETAIL_VIEW,
    data,
  }
}

export function tourV2OfferVariationView(data: App.Tours.TourV2Offer) {
  return {
    type: TOUR_V2_OFFER_VARIATION_VIEW,
    data,
  }
}

export function packageViewMoreDetailsClick() {
  return {
    type: PACKAGE_VIEW_MORE_DETAILS_CLICK,
  }
}

export function initialiseSearchSessionId(offerListKey: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.analytics.search.sessions[offerListKey]) {
      return
    }

    dispatch({
      type: INITIALISE_SEARCH_SESSION_ID,
      offerListKey,
      sessionId: uuidV4(),
    })
  }
}

export function fireGenesysCustomEvent({ type, data = {} }: { type: string, data?: Record<string, any> }) {
  return {
    type: GENESYS_CUSTOM_EVENT,
    data: {
      eventType: type,
      ...data,
    },
  }
}

interface PageViewParams {
  template: string;
  params: object;
  path: string;
  search?: string;
}

export function registerPageView(params: PageViewParams) {
  return {
    type: PAGE_VIEW,
    pathTemplate: params.template,
    pathParams: params.params,
    path: params.path,
    search: params.search,
  }
}
