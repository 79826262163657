import React, { useState, useEffect, useCallback } from 'react'

import { SaveItemsCallbackResult } from '../BookmarkButton/utils'

import {
  createTripEvent,
  createTripSaveEvent,
  saveToTripEvent,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import usePrevious from 'hooks/usePrevious'
import { EmptyArray } from 'lib/array/arrayUtils'
import { setRecentlySavedTripId } from 'storage/recentSavedTrip'
import NameTrip from 'tripPlanner/components/EntryPoints/Common/Fields/NameTrip'
import TripSelect from 'tripPlanner/components/EntryPoints/ModalChildren/TripSelect'
import AdditionalDetails from 'tripPlanner/components/TripModal/Trips/CreateTrip/AdditionalDetails'
import { useCreateTrip, useEditableTrips } from 'tripPlanner/hooks/api/trip'
import useBookmarkSnackbarHandlers from 'tripPlanner/hooks/bookmarks/useSavedItemSnackbarHandlers'
import {
  setCurrentSelectionId,
  setTripItemHasJustBeenAdded,
} from 'tripPlanner/reducers/actions'
import { FullTrip, BasicTrip } from 'tripPlanner/types/common'
import { getDefaultTripImageId } from 'tripPlanner/utils'
import { tripItemSelectionId } from 'tripPlanner/utils/itemSelection'

const titleOptions = {
  hasTrips: 'Save to trip',
  createTrip: 'Name your new trip',
  flight: 'Save this flight to your first trip',
  offer: 'Save this offer to your first trip',
} as const

export type TripPlannerSaveModalResult =
  | {
      status: 'dismissed'
    }
  | {
      status: 'error'
      error: any
    }
  | {
      status: 'created'
      trip: BasicTrip | FullTrip
    }

interface Props {
  /** Result should be an array of the saved item IDs */
  createTripItems: (tripId: string) => Promise<SaveItemsCallbackResult>
  itemTypeLabel: 'offer' | 'flight'
  offerImageId?: string
  defaultTripName?: string
  currentTripId?: string
  isCreatingItem: boolean
}

type ModalState =
  | { name: 'select' }
  | { name: 'create' }
  | { name: 'details'; trip: FullTrip }

function SaveModal({
  defaultTripName = '',
  createTripItems,
  itemTypeLabel,
  offerImageId,
  isCreatingItem,
}: Props) {
  const { data, isFetching, isFetched: tripsAreFetched } = useEditableTrips()
  const trips: Array<BasicTrip> = data ?? EmptyArray
  const hasTrips = trips.length > 0

  const [tripName, setTripName] = useState(defaultTripName)
  const [errorMsg, setErrorMsg] = useState('')
  const [view, setView] = useState<ModalState>(
    trips.length > 0 ? { name: 'select' } : { name: 'create' },
  )
  const [savedItemIds, setSavedItemIds] = useState<Array<string>>([])

  const dispatch = useAppDispatch()

  const isCreateModal = view.name === 'create'

  const newTripOfferTitle = hasTrips ?
    view.name == 'create' ?
      titleOptions.createTrip :
      titleOptions.hasTrips :
    itemTypeLabel === 'flight' ?
      titleOptions.flight :
      titleOptions.offer

  const modalElement = useModalElementContext<TripPlannerSaveModalResult>()

  const tripsWereFetched = usePrevious(tripsAreFetched)
  useEffect(() => {
    if (
      modalElement.open &&
      view.name === 'select' &&
      tripsAreFetched &&
      !tripsWereFetched &&
      !hasTrips
    ) {
      setView({ name: 'create' })
    }
  }, [hasTrips, modalElement.open, tripsAreFetched, tripsWereFetched, view])

  useEffect(() => {
    if (isCreateModal) {
      setErrorMsg('')
    }
  }, [isCreateModal])

  const { mutate: createTrip, isLoading: isCreatingTrip } = useCreateTrip({
    onSuccess: async(trip) => {
      try {
        const result = await createTripItems(trip.id)
        setSavedItemIds(result.savedItemIds)
        setView({ name: 'details', trip })
      } catch (error) {
        // Just close the modal - other error handling should be handled wherever `createTripItems` is defined
        modalElement.resolve({ status: 'error', error })
      }
    },
    onError: (e) => {
      const error = e

      if (error.code === 'TripNameConflict') {
        setErrorMsg(error.message)
      } else {
        setErrorMsg('Unknown server error')
      }
    },
  })

  const { showSaveSuccessSnackbar } = useBookmarkSnackbarHandlers()

  const concludeSave = useCallback(
    (trip: FullTrip | BasicTrip, savedItemIds: Array<string>) => {
      const itemId = savedItemIds[0]
      dispatch(setCurrentSelectionId(tripItemSelectionId(itemId)))
      dispatch(setTripItemHasJustBeenAdded())
      showSaveSuccessSnackbar(trip.id, trip.name, itemId)
      setRecentlySavedTripId(trip.id)
    },
    [dispatch, showSaveSuccessSnackbar],
  )

  const onCloseAdditionalDetails = useCallback(
    (trip: FullTrip) => {
      modalElement.resolve({ status: 'created', trip })
      concludeSave(trip, savedItemIds)
    },
    [concludeSave, modalElement, savedItemIds],
  )

  const isTripNameEmpty = tripName.length === 0
  const createNewTrip = useCallback(async() => {
    if (isTripNameEmpty) {
      setErrorMsg('The trip name field cannot be empty')
    } else {
      fireInteractionEvent(
        saveToTripEvent('modal_new_trip_name', 'global', 'save'),
      )
      if (!hasTrips) {
        fireInteractionEvent(
          saveToTripEvent('modal_first_trip_name', 'global', 'save'),
        )

        fireInteractionEvent(createTripSaveEvent(tripName, false))
      }
      createTrip({
        name: tripName,
        imageId: offerImageId || getDefaultTripImageId(trips),
      })
    }
  }, [createTrip, offerImageId, tripName, trips, isTripNameEmpty, hasTrips])

  const isLoading = isCreatingTrip || isCreatingItem || isFetching
  const onDismiss = useCallback(() => {
    fireInteractionEvent(
      saveToTripEvent(
        isCreateModal ? 'modal_new_trip_name' : 'modal',
        'global',
        'dismiss',
      ),
    )
    if (isCreateModal && !hasTrips) {
      fireInteractionEvent(
        saveToTripEvent('modal_first_trip_name', 'global', 'dismiss'),
      )
      fireInteractionEvent(
        createTripEvent(
          'modal_first_trip_name',
          'global',
          'dismiss',
          true,
          true,
        ),
      )
    }
    modalElement.resolve({ status: 'dismissed' })
  }, [modalElement, isCreateModal, hasTrips])

  const onCreateSelect = useCallback(() => {
    fireInteractionEvent(saveToTripEvent('modal_new_trip', 'global', 'click'))
    setView({ name: 'create' })
  }, [])

  const onSelectTrip = useCallback(
    async(trip: FullTrip | BasicTrip) => {
      fireInteractionEvent(
        saveToTripEvent('modal_existing_trip', 'global', 'click'),
      )
      const result = await createTripItems(trip.id)
      modalElement.resolve({ status: 'created', trip })
      concludeSave(trip, result.savedItemIds)
    },
    [concludeSave, createTripItems, modalElement],
  )

  if (view.name === 'select') {
    return (
      <ModalBase>
        <ModalHeader
          title={isLoading ? '' : newTripOfferTitle}
          onCloseButtonClick={onDismiss}
        />
        <ModalBody>
          <ModalContent>
            {!isLoading && (
              <TripSelect
                onCreateSelect={onCreateSelect}
                onSelectTrip={onSelectTrip}
                trips={trips}
              />
            )}
            {isLoading && <LoadingIndicator floating />}
          </ModalContent>
        </ModalBody>
      </ModalBase>
    )
  } else if (view.name === 'create') {
    return (
      <ModalBase size={!hasTrips ? 'L' : 'S'}>
        <ModalHeader
          title={isLoading ? '' : newTripOfferTitle}
          onCloseButtonClick={onDismiss}
        />
        <ModalBody>
          <ModalContent>
            {!isLoading && (
              <NameTrip
                setErrorMsg={setErrorMsg}
                setTripName={setTripName}
                firstTime={!hasTrips}
                tripName={tripName}
                errorMsg={errorMsg}
                onSubmit={createNewTrip}
              />
            )}
            {isLoading && <LoadingIndicator floating />}
          </ModalContent>
        </ModalBody>
        <ModalFooter
          primaryActionProps={{
            children: 'Create trip',
            disabled: isTripNameEmpty || isLoading,
            onClick: createNewTrip,
          }}
          secondaryActionProps={{
            children: 'Cancel',
            disabled: isLoading,
            onClick: onDismiss,
          }}
        />
      </ModalBase>
    )
  } else {
    return (
      <ModalBase size="L" height="auto">
        <AdditionalDetails
          trip={view.trip}
          onClose={onCloseAdditionalDetails}
          isInBookmarkingFlow
        />
      </ModalBase>
    )
  }
}

export default SaveModal
