import React, { MouseEventHandler, useCallback, useContext, useMemo, useRef } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import cn from 'clsx'
import { SearchMenuStates } from '../type'
import DateInputDesktop from './SearchDateInput/SearchDateInputDesktop'
import SearchTravellersInputDesktop from './SearchTravellersInput/SearchTravellersInputDesktop'
import { isRoomsInvalid } from 'lib/search/searchUtils'
import TextButton from 'components/Luxkit/Button/TextButton'
import zIndex from 'styles/tools/z-index'
import { DATE_SEARCH_OPTION_IDS, LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL, LOCATION_SEARCH_INPUT_TITLE_LABEL } from 'constants/search'
import config from 'constants/config'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import SearchLocationInput from './SearchLocationInput/SearchLocationInput'
import BusinessTravellerAccountGuard from 'businessTraveller/components/BusinessTravellerAccountGuard'
import BusinessTravellerSelectHotelsDesktop from 'businessTraveller/components/select-traveller/BusinessTravellerSelectHotelsDesktop'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineArrowLeftIcon from 'components/Luxkit/Icons/line/LineArrowLeftIcon'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import { useAppDispatch } from 'hooks/reduxHooks'
import { pushWithRegion } from 'actions/NavigationActions'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import BusinessTravellerSelectLoadingSkeleton from 'businessTraveller/components/select-traveller/BusinessTravellerSelectLoadingSkeleton'
import { connect } from 'react-redux'
import { selectLoggedIn } from 'selectors/accountSelectors'
import NavigationHeader from 'components/App/Header/HeaderMain/NavigationHeader/NavigationHeader'
import Group from 'components/utils/Group'
import { useHistory } from 'react-router'
import BusinessTravellerSelectDisabled from 'businessTraveller/components/select-traveller/BusinessTravellerSelectDisabled'

const InputWrapper = styled.section`
  padding: 0;

  ${mediaQueryUp.tablet} {
    width: 100%;
    max-width: ${rem(1140)};
    position: relative;
    gap: ${rem(12)};
    display: grid;
    z-index: ${zIndex.searchMenu};
    grid-template:
      "location-input location-input location-input" auto
      "date-input traveller-input actions" auto;
  }

  ${mediaQueryUp.desktop} {
    grid-template: "location-input date-input traveller-input actions" auto / 1fr ${rem(320)} ${rem(154)} auto;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.location-input {
    grid-area: location-input;
  }

  &.date-input {
    grid-area: date-input;
  }

  &.traveller-input {
    grid-area: traveller-input;
  }

  &.actions {
    grid-area: actions;
  }

  &.arrow {
    grid-area: arrow;
    display: flex;
    align-items: flex-start;
  }

  &.filters {
    grid-area: filters;
    display: flex;
    align-items: flex-end;
  }

  &.login {
    grid-area: login;
  }
`

const SearchButton = styled(TextButton)`
  height: ${rem(50)};
  min-width: ${rem(112)};
`

interface MappedStateProps {
  isLoggedIn: boolean,
  routeHistory: App.RouteHistoryState
}

interface Props {
  searchItem: App.SearchItem;
  rooms: Array<App.Occupants>;
  openDatePickerMenu: (e: React.MouseEvent<HTMLButtonElement>) => void;
  openTravellerMenu: (e: React.MouseEvent<HTMLButtonElement>) => void;
  openLocationMenu: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  closeMenu: () => void;
  activeMenu: SearchMenuStates;
  onRoomChange: (rooms: Array<App.Occupants>) => void;
  onRoomsApply: () => void;
  onDatesApply: () => void;
  onDatesDecline: () => void;
  showRoomErrors: boolean;
  anytimeDateSelected?: boolean;
  selectAnytimeDate?: (shouldApply: boolean) => void;
  selectSpecificDates?: () => void;
  selectFlexibleDates?: () => void;
  onFlexibleMonthSelected?: () => void;
  onFlexibleNightsSelected?: () => void;
  dateSearchOptionId?: DATE_SEARCH_OPTION_IDS
  onLocationChange?: (item: App.SearchItem) => void;
  saleUnit?: string;
  typeaheadTypes?: Array<App.SearchPlaceType>;
  recentSearches?: Array<App.FullRecentSearch>;
  switchView?: {
    label: string,
    view: 'map' | 'list'
    URL: string
  }
  locationSearchPlaceholder?: string
}

const SearchDesktopInputs = React.forwardRef<HTMLInputElement, Props & MappedStateProps>((props, ref) => {
  const {
    activeMenu,
    searchItem,
    rooms,
    openTravellerMenu,
    openLocationMenu,
    closeMenu,
    openDatePickerMenu,
    onRoomChange,
    onRoomsApply,
    onDatesApply,
    onDatesDecline,
    showRoomErrors,
    anytimeDateSelected,
    selectAnytimeDate,
    selectSpecificDates,
    selectFlexibleDates,
    dateSearchOptionId,
    onLocationChange,
    saleUnit,
    typeaheadTypes,
    recentSearches,
    switchView,
    locationSearchPlaceholder = LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL,
    isLoggedIn,
    routeHistory,
  } = props
  const dispatch = useAppDispatch()
  const { checkinDate, checkoutDate } = useContext(GlobalSearchStateContext)

  const isFormInvalid = useMemo(() => isRoomsInvalid(rooms), [rooms])
  const history = useHistory()
  const datesSelected = !!(checkinDate && checkoutDate)

  const isSearchDisabled = config.businessTraveller.currentAccountMode === 'business' && !datesSelected
  const isBusinessTravellerSelectEnabled = config.businessTraveller.currentAccountMode === 'business'

  const toListView = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()

    if (switchView) {
      // If you are navigating back from the map view you should be taken back to where you have scrolled
      if (routeHistory.currentPath === '/au/search/map' && routeHistory.prevPath === '/au/search') {
        history.goBack()
        return
      }

      dispatch(pushWithRegion(switchView.URL))
    }
  }, [switchView, routeHistory.currentPath, routeHistory.prevPath, dispatch, history])

  const isMapPage = switchView?.view === 'list'

  const inputWrapperRef = useRef<HTMLDivElement>(null)

  return <Group direction="horizontal" gap={12} horizontalAlign="center" verticalAlign="center">
    {isMapPage && <CSSBreakpoint min="tablet">
      <TextButton
        kind="tertiary"
        size="large"
        onClick={toListView}
        className="list-view"
        aria-label="list-view"
        data-testid="list-view"
        startIcon={<LineArrowLeftIcon/>}
      >
        {switchView.label}
      </TextButton>
    </CSSBreakpoint>}

    <InputWrapper className={cn({ 'map-view': isMapPage })} ref={inputWrapperRef}>
      <InputContainer className="location-input">
        <SearchLocationInput
          label={LOCATION_SEARCH_INPUT_TITLE_LABEL}
          placeholder={locationSearchPlaceholder}
          placeTypes={typeaheadTypes}
          onChange={onLocationChange}
          variant="dropdown"
          inputRef={ref}
          recentSearchItems={recentSearches}
          onLocationToggle={openLocationMenu}
          controlsContainerRef={inputWrapperRef}
          searchVertical="hotel"
        />
      </InputContainer>
      <InputContainer className="date-input">
        <DateInputDesktop
          dropdownAnchorRef={inputWrapperRef}
          onDateToggle={openDatePickerMenu}
          isToggled={activeMenu === SearchMenuStates.Dates}
          onDatesApply={onDatesApply}
          onDatesDecline={onDatesDecline}
          anytimeDateSelected={anytimeDateSelected}
          selectAnytimeDate={selectAnytimeDate}
          selectSpecificDates={selectSpecificDates}
          selectFlexibleDates={selectFlexibleDates}
          dateSearchOptionId={dateSearchOptionId}
          closeMenu={closeMenu}
        />
      </InputContainer>
      <InputContainer className="traveller-input">
        {isBusinessTravellerSelectEnabled && <BusinessTravellerAccountGuard
          accountMode="business"
          employeeRoles={['BUSINESS_ADMIN', 'BUSINESS_MANAGER']}
          loading={<BusinessTravellerSelectLoadingSkeleton />}
          fallback={<BusinessTravellerSelectDisabled label="Guests" value="1 adult (1 room)" className="traveller-input" />}
        >
          <BusinessTravellerSelectHotelsDesktop
            activeMenu={activeMenu}
            onRoomChange={onRoomChange}
            openTravellerMenu={openTravellerMenu}
            closeMenu={closeMenu}
          />
        </BusinessTravellerAccountGuard>}
        {!isBusinessTravellerSelectEnabled && <SearchTravellersInputDesktop
          dropdownAnchorRef={inputWrapperRef}
          rooms={rooms}
          onTravellersToggle={openTravellerMenu}
          isToggled={activeMenu === SearchMenuStates.Rooms}
          onRoomChange={onRoomChange}
          onRoomsApply={onRoomsApply}
          closeMenu={closeMenu}
          isLastStep={!!searchItem}
          error={showRoomErrors && isFormInvalid}
          saleUnit={saleUnit}
        />}
      </InputContainer>
      <InputContainer className="actions">
        {!isMapPage && <SearchButton disabled={isSearchDisabled} kind="primary" type="submit" size="large">
          Search
        </SearchButton>}
        {isMapPage && <IconButton disabled={isSearchDisabled} kind="primary" type="submit" size="large">
          <LineSearchIcon />
        </IconButton>}
      </InputContainer>
    </InputWrapper>
    {isMapPage && <NavigationHeader isLoggedIn={isLoggedIn} />}
  </Group>
})

export default connect<MappedStateProps, undefined, Props, App.State>((state: App.State): MappedStateProps => (
  {
    isLoggedIn: selectLoggedIn(state),
    routeHistory: state.routeHistory,
  }
), null, null, { forwardRef: true })(SearchDesktopInputs)
