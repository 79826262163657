import { createSelector } from 'reselect'
import { sortBy } from 'lib/array/arrayUtils'
import getCreditPayableAmount from 'checkout/selectors/payment/getCreditPayableAmount'

const getCheckoutLuxLoyaltyUnearnableCreditAmount = createSelector(
  (state: App.State) => getCreditPayableAmount(state),
  (state: App.State) => state.auth.account.creditsCollection,
  (creditPayableAmount, creditsCollection) => {
    if (!creditPayableAmount || !creditsCollection?.data?.length) return 0

    let remainingCreditAmount = creditPayableAmount

    const sortedCreditsCollectionByExpiryDate = sortBy(creditsCollection.data, (credit) => {
      return new Date(credit.expiresAt).getTime()
    }, 'asc')

    sortedCreditsCollectionByExpiryDate.forEach((credit) => {
      if (credit.isLuxLoyaltyEarnable) {
        const amountToSubtract = Math.min(remainingCreditAmount, credit.amount)
        remainingCreditAmount -= amountToSubtract
      }
    })

    return remainingCreditAmount
  },
)

export default getCheckoutLuxLoyaltyUnearnableCreditAmount
