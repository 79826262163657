import cn from 'clsx'
import React, { CSSProperties, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { themeClassName } from 'lib/theme/themeUtils'

const Root = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.eight};
  box-shadow: none;
  transition: box-shadow 0.2s;

  &.bordered {
    border: 1px solid ${props => props.theme.palette.neutral.default.five};
    border-radius: ${props => props.theme.borderRadius.S};
  }

  &.type-clean {
    border: 1px solid ${props => props.theme.palette.neutral.default.five};

    &:not(.axis-vertical) {
      & + & {
        border-top: 0;
      }
    }
  }

  &.type-light {
    background-color: ${props => props.theme.palette.neutral.default.six};
  }

  &.type-dark {
    background-color: ${props => props.theme.palette.neutral.default.one};
  }

  &.type-light-grey {
    background-color: ${props => props.theme.palette.neutral.default.seven};
  }

  &.type-clear {
    background-color: transparent;
  }

  &.type-lux-plus {
    background-color: ${props => props.theme.palette.product.luxPlus.background};
  }

  &.type-tier-bronze {
    background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.lightBackground};
  }

  &.type-tier-silver {
    background-color: ${props => props.theme.palette.product.luxLoyalty.silver.lightBackground};
  }

  &.type-tier-gold {
    background-color: ${props => props.theme.palette.product.luxLoyalty.gold.lightBackground};
  }

  &.type-tier-platinum {
    background-color: ${props => props.theme.palette.product.luxLoyalty.platinum.lightBackground};
  }

  &.shadow {
    box-shadow: ${props => props.theme.shadow.bottom.small};
  }

  &.axis-vertical {
    & + & {
      margin-top: ${rem(16)};
    }
  }

  &.axis-inline {
    & + &{
      margin-left: ${rem(20)};
    }
  }

`

interface Props extends React.ComponentProps<typeof Root> {
  axis?: 'vertical' | 'inline';
  type?: 'clean' | 'light' | 'clear' | 'light-grey' | 'dark' | 'lux-plus' | 'tier-bronze' | 'tier-silver' | 'tier-gold' | 'tier-platinum';
  shadow?: boolean;
  bordered?: boolean;
  paletteType?: App.Design.PaletteType
  tabletPaletteType?: App.Design.PaletteType
  desktopPaletteType?: App.Design.PaletteType
  style?: CSSProperties
  'data-testid'?: string
}

const Pane = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    axis,
    children,
    type,
    shadow,
    className,
    bordered,
    paletteType,
    tabletPaletteType,
    desktopPaletteType,
    ...rest
  } = props

  return (
    <Root {...rest} className={cn(
      className,
      themeClassName({
        base: paletteType,
        tabletAndUp: tabletPaletteType,
        // types are complicated here, but it's fine, I promise
        desktopAndUp: desktopPaletteType as any,
      }),
      axis ? `axis-${axis}` : undefined,
      type ? `type-${type}` : undefined,
      { shadow, bordered },
    )} ref={ref}
    >
      {children}
    </Root>
  )
})

Pane.displayName = 'Pane'

export default Pane
