import React, { useState, useCallback, useContext, ComponentProps } from 'react'
import { trackAutosuggestionClick, trackAutosuggestionsResult } from 'api/interactionStudio'
import GeoContext from 'contexts/geoContext'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import noop from 'lib/function/noop'
import uuidV4 from 'lib/string/uuidV4Utils'
import SearchPlaceSelect from 'components/Search/SearchPlaceSelect/SearchPlaceSelect'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchExperienceById } from 'actions/ExperienceActions'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { lereRecommendedDestinationsToTrendingDestinations } from 'lib/customer/recommendationUtils'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import { DESTINATION_MODEL_ELIGIBLE_REGIONS, SEARCH_VERTICALS } from 'constants/search'
const SEARCH_BAR_DESTINATIONS_LIMIT = 9
const MINIMUM_NUM_DESTINATIONS = 6

interface Props {
  placeTypes?: Array<App.SearchPlaceType>;
  searchTypes?: Array<App.SearchType>;
  placeholder?: string;
  onChange?: (item?: App.SearchItem) => void;
  className?: string;
  label?: string;
  variant: 'inline' | 'dropdown';
  dropdownSize?: ComponentProps<typeof SearchPlaceSelect>['dropdownSize']
  inputRef?: React.Ref<HTMLInputElement>;
  controlsContainerRef?: React.RefObject<HTMLDivElement>;
  /** Whether or not this search input is for the secondary location on a search */
  isSecondaryLocation?: boolean;
  /** Allow to provide your own set of placeholder items (often 'popular places') instead of taking from state */
  placeholderItems?: Array<App.SearchItem>;
  /** A list of recent search items, will be displayed along with placeholder items */
  recentSearchItems?: Array<App.FullRecentSearch>;
  /** Use this to supply your own search results list the input will use */
  overrideSearchItems?: Array<App.SearchItem>;
  required?: boolean;
  searchVertical?: App.SearchPlaceVertical;
  onLocationToggle?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Common search control for setting search for a 'place'
 * Is already hard wired into the existing global search context
 * and will populate the 'searchItem' property
 */
function SearchLocationInput(props: Props) {
  const {
    searchTypes,
    placeholder,
    onChange = noop,
    className,
    label,
    placeTypes,
    variant,
    dropdownSize,
    inputRef,
    isSecondaryLocation,
    placeholderItems,
    required,
    searchVertical,
    recentSearchItems,
    overrideSearchItems,
    onLocationToggle,
    controlsContainerRef,
  } = props
  const dispatch = useAppDispatch()
  const [searchSuggestionSessionId, setSearchSuggestionSessionId] = useState<string>('')
  const [currentSuggestions, setCurrentSuggestions] = useState<Array<App.SearchItem>>([])
  const { currentRegionCode } = useContext(GeoContext)
  const searchState = useContext(GlobalSearchStateContext)
  const { searchVerticals, popularDestinations } = searchState
  const searchItem = isSecondaryLocation ? searchState.secondarySearchItem : searchState.searchItem
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const domainUserId = useAppSelector(state => state.auth.domainUserId)
  const leUserId = useAppSelector(getCurrentUserId)
  const recommendedDestinations = useAppSelector(state => state.recommendations.recommendedDestinations)
  const { openedSearchVertical } = useGlobalSearchURLHashVertical()
  const onLocationSelect = useCallback((searchItem?: App.SearchItem, searchPhrase: string = '') => {
    if (!searchItem) {
      searchDispatch({
        type: isSecondaryLocation ? GlobalSearchStateActions.UNSET_SECONDARY_SEARCH_ITEM : GlobalSearchStateActions.UNSET_SEARCH_ITEM,
      })
    } else {
      if (searchItem.searchType === 'experience') {
        dispatch(fetchExperienceById(searchItem.value))
      }

      searchDispatch({
        type: isSecondaryLocation ? GlobalSearchStateActions.SET_SECONDARY_SEARCH_ITEM : GlobalSearchStateActions.SET_SEARCH_ITEM,
        searchItem,
      })

      const showSuggestions = searchPhrase && searchPhrase !== searchState.searchItem?.format.mainText
      const clickedSuggestionIndex = showSuggestions ? currentSuggestions.findIndex(item => item.value === searchItem.value) : -1

      trackAutosuggestionClick({
        region: currentRegionCode,
        verticals: searchVerticals,
        searchSuggestionSessionId,
        searchPhrase,
        clickedSuggestionIndex,
        clickedSuggestion: searchItem,
      })
    }
    onChange(searchItem)
  }, [onChange, searchDispatch, isSecondaryLocation, searchState.searchItem?.format.mainText, currentSuggestions, currentRegionCode, searchVerticals, searchSuggestionSessionId, dispatch])

  const onResultsChange = useCallback((results: Array<App.SearchItem>, searchPhrase: string) => {
    setCurrentSuggestions(results)
    searchDispatch({
      type: GlobalSearchStateActions.SET_SUGGESTED_SEARCH_ITEMS,
      searchItems: results,
    })
    const newSearchSuggestionSessionId = uuidV4()
    setSearchSuggestionSessionId(newSearchSuggestionSessionId)
    trackAutosuggestionsResult({
      region: currentRegionCode,
      verticals: searchVerticals,
      searchSuggestionSessionId: newSearchSuggestionSessionId,
      searchPhrase,
      suggestions: results,
    })
  }, [currentRegionCode, searchVerticals, searchDispatch])
  const isEligibleRegion = DESTINATION_MODEL_ELIGIBLE_REGIONS.includes(currentRegionCode)
  const hasUserId = !!domainUserId || !!leUserId
  const hotelSearchSelected = openedSearchVertical === SEARCH_VERTICALS.HOTELS
  const hasRecommendations = recommendedDestinations.state !== 'loading' && recommendedDestinations.destinations.length > 0
  const shouldShowRecommendations = isEligibleRegion && hasUserId && hotelSearchSelected && hasRecommendations
  const personalisedRecommendations = lereRecommendedDestinationsToTrendingDestinations(recommendedDestinations.destinations).slice(0, SEARCH_BAR_DESTINATIONS_LIMIT)
  const personalisedRecsSearchItem: Array<App.SearchItem> = personalisedRecommendations.map(rec => ({
    value: rec.destinationId,
    format: {
      mainText: rec.name,
    },
    imageId: rec.imageId,
    searchType: 'destination',
  } as App.SearchDestination))
  const popularItems = (shouldShowRecommendations && personalisedRecsSearchItem.length >= MINIMUM_NUM_DESTINATIONS) ? personalisedRecsSearchItem : (placeholderItems ?? popularDestinations)

  return <SearchPlaceSelect
    className={className}
    label={label}
    placeholder={placeholder}
    value={searchItem}
    searchTypes={searchTypes}
    placeTypes={placeTypes}
    onChange={onLocationSelect}
    onToggle={onLocationToggle}
    onResultsChange={onResultsChange}
    placeholderItems={popularItems}
    placeholderTitle="Popular places"
    variant={variant}
    dropdownSize={dropdownSize}
    inputRef={inputRef}
    required={required}
    searchVertical={searchVertical}
    recentSearchItems={recentSearchItems}
    overrideSearchItems={overrideSearchItems}
    controlsContainerRef={controlsContainerRef}
  />
}

export default SearchLocationInput
