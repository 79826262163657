import { dateIsAfter, dateIsAfterOrSame, dateIsBefore, dateIsBeforeOrSame, isSameDay, startOfDay } from 'lib/datetime/dateUtils'
import { sortByAll } from 'lib/array/arrayUtils'
import { CHECKOUT_ITEM_TYPE_BEDBANK, CHECKOUT_ITEM_TYPE_EXPERIENCE, CHECKOUT_ITEM_TYPE_FLIGHT, CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_TRANSFER } from 'constants/checkout'

export type OverlappingItem = {
  itemId: string;
  type: string;
  checkIn?: string;
  checkOut?: string;
  date?: string;
  offerId?: string;
  title?: string | null;
  description?: string | null;
  roomImage?: App.Image;
  occupancy?: App.Occupants;
  fareType?: string;
  maxPassengers?: number;
}

export type OverlapResult = {
  hasOverlap: boolean
  overlappingItem: Array<OverlappingItem>
}

export function getStartDate(item: OverlappingItem): Date {
  let dateStr = ''
  if (item.type === CHECKOUT_ITEM_TYPE_LE_HOTEL || item.type === CHECKOUT_ITEM_TYPE_BEDBANK || item.type === CHECKOUT_ITEM_TYPE_FLIGHT) {
    dateStr = item.checkIn || ''
  } else if (item.type === CHECKOUT_ITEM_TYPE_EXPERIENCE || item.type === CHECKOUT_ITEM_TYPE_TRANSFER) {
    dateStr = item.date || ''
  }
  return startOfDay(new Date(dateStr))
}

export function getEndDate(item: OverlappingItem): Date {
  if (item.type === CHECKOUT_ITEM_TYPE_LE_HOTEL || item.type === CHECKOUT_ITEM_TYPE_BEDBANK || item.type === CHECKOUT_ITEM_TYPE_FLIGHT) {
    return item.checkOut ? startOfDay(new Date(item.checkOut)) : getStartDate(item)
  }
  return getStartDate(item)
}

function isSameTypeAndDate(item1: OverlappingItem, item2: OverlappingItem): boolean {
  return item1.type === item2.type && item1.date === item2.date
}

function isFlightHotelOverlap(item: OverlappingItem, second: OverlappingItem, itemStart: Date, itemEnd: Date, secondStart: Date, secondEnd: Date): boolean {
  if (
    (item.type === CHECKOUT_ITEM_TYPE_FLIGHT && [CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(second.type)) &&
    isSameDay(itemStart, secondStart) && isSameDay(itemEnd, secondEnd)
  ) {
    return false
  }

  if (
    ([CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(item.type) && second.type === CHECKOUT_ITEM_TYPE_FLIGHT) &&
    isSameDay(itemStart, secondStart) && isSameDay(itemEnd, secondEnd)
  ) {
    return false
  }

  return (
    (item.type === CHECKOUT_ITEM_TYPE_FLIGHT && [CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(second.type)) ||
    ([CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(item.type) && second.type === CHECKOUT_ITEM_TYPE_FLIGHT)
  ) && (
    (item.type === CHECKOUT_ITEM_TYPE_FLIGHT && (
      dateIsAfter(itemStart, secondStart) && dateIsBefore(itemStart, secondEnd) ||
      dateIsAfter(itemEnd, secondStart) && dateIsBefore(itemEnd, secondEnd)
    )) ||
    (second.type === CHECKOUT_ITEM_TYPE_FLIGHT && (
      dateIsAfter(secondStart, itemStart) && dateIsBefore(secondStart, itemEnd) ||
      dateIsAfter(secondEnd, itemStart) && dateIsBefore(secondEnd, itemEnd)
    ))
  )
}

function isSameHotelOrBedbank(item1: OverlappingItem, item2: OverlappingItem): boolean {
  return (
    [CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(item1.type) &&
    [CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(item2.type) &&
    item1.title === item2.title
  )
}

function findOverlaps(items: Array<OverlappingItem>) {
  const overlappingItems = new Set<OverlappingItem>()

  items.forEach((item) => {
    const itemStart = getStartDate(item)
    const itemEnd = getEndDate(item)

    const overlappingItemList = items.filter((second) => {
      if (second.itemId === item.itemId) return false

      if (
        ((item.type === CHECKOUT_ITEM_TYPE_FLIGHT && [CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(second.type)) ||
        ([CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(item.type) && second.type === CHECKOUT_ITEM_TYPE_FLIGHT)) &&
        isSameDay(getStartDate(item), getStartDate(second)) &&
        isSameDay(getEndDate(item), getEndDate(second))
      ) {
        return false
      }

      if (item.type === CHECKOUT_ITEM_TYPE_FLIGHT && [CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(second.type)) {
        const flightStart = itemStart
        const flightEnd = itemEnd
        const hotelStart = getStartDate(second)
        const hotelEnd = getEndDate(second)

        if ((dateIsAfterOrSame(hotelStart, flightStart) && dateIsBeforeOrSame(hotelStart, flightEnd)) &&
          (dateIsAfterOrSame(hotelEnd, flightStart) && dateIsBeforeOrSame(hotelEnd, flightEnd))
        ) {
          return false
        }
      }
      if ([CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK].includes(item.type) && second.type === CHECKOUT_ITEM_TYPE_FLIGHT) {
        const hotelStart = itemStart
        const hotelEnd = itemEnd
        const flightStart = getStartDate(second)
        const flightEnd = getEndDate(second)

        if ((dateIsAfterOrSame(hotelStart, flightStart) && dateIsBeforeOrSame(hotelStart, flightEnd)) &&
          (dateIsAfterOrSame(hotelEnd, flightStart) && dateIsBeforeOrSame(hotelEnd, flightEnd))) {
          return false
        }
      }

      if ((item.type === CHECKOUT_ITEM_TYPE_TRANSFER || second.type === CHECKOUT_ITEM_TYPE_TRANSFER) || (item.type === CHECKOUT_ITEM_TYPE_EXPERIENCE || second.type === CHECKOUT_ITEM_TYPE_EXPERIENCE)) {
        return isSameTypeAndDate(item, second)
      }

      const secondStart = getStartDate(second)
      const secondEnd = getEndDate(second)

      if (isFlightHotelOverlap(item, second, itemStart, itemEnd, secondStart, secondEnd)) {
        return true
      }

      if (isSameHotelOrBedbank(item, second)) {
        return false
      }

      if (isSameDay(itemEnd, secondStart) || isSameDay(secondEnd, itemStart)) {
        return false
      }

      return dateIsBeforeOrSame(secondStart, itemEnd) && dateIsAfterOrSame(secondEnd, itemStart)
    })

    if (overlappingItemList.length > 0) {
      overlappingItems.add(item)
      overlappingItemList.forEach(overlappingItems.add, overlappingItems)
    }
  })

  return Array.from(overlappingItems)
}

export const userCartDatesOverlapping = (items: Array<OverlappingItem>) => {
  if (items.length < 2) {
    return { hasOverlap: false, overlappingItem: [] }
  }

  const filteredItems = items.filter(item =>
    [CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_BEDBANK, CHECKOUT_ITEM_TYPE_EXPERIENCE, CHECKOUT_ITEM_TYPE_TRANSFER, CHECKOUT_ITEM_TYPE_FLIGHT].includes(item.type),
  )

  const overlappingItem = findOverlaps(filteredItems)
  const itemTypeOrder: { [key: string]: number } = {
    hotel: 0,
    bedbankHotel: 1,
    flight: 2,
    experience: 3,
    transfer: 4,
  }

  const result = sortByAll(
    overlappingItem,
    [
      { selector: item => itemTypeOrder[item.type], direction: 'asc' },
      {
        selector: item => {
          return getStartDate(item).getTime()
        },
        direction: 'asc',
      },
    ],
  )

  return { hasOverlap: result.length > 0, overlappingItem: result }
}
