import React, { useCallback, useId } from 'react'
import { formToObject } from 'lib/forms/formToObject'
import useQueryParams from 'hooks/useQueryParams'
import useModalElementContext from 'hooks/Modal/useModalElementContext'

import Heading from 'components/Luxkit/Typography/Heading'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import RadioGroup from 'components/Luxkit/Radio/RadioGroup'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import { CruiseSearchSortOptions } from './Inputs/CruiseSortDropdown'
import CruiseSearchFiltersInputs from './Inputs/CruiseExtraFiltersInputs'
import { deleteSearchParams, setManySearchParamValues } from 'lib/url/searchUrlUtils'
import { CruiseSearchFilterKeys } from 'constants/cruise'
import { replace } from 'connected-react-router'
import { useLocation } from 'react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import useToggle from 'hooks/useToggle'

interface Props {
  listFilters: App.OfferListFilters
  isOpen?: boolean
  onClose?: () => void
  countSelected?: number
}

function CruiseSearchFiltersDrawer(props: Props) {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const { listFilters, isOpen = true, onClose, countSelected } = props
  const formId = useId()
  const modalElement = useModalElementContext<unknown>()
  const queryParams = useQueryParams()
  const sortValue = queryParams.get('sortBy') ?? CruiseSearchSortOptions[0].value
  const cruisePriceByNight = useCruisePriceByNight()
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle(isOpen)

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // eslint-disable-next-line
    const values = formToObject(e.currentTarget) as unknown as Record<string, any>
    values.priceBy = cruisePriceByNight ? 'night' : 'person'
    modalElement.resolve(values)
    toggleIsDrawerOpen()
  }, [cruisePriceByNight, modalElement, toggleIsDrawerOpen])

  const onResetAll = useCallback(() => {
    modalElement.resolve({})
    toggleIsDrawerOpen()
  }, [modalElement, toggleIsDrawerOpen])

  const onChange = useCallback((nextFilters: Record<string, any>) => {
    const urlValues = Object.entries(nextFilters).map(([key, value]) => ({ paramName: key, paramValue: value }))
    // reset all our possible filter keys
    let nextSearch = deleteSearchParams(search, ...CruiseSearchFilterKeys)
    nextSearch = setManySearchParamValues(nextSearch, urlValues)
    dispatch(replace({ search: nextSearch }))
  }, [dispatch, search])

  const onCloseDrawer = useCallback(() => {
    toggleIsDrawerOpen()
    onClose?.()
  }, [onClose, toggleIsDrawerOpen])

  return <ModalBase isOpen={isDrawerOpen} height="max">
    <ModalHeader title="Sort & filter" onCloseButtonClick={onCloseDrawer}/>
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={20} as="form" id={formId} onSubmit={onSubmit}>
          <VerticalSpacer gap={12} >
            <Heading variant="heading4">Sort by</Heading>
            <RadioGroup>
              {CruiseSearchSortOptions.map(option => <RadioInput
                key={option.value}
                defaultChecked={sortValue === option.value}
                name="sortBy"
                value={option.value}
              >
                {option.label}
              </RadioInput>)}
            </RadioGroup>
          </VerticalSpacer>
          <div>
            <Heading variant="heading4">Filter by</Heading>
            <CruiseSearchFiltersInputs
              listFilters={listFilters}
              onChange={onChange}
            />
          </div>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
    <ModalFooter>
      <Group direction="horizontal" gap={12} horizontalAlign="stretch">
        <TextButton
          kind="tertiary"
          size="large"
          onClick={onResetAll}
        >
          Reset all {!!countSelected && `(${countSelected})`}
        </TextButton>
        <TextButton
          kind="primary"
          type="submit"
          size="large"
          form={formId}
        >
          Apply
        </TextButton>
      </Group>
    </ModalFooter>
  </ModalBase>
}

export default CruiseSearchFiltersDrawer
