import { HIGH_RISK_LEAD_TIME_FOR_MAJORITY_CREDIT_IN_DAYS, HIGH_RISK_TRANSACTION_CREDIT_PERCENTAGE_THRESHOLD, MAX_STRIPE_CUSTOM_PAYTO_PAYMENT, PAYMENT_OPTIONS, PaymentMethod, PaypalSupplementaryData, RAZORPAY_PAYMENT_TYPE, SHORT_LEAD_TIME_WITH_GOOGLE_PAY_LEAD_TIME_DAYS, STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE, STRIPE_PAYMENT_TYPE } from 'constants/payment'
import { createSelector } from 'reselect'
import { isStandaloneFlights } from 'checkout/selectors/view/flights'
import { cartIncludesTourV2 } from './toursv2'
import {
  isStripe3dsEnabled,
  isStripe3dsV2Enabled,
  isStripe3dsForGiftCardsEnabled,
  isStripe3dsForStandaloneEnabled,
  isStripeBedbankRepeatCustomerCheckEnabled,
  isStripePaymentElementGiropayEnabled,
  isStripeCustomPaytoEnabled,
  isStripePaymentElementCardEnabled,
  isStripePaymentElementIDealEnabled,
  isStripePaymentElementKlarnaEnabled,
  isStripePaymentElementPayNowEnabled,
  isStripePaymentElementBusinessCardEnabled,
  isStripePaymentElementWeChatPayEnabled,
} from 'lib/payment/stripeUtils'
import { isGooglePayEnabled } from 'lib/payment/googlePayUtils'
import { isStandaloneExperiences } from 'checkout/selectors/payment/experience'
import { getAccommodationItems } from 'checkout/selectors/view/accommodation'
import { getCarHireItems } from 'checkout/selectors/view/carHire'
import { isLEStaffOrAdmin } from 'selectors/accountSelectors'
import { findPostPurchaseCheckout, isBedbankItem, isCruiseItem, isGiftCardItem, isLEAccommodationItem, isLEHotelItem, isTourV1Item, isTourV2Item } from 'lib/checkout/checkoutUtils'
import getPaymentMethods, { getPaymentMethodLimitsByRegion } from 'lib/payment/getPaymentMethods'
import getPayableAfterModifiers from './getPayableAfterModifiers'
import getCheckoutTotalsView from 'checkout/selectors/payment/getCheckoutTotalsView'
import getCheckoutTotal from 'checkout/selectors/payment/getCheckoutTotal'
import getPayableTotal from 'checkout/selectors/payment/getPayableTotal'
import {
  isBraintreeEnabled,
  isKlarnaEnabled,
  isWithinKlarnaAvailabilityRange,
  isKlarnaBpEnabled,
  isWithinKlarnaBpAvailabilityRange,
  getProjectedLeadTimeDaysByCartItems,
  isPayPalEnabled,
} from 'lib/payment/paymentUtils'
import { isAfterPayBpEnabled, isWithinAfterPayBpAvailabilityRange } from 'lib/payment/afterPayBpUtils'
import { isHoolahBpEnabled, isWithinHoolahBpAvailabilityRange } from 'lib/payment/hoolahBpUtils'
import { isApplePayEnabled } from 'lib/payment/applePayUtils'
import { isAtomeBpEnabled, isWithinAtomeBpAvailabilityRange } from 'lib/payment/atomeBpUtils'
import { isWaaveBpEnabled, isWithinWaaveBpAvailabilityRange } from 'lib/payment/waaveBpUtils'
import config from 'constants/config'
import { isVelocityEnabled } from 'selectors/featuresSelectors'
import { getVelocityEligibleItems } from './partnerships'
import { VIRGIN_VELOCITY_CHECKOUT_PAYMENT_METHODS } from 'constants/partnerships'
import { mapObject } from 'lib/object/objectUtils'
import getCreditPayableAmount from 'checkout/selectors/payment/getCreditPayableAmount'
import moment from 'moment'
import shouldShowGiftCards from 'lib/payment/shouldShowGiftCards'
import { isLuxPlusSubscriptionInCart, isStandaloneLuxPlusSubscription } from '../view/luxPlusSubscription'
import { getVillaItems } from '../view/villa'
import { isSecurePaymentEnabled } from 'lib/payment/securePaymentUtils'
import { isCruiseCheckout } from '../view/cruise'
import { isPaymentTypeFlyingBlueEnabled } from 'lib/payment/flyingblueUtils'
import { isPaymentTypeAzupayPayIdEnabled } from 'lib/payment/azupayUtils'
import { isPaymentScheduleBalance } from '../tourV2Selectors'
import { isPostPurchaseAncillaryPayment } from './isPostPurchaseAncillaryPayment'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import { getOptimizelyFeatureFlagVariation } from 'lib/optimizely/optimizelyUtils'
import getExperienceItems from '../view/getExperienceItems'
import getTransferItems from '../view/getTransferItems'

export const isDepositSelected = (state: App.State) => state.checkout.payment.paymentSelected === PAYMENT_OPTIONS.DEPOSIT

export const isPaymentScheduleSelected = (state: App.State) => state.checkout.payment.paymentSelected === PAYMENT_OPTIONS.PAYMENT_SCHEDULE

const getCheckoutCurrency = (state: App.State) => state.checkout.cart.currencyCode

export const isRazorpayAvailable = createSelector(
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => cartIncludesTourV2(state), // Temporarily disabled for tours, until it can be tested
  (currency, isStandaloneFlights, includesTourV2) => !includesTourV2 && getPaymentMethods(currency).includes(RAZORPAY_PAYMENT_TYPE) && !isStandaloneFlights,
)

export const isReserveForFreeSelected = (state: App.State) => state.checkout.payment.paymentSelected === PAYMENT_OPTIONS.RESERVE_FOR_FREE

export const isStripePaymentElementCardAvailable = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.geo.currentCurrency,
  (state: App.State) => isRazorpayAvailable(state),
  (regionCode, currencyCode, razorpayAvailable) => {
    return !!isStripePaymentElementCardEnabled(regionCode, currencyCode) && !razorpayAvailable
  },
)

export const isStripePaymentElementBusinessCardAvailable = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.geo.currentCurrency,

  (regionCode, currencyCode) => {
    return config.businessTraveller.currentAccountMode === 'business' && !!isStripePaymentElementBusinessCardEnabled(regionCode, currencyCode)
  },
)

const isGiftCardPurchase = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.length > 0 && items.some(isGiftCardItem),
)

export const isGiftCardEnabled = createSelector(
  (state: App.State) => getCheckoutTotal(state),
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (payableTotal, currencyCode, isGiftCard, isStandaloneFlight, isLuxPlusSubscriptionInCart): boolean => {
    // Disables gift card if there is no payment
    if (!payableTotal) return false

    return shouldShowGiftCards(currencyCode) &&
      !isGiftCard &&
      !isStandaloneFlight &&
      !isLuxPlusSubscriptionInCart
  },
)

const getGrandTotal = (state: App.State) => getCheckoutTotalsView(state).data.grandTotal

export const isFullPaymentSelected = (state: App.State) =>
  state.checkout.payment.paymentSelected === PAYMENT_OPTIONS.FULL &&
  !isPostPurchaseAncillaryPayment(state)

const getClosestProjectedLeadTimeDays = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (cartItems) => getProjectedLeadTimeDaysByCartItems(cartItems),
)

export const isGooglePayAvailable = createSelector(
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => state.system.canPayWithGooglePay,
  (state: App.State) => getClosestProjectedLeadTimeDays(state),
  (currency, canPayWithGooglePay, closestProjectedLeadTimeDays): boolean => {
    return isGooglePayEnabled(currency) &&
      canPayWithGooglePay &&
      // Adding card to Google pay does not always enforce OTP, high chance of fraud
      closestProjectedLeadTimeDays >= SHORT_LEAD_TIME_WITH_GOOGLE_PAY_LEAD_TIME_DAYS
  },
)

export const isApplePayAvailable = createSelector(
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => state.system.canPayWithApplePay,
  (currency, canPayWithApplePay): boolean => isApplePayEnabled(currency) && canPayWithApplePay)

export const isPromoCodeAllowed = createSelector(
  (state: App.State) => getCheckoutTotal(state),
  (state: App.State) => isStandaloneExperiences(state),
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => getVillaItems(state),
  (state: App.State) => getCarHireItems(state),
  (state: App.State) => getTransferItems(state),
  (state: App.State) => state.checkout.payment.partnerships,
  (state: App.State) => isLEStaffOrAdmin(state),
  (state: App.State) => isPostPurchaseAncillaryPayment(state),
  (state: App.State) => state.checkout.cart.mode === 'change-package',
  (state: App.State) => isStandaloneLuxPlusSubscription(state),
  (state: App.State) => getExperienceItems(state),
  (payableTotal,
    isStandaloneExperiences,
    accommodationItems,
    villaItems,
    carHireItems,
    transferItem,
    partnerships,
    isLEStaff,
    isAncillaryPayment,
    isChangePackage,
    isStandaloneLuxPlusSubscription,
    experienceItems,
  ): boolean => {
    const isExperience = experienceItems.length > 0

    if (!config.PROMO_CODES_ENABLED) {
      return false
    }

    // Disables promo code if there is no payment
    if (!payableTotal) return false

    if (partnerships?.velocity?.burn?.isApplied || isAncillaryPayment || isChangePackage) { return false }
    return isExperience || isStandaloneExperiences ||
      isStandaloneLuxPlusSubscription ||
      (accommodationItems.length > 0 && accommodationItems.every(item => isLEAccommodationItem(item) || isBedbankItem(item) || isTourV1Item(item) || isTourV2Item(item) || isCruiseItem(item))) ||
      ((isLEStaff) && carHireItems.length > 0) ||
      (villaItems.length > 0) ||
      (transferItem.length > 0)
  },
)

const cartHasBedbankItem = (state: App.State) => state.checkout.cart.items.some(item => item.itemType === 'bedbankHotel')

export const isStripe3dsV2Available = createSelector(
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => state.auth.account.numberOfPurchases,
  (state: App.State) => cartHasBedbankItem(state),
  (state: App.State) => isRazorpayAvailable(state),
  (state: App.State) => isStripePaymentElementCardAvailable(state),
  (state: App.State) => getCheckoutCurrency(state),
  (isStandaloneFlights, isGiftCardPurchase, numberOfPurchases, hasBedbankItem, razorpayAvailable, stripePaymentElementCardAvailable, currency) => (
    (isStandaloneFlights && isStripe3dsForStandaloneEnabled()) ||
      (isGiftCardPurchase && isStripe3dsForGiftCardsEnabled()) ||
      (numberOfPurchases < 1 && hasBedbankItem && isStripeBedbankRepeatCustomerCheckEnabled()) ||
      isStripe3dsEnabled()
  ) && !razorpayAvailable && !stripePaymentElementCardAvailable && isStripe3dsV2Enabled(currency),
)

const isStripe3dsAvailable = createSelector(
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => state.auth.account.numberOfPurchases,
  (state: App.State) => cartHasBedbankItem(state),
  (state: App.State) => isRazorpayAvailable(state),
  (state: App.State) => isStripePaymentElementCardAvailable(state),
  (state: App.State) => isStripe3dsV2Available(state),
  (isStandaloneFlights, isGiftCardPurchase, numberOfPurchases, hasBedbankItem, razorpayAvailable, stripePaymentElementCardAvailable, stripeV2Available) => (
    (isStandaloneFlights && isStripe3dsForStandaloneEnabled()) ||
      (isGiftCardPurchase && isStripe3dsForGiftCardsEnabled()) ||
      (numberOfPurchases < 1 && hasBedbankItem && isStripeBedbankRepeatCustomerCheckEnabled()) ||
      isStripe3dsEnabled()
  ) && !razorpayAvailable && !stripePaymentElementCardAvailable && !stripeV2Available,
)

const isStripeAvailable = createSelector(
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => isStripe3dsAvailable(state),
  (state: App.State) => isRazorpayAvailable(state),
  (state: App.State) => isStripePaymentElementCardAvailable(state),
  (state: App.State) => isStripe3dsV2Available(state),
  (currency, stripe3dsAvailable, razorpayAvailable, stripePaymentElementCardAvailable, isStripe3dsV2Available) => getPaymentMethods(currency).includes(STRIPE_PAYMENT_TYPE) && !stripe3dsAvailable && !razorpayAvailable && !stripePaymentElementCardAvailable && !isStripe3dsV2Available,
)

// TODO: implement- (copied this function over from checkoutPaymentSelectors, looks like it's been unimplemented for a long time)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isBookDatesOnly = (state: App.State) => false

/**
* @deprecated Will be removed once new PayPal integration is completed
*/
const isBraintreeAvailable = createSelector(
  (state: App.State) => isBraintreeEnabled(state, getCheckoutCurrency(state), state.auth.account.isSpoofed),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (state: App.State) => state.system.rawUserAgentString,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (isBraintreeEnabled, isGiftCard, isLuxPlusSubscriptionInCart, userAgentBrowserName, isPostPurchase) => {
    return isBraintreeEnabled &&
      !isGiftCard &&
      !isLuxPlusSubscriptionInCart &&
      !userAgentBrowserName?.toLowerCase().includes('mobileapp-android') && // Can be removed in early 2024 when paypal sdk upgraded on customer portal

      // We need to temporarily hide PayPal for change dates.
      // Paypal payments are breaking when when combined with credits for the creation of the new reservation.
      // Payments team have a ticket to fix this.  This code can be reverted when completed.
      // https://aussiecommerce.atlassian.net/browse/PP-2212
      // disable paypal flow for postpurchase due to payment issues, revisit when paypal commerce platform is used instead of braintree
      !isPostPurchase
  },
)

const isPayPalAvailable = createSelector(
  (state: App.State) => isPayPalEnabled(state, getCheckoutCurrency(state), state.auth.account.isSpoofed),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => getGrandTotal(state),
  (state: App.State) => getCheckoutTotalsView(state).data.amountPayableByCredit,
  (state: App.State) => getClosestProjectedLeadTimeDays(state),
  (state: App.State) => state.checkout.payment.useCredit,
  (isPayPalEnabled, isGiftCard, isLuxPlusSubscriptionInCart, isPostPurchase, grandTotal, amountPayableByCredit, closestProjectedLeadTimeDays, useCredit) => {
    const creditPercentage = amountPayableByCredit / grandTotal
    const isHighRisk = useCredit && closestProjectedLeadTimeDays < HIGH_RISK_LEAD_TIME_FOR_MAJORITY_CREDIT_IN_DAYS && creditPercentage > HIGH_RISK_TRANSACTION_CREDIT_PERCENTAGE_THRESHOLD
    return isPayPalEnabled && !isGiftCard && !isLuxPlusSubscriptionInCart && !isPostPurchase && !isHighRisk
  },
)

// For user with credits on the checkout page,
// the grand total could be > 35 while the app
// is fetching the user's credit balance (assumed
// to be 0). This causes Klarna Checkout to be
// mounted and immediately unmounted once the user
// data returns and causes the grand total to equal 0
const isKlarnaAvailable = createSelector(
  (state: App.State) => getGrandTotal(state),
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => cartHasBedbankItem(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isStandaloneExperiences(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (
    cartTotal,
    currency,
    hasBedbankItem,
    isStandaloneFlights,
    isStandaloneExperiences,
    isGiftCard,
    isLuxPlusSubscriptionInCart,
  ) => !hasBedbankItem &&
    isKlarnaEnabled(currency) &&
    isWithinKlarnaAvailabilityRange(cartTotal) &&
    !isStandaloneFlights &&
    !isStandaloneExperiences &&
    !isGiftCard &&
    !isLuxPlusSubscriptionInCart,
)

const isKlarnaBpAvailable = createSelector(
  (state: App.State) => getGrandTotal(state),
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => cartHasBedbankItem(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isStandaloneExperiences(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (
    cartTotal,
    currency,
    hasBedbankItem,
    isStandaloneFlights,
    isStandaloneExperiences,
    isGiftCard,
    isLuxPlusSubscriptionInCart,
  ) => !hasBedbankItem &&
    isKlarnaBpEnabled(currency) &&
    isWithinKlarnaBpAvailabilityRange(cartTotal, currency) &&
    !isStandaloneFlights &&
    !isStandaloneExperiences &&
    !isGiftCard &&
    !isLuxPlusSubscriptionInCart,
)

const isAfterPayBpAvailable = createSelector(
  (state: App.State) => getGrandTotal(state),
  (state: App.State) => isBookDatesOnly(state),
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (
    cartTotal,
    bookDatesOnly,
    currency,
    isStandaloneFlights,
    isGiftCard,
    isLuxPlusSubscriptionInCart,
  ) => isAfterPayBpEnabled(currency) &&
    !bookDatesOnly &&
    isWithinAfterPayBpAvailabilityRange(cartTotal, currency) &&
    !isStandaloneFlights &&
    !isGiftCard &&
    !isLuxPlusSubscriptionInCart,
)

const isHoolahBpAvailable = createSelector(
  (state: App.State) => getGrandTotal(state),
  (state: App.State) => isBookDatesOnly(state),
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => cartIncludesTourV2(state), (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (
    cartTotal,
    bookDatesOnly,
    currency,
    includesTourV2,
    isStandaloneFlights,
    isGiftCard,
    isLuxPlusSubscriptionInCart,
  ) =>
    isHoolahBpEnabled(currency) &&
    !includesTourV2 &&
    !bookDatesOnly &&
    !isStandaloneFlights &&
    isWithinHoolahBpAvailabilityRange(cartTotal, currency) &&
    !isGiftCard &&
    !isLuxPlusSubscriptionInCart,
)

const isAtomeBpAvailable = createSelector(
  (state: App.State) => getGrandTotal(state),
  (state: App.State) => isBookDatesOnly(state),
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => cartIncludesTourV2(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (
    cartTotal,
    bookDatesOnly,
    currency,
    includesTourV2,
    isStandaloneFlights,
    isGiftCard,
    isLuxPlusSubscriptionInCart,
  ) =>
    isAtomeBpEnabled(currency) &&
    !includesTourV2 &&
    !bookDatesOnly &&
    !isStandaloneFlights &&
    isWithinAtomeBpAvailabilityRange(cartTotal, currency) &&
    !isGiftCard &&
    !isLuxPlusSubscriptionInCart,
)

const isWaaveBpAvailable = createSelector(
  (state: App.State) => getGrandTotal(state),
  (state: App.State) => isBookDatesOnly(state),
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => cartIncludesTourV2(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (
    cartTotal,
    bookDatesOnly,
    currency,
    isStandaloneFlights,
    includesTourV2,
    isGiftCard,
    isLuxPlusSubscriptionInCart,
  ) =>
    isWaaveBpEnabled(currency) &&
    !includesTourV2 &&
    !bookDatesOnly &&
    !isStandaloneFlights &&
    // !isChangePackage && // Add this here once the new checkout has package change feature available
    isWithinWaaveBpAvailabilityRange(cartTotal, currency) &&
    !!config.WAAVE_BP_GLOBAL_CHECKOUT_ENABLED &&
    !isGiftCard &&
    !isLuxPlusSubscriptionInCart,
)

export const isVelocityAvailable = createSelector(
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => isVelocityEnabled(state),
  (state: App.State) => getVelocityEligibleItems(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (currency, isVelocityEnabled, velocityEligibleItems, isStandaloneFlights, isLuxPlusSubscriptionInCart): boolean =>
    currency === 'AUD' &&
    isVelocityEnabled &&
    !isStandaloneFlights &&
    velocityEligibleItems.length > 0 &&
    !isLuxPlusSubscriptionInCart,
)

const isStripePaymentElementGiropayAvailable = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (regionCode, isLuxPlusSubscriptionInCart):boolean =>
    !!isStripePaymentElementGiropayEnabled(regionCode) &&
    !isLuxPlusSubscriptionInCart,
)

export const isStripePaymentElementIDealAvailable = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (regionCode, currencyCode, isLuxPlusSubscriptionInCart):boolean =>
    currencyCode === 'EUR' &&
    !!isStripePaymentElementIDealEnabled(regionCode) &&
    !isLuxPlusSubscriptionInCart,
)

export const isStripePaymentElementWeChatPayAvailable = createSelector(
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (currencyCode, payableTotal, isLuxPlusSubscriptionInCart):boolean =>
    currencyCode === 'CNY' &&
    !!isStripePaymentElementWeChatPayEnabled(currencyCode) &&
    payableTotal <= 50000 &&
    !isLuxPlusSubscriptionInCart,
)

export const isStripePaymentElementKlarnaAvailable = createSelector(
  (state: App.State) => state.geo.currentCurrency,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (state: App.State) => isCruiseCheckout(state),
  (state: App.State) => getPayableTotal(state),
  (currencyCode, regionCode, isLuxPlusSubscriptionInCart, isCruiseInCart, payableTotal):boolean =>
    !!isStripePaymentElementKlarnaEnabled(currencyCode) &&
    !isLuxPlusSubscriptionInCart &&
    getPaymentMethodLimitsByRegion(regionCode)?.some(region => region.paymentMethod === STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE && payableTotal <= region.limit) &&
    !isCruiseInCart,
)

export const isStripePaymentElementPayNowAvailable = createSelector(
  (state: App.State) => state.geo.currentCurrency,
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (currencyCode, isLuxPlusSubscriptionInCart):boolean =>
    !!isStripePaymentElementPayNowEnabled(currencyCode) &&
    !isLuxPlusSubscriptionInCart,
)

export const isPaymentTypeFlyingBlueAvailable = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => getCheckoutCurrency(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (regionCode, currencyCode, isLuxPlusSubscriptionInCart): boolean =>
    currencyCode === 'EUR' &&
    !!isPaymentTypeFlyingBlueEnabled(regionCode) &&
    !isLuxPlusSubscriptionInCart,
)

export const isPaymentTypeAzupayPayIdAvailable = createSelector(
  (state: App.State) => state.geo.currentCurrency,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (state: App.State) => !!getOptimizelyFeatureFlagVariation(state, OptimizelyFeatureFlags.payIdTargetedRelease),
  (currencyCode, isPostPurchase, isLuxPlusSubscriptionInCart, isAzupayPayIdOptimizelyEnabled): boolean =>
    !!isPaymentTypeAzupayPayIdEnabled(currencyCode) &&
    !isLuxPlusSubscriptionInCart &&
    !isPostPurchase &&
    !isAzupayPayIdOptimizelyEnabled,
)

export const isStripeCustomPaytoAvailable = createSelector(
  (state: App.State) => state.geo.currentCurrency,
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (state: App.State) => getPayableAfterModifiers(state),
  (currencyCode, isLuxPlusSubscriptionInCart, payableAfterModifiers):boolean =>
    currencyCode === 'AUD' &&
    !!(isStripeCustomPaytoEnabled(currencyCode)) &&
    payableAfterModifiers <= MAX_STRIPE_CUSTOM_PAYTO_PAYMENT &&
    !isLuxPlusSubscriptionInCart,
)

const isGoCardlessAvailable = () => false // cleanup required

export const isSecurePaymentAvailable = createSelector(
  (state: App.State) => state.geo.currentCurrency,
  (state: App.State) => state.auth.account.isSpoofed,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.checkout.cart.items,
  (currencyCode, isSpoofed, postPurchase, items): boolean =>
    isSpoofed &&
    !postPurchase &&
    isSecurePaymentEnabled(currencyCode) &&
    !items.some(isTourV1Item),
)

export const getPaymentMethodAvailability = createSelector(
  (state: App.State) => isFullPaymentSelected(state),
  (state: App.State) => state.checkout.promo,
  (state: App.State) => isStripeAvailable(state),
  (state: App.State) => isBraintreeAvailable(state),
  (state: App.State) => isPayPalAvailable(state),
  (state: App.State) => isRazorpayAvailable(state),
  (state: App.State) => isKlarnaAvailable(state),
  (state: App.State) => isKlarnaBpAvailable(state),
  (state: App.State) => isAfterPayBpAvailable(state),
  (state: App.State) => isHoolahBpAvailable(state),
  (state: App.State) => isStripe3dsAvailable(state),
  (state: App.State) => isAtomeBpAvailable(state),
  (state: App.State) => isWaaveBpAvailable(state),
  (state: App.State) => isVelocityAvailable(state),
  (state: App.State) => isStripePaymentElementCardAvailable(state),
  (state: App.State) => isStripePaymentElementGiropayAvailable(state),
  (state: App.State) => isStripePaymentElementIDealAvailable(state),
  (state: App.State) => isStripePaymentElementKlarnaAvailable(state),
  (state: App.State) => isStripePaymentElementWeChatPayAvailable(state),
  (state: App.State) => isStripePaymentElementPayNowAvailable(state),
  (state: App.State) => isStripeCustomPaytoAvailable(state),
  (_: App.State) => isGoCardlessAvailable(),
  (state: App.State) => state.checkout.payment.partnerships.velocity?.burn?.isApplied,
  (state: App.State) => isReserveForFreeSelected(state),
  (state: App.State) => isStripe3dsV2Available(state),
  (state: App.State) => isSecurePaymentAvailable(state),
  (state: App.State) => isPaymentTypeFlyingBlueAvailable(state),
  (state: App.State) => isPaymentTypeAzupayPayIdAvailable(state),
  (state: App.State) => isStripePaymentElementBusinessCardAvailable(state),
  (state: App.State) => isPaymentScheduleSelected(state),
  (
    isFullPaymentSelected,
    promotion,
    stripeAvailable,
    braintreeAvailable,
    paypalAvailable,
    razorpayAvailable,
    klarnaAvailable,
    klarnaBpAvailable,
    afterPayBpAvailable,
    hoolahBpAvailable,
    stripe3dsAvailable,
    atomeBpAvailable,
    waaveBpAvailable,
    isVelocityAvailable,
    stripePaymentElementCardAvailable,
    stripePaymentElementGiropayAvailable,
    stripePaymentElementIDealAvailable,
    stripePaymentElementKlarnaAvailable,
    stripePaymentElementWeChatPayAvailable,
    stripePaymentElementPayNowAvailable,
    stripeCustomPaytoAvailable,
    goCardlessAvailable,
    isVelocityApplied,
    isReserveForFreeSelected,
    stripe3dsV2Available,
    isSecurePaymentAvailable,
    flyingBlueAvailable,
    azupayPayIdAvailable,
    isStripePaymentElementBusinessCardAvailable,
    isPaymentScheduleSelected,
  ): Record<PaymentMethod, boolean> => {
    const paymentMethodAvailabilities: Record<string, boolean> = {
      stripe: stripeAvailable && isFullPaymentSelected,
      stripe_3ds: stripe3dsAvailable,
      stripe_3ds_v2: stripe3dsV2Available,
      stripe_payment_element_card: stripePaymentElementCardAvailable,
      stripe_payment_element_giropay: stripePaymentElementGiropayAvailable && isFullPaymentSelected,
      stripe_payment_element_ideal: stripePaymentElementIDealAvailable && isFullPaymentSelected,
      stripe_payment_element_klarna: stripePaymentElementKlarnaAvailable && isFullPaymentSelected,
      stripe_payment_element_wechatpay: stripePaymentElementWeChatPayAvailable && isFullPaymentSelected,
      stripe_payment_element_paynow: stripePaymentElementPayNowAvailable && isFullPaymentSelected,
      braintree: braintreeAvailable && isFullPaymentSelected,
      paypal: paypalAvailable && isFullPaymentSelected,
      razorpay: razorpayAvailable && isFullPaymentSelected,
      klarna: klarnaAvailable && isFullPaymentSelected,
      klarna_bp: klarnaBpAvailable && isFullPaymentSelected,
      afterpay_bp: afterPayBpAvailable && isFullPaymentSelected,
      hoolah_bp: hoolahBpAvailable && isFullPaymentSelected,
      velocity: isVelocityAvailable && isFullPaymentSelected,
      atome_bp: atomeBpAvailable && isFullPaymentSelected,
      waave_bp: waaveBpAvailable && isFullPaymentSelected,
      stripe_custom_payto: stripeCustomPaytoAvailable && isFullPaymentSelected,
      gocardless: goCardlessAvailable && isFullPaymentSelected,
      secure_payment: isSecurePaymentAvailable && !isReserveForFreeSelected && !isPaymentScheduleSelected,
      flying_blue: flyingBlueAvailable && isFullPaymentSelected,
      azupay_pay_id: azupayPayIdAvailable && isFullPaymentSelected,
      stripe_payment_element_business_card: isStripePaymentElementBusinessCardAvailable,
    }

    if (isVelocityApplied) {
      for (const key of Object.keys(paymentMethodAvailabilities)) {
        if (VIRGIN_VELOCITY_CHECKOUT_PAYMENT_METHODS.includes(key)) {
          continue
        }
        paymentMethodAvailabilities[key] = false
      }
    }

    const applyPromotionModifiers = promotion && promotion.allowedPaymentMethods.length > 0
    const applyPartnershipModifiers = isVelocityApplied

    // @ts-ignore
    return mapObject(paymentMethodAvailabilities, (value, payMethod: string) => {
      let result = value

      if (applyPromotionModifiers) {
        result = result && promotion.allowedPaymentMethods.includes(payMethod)
      }

      if (applyPartnershipModifiers) {
        result = result && VIRGIN_VELOCITY_CHECKOUT_PAYMENT_METHODS.includes(payMethod)
      }

      return result
    })
  },
)

export const isCreditPayable = createSelector(
  (state: App.State) => getCreditPayableAmount(state),
  (state: App.State) => isStandaloneFlights(state),
  (state: App.State) => isGiftCardPurchase(state),
  (state: App.State) => isPaymentScheduleBalance(state),
  (credit, isStandaloneFlights, isGiftCard, isPaymentScheduleBalance): boolean => {
    return credit > 0 && !isStandaloneFlights && !isGiftCard && !isPaymentScheduleBalance
  },
)

const toISO601Format = (date: string) => moment.utc(date).format('YYYY-MM-DDT hh:mm:ss.SSSZ')
export const getPaypalSupplementaryData = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.bedbankOffers,
  (state: App.State) => state.offer.tourV2Offers,
  (cartItems, offers, bedbankOffers, tourV2Offers): PaypalSupplementaryData => {
    const leHotelItem = cartItems.find(isLEHotelItem)
    const bedbankItem = cartItems.find(isBedbankItem)
    const tourV1Item = cartItems.find(isTourV1Item)
    const tourV2Item = cartItems.find(isTourV2Item)

    if (tourV1Item) {
      const pkg = offers[tourV1Item.offerId]?.packages.find(p => p.id === tourV1Item.packageId)
      return {
        ota_service_start_date: toISO601Format(tourV1Item.startDate!),
        ota_service_end_date: toISO601Format(tourV1Item.endDate!),
        ota_start_country: pkg?.tour?.geoData.countryCode ?? undefined,
      }
    }
    else if (tourV2Item) {
      const offer = tourV2Offers[tourV2Item.offerId]
      const variation = offer?.variations[tourV2Item.purchasableOption.fkVariationId]
      return {
        ota_service_start_date: toISO601Format(tourV2Item.startDate),
        ota_service_end_date: toISO601Format(tourV2Item.endDate),
        ota_start_country: variation?.countriesVisited?.[0],
      }
    }
    else if (leHotelItem) {
      const offer = offers[leHotelItem.offerId]
      const supplementaryData = {
        ota_start_country: offer?.property?.geoData.countryCode,
      } as PaypalSupplementaryData
      if (leHotelItem.reservationType === 'instant_booking') {
        supplementaryData.ota_service_start_date = toISO601Format(leHotelItem.checkIn)
        supplementaryData.ota_service_end_date = toISO601Format(leHotelItem.checkOut)
      }
      return supplementaryData
    }
    else if (bedbankItem) {
      const offer = bedbankOffers[bedbankItem.offerId]
      return {
        ota_service_start_date: toISO601Format(bedbankItem.checkIn),
        ota_service_end_date: toISO601Format(bedbankItem.checkOut),
        ota_start_country: offer?.property.address.countryCode,
      }
    }
    return {}
  },
)

export const isAnyInstalmentPayAvailable = createSelector(
  (state: App.State) => isKlarnaAvailable(state),
  (state: App.State) => isAfterPayBpAvailable(state),
  (state: App.State) => isKlarnaBpAvailable(state),
  (state: App.State) => isHoolahBpAvailable(state),
  (state: App.State) => isAtomeBpAvailable(state),
  (klarna, afterPayBp, klarna_bp, hoolah_bp, atome_bp) => klarna || afterPayBp || klarna_bp || hoolah_bp || atome_bp,
)
