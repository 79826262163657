import request from 'api/requestUtils'
import { templates } from '@luxuryescapes/lib-uri-templates'
import createBatchedPromise from './createBatchedPromise'
import { paths } from '@luxuryescapes/contract-svc-reservation/index'
import { arrayToObject } from 'lib/array/arrayUtils'

type SurchargeMarginBatchCalculateServerResponse = paths['/api/rate-plans/surcharge-dates/margin']['post']['responses'][200]['content']['application/json']
type SurchargeMarginBatchCalculateServerRequestPayload = paths['/api/rate-plans/surcharge-dates/margin']['post']['parameters']['body']['payload']

export const batchFetchSurchargeMargin = createBatchedPromise({
  argsReducerFn: (
    accumulatedArgs: Record<string, { startDate: string, endDate: string }>,
    singleArg: [roomRateId: string, surchargeDateBlockInfo: { startDate: string, endDate: string }],
  ) => {
    const [ratePlanId, surchargeDateBlockInfo] = singleArg
    return {
      ...accumulatedArgs,
      [ratePlanId]: { ...surchargeDateBlockInfo },
    }
  },
  batchRequestFn: (batchedArgs) => {
    return requestPostBatchSurchargeMargin(batchedArgs)
  },
  singleValueFn: (singleArg, batchedResponse) => {
    return batchedResponse
  },
})

async function requestPostBatchSurchargeMargin(
  payload: Record<string, { startDate: string, endDate: string }>,
) {
  try {
    const response = await request.post<SurchargeMarginBatchCalculateServerResponse, SurchargeMarginBatchCalculateServerRequestPayload>(
      '/api/rate-plans/surcharge-dates/margin',
      payload,
      { credentials: 'include' },
    )

    const result = mapSurchargeMarginResponse(response)

    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

function mapSurchargeMarginResponse(marginsResponse: SurchargeMarginBatchCalculateServerResponse): { [key: string]: { marginAud: number } } {
  if (!Array.isArray(marginsResponse)) {
    // return it as is because its the old format
    return marginsResponse.result
  } else {
    const marginsArray = marginsResponse as unknown as Array<{
        roomRateId: string;
        checkIn: string;
        checkOut: string;
        marginAud: number;
    }>

    const result = arrayToObject(
      marginsArray,
      // Don't use getObjectKey because we want the keys to match between
      // the response and the request payloads, but they have slightly
      // different data. These fields are common to both.
      (item) => `${item.roomRateId}_${item.checkIn}_${item.checkOut}`,
      (item) => ({ marginAud: item.marginAud }),
    )

    return result
  }
}

function tourDateMap(date): App.TourDate {
  return {
    startDate: date.start_date,
    endDate: date.end_date,
    monthGroup: date.month_group,
    totalAvailable: date.total_available,
    guaranteedDeparture: date.guaranteedDeparture,
  }
}

export function tourEnquiry(
  tourId: string,
  tourOptionId: string,
  numberOfDays: number,
  noOfAdults: number,
) {
  return request.get<App.ApiResponse<Array<any>>>(templates.reservation.tour_enquiry.expand({
    tour_id: tourId,
    id: tourOptionId,
    days: numberOfDays,
    timezone_offset: timezoneOffset(),
    no_of_adults: noOfAdults,
  }))
    .then(data => data.result.map(tourDateMap))
}

function timezoneOffset() {
  return new Date().getTimezoneOffset() / 60 * -1
}
