import {
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  API_CALL_SUCCESS,
  DISMISS_NOTIFICATION_BANNER,
} from 'actions/actionConstants'
import {
  FETCH_LANDING_PAGE,
  FETCH_MAGAZINE_ARTICLES,
  FETCH_NOTIFICATION_BANNER,
  FETCH_BANNERS,
  FETCH_FAQ,
  FETCH_LIST_BANNERS,
  FETCH_DREAM_SITE_BLOG_POSTS,
  FETCH_LINK_BOX,
  FETCH_SITE_BANNER,
  CLEAR_SITE_BANNER,
} from 'actions/apiActionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'

export const initialContentState: App.ContentState = {
  dreamSiteBlogPosts: {},
  notifications: {},
  siteBanner: {
    data: undefined,
  },
  banners: {},
  listBanners: {},
  searchFooterLinkBox: {},
  landingPages: {},
  landingPageErrors: {},
  magazineArticles: {
    posts: [],
    error: undefined,
    fetching: false,
  },
  faqs: {
    legal: {},
    destinationProduct: {},
  },
}

const apiRequests = reducerSwitch<App.ContentState>({
  [FETCH_DREAM_SITE_BLOG_POSTS]: (state, action) => ({
    dreamSiteBlogPosts: {
      ...state.dreamSiteBlogPosts,
      [action.key]: [],
    },
  }),
  [FETCH_LANDING_PAGE]: (state, action) => ({
    landingPageErrors: {
      ...state.landingPageErrors,
      [action.key]: undefined,
    },
  }),
  [FETCH_MAGAZINE_ARTICLES]: (state) => ({
    magazineArticles: {
      ...state.magazineArticles,
      fetching: true,
    },
  }),
  [FETCH_FAQ]: (state, action) => ({
    faqs: {
      ...state.faqs,
      [action.faqType]: {
        ...state.faqs[action.faqType],
        [action.slug]: {
          fetching: true,
        },
      },
    },
  }),
  [FETCH_SITE_BANNER]: (state) => ({
    siteBanner: {
      fetching: true,
      data: state.siteBanner.data,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.ContentState>({
  [FETCH_NOTIFICATION_BANNER]: (state, action) => ({
    notifications: {
      ...state.notifications,
      [action.slug]: action.data,
    },
  }),
  [FETCH_BANNERS]: (state, action) => ({
    banners: {
      ...state.banners,
      [action.location]: action.data,
    },
  }),
  [FETCH_LIST_BANNERS]: (state, action) => ({
    listBanners: {
      ...state.listBanners,
      [action.location]: action.data,
    },
  }),
  [FETCH_LANDING_PAGE]: (state, action) => ({
    landingPages: {
      ...state.landingPages,
      [action.key]: action.data,
    },
  }),
  [FETCH_LINK_BOX]: (state, action) => {
    return ({
      searchFooterLinkBox: {
        ...state.searchFooterLinkBox,
        [action.key]: action.data,
      },
    }) },
  [FETCH_MAGAZINE_ARTICLES]: (state, action) => ({
    magazineArticles: {
      ...state.magazineArticles,
      posts: action.data,
      fetching: false,
    },
  }),
  [FETCH_FAQ]: (state, action) => ({
    faqs: {
      ...state.faqs,
      [action.faqType]: {
        ...state.faqs[action.faqType],
        [action.slug]: {
          content: action.data,
          fetching: false,
        },
      },
    },
  }),
  [FETCH_DREAM_SITE_BLOG_POSTS]: (state, action) => ({
    dreamSiteBlogPosts: {
      ...state.dreamSiteBlogPosts,
      [action.key]: action.data,
    },
  }),
  [FETCH_SITE_BANNER]: (state, action) => ({
    siteBanner: {
      fetching: false,
      data: action.data,
    },
  }),
})

const apiFailures = reducerSwitch<App.ContentState>({
  [FETCH_LANDING_PAGE]: (state, action) => ({
    landingPageErrors: {
      ...state.landingPageErrors,
      [action.key]: action.error,
    },
  }),
  [FETCH_MAGAZINE_ARTICLES]: (state, action) => ({
    magazineArticles: {
      ...state.magazineArticles,
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_SITE_BANNER]: (state, action) => ({
    siteBanner: {
      fetching: false,
      data: state.siteBanner.data,
      error: action.error,
    },
  }),
})

export default createReducer<App.ContentState>(initialContentState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [DISMISS_NOTIFICATION_BANNER]: (state, action) => ({
    notifications: {
      ...state.notifications,
      [action.slug]: {
        ...state.notifications[action.slug],
        show: false,
      },
    },
  }),
  [CLEAR_SITE_BANNER]: () => ({
    siteBanner: {
      data: undefined,
    },
  }),
})
