import React, { useCallback, useContext } from 'react'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import styled from 'styled-components'
import { rem } from 'polished'
import HeadingTextBlock from 'components/Luxkit/TextBlocks/HeadingTextBlock'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import {
  BRAND_SIGNATURE_SERIES,
  TOUR_ASIA_PROMO_REGIONS,
  TOUR_ASIA_TAKEOVER_SEARCH_PLACE_IDS,
  TOUR_V2_HERO_IMAGE_ASIA_MOBILE_BACKGROUND,
} from 'constants/tours'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Pill from 'components/Luxkit/Pill'
import TextLink from 'components/Luxkit/TextLink'
import Group from 'components/utils/Group'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import { pushWithRegion } from 'actions/NavigationActions'
import { scrollToId } from 'lib/window/scrollUtils'
import Places from 'constants/places'
import { LUXURY_ESCAPES } from 'constants/brands'
import { mediaQueryUp } from 'components/utils/breakpoint'
import HeroImage from 'content/components/HeroImage'
import FormatCurrency from 'components/Common/FormatCurrency/FormatCurrency'
import GeoContext from 'contexts/geoContext'

const BackgroundImage = styled(HeroImage)`
  min-height: ${rem(165)};

  ${mediaQueryUp.tablet} {
    min-height: ${rem(200)};
  }
`

const TourAsiaImageHeader = styled(Group)`
  position: relative;
  z-index: 1;
`

interface Props {
  modalVariant?: 'offer' | 'generic',
  onOfferClick?: ()=> void
}

function TourAsiaCampaignModal(props : Props) {
  const { currentRegionCode, currentCurrency } = useContext(GeoContext)
  const modalVariant = props.modalVariant ?? 'generic'
  const modalContext = useModalElementContext()
  const appDispatch = useAppDispatch()
  const handleClick = useCallback(() => {
    if (modalVariant === 'generic') {
      const urlSearchParams = new URLSearchParams({
        destinationId: Places.Asia.id,
        destinationName: Places.Asia.name,
        destinations: TOUR_ASIA_TAKEOVER_SEARCH_PLACE_IDS.toString(),
        operators: [BRAND_SIGNATURE_SERIES, LUXURY_ESCAPES].toString(),
      })
      appDispatch(pushWithRegion(`/search/tours?${urlSearchParams.toString()}`))
    } else if (modalVariant === 'offer') {
      scrollToId('TOUR_DEPARTURE_DATES')
    }

    modalContext.resolve()
  }, [modalVariant, modalContext, appDispatch])
  return <ModalBase>
    <ModalHeader title="" isOverlay >
    </ModalHeader>
    <ModalBody>
      <BackgroundImage
        id={TOUR_V2_HERO_IMAGE_ASIA_MOBILE_BACKGROUND}
        fit="center"
        gravity="auto"
      >
        <TourAsiaImageHeader fullWidth direction="vertical" horizontalAlign="start" verticalAlign="center" gap={4}>
          <HeadingTextBlock colour="neutral-eight" variant="heading1" align="start">The best of</HeadingTextBlock>
          <HeadingTextBlock colour="neutral-eight" variant="heading1" align="start"><i>Asia</i> on Tour</HeadingTextBlock>
          <HeadingTextBlock colour="neutral-eight" variant="heading6" align="start">Use code <Pill kind="primary" variant="success">TOURASIA</Pill> at checkout</HeadingTextBlock>
        </TourAsiaImageHeader>
      </BackgroundImage>
      <ModalContent>
        <VerticalSpacer gap={12}>
          <HeadingTextBlock variant="heading4">Save up to <FormatCurrency format="dynamic" currency={currentCurrency} value={TOUR_ASIA_PROMO_REGIONS[currentRegionCode]} /> off tours across Asia</HeadingTextBlock>
          <BodyTextBlock variant="medium" >Save 5% off tours across Asia. Minimum spend applies. Offer applies to Luxury Escapes Tours only. Code can only be used once per customer. Offer ends at 11:59pm (AEST) on 28th April 2025. <TextLink weight="regular" to="/promotions">Terms & Conditions apply.</TextLink></BodyTextBlock>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
    <ModalFooter primaryActionProps={{
      children: (modalVariant === 'offer') ? 'View availabilities' : 'View all offers',
      onClick: handleClick,
    }} actionsLayout="auto"
    >
    </ModalFooter>
  </ModalBase>
}

export default TourAsiaCampaignModal
