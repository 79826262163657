import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import { searchSteps } from 'components/SearchV2/Components/Mobile/TabContents/HotelsTabContent'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import ModalContext from 'contexts/ModalContext'
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import BusinessTravellerSelectHotelsModal from './BusinessTravellerSelectHotelsModal'
import { pluralizeToString } from 'lib/string/pluralize'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { sum } from 'lib/array/arrayUtils'

interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
}

interface Props {
  activeStep?: searchSteps
  onBusinessTravellerSelectClick: () => void
  gotoNextStep: () => void
}

function BusinessTravellerSelectHotelsMobile(props: Props & MappedStateProps) {
  const {
    activeStep,
    travellerEmployees,
    onBusinessTravellerSelectClick,
    gotoNextStep,
  } = props

  const showModal = useContext(ModalContext)

  const { occupancies } = useContext(GlobalSearchStateContext)

  const showBusinessTravellerSelectModal = useCallback(async() => {
    await showModal(<BusinessTravellerSelectHotelsModal />)
  }, [showModal])

  const handleSelectTravellerClick = useCallback(() => {
    if (activeStep !== searchSteps.GUESTS) {
      onBusinessTravellerSelectClick()
    } else {
      gotoNextStep()
    }
  }, [activeStep, gotoNextStep, onBusinessTravellerSelectClick])

  const fieldValue = useMemo(() => {
    if (travellerEmployees.length === 0) {
      return undefined
    }
    if (occupancies[0].adults === 1 && travellerEmployees.length === 1) {
      return `${travellerEmployees[0].firstName} ${travellerEmployees[0].lastName}`
    }
    const adultsCount = pluralizeToString('adult', sum(occupancies, room => room.adults))
    const roomCount = pluralizeToString('room', occupancies.length)
    return `${adultsCount} (${roomCount})`
  }, [occupancies, travellerEmployees])

  const localInputRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (activeStep === searchSteps.GUESTS) {
      showBusinessTravellerSelectModal()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  return (
    <SearchFormField
      label="Travellers"
      required
      requiredErrorMessage="Please select traveller"
      value={fieldValue}
      displayValue={fieldValue}
      placeholder="Select Traveller"
      onClick={handleSelectTravellerClick}
      ref={localInputRef}
    />
  )
}

export default connect<MappedStateProps, {}, Props, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
  }
})(BusinessTravellerSelectHotelsMobile)
