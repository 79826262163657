import { getOrderItemInsuranceLetterPDF } from 'api/pdf'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { downloadPdf } from 'lib/web/downloadPdf'
import moment from 'moment'

export function getOrderInsuranceLetterFilename(order: App.Order) {
  return `Insurance-Letter-${moment(order.createdAt).format(ISO_DATE_FORMAT)}-${order.bookingNumber}.pdf`
}

export async function downloadCancellationInsuranceLetter(
  order: App.Order,
  item: App.OrderItem | App.OrderBedbankItem,
  offer?: App.HotelOffer,
) {
  try {
    const blob = await getOrderItemInsuranceLetterPDF(order, item, offer)
    const fileName = getOrderInsuranceLetterFilename(order)
    downloadPdf(blob, fileName)
    showSnackbar(
      'Insurance letter has been downloaded successfully.',
      'success',
      { heading: 'Download completed' },
    )
  } catch {
    showSnackbar(
      'Your insurance letter could not be downloaded. Please contact us for support.',
      'critical',
      { heading: 'Error' },
    )
  }
}

export function hasApprovedBNBLRefundRequest(order: App.Order, item: App.OrderItem | App.OrderBedbankItem) {
  const statuses = ['approved_bnbl', 'customer_put_on_hold']
  return statuses.includes(order.refundRequests[item.id]?.status) || statuses.includes(order.refundRequests[item.downgradedFromId ?? '']?.status)
}
