import { excludeNullOrUndefined } from 'checkout/utils'
import { createSelector } from 'reselect'
import { getAccommodationBreakdownView } from './accommodation'
import { getBookingProtectionBreakdownView } from './bookingProtection'
import { getBundleAndSaveBreakdownView } from './bundleAndSave'
import { getCarHireBreakdownView } from './carHire'
import { getCustomOfferBreakdownView } from './customOffer'
import { getFlightBreakdownView } from './flights'
import { getGiftCardBreakdownView } from './giftCard'
import getInsuranceBreakdownView from './getInsuranceBreakdownView'
import { getLuxPlusBreakdownView } from './luxPlusSubscription'
import { getTourExperienceV2BreakdownView } from './toursv2'
import { getVillaBreakdownView } from './villa'
import getLuxLoyaltyLoungePassBreakdownView from './getLuxLoyaltyLoungePassBreakdownView'
import getExperienceBreakdownView from './getExperienceBreakdownView'
import getTransfersBreakdownView from './getTransfersBreakdownView'

const getAllBreakdownViews = createSelector(
  (state: App.State) => getAccommodationBreakdownView(state),
  (state: App.State) => getTourExperienceV2BreakdownView(state),
  (state: App.State) => getVillaBreakdownView(state),
  (state: App.State) => getFlightBreakdownView(state),
  (state: App.State) => getExperienceBreakdownView(state),
  (state: App.State) => getTransfersBreakdownView(state),
  (state: App.State) => getInsuranceBreakdownView(state),
  (state: App.State) => getCarHireBreakdownView(state),
  (state: App.State) => getBundleAndSaveBreakdownView(state),
  (state: App.State) => getGiftCardBreakdownView(state),
  (state: App.State) => getCustomOfferBreakdownView(state),
  (state: App.State) => getLuxPlusBreakdownView(state),
  (state: App.State) => getBookingProtectionBreakdownView(state),
  (state: App.State) => getLuxLoyaltyLoungePassBreakdownView(state),
  ({ hasRequiredData, data: { tours, accommodations } }, tourExperiencesBreakdown, ...views): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    // Reorder views to ensure that the order of the breakdowns is consistent
    const tourBreakdown: App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> = {
      hasRequiredData,
      data: tours,
    }
    const accommodationBreakdown: App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> = {
      hasRequiredData,
      data: accommodations,
    }
    const sortedViews = [tourBreakdown, tourExperiencesBreakdown, accommodationBreakdown, ...views]
    const allViews = sortedViews.flatMap(view => view.data)
      .filter(excludeNullOrUndefined)

    return {
      hasRequiredData: sortedViews.every(o => o.hasRequiredData),
      data: allViews,
    }
  },
)

export default getAllBreakdownViews
