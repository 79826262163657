import { createSelector } from 'reselect'
import getCheckoutPointsCalculationRequests from 'luxLoyalty/selectors/checkout/pointsCalculation/getCheckoutPointsCalculationRequests'
import buildPointsKey from 'luxLoyalty/lib/pointsCalculation/buildPointsKey'
import { EmptyArray } from 'lib/array/arrayUtils'
import getAllItemsSummaryTitles from 'checkout/selectors/view/getAllItemsSummaryTitles'
import getAllItemViews from 'checkout/selectors/view/getAllItemViews'
import getCheckoutLuxLoyaltyPointsEarnTotals from 'luxLoyalty/selectors/checkout/pointsCalculation/getCheckoutLuxLoyaltyPointsEarnTotals'
import getShouldEarnLuxLoyaltyPoints from 'luxLoyalty/selectors/checkout/getShouldEarnLuxLoyaltyPoints'

const getCheckoutLuxLoyaltyPointsEarnCalculationView = createSelector(
  (state: App.State) => state.luxLoyalty.pointsEarnCalculations,
  (state: App.State) => state.auth.account.memberId,
  (state: App.State) => getCheckoutPointsCalculationRequests(state),
  (state: App.State) => getAllItemsSummaryTitles(state),
  (state: App.State) => getAllItemViews(state),
  (state: App.State) => getCheckoutLuxLoyaltyPointsEarnTotals(state),
  (state: App.State) => getShouldEarnLuxLoyaltyPoints(state),
  (
    pointsEarnCalculations,
    memberId,
    calculationRequests,
    allItemsSummaryTitles,
    allItemViews,
    itemsEarnTotals,
    shouldEarnLuxLoyaltyPoints,
  ): App.WithDataStatus<App.Checkout.LuxLoyaltyPointsEarnCalculationView | undefined> => {
    const earnableItems: Array<App.Checkout.LuxLoyaltyPointsEarnItem> = []

    calculationRequests.forEach((request) => {
      const pointsKey = buildPointsKey(request, memberId ?? '')
      const pointsEarnCalculation = pointsEarnCalculations[pointsKey]
      const itemView = allItemViews.data.get(request.itemId ?? '')?.data
      const luxLoyaltyProductType = itemView?.luxLoyaltyProductType

      if (pointsEarnCalculation?.data && itemView && luxLoyaltyProductType) {
        const totals = itemsEarnTotals.get(itemView.item.itemId)

        if (!totals) return

        earnableItems.push({
          itemId: itemView.item.itemId,
          itemTransactionKey: itemView.item.transactionKey,
          itemType: itemView.item.itemType,
          luxLoyaltyProductType,
          titles: allItemsSummaryTitles.get(itemView.item.itemId) ?? EmptyArray,
          totals,
          pointsEarnCalculation,
        })
      }
    })

    const allCalculationsFetched = earnableItems.every(item => !item.pointsEarnCalculation.fetching)

    if (!allCalculationsFetched) {
      return {
        hasRequiredData: false,
        data: undefined,
      }
    }

    return {
      hasRequiredData: true,
      data: {
        shouldEarnLuxLoyaltyPoints,
        earnableItems,
      },
    }
  },
)

export default getCheckoutLuxLoyaltyPointsEarnCalculationView
