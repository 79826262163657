import { partitionBy } from 'lib/array/arrayUtils'
import { dateIsAfter } from 'lib/datetime/dateUtils'
import { PAYMENT_METHODS_NOT_ALLOWED_REFUND } from 'constants/payment'
import { orderHasInsuranceBookingProtection } from './orderInsuranceUtils'

function isBedbankDateChangeAllowed(order: App.Order, item: App.OrderBedbankItem) {
  const [cancelledRooms, activeRooms] = partitionBy(item.rooms, room => room.status === 'cancelled')
  const canChangeDates = activeRooms.every(room => room.refundable) && !order.hasFlight && !orderHasInsuranceBookingProtection(order)
  const firstRoom = activeRooms[0] ?? cancelledRooms[0]

  const cancellationPolicyStart = firstRoom.cancellationPolicies?.policies?.[0]?.start
  // if there is no cancellation policy set, assume expired
  const isExpired = cancellationPolicyStart ? !dateIsAfter(cancellationPolicyStart, new Date()) : true

  const refund = order.refunds.find(item => item.itemId === firstRoom?.roomId)

  const isAllowAutoRefund = !order.payments.some((payment) =>
    PAYMENT_METHODS_NOT_ALLOWED_REFUND.includes(payment.type),
  )

  return !!activeRooms.length && !refund && !isExpired && isAllowAutoRefund && canChangeDates && !order.locked
}

export default isBedbankDateChangeAllowed
