import { ExperienceOrderCreationItem } from 'api/order'
import getLuxLoyaltyLoungePassItemViews from 'checkout/selectors/view/getLuxLoyaltyLoungePassItemViews'
import { ITEM_TYPE_BNBL_EXPERIENCE, ITEM_TYPE_EXPERIENCE } from 'constants/cart'
import config from 'constants/config'
import { ExperienceBookingType } from 'constants/experience'
import { createSelector } from 'reselect'
import { getFormattedTravellers } from '../orderCreationSelectors'
import getExperienceItemSourceApp from '../getExperienceItemSourceApp'
import { getExperienceTicketDate } from 'lib/experiences/experienceUtils'

const getFormattedLoyaltyLoungePassItems = createSelector(
  (state: App.State) => getLuxLoyaltyLoungePassItemViews(state),
  (state: App.State) => getFormattedTravellers(state),
  (state: App.State) => getExperienceItemSourceApp(state),
  (views, travellers, sourceApp): Array<ExperienceOrderCreationItem> => {
    return views.data.map(view => {
      return {
        type: view.item.isBookLater ? ITEM_TYPE_BNBL_EXPERIENCE : ITEM_TYPE_EXPERIENCE,
        provider_offer_id: view.item.experienceId,
        brand: config.BRAND,
        transaction_key: view.item.transactionKey,
        total: view.ticket.price,
        categories: view.experience.categories,
        cancellation_policies: view.experience.cancellationPolicies.length > 0 ? {
          isFree: view.experience.copy.cancellationInfo.isFree,
          timezoneOffset: `${new Date().getTimezoneOffset()}`,
          refundPolicies: view.experience.cancellationPolicies.map(policy => ({
            id: policy.id,
            periods: policy.periods,
            periodLabel: policy.periodLabel,
            type: policy.type,
            value: policy.value,
          })),
          text: view.experience.copy.cancellationInfo.text,
        } : undefined,
        le_exclusive: view.experience.leExclusive,
        redemption_location_id: view.experience.redemptionLocations[0]?.id,
        redemption_location_name: view.experience.redemptionLocations[0]?.name,
        traveller_info: {
          customer: travellers?.customer,
        },
        ticket: {
          fareType: view.ticket.name,
          identifier: view.ticket.id,
          productId: view.ticket.productId,
          type: view.ticket.type,
          date: getExperienceTicketDate(view.item.date, view.item.time),
          rateStartDate: view.ticket.rateStartDate,
          rateEndDate: view.ticket.rateEndDate,
          bookByDate: view.ticket.bookByDate ?? view.experience.bookByDate,
          isExtra: view.ticket.isExtra,
        },
        title: view.experience.name,
        booking_type: view.item.isBookLater ? ExperienceBookingType.BUY_NOW_BOOK_LATER : ExperienceBookingType.INSTANT_BOOKING,
        item_discounts: [],
        source_app: sourceApp,
        ticketed: view.experience.ticketed,
        taxes_and_fees: view.ticket.taxesAndFees,
      }
    })
  },
)

export default getFormattedLoyaltyLoungePassItems
