import { createSelector } from 'reselect'
import { isLoyaltyTransferItem } from 'lib/checkout/checkoutUtils'

/**
 * Retrieves all loyalty airport transfer items in the checkout
 */
const getLuxLoyaltyTransferItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items) => {
    return items.filter(isLoyaltyTransferItem)
  },
)

export default getLuxLoyaltyTransferItems
