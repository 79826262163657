import { RATINGS } from 'constants/analytics'
import offerTypeConfig from 'api/config/offer'
import { buildProductCategory } from 'analytics/eventDefinitions'
import { OfferPackageOptionView } from 'selectors/offerPage/offerPageSelectors'
import { RESERVATION_TYPE_INSTANT_BOOKING } from 'constants/reservation'
import { BundleOfferPackageOptionView } from 'selectors/offerPage/bundledOfferPageSelectors'

interface UATransactionProduct {
  brand?: string
  category: string
  id: string
  name: string
  price: `${number}`
  productRating: number
  quantity: number
  variant: string
}

interface UATransactionFlight extends UATransactionProduct {
  flightDestination?: string
  flightOrigin?: string
  provider: string
  validatingCarrierName?: string
}

interface UATransactionBaseAccommodationProduct extends UATransactionProduct {
  bookingNumber?: string
  category: string
  subCategory: string
  packageName: string
}

interface UATransactionLEAccommodationProduct extends UATransactionBaseAccommodationProduct {
  category: string
  location: string
  // basePrice: number  // This name will be added by this ticket CRO-1134
  packageId: string
}

interface ExperienceQuantityItem {
  ticket: App.PackageAddonItem | App.ExperienceItemTicket,
  count: number,
}

function mapTourOrderItemToTransactionProduct(item: App.Tours.TourV2OrderItem): UATransactionProduct {
  return {
    name: item.tour.tourOptionTitle,
    id: item.tourId,
    price: `${item.total}`,
    category: 'tour_v2',
    variant: 'instant_booking',
    quantity: 1,
    productRating: RATINGS.tour,
  }
}

function getExperienceVariant(item: App.OrderExperienceItem) {
  const { provider, leExclusive } = item

  let result

  switch (provider) {
    case 'led':
      result = leExclusive ? 'LED Flash (LE Exclusive)' : 'LED Always On'
      break
    case 'rez':
      result = leExclusive ? 'Rezdy Flash (LE Exclusive)' : 'Rezdy Always On'
      break
    default:
      result = 'Unknown provider'
  }

  return result
}

function mapExperienceOrderItemToTransactionProduct(item: App.OrderExperienceItem): UATransactionProduct {
  return {
    name: item.ticket.name,
    id: item.id,
    price: `${item.total}`,
    category: 'experience',
    variant: getExperienceVariant(item),
    quantity: 1,
    productRating: RATINGS.experiences,
  }
}

function mapFlightsOrderItemToTransactionProduct(item: App.OrderFlightItem): UATransactionFlight {
  return {
    name: '', // This name will be added by this ticket CRO-1134
    id: item.itemId,
    price: `${item.total}`,
    category: 'flight',
    variant: 'instant_booking',
    provider: item.provider,
    quantity: 1,
    productRating: RATINGS.flight,
  }
}

function mapAccommodationOrderItemToTransactionProduct(item: App.OrderItem): UATransactionLEAccommodationProduct {
  const typeConfig = offerTypeConfig[item.offer.type]!
  return {
    ...(item ?? {}),
    name: item.reservation?.property?.name || item.reservation?.tourName || '',
    id: item.offerId,
    price: `${item.packagePrice + (item.reservation?.surcharge || 0) + (item.reservation?.extraGuestSurcharge || 0)}`,
    packageId: item.id,
    packageName: item.package.name,
    quantity: 1,
    category: buildProductCategory(typeConfig as any),
    subCategory: typeConfig.analyticsType,
    variant: item.reservationType,
    productRating: RATINGS[typeConfig.analyticsType],
    bookingNumber: item.bookingNumber,
    location: item.offer.location,
    // basePrice: '', This name will be added by this ticket CRO-1134,
  }
}

/**
 * This event use accommodation item when it is added to checkout.
 * So will not use App.OrderItem because order is not completed yet.
 */
export function mapCheckoutAccommodationItemToProduct(
  offer: App.Offer | App.OfferSummary,
  packageOption: OfferPackageOptionView,
  reservationType: string,
): UATransactionLEAccommodationProduct {
  const { package: pkg } = packageOption

  return {
    name: offer.property?.name || '',
    id: offer.id,
    price: `${packageOption.hotelPrice + packageOption.surcharge}`,
    category: buildProductCategory(offer),
    subCategory: offer.analyticsType,
    variant: reservationType || '',
    quantity: 1,
    packageId: pkg.id || '',
    packageName: pkg.name,
    location: offer.location,
    productRating: RATINGS[offer.analyticsType],
  }
}

export function mapCheckoutBundleAndSaveItemToProduct(
  offer: App.Offer,
  packageOption: BundleOfferPackageOptionView,
) {
  return {
    name: offer.property?.name || '',
    id: offer.id,
    price: `${packageOption.hotelPrice + packageOption.surcharge}`,
    category: buildProductCategory(offer),
    subCategory: offer.analyticsType,
    variant: RESERVATION_TYPE_INSTANT_BOOKING,
    quantity: 1,
    packageId: packageOption.bundlePackageId || '',
    packageName: packageOption.bundlePackageName,
    location: offer.location,
    productRating: RATINGS[offer.analyticsType],
  }
}

/**
 * This event use experience item when it is added to checkout.
 */
export function mapCheckoutExperienceItemToProduct(
  experience: App.ExperienceBookingView,
  ticket: App.PackageAddonItem | App.ExperienceItemTicket,
  quantity: number,
): UATransactionProduct {
  return {
    quantity,
    id: experience.id,
    name: experience.title,
    category: 'experience',
    variant: 'instant_booking',
    productRating: RATINGS.experiences,
    price: ticket.price ? `${ticket.price}` : '0',
  }
}

/**
 * This event use experience transfer item when it is added to checkout.
 */
export function mapCheckoutTransferItemToProduct(
  experience: App.ExperienceBookingView,
  transfer: App.ExperienceTransferView,
): UATransactionProduct {
  return {
    quantity: 1,
    id: experience.id,
    name: experience.title,
    category: 'transfer',
    variant: 'instant_booking',
    productRating: RATINGS.experiences,
    price: transfer.option?.price ? `${transfer.option?.price}` : '0',
  }
}

function mapBedbankItemToTransactionProduct(item: App.OrderBedbankItem): UATransactionBaseAccommodationProduct {
  return {
    // ...(item ?? {}),
    id: item.offer.id,
    name: item.offer.name,
    price: `${item.total}`,
    category: `${item.offer.parentType}/${item.offer.analyticsType}`,
    subCategory: item.offer.analyticsType,
    variant: item.offer.analyticsType,
    quantity: 1,
    packageName: item.rooms[0].name, // This will change with multirooms
    productRating: RATINGS[item.offer.analyticsType],
    bookingNumber: item.bookingNumber,
  }
}

interface UATransaction {
  ecommerce: {
    currencyCode: string
    purchase: {
      _clear: true
      actionField: {
        affiliation: string
        coupon?: string
        id: string,
        revenue: `${number}`
        shipping: `${number}`
        tax: `${number}`
      },
      isNewOrder: boolean
      products: Array<UATransactionProduct | UATransactionFlight | UATransactionLEAccommodationProduct | UATransactionBaseAccommodationProduct>
    }
  }
  event: 'transaction'
}

export function mapOrderToEcommercePurchase(order: App.Order): UATransaction {
  const {
    id: orderId,
    currencyCode,
    source,
    leLabel,
    total,
    tourItems,
    experienceItems,
    flightItems,
    items: accommodationItems,
    bedbankItems,
  } = order

  return {
    event: 'transaction',
    ecommerce: {
      currencyCode,
      purchase: {
        _clear: true,
        isNewOrder: true,
        actionField: {
          id: orderId,
          affiliation: leLabel === 'scoopontravel' ? 'travel' : source, // logic taken from src/client/analytics/eventDefinitions[function transaction]
          revenue: `${Math.round(total * 100) / 100}`,
          shipping: '0',
          tax: '0',
        },
        products: [
          ...tourItems.map(mapTourOrderItemToTransactionProduct),
          ...experienceItems.map(mapExperienceOrderItemToTransactionProduct),
          ...flightItems.map(mapFlightsOrderItemToTransactionProduct),
          ...accommodationItems.map(mapAccommodationOrderItemToTransactionProduct),
          ...bedbankItems.map(mapBedbankItemToTransactionProduct),
        ],
      },
    },
  }
}
export function addAccommodationToCheckout(
  offer: App.Offer | App.OfferSummary,
  packageOption: OfferPackageOptionView,
  reservationType: string,
  currencyCode: string,
) {
  return {
    event: 'add-to-cart',
    hitType: 'add-to-cart',
    ecommerce: {
      currencyCode,
      add: {
        _clear: true,
        products: [mapCheckoutAccommodationItemToProduct(offer, packageOption, reservationType)],
      },
    },
  }
}

export function addBundleAndSaveToCheckout(
  offer: App.Offer,
  packageOption: BundleOfferPackageOptionView,
  currencyCode: string,
) {
  return {
    event: 'add-to-cart',
    hitType: 'add-to-cart',
    ecommerce: {
      currencyCode,
      add: {
        _clear: true,
        products: [mapCheckoutBundleAndSaveItemToProduct(offer, packageOption)],
      },
    },
  }
}

export function addExperienceToCheckout(
  experience: App.ExperienceBookingView,
  ticket: ExperienceQuantityItem,
  currencyCode: string,
) {
  return {
    event: 'add-to-cart',
    hitType: 'add-to-cart',
    ecommerce: {
      currencyCode,
      add: {
        _clear: true,
        products: [mapCheckoutExperienceItemToProduct(experience, ticket.ticket, ticket.count)],
      },
    },
  }
}

export function addTransferToCheckout(
  experience: App.ExperienceBookingView,
  transfer: App.ExperienceTransferView,
  currencyCode: string,
) {
  return {
    event: 'add-to-cart',
    hitType: 'add-to-cart',
    ecommerce: {
      currencyCode,
      add: {
        _clear: true,
        products: [mapCheckoutTransferItemToProduct(experience, transfer)],
      },
    },
  }
}
