import FilterChip from 'components/Luxkit/Chips/FilterChip'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import styled from 'styled-components'
import { rem } from 'polished'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import LineAngleUpIcon from 'components/Luxkit/Icons/line/LineAngleUpIcon'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import Group from 'components/utils/Group'
import { replace } from 'connected-react-router'
import { CUSTOMER_RATING_GTE } from 'constants/search'
import { useAppDispatch } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import { deleteSearchParams, propertyToDestinationSearch, setSearchParamValue } from 'lib/url/searchUrlUtils'
import React, { useCallback } from 'react'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Modal from 'components/Luxkit/Modal/Modal'
import { useScreenSizeOnly } from 'hooks/useScreenSize'

const RadioGroup = styled(Group)`
  padding-inline: ${rem(16)};
`

const customerRatingOptions = [
  CUSTOMER_RATING_GTE.EXCEPTIONAL,
  CUSTOMER_RATING_GTE.VERY_GOOD,
  CUSTOMER_RATING_GTE.GOOD,
  CUSTOMER_RATING_GTE.ANY_RATING,
]

interface Props {
  filters: App.OfferListFilters
  search: string
  label: string
  place?: App.Place;
}

function CustomerRatingDropdown(props: Props) {
  const { filters, search, label, place } = props
  const dispatch = useAppDispatch()
  const isMobile = useScreenSizeOnly('mobile')
  const [isDropdownOpen, toggleDropDown, , closeDropdown] = useToggle()
  const filterChipRef = React.useRef<HTMLButtonElement>(null)
  const selectedRating = filters?.customerRatingGte ?? CUSTOMER_RATING_GTE.ANY_RATING.value

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value === CUSTOMER_RATING_GTE.ANY_RATING.value) {
      dispatch(replace({ search: propertyToDestinationSearch(deleteSearchParams(search, 'customerRatingGte'), place) }))
    } else {
      dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(search, 'customerRatingGte', e.target.value), place) }))
    }
    closeDropdown()
  }, [closeDropdown, dispatch, place, search])

  const hasRatingBeenSelected = selectedRating !== CUSTOMER_RATING_GTE.ANY_RATING.value && !!selectedRating
  const chipLabel = label + (hasRatingBeenSelected ? ` (${selectedRating}.0+)` : '')

  return (
    <div>
      <FilterChip
        selected={hasRatingBeenSelected}
        ref={filterChipRef}
        size="medium"
        onClick={toggleDropDown}
        endIcon={isDropdownOpen ? <LineAngleUpIcon /> : <LineAngleDownIcon />}
      >
        {chipLabel}
      </FilterChip>
      <CSSBreakpoint min="tablet">
        <DropdownList
          size="S"
          triggerRef={filterChipRef}
          anchorRef={filterChipRef}
          open={isDropdownOpen}
          placement="bottom-start"
          onClose={closeDropdown}
        >
          <RadioGroup direction="vertical" gap={16}>
            {customerRatingOptions.map(option => (
              <RadioInput
                key={option.label}
                checked={option.value == selectedRating}
                onChange={onChange}
                name={option.label}
                value={option.value}
              >
                {option.label}
              </RadioInput>
            ))}
          </RadioGroup>
        </DropdownList>
      </CSSBreakpoint>
      <CSSBreakpoint max="mobile">
        <Modal
          title={label}
          isOpen={isDropdownOpen && isMobile}
          onClose={closeDropdown}
          primaryActionText="Apply"
          onPrimaryActionClick={closeDropdown}
        >
          <Group direction="vertical" gap={16}>
            {customerRatingOptions.map(option => (
              <RadioInput
                key={option.label}
                checked={option.value == selectedRating}
                onChange={onChange}
                name={option.label}
                value={option.value}
              >
                {option.label}
              </RadioInput>
            ))}
          </Group>
        </Modal>
      </CSSBreakpoint>
    </div>
  )
}

export default CustomerRatingDropdown
