import TextButton from 'components/Luxkit/Button/TextButton'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import TourProductTypeLabel from 'components/Tours/TourProductTypeLabel'
import TourV2UrgencyTags from 'components/Tours/TourV2UrgencyTags'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import useSearchTourVariation from 'hooks/TourV2/useSearchTourVariation'
import { EmptyArray } from 'lib/array/arrayUtils'
import { EmptyObject } from 'lib/object/objectUtils'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import React, { useMemo } from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import SearchTile, { SearchTileLogo } from '../SearchTile'
import SearchTileRowOverlineJourney from '../shared/SearchTileRowOverlineJourney'
import TourV2SearchTileFeatures from './TourV2SearchTileFeatures'
import TourV2SearchTileMeta from './TourV2SearchTileMeta'
import TourV2SearchTilePriceStack from './TourV2SearchTilePriceStack'
import SolidStarCircleIcon from 'components/Luxkit/Icons/solid/SolidStarCircleIcon'
import StructureLabel from 'components/Luxkit/Label/StructureLabel'
import { isTourLimitedTimeOffer } from 'lib/tours/tourUtils'
import { CRUISE_FLASH_OFFER_TYPE } from 'constants/cruise'

interface Props {
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary
  filters?: App.OfferListFilters
}

function TourV2SearchTile(props: Props) {
  const { offer, filters = EmptyObject }: Props = props

  const variation = useSearchTourVariation(offer, filters)

  const offerUrl = useOfferUrl(offer, {
    filters,
    offerLinkIncludesFilters: true,
  })

  const isLimitedTimeOffer = isTourLimitedTimeOffer(offer)

  const cruiseFlashOfferLogo = useMemo((): SearchTileLogo | undefined => {
    const isCruiseFlashOffer = offer.productType === CRUISE_FLASH_OFFER_TYPE
    if (!isCruiseFlashOffer) {
      return
    }

    return {
      compact: { id: offer.brand.logoImage?.id, title: offer.brand.name },
      full: { id: offer.brand.logoImage?.id, title: offer.brand.name },
    }
  }, [offer])

  return <SearchTile
    className={TourV2SearchTile.name}
    productType={offer.productType}
    offerType={offer.type}
    action={<TextButton nonInteractable kind="primary">View offer</TextButton>}
    bookmarkAction={<BookmarkButton offer={offer} />}
    features={variation ? <TourV2SearchTileFeatures variation={variation} offer={offer} filters={filters} /> : undefined}
    images={variation?.images ?? EmptyArray}
    labels={<TourV2UrgencyTags offer={offer}/>}
    meta={<TourV2SearchTileMeta variation={variation} offer={offer} filters={filters} />}
    overline={variation?.startLocation && variation?.endLocation ? <SearchTileRowOverlineJourney start={variation.startLocation} end={variation.endLocation} /> : undefined}
    pricePoint={<TourV2SearchTilePriceStack offer={offer} filters={filters} variation={variation} />}
    productLabel={<LabelGroup>
      {isLimitedTimeOffer &&
        <StructureLabel startIcon={<SolidStarCircleIcon />} variant="exclusive">Limited Time Lux Exclusive</StructureLabel>
      }
      <TourProductTypeLabel productType={offer.productType} offerUrl={offerUrl} />
    </LabelGroup>}
    rating={isOfferRatingDisplayable(offer.rating, 0) ? offer.rating : undefined}
    title={offer.name}
    logo={cruiseFlashOfferLogo}
  />
}

export default TourV2SearchTile
