import React, { useContext } from 'react'
import GeoContext from 'contexts/geoContext'
import { LUXURY_ESCAPES } from 'constants/brands'
import config from 'constants/config'
import HeadData from 'components/Common/HeadData'

const headTitle = config.BRAND === LUXURY_ESCAPES ? 'Luxury Cruises' : 'Cruises'

const description = config.BRAND === LUXURY_ESCAPES ?
  'Embark on a life changing cruise. Curated by travel experts to save you time. Featuring luxury accommodation, select dining and exclusive inclusions.' :
  undefined

function CruiseHeadData() {
  const { currentRegionCode } = useContext(GeoContext)
  return <HeadData title={`${headTitle} - ${config.title} ${currentRegionCode}`} description={description} />
}

export default CruiseHeadData
