import requestUtils from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-business'
import qs from 'qs'

type BusinessPolicyResponse = paths['/api/business-traveller/businesses/{businessId}/policies']['get']['responses'][200]['content']['application/json']

type BusinessPolicyResult = paths['/api/business-traveller/businesses/{businessId}/policies']['get']['responses'][200]['content']['application/json']['result']

function mapPolicies(policies: BusinessPolicyResult): Array<App.BusinessTraveller.BusinessPolicy> {
  const mappedBudget: Array<App.BusinessTraveller.BusinessPolicy> = policies.map((budget) => {
    return {
      id: budget.id,
      policyType: budget.policyType,
      percentCreditShare: budget.percentCreditShare,
      overBudgetAuth: budget.overBudgetAuth,
    }
  })
  return mappedBudget
}

interface Params {
  businessId: string;
  policyType: 'hotel' | 'flight' | 'car_hire'
}

async function requestGetPolicy(params: Params) {
  const { businessId, policyType } = params

  try {
    const queryParams = qs.stringify({
      policyType,
    })

    const policies = await requestUtils.get<BusinessPolicyResponse>(
      `/api/business-traveller/businesses/${businessId}/policies?${queryParams}`,
      { credentials: 'include' },
    )

    const mappedPolicies = mapPolicies(policies.result)

    // we should only ever have 1 policy of type 'hotel' | 'flight' | 'car_hire'
    return mappedPolicies.length ? mappedPolicies[0] : undefined
  } catch (error) {
    return Promise.reject(error)
  }
}

export default requestGetPolicy
