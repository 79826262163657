import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import { getBusinessTravellerSelectEnabled } from 'businessTraveller/utils/getBusinessTravellerSelectEnabled'
import Clickable from 'components/Common/Clickable'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import { prettyFlexibleDates } from 'components/Search/SearchForm/SearchDateInput/FlexibleDateSearchField'
import { prettyDates, getTotalRoomsString } from 'components/Search/utils'
import { DATE_SEARCH_OPTION_IDS } from 'constants/search'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { formatOccupantsAsGuests } from 'lib/offer/occupancyUtils'
import { rem } from 'polished'
import React, { useContext, useMemo } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import HotelExposedFiltersHeader from './HotelExposedFiltersHeader/HotelExposedFiltersHeader'
import { deleteSearchParams } from 'lib/url/searchUrlUtils'
import { OfferListSortOption } from 'constants/offerListFilters'

const Container = styled.div`
  padding: 0 ${rem(20)} ${rem(12)};
  > * + * {
    margin-top: ${rem(12)};
  }
`

const InlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * + * {
    margin-left: ${rem(8)};
  }
`

const EditContainer = styled(Clickable)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(8)};
  width: 100%;
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DescriptionSection = styled.div`
  display: flex;
  column-gap: ${rem(8)};
`

const StyledIconButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface MappedStateProps {
  businessAccount?: App.BusinessTraveller.CurrentBusinessAccount;
  travellerEmployees?: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>;
  windowSearch: string;
}

interface Props {
  onEdit: () => void;
  filters: App.OfferListFilters;
  sortOptions?: Array<OfferListSortOption>
  className?: string;
  hideFilters?: boolean
}

function SearchField(props: Props & MappedStateProps) {
  const { onEdit, businessAccount, travellerEmployees, filters, windowSearch, sortOptions, className, hideFilters } = props

  const {
    checkinDate,
    checkoutDate,
    occupancies,
    searchTargetLabel,
    dateSearchOptionId,
    flexibleMonths,
    flexibleNights,
    userSelectedFlexibleMonths,
  } = useContext(GlobalSearchStateContext)

  const isBusinessTravellerSelectEnabled = getBusinessTravellerSelectEnabled(businessAccount)

  const mapViewURL = useMemo(() => `/search/map?${deleteSearchParams(windowSearch, 'sortBy')}`, [windowSearch])

  const searchBarDescription = useMemo(() => {
    const bulletPointSeparator = '\u2022'
    if (dateSearchOptionId === DATE_SEARCH_OPTION_IDS.ANYTIME) {
      return [
        'Anytime',
      ]
    }
    const datesDetails = dateSearchOptionId === DATE_SEARCH_OPTION_IDS.FLEXIBLE ?
      prettyFlexibleDates(userSelectedFlexibleMonths ? flexibleMonths : undefined, flexibleNights) :
      prettyDates(checkinDate, checkoutDate)
    const occupancyDetails = formatOccupantsAsGuests(...occupancies)
    const roomsDetails = getTotalRoomsString(occupancies)
    const occupancyWithRoomDetails = `${occupancyDetails} ${isBusinessTravellerSelectEnabled ? '' : roomsDetails}`

    if (isBusinessTravellerSelectEnabled) {
      const travellerCount = travellerEmployees?.length ?? 1
      const travellers = travellerCount > 1 ? `${travellerCount} travellers` : '1 traveller'

      return [
        datesDetails,
        bulletPointSeparator,
        travellers,
        bulletPointSeparator,
        occupancyWithRoomDetails,
      ]
    }

    return [
      datesDetails,
      bulletPointSeparator,
      occupancyWithRoomDetails,
    ]
  }, [dateSearchOptionId, userSelectedFlexibleMonths, flexibleMonths, flexibleNights, checkinDate, checkoutDate, occupancies, isBusinessTravellerSelectEnabled, travellerEmployees?.length])
  return (
    <Container className={className}>
      <InlineContainer>
        <EditContainer onClick={onEdit}>
          <TextContainer>
            <Heading variant="heading6" >{searchTargetLabel ?? 'Map area'}</Heading>
            <DescriptionSection>
              {searchBarDescription.map((description, index) => (
                <Caption key={index} variant="large" lineClamp={1}>
                  {description}
                </Caption>
              ))}
            </DescriptionSection>
          </TextContainer>
          <LineSearchIcon size={20} />
        </EditContainer>
        <StyledIconButton kind="tertiary" to={mapViewURL}>
          <LineMapIcon />
          <BodyText variant="small">Map</BodyText>
        </StyledIconButton>
      </InlineContainer>
      {!hideFilters &&
        <HotelExposedFiltersHeader filters={filters} showSort sortOptions={sortOptions} />
      }
    </Container>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState): MappedStateProps => ({
  businessAccount: appState.businessTraveller.currentBusinessAccount,
  travellerEmployees: selectSelectedTravellerEmployees(appState),
  windowSearch: appState.router.location.search,
}))(SearchField)
