import pluralize from 'lib/string/pluralize'
import React, { memo } from 'react'
import FormatDecimal from './FormatDecimal'

interface Props {
  count: number
  singular: string
  /**
   * If not provided, either the already known plural will be used or the singular will be appended with an "s"
   */
  plural?: string
  /**
   * If enabled, it'll prepend the pluralised word with the count.
   *
   * @example true => "10 things"
   */
  withCount?: boolean
  /**
   * @default single space
   */
  delimiter?: string
}

function Plural(props: Props) {
  const { count, singular, plural, withCount, delimiter = ' ' } = props
  const { text } = pluralize({ count, singular, plural })

  if (withCount) return <><FormatDecimal value={count} />{delimiter}{text}</>
  return <>{text}</>
}

export default memo(Plural)
