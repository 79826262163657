import React from 'react'
import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import PaneBody from 'components/Common/Pane/PaneBody'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import styled from 'styled-components'
import { CRUISE_CONTACT } from 'constants/cruise'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import TextLink from 'components/Luxkit/TextLink'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const SameHeightPaneSm = styled(Pane)`
  margin-top: ${rem(32)};

  ${mediaQueryUp.tablet} {
    margin-top: 0;
    height: 100%;
  }
`

const ContactContent = styled(PaneBody)`
  display: grid;

  ${mediaQueryUp.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Root = styled(VerticalSpacer)`
  text-align: center;
`

function CruisesContact() {
  return <Pane>
    <PaneBody size="xl-y md">
      <LayoutContainer>
        <Heading variant="heading2" align="center" as="h2">
          Need help? Get in touch with us.
        </Heading>
        <ContactContent size="xl-y">
          {CRUISE_CONTACT.map(({ Icon, title, text, subText, link }, i) => {
            return (
              <SameHeightPaneSm key={i}>
                <PaneBody size="lg xl-y">
                  <Root gap={16}>
                    <Icon size={30} colour="primary" />
                    <div>
                      <BodyText variant="medium" weight="bold">
                        {title}
                      </BodyText>
                      <BodyText variant="small" as="p">
                        {text}
                      </BodyText>
                      <TextLink variant="default" size="small" weight="bold" href={link}>
                        {subText}
                      </TextLink>
                    </div>
                  </Root>
                </PaneBody>
              </SameHeightPaneSm>
            )
          })}
        </ContactContent>
      </LayoutContainer>
    </PaneBody>
  </Pane>
}

export default CruisesContact
