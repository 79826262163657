import { definitions } from '@luxuryescapes/contract-trip'

import {
  DeleteTripItemResponse,
  UpdateTripItemRequest,
  UpdateTripItemResponse,
  CreateTripItemRequest,
  CreateTripItemResponse,
  CreateTripItemBatchRequest,
  CreateTripItemBatchResponse,
  DeleteTripItemsByOrderIdResponse,
  GetTripItemsResponse,
  MoveItemToOtherTripRequest,
  MoveItemToOtherTripResponse,
  PatchTripItemResponse,
  PatchTripItemRequest,
  RankTripItemRequest,
  RankTripItemResponse,
  NLPItemCreationMessageRequest,
  NLPItemCreationMessageResponse,
} from './types'
import { baseUri, getOptions } from '../utils'

import request from 'api/requestUtils'
import { NLPResponseOption } from 'tripPlanner/types/nlp'
import { mapNLPResponseOptionToItemPayload } from './mapNLPResponseOptionToItemPayload'

export function getTripItems(
  accessToken?: string,
): Promise<Array<definitions['tripItem']>> {
  return request
    .get<GetTripItemsResponse>(`${baseUri}/items`, getOptions(accessToken))
    .then((response) => response.result)
}

type CreateBatchArgs = {
  tripId: string
} & CreateTripItemBatchRequest

export async function createTripItemBatch(
  { tripId, tripItems }: CreateBatchArgs,
  accessToken?: string,
): Promise<definitions['createItemsBatchResponse']> {
  return request
    .post<
      App.ApiResponse<CreateTripItemBatchResponse>,
      CreateTripItemBatchRequest
    >(
      `${baseUri}/${tripId}/items/batch`,
      {
        tripItems,
      },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

interface CreateArgs {
  tripId: string
  tripItem: CreateTripItemRequest
}

export async function createTripItem(
  { tripId, tripItem }: CreateArgs,
  accessToken?: string,
): Promise<definitions['tripItem']> {
  return request
    .post<App.ApiResponse<CreateTripItemResponse>, CreateTripItemRequest>(
      `${baseUri}/${tripId}/items`,
      tripItem,
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

interface UpdateArgs {
  tripId: string
  tripItemId: string
  tripItem: UpdateTripItemRequest
}

export async function updateTripItem(
  { tripId, tripItemId, tripItem }: UpdateArgs,
  accessToken?: string,
): Promise<definitions['tripItem']> {
  return request
    .put<App.ApiResponse<UpdateTripItemResponse>, UpdateTripItemRequest>(
      `${baseUri}/${tripId}/items/${tripItemId}`,
      tripItem,
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

interface PatchArgs {
  tripId: string
  tripItemId: string
  tripItem: PatchTripItemRequest
}

export async function patchTripItem(
  { tripId, tripItemId, tripItem }: PatchArgs,
  accessToken?: string,
): Promise<definitions['tripItem']> {
  return request
    .patch<App.ApiResponse<PatchTripItemResponse>, PatchTripItemRequest>(
      `${baseUri}/${tripId}/items/${tripItemId}`,
      tripItem,
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

interface DeleteArgs {
  tripId: string
  tripItemId: string
}

export async function deleteTripItem(
  { tripId, tripItemId }: DeleteArgs,
  accessToken?: string,
): Promise<null> {
  return request
    .delete<App.ApiResponse<DeleteTripItemResponse>>(
      `${baseUri}/${tripId}/items/${tripItemId}`,
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

interface DeleteByOrderArgs {
  tripId: string
  orderId: string
}

export async function deleteTripItemsByOrderId(
  { tripId, orderId }: DeleteByOrderArgs,
  accessToken?: string,
): Promise<null> {
  return request
    .delete<App.ApiResponse<DeleteTripItemsByOrderIdResponse>>(
      `${baseUri}/${tripId}/order/${orderId}`,
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export function moveItemToOtherTrip(
  {
    sourceTripId,
    destinationTripId,
    tripItemId,
  }: {
    sourceTripId: string
    destinationTripId: string
    tripItemId: string
  },
  accessToken?: string,
): Promise<{ destinationTrip: definitions['fullTrip'] }> {
  const uri = `${baseUri}/${sourceTripId}/items/${tripItemId}/move-to-other-trip`
  return request
    .post<
      App.ApiResponse<MoveItemToOtherTripResponse>,
      MoveItemToOtherTripRequest
    >(
      uri,
      {
        destinationTripId,
      },
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

interface RankArgs {
  tripId: string
  tripItemId: string
  rankSpec: RankTripItemRequest
}

export async function rankTripItem(
  { tripId, tripItemId, rankSpec }: RankArgs,
  accessToken?: string,
): Promise<RankTripItemResponse> {
  return request
    .post<App.ApiResponse<RankTripItemResponse>, RankTripItemRequest>(
      `${baseUri}/${tripId}/items/${tripItemId}/rank`,
      rankSpec,
      getOptions(accessToken),
    )
    .then((response) => response.result)
}

export interface NLPItemCreationMessageArgs {
  tripId: string
  message: string
  locationBias?: string
  sentTimestamp: number
  date?: string
}

export async function nlpItemCreationMessage(
  { tripId, message, locationBias, sentTimestamp, date }: NLPItemCreationMessageArgs,
  accessToken?: string,
): Promise<{ response: NLPItemCreationMessageResponse, sentTimestamp: number }> {
  return request
    .post<App.ApiResponse<NLPItemCreationMessageResponse>, NLPItemCreationMessageRequest>(
      `${baseUri}/${tripId}/items/nlp-item-create`,
      {
        message,
        locationBias,
        date,
      },
      getOptions(accessToken),
    )
    .then((response) => ({
      response: response.result,
      sentTimestamp,
    }))
}

interface NLPCreateArgs {
  tripId: string
  item: NLPResponseOption
}

export async function createTripItemFromNLPOption(
  { tripId, item }: NLPCreateArgs,
  accessToken?: string,
): Promise<definitions['tripItem']> {
  const itemPayload = await mapNLPResponseOptionToItemPayload(item)
  if (!itemPayload) {
    throw new Error('Invalid NLP response option')
  }
  return createTripItem({ tripId, tripItem: itemPayload }, accessToken)
}
