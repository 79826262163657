import React, { MutableRefObject, RefObject, useCallback, useMemo, useState } from 'react'
import useResizeObserver, { UseResizeObserverCallback } from '@react-hook/resize-observer'
import debounce from 'lodash.debounce'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

interface UseElementSizeOptions {
  enableUpdate?: boolean
  debounceTime?: number
}

/*
  Note: `span` elements don't seem to work properly with ResizeObserver.
  If the size of a span changes due to fonts loading in, the observer
  won't necessarily update, at least in Chrome. Suggest using these
  hooks only with block elements.
*/

export function useElementWidth(
  target?: React.MutableRefObject<HTMLElement | null>,
) {
  const [width, setWidth] = useState<number | null>(null)

  useIsomorphicLayoutEffect(() => {
    if (target?.current) {
      setWidth(target.current.getBoundingClientRect().width)
    }
  }, [target?.current])

  useResizeObserver(target as RefObject<HTMLElement> ?? null, (entry) => setWidth(entry.contentRect.width))
  return width
}

export function useElementHeight(
  target?: React.MutableRefObject<HTMLElement | null>,
  {
    enableUpdate = true,
    debounceTime = 0,
  }: UseElementSizeOptions = {}) {
  const [height, setHeightRaw] = useState<number | null>(null)

  useIsomorphicLayoutEffect(() => {
    if (target?.current) {
      setHeightRaw(target.current.getBoundingClientRect().height)
    }
  }, [target?.current])

  const setHeight = useMemo<typeof setHeightRaw>(
    () => {
      if (debounceTime) {
        return debounce(setHeightRaw, debounceTime)
      }
      return setHeightRaw
    },
    [debounceTime],
  )

  useResizeObserver(target as RefObject<HTMLElement> ?? null, (entry) => {
    if (enableUpdate) {
      // Some obscure clients might not support borderBoxSize yet
      const height = entry.borderBoxSize?.[0] ? entry.borderBoxSize[0].blockSize : target?.current!.getBoundingClientRect().height!
      setHeight(height)
    }
  })
  return height
}

type RefLike<T> = RefObject<T> | MutableRefObject<T>

function isElementRef<T extends HTMLElement>(element: RefObject<T> | MutableRefObject<T> | T | null): element is RefLike<T> {
  return !!element && 'current' in element
}

export type ElementBoundingClientRectObserver = (rect: DOMRect, observerEntry?: ResizeObserverEntry) => void
export function useElementBoundingClientRectObserver<T extends HTMLElement>(
  target: RefObject<T> | MutableRefObject<T> | T | null,
  /**
   * Ensure the reference is stable.
   */
  observer: ElementBoundingClientRectObserver,
) {
  const handleObservation = useCallback((t: RefObject<T> | MutableRefObject<T> | T | null) => {
    const element = isElementRef(t) ? t.current : t
    if (element) {
      observer(element.getBoundingClientRect(), undefined)
    }
  }, [observer])

  useIsomorphicLayoutEffect(() => {
    handleObservation(target)
  }, [target])

  const handleResizeObservation = useCallback<UseResizeObserverCallback>((entry) => {
    handleObservation((entry.target as T) ?? target)
  }, [handleObservation, target])

  useResizeObserver(target ?? null, handleResizeObservation)
}
