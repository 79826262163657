/**
 * DO NOT IMPORT COLOURS FROM THIS FILE DIRECTLY.
 * These primitive colours are set up to be used as theme.palette configuration only.
 */
export const BLUE: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#F2F7FF',
  100: '#DBE9FF',
  200: '#BBD7FF',
  300: '#8BC0FF',
  400: '#5DA4FA',
  500: '#3688F0',
  600: '#376DD3',
  700: '#304DAB',
  800: '#1F3579',
  900: '#0F1C47',
  950: '#081131',
}

export const PURPLE: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#E7E7F9',
  100: '#CFCEF3',
  200: '#B7B6ED',
  300: '#9E9DE6',
  400: '#8685E0',
  500: '#6B6ADA',
  600: '#5C5AB6',
  700: '#494891',
  800: '#37366D',
  900: '#16162C',
  950: '#111124',
}

export const GOLD: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#F7F4E1',
  100: '#EFE8D1',
  200: '#DDD5B8',
  300: '#C6BC92',
  400: '#AEA478',
  500: '#96885F',
  600: '#78704E',
  700: '#505439',
  800: '#403A26',
  900: '#2C2819',
  950: '#16130A',
}

export const PLATINUM: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#F2F3F7',
  100: '#E1E9EE',
  200: '#C7D7E0',
  300: '#A3C1D0',
  400: '#84A9B9',
  500: '#668F9F',
  600: '#537684',
  700: '#3D5863',
  800: '#293C47',
  900: '#1B2A30',
  950: '#0C151A',
}

export const RED: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#FEF0F1',
  100: '#FDE2E3',
  200: '#FCC8CC',
  300: '#F8A7AE',
  400: '#F48189',
  500: '#EF505F',
  600: '#D43044',
  700: '#A02332',
  800: '#721622',
  900: '#500E18',
  950: '#2C050A',
}

export const ORANGE: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#FBEFE2',
  100: '#F6DFC4',
  200: '#F2CFA7',
  300: '#EDBE89',
  400: '#E9AE6C',
  500: '#E49F4E',
  600: '#BE8441',
  700: '#986934',
  800: '#724F27',
  900: '#4C351A',
  950: '#1C1105',
}

export const LIME_GREEN: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#BFE9C8',
  100: '#9FDEAD',
  200: '#80D292',
  300: '#60C777',
  400: '#40BC5B',
  500: '#20B140',
  600: '#1B9335',
  700: '#15762B',
  800: '#105920',
  900: '#0B3B15',
  950: '#000000',
}

export const TEAL: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#CAFFEB',
  100: '#7EFFD4',
  200: '#01F1BD',
  300: '#02D5A6',
  400: '#02B990',
  500: '#009B79',
  600: '#018063',
  700: '#00604A',
  800: '#004333',
  900: '#002E22',
  950: '#001710',
}

export const BLACK: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#FFFFFF',
  100: '#E3E3E3',
  200: '#C6C6C6',
  300: '#AAAAAA',
  400: '#8E8E8E',
  500: '#717171',
  600: '#555555',
  700: '#393939',
  800: '#1C1C1C',
  900: '#000000',
  950: '#000000',
}

export const GREY: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#F3F3F4',
  100: '#E7E8EA',
  200: '#D4D5D8',
  300: '#BABCC2',
  400: '#A0A3AB',
  500: '#868993',
  600: '#6D717C',
  700: '#505460',
  800: '#363A45',
  900: '#252830',
  950: '#111318',
}

export const BRONZE: App.Design.PrimitiveColourRange = {
  0: '#FFFFFF',
  50: '#F8F3F2',
  100: '#EFE7E3',
  200: '#E1D1C9',
  300: '#D4B5A4',
  400: '#BF9C88',
  500: '#AD7E5F',
  600: '#91674E',
  700: '#6E4D39',
  800: '#4D3526',
  900: '#352319',
  950: '#1C110A',
}
