import Label from 'components/Luxkit/Label'
import { useOfferBestPrice } from 'hooks/Offers/useOfferBestPrice'
import { pluralizeToString } from 'lib/string/pluralize'
import React from 'react'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import { OFFER_BOOKINGS_THRESHOLD } from 'constants/offer'
import BookingsCountLabel from 'components/Common/Labels/BookingsCountLabel'

const URGENCY_TAG_THRESHOLD = 5

interface Props {
  offer: App.Offer | App.OfferSummary;
  filters?: App.OfferListFilters;
}

function OfferTilePricingUrgencyTags(props: Props) {
  const {
    offer,
    filters,
  } = props

  const numRoomsPackage = useOfferBestPrice(offer, filters, { disabled: !!filters?.isStream })
  const offerMetaData = useOfferMetaData(offer.id, filters)

  const showBookedTodayUrgencyTag = offer.stats.booked >= OFFER_BOOKINGS_THRESHOLD

  const availableRooms = numRoomsPackage?.availableRooms ?? offerMetaData?.availableRooms
  const showLowRoomsUrgencyTag = !showBookedTodayUrgencyTag &&
    !!availableRooms &&
    availableRooms !== 0 &&
    availableRooms <= URGENCY_TAG_THRESHOLD &&
    !!filters?.checkIn

  return <>
    <BookingsCountLabel bookings={offer.stats.booked} threshold="offer" />
    {showLowRoomsUrgencyTag && <Label variant="urgency" kind="plain">
      Only {pluralizeToString('room', availableRooms)} left at this price!
    </Label>}
  </>
}

export default React.memo(OfferTilePricingUrgencyTags)
