import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import React, { useMemo } from 'react'
import TourV2Location from './TourV2Location'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import TourV2PriceDetails from './TourV2PriceDetails'
import TourV2UrgencyTags from 'components/Tours/TourV2UrgencyTags'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import { TOUR_STYLED_PRODUCT_TYPE_TITLES } from 'constants/tours'
import { isTourProductTypeStyled } from 'lib/tours/tourUtils'
import findCheapestTourV2PurchasableOption from 'lib/tours/findCheapestTourV2PurchasableOption'
import { generateLuxLoyaltyPointsCalculatorTourOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'

interface Props {
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary;
  onClick: () => void;
  className?: string;
  offerUrl: string;
  size: 'small' | 'medium';
}

function TourV2OfferCard(props: Props) {
  const {
    offer,
    onClick,
    className,
    offerUrl,
    size,
  } = props
  const { cheapestPurchasableOption, cheapestVariation } = useCheapestOfTourV2Offer(offer)
  const variationDetails = useTourV2VariationDetails(cheapestVariation)

  const providerName = useMemo<string>(() => {
    return [
      offer.operatedBy,
      isTourProductTypeStyled(offer.productType) ? TOUR_STYLED_PRODUCT_TYPE_TITLES[offer.productType] : undefined,
    ].filter(Boolean).join(' · ')
  }, [offer])

  const pointsEarnCalculationRequests = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>>(
    () => {
      const cheapestPurchasableOption = findCheapestTourV2PurchasableOption(offer)
      return [generateLuxLoyaltyPointsCalculatorTourOptions(offer, { price: cheapestPurchasableOption?.price, memberPrice: cheapestPurchasableOption?.memberPrice, roomType: cheapestPurchasableOption?.roomType })]
    },
    [offer],
  )

  if (!cheapestPurchasableOption || !cheapestVariation) {
    return null
  }

  const {
    startLocation,
    endLocation,
    hasTheSameStartAndEndLocation,
  } = variationDetails

  const updatedEndLocation = hasTheSameStartAndEndLocation ? startLocation : endLocation

  return (
    <ProductPaletteProvider product={offer}>
      {size === 'medium' && <CarouselCardMedium
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} withLabel="tablet" />}
        title={offer.name}
        rating={isOfferRatingDisplayable(offer.rating, 0) ? offer.rating : undefined}
        providerName={providerName}
        image={cheapestVariation.images[0]}
        location={<TourV2Location startLocation={startLocation} endLocation={updatedEndLocation} variant="start_and_end" />}
        priceDetails={<TourV2PriceDetails offer={offer} variant="condensed" />}
        to={offerUrl}
        urgencyLabels={<TourV2UrgencyTags offer={offer} />}
        onClick={onClick}
        loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
      />}
      {size === 'small' && <CarouselCardSmall
        className={className}
        rating={isOfferRatingDisplayable(offer.rating, 0) ? offer.rating : undefined}
        bookmarkButton={<BookmarkButton offer={offer} withLabel="tablet" />}
        image={cheapestVariation.images[0]}
        location={<TourV2Location startLocation={startLocation} endLocation={updatedEndLocation} variant="start_and_end" />}
        title={offer.name}
        priceDetails={<TourV2PriceDetails offer={offer} />}
        to={offerUrl}
        productType={offer.productType}
        urgencyLabels={<TourV2UrgencyTags offer={offer} />}
        onClick={onClick}
        loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
      />}
    </ProductPaletteProvider>
  )
}

export default TourV2OfferCard
