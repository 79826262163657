import { fetchRecentSearches } from 'actions/SearchActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect } from 'react'

function useRecentSearches(): Array<App.FullRecentSearch> {
  const dispatch = useAppDispatch()

  const recentSearches = useAppSelector(state => state.auth.recentSearches)
  useEffect(() => {
    dispatch(fetchRecentSearches())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return recentSearches
}

export default useRecentSearches
