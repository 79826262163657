import React, { useImperativeHandle, useRef, useCallback } from 'react'
import noop from 'lib/function/noop'
import styled from 'styled-components'
import Input from './Input'
import useMountedEffect from 'hooks/useMountedEffect'

const InvisibleInput = styled(Input)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  clip: rect(0,0,0,0);
  pointer-events: none;
  user-select: none;
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  invalidMessage?: string;
  onDirtyChange?: (dirty: boolean) => void;
  onErrorUpdate?: (message?: string) => void;
}

const validityErrors: Array<keyof ValidityState> = ['badInput', 'patternMismatch', 'tooLong', 'tooShort', 'rangeOverflow', 'rangeUnderflow', 'typeMismatch', 'valueMissing']

const HiddenInput = React.forwardRef<HTMLInputElement | null, Props>((props, ref) => {
  const {
    className,
    onErrorUpdate = noop,
    invalidMessage,
    onDirtyChange,
    defaultValue,
    required,
    onChange,
    value = '',
    ...rest
  } = props
  const input = useRef<HTMLInputElement | null>(null)

  const onInputErrorUpdate = useCallback((input: HTMLInputElement) => {
    const validity = input.validity
    if (
      validity &&
      !validity.valid &&
      // make sure a not-custom error is what's triggering our invalid condition
      validityErrors.some(error => validity[error])
    ) {
      const nextMessage = invalidMessage ?? input.validationMessage
      input.setCustomValidity(nextMessage)
      onErrorUpdate(nextMessage)
    } else {
      input.setCustomValidity('')
      onErrorUpdate(undefined)
    }
  }, [invalidMessage, onErrorUpdate])

  useMountedEffect(() => {
    if (input.current) {
      input.current.dispatchEvent(new Event('change', { bubbles: true }))
      onInputErrorUpdate(input.current)
    }
  }, [onInputErrorUpdate, value])

  useImperativeHandle(ref, () => input.current!)

  return (
    <InvisibleInput
      {...rest}
      inputMode="none"
      aria-hidden="true"
      value={value}
      tabIndex={-1}
      required={required}
      className={className}
      ref={input}
      onChange={onChange}
      onDirtyChange={onDirtyChange}
      onErrorUpdate={onInputErrorUpdate}
    />
  )
})

export default HiddenInput
