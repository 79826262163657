import { getGeoRedirect, clearGeoRedirect } from 'storage/geo'
import moment from 'moment'
import config from 'constants/config'

export default function shouldShowGeoRedirect() {
  const data = getGeoRedirect()
  if (data?.status) {
    if (config.agentHub.isEnabled || moment(data.expiry_timestamp).isBefore(moment().unix())) {
      clearGeoRedirect()
      return true
    }

    return false
  }

  return true
}
