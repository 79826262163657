import React, { useCallback, useContext } from 'react'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import HeadingTextBlock from 'components/Luxkit/TextBlocks/HeadingTextBlock'
import GeoContext from 'contexts/geoContext'
import FormatCurrency from 'components/Common/FormatCurrency/FormatCurrency'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import TableBody from 'components/Luxkit/Tables/TableBody'
import TableBodyRow from 'components/Luxkit/Tables/TableBodyRow'
import TableBodyCell from 'components/Luxkit/Tables/TableBodyCell'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Table from 'components/Luxkit/Tables/Table'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextLink from 'components/Luxkit/TextLink'
import Pill from 'components/Luxkit/Pill'
import styled from 'styled-components'
import { rem } from 'polished'
import { getImageUrl } from 'lib/image/imageUtils'
import useModalElementContext from 'hooks/Modal/useModalElementContext'

const SPEND_EARN_MODAL_IMAGE_BACKGROUND = 'promo-spend-earn-background'

const SpendEarnHeader = styled(VerticalSpacer)`
  background-image: url(${getImageUrl(SPEND_EARN_MODAL_IMAGE_BACKGROUND, { brightness: -50 })});
  background-position: center;
  background-size: 100%;
  padding: ${rem(48)} 0;
`

interface Props {
  maxCredit: number
  spendEarnList: Array<{ spend: number, earn: number }>
}

function TourSpendEarnDetailModal({ maxCredit, spendEarnList }: Props) {
  const currency = useContext(GeoContext).currentCurrency
  const elementContext = useModalElementContext()

  const onClose = useCallback(() => elementContext?.resolve(), [elementContext])

  return <ModalBase>
    <ModalHeader title="Spend more, earn more" isOverlay />
    <ModalBody>
      <SpendEarnHeader gap={16}>
        <HeadingTextBlock colour="neutral-eight" variant="heading1" align="center"><i>Spend</i> more,<br /><i>Earn</i> more</HeadingTextBlock>
        <HeadingTextBlock colour="neutral-eight" variant="heading6" align="center">Use code <Pill kind="primary" variant="success">TOURDEAL</Pill> at checkout</HeadingTextBlock>
      </SpendEarnHeader>
      <ModalContent>
        <VerticalSpacer gap={20}>
          <HeadingTextBlock variant="heading4">Book a tour and enjoy up to <FormatCurrency value={maxCredit} currency={currency} /> Luxury Escapes credit</HeadingTextBlock>
          <Table>
            <TableBody>
              <TableBodyRow>
                <TableBodyCell>
                  <BodyText variant="medium" weight="bold">Total spend</BodyText>
                </TableBodyCell>
                <TableBodyCell>
                  <BodyText variant="medium" weight="bold">Credit you’ll earn</BodyText>
                </TableBodyCell>
              </TableBodyRow>
              {spendEarnList.map(({ spend, earn }) => <TableBodyRow key={`${spend}-${earn}`}>
                <TableBodyCell><FormatCurrency value={spend} currency={currency} /></TableBodyCell>
                <TableBodyCell><FormatCurrency value={earn} currency={currency} /></TableBodyCell>
              </TableBodyRow>,
              )}
            </TableBody>
          </Table>
          <BodyTextBlock variant="medium">Offer applies to Luxury Escapes Tours only. Code can only be used once per customer. <TextLink onClick={onClose} to="/promotions" weight="regular">Terms & conditions apply.</TextLink></BodyTextBlock>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
  </ModalBase>
}

export default TourSpendEarnDetailModal
