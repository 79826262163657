import requestUtils from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-business'
import { getBatchPlaceById } from 'api/search'
import { isNonNullable } from 'lib/array/arrayUtils'

type CalculatedBusinessBudgetServerResponse = paths['/api/business-traveller/businesses/{businessId}/calculated-budget']['get']['responses'][200]['content']['application/json']

function mapCalculatedBusinessBudget(serverData: CalculatedBusinessBudgetServerResponse['result'], lePlaces: Array<App.Place>): App.BusinessTraveller.BusinessBudgetInfo {
  const mappedBudget: App.BusinessTraveller.BusinessBudgetInfo = {
    maxNightlyBudget: serverData.maxNightlyBudget,
    minNightlyBudget: serverData.minNightlyBudget,
    budgets: serverData.budgets.map(budget => ({
      id: budget.id,
      budget: budget.budget,
      placeId: budget.placeId,
      placeType: budget.placeType,
      currencyCode: budget.currencyCode,
      regionId: budget.regionId,
      countryId: budget.countryId,
      stateId: budget.stateId,
      name: lePlaces.find(place => place.id === budget.placeId)?.name ?? '',
    })),
  }
  return mappedBudget
}

interface Params {
  businessId: string;
  toBeCalculatedBusinessBudget: App.BusinessTraveller.ToBeCalculatedBusinessBudget
}

async function requestGetCalculatedBusinessBudget(params: Params) {
  const { businessId, toBeCalculatedBusinessBudget } = params
  const { currencyCode, lePlaceId, longitude, latitude } = toBeCalculatedBusinessBudget

  try {
    let urlParams = `?currencyCode=${currencyCode}`
    if (lePlaceId) {
      urlParams += `&lePlaceId=${lePlaceId}`
    }
    if (longitude && latitude) {
      urlParams += `&longitude=${longitude}&latitude=${latitude}`
    }

    const budgetData = await requestUtils.get<CalculatedBusinessBudgetServerResponse>(
      `/api/business-traveller/businesses/${businessId}/calculated-budget${urlParams}`,
      { credentials: 'include' },
    )
    const lePlaces = (await getBatchPlaceById(budgetData.result.budgets.map(budget => budget.placeId).filter(isNonNullable)))
    return mapCalculatedBusinessBudget(budgetData.result, lePlaces)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default requestGetCalculatedBusinessBudget
