import {
  Middleware,
} from 'redux'
import apiMiddleware from 'middlewares/apiMiddleware'
import rootReducer from './reducers'
import { History, LocationState } from 'history'
import { configureStore } from '@reduxjs/toolkit'
import config from 'constants/config'
import { CallHistoryMethodAction } from 'connected-react-router'
import { AppAction } from 'actions/ActionTypes'
import { actionSanitisers, stateSanitisers } from './storeSanitise'

interface StoreOptions {
  apiMiddleware?: boolean;
  devTools?: boolean;
}

export default function buildStore(
  history: History<LocationState>,
  initialState?: Partial<App.State>,
  middlewares: Array<Middleware> = [],
  options: StoreOptions = {
    apiMiddleware: true,
    devTools: true,
  },
) {
  return configureStore({
    reducer: rootReducer(history),
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers({ autoBatch: true })
    },
    middleware: (getDefaultMiddleware) => {
      const defaults = getDefaultMiddleware({
        thunk: true,
        serializableCheck: false,
        immutableCheck: config.REDUX_IMMUTABILITY_CHECK_ENABLED,
      })

      middlewares.forEach(mid => {
        defaults.push(mid)
      })

      if (options.apiMiddleware) {
        defaults.push(apiMiddleware)
      }

      return defaults
    },
    devTools: options.devTools ? {
      autoPause: true,
      actionSanitizer: (action, idNumber) => actionSanitisers.reduce((action, sanitiser) => (
        sanitiser.enabled ? sanitiser.sanitise(action, idNumber) : action
      ), action),
      stateSanitizer: (state: any, index: number) => stateSanitisers.reduce((state, sanitiser) => (
        sanitiser.enabled ? sanitiser.sanitise(state, index) : state
      ), state),
    } : false,
    preloadedState: initialState as App.State,
  })
}

export type AppStore = ReturnType<typeof buildStore>
type Dispatchable = AppAction<Parameters<AppStore['dispatch']>[0]> | CallHistoryMethodAction
export type AppDispatch = (action: Dispatchable, ...extraArgs: Array<any>) => any;
