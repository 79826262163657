import config from 'constants/config'
import {
  KLARNA_PAYMENT_TYPE,
  KLARNA_BP_PAYMENT_TYPE,
  MIN_KLARNA_PAYMENT,
  MAX_KLARNA_PAYMENT,
  MIN_KLARNA_BP_GBP_PAYMENT,
  MIN_KLARNA_BP_NZD_PAYMENT,
  MIN_KLARNA_BP_USD_PAYMENT,
  MAX_KLARNA_BP_GBP_PAYMENT,
  MAX_KLARNA_BP_NZD_PAYMENT,
  MAX_KLARNA_BP_USD_PAYMENT,
  BRAINTREE_PAYMENT_TYPE,
  paymentMethodLabels,
  APPLE_PAY_PAYMENT_TYPE,
  BASE_MERCHANT_FEE,
  GOOGLE_PAY_PAYMENT_TYPE,
  GO_CARDLESS_PAYMENT_TYPE,
  STRIPE_3DS_PAYMENT_TYPE,
  STRIPE_3DS_V2_PAYMENT_TYPE,
  STRIPE_PAYMENT_TYPE,
  STRIPE_CUSTOM_PAYTO_TYPE,
  STRIPE_PAYMENT_ELEMENT_CARD_TYPE,
  STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE,
  SECURE_PAYMENT_TYPE,
  STRIPE_PAYMENT_ELEMENT_BUSINESS_CARD_TYPE,
  SHORT_LEAD_TIME_WITH_CREDIT_MODAL_LEAD_TIME_DAYS,
  SHORT_LEAD_TIME_WITH_CREDIT_MODAL_PERCENTAGE_THRESHOLD,
  PAYPAL_PAYMENT_TYPE,
  DEBIT_CARD_MERCHANT_FEE,
} from 'constants/payment'
import getPaymentMethods from 'lib/payment/getPaymentMethods'
import { isAfterPayBpEnabled, isWithinAfterPayBpAvailabilityRange } from 'lib/payment/afterPayBpUtils'
import { isHoolahBpEnabled, isWithinHoolahBpAvailabilityRange } from 'lib/payment/hoolahBpUtils'
import { isAtomeBpEnabled, isWithinAtomeBpAvailabilityRange } from 'lib/payment/atomeBpUtils'
import { isWaaveBpEnabled, isWithinWaaveBpAvailabilityRange } from 'lib/payment/waaveBpUtils'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { PaymentMethodRowData } from 'checkout/Components/CheckoutSteps/Payment/PurchaseBox/PaymentOptions/common'
import moment from 'moment'
import { calculateDaysBetweenDates } from './payInInstalmentsUtils'
import { sum } from 'lib/array/arrayUtils'
import { isBedbankItem, isCarHireItem, isCruiseItem, isExperienceItem, isFlightItem, isInstantBookingLEHotelItem, isTourItem, isTourV2ExperienceItem, isTransferItem, isVillaItem } from 'lib/checkout/checkoutUtils'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { getOptimizelyFeatureFlagVariation } from 'lib/optimizely/optimizelyUtils'

export const isKlarnaEnabled = (currency: string): boolean =>
  !!(config.KLARNA_ENABLED && getPaymentMethods(currency).includes(KLARNA_PAYMENT_TYPE))

export const isWithinKlarnaAvailabilityRange = (amount: number): boolean =>
  amount >= MIN_KLARNA_PAYMENT && amount <= MAX_KLARNA_PAYMENT

export const isKlarnaBpEnabled = (currency: string): boolean => {
  return config.KLARNA_BP_CURRENCIES ? isEnabledForFeature(config.KLARNA_BP_CURRENCIES, currency) && getPaymentMethods(currency).includes(KLARNA_BP_PAYMENT_TYPE) : false
}

export const isWithinKlarnaBpAvailabilityRange = (amount: number, currency:string): boolean => {
  switch (currency) {
    case 'NZD':
      return (amount >= MIN_KLARNA_BP_NZD_PAYMENT && amount <= MAX_KLARNA_BP_NZD_PAYMENT)
    case 'USD':
      return (amount >= MIN_KLARNA_BP_USD_PAYMENT && amount <= MAX_KLARNA_BP_USD_PAYMENT)
    case 'GBP':
      return (amount >= MIN_KLARNA_BP_GBP_PAYMENT && amount <= MAX_KLARNA_BP_GBP_PAYMENT)
    default:
      return false
  }
}

export const getKlarnaBpMaxLimit = (currency:string) :number => {
  switch (currency) {
    case 'NZD':
      return MAX_KLARNA_BP_NZD_PAYMENT
    case 'USD':
      return MAX_KLARNA_BP_USD_PAYMENT
    case 'GBP':
      return MAX_KLARNA_BP_GBP_PAYMENT
    default:
      return 0
  }
}

export function calculateInstalments(amount: number, instalments: number): number {
  return Math.ceil(parseFloat(((amount / instalments) * 100).toFixed(3))) / 100
}

export function isBraintreeEnabled(state: App.State, currency: string, isSpoofed: boolean) {
  return getPaymentMethods(currency).includes(BRAINTREE_PAYMENT_TYPE) && !isPayPalEnabled(state, currency, isSpoofed)
}

export function isPayPalEnabled(state: App.State, currency: string, isSpoofed: boolean) {
  return config.PAYPAL_ENABLED && getPaymentMethods(currency).includes(PAYPAL_PAYMENT_TYPE) && (getOptimizelyFeatureFlagVariation(state, OptimizelyFeatureFlags.paypalTargetedRelease) || isSpoofed)
}

export function isStripePaymentElementBusinessCardEnabled(currency: string) {
  return config.STRIPE_PAYMENT_ELEMENT_BUSINESS_CARD_ENABLED && getPaymentMethods(currency).includes(STRIPE_PAYMENT_ELEMENT_BUSINESS_CARD_TYPE)
}

export function getBNPLMethodAvailability(currencyCode: string, ...prices: Array<number>) {
  return {
    isKlarnaAvailable: isKlarnaEnabled(currencyCode) && prices.some(price => isWithinKlarnaAvailabilityRange(price)),
    isKlarnaBpAvailable: isKlarnaBpEnabled(currencyCode) && prices.some(price => isWithinKlarnaBpAvailabilityRange(price, currencyCode)),
    isAfterPayBpAvailable: isAfterPayBpEnabled(currencyCode) && prices.some(price => isWithinAfterPayBpAvailabilityRange(price, currencyCode)),
    isHoolahBpAvailable: isHoolahBpEnabled(currencyCode) && prices.some(price => isWithinHoolahBpAvailabilityRange(price, currencyCode)),
    isAtomeBpAvailable: isAtomeBpEnabled(currencyCode) && prices.some(price => isWithinAtomeBpAvailabilityRange(price, currencyCode)),
    isWaaveBpAvailable: isWaaveBpEnabled(currencyCode) && prices.some(price => isWithinWaaveBpAvailabilityRange(price, currencyCode)),
  }
}

export function isWithInStartDateRange(startDate: string, eligibilityRangeInDays: number) {
  if (!startDate) return true

  const currentDate = Date.now()
  const eligibilityRange = eligibilityRangeInDays * 24 * 60 * 60 * 1000

  const startDateInIsoFormat = new Date(startDate).toISOString()
  const startDateInMiliseonds = Date.parse(startDateInIsoFormat)

  return currentDate + eligibilityRange < startDateInMiliseonds
}

export function paymentMethodsToString(paymentMethods: Array<string> = []): string {
  const s = new Set<string>()
  paymentMethods.forEach(method => s.add(paymentMethodLabels[method] || method))
  const arr = Array.from(s)
  const formatter = new Intl.ListFormat('en', { type: 'disjunction' })
  return `This promo code applies to ${formatter.format(arr)}`
}

const merchantFeePaymentTypes: Array<App.MerchantFeePaymentType> = ['visa', 'mastercard', 'amex']
export function isCardAcceptedMerchantFeePaymentType(brand: string): brand is App.MerchantFeePaymentType {
  return merchantFeePaymentTypes.includes(brand as App.MerchantFeePaymentType)
}

export function calculateMerchantFeeForOrder(merchantFeeDetails: Array<App.MerchantFeeDetails>): number {
  if (!merchantFeeDetails) return 0
  return merchantFeeDetails.reduce((total, merchantFeeItem) => {
    return total + parseFloat(merchantFeeItem.amount)
  }, 0)
}

export function getMerchantFeeByOrderItem(merchantFeeDetails: Array<App.MerchantFeeDetails>, itemId: string): App.MerchantFeeDetails | null {
  const merchantFeeItem = merchantFeeDetails?.find(item => item.fkItem === itemId)
  return merchantFeeItem || null
}

function merchantFeeCaption(pspName: string, isGooglePayAvailable: boolean, isApplePayAvailable: boolean, isDebitCardMerchantFeesEnabled: boolean) {
  let walletAvailability = ''
  if (isGooglePayAvailable) {
    walletAvailability += ' and Google Pay'
  } else if (isApplePayAvailable) {
    walletAvailability += ' and Apple Pay'
  }

  switch (pspName) {
    case STRIPE_3DS_PAYMENT_TYPE:
    case STRIPE_PAYMENT_TYPE:
    case STRIPE_3DS_V2_PAYMENT_TYPE:
      return `Payment fee from ${BASE_MERCHANT_FEE}%`
    case BRAINTREE_PAYMENT_TYPE:
    case PAYPAL_PAYMENT_TYPE:
    case APPLE_PAY_PAYMENT_TYPE:
    case GOOGLE_PAY_PAYMENT_TYPE:
    case STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE:
      return `Payment fee of ${BASE_MERCHANT_FEE}%`
    case GO_CARDLESS_PAYMENT_TYPE:
      return 'No Payment fee; transfer directly from your bank account'
    case STRIPE_CUSTOM_PAYTO_TYPE:
      return 'No Payment fee; Pay quickly and directly from your bank account with PayTo'
    case STRIPE_PAYMENT_ELEMENT_CARD_TYPE:
      if (isDebitCardMerchantFeesEnabled) {
        return `Debit card fees from ${DEBIT_CARD_MERCHANT_FEE}%, Credit card${walletAvailability} fees from ${BASE_MERCHANT_FEE}%`
      }
      return `No Debit card fee, Credit card${walletAvailability} fees from ${BASE_MERCHANT_FEE}%`
    case STRIPE_PAYMENT_ELEMENT_BUSINESS_CARD_TYPE:
      return `Billed to company. No Debit card fee, Credit card${walletAvailability} fees from ${BASE_MERCHANT_FEE}%`
    case SECURE_PAYMENT_TYPE:
      return 'Generate a payment link to receive payment securely over the phone'
    default:
      return null
  }
}

export function editPaymentMethodCaptions(paymentMethods: Array<PaymentMethodRowData>, isMerchantFeeAvailable: boolean, isStandaloneLuxPlusSubscription: boolean, isGooglePayAvailable: boolean, isApplePayAvailable: boolean, isLebtMerchantFeesEnabled: boolean = false) : Array<PaymentMethodRowData> {
  // No Merchant fees on LuxPLus
  if (!isMerchantFeeAvailable || isStandaloneLuxPlusSubscription) return paymentMethods

  if (config.businessTraveller.currentAccountMode === 'business' &&
    !isLebtMerchantFeesEnabled) {
    return paymentMethods
  }

  const paymentMethodsWithUpdatedLabels = paymentMethods.map((paymentMethod) => {
    const isDebitCardMerchantFeesEnabled = !!useOptimizelyExperiment(OptimizelyExperiments.paymentsDebitCardMerchantFees)
    return { ...paymentMethod, subLabel: (merchantFeeCaption(paymentMethod.name, isGooglePayAvailable, isApplePayAvailable, isDebitCardMerchantFeesEnabled) || paymentMethod.subLabel) }
  })

  return paymentMethodsWithUpdatedLabels
}

export function isStripePaymentCardV2(card: App.StripePaymentCard | App.StripePaymentCardV2): card is App.StripePaymentCardV2 {
  return 'funding' in card
}

export function shouldBlockShortLeadTimeWithCredit(payments: Array<App.PaymentInfo>, projectedLeadTimeDays: number): boolean {
  const creditAmount = sum(payments?.filter(payment => payment.type === 'credits') ?? [], (payment) => payment.amount)
  const orderAmount = sum(payments ?? [], (payment) => payment.amount)

  return config.FRAUD_SHORT_LEADTIME_PLUS_CREDITS_BLOCK_ENABLED &&
    !!orderAmount && // when order modification is not free for post purchase
    projectedLeadTimeDays <= SHORT_LEAD_TIME_WITH_CREDIT_MODAL_LEAD_TIME_DAYS &&
    (creditAmount / orderAmount) > SHORT_LEAD_TIME_WITH_CREDIT_MODAL_PERCENTAGE_THRESHOLD
}

export function getProjectedLeadTimeDaysByCartItems(cartItems: Array<App.Checkout.AnyItem>): number {
  const DEFAULT_LEAD_TIME = 999
  const leadTimeList = cartItems.map(cartItem => {
    if (
      isInstantBookingLEHotelItem(cartItem) || // Covers LPC and Flash
      isBedbankItem(cartItem) || // Covers LPP
      isVillaItem(cartItem)
    ) {
      return cartItem.checkIn
    }

    if (
      isCruiseItem(cartItem) ||
      isTourItem(cartItem)
    ) {
      return cartItem.startDate
    }

    if (
      isExperienceItem(cartItem) ||
      isTourV2ExperienceItem(cartItem)
    ) {
      return cartItem.date
    }

    if (
      isFlightItem(cartItem)
    ) {
      return cartItem.flights?.[0]?.departingDate ?? DEFAULT_LEAD_TIME
    }

    if (
      isCarHireItem(cartItem)
    ) {
      return cartItem.pickUpDate
    }

    if (
      isTransferItem(cartItem)
    ) {
      return cartItem.transfer.date
    }

    // Item does not appear to have a date so should assign date with default lead time.
    const today = moment.utc().format('YYYY-MM-DD')
    return moment(today).add(DEFAULT_LEAD_TIME, 'days').endOf('day').format('YYYY-MM-DD')
  }).filter(date => typeof date === 'string' && date).sort() as Array<string>

  if (!leadTimeList.length) {
    return DEFAULT_LEAD_TIME
  }

  return calculateDaysBetweenDates(leadTimeList[0])
}
