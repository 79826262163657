import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { getImageUrl, ResponsiveImageOptions } from 'lib/image/imageUtils'
import TextLink from 'components/Luxkit/TextLink'
import Image from 'components/Common/Image'
import React, { useCallback, useContext } from 'react'
import HostDetailModal from 'components/OfferPage/TourV2/HostDetail/HostDetailModal'
import styled from 'styled-components'
import { rem } from 'polished'
import ModalContext from 'contexts/ModalContext'

const SponsorsHostsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${rem(40)};
  padding: ${rem(16)} 0;
`

const HostContainer = styled(Group)`
  width: ${rem(112)};
`

const HostImageRounded = styled.img`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => props.theme.borderRadius.round};

  width: ${rem(60)};
  height: ${rem(60)};
`

const SponsorImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: ${props => props.theme.borderRadius.S};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  padding: ${rem(8)} ${rem(16)};
  min-height: ${rem(70)};
  min-width: ${rem(110)};
`

interface Props {
  hosts: Array<App.Tours.Host>
  sponsors: Array<App.Tours.Sponsor>
}

export const IMAGE_HOST_CONFIG: ResponsiveImageOptions = {
  width: 100,
  aspectRatio: '1:1',
  gravity: 'center',
  highRes: true,
}

function TourV2HostsSponsors(props: Props) {
  const { hosts, sponsors } = props
  const showModal = useContext(ModalContext)

  const showModalDetailByHost = useCallback((host: App.Tours.Host) => showModal(<HostDetailModal host={host} />), [showModal])

  return <SponsorsHostsContainer>
    {hosts.length > 0 && <Group direction="vertical" gap={16}>
      <BodyText variant="medium" weight="bold" >Hosted by</BodyText>
      <Group direction="horizontal" gap={16} verticalAlign="start">
        {hosts.map((host, index) => (
          <HostContainer key={index} direction="vertical" horizontalAlign="center" verticalAlign="center" gap={8}>
            <HostImageRounded src={getImageUrl(host.svcImageId, IMAGE_HOST_CONFIG)} alt={host.name} />
            <Group direction="vertical" horizontalAlign="center">
              <BodyText align="center" variant="medium" weight="bold">{host.name}</BodyText>
              <TextLink weight="regular" size="small" onClick={() => showModalDetailByHost(host)}>Learn more</TextLink>
            </Group>
          </HostContainer>
        ))}
      </Group>
    </Group>}
    {sponsors.length > 0 && <Group direction="vertical" gap={16}>
      <BodyText variant="medium" weight="bold" >In partnership with</BodyText>
      <Group direction="horizontal" gap={16} wrap="wrap">
        {sponsors.map((sponsor, index) => (<SponsorImageContainer key={index}>
          <Image
            id={sponsor.svcImageId}
            quality="eco"
            width={70}
            alt={sponsor.name}
            aria-hidden="true"
            dpr={2}
          />
        </SponsorImageContainer>))}
      </Group>
    </Group>}
  </SponsorsHostsContainer>
}

export default TourV2HostsSponsors
