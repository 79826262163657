import React, { ComponentProps } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import _Button from './BaseButton'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { LargeButtonTypography, MediumButtonTypography, SmallButtonTypography } from '../Typography/ButtonTypography'

const UnderlineText = styled.span`
  border-bottom: 1px solid currentColor;
`

const StyledBaseButton = styled(_Button)`
  &.kind-primary, &.kind-secondary {
    min-width: ${rem(80)};
  }

  > svg:first-of-type,
  > svg:last-of-type {
    flex-shrink: 0;
    height: var(--button-icon-size, auto);
    width: var(--button-icon-size, auto);
  }

  &.has-icon-start {
    > :first-child {
      margin-right: ${rem(8)};
    }
  }

  &.has-icon-end {
    > :last-child {
      margin-left: ${rem(8)};
    }
  }

  &.size-small {
    --button-size: ${rem(32)};
    --button-icon-size: ${rem(16)};
    --button-padding: ${rem(8)};

    ${SmallButtonTypography}
  }

  &.size-medium {
    --button-size: ${rem(40)};
    --button-icon-size: ${rem(20)};
    --button-padding: ${rem(12)};

    ${MediumButtonTypography}
  }

  &.size-large {
    --button-size: ${rem(48)};
    --button-icon-size: ${rem(24)};
    --button-padding: ${rem(16)};

    ${LargeButtonTypography}
  }

  &.shape-round {
    --button-border-radius: ${props => props.theme.borderRadius.round};
  }

  &.shape-square {
    --button-border-radius: ${props => props.theme.borderRadius.S};
  }

  &.fit-flex {
    display: flex;
    width: 100%;
  }

  &.fit-full-width {
    width: 100%;
  }

  &.fit-mobile-full-width {
    width: 100%;

    ${mediaQueryUp.tablet} {
      width: auto;
    }
  }

  &.fit-desktop-full-width {
    ${mediaQueryUp.tablet} {
      width: 100%;
    }
  }

  &.outdented {
    transform: translate(calc(var(--button-padding) * var(--h-outdent-multiplier, 0)), calc(var(--button-padding) * var(--v-outdent-multiplier, 0)));
  }


  @media print {
    display: none;
  }
`

function determineOutdentMultiplier(outdent: 'start' | 'end' | undefined): number | undefined {
  switch (outdent) {
    case 'start':
      return -1
    case 'end':
      return 1
    default:
      return undefined
  }
}

interface Props extends ComponentProps<typeof _Button> {
  /**
   * Buttons *require* children, it doesn't make sense without having content in the button
   */
  children: React.ReactNode;
  /**
   * Determines how a buttons sizing behaviour will work
   * Supports specifying only for certain breakpoints, e.g. desktop/mobile
   *
   * @default auto
   **/
  fit?: 'auto' | 'full-width' | 'flex' | 'mobile-full-width' | 'desktop-full-width'
  /** An icon element that will go at the start (often the 'left' side) of the button */
  startIcon?: React.ReactElement
  /** An icon element that will go at the end (often the 'right' side) of the button */
  endIcon?: React.ReactElement
  /**
   * Horizontal outdent, or the inverse of indent, negates the inline 'padding' around the button in the chosen direction
   * so its content can visually align with the 'edge'. Mostly used for tertiary buttons that have no border/background.
   * */
  horizontalOutdent?: 'start' | 'end'
  /**
   * Vertical outdent, or the inverse of indent, negates the block 'padding' around the button in the chosen direction
   * so its content can visually align with the 'edge'. Mostly used for tertiary buttons that have no border/background.
   * */
  verticalOutdent?: 'start' | 'end'
  /** The general shape of the button, square will have a minor border radius, round will be fully round */
  shape?: 'round' | 'square'
  /**
   * Determines the height/padding of the button as well as the size of icons if they are provided
   *
   * @default medium
   *  */
  size?: 'small' | 'medium' | 'large'
  /** ID applied to the button to target for testing purposes */
  'data-testid'?: string;
}

/**
 * Primary and secondary kinds will apply a minimum width.
 */
const TextButton = React.forwardRef<HTMLButtonElement, Props>(function TextButton(
  {
    children,
    className,
    fit = 'auto',
    startIcon = null,
    horizontalOutdent,
    verticalOutdent,
    endIcon = null,
    shape = 'square',
    size = 'medium',
    style,
    ...baseButtonProps
  },
  ref,
) {
  const hasAnyIcon = !!(startIcon || endIcon)
  const addUnderline = !hasAnyIcon && baseButtonProps.kind === 'tertiary'

  return <StyledBaseButton
    {...baseButtonProps}
    ref={ref}
    className={cn(
      className,
      `size-${size}`,
      `fit-${fit}`,
      `shape-${shape}`,
      {
        'has-icon-start': !!startIcon,
        'has-icon-end': !!endIcon,
        outdented: horizontalOutdent || verticalOutdent,
      },
    )}
    style={{
      ...style,
      '--h-outdent-multiplier': determineOutdentMultiplier(horizontalOutdent),
      '--v-outdent-multiplier': determineOutdentMultiplier(verticalOutdent),
    }}
  >
    {startIcon}
    {addUnderline && <UnderlineText>{children}</UnderlineText>}
    {!addUnderline && children}
    {endIcon}
  </StyledBaseButton>
})

export default TextButton
