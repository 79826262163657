import React, { useContext } from 'react'
import { pluralizeToString } from 'lib/string/pluralize'
import { EmptyArray } from 'lib/array/arrayUtils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import useCruiseSearchCount from 'hooks/Cruise/useCruiseSearchCount'
import useCruiseSearchFlashOffers from 'hooks/Cruise/useCruiseSearchFlashOffers'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LoadingAnimation from 'components/Common/Loading/LoadingAnimation'

interface Props {
  onClear: () => void;
  onApply: () => void;
  initialFilters?: App.OfferListFilters;
  shouldIgnoreFlashOffers?: boolean;
}

const filters = {}

function CruiseFilterFooter({
  onClear,
  onApply,
  initialFilters,
  shouldIgnoreFlashOffers,
}: Props) {
  const globalFilters = useContext(GlobalSearchStateContext)
  const {
    cruiseLines,
    searchItems,
    secondarySearchItems,
    durationMax,
    durationMin,
    durationRange,
    flexibleMonths,
    checkinDate,
    checkoutDate,
  } = globalFilters

  const [searchCount, fetchingSearchCount] = useCruiseSearchCount({
    cruiseLines,
    destinationIds: searchItems.map(({ value }) => value),
    departureIds: secondarySearchItems.map(({ value }) => value),
    durationMin,
    durationMax,
    durationRange,
    departureMonths: flexibleMonths?.split(',') || EmptyArray,
    ...(!!checkinDate && !!checkoutDate && {
      departureStartDate: checkinDate.format(ISO_DATE_FORMAT),
      departureEndDate: checkoutDate.format(ISO_DATE_FORMAT),
    }),
    ...(initialFilters || {}),
  })

  const flashOffers = useCruiseSearchFlashOffers({
    filters,
    globalFilters,
  })

  const count = shouldIgnoreFlashOffers ? searchCount : searchCount + (flashOffers.offerCount || 0)

  return <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
    <BodyText data-testid="cruise-filter-footer-available-results" variant="medium">
      {!fetchingSearchCount && `${pluralizeToString('cruise', count)} available`}
      {fetchingSearchCount && <LoadingAnimation size={8} />}
    </BodyText>

    <Group direction="horizontal" horizontalAlign="end" gap={16}>
      <TextButton
        kind="tertiary"
        onClick={onClear}
        data-testid="cruise-filter-footer-clear-all"
      >
        Reset all
      </TextButton>
      <TextButton
        kind="primary"
        onClick={onApply}
        data-testid="cruise-filter-footer-apply"
      >
        Apply
      </TextButton>
    </Group>
  </Group>
}

export default CruiseFilterFooter
