import { createSelector } from 'reselect'
import { floatify } from 'lib/maths/mathUtils'
import getDepositAmountBeforeCreditApplied from './getDepositAmountBeforeCreditApplied'
import getPayableTotal from './getPayableTotal'
import getDiscountTotal from './getDiscountTotal'

const getDepositRemainingBeforeCreditApplied = createSelector(
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => getDiscountTotal(state),
  (state: App.State) => getDepositAmountBeforeCreditApplied(state),
  (total, discount, deposit): number => {
    return floatify(total - discount - deposit)
  },
)

export default getDepositRemainingBeforeCreditApplied
