import { getLuxPlusSubscriptionItemView } from 'checkout/selectors/view/luxPlusSubscription'
import { EmptyArray } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'

const getCheckoutLuxPlusPointsCalculationRequests = createSelector(
  (state: App.State) => getLuxPlusSubscriptionItemView(state),
  (luxPlusSubscriptionItemView): Array<App.LuxLoyaltyPointsEarnCalculationRequest> => {
    if (!luxPlusSubscriptionItemView.hasRequiredData) return EmptyArray

    return luxPlusSubscriptionItemView.data.map((itemView) => {
      return {
        price: itemView.totals.price,
        luxLoyaltyProductType: 'lux_plus',
        itemId: itemView.item.itemId,
      }
    })
  },
)

export default getCheckoutLuxPlusPointsCalculationRequests
