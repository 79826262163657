import React, { useEffect, useMemo } from 'react'
import { HOTEL_SEARCH_TYPEAHEAD_TYPES, LOCATION_SEARCH_ERROR_MESSAGE } from 'constants/search'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import BodyText from 'components/Luxkit/Typography/BodyText'
import SearchMenuListItem from '../SearchForm/SearchMenu/SearchMenuListItem'
import { usePlacesBySearchPhrase } from 'hooks/usePlace'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import styled from 'styled-components'
import { take } from 'lib/array/arrayUtils'
import clsx from 'clsx'
import ListItem from 'components/Luxkit/List/ListItem'
import ListSubheader from 'components/Luxkit/List/ListSubheader'
import { connect } from 'react-redux'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { pluralizeToString } from '../../../lib/string/pluralize'

const ResultContainer = styled.div`
  position: relative;
  min-height: ${rem(80)};
`

const Separator = styled.div`
  margin-top: ${rem(24)};
`

const ItemContainer = styled.div`
  &.horizontal {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.useImage {
    display: flex;
    column-gap: ${rem(40)};
    flex-wrap: wrap;
  }
`
interface MappedProps {
  canViewLuxPlusBenefits: boolean;
}

interface Props extends MappedProps{
  className?: string;
  searchPhrase?: string;
  searchTypes?: Array<App.SearchType>;
  placeTypes?: Array<App.SearchPlaceType>;
  onChange?: (item: App.SearchItem) => void;
  onResultsChange?: (results: Array<App.SearchItem>, searchPhrase: string) => void;
  vertical?: App.SearchPlaceVertical,
  overrideSearchItems?: Array<App.SearchItem>;
  selectedDropdown?: number;
  showHorizontalLayout?: boolean;
  useImage?: boolean;
}

function SearchPlaceSelectList(props: Props) {
  const {
    className,
    searchPhrase = '',
    onChange = noop,
    placeTypes,
    searchTypes,
    onResultsChange = noop,
    overrideSearchItems,
    vertical,
    selectedDropdown,
    showHorizontalLayout = false,
    canViewLuxPlusBenefits,
    useImage,
  } = props
  const isHotelSearch = placeTypes == HOTEL_SEARCH_TYPEAHEAD_TYPES
  const priority = isHotelSearch ? 'next' : 'current'
  const limit = vertical === 'tour' ? 10 : 9
  const [searchResults, loading] = usePlacesBySearchPhrase(searchPhrase, {
    disabled: !!overrideSearchItems,
    placeTypes,
    searchTypes,
    vertical,
    priority,
    limit,
    canViewLuxPlusBenefits,
  })

  useEffect(() => {
    onResultsChange(searchResults, searchPhrase)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults])

  const tourResults = useMemo(() => {
    if (!overrideSearchItems) {
      return searchResults.filter(result => result.searchType === 'tour')
    }
    return overrideSearchItems.filter(result => result.searchType === 'tour')
  }, [overrideSearchItems, searchResults])

  const searchResultAmount = (() => {
    if (useImage) {
      return 9
    }

    if (vertical === 'tour' || showHorizontalLayout) {
      return 6
    }
    return 8
  })()

  const finalResults = useMemo(() => {
    if (!overrideSearchItems) {
      return take(searchResults.filter(result => result.searchType !== 'tour'), searchResultAmount)
    }
    return overrideSearchItems.filter(result => {
      const normalisedSearch = searchPhrase.toLowerCase()
      return result.format.mainText.toLowerCase().includes(normalisedSearch) ||
        result.format.secondaryText?.toLowerCase().includes(normalisedSearch)
    })
  }, [overrideSearchItems, searchPhrase, searchResultAmount, searchResults])

  return <ResultContainer className={className}>
    <LoadingIndicator visible={loading} opaque floating>
      <BodyText variant="large" weight="bold" colour="neutral-one">Loading</BodyText>
    </LoadingIndicator>
    {searchPhrase.length > 0 && finalResults.length === 0 && tourResults.length === 0 && !loading && <ListItem
      nonInteractable
      title={<>Sorry, we couldn't find <b>{searchPhrase}</b>.</>}
      subtitle={LOCATION_SEARCH_ERROR_MESSAGE}
    />}
    {searchPhrase.length > 0 && <ItemContainer className={clsx({ horizontal: showHorizontalLayout, useImage })}>
      {finalResults.map((searchItem, index) => <SearchMenuListItem
        key={`${searchItem.value}_${index}`}
        searchItem={searchItem}
        onClick={onChange}
        searchPhrase={searchPhrase}
        selected={index === selectedDropdown}
        horizontal={showHorizontalLayout}
        searchVertical={vertical}
        imageId={searchItem.format.imageId}
        useImage={useImage}
      />)}
    </ItemContainer>}
    {tourResults.length > 0 && <Separator>
      <ListSubheader>
        Related {pluralizeToString('tour', tourResults.length)}
      </ListSubheader>
      <ItemContainer className={clsx({ horizontal: showHorizontalLayout })}>
        {tourResults.map((searchItem, index) => <SearchMenuListItem
          key={`${searchItem.value}_${index}`}
          searchItem={searchItem}
          onClick={onChange}
          searchPhrase={searchPhrase}
        />)}
      </ItemContainer>
    </Separator>}
  </ResultContainer>
}
const mapStateToProps = (state: App.State): MappedProps => ({
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
})

export default connect(mapStateToProps)(SearchPlaceSelectList)
