import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineBellIcon from 'components/Luxkit/Icons/line/LineBellIcon'
import Caption from 'components/Luxkit/Typography/Caption'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import useToggle from 'hooks/useToggle'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import HeaderNotificationItem from './HeaderNotificationItem'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { viewAllUserNotifications } from 'actions/UserNotificationActions'
import ModalContext from 'contexts/ModalContext'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import useUserNotifications from 'hooks/Notifications/useUserNotifications'

const StyledNotificationIconButton = styled(IconButton)`
  position: relative;
`

const RedDotOnSignUp = styled(Caption)`
  position: absolute;
  top: ${rem(2)};
  right: ${rem(2)};
  padding: ${rem(2)} ${rem(4)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.messaging.critical.normalBackground};
  color: ${props => props.theme.palette.messaging.critical.normalContrast};
`

const EmptyState = styled(Group)`
  padding: ${rem(64)} ${rem(20)};
`

function HeaderNotification() {
  const [isOpen, toggleMenu, _, off] = useToggle()
  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)

  const isMobileScreen = useScreenSizeOnly('mobile')
  const [notifications] = useUserNotifications()
  const newNotifications = useMemo(() => notifications.filter(item => !item.viewed).length, [notifications])
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isSubscribed = useAppSelector(state => state.leSubscriptions.settings.app_push_subscribed)

  const Content = useMemo(() => (
    <>
      {!notifications.length && <EmptyState direction="vertical" gap={8} horizontalAlign="center">
        <LineBellIcon />
        <Group direction="vertical" horizontalAlign="center">
          <BodyText variant="large" weight="bold">No new notifications</BodyText>
          {isSubscribed && <BodyText variant="medium">We’ll let you know when updates arrive.</BodyText>}
        </Group>
      </EmptyState>}
      {!!notifications.length && <Group direction="vertical">
        {notifications.map(notification => <HeaderNotificationItem
          key={notification.id}
          notification={notification}
        />)}
      </Group>}
    </>
  ), [isSubscribed, notifications])

  const closeMenu = useCallback(() => {
    off()
    dispatch(viewAllUserNotifications())
  }, [dispatch, off])

  const onNotificationsClick = useCallback(async() => {
    if (isMobileScreen) {
      // Designers fudged the padding on the items, so we'll make the
      // the modal paddingless until we get it aligned properly
      await showModal(<ModalBase height="full" >
        <ModalHeader title="Notifications" />
        <ModalBody>
          {Content}
        </ModalBody>
      </ModalBase>)
      closeMenu()
    } else {
      toggleMenu()
    }
  }, [Content, closeMenu, isMobileScreen, showModal, toggleMenu])

  return (
    <>
      <StyledNotificationIconButton
        ref={buttonRef}
        kind="tertiary"
        variant="default"
        role="menuitem"
        onClick={onNotificationsClick}
      >
        <LineBellIcon />
        {!!newNotifications && <RedDotOnSignUp variant="small">
          {newNotifications > 9 ? '9+' : newNotifications}
        </RedDotOnSignUp>}
      </StyledNotificationIconButton>
      <DropdownList
        open={isOpen && !isMobileScreen}
        size="M"
        width="min"
        anchorRef={buttonRef}
        triggerRef={buttonRef}
        placement="bottom-end"
        onClose={closeMenu}
      >
        {Content}
      </DropdownList>
    </>
  )
}

export default HeaderNotification
