import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'

function facilityValueMap(facility: PublicOfferV2.BedbankPackage['amenityGroups'][0]['values'][0]): App.FacilityValue {
  return {
    name: facility.name,
  }
}

export default function facilityGroupsMap(group: PublicOfferV2.BedbankPackage['amenityGroups'][0]): App.FacilityGroup {
  return {
    name: group.name,
    values: group.values.map(facilityValueMap),
  }
}
