import { AppAction } from 'actions/ActionTypes'
import { API_CALL } from 'actions/actionConstants'
import createBatchedPromise from 'api/createBatchedPromise'
import { BUSINESS_TRAVELLER_API_ACTIONS } from 'reducers/businessTravellerApiActionReducers'
import requestPostBatchCalculateBusinessCredits from 'businessTraveller/api/requestPostBatchCalculateBusinessCredits'

const requestCalculateBusinessCredits = createBatchedPromise({
  argsReducerFn: (
    accumulatedArgs: Record<string, App.BusinessTraveller.ToBeCalculatedBusinessCreditItem>,
    singleArg: [creditItemKey: string, creditItem: App.BusinessTraveller.ToBeCalculatedBusinessCreditItem],
  ) => {
    const [creditItemKey, creditItem] = singleArg
    return {
      ...accumulatedArgs,
      [creditItemKey]: creditItem,
    }
  },
  batchRequestFn: (batchedArgs, businessId: string) => {
    return requestPostBatchCalculateBusinessCredits(
      { businessId },
      { creditItems: batchedArgs },
    )
  },
  singleValueFn: (singleArg, batchedResponse) => {
    const [creditItemKey] = singleArg
    return batchedResponse[creditItemKey]
  },
})

interface Params {
  refetch?: boolean
}

function fetchCalculatedBusinessCredits(
  toBeCalculatedBusinessCreditItem: [creditItemKey: string, creditItem: App.BusinessTraveller.ToBeCalculatedBusinessCreditItem],
  businessId: string,
  params?: Params,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const [creditItemKey] = toBeCalculatedBusinessCreditItem
    const currentOfferCredits = state.businessTraveller.offersCredits[creditItemKey]

    if (!currentOfferCredits || params?.refetch) {
      dispatch({
        type: API_CALL,
        api: BUSINESS_TRAVELLER_API_ACTIONS.FETCH_OFFER_CREDITS,
        creditItemKey,
        request: () => requestCalculateBusinessCredits(toBeCalculatedBusinessCreditItem, businessId),
      })
    }
  }
}

export default fetchCalculatedBusinessCredits
