import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import OfferList, { OfferListAction, OfferListAndAction, OfferListV2Item } from 'components/OfferList/OfferList'
import useOfferList from 'hooks/Offers/useOfferList'
import { useAppSelector } from 'hooks/reduxHooks'
import { isBedbankOffer } from 'lib/offer/offerTypes'
import { pluralizeToString } from 'lib/string/pluralize'
import React, { useMemo } from 'react'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'

interface Props {
  filters: App.OfferListFilters;
  showTextHeading?: boolean;
}

function HotelSearchNearbyPropertyOfferList(props: Props) {
  const { filters, showTextHeading = true } = props

  const showSlimMobile = useSlimMobileSearch()
  const hotelList = useOfferList(filters)

  const propertyOffer = useAppSelector(state => state.offer.offers[hotelList.offerIds[0]] || state.offer.bedbankOffers[hotelList.offerIds[0]])
  const propertyOfferLoading = useAppSelector(state => state.offer.offersLoading[hotelList.offerIds[0]])

  const nearbyFilters = useMemo(() => ({ ...filters, searchNearby: true }), [filters])
  const nearbyOfferList = useOfferList(nearbyFilters)

  const propertyLocation = useMemo(() => {
    if (propertyOffer) {
      return isBedbankOffer(propertyOffer) ? propertyOffer.property.address.city : propertyOffer.property.geoData.locality
    }
  }, [propertyOffer])

  const overrideNearbyOfferList = useMemo<OfferListAndAction>(() => ({
    action: OfferListAction.OVERRIDE,
    offerList: nearbyOfferList,
  }), [nearbyOfferList])

  const extras: Array<OfferListV2Item> = []
  if (showTextHeading) {
    extras.push({
      id: 'nearby-heading',
      position: 'start',
      element: <>
        {!propertyOffer && !propertyOfferLoading && <MessageBanner align="center" icon={<LineInfoCircleIcon />} kind="informational-primary" description="This offer is no longer available" />}
        <Heading variant="heading4">
          {pluralizeToString('similar offer', nearbyOfferList.offerIds.length)}{' '}
          found{propertyLocation ? ` near ${propertyLocation}` : ' nearby'}
        </Heading>
      </>,
      show: 'hasOffers',
    })
  }

  return <OfferList
    fullWidth={showSlimMobile}
    interstitials={extras}
    filters={nearbyFilters}
    tileStyle="search"
    extraOfferListAndAction={overrideNearbyOfferList}
  />
}

export default HotelSearchNearbyPropertyOfferList
