import { QueryClient, QueryKey, UseQueryResult } from '@tanstack/react-query'
import * as TripMetadataKeys from '../reactQueryKeys/tripMetadata'
import * as TripKeys from '../reactQueryKeys/trips'
import * as OrderKeys from '../reactQueryKeys/orders'
import * as UserSettingsKeys from '../reactQueryKeys/userSettings'

export type OfferTypeForTripItemTypes =
| App.Offer
| App.OfferSummary
| App.Tours.TourV2Offer
| App.Tours.TourV2OfferSummary
| App.BedbankOffer
| App.BedbankOfferSummary
| App.CruiseOffer
| App.ExperienceOffer

// To make multi-query results a bit nicer to work with. Add new fields as needed.
export type MultiQueryResults<T> = {
  data: Array<T>
  isLoading: boolean
  isSuccess: boolean
  results: Array<UseQueryResult<T>>
}

export const MINUTES = 60 * 1000
export const NON_TRIP_STALE_TIME = 4 * MINUTES
export const TRIP_STALE_TIME = 4 * MINUTES/**

/** Invalidate a query and also cancel any in-progress fetch for that query.
  * If we invalidate a query while a fetch is in progress, the query cache
  * will be written with potentially outdated data after the invalidation
  * has already been done.
  */
export function cancelAndInvalidate(
  queryClient: QueryClient,
  queryKey: QueryKey,
) {
  queryClient.cancelQueries(queryKey)
  queryClient.invalidateQueries(queryKey)
}

export function invalidateTrip(queryClient: QueryClient, tripId: string) {
  cancelAndInvalidate(queryClient, TripKeys.detail(tripId))
  cancelAndInvalidate(queryClient, TripKeys.summary(tripId))
  cancelAndInvalidate(queryClient, TripMetadataKeys.metadata(tripId))
  cancelAndInvalidate(queryClient, TripKeys.collaborator(tripId))
  cancelAndInvalidate(queryClient, TripKeys.lists)
  cancelAndInvalidate(queryClient, TripKeys.tripItemsList)
}

export function removeTripQueries(queryClient: QueryClient, tripId: string) {
  queryClient.removeQueries(TripKeys.detail(tripId))
  queryClient.removeQueries(TripKeys.summary(tripId))
  queryClient.removeQueries(TripMetadataKeys.metadata(tripId))
  queryClient.removeQueries(TripKeys.collaborator(tripId))
  queryClient.removeQueries(TripKeys.tripItemsList)
  queryClient.invalidateQueries(TripKeys.lists)
}

export function removeAuthedQueries(queryClient: QueryClient) {
  queryClient.removeQueries(TripKeys.all)
  queryClient.removeQueries(OrderKeys.all)
  queryClient.removeQueries(UserSettingsKeys.all)
}
