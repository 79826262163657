import { createSelector } from 'reselect'
import { getIsLuxLoyaltyEnabled } from './luxLoyaltyFeatureToggles'

const getLoyaltyRoomUpgradeBenefit = createSelector(
  (state: App.State) => getIsLuxLoyaltyEnabled(state),
  (state: App.State) => state.luxLoyalty.account.data?.benefits,
  (
    isLuxLoyaltyEnabled,
    benefits,
  ): App.LuxLoyaltyBenefit | undefined => {
    if (!isLuxLoyaltyEnabled) return
    return benefits?.find(benefit => benefit.code === 'hotel_upgrade')
  },
)

export default getLoyaltyRoomUpgradeBenefit
