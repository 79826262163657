import { min } from 'lib/array/arrayUtils'

export function getLowestPricePackage(
  offer: App.Offer | App.BundledOffer | App.OfferSummary,
  offerPackages: Array<App.HotelPackage>,
  ignoreDefaultOptions = false,
) {
  let packages = ignoreDefaultOptions ? offerPackages : offerPackages.filter(x => offer.defaultOptions.includes(x.uniqueKey))
  packages = packages.length > 0 ? packages : offerPackages
  return min(packages, pkg => (pkg.memberPrice || pkg.price) + (pkg.surcharge ?? 0) || Number.MAX_SAFE_INTEGER)
}
