import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import sanitizeItemContextMetadata from './sanitizeItemContextMetadata'

function getLuxPlusOrderItems(order: App.Order, isMultiCart?: boolean) {
  return order.subscriptionItems.map(orderItem => {
    return comluxgroup.createItem_1_1_0(
      {
        offerId: orderItem.subscriptionOfferId,
        categoryId: 'luxury_plus',
        productId: 'luxury_plus',
        itemId: orderItem.id,
        currency: order.currencyCode,
        price: orderItem.total,
        value: orderItem.total,
        offerLeadPrice: orderItem.total,
        bookingNumber: order.bookingNumber,
        orderId: order.id,
        metadata: sanitizeItemContextMetadata({
          isMultiCart,
        }),
      },
    )
  })
}

export default getLuxPlusOrderItems
