import React, { useCallback, useMemo } from 'react'
import { escapeRegex } from 'lib/string/regexUtils'
import Image from 'components/Common/Image'
import ListItem from 'components/Luxkit/List/ListItem'
import CheckboxButton from 'components/Luxkit/Checkbox/CheckboxButton'

function getSearchableTextBold(text: string, searchTerm?: string) {
  if (!searchTerm) return text
  const search = new RegExp(`(${escapeRegex(searchTerm)})`, 'ig')
  const bits = text.split(search)

  return <>
    {bits.map((textBit, index) => textBit.toLowerCase() === searchTerm.toLowerCase() ? <b key={index}>{textBit}</b> : textBit, '')}
  </>
}

interface Props {
  searchItem: App.SearchItem;
  imageId?: string;
  searchTerm?: string;
  isSelected: boolean;
  isDisabled: boolean;
  isSearching: boolean;
  hideImages?: boolean;
  onChange: (value: App.SearchItem | null, isRemoving?: boolean) => void;
}

function CruiseLocationItem({ searchItem, imageId, searchTerm, isSelected, isDisabled, isSearching, onChange, hideImages }: Props) {
  const onLocationChange = useCallback(() => {
    onChange(searchItem, isSelected)
  }, [onChange, searchItem, isSelected])

  const title = useMemo(
    () => getSearchableTextBold(searchItem.format.mainText, searchTerm),
    [searchTerm, searchItem.format.mainText],
  )
  const subtitle = useMemo(
    () => getSearchableTextBold(searchItem.format.secondaryText || '', searchTerm),
    [searchTerm, searchItem.format.secondaryText],
  )

  return <ListItem
    onClick={onLocationChange}
    disabled={isDisabled}
    startIcon={!isSearching && !hideImages && <Image
      id={imageId ?? 'f2uxhz5mnuyqnyln891b'}
      alt={searchItem.format.mainText}
      width={48}
      height={48}
    />}
    title={title}
    subtitle={subtitle}
    data-testid={`cruise-location-item-${searchItem.format.mainText}`}
    endIcon={<CheckboxButton size="large" checked={isSelected} disabled={isDisabled} />}
  />
}

export default CruiseLocationItem
