import {
  CREATE_SEARCH,
  FETCH_RECENT_SEARCHES,
  SET_RECENT_SEARCHES,
} from './actionConstants'
import uuidV4 from 'lib/string/uuidV4Utils'
import { AppAction } from 'actions/ActionTypes'
import { deleteRecentSearch, getRecentSearches, saveRecentSearch } from 'components/Search/utils'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'

export function createSearch(action: {searchKey: string, searchId: string, searchContext?: App.SearchContext}) {
  const { searchKey, searchId, searchContext } = action

  return {
    type: CREATE_SEARCH,
    key: searchKey,
    searchContext,
    searchId,
  }
}

export function setSearchId(searchKey: string, options: {newSearchId?: string, searchContext?: App.SearchContext} = {}): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const { newSearchId, searchContext } = options

    const searchId = state.search.searches[searchKey]?.searchId ?? newSearchId ?? uuidV4()

    dispatch(createSearch({ searchKey, searchId, searchContext }))
  }
}

export function fetchRecentSearches(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.auth.recentSearches.length > 0) {
      // already updated this, don't need to do it again
      // it should always be in sync after initial fetch
      return
    }

    const recentSearches = getRecentSearches(state.auth.account.memberId)

    dispatch({
      type: FETCH_RECENT_SEARCHES,
      data: recentSearches,
    })
  }
}

export function addRecentSearch(
  searchItem: App.SearchItem,
  dates: App.SearchDates,
  rooms: Array<App.Occupants>,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const travellers = selectSelectedTravellerEmployees(state)
    saveRecentSearch(searchItem, dates, rooms, state.auth.account.memberId, travellers)

    dispatch({
      type: SET_RECENT_SEARCHES,
      // our source of truth for recent search is local storage (for now?)
      // so once we save it, get the whole list
      data: getRecentSearches(state.auth.account.memberId),
    })
  }
}

export function removeRecentSearch(
  id: string,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    deleteRecentSearch(id, state.auth.account.memberId)

    dispatch({
      type: SET_RECENT_SEARCHES,
      // our source of truth for recent search is local storage (for now?)
      // so once we save it, get the whole list
      data: getRecentSearches(state.auth.account.memberId),
    })
  }
}
