import { arrayToMap, EmptyMap } from 'lib/array/arrayUtils'
import { isExperienceItem, isExperienceTicketViewItem, isTourV2ExperienceItem, isTourV2Item } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import getExperienceItemsView from '../view/getExperienceItemsView'

function getDiscountItemId(item: App.Checkout.AnyItem): string {
  if (isTourV2Item(item)) {
    return 'roomId' in item.occupancy ? item.occupancy.roomId as string : item.itemId
  }

  if (isTourV2ExperienceItem(item)) {
    return item.purchasableOption.fkExperienceId ?? item.itemId
  }

  if (isExperienceItem(item)) {
    // experiences items need to be handled separately as we process promos at ticket level
    // but experience items are at experience level (higher) and will never match
    return ''
  }

  if (isExperienceTicketViewItem(item)) {
    return item.transactionKey
  }

  return item.itemId
}

const getAllItemsDiscountTotals = createSelector(
  (state: App.State) => state.checkout.promo?.items,
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => getExperienceItemsView(state),
  (promoItems, cartItems, experienceViews): Map<string, number | undefined> => {
    if (!promoItems?.length) {
      return EmptyMap
    }
    const map = new Map<string, number | undefined>()

    const promoItemsById = arrayToMap(promoItems, item => item.itemId)
    cartItems.forEach((item) => {
      const itemId = getDiscountItemId(item)

      if (itemId) {
        map.set(item.itemId, promoItemsById.get(itemId)?.discountAmount)
      }
    })

    experienceViews.data.forEach(view => {
      view.ticketViews.forEach(ticket => {
        // experiences promos are actually stored against transaction key, not item id
        // This is because they are 'fake' items and item id doesn't really exist at the ticket level
        const itemId = ticket.item.transactionKey
        map.set(ticket.item.itemId, promoItemsById.get(itemId)?.discountAmount)
      })
    })

    if (!map.size) return EmptyMap

    return map
  },
)

export default getAllItemsDiscountTotals
