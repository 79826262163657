import { sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { getBundleAndSaveItemViews } from '../view/bundleAndSave'

export const getBundleAndSaveTotals = createSelector(
  (state: App.State) => getBundleAndSaveItemViews(state),
  (items): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    const views = items.data
    const allHotels = views.flatMap(view => view.hotels)
    return {
      hasRequiredData: true,
      data: {
        price: sum(allHotels, hotel => hotel.totals.price),
        memberPrice: sum(allHotels, hotel => hotel.totals.memberPrice),
        value: sum(allHotels, hotel => {
          if (hotel.package.bundleDiscountPercent) {
            return Number(Number((hotel.totals.price * 100) / (100 - hotel.package.bundleDiscountPercent)).toFixed(2))
          }
          return hotel.totals.value
        }),
        surcharge: sum(allHotels, hotel => hotel.totals.surcharge),
        taxesAndFees: sum(allHotels, hotel => hotel.totals.taxesAndFees),
        otherFees: {
          propertyFees: sum(allHotels, hotel => hotel.totals.otherFees?.propertyFees ?? 0),
        },
        propertiesFees: allHotels.reduce<Array<App.PropertyFeesDetails>>((acc, hotel) => {
          if (hotel.totals.otherFees?.propertyFees && hotel.offer) {
            acc.push({
              propertyName: hotel.offer.property.name,
              propertyFees: hotel.totals.otherFees?.propertyFees ?? 0,
              dueAtPropertyMessage: hotel.offer.property.taxesAndFeesContent ? hotel.offer.property.taxesAndFeesContent : '',
            })
          }
          return acc
        }, []),
        memberValue: 0,
        extraGuestSurcharge: 0,
      },
    }
  },
)
