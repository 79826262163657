import { definitions } from '@luxuryescapes/contract-trip'
import { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { createTrip, deleteTrip, getTrip, getTripMetadata, getTrips, recordTripView, updateTrip, uploadTripImage } from 'tripPlanner/api'
import * as Mapper from 'tripPlanner/mappers'
import { BasicTrip, FullTrip, TravellerRoom, TripLocation, TripMetadata } from 'tripPlanner/types/common'
import * as TripKeys from '../reactQueryKeys/trips'
import { cancelAndInvalidate, invalidateTrip, TRIP_STALE_TIME } from './common'
import { useCallback } from 'react'
import { getTripViewedTimes, sortTripsByMostRecentlyViewedOrUpdated } from 'tripPlanner/utils/recentTrips'
import { CreateTripError, DeleteTripError, GetTripError, GetTripMetadataError, RecordTripViewError, UpdateTripError, UploadTripImageError } from 'tripPlanner/api/types'
import * as TripMetadataKeys from '../reactQueryKeys/tripMetadata'
import { updateWhere } from 'lib/array/arrayUtils'
import moment, { Moment } from 'moment'
import { clearRecentlySavedTripId } from 'storage/recentSavedTrip'
import { applyPatchFields } from '../utils'
import { getPlaceById } from 'api/search'
import dedupeConcat from 'lib/array/arrayConcatDedupe'
import { placeById } from '../reactQueryKeys/search'

const TRIP_API_DEFAULT_TRIPS: Array<definitions['basicTrip']> = []

const DEFAULT_LOCATIONS: Array<TripLocation> = []

export const useTrips = (
  options?: UseQueryOptions<
    Array<definitions['basicTrip']>, unknown, Array<BasicTrip>
  >,
) => {
  const loggedIn = useAppSelector(selectLoggedIn)

  return useQuery({
    queryKey: loggedIn ? TripKeys.lists : TripKeys.listsLoggedOut,
    queryFn: loggedIn ? () => getTrips() : () => TRIP_API_DEFAULT_TRIPS,
    select: Mapper.basicTrips,
    initialData: loggedIn ? undefined : TRIP_API_DEFAULT_TRIPS,
    placeholderData: TRIP_API_DEFAULT_TRIPS,
    enabled: loggedIn,
    staleTime: TRIP_STALE_TIME,
    ...options,
  })
}

const filterEditableTrips = (data: Array<definitions['basicTrip']>) => {
  const trips = Mapper.basicTrips(data)
  return trips.filter((trip) => trip.role !== 'VIEWER')
}

export const useEditableTrips = (
  options?: UseQueryOptions<
    Array<definitions['basicTrip']>, unknown, Array<BasicTrip>
  >,
) => {
  return useTrips({
    select: filterEditableTrips,
    ...options,
  })
}

export const filterOwnedTrips = (data: Array<definitions['basicTrip']>) => {
  const trips = Mapper.basicTrips(data)
  return trips.filter((trip) => trip.role === 'OWNER')
}

export const useOwnedTrips = (
  options?: UseQueryOptions<
    Array<definitions['basicTrip']>, unknown, Array<BasicTrip>
  >,
) => {
  return useTrips({
    select: filterOwnedTrips,
    ...options,
  })
}

export const useRecentTrips = (
  options?: Parameters<typeof useTrips>[0],
): UseQueryResult<Array<BasicTrip>, unknown> => {
  const lastViewedTimes = getTripViewedTimes()
  return useTrips({
    select: useCallback(
      (data: Array<definitions['basicTrip']>) => {
        const trips = Mapper.basicTrips(data)
        return sortTripsByMostRecentlyViewedOrUpdated(trips, lastViewedTimes)
      },
      [lastViewedTimes],
    ),
    ...options,
  })
}

export const useTrip = ({
  tripId, withPlaceholder = true, ...options
}: {
  tripId?: string
  withPlaceholder?: boolean
} & UseQueryOptions<
  definitions['fullTrip'], GetTripError, FullTrip, ReturnType<typeof TripKeys.detail>
>): UseQueryResult<FullTrip, GetTripError> => {
  const queryClient = useQueryClient()
  return useQuery(TripKeys.detail(tripId!), () => getTrip(tripId!), {
    select: Mapper.fullTrip,
    enabled: !!tripId,
    staleTime: TRIP_STALE_TIME,
    placeholderData: withPlaceholder ?
        (): definitions['fullTrip'] | undefined => {
          const basicTrip = queryClient
            .getQueryData<Array<definitions['basicTrip']>>(TripKeys.lists)
            ?.find((d) => d.id === tripId)

          if (!basicTrip) {
            return undefined
          }
          const { bookmarkIdSets, ...rest } = basicTrip
          return {
            items: [],
            isAgentAttributed: false,
            ...rest,
          }
        } :
      undefined,
    ...options,
  })
}

export const useTripMetadataPrefetch = ({ tripId }: { tripId: string}) => {
  const queryClient = useQueryClient()

  queryClient.prefetchQuery(TripMetadataKeys.metadata(tripId), () => getTripMetadata(tripId),
  )
}

export const useTripMetadata = ({
  tripId, onSuccess, onError,
}: {
  tripId: string
  onSuccess?: () => void
  onError?: (err: GetTripMetadataError) => void
}): UseQueryResult<TripMetadata, GetTripMetadataError> => {
  return useQuery(
    TripMetadataKeys.metadata(tripId),
    () => getTripMetadata(tripId),
    {
      select: Mapper.tripMetadata,
      onSuccess,
      onError,
      enabled: !!tripId,
    },
  )
}

export const useRecordTripView = (): UseMutationResult<
  null, RecordTripViewError, Parameters<typeof recordTripView>[0]
> => {
  const queryClient = useQueryClient()
  return useMutation((tripId) => recordTripView(tripId), {
    retry: false,
    onSuccess: (_data, tripId) => {
      // Update `interactedAt` for local copies of trip
      queryClient.setQueryData<Array<BasicTrip>>(
        TripKeys.lists,
        updateWhere<BasicTrip>(
          (trip) => trip.id === tripId,
          (trip) => ({
            ...trip,
            interactedAt: moment(),
          }),
        ),
      )
      queryClient.setQueryData<FullTrip>(
        TripKeys.detail(tripId),
        (trip) => trip && {
          ...trip,
          interactedAt: moment(),
        },
      )
    },
  })
}

export const useCreateTrip = (
  options?: UseMutationOptions<
    FullTrip, CreateTripError, Parameters<typeof createTrip>[0]
  >,
): UseMutationResult<
  FullTrip, CreateTripError, Parameters<typeof createTrip>[0], { previousTrips: Array<BasicTrip> | undefined}
> => {
  const queryClient = useQueryClient()

  return useMutation((params) => createTrip(params).then(Mapper.fullTrip), {
    retry: false,
    ...options,
    // When mutate is called:
    onMutate: async(createTripArgs) => {
      // Clear the recently saved trip ID in case the user wants to save to this trip next
      clearRecentlySavedTripId()

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(TripKeys.lists)

      // Snapshot the previous value
      const previousTrips = queryClient.getQueryData<Array<BasicTrip>>(
        TripKeys.lists,
      )

      // Optimistically update to the new value
      queryClient.setQueryData<Array<BasicTrip>>(TripKeys.lists, (old) => {
        const { startDate, endDate, ...restArgs } = createTripArgs
        const newTrip: BasicTrip = {
          id: '',
          createdAt: moment(),
          updatedAt: moment(),
          interactedAt: moment(),
          role: 'OWNER',
          bookmarkIdSets: [],
          plannedIdSets: [],
          startDate,
          endDate,
          itemCount: 0,
          isActivelyPlanned: false,
          isConciergeTrip: false,
          ...restArgs,
        }
        if (!old) return [newTrip]
        return [...old, newTrip]
      })

      await options?.onMutate?.(createTripArgs)

      // Return a context object with the snapshotted value
      return { previousTrips }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, variables, context) => {
      queryClient.setQueryData(TripKeys.lists, context?.previousTrips)
      options?.onError?.(error, variables, context)
    },
    onSuccess: (trip, variables, context) => {
      queryClient.prefetchQuery(TripKeys.detail(trip.id), () => getTrip(trip.id),
      )
      options?.onSuccess?.(trip, variables, context)
    },
    onSettled: (trip, error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.lists)
      options?.onSettled?.(trip, error, variables, context)
    },
  })
}

export const useDeleteTrip = ({
  onSuccess, onError,
}: {
  onSuccess: () => void
  onError: (err: DeleteTripError) => void
}): UseMutationResult<
  null, DeleteTripError, string, { previousTrips: Array<BasicTrip> | undefined}
> => {
  const queryClient = useQueryClient()

  return useMutation(deleteTrip, {
    onMutate: async(tripId) => {
      // Clear the recently saved trip ID to avoid trying to auto-save to deleted trip
      clearRecentlySavedTripId()

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(TripKeys.lists)
      await queryClient.cancelQueries(TripKeys.detail(tripId))

      // Snapshot the previous value
      const previousTrips = queryClient.getQueryData<Array<BasicTrip>>(
        TripKeys.lists,
      )

      // Optimistically update to the new value
      queryClient.setQueryData<Array<BasicTrip>>(TripKeys.lists, (old) => {
        if (!old) return []
        return [...old.filter((trip) => trip.id !== tripId)]
      })

      // Return a context object with the snapshotted value
      return { previousTrips }
    },
    onError: (err, _newTrip, context) => {
      queryClient.setQueryData(TripKeys.lists, context?.previousTrips)
      onError(err)
    },
    onSuccess: (_data, tripId) => {
      queryClient.removeQueries(TripKeys.detail(tripId))
      onSuccess()
    },
    onSettled: () => {
      cancelAndInvalidate(queryClient, TripKeys.lists)
    },
  })
}

interface UpdateParams {
  tripId: string
  name?: string
  startDate?: Moment | undefined | null
  endDate?: Moment | undefined | null
  imageId?: string
  originPlaceId?: string | null
  destinationPlaceIds?: Array<string>
  travellerRooms?: Array<TravellerRoom>
}

interface UpdateContext {
  previousTrips: Array<BasicTrip> | undefined
  previousTrip: FullTrip | undefined
}

export const useUpdateTrip = (
  options?: UseMutationOptions<
    definitions['fullTrip'], UpdateTripError, UpdateParams
  >,
): UseMutationResult<
  definitions['fullTrip'], UpdateTripError, UpdateParams, UpdateContext
> => {
  const queryClient = useQueryClient()

  return useMutation<
    definitions['fullTrip'],
    UpdateTripError,
    UpdateParams,
    UpdateContext
  >((args) => updateTrip(args), {
    retry: false,
    ...options,
    onMutate: async({ tripId, ...props }) => {
      const args = props

      await queryClient.cancelQueries(TripKeys.detail(tripId))
      await queryClient.cancelQueries(TripKeys.lists)

      const previousTrips = queryClient.getQueryData<Array<BasicTrip>>(
        TripKeys.lists,
      )
      if (previousTrips) {
        const currentTrips: Array<BasicTrip> = []
        previousTrips.forEach((val) => currentTrips.push(Object.assign({}, val)),
        )

        const trip = currentTrips.find((trip) => trip.id === tripId)
        if (trip) {
          const modifiedTrip = applyPatchFields(trip, args)
          const tripIndex = currentTrips.indexOf(trip)
          currentTrips[tripIndex] = modifiedTrip
          queryClient.setQueryData(TripKeys.lists, currentTrips)
        }
      }

      const previousTrip = queryClient.getQueryData<FullTrip>(['trip', tripId])
      if (previousTrip) {
        queryClient.setQueryData(
          TripKeys.detail(tripId),
          applyPatchFields(previousTrip, args),
        )
      }

      return { previousTrips, previousTrip }
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(TripKeys.lists, context?.previousTrips)
      queryClient.setQueryData(
        TripKeys.detail(variables.tripId),
        context?.previousTrip,
      )
      options?.onError?.(error, variables, context)
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(TripKeys.detail(variables.tripId), data)
      options?.onSuccess?.(data, variables, context)
    },
    onSettled: (data, error, variables, context) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

export const useUploadTripImage = (
  options?: UseMutationOptions<
    FullTrip, UploadTripImageError, Parameters<typeof uploadTripImage>[0]
  >,
): UseMutationResult<FullTrip, UploadTripImageError, Parameters<typeof uploadTripImage>[0]> => {
  const queryClient = useQueryClient()

  return useMutation((args) => uploadTripImage(args).then(Mapper.fullTrip), {
    retry: false,
    ...options,
    onSettled: (data, error, variables, context) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

export const useTripDestinations = ({
  tripId, includesTripDestinations = true, includesTripLocations = true, prioritise = 'destinations',
}: {
  tripId: string
  includesTripDestinations?: boolean
  includesTripLocations?: boolean
  prioritise?: 'destinations' | 'locations'
}): Array<App.SearchDestination> => {
  const { data: tripMetadata } = useTripMetadata({ tripId })
  const { data: trip } = useTrip({ tripId })

  const listOfPlaceIdsFromTripDestinations = includesTripDestinations ?
    trip?.destinationsGeo?.map((dest) => dest.lePlaceId) || [] :
      []

  const listOfPlaceIdsFromTripLocations = includesTripLocations ?
      (tripMetadata?.locations || DEFAULT_LOCATIONS)
        ?.filter((location) => !location.isOrigin)
        .map((dest) => dest.placeGroup.lePlaceId) :
      []

  let listOfPlaceIds: Array<string> = []

  if (prioritise === 'destinations') {
    listOfPlaceIds = dedupeConcat(
      listOfPlaceIdsFromTripDestinations,
      listOfPlaceIdsFromTripLocations,
    )
  } else if (prioritise === 'locations') {
    listOfPlaceIds = dedupeConcat(
      listOfPlaceIdsFromTripLocations,
      listOfPlaceIdsFromTripDestinations,
    )
  }

  const results = useQueries({
    queries: listOfPlaceIds.map((placeId) => ({
      queryKey: placeById(placeId),
      queryFn: () => getPlaceById(placeId).then<App.SearchDestination>((place) => ({
        searchType: 'destination',
        destinationType: place.type ?? '',
        value: place.id,
        format: {
          mainText: place.name,
          secondaryText: place.canonicalName,
        },
      })),
      staleTime: Infinity,
    })),
  })

  if (results.find((result) => result.isLoading)) {
    return []
  }

  return results.reduce<Array<App.SearchDestination>>((acc, result) => {
    if (result.data) {
      acc.push(result.data)
    }
    return acc
  }, [])
}
