import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseBorderRadii, whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const cudoTravelDefaultPalette: App.Design.Palette<string> = {
  ...whiteLabelBaseDefaultPalette,
}

export const cudoTravelShadows: App.Design.Shadows<string> = { ...baseThemeShadows }
export const cudoTravelBorderRadii: App.Design.BorderRadii<string> = { ...whiteLabelBaseBorderRadii }

const cudoTravelTheme: App.Design.Theme = {
  ...whiteLabelBaseTheme,
}

export default cudoTravelTheme
