import { createSelector } from 'reselect'
import { getFlightItems } from '../view/flights'
import { getOccupantsFromItems } from 'lib/insurance/insuranceOccupants'

const getInsuranceFlightOccupants = createSelector(
  (state: App.State) => getFlightItems(state),
  (items) => {
    if (items.length === 0) return

    return getOccupantsFromItems(
      items,
      item => item.itemId,
      item => item.occupants,
    )
  },
)

export default getInsuranceFlightOccupants
