import React from 'react'
import LuxPlusLabelWithModal from 'luxPlus/components/LuxPlusLabelWithModal'

interface Props {
  luxPlusInclusions: Array<App.PackageInclusion>;
  offer?: App.CruiseOffer;
  isModalPrimaryActionHidden?: boolean;
}

function CruiseLuxPlusOnboardCreditLabel(props: Props) {
  const { luxPlusInclusions, offer, isModalPrimaryActionHidden } = props
  const firstLuxPlusInclusion = luxPlusInclusions[0]

  return <>
    {!!firstLuxPlusInclusion && <LuxPlusLabelWithModal
      type="bonus-inclusions"
      description={firstLuxPlusInclusion.description}
      inclusions={luxPlusInclusions}
      offer={offer}
      isModalPrimaryActionHidden={isModalPrimaryActionHidden}
    />}
  </>
}

export default CruiseLuxPlusOnboardCreditLabel
