import { EmptyArray } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { getTourV2ExperienceItemsViews } from 'checkout/selectors/view/toursv2'

const getCheckoutTourExperiencePointsCalculationRequests = createSelector(
  (state: App.State) => getTourV2ExperienceItemsViews(state),
  (tourExperienceItemView): Array<App.LuxLoyaltyPointsEarnCalculationRequest> => {
    if (!tourExperienceItemView.hasRequiredData) return EmptyArray

    return tourExperienceItemView.data.map((itemView: App.Checkout.TourV2ExperienceItemView) => {
      return {
        price: itemView.totals.price,
        luxLoyaltyProductType: itemView.luxLoyaltyProductType as App.LuxLoyaltyTourProductType,
        itemId: itemView.item.itemId,
      }
    })
  },
)

export default getCheckoutTourExperiencePointsCalculationRequests
