export default function removeSpace(value: string) {
  if (!value) {
    return ''
  }
  return value.toString().replace(/\s+/g, '-')
}

export function addSpace(value: string) {
  if (!value) {
    return ''
  }
  return value.toString().replace(/-+/g, ' ')
}

export function urlTransform(value: string): string
export function urlTransform(value?: string): string | undefined
export function urlTransform(value?: string) {
  if (!value) {
    return value
  }
  return encodeURI(value.toString().replace(/, /g, ',').replace(/-+/g, '+').replace(/\s+/g, '-'))
}

export function humanTransform(value: string): string
export function humanTransform(value?: string): string | undefined
export function humanTransform(value?: string) {
  if (!value) {
    return value
  }
  return decodeURI(value.toString().replace(/-+/g, ' ').replace(/[+]+/g, '-').replace(/,/g, ', '))
}

export function destinationNameToUrlTransform(value: string): string
export function destinationNameToUrlTransform(value?: string): string | undefined
export function destinationNameToUrlTransform(value?: string) {
  if (!value) {
    return value
  }
  return value.toString().replace(/, /g, '/').replace(/\s+/g, '-').toLowerCase()
}

export function nameToSearchFriendlyQueryParamsTransform(value?: string): string | undefined
export function nameToSearchFriendlyQueryParamsTransform(value: string): string
export function nameToSearchFriendlyQueryParamsTransform(value?: string) {
  if (!value) {
    return value
  }
  return value.toString().replace(/, /g, '/').replace(/\s+/g, '+')
}
