import React, { useCallback, useEffect, useState } from 'react'
import OfferSortControl from 'components/Common/OfferSortControl/OfferSortControl'
import styled from 'styled-components'
import zIndex from 'styles/tools/z-index'
import { OfferListSortOption } from 'constants/offerListFilters'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import { deleteSearchParams, setManySearchParamValues } from 'lib/url/searchUrlUtils'
import { CruiseSearchFilterKeys } from 'constants/cruise'
import { replace } from 'connected-react-router'
import { useLocation } from 'react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import CruiseSearchPriceChip from './CruiseSearchPriceChip'
import CruiseSearchShipChip from './CruiseSearchShipChip'
import CruiseSearchPromotionChip
  from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchPromotionChip'
import CruiseSearchCabinTypeChip
  from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchCabinTypeChip'
import useCruiseFacetFiltersParams from 'hooks/useCruiseFacetFiltersParams'

export const HeaderContainer = styled.div`
  position: sticky;
  z-index: ${zIndex.searchSubMenu};
  background: ${props => props.theme.palette.neutral.default.eight};
  width: 100%;
  top: 0;

  &.lifted {
    box-shadow: ${(props) => props.theme.shadow.flat.small};
  }
`

export interface Form {
  minPrice?: number | undefined;
  maxPrice?: number | undefined;
  cabinTypes?: string | undefined;
  cruiseShips?: Array<string> | undefined;
  promotions?: Array<string> | undefined;
  isSpecialOffers?: string | undefined;
  isLuxExclusivePromotion?: string | undefined;
  isLuxPlus?: string | undefined;
}

interface Props {
  listFilters: App.OfferListFilters;
  sortOptions: Array<OfferListSortOption>;
  onShowFilters: () => Promise<void>;
  drawerMode?: boolean;
}

function CruiseSearchChips(props: Props) {
  const { sortOptions, listFilters, drawerMode } = props
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const isSlimMobileSearch = useSlimMobileSearch()
  const showSortDropdown = !isSlimMobileSearch
  const { facetParams, mappedFilters, queryParamsFilters: filters } = useCruiseFacetFiltersParams({ filters: listFilters })

  const [facets, fetching] = useCruiseSearchFacets(facetParams)
  const [filterForm, setFilterForm] = useState({
    minPrice: mappedFilters.price?.min,
    maxPrice: mappedFilters.price?.max,
    cabinTypes: mappedFilters.cabinTypes,
    cruiseShips: mappedFilters.cruiseShips,
    promotions: mappedFilters.promotions,
    isSpecialOffers: filters.isSpecialOffers?.size! ? Array.from(filters.isSpecialOffers)[0] : undefined,
    isLuxExclusivePromotion: filters.isLuxExclusivePromotion?.size! ? Array.from(filters.isLuxExclusivePromotion)[0] : undefined,
    isLuxPlus: filters.isLuxPlus?.size! ? Array.from(filters.isLuxPlus)[0] : undefined,
  } as Form)

  const onChangeFilterForm = useCallback((filterForm: Form) => {
    setFilterForm(filterForm)

    if (!filterForm) return

    if (filterForm && Object.keys(filterForm).length > 0) {
      const urlValues = Object.entries({ ...filterForm }).map(([key, value]) => ({ paramName: key, paramValue: value }))
      let nextSearch = deleteSearchParams(search, ...CruiseSearchFilterKeys)
      nextSearch = setManySearchParamValues(nextSearch, urlValues)
      dispatch(replace({ search: nextSearch }))
    } else {
      dispatch(replace({ search: undefined }))
    }
  }, [dispatch, search])

  // set initial filter form
  useEffect(() => {
    setFilterForm({
      minPrice: mappedFilters.price?.min,
      maxPrice: mappedFilters.price?.max,
      cabinTypes: mappedFilters.cabinTypes,
      cruiseShips: mappedFilters.cruiseShips,
      promotions: mappedFilters.promotions,
      isSpecialOffers: filters.isSpecialOffers?.size! ? Array.from(filters.isSpecialOffers)[0] : undefined,
      isLuxExclusivePromotion: filters.isLuxExclusivePromotion?.size! ? Array.from(filters.isLuxExclusivePromotion)[0] : undefined,
      isLuxPlus: filters.isLuxPlus?.size! ? Array.from(filters.isLuxPlus)[0] : undefined,
    } as Form)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <OfferSortControl
      sortOptions={sortOptions}
      roundedButton
      showSelectedHighlight
      showDropdown={showSortDropdown}
    />
    <>
      <CruiseSearchPriceChip
        drawerMode={drawerMode}
        facets={facets}
        filters={filters}
        listFilters={{
          minPrice: listFilters.minPrice,
          maxPrice: listFilters.maxPrice,
        }}
        filterForm={filterForm}
        setFilterForm={onChangeFilterForm}
        fetching={fetching}
      />
      <CruiseSearchShipChip
        drawerMode={drawerMode}
        facets={facets}
        filters={filters}
        filterForm={filterForm}
        setFilterForm={onChangeFilterForm}
        fetching={fetching}
      />
      <CruiseSearchCabinTypeChip
        drawerMode={drawerMode}
        facets={facets}
        filters={filters}
        filterForm={filterForm}
        setFilterForm={onChangeFilterForm}
        fetching={fetching}
      />
      <CruiseSearchPromotionChip
        drawerMode={drawerMode}
        facets={facets}
        filters={filters}
        filterForm={filterForm}
        setFilterForm={onChangeFilterForm}
        fetching={fetching}
      />
    </>
  </>
}

export default CruiseSearchChips
