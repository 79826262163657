import React, { useContext } from 'react'
import TourAsiaCampaignProfileImage from 'components/Tours/Campaign/TourAsiaCampaignProfileImage'
import ModalContext from 'contexts/ModalContext'
import TourAsiaCampaignModal from './TourAsiaCampaignModal'
import PageBanner from 'components/Luxkit/Banners/PageBanner'
import styled from 'styled-components'
import TextLink from 'components/Luxkit/TextLink'

const BannerWrapper = styled(PageBanner)`
  && {
    background-color: transparent;
    padding: 0;
  }
`

function TourAsiaCampaignOfferBanner() {
  const showModal = useContext(ModalContext)
  const showTourAsiaModal = () => { showModal(<TourAsiaCampaignModal modalVariant="offer"/>) }
  return <BannerWrapper
    kind="informational-primary"
    align="start"
    tabletAlign="center"
    density="compact"
    icon={<TourAsiaCampaignProfileImage size="medium" />}
    description={<>Explore the best of Asia on Tour. Unlock exclusive savings with code: <b>TOURASIA.</b></>}
    trailingContent={<TextLink onClick={showTourAsiaModal}>Learn more</TextLink>}
  />
}

export default TourAsiaCampaignOfferBanner
