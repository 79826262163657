import { ItemContextMetadata } from 'analytics/snowplow/events'
import { isBookingProtectionOrderItem, isInsuranceOrderItem, isOrderItem, isTourV2OrderItem } from 'lib/order/orderUtils'

/**
 *
 * Util to calculate the member pricing metadata for an order item
 *
 * Return member price if available, otherwise return non-member price as `memberPrice`
 *
 * @param orderItem Order items that support member pricing
 * @returns
 */
const getOrderDataMemberPricingMetadata = (
  orderItem: App.OrderItem | App.Tours.TourV2OrderItem | App.OrderInsuranceItem | App.OrderBookingProtectionItem,
): ItemContextMetadata | undefined => {
  const { memberPrice } = orderItem
  let nonMemberPrice: number | undefined
  if (isTourV2OrderItem(orderItem) ||
      isInsuranceOrderItem(orderItem) ||
      isBookingProtectionOrderItem(orderItem)
  ) {
    nonMemberPrice = orderItem.publicPrice
  } else if (isOrderItem(orderItem)) {
    nonMemberPrice = orderItem.package.price
  }
  const memberPriceOrPrice = memberPrice && memberPrice > 0 ? memberPrice : nonMemberPrice
  return memberPriceOrPrice && memberPriceOrPrice > 0 ? { memberPrice: memberPriceOrPrice, nonMemberPrice } : undefined
}

export default getOrderDataMemberPricingMetadata
