import Clickable from 'components/Common/Clickable'
import SolidStarCircleIcon from 'components/Luxkit/Icons/solid/SolidStarCircleIcon'
import Label from 'components/Luxkit/Label'
import UltraLuxLogo from 'components/Luxkit/Label/UltraLuxLogo'
import { TOUR_STYLED_PRODUCT_TYPE_TITLES } from 'constants/tours'
import ModalContext from 'contexts/ModalContext'
import React, { ComponentProps, MouseEventHandler, useCallback, useContext } from 'react'
import TourStyledProductTypeInfoModal from './TourStyledProductTypeInfoModal'
import { isTourProductTypeStyled } from 'lib/tours/tourUtils'
import { LUXURY_ESCAPES } from 'constants/brands'
import config from 'constants/config'
import { LE_PARTNER } from 'constants/productLabel'
import SolidBrightnessEmptyIcon from 'components/Luxkit/Icons/solid/SolidBrightnessEmptyIcon'

const TOUR_STYLE_LABEL_PROPS: Record<App.Tours.V2ProductType, ComponentProps<typeof Label> | undefined> = {
  deluxe_tour: {
    kind: 'secondary',
    variant: 'dark',
    children: TOUR_STYLED_PRODUCT_TYPE_TITLES.deluxe_tour,
  },
  premium_tour: {
    kind: 'secondary',
    variant: 'dark',
    startIcon: <SolidBrightnessEmptyIcon />,
    children: TOUR_STYLED_PRODUCT_TYPE_TITLES.premium_tour,
  },
  ultra_lux_tour: {
    kind: 'secondary',
    variant: 'ghost',
    children: <UltraLuxLogo height={10} />,
  },
  signature_series_tour: {
    kind: 'primary',
    variant: 'dark',
    startIcon: <SolidStarCircleIcon />,
    children: TOUR_STYLED_PRODUCT_TYPE_TITLES.signature_series_tour,
  },
  partner_tour: config.BRAND === LUXURY_ESCAPES ? {
    kind: 'secondary',
    variant: 'grey',
    children: LE_PARTNER,
  } : undefined,
  direct_tour: undefined,
  connection_tour: undefined,
  cruise_tour: undefined,
}

interface Props {
  productType: App.Tours.V2ProductType
  offerUrl?: string
}

function TourProductTypeLabel({ productType, offerUrl }: Props) {
  const showModal = useContext(ModalContext)
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    if (isTourProductTypeStyled(productType)) {
      showModal(<TourStyledProductTypeInfoModal
        productType={productType}
        primaryActionText={offerUrl ? 'View offer' : undefined}
        primaryActionTo={offerUrl}
      />)
    }
  }, [showModal, productType, offerUrl])

  const config = TOUR_STYLE_LABEL_PROPS[productType]

  if (!config) return null

  return <Clickable onClick={handleClick}>
    <Label {...config} />
  </Clickable>
}

export default TourProductTypeLabel
