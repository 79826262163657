import { StepType, TourProvider as BaseTourProvider } from '@reactour/tour'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import {
  TripOnboardingEvents,
  tripOnboardingImpressionEvent,
} from 'analytics/eventDefinitions'
import {
  fireInteractionEvent,
  fireNonInteractionEvent,
} from 'api/googleTagManager'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import IconButton from 'components/Luxkit/Button/IconButton'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import { useResponsive } from 'hooks/useResponsive'
import {
  setSeenStatus as setTourOnboardingSeenStatus,
  setSeenInThisSessionStatus,
} from 'tripPlanner/storage/onboardingTour'

const stepContent = (heading: string, body: string) => (
  <VerticalSpacer gap={8}>
    <Heading variant="heading6">{heading}</Heading>
    <BodyText variant="large">{body}</BodyText>
  </VerticalSpacer>
)

const onboardingSteps: Array<StepType> = [
  {
    selector: '.onboarding-tour-element-add-to-trip',
    resizeObservables: ['.onboarding-tour-element-add-to-trip'],
    mutationObservables: ['.onboarding-tour-element-add-to-trip'],
    content: stepContent(
      'Add anything to your trip',
      'Import your Luxury Escapes bookings or add anything else you want to do on your trip',
    ),
    padding: { mask: 8 },
  },
  {
    selector: '.onboarding-tour-element-map',
    resizeObservables: ['.onboarding-tour-element-map'],
    mutationObservables: ['.onboarding-tour-element-map'],
    content: stepContent(
      'Map out your journey',
      'Visualise your entire trip on a map with all your personalised trip items',
    ),
    padding: { mask: 8 },
  },
  {
    selector: '.onboarding-tour-element-itinerary-tab',
    resizeObservables: ['.onboarding-tour-element-itinerary-tab'],
    mutationObservables: ['.onboarding-tour-element-itinerary-tab'],
    content: stepContent(
      'Day-by-day view',
      'Once you add trip dates you’ll be able to view your trip as a day-by-day itinerary.',
    ),
    padding: { mask: 0 },
  },
]

const onboardingStepsMobile = onboardingSteps

const onboardingStepsDesktop: Array<StepType> = [
  onboardingSteps[0],
  {
    ...onboardingSteps[1],
    position: 'center',
    padding: { mask: 0 },
  },
  onboardingSteps[2],
]

const styles: React.ComponentProps<typeof BaseTourProvider>['styles'] = {
  popover: (base) => ({
    ...base,
    borderRadius: rem(8),
    boxShadow:
      // Ideally we'd read this from the theme rather than copy/pasting it here, but that's a lot of hassle for one CSS property
      '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 4px 14px rgba(0, 0, 0, 0.05), 0px 8px 24px rgba(0, 0, 0, 0.05), 0px 24px 35px rgba(0, 0, 0, 0.05)',
    padding: rem(20),
    paddingTop: rem(40),
  }),
  maskWrapper: (base) => ({
    ...base,
    opacity: 0.35,
  }),
}

const NavigationGroup = styled(Group)`
  margin-top: ${rem(16)};
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${rem(8)};
  right: ${rem(8)};
`

const StepDisplay = styled(Caption)`
  position: absolute;
  top: ${rem(16)};
  left: ${rem(20)};
`

const components: React.ComponentProps<typeof BaseTourProvider>['components'] =
  {
    Close: ({ onClick }) => (
      <CloseButton kind="tertiary" onClick={onClick}>
        <LineTimesIcon colour="neutral-one" />
      </CloseButton>
    ),
    Badge: ({ children }: any) => (
      <StepDisplay variant="medium" colour="neutral-three">
        {children}
      </StepDisplay>
    ),
    Navigation: ({ currentStep, setCurrentStep, setIsOpen, steps }) => {
      const isLast = currentStep === steps.length - 1
      const skip = () => {
        fireInteractionEvent(
          TripOnboardingEvents('dismiss', currentStep + 1, 'skip'),
        )
        setIsOpen(false)
      }
      const done = () => {
        fireInteractionEvent(
          TripOnboardingEvents('done_click', currentStep + 1),
        )
        setIsOpen(false)
      }
      const next = () => {
        fireInteractionEvent(
          TripOnboardingEvents('continue_click', currentStep + 1),
        )
        setCurrentStep(currentStep + 1)
      }

      return (<NavigationGroup
        direction="horizontal"
        horizontalAlign={isLast ? 'end' : 'space-between'}
      >
        {isLast && (
          <TextButton kind="tertiary" onClick={done} horizontalOutdent="end">
            Done
          </TextButton>
        )}
        {!isLast && (
          <>
            <TextButton
              kind="tertiary"
              variant="dark"
              onClick={skip}
              horizontalOutdent="start"
            >
              Skip
            </TextButton>
            <TextButton kind="tertiary" onClick={next} horizontalOutdent="end">
              Continue
            </TextButton>
          </>
        )}
      </NavigationGroup>
      )
    },
  }

const badgeContent: React.ComponentProps<
  typeof BaseTourProvider
>['badgeContent'] = (props) => `${props.currentStep + 1} / ${props.totalSteps}` // currentStep is zero-indexed

const markSeen = () => {
  fireNonInteractionEvent(tripOnboardingImpressionEvent())
  setTourOnboardingSeenStatus(true)
  setSeenInThisSessionStatus(true)
}

interface Props {
  children: React.ReactNode
}

type TourProviderProps = React.ComponentProps<typeof BaseTourProvider>

function TourProvider({ children }: Props) {
  const [match] = useResponsive()
  const isDesktopLayout = !!(match.desktop || match.largeDesktop)

  const steps = isDesktopLayout ? onboardingStepsDesktop : onboardingStepsMobile
  const onClickClose: TourProviderProps['onClickClose'] = ({ currentStep, setIsOpen }) => {
    fireInteractionEvent(
      TripOnboardingEvents('dismiss', currentStep + 1, 'exit'),
    )
    setIsOpen(false)
  }
  const onClickMask: TourProviderProps['onClickMask'] = ({ currentStep, setIsOpen }) => {
    fireInteractionEvent(
      TripOnboardingEvents('dismiss', currentStep + 1, 'dismiss'),
    )
    setIsOpen(false)
  }

  return (
    <BaseTourProvider
      steps={steps}
      styles={styles}
      components={components}
      badgeContent={badgeContent}
      showDots={false}
      afterOpen={markSeen}
      onClickClose={onClickClose}
      onClickMask={onClickMask}
    >
      {children}
    </BaseTourProvider>
  )
}

export default TourProvider
