import React from 'react'
import BusinessTravellerListBudgetChipFilter from './BusinessTravellerListBudgetChipFilter'
import BusinessTravellerMapBudgetChipFilter from './BusinessTravellerMapBudgetChipFilter'
import { connect } from 'react-redux'

interface MappedStateProps {
  path: string,
}

interface Props {
  label: string
  filters: App.OfferListFilters
  search: string
}

function BusinessTravellerBudgetFilterChip(props: Props & MappedStateProps) {
  const { label, filters, search, path } = props

  const isMapView = path.includes('/map')

  return <>
    {!isMapView && <BusinessTravellerListBudgetChipFilter
      label={label}
      filters={filters}
      search={search}
    />}
    {isMapView && <BusinessTravellerMapBudgetChipFilter
      label={label}
      filters={filters}
      search={search}
    />}
  </>
}

const mapStateToProps = (state: App.State) => {
  return {
    path: state.router.location.pathname,
  }
}

export default connect(mapStateToProps)(BusinessTravellerBudgetFilterChip)
