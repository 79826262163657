/**
 *
 * @param itemDiscount The item discount to format
 * @returns the formatted item discount to be send to svc-order
 */
function formatItemDiscount(itemDiscount: App.Checkout.ItemDiscount): App.Checkout.FormattedItemDiscount {
  return {
    item_id: itemDiscount.itemId,
    offer_id: itemDiscount.offerId,
    category_bk: itemDiscount.categoryBK,
    sub_category_bk: itemDiscount.subCategoryBK,
    discount_value: itemDiscount.discountValue,
    discount_type: itemDiscount.discountType,
    discount_amount: itemDiscount.discountAmount,
    discountable_total: itemDiscount.discountableTotal,
    hide_discount_percentage: itemDiscount.hideDiscountPercentage,
    item_info_string: itemDiscount.itemInfoString,
  }
}

export default formatItemDiscount
