import ResponsiveImage from 'components/Common/ResponsiveImage'
import useCurrentSiteTakeover, { SiteTakeoverBackground } from 'hooks/useCurrentSiteTakeover'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Image from 'components/Common/Image'
import { mediaQueryUp } from 'components/utils/breakpoint'
import LayoutContainer from '../LayoutContainer'

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #FDF2EA;
  overflow: hidden;
`

const ValentineTakeoverImage = styled(ResponsiveImage)`
  opacity: 0.03;
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;

  &.overlay-hero {
    background: linear-gradient(180deg, rgba(255, 91, 47, 0.20) 0%, rgba(255, 222, 238, 0.20) 30%, rgba(255, 255, 255, 0.00) 40%);
  }
`

/**
 * No step on!
 */
const Heart = styled.div`
  position: absolute;

  img {
    height: 100%;
    width: 100%;
  }


  &.kind-hero, &.kind-search {
    right: 0;
    width: ${rem(142)};
    transform: translateX(40%) rotate(-30deg);

    ${mediaQueryUp.largeDesktop} {
      transform: unset;
      top: ${rem(-20)};
      width: ${rem(460)};
    }
  }

  &.kind-default {
    top: ${rem(-28)};
    right: ${rem(-100)};
    width: ${rem(237)};
    transform: rotate(-21deg);

    ${mediaQueryUp.desktop} {
      top: ${rem(-12)};
      right: 0;
      width: ${rem(273)};
      height: ${rem(94)};
      transform: translateY(-${rem(10)});
    }
  }
`

const HeartFlyingGif = styled.div`
  position: absolute;

  img {
    height: 100%;
    width: 100%;
  }

  &.kind-hero {
    top: 0;
    right: 0;
    width: ${rem(120)};
    height: ${rem(120)};

    ${mediaQueryUp.tablet} {
      right: unset;
      top: ${rem(12)};
      left: ${rem(380)};
    }
  }

  &.kind-search {
    top: 0;
    right: 0;
    width: ${rem(75)};

    ${mediaQueryUp.tablet} {
      right: ${rem(480)};
      width: ${rem(130)};
      height: ${rem(130)};
    }
  }

  &.kind-default {
    top: 0;
    right: ${rem(96)};
    width: ${rem(64)};
    height: ${rem(64)};

    ${mediaQueryUp.tablet} {
      left: 0;
      right: unset;
      width: ${rem(50)};
      height: ${rem(50)};
    }
  }
`

const ImagesContainer = styled(LayoutContainer)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`

interface Props extends Omit<React.ComponentProps<typeof ResponsiveImage>, 'id' | 'fit'> {
  background: SiteTakeoverBackground
}

function ValentineSiteTakeoverBackground(props: Props) {
  const { className, background, ...imageProps } = props

  const currentTakeover = useCurrentSiteTakeover()

  if (!currentTakeover) {
    return null
  }

  return <Container className={className}>
    <ValentineTakeoverImage
      {...imageProps}
      id={currentTakeover.imageId}
      fit="center"
      dpr={2}
    />
    <Gradient className={`overlay-${background}`} />
    <ImagesContainer>
      <Heart className={`kind-${background}`}>
        <Image id="6a6m0tqtsqjcb49n3ss" quality="best" width={460} />
      </Heart>
      <HeartFlyingGif className={`kind-${background}`}>
        <Image id="sg1vv90pqj9rgvbz8ypg" enhancedFormats={false} width={120} />
      </HeartFlyingGif>
    </ImagesContainer>
  </Container>
}

export default ValentineSiteTakeoverBackground
