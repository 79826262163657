import { toggleDisableMembership } from 'actions/LuxPlusActions'
import SwitchButton from 'components/Luxkit/Switch/SwitchButton'
import { useAppDispatch } from 'hooks/reduxHooks'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'

interface MappedProps {
  disableMembership: boolean;
}

function DisableMembershipToggle({
  disableMembership,
}: MappedProps) {
  const dispatch = useAppDispatch()

  const handleChange = useCallback(() => {
    dispatch(toggleDisableMembership())
  }, [dispatch])

  return <SwitchButton
    checked={disableMembership}
    onChange={handleChange}
  >
    Disable {LUXURY_PLUS.PROGRAM_NAME}
  </SwitchButton>
}

function mapStateToProps(state: App.State) {
  return {
    disableMembership: state.auth.account.luxPlus.member.disableMembership,
  }
}

export default connect(mapStateToProps)(DisableMembershipToggle)
