import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId, getCruiseTourV2OrExperienceOffer } from 'analytics/snowplow/helpers/itemCategorisation'
import { last } from 'lib/array/arrayUtils'
import { sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { isTourV2Offer } from 'lib/offer/offerTypes'

function getTourV2CheckoutItem(state: App.State, item: App.Checkout.TourV2Item) {
  const offerId = item.offerId
  let offer = getCruiseTourV2OrExperienceOffer(state, offerId) as App.Tours.TourV2Offer
  const tourV2Offer = state.offer.offers[offerId]
  if (!offer && isTourV2Offer(tourV2Offer)) {
    offer = tourV2Offer as App.Tours.TourV2Offer
  }
  const variation = offer?.variations[item.purchasableOption.fkVariationId]
  const { categoryId, productId } = getCategoryAndProductId('tour_v2', offer)
  const {
    valuedAt: value,
    fkVariationId: variant,
    fkDepartureId: packageId,
    packageType: typeId,
    fkRoomTypePricingId: rateId,
    price: pricePerPerson,
    memberPrice: memberPricePerPerson,
  } = item.purchasableOption
  const price = pricePerPerson * sumUpOccupancies([item.occupancy])
  const memberPriceOrPricePerPerson = memberPricePerPerson > 0 ? memberPricePerPerson : pricePerPerson
  const memberPrice = memberPriceOrPricePerPerson * sumUpOccupancies([item.occupancy])
  const originCity = variation?.startLocation
  const originCountry = variation?.countriesVisited[0]
  const destinationCity = variation?.endLocation
  const destinationCountry = variation ? last(variation.countriesVisited) : undefined

  return comluxgroup.createItem_1_1_0(
    {
      offerId,
      categoryId,
      productId,
      packageId,
      typeId,
      rateId,
      travelStart: item.startDate,
      travelEnd: item.endDate,
      duration: item.duration,
      numberOfAdults: item.occupancy.adults,
      numberOfChildren: item.occupancy.children,
      numberOfInfants: item.occupancy.infants,
      childrenAges: item.occupancy.childrenAge,
      value,
      variant,
      price,
      offerLeadPrice: item.purchasableOption.price,
      offerLeadDuration: item.duration ? String(item.duration) : undefined,
      offerLeadUnit: offer?.saleUnit,
      originCity,
      originCountry,
      destinationCity,
      destinationCountry,
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
      metadata: price ? JSON.stringify({ memberPrice, nonMemberPrice: price }) : undefined,
    },
  )
}

export default getTourV2CheckoutItem
