import { mapExperienceCartItemToSummaryViews } from 'checkout/lib/utils/experiences/view'
import { excludeNullOrUndefined } from 'checkout/utils'
import { createSelector } from 'reselect'
import getExperienceItems from './getExperienceItems'
import { getExperienceTimesKey, getTotalSeats } from 'lib/experiences/experienceUtils'
import config from 'constants/config'
import { sum, countByAsRecord, isNonNullable, arrayToMap, fillArray } from 'lib/array/arrayUtils'
import { calculateDiscount } from 'lib/payment/calculateDiscount'
import { incrementUuidV4 } from 'lib/string/uuidV4Utils'
import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'
import mapExperienceCartItemToTicketItemView from 'checkout/lib/utils/experiences/mapExperienceCartItemToTicketView'

function getTicketKey(ticket: App.ExperienceItemTicket, requiresTime?: boolean) {
  return `${ticket.id}-${ticket.date}-${requiresTime ? ticket.time : ''}`
}

function getCartTicketKey(ticket: App.Checkout.ExperienceItemTicket, time: string = '') {
  return `${ticket.ticketId}-${ticket.date}-${time}`
}

const getExperienceItemsView = createSelector(
  (state: App.State) => getExperienceItems(state),
  (state: App.State) => state.experience.experiences,
  (state: App.State) => state.experience.experienceTimes,
  (state: App.State) => state.checkout.cart.currencyCode,
  (
    experienceItems,
    experiences,
    experienceTimes,
    currencyCode,
  ): App.WithDataStatus<Array<App.Checkout.ExperienceItemView>> => {
    const itemViews = experienceItems.map(cartItem => {
      const experience = experiences[cartItem.experienceId]

      const key = getExperienceTimesKey(cartItem.experienceId, cartItem.date, {
        currency: currencyCode,
        pickupPointId: cartItem.pickupPointId,
        redemptionLocationId: cartItem.redemptionLocationId,
        isBuyNowBookLater: cartItem.isBuyNowBookLater,
        ticketMode: cartItem.ticketModeKey,
        isGift: cartItem.isGift,
      })

      const timeSlots = experienceTimes[cartItem.experienceId]?.[key]?.slots
      const hasTickets = timeSlots?.some(slot => slot.tickets.length)

      if (experience && hasTickets && timeSlots) {
        const requiresTime = experience.bookingType === 'CALENDAR-TIMESLOTS' && !cartItem.isBuyNowBookLater && !cartItem.isGift
        const ticketsByKey = arrayToMap(timeSlots.flatMap(slot => slot.tickets), ticket => getTicketKey(ticket, requiresTime))
        // Creates a per-ticket view (tickets * ticket count)
        const ticketViews = cartItem.tickets.flatMap(ticketItem => {
          const key = getCartTicketKey(ticketItem, requiresTime ? cartItem.time : undefined)
          const ticket = ticketsByKey.get(key)
          if (ticket) {
            return fillArray(ticketItem.count).map(index => ({
              item: ticketItem,
              ticket,
              orderItemId: ticketItem.orderItemIds?.[index],
              // only the first item has the actual
              hasLoyaltyDiscount: ticketItem.loyaltyTransferDiscount && index === 0,
            }))
          }
          return []
        }).map((ticketItem, index) => {
          const nextTransactionKey = incrementUuidV4(cartItem.transactionKey, index)
          const nextItemId = incrementUuidV4(cartItem.itemId, index)
          return mapExperienceCartItemToTicketItemView(
            ticketItem.item,
            ticketItem.ticket,
            nextTransactionKey,
            nextItemId,
            {
              isBookDates: cartItem.isBookingBNBL,
              orderItemId: ticketItem.orderItemId,
              hasLoyaltyDiscount: ticketItem.hasLoyaltyDiscount,
            },
          )
        })

        // now get the summary versions (This summerises to the ticket level)
        const ticketSummaryViews = cartItem.tickets.map(ticketItem => {
          const key = getCartTicketKey(ticketItem, requiresTime ? cartItem.time : undefined)
          const ticket = ticketsByKey.get(key)
          return mapExperienceCartItemToSummaryViews(ticketItem, ticket, { isBookDates: cartItem.isBookingBNBL })
        }).filter(isNonNullable)

        const price = sum(ticketSummaryViews, tv => tv.price)
        const value = sum(ticketSummaryViews, tv => tv.value)
        const taxesAndFees = sum(ticketSummaryViews, tv => tv.taxesAndFees)

        const appPriceTickets = ticketSummaryViews.filter(tv => tv.discounts.app.amount > 0)
        const appDiscountAmount = sum(appPriceTickets, tv => tv.discounts.app.amount)
        const appDiscountPercentage = appDiscountAmount > 0 ? sum(appPriceTickets, tv => tv.discounts.app.percentage) / appPriceTickets.length : 0

        // The code below assumes that all the selected tickets will always be from the same slot
        const ticketsKeys = new Set(ticketSummaryViews.map(ticketView => `${ticketView.ticketId}-${ticketView.date}`))
        const currentSession = timeSlots.find(slot => slot.tickets.some(ticket => ticketsKeys.has(`${ticket.id}-${ticket.date}`)))
        let isSessionPurchaseLimitReached = false

        if (currentSession) {
          const ticketsCounts = countByAsRecord(ticketViews, view => view.ticket.id)
          isSessionPurchaseLimitReached = !getTotalSeats(currentSession, ticketsCounts).isWithinSessionLimit
        }

        const itemView: App.Checkout.ExperienceItemView = {
          item: cartItem,
          luxLoyaltyProductType: getLuxLoyaltyProductType(experience),
          id: experience.id,
          type: experience.productType,
          experience,
          experienceId: experience.id,
          primaryCategory: (experience.categories.find(cat => cat.level === 'parent') ?? experience.categories[0])?.name,
          categories: experience.categories,
          location: experience.location.name,
          latitude: experience.location.latitude,
          longitude: experience.location.longitude,
          timezone: experience.location.timezone,
          externalImage: experience.images[0],
          totals: {
            price,
            memberPrice: 0,
            value,
            mobileAppDiscount: {
              amount: appDiscountAmount,
              percentage: appDiscountPercentage,
            },
            taxesAndFees,
            surcharge: 0,
            memberValue: 0,
            extraGuestSurcharge: 0,
          },
          discount: calculateDiscount(price, value),
          propertyFees: 0,
          title: experience.name,
          ticketViews,
          ticketSummaryViews,
          leExclusive: experience.leExclusive && config.BRAND === 'luxuryescapes',
          freeCancellation: experience.cancellationPolicies.length > 0,
          pickupPoint: cartItem.pickupPointId ? experience.pickupPoints.find(pp => pp.id === cartItem.pickupPointId) : undefined,
          language: cartItem.languageId ? experience.languages.find(l => l.id === cartItem.languageId) : undefined,
          bookingDate: experience.hasCalendar ? cartItem.date : undefined,
          bookingTime: cartItem.time,
          cancellationPolicies: experience.cancellationPolicies,
          cancellationInfo: experience.copy.cancellationInfo,
          hasCalendar: experience.hasCalendar,
          hasTimeslots: experience.hasTimeslots,
          hideTimeSlots: experience.hideTimeSlots,
          designation: 'Experiences',
          redemptionLocation: cartItem.redemptionLocationId ?
            experience.redemptionLocations.find(rl => rl.id === cartItem.redemptionLocationId) : undefined,
          isBuyNowBookLater: cartItem.isBuyNowBookLater,
          isGift: cartItem.isGift,
          isBookDates: cartItem.isBookingBNBL,
          expirationDate: experience.expirationDate,
          bookByDate: experience.bookByDate,
          isSessionPurchaseLimitReached,
        }

        return itemView
      }
    })

    return {
      hasRequiredData: itemViews.every(Boolean),
      data: itemViews.filter(excludeNullOrUndefined),
    }
  },
)

export default getExperienceItemsView
