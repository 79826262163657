import { useMemo } from 'react'
import { SEARCH_VERTICALS } from 'constants/search'
import { useGlobalHotelsSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalHotelsSearch'
import HotelsTabContent from '../Components/Mobile/TabContents/HotelsTabContent'
import useGlobalSearchVerticalTabBooking from './useGlobalSearchVerticalTabBooking'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import useQueryParams from 'hooks/useQueryParams'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'

function useGlobalSearchVerticalTabHotels(searchVerticalItem?: App.SearchVerticalItem): App.GlobalSearchVerticalTabV2 {
  const queryParams = useQueryParams()
  const {
    globalHotelsSearchDispatch,
    globalHotelsSearchState,
  } = useGlobalHotelsSearchContext(GLOBAL_SEARCH_INITIAL_STATE, queryParams)

  const {
    onSearch,
    context,
  } = useGlobalSearchVerticalTabBooking({
    globalSearchDispatch: globalHotelsSearchDispatch,
    globalSearchState: globalHotelsSearchState,
  })

  return useMemo(() => {
    return {
      isEnabled: !!searchVerticalItem,
      label: searchVerticalItem?.label ?? 'hotels & resorts',
      key: SEARCH_VERTICALS.HOTELS,
      context,
      onSearch,
      ContentComponent: HotelsTabContent,
      Icon: LineBedIcon,
    }
  }, [context, onSearch, searchVerticalItem])
}

export default useGlobalSearchVerticalTabHotels
