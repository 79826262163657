import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { rem } from 'polished'
import zIndex from 'styles/tools/z-index'
import { withRouter, RouteComponentProps } from 'react-router'
import {
  bookingPaymentUrlPattern,
  bookingUrlPattern,
  checkoutPaymentUrlPattern,
  reviewUrlPattern,
  tripPlannerUrlPattern,
  checkoutUrlPattern,
} from 'constants/url'
import { skyCheckModalOpen, quoteEmailModalOpen, showCustomerSignatureModal, cartLinkModalOpen, termsAndConditionsModalOpen } from 'actions/UiActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineArrowLeftIcon from 'components/Luxkit/Icons/line/LineArrowLeftIcon'
import MyCartMenu from 'components/App/Header/HeaderMain/NavigationHeader/MyCartMenu'
import SherlogMenu from 'components/App/Header/HeaderMain/NavigationHeader/SherlogMenu'
import Group from 'components/utils/Group'
import config from 'constants/config'
import MultiCartItemModeToggle from 'components/App/Header/HeaderMain/NavigationHeader/MultiCartItemModeToggle'
import { checkCanViewLuxPlusBenefits, _isActiveMemberInCurrentRegion, isFreePreviewLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import clsx from 'clsx'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { registerRequestInterceptor, registerRequestPreprocessor } from 'api/requestUtils'
import sherLogRequestLogger from 'api/preprocessors/sherLogRequestLogger'
import { logNetworkRequest, logNetworkResponse } from 'actions/SherLogActions'
import sherLogResponseLogger from 'api/interceptors/sherLogResponseLogger'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { themeClassName } from 'lib/theme/themeUtils'
import { isMultiCartEnabled, isSpoofed } from 'selectors/featuresSelectors'
import { cartServicePayloadSelector } from 'checkout/selectors/cartServiceSelectors'
import ShowMarginSpoofModeToggle from './Header/HeaderMain/NavigationHeader/ShowMarginSpoofModeToggle'
import DisableMembershipToggle from './Header/HeaderMain/NavigationHeader/DisableMembershipToggle'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import getCanApplyLoyaltyTransferDiscount from 'luxLoyalty/selectors/checkout/loyalty/getCanApplyLoyaltyTransferDiscount'
import { setLoyaltyDiscountOnTransferItem } from 'actions/CheckoutActions'
import getLuxLoyaltyProgramDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyProgramDisplayName'

const Container = styled(Group)`
  padding: ${rem(8)};
  background-color: ${props => props.theme.palette.messaging.critical.normalBackground};
  position: sticky;
  top: 0;
  z-index: ${zIndex.spoofingWarning};

  &.lux-plus-member {
    background-color: ${props => props.theme.palette.product.luxPlus.background};
  }
`

interface Props extends RouteComponentProps<any> {
  isSpoofed: boolean;
  isStoreMode: boolean;
  fullName: string;
  utmMedium: string;
  quoteEmailModalOpen: Utils.ActionDispatcher<typeof quoteEmailModalOpen>;
  cartLinkModalOpen: Utils.ActionDispatcher<typeof cartLinkModalOpen>;
  termsAndConditionsModalOpen: Utils.ActionDispatcher<typeof termsAndConditionsModalOpen>;
  skyCheckModalOpen: Utils.ActionDispatcher<typeof skyCheckModalOpen>
  showCustomerSignatureModal: Utils.ActionDispatcher<typeof showCustomerSignatureModal>;
  isExperienceOnlyPurchase: boolean;
  isFlightOnlyPurchase: boolean;
  isBundlePurchase: boolean;
  isMultiItemMode: boolean;
  canViewLuxPlusBenefits: boolean;
  hasTravellers: boolean;
  logNetworkRequest: Utils.ActionDispatcher<typeof logNetworkRequest>;
  logNetworkResponse: Utils.ActionDispatcher<typeof logNetworkResponse>;
  setLoyaltyDiscountOnTransferItem: Utils.ActionDispatcher<typeof setLoyaltyDiscountOnTransferItem>;
  isFreePreviewLuxPlusMember: boolean;
  isCartCreated: boolean;
  isActiveMemberInCurrentRegion: boolean;
  disableMembership: boolean;
  isTermsAndConditionsEnabled: boolean;
  isMultiCartEnabled: boolean;
  canApplyLoyaltyTransferDiscount: boolean;
}

class SpoofingWarning extends Component<React.PropsWithChildren<Props>> {
  async componentDidMount() {
    /* forceUpdate has been used exclusively due
    to there being a mismatch between the Client
    and the Server Side rendering. The Client
    does not know about the Auth service - so the App
    hydrating doesn’t add the component styles as it
    doesn’t think they should be there. However running
    forceUpdate will force re-evaluation and ensure
    the styles get added to the DOM. */
    this.forceUpdate()

    if (this.props.isSpoofed) {
      registerRequestPreprocessor(sherLogRequestLogger(this.props.logNetworkRequest))
      registerRequestInterceptor(sherLogResponseLogger(this.props.logNetworkResponse))
    }
  }

  openModal = () => {
    this.props.quoteEmailModalOpen()
  }

  openCartModal = () => {
    this.props.cartLinkModalOpen()
  }

  openTermsAndConditionsModal = () => {
    this.props.termsAndConditionsModalOpen()
  }

  openSkyCheckModal = () => {
    this.props.skyCheckModalOpen()
  }

  openCustomerSignaturenModal = () => {
    this.props.showCustomerSignatureModal()
  }

  addDiscountToLoyaltyTransfer = () => {
    this.props.setLoyaltyDiscountOnTransferItem()
  }

  render() {
    const {
      fullName,
      location,
      isSpoofed,
      utmMedium,
      isBundlePurchase,
      canViewLuxPlusBenefits,
      isFreePreviewLuxPlusMember,
      isCartCreated,
      isActiveMemberInCurrentRegion,
      disableMembership,
      isTermsAndConditionsEnabled,
      isStoreMode,
      isMultiCartEnabled,
      canApplyLoyaltyTransferDiscount,
    } = this.props
    const isStoreStaff = utmMedium === 'popup_store'

    const isPaymentPage = bookingPaymentUrlPattern.test(location.pathname) || checkoutPaymentUrlPattern.test(location.pathname)
    const isBookingPage = bookingUrlPattern.test(location.pathname) || checkoutPaymentUrlPattern.test(location.pathname)
    const isTripPlannerPage = tripPlannerUrlPattern.test(location.pathname)
    const isReviewPage = reviewUrlPattern.test(location.pathname)
    const isCheckoutPage = checkoutUrlPattern.test(location.pathname)
    const showBackButton = isBookingPage || isReviewPage || isCheckoutPage
    const showConfirmationButton = (isBookingPage || isReviewPage || isCheckoutPage) && this.props.hasTravellers
    const showQuoteButton = (isPaymentPage || isReviewPage || isTripPlannerPage) && !isBundlePurchase
    const showTermsAndConditionsButton = isTermsAndConditionsEnabled && isPaymentPage && isStoreStaff
    const showCartLinkButton = isPaymentPage && isCartCreated
    const onBack = () => {
      this.props.history.goBack()
    }

    if (!isSpoofed) {
      return null
    }

    return (
      <Container
        direction="horizontal"
        horizontalAlign="space-between"
        verticalAlign="center"
        gap={8}
        className={clsx(themeClassName('inverse'), { 'lux-plus-member': (canViewLuxPlusBenefits && !disableMembership) })}
      >
        <Group direction="horizontal" gap={8} verticalAlign="center" horizontalAlign="start">
          {showBackButton && <TextButton
            kind="tertiary"
            horizontalOutdent="start"
            startIcon={<LineArrowLeftIcon />}
            onClick={onBack}
          >
            Back
          </TextButton>}
        </ Group>
        <BodyText variant="large" weight="bold" colour="neutral-one" align="center">
          Acting as {fullName} {canViewLuxPlusBenefits && ` - ${isFreePreviewLuxPlusMember ? 'Free Preview ' : ''} ${LUXURY_PLUS.PROGRAM_NAME} Member `}
        </BodyText>
        <Group direction="horizontal" gap={8} verticalAlign="center" horizontalAlign="end">
          {canApplyLoyaltyTransferDiscount && <TextButton kind="primary" onClick={this.addDiscountToLoyaltyTransfer}>Redeem {getLuxLoyaltyProgramDisplayName()} benefit</TextButton>}
          {isActiveMemberInCurrentRegion && <DisableMembershipToggle />}
          {!isStoreMode && <ShowMarginSpoofModeToggle />}
          {config.UNIVERSAL_CHECKOUT_SPOOFED_MULTI_CART_ITEMS_ENABLED && !isCheckoutPage && !isMultiCartEnabled && <MultiCartItemModeToggle />}
          {config.SPOOFING_SEND_CONFIRMATION_EMAIL && showConfirmationButton && <TextButton kind="secondary" onClick={this.openSkyCheckModal}>Send traveller details</TextButton>}
          {showQuoteButton && isStoreStaff && <TextButton kind="secondary" onClick={this.openCustomerSignaturenModal}>Capture Signature</TextButton>}
          {showTermsAndConditionsButton && <TextButton kind="primary" onClick={this.openTermsAndConditionsModal}>Terms and Conditions</TextButton>}
          {showCartLinkButton && <TextButton kind="primary" onClick={this.openCartModal}>Cart Link</TextButton>}
          {showQuoteButton && <TextButton kind="primary" onClick={this.openModal}>Send Quote</TextButton>}
          {config.UNIVERSAL_CHECKOUT_SPOOFED_MULTI_CART_ITEMS_ENABLED && !isMultiCartEnabled && <MyCartMenu /> }
          <SherlogMenu />
        </Group>
      </Container>
    )
  }
}

function isExperienceOnlyPurchase(state: App.State) {
  const items = state.checkout.cart.items
  return items.length ? items.every(item => item.itemType === 'experience') : false
}

function isBundlePurchase(state: App.State) {
  const items = state.checkout.cart.items
  return items.length ? items.some(item => item.itemType === 'bundleAndSave') : false
}

function isFlightOnlyPurchase(state: App.State) {
  const items = state.checkout.cart.items
  return items.length ? items.every(item => item.itemType === 'flight') : false
}

function hasTravellers(state: App.State) {
  const items = state.checkout.cart.items
  const travellers = state.checkout.form.travellerForms
  return travellers.length > 0 && items.length ? items.some(item => item.itemType === 'flight') : false
}

function isCartCreated(state: App.State) {
  const cart = cartServicePayloadSelector(state)
  return !!cart?.payload?.items?.length
}

function mapStateToProps(state: App.State) {
  return ({
    isSpoofed: isSpoofed(state),
    isStoreMode: state.system.storeMode,
    fullName: state.auth.account.fullName,
    utmMedium: state.utm.medium,
    isExperienceOnlyPurchase: isExperienceOnlyPurchase(state),
    isFlightOnlyPurchase: isFlightOnlyPurchase(state),
    isBundlePurchase: isBundlePurchase(state),
    isMultiItemMode: state.checkout.cart.isMultiItemMode,
    hasTravellers: hasTravellers(state),
    isFreePreviewLuxPlusMember: isFreePreviewLuxPlusMember(state),
    isCartCreated: isCartCreated(state),
    isActiveMemberInCurrentRegion: _isActiveMemberInCurrentRegion(state),
    canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
    disableMembership: state.auth.account.luxPlus.member.disableMembership,
    isTermsAndConditionsEnabled: !!state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.opexTermsAndConditionsModal],
    isMultiCartEnabled: isMultiCartEnabled(state),
    canApplyLoyaltyTransferDiscount: getCanApplyLoyaltyTransferDiscount(state),
  })
}

const mapDispatchToProps = {
  cartLinkModalOpen,
  quoteEmailModalOpen,
  termsAndConditionsModalOpen,
  skyCheckModalOpen,
  showCustomerSignatureModal,
  logNetworkRequest,
  logNetworkResponse,
  setLoyaltyDiscountOnTransferItem,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpoofingWarning))
