import qs from 'qs'
import request, { authOptions } from 'api/requestUtils'
import { actions } from '@luxuryescapes/contract-svc-agent'
import wait from 'lib/promise/wait'
import { mapOfferCommission } from './mappers/agentHubMap'

type AgentHubGetOffersCommissionResponse = actions['calculateOffersCommission']['responses']['200']['content']['application/json']['result']
type OfferCommissionBody = actions['calculateOffersCommission']['parameters']['body']['calculateOffersCommissionBody']

export type AgentHubGetOffersCommissionBody = {
  offerId: string
  price: number
  location: Array<string>
  type: App.ProductType | App.OfferType
  vendor: string
  packages: Array<{
    packageId: string
    mx: string
  }>
}

let offerFetchAccumulator: OfferCommissionBody['offers'] = []
let offerFetchPromise: Promise<{ [offerId: string]: App.AgentHubOfferCommission }> | undefined

export async function getOffersCommission(offers: OfferCommissionBody['offers'], regionCode: string): Promise<Record<string, App.AgentHubOfferCommission>> {
  const query = qs.stringify({
    region: regionCode,
  })

  const body: OfferCommissionBody = {
    offers,
  }

  const response = await request.post<App.ApiResponse<AgentHubGetOffersCommissionResponse>, OfferCommissionBody>(`/api/agent/calculate-offers-commission?${query}`, body, authOptions())
  const offersCommission = response.result.reduce<Record<string, App.AgentHubOfferCommission>>((acc, offerCommission) => {
    acc[offerCommission.offerId] = mapOfferCommission(offerCommission)
    return acc
  }, {})

  return offersCommission
}

export async function getAccumulatedOfferCommissionById(offerId: string, offerCommission: AgentHubGetOffersCommissionBody, regionCode: string) {
  offerFetchAccumulator.push(offerCommission)
  if (!offerFetchPromise) {
    offerFetchPromise = wait(32).then(async() => {
      const offersToFetch = offerFetchAccumulator
      offerFetchAccumulator = []
      offerFetchPromise = undefined

      const offers = await getOffersCommission(offersToFetch, regionCode)

      return offers
    })
  }

  return offerFetchPromise.then((offers) => offers[offerId])
}
