import { createSelector } from 'reselect'
import { sum } from 'lib/array/arrayUtils'
import getExperienceItems from '../view/getExperienceItems'

const getExperienceTicketCount = (experience: App.Checkout.ExperienceItem) => {
  return sum(experience.tickets, ticket => ticket.count)
}

const getInsuranceExperienceOccupants = createSelector(
  (state: App.State) => getExperienceItems(state),
  (experiences): App.Occupants => {
    return {
      adults: sum(experiences, getExperienceTicketCount),
      children: 0,
      infants: 0,
      childrenAge: [],
    }
  },
)

export default getInsuranceExperienceOccupants
