import React from 'react'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import styled from 'styled-components'
import CSSBreakpoint from '../../utils/CSSBreakpoint'

const CheckBoxSkeleton = styled(LoadingBox)`
  height: 1.5rem;
  width: 1.5rem;
`

const TextSkeleton = styled(LoadingBox)`
  height: 24px;
  flex: 1;
`

const HeaderSkeleton = styled(LoadingBox)`
  height: 24px;
  width: 188px;
`

const FilterGroupSkeleton = styled(Group)`
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};
  padding: 16px 0;
`

function HotelSearchFilterPaneLoadingSkeleton() {
  const filterSkeletonRows = [5, 3, 3, 5, 10, 5]
  const filterSkeletonGroups = new Array(filterSkeletonRows.length).fill(undefined)

  return (
    <CSSBreakpoint min="tablet">
      <VerticalSpacer gap={2}>
        {filterSkeletonGroups.map((_, i) => (
          <FilterGroupSkeleton direction="vertical" gap={20} key={i}>
            <HeaderSkeleton />
            {[...Array(filterSkeletonRows[i])].map((_, ri) => (
              <Group direction="horizontal" verticalAlign="center" gap={12} key={ri}>
                <CheckBoxSkeleton />
                <TextSkeleton />
              </Group>
            ))}
          </FilterGroupSkeleton>
        ))}
      </VerticalSpacer>
    </CSSBreakpoint>
  )
}

export default HotelSearchFilterPaneLoadingSkeleton
