import React, { useContext, useEffect, useMemo, useState } from 'react'
import ModalContext from 'contexts/ModalContext'
import LuxPlusFreePreviewAutoModal from 'luxPlus/components/modals/LuxPlusFreePreviewAutoModal'
import { useAppSelector } from 'hooks/reduxHooks'
import { checkCanRedeemLuxPlusBenefits, isFreePreviewLuxPlusMember, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import { selectLoggedIn } from 'selectors/accountSelectors'
import MasterModalContext from 'contexts/MasterModalContext'
import {
  get as getLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import useQueryParams from 'hooks/useQueryParams'
import { matchPath } from 'react-router'
import config from 'constants/config'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import LuxPlusFreePreviewAutoModalInclusionsVariant from 'luxPlus/components/modals/LuxPlusFreePreviewAutoModalInclusionsVariant'
import LuxPlusFreePreviewAutoModalShortVariant from 'luxPlus/components/modals/LuxPlusFreePreviewAutoModalShortVariant'

const pathsBlacklist = [
  '/:region/checkout',
  '/:region/booking/confirmation',
  `/:region/${LUXURY_PLUS_PATHS.DEFAULT_LANDING_PAGE}`,
]

function useLuxPlusFreePreviewAutoModal() {
  const showModal = useContext(ModalContext)
  const loggedIn = useAppSelector(selectLoggedIn)
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const isDismissed = getLocalStorage(LUXURY_PLUS.DISMISS_FREE_PREVIEW_STORAGE_KEY)
  const modalState = useContext(MasterModalContext)
  const queryParams = useQueryParams()
  const freePreviewMember = useAppSelector(isFreePreviewLuxPlusMember)
  const canRedeemLuxPlusBenefits = useAppSelector(checkCanRedeemLuxPlusBenefits)
  const isSpoofed = useAppSelector(state => state.auth.account.isSpoofed)
  const alreadyEnrolled = freePreviewMember || canRedeemLuxPlusBenefits
  const isNewUser = useAppSelector(state => state.luxLoyalty.enrolment === 'newUserRegistration')

  const [canShowModal, setCanShowModal] = useState(false)

  const path = useAppSelector(state => state.router.location.pathname)
  const isModalPreventedPage = !!matchPath(path, {
    path: pathsBlacklist,
    exact: false,
  })

  const isUserEligible = config.LUXURY_PLUS_FREE_PREVIEW_MODAL && loggedIn && luxPlusEnabled && !isDismissed && !alreadyEnrolled && !isSpoofed && isNewUser
  const shouldShowModal = isUserEligible && !modalState.open && !queryParams.get(LUXURY_PLUS.FREE_PREVIEW_AUTO_ENROLL_QUERY_PARAM) && !isModalPreventedPage

  const freePreviewAfterSignUpLogInVariant = useOptimizelyExperiment(OptimizelyExperiments.croLPFreePreviewAfterSignUpLogIn, shouldShowModal)

  const FreePreviewModalVariant = useMemo(() => {
    if (freePreviewAfterSignUpLogInVariant === 'variant_1') return <LuxPlusFreePreviewAutoModalInclusionsVariant />
    if (freePreviewAfterSignUpLogInVariant === 'variant_2') return <LuxPlusFreePreviewAutoModalShortVariant />
    return <LuxPlusFreePreviewAutoModal />
  }, [freePreviewAfterSignUpLogInVariant])

  // Only show modal if user comes from a logged out state
  useEffect(() => {
    if (!loggedIn) {
      setCanShowModal(true)
    }
  }, [loggedIn])

  useEffect(() => {
    if (shouldShowModal && canShowModal) {
      showModal(FreePreviewModalVariant)
    }
  }, [shouldShowModal, showModal, FreePreviewModalVariant, canShowModal])
}

export default useLuxPlusFreePreviewAutoModal
