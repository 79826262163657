import { groupBy } from 'lib/array/arrayUtils'

export function groupByBundlePackages(offer: App.BundleOffer, packages: Array<App.HotelPackage>): { [bundlePackageId: string]: Array<App.HotelPackage> } {
  const m = new Map<string, Array<App.HotelPackage>>()

  const groupedPackages = Object.fromEntries(groupBy(packages, (p) => `${p.offerId}:${p.lePackageId}:${p.roomRate?.id}`).entries())
  for (const bundlePackageId of Object.keys(offer.bundlePackages)) {
    const options = offer.bundlePackages[bundlePackageId].options
    const bundlePackageOptions = options.map((o) => groupedPackages[`${o.fkOfferId}:${o.lePackageId}:${o.fkRoomRateId}`])

    m.set(bundlePackageId, bundlePackageOptions.every((o) => o?.length > 0) ? bundlePackageOptions.flat() : [])
  }

  return Object.fromEntries(m.entries())
}
