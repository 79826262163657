import Group from 'components/utils/Group'
import React, { ComponentProps } from 'react'
import PriceRowPrice from './PriceRowPrice'
import PriceRowLuxPlusPrice from './LuxPlus/PriceRowLuxPlusPrice'
import clsx from 'clsx'
import styled from 'styled-components'
import PriceRowLuxPlusNonMemberPrice from './LuxPlus/PriceRowLuxPlusNonMemberPrice'

const Stack = styled(Group)`
  &.h-align-start {
    text-align: start;
  }
  &.h-align-center {
    text-align: center;
  }
  &.h-align-end {
    text-align: end;
  }
`

type InheritedPriceRowPriceProps = Pick<ComponentProps<typeof PriceRowPrice>, 'saleUnit' | 'size' | 'signDisplay'>

interface Props extends InheritedPriceRowPriceProps {
  memberPrice: number
  nonMemberPrice: ComponentProps<typeof PriceRowPrice>['price']
  /**
   * @default start
   */
  horizontalAlign?: 'start' | 'center' | 'end';
  desktopHorizontalAlign?: 'start' | 'center' | 'end';
  testid?: string;
  optimizelyExperiment?: string;
  orientation?: 'vertical' | 'horizontal';
}

const SIZES_NON_MEMBER_SIZE: Record<Props['size'], ComponentProps<typeof PriceRowLuxPlusNonMemberPrice>['size']> = {
  S: 'M',
  M: 'M',
  L: 'L',
}

function PriceStackPriceWithLoyaltyLeadWithLuxPlusPrice(props: Props) {
  const {
    memberPrice,
    nonMemberPrice,
    saleUnit,
    size,
    horizontalAlign = 'start',
    desktopHorizontalAlign,
    signDisplay,
    orientation,
    ...rest
  } = props

  return <Stack
    {...rest}
    className={clsx(PriceStackPriceWithLoyaltyLeadWithLuxPlusPrice.name, `h-align-${horizontalAlign}`)}
    direction="vertical"
    horizontalAlign={horizontalAlign}
    gap={2}
    desktopHorizontalAlign={desktopHorizontalAlign}
  >
    <PriceRowLuxPlusPrice
      memberPrice={memberPrice}
      saleUnit={saleUnit}
      logoPlacement="trailing"
      size={size}
      signDisplay={signDisplay}
    />
    <PriceRowLuxPlusNonMemberPrice
      nonMemberPrice={nonMemberPrice}
      size={SIZES_NON_MEMBER_SIZE[size]}
    />
  </Stack>
}

export default PriceStackPriceWithLoyaltyLeadWithLuxPlusPrice
