import requestUtils from 'api/requestUtils'
import { BusinessResponses } from '@luxuryescapes/contract-business'
import qs from 'qs'
import { unique } from 'lib/array/arrayUtils'

function mapEmployees(serverData: BusinessResponses.EmployeeListIndex): Array<App.BusinessTraveller.Employee> {
  const employees = serverData.result.map((employee) => {
    return {
      customerId: employee.customerId,
      email: employee.email,
      firstName: employee.firstName,
      id: employee.id,
      lastName: employee.lastName,
      position: employee.position,
      roles: unique(employee.roles),
    }
  })

  return employees
}

interface Params {
  businessId: string;
  limit?: number,
  page?: number
}

async function requestGetEmployees(params: Params) {
  const { businessId, limit = 100, page = 0 } = params

  const queryParams = qs.stringify({
    limit,
    page,
    status: 'ACTIVATED',
  })

  try {
    const response = await requestUtils.get<BusinessResponses.EmployeeListIndex>(
      `/api/business-traveller/businesses/${businessId}/employees?${queryParams}`,
      { credentials: 'include' },
    )
    return mapEmployees(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default requestGetEmployees
