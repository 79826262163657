import Group from 'components/utils/Group'
import React, { ComponentProps } from 'react'
import PriceRowPrice from './PriceRowPrice'
import PriceRowLuxPlusPrice from './LuxPlus/PriceRowLuxPlusPrice'
import clsx from 'clsx'
import styled from 'styled-components'

const Stack = styled(Group)`
  &.h-align-start {
    text-align: start;
  }
  &.h-align-center {
    text-align: center;
  }
  &.h-align-end {
    text-align: end;
  }
`

type InheritedPriceRowPriceProps = Pick<ComponentProps<typeof PriceRowPrice>, 'saleUnit' | 'size' | 'signDisplay'>

interface Props extends InheritedPriceRowPriceProps {
  memberPrice: number
  nonMemberPrice: ComponentProps<typeof PriceRowPrice>['price']
  /**
   * @default start
   */
  horizontalAlign?: 'start' | 'center' | 'end';
  desktopHorizontalAlign?: 'start' | 'center' | 'end';
  testid?: string;
}

function PriceStackPriceWithLoyalty(props: Props) {
  const {
    memberPrice,
    nonMemberPrice,
    saleUnit,
    size,
    horizontalAlign = 'start',
    desktopHorizontalAlign,
    signDisplay,
    ...rest
  } = props

  return <Stack
    {...rest}
    className={clsx(PriceStackPriceWithLoyalty.name, `h-align-${horizontalAlign}`)}
    direction="vertical"
    horizontalAlign={horizontalAlign}
    gap={2}
    desktopHorizontalAlign={desktopHorizontalAlign}
  >
    <PriceRowPrice
      price={nonMemberPrice}
      saleUnit={saleUnit}
      size={size}
      signDisplay={signDisplay}

    />
    <PriceRowLuxPlusPrice
      memberPrice={memberPrice}
      saleUnit={saleUnit}
      logoPlacement="trailing"
      size={size}
      signDisplay={signDisplay}
    />
  </Stack>
}

export default PriceStackPriceWithLoyalty
