import { get, set } from 'lib/storage/versionedLocalStorageUtils'
import moment from 'moment'

const SITE_BANNER_DISMISSALS_KEY = 'siteBannerDismissals'
const SITE_BANNER_DISMISSALS_VERSION = 1

interface DismissalState {
  endDate: string
}

function getDismissals(): Record<string, DismissalState> {
  return get(SITE_BANNER_DISMISSALS_KEY, SITE_BANNER_DISMISSALS_VERSION) ?? {}
}

export function recordSiteBannerDismissal(bannerId: string, bannerEndDate: string) {
  const dismissals = getDismissals()

  // Clear out old irrelevant dismissals
  const now = moment()
  Object.keys(dismissals).forEach(id => {
    const expiry = moment(dismissals[id].endDate)
    if (now.diff(expiry, 'days') > 1) {
      delete dismissals[id]
    }
  })

  dismissals[bannerId] = { endDate: bannerEndDate }
  set(SITE_BANNER_DISMISSALS_KEY, SITE_BANNER_DISMISSALS_VERSION, dismissals)
}

export function isBannerDismissed(bannerId: string): boolean {
  const dismissals = getDismissals()
  return !!dismissals[bannerId]
}

export function getDismissedBannerIds(): Set<string> {
  const dismissals = getDismissals()
  return new Set(Object.keys(dismissals))
}
