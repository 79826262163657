import React from 'react'

import ViewContext from './ViewContext'
import ViewState, { ViewAction } from './ViewState'

interface Props {
  children: React.ReactNode
  dispatch: React.Dispatch<ViewAction>
  state: ViewState
}

export default function ViewContextProvider(props: Props) {
  const { children, dispatch, state } = props

  return (
    <ViewContext.Provider value={{ state, dispatch }}>
      {children}
    </ViewContext.Provider>
  )
}
