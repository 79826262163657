/* eslint-disable no-restricted-imports */
import { shallowEqual as rrShallowEqual, useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import { AppDispatch } from '../store'

// Optionally use these instead of 'useDispatch` and `useAppSelector` to avoid having to manually type them
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<App.State> = useSelector

/**
 * Alternate typing for react-redux's `shallowEqual` to play nicely with type inference.
 *
 * Since react-redux's `shallowEqual` is typed as `(objA: any, objB: any): boolean`,
 * using it with `useSelector` or `useAppSelector` will cause the return type of the
 * selector to be inferred as `any`. This version won't cause that problem.
 */
export const shallowEqual = rrShallowEqual as <T>(a: T, b: T) => boolean
