import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { PackageOption_1_0_1 } from '@luxuryescapes/contract-data-event-schemas/lib/com.luxgroup'
import { convertDateByTimezone } from '@luxuryescapes/lib-refunds/build/lib/formatDate'
import { filterDefinedProperties } from 'analytics/snowplow/helpers'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'

export const getBedbankRateItemContext = (
  offer: App.BedbankOffer | App.BedbankOfferSummary,
  rate: App.BedbankRate,
  price: number,
  value: number,
  checkIn: string,
  checkOut: string,
  occupancy: Array<App.Occupants>,
  currency: string,
) => {
  const offerId = offer.id
  const rateId = rate.id
  const propertyFees = rate.totals.propertyFees ?? 0
  const numberOfAdults = occupancy[0].adults
  const numberOfChildren = occupancy[0].children
  const numberOfInfants = occupancy[0].infants
  const { categoryId, productId } = getCategoryAndProductId(offer.parentType, offer)

  return filterDefinedProperties([
    comluxgroup.createItem_1_1_0({
      offerId,
      categoryId,
      productId,
      typeId: rate.roomId,
      price,
      value,
      propertyFees,
      currency,
      travelStart: checkIn,
      travelEnd: checkOut,
      numberOfAdults,
      numberOfChildren,
      numberOfInfants,
      rateId,
    }),
  ])
}

export const getBedbankRateListContext = (
  ratePosition: number,
  rateListId: string,
  roomPosition: number,
  roomListId: string,
) => {
  return filterDefinedProperties([
    comluxgroup.createListItem_1_0_0({
      itemPosition: ratePosition,
      listInstance: rateListId,
      listName: 'PackageOptionList - Hotels - Bedbank rates',
    }),
    comluxgroup.createListItem_1_0_0({
      itemPosition: roomPosition,
      listInstance: roomListId,
      listName: 'PackageList - Hotels - Bedbank rooms',
    }),
  ])
}

export const getBedbankRateContext = (
  rate: App.BedbankRate,
  isFlightBundle: boolean, // rate.isFlightBundle is not reliable, so we pass it explicitly
) => {
  const rateId = rate.id

  const cancellationPolicy = {
    refundable: rate.refundable,
    partiallyRefundable: rate.partiallyRefundable,
    penalties: rate.cancellationPolicy.policies.flatMap<PackageOption_1_0_1['cancellationPolicy']['penalties'][number]>((cp) => {
      try {
        const start = convertDateByTimezone(cp.start, cp.timezone).toISOString()
        const end = convertDateByTimezone(cp.end, cp.timezone).toISOString()
        return {
          start,
          end,
          ...(cp.amount ? { amount: cp.amount } : {}), // cp either have amount or nights or percent
          ...(cp.nights ? { nights: cp.nights } : {}),
          ...(cp.percent ? { percent: cp.percent } : {}),
          currency: cp.currency,
        }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_) {
        // can't parse the date, skip this entry
        return []
      }
    }),
  }

  const inclusions = rate.inclusions.map(x => x.description)

  return filterDefinedProperties([
    comluxgroup.createPackageOption_1_0_1({
      id: rateId,
      supplier: rate.supplierCode,
      cancellationPolicy,
      inclusions,
      isFlightBundle,
    }),
  ])
}
