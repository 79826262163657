import { dateDifference } from 'lib/datetime/dateUtils'
import { getTourV2DepositMinimumValueForCurrencyCode } from 'lib/payment/depositsUtils'
import { TOUR_V2_DEPOSIT_MINIMUM_VALUE_PER_CURRENCY_CODE } from 'constants/checkout'
import { TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD } from 'constants/tours'
import config from 'constants/config'

export function isTourV2DepositEnabledForCurrency(currencyCode: string) {
  return config.DEPOSITS_ENABLED && Object.keys(TOUR_V2_DEPOSIT_MINIMUM_VALUE_PER_CURRENCY_CODE).includes(currencyCode)
}

export function isTourV2DepositValueAllowed(price: number, currencyCode: string): boolean {
  return price >= getTourV2DepositMinimumValueForCurrencyCode(currencyCode)
}

export function isTourV2DepositDateAllowed(startDate: string, depositNumberOfDays: number): boolean {
  const days = dateDifference(new Date(startDate)).days
  return days >= depositNumberOfDays
}

export default function isTourV2DepositAllowed(startDate: string, price: number, currencyCode: string, depositType: App.Tours.V2OfferDepositType, depositNumberOfDays: number | undefined): boolean {
  return config.DEPOSITS_ENABLED &&
    isTourV2DepositEnabledForCurrency(currencyCode) &&
    isTourV2DepositDateAllowed(startDate, depositNumberOfDays ?? TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD) &&
    isTourV2DepositValueAllowed(price, currencyCode) &&
    depositType !== 'noDeposit'
}
