import React from 'react'
import BedbankSearchTileWalledContent from './BedbankSearchTileWalledContent'
import BedbankSearchTileSignUpButton from './BedbankSearchTileSignUpButton'
import { EmptyObject } from 'lib/object/objectUtils'
import SearchTilePrimaryActionButton from '../shared/SearchTilePrimaryActionButton'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  filters?: App.OfferListFilters
  utmBp?: string
}

function BedbankSearchTileAction({
  offer,
  filters = EmptyObject,
  utmBp,
}: Props) {
  // Only on bedbank titles when bp=2 is set and the user isn’t logged in. Clicking on “Login for member savings” triggers the login modal.
  const isLoginForMemberSavings = utmBp === '2'

  return <BedbankSearchTileWalledContent
    wall={<BedbankSearchTileSignUpButton isLoginForMemberSavings={isLoginForMemberSavings} />}
    offer={offer}
    filters={filters}
    hidePricing={isLoginForMemberSavings || offer.hidePricing}
  >
    <SearchTilePrimaryActionButton />
  </BedbankSearchTileWalledContent>
}

export default BedbankSearchTileAction
