import clsx from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

export const StyledModalContent = styled.div`
  padding: ${rem(20)};

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)};
  }

  &.flush {
    padding-inline: 0;
  }

  &:empty {
    display: none;
  }

  & + & {
    padding-top: 0;
  }
`

interface ModalContentProps extends PropsWithChildren {
  className?: string
  /**
   * Removes horizontal padding
   */
  flush?: boolean
}

/**
 * @description This component is the wrapper for modal content that applies proper paddings.
 * It should never come before `ModalHeader.tsx` or after `ModalFooter.tsx`.
 * @see `Modal.tsx` for usage
 * */
function ModalContent({ children, className, flush }: ModalContentProps) {
  return <StyledModalContent className={clsx(className, { flush })}>
    {children}
  </StyledModalContent>
}

export default ModalContent
