import { exceedsTicketAvailability } from 'lib/checkout/experiences/cart'

function calculateValue(price: number, discountPercentage: number) {
  const priceFraction = 1 - (discountPercentage / 100)
  if (priceFraction === 0) { return 0 }
  return price / priceFraction
}

interface TicketViewOptions {
  isBookDates?: boolean;
  orderItemId?: string;
}

export function mapExperienceCartItemToSummaryViews(
  cartTicketItem: App.Checkout.ExperienceItemTicket,
  ticket: App.ExperienceItemTicket | undefined,
  options: TicketViewOptions = {},
): App.Checkout.ExperienceItemTicketSummaryView | undefined {
  const { ticketId, count } = cartTicketItem

  const ticketPrice = ticket?.price ?? 0
  // If we're just booking dates, then it's already paid for and price is 0
  let price = options.isBookDates ? 0 : ticketPrice * cartTicketItem.count
  if (cartTicketItem.loyaltyTransferDiscount && price) {
    // this is our special loyalty transfer discount, so make one of the items 'free'
    price -= ticketPrice
  }

  if (cartTicketItem.count === 0) {
    return undefined
  }

  const exceededLimit = ticket ? (exceedsTicketAvailability(count, ticket.purchaseLimit.max)) : false

  return {
    ticketId,
    name: ticket?.name ?? cartTicketItem.name,
    fareType: ticket?.fareType ?? '',
    price,
    value: calculateValue(price, ticket?.discounts.base.amount ?? 0),
    discounts: {
      base: {
        amount: ticket?.discounts.base.amount ?? 0,
        percentage: ticket?.discounts.base.percentage ?? 0,
      },
      app: {
        amount: ticket?.discounts.app.amount ?? 0,
        percentage: ticket?.discounts.app.percentage ?? 0,
      },
    },
    count,
    unavailable: !ticket || exceededLimit,
    purchaseLimit: ticket?.purchaseLimit ?? { min: 1, max: 9999, maxReason: 'AVAILABILITY' },
    taxesAndFees: ticket?.taxesAndFees ?? 0,
    participantsPerUnit: ticket?.participantsPerUnit ?? 1,
    date: ticket?.date ?? '',
    time: ticket?.time ?? '',
  }
}
