import React, { forwardRef, ReactNode } from 'react'
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height'

interface Props extends Omit<AnimateHeightProps, 'height' | 'children'> {
  show: boolean
  children: ReactNode
  animateOpacity?: boolean
}

/**
 * @deprecated use `<Expander>` instead
 */
const SlideDown = forwardRef<HTMLDivElement, Props>(function SlideDown(
  { show, ...props },
  ref,
) {
  return <AnimateHeight
    {...props}
    height={show ? 'auto' : 0}
    ref={ref}
  />
})

export default SlideDown
