import { shade, tint, transparentize } from 'polished'
import base from './base'
import { BLUE, BRONZE, GOLD, GREY, LIME_GREEN, ORANGE, PLATINUM, PURPLE, RED, TEAL } from './primitiveColours'
import { baseThemeShadows } from './shadows'

const brandPalette: Record<App.Design.PaletteType, App.Design.BrandPalette<string>> = {
  default: {
    primary: {
      normal: GREY[800],
      darkest: shade(0.24, '#363A45'),
      dark: shade(0.16, '#363A45'),
      light: tint(0.76, '#363A45'),
      lightest: tint(0.86, '#363A45'),
      hover: tint(0.16, '#363A45'),
      active: tint(0.24, '#363A45'),
    },
  },
  inverse: {
    primary: {
      normal: '#FFFFFF',
      darkest: shade(0.24, '#FFFFFF'),
      dark: shade(0.16, '#FFFFFF'),
      light: tint(0.76, '#FFFFFF'),
      lightest: tint(0.86, '#FFFFFF'),
      hover: tint(0.16, '#FFFFFF'),
      active: tint(0.24, '#FFFFFF'),
    },
  },
}

const neutralPalette: Record<App.Design.PaletteType, App.Design.NeutralPalette<string>> = {
  default: {
    default: {
      one: GREY[800],
      one20: transparentize(0.2, GREY[800]),
      two: GREY[700],
      three: GREY[500],
      four: GREY[300],
      five: GREY[200],
      five40: transparentize(0.4, GREY[200]),
      six: GREY[100],
      six25: transparentize(0.25, GREY[100]),
      seven: GREY[50],
      eight: GREY[0],
      clear: transparentize(0.84, GREY[0]),
    },
    control: {
      black100: '#000000',
      black25: transparentize(0.25, '#000000'),
      white16: transparentize(0.16, '#FFFFFF'),
      white24: transparentize(0.24, '#FFFFFF'),
    },
  },
  inverse: {
    default: {
      one: GREY[0],
      one20: transparentize(0.2, GREY[0]),
      two: GREY[50],
      three: GREY[100],
      four: GREY[200],
      five: GREY[300],
      five40: transparentize(0.4, GREY[300]),
      six: GREY[500],
      six25: transparentize(0.25, GREY[500]),
      seven: GREY[700],
      eight: GREY[800],
      clear: transparentize(0.84, GREY[0]),
    },
    control: {
      black100: '#000000',
      black25: transparentize(0.25, '#000000'),
      white16: transparentize(0.16, '#FFFFFF'),
      white24: transparentize(0.24, '#FFFFFF'),
    },
  },
}

const productPalette: Record<App.Design.PaletteType, App.Design.ProductPalette<string>> = {
  default: {
    exclusive: {
      foreground: GOLD[600],
      background: GOLD[500],
      contrast: GREY[0],
    },
    lastMinute: {
      foreground: RED[600],
      background: RED[500],
      contrast: GREY[0],
    },
    partner: {
      foreground: GREY[500],
      background: GREY[500],
      contrast: GREY[0],
    },
    premium: {
      foreground: GREY[700],
      background: GREY[700],
      contrast: GREY[0],
    },
    ultralux: {
      foreground: GOLD[600],
      background: GOLD[500],
      contrast: GREY[0],
      active: shade(0.24, GOLD[500]),
      hover: shade(0.16, GOLD[500]),
    },
    luxPlus: {
      foreground: BLUE[700],
      altForeground: BLUE[700],
      background: BLUE[700],
      contrast: GREY[0],
      active: shade(0.24, BLUE[700]),
      altActive: shade(0.24, BLUE[700]),
      hover: shade(0.16, BLUE[700]),
      altHover: shade(0.16, BLUE[700]),
    },
    luxLoyalty: {
      bronze: {
        gradient: `linear-gradient(45deg, ${BRONZE[300]} 0%, ${BRONZE[50]} 50%, ${BRONZE[300]} 100%)`,
        darkBackground: BRONZE[500],
        darkContrast: GREY[0],
        darkForeground: BRONZE[500],
        lightBackground: BRONZE[100],
        lightContrast: BRONZE[600],
        lightForeground: BRONZE[100],
        normalBackground: BRONZE[300],
        normalContrast: GREY[950],
        normalForeground: BRONZE[300],
      },
      silver: {
        gradient: `linear-gradient(45deg, ${GREY[300]} 0%, ${GREY[100]} 50%, ${GREY[300]} 100%)`,
        darkBackground: GREY[500],
        darkContrast: GREY[0],
        darkForeground: GREY[500],
        lightBackground: GREY[100],
        lightContrast: GREY[600],
        lightForeground: GREY[100],
        normalBackground: GREY[300],
        normalContrast: GREY[950],
        normalForeground: GREY[300],
      },
      gold: {
        gradient: `linear-gradient(45deg, ${GOLD[300]} 0%, ${GOLD[100]} 50%, ${GOLD[300]} 100%)`,
        darkBackground: GOLD[600],
        darkContrast: GREY[0],
        darkForeground: GOLD[500],
        lightBackground: GOLD[100],
        lightContrast: GOLD[600],
        lightForeground: GOLD[100],
        normalBackground: GOLD[300],
        normalContrast: GREY[950],
        normalForeground: GOLD[300],
      },
      platinum: {
        gradient: `linear-gradient(45deg, ${PLATINUM[500]} 0%, ${PLATINUM[300]} 50%, ${PLATINUM[500]} 100%)`,
        darkBackground: PLATINUM[500],
        darkContrast: GREY[0],
        darkForeground: PLATINUM[500],
        lightBackground: PLATINUM[100],
        lightContrast: PLATINUM[600],
        lightForeground: PLATINUM[100],
        normalBackground: PLATINUM[300],
        normalContrast: GREY[950],
        normalForeground: PLATINUM[300],
      },
    },
  },
  inverse: {
    exclusive: {
      foreground: GOLD[300],
      background: GOLD[500],
      contrast: GREY[0],
    },
    lastMinute: {
      foreground: RED[200],
      background: RED[500],
      contrast: GREY[0],
    },
    partner: {
      foreground: GREY[200],
      background: GREY[500],
      contrast: GREY[0],
    },
    premium: {
      foreground: GREY[300],
      background: GREY[700],
      contrast: GREY[0],
    },
    ultralux: {
      foreground: GOLD[300],
      background: GOLD[500],
      contrast: GREY[0],
      active: shade(0.24, GOLD[500]),
      hover: shade(0.16, GOLD[500]),
    },
    luxPlus: {
      foreground: BLUE[300],
      altForeground: GREY[0],
      background: BLUE[700],
      contrast: GREY[0],
      active: shade(0.24, BLUE[700]),
      altActive: shade(0.24, GREY[0]),
      hover: shade(0.16, BLUE[700]),
      altHover: shade(0.16, GREY[0]),
    },
    luxLoyalty: {
      bronze: {
        gradient: `linear-gradient(45deg, ${BRONZE[300]} 0%, ${BRONZE[50]} 50%, ${BRONZE[300]} 100%)`,
        darkBackground: BRONZE[500],
        darkContrast: GREY[0],
        darkForeground: BRONZE[200],
        lightBackground: BRONZE[100],
        lightContrast: BRONZE[600],
        lightForeground: BRONZE[100],
        normalBackground: BRONZE[300],
        normalContrast: GREY[950],
        normalForeground: BRONZE[300],
      },
      silver: {
        gradient: `linear-gradient(45deg, ${GREY[300]} 0%, ${GREY[100]} 50%, ${GREY[300]} 100%)`,
        darkBackground: GREY[500],
        darkContrast: GREY[0],
        darkForeground: GREY[200],
        lightBackground: GREY[100],
        lightContrast: GREY[600],
        lightForeground: GREY[100],
        normalBackground: GREY[300],
        normalContrast: GREY[950],
        normalForeground: GREY[300],
      },
      gold: {
        gradient: `linear-gradient(45deg, ${GOLD[300]} 0%, ${GOLD[100]} 50%, ${GOLD[300]} 100%)`,
        darkBackground: GOLD[400],
        darkContrast: GREY[0],
        darkForeground: GOLD[200],
        lightBackground: GOLD[100],
        lightContrast: GOLD[600],
        lightForeground: GOLD[100],
        normalBackground: GOLD[300],
        normalContrast: GREY[950],
        normalForeground: GOLD[300],
      },
      platinum: {
        gradient: `linear-gradient(45deg, ${PLATINUM[500]} 0%, ${PLATINUM[300]} 50%, ${PLATINUM[500]} 100%)`,
        darkBackground: PLATINUM[500],
        darkContrast: GREY[0],
        darkForeground: PLATINUM[200],
        lightBackground: PLATINUM[100],
        lightContrast: PLATINUM[600],
        lightForeground: PLATINUM[100],
        normalBackground: PLATINUM[300],
        normalContrast: GREY[950],
        normalForeground: PLATINUM[300],
      },
    },
  },
}

const messagingPalette: Record<App.Design.PaletteType, App.Design.MessagingPalette<string>> = {
  default: {
    success: {
      normalForeground: LIME_GREEN[500],
      normalBackground: LIME_GREEN[500],
      normalContrast: GREY[0],
      lightForeground: LIME_GREEN[50],
      lightBackground: LIME_GREEN[50],
      lightContrast: GREY[800],
      darkForeground: LIME_GREEN[600],
      darkBackground: LIME_GREEN[600],
      darkContrast: GREY[0],
    },
    warning: {
      normalForeground: ORANGE[500],
      normalBackground: ORANGE[500],
      normalContrast: GREY[800],
      lightForeground: ORANGE[100],
      lightBackground: ORANGE[100],
      lightContrast: GREY[800],
      darkForeground: ORANGE[600],
      darkBackground: ORANGE[600],
      darkContrast: GREY[0],
    },
    urgency: {
      normalForeground: RED[500],
      normalBackground: RED[500],
      normalContrast: GREY[0],
      lightForeground: RED[200],
      lightBackground: RED[200],
      lightContrast: GREY[800],
      darkForeground: RED[600],
      darkBackground: RED[600],
      darkContrast: GREY[0],
    },
    critical: {
      normalForeground: RED[500],
      normalBackground: RED[500],
      normalContrast: GREY[0],
      lightForeground: RED[200],
      lightBackground: RED[200],
      lightContrast: GREY[800],
      darkForeground: RED[600],
      darkBackground: RED[600],
      darkContrast: GREY[0],
    },
    favourite: {
      normalForeground: RED[500],
      normalBackground: RED[500],
      normalContrast: GREY[0],
      lightForeground: RED[200],
      lightBackground: RED[200],
      lightContrast: GREY[800],
      darkForeground: RED[600],
      darkBackground: RED[600],
      darkContrast: GREY[0],
    },
  },
  inverse: {
    success: {
      normalForeground: LIME_GREEN[500],
      normalBackground: LIME_GREEN[500],
      normalContrast: GREY[0],
      lightForeground: LIME_GREEN[50],
      lightBackground: LIME_GREEN[50],
      lightContrast: GREY[800],
      darkForeground: LIME_GREEN[600],
      darkBackground: LIME_GREEN[600],
      darkContrast: GREY[0],
    },
    warning: {
      normalForeground: ORANGE[500],
      normalBackground: ORANGE[500],
      normalContrast: GREY[800],
      lightForeground: ORANGE[300],
      lightBackground: ORANGE[100],
      lightContrast: GREY[800],
      darkForeground: ORANGE[600],
      darkBackground: ORANGE[600],
      darkContrast: GREY[800],
    },
    urgency: {
      normalForeground: RED[500],
      normalBackground: RED[500],
      normalContrast: GREY[0],
      lightForeground: RED[200],
      lightBackground: RED[200],
      lightContrast: GREY[800],
      darkForeground: RED[600],
      darkBackground: RED[600],
      darkContrast: GREY[0],
    },
    critical: {
      normalForeground: RED[500],
      normalBackground: RED[500],
      normalContrast: GREY[0],
      lightForeground: RED[200],
      lightBackground: RED[200],
      lightContrast: GREY[800],
      darkForeground: RED[600],
      darkBackground: RED[600],
      darkContrast: GREY[0],
    },
    favourite: {
      normalForeground: RED[500],
      normalBackground: RED[500],
      normalContrast: GREY[0],
      lightForeground: RED[200],
      lightBackground: RED[200],
      lightContrast: GREY[800],
      darkForeground: RED[600],
      darkBackground: RED[600],
      darkContrast: GREY[0],
    },
  },
}

const highlightPalette: Record<App.Design.PaletteType, App.Design.HighlightPalette<string>> = {
  default: {
    primary: {
      normalForeground: TEAL[200],
      normalBackground: TEAL[200],
      normalContrast: GREY[800],
      normalActive: shade(0.24, TEAL[200]),
      normalHover: shade(0.16, TEAL[200]),
      lightForeground: TEAL[50],
      lightBackground: TEAL[50],
      lightContrast: GREY[800],
    },
    secondary: {
      normalForeground: TEAL[500],
      normalBackground: TEAL[500],
      normalContrast: GREY[0],
      normalActive: shade(0.24, TEAL[500]),
      lightForeground: TEAL[50],
      lightBackground: TEAL[50],
      lightContrast: GREY[800],
    },
    tertiary: {
      normalForeground: PURPLE[500],
      normalBackground: PURPLE[500],
      normalContrast: GREY[0],
      lightForeground: PURPLE[50],
      lightBackground: PURPLE[50],
      lightContrast: GREY[800],
    },
    luxPlus: {
      normalForeground: BLUE[400],
    },
  },
  inverse: {
    primary: {
      normalForeground: TEAL[200],
      normalBackground: TEAL[200],
      normalContrast: GREY[800],
      normalActive: shade(0.24, TEAL[200]),
      normalHover: shade(0.16, TEAL[200]),
      lightForeground: TEAL[50],
      lightBackground: TEAL[50],
      lightContrast: GREY[800],
    },
    secondary: {
      normalForeground: TEAL[300],
      normalBackground: TEAL[300],
      normalContrast: GREY[0],
      normalActive: shade(0.24, TEAL[300]),
      lightForeground: TEAL[300],
      lightBackground: TEAL[50],
      lightContrast: GREY[800],
    },
    tertiary: {
      normalForeground: PURPLE[500],
      normalBackground: PURPLE[500],
      normalContrast: GREY[0],
      lightForeground: PURPLE[500],
      lightBackground: PURPLE[50],
      lightContrast: PURPLE[500],
    },
    luxPlus: {
      normalForeground: BLUE[400],
    },
  },
}

export const luxuryEscapesDefaultPalette: App.Design.Palette<string> = {
  brand: brandPalette.default,
  neutral: neutralPalette.default,
  product: productPalette.default,
  messaging: messagingPalette.default,
  highlight: highlightPalette.default,
}

export const luxuryEscapesInversePalette: App.Design.Palette<string> = {
  brand: brandPalette.inverse,
  neutral: neutralPalette.inverse,
  product: productPalette.inverse,
  messaging: messagingPalette.inverse,
  highlight: highlightPalette.inverse,
}

export const luxuryEscapesShadows: App.Design.Shadows<string> = { ...baseThemeShadows }
export const luxuryEscapesBorderRadii: App.Design.BorderRadii<string> = {
  XS: '0px',
  S: '2px',
  M: '4px',
  L: '8px',
  round: '9999px',
}

const luxuryEscapesTheme: App.Design.Theme = {
  ...base,
  typography: {
    heading: {
      one: {
        weight: 'semiBold',
      },
      oneAlt: {
        letterSpacing: '-0.5px',
        weight: 'semiBold',
      },
      two: {
        weight: 'semiBold',
      },
      twoAlt: {
        weight: 'semiBold',
      },
      three: {
        weight: 'semiBold',
      },
      threeAlt: {
        weight: 'semiBold',
        letterSpacing: '-0.5px',
      },
      four: {
        weight: 'semiBold',
      },
      five: {
        weight: 'superBold',
      },
      six: {
        weight: 'superBold',
      },
    },
  },
  font: {
    primary: {
      provider: 'local',
      localFileSrc: '/fonts/LE-fonts.css',
      name: 'Suisse Int\'l\'',
      family: '"Suisse Int\'l", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      weight: {
        regular: '400',
        bold: '500',
        semiBold: '500',
        superBold: '600',
      },
    },
    secondary: {
      provider: 'local',
      localFileSrc: '/fonts/LE-fonts.css',
      name: '"Suisse Int\'l\'"',
      family: '"Suisse Int\'l\", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      weight: {
        superBold: '600',
        bold: '500',
        semiBold: '450',
        regular: '400',
      },
    },
    secondaryAlternate: {
      provider: 'local',
      localFileSrc: '/fonts/LE-fonts.css',
      name: 'Suisse Works',
      family: '"Suisse Works", "Suisse Int\'l", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      weight: {
        // only semi-bold is offered by this alternate font
        bold: '450',
        semiBold: '450',
        regular: '450',
        superBold: '450',
      },
    },
  },
}

export default luxuryEscapesTheme
