import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import { buildAvailableRateKey } from 'lib/offer/availabilityUtils'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { getCalenderDaysByCheckIn } from 'lib/offer/offerCalendarUtils'

export function getInstantBookingPriceAndSurcharge(
  item: App.Checkout.InstantBookingLEHotelItem,
  offer: App.Offer,
  calendarsRatesByOccupancy: { [occupancyKey: string]: { [packageKey: string]: App.Calendar } },
  availableRatesByOccupancy: { [offerId: string]: App.OfferAvailableRatesByOccupancy },
): App.WithDataStatus<App.Checkout.ItemViewTotals & { extraGuestSurchargesPayableAtProperty: boolean }> {
  let hasRequiredData = false
  let surcharge = 0
  let extraGuestSurcharge = 0
  let extraGuestSurchargesPayableAtProperty = false
  let price = 0
  let memberPrice = 0
  let taxesAndFees = 0

  let uniqueKey = getPackageUniqueKey(item.packageId, item.duration, item.roomRateId)

  if (item.hasLoyaltyBenefitRoomUpgrade) {
    const originPackage = offer.packages.find(pkg => pkg.uniqueKey === uniqueKey)
    if (originPackage?.luxLoyalty.targetUpgradePackageUniqueKey) {
      const upgradePackage = offer.packages.find(pkg => pkg.uniqueKey === originPackage.luxLoyalty.targetUpgradePackageUniqueKey)
      if (upgradePackage) {
        // @ts-expect-error string satisfies the magic string here
        uniqueKey = upgradePackage.uniqueKey
      }
    }
  }

  if (offer.type === OFFER_TYPE_ALWAYS_ON && (offer.property?.useDynamicOccupancy || offer.property?.useDynamicCancellationPolicies)) {
    const key = buildAvailableRateKey(item.checkIn, item.checkOut, [item.occupancy])
    const availableRates = availableRatesByOccupancy[item.offerId]?.[key]?.rates
    const availableRate = availableRates?.find(rate => rate.packageUniqueKey === uniqueKey)

    if (availableRate) {
      hasRequiredData = true
      surcharge = availableRate.surcharge
      extraGuestSurcharge = availableRate.extraGuestSurcharge
      price = availableRate.hotelPrice
      memberPrice = availableRate.hotelMemberPrice
      taxesAndFees = availableRate.taxesAndFees
    }
  } else {
    const calendars = calendarsRatesByOccupancy[generateOccupancyStringByRoom(item.occupancy)]
    const pkgCalendar = calendars?.[uniqueKey]
    const pkgByDay = pkgCalendar && getCalenderDaysByCheckIn(pkgCalendar)[item.checkIn]
    const pkg = offer.packages.find(p => p.uniqueKey === uniqueKey)

    if (pkgByDay) {
      hasRequiredData = true
      surcharge = pkgByDay.surcharge
      extraGuestSurcharge = pkgByDay.extraGuestSurcharge ?? 0
      extraGuestSurchargesPayableAtProperty = !!pkg?.roomRate?.extraGuestSurcharges.length && pkg.roomRate.extraGuestSurcharges.some(surcharge => surcharge?.payableAtProperty)
      price = pkgByDay.hotelPrice
      memberPrice = pkgByDay.hotelMemberPrice
      taxesAndFees = pkgByDay.taxesAndFees
    }
  }

  return {
    hasRequiredData,
    data: {
      surcharge,
      extraGuestSurcharge,
      extraGuestSurchargesPayableAtProperty,
      price,
      memberPrice,
      taxesAndFees,
      value: 0,
      memberValue: 0,
    },
  }
}
