import { OFFER_TYPE_CRUISE, OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import { mergeFees } from 'checkout/lib/utils/payments/fees'
import { getTourV2ItemViewPrice } from 'checkout/lib/utils/tours/priceView'
import { excludeNullOrUndefined } from 'checkout/utils'
import config from 'constants/config'
import { CHECKOUT_ITEM_TYPE_TOUR_V2 } from 'constants/checkout'

interface Options {
  checkoutWithMemberPrice: boolean;
  hasLuxPlusOrSubscriptionInCart: boolean;
  isPostPurchaseTourDateChange: boolean;
}

export function computeAccommodationOfferTotalsView(
  view: App.Checkout.AccommodationOfferView,
  options: Options,
): App.WithDataStatus<App.Checkout.ItemViewTotals> {
  let price = 0
  let memberPrice = 0
  let value = 0
  let memberValue = 0
  let surcharge = 0
  let taxesAndFees = 0
  let businessTravellerCredits = 0
  let paidPrice = 0
  let newPrice = 0
  let newSurcharge = 0
  let newExtraGuestSurcharge = 0

  view.itemViews.forEach((itemView: App.Checkout.AccommodationItemView) => {
    let itemPrice = itemView.totals.price
    let itemMemberPrice = itemView.totals.memberPrice
    let itemValue = itemView.totals.value

    if (view.offer && itemView.kind === CHECKOUT_ITEM_TYPE_TOUR_V2 && view.offerType === OFFER_TYPE_TOUR_V2) {
      const { totalPrice, totalValuedAtPrice, totalMemberPrice } = getTourV2ItemViewPrice(view.offer, itemView.item.purchasableOption, itemView.occupancy)
      itemPrice = totalPrice
      itemValue = totalValuedAtPrice ?? 0
      itemMemberPrice = totalMemberPrice
      // for tour date change remove the paid price from the total price
      if (options.isPostPurchaseTourDateChange) {
        const itemPaidPrice = itemView.totals.paidPrice ?? 0
        itemView.totals.newPrice = options.checkoutWithMemberPrice && totalMemberPrice ? totalMemberPrice : totalPrice
        itemPrice = itemView.totals.newPrice - itemPaidPrice
        itemMemberPrice = totalMemberPrice ? totalMemberPrice - itemPaidPrice : totalMemberPrice
        itemValue = 0
      }
    } else if (itemView.kind === 'cruise' && view.offerType === OFFER_TYPE_CRUISE) {
      itemPrice = itemView?.totals.price ?? 0
    }
    price += itemPrice
    memberPrice += (itemMemberPrice || itemPrice)
    value += itemValue
    memberValue += itemView.totals.memberValue
    paidPrice += itemView.totals.paidPrice ?? 0
    newPrice += itemView.totals.newPrice ?? 0
    newSurcharge += itemView.totals.newSurcharge ?? 0
    newExtraGuestSurcharge += itemView.totals.newExtraGuestSurcharge ?? 0
    surcharge += itemView.totals.surcharge ?? 0
    taxesAndFees += itemView.totals.taxesAndFees
    if (config.businessTraveller.currentAccountMode === 'business') {
      businessTravellerCredits += itemView.totals.businessTravellerCredits ?? 0
    }
  })

  const otherFees = view.itemViews
    .map((view: App.Checkout.AccommodationItemView) => view.totals.otherFees)
    .filter(excludeNullOrUndefined)

  return {
    hasRequiredData: true,
    data: {
      price: options.checkoutWithMemberPrice && memberPrice > 0 ? memberPrice : price,
      memberPrice,
      value: options.hasLuxPlusOrSubscriptionInCart ? memberValue : value,
      surcharge,
      taxesAndFees,
      otherFees: mergeFees(otherFees),
      ...(businessTravellerCredits && { businessTravellerCredits }),
      paidPrice,
      newPrice,
      newSurcharge,
      newExtraGuestSurcharge,
      memberValue: 0,
      extraGuestSurcharge: 0,
    },
  }
}
