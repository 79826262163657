import config from 'constants/config'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { isLpcHotelOffer } from 'lib/offer/offerTypes'
import { max, min, nonNullable } from 'lib/array/arrayUtils'

export const isPayFullyNowAvailable = (offer: App.CarHireOffer) => {
  return !!offer.rateOptions.find((option) => !!option.payNowAmount && !option.payOnArrivalAmount)
}

export const isPayFullyOnArrivalAvailable = (offer: App.CarHireOffer) => {
  return !!offer.rateOptions.find((option) => !option.payNowAmount && !!option.payOnArrivalAmount)
}

export const isPayPartiallyAvailable = (offer: App.CarHireOffer) => {
  return !!offer.rateOptions.find((option) => !!option.payNowAmount && !!option.payOnArrivalAmount)
}

export const getRateOptionByRateType = (offer: App.CarHireOffer, rateOptionType: App.CarHireRateOptionTypes) => {
  return offer.rateOptions.find((option) => {
    return (rateOptionType === 'payFullyNow' && !!option.payNowAmount && !option.payOnArrivalAmount) ||
      (rateOptionType === 'payFullyOnArrival' && !option.payNowAmount && !!option.payOnArrivalAmount) ||
      (rateOptionType === 'payPartiallyNow' && !!option.payNowAmount && !!option.payOnArrivalAmount)
  })
}

export const getRateOptionType = (option: App.CarHireOffer['rateOptions'][number]): App.CarHireRateOptionTypes => {
  if (!!option.payNowAmount && !option.payOnArrivalAmount) return 'payFullyNow'
  if (!option.payNowAmount && !!option.payOnArrivalAmount) return 'payFullyOnArrival'
  return 'payPartiallyNow'
}

export const getOrderItemsEligibleForCarHireBundledDiscount = (order: App.Order, offers: Array<App.AnyOffer>) => {
  const hotels: Array<{
    startDate: string,
    endDate: string,
    latitude: number,
    longitude: number,
    countryCode: string,
    offer: App.OrderItemOffer | App.OrderBedbankOffer,
  }> = [
    ...nonNullable(order.items.map(item => {
      const hotelOffer = offers.find(offer => offer.id === item.offer.id)
      if (isLpcHotelOffer(hotelOffer) && item.offer.property && item.reservation) {
        return {
          startDate: item.reservation.startDate,
          endDate: item.reservation.endDate,
          latitude: hotelOffer.property.latitude,
          longitude: hotelOffer.property.longitude,
          countryCode: hotelOffer.property.geoData.countryCode ?? '',
          offer: item.offer,
        }
      }
    })),
    ...nonNullable(order.bedbankItems.map(item => {
      return {
        startDate: item.checkIn.format(ISO_DATE_FORMAT),
        endDate: item.checkOut.format(ISO_DATE_FORMAT),
        latitude: item.offer.property.latitude,
        longitude: item.offer.property.longitude,
        countryCode: item.offer.property.address.countryCode,
        offer: item.offer,
      }
    })),
  ].filter(item => item.countryCode.length > 0 && config.BUNDLED_CAR_HIRE_REGIONS.includes(item.countryCode))

  const earliestItem = min(hotels, item => new Date(item.startDate))
  const latestItem = max(hotels, item => new Date(item.endDate))

  return {
    earliestItem,
    latestItem,
    items: hotels,
  }
}
