import React, { useCallback, useContext, useRef } from 'react'

import { IS_SAVED_COPY, NOT_SAVED_COPY, SaveItemsCallbackResult } from './utils'
import BaseBookmarkButton from '../Common/BaseBookmarkButton'
import SaveModal, { TripPlannerSaveModalResult } from '../Common/SaveModal'

import {
  saveToTripEvent,
  tripLoginModalSignUpDismiss,
  tripLoginModalSignUpView,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import AnalyticsPageContext from 'contexts/Analytics/analyticsPageContext'
import ModalContext from 'contexts/ModalContext'
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks'
import usePendingLoginHandler from 'hooks/usePendingLoginHandler'
import { EmptyArray } from 'lib/array/arrayUtils'
import {
  clearRecentlySavedTripId,
  getRecentlySavedTripId,
  setRecentlySavedTripId,
} from 'storage/recentSavedTrip'
import { useDeleteTripItem } from 'tripPlanner/hooks/api/tripItem'
import { useTrip, useEditableTrips } from 'tripPlanner/hooks/api/trip'
import { useProcessBookmarks } from 'tripPlanner/hooks/api/bookmark'
import useBookmarkDetails from 'tripPlanner/hooks/bookmarks/useBookmarkDetails'
import useBookmarkSnackbarHandlers from 'tripPlanner/hooks/bookmarks/useSavedItemSnackbarHandlers'
import useNewTripNameFromOffer from 'tripPlanner/hooks/useNewTripNameFromOffer'
import {
  setCurrentSelectionId,
  setTripItemHasJustBeenAdded,
} from 'tripPlanner/reducers/actions'
import {
  getImmersiveTripId,
  selectTripPlannerTemplateId,
  selectTripPlannerTemplateItemId,
} from 'tripPlanner/selectors'
import { BasicTrip, FullTrip } from 'tripPlanner/types/common'
import { tripItemSelectionId } from 'tripPlanner/utils/itemSelection'
import { CruiseBookmarkPayload } from 'tripPlanner/api/bookmark/types'

export interface Props {
  offer: App.CruiseOffer
  withLabel?: React.ComponentProps<typeof BaseBookmarkButton>['withLabel']
}

const trackTripLoginModalSignUpView = () => {
  fireInteractionEvent(tripLoginModalSignUpView('save'))
}
const trackTripLoginModalSignUpDismiss = () => {
  fireInteractionEvent(tripLoginModalSignUpDismiss('save'))
}

function CruiseBookmarkButton({ offer, withLabel }: Props) {
  const currentTripId = useAppSelector(getImmersiveTripId)
  const { data: currentTrip } = useTrip({ tripId: currentTripId })
  const dispatch = useAppDispatch()
  const analyticsPage = useContext(AnalyticsPageContext)
  const details = useBookmarkDetails(offer.id)
  const isSaved = !!details
  const { data } = useEditableTrips()
  const trips = data ?? EmptyArray

  const didAutoSelectTrip = useRef(false)

  // A promise that can be awaited to make sure we only show snackbar messages once the modal is closed
  const modalPromise = useRef<Promise<any>>(Promise.resolve())

  const {
    showSaveSuccessSnackbar,
    showSaveErrorSnackbar,
    showRemoveSuccessSnackbar,
    showRemoveErrorSnackbar,
  } = useBookmarkSnackbarHandlers()

  const { mutate: deleteBookmark, isLoading: isDeleting } = useDeleteTripItem({
    onSuccess: (_res, vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveSuccessSnackbar(trip.id, trip.name)
    },
    onError: (_res, _vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveErrorSnackbar(trip.name)
    },
  })

  const { mutateAsync: processBookmarks, isLoading: isCreating } =
    useProcessBookmarks({
      onError: (e, variables) => {
        console.error(e)
        showSaveErrorSnackbar(
          trips.find((t) => t.id === variables.tripIdsAdded[0])?.name,
        )

        // Clear the recently saved trip ID in case it errored because the trip was deleted
        clearRecentlySavedTripId()
      },
    })

  const templateId = useAppSelector(selectTripPlannerTemplateId)
  const templateItemId = useAppSelector(selectTripPlannerTemplateItemId)

  const newTripName = useNewTripNameFromOffer(offer)
  const createTripItems = useCallback(
    async(tripId: string): Promise<SaveItemsCallbackResult> => {
      const payload: CruiseBookmarkPayload = {
        type: 'cruise_v2',
        code: offer.id,
        templateId,
        templateItemId,
      }

      const bookmarkResult = await processBookmarks({
        items: [payload],
        tripIdsAdded: [tripId],
        tripIdsRemoved: [],
      })

      return {
        savedItemIds: bookmarkResult.created.map((item) => item.id),
      }
    },
    [offer.id, processBookmarks, templateId, templateItemId],
  )

  const createTripItemsImmediate = useCallback(
    async(trip: FullTrip | BasicTrip) => {
      try {
        const res = await createTripItems(trip.id)
        const itemId = res.savedItemIds[0]
        dispatch(setCurrentSelectionId(tripItemSelectionId(itemId)))
        dispatch(setTripItemHasJustBeenAdded())
        modalPromise.current.then(() =>
          showSaveSuccessSnackbar(trip.id, trip.name, itemId, 'CRUISE', true),
        )
        setRecentlySavedTripId(trip.id)
      } catch {
        // Do nothing - failure case is handled by the hook's onError handler
      }
    },
    [createTripItems, dispatch, showSaveSuccessSnackbar],
  )

  const showModal = useContext(ModalContext)
  const openSaveModal = useCallback(() => {
    return showModal<TripPlannerSaveModalResult>(
      <SaveModal
        itemTypeLabel="offer"
        defaultTripName={newTripName}
        createTripItems={createTripItems}
        isCreatingItem={isCreating}
      />,
    )
  }, [createTripItems, isCreating, newTripName, showModal])

  const onSave = useCallback(async() => {
    didAutoSelectTrip.current = false
    const recentlySavedTripId = getRecentlySavedTripId()
    if (isSaved) {
      deleteBookmark({ tripId: details.trip.id, tripItemId: details.itemId })
    } else if (currentTrip) {
      fireInteractionEvent(
        saveToTripEvent('button', 'immersive', 'click', analyticsPage),
      )
      createTripItemsImmediate(currentTrip)
    } else {
      fireInteractionEvent(
        saveToTripEvent('button', 'global', 'click', analyticsPage),
      )
      const recentlySavedTrip = trips.find((t) => t.id === recentlySavedTripId)
      if (recentlySavedTrip) {
        didAutoSelectTrip.current = true
        createTripItemsImmediate(recentlySavedTrip)
      } else {
        modalPromise.current = openSaveModal()
        await modalPromise.current
        modalPromise.current = Promise.resolve()
      }
    }
  }, [
    currentTrip,
    createTripItemsImmediate,
    deleteBookmark,
    isSaved,
    openSaveModal,
    details,
    analyticsPage,
    trips,
  ])

  const onButtonClick = usePendingLoginHandler(
    onSave,
    'tripPlannerLogin',
    trackTripLoginModalSignUpView,
    trackTripLoginModalSignUpDismiss,
  )

  return (
    <BaseBookmarkButton
      label={isSaved ? IS_SAVED_COPY : NOT_SAVED_COPY}
      isSaved={isSaved}
      isProcessing={isDeleting || isCreating}
      onClick={onButtonClick}
      withLabel={withLabel}
    />
  )
}
export default CruiseBookmarkButton
