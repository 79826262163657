import FilterChip from 'components/Luxkit/Chips/FilterChip'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import React, { useCallback, useContext, useMemo } from 'react'
import { Form } from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchChips'
import ModalContext from 'contexts/ModalContext'
import ModalCruiseSearchPromotionChip
  from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/Modal/ModalCruiseSearchPromotionChip'

export interface CruiseSearchPromotionChipProps {
  facets: Array<App.CruiseSearchFacet>;
  filters: {
    isLuxExclusivePromotion?: Set<string>;
    isSpecialOffers?: Set<string>;
    isLuxPlus?: Set<string>;
  };
  filterForm: Form;
  setFilterForm: (form: Form) => void;
  fetching?: boolean;
  drawerMode?: boolean;
}

function CruiseSearchPromotionChip(props: CruiseSearchPromotionChipProps) {
  const { filters, facets, filterForm, setFilterForm, fetching, drawerMode } = props
  const showModal = useContext(ModalContext)

  const openModal = useCallback(async() => {
    await showModal<number>(<ModalCruiseSearchPromotionChip
      drawerMode={drawerMode}
      facets={facets}
      filterForm={filterForm}
      filters={filters}
      setFilterForm={setFilterForm}
      fetching={fetching}
    />)
  }, [drawerMode, facets, fetching, filterForm, filters, setFilterForm, showModal])

  const countSelected = useMemo(() => {
    return (filters.isLuxExclusivePromotion?.size || 0) +
      (filters.isSpecialOffers?.size || 0) +
      (filters.isLuxPlus?.size || 0)
  }, [filters.isLuxExclusivePromotion?.size, filters.isLuxPlus?.size, filters.isSpecialOffers?.size])

  return <FilterChip
    selected={!!filters.isLuxExclusivePromotion || !!filters.isSpecialOffers || !!filters.isLuxPlus}
    size="medium"
    onClick={openModal}
    endIcon={<LineAngleDownIcon />}
  >
    Promotions {countSelected > 0 && `(${countSelected})`}
  </FilterChip>
}

export default CruiseSearchPromotionChip
