import React from 'react'
import Heading from '../../Luxkit/Typography/Heading'
import { getMonthName } from 'lib/datetime/dateUtils'

interface Props {
  currentDate: Date;
}

function VerticalDatePickerHeader(props: Props) {
  const {
    currentDate,
  } = props

  return (
    <Heading variant="heading5">{getMonthName(currentDate)} {currentDate.getFullYear()}</Heading>
  )
}

export default React.memo(VerticalDatePickerHeader)
