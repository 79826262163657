import moment from 'moment'
import { Order as OrderTypes } from '@luxuryescapes/contract-svc-order'
import { Reservation as ReservationTypes } from '@luxuryescapes/contract-svc-reservation'
import {
  buildCancellationPolicies,
  buildPartiallyRefundableCancellationPolicies,
  connectionCancellationPolicyMap,
} from '@luxuryescapes/lib-refunds'
import { RESERVATION_TYPE_BOOK_LATER } from 'constants/reservation'
import offerTypeConfig from '../config/offer'
import { LE_CONTRACTED_HOTEL_TYPES, OFFER_TYPE_BED_BANK } from 'constants/offer'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { addSpace } from 'lib/string/removeSpaceUtils'
import facilityGroupsMap from './facilityGroupMap'
import { nonNullable, partitionBy, sortBy } from 'lib/array/arrayUtils'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import formatPropertyAddress from 'api/lib/formatPropertyAddress'
import { bedbankPropertyAddressMap, bedbankPropertyFinePrintMap } from './bedbankOfferMap'
import { tourV2OrderItemMap, tourV2OrderOptionalExperienceItemMap } from './TourV2/tourV2OrderMapper'
import { INSURANCE_CANCELLATION_REASON_UPGRADED } from 'constants/insurance'
import { cruiseOrderItemMap } from './Cruise/cruiseMap'
import { carHireOrderItemMap } from './carHireMap'
import config from 'constants/config'
import { PROMO_PAYMENT_TYPE } from 'constants/payment'
import { ServerRefundMeta } from 'api/order'
import determineOfferFeatureSymbol from 'lib/offer/determineOfferFeatureSymbol'
import uuidV4 from 'lib/string/uuidV4Utils'
import { getOfferInclusionDescriptionFromMarkdown, getOfferInclusionFromMarkdown } from './offerInclusionMap'
import { EXPERIENCE_CANCELLATION_REASON_DOWNGRADED } from 'constants/experience'

export function reservationCancellationPoliciesMap(reservation: any): Array<App.OrderCancellationPolicyTypes> {
  if (reservation?.connection?.cancellation_policies) {
    return reservation.connection.cancellation_policies.map(connectionCancellationPolicyMap)
  } else if (reservation?.cancellation_policies) {
    return reservation.cancellation_policies.map((policy): App.OrderCancellationPolicy => ({
      start: policy.start,
      end: policy.end,
      type: policy.cancellation_policy,
      policyDetail: policy.cancellation_policy_details,
    }))
  }

  return []
}

export function reservationMap(
  reservation: OrderTypes.Reservation | any,
  itemId: string,
): App.OrderReservation | undefined {
  if (!reservation) {
    return undefined
  }

  if (reservation.property) {
    return propertyReservationMap(reservation, itemId)
  }

  if (reservation.tour_option) {
    return {
      itemId,
      tourName: reservation.tour ? reservation.tour.name : null,
      tourOption: reservation.tour_option,
      endDate: reservation.end_date,
      startDate: reservation.start_date,
      duration: reservation.number_of_days,
      guestFirstName: reservation.guest_first_name,
      guestLastName: reservation.guest_last_name,
      travellerDetailsRequired: reservation.traveller_details_required,
      state: reservation.state,
    } as App.OrderReservation
  }
}

function tourItemReservationMap(reservation: any, itemId: string): App.OrderReservation {
  return {
    itemId,
    startDate: reservation.start_date,
    endDate: reservation.end_date,
    guestFirstName: reservation.guest_first_name,
    guestLastName: reservation.guest_last_name,
    guestSpecialRequests: reservation.guest_special_requests,
    adults: reservation.number_of_adults,
    children: reservation.number_of_children,
    infants: reservation.number_of_infants || 0,
    duration: reservation.number_of_nights,
    childrenAge: reservation.children_ages,
    roomType: reservation.room_type,
    property: reservation.property,
    taxesAndFees: reservation.room_rate_tax_sell_amount || 0,
    propertyFees: reservation.room_rate_property_fee_sell_amount || 0,
    channelManager: reservation.channel_manager,
    surcharge: reservation.surcharge_total,
    extraGuestSurcharge: reservation.extra_guest_surcharge_amount,
    extraGuestSurchargePayableAtProperty: reservation.extra_guest_surcharge_amount_payable_at_property,
    surchargePaidDirectToVendor: reservation.surcharge_paid_direct_to_vendor,
    exceedsIncludedGuests: reservation.exceeds_included_guests,
    state: reservation.state,
    cancellationPolicies: reservationCancellationPoliciesMap(reservation),
  }
}

function propertyReservationMap(reservation: any, itemId: string): App.OrderReservation {
  return {
    itemId,
    startDate: reservation.check_in,
    endDate: reservation.check_out,
    guestFirstName: reservation.guest_first_name,
    guestLastName: reservation.guest_last_name,
    guestSpecialRequests: reservation.guest_special_requests,
    guestSpecialRequestsVendorResponse: reservation.guest_special_requests_vendor_response,
    guestSpecialRequestsVendorHasResponded: reservation.guest_special_requests_vendor_has_responded,
    adults: reservation.number_of_adults,
    children: reservation.number_of_children,
    infants: reservation.number_of_infants || 0,
    duration: reservation.number_of_nights,
    childrenAge: reservation.children_ages,
    roomType: reservation.room_type,
    property: reservation.property,
    taxesAndFees: reservation.room_rate_tax_sell_amount || 0,
    propertyFees: reservation.room_rate_property_fee_sell_amount || 0,
    channelManager: reservation.channel_manager,
    surcharge: reservation.surcharge_total,
    extraGuestSurcharge: reservation.extra_guest_surcharge_amount,
    extraGuestSurchargePayableAtProperty: reservation.extra_guest_surcharge_amount_payable_at_property,
    surchargePaidDirectToVendor: reservation.surcharge_paid_direct_to_vendor,
    exceedsIncludedGuests: reservation.exceeds_included_guests,
    state: reservation.state,
    cancellationPolicies: reservationCancellationPoliciesMap(reservation),
  }
}

export function creditMap(credit) {
  return {
    id: credit.id_credit,
    amount: parseFloat(credit.amount),
    createdAt: credit.created_at,
    expiresAt: credit.expires_at,
    creditType: credit.credit_type,
    currency: credit.currency,
  }
}

export function offerMap(offer, offerPackage): App.OrderItemOffer {
  const typeConfig = offerTypeConfig[offer.type]

  const isHotel = LE_CONTRACTED_HOTEL_TYPES.includes(offer.type)

  if (isHotel && offerPackage.property) {
    return {
      id: offer.id_salesforce_external,
      slug: offer.slug,
      type: typeConfig.type,
      parentType: typeConfig.parentType,
      name: offer.name,
      location: offer.location,
      image: imageMap(offer.image),
      bookByDate: offer.book_by_date,
      bundleOfferId: offer.bundle_offer_id,
      bundleOffer: offer.bundle_offer,
      property: orderOfferPropertyMap(offerPackage.property),
    }
  }

  return {
    id: offer.id_salesforce_external,
    slug: offer.slug,
    type: typeConfig.type,
    parentType: typeConfig.parentType,
    name: offer.name,
    location: offer.location,
    image: imageMap(offer.image),
    bookByDate: offer.book_by_date,
    bundleOfferId: offer.bundle_offer_id,
    bundleOffer: offer.bundle_offer,
  }
}

export function orderOfferPropertyMap(property): App.OrderItemOfferProperty {
  return {
    id: property.id,
    name: property.name,
    type: property.type,
    address: property.address,
    category: property.category,
    timezone: property.timezone,
    latitude: property.latitude,
    longitude: property.longitude,
    geoData: property.geo_data ? {
      administrativeAreaLevel1: property.geo_data.administrative_area_level_1,
      administrativeAreaLevel2: property.geo_data.administrative_area_level_2,
      administrativeAreaLevel3: property.geo_data.administrative_area_level_3,
      administrativeAreaLevel4: property.geo_data.administrative_area_level_4,
      administrativeAreaLevel5: property.geo_data.administrative_area_level_5,
      continentCode: property.geo_data.continent_code,
      country: property.geo_data.country,
      countryCode: property.geo_data.country_code,
      locality: property.geo_data.locality,
      placeId: property.geo_data.place_id,
      route: property.geo_data.route,
      streetNumber: property.geo_data.street_number,
    } : undefined,
    locationHeading: property.location_heading || '',
    locationSubheading: property.location_subheading || '',
    roomType: {
      images: property?.room_type?.images ?? [],
    },
    timezoneOffset: property.timezone_offset,
  }
}

export function imageMap(offerImage) {
  return {
    id: offerImage.id_cloudinary_external,
  }
}

export function offerPackageMap(item): App.OrderItemPackage {
  const offerPackage = item.offer_package as OrderTypes.OfferPackage
  if ('fk_room_rate_id' in offerPackage) {
    return {
      id: offerPackage.id_salesforce_external ?? offerPackage.le_package_id,
      uniqueKey: getPackageUniqueKey(offerPackage.id_salesforce_external ?? offerPackage.le_package_id, item.number_of_nights, offerPackage.fk_room_rate_id),
      name: offerPackage.name,
      description: offerPackage.description,
      price: offerPackage.price,
      value: offerPackage.value,
      duration: item.number_of_nights,
      propertyId: offerPackage.fk_property_id,
      roomTypeId: offerPackage.fk_room_type_id,
      roomRateId: offerPackage.fk_room_rate_id,
      qffBonusPoints: offerPackage.qff_bonus_points,
      roomTypeName: offerPackage?.property?.room_type?.name,
      roomTypeImage: offerPackage?.property?.room_type?.imageId,
      roomTypeAmenities: offerPackage?.property?.room_type?.amenities,
      roomPolicyDescription: offerPackage?.property?.room_policy_description,
      ratePlanCancellationPolicy: offerPackage?.property?.roomRate?.rate_plan?.cancellation_policy,
    } as App.OrderItemHotelPackage
  }

  return {
    id: offerPackage.id_salesforce_external,
    uniqueKey: offerPackage.id_salesforce_external ?? offerPackage.le_package_id,
    name: offerPackage.name,
    description: offerPackage.description,
    price: offerPackage.price,
    value: offerPackage.value,
    duration: item.number_of_days,
    qffBonusPoints: offerPackage.qff_bonus_points,
  } as App.OrderItemTourPackage
}

function mapInclusionV2(external: ReservationTypes.PackageInclusion): App.PackageInclusion {
  return {
    id: external.id,
    isBonus: external.style === 'bonus',
    isHighlighted: external.style === 'highlighted',
    description: external.inclusion?.name ?? '',
    symbol: determineOfferFeatureSymbol(external.inclusion?.category?.icon),
    parentCategory: external.inclusion?.category?.category as App.PackageInclusionParentCategory,
    luxPlusTier: external.lux_plus_tier as App.MembershipSubscriptionTier,
  }
}

function getInclusionsV2(inclusions?: OrderTypes.HotelInclusion['inclusionsV2']) {
  return inclusions?.filter(inclusion => !inclusion.lux_plus_tier).map(mapInclusionV2)
}

function getLuxPlusInclusionsV2(inclusions?: OrderTypes.HotelInclusion['inclusionsV2']) {
  return inclusions?.filter(inclusion => inclusion.lux_plus_tier).map(mapInclusionV2)
}

export function itemMap(item, order): App.OrderItem {
  const offer = offerMap(item.offer, item.offer_package)

  let reservation
  if (item.reservation) {
    reservation = offer.type === 'tour' ? tourItemReservationMap(item.reservation, item.id) : propertyReservationMap(item.reservation, item.id)
  }

  return {
    id: item.id,
    status: item.status,
    bookingNumber: item.booking_number,
    vendorId: item.fk_vendor_id,
    offerId: item.offer.id_salesforce_external,
    offer,
    total: parseFloat(item.total),
    packagePrice: item.package_price,
    memberPrice: item.offer_package?.lux_plus_price ?? 0,
    package: offerPackageMap(item),
    reservation,
    reservationMade: item.reservation_made,
    reservationError: item.reservation_error,
    reservationType: item.reservation_type,
    hasReservationHistory: item.has_reservation_history || false,
    transactionKey: item.transaction_key,
    duration: item.number_of_nights || item.number_of_days,
    durationLabel: item.number_of_nights ? pluralizeToString('Night', item.number_of_nights) : pluralizeToString('Day', item.number_of_days),
    isDowngraded: item.is_downgraded,
    downgradeType: item.downgrade_type,
    downgradedFromId: item.fk_downgraded_from_id,
    noShow: item.no_show,
    onHold: item.on_hold,
    customerCanRefundToCredit: item.customer_can_refund_to_credit,
    canBookDates: (
      (!order.gift_status || order.gift_status === 'redeemed') &&
      item.offer.booking_type === 'reservation' &&
      order.status !== 'cancelled' &&
      item.status !== 'cancelled' &&
      item.reservation_type === RESERVATION_TYPE_BOOK_LATER &&
      !item.reservation_made
    ),
    isAwaitingOnHoldPermission: item.is_awaiting_on_hold_permission,
    inclusions: {
      inclusionHighlights: getOfferInclusionFromMarkdown(item.inclusions?.total_inclusions || item.offer_package.description || item.inclusions?.inclusion_highlights),
      // there's some bad data in order service and we were getting array with 'null' in it from the server
      bonusInclusions: nonNullable(item.inclusions?.bonus_inclusions ?? []).map<App.OfferInclusion>((i: string) => ({
        id: uuidV4(),
        description: getOfferInclusionDescriptionFromMarkdown(i),
      })),
      inclusions: getInclusionsV2(item.inclusions?.inclusionsV2),
      luxPlusInclusions: item.inclusions?.inclusionsV2?.length ? getLuxPlusInclusionsV2(item.inclusions?.inclusionsV2) : item.inclusions?.lux_plus_inclusions?.map(luxPlusInclusionMap),
    },
    propertyFees: item.property_fees,
    isCompedPackage: item.is_comped_package,
    numberOfDateChanges: item.number_of_date_changes ?? 0,
    arrivalDetails: {
      arrivalDate: item.arrival_details?.arrival_date || null,
      arrivalTime: item.arrival_details?.arrival_time || null,
      arrivalFlightNumber: item.arrival_details?.arrival_flight_number || null,
    },
    subscriberTier: item.subscriber_tier,
    channelMarkupId: item.channel_markup_id,
    mx: item.mx ?? undefined,
  }
}

function luxPlusInclusionMap(inclusion): App.OrderLuxPlusInclusion {
  return {
    id: inclusion.id ?? uuidV4(),
    description: inclusion.description,
    amount: inclusion.amount,
    currency: inclusion.currency,
    type: inclusion.type,
    unit: inclusion.unit,
    luxPlusTier: inclusion.lux_plus_tier,
  }
}

export function customOfferItemMap(item): App.CustomOfferItem {
  return {
    ...item,
  }
}

function orderBedbankPropertyMap(
  serverOffer: OrderTypes.BedbankOffer,
): App.BedbankProperty {
  return {
    id: serverOffer.id,
    address: bedbankPropertyAddressMap(serverOffer.address!),
    location: formatPropertyAddress(serverOffer.address!),
    timezone: serverOffer.timezone,
    latitude: serverOffer.location.latitude,
    longitude: serverOffer.location.longitude,
    finePrint: bedbankPropertyFinePrintMap(serverOffer.fine_print as any),
    phone: serverOffer.phone,
    name: serverOffer.name,
  }
}

export function bedbankOfferMap(
  item: OrderTypes.BedbankItem,
  order: OrderTypes.Order,
): App.OrderBedbankOffer {
  const typeConfig = offerTypeConfig[OFFER_TYPE_BED_BANK]!

  const property: App.BedbankProperty = orderBedbankPropertyMap(item.offer)

  const pkg: App.BedbankPackage = {
    bedGroupsDescription: item.rooms[0].bed_group.description,
    capacities: {
      ageCategories: [],
      combinations: item.rooms.map(x => ({
        adults: x.number_of_adults,
        children: x.number_of_children,
        infants: x.number_of_infants,
      })),
    },
    facilityGroups: item.offer.room.facility_groups.map(facilityGroupsMap),
    images: item.offer.room.image ? [{
      id: item.offer.room.image.id_cloudinary_external,
    }] : [],
    name: item.offer.room.name,
    description: item.offer.room.description,
    features: item.offer.room.features,
    roomId: item.offer.room.id,
    stats: {
      booked: 0,
    },
  }

  const rate: App.OrderBedbankRate = {
    bedGroups: [item.rooms[0].bed_group as any],
    currencyCode: order.currency_code,
    regionCode: order.region_code,
    id: item.fk_room_rate_id,
    roomId: item.fk_room_rate_id,
    room: pkg,
    groupId: undefined,
    inclusions: [],
    memberDiscount: 0,
    discount: 0,
    value: 0,
    occupancyPricing: item.rooms.map(room => ({
      occupancy: generateOccupancyStringByRoom({
        adults: room.number_of_adults,
        children: room.number_of_children,
        infants: room.number_of_infants,
        childrenAge: room.children_ages,
      }),
      inclusive: room.price,
      exclusive: room.exclusive_price,
      taxesAndFees: room.taxes_and_fees,
      salesTax: room.sales_tax,
      fees: (room as any).fees,
      total: room.price,
    })),
    partiallyRefundable: item.rooms[0].partially_refundable,
    refundable: item.rooms[0].refundable,
    isFlightBundle: item.rooms[0].is_flight_bundle,
    nights: item.nights,
    totals: {
      inclusive: item.total,
      exclusive: item.exclusive_price,
      taxesAndFees: item.taxes_and_fees,
      propertyFees: item.property_fees,
      total: item.total + (item.property_fees ?? 0),
    },
    cancellationPolicies: item.rooms[0].cancellation_policies,
    nonRefundableDateRanges: item.rooms[0].nonrefundable_dateranges,
    cancellationPolicy: item.rooms[0].partially_refundable ? buildPartiallyRefundableCancellationPolicies({
      checkIn: item.check_in,
      checkOut: item.check_out,
      policies: item.rooms[0].cancellation_policies,
      nonRefundableDates: item.rooms[0].nonrefundable_dateranges,
    }, { timezone: property.timezone }) : buildCancellationPolicies(item.rooms[0].cancellation_policies, {
      timezone: property.timezone,
      currencyCode: order.currency_code,
      regionCode: order.region_code,
    }),
    supplierCode: item.supplier_code,
  }

  return {
    type: typeConfig.type as App.BedbankOfferType,
    productType: typeConfig.productType,
    analyticsType: typeConfig.analyticsType as App.BedbankAnalyticsType,
    parentType: typeConfig.parentType,
    typeLabel: typeConfig.typeLabel,
    walledGarden: typeConfig.walledGarden,
    metaDescription: '',
    popularFacilities: [],
    id: item.offer.id,
    name: item.offer.name,
    slug: item.offer.slug,
    saleUnit: 'room',
    saleUnitLong: 'room',
    description: item.offer.description,
    attractions: item.offer.attractions,
    displayPricingAsPerPerson: false,
    promoteAsBundle: false,
    // @ts-ignore: maybe field should be optional?
    flights: undefined,
    image: {
      id: item.offer.image.id_cloudinary_external,
    },
    images: [
      {
        id: item.offer.image.id_cloudinary_external,
      },
    ],
    imagesCount: 1,
    tagline: item.offer.tagline,
    additionalPromoContent: item.offer.additional_promo_content,
    promotions: item.offer.promotions.map((promo) => ({
      id: promo.id,
      name: promo.name,
      inclusions: promo.inclusions?.map<App.OfferInclusion>((externalInclusion) => ({
        id: uuidV4(),
        description: externalInclusion.text,
        symbol: determineOfferFeatureSymbol(externalInclusion.icon),
        isHighlighted: externalInclusion.isHighlighted,
      })) ?? [],
      periodSchedule: promo.period_schedule,
      travelPeriod: promo.travel_period,
      los: promo.los,
      rateInclusionsLong: promo.rate_inclusions_long,
      rateInclusionsShort: promo.rate_inclusions_short,
      restrictToRates: promo.restrict_to_rates,
    })),
    property,
    phone: `+${addSpace(item.offer.phone)}`,
    facilityGroups: item.offer.facility_groups.map(facilityGroupsMap),
    packages: [
      {
        ...pkg,
        rate,
      },
    ],
    luxPlus: {},
    stats: {
      booked: 0,
      views: 0,
    },
  }
}

export function bedbankItemMap(item, order): App.OrderBedbankItem {
  const offer = bedbankOfferMap(item, order)
  return {
    transactionKey: item.transaction_key,
    id: item.id,
    orderId: item.fk_order_id,
    propertyId: item.fk_property_id,
    roomTypeId: item.fk_room_type_id,
    roomRateId: item.fk_room_rate_id,
    reservationId: item.id_reservation,
    bookingNumber: item.booking_number,
    bookingEmail: item.booking_email,
    bookingPhone: item.booking_phone,
    checkIn: moment(item.check_in),
    checkOut: moment(item.check_out),
    duration: item.nights,
    status: item.status,
    total: item.total,
    offer,
    rooms: item.rooms.map(x => bedbankRoomMap(item, x, order, offer)),
    taxesAndFees: item.taxes_and_fees,
    propertyFees: item.property_fees,
    exclusivePrice: item.exclusive_price,
    isDowngraded: item.is_downgraded,
    downgradeType: item.downgrade_type,
    downgradedFromId: item.fk_downgraded_from_id,
    numberOfDateChanges: item.number_of_date_changes,
    mx: item.mx ?? undefined,
  }
}

// actual item doesn't exist in lib types yet
interface ApiOrderExperienceItem {
  booking_number: string;
  booking_start_date?: string;
  provider_offer_id: string;
  cancellation_policies: {
    refundPolicies: Array<{ id: string, type: 'PERCENTAGE' | 'ABSOLUTE', value: number, periods: Array<string>, periodLabel: string }>
  }
  currency: string;
  customer_info: Record<string, string>;
  discount_amount?: number;
  discount_percent?: number;
  expiration_date?: string;
  fk_order_id: string;
  id: string;
  language?: string;
  le_exclusive: boolean;
  accommodation_parent_item_id?: string,
  meeting_point?: string;
  pickup_point_id?: string;
  pickup_point_name?: string;
  redemption_location_id?: string;
  redemption_location_name?: string;
  status: string;
  ticket: {
    date: string;
    fareType: string;
    identifier: string;
    productId: string;
    type: string;
    bookByDate?: string;
    rateStartDate?: string;
    rateEndDate?: string;
  };
  total: number;
  transaction_key: string;
  booking_type: App.ReservationType;
  provider: string;
  app_discount_amount?: number;
  app_discount_percent?: number;
  item_discounts: Array<App.Checkout.ItemDiscount>;
  complimentary?: boolean;
  created_at: string;
}

export function experienceTransferItemMap(
  item: any,
): App.OrderTransferItem {
  // airport drop off ID will be an airport code which is always 3 letters
  const isToAirport = item.deal_options.type === 'HOTEL-TO-AIRPORT'

  return {
    type: item.deal_options.type,
    id: item.id,
    bookingNumber: item.booking_number,
    status: item.status,
    experienceId: item.provider_offer_id,
    total: item.total,
    leExclusive: item.le_exclusive,
    accommodation_parent_item_id: item.accommodation_parent_item_id,
    // time started out required then became optional
    // unfortunately used to store date/time as a single field
    // to distinguish the two, new version started storing them in separate fields
    date: item.ticket.day ?? moment(item.ticket.date).format(ISO_DATE_FORMAT),
    time: item.ticket.day ? item.ticket.time : moment(item.ticket.date).format('HH:mm'),
    cancellationPolicies: item.cancellation_policies?.refundPolicies?.map(policy => ({
      id: policy.id,
      type: policy.type,
      value: policy.value,
      periods: policy.periods,
      periodLabel: policy.periodLabel,
    })) ?? [],
    option: item.deal_options.option,
    specialRequests: item.special_requests,
    flightNumber: item.deal_options.flight_number,
    travellers: {
      adults: item.deal_options.adults ?? 0,
      children: item.deal_options.children ?? 0,
      childSeats: item.deal_options.child_seats ?? 0,
      boosterSeats: item.deal_options.boosterSeats ?? 0,
    },
    price: item.total,
    hotel: {
      googlePlaceId: isToAirport ? item.pickup_point_id : item.dropoff_point_id,
      name: isToAirport ? item.pickup_point_name : item.dropoff_point_name,
    },
    airport: {
      code: isToAirport ? item.dropoff_point_id : item.pickup_point_id,
      name: isToAirport ? item.dropoff_point_name : item.pickup_point_name,
    },
    mobileAppDiscountAmount: item.app_discount_amount ?? 0,
    mobileAppDiscountPercentage: item.app_discount_percent ?? 0,
    baggage: {
      bags: item.deal_options.bags ?? 0,
      oversizedBags: item.deal_options.oversized_bags ?? 0,
    },
    complimentary: !!item.complimentary,
    createdAt: item.created_at,
  }
}

function experienceItemMap(
  item: any,
): App.OrderExperienceItem {
  const serverItem = item as ApiOrderExperienceItem
  return {
    id: serverItem.id,
    transactionKey: serverItem.transaction_key,
    bookingNumber: serverItem.booking_number,
    status: serverItem.status as any,
    experienceId: serverItem.provider_offer_id,
    total: serverItem.total,
    languageId: serverItem.language,
    leExclusive: serverItem.le_exclusive,
    accommodation_parent_item_id: serverItem.accommodation_parent_item_id,
    date: serverItem.ticket.date ? moment(serverItem.ticket.date).format(ISO_DATE_FORMAT) : undefined,
    time: serverItem.ticket.date ? moment(serverItem.ticket.date).format('HH:mm') : undefined,
    bookByDate: serverItem.ticket.bookByDate ? moment(serverItem.ticket.bookByDate).format(ISO_DATE_FORMAT) : undefined,
    cancellationPolicies: serverItem.cancellation_policies?.refundPolicies?.map(policy => ({
      id: policy.id,
      type: policy.type,
      value: policy.value,
      periods: policy.periods,
      periodLabel: policy.periodLabel,
    })) ?? [],
    // @ts-ignore: field might be undefined
    pickupPoint: serverItem.pickup_point_id ? {
      id: serverItem.pickup_point_id,
      name: serverItem.pickup_point_name,
    } : undefined,
    // @ts-ignore: field might be undefined
    redemptionLocation: serverItem.redemption_location_id ? {
      id: serverItem.redemption_location_id,
      name: serverItem.redemption_location_name,
    } : undefined,
    ticket: {
      id: serverItem.ticket.identifier,
      name: serverItem.ticket.fareType,
      bookByDate: serverItem.ticket.bookByDate,
      rateStartDate: serverItem.ticket.rateStartDate,
      rateEndDate: serverItem.ticket.rateEndDate,
    },
    customerInfo: serverItem.customer_info,
    bookingType: serverItem.booking_type,
    expirationDate: serverItem.expiration_date,
    provider: serverItem.provider,
    mobileAppDiscountAmount: serverItem.app_discount_amount ?? 0,
    mobileAppDiscountPercentage: serverItem.app_discount_percent ?? 0,
    complimentary: !!serverItem.complimentary,
    createdAt: serverItem.created_at,
  }
}

export function addonItemMap(item): App.OrderAddonItem {
  return {
    transactionKey: item.transaction_key,
    id: item.id,
    idSalesforceExternal: item.salesforce_id,
    opportunitySalesforceId: item.fk_opportunity_salesforce_id,
    offerSalesforceId: item.offer_salesforce_id,
    status: item.status,
    bookingNumber: item.booking_number,
    name: item.name,
    description: item.description,
    details: item.detailed_description,
    imageId: item.image_cloudinary_external,
    booked: item.channel_booking_made,
    channelProvider: item.channel_provider,
    total: item.total,
    vendorBookingEmail: item.vendor_booking_email,
    vendorContactPhone: item.vendor_contact_phone,
    channelFareType: item.fare_type,
    channelBookingMade: item.channel_booking_made,
    channelBookingId: item.channel_booking_id,
    channelBookingStartTime: item.channel_booking_start_time_local,
    channelBookingEndTime: item.channel_booking_end_time_local,
    channelGroupingId: item.channel_grouping,
    channelProductId: item.channel_product_id,
    channelFareId: item.channel_fare_id,
    channelBookingChangeable: item.channel_booking_changeable,
    hasOfflineBooking: item.channel_offline_booking,
    offlineBookingInstructions: item.channel_offline_booking_instructions,
    maxDaysBeforeCheckin: item.channel_max_days_to_book_before_checkin,
    locationText: item.location_text,
    complimentary: item.complimentary,
    fareTypeText: item.fare_type_display || item.fare_type || null,
  }
}

// Order type should be `OrderTypes.InsuranceItem` but it's not fully fleshed out yet
export function insuranceItemMap(item): App.OrderInsuranceItem {
  return {
    transactionKey: item.transaction_key,
    id: item.id,
    origin: item.origin,
    policyId: item.contract_number,
    destinations: item.destinations,
    startDate: item.start_date,
    endDate: item.end_date,
    travellers: item.travellers_details.map(t => ({
      firstName: t.first_name,
      lastName: t.surname,
      ageRange: t.age_range,
    })),
    // use href!
    pds: item._links?.pds ? item._links.pds.href : '',
    status: item.status,
    statusReason: item.status_reason,
    productName: item.product_name,
    total: item.total,
    provider: item.provider,
    idProduct: item.id_product,
    createdAt: item.created_at,
    subscriptionId: item.subscription_id,
    coverAmount: item.cover_amount,
    productType: item.product_type,
    policyIds: item.policy_ids ?? [],
    policyNames: item.policy_names ?? [],
    insuranceType: item.insurance_type,
    memberPrice: item.lux_plus_price ?? 0,
    publicPrice: item.public_price ?? 0,
    mobilePrice: item.mobile_price ?? 0,
    upgradedFromId: item.fk_upgraded_from_id,
    refundClaimUrl: item._links.refund_url?.href,
    coiPdfUrl: item.coi_pdf_url,

  }
}

function bookingProtectionItemMap(item: OrderTypes.BookingProtectionItem): App.OrderBookingProtectionItem {
  return {
    productName: 'Cancellation Protection',
    transactionKey: item.transaction_key,
    bookingReferenceId: item.fk_order_id,
    currencyCode: item.currency_code,
    eventTravelDateTime: item.event_travel_date_time,
    id: item.id_booking_protection_items,
    numberOfTickets: item.number_of_tickets,
    total: item.total,
    status: item.status as App.OrderItemStatus,
    quoteId: item.quote_id,
    saleId: item.sale_id,
    coverAmount: item.cover_amount,
    refundInitiatedFromProvider: item.refund_initiated_from_provider,
    memberPrice: item.lux_plus_price ?? 0,
    publicPrice: item.public_price ?? 0,
    mobilePrice: item.mobile_price ?? 0,
  }
}

export function bedbankRoomMap(item, room, order, offer): App.OrderBedbankRoom {
  return {
    roomId: room.id,
    name: room.name,
    reservationRoomId: room.id,
    refundable: room.refundable,
    partiallyRefundable: room.partially_refundable,
    isFlightBundle: room.is_flight_bundle,
    occupancy: {
      adults: room.number_of_adults,
      children: room.number_of_children,
      infants: room.number_of_infants,
      childrenAge: room.children_ages,
    },
    guestFirstName: room.guest_first_name,
    guestLastName: room.guest_last_name,
    guestSpecialRequests: room.guest_special_requests,
    status: room.status,
    price: room.price,
    exclusivePrice: room.exclusive_price,
    bedGroup: {
      id: room.bed_group.id,
      description: room.bed_group.description,
      configuration: room.bed_group.configuration,
    },
    cancellationPolicies: room.partially_refundable ? buildPartiallyRefundableCancellationPolicies({
      checkIn: item.check_in,
      checkOut: item.check_out,
      policies: room.cancellation_policies,
      nonRefundableDates: room.nonrefundable_dateranges,
    }, { timezone: offer.property.timezone }) : buildCancellationPolicies(room.cancellation_policies, {
      timezone: offer.property.timezone,
      currencyCode: order.currency_code,
      regionCode: order.region_code,
    }),
  }
}

export function giftCardItemsMap(item): App.OrderGiftCardItem {
  return {
    transactionKey: item.transaction_key,
    itemId: item.id,
    total: item.total,
    expiry: item.expiry,
    code: item.code,
    personalised: item.personalised,
    product: item.product ? {
      image: { id: item.product.imageId, url: item.product.imageUrl, title: item.product.name },
      name: item.product.name,
      items: item.product.items,
      type: item.product.type,
      location: item.product.location,
      url: item.product.url,
    } : undefined,
  }
}
type PromoFields = {
  id_promo_code: string
  code_name:string
} | {}

export function paymentMap(payment): App.OrderPayment {
  const promoFields:PromoFields = payment.type == PROMO_PAYMENT_TYPE ? {
    id_promo_code: payment?.response_json?.id_promo,
    code_name: payment?.response_json?.code_name,
  } : {}

  return {
    id: payment.id_payment,
    amount: parseFloat(payment.amount),
    currencyCode: payment.currency,
    type: payment.type,
    status: payment.status,
    createdAt: payment.created_at,
    transaction_key: payment.transaction_key,
    depositDetails: payment.depositDetails,
    instalmentDetails: payment.instalmentDetails,
    isDeferred: !!payment.deferredPaymentDetails?.id_deferred_payment,
    pspName: payment.psp_name,
    ...promoFields,
  }
}

export function refundMetaMap(refundMeta: ServerRefundMeta): App.OrderRefundMeta {
  return {
    orderId: refundMeta.fk_orders,
    orderItemId: refundMeta.fk_items,
    reason: refundMeta.reason,
    cashAmount: parseFloat(refundMeta.cash_amount),
    comment: refundMeta.comment,
  }
}

export function refundMap(refund, meta): App.OrderRefund {
  return {
    itemId: refund.item_id,
    amount: parseFloat(refund.amount),
    method: refund.refund_method,
    createdAt: refund.created_at,
    meta: meta ? refundMetaMap(meta) : undefined,
    reason: refund.reason,
    comment: refund.comment,
  }
}

export function flightTravellerMap(traveller: any): App.OrderFlightTraveller {
  return {
    id: traveller.id,
    title: traveller.title,
    surname: traveller.surname,
    givenName: traveller.given_name,
  }
}

export function flightEmbeddedInsuranceMap(item: any): Record<string, boolean> {
  const journeyKeyInsuranceMap: Record<string, boolean> = {}

  if (!item.journey) return journeyKeyInsuranceMap

  if (item.journey.departing) {
    journeyKeyInsuranceMap[item.journey.departing.journey_key] = !!item.journey.departing.insurance?.id
  }
  if (item.journey.returning) {
    journeyKeyInsuranceMap[item.journey.returning.journey_key] = !!item.journey.returning.insurance?.id
  }
  return journeyKeyInsuranceMap
}

export function flightItemMap(item: any): App.OrderFlightItem {
  return {
    transactionKey: item.transaction_key,
    itemId: item.id,
    orderId: item.fk_order_id,
    reservationId: item.fk_reservation_id,
    status: item.status,
    total: item.total,
    provider: item.provider,
    pnrId: item.pnr_id,
    departureDate: item.journey?.departing?.flights?.[0]?.departing_date,
    arrivalDate: item.journey?.departing?.flights?.[0]?.arrival_date,
    atolFee: item.journey?.atolFee || 0,
    travellers: item.journey?.travellers?.map(flightTravellerMap),
    embeddedInsuranceByJourneyKey: flightEmbeddedInsuranceMap(item),
  }
}

function mapOrderBusinessTravellerCreditItem(item: OrderTypes.BusinessCreditItem): App.OrderBusinessTravellerCreditItem {
  return {
    id: item.id,
    total: item.total,
    type: 'business_credit',
  }
}

type RawServiceFee = {
  total: number;
  status: string;
  service_fee_percentage: number;
}

export function serviceFeeMap(serviceFeeResponse: Array<RawServiceFee> | undefined): { serviceFee: App.ServiceFee } | null {
  if (!serviceFeeResponse || (!Array.isArray(serviceFeeResponse) || serviceFeeResponse.length === 0)) {
    return null
  }
  const item = serviceFeeResponse[0]
  return {
    serviceFee: {
      total: Number(item.total),
      percentage: item.service_fee_percentage,
      status: item.status as App.ServiceFeeStatus,
    },
  }
}

export function subscriptionItemMap(item: OrderTypes.SubscriptionItem): App.OrderItemLuxPlusSubscription {
  return {
    id: item.id,
    type: 'subscription',
    subType: item.sub_type as any,
    status: item.status as any,
    subscriptionOfferId: item.subscription_offer_id,
    subscriptionPeriodId: item.subscription_period_id ?? undefined,
    subscriptionId: item.subscription_id,
    transactionKey: item.transaction_key,
    total: item.total,
  }
}

function orderItemsCount(allItems: Record<string, any>): number {
  let totalCount = 0

  for (const key in allItems) {
    if (key.endsWith('_items') && Array.isArray(allItems[key])) {
      totalCount += allItems[key].length
    }
  }

  return totalCount
}

export function orderMap(order: OrderTypes.Order): App.Order {
  const [experienceItems, transferItems] = partitionBy(order.experience_items ?? [], item => !item.deal_options)

  const mappedOrder: App.Order = {
    id: order.id,
    externalOrderId: order.external_order_id,
    bookingNumber: order.number,
    currencyCode: order.currency_code,
    createdAt: order.created_at,
    status: order.status as App.OrderStatus, // Pending svc-order contract update
    orderSuccess: (order as any).order_success,
    displayStatus: order.display_status,
    experienceItems: experienceItems.map(experienceItemMap),
    transferItems: transferItems.filter(item => item.status_reason !== EXPERIENCE_CANCELLATION_REASON_DOWNGRADED).map(item => experienceTransferItemMap(item)),
    // When an order has a status of 'processed', the order of items is swapped, no idea why
    // so keep them consistent by ordering by created_at
    items: sortBy(order.items, (item) => moment(item.created_at).valueOf(), 'asc').map(item => itemMap(item, order)),
    itemsCount: orderItemsCount(order),
    giftCardItems: order.gift_card_items.map(giftCardItemsMap),
    insuranceItems: order.insurance_items.filter(i => i.status_reason !== INSURANCE_CANCELLATION_REASON_UPGRADED).map(insuranceItemMap),
    bookingProtectionItems: order.booking_protection_items.map(bookingProtectionItemMap),
    addonItems: order.addon_items.map(addonItemMap),
    flightItems: sortBy(order.flight_items, (item: any) => item.journey?.legNumber, 'asc').map(flightItemMap),
    bedbankItems: order.bedbank_items.map(item => bedbankItemMap(item, order)),
    tourItems: order.tour_items?.map(item => tourV2OrderItemMap(item)) ?? [], // type missing from contract
    tourExperienceItems: order.tour_optional_experience_items?.map(item => tourV2OrderOptionalExperienceItemMap(item)) ?? [], // type missing from contract
    carHireItems: order.car_hire_items?.map(item => carHireOrderItemMap(item)) ?? [], // type missing from contract
    cruiseItems: order.cruise_items?.map(item => cruiseOrderItemMap(item)) ?? [], // type missing from contract
    customOfferItems: order.custom_offer_items?.map(item => customOfferItemMap(item)) ?? [], // type missing from contract
    source: order.utm_source,
    medium: order.utm_medium,
    campaign: order.utm_campaign,
    leLabel: order.le_label,
    leAttribution: order.le_attribution,
    luxLoyalty: {
      tier: order.lux_loyalty_tier as App.LuxLoyaltyTier,
      pointsSpent: [],
      benefitsRedeemed: [],
    },
    partnerships: order.partnerships || {},
    purchasingCustomerId: order.purchasing_customer_id,
    regionCode: order.region_code,
    hasFlight: order.has_flight,
    hasExperience: order.has_experience,
    hasInsurance: order.has_insurance,
    hasBookingProtection: order.has_booking_protection,
    transactionKey: order.transaction_key,
    total: order.total,
    payments: [],
    refunds: [],
    orderGiftStatus: order.gift_status as App.OrderGiftStatus,
    orderGift: order.order_gift ? {
      receiverEmail: order.order_gift.receiver_email,
      receiverName: order.order_gift.receiver_name,
      giverName: order.order_gift.giver_name,
      giftMessage: order.order_gift.gift_message,
      scheduleDate: order.order_gift.scheduled_date,
    } : undefined,
    shouldShowTotalsBreakdown: order.gift_status !== 'redeemed',
    customerId: order.fk_customer_id,
    customerFullName: order.customer_full_name,
    subscriptionItems: order.subscription_items?.map(subscriptionItemMap),
    subscriberTier: order.subscriber_tier ?? undefined, // can come back as null
    ...serviceFeeMap((order as any).service_fee_items),
    refundRequests: {},
    outsidePolicyDatesRequests: {},
    type: 'summary',
    locked: !!(order as any).order_lock?.is_locked,
    hasCarHireIntention: order.has_car_hire_intention ?? false,
  }

  if (config.businessTraveller.currentAccountMode === 'business' && order.business_credit_items) {
    mappedOrder.businessTravellerCreditItems = order.business_credit_items.map(mapOrderBusinessTravellerCreditItem)
  }

  return mappedOrder
}
