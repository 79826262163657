import { fetchAndSubscribeToUserNotifications, unsubscribeFromUserNotifications } from 'actions/UserNotificationActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { EmptyArray } from 'lib/array/arrayUtils'
import { useEffect } from 'react'

type Return = [
  notifications: Array<App.UserNotification>,
  boolean,
  any,
]

function useUserNotifications(): Return {
  const dispatch = useAppDispatch()

  const notifications = useAppSelector(state => state.auth.notifications)
  const fetching = useAppSelector(state => state.auth.fetchingNotifications)
  const error = useAppSelector(state => state.auth.notificationsError)

  useEffect(() => {
    dispatch(fetchAndSubscribeToUserNotifications())

    return () => {
      dispatch(unsubscribeFromUserNotifications())
    }
  }, [dispatch])

  return [
    notifications ?? EmptyArray,
    !!fetching,
    error,
  ]
}

export default useUserNotifications
