import getObjectKey from 'lib/object/getObjectKey'

function getLuxLoyaltyPointBurnCalculationKey(
  requests: Array<App.Checkout.LuxLoyaltyPointBurnCalculationRequest>,
): string {
  return getObjectKey(requests.map(({ item, ...rest }) => ({
    ...rest,
    // Don't need to stringify the massive checkout item object!
    // The `itemId` should be reliable.
    itemId: item.itemId,
  })))
}

export default getLuxLoyaltyPointBurnCalculationKey
