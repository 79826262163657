import { Order } from '@luxuryescapes/contract-svc-order'
import { isMobileAppDevice } from 'lib/web/deviceUtils'
import { createSelector } from 'reselect'

/**
 * @see {@link Order.SourceApp}
 */
const getExperienceItemSourceApp = createSelector(
  (state: App.State) => isMobileAppDevice(state.system.rawUserAgentString),
  (state: App.State) => state.interactionStudio.platform,
  (isMobileApp, platform): Order.SourceApp => {
    if (isMobileApp) {
      return 'mobile-app'
    } else if (platform === 'ios' || platform === 'android') {
      return 'mobile-web'
    }
    return 'web'
  },
)

export default getExperienceItemSourceApp
