import React, { PropsWithChildren, useMemo } from 'react'
import styled, { css } from 'styled-components'
import cn from 'clsx'

import { breakpointSizes, mediaQueryUp, mediaQueryDown, mediaQueryOnly } from 'components/utils/breakpoint'
import { objectKeys } from 'lib/object/objectUtils'

const sizes = objectKeys(breakpointSizes)
const precompiledCss = sizes.map(size => {
  if (size === 'mobile') {
    return css`
      &.hidedown-${size} {
        ${mediaQueryDown[size]} { display: none; }
      }
  
      &.hideonly-${size} {
        ${mediaQueryOnly[size]} { display: none; }
      }
    `
  } else if (size === 'extraLargeDesktop') {
    return css`
      &.hideup-${size} {
        ${mediaQueryUp[size]} { display: none; }
      }
  
      &.hideonly-${size} {
        ${mediaQueryOnly[size]} { display: none; }
      }
    `
  } else {
    return css`
      &.hidedown-${size} {
        ${mediaQueryDown[size]} { display: none; }
      }

      &.hideup-${size} {
        ${mediaQueryUp[size]} { display: none; }
      }

      &.hideonly-${size} {
        ${mediaQueryOnly[size]} { display: none; }
      }
    `
  }
})

const UpDownBreakpoint = styled.div`
  ${precompiledCss}

  &:empty {
    display: none;
  }
`

interface Props extends React.HTMLAttributes<HTMLElement> {
  min?: App.ScreenSize;
  max?: App.ScreenSize;
  only?: App.ScreenSize;
  not?: App.ScreenSize;
  as?: React.ElementType;
  className?: string;
}

const CSSBreakpoint = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const { min, max, only, not, className, children, ...rest } = props

  const onlyOrMin = only ?? min
  const onlyOrMax = only ?? max

  const hideDownSize = useMemo(() => {
    if (onlyOrMin) {
      const minIndex = sizes.indexOf(onlyOrMin)
      return sizes[minIndex - 1]
    }
  }, [onlyOrMin])

  const hideUpSize = useMemo(() => {
    if (onlyOrMax) {
      const maxIndex = sizes.indexOf(onlyOrMax)
      return sizes[maxIndex + 1]
    }
  }, [onlyOrMax])

  const classes = cn(className, {
    [`hidedown-${hideDownSize}`]: hideDownSize,
    [`hideup-${hideUpSize}`]: hideUpSize,
    [`hideonly-${not}`]: not,
  })

  return <UpDownBreakpoint {...rest} className={classes} ref={ref}>
    {children}
  </UpDownBreakpoint>
})

CSSBreakpoint.displayName = 'CSSBreakpoint'

export default CSSBreakpoint
