import { shallowEqual } from 'hooks/reduxHooks'

/** For use with `React.memo` */
function shallowEqualWithNestedProps<P, N extends keyof P>(nestedPropNames: Array<N>) {
  return (prevProps: Readonly<P>, nextProps: Readonly<P>): boolean => {
    const prevRest = { ...prevProps }
    const nextRest = { ...nextProps }

    for (const prop of nestedPropNames) {
      if (!shallowEqual(prevProps[prop], nextProps[prop])) {
        return false
      }
      delete prevRest[prop]
      delete nextRest[prop]
    }

    return shallowEqual(prevRest, nextRest)
  }
}

export default shallowEqualWithNestedProps
