import { useAppSelector } from 'hooks/reduxHooks'
import { useMemo } from 'react'

/**
 * Retrieves the users current benefit for the type given
 * @returns The benefit, or nothing if they don't have it
 */
function useLuxLoyaltyUserBenefit(
  /** The benefit type to retrieve */
  benefitType: App.LuxLoyaltyBenefitType,
): App.LuxLoyaltyBenefit | undefined {
  const userBenefits = useAppSelector(state => state.luxLoyalty.account.data?.benefits)
  return useMemo(() => userBenefits?.find(benefit => benefit.code === benefitType), [benefitType, userBenefits])
}

export default useLuxLoyaltyUserBenefit
