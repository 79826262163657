/**
 * A file to store 'lists' that are used to show things in the car hire page
 */
import React from 'react'
import LineFileAltIcon from 'components/Luxkit/Icons/line/LineFileAltIcon'
import LineExclamationTriangleIcon from 'components/Luxkit/Icons/line/LineExclamationTriangleIcon'
import LineClockEightIcon from 'components/Luxkit/Icons/line/LineClockEightIcon'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import LineCarIcon from 'components/Luxkit/Icons/line/LineCarIcon'
import LineFileTimesAltIcon from 'components/Luxkit/Icons/line/LineFileTimesAltIcon'
import LineShieldCheckIcon from 'components/Luxkit/Icons/line/LineShieldCheckIcon'
import LineShieldPlusIcon from 'components/Luxkit/Icons/line/LineShieldPlusIcon'

interface FAQ {
  icon: React.ReactElement;
  question: string;
  answer: string;
}

export const HARDCODED_SUPPLIERS: Array<{ name: string, image: App.Image }> = [
  { name: 'Avis', image: { url: 'https://ctimg-svg.cartrawler.com/supplier-images/avis.svg' } },
  { name: 'Hertz', image: { url: 'https://ctimg-svg.cartrawler.com/supplier-images/hertz.svg' } },
  { name: 'Europcar', image: { url: 'https://ctimg-svg.cartrawler.com/supplier-images/europcar.svg' } },
  { name: 'Sixt', image: { url: 'https://ctimg-svg.cartrawler.com/supplier-images/sixt.svg' } },
  { name: 'Thrifty', image: { url: 'https://ctimg-svg.cartrawler.com/supplier-images/thrifty.svg' } },
  { name: 'Budget', image: { url: 'https://ctimg-svg.cartrawler.com/supplier-images/budget.svg' } },
  { name: 'Alamo', image: { url: 'https://ctimg-svg.cartrawler.com/supplier-images/alamo.svg' } },
  { name: 'Enterprise', image: { url: 'https://ctimg-svg.cartrawler.com/supplier-images/enterprise.svg' } },
]

export const TRANSPORTATION_CODE = {
  AIRPLANE: 14,
}

export function getCarHireFAQ(isCarHireInsuranceEnabled: boolean): Array<FAQ> {
  const FAQ = [
    {
      icon: <LineFileAltIcon />,
      question: 'What documents do I need to rent a car?',
      answer: `
  To rent a car, you will typically require:

  1. Valid drivers’ license. Remember to carry it with you when picking up the rental car.
  2. Identification: A valid passport or national identification card to confirm your identity.
  3. Payment method: In your name with sufficient funds to cover the rental charges and any security deposit.`,
    },
    {
      icon: <LineUserIcon />,
      question: 'How old do I need to be to rent a car?',
      answer: `The minimum driver age for most rental companies is 25 years. Driver's younger than 30 may incur a surcharge or be limited in the vehicle type they can select. Older drivers aged over 70 may require a medical and incur a surcharge. Make sure you enter your correct age, at the time of hire, when searching for a car so you can see the options available for you.
      All guests intending to drive must present a valid full national driving license, which has been held for at least three years. A current international driving license or translation must accompany driving licenses issued in a language other than English.`,
    },
    {
      icon: <LineCarIcon />,
      question: 'What should I look for when choosing a rental car?',
      answer: `
  - Passenger and baggage capacity: Ensure ample space for you, your passengers, and all your luggage by checking the passenger and baggage capacity.
  - Transmission: Choose between manual and automatic transmission to ensure you're comfortable with the car's driving system.
  - Mileage policy: Verify the mileage policy to understand if there are any limitations on the number of kilometers you can drive during the rental period.
  - Pick up and drop off location: Confirm the pick-up and drop-off location, especially for airport rentals, as you may need to utilize a free shuttle or terminal service. Find all this information in the offer and your booking confirmation.`,
    },
    ...isCarHireInsuranceEnabled ? [{
      icon: <LineShieldCheckIcon />,
      question: 'Do I need insurance when renting a car?',
      answer: `
  It is highly recommended to have insurance coverage when renting a car. Most rental companies offer insurance options, including Collision Damage Waiver (CDW) and Theft Protection, which provide financial protection in case of an accident, theft, or damage to the rental vehicle. It\'s important to review the insurance terms and conditions prior to booking.

  Luxury Escapes offers an Enhanced Protection insurance option that covers up to 100% of the excess in case of an incident. This provides you with added peace of mind during your rental period.`,
    }] : [],
    ...isCarHireInsuranceEnabled ? [{
      icon: <LineShieldPlusIcon />,
      question: 'Can I purchase insurance through Luxury Escapes?',
      answer: 'Absolutely! Luxury Escapes offers an Enhanced Protection insurance option that covers up to 100% refundable excess in case of an incident. This provides you with added peace of mind during your rental period. Currently, Enhanced Protection coverage can be purchased as an add-on during your Car Hire checkout. Full coverage information can be found by clicking learn more on each policy on the checkout page.',
    }] : [],
    {
      icon: <LineExclamationTriangleIcon />,
      question: 'What should I do if I get into an accident in a rental car?',
      answer: `If you find yourself in an accident while driving a rental car, follow these steps:
  1. Ensure safety: Prioritise your safety and the safety of others involved. Move to a safe location if possible and contact emergency services if necessary.
  2. Inform the rental company and police: Report the accident immediately using their designated emergency contact number. They will guide you on the necessary next steps.
  3. Document the incident: Take photos of the accident scene, gather information from any involved parties or witnesses, and make note of any relevant details. This documentation may be required for insurance claims. Information you’ll likely need to collect includes registration and license number of both vehicles and drivers, address of the other driver and photos of the incident.`,
    },
    {
      icon: <LineClockEightIcon />,
      question: 'What happens if I return the car late?',
      answer: 'Returning the rental car late may result in additional charges. Rental companies usually have specific policies regarding late returns, including fees charged per hour or additional full-day charges. It\'s important to adhere to the agreed-upon return time to avoid any unexpected costs. If you anticipate being late, contact the rental company in advance to discuss possible solutions.',
    },
    {
      icon: <LineFileTimesAltIcon />,
      question: 'Can I cancel my rental reservation?',
      answer: 'Most reservations can be cancelled up to 48 hours prior to pick up. Your cancellation policy is clearly highlighted on your booking. Prior to purchase, review the terms and conditions of your specific reservation as they may vary depending on the rental company. To cancel your reservation, please contact our Customer Support team on 1300 88 99 00.',
    },
  ]

  return FAQ
}

export const CAR_HIRE_INSURANCE_COVERS = [
  {
    value: 'Non-refundable excess',
    includedInLimitedInsurance: true,
  },
  {
    value: 'Third Party Protection',
    includedInLimitedInsurance: true,
  },
  {
    value: 'Your personal belongings',
    includedInLimitedInsurance: false,
  },
  {
    value: 'Overseas medical expenses',
    includedInLimitedInsurance: false,
  },
  {
    value: 'Replacement keys and locks',
    includedInLimitedInsurance: false,
  },
  {
    value: 'Accidental death',
    includedInLimitedInsurance: false,
  },
  {
    value: 'Misfuelling fees',
    includedInLimitedInsurance: false,
  },
]

export const trendingDestinationToSearchLocation = new Map<string, App.SearchItem>([
  ['SYDNEY', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_678c61ac5b4f91ca86e112fc50e63766',
    format: {
      mainText: 'Sydney',
      secondaryText: 'New South Wales, Australia',
    },
  }],
  ['HOBART', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_b573c52d599a979ba8c16e9789bb8014',
    format: {
      mainText: 'Hobart',
      secondaryText: 'Tasmania, Australia',
    },
  }],
  ['WAIKIKI', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_21bf8d519b1e67cd0b9d4b50b55fccdd',
    format: {
      mainText: 'Waikiki',
      secondaryText: 'Honolulu, Hawaii, United States of America',
    },
  }],
  ['CHRISTCHURCH', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_1e15efc3fac4ba31daa6f91082c97e97',
    format: {
      mainText: 'Christchurch',
      secondaryText: 'Canterbury, New Zealand',
    },
  }],
  ['ATHENS', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_342fdc93f26b8bb8fe3548a9188ea5d2',
    format: {
      mainText: 'Athens',
      secondaryText: 'Attica, Greece',
    },
  }],
  ['ROME', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_832d851fd4d8851affa0b77077f5b4d6',
    format: {
      mainText: 'Rome',
      secondaryText: 'Lazio, Italy',
    },
  }],
  ['BARCELONA', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_af15ce7cbd279b1a769d85d1540a5073',
    format: {
      mainText: 'Barcelona',
      secondaryText: 'Catalonia, Spain',
    },
  }],
  ['LONDON', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_66ff0e7c30438d414ff0a941b759f114',
    format: {
      mainText: 'London',
      secondaryText: 'England, United Kingdom',
    },
  }],
  ['MELBOURNE', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_15fa44474c92da27cf2125373356db4c',
    format: {
      mainText: 'Melbourne',
      secondaryText: 'Victoria, Australia',
    },
  }],
  ['GOLD COAST', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_bac732346917b0a1231354ef55faf00a',
    format: {
      mainText: 'Gold Coast',
      secondaryText: 'Queensland, Australia',
    },
  }],
  ['CAIRNS', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_55dddb74a8c2e96db33e4363b0a73b98',
    format: {
      mainText: 'Cairns',
      secondaryText: 'Cairns, Queensland, Australia',
    },
  }],
  ['LAUNCESTON', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_019cf615e71dc6ec778f64fb0022f3d9',
    format: {
      mainText: 'Launceston',
      secondaryText: 'Tasmania, Australia',
    },
  }],
  ['PERTH', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_7c27cf9f8fe09724bb754e474610eb0e',
    format: {
      mainText: 'Perth',
      secondaryText: 'Western Australia, Australia',
    },
  }],
  ['BRISBANE', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_b96d145f6c31e35755f4a6841574ba37',
    format: {
      mainText: 'Brisbane',
      secondaryText: 'Queensland, Australia',
    },
  }],
  ['AUCKLAND', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_464adf7b911508701f4c4e70eab19a6e',
    format: {
      mainText: 'Auckland',
      secondaryText: 'Auckland Region, New Zealand',
    },
  }],
  ['NEW YORK', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_1ada7faf229425b786d87f7d9d5d43f2',
    format: {
      mainText: 'New York',
      secondaryText: 'New York, United States of America',
    },
  }],
  ['SAN FRANCISCO', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_bd80d53a4b5d3aef9d8a1fabe2feecfc',
    format: {
      mainText: 'San Francisco',
      secondaryText: 'California, United States of America',
    },
  }],
  ['ADELAIDE', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_f4c2c73d7fe5eb38fd83d32a61604247',
    format: {
      mainText: 'Adelaide',
      secondaryText: 'South Australia, Australia',
    },
  }],
  ['BROOME', {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_83fba92646fd4b0c7d8995bf405214c8',
    format: {
      mainText: 'Broome',
      secondaryText: 'Western Australia, Australia',
    },
  }],
])

export const trendingDestinationsToImageId = new Map<string, string>([
  ['SYDNEY', 'sl51ijurwy2yd2grv63'],
  ['WAIKIKI', 'sl51ijurwy2yd2grv63'],
  ['CHRISTCHURCH', 'er18h25ch9z4igigtj'],
  ['ATHENS', '1fem2o5idwichvob7lh'],
  ['ROME', 'awrbmy83k9yawi7pre'],
  ['BARCELONA', '4rtbj79ohskfqb6iwa'],
  ['LONDON', '8j2786i5gb1lwqp27bkj'],
  ['MELBOURNE', 'asgz0fkt6cf311m98rq'],
  ['GOLD COAST', 'l85etp6jx8l4q5e9066e'],
  ['CAIRNS', 'o7kc5i36pgxedgdlkg0g'],
  ['HOBART', '1p3vk99nrobag2fhdzsx'],
  ['LAUNCESTON', '1qtj8zwzzoq8dtb1em2w'],
  ['PERTH', 'e9zytyo6qkuq0xsiwra'],
  ['BRISBANE', 'fnd6pvqqrne0iq6jrqs'],
  ['AUCKLAND', 'pzjxqagnbik2lesq86x'],
  ['NEW YORK', 'qqh34vxmsflghdk6q9f'],
  ['SAN FRANCISCO', '2pzhx667zpbolv1dup'],
  ['ADELAIDE', 'idk3in3pu8uhpknzafe'],
  ['BROOME', 'diroqvfo7ghqesx0dam'],
])

export const TRENDING_DESTINATIONS: Record<string, Pick<App.CarHireDestinationPriceInfoParams, 'cities' | 'airports'>> = {
  WORLDWIDE: {
    cities: ['Waikiki', 'Christchurch', 'Athens', 'New York', 'San Francisco', 'Rome', 'Barcelona', 'London', 'Hobart', 'Auckland'],
  },
  AUSTRALIA: {
    cities: ['Sydney', 'Melbourne', 'Gold coast', 'Cairns', 'Adelaide', 'Hobart', 'Launceston', 'Broome', 'Brisbane'],
  },
}
