import { CHECKOUT_ITEM_TYPE_BEDBANK, CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE, CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_TOUR_V1 } from 'constants/checkout'
import { RESERVATION_TYPE_BOOK_LATER, RESERVATION_TYPE_INSTANT_BOOKING } from 'constants/reservation'
import { OfferPackageOptionView } from 'selectors/offerPage/offerPageSelectors'
import uuidV4 from 'lib/string/uuidV4Utils'
import { PackageOptionViewByRoom } from 'selectors/offerPage/multipleRoomBookingSelectors'
import { addGTMEvent } from 'api/googleTagManager'
import { addAccommodationToCheckout, addBundleAndSaveToCheckout } from 'checkout/lib/analytics/checkoutUAMappers'
import { nonNullable } from 'lib/array/arrayUtils'
import { BundleOfferPackageOptionView } from 'selectors/offerPage/bundledOfferPageSelectors'

type Period = { checkIn: string, checkOut: string, duration?: number }

export function generateMultipleRoomCheckoutItems(
  selectedPackagesByRoom: PackageOptionViewByRoom,
  offer: App.Offer,
  dates: Period,
  roomOccupants: Array<App.RoomOccupants>,
  currencyCode: string,
): Array<App.Checkout.InstantBookingLEHotelItem> {
  return nonNullable(Object.entries(selectedPackagesByRoom).map(([roomId, packageOption]) => {
    const occupancy = roomOccupants.find(room => room.roomId === roomId)
    if (packageOption && occupancy) {
      const reservationType = RESERVATION_TYPE_INSTANT_BOOKING

      addGTMEvent(addAccommodationToCheckout(offer, packageOption, reservationType, currencyCode))

      return ({
        itemId: uuidV4(),
        offerId: offer.id,
        transactionKey: uuidV4(),
        itemType: CHECKOUT_ITEM_TYPE_LE_HOTEL,
        reservationType,
        packageId: packageOption.package.id,
        roomRateId: packageOption.package.roomRate!.id,
        duration: packageOption.package.duration,
        occupancy,
        ...dates,
      })
    }
    return undefined
  }))
}

export function generateBundleAndSaveCheckoutItems(
  offer: App.Offer,
  packageOption: BundleOfferPackageOptionView,
  dates: Record<string, Required<Period>>,
  roomOccupants: Array<App.Occupants>,
  currencyCode: string,
): Array<App.Checkout.BundleAndSaveItem> {
  return roomOccupants.map((occupancy): App.Checkout.BundleAndSaveItem => {
    addGTMEvent(addBundleAndSaveToCheckout(offer, packageOption, currencyCode))

    return {
      itemId: uuidV4(),
      offerId: offer.id,
      transactionKey: uuidV4(),
      itemType: CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
      packageId: packageOption.bundlePackageId,
      occupancy,
      dates,
    }
  })
}

export function generateVillaCheckoutItem(
  offer: App.Offer | App.OfferSummary,
  packageOption: OfferPackageOptionView,
  dates: Period,
  occupancy: App.Occupants,
  currencyCode: string,
): App.Checkout.VillaItem {
  const reservationType = RESERVATION_TYPE_INSTANT_BOOKING

  addGTMEvent(addAccommodationToCheckout(offer, packageOption, reservationType, currencyCode))

  return {
    itemId: uuidV4(),
    offerId: offer.id,
    transactionKey: uuidV4(),
    itemType: 'villa',
    reservationType,
    packageId: packageOption.package.id,
    roomRateId: packageOption.package.roomRate!.id,
    duration: packageOption.package.duration,
    occupancy,
    ...dates,
  }
}

interface GenerateAccommodationCheckoutItemsOptions {
  offer: App.Offer | App.OfferSummary,
  packageOption: OfferPackageOptionView,
  dates: Period,
  roomOccupants: Array<App.Occupants>,
  currencyCode: string,
  hasLoyaltyBenefitRoomUpgrade?: boolean,
}
export function generateAccommodationCheckoutItems({
  offer,
  packageOption,
  dates,
  roomOccupants,
  currencyCode,
  hasLoyaltyBenefitRoomUpgrade,
}: GenerateAccommodationCheckoutItemsOptions): Array<App.Checkout.InstantBookingLEHotelItem> {
  return roomOccupants.map(occupancy => {
    const reservationType = RESERVATION_TYPE_INSTANT_BOOKING

    addGTMEvent(addAccommodationToCheckout(offer, packageOption, reservationType, currencyCode))

    return {
      itemId: uuidV4(),
      offerId: offer.id,
      transactionKey: uuidV4(),
      itemType: CHECKOUT_ITEM_TYPE_LE_HOTEL,
      reservationType,
      packageId: packageOption.package.id,
      roomRateId: packageOption.package.roomRate!.id,
      duration: packageOption.package.duration,
      occupancy,
      hasLoyaltyBenefitRoomUpgrade,
      ...dates,
    }
  })
}

export function generateBedbankAccommodationCheckoutItems(
  offer: App.BedbankOffer,
  pkg: App.BedbankPackage,
  rate: App.BedbankRate,
  dates: Required<Period>,
  roomOccupants: Array<App.Occupants>,
  sessionId?: string,
  bundledItemIds?: Array<string>,
): Array<App.Checkout.BedbankHotelItem> {
  return roomOccupants.map<App.Checkout.BedbankHotelItem>(occupancy => ({
    offerId: offer.id,
    sessionId,
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_BEDBANK,
    roomId: pkg.roomId,
    roomRateId: rate.id,
    rateGroupId: rate.groupId,
    bedGroupId: rate.bedGroups[0].id,
    isFlightBundle: rate.isFlightBundle,
    boardCode: rate.boardCode,
    refundable: rate.refundable,
    occupancy,
    ...dates,
    bundledItemIds,
  }))
}

interface BedbankChangeDatesItemsOptions {
  orderId: string,
  orderItemId: string,
  offerId: string,
  roomId: string,
  roomRateId: string,
  rateGroupId?: string,
  bedGroupId: string,
  isFlightBundle: boolean,
  dates: { checkIn: string, checkOut: string, duration: number },
  occupancies: Array<App.Occupants>,
}

export function generateBedbankChangeDatesAccommodationCheckoutItems({
  orderId,
  orderItemId,
  offerId,
  roomId,
  roomRateId,
  bedGroupId,
  rateGroupId,
  isFlightBundle,
  dates,
  occupancies,
}: BedbankChangeDatesItemsOptions): Array<App.Checkout.BedbankHotelItem> {
  return occupancies.map<App.Checkout.BedbankHotelItem>(occupancy => ({
    offerId,
    itemId: uuidV4(),
    orderItemId,
    orderId,
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_BEDBANK,
    roomId,
    roomRateId,
    bedGroupId,
    rateGroupId,
    isFlightBundle,
    occupancy,
    ...dates,
  }))
}

export function generateBNBLCheckoutItem(
  offer: App.Offer,
  packageOption: App.Package,
  numOfRooms = 1,
): Array<App.Checkout.BNBLLEHotelItem> {
  return new Array(numOfRooms).fill(null).map(() => ({
    offerId: offer.id,
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_LE_HOTEL,
    reservationType: RESERVATION_TYPE_BOOK_LATER,
    packageId: packageOption.id,
    roomRateId: packageOption.roomRate!.id,
    duration: packageOption.duration,
  }))
}

export function generateSelectDateCheckoutItem(
  offer: App.Offer,
  packageOption: App.Package,
  orderId: string,
  orderItemId: string,
): App.Checkout.BNBLLEHotelSetDatesItem {
  return {
    itemId: uuidV4(),
    offerId: offer.id,
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_LE_HOTEL,
    reservationType: RESERVATION_TYPE_INSTANT_BOOKING,
    packageId: packageOption.id,
    roomRateId: packageOption.roomRate!.id,
    duration: packageOption.duration,
    orderItemId,
    orderId,
  }
}

export function generateTourCheckoutItem(
  offer: App.Offer,
  pkg: App.Package,
  occupancy: App.Occupants,
  options: {
    dates?: { startDate: string, endDate: string },
  } = {},
): App.Checkout.LETourV1Item {
  return {
    itemId: uuidV4(),
    offerId: offer.id,
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_TOUR_V1,
    packageId: pkg.id,
    duration: pkg.duration,
    occupancy,
    startDate: options.dates?.startDate,
    endDate: options.dates?.endDate,
  }
}

export function generatePackageUpgradeItem(
  offer: App.Offer | App.OfferSummary,
  order: App.Order,
  orderItem: App.OrderItem,
): App.Checkout.InstantBookingLEHotelItem {
  const pkgOption = orderItem.package as App.OrderItemHotelPackage
  const reservation = orderItem.reservation

  if (!reservation) {
    throw new Error('Reservation is required for package upgrade')
  }

  const occupancy: App.Occupants = {
    adults: reservation.adults,
    children: reservation.children,
    infants: reservation.infants,
    childrenAge: reservation.childrenAge,
  }

  return {
    itemId: uuidV4(),
    offerId: offer.id,
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_LE_HOTEL,
    reservationType: RESERVATION_TYPE_INSTANT_BOOKING,
    packageId: pkgOption.id,
    roomRateId: pkgOption.roomRateId,
    duration: pkgOption.duration,
    checkIn: reservation.startDate,
    checkOut: reservation.endDate,
    occupancy,
    orderId: order.id,
    orderItemId: orderItem.id,
    isUpdated: false,
  }
}

export function generateChangeDatesItem(
  offer: App.Offer | App.OfferSummary,
  order: App.Order,
  orderItem: App.OrderItem,
  selectedDate?: Partial<App.CalendarDay>,
): App.Checkout.InstantBookingLEHotelItem {
  const pkgOption = orderItem.package as App.OrderItemHotelPackage
  const reservation = orderItem.reservation

  if (!reservation) {
    throw new Error('Reservation is required for package upgrade')
  }

  const occupancy: App.Occupants = {
    adults: reservation.adults,
    children: reservation.children,
    infants: reservation.infants,
    childrenAge: reservation.childrenAge,
  }

  return {
    itemId: uuidV4(),
    offerId: offer.id,
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_LE_HOTEL,
    reservationType: RESERVATION_TYPE_INSTANT_BOOKING,
    packageId: pkgOption.id,
    roomRateId: pkgOption.roomRateId,
    duration: pkgOption.duration,
    checkIn: selectedDate?.checkIn ?? reservation.startDate,
    checkOut: selectedDate?.checkOut ?? reservation.endDate,
    occupancy,
    orderId: order.id,
    orderItemId: orderItem.id,
    isUpdated: !!selectedDate,
  }
}
