import { createSelector } from 'reselect'
import { getTravellerFormSchemaRequestKey } from './request/travellerSchema'

const getCheckoutTravellerSchema = createSelector(
  (state: App.State) => state.checkout.schemaData[getTravellerFormSchemaRequestKey(state)],
  (schema): App.Checkout.TravellerFormSchemaData | null => {
    return schema ?? null
  },
)

export default getCheckoutTravellerSchema
