import React, { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react'

import TextInput from 'components/Common/Form/Input/TextInput'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { connect } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { resetUserPassword } from 'actions/AuthActions'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import { selectLoggedIn } from 'selectors/accountSelectors'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { AccountAccessModalResult } from './AccountAccessModal'

interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

interface Props {
  error?: any;
  token: string;
  callbackPath?: string;
  dismissable?: boolean;
}

function AccountAccessResetPassword(props: Props) {
  const {
    error,
    token,
    callbackPath,
    dismissable,
  } = props

  const firstInput = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const modalContext = useModalElementContext<AccountAccessModalResult>()
  const loggedIn = useAppSelector(selectLoggedIn)
  const authProcessing = useAppSelector(state => state.auth.processing)
  const authError = useAppSelector(state => state.auth.error)
  const [resetting, setResetting] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<string | null>(null)

  useEffect(() => {
    if (resetting && !authProcessing) {
      // must be done with the reset operation
      setResetting(false)
      if (loggedIn && !authError) {
        // done and we're now logged in - must've been successful, close the modal
        modalContext.resolve({ loggedIn: true })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetting, authProcessing, loggedIn, authError])

  useEffect(() => {
    firstInput.current?.focus()
  }, [])

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault()
    const { password, confirmPassword } = formToObject<ResetPasswordForm>(event.currentTarget)
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match')
      return
    }
    setPasswordError(null)
    setResetting(true)
    dispatch(resetUserPassword(token, password, callbackPath))
  }, [dispatch, token, callbackPath])

  return (<form onSubmit={onSubmit} name="forgotPasswordForm">
    <ModalHeader
      title="Update your password"
      dismissible={dismissable}
    />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={24}>
          <Group direction="vertical" gap={20}>
            {error?.message && <MessageBanner kind="critical" description={error.message} />}
            {passwordError && <MessageBanner kind="critical" description={passwordError} />}
            <BodyText variant="large">
              Enter a new password
            </BodyText>
          </Group>
          <TextInput
            id="password"
            type="password"
            name="password"
            label="New password"
            placeholder="New password"
            requiredErrorMessage="Password is required"
            ref={firstInput}
            minLength={8}
            required
            helpText="Minimum 8 characters"
          />
          <TextInput
            id="confirmPassword"
            type="password"
            name="confirmPassword"
            label="Confirm new password"
            placeholder="Confirm new password"
            requiredErrorMessage="Password confirmation is required"
            minLength={8}
            required
            helpText="Re-enter the new password"
          />
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
    <ModalFooter
      primaryActionProps={{
        children: 'Change password',
        type: 'submit',
      }}
    />
  </form>)
}

function mapStateToProps(state: App.State) {
  return {
    error: state.auth.error,
  }
}

export default connect(mapStateToProps)(AccountAccessResetPassword)
