import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { isSpoofed } from 'selectors/featuresSelectors'
import { getLuxPlusSubscriptionItems } from '../view/luxPlusSubscription'
import getPayableAfterModifiers from './getPayableAfterModifiers'
import getDepositCalculations from './getDepositCalculations'

const getCreditPayableAmount = createSelector(
  (state: App.State) => getPayableAfterModifiers(state),
  (state: App.State) => state.auth.account.creditsByCurrency[state.checkout.cart.currencyCode],
  (state: App.State) => isSpoofed(state),
  (state: App.State) => getDepositCalculations(state),
  (state: App.State) => state.checkout.payment.rebookingID,
  (state: App.State) => getLuxPlusSubscriptionItems(state),
  (payable, credits, isSpoofed, depositCalculations, rebookingID, luxPlusCartSubscriptionItems): number => {
    let maxCreditTotal: number
    const creditBalance = credits?.balance ?? 0
    if (isSpoofed && rebookingID) {
      maxCreditTotal = depositCalculations.maxDepositCreditAmount
    } else {
      maxCreditTotal = Math.min(creditBalance, payable)
    }

    if (luxPlusCartSubscriptionItems.length > 0) {
      const subscriptionItem = luxPlusCartSubscriptionItems[0]
      const maxCreditTotalPayableForSubscription = floatify(payable - subscriptionItem.amount)

      if (maxCreditTotal > maxCreditTotalPayableForSubscription) {
        maxCreditTotal = maxCreditTotalPayableForSubscription
      }
    }

    return floatify(maxCreditTotal)
  },
)

export default getCreditPayableAmount
