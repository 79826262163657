import Group from 'components/utils/Group'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { BaseToggleOption } from './ToggleOption'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const Container = styled(Group)`
  > ${BaseToggleOption} {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & + * {
      margin-left: -1px; /* to overlap border */
    }

    ${mediaHoverable} {
      &:hover {
        z-index: 1; /* so that the overlapped border can be visible */
      }
    }
    &.is-selected {
      z-index: 1; /* so that the overlapped border can be visible */
    }
  }
`

interface Props extends PropsWithChildren {
  /**
   * @default stretch
   */
  horizontalAlign?: 'start' | 'stretch'
  /**
   * @default stretch
   */
  tabletHorizontalAlign?: 'start' | 'stretch'
  /**
   * @default stretch
   */
  desktopHorizontalAlign?: 'start' | 'stretch'
}

function ToggleOptionsGroup(props: Props) {
  const {
    children,
    horizontalAlign = 'stretch',
    tabletHorizontalAlign = 'stretch',
    desktopHorizontalAlign = 'stretch',
  } = props

  return <Container
    direction="horizontal"
    verticalAlign="stretch"
    horizontalAlign={horizontalAlign}
    tabletHorizontalAlign={tabletHorizontalAlign}
    desktopHorizontalAlign={desktopHorizontalAlign}
    gap={0}
  >
    {children}
  </Container>
}

export default ToggleOptionsGroup
