import React, { RefObject, useEffect, useMemo, useState } from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import debounce from 'lodash.debounce'

type ObservedScrollEnds = {
  hasReachedHorizontalEnd: boolean,
  hasReachedHorizontalStart: boolean,
  hasReachedVerticalEnd: boolean,
  hasReachedVerticalStart: boolean,
}

interface ScrollEndsObserverOptions {
  initiallyHasReachedHorizontalEnd: boolean,
  initiallyHasReachedHorizontalStart: boolean,
  initiallyHasReachedVerticalEnd: boolean,
  initiallyHasReachedVerticalStart: boolean,
}

function useScrollEndsObserver<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  watchedDependency?: any,
  options?: ScrollEndsObserverOptions,
): ObservedScrollEnds {
  const [hasReached, setHasReached] = useState<ObservedScrollEnds>({
    hasReachedHorizontalEnd: options?.initiallyHasReachedHorizontalEnd ?? true,
    hasReachedHorizontalStart: options?.initiallyHasReachedHorizontalStart ?? true,
    hasReachedVerticalEnd: options?.initiallyHasReachedVerticalEnd ?? true,
    hasReachedVerticalStart: options?.initiallyHasReachedVerticalStart ?? true,
  })

  const [elementWidth, setElementWidth] = useState<number>(0)
  const [elementHeight, setElementHeight] = useState<number>(0)

  const checkScrollEnds = useMemo(() => debounce(() => {
    if (ref.current) {
      setHasReached({
        hasReachedHorizontalEnd: ref.current.scrollLeft + Math.ceil(elementWidth) >= ref.current.scrollWidth,
        hasReachedHorizontalStart: ref.current.scrollLeft <= 0,
        hasReachedVerticalEnd: ref.current.scrollTop + Math.ceil(elementHeight) >= ref.current.scrollHeight,
        hasReachedVerticalStart: ref.current.scrollTop <= 0,
      })
    } else {
      setHasReached({
        hasReachedHorizontalEnd: true,
        hasReachedHorizontalStart: true,
        hasReachedVerticalEnd: true,
        hasReachedVerticalStart: true,
      })
    }
    // allow only checking at most every 2 frames to prevent layout changes
  }, 32), [elementHeight, elementWidth, ref])

  useResizeObserver(ref as RefObject<HTMLElement>, (element) => {
    setElementHeight(element.borderBoxSize[0].blockSize)
    setElementWidth(element.borderBoxSize[0].inlineSize)
  })

  useEffect(() => {
    const element = ref.current
    if (element) {
      element.addEventListener('scroll', checkScrollEnds, { passive: true })

      return () => element.removeEventListener('scroll', checkScrollEnds)
    }
  }, [checkScrollEnds, ref])

  useEffect(() => {
    if (ref.current) {
      checkScrollEnds()
    }
  }, [watchedDependency, elementHeight, elementWidth, checkScrollEnds, ref])

  return hasReached
}

export default useScrollEndsObserver
