import { flightsFareClassesClick, flightsFareTypesClick } from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import { FlightDropdownOption } from 'components/Flights/types'
import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import { fareCabins, fareTypes, FlightsClassTypes } from 'constants/flight'
import React, { useCallback, useRef, useState } from 'react'
import FareDropdownOption from './FareDropdownOption'

interface Props {
  options: Array<FlightDropdownOption>;
  selectedOption?: FlightDropdownOption;
  className?: string;
  'data-testid'?: string;
  onOptionChange: (option: FlightDropdownOption<FlightsClassTypes>) => void;
}

function FareDropdown(props: Props) {
  const { options, selectedOption, className, onOptionChange } = props
  const [isOpen, setOpen] = useState(false)
  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const handleClose = () => setOpen(false)

  const handleOpen = () => {
    setOpen(!isOpen)

    if (options === fareTypes) {
      fireInteractionEvent(flightsFareTypesClick())
    } else if (options === fareCabins) {
      fireInteractionEvent(flightsFareClassesClick())
    }
  }

  const onOptionSelect = useCallback((option: FlightDropdownOption<FlightsClassTypes>) => {
    onOptionChange(option)
    handleClose()
  }, [onOptionChange])

  return (<>
    <TextButton
      className={className}
      kind="tertiary"
      endIcon={<DropdownChevron open={isOpen} />}
      onClick={handleOpen}
      ref={triggerRef}
      data-testid={props['data-testid']}
    >
      {selectedOption?.label}
    </TextButton>

    <DropdownList
      size="S"
      anchorRef={triggerRef}
      triggerRef={triggerRef}
      open={isOpen}
      onClose={handleClose}
    >
      {options.map(option => (
        <FareDropdownOption
          key={option.value}
          selected={option === selectedOption}
          option={option}
          onOptionSelect={onOptionSelect}
          data-testid={option.value}
        />
      ))}
    </DropdownList>
  </>)
}

export default FareDropdown
