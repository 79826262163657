import React, { useCallback, useEffect } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import TextLink from 'components/Luxkit/TextLink'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import { pushWithRegion } from 'actions/NavigationActions'
import { fetchProductSubscription } from 'actions/LuxPlusActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { set as setLocalStorage } from 'lib/storage/isomorphicLocalStorage'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import LuxPlusBenefitsList from 'luxPlus/components/LuxPlusBenefitsList'
import LuxPlusBenefitsListLoadingSkeleton from 'luxPlus/components/LuxPlusBenefitsListLoadingSkeleton'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import LuxPlusLogoWrapper from '../LuxPlusLogoWrapper'
import { themeClassName } from 'lib/theme/themeUtils'

const LuxPlusHeader = styled(Group)`
  padding: ${rem(20)};
  background-color: ${(props) => props.theme.palette.product.luxPlus.background};
`

function LuxPlusFreePreviewAutoModalInclusionsVariant() {
  const dispatch = useAppDispatch()
  const givenName = useAppSelector(state => state.auth.account.givenName)
  const subscription = useAppSelector(state => state.luxPlus.products.subscription)

  const isMobile = useScreenSizeOnly('mobile')
  const modalContext = useModalElementContext()

  const onGetFreePreview = useCallback(() => {
    modalContext.resolve()
    dispatch(pushWithRegion(`/${LUXURY_PLUS_PATHS.DEFAULT_LANDING_PAGE}?${LUXURY_PLUS.FREE_PREVIEW_AUTO_ENROLL_QUERY_PARAM}=true`))
  }, [modalContext, dispatch])

  const onDismiss = useCallback(() => {
    setLocalStorage(LUXURY_PLUS.DISMISS_FREE_PREVIEW_STORAGE_KEY, 1)
    modalContext.resolve()
  }, [modalContext])

  useEffect(() => {
    dispatch(fetchProductSubscription())
  }, [dispatch])

  return (
    <ModalBase onClose={onDismiss}>
      <ModalBody>
        <LuxPlusHeader direction="vertical" gap={16}>
          <LuxPlusLogoWrapper height={16} className={themeClassName('inverse')} />
          <Heading variant="heading2" colour="neutral-eight">{LUXURY_PLUS.FREE_PREVIEW_DURATION}-day Free Preview of our VIP Travel Club</Heading>
        </LuxPlusHeader>
        <ModalContent>
          <Group direction="vertical" gap={12}>
            <BodyText variant="medium">{givenName ? `${givenName}, you're` : "You're"} invited to experience the member-only benefits of LuxPlus+</BodyText>
            {subscription.fetching && <LuxPlusBenefitsListLoadingSkeleton benefitsCounts={7} />}
            {!!subscription.offers && <LuxPlusBenefitsList kind="short" benefits={subscription.offers?.[0].benefits || []} />}
            <BodyText variant="medium" weight="bold">No Credit card required. <TextLink target="_blank" to={`/${LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE}`}>T&Cs apply</TextLink>.</BodyText>
          </Group>
        </ModalContent>
      </ModalBody>
      <ModalFooter actionsLayout={isMobile ? 'stacked' : 'auto'} primaryActionProps={{ children: `Start ${LUXURY_PLUS.FREE_PREVIEW_DURATION} day-free preview`, onClick: onGetFreePreview }} secondaryActionProps={{ children: 'Maybe later', onClick: onDismiss }} />
    </ModalBase>
  )
}

export default LuxPlusFreePreviewAutoModalInclusionsVariant
