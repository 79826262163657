import clsx from 'clsx'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import StructureLabel from 'components/Luxkit/Label/StructureLabel'
import SvgIcon from 'components/Luxkit/SvgIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import { TOUR_STYLED_PRODUCT_TYPE_HOTEL_BENEFITS, TOUR_STYLED_PRODUCT_TYPE_TITLES } from 'constants/tours'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  padding: ${rem(12)} ${rem(20)} ${rem(28)};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};

  ${mediaQueryUp.tablet} {
    padding: ${rem(20)} ${rem(20)} ${rem(28)};
  }

  &.is-current {
    padding-top: ${rem(20)};
    border-color: ${props => props.theme.palette.neutral.default.one}
  }
`

const CurrentLabel = styled(StructureLabel)`
  position: absolute;
  top: -1px;
  left: -1px;
  transform: translateY(-50%);
`

interface TourStyleBenefit {
  Icon: typeof SvgIcon
  description: string
}

interface TourStyleContent {
  subtitle: string
  benefits: Array<TourStyleBenefit>
}

const TOUR_STYLES_BENEFITS: Record<Exclude<App.Tours.V2ProductTypeStyled, App.Tours.V2SignatureSeriesProductType>, TourStyleContent> = {
  deluxe_tour: {
    subtitle: 'Discover the highlights, effortlessly',
    benefits: [
      { Icon: LineBedIcon, description: TOUR_STYLED_PRODUCT_TYPE_HOTEL_BENEFITS.deluxe_tour },
      { Icon: LineUsersAltIcon, description: 'Max 24 travellers' },
      { Icon: LineMapIcon, description: 'Unmissable experiences' },
    ],
  },
  premium_tour: {
    subtitle: 'Elevated experiences, elevated accommodation',
    benefits: [
      { Icon: LineBedIcon, description: TOUR_STYLED_PRODUCT_TYPE_HOTEL_BENEFITS.premium_tour },
      { Icon: LineUsersAltIcon, description: 'Max 16 travellers' },
      { Icon: LineMapIcon, description: 'Dedicated agent' },
    ],
  },
  ultra_lux_tour: {
    subtitle: 'Only the best, from start to finish',
    benefits: [
      { Icon: LineBedIcon, description: TOUR_STYLED_PRODUCT_TYPE_HOTEL_BENEFITS.ultra_lux_tour },
      { Icon: LineUsersAltIcon, description: 'Max 12 travellers' },
      { Icon: LineMapIcon, description: 'Signature dining experiences' },
    ],
  },
}

interface Props {
  productType: Exclude<App.Tours.V2ProductTypeStyled, App.Tours.V2SignatureSeriesProductType>
  isCurrent: boolean
}

function TourStyledNonSignatureProductTypeInfoSheet({ productType, isCurrent }: Props) {
  const content = TOUR_STYLES_BENEFITS[productType]

  return <Container className={clsx({ 'is-current': isCurrent })}>
    {isCurrent && <CurrentLabel variant="dark">Current tour</CurrentLabel>}
    <Group direction="vertical" gap={20}>
      <Group direction="vertical" gap={4}>
        <Heading variant="heading4" colour="neutral-one">
          {TOUR_STYLED_PRODUCT_TYPE_TITLES[productType]}
        </Heading>
        <BodyText variant="medium" colour="neutral-two">
          {content.subtitle}
        </BodyText>
      </Group>
      <Group direction="vertical" gap={8}>
        {content.benefits.map((benefit) => <BodyTextBlock
          key={benefit.description}
          colour="neutral-two"
          variant="medium"
          startIcon={<benefit.Icon />}
        >
          {benefit.description}
        </BodyTextBlock>)}
      </Group>
    </Group>
  </Container>
}

export default TourStyledNonSignatureProductTypeInfoSheet
