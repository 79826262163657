import { fetchDestinationImageId } from 'actions/PlaceActions'
import { getListName } from 'analytics/enhanced-ecommerce'
import OfferList, { OfferListV2Item } from 'components/OfferList/OfferList'
import { OfferListEventHandler, OfferListEvents, OfferListEventsProvider } from 'components/OfferList/OfferListEventsContext'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import { GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { useGlobalHotelsSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalHotelsSearch'
import useOfferListTracking from 'hooks/Offers/useOfferListTracking'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useQueryParams from 'hooks/useQueryParams'
import getOfferListKey from 'lib/offer/offerListKey'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router'
import * as Analytics from 'analytics/analytics'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import SearchForm, { SearchFormRef } from 'components/Search/SearchForm'
import { HOTEL_SEARCH_TYPEAHEAD_TYPES, SEARCH_VERTICALS } from 'constants/search'
import LayoutContainer from 'components/Common/LayoutContainer'
import HotelSearchNearbyPropertyOfferList from '../HotelSearchPage/HotelSearchNearbyPropertyOfferList'
import { getImageUrl } from 'lib/image/imageUtils'
import { buildSearchParamsForFilterPane, decodeFromUrlString, DestinationLandingPageType, getDescription, getDestinationLandingPageType, getFilters, getMoreResortsFilters, getTitleAndSubTitle, validateHolidayType } from './DestinationLandingPageUtils'
import HeadData from 'components/Common/HeadData'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import HotelSearchPageFilterPane from '../HotelSearchPage/HotelSearchPageFilterPane'
import { deleteSearchParams } from 'lib/url/searchUrlUtils'
import config from 'constants/config'
import { EnabledFilters } from 'components/Search/type'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import cn from 'clsx'
import { useScreenSizeUp } from 'hooks/useScreenSize'
import useOfferList from 'hooks/Offers/useOfferList'
import { getSearchTypeFromFilters, isSearchStreamingSupported } from 'lib/search/searchUtils'
import HotelSearchHeader from '../HotelSearchPage/HotelSearchHeader'
import { getUrlOfferListFilters } from 'selectors/routerSelectors'
import { searchSortByTypes } from 'constants/offerListFilters'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import RedirectWithStatus from 'components/Common/RedirectWithStatus'
import OfferCountAndSort from '../HotelSearchPage/OfferCountAndSort'
import { pluralizeToString } from 'lib/string/pluralize'

const Banner = styled.div<{ imageUrl?: string }>`
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${rem(48)};

  & > h1, & > h2 {
    margin: 0 auto;
    padding: 0;
  }

  ${mediaQueryUp.tablet} {
    margin-bottom: ${rem(16)};
  }

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${props => props.imageUrl}) lightgray 50% / cover no-repeat;
`
const StyledVerticalSpacer = styled(VerticalSpacer)`
  grid-area: offer-list;
`
const StyledHotelSearchPageFilterPane = styled(HotelSearchPageFilterPane)`
  grid-area: offer-filters;
`

const SearchFormContainer = styled.div`
${mediaQueryUp.tablet} {
  padding: 0 ${rem(10)} ${rem(16)} ${rem(10)};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.six};
}
`

interface MatchProps {
  holidayType: string;
  location: string;
  regionCode: string;
}

const StyledLayoutContainer = styled(LayoutContainer)`
  &.filterPane {
    background-color: ${props => props.theme.palette.neutral.default.eight};
    display: grid;
    padding: ${rem(24)} 0;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: ${rem(32)};

    ${mediaQueryUp.desktop} {
      grid-template: "offer-filters offer-list" auto / ${rem(278)} minmax(0, 2fr);
      padding-top: ${rem(12)};
    }
  }

  &.fullWidth {
    padding: ${rem(16)} 0;
  }
`

interface Props extends RouteComponentProps<MatchProps> {
  filters: App.OfferListFilters;
  regionCode: string;
  isSearchEnabled: boolean;
  locationToPlace: { [location: string]: App.Place }
  placesLoading: { [location: string]: boolean }
  customHeadings?: App.ContentHeadings;
  isDynamicSearchFooterBoxContent: boolean
  windowSearch: string,
  filtersFromUrl: App.OfferListFilters,
  placesLookUp: { [id: string]: App.Place }
}

function DestinationLandingPage(props: Props) {
  const { locationToPlace, windowSearch, filters, placesLookUp } = props
  const sitelinks = useAppSelector(state => state.marketing.sitelinks)
  const formRef = useRef<SearchFormRef>(null)
  const dispatch = useAppDispatch()
  const match = useRouteMatch<MatchProps>()
  const queryParams = useQueryParams()
  const isDesktop = useScreenSizeUp('largeDesktop')
  const { holidayType: urlHolidayType, location: urlLocation } = match.params
  const holidayType = urlHolidayType ? decodeFromUrlString(urlHolidayType) : ''
  const hasHolidayType = validateHolidayType(holidayType) && holidayType !== ''
  const location = decodeFromUrlString(urlLocation)
  const showSlimMobile = useSlimMobileSearch()
  const destinationLandingPageType = getDestinationLandingPageType(match.path)
  const place = locationToPlace[location]
  const {
    title, subtitle,
  } = getTitleAndSubTitle({ destinationLandingPageType, placeName: place?.name ?? '', holidayType })
  const history = useHistory()
  const searchParamsForFilterPane = buildSearchParamsForFilterPane({
    originalSearch: windowSearch,
    holidayType,
    destinationName: place?.name ?? '',
    destinationId: place?.id ?? '',
  })

  const mapViewURL = useMemo(() => `/search/map?${deleteSearchParams(searchParamsForFilterPane, 'sortBy')}`, [searchParamsForFilterPane])
  const showFilterPane = isDesktop
  const imageId = placesLookUp[place?.id]?.imageId
  const imageUrl = getImageUrl(imageId ?? '')

  const { globalHotelsSearchDispatch, globalHotelsSearchState } = useGlobalHotelsSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    searchType: 'destination',
    searchItem: { searchType: 'destination', value: place?.id, format: { mainText: place?.name, secondaryText: '' } },
    searchTargetLabel: place?.name,
  }, queryParams)

  const listFilters = useMemo((): App.OfferListFilters => {
    return getFilters(
      {
        filters,
        destinationLandingPageType,
        hasHolidayType,
        urlHolidayType,
        place,
      })
  }, [destinationLandingPageType, hasHolidayType, place, urlHolidayType, filters])
  const streamResults = isSearchStreamingSupported(
    {
      ...listFilters,
      isStream: true,
    },
  )

  useEffect(() => {
    // Replace the search params in the url use react router
    // this will allow the filter pane to work
    history.replace({
      search: searchParamsForFilterPane,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(fetchDestinationImageId(place?.id))
  }, [place, dispatch, location, sitelinks, locationToPlace])

  const hotelOfferList = useOfferList(listFilters, {
    noFetch: true,
    streamResults,
  })
  const mainListTotal = hotelOfferList.offerCount

  const nearbyFilters = useMemo(() => {
    const { holidayTypes, limit, ...restFilters } = listFilters
    return {
      ...restFilters,
      limit: 20,
      searchNearby: true,
    }
  }, [listFilters])

  const moreResortsFilter = useMemo(() => {
    return getMoreResortsFilters({
      filters: listFilters,
    })
  }, [listFilters])

  const enabledFilters: EnabledFilters = useMemo(() => (
    {
      inclusions: true,
      amenities: true,
      customerRating: true,
      priceFilter: config.ENABLE_HOTEL_SEARCH_PAGE_PRICE_FILTER,
      holidayTypes: true,
      locations: true,
      offerTypes: config.ENABLE_VILLA_RENTALS,
      popularFilters: true,
      luxPlusFeatures: true,
      agentHubFeatures: config.agentHub.isEnabled,
    }
  ), [])

  const trackingName = destinationLandingPageType === DestinationLandingPageType.HolidayPackages ? 'HolidayPackage Page' : 'Resort Page'
  const tracking = useOfferListTracking(
    getListName('OfferList', trackingName, 'Main Results'),
    {
      ga: {
        category: 'OfferList',
        label: 'Main Results',
      },
      key: getOfferListKey(listFilters),
    },
  )

  const searchType = getSearchTypeFromFilters(listFilters)
  const offerListFiltersProps = {
    searchSortByTypes,
    searchType,
    switchView: {
      label: 'Map view',
      view: 'map' as const,
      URL: mapViewURL,
    },
    listFilters,
  }

  const onListEvent = useCallback<OfferListEventHandler>((dispatchAction) => {
    if (dispatchAction.type === OfferListEvents.productClick) {
      Analytics.trackClientEvent({
        subject: 'search-click-position',
        action: `ab-value: ${dispatchAction.position + 1}`,
        category: 'logging',
        type: 'operational',
        optimizelyEventId: '29214570602',
        optimizelyEventKey: 'search-click-position',
      })
    }
  }, [])

  const specificPlaces = useMemo<Array<App.SpecificPlace>>(() => {
    if (!sitelinks) {
      return []
    }
    const currentPlaceName = place?.name.toLowerCase()
    const specificPlaces: Array<App.SpecificPlace> = []
    const siteLinkPlaces = sitelinks[currentPlaceName]?.data?.siteLinkPlaces ?? []

    for (const sitelinkPlaceName of siteLinkPlaces) {
      const _place = locationToPlace[sitelinkPlaceName]
      const _imageId = placesLookUp[_place?.id]?.imageId
      if (_place) {
        const name = _place?.name
        const caption = sitelinks[sitelinkPlaceName]?.data?.caption ?? ''
        const image = _imageId ?? ''
        const placeId = _place?.id

        if (!name || !caption || !image || !placeId) {
          continue
        }

        specificPlaces.push({
          name,
          caption,
          image,
          placeId,
          destinationLandingPageSlug: sitelinkPlaceName,
        })
      }
    }
    return specificPlaces
  }, [place?.name, sitelinks, locationToPlace, placesLookUp])

  const matchHolidayType = useRouteMatch('/:regionCode/holiday-packages/:location/:holidayType')

  if (mainListTotal === 0) {
    const regionCode = match.params.regionCode
    if (matchHolidayType) {
      return <RedirectWithStatus code={302} to={`/${regionCode}/holiday-packages/${match.params.location}`} withSearchAndHash={false} />
    }
    else {
      return <RedirectWithStatus code={302} to={`/${regionCode}`} withSearchAndHash={false} />
    }
  }

  const extras: Array<OfferListV2Item> = [
    {
      id: 'heading',
      position: 'start',
      element: <OfferCountAndSort
        mainListTotal={mainListTotal as number}
        isFlexible
        filters={listFilters}
        windowSearch={searchParamsForFilterPane}
        shouldShowFilters={!showFilterPane}
        showHolidayTypes
        countTextOverride={`Showing ${pluralizeToString('offer', mainListTotal as number)}`}
        sortOptions={searchSortByTypes}
        showOfferCount={destinationLandingPageType === DestinationLandingPageType.HolidayPackages}
        specificPlaces={specificPlaces}
        isDestinationLandingPage
        destinationName={place?.name}
        urlLocationForDestinationLandingPage={urlLocation}
        pageTypeForDestinationLandingPage={destinationLandingPageType}
        holidayTypeForDestinationLandingPage={holidayType}
      />,
    },
    {
      id: 'more-resorts',
      position: 'end',
      element: <HotelSearchNearbyPropertyOfferList showTextHeading={false} filters={moreResortsFilter} />,
    },
    {
      id: 'nearby-property-offers',
      position: 'end',
      element: <HotelSearchNearbyPropertyOfferList filters={nearbyFilters} />,
    },
  ]

  return (
    <GlobalSearchContextProvider
      state={globalHotelsSearchState}
      dispatch={globalHotelsSearchDispatch}
    >
      <HeadData
        title={title}
        description={getDescription({ destinationLandingPageType, placeName: place?.name ?? '', holidayType })}
      />

      <CSSBreakpoint max="mobile">
        <HotelSearchHeader
          filters={listFilters}
          formRef={formRef}
          offerListFiltersProps={offerListFiltersProps}
          searchVertical={SEARCH_VERTICALS.HOTELS}
          baseSearchPath="/search"
          baseSearchMapPath="/search/map"
        />
      </CSSBreakpoint>
      <Banner imageUrl={imageUrl}>
        <Heading variant="heading2" align="center" as="h1" colour="neutral-eight">{title}</Heading>
        <BodyText variant="large" align="center" as="h2" colour="neutral-eight">{subtitle}</BodyText>
      </Banner>
      <CSSBreakpoint min="tablet">
        <SearchFormContainer>
          <SearchForm
            submitOnApply
            filters={listFilters}
            ref={formRef}
            showDateFieldOnMobile={false}
            typeaheadTypes={HOTEL_SEARCH_TYPEAHEAD_TYPES}
          />
        </SearchFormContainer>
      </CSSBreakpoint>
      <StyledLayoutContainer
        size={showFilterPane ? 'xl' : 'lg'}
        className={cn({ filterPane: showFilterPane, fullWidth: showSlimMobile })}
      >
        <StyledVerticalSpacer gap={16}>
          <OfferListEventsProvider tracking={tracking} onListEvent={onListEvent}>
            <OfferList
              filters={listFilters}
              tileStyle="search"
              interstitials={extras}
              fullWidth={showSlimMobile}
            />
          </OfferListEventsProvider>
        </StyledVerticalSpacer>
        {showFilterPane && <StyledHotelSearchPageFilterPane
          filters={listFilters}
          mapViewURL={mapViewURL}
          search={searchParamsForFilterPane}
          enabledFilters={enabledFilters}
        />
        }
      </StyledLayoutContainer>
    </GlobalSearchContextProvider>
  )
}

const mapStateToProps = (state: App.State) => ({
  placesLookUp: state.destination.places,
  locationToPlace: state.destination.locationToPlace,
  placesLoading: state.destination.placesLoading,
  regionCode: state.geo.currentRegionCode,
  customHeadings: state.content?.secureHeadingParams?.landing,
  windowSearch: state.router.location.search,
  filters: getUrlOfferListFilters(state),
})

export default connect(mapStateToProps)(DestinationLandingPage)
