import noop from 'lib/function/noop'
import React, { PropsWithChildren } from 'react'

export type SearchEventData = {
  filters?: App.OfferListFilters;
  searchItem?: App.SearchItem;
  checkIn?: string | null;
  checkOut?: string | null;
  vertical: string;
  isFlexibleDates?: boolean;
  occupancies?: Array<App.Occupants>;
}

type SearchEvent = (eventSubject: string, data: SearchEventData) => void

interface Props {
  onEvent: SearchEvent;
}

const GlobalSearchTrackingContext = React.createContext<SearchEvent>(noop)

export function GlobalSearchTrackingProvider(props: PropsWithChildren<Props>) {
  const { children, onEvent } = props

  return <GlobalSearchTrackingContext.Provider value={onEvent}>
    {children}
  </GlobalSearchTrackingContext.Provider>
}

export default GlobalSearchTrackingContext
