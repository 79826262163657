import { getBookingDetailsByIds } from 'api/experiences'
import asyncPoll from 'lib/promise/asyncPoll'

interface PollExperienceVouchersParams {
  experienceItemIds: Array<string>
  maxTime: number
  pollInterval: number
}

/**
 * Polls for experience booking details until all vouchers are filled
 * Only items that are not cancelled will be re-fetched
 */
const pollExperiencesVouchers = async({ experienceItemIds, maxTime, pollInterval }: PollExperienceVouchersParams) => {
  if (experienceItemIds.length === 0) return

  return asyncPoll({
    validateFunction: async(result) => {
      const loadedItems = result.filter((item) => item.voucherUrl || item.status === 'cancelled')
      return loadedItems.length >= experienceItemIds.length
    },
    apiCall: () => getBookingDetailsByIds(experienceItemIds),
    maxTime,
    pollInterval,
  })
}

export default pollExperiencesVouchers
