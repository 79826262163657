import LineRoomUpgradeIcon from 'components/Luxkit/Icons/line/LineRoomUpgradeIcon'
import ModalContext from 'contexts/ModalContext'
import { useAppSelector } from 'hooks/reduxHooks'
import getLuxLoyaltyDisplayTier from 'luxLoyalty/selectors/getLuxLoyaltyDisplayTier'
import React, { useCallback, useContext } from 'react'
import LuxLoyaltyRoomUpgradeBenefitModal from './LuxLoyaltyRoomUpgradeBenefitModal'
import LuxLoyaltyTierBenefitLabel from './LuxLoyaltyTierBenefitLabel'
import withMountGuard from 'hocs/withMountGuard'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'

function LuxLoyaltyRoomUpgradeBenefitLabel() {
  const tier = useAppSelector(getLuxLoyaltyDisplayTier)
  const showModal = useContext(ModalContext)

  const showRoomUpgradesModal = useCallback(() => {
    showModal(<LuxLoyaltyRoomUpgradeBenefitModal />)
  }, [showModal])

  return <LuxLoyaltyTierBenefitLabel
    tier={tier}
    icon={<LineRoomUpgradeIcon />}
    onClick={showRoomUpgradesModal}
  >
    Free room upgrade
  </LuxLoyaltyTierBenefitLabel>
}

export default withMountGuard(() => {
  return useAppSelector(getIsLuxLoyaltyEnabled)
})(LuxLoyaltyRoomUpgradeBenefitLabel)
