import React, { useCallback } from 'react'
import clsx from 'clsx'

import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import SolidHeartIcon from 'components/Luxkit/Icons/solid/SolidHeartIcon'
import noop from 'lib/function/noop'
import { themeClassName } from 'lib/theme/themeUtils'
import { preventDefault, stopPropagation } from 'lib/web/eventHandlerUtils'
import IconButton from 'components/Luxkit/Button/IconButton'
import AssistChip from 'components/Luxkit/Chips/AssistChip'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'

const IconBookmarkButton = styled(IconButton)`
  &.withlabel-tablet {
    ${mediaQueryUp.tablet} {
      display: none;
    }
  }
`

const ChipBookmarkButton = styled(AssistChip)`
  &.withlabel-tablet {
    display: none;

    ${mediaQueryUp.tablet} {
      display: inline-flex;
    }
  }
`

interface Props {
  className?: string
  label?: string
  onClick?: () => void
  isSaved?: boolean
  isProcessing?: boolean
  testId?: string
  size?: 'small' | 'medium' | 'large'
  withLabel?: 'never' | 'tablet' | 'always'
}

function BaseBookmarkButton(props: Props) {
  const {
    className,
    label,
    onClick = noop,
    isSaved = false,
    isProcessing = false,
    testId,
    size = 'medium',
    withLabel: withLabelProp = 'always',
  } = props

  const withLabel = label ? withLabelProp : 'never'

  const savedIcon = isSaved ? (
    <SolidHeartIcon colour={isProcessing ? 'neutral-four' : 'favourite'} />
  ) : (
    <LineHeartIcon colour={isProcessing ? 'neutral-four' : 'favourite'} />
  )

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      preventDefault(e)
      stopPropagation(e)

      onClick()
    },
    [onClick],
  )

  const iconOnlyButton = <IconBookmarkButton
    className={clsx(className, themeClassName('default'), `withlabel-${withLabel}`)}
    shape="circle"
    kind="secondary"
    variant="dark"
    onClick={handleClick}
    disabled={isProcessing}
    aria-label={label}
    size={size ?? 'medium'}
    data-testid={clsx(testId, {
      'bookmark-button-saved': isSaved,
      'bookmark-button-unsaved': !isSaved,
    })}
  >
    {savedIcon}
  </IconBookmarkButton>

  const chipButton = <ChipBookmarkButton
    className={clsx(className, themeClassName('default'), `withlabel-${withLabel}`)}
    variant="filled"
    size={size ?? 'small'}
    onClick={handleClick}
    startIcon={savedIcon}
    aria-label={label}
    disabled={isProcessing}
    data-testid={clsx(testId, {
      'bookmark-button-saved': isSaved,
      'bookmark-button-unsaved': !isSaved,
    })}
  >
    {label}
  </ChipBookmarkButton>

  return <>
    {withLabel === 'never' && iconOnlyButton}
    {withLabel === 'always' && chipButton}
    {withLabel === 'tablet' && <>
      {iconOnlyButton}
      {chipButton}
    </>}
  </>
}

export default React.memo(BaseBookmarkButton)
