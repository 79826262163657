import { DESTINATIONS } from 'constants/config/region'
import { buildHotelUrl } from './utils'

export function getTrendingDestinationFilters(countryCode: string): Array<{ id: App.DestinationSelectedFilters, title: string }> {
  if (countryCode == 'AU' || countryCode === 'NZ') {
    return [{ id: 'hotel', title: 'Places to stay' }, { id: 'cruise', title: 'Cruising the seas' }, { id: 'tour', title: 'Guided group tours' }]
  } else {
    return [{ id: 'hotel', title: 'Places to stay' }, { id: 'tour', title: 'Guided group tours' }]
  }
}

const buildTourUrl = (destination: string, destinationId: string) => `/search/tours?destinationId=${destinationId}&destinationName=${destination}`
const buildCruiseUrl = (destination: string, destinationId: string) => `/search/cruises?destinationId=${destinationId}&destinationName=${destination}`

export type TrendingDestination = {
  name: string;
  imageId: string;
  url: string;
  destinationId?: string;
}

export function getTrendingDestinationsForFilter(filterId: App.DestinationSelectedFilters, currentRegionCode: string) {
  // Trending destinations of Australia are the default ones for all other countries
  switch (filterId) {
    case 'hotel':
      return trendingHotelDestinations[currentRegionCode] || trendingHotelDestinations.AU
    case 'cruise':
      return trendingCruiseDestinations[currentRegionCode] || trendingCruiseDestinations.AU
    case 'tour':
      return trendingTourDestinations[currentRegionCode] || trendingTourDestinations.AU
    default:
      return trendingHotelDestinations[currentRegionCode] || trendingTourDestinations.AU
  }
}

const buildUrl = (offerType: string) => {
  switch (offerType) {
    case 'hotel':
      return buildHotelUrl
    case 'tour':
      return buildTourUrl
    case 'cruise':
      return buildCruiseUrl
    default:
      return buildHotelUrl
  }
}

const buildTrendingDestination = ({ destination, imageId, offerType = 'hotel' }: { destination: string, imageId: string, offerType?: 'hotel' | 'tour' | 'cruise' }) => {
  const { displayName, urlName, destinationId } = DESTINATIONS[destination]
  const url = buildUrl(offerType)(urlName || displayName, destinationId)
  return { name: displayName, destinationId, imageId, url }
}

export const trendingHotelDestinations: { [key: string]: Array<TrendingDestination> | undefined } = {
  AU: [
    buildTrendingDestination({ destination: 'bali', imageId: 'vg4uvt2y5dgkivujuba6' }),
    buildTrendingDestination({ destination: 'queensland', imageId: '7wwkjgk1cn0yafyyek' }),
    buildTrendingDestination({ destination: 'thailand', imageId: 'lids8h5rs9zj2xvkxr' }),
    buildTrendingDestination({ destination: 'melbourne', imageId: 'u4joiwhggaqtgdfvoj9j' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: 'qnj8qzc3dff227vie4xt' }),
    buildTrendingDestination({ destination: 'fiji', imageId: 'h1t0c6c9brq83bwhazx' }),
    buildTrendingDestination({ destination: 'maldives', imageId: 'dapbr82l65bcj5iy6v8' }),
    buildTrendingDestination({ destination: 'sydney', imageId: 'u95fdaucycrnml7y5fng' }),
    buildTrendingDestination({ destination: 'europe', imageId: 'n32e56two8igfwfrzvq' }),
    buildTrendingDestination({ destination: 'australia', imageId: '6swob1q88xi45bmcm4lgm' }),
    buildTrendingDestination({ destination: 'dubai', imageId: 'eoo7x60icwbgndyc1wcge' }),
    buildTrendingDestination({ destination: 'uk', imageId: 'bh52x5crz46nt54uxsl' }),
  ],
  NZ: [
    buildTrendingDestination({ destination: 'fiji', imageId: '0r1k024m4f05bp20kzgn' }),
    buildTrendingDestination({ destination: 'new_zealand', imageId: '4ezeb1xkmkcuawwm0cf8' }),
    buildTrendingDestination({ destination: 'bali', imageId: '1to55kpf7i6zrbf1m8l' }),
    buildTrendingDestination({ destination: 'cook_islands', imageId: 'x382u2epn5ub73h8l3' }),
    buildTrendingDestination({ destination: 'gold_coast', imageId: 'krvovndggw4zalo1kgo8' }),
    buildTrendingDestination({ destination: 'sydney', imageId: 'u95fdaucycrnml7y5fng' }),
    buildTrendingDestination({ destination: 'melbourne', imageId: 'u4joiwhggaqtgdfvoj9j' }),
    buildTrendingDestination({ destination: 'thailand', imageId: 'ztss9zt897xooxoarh3' }),
    buildTrendingDestination({ destination: 'singapore', imageId: 'b4u5c9v9eplflpfjohz8' }),
    buildTrendingDestination({ destination: 'maldives', imageId: 'zme6trnp3refzciidsk' }),
    buildTrendingDestination({ destination: 'samoa', imageId: 'kgp44kj57lap71v2djyt' }),
    buildTrendingDestination({ destination: 'hawaii', imageId: 'q26a5y2h5aijx52y8nfm' }),
    buildTrendingDestination({ destination: 'queensland', imageId: 'trendingDestination.queensland' }),
  ],
  GB: [
    buildTrendingDestination({ destination: 'greece', imageId: 'la8fpc5ehwxlh9cyd9ne' }),
    buildTrendingDestination({ destination: 'maldives', imageId: 'e8tfsm17scdhkl291mt' }),
    buildTrendingDestination({ destination: 'bali', imageId: '1to55kpf7i6zrbf1m8l' }),
    buildTrendingDestination({ destination: 'uk', imageId: 'ro18bawi3c1q4ky0jl' }),
    buildTrendingDestination({ destination: 'dubai', imageId: 'eoo7x60icwbgndyc1wcge' }),
    buildTrendingDestination({ destination: 'thailand', imageId: '1y2qjvneh2zelxpg2fog' }),
    buildTrendingDestination({ destination: 'spain', imageId: '7mxbm2s01q38r2900zj' }),
    buildTrendingDestination({ destination: 'france', imageId: 'n32e56two8igfwfrzvq' }),
    buildTrendingDestination({ destination: 'italy', imageId: '105jinf6w80g48xz4m5i' }),
    buildTrendingDestination({ destination: 'mauritius', imageId: 'ztyqafpx7lywouc2093' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: '7pmj8f3m1cz7ip3920k' }),
    buildTrendingDestination({ destination: 'usa', imageId: 'trendingDestination.usa' }),
  ],
  US: [
    buildTrendingDestination({ destination: 'maldives', imageId: 'dapbr82l65bcj5iy6v8' }),
    buildTrendingDestination({ destination: 'mexico', imageId: 'm4q0xykf28g85c3uv1r' }),
    buildTrendingDestination({ destination: 'dubai', imageId: 'eoo7x60icwbgndyc1wcge' }),
    buildTrendingDestination({ destination: 'bali', imageId: 'vg4uvt2y5dgkivujuba6' }),
    buildTrendingDestination({ destination: 'florida', imageId: 'rasl0402sq9ft6vloi7b' }),
    buildTrendingDestination({ destination: 'australia', imageId: '1x64o7uzz17yf0lu1o' }),
    buildTrendingDestination({ destination: 'italy', imageId: 'wbk1yjnf56awezs1lu' }),
    buildTrendingDestination({ destination: 'fiji', imageId: 'h1t0c6c9brq83bwhazx' }),
    buildTrendingDestination({ destination: 'california', imageId: 'mky1rem3l8kdhe9h78b' }),
    buildTrendingDestination({ destination: 'thailand', imageId: 'lids8h5rs9zj2xvkxr' }),
    buildTrendingDestination({ destination: 'uk', imageId: 'bh52x5crz46nt54uxsl' }),
    buildTrendingDestination({ destination: 'usa', imageId: '2agstgb25xcdai5sl2y' }),
  ],
  SG: [
    buildTrendingDestination({ destination: 'bali', imageId: 'vg4uvt2y5dgkivujuba6' }),
    buildTrendingDestination({ destination: 'maldives', imageId: 'dapbr82l65bcj5iy6v8' }),
    buildTrendingDestination({ destination: 'phuket', imageId: 'zeh4dhitct7zlmwprc7' }),
    buildTrendingDestination({ destination: 'khao_lak', imageId: 'qu4sq209xav4bgjlyw5i' }),
    buildTrendingDestination({ destination: 'malaysia', imageId: 'i06o0tyhuxlsyxvnwxro' }),
    buildTrendingDestination({ destination: 'bangkok', imageId: 'i0smaysxpczk77hi55ix' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: '7pmj8f3m1cz7ip3920k' }),
    buildTrendingDestination({ destination: 'singapore', imageId: 'b4u5c9v9eplflpfjohz8' }),
    buildTrendingDestination({ destination: 'dubai', imageId: 'eoo7x60icwbgndyc1wcge' }),
    buildTrendingDestination({ destination: 'australia', imageId: '1x64o7uzz17yf0lu1o' }),
  ],
}

export const trendingTourDestinations: { [key: string]: Array<TrendingDestination> | undefined } = {
  AU: [
    buildTrendingDestination({ destination: 'vietnam', imageId: 'qnj8qzc3dff227vie4xt', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'egypt', imageId: 'm4rcv17j80xrikxq419', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'japan', imageId: '6tkmq2mo1izznyjyfln', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'spain', imageId: 'muntccdhicnwfzt7gffd', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'africa', imageId: 'v2u8uob7mgdu7o6y6njq', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'india', imageId: 'mz1sew47lfvzk0t6h8a7', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'australia', imageId: '6swob1q88xi45bmcm4lgm', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'italy', imageId: 'wbk1yjnf56awezs1lu', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'morocco', imageId: 'n8hs8sldgkghrq1tbxg', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'south_america', imageId: 'rgpvhefakcq8rengtq7', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'china', imageId: 'mhzaf5oykblji2972gp', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'sri_lanka', imageId: 'w9v8ovyoltpvlzhsbgwh', offerType: 'tour' }),
  ],
  NZ: [
    buildTrendingDestination({ destination: 'japan', imageId: 'trendingDestination.japan', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'australia', imageId: 'trendingDestination.australia', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'egypt', imageId: 'antn11vqwfr0ri531r8n', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'jordan', imageId: 'cus7841371dt5w7bhg', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'spain', imageId: 's58m3u0qykrppcy2dz3', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: 'trendingDestination.vietnam', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'africa', imageId: 'trendingDestination.africa', offerType: 'tour' }),
  ],
  GB: [
    buildTrendingDestination({ destination: 'vietnam', imageId: 'trendingDestination.vietnam', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'africa', imageId: 'trendingDestination.africa', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'australia', imageId: 'trendingDestination.australia', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'japan', imageId: 'trendingDestination.japan', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'india', imageId: 'dvjh0wlaml8zrfb73f4q', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'sri_lanka', imageId: 'w9v8ovyoltpvlzhsbgwh', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'egypt', imageId: 'antn11vqwfr0ri531r8n', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'jordan', imageId: 'cus7841371dt5w7bhg', offerType: 'tour' }),
  ],
  US: [
    buildTrendingDestination({ destination: 'egypt', imageId: 'm4rcv17j80xrikxq419', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'japan', imageId: '6tkmq2mo1izznyjyfln', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'africa', imageId: 'v2u8uob7mgdu7o6y6njq', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'india', imageId: 'dvjh0wlaml8zrfb73f4q', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'australia', imageId: '1x64o7uzz17yf0lu1o', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'vietnam', imageId: 'qnj8qzc3dff227vie4xt', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'morocco', imageId: 'n8hs8sldgkghrq1tbxg', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'new_zealand', imageId: '4hetryqp8ab10q756sne', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'china', imageId: 'mhzaf5oykblji2972gp', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'sri_lanka', imageId: 'w9v8ovyoltpvlzhsbgwh', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'south_america', imageId: 'rgpvhefakcq8rengtq7', offerType: 'tour' }),
    buildTrendingDestination({ destination: 'turkey', imageId: 'ora3bukxq6ow99lpe3', offerType: 'tour' }),
  ],

}

export const trendingCruiseDestinations: { [key: string]: Array<TrendingDestination> | undefined } = {
  AU: [
    buildTrendingDestination({ destination: 'sydney', imageId: 'u95fdaucycrnml7y5fng', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'brisbane', imageId: 'm8ed3sf51fnes1qvgdm', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'melbourne', imageId: 'u4joiwhggaqtgdfvoj9j', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'mediterranean', imageId: 'wzm1voynao2atve74bkg', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'south_pacific', imageId: 'in1vh7z31ho0u045bak', offerType: 'cruise' }),
    buildTrendingDestination({ destination: 'new_zealand', imageId: '4ezeb1xkmkcuawwm0cf8', offerType: 'cruise' }),
  ],
}
