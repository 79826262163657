import React from 'react'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import OfferSortControl from 'components/Common/OfferSortControl/OfferSortControl'
import LineFilterAltIcon from 'components/Luxkit/Icons/line/LineFilterAltIcon'
import useGlobalSearchURLHashFilter from 'hooks/GlobalSearch/useGlobalSearchURLHashFilter'
import FilterDropdown from './FilterDropdown'
import { connect } from 'react-redux'
import getOfferListKey from 'lib/offer/offerListKey'
import { queryKeyAmenities, queryKeyHolidayTypes, queryKeyInclusions } from 'constants/url'
import CustomerRatingDropdown from './CustomerRatingDropdown'
import InclusionsDropdown from './InclusionsDropdown'
import Carousel from 'components/Luxkit/Carousel/Carousel'
import styled from 'styled-components'
import zIndex from 'styles/tools/z-index'
import { useInView } from 'react-intersection-observer'
import { OfferListSortOption } from 'constants/offerListFilters'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import config from 'constants/config'
import BusinessTravellerBudgetFilterChip from '../../../../../businessTraveller/components/search/hotel-exposed-filter-chips/BusinessTravellerBudgetFilterChip'

const HeaderContainer = styled.div`
  position: sticky;
  z-index: ${zIndex.searchSubMenu};
  background: ${props => props.theme.palette.neutral.default.eight};
  width: 100%;
  top: 0;

  &.lifted {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  }
`

interface MappedStateProps {
  availableFilters: App.OfferListFilterOptions | undefined;
  search: string;
}

interface Props {
  filters: App.OfferListFilters;
  showSort?: boolean;
  sortOptions?: Array<OfferListSortOption>
}

function HotelExposedFiltersHeader(props: Props & MappedStateProps) {
  const { availableFilters, filters, search, showSort = false, sortOptions } = props
  const { toggleFilterPanel } = useGlobalSearchURLHashFilter()
  const isSlimMobileSearch = useSlimMobileSearch()
  const showSortDropdown = !isSlimMobileSearch

  const [toLiftRef, liftInView] = useInView()

  const filterCount = (filters.holidayTypes?.length ?? 0) + (filters.amenities?.length ?? 0) + (filters.inclusions?.length ?? 0) +
    (filters?.priceLte ? 1 : 0) + (filters.locations?.length ?? 0) + (filters.customerRatingGte ? 1 : 0) + (filters.offerTypes?.length === 1 ? 1 : 0)

  return (
    <>
      <div ref={toLiftRef} />
      <HeaderContainer className={!liftInView ? 'lifted' : ''}>
        <Carousel gap={8} gutterStyle="overflow">
          <FilterChip
            size="medium"
            startIcon={<LineFilterAltIcon />}
            onClick={toggleFilterPanel}
            selected={filterCount > 0}
          >
            All filters {filterCount ? `(${filterCount})` : ''}
          </FilterChip>
          {showSort && !!sortOptions?.length && (
            <OfferSortControl
              sortOptions={sortOptions}
              roundedButton
              showSelectedHighlight
              showDropdown={showSortDropdown}
            />
          )}
          <>
            {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerBudgetFilterChip
              label="Budget"
              filters={filters}
              search={search}
            />}
            <CustomerRatingDropdown filters={filters} search={search} label="Customer rating" />
            <InclusionsDropdown
              searchQueryKey={queryKeyInclusions}
              label="Inclusions"
              availableFilters={availableFilters?.filters?.inclusions}
              selectedFilters={filters?.inclusions ?? []}
              search={search}
            />
            <FilterDropdown
              searchQueryKey={queryKeyHolidayTypes}
              label="Type of escape"
              availableFilters={availableFilters?.filters?.holidayTypes}
              filterOrder={availableFilters?.filterOrder?.holidayTypes}
              selectedFilters={filters?.holidayTypes ?? []}
              search={search}
            />
            <FilterDropdown
              searchQueryKey={queryKeyAmenities}
              label="Amenities"
              availableFilters={availableFilters?.filters?.amenities}
              filterOrder={availableFilters?.filterOrder?.amenities}
              selectedFilters={filters?.amenities ?? []}
              search={search}
            />
          </>
        </Carousel>
      </HeaderContainer>
    </>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>(
  (appState, ownProps): MappedStateProps => {
    const key = getOfferListKey(ownProps.filters)
    const availableFilters = appState.offer.offerListFilterOptions
    return {
      availableFilters: availableFilters[key],
      search: appState.router.location.search,
    }
  },
)(HotelExposedFiltersHeader)
