import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import DropdownChevron from '../../Luxkit/Dropdown/DropdownChevron'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Group from 'components/utils/Group'

const FilterGroupLoader = styled(Group)`
  padding-bottom: ${rem(8)};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};

  >:first-child {
    flex-grow: 1;
  }
`

const CheckboxGroup = styled.div`
  margin-top: ${rem(16)};

  > * {
    margin-top: ${rem(12)};
  }
`

function FilterPanelCheckboxLoader() {
  return <div>
    <FilterGroupLoader direction="horizontal" horizontalAlign="space-between" gap={8}>
      <TextLoadingBox typography="heading5" width="100%" />
      <DropdownChevron open />
    </FilterGroupLoader>
    <CheckboxGroup>
      <CheckboxInput size="large"><TextLoadingBox width="100%" /></CheckboxInput>
      <CheckboxInput size="large"><TextLoadingBox width="100%" /></CheckboxInput>
      <CheckboxInput size="large"><TextLoadingBox width="100%" /></CheckboxInput>
    </CheckboxGroup>
  </div>
}

export default React.memo(FilterPanelCheckboxLoader)
