import React, { ComponentType } from 'react'

function resolvePredication(predicate: boolean | (() => boolean)): boolean {
  if (typeof predicate === 'function') return predicate()
  return predicate
}

/**
 * Creates a HoC that conditionally renders the given component based on the provided predicate.
 */
function withMountGuard(predicate: boolean | (() => boolean)) {
  return function<Props, Element extends HTMLElement>(Component: ComponentType<Props & { ref?: React.Ref<Element> }>) {
    function GuardedComponent(props: Props & { ref?: React.Ref<Element> }) {
      if (!resolvePredication(predicate)) return null
      return <Component {...props} />
    }
    GuardedComponent.displayName = `withMountGuard(${Component.displayName || Component.name || 'Component'})`
    return GuardedComponent
  }
}

export default withMountGuard
