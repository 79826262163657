import moment from 'moment'
import { SelfDescribingJson } from '@snowplow/browser-tracker'
import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'

import { AnyAction, ListenerEffectAPI } from '@reduxjs/toolkit'
import { cartIsAccommodationItem, getCruiseTourV2OrExperienceOffer, getHotelOrTourOffer } from './helpers/itemCategorisation'
import { getCruiseOffer } from 'api/cruises'
import { getExperienceById } from 'api/experiences'
import { getGeneralOfferItem, getHotelOrTourOfferItem } from './eventContexts/item/offerContext'
import { getOfferById } from 'api/offer'
import { isAccommodationItem, isBedbankItem, isBookingProtectionItem, isCarHireItem, isCruiseItem, isExperienceItem, isGiftCardItem, isInsuranceItem, isLuxPlusSubscriptionItem, isTourV1Item, isTourV2Item, isTransferItem } from 'lib/checkout/checkoutUtils'
import { isFlightItem } from 'checkout/lib/utils/flights/cart'
import { OfferContextEvent } from './context'
import { PROVIDERS } from 'constants/experience'
import { removeUndefinedAndNullProperties } from 'lib/object/objectUtils'
import { ThunkDispatch } from 'redux-thunk'
import config from 'constants/config'
import getAddonOrderItems from './eventContexts/item/orderAddonContext'
import getBedbankHotelOrderItems from './eventContexts/item/orderBedbankContext'
import getBookingProtectionCheckoutItems from './eventContexts/item/checkoutBookingProtectionContext'
import getCarHireCheckoutItem from './eventContexts/item/checkoutCarHireContext'
import getCarHireOrderItems from './eventContexts/item/orderCarHireContext'
import getCruiseCheckoutItem from './eventContexts/item/checkoutCruiseContext'
import getCruiseOrderItems from './eventContexts/item/orderCruiseContext'
import getExperienceCheckoutItem from './eventContexts/item/checkoutExperienceContext'
import getExperienceOrderItems from './eventContexts/item/orderExperienceContext'
import getFlightCartItems from './eventContexts/item/cartFlightsContext'
import getFlightOrderItems from './eventContexts/item/orderFlightContext'
import getGiftCardCheckoutItem from './eventContexts/item/checkoutGiftCardContext'
import getHotelCheckoutItem from './eventContexts/item/checkoutHotelContext'
import getInsuranceCheckoutItems from './eventContexts/item/checkoutInsuranceContext'
import getInsuranceOrderItems from './eventContexts/item/orderInsuranceContext'
import getLeHotelOrderItems from './eventContexts/item/orderHotelContext'
import getLuxPlusCartItem from './eventContexts/item/cartLuxPlusContext'
import getTourOrderItems from './eventContexts/item/orderTourContext'
import getTourV1CheckoutItem from './eventContexts/item/checkoutTourV1Context'
import getTourV2CheckoutItem from './eventContexts/item/checkoutTourV2Context'
import { SnowplowItemContextType } from './events'
import orderContext from './eventContexts/orderContext'
import {
  isBedbankOffer,
  isCarHireOffer,
  isCruiseOffer,
  isExperienceOffer,
  isFlightDeal,
  isJourneyV2,
  isLEOffer,
  isTourV2Offer,
} from 'lib/offer/offerTypes'
import getBookingProtectionOrderItems from './eventContexts/item/orderBookingProtectionContext'
import getGiftCardOrderItems from './eventContexts/item/orderGiftCardContext'
import getLuxPlusOrderItems from './eventContexts/item/orderLuxPlusContext'
import { TrackableProduct } from 'components/OfferList/OfferListEventsContext'
import { getChannelMarkup } from 'selectors/channelMarkupSelector'
import { arrayToMap } from 'lib/array/arrayUtils'
import { OfferPageState } from 'contexts/OfferPage/offerPageStateReducer'
import getExperienceCartItem from './eventContexts/item/cartExperienceContext'
import getHotelCartItem from './eventContexts/item/cartHotelContext'
import getBedbankHotelCartItem from './eventContexts/item/cartBedbankContext'
import cartFlightsContextForUserCart from './eventContexts/item/cartFlightsContextForUserCart'
import { getOfferDeepLink } from 'selectors/offerDeepLinkSelector'

const REDUX_STATE_OBSERVER_TIMEOUT = 3000 // 3 seconds

/**
 * Event definition helpers
 **/

export function buildLuxPlusUpsellContext(
  notSuitable: string,
  yes: string,
  whyNotSuitable?: string,
  isLuxPlusOffered?: string,
  declineReason?: string,
  agentId?: string,
): Array<{
  schema: string;
  data: Record<string, unknown>;
  }> {
  const data = [
    {
      agentId,
      questionId: 'isLuxPlusOffered',
      questionText: 'Did you offer LuxPlus to the customer?',
      answer: isLuxPlusOffered,
    },
  ]

  if (isLuxPlusOffered === notSuitable && whyNotSuitable !== '') {
    data.push({
      agentId,
      questionId: 'whyNotSuitable',
      questionText: 'Why was LuxPlus not suitable?',
      answer: whyNotSuitable,
    })
  }

  if (isLuxPlusOffered === yes && declineReason !== '') {
    data.push({
      agentId,
      questionId: 'declineReason',
      questionText: 'Why did the customer decline LuxPlus?',
      answer: declineReason,
    })
  }

  return data.map((item) => comluxgroup.createSurvey_1_0_0(item))
}

export function buildInsuranceUpsellContext(
  notSuitable: string,
  yes: string,
  no: string,
  whyNotSuitable?: string,
  isCovermore?: string,
  isOffered?: string,
  declineReason?: string,
  agentId?: string,
): Array<{
  schema: string;
  data: Record<string, unknown>;
}> {
  const data = [
    {
      agentId,
      questionId: 'isCovermore',
      questionText:
        'Is the customer purchasing travel protection through Covermore?',
      answer: isCovermore,
    },
    ...(isCovermore === no ?
        [
          {
            agentId,
            questionId: 'isOffered',
            questionText: 'Did you offer travel protection to the customer?',
            answer: isOffered,
          },
        ] :
        []),
    ...(isOffered === notSuitable && whyNotSuitable !== '' ?
        [
          {
            agentId,
            questionId: 'whyNotSuitable',
            questionText: 'Why was the customer/product not suitable?',
            answer: whyNotSuitable,
          },
        ] :
        []),
    ...(isOffered === yes && declineReason !== '' ?
        [
          {
            agentId,
            questionId: 'declineReason',
            questionText: 'Why did the customer decline the travel protection?',
            answer: declineReason,
          },
        ] :
        []),
  ]

  return data.map((item) => comluxgroup.createSurvey_1_0_0(item))
}

export type ExperienceEventDefinitionKey =
  | 'SAVE_TO_TRIP_BUTTON_CLICKED'
  | 'SHARE_BUTTON_CLICKED'
  | 'SHARE_OPTION_SELECTED'
  | 'TITLE_COPIED'
  | 'CHECK_AVAILABILITY_CLICKED'
  | 'GIFTING_AVAILABLE_CLICKED'
  | 'SUPPORT_TEAM_NUMBER_BUTTON_CLICKED'
  | 'SUPPORT_TEAM_EMAIL_BUTTON_CLICKED'
  | 'GIVE_AS_GIFT_CLICKED'
  | 'GIFT_COMPONENT_CLOSED'
  | 'GIFT_INCREASE_OPTION_SELECTED'
  | 'GIFT_DECREASE_OPTION_SELECTED'
  | 'GIFT_BUY_NOW_CLICKED'
  | 'INTEREST_FREE_INFO_CLICKED'
  | 'BNBL_BUTTON_CLICKED'
  | 'BNBL_LEARN_MORE_BUTTON_CLICKED'
  | 'BNBL_COMPONENT_CLOSED'
  | 'BNBL_INCREASE_OPTION_SELECTED'
  | 'BNBL_DECREASE_OPTION_SELECTED'
  | 'BNBL_BUY_NOW_CLICKED'
  | 'BROWSE_ALL_EXPERIENCES_CLICK'
  | 'CALENDAR_OPEN'
  | 'CALENDAR_CLOSE'
  | 'DATE_ON_CALENDAR_SELECTED'
  | 'DATE_ON_CALENDAR_SELECTED_NON_INTERACTION'
  | 'LANGUAGE_SELECTED'
  | 'NEXT_MONTH_CLICKED'
  | 'PREV_MONTH_CLICKED'
  | 'TIME_SLOT_SELECTED'
  | 'TIME_SLOT_SELECTED_NON_INTERACTION'
  | 'INCREASE_OPTION_SELECTED'
  | 'DECREASE_OPTION_SELECTED'
  | 'BUY_NOW_CLICKED'
  | 'CANCELLATION_POLICY_CLICKED'
  | 'INFO_HOW_TO_REDEEM_OPEN'
  | 'INFO_HOW_TO_REDEEM_CLOSE'
  | 'INFO_CANCELLATION_POLICY_OPEN'
  | 'INFO_CANCELLATION_POLICY_CLOSE'
  | 'INFO_AVAILABILITY_OPEN'
  | 'INFO_AVAILABILITY_CLOSE'
  | 'INFO_CONDITIONS_OF_OFFER_OPEN'
  | 'INFO_CONDITIONS_OF_OFFER_CLOSE'
  | 'INFO_VENDOR_INFORMATION_OPEN'
  | 'INFO_VENDOR_INFORMATION_CLOSE'
  | 'INFO_FINE_PRINT_INFORMATION_OPEN'
  | 'INFO_FINE_PRINT_INFORMATION_CLOSE'
  | 'PICKUP_LOCATION_SELECTED'
  | 'PICKUP_LOCATION_SELECTED_NON_INTERACTION'
  | 'REDEMPTION_LOCATION_SELECTED'
  | 'REDEMPTION_LOCATION_SELECTED_NON_INTERACTIVE'
  | 'GEOLOCATION_OPENED'
  | 'GEOLOCATION_CLOSED'
  | 'GEOLOCATION_USE_CURRENT_LOCATION_CLICKED'
  | 'GEOLOCATION_SELECTED'
  | 'CATEGORY_CLICKED'
  | 'OFFER_TILE_CLICKED'
  | 'OFFER_TILE_IMPRESSION'
  | 'CAROUSEL_NEXT_CLICKED'
  | 'CAROUSEL_PREV_CLICKED'
  | 'VIEW_ON_MAP_CLICKED'
  | 'VIEW_ALL_CLICKED'
  | 'TRENDING_TILE_CLICKED'
  | 'ARTICLE_TILE_CLICKED'
  | 'CHECKOUT_SKIP_CLICKED'
  | 'CHECKOUT_CONTINUE_CLICKED'
  | 'TICKET_MODE_SELECTED'
  | 'TICKET_MODE_SELECTED_NON_INTERACTIVE'

export const experienceOfferEventsMap: Record<ExperienceEventDefinitionKey, Omit<comluxgroup.ClientEvent_1_0_0, 'category'>> = {
  SAVE_TO_TRIP_BUTTON_CLICKED: {
    action: 'pressed',
    subject: 'save to trip',
    type: 'interaction',
  },
  SHARE_BUTTON_CLICKED: {
    action: 'pressed',
    subject: 'share button',
    type: 'interaction',
  },
  SHARE_OPTION_SELECTED: {
    action: 'selected',
    subject: 'share option',
    type: 'interaction',
  },
  TITLE_COPIED: {
    action: 'copied',
    subject: 'experience title',
    type: 'interaction',
  },
  CHECK_AVAILABILITY_CLICKED: {
    action: 'pressed',
    subject: 'check availability',
    type: 'interaction',
  },
  GIFTING_AVAILABLE_CLICKED: {
    action: 'pressed',
    subject: 'gifting available',
    type: 'interaction',
  },
  SUPPORT_TEAM_NUMBER_BUTTON_CLICKED: {
    action: 'pressed',
    subject: 'support team number button',
    type: 'interaction',
  },
  SUPPORT_TEAM_EMAIL_BUTTON_CLICKED: {
    action: 'pressed',
    subject: 'support team email button',
    type: 'interaction',
  },
  GIVE_AS_GIFT_CLICKED: {
    action: 'pressed',
    subject: 'give as gift',
    type: 'interaction',
  },
  GIFT_COMPONENT_CLOSED: {
    action: 'closed',
    subject: 'gift component',
    type: 'interaction',
  },
  GIFT_INCREASE_OPTION_SELECTED: {
    action: 'selected',
    subject: 'gift increase option',
    type: 'interaction',
  },
  GIFT_DECREASE_OPTION_SELECTED: {
    action: 'selected',
    subject: 'gift decrease option',
    type: 'interaction',
  },
  GIFT_BUY_NOW_CLICKED: {
    action: 'pressed',
    subject: 'gift buy now',
    type: 'interaction',
  },
  BNBL_LEARN_MORE_BUTTON_CLICKED: {
    action: 'pressed',
    subject: 'bnbl lean more button',
    type: 'interaction',
  },
  INTEREST_FREE_INFO_CLICKED: {
    action: 'pressed',
    subject: 'interest free info button',
    type: 'interaction',
  },
  BNBL_BUTTON_CLICKED: {
    action: 'pressed',
    subject: 'bnbl buy now',
    type: 'interaction',
  },
  BNBL_COMPONENT_CLOSED: {
    action: 'closed',
    subject: 'bnbl component',
    type: 'interaction',
  },
  BNBL_INCREASE_OPTION_SELECTED: {
    action: 'selected',
    subject: 'bnbl increase option',
    type: 'interaction',
  },
  BNBL_DECREASE_OPTION_SELECTED: {
    action: 'selected',
    subject: 'bnbl decrease option',
    type: 'interaction',
  },
  BNBL_BUY_NOW_CLICKED: {
    action: 'pressed',
    subject: 'bnbl buy now',
    type: 'interaction',
  },
  BROWSE_ALL_EXPERIENCES_CLICK: {
    action: 'pressed',
    subject: 'browse all experiences',
    type: 'interaction',
  },
  CALENDAR_OPEN: {
    action: 'opened',
    subject: 'calendar',
    type: 'interaction',
  },
  CALENDAR_CLOSE: {
    action: 'closed',
    subject: 'calendar',
    type: 'interaction',
  },
  DATE_ON_CALENDAR_SELECTED: {
    action: 'selected',
    subject: 'date on calendar',
    type: 'interaction',
  },
  DATE_ON_CALENDAR_SELECTED_NON_INTERACTION: {
    action: 'selected',
    subject: 'date on calendar',
    type: 'nonInteraction',
  },
  NEXT_MONTH_CLICKED: {
    action: 'pressed',
    subject: 'next month',
    type: 'interaction',
  },
  PREV_MONTH_CLICKED: {
    action: 'pressed',
    subject: 'prev month',
    type: 'interaction',
  },
  TIME_SLOT_SELECTED: {
    action: 'selected',
    subject: 'time slot',
    type: 'interaction',
  },
  TIME_SLOT_SELECTED_NON_INTERACTION: {
    action: 'selected',
    subject: 'time slot',
    type: 'nonInteraction',
  },
  INCREASE_OPTION_SELECTED: {
    action: 'selected',
    subject: 'increase option',
    type: 'interaction',
  },
  DECREASE_OPTION_SELECTED: {
    action: 'selected',
    subject: 'decrease option',
    type: 'interaction',
  },
  BUY_NOW_CLICKED: {
    action: 'pressed',
    subject: 'buy now',
    type: 'interaction',
  },
  CANCELLATION_POLICY_CLICKED: {
    action: 'pressed',
    subject: 'cancellation policy',
    type: 'interaction',
  },
  INFO_HOW_TO_REDEEM_OPEN: {
    action: 'opened',
    subject: 'info how to redeem',
    type: 'interaction',
  },
  INFO_CANCELLATION_POLICY_OPEN: {
    action: 'opened',
    subject: 'info cancellation policy',
    type: 'interaction',
  },
  INFO_AVAILABILITY_OPEN: {
    action: 'opened',
    subject: 'info availability',
    type: 'interaction',
  },
  INFO_CONDITIONS_OF_OFFER_OPEN: {
    action: 'opened',
    subject: 'info conditions of Experience',
    type: 'interaction',
  },
  INFO_VENDOR_INFORMATION_OPEN: {
    action: 'opened',
    subject: 'info vendor information',
    type: 'interaction',
  },
  INFO_FINE_PRINT_INFORMATION_OPEN: {
    action: 'opened',
    subject: 'info fine print information',
    type: 'interaction',
  },
  INFO_HOW_TO_REDEEM_CLOSE: {
    action: 'closed',
    subject: 'info how to redeem',
    type: 'interaction',
  },
  INFO_CANCELLATION_POLICY_CLOSE: {
    action: 'closed',
    subject: 'info cancellation policy',
    type: 'interaction',
  },
  INFO_AVAILABILITY_CLOSE: {
    action: 'closed',
    subject: 'info availability',
    type: 'interaction',
  },
  INFO_CONDITIONS_OF_OFFER_CLOSE: {
    action: 'closed',
    subject: 'info conditions of Experience',
    type: 'interaction',
  },
  INFO_VENDOR_INFORMATION_CLOSE: {
    action: 'closed',
    subject: 'info vendor information',
    type: 'interaction',
  },
  INFO_FINE_PRINT_INFORMATION_CLOSE: {
    action: 'closed',
    subject: 'info fine print information',
    type: 'interaction',
  },
  LANGUAGE_SELECTED: {
    action: 'selected',
    subject: 'language',
    type: 'interaction',
  },
  PICKUP_LOCATION_SELECTED: {
    action: 'selected',
    subject: 'pickup location',
    type: 'interaction',
  },
  PICKUP_LOCATION_SELECTED_NON_INTERACTION: {
    action: 'selected',
    subject: 'pickup location',
    type: 'nonInteraction',
  },
  REDEMPTION_LOCATION_SELECTED: {
    action: 'selected',
    subject: 'redemption location',
    type: 'interaction',
  },
  REDEMPTION_LOCATION_SELECTED_NON_INTERACTIVE: {
    action: 'selected',
    subject: 'redemption location',
    type: 'nonInteraction',
  },
  TICKET_MODE_SELECTED: {
    action: 'selected',
    subject: 'ticket mode',
    type: 'interaction',
  },
  TICKET_MODE_SELECTED_NON_INTERACTIVE: {
    action: 'selected',
    subject: 'ticket mode',
    type: 'nonInteraction',
  },
  GEOLOCATION_OPENED: {
    action: 'opened',
    subject: 'geo location',
    type: 'interaction',
  },
  GEOLOCATION_CLOSED: {
    action: 'closed',
    subject: 'geo location',
    type: 'interaction',
  },
  GEOLOCATION_USE_CURRENT_LOCATION_CLICKED: {
    action: 'pressed',
    subject: 'use my current location',
    type: 'interaction',
  },
  GEOLOCATION_SELECTED: {
    action: 'pressed',
    subject: 'location',
    type: 'interaction',
  },
  CATEGORY_CLICKED: {
    action: 'pressed',
    subject: 'experiences category tile',
    type: 'interaction',
  },
  OFFER_TILE_CLICKED: {
    action: 'pressed',
    subject: 'offer tile',
    type: 'interaction',
  },
  OFFER_TILE_IMPRESSION: {
    action: 'impression',
    subject: 'offer tile',
    type: 'nonInteraction',
  },
  CAROUSEL_NEXT_CLICKED: {
    action: 'pressed',
    subject: 'carousel next button',
    type: 'interaction',
  },
  CAROUSEL_PREV_CLICKED: {
    action: 'pressed',
    subject: 'carousel prev button',
    type: 'interaction',
  },
  VIEW_ON_MAP_CLICKED: {
    action: 'pressed',
    subject: 'view on map',
    type: 'interaction',
  },
  VIEW_ALL_CLICKED: {
    action: 'pressed',
    subject: 'view all',
    type: 'interaction',
  },
  TRENDING_TILE_CLICKED: {
    action: 'pressed',
    subject: 'trending destination tile',
    type: 'interaction',
  },
  ARTICLE_TILE_CLICKED: {
    action: 'pressed',
    subject: 'article tile',
    type: 'interaction',
  },
  CHECKOUT_SKIP_CLICKED: {
    action: 'pressed',
    subject: 'checkout skip button',
    type: 'interaction',
  },
  CHECKOUT_CONTINUE_CLICKED: {
    action: 'pressed',
    subject: 'checkout continue button',
    type: 'interaction',
  },
}

interface ExperienceItemProps {
  packageId?: string;
  typeId?: string;
  rateId?: string;
  variant?: string;
  travelStart?: string;
  travelEnd?: string;
  month?: string;
  year?: string;
  duration?: number;
  numberOfAdults?: number;
  numberOfChildren?: number;
  numberOfInfants?: number;
  childrenAges?: Array<number>;
  value?: number;
  price?: number;
  surcharge?: number;
  propertyFees?: number;
  currency: string;
  region: string;
  brand: string;
  reservationType?: string;
  bookingNumber?: string;
  status?: string;
  orderId?: string;
  itemId?: string;
  metadata?: string;
}

interface ExperienceListProps {
  listId?: string;
  listName?: string;
  listSource?: string;
}

interface ExperienceOfferEventContextData {
  offer: App.ExperienceOffer;
  itemProps?: ExperienceItemProps;
  listProps?: ExperienceListProps;
  geo: App.GeoState;
}

export interface ExperienceOfferEventContext {
  type: 'impression' | 'click' | 'item';
  data: ExperienceOfferEventContextData;
}

function itemToEventContext(event: ExperienceOfferEventContextData) {
  return comluxgroup.createItem_1_0_0({
    ...event.itemProps,
    brand: config.BRAND,
    offerId: event.offer.id,
    offerType: event.offer.productType,
    currency: event.geo.currentCurrency,
    region: event.geo.currentRegionCode,
  })
}

function getProductAction(event: ExperienceOfferEventContextData) {
  return {
    ...event.listProps,
    id: event.offer.id,
    name: event.offer.name,
    currency: event.geo.currentCurrency,
    productType: event.offer.productType,
    price: event.offer.price,
  }
}

function productClickEventContext(event: ExperienceOfferEventContextData) {
  return comluxgroup.createProductClickContext_1_0_1(getProductAction(event))
}

function productImpressionContext(event: ExperienceOfferEventContextData) {
  return comluxgroup.createProductImpressionContext_1_0_1(getProductAction(event))
}

export function getExperienceContext(eventContexts: Array<ExperienceOfferEventContext>): Array<SelfDescribingJson> {
  return eventContexts.map((eventContext) => {
    if (eventContext.type === 'impression') {
      return productImpressionContext(eventContext.data)
    }
    if (eventContext.type === 'click') {
      return productClickEventContext(eventContext.data)
    }
    // then eventContext.type === 'item'
    return itemToEventContext(eventContext.data)
  })
}

export interface ExperienceEventEntity {
  data: {
    offer: App.ExperienceOffer;
    itemProps?: ExperienceItemProps;
    listProps?: ExperienceListProps;
  }
  type: 'impression' | 'click' | 'item'
}

export const offerEventsMap: Record<OfferContextEvent, Omit<comluxgroup.ClientEvent_1_0_0, 'category'>> = {
  UPGRADE_MODAL_OPENED: {
    action: 'opened',
    subject: 'upgrade_modal',
    type: 'interaction',
  },
  UPGRADE_MODAL_ACCEPTED: {
    action: 'accepted',
    subject: 'upgrade_modal',
    type: 'interaction',
  },
  UPGRADE_MODAL_REJECTED: {
    action: 'rejected',
    subject: 'upgrade_modal',
    type: 'interaction',
  },
  UPGRADE_MODAL_CLOSED: {
    action: 'closed',
    subject: 'upgrade_modal',
    type: 'interaction',
  },
  BOOK_NOW_PRESSED: {
    action: 'pressed',
    subject: 'book_now',
    type: 'interaction',
  },
  BUY_NOW_CHOOSE_DATES_LATER_PRESSED: {
    action: 'pressed',
    subject: 'buy_now_choose_dates_later',
    type: 'interaction',
  },
  CHECK_AVAILABILITY_PRESSED: {
    action: 'pressed',
    subject: 'check_availability',
    type: 'interaction',
  },
  VIEW_PACKAGE_OPTIONS_PRESSED: {
    action: 'pressed',
    subject: 'view_package_options',
    type: 'interaction',
  },
  DURATION_PRESSED: {
    action: 'pressed',
    subject: 'duration',
    type: 'interaction',
  },
  OCCUPANCY_FILTERS_APPLIED: {
    action: 'applied',
    subject: 'guests',
    type: 'interaction',
  },
  OCCUPANCY_FILTERS_PRESSED: {
    action: 'pressed',
    subject: 'guests',
    type: 'interaction',
  },
  OCCUPANCY_FILTERS_CLOSED: {
    action: 'closed',
    subject: 'guests',
    type: 'interaction',
  },
  CAPACITY_HOTEL_FILTER_OPENED: {
    action: 'opened',
    subject: 'guests',
    type: 'interaction',
  },
  CALENDAR_MONTH_SELECTED: {
    action: 'selected',
    subject: 'calendar_month',
    type: 'interaction',
  },
  CALENDAR_CHECK_IN_SELECTED: {
    action: 'selected',
    subject: 'calendar_check_in',
    type: 'interaction',
  },
  CALENDAR_FILTER_OPENED: {
    action: 'opened',
    subject: 'when',
    type: 'nonInteraction',
  },
  CALENDAR_TOGGLE_PRESSED: {
    action: 'pressed',
    subject: 'when',
    type: 'interaction',
  },
  CALENDAR_CLOSED: {
    action: 'closed',
    subject: 'when',
    type: 'interaction',
  },
  CALENDAR_DATE_CHANGED: {
    action: 'changed',
    subject: 'when',
    type: 'interaction',
  },
  CALENDAR_FILTERS_APPLY: {
    action: 'applied',
    subject: 'when',
    type: 'interaction',
  },
  CALENDAR_HOTEL_TOGGLED: {
    action: 'toggled',
    subject: 'when_hotel_flight',
    type: 'interaction',
  },
  CALENDAR_SUB_FILTER_OPENED: {
    action: 'opened',
    subject: 'when_subfilter',
    type: 'interaction',
  },
  CALENDAR_SUB_FILTER_CLOSED: {
    action: 'closed',
    subject: 'when_subfilter',
    type: 'interaction',
  },
  AIRPORT_FILTER_CLOSED: {
    action: 'closed',
    subject: 'airport',
    type: 'interaction',
  },
  AIRPORT_FILTER_PRESSED: {
    action: 'pressed',
    subject: 'airport',
    type: 'interaction',
  },
  AIRPORT_FILTER_SELECTED: {
    action: 'selected',
    subject: 'airport',
    type: 'interaction',
  },
  HOTEL_NAME_SELECTED: {
    action: 'selected',
    subject: 'hotel_name',
    type: 'interaction',
  },
  DURATION_FILTER_SELECTED: {
    action: 'selected',
    subject: 'duration',
    type: 'interaction',
  },
  DURATION_FILTER_CLOSED: {
    action: 'closed',
    subject: 'duration',
    type: 'interaction',
  },
  DURATION_FILTER_PRESSED: {
    action: 'pressed',
    subject: 'duration',
    type: 'interaction',
  },
  VIEW_TOUR_OPTIONS_PRESSED: {
    action: 'pressed',
    subject: 'tour_option',
    type: 'interaction',
  },
  VIEW_TOUR_OPTIONS_EXTENDED_PRESSED: {
    action: 'pressed',
    subject: 'tour_option_ext',
    type: 'interaction',
  },
  VIEW_TOUR_OPTIONS_EXTENDED_ALT_PRESSED: {
    action: 'pressed',
    subject: 'tour_option_ext_alt',
    type: 'interaction',
  },
}

type SeachContextOverrideParams = Omit<Partial<comluxgroup.Search_1_0_2>, 'searchParams'> & { searchParams: Partial<comluxgroup.Search_1_0_2['searchParams']> }

export function buildSearchContext(override?: SeachContextOverrideParams): comluxgroup.Search_1_0_2 {
  return {
    verticals: [],
    searchBar: '',
    searchType: '',
    searchSessionId: '',
    searchResults: [],
    ...override,
    searchParams: {
      landmarkId: null,
      propertyId: null,
      destinationId: null,
      checkIn: null,
      checkOut: null,
      isFlexibleDates: false,
      occupancies: [],
      sort: {
        isPristine: true,
      },
      filters: {
        holidayTypes: [],
        locations: [],
        amenities: [],
      },
      ...override?.searchParams,
    },
  }
}

export function mapToSnowplowFilters(existingFilters: App.OfferListFilters): comluxgroup.Search_1_0_2['searchParams']['filters'] {
  return {
    holidayTypes: existingFilters.holidayTypes ?? [],
    locations: existingFilters.locations ?? [],
    amenities: existingFilters.amenities ?? [],
    propertyTypes: existingFilters.propertyTypes ?? [],
    categories: existingFilters.categories ?? [],
    minimumCustomerRating: existingFilters.customerRatingGte ?? 0,
    luxPlusFeatures: existingFilters.luxPlusFeatures ?? [],
    inclusions: existingFilters.inclusions ?? [],
  }
}

export function createTourOfferItemContexts(
  offer: App.Tours.TourV2Offer,
  regionCode: string,
  currency: string,
  tourItems: Array<App.Checkout.TourV2Item> = [],
): Array<ReturnType<typeof comluxgroup.createItem_1_0_0>> {
  const itemContexts: Array<ReturnType<typeof comluxgroup.createItem_1_0_0>> = []

  if (tourItems.length > 0) {
    tourItems.forEach((tourItem: App.Checkout.TourV2Item) => {
      const hasDates = !!(tourItem.startDate && tourItem.endDate)
      const duration = hasDates ? moment(tourItem?.endDate).diff(tourItem?.startDate, 'days') : undefined
      itemContexts.push(comluxgroup.createItem_1_0_0({
        offerId: offer.id,
        offerType: offer.type,
        region: regionCode,
        packageId: tourItem?.purchasableOption?.fkVariationId,
        currency,
        travelStart: tourItem?.startDate,
        travelEnd: tourItem?.endDate,
        duration,
        childrenAges: tourItem?.occupancy?.childrenAge,
        numberOfAdults: tourItem?.occupancy?.adults,
        numberOfChildren: tourItem?.occupancy?.children,
        numberOfInfants: tourItem?.occupancy?.infants,
        brand: config.BRAND,
      }))
    })
  }

  return itemContexts
}

export function getItemsContextForOffer(state: App.State) {
  const offer = getOffer(state)
  if (!offer) return

  const { hotelOrTourOffer, otherOffers, variationId } = offer
  if (!hotelOrTourOffer && !otherOffers) return
  if (otherOffers) return getGeneralOfferItem(otherOffers, state, variationId)
  if (hotelOrTourOffer) return getHotelOrTourOfferItem(hotelOrTourOffer, state)
}

export interface ItemContextForOfferParams {
  state: App.State,
  offer: TrackableProduct,
  leadPrice?: number,
  price?: number,
  duration?: number,
  offerPageState?: OfferPageState,
  productAvailable?: boolean
  roomRateId?: string,
  metadata?: string,
}

export function getAnyItemContextForOffer(itemContextParams: ItemContextForOfferParams) {
  const { state, offer, leadPrice, price, duration, offerPageState, productAvailable, roomRateId, metadata } = itemContextParams
  if (
    isCarHireOffer(offer) ||
    isFlightDeal(offer) ||
    isJourneyV2(offer) ||
    isExperienceOffer(offer) ||
    isTourV2Offer(offer) ||
    isCruiseOffer(offer)
  ) {
    return getGeneralOfferItem(offer, state, null, offerPageState)
  }
  if (isLEOffer(offer) || isBedbankOffer(offer)) {
    return getHotelOrTourOfferItem(offer, state, leadPrice, price, duration, offerPageState, productAvailable, roomRateId, metadata)
  }
}

export async function getItemsContextForCheckout(state: App.State, itemsToSkip: Array<SnowplowItemContextType> = [], isMultiCart?: boolean) {
  const items = await state.checkout.cart.items.reduce(async(previousItemsPromise, currentItem) => {
    const items = await previousItemsPromise
    if (isExperienceItem(currentItem) && !itemsToSkip.includes('experience')) return [...items, ...(await getExperienceCheckoutItem(state, currentItem, isMultiCart))]
    if (isTransferItem(currentItem) && !itemsToSkip.includes('experience')) return [...items, ...(await getExperienceCheckoutItem(state, currentItem, isMultiCart))]
    if (isFlightItem(currentItem) && !itemsToSkip.includes('flight')) return [...items, ...(await getFlightCartItems(state, currentItem, isMultiCart))]
    if (isCarHireItem(currentItem) && !itemsToSkip.includes('carHire')) return [...items, (await getCarHireCheckoutItem(state, currentItem))]
    if (isCruiseItem(currentItem) && !itemsToSkip.includes('cruise')) return [...items, getCruiseCheckoutItem(state, currentItem)]
    if (cartIsAccommodationItem(currentItem) && !itemsToSkip.includes('hotel')) return [...items, getHotelCheckoutItem(state, currentItem, undefined, undefined, isMultiCart)]
    if (isInsuranceItem(currentItem) && !itemsToSkip.includes('insurance')) return [...items, ...getInsuranceCheckoutItems(state, currentItem)]
    if (isBookingProtectionItem(currentItem) && !itemsToSkip.includes('bookingProtection')) return [...items, ...getBookingProtectionCheckoutItems(state, currentItem)]
    // also includes cruise v1 so still needed
    if (isTourV1Item(currentItem) && !itemsToSkip.includes('tour')) return [...items, getTourV1CheckoutItem(state, currentItem)]
    if (isTourV2Item(currentItem) && !itemsToSkip.includes('tour')) return [...items, getTourV2CheckoutItem(state, currentItem)]
    if (isGiftCardItem(currentItem) && !itemsToSkip.includes('giftCard')) return [...items, getGiftCardCheckoutItem(state, currentItem)]
    if (isLuxPlusSubscriptionItem(currentItem) && !itemsToSkip.includes('luxuryPlus')) return [...items, getLuxPlusCartItem(state, currentItem)]
    return items
  }, Promise.resolve([]))
  return filterDefinedProperties(items.filter((item):item is SelfDescribingJson => !!item))
}

export async function getItemsContextForCart(state: App.State, items: Array<App.Checkout.AnyItem>, itemsToSkip: Array<SnowplowItemContextType> = [], isMultiCart?: boolean) {
  const mappedItems:Array<SelfDescribingJson> = []
  for (const item of items) {
    if (isExperienceItem(item) && !itemsToSkip.includes('experience')) mappedItems.push(...(await getExperienceCartItem(state, item, isMultiCart)))
    if (isTransferItem(item) && !itemsToSkip.includes('experience')) mappedItems.push(...(await getExperienceCartItem(state, item, isMultiCart)))
    if (isFlightItem(item) && !itemsToSkip.includes('flight')) mappedItems.push(...(await cartFlightsContextForUserCart(state, item)))
    if (isBedbankItem(item) && !itemsToSkip.includes('hotel')) mappedItems.push(getBedbankHotelCartItem(state, item, isMultiCart))
    if (!isBedbankItem(item) && cartIsAccommodationItem(item) && !itemsToSkip.includes('hotel')) mappedItems.push(getHotelCartItem(state, item, undefined, isMultiCart))
    if (isLuxPlusSubscriptionItem(item) && !itemsToSkip.includes('luxuryPlus')) mappedItems.push(getLuxPlusCartItem(state, item, isMultiCart))
  }
  return filterDefinedProperties(mappedItems.filter((item): item is SelfDescribingJson => !!item))
}

export function getOrderFromState(state: App.State) {
  const pathParams = state.routeHistory.currentPathParams as { orderId?: string }
  const orderId = pathParams.orderId || state.router.location.query?.orderId
  return state.orders.orders[orderId]
}

export async function getOrderItemsContext(state: App.State, order: App.Order, isMultiCart?: boolean) {
  try {
    const asyncItems = await Promise.all([
      getTourOrderItems(state, order),
      getCarHireOrderItems(state, order),
      getLeHotelOrderItems(state, order, isMultiCart),
      getBedbankHotelOrderItems(state, order, isMultiCart),
      getCruiseOrderItems(state, order),
      getExperienceOrderItems(state, order, isMultiCart),
      getFlightOrderItems(state, order, isMultiCart),
    ])
    return [
      orderContext(order),
      ...getAddonOrderItems(order),
      ...getInsuranceOrderItems(order, isMultiCart),
      ...getBookingProtectionOrderItems(order, isMultiCart),
      ...getGiftCardOrderItems(order),
      ...getLuxPlusOrderItems(order, isMultiCart),
      ...asyncItems.flat(),
    ]
  } catch (err) {
    console.error(err)
    return []
  }
}

export async function getPageOfferCategory(state: App.State): Promise<comluxgroup.Page_1_0_0['category']> {
  if (!state.routeHistory.currentPathParams) return

  let offerId = (state.routeHistory.currentPathParams as any).offerId
  // For some dum reason the cruises analytics page params has id and not offerId
  if (!offerId) offerId = (state.routeHistory.currentPathParams as any).id
  if (!offerId) return

  const offer = getOffer(state)
  if (!offer) return

  let hotelOrTourOffer = offer.hotelOrTourOffer
  let otherOffers = offer.otherOffers

  let category: comluxgroup.Page_1_0_0['category']

  if (!hotelOrTourOffer && !otherOffers) {
    // offer isn't loaded yet, fetch it, this should be only in rare cases
    //   -> state should be pre-populated from SSR so offer should be present
    //   -> hence only in the case where SSR or SS data fetching is disabled + the offer isn't loaded by the time this is executed this should kick in
    //   -> we also don't want to populate the redux state from here if this is executed due to specific logic residing in hooks that load the offers that determine how the state is saved.

    // handle errors as we are making direct calls to the api, if we don't handle errors here the PageViewed event chain is stopped and not tracked
    try {
      // if offerId starts with an experience provider prefix, no current logic/selector for this
      if (Object.keys(PROVIDERS).find(provider => offerId.startsWith(provider))) {
        otherOffers = await getExperienceById(offerId, { currentRegionCode: state.geo.currentRegionCode, currentCurrency: state.geo.currentCurrency })
      } else {
        const channelMarkup = getChannelMarkup(state)
        const offerDeepLink = getOfferDeepLink(state)
        const anyOffer = await getOfferById(offerId, { region: state.geo.currentRegionCode, channelMarkup, offerDeepLink })
        type OtherOfferTypes = App.Tours.TourV2Offer | App.CruiseOffer | App.ExperienceOffer | undefined
        // if anyOffer is of type OtherOfferTypes
        if ((anyOffer as OtherOfferTypes)?.type) {
          otherOffers = anyOffer as OtherOfferTypes
        } else {
          hotelOrTourOffer = anyOffer as App.Offer
        }
      }
      // on api failure no fallback except if it's a cruise page (getOfferById will fail for cruises v2)
    } catch {
      if (state.routeHistory.currentPath.includes('/cruises/')) {
        try {
          otherOffers = await getCruiseOffer(offerId, state.geo.currentRegionCode, state.geo.currentCurrency)
          // no remaining fallback, will return empty category
        } catch { }
      }
    }
  }

  if (hotelOrTourOffer) {
    // Handle tours that are cruises
    if (hotelOrTourOffer.type !== 'bedbank_hotel' && hotelOrTourOffer.holidayTypes?.includes('Cruises')) category = 'cruise'

    // Handle last minute hotel manually because business requirements are to track it as such
    else if (hotelOrTourOffer.type === 'last_minute_hotel') category = hotelOrTourOffer.type

    // Otherwise fallback on generic parent type
    else category = hotelOrTourOffer.parentType.includes('tour') ? 'tour' : hotelOrTourOffer.parentType
  } else if (otherOffers) {
    // @ts-expect-error `tour_v2` is not assignable to `category` BUT, it'll be excluded as long as the ternary condition is met.
    category = otherOffers.parentType.includes('tour') ? 'tour' : otherOffers.parentType
  }

  // Override category with ultra lux when needed, from productType string
  if (hotelOrTourOffer || otherOffers) {
    const type = hotelOrTourOffer ? hotelOrTourOffer.productType : otherOffers?.productType
    if (type?.includes('ultralux')) category = 'ultralux'
    else if (type?.includes('ultra_lux')) category = 'ultralux'
  }

  return category
}

function getOffer(state: App.State) {
  const offerId = getOfferId(state)
  if (!offerId) return

  const variationId = (state.routeHistory.currentPathParams as any).variationId

  const hotelOrTourOffer = getHotelOrTourOffer(state, offerId, variationId)
  const otherOffers = getCruiseTourV2OrExperienceOffer(state, offerId, variationId)

  return {
    hotelOrTourOffer,
    otherOffers,
    variationId,
  }
}

export const getOrderId = (state: App.State): string => {
  const pathParams = state?.routeHistory.currentPathParams as { orderId?: string }
  return pathParams.orderId || state?.router?.location?.query?.orderId
}

const getOfferId = (state: App.State): string | undefined => {
  if (!state.routeHistory.currentPathParams) return
  let offerId = (state.routeHistory.currentPathParams as any).offerId
  // For some dum reason the cruises analytics page params has id and not offerId
  if (!offerId) offerId = (state.routeHistory.currentPathParams as any).id
  return offerId
}

const getCheckoutOfferId = (state: App.State): Array<string> | undefined => {
  if (state.checkout.cart.items.length) {
    return state.checkout.cart.items.map(itm => {
      if (isAccommodationItem(itm)) return itm.offerId
      if (isExperienceItem(itm)) return itm.experienceId
      return ''
    }).filter(s => s.length)
  }
}

export function orderIdLoadedAndErrored(currentState: App.State, orderId: string): boolean {
  const fetchingDone = currentState.orders.ordersFetching[orderId]
  const fetchingError = currentState.orders.orderErrors[orderId]
  return fetchingDone && !!fetchingError
}

export const orderIdStateIsLoaded = (currentState: App.State, orderId: string): boolean => {
  const orderData = currentState?.orders?.orders?.[orderId]
  if (orderIdLoadedAndErrored(currentState, orderId)) return true
  if (!orderData) return false

  const allLeOffersLoaded =
    orderData.items.every(item => !!currentState.offer.offers[item.offerId])

  const allBedbankOffersLoaded =
    orderData.bedbankItems.every(item => !!currentState.offer.offers[item.offer.id])

  const allCruiseOffersLoaded =
    orderData.cruiseItems.every(item => {
      const cruiseOfferId = currentState.cruise.cruiseDeparture[item.departureId]?.offer?.id
      return !!cruiseOfferId && !!currentState.cruise.cruiseOffers[cruiseOfferId]
    })

  const allExperienceOffersLoaded =
    orderData.experienceItems.every(item => !!currentState.experience.experiences[item.experienceId])

  const allFlightOffersLoaded =
    orderData.flightItems.every(item => !!currentState.orders.flightDetails[item.itemId]?.flight)

  const allTourOffersLoaded =
    orderData.tourItems.every(item => !!currentState.offer.offers[item.tourId])

  const allCarHireDataLoaded =
    orderData.carHireItems.every(item =>
      !!item.idReservation &&
      !!currentState.carHire.reservationInfo[item.idReservation].reservation,
    )

  return allLeOffersLoaded &&
    allBedbankOffersLoaded &&
    allCruiseOffersLoaded &&
    allExperienceOffersLoaded &&
    allFlightOffersLoaded &&
    allTourOffersLoaded &&
    allCarHireDataLoaded
}

const offerIdStateIsLoaded = (state: App.State, offerId: string): boolean => {
  const variationId = (state.routeHistory.currentPathParams as any).variationId
  const hotelOrTourOffer = getHotelOrTourOffer(state, offerId, variationId)
  const otherOffers = getCruiseTourV2OrExperienceOffer(state, offerId, variationId)
  return !!hotelOrTourOffer || !!otherOffers || offerIdStateHasError(state, offerId)
}

export function offerIdStateHasError(state: App.State, offerId: string) {
  const offerError = state.offer.offerErrors[offerId]
  const offerLoading = state.offer.offerSummariesLoading[offerId] && state.offer.offersLoading[offerId]
  const cruiseError = state.cruise.cruiseOffersErrors[offerId]
  const cruiseLoading = state.cruise.cruiseOffersLoading[offerId]
  const experienceError = state.experience.experienceErrors[offerId]
  const experienceLoading = state.experience.fetchingExperiences[offerId]
  return (offerError && !offerLoading) ||
    (cruiseError && !cruiseLoading) ||
    (experienceError && !experienceLoading)
}

export const waitPageViewDataLoaded = async(state: App.State, listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>) => {
  // check conditions for awaiting specific state
  const listeners: Array<Promise<boolean>> = []
  const orderId = getOrderId(state)
  if (orderId) {
    listeners.push(listenerApi.condition(
      (_, currentState: App.State) => {
        return orderIdStateIsLoaded(currentState, orderId)
      },
      REDUX_STATE_OBSERVER_TIMEOUT))
  }
  const offerId = getOfferId(state)
  if (offerId) {
    listeners.push(listenerApi.condition(
      (_, currentState: App.State) => {
        return offerIdStateIsLoaded(currentState, offerId)
      },
      REDUX_STATE_OBSERVER_TIMEOUT))
  }
  const checkoutOfferIds = getCheckoutOfferId(state)
  if (checkoutOfferIds?.length) {
    listeners.push(listenerApi.condition(
      (_, currentState: App.State) => {
        return checkoutOfferIds.every(id => offerIdStateIsLoaded(currentState, id))
      },
      REDUX_STATE_OBSERVER_TIMEOUT))
  }
  if (!state.auth.initComplete) {
    listeners.push(listenerApi.condition(
      (_, currentState: App.State) => {
        return currentState.auth.initComplete
      },
      REDUX_STATE_OBSERVER_TIMEOUT))
  }

  return Promise.all(listeners)
}

export function filterDefinedProperties(contexts: Array<{
  schema: string;
  data: Record<string, unknown>;
}>) {
  return contexts.map((context) => {
    const contextClone = { ...context }

    const data = contextClone.data
    const definedProperties = removeUndefinedAndNullProperties(data)

    return {
      ...context,
      data: definedProperties,
    }
  })
}

export function orderItemTransactionKeyMap(order: App.Order) {
  const itemsWithTransactionId = [
    ...order.addonItems,
    ...order.bedbankItems,
    ...order.bookingProtectionItems,
    ...order.carHireItems,
    ...order.experienceItems,
    ...order.items,
    ...order.insuranceItems,
    ...order.subscriptionItems,
  ].map(item => ({ id: item.id, transactionKey: item.transactionKey }))

  const itemsWithItemId = [
    ...order.cruiseItems,
    ...order.flightItems,
    ...order.tourItems,
    ...order.giftCardItems,
  ].map(item => ({ id: item.itemId, transactionKey: item.transactionKey }))

  return arrayToMap([...itemsWithTransactionId, ...itemsWithItemId],
    item => item.transactionKey)
}
