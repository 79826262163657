import { getPlural } from 'lib/string/pluralize'
import { capitalise } from 'lib/string/stringUtils'
import { fareTypeLabelMap, FlightsFareTypes } from 'constants/flight'
import moment from 'moment'
import { SHORT_DAY_NAME_DAY_MONTH_YEAR } from 'constants/dateFormats'
import { last } from 'lib/array/arrayUtils'

interface Supplement {
  validatingCarrierName: string;
  validatingCarrierLogo: string;
}

export function buildFlightItemBreakdownView(
  itemView: App.Checkout.FlightItemView,
  segment: App.Checkout.FlightItemViewJourney,
  supplement: Supplement,
): App.Checkout.FlightItemBreakdownView {
  return {
    itemId: itemView.item.itemId,
    title: formatJourneyShorthand(segment.journeyFlight),
    additionalInfo: [{ text: formatJourneyDepartureDate(segment.journeyFlight) }],
    itemType: 'flight',
    additionalElements: [],
    price: segment.cost || segment.journeyFlight.cost,
    journey: segment.journeyFlight,
    validatingCarrierLogo: supplement.validatingCarrierLogo,
    validatingCarrierName: supplement.validatingCarrierName,
    isBundled: itemView.isBundled,
    taxesAndFees: itemView.totals.taxesAndFees,
    fareFamily: segment.fareFamily?.fareFamily.description,
  }
}

export function formatJourneyDepartureDate(journey: App.JourneyFlight): string {
  return moment(journey.flights[0].departingDate).format(SHORT_DAY_NAME_DAY_MONTH_YEAR)
}

export function formatFareType(type: 'return' | 'oneWay' | 'multiCity'): string {
  const titleNoun = type === FlightsFareTypes.ONE_WAY ? 'flight' : 'flights'
  return `${fareTypeLabelMap[type]} ${titleNoun}`
}

export function formatJourneyShorthand(journey: App.JourneyFlight): string {
  const journeyDepartureLocation = journey.flights[0].departingDisplayNames.primary
  const journeyArrivalLocation = last(journey.flights).arrivalDisplayNames.primary
  return `${journeyDepartureLocation} to ${journeyArrivalLocation}`
}

export function formatJourneySummaryTitle(journeys: Array<App.Checkout.FlightItemViewJourney>, fareType: FlightsFareTypes): string {
  if ([FlightsFareTypes.RETURN, FlightsFareTypes.ONE_WAY].includes(fareType)) {
    return formatJourneyShorthand(journeys[0].journeyFlight)
  }

  const departureLocation = journeys[0].journeyFlight.flights[0].departingDisplayNames.primary
  const lastJourney = last(journeys)
  const arrivalLocation = last(lastJourney.journeyFlight.flights).arrivalDisplayNames.primary

  return `${departureLocation} to ${arrivalLocation}`
}

export function formatFlightPassengersShorthand(passengers: Array<App.Checkout.FlightPassenger>): string {
  const paxMap = passengers.reduce<{[type: string]: number}>((acc, item) => {
    return { ...acc, [item.type]: item.type in acc ? acc[item.type] + 1 : 1 }
  }, {})
  const elements = Object.keys(paxMap).map(key => {
    const count = paxMap[key]
    return `${count} ${capitalise(getPlural(key, count))}`
  })
  return elements.join(', ')
}
