import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import LuxPlusHiddenOfferDetails from './LuxPlusHiddenOfferDetails'
import LuxPlusHiddenOfferImageContainer from './LuxPlusHiddenOfferImageContainer'
import Image from 'components/Common/Image/Image'
import useHiddenOfferClickHandler from './useHiddenOfferClickHandler'
import NestableClickable from 'components/Common/NestableClickable'
import { LabelCountProvider } from 'contexts/LabelCountContext'

const LayoutWrapper = styled(NestableClickable)`
  display: grid;
  grid-template-rows: ${rem(200)} 1fr;
  gap: ${rem(16)};
  height: 100%;
  position: relative;

  &.hidden {
    visibility: hidden;
    display: none;
  }
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  className?: string;
  urgencyLabels?: Array<App.OfferUrgencyLabel>;
}

function LuxPlusHiddenOfferCarouselCard(props: Props) {
  const {
    offer,
    urgencyLabels,
    className,
  } = props
  const onHandleHiddenOffer = useHiddenOfferClickHandler(offer)

  return <LabelCountProvider maxLabels={2}>
    <LayoutWrapper className={className} onClick={onHandleHiddenOffer}>
      <LuxPlusHiddenOfferImageContainer>
        <Image
          aspectRatio="3:2"
          image={offer.image}
          fit="center"
          quality="low"
          height="20"
          width="32"
          alt={`Exclusive hidden offer in ${offer.locationSubheading}`}
        />
      </LuxPlusHiddenOfferImageContainer>
      <LuxPlusHiddenOfferDetails offer={offer} urgencyLabels={urgencyLabels} />
    </LayoutWrapper>
  </LabelCountProvider>
}

export default LuxPlusHiddenOfferCarouselCard
