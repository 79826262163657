import ResponsiveImage from 'components/Common/ResponsiveImage'
import useCurrentSiteTakeover, { SiteTakeoverBackground } from 'hooks/useCurrentSiteTakeover'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #DA5E31 3%, #DA3146 23%, #670B0B 100%);
  overflow: hidden;
`

interface Props extends Omit<React.ComponentProps<typeof ResponsiveImage>, 'id' | 'fit'> {
  background: SiteTakeoverBackground
}

function MarchTravelFrenzyTakeoverBackground(props: Props) {
  const { className } = props

  const currentTakeover = useCurrentSiteTakeover()

  if (!currentTakeover) {
    return null
  }

  return <Container className={className} />
}

export default MarchTravelFrenzyTakeoverBackground
