import React from 'react'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import PageBanner from 'components/Luxkit/Banners/PageBanner'
import LineExclamationTriangleIcon from 'components/Luxkit/Icons/line/LineExclamationTriangleIcon'

function LuxPlusWarningMessageBanner() {
  return <PageBanner
    kind="warning"
    align="center"
    icon={<LineExclamationTriangleIcon />}
    description={<>
      We are currently experiencing issues with your{' '}
      {LUXURY_PLUS.PROGRAM_NAME} membership. Our team is on it. Please check back later.
    </>}
  >
  </PageBanner>
}

export default LuxPlusWarningMessageBanner
