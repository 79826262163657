import { createSelector } from 'reselect'

export const getSherlogFields = createSelector(
  (state: App.State) => state.router.location.pathname,
  (state: App.State) => state.routeHistory,
  (state: App.State) => state.checkout.cart,
  (state: App.State) => state.sherLog,
  (path, routeHistory, cart, sherlog) => {
    const orderResponse = sherlog.logData.find(log => log.requestName === '/api/orders') || undefined

    const orderResponseBody = orderResponse?.responseBody
    const orderRequestBody = orderResponse?.requestBody

    const failedRequests = sherlog.logData.filter(log => log.status && !['200', '201'].includes(log.status.toString()))
    return {
      trace_id: sherlog.traceId,
      url: path,
      route_history: JSON.stringify([routeHistory.currentPath, ...routeHistory.recentHistory]),
      admin_url: orderRequestBody && 'https://admin.luxuryescapes.com/purchases/' + orderResponseBody?.id,
      order_id: path.includes('/au/account/my-escapes/order/') ? path.split('/order/')[1] : orderResponseBody?.id,
      booking_number: orderResponseBody?.number,
      payment_methods_used: orderRequestBody?.payments.payments.map((payment: any) => payment.type).join(', '),
      currency: orderRequestBody?.payments.currency,
      is_deposit_order: orderRequestBody?.payments.isDepositPayment.toString(),
      cart_item_count: cart.items.length,
      cart_id: cart.cartId,
      cart_item_types: cart.items.reduce((typesList, currentItem) => typesList + (typesList && ', ') + currentItem.itemType, ''),
      failed_requests: failedRequests.map(failedRequest => failedRequest.requestName).join(', '),
    }
  },
)
