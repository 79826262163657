import React from 'react'
import { rem } from 'polished'

import { useAppDispatch } from 'hooks/reduxHooks'
import { pushWithRegion } from 'actions/NavigationActions'
import moment from 'moment'
import { formatOccupantsAsGuests } from 'lib/offer/occupancyUtils'
import { encodeSearchParams } from 'lib/search/searchUtils'
import switchFunc from 'lib/function/switchFunc'
import styled from 'styled-components'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import LayoutContainer from 'components/Common/LayoutContainer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import { getFormattedDateRange, getRecentSearchDescription } from 'components/Search/utils'
import Clickable from 'components/Common/Clickable'
import Typography from 'components/Luxkit/Typography/Typography'
import LineHistoryIcon from 'components/Luxkit/Icons/line/LineHistoryIcon'
import { DEFAULT_FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import useRecentSearches from 'hooks/Search/useRecentSearches'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const SearchItem = styled(Clickable)`
  display: flex;
  gap: ${rem(12)};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  padding: ${rem(12)} ${rem(16)};
  border-radius: ${props => props.theme.borderRadius.S};
  align-items: center;
  transition: border-color 0.25s;

  ${mediaHoverable} {
    &:hover {
      border-color: ${props => props.theme.palette.neutral.default.one};
    }
  }
`

const searchTypeConfig = switchFunc({
  property: { icon: <LineBedIcon colour="primary"/>, mainText: '' },
}, { icon: <LineMapMarkerIcon colour="primary" />, mainText: 'Hotels in ' })

interface Props {
  onClick?: () => void;
}

function getSubtitleTextFromRecentSearch(recentSearch: App.FullRecentSearch) {
  const { dates, rooms } = recentSearch
  const dateText = getRecentSearchDescription(dates.checkIn, dates.checkOut, dates.flexibleMonths, dates.flexibleDuration as DEFAULT_FLEXIBLE_DURATION_RANGE, dates.isAnytime)

  const occupancyText = formatOccupantsAsGuests(...rooms)

  const subtitleText = dateText !== '' && occupancyText !== '' && occupancyText !== '' ? `${dateText} • ${occupancyText}` : ''

  return subtitleText
}

function RecentSearches(props: Props) {
  const { onClick } = props
  const dispatch = useAppDispatch()
  const recentSearches = useRecentSearches()

  function onClickHandler(recentSearch: App.FullRecentSearch) {
    onClick?.()
    const dates = {
      checkIn: recentSearch.dates?.checkIn,
      checkOut: recentSearch.dates?.checkOut,
    }
    const isFlexibleWithDate = !dates?.checkIn || !dates?.checkOut
    const queryParams = encodeSearchParams({
      urlSearch: '',
      searchItem: recentSearch.searchItem,
      dates,
      rooms: recentSearch.rooms,
      isFlexibleWithDate,
    }).toString()
    dispatch(pushWithRegion('/search', queryParams))
  }

  const recentSearchItems = recentSearches.map((item) => {
    return {
      ...item,
      searchType: searchTypeConfig(item.searchItem.searchType),
      searchMainText: item.searchItem.format.mainText,
      occupancyText: formatOccupantsAsGuests(...item.rooms),
      datesText: item.dates?.checkIn && item.dates?.checkOut ?
        `${getFormattedDateRange(moment(item.dates.checkIn), moment(item.dates.checkOut))}` :
        'Anytime',
    }
  })

  if (!recentSearchItems.length) {
    return null
  }

  return (
    <LayoutContainer>
      <VerticalSpacer gap={12}>
        <Caption variant="large">Recent searches</Caption>
        <CardCarousel gap={16}>
          {recentSearchItems.map((item) =>
            <SearchItem
              key={item.recentSearchId}
              onClick={() => onClickHandler(item)}
            >
              <LineHistoryIcon />
              <Typography wrap="truncate">
                <BodyText wrap="truncate" variant="small" weight="bold">
                  {item.searchItem.format.mainText}
                </BodyText>
                <Caption variant="large" colour="neutral-three">{getSubtitleTextFromRecentSearch(item)}</Caption>
              </Typography>
            </SearchItem>,
          )}
        </CardCarousel>
      </VerticalSpacer>
    </LayoutContainer>
  )
}

export default RecentSearches
