import { createSelector } from 'reselect'
import getTransferItems from './getTransferItems'
import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'

export const getTransferItemsView = createSelector(
  (state: App.State) => getTransferItems(state),
  (state: App.State) => state.experience.experiences,
  (
    transferItems,
    experiences,
  ): App.WithDataStatus<Array<App.Checkout.TransferItemView>> => {
    return {
      // make sure full experience data has been fetched for every transfer
      hasRequiredData: transferItems.every(transfer => !!experiences[transfer.experienceId]),
      data: transferItems.map(item => {
        const offer = experiences[item.experienceId]
        return {
          luxLoyaltyProductType: getLuxLoyaltyProductType(offer),
          item,
          totals: {
            mobileAppDiscount: {
              amount: item.transfer.option?.discounts?.app.amount ?? 0,
              percentage: item.transfer.option?.discounts?.app.percentage ?? 0,
            },
            price: item.transfer.option?.price ?? 0,
            memberPrice: item.transfer.option?.memberPrice ?? 0,
            taxesAndFees: item.transfer.option?.taxesAndFees ?? 0,
            value: item.transfer.option?.price ?? 0,
            memberValue: item.transfer.option?.price ?? 0,
            surcharge: 0,
            extraGuestSurcharge: 0,
          },
        }
      }),
    }
  },
)

export default getTransferItemsView
