import React, { useContext } from 'react'
import CruisesSearchPage from '../SearchPage/CruiseSearchPage'
import { RouteComponentProps } from 'react-router'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import HeroImage from 'content/components/HeroImage'
import LayoutContainer from 'components/Common/LayoutContainer'
import styled from 'styled-components'
import { rem } from 'polished'
import useCruiseSearchCount from 'hooks/Cruise/useCruiseSearchCount'
import RedirectWithStatus from 'components/Common/RedirectWithStatus'
import GeoContext from 'contexts/geoContext'
import { decodeFromUrlString } from './CruiseSeoPageUtil'
const StyledHeroImage = styled(HeroImage)`
  min-height: ${rem(180)};
`

function CruiseShipsLandingPage(props: RouteComponentProps<{ shipNames: string }>) {
  const { shipNames } = props.match.params
  const readableShipNames = decodeFromUrlString(shipNames)

  const filters : App.OfferListFilters = {
    cruiseShips: [readableShipNames],
  }

  const [searchCount, fetchingSearchCount] = useCruiseSearchCount(filters)
  const { currentRegionCode } = useContext(GeoContext)

  if (!fetchingSearchCount && searchCount === 0) {
    return <RedirectWithStatus code={302} to={`/${currentRegionCode.toLowerCase()}/cruises`} withSearchAndHash={false} />
  }

  const imageId = 'cx0zqdkcwd7ok8d10ag'
  const title = `Cruises on ${readableShipNames}`
  const subtitle = `Explore luxury cruise deals aboard ${readableShipNames}`

  return (
    <>
      <StyledHeroImage
        id={imageId}
        brightness={-25}
        fit="center"
        gravity="center"
      >
        <LayoutContainer relative>
          <Heading variant="heading2" align="center" as="h1" colour="neutral-eight">{title}</Heading>
          <BodyText variant="large" align="center" as="h2" colour="neutral-eight">{subtitle}</BodyText>
        </LayoutContainer>
      </StyledHeroImage>
      <CruisesSearchPage initFilter={filters} shouldHideCruiseShipsFilters />
    </>
  )
}

export default CruiseShipsLandingPage
