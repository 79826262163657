import request, { authOptions } from 'api/requestUtils'
import { actions, definitions } from '@luxuryescapes/contract-svc-agent'
import qs from 'qs'
import { agentHubCommissionMap } from './mappers/agentHubMap'

type GetAgentHubCommissionPromoCodeBody = Omit<definitions['calculateCartCommissionBody'], 'brand'>
type GetAgentHubCommissionPromoResponse = actions['calculateCartCommission']['responses']['200']['content']['application/json']['result']

type CartItem = {
  type: string;
  offerId: string;
  price: number;
  location: Array<string>;
  vendor: string;
  margin?: number;
  itemIds?: string;
  transactionKey: string;
  mx?: string;
}

type FetchAgentHubCommissionPromoCodeBody = {
  items: Array<CartItem>;
  cartId?: string;
  total: number;
  transactionKey: string;
}

export async function getAgentHubCommissionPromoCode(regionCode: string, cart: FetchAgentHubCommissionPromoCodeBody, force?: boolean) {
  const query = qs.stringify({
    region: regionCode,
  })

  const body: GetAgentHubCommissionPromoCodeBody = {
    force,
    cart: {
      items: cart.items,
      cartId: cart.cartId,
      total: cart.total,
      orderTransactionKey: cart.transactionKey,
    },
  }

  const response = await request.post<App.ApiResponse<GetAgentHubCommissionPromoResponse>, GetAgentHubCommissionPromoCodeBody>(
    `/api/agent/calculate-cart-commission?${query}`,
    body,
    authOptions(),
  )

  return agentHubCommissionMap(response.result)
}
