import { FLEXIBLE_DURATION_RANGE } from 'constants/search'

// SearchMenuStates is a set of possible states for the search menu
export enum SearchMenuStates {
  Closed = 'CLOSED',
  Main = 'MAIN',
  Locations = 'LOCATIONS',
  Dates = 'DATES',
  Rooms = 'ROOMS',
  ExperienceCategories = 'ExperienceCategories',
  CruiseLines = 'CruiseLines',
  DeparturePorts = 'DeparturePorts',
  CruiseWhen = 'CruiseWhen',
  CarHireDatePicker = 'CarHireDatePicker',
  CarHirePickUpTime = 'CarHirePickUpTime',
  CarHireReturnTime = 'CarHireReturnTime',
  CarHireBusinessTravellerSelect = 'CarHireBusinessTravellerSelect',
  TourGroupType = 'TourGroupType',
}

export interface RecentDate {
  checkinDate: moment.Moment,
  checkoutDate: moment.Moment,
}

export interface RecentFlexibleSearch {
  monthsSelection?: string,
  durationSelection?: FLEXIBLE_DURATION_RANGE,
}

export interface EnabledFilters {
  holidayTypes: boolean;
  amenities: boolean;
  inclusions: boolean;
  locations: boolean;
  customerRating: boolean;
  priceFilter: boolean;
  offerTypes: boolean;
  popularFilters: boolean;
  bedrooms?: boolean;
  beds?: boolean;
  bathrooms?: boolean;
  luxPlusFeatures: boolean;
  agentHubFeatures: boolean;
}

export const defaultEnabledFilters: EnabledFilters = {
  holidayTypes: true,
  amenities: true,
  inclusions: true,
  locations: true,
  customerRating: true,
  priceFilter: true,
  bedrooms: false,
  beds: false,
  bathrooms: false,
  offerTypes: false,
  popularFilters: true,
  luxPlusFeatures: false,
  agentHubFeatures: false,
}

export interface RecentFilters {
  holidayTypes: Array<string>;
  amenities: Array<string>;
  inclusions: Array<string>;
  locations: Array<string>;
  customerRatingGte?: number;
  expiryDate?: number;
  orderBy?: Array<string | number>;
  bedroomsGte?: number;
  bedsGte?: number;
  bathroomsGte?: number;
  luxPlusFeatures?: Array<string>;
  agentHubFeatures?: Array<string>;
}

export type Position = 'right' | 'left' | 'up' | 'down' | 'middle';
