import { useAppDispatch } from 'hooks/reduxHooks'
import noop from 'lib/function/noop'
import React, { useCallback } from 'react'
import RecentSearchSuggestionItem from './RecentSearchSuggestionItem'
import styled from 'styled-components'
import Caption from 'components/Luxkit/Typography/Caption'
import { rem } from 'polished'
import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { removeRecentSearch } from 'actions/SearchActions'

const StyledCaption = styled(Caption)`
  padding: 0;

  ${mediaQueryUp.tablet} {
    &:not(.horizontal) {
      padding: 0 ${rem(16)};
    }
  }
`

const RecentSearchItemContainer = styled.div`
  &.horizontal {
    display: flex;
    column-gap: ${rem(40)};
    flex-wrap: wrap;
    padding: 0;
  }

`

interface Props {
  recentSearches: Array<App.FullRecentSearch>;
  onDelete?: (item: App.FullRecentSearch) => void;
  closeDropdown?: () => void;
  selectedDropdown?: number;
  onRecentSearchClick: (recentSearch: App.FullRecentSearch) => void;
  horizontal?: boolean;
  iconBackground?: boolean;
  className?: string;
}

function RecentSearchSuggestions(props: Props) {
  const { recentSearches, onDelete = noop, selectedDropdown, onRecentSearchClick, horizontal, iconBackground, className } = props

  const dispatch = useAppDispatch()

  const onDeleteClick = useCallback((recentSearch: App.FullRecentSearch) => {
    dispatch(removeRecentSearch(recentSearch.recentSearchId))
    onDelete(recentSearch)
  }, [dispatch, onDelete])

  return (
    <>
      <StyledCaption variant="large" weight="bold" className={cn({ horizontal })}>Recent searches</StyledCaption>
      <div data-testid="search-recent-searches" className={className}>
        <RecentSearchItemContainer className={cn({ horizontal })}>
          {recentSearches.map((recentSearch, index) => <RecentSearchSuggestionItem
            key={recentSearch.recentSearchId}
            selected={index === selectedDropdown}
            iconBackground={iconBackground}
            recentSearch={recentSearch}
            onClick={onRecentSearchClick}
            onDelete={onDeleteClick}
            horizontal={horizontal}
          />)}
        </RecentSearchItemContainer>
      </div>
    </>
  )
}

export default RecentSearchSuggestions
