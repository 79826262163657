import loadable from '@loadable/component'
import { tripLoginModalSignUpDismiss, tripLoginModalSignUpView } from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import SolidHeartIcon from 'components/Luxkit/Icons/solid/SolidHeartIcon'
import ModalContext from 'contexts/ModalContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import usePendingLoginHandler from 'hooks/usePendingLoginHandler'
import React, { ComponentProps, useCallback, useContext, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { connect } from 'react-redux'
import styled from 'styled-components'
import FirstTripItemOnboardingTooltip from 'tripPlanner/components/Drawer/FirstTripItemOnboardingTooltip'
import { PrimaryNotificationDot } from 'tripPlanner/components/NotificationDot'
import { useTrips } from 'tripPlanner/hooks/api/trip'
import { clearFirstTripItemHasJustBeenAdded } from 'tripPlanner/reducers/actions'
import { useUserSettings } from 'tripPlanner/hooks/api/userSettings'

const SavedViewModal = loadable(() => import(/* webpackChunkName: "TripSavedViewModal" */ 'tripPlanner/components/Drawer/SavedViewModal'))

const MenuItem = styled.div`
  position: relative;
`

const TextWithNotification = styled.div`
  position: relative;
`

const MyTripNotificationDot = styled(PrimaryNotificationDot)`
  position: absolute;
  top: 0;
  left: 100%;
`

type InheritedTextButtonProps = Pick<ComponentProps<typeof TextButton>, 'size'>

interface Props extends InheritedTextButtonProps {
  tripItemHasJustBeenAdded: boolean;
  showFirstTripTooltip: boolean;
}

const trackTripLoginModalSignUpView = () => {
  fireInteractionEvent(tripLoginModalSignUpView('saved_drawer'))
}
const trackTripLoginModalSignUpDismiss = () => {
  fireInteractionEvent(tripLoginModalSignUpDismiss('saved_drawer'))
}

function NavigationHeaderTripsMenu(props: Props) {
  const { size, tripItemHasJustBeenAdded, showFirstTripTooltip } = props

  const { data: userSettings } = useUserSettings()
  const userHasBeenAddedToNewTrips = userSettings?.recentlyAddedToTripIDs && userSettings.recentlyAddedToTripIDs.length > 0

  const showModal = useContext(ModalContext)
  const openModal = useCallback(() => {
    showModal(<SavedViewModal mode="drawer" size="L" />)
  }, [showModal])
  const onClick = usePendingLoginHandler(openModal, 'tripPlannerLogin',
    trackTripLoginModalSignUpView,
    trackTripLoginModalSignUpDismiss,
  )
  const saveButtonRef = useRef<HTMLButtonElement | null>(null)
  const { data: trips = [] } = useTrips()
  const [menuItemRef, menuItemInView] = useInView({ triggerOnce: true, skip: !showFirstTripTooltip })

  const showTripNotificationDot = userHasBeenAddedToNewTrips || tripItemHasJustBeenAdded
  const showTooltip = menuItemInView && showFirstTripTooltip

  const dispatch = useAppDispatch()
  const hasSavedItems = !!trips?.find(trip => trip.itemCount > 0)

  const onDismissFirstTripTooltip = useCallback(() => {
    dispatch(clearFirstTripItemHasJustBeenAdded())
  }, [dispatch])

  return <MenuItem ref={menuItemRef}>
    <TextButton
      kind="tertiary"
      variant="dark"
      size={size}
      startIcon={ hasSavedItems ? <SolidHeartIcon colour="favourite"/> : <LineHeartIcon />}
      onClick={onClick}
      ref={saveButtonRef}
    >
      <TextWithNotification>Trip plans{showTripNotificationDot && <MyTripNotificationDot />}</TextWithNotification>
    </TextButton>
    {showTooltip && trips[0] && (
      <FirstTripItemOnboardingTooltip
        isOpen={showTooltip}
        anchorRef={saveButtonRef}
        placement="bottom"
        trip={trips[0]}
        onDismiss={onDismissFirstTripTooltip}
      />)
    }
  </MenuItem>
}

function mapStateToProps(state: App.State) {
  return {
    tripItemHasJustBeenAdded: state.tripPlanner.tripItemHasJustBeenAdded,
    showFirstTripTooltip: state.tripPlanner.firstTripItemHasJustBeenAdded,
  }
}

export default connect(mapStateToProps)(NavigationHeaderTripsMenu)
