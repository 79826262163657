import qs from 'qs'

/**
 * Initialises the site take over based on the search query found
 */
function intialiseSiteTakeover() {
  const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  if (typeof searchParams.takeoverPreview === 'string') {
    window.__DEBUG_TAKEOVER_ID__ = searchParams.takeoverPreview
  }
}

export default intialiseSiteTakeover
