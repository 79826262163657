import { groupBy, nonNullable } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import offerPageURL from 'lib/offer/offerPageURL'
import { getConfidenceLabels, getLabels } from 'checkout/lib/utils/accommodation/label'
import { getLocationString } from 'checkout/lib/utils/accommodation/location'
import { excludeNullOrUndefined } from 'checkout/utils'

import { getFullOffers } from 'selectors/offerSelectors'
import { isTourV1Offer } from 'lib/offer/offerTypes'
import getFlightBundledItemIds from './getFlightBundledItemIds'
import { pluralizeToString } from 'lib/string/pluralize'
import { getCheckoutLuxPlusTier } from '../checkoutLuxPlusSelectors'
import { getDepositServiceFeeConfig, getDefaultDepositAmountPercentage } from '../featureConfig/deposit'
import { checkoutWithMemberPrice } from './luxPlusSubscription'
import { getLEPackageItemView } from 'checkout/lib/utils/accommodation/view'
import { getDepositAmountPercentage } from 'lib/payment/depositsUtils'
import { getTourV1Items } from './tourv1'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'

const getTourV1ItemViews = createSelector(
  (state: App.State) => getTourV1Items(state),
  (state: App.State) => getDepositServiceFeeConfig(state),
  (state: App.State) => getDefaultDepositAmountPercentage(state),
  (state: App.State) => getFullOffers(state),
  (state: App.State) => state.calendar.calendarsByOccupancy,
  (state: App.State) => state.offer.offerAvailableRatesByOccupancy,
  (state: App.State) => state.businessTraveller.offersCredits,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => getCheckoutLuxPlusTier(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => getFlightBundledItemIds(state),
  (
    cartItems,
    serviceFee,
    defaultDepositAmountPercentage,
    offers,
    calendarsByOccupancy,
    availableRatesByOccupancy,
    offerCredits,
    postPurchase,
    existingOrder,
    luxPlusTier,
    checkoutWithMemberPrice,
    bundledItemIds,
  ): App.WithDataStatus<Array<App.Checkout.LEAccommodationOfferView>> => {
    // Each item = 1 room, but we want to process the same dates/offer as a single view
    const itemsByBookingKey = groupBy(cartItems, item => `${item.offerId}-${item.startDate}-${item.endDate}`)
    const itemGroups = itemsByBookingKey.values().map(items => ({
      offerId: items[0].offerId,
      items,
    })).toArray()

    const views = itemGroups.map((itemGroup): App.WithDataStatus<App.Checkout.LEAccommodationOfferView | undefined> => {
      const { offerId, items } = itemGroup

      const offer = offers[offerId]
      if (!isTourV1Offer(offer)) {
        return {
          hasRequiredData: false,
          data: undefined,
        }
      }

      const firstItem = items[0]
      const itemViewsWithStatuses = items.map(item => getLEPackageItemView(
        item,
        calendarsByOccupancy,
        availableRatesByOccupancy,
        checkoutWithMemberPrice,
        offer,
        offerCredits,
        postPurchase,
        existingOrder,
        luxPlusTier,
      ))

      const offerView: App.Checkout.LEAccommodationOfferView = {
        offerId,
        offer,
        offerPageUrl: offerPageURL(offer),
        ...getLabels(offer),
        bedGroups: [],
        confidenceLabels: getConfidenceLabels(offer),
        duration: firstItem.duration,
        startDate: firstItem.startDate,
        endDate: firstItem.endDate,
        image: offer.image,
        location: getLocationString(offer),
        occupancy: itemGroup.items.map(item => item.occupancy).filter(excludeNullOrUndefined),
        offerType: offer.type,
        reservationType: 'instant_booking',
        urgencyLabels: offer.urgencyTags,
        saleUnit: offer.saleUnitLong,
        durationLabel: pluralizeToString('day', firstItem.duration),
        offerLoaded: true,
        itemViews: itemViewsWithStatuses.map(i => i.data),
        propertyTimezone: '',
        bundledWithFlightsOnly: offer.bundledWithFlightsOnly,
        designation: 'accommodation',
        depositType: 'percentage',
        depositAmount: getDepositAmountPercentage(offer, defaultDepositAmountPercentage),
        serviceFee,
        isBundled: items.some(item => bundledItemIds.has(item.itemId)),
      }

      return {
        data: offerView,
        hasRequiredData: itemViewsWithStatuses.every(itemView => itemView.hasRequiredData),
      }
    })

    return {
      data: nonNullable(views.map(view => view.data)),
      hasRequiredData: views.every(view => view.hasRequiredData),
    }
  },
)

export default getTourV1ItemViews
