import { sum } from 'lib/array/arrayUtils'
import { mergeFees } from 'checkout/lib/utils/payments/fees'
import config from 'constants/config'

export function addTotals(
  totals: Array<App.WithDataStatus<App.Checkout.ItemViewTotals>>,
): App.WithDataStatus<App.Checkout.ItemViewTotals> {
  const businessTravellerCredits = config.businessTraveller.currentAccountMode === 'business' ?
    sum(totals, t => t.data.businessTravellerCredits ?? 0) :
    undefined

  return {
    hasRequiredData: totals.every(t => t.hasRequiredData),
    data: {
      price: sum(totals, t => t.data.price),
      memberPrice: sum(totals, t => t.data.memberPrice),
      value: sum(totals, t => (t.data.value ?? 0)),
      surcharge: sum(totals, t => (t.data.surcharge ?? 0)),
      taxesAndFees: sum(totals, t => t.data.taxesAndFees),
      otherFees: mergeFees(totals.map(total => total.data.otherFees ?? {})),
      propertiesFees: totals.filter(total => total.data.propertiesFees).flatMap(total => total.data.propertiesFees ?? []),
      businessTravellerCredits,
      carHirePaidOnPickUpFees: sum(totals, t => (t.data.carHirePaidOnPickUpFees ?? 0)),
      carHireAddonFees: sum(totals, t => (t.data.carHireAddonFees ?? 0)),
      carHirePayOnArrival: sum(totals, t => (t.data.carHirePayOnArrival ?? 0)),
      paidPrice: sum(totals, t => (t.data.paidPrice ?? 0)),
      newPrice: sum(totals, t => (t.data.newPrice ?? 0)),
      newSurcharge: sum(totals, t => (t.data.newSurcharge ?? 0)),
      newExtraGuestSurcharge: sum(totals, t => (t.data.newExtraGuestSurcharge ?? 0)),
      memberValue: 0,
      extraGuestSurcharge: 0,
    },
  }
}
