import React, { useCallback, useContext, useMemo, useRef } from 'react'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import { StandaloneFlightMenu } from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import ModalContext from 'contexts/ModalContext'
import { connect } from 'react-redux'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import BusinessTravellerMultiSelectModal from './BusinessTravellerMultiSelectModal'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'

interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
  guestCount?: number
}

function BusinessTravellerMultiSelectFlightsMobile(props: MappedStateProps) {
  const { travellerEmployees, guestCount } = props

  const showModal = useContext(ModalContext)
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const { activeMenu } = state

  const setActiveMenu = useCallback((activeMenu?: StandaloneFlightMenu) => {
    dispatch({ type: FlightSearchWidgetActions.SET_ACTIVE_MENU, activeMenu })
  }, [dispatch])

  const handleSelectTravellerClick = useCallback(async() => {
    if (activeMenu !== StandaloneFlightMenu.occupants) {
      setActiveMenu(StandaloneFlightMenu.occupants)

      await showModal(<BusinessTravellerMultiSelectModal />)

      setActiveMenu()
    } else {
      setActiveMenu()
    }
  }, [activeMenu, setActiveMenu, showModal])

  const fieldValue = useMemo(() => {
    const totalTravellers = (travellerEmployees?.length ?? 0) + (guestCount ?? 0)
    if (!totalTravellers) return undefined
    if (totalTravellers === 1) {
      const firstTraveller = travellerEmployees[0]

      return firstTraveller ? `${firstTraveller.firstName} ${firstTraveller.lastName}` : 'Guest traveller'
    }
    return `${totalTravellers} travellers`
  }, [travellerEmployees, guestCount])

  const localInputRef = useRef<HTMLButtonElement>(null)

  return (
    <SearchFormField
      label="Travellers"
      required
      requiredErrorMessage="Please select traveller"
      value={fieldValue}
      displayValue={fieldValue}
      placeholder="Select Traveller"
      onClick={handleSelectTravellerClick}
      ref={localInputRef}
    />
  )
}

export default connect<MappedStateProps, {}, {}, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
    guestCount: state.businessTraveller.selectedTravellerGuestCount,
  }
})(BusinessTravellerMultiSelectFlightsMobile)
