import FilterChip from 'components/Luxkit/Chips/FilterChip'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import {
  CruisePriceFilterProps,
} from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruisePriceFilter'
import React, { useCallback, useContext, useMemo } from 'react'
import { Form } from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchChips'
import ModalContext from 'contexts/ModalContext'
import ModalCruiseSearchPriceChip
  from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/Modal/ModalCruiseSearchPriceChip'

export interface CruiseSearchPriceChipProps {
  facets: Array<App.CruiseSearchFacet>;
  filters: {
    price: {
      min?: number;
      max?: number;
    };
  }
  listFilters: CruisePriceFilterProps;
  filterForm: Form;
  setFilterForm: (form: Form) => void;
  fetching?: boolean;
  drawerMode?: boolean;
}

function CruiseSearchPriceChip(props: CruiseSearchPriceChipProps) {
  const {
    filters,
    listFilters,
    facets,
    filterForm,
    setFilterForm,
    fetching,
    drawerMode,
  } = props
  const showModal = useContext(ModalContext)

  const selected = useMemo(() => {
    const priceFacet = facets.find(facet => facet.name === 'cruise_prices')
    if (!priceFacet) return false
    const minPrice = !!filters.price?.min && filters.price.min > priceFacet?.min!
    const maxPrice = !!filters.price?.max && filters.price.max < priceFacet?.max!
    return minPrice || maxPrice
  }, [facets, filters.price?.max, filters.price?.min])

  const openModal = useCallback(async() => {
    await showModal(
      <ModalCruiseSearchPriceChip
        drawerMode={drawerMode}
        listFilters={listFilters}
        facets={facets}
        filterForm={filterForm}
        setFilterForm={setFilterForm}
        fetching={fetching}
        filters={filters}
      />,
    )
  }, [drawerMode, facets, fetching, filterForm, filters, listFilters, setFilterForm, showModal])

  return <FilterChip
    data-testid="cruise-search-price-chip-button"
    selected={selected}
    size="medium"
    onClick={openModal}
    endIcon={<LineAngleDownIcon />}
  >
    Price
  </FilterChip>
}

export default CruiseSearchPriceChip
