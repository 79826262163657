import { FilterPanelCheckItem } from 'components/Common/FilterPanel/FilterPanelCheckboxGroup'
import FilterPanelCheckboxLoader from 'components/Common/FilterPanel/FilterPanelCheckboxLoader'
import FilterPanelFilterGroup from 'components/Common/FilterPanel/FilterPanelFilterGroup'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import debounce from 'lodash/debounce'
import React, { useCallback, useMemo, useRef } from 'react'
import Group from 'components/utils/Group'
import noop from 'lib/function/noop'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import { semiFormToObject } from 'lib/forms/formUtils'
import CruisePriceFilter, {
  CruisePriceFilterChangeProps,
} from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruisePriceFilter'
import CruiseShipFilter
  from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruiseShipFilter'
import CruisePromotionFilter
  from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruisePromotionFilter'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import LuxPlusLabel from 'luxPlus/components/LuxPlusLabel'
import CruiseLuxPlusFilter
  from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruiseLuxPlusFilter'
import { getCruiseLuxPlusItems } from 'lib/cruises/cruiseUtils'
import useCruiseFacetFiltersParams from 'hooks/useCruiseFacetFiltersParams'

export function buildPanelCheckItems(
  facets: Array<App.CruiseSearchFacet>,
  searchString?: string,
) {
  if (facets.length === 0) return []

  return facets.map((item): FilterPanelCheckItem => ({
    value: item.name,
    hidden: !!(searchString && facets.length > 5 && !item.name.toLowerCase().includes(searchString.toLowerCase())),
    label: (
      <Group direction="horizontal" horizontalAlign="space-between">
        {item.name}
        <span>({(item.count || 0) + (item.flashOfferCount || 0)})</span>
      </Group>
    ),
  }))
}

interface Props {
  listFilters: App.OfferListFilters;
  onChange?: (values: Record<string, any>) => void;
  shouldHidePromotionFilters?: boolean;
  shouldHideCruiseShips?: boolean;
}

function CruiseSearchFiltersInputs(props: Props) {
  const {
    listFilters,
    onChange = noop,
    shouldHidePromotionFilters = false,
    shouldHideCruiseShips = false,
  } = props

  const containerRef = useRef<HTMLDivElement | null>(null)
  const isMobile = useScreenSizeOnly('mobile')
  const { facetParams, queryParamsFilters: filters, mappedFilters } = useCruiseFacetFiltersParams({ filters: listFilters })

  const [facets, fetchingFacets] = useCruiseSearchFacets(facetParams)
  const getFormValues = useCallback(() => {
    const formValues = semiFormToObject<Record<string, any>>(containerRef.current!)
    delete formValues.price

    if (formValues.isLuxExclusivePromotion) {
      formValues.isLuxExclusivePromotion = true
    }

    if (formValues.isSpecialOffers) {
      formValues.isSpecialOffers = true
    }

    if (formValues.isLuxPlus) {
      formValues.isLuxPlus = true
    }

    return formValues
  }, [])

  const onFilterChange = useCallback(() => {
    const formValues = getFormValues()
    onChange(formValues)
  }, [onChange, getFormValues])

  const onResetPrices = useCallback(() => {
    const formValues = getFormValues()
    onChange({ ...formValues, minPrice: undefined, maxPrice: undefined })
  }, [onChange, getFormValues])

  const onDelayedFilterChange = useMemo(() => debounce(onFilterChange, 500), [onFilterChange])

  const onPriceFilterChange = useCallback((priceFilters: CruisePriceFilterChangeProps) => {
    const formValues = getFormValues()
    onChange({ ...formValues, ...priceFilters })
  }, [getFormValues, onChange])

  const isLuxPlusItemsExists = useMemo(() => {
    const luxPlusItems = getCruiseLuxPlusItems(facets)
    return luxPlusItems.some(item => item.count! > 0)
  }, [facets])

  return <VerticalSpacer gap={24} ref={containerRef}>
    {fetchingFacets && <>
      <FilterPanelCheckboxLoader />
      <FilterPanelCheckboxLoader />
      {isMobile && <FilterPanelCheckboxLoader />}
    </>}
    {(!fetchingFacets || isMobile) && <>
      {isLuxPlusItemsExists && <FilterPanelFilterGroup
        title={<LuxPlusLabel type="only-logo" />}
        onReset={onFilterChange}
      >
        <CruiseLuxPlusFilter
          isLuxPlus={filters.isLuxPlus}
          onChange={onDelayedFilterChange}
          facets={facets}
        />
      </FilterPanelFilterGroup>}

      {!shouldHidePromotionFilters && <FilterPanelFilterGroup
        title="Promotions"
        canReset={!!filters.promotions?.size}
        onReset={onFilterChange}
      >
        <CruisePromotionFilter
          facets={facets}
          isLuxExclusivePromotion={filters.isLuxExclusivePromotion}
          isSpecialOffers={filters.isSpecialOffers}
          onChange={onDelayedFilterChange}
        />
      </FilterPanelFilterGroup>}

      <FilterPanelFilterGroup
        title="Price"
        canReset={!!filters.price?.min || !!filters.price?.max}
        onReset={onResetPrices}
      >
        <CruisePriceFilter
          filters={mappedFilters}
          onChange={onPriceFilterChange}
          facets={facets}
        />
      </FilterPanelFilterGroup>
      {!shouldHideCruiseShips && <FilterPanelFilterGroup
        title="Cruise ships"
        canReset={!!filters.cruiseShips?.size}
        onReset={onFilterChange}
      >
        <CruiseShipFilter
          cruiseShips={filters.cruiseShips}
          facets={facets}
          onChange={onDelayedFilterChange}
        />
      </FilterPanelFilterGroup>
      }
    </>}
  </VerticalSpacer>
}

export default CruiseSearchFiltersInputs
