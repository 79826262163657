import { CreateTripItemRequest } from 'tripPlanner/api/tripItem/types'
import { components } from '@luxuryescapes/contract-trip/dist/server'
import { getGoogleExploreItemsPlaceDetails } from 'tripPlanner/api/places'
import { assertUnreachable } from 'tripPlanner/utils'
import { NLPResponseOption } from 'tripPlanner/types/nlp'

type ResponseOptionPlace = Exclude<Exclude<NLPResponseOption['parsedFields'], undefined>['fromPlace'], undefined>
type ItemCreationPayloadPlace = components['schemas']['place']

// Ideally, we should be able to just send the place ID and have svc-trip get the details.
async function getPlaceDetail(place: ResponseOptionPlace): Promise<{
  place: ItemCreationPayloadPlace
  url?: string
  phone?: string
  photo?: string
}> {
  if (!place.placeId) {
    return {
      place: {
        type: 'CUSTOM',
        name: place.name,
      },
    }
  }
  const placeDetail = (await getGoogleExploreItemsPlaceDetails([place.placeId])).items[0]
  return {
    place: placeDetail.place,
    url: placeDetail.website,
    phone: placeDetail.phone,
    photo: placeDetail.photo?.photoUri,
  }
}

function mapAirportPlace(place: ResponseOptionPlace): ItemCreationPayloadPlace {
  if (place.point) {
    return {
      type: 'GEO',
      name: place.name,
      point: {
        lat: place.point.lat,
        lng: place.point.lng,
      },
    }
  }
  return {
    type: 'CUSTOM',
    name: place.name,
  }
}

export async function mapNLPResponseOptionToItemPayload(option: NLPResponseOption): Promise<CreateTripItemRequest | undefined> {
  if (!option.parsedFields) {
    return undefined
  }
  const {
    label,
    parsedFields: {
      itemType,
      fromPlace,
      toPlace,
      startDate,
      startTime,
      endDate,
      endTime,
    },
  } = option

  if (!itemType) {
    throw new Error('Item type is required')
  }

  const commonFields = {
    startDate,
    endDate,
    startTime,
    endTime,
    isBooked: false,
    sourceType: 'NA',
  } as const

  if (itemType === 'THING_TO_DO') {
    if (!toPlace) {
      return undefined
    }
    const { place, url, phone } = await getPlaceDetail(toPlace)
    return {
      name: label,
      type: itemType,
      startPlace: place,
      endPlace: place,
      url,
      phone,
      ...commonFields,
    }
  }

  else if (itemType === 'ATTRACTION' || itemType === 'EVENT' || itemType === 'RESTAURANT_BAR') {
    if (!toPlace) {
      return undefined
    }
    const { place, url, phone } = await getPlaceDetail(toPlace)
    return {
      name: label,
      type: itemType,
      place,
      url,
      phone,
      ...commonFields,
    }
  }

  else if (itemType === 'ACCOMMODATION') {
    if (!toPlace) {
      return undefined
    }
    const { place, url, phone } = await getPlaceDetail(toPlace)
    return {
      type: itemType,
      place,
      url,
      phone,
      ...commonFields,
    }
  }

  else if (itemType === 'CAR_RENTAL' || itemType === 'BUS' || itemType === 'FERRY' || itemType === 'OTHER_TRANSPORT' || itemType === 'TRAIN') {
    if (!fromPlace || !toPlace) {
      return undefined
    }
    const [{ place: startPlace }, { place: endPlace, url, phone }] = await Promise.all([getPlaceDetail(fromPlace), getPlaceDetail(toPlace)])
    return {
      type: itemType,
      startPlace,
      endPlace,
      url,
      phone,
      ...commonFields,
    }
  }

  else if (itemType === 'TOUR' || itemType === 'CRUISE') {
    if (!fromPlace || !toPlace) {
      return undefined
    }
    const [{ place: startPlace }, { place: endPlace, url, phone }] = await Promise.all([getPlaceDetail(fromPlace), getPlaceDetail(toPlace)])
    return {
      name: label,
      type: itemType,
      startPlace,
      endPlace,
      url,
      phone,
      ...commonFields,
    }
  }

  else if (itemType === 'FLIGHT') {
    if (!fromPlace || !toPlace || !fromPlace.airportCode || !toPlace.airportCode) {
      return undefined
    }
    return {
      type: itemType,
      startPlace: mapAirportPlace(fromPlace),
      endPlace: mapAirportPlace(toPlace),
      originAirportCode: fromPlace.airportCode,
      destinationAirportCode: toPlace.airportCode,
      ...commonFields,
    }
  }

  else if (itemType === 'NOTE') {
    return {
      type: itemType,
      notes: label,
      ...commonFields,
    }
  }

  else {
    assertUnreachable(itemType)
  }
}
