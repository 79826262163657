import request, { authOptions } from 'api/requestUtils'
import { definitions } from '@luxuryescapes/contract-svc-agent'

type AgentHubGetBookingResponse = definitions['bookingSchema']

export async function getAgentHubBookingByOrderId(orderId: string): Promise<App.AgentHubBooking> {
  const response = await request.get<App.ApiResponse<AgentHubGetBookingResponse>>(`/api/agent/bookings/${orderId}`, authOptions())

  const result = {
    commissionAmount: response.result.commissionAmount,
    isGrossPayment: response.result.isGrossPayment ?? false,
  }

  return result
}
