import React, { ComponentProps, forwardRef, memo } from 'react'
import styled from 'styled-components'
import _DropdownFooter from '../_DropdownFooter'
import TextButton from 'components/Luxkit/Button/TextButton'
import { rem } from 'polished'
import Group from 'components/utils/Group'
import shallowEqualWithNestedProps from 'lib/memoize/shallowEqualWithNestedProps'

const Container = styled(_DropdownFooter)`
  padding: ${rem(16)};
`

interface PrimaryActionProps extends Omit<ComponentProps<typeof TextButton>, 'kind' | 'variant'> {}
interface SecondaryActionProps extends Omit<ComponentProps<typeof TextButton>, 'kind' | 'variant'> {
  kind: 'secondary' | 'tertiary'
}

interface Props {
  primaryActionProps?: PrimaryActionProps
  isPrimaryActionHidden?: boolean
  secondaryActionProps?: SecondaryActionProps
  isSecondaryActionHidden?: boolean
  /**
   * @default default
   */
  actionsLayout?: 'default' | 'stacked'
}

const DropdownListFooter = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    primaryActionProps,
    isPrimaryActionHidden,
    secondaryActionProps,
    isSecondaryActionHidden,
    actionsLayout = 'default',
  } = props

  const hasPrimaryAction = !isPrimaryActionHidden && !!primaryActionProps?.children
  const hasSecondaryAction = !isSecondaryActionHidden && !!secondaryActionProps?.children

  return <Container ref={ref}>
    <Group
      direction={actionsLayout === 'stacked' ? 'vertical' : 'horizontal'}
      horizontalAlign={actionsLayout === 'stacked' ? 'stretch' : 'end'}
      verticalAlign="end"
      gap={8}
      wrap="wrap"
    >
      {hasSecondaryAction && <TextButton
        {...secondaryActionProps}
        variant="default"
        size="medium"
      />}
      {hasPrimaryAction && <TextButton
        {...primaryActionProps}
        kind="primary"
        variant="default"
        size="medium"
      />}
    </Group>
  </Container>
})

export default memo(DropdownListFooter, shallowEqualWithNestedProps(['primaryActionProps', 'secondaryActionProps']))
