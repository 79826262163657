import { AppStore } from 'src/client/store'
import config from 'constants/config'
import { me } from 'api/auth'
import { USER_LOGIN } from 'actions/apiActionConstants'
import { API_CALL_SUCCESS, ACCOUNT_INIT_COMPLETE } from 'actions/actionConstants'
import { selectLoggedIn } from 'selectors/accountSelectors'
import isBot from 'lib/web/isBot'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'

function initialiseUser(store: AppStore) {
  const state = store.getState()
  const loggedIn = selectLoggedIn(state)

  if (!config.ALL_SINGLE_DOMAIN && !loggedIn && !isBot) {
    // There's a known issue where we should be logged in but the token didn't make it through
    // to the front end server for SSR. This happens when the front end server and api are on different domains
    // e.g. we're coming from an api/redirect. The auth token cookie is set on the API domain, not the front end domain
    // This means that we need to manually fetch our account details with *our* cookie (servers didn't work)
    // as the browser will carry our cookie to the api domain request
    // This mostly affects mobile apps, local development and local e2e running
    me(undefined, state.geo.currentRegionCode, isLuxPlusEnabled(state)).then(account => {
      store.dispatch({
        type: API_CALL_SUCCESS,
        api: USER_LOGIN,
        data: {
          account,
        },
      })
    }).catch(() => {
      // tell the store that we have finished user initialisation
      // the typically api flow with API_CALL_FAILURE isn't used to prevent logging errors
      store.dispatch({
        type: ACCOUNT_INIT_COMPLETE,
      })
      // nothing, ignore, guess we didn't have a cookie sitting around
    })
  } else if (loggedIn) {
    store.dispatch({
      type: API_CALL_SUCCESS,
      api: USER_LOGIN,
      data: {
        accessToken: state.auth.accessToken,
        account: state.auth.account,
      },
    })
  } else if (!loggedIn && !isBot) {
    // handle the case in prod where we know the user has no auth cookie and we don't need to check /me
    store.dispatch({
      type: ACCOUNT_INIT_COMPLETE,
    })
  }
}

export default initialiseUser
