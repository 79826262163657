import React, { useMemo } from 'react'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import styled from 'styled-components'
import { rem } from 'polished'
import ResponsiveImage from 'components/Common/ResponsiveImage/ResponsiveImage'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { isEmpty } from 'lib/array/arrayUtils'

interface Props {
  host: App.Tours.Host;
}

const ModalContainer = styled(ModalContent)`
  padding-top: ${rem(20)};
  ${mediaQueryUp.tablet} {
    padding-top: ${rem(24)};
  }
`

function HostDetailModal(props: Props) {
  const { svcImageId, name, description } = props.host

  const { title, descriptions } = useMemo(() => {
    const [title, ...content] = description.split('\n')
    return { title, descriptions: content.filter(content => !isEmpty(content)) }
  }, [description])

  return <ModalBase size="S">
    <ModalHeader title={name} />
    <ModalBody>
      <ResponsiveImage
        id={svcImageId}
        tabletWidth="560px"
        mobileWidth="100%"
        alt={name}
        quality="good"
      />
      <ModalContainer>
        <VerticalSpacer gap={4}>
          <MarkdownRender content={title} fontSize="medium"/>
          {descriptions.map((description, index) =>
            <MarkdownRender key={index} content={description} fontSize="medium"/>,
          )}
        </VerticalSpacer>
      </ModalContainer>
    </ModalBody>
  </ModalBase>
}

export default HostDetailModal
