export function getAgentHubExperienceType(experience: App.ExperienceOffer): App.ProductType {
  switch (experience.productType) {
    case 'transfer':
      return 'transfer'
    default:
      return 'experience'
  }
}

export function getAgentHubCommissionItemKey(item: App.Checkout.AnyItem | App.Checkout.AnyItemBreakdownView): string {
  const transactionKey = item.transactionKey

  if ('experienceId' in item && item.itemType === 'experience') {
    return transactionKey + '-' + item.experienceId
  }
  if ('offerId' in item) {
    return transactionKey + '-' + item.offerId
  }
  return transactionKey!
}
