export const PARTNER_BRANDS_ENABLED_REGIONS = ['AU']

export const REQUIRE_POSTCODE_REGIONS = ['AU']

export const PARTNERSHIP_LINK_ACCOUNT_REGIONS = ['AU']

export const USER_REVIEWS_PROMO_CODE_REGIONS = ['AU']

export const ULTRA_LUX_CONTRACT_REGIONS = ['AU']

export const TIMELY_ENABLED_REGIONS = ['AU']

export const MERCHANT_FEE_ENABLED_REGIONS = ['AU']

export const USE_MID_VALUE_CONTACT_TYPE_REGIONS = ['AU']

export const PRIORITY_SUPPORT_NUMBER_REGIONS = ['AU']

export const VELOCITY_ENABLED_REGIONS = ['AU']

export const PAYMENT_SCHEDULE_ENABLED_REGIONS = ['AU']

export const HOMEPAGE_FLIGHT_DEALS_REGIONS = ['AU']

export const HOMEPAGE_EUROPE_INSPIRATION_BANNERS_REGIONS = ['GB', 'FR', 'IE', 'NL', 'DE', 'IT', 'ES']

export const LETV_SUPPORT_REGIONS = ['AU']

export const TRANSFERS_USP_REGIONS = ['AU']

export const TOUR_ONLY_REGIONS = ['AU']

export const TOUR_V2_PRIVATE_REQUEST_EXCLUDED_REGIONS = ['IN']

export const TOUR_POST_PRE_ACCOMMODATIONS_EXCLUDE_COUNTRY_CODES = ['JP']

export const NO_24_7_SUPPORT_REGIONS = ['IN']

export const ATOL_REGIONS = ['GB']

export const PRODUCT_REVIEW_REGIONS = ['AU']

export const TWELVE_HOUR_FORMAT_REGIONS = new Set(['IN', 'PH', 'MY', 'SA', 'IE', 'CA', 'US', 'AU', 'NZ'])

export const STRIPE_POSTCODE_REGIONS = ['US']

export const PARTNERSHIP_NON_VIRGIN_REGIONS = ['AU', 'GB', 'US']

export const EUROPE_REGIONS = new Set(['GB', 'FR', 'DE', 'IE', 'IT', 'NL', 'ES'])

export const CRUISE_SEARCH_DEPARTURE_REGIONS: Array<App.CruiseDepartureRegion> = [
  {
    name: 'Australia & New Zealand',
    identifier: 'australia-new-zealand',
    countries: ['Australia', 'New Zealand'],
    countriesCodes: ['AU', 'NZ'],
    placeIds: 'le_9fc3d7152ba9336a670e36d0ed79bc43,le_d3d9446802a44259755d38e6d163e820',
  },
  {
    name: 'Europe',
    identifier: 'europe',
    countries: ['Europe', 'Northern Europe', 'Austria', 'Belgium', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Iceland', 'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 'Switzerland', 'United Kingdom'],
    countriesCodes: ['AT', 'BE', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'NO', 'PL', 'PT', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB'],
    placeIds: 'le_12767faf1bf8cafc7594430586c558c2',
  },
  {
    name: 'North America',
    identifier: 'north-america',
    countries: ['Hawaii', 'Alaska', 'Canada', 'United States of America', 'Mexico'],
    countriesCodes: ['US', 'CA', 'MX'],
    placeIds: 'le_cf874aad79e14b401a4c86954a596fa5',
  },
  {
    name: 'Latin America & Caribbean',
    identifier: 'latin-america-caribbean',
    countries: ['Caribbean', 'South America', 'Argentina', 'Bahamas', 'Barbados', 'Belize', 'Bermuda', 'Brazil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 'Dominican Republic', 'Ecuador', 'Guatemala', 'Honduras', 'Jamaica', 'Nicaragua', 'Panama', 'Peru', 'Puerto Rico', 'Trinidad and Tobago', 'Uruguay', 'Venezuela'],
    countriesCodes: ['AR', 'BS', 'BB', 'BZ', 'BM', 'BR', 'CL', 'CO', 'CR', 'CU', 'DO', 'EC', 'GT', 'HN', 'JM', 'NI', 'PA', 'PE', 'PR', 'TT', 'UY', 'VE'],
    placeIds: 'le_ded8e3f6fa8da10375ce4f89e1124893,le_d7bce4d8dbac13945471c343c4973ba3',
  },
  {
    name: 'Asia & the Pacific',
    identifier: 'asia-pacific',
    countries: ['Mediterranean', 'Asia', 'China', 'Hong Kong', 'India', 'Indonesia', 'Japan', 'Malaysia', 'Philippines', 'Singapore', 'South Korea', 'Taiwan', 'Thailand', 'Vietnam'],
    countriesCodes: ['CN', 'HK', 'IN', 'ID', 'JP', 'MY', 'PH', 'SG', 'KR', 'TW', 'TH', 'VN'],
    placeIds: 'le_fdbf7de11c1a054dc927adf66d9b5fce,le_a8697c7e6e7988b29a9de094b8d554cc',
  },
]

export const CAR_HIRE_ENHANCED_PROTECTION_REGIONS = ['US']

export const CAR_HIRE_MOCKED_TOP_LOCATION_COUNTRIES = [
  'Australia',
  'United States',
  'New Zealand',
  'United Kingdom',
  'Ireland',
  'Canada',
  'Mexico',
  'Austria',
  'Israel',
  'France',
  'Singapore',
  'Italy',
  'Spain',
  'Norway',
  'Germany',
  'Japan',
  'Finland',
  'Greece',
  'Denmark',
]

export const ASIAN_COUNTRIES = ['SG', 'HK', 'IN', 'MY', 'TH', 'ID', 'PH', 'VN', 'CN', 'JP', 'KR', 'TW', 'MO', 'AE', 'SA', 'IL', 'QA']

export const NAVBAR_SPECIFIC_ORDER_REGIONS_LIST = new Set(['AU', 'NZ', 'GB', 'US', 'SG', 'IN'])

export const REGION_DROPDOWN_START_ORDER = ['AU', 'US', 'GB', 'NZ', 'SG']

export const LAST_MINUTE_REGIONS_LIST = ['AU', 'NZ']

export const CRUISE_AVAILABLE_REGIONS = new Set(['AU', 'NZ'])

export const CONTACT_GENERAL_REGIONS_EXCLUDE_LIST = ['AU', 'US', 'GB', 'NZ']

export const US_AMOUNT_REGIONS_LIST = ['US', 'UK']

export const INSURANCE_VALID_COUNTRIES = ['NZ', 'AU', 'SG', 'MY', 'TH', 'IN', 'HK']

export const TRAVEL_PROTECTION_VALID_COUNTRIES = ['AU', 'NZ']

export const AGENT_HUB_DEFAULT_PHONE_NUMBER_PREFIX: Record<string, string> = {
  AU: '+61',
  CA: '+1',
  CN: '+86',
  FR: '+33',
  DE: '+49',
  IN: '+91',
  ID: '+62',
  IE: '+353',
  IL: '+972',
  IT: '+39',
  JP: '+81',
  KR: '+82',
  MO: '+853',
  MY: '+60',
  NL: '+31',
  NZ: '+64',
  PH: '+63',
  QA: '+974',
  RU: '+7',
  SA: '+966',
  SG: '+65',
  ZA: '+27',
  ES: '+34',
  TW: '+886',
  TH: '+66',
  AE: '+971',
  GB: '+44',
  US: '+1',
  VN: '+84',
}

export const ISO2_START_ORDER = ['au', 'us', 'gb', 'nz', 'sg']

export const RESUBSCRIBE_PROMO_CODE_REGIONS:Array<string> = []

type DestinationType = {
  [destinationName: string]: {
    displayName: string;
    urlName?: string;
    destinationId: string;
  }
}

export const DESTINATIONS: DestinationType = {
  africa: { displayName: 'Africa', destinationId: 'le_b02e6098244d867ef9e033afaf097d81' },
  antigua: { displayName: 'Antigua and Barbuda', destinationId: 'le_b599e8250e4481aaa405a715419c8179' },
  australia: { displayName: 'Australia', destinationId: 'le_d3d9446802a44259755d38e6d163e820' },
  bali: { displayName: 'Bali', destinationId: 'le_64930b7151b6bc3e289f37d5da62ac9f' },
  bangkok: { displayName: 'Bangkok', destinationId: 'le_ff7aa1f9b4062bfee0f99a0453506f1b' },
  brisbane: { displayName: 'Brisbane', destinationId: 'le_b96d145f6c31e35755f4a6841574ba37' },
  california: { displayName: 'California', destinationId: 'le_7eabe3a1649ffa2b3ff8c02ebfd5659f' },
  china: { displayName: 'China', destinationId: 'le_a5bfc9e07964f8dddeb95fc584cd965d' },
  cook_islands: { displayName: 'Cook Islands', destinationId: 'le_3416a75f4cea9109507cacd8e2f2aefc' },
  dubai: { displayName: 'Dubai', destinationId: 'le_43baa6762fa81bb43b39c62553b2970d' },
  egypt: { displayName: 'Egypt', destinationId: 'le_9a1158154dfa42caddbd0694a4e9bdc8' },
  europe: { displayName: 'Europe', destinationId: 'le_12767faf1bf8cafc7594430586c558c2' },
  fiji: { displayName: 'Fiji', destinationId: 'le_72b32a1f754ba1c09b3695e0cb6cde7f' },
  france: { displayName: 'France', destinationId: 'le_093f65e080a295f8076b1c5722a46aa2' },
  florida: { displayName: 'Florida', destinationId: 'le_eb163727917cbba1eea208541a643e74' },
  gold_coast: { displayName: 'Gold Coast', destinationId: 'le_bac732346917b0a1231354ef55faf00a' },
  greece: { displayName: 'Greece', destinationId: 'le_735b90b4568125ed6c3f678819b6e058' },
  hawaii: { displayName: 'Hawaii', destinationId: 'le_979d472a84804b9f647bc185a877a8b5' },
  india: { displayName: 'India', destinationId: 'le_f033ab37c30201f73f142449d037028d' },
  italy: { displayName: 'Italy', destinationId: 'le_93db85ed909c13838ff95ccfa94cebd9' },
  japan: { displayName: 'Japan', destinationId: 'le_7647966b7343c29048673252e490f736' },
  jordan: { displayName: 'Jordan', destinationId: 'le_8613985ec49eb8f757ae6439e879bb2a' },
  khao_lak: { displayName: 'Khao Lak', destinationId: 'le_f3ad1c493ba61ad63bb43d6947f42d95' },
  maldives: { displayName: 'Maldives', destinationId: 'le_2723d092b63885e0d7c260cc007e8b9d' },
  malaysia: { displayName: 'Malaysia', destinationId: 'le_a3c65c2974270fd093ee8a9bf8ae7d0b' },
  mauritius: { displayName: 'Mauritius', destinationId: 'le_2b44928ae11fb9384c4cf38708677c48' },
  mediterranean: { displayName: 'Mediterranean', destinationId: 'le_93db85ed909c13838ff95ccfa94cebd9' },
  melbourne: { displayName: 'Melbourne', destinationId: 'le_15fa44474c92da27cf2125373356db4c' },
  mexico: { displayName: 'Mexico', destinationId: 'le_eb160de1de89d9058fcb0b968dbbbd68' },
  morocco: { displayName: 'Morocco', destinationId: 'le_a0a080f42e6f13b3a2df133f073095dd' },
  new_zealand: { displayName: 'New Zealand', destinationId: 'le_9fc3d7152ba9336a670e36d0ed79bc43' },
  netherlands: { displayName: 'Netherlands', destinationId: 'le_d1f491a404d6854880943e5c3cd9ca25' },
  phuket: { displayName: 'Phuket', destinationId: 'le_d47bb339212ddfab0fadd99d492a5fc3' },
  queensland: { displayName: 'Queensland', destinationId: 'le_3d3621cee6f49e93a34f3f0f654ab41a' },
  samoa: { displayName: 'Samoa', destinationId: 'le_2a79ea27c279e471f4d180b08d62b00a' },
  singapore: { displayName: 'Singapore', destinationId: 'le_bd4c9ab730f5513206b999ec0d90d1fb' },
  south_africa: { displayName: 'South Africa', destinationId: 'le_9766527f2b5d3e95d4a733fcfb77bd7e' },
  south_america: { displayName: 'South America', destinationId: 'le_9047dd7f7766d8b99048e7f52f9abb2b' },
  south_korea: { displayName: 'South Korea', destinationId: 'le_f4b9ec30ad9f68f89b29639786cb62ef' },
  south_pacific: { displayName: 'South Pacific', destinationId: 'le_a8697c7e6e7988b29a9de094b8d554cc' },
  spain: { displayName: 'Spain', destinationId: 'le_7e7757b1e12abcb736ab9a754ffb617a' },
  sri_lanka: { displayName: 'Sri Lanka', destinationId: 'le_5878a7ab84fb43402106c575658472fa' },
  sydney: { displayName: 'Sydney', destinationId: 'le_678c61ac5b4f91ca86e112fc50e63766' },
  thailand: { displayName: 'Thailand', destinationId: 'le_8f85517967795eeef66c225f7883bdcb' },
  turkey: { displayName: 'Turkey', urlName: 'Türkiye (Turkey)', destinationId: 'le_cedebb6e872f539bef8c3f919874e9d7' },
  uae: { displayName: 'United Arab Emirates', destinationId: 'le_a2557a7b2e94197ff767970b67041697' },
  uk: { displayName: 'UK', urlName: 'United Kingdom', destinationId: 'le_cfecdb276f634854f3ef915e2e980c31' },
  usa: { displayName: 'USA', urlName: 'United States of America', destinationId: 'le_757b505cfd34c64c85ca5b5690ee5293' },
  vietnam: { displayName: 'Vietnam', destinationId: 'le_a597e50502f5ff68e3e25b9114205d4a' },
}
