import React, { useCallback, useEffect, useRef, useState } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import * as Analytics from 'analytics/analytics'
import useClickOutside from 'hooks/useClickOutside'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import TextButton from 'components/Luxkit/Button/TextButton'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import BodyText from 'components/Luxkit/Typography/BodyText'
import StickyPrompt from 'components/Common/StickyPromptCard/StickyPrompt'
import EnablePushNotificationsPromptIcon from './EnablePushNotificationsPromptIcon'
import { requestBrowserPushPermission } from 'api/braze'
import { USER_PUSH_NOTIFICATIONS_DISMISSED_KEY } from 'constants/auth'
import { OptimizelyExperiments } from 'constants/optimizely'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import {
  set as setLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'

const PushNotificationsPrompt = styled(StickyPrompt)`
  padding: ${rem(24)};

  ${mediaQueryUp.tablet} {
    width: ${rem(360)};
  }
`

const NATIVE_PROMPT_VARIANT_NAME = 'variant_2'

const variantsMap: Record<string, { title: string, description: string } | undefined> = {
  control: {
    title: 'Enable notifications to get the latest updates, special offers, and more',
    description: 'You can disable these anytime from your browser settings.',
  },
  variant_1: {
    title: 'Unlock exclusive offers',
    description: 'Enable notifications and be the first in line for our limited time offers.',
  },
  [NATIVE_PROMPT_VARIANT_NAME]: undefined,
}

function EnablePushNotificationsPrompt() {
  const promptRef = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState<boolean>(true)
  const isMobileDevice = useIsMobileDevice()
  const croWebPushNotificationTriggerVariant = useOptimizelyExperiment(OptimizelyExperiments.croWebPushNotificationTrigger)
  const variantData = croWebPushNotificationTriggerVariant in variantsMap ?
    variantsMap[croWebPushNotificationTriggerVariant] :
    variantsMap.control

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'push_primer',
      action: 'impression',
      category: 'web_push_notifications',
      type: 'nonInteraction',
    })
  }, [])

  useEffect(() => {
    if (croWebPushNotificationTriggerVariant === NATIVE_PROMPT_VARIANT_NAME) {
      requestBrowserPushPermission()
      setShow(false)
    }
  }, [croWebPushNotificationTriggerVariant])

  const dismissPrompt = useCallback(() => {
    setLocalStorage(USER_PUSH_NOTIFICATIONS_DISMISSED_KEY, 1)
    setShow(false)
  }, [])

  const onEnablePushNotifications = useCallback(() => {
    requestBrowserPushPermission()
    setShow(false)
  }, [])

  useClickOutside(promptRef, dismissPrompt, show)

  if (isMobileDevice || croWebPushNotificationTriggerVariant === NATIVE_PROMPT_VARIANT_NAME) {
    return null
  }

  return <PushNotificationsPrompt promptPosition="left" show={show} ref={promptRef}>
    <Group direction="vertical" horizontalAlign="center" gap={16}>
      <Group direction="horizontal" gap={12}>
        <EnablePushNotificationsPromptIcon />
        <Group direction="vertical" gap={4}>
          <Heading variant="heading6">{variantData?.title}</Heading>
          <BodyText variant="medium" colour="neutral-two">{variantData?.description}</BodyText>
        </Group>
      </Group>
      <Group direction="horizontal" gap={12}>
        <TextButton size="large" kind="tertiary" onClick={dismissPrompt}>
          Maybe later
        </TextButton>
        <TextButton size="large" kind="primary" onClick={onEnablePushNotifications}>
          Yes, notify me
        </TextButton>
      </Group>
    </Group>
  </PushNotificationsPrompt>
}

export default React.memo(EnablePushNotificationsPrompt)
