import getLuxLoyaltyDefaultTier from 'luxLoyalty/selectors/getLuxLoyaltyDefaultTier'

/**
 * @returns the tier to display to the user
 * - If the user has an account we should display their current tier
 * - If the user does not have an account we should display their eligible tier fetched from the service
 * - Fallback to the default tier if necessary
 */
function getLuxLoyaltyDisplayTier(state: App.State): App.LuxLoyaltyTier {
  const accountTier = state.luxLoyalty.account.data?.tier

  return accountTier ?? getLuxLoyaltyDefaultTier()
}

export default getLuxLoyaltyDisplayTier
