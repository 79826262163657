import getItemViewTotalsPrice from 'checkout/lib/utils/getItemViewTotalsPrice'
import getItemViewAppliedLoyaltyDiscount from 'checkout/lib/utils/luxLoyalty/getItemViewAppliedLoyaltyDiscount'
import getItemViewAppliedLoyaltyUpgrade from 'checkout/lib/utils/luxLoyalty/getItemViewAppliedLoyaltyUpgrade'
import { EmptyMap } from 'lib/array/arrayUtils'
import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getAllItemViews from '../view/getAllItemViews'
import { checkoutWithMemberPrice, selectShouldUseBookingProtectionMemberPrice, selectShouldUseInsuranceMemberPrice } from '../view/luxPlusSubscription'

interface TotalsPricing {
  price: number
  loyaltyUpgradeTargetPrice: number
}

const getCheckoutLuxLoyaltyItemsPriceInCurrency = createSelector(
  (state: App.State) => getAllItemViews(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => selectShouldUseInsuranceMemberPrice(state),
  (state: App.State) => selectShouldUseBookingProtectionMemberPrice(state),
  (
    allItemViews,
    canCheckoutWithMemberPrice,
    shouldUseInsuranceMemberPrice,
    shouldUseBookingProtectionMemberPrice,
  ): Map<string, TotalsPricing> => {
    if (!allItemViews.hasRequiredData) return EmptyMap

    const priceByItem: Map<string, TotalsPricing> = new Map()

    allItemViews.data.forEach((itemView) => {
      const view = itemView.data

      if (view.luxLoyaltyProductType) {
        let priceInCurrency: number = 0
        let upgradePrice: number = 0

        switch (view.luxLoyaltyProductType) {
          case 'insurance':
            const insuranceTotal = getItemViewTotalsPrice(view.totals, shouldUseInsuranceMemberPrice)
            const loyaltyInsuranceDiscount = getItemViewAppliedLoyaltyDiscount(view)
            priceInCurrency = insuranceTotal
            upgradePrice = insuranceTotal + loyaltyInsuranceDiscount
            break
          case 'cfmr':
            const cfmrTotal = getItemViewTotalsPrice(view.totals, shouldUseBookingProtectionMemberPrice)
            const loyaltyCfmrDiscount = getItemViewAppliedLoyaltyDiscount(view)
            priceInCurrency = cfmrTotal
            upgradePrice = cfmrTotal + loyaltyCfmrDiscount
            break
          default:
            priceInCurrency = getItemViewTotalsPrice(view.totals, canCheckoutWithMemberPrice)
            const appliedLoyaltyUpgrade = getItemViewAppliedLoyaltyUpgrade(view)
            if (appliedLoyaltyUpgrade) {
              upgradePrice = getItemViewTotalsPrice(appliedLoyaltyUpgrade.targetTotals, canCheckoutWithMemberPrice)
            }
            break
        }

        priceByItem.set(view.item.itemId, {
          price: floatify(priceInCurrency),
          loyaltyUpgradeTargetPrice: floatify(upgradePrice),
        })
      }
    })

    if (!priceByItem.size) return EmptyMap

    return priceByItem
  },
)

export default getCheckoutLuxLoyaltyItemsPriceInCurrency
