import request, { authOptions } from 'api/requestUtils'
import { definitions } from '@luxuryescapes/contract-svc-agent'
import { agentHubAccountMapper } from './mappers/agentHubMap'

type SetAgencyDetailsRequest = definitions['userAgencyRequest']
type SetAgencyDetailsResponse = definitions['userWithAgencyResponse']

export interface SetAgencyDetailsParams {
  name: string;
  businessCodeValue: string
  streetAddress: string;
  city: string;
  stateOrTerritory: string;
  postcode: string;
  country: string;
  affiliation: string;
  agentId: string;
  regionCode: App.AgentHubEnabledRegions;
}

async function requestPostSetAgencyDetails(agentId: string, agentData: SetAgencyDetailsParams) {
  const requestBody: SetAgencyDetailsRequest = {
    name: agentData.name,
    businessCodeValue: agentData.businessCodeValue,
    streetAddress: agentData.streetAddress,
    city: agentData.city,
    stateOrTerritory: agentData.stateOrTerritory,
    postcode: agentData.postcode,
    country: agentData.country,
    affiliation: agentData.affiliation,
    regionCode: agentData.regionCode,
  }

  const response = await request.post<App.ApiResponse<SetAgencyDetailsResponse>, any>(
    `/api/agent/user/${agentId}/agency`,
    requestBody,
    authOptions(),
  )

  return agentHubAccountMapper(response.result)
}

export default requestPostSetAgencyDetails
