import CruisePriceFilter
, {
  CruisePriceFilterChangeProps,
} from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseSearchExtraFilter/CruisePriceFilter'
import Modal from 'components/Luxkit/Modal/Modal'
import React, { useCallback } from 'react'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import {
  CruiseSearchPriceChipProps,
} from 'components/Cruises/SearchPage/Filters/Inputs/CruiseSearchMobileChips/CruiseSearchPriceChip'

function ModalCruiseSearchPriceChip(props: CruiseSearchPriceChipProps) {
  const {
    listFilters,
    facets,
    filterForm,
    setFilterForm,
    fetching,
    drawerMode,
  } = props
  const modalElement = useModalElementContext<boolean>()

  const closeModal = useCallback(() => {
    modalElement.resolve(true)
  }, [modalElement])

  const resetFilter = useCallback(() => {
    setFilterForm({ ...filterForm, ...{ minPrice: undefined, maxPrice: undefined } })
    closeModal()
  }, [closeModal, filterForm, setFilterForm])

  const updateForm = useCallback((newFormPrice: CruisePriceFilterChangeProps) => {
    if (newFormPrice && Object.keys(newFormPrice).length > 0) {
      setFilterForm({ ...filterForm, ...newFormPrice })
    } else {
      setFilterForm({ ...filterForm, minPrice: undefined, maxPrice: undefined })
    }
  }, [filterForm, setFilterForm])

  return <Modal
    mode={drawerMode ? 'drawer' : 'default'}
    title="Price"
    primaryActionText="Apply"
    onPrimaryActionClick={closeModal}
    secondaryActionText="Reset all"
    onSecondaryActionClick={resetFilter}
  >
    <CruisePriceFilter
      facets={facets}
      filters={listFilters}
      onChange={updateForm}
      fetching={fetching}
    >
    </CruisePriceFilter>
  </Modal>
}

export default ModalCruiseSearchPriceChip
