import { rem } from 'polished'
import cn from 'clsx'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import variables from 'styles/vendor/bootstrap/variables'

// Items in carousels often have things like shadows/extra borders
// because our container is overflow hidden, we accidentally cut these off too
// so we add padding + equal negative margin to allow space for these vertical overflows
// but make sure it doesn't take up any actual space
// 32 is the size of our largest shadow
const VerticalGutterSpace = rem(8)

const CarouselTrack = styled.div`
  position: relative;

  ${mediaQueryUp.desktop} {
    &.gutter-style-default {
      overflow: hidden;
      padding: ${VerticalGutterSpace} 0;
      margin: -${VerticalGutterSpace} 0;
    }
  }
`

const CarouselGrid = styled.div`
  display: grid;
  gap: var(--carousel-gap);
  grid-auto-flow: column;
  overflow-x: auto;
  scrollbar-width: none;
  padding: ${VerticalGutterSpace} var(--carousel-gutter);
  margin: -${VerticalGutterSpace} calc(var(--carousel-gutter) * -1);
  scroll-padding-inline: var(--carousel-gutter);
  grid-auto-columns: var(--carousel-width);

  &::-webkit-scrollbar {
    display: none;
  }

  &.align-center {
    max-width: fit-content;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  ${mediaQueryUp.tablet} {
    grid-auto-columns: var(--carousel-tablet-width);
    padding: ${VerticalGutterSpace} var(--carousel-desktop-gutter);
    margin: -${VerticalGutterSpace} calc(var(--carousel-desktop-gutter) * -1);
    scroll-padding-inline: var(--carousel-desktop-gutter);
  }

  ${mediaQueryUp.desktop} {
    &.gutter-style-default {
      padding: ${VerticalGutterSpace} 0;
      margin: -${VerticalGutterSpace} 0;
      scroll-padding-inline: 0;
    }

    &.paged {
      gap: 0;
      grid-auto-columns: calc(100% / var(--carousel-items));
      grid-template-columns: repeat(var(--carousel-items), calc(100% / var(--carousel-items)));
      margin-left: calc(var(--carousel-gap) * -1);

      > * {
        margin-left:  var(--carousel-gap);
      }
    }

    &:not(.paged) {
      grid-auto-columns: var(--carousel-desktop-width);
    }
  }

  &.snap {
    scroll-snap-type: x mandatory;

    &-center {
      > * {
        scroll-snap-align: center;
      }
    }

    &-start {
      > * {
        scroll-snap-align: start;
      }
    }

    &-end {
      > * {
        scroll-snap-align: end;
      }
    }
  }
`

interface Props extends React.PropsWithChildren {
  className?: string;
  /**
   * Enforces the number of elements visible at once at desktop screen widths
   * At lower screen widths it will always use the width prop or intrinsic width of the children
   */
  pageSize?: number;
  /**
   * The width of each individual element at mobile sizes and a fallback
   * if page size is not specified
   */
  width?: string;
    /**
   * The width of each individual element at tablet sizes and a fallback
   * if page size is not specified
   */
  tabletWidth?: string;
  /**
   * The width of each individual element at desktop sizes
   * Will prefer 'pageSize' if set
   */
  desktopWidth?: string;
  /**
   * The way gutters are handled
   *
   * 'default' will only 'overflow' at tablet and lower sizes
   *
   * 'overflow' will always overflow, i.e. poke past the gutter
   */
  gutterStyle?: 'overflow' | 'default';
  /**
   * The size of the "gutter" on the left/right hand side of the carousel
   * This is used at small widths to have the carousel overflow the gutter
   * and look like it scrolls off the page
   *
   * @default 16px
   * */
  gutterSize?: number | string;
  /**
   * Overrides the `gutterSize` prop for size `sm` and up
   * */
  desktopGutterSize?: number | string;
  /**
   * The gap size between each element in pixels
   *
   * @default 20
   */
  gap?: number;
  alignment?: 'center';
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  /**
   * Snaps the main element in the the carousel to the position specified
   * This will trigger mandatory snap behaviour and thus always be applied
   */
  snap?: 'center' | 'start' | 'end';
  ref?: React.Ref<HTMLDivElement>;
}

const layoutContainerGutter = variables.gutterWidth / 2

export const DEFAULT_CAROUSEL_GAP = 20

function cssSize(size: string | number | undefined) {
  return typeof size === 'number' ? rem(size) : size
}

/**
 * A base 'carousel' component. This provides the track and grid only along with sizing of elements behaviour
 * The specialised carousels can be found in this directory, e.g. CardCarousel and ImageCarousel.
 */
function Carousel(props: Props) {
  const {
    children,
    pageSize,
    width,
    tabletWidth,
    desktopWidth,
    gutterSize = layoutContainerGutter,
    desktopGutterSize,
    gap = DEFAULT_CAROUSEL_GAP,
    onScroll,
    className,
    alignment,
    gutterStyle = 'default',
    snap,
    ref,
  } = props

  return <CarouselTrack className={cn(
    `gutter-style-${gutterStyle}`,
    className,
  )}
  >
    <CarouselGrid
      ref={ref}
      onScroll={onScroll}
      style={{
        '--carousel-gutter': cssSize(gutterSize),
        '--carousel-desktop-gutter': cssSize(desktopGutterSize) ?? cssSize(gutterSize),
        '--carousel-items': pageSize,
        '--carousel-width': width || 'min-content',
        '--carousel-tablet-width': tabletWidth || width || 'min-content',
        '--carousel-desktop-width': desktopWidth || tabletWidth || width || 'min-content',
        '--carousel-gap': rem(gap),
      } as CSSProperties}
      className={cn(
        snap ? `snap-${snap}` : undefined,
        alignment ? `align-${alignment}` : undefined,
        `gutter-style-${gutterStyle}`,
        {
          paged: !!pageSize,
          snap,
        })}
    >
      {children}
    </CarouselGrid>
  </CarouselTrack>
}

export default Carousel
