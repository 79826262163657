import React, { useMemo } from 'react'
import FilterPanelCheckboxGroup from 'components/Common/FilterPanel/FilterPanelCheckboxGroup'
import { getCruiseLuxPlusItems } from 'lib/cruises/cruiseUtils'
import { buildPanelCheckItems } from 'components/Cruises/SearchPage/Filters/Inputs/CruiseExtraFiltersInputs'

interface Props {
  isLuxPlus?: Set<string>;
  onChange: (nextFilters: Record<string, any>) => void;
  facets: Array<App.CruiseSearchFacet>;
}

function CruiseLuxPlusFilter({
  isLuxPlus,
  onChange,
  facets,
}: Props) {
  const luxPlusItems = useMemo(() => {
    const luxPlusFacets = getCruiseLuxPlusItems(facets)
    return buildPanelCheckItems(luxPlusFacets)
  }, [facets])

  return <FilterPanelCheckboxGroup
    name="isLuxPlus"
    items={luxPlusItems}
    onChange={onChange}
    defaultSelected={isLuxPlus}
  />
}

export default CruiseLuxPlusFilter
