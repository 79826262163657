import { createSelector } from 'reselect'
import { canAddInsuranceToOrder, haveOrdersBeenFetched, isItemCancelled, sortOrdersByDeparture } from 'lib/order/orderUtils'
import isUpcoming, { isExperienceItemUpcoming, isUpcomingForPartnerVelocityEarnEligibility } from 'lib/order/isUpcoming'
import { isEmpty, last, nonNullable, groupBy } from 'lib/array/arrayUtils'
import { isOrderUpsellable } from 'actions/UpsellAndAvailableCreditSnackbar/utils'
import config from 'constants/config'
import { VIRGIN_VELOCITY_END_DATE, VIRGIN_VELOCITY_START_DATE } from 'constants/partnerships'
import { VELOCITY_ENABLED_REGIONS } from 'constants/config/region'
import { isBedbankType } from 'lib/offer/offerTypes'
import { capitaliseFirstLetterOnly } from 'lib/string/stringUtils'

export const getOrdersThatCanHaveInsuranceAdded = createSelector(
  (state: App.State) => state.orders.orders,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.offer.offers,
  (orders, regionCode, offers) => {
    return Object.values(orders).filter(order => {
      const offer = offers[order.items[0]?.offerId]
      return canAddInsuranceToOrder(order, regionCode, offer)
    })
  },
)

export const getOrderOffers = createSelector(
  (state: App.State) => state.offer.offers,
  (state: App.State, offerIds: Array<string>) => offerIds,
  (offers: Record<string, App.Offer>, offerIds: Array<string>) => {
    return nonNullable(offerIds.map(offerId => offers[offerId]))
  },
)

export const getUpcomingOrders = createSelector(
  (state: App.State) => state.orders.orders,
  (orders) => Object.values(orders).filter(isUpcoming),
)

export const getOrderBookingNumbers = createSelector(
  (state: App.State) => state.orders.orders,
  (orders) => {
    return Object.values(orders).flatMap(({ id, bookingNumber, items, bedbankItems, tourItems, experienceItems, carHireItems, flightItems }) => {
      let locationSubheading = items[0]?.offer?.property?.locationSubheading
      if (isBedbankType(bedbankItems[0]?.offer?.type)) {
        locationSubheading = bedbankItems[0]?.offer?.property?.location
      }
      const itemObjects = items.map(item => ({
        id,
        startDate: item.reservation?.startDate,
        endDate: item.reservation?.endDate,
        bookingNumber: item.bookingNumber,
        latitude: item?.offer.property?.latitude,
        longitude: item?.offer.property?.longitude,
        locationSubheading,
        status: item.status,
      }))
      const bedbankItemObjects = bedbankItems.map(item => ({
        id,
        startDate: item.checkIn,
        endDate: item.checkOut,
        bookingNumber: item.bookingNumber,
        latitude: item?.offer.property?.latitude,
        longitude: item?.offer.property?.longitude,
        locationSubheading: item?.offer?.property?.location,
        status: item.status,
      }))
      const tourItemObjects = tourItems.map(item => ({
        id,
        bookingNumber: item.bookingId,
        status: item.status,
      }))
      const experienceItemObjects = experienceItems.map(item => ({
        id,
        bookingNumber: item.bookingNumber,
        status: item.status,
      }))
      const carHireItemObjects = carHireItems.map(item => ({
        id,
        bookingNumber,
        status: item.status,
      }))

      const flightItemObjects = flightItems.map(item => ({
        id,
        bookingNumber: item.pnrId,
        status: item.status,
        departureDate: item.departureDate,
      }))

      return [...itemObjects, ...bedbankItemObjects, ...tourItemObjects, ...experienceItemObjects, ...carHireItemObjects, ...flightItemObjects]
    })
  },
)

export const getPendingOrders = createSelector(
  (state: App.State) => state.orders.orders,
  (orders) => Object.values(orders).filter(order => order.status === 'pending'),
)

const getNonEmptyOrders = (orders: Array<App.Order>) => orders.filter(order =>
  !isEmpty(order.items) ||
  !isEmpty(order.bedbankItems) ||
  !isEmpty(order.flightItems) ||
  !isEmpty(order.tourItems) ||
  !isEmpty(order.experienceItems) ||
  !isEmpty(order.cruiseItems) ||
  !isEmpty(order.carHireItems) ||
  !isEmpty(order.transferItems) ||
  !isEmpty(order.customOfferItems),
)

export const getNextUpsellableOrder = createSelector(
  (state: App.State) => getUpcomingOrders(state),
  (orders): App.Order => {
    const sortedOrders = sortOrdersByDeparture(
      getNonEmptyOrders(orders).filter(
        (order) => order.status !== 'cancelled' && isOrderUpsellable(order),
      ),
    )
    return sortedOrders[0]
  },
)

export const getSortedOrdersByDeparture = createSelector(
  (state: App.State) => haveOrdersBeenFetched(state),
  (state: App.State) => state.orders.orders,
  (ordersFetched, orders) => {
    const allUnsortedOrders = getNonEmptyOrders(Object.values(orders))
    return {
      fetched: ordersFetched,
      data: sortOrdersByDeparture(allUnsortedOrders),
    }
  },
)

export const getUpcomingExperienceItems = createSelector(
  (state: App.State) => state.orders.orders,
  (orders) => {
    return Object.values(orders).filter(Boolean).flatMap(order => {
      return order.experienceItems.filter(item => isExperienceItemUpcoming(item, order))
    })
  },
)

const isLuxuryescapes: boolean = config.BRAND === 'luxuryescapes'

export function isOrderVelocityEligiblePostCheckout(order: App.Order) {
  return isLuxuryescapes &&
  VELOCITY_ENABLED_REGIONS.includes(order?.regionCode) &&
  isUpcomingForPartnerVelocityEarnEligibility(order) &&
  order.createdAt > VIRGIN_VELOCITY_START_DATE &&
  order.createdAt <= VIRGIN_VELOCITY_END_DATE
}

interface OrderTypeInfo {
  type: string;
  count: number;
  primaryName?: string;
}

export const getOrderOfferTypes = createSelector(
  (_, order: App.Order) => order,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.carHire.reservationInfo,
  (state: App.State) => state.experience.experiences,
  (state: App.State) => state.orders.flightDetails,
  (order: App.Order, offers: Record<string, App.Offer>, carHireReservationInfo: Record<string, App.CarHireReservationInfo>, experiences: Record<string, App.ExperienceOffer>, flightDetails: App.ApiStateRecord<{ flight: App.FlightOrderItemDetails }>): Array<OrderTypeInfo> => {
    const types: Array<OrderTypeInfo> = []
    if (!offers) return types

    if (order.tourItems.length > 0) {
      const activeTourItems = order.tourItems.filter((item: App.Tours.TourV2OrderItem) => !isItemCancelled(item))
      const groupedTours = groupBy(
        activeTourItems,
        (item) => `${item.tourId}_${item.tour?.startDate}_${item.tour?.endDate}`,
      )

      if (groupedTours.size > 0) {
        const tourOffer = offers[order.tourItems[0]?.tourId]
        types.push({
          type: 'Tours',
          count: groupedTours.size,
          primaryName: tourOffer?.name,
        })
      }
    }

    const hasAccommodation = order.items.length > 0 || order.bedbankItems.length > 0
    if (hasAccommodation) {
      const activeItems = order.items.filter((item: App.OrderItem) => !isItemCancelled(item))
      const activeBedbankItems = order.bedbankItems.filter((item: App.OrderBedbankItem) => !isItemCancelled(item))

      const groupedAccommodations = groupBy(
        [...activeItems, ...activeBedbankItems],
        (item) => {
          if ('offerId' in item) {
            return `${item.offerId}_${item.reservation?.startDate}_${item.reservation?.endDate}`
          }
          return `${item.offer.id}_${item.checkIn?.toISOString()}_${item.checkOut?.toISOString()}`
        },
      )

      if (groupedAccommodations.size > 0) {
        const firstAccommodation = order.items[0]?.offer || order.bedbankItems[0]?.offer
        types.push({
          type: 'Accommodation',
          count: groupedAccommodations.size,
          primaryName: firstAccommodation?.property?.name || firstAccommodation?.name,
        })
      }
    }

    if (order.carHireItems.length > 0) {
      const activeCarHireItems = order.carHireItems.filter((item: App.CarHireOrderItem) => !isItemCancelled(item))
      const groupedCarHire = groupBy(
        activeCarHireItems,
        (item) => `${item.idReservation}_${item.reservation?.pickUp.date}_${item.reservation?.dropOff.date}`,
      )

      if (groupedCarHire.size > 0) {
        const reservationId = order.carHireItems[0]?.idReservation || ''
        const reservationInfo = carHireReservationInfo[reservationId]?.reservation
        types.push({
          type: 'Car Hire',
          count: groupedCarHire.size,
          primaryName: `${reservationInfo?.vehicle?.model || ''} Car Hire`,
        })
      }
    }

    if (order.cruiseItems.length > 0) {
      const activeCruiseItems = order.cruiseItems.filter((item: App.CruiseOrderItem) => !isItemCancelled(item))
      const groupedCruises = groupBy(
        activeCruiseItems,
        (item) => `${item.cruiseOfferId}_${item.arrivalDate}`,
      )

      if (groupedCruises.size > 0) {
        types.push({
          type: 'Cruises',
          count: groupedCruises.size,
          primaryName: order.cruiseItems[0]?.offerName,
        })
      }
    }

    if (order.flightItems.length > 0) {
      const activeFlightItems = order.flightItems.filter((item: App.OrderFlightItem) => !isItemCancelled(item))
      const groupedFlights = groupBy(
        activeFlightItems,
        (item) => `${item.pnrId}_${item.departureDate}`,
      )

      if (groupedFlights.size > 0) {
        const firstFlightDetails = flightDetails[order.flightItems[0]?.itemId]?.flight
        const lastFlightDetails = order.flightItems.length > 1 ?
          flightDetails[order.flightItems[order.flightItems.length - 1]?.itemId]?.flight :
          undefined

        const legs: Array<App.Leg> = (() => {
          if (firstFlightDetails && lastFlightDetails) {
            return [...(firstFlightDetails.bookingJourney?.legs || []), ...(lastFlightDetails.bookingJourney?.legs || [])]
          } else if (firstFlightDetails) {
            return firstFlightDetails.bookingJourney?.legs || []
          }
          return []
        })()

        let tripType = ''
        if (legs.length === 2 && firstFlightDetails && !lastFlightDetails) {
          tripType = 'Return'
        } else if (firstFlightDetails && lastFlightDetails) {
          tripType = 'TwoOneWay'
        } else if (legs.length === 1) {
          tripType = 'OneWay'
        } else if (legs.length > 2) {
          tripType = 'MultiCity'
        } else if (legs.length === 2) {
          const isOpenJaw = legs[0].flights && legs[1].flights &&
            last(legs[0].flights).arrivalAirport !== legs[1].flights[0].departureAirport
          const isReverseOpenJaw = legs[0].flights && legs[1].flights &&
            last(legs[1].flights).arrivalAirport !== legs[0].flights[0].departureAirport
          if (isOpenJaw || isReverseOpenJaw) {
            tripType = 'MultiCity'
          }
        }

        let flightTitle = ''
        if (tripType === 'MultiCity') {
          const uniqueCities = new Set()
          legs.forEach(leg => {
            uniqueCities.add(leg.flights?.[0]?.departureCity)
            uniqueCities.add(last(leg.flights)?.arrivalCity)
          })
          const cities: Array<string> = Array.from(uniqueCities) as Array<string>
          flightTitle = `Flights to ${cities.slice(0, -1).join(', ')} & ${cities.slice(-1)}`
        } else if (legs.length > 0) {
          const firstLeg = legs[0]
          const departingFlight = firstLeg.flights?.[0]
          const returningFlight = last(firstLeg.flights)

          if (departingFlight?.departureAirportName &&
              departingFlight?.departureCountry &&
              returningFlight?.arrivalAirportName &&
              returningFlight?.arrivalCountry) {
            flightTitle = `${departingFlight.departureAirportName}, ${departingFlight.departureCountry} to ${returningFlight.arrivalAirportName}, ${returningFlight.arrivalCountry}`
          }
        }

        types.push({
          type: 'Flights',
          count: groupedFlights.size,
          primaryName: flightTitle || 'Flights',
        })
      }
    }

    if (order.experienceItems.length > 0) {
      const activeExperienceItems = order.experienceItems.filter((item: App.OrderExperienceItem) => !isItemCancelled(item))
      const groupedExperiences = groupBy(
        activeExperienceItems,
        (item) => `${item.experienceId}_${item.date}_${item.time}`,
      )

      if (groupedExperiences.size > 0) {
        types.push({
          type: 'Experiences',
          count: groupedExperiences.size,
          primaryName: order.experienceItems[0].ticket?.name,
        })
      }
    }

    if (order.transferItems.length > 0) {
      const activeTransferItems = order.transferItems.filter((item: App.OrderTransferItem) => !isItemCancelled(item))
      const groupedTransfers = groupBy(
        activeTransferItems,
        (item) => `${item.experienceId}_${item.date}_${item.type}`,
      )

      if (groupedTransfers.size > 0) {
        types.push({
          type: 'Transfers',
          count: groupedTransfers.size,
          primaryName: experiences[order.transferItems[0]?.experienceId]?.name,
        })
      }
    }

    if (order.customOfferItems?.[0]) {
      const activeCustomOfferItems = order.customOfferItems.filter((item: App.CustomOfferItem) => !isItemCancelled(item))
      const groupedCustomOffers = groupBy(
        activeCustomOfferItems,
        (item) => `${item.custom_offer.id}_${item.custom_offer.items[0].type}`,
      )

      if (groupedCustomOffers.size > 0) {
        const customOffer = order.customOfferItems[0]
        const type = customOffer.custom_offer.items[0].type
        if (type !== 'other') {
          types.push({
            type: capitaliseFirstLetterOnly(type),
            count: groupedCustomOffers.size,
            primaryName: customOffer.custom_offer.items[0].name,
          })
        }
      }
    }

    if (order.insuranceItems.length > 0 || order.bookingProtectionItems.length > 0) {
      types.push({
        type: 'Insurance',
        count: 1,
        primaryName: 'Insurance',
      })
    }

    if (order.subscriptionItems?.length > 0) {
      types.push({
        type: 'LuxPlus',
        count: 1,
        primaryName: 'LuxPlus',
      })
    }

    return types
  },
)
