import { sum } from 'lib/array/arrayUtils'
import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getCheckoutLuxLoyaltyBurningPointItems from './getCheckoutLuxLoyaltyBurningPointItems'

export const getCheckoutLuxLoyaltyBurnTotalInPoints = createSelector(
  (state: App.State) => getCheckoutLuxLoyaltyBurningPointItems(state),
  (
    burningPointItems,
  ): number => {
    return sum(
      burningPointItems,
      (item) => item.pointsPaid,
    )
  },
)

export const getCheckoutLuxLoyaltyBurnTotalInCurrency = createSelector(
  (state: App.State) => getCheckoutLuxLoyaltyBurningPointItems(state),
  (
    burningPointItems,
  ): number => {
    return floatify(sum(
      burningPointItems,
      (item) => (item.pointsPaid * item.priceInCurrency) / item.priceInPoints,
    ))
  },
)
