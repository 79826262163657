import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import request from 'api/requestUtils'
import { templates } from '@luxuryescapes/lib-uri-templates'
import { serialiseOccupancy } from 'lib/search/searchUtils'
import qs from 'qs'
import bedbankRateMap from './mappers/bedbankRateMap'
import { bedbankPackageMap } from './mappers/bedbankOfferMap'
import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'
import wait from 'lib/promise/wait'
import { arrayToObject } from 'lib/array/arrayUtils'

interface getPackagesByBedbankOfferIdParams {
  checkIn: string,
  checkOut: string,
  region: string;
  id: string;
  rooms: Array<App.Occupants>;
  timezone: string;
  preview?: string;
  isSpoofed?: boolean;
  source?: string;
}

export function getRatesForBedbankOffer(params: getPackagesByBedbankOfferIdParams) {
  const uri = templates.offer.publicOfferPackages.expand({
    checkIn: params.checkIn,
    checkOut: params.checkOut,
    occupancy: params.rooms.map(generateOccupancyStringByRoom),
    offerId: params.id,
    region: params.region,
    preview: params.preview,
    source: params.source,
  })

  return request.get<App.ApiResponse<Array<PublicOfferV2.BedbankPackage>>>(uri, params.isSpoofed ? { credentials: 'include' } : {}).then(data => data.result.flatMap(pkg => {
    const room = bedbankPackageMap(pkg)
    return (pkg.rates ?? []).map(rate => bedbankRateMap({
      checkIn: params.checkIn,
      checkOut: params.checkOut,
      timezone: params.timezone,
      rate,
      room,
    }))
  }))
}

interface getPackagesByBedbankOffersParams {
  checkIn: string,
  checkOut: string,
  region: string;
  rooms: Array<App.Occupants>;
  preview?: string;
  isSpoofed?: boolean;
  source?: string;
}

export function getRatesForBedbankOffers(offerIds: Array<string>, params: getPackagesByBedbankOffersParams) {
  const uri = templates.offer.publicOffers.expand({})

  const queryStrings = qs.stringify({
    offerIds: offerIds.join(','),
    checkIn: params.checkIn,
    checkOut: params.checkOut,
    region: params.region,
    preview: params.preview,
    occupancy: params.rooms ? serialiseOccupancy(params.rooms) : undefined,
    source: params.source,
  })

  return request.get<App.ApiResponse<Array<PublicOfferV2.BedbankOffer>>>(`${uri}?${queryStrings}`, params.isSpoofed ? { credentials: 'include' } : {}).then((response) =>
    response.result.map(serverOffer => {
      return {
        offerId: serverOffer.id,
        rates: serverOffer.packages.flatMap(pkg => {
          const room = bedbankPackageMap(pkg)
          return (pkg.rates ?? []).map(rate => bedbankRateMap({
            checkIn: params.checkIn,
            checkOut: params.checkOut,
            timezone: serverOffer.property.timezone,
            rate,
            room,
          }))
        }),
      }
    }),
  )
}

const ratesFetchAccumulator: Record<string, Array<string>> = {}
const ratesFetchPromise: Record<string, Promise<{ [id: string]: { offerId: string, rates: Array<App.BedbankRate> } }> | undefined> = {}

/**
 * This function will get and return a bedbank rate
 * However, it won't always do this straight away or in a separate request
 * The function will 'accumulate' all requests over a period of time and then fetch them
 * all in one go - returning them individually to the appropriate function call
 * @returns A promise with the bedbank rates requested
 */
export function getAccumulatedBedbankRatesById(params: getPackagesByBedbankOfferIdParams) {
  const paramsKey = `${params.checkIn}-${params.checkOut}-${serialiseOccupancy(params.rooms ?? [])}`
  if (!ratesFetchAccumulator[paramsKey]) {
    ratesFetchAccumulator[paramsKey] = []
  }
  ratesFetchAccumulator[paramsKey].push(params.id)
  let promise = ratesFetchPromise[paramsKey]
  if (!promise) {
    // give it 32ms to accumulate offer calls - that's around two frames at 60 fps worth of time
    promise = wait(32).then(async() => {
      const offerIdsToFetch = ratesFetchAccumulator[paramsKey]
      // reset our state in prep for next set of calls
      ratesFetchAccumulator[paramsKey] = []
      ratesFetchPromise[paramsKey] = undefined

      const rates = await getRatesForBedbankOffers(offerIdsToFetch, params)
      const ratesById = arrayToObject(rates, rates => rates.offerId)
      return ratesById
    })
    ratesFetchPromise[paramsKey] = promise
  }

  return promise.then((rates) => rates[params.id].rates)
}
