import { getGenesysAgentCallbackAvailability } from 'api/supportAssistant'
import DatePickerInput from 'components/Common/Form/Input/DatePickerInput'
import InputLabelWrap from 'components/Common/Form/Input/InputLabelWrap'
import Select from 'components/Common/Form/Input/Select'
import Group from 'components/utils/Group'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react'

const TIME_ZONES = [
  'Australia/Sydney',
  'Australia/Melbourne',
  'Australia/Brisbane',
  'Australia/Hobart',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Pacific/Auckland',
]

const NOW = moment()

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

interface Props {
  type: App.GenesysAgentCallbackType
}

interface CallbackTimeInterval {
  key: string
  start: string
}

function AgentCallbackRequestDateTimeInput({ type }: Props) {
  const [date, setDate] = useState<string | undefined >(NOW.format(ISO_DATE_FORMAT))

  const [time, setTime] = useState<string>()
  const [timezone, setTimezone] = useState<string>(tz)
  const [filteredTimes, setFilteredTimes] = useState<Array<CallbackTimeInterval>>([])

  const handleDateChange = useCallback<NonNullable<ComponentProps<typeof DatePickerInput>['onDateChange']>>((_, isoDate) => {
    setDate(isoDate)
  }, [])

  const fetchAvailability = useCallback(async(timezone: string, date: string | undefined) => {
    return await getGenesysAgentCallbackAvailability({
      date,
      timezone,
    })
  }, [])

  useEffect(() => {
    const fetchAndSetIntervals = async() => {
      const availability = await fetchAvailability(timezone, date)
      const times = availability.data.times

      if (times.length === 0) {
        const tomorrowsDate = moment(NOW).add(1, 'day')
        setDate(tomorrowsDate.format(ISO_DATE_FORMAT))
      }

      setFilteredTimes(times)

      // We don't want to set the default time for these callback types
      if (type !== 'SUPPORT' && type !== 'SALES') {
        setTime(times[0].key)
      }
    }

    fetchAndSetIntervals()
  }, [date, fetchAvailability, timezone, type])

  const { minDate, maxDate } = useMemo(() => {
    // Genesys constraints only allow callbacks up to 30 days in the future
    const maxDate = new Date(NOW.year(), NOW.month(), NOW.date() + 30)

    const lastAvailableTime = filteredTimes.at(-1)?.key
    if (lastAvailableTime) {
      const [lastAvailableHour] = lastAvailableTime.split(':').map(Number)
      if (NOW.hour() >= lastAvailableHour) {
        const minDate = new Date(NOW.year(), NOW.month(), NOW.date() + 1)
        return { minDate, maxDate }
      }
    }

    const minDate = new Date(NOW.year(), NOW.month(), NOW.date())
    return { minDate, maxDate }
  }, [filteredTimes])

  return <Group direction="vertical" gap={8} horizontalAlign="stretch">
    <InputLabelWrap
      label="Preferred callback date and time"
      optionalIndicatorEnabled={false}
    >
      <Group direction="horizontal" gap={8} horizontalAlign="stretch">
        <DatePickerInput
          required
          dropdownPlacement="bottom"
          name="callbackDate"
          min={minDate}
          max={maxDate}
          value={date}
          onDateChange={handleDateChange}
          requiredErrorMessage="Please select date"
        />
        <Select
          required
          invalidErrorMessage="Please select time"
          name="callbackTime"
          autoComplete="off"
          value={time}
          onChange={(e) => setTime(e.target.value)}
        >
          {filteredTimes.map((interval) => (
            <option key={interval.key} value={interval.start}>{interval.key}</option>
          ))}
        </Select>
      </Group>
      <Select
        required
        label="Timezone"
        invalidErrorMessage="Please select timezone"
        name="timezone"
        autoComplete="off"
        value={timezone}
        onChange={(e) => setTimezone(e.target.value)}
      >
        {TIME_ZONES.map((timezone) => (
          <option key={timezone} value={timezone}>
            {timezone}
          </option>
        ))}
      </Select>
    </InputLabelWrap>
  </Group>
}

export default AgentCallbackRequestDateTimeInput
