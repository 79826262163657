import { ValueOf } from 'type-fest'
import { createReducer } from 'lib/redux/reducerUtils'
import { SearchMenuStates } from 'components/Search/type'
import {
  DATE_SEARCH_OPTION_IDS,
  DEFAULT_FLEXIBLE_DURATION_RANGE,
  FLEXIBLE_DURATION_RANGE,
  SEARCH_VERTICALS,
} from 'constants/search'
import config from 'constants/config'
import { OfferListSortOption, SORT_OPTION_RECOMMENDED } from 'constants/offerListFilters'

export default interface GlobalSearchState {
  activeMenu: SearchMenuStates
  areErrorsShown: boolean
  areRoomErrorsShown: boolean
  checkinDate: moment.Moment | undefined
  checkoutDate: moment.Moment | undefined
  flexibleMonths: string | undefined
  flexibleNights: FLEXIBLE_DURATION_RANGE
  durationRange: Array<FLEXIBLE_DURATION_RANGE>
  userSelectedFlexibleMonths: boolean
  isFlexibleDateSelected: boolean | undefined
  isAnytimeDateSelected: boolean
  dateSearchOptionId: DATE_SEARCH_OPTION_IDS
  isLoadingPopularDestinations?: boolean
  occupancies: Array<App.Occupants> | undefined
  offerDistanceFromSearchTarget?: { [offerId: string]: number }
  popularDestinations: Array<App.SearchItem>
  recentOccupancies: Array<Array<App.Occupants>>
  /**
   * "Flexible night" durations, to replace the enum `flexibleNights` at some point
   * That way it works for multiple product types
   **/
  durationMin: number | undefined
  durationMax: number | undefined
  /*
    The 'main' location search item
    Often the "from" in a from-to search and the only one in a single location search
  */
  searchItem: App.SearchItem | undefined
  searchItems: Array<App.SearchItem>
  /*
    The 'secondary' location search item
    Often the "to" in a from-to search and not used for single location searches
  */
  secondarySearchItem: App.SearchItem | undefined
  secondarySearchItems: Array<App.SearchItem>
  searchTargetLabel?: string
  searchType?: App.SearchItem['searchType']
  searchVerticals: Set<SEARCH_VERTICALS>
  suggestedSearchItems: Array<App.SearchItem>
  onBedbankSelectDatesPrompt?: (bedbankOfferId: string, bedbankOfferType: App.OfferType, windowSearch: string) => void
  isEditMode: boolean
  experienceCategories?: Array<number>
  cruiseLines: Array<string>
  pickUpTime: string
  dropOffTime: string
  driversAgeCategory: App.CarHireDriverAgeCategory | undefined
  driversAge: number | undefined
  urlOfferId?: string
  urlOfferType: App.OfferType | undefined
  tourGroupTypes?: Array<App.Tours.TourGroupType>
  sortBy?: OfferListSortOption
  disableMultiRoom: boolean
  disableSaleUnit: boolean
  disableRecentSearches: boolean
  eventAnalytics: {
    searchId?: string
  }
}

export const GLOBAL_SEARCH_INITIAL_STATE: GlobalSearchState = {
  activeMenu: SearchMenuStates.Closed,
  areErrorsShown: false,
  areRoomErrorsShown: false,
  checkinDate: undefined,
  checkoutDate: undefined,
  flexibleNights: DEFAULT_FLEXIBLE_DURATION_RANGE.EMPTY,
  durationRange: [DEFAULT_FLEXIBLE_DURATION_RANGE.EMPTY],
  flexibleMonths: undefined,
  userSelectedFlexibleMonths: false,
  isEditMode: false,
  isFlexibleDateSelected: false,
  isAnytimeDateSelected: true,
  dateSearchOptionId: DATE_SEARCH_OPTION_IDS.SPECIFIC,
  isLoadingPopularDestinations: true,
  occupancies: [config.search.defaultOccupants],
  popularDestinations: [],
  recentOccupancies: [],
  searchItem: undefined,
  searchItems: [],
  secondarySearchItem: undefined,
  secondarySearchItems: [],
  searchVerticals: new Set(),
  suggestedSearchItems: [],
  experienceCategories: [],
  cruiseLines: [],
  pickUpTime: '',
  dropOffTime: '',
  urlOfferId: '',
  urlOfferType: undefined,
  driversAgeCategory: undefined,
  driversAge: undefined,
  durationMax: undefined,
  durationMin: undefined,
  tourGroupTypes: [],
  sortBy: SORT_OPTION_RECOMMENDED,
  disableMultiRoom: false,
  disableSaleUnit: false,
  disableRecentSearches: false,
  eventAnalytics: {},
}

export enum GlobalSearchStateActions {
  POPULATE_POPULAR_DESTINATIONS = 'POPULATE_POPULAR_DESTINATIONS',
  SET_ACTIVE_MENU = 'SET_ACTIVE_MENU',
  SET_CHECKIN_DATE = 'SET_CHECKIN_DATE',
  SET_CHECKOUT_DATE = 'SET_CHECKOUT_DATE',
  UNSET_CHECKIN_DATE = 'UNSET_CHECKIN_DATE',
  UNSET_CHECKOUT_DATE = 'UNSET_CHECKOUT_DATE',
  SET_FLEXIBLE_DURATION = 'SET_FLEXIBLE_DURATION',
  SET_FLEXIBLE_DURATION_RANGE = 'SET_FLEXIBLE_DURATION_RANGE',
  SET_FLEXIBLE_MONTH_RANGE = 'SET_FLEXIBLE_MONTH_RANGE',
  SET_USER_SELECTED_FLEXIBLE_MONTHS = 'SET_USER_SELECTED_FLEXIBLE_MONTHS',
  UNSET_FLEXIBLE_DURATION = 'UNSET_FLEXIBLE_DURATION',
  UNSET_FLEXIBLE_MONTH_RANGE = 'UNSET_FLEXIBLE_MONTH_RANGE',
  UNSET_USER_SELECTED_FLEXIBLE_MONTHS = 'UNSET_USER_SELECTED_FLEXIBLE_MONTHS',
  UNSET_SEARCH_ITEM = 'UNSET_SEARCH_ITEM',
  UNSET_SECONDARY_SEARCH_ITEM = 'UNSET_SECONDARY_SEARCH_ITEM',
  UNSET_URL_OFFER_ID = 'UNSET_URL_OFFER_ID',
  UNSET_URL_OFFER_TYPE = 'UNSET_URL_OFFER_TYPE',
  RESET_STATE = 'RESET_STATE',
  SET_EDIT_MODE = 'SET_EDIT_MODE',
  SET_OCCUPANCIES = 'SET_OCCUPANCIES',
  UNSET_OCCUPANCIES = 'UNSET_OCCUPANCIES',
  SET_OFFER_DISTANCE_FROM_SEARCH_TARGET = 'SET_OFFER_DISTANCE_FROM_SEARCH_TARGET',
  SET_RECENT_OCCUPANCIES = 'SET_RECENT_OCCUPANCIES',
  SET_RECENT_SEARCH_ITEMS = 'SET_RECENT_SEARCH_ITEMS',
  SET_SEARCH_ITEM = 'SET_SEARCH_ITEM',
  SET_SEARCH_ITEMS = 'SET_SEARCH_ITEMS',
  SET_SECONDARY_SEARCH_ITEM = 'SET_SECONDARY_SEARCH_ITEM',
  SET_SECONDARY_SEARCH_ITEMS = 'SET_SECONDARY_SEARCH_ITEMS',
  SET_URL_OFFER_ID = 'SET_URL_OFFER_ID',
  SET_URL_OFFER_TYPE = 'SET_URL_OFFER_TYPE',
  SET_SEARCH_TARGET_LABEL = 'SET_SEARCH_TARGET_LABEL',
  SET_SEARCH_TYPE = 'SET_SEARCH_TYPE',
  SET_SUGGESTED_SEARCH_ITEMS = 'SET_SUGGESTED_SEARCH_ITEMS',
  TOGGLE_ERRORS = 'TOGGLE_ERRORS',
  TOGGLE_ANYTIME_DATES_SELECTED = 'TOGGLE_ANYTIME_DATES_SELECTED',
  SET_DATE_SEARCH_OPTION = 'SET_DATE_SEARCH_OPTION',
  TOGGLE_ROOM_ERRORS = 'TOGGLE_ROOM_ERRORS',
  UNPOPULATE_POPULAR_DESTINATIONS = 'UNPOPULATE_POPULAR_DESTINATIONS',
  SET_EXPERIENCE_CATEGORIES = 'SET_EXPERIENCE_CATEGORIES',
  SET_CRUISE_LINES = 'SET_CRUISE_LINES',
  SET_PICKUP_TIME = 'SET_PICKUP_TIME',
  SET_RETURN_TIME = 'SET_RETURN_TIME',
  UNSET_PICKUP_TIME = 'UNSET_PICKUP_TIME',
  UNSET_RETURN_TIME = 'UNSET_RETURN_TIME',
  SET_DRIVERS_AGE_CATEGORY = 'SET_DRIVERS_AGE_CATEGORY',
  SET_DRIVERS_AGE = 'SET_DRIVERS_AGE',
  SET_DURATION_RANGE = 'SET_DURATION_RANGE',
  SET_TOUR_GROUP_TYPE = 'SET_TOUR_GROUP_TYPE',
  SELECT_ALL_TOUR_GROUP_TYPE = 'SELECT_ALL_TOUR_GROUP_TYPE',
}

export type GlobalSearchAction = ValueOf<Utils.FullActionMap<{
  [GlobalSearchStateActions.POPULATE_POPULAR_DESTINATIONS]: {
    popularDestinations: GlobalSearchState['popularDestinations']
  }
  [GlobalSearchStateActions.UNPOPULATE_POPULAR_DESTINATIONS]: { }
  [GlobalSearchStateActions.SET_CHECKIN_DATE]: {
    date: GlobalSearchState['checkinDate'] | undefined
  }
  [GlobalSearchStateActions.SET_CHECKOUT_DATE]: {
    date: GlobalSearchState['checkoutDate'] | undefined
  }
  [GlobalSearchStateActions.SET_FLEXIBLE_DURATION]: {
    flexibleNights: GlobalSearchState['flexibleNights']
  }
  [GlobalSearchStateActions.SET_FLEXIBLE_DURATION_RANGE]: {
    durationRange: GlobalSearchState['durationRange']
  }
  [GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE]: {
    flexibleMonths: GlobalSearchState['flexibleMonths']
  }
  [GlobalSearchStateActions.SET_USER_SELECTED_FLEXIBLE_MONTHS]: {
    userSelectedFlexibleMonths: GlobalSearchState['userSelectedFlexibleMonths']
  }
  [GlobalSearchStateActions.UNSET_CHECKIN_DATE]: { }
  [GlobalSearchStateActions.UNSET_CHECKOUT_DATE]: { }
  [GlobalSearchStateActions.UNSET_FLEXIBLE_DURATION]: { }
  [GlobalSearchStateActions.UNSET_FLEXIBLE_MONTH_RANGE]: { }
  [GlobalSearchStateActions.UNSET_USER_SELECTED_FLEXIBLE_MONTHS]: { }
  [GlobalSearchStateActions.UNSET_SEARCH_ITEM]: { }
  [GlobalSearchStateActions.UNSET_URL_OFFER_ID]: { }
  [GlobalSearchStateActions.UNSET_URL_OFFER_TYPE]: { }
  [GlobalSearchStateActions.RESET_STATE]: {}
  [GlobalSearchStateActions.SET_EDIT_MODE]: {
    isEditMode: GlobalSearchState['isEditMode']
  }
  [GlobalSearchStateActions.SET_SEARCH_ITEM]: {
    searchItem: GlobalSearchState['searchItem']
  }
  [GlobalSearchStateActions.SET_SEARCH_ITEMS]: {
    searchItems: GlobalSearchState['searchItems']
  }
  [GlobalSearchStateActions.SET_SECONDARY_SEARCH_ITEM]: {
    searchItem: GlobalSearchState['secondarySearchItem'] | undefined
  }
  [GlobalSearchStateActions.SET_SECONDARY_SEARCH_ITEMS]: {
    secondarySearchItems: GlobalSearchState['secondarySearchItems']
  }
  [GlobalSearchStateActions.SET_URL_OFFER_ID]: {
    urlOfferId: GlobalSearchState['urlOfferId']
  }
  [GlobalSearchStateActions.SET_URL_OFFER_TYPE]: {
    urlOfferType: GlobalSearchState['urlOfferType']
  }
  [GlobalSearchStateActions.SET_SEARCH_TYPE]: {
    searchType: GlobalSearchState['searchType']
  }
  [GlobalSearchStateActions.SET_SEARCH_TARGET_LABEL]: {
    searchTargetLabel: GlobalSearchState['searchTargetLabel']
  }
  [GlobalSearchStateActions.SET_OFFER_DISTANCE_FROM_SEARCH_TARGET]: {
    offerDistanceFromSearchTarget: GlobalSearchState['offerDistanceFromSearchTarget']
  }
  [GlobalSearchStateActions.TOGGLE_ERRORS]: {
    shown?: GlobalSearchState['areErrorsShown']
  }
  [GlobalSearchStateActions.TOGGLE_ROOM_ERRORS]: {
    shown?: GlobalSearchState['areRoomErrorsShown']
  }
  [GlobalSearchStateActions.SET_ACTIVE_MENU]: {
    menu: GlobalSearchState['activeMenu']
  }
  [GlobalSearchStateActions.SET_OCCUPANCIES]: {
    occupancies: GlobalSearchState['occupancies']
  }
  [GlobalSearchStateActions.UNSET_OCCUPANCIES]: {}
  [GlobalSearchStateActions.SET_RECENT_OCCUPANCIES]: {
    occupancies: GlobalSearchState['recentOccupancies']
  }
  [GlobalSearchStateActions.SET_SUGGESTED_SEARCH_ITEMS]: {
    searchItems: GlobalSearchState['suggestedSearchItems']
  }
  [GlobalSearchStateActions.TOGGLE_ANYTIME_DATES_SELECTED]: {
    selected?: GlobalSearchState['isAnytimeDateSelected']
  }
  [GlobalSearchStateActions.SET_DATE_SEARCH_OPTION]: {
    optionId?: GlobalSearchState['dateSearchOptionId']
  }
  [GlobalSearchStateActions.SET_EXPERIENCE_CATEGORIES]: {
    categories: GlobalSearchState['experienceCategories'];
  }
  [GlobalSearchStateActions.SET_CRUISE_LINES]: {
    cruiseLines: Array<string>
  }
  [GlobalSearchStateActions.SET_PICKUP_TIME]: {
    pickupTime: GlobalSearchState['pickUpTime']
  }
  [GlobalSearchStateActions.SET_RETURN_TIME]: {
    dropOffTime: GlobalSearchState['dropOffTime']
  }
  [GlobalSearchStateActions.SET_DRIVERS_AGE_CATEGORY]: {
    driversAgeCategory: App.CarHireDriverAgeCategory;
  }
  [GlobalSearchStateActions.SET_DRIVERS_AGE]: {
    driversAge?: number;
  }
  [GlobalSearchStateActions.UNSET_PICKUP_TIME]: {}
  [GlobalSearchStateActions.UNSET_RETURN_TIME]: {}
  [GlobalSearchStateActions.UNSET_SECONDARY_SEARCH_ITEM]: {}
  [GlobalSearchStateActions.SET_DURATION_RANGE]: {
    min?: number;
    max?: number;
  }
  [GlobalSearchStateActions.SET_TOUR_GROUP_TYPE]: {
    tourGroupTypes: GlobalSearchState['tourGroupTypes']
  }
  [GlobalSearchStateActions.SELECT_ALL_TOUR_GROUP_TYPE]: {}
}>>

export const globalSearchStateReducer = createReducer<GlobalSearchState, GlobalSearchAction>(
  GLOBAL_SEARCH_INITIAL_STATE,
  {
    [GlobalSearchStateActions.POPULATE_POPULAR_DESTINATIONS]: (state, action) => ({
      popularDestinations: action.popularDestinations,
      isLoadingPopularDestinations: false,
    }),
    [GlobalSearchStateActions.UNPOPULATE_POPULAR_DESTINATIONS]: () => ({
      popularDestinations: [],
      isLoadingPopularDestinations: true,
    }),
    [GlobalSearchStateActions.SET_CHECKIN_DATE]: (state, action) => ({
      checkinDate: action.date,
    }),
    [GlobalSearchStateActions.SET_CHECKOUT_DATE]: (state, action) => ({
      checkoutDate: action.date,
    }),
    [GlobalSearchStateActions.UNSET_CHECKIN_DATE]: () => ({
      checkinDate: undefined,
    }),
    [GlobalSearchStateActions.UNSET_CHECKOUT_DATE]: () => ({
      checkoutDate: undefined,
    }),
    [GlobalSearchStateActions.SET_FLEXIBLE_DURATION]: (state, action) => ({
      flexibleNights: action.flexibleNights,
    }),
    [GlobalSearchStateActions.SET_FLEXIBLE_DURATION_RANGE]: (state, action) => ({
      durationRange: action.durationRange,
    }),
    [GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE]: (state, action) => ({
      flexibleMonths: action.flexibleMonths,
    }),
    [GlobalSearchStateActions.SET_USER_SELECTED_FLEXIBLE_MONTHS]: (state, action) => ({
      userSelectedFlexibleMonths: action.userSelectedFlexibleMonths,
    }),
    [GlobalSearchStateActions.UNSET_FLEXIBLE_DURATION]: () => ({
      flexibleNights: DEFAULT_FLEXIBLE_DURATION_RANGE.EMPTY,
    }),
    [GlobalSearchStateActions.UNSET_FLEXIBLE_MONTH_RANGE]: () => ({
      flexibleMonths: '',
    }),
    [GlobalSearchStateActions.UNSET_USER_SELECTED_FLEXIBLE_MONTHS]: () => ({
      userSelectedFlexibleMonths: false,
    }),
    [GlobalSearchStateActions.RESET_STATE]: () => (GLOBAL_SEARCH_INITIAL_STATE),
    [GlobalSearchStateActions.SET_SEARCH_ITEM]: (state, action) => ({
      searchItem: action.searchItem,
      eventAnalytics: state.eventAnalytics,
    }),
    [GlobalSearchStateActions.SET_SEARCH_ITEMS]: (state, action) => ({
      searchItems: action.searchItems,
    }),
    [GlobalSearchStateActions.SET_SECONDARY_SEARCH_ITEM]: (state, action) => ({
      secondarySearchItem: action.searchItem,
    }),
    [GlobalSearchStateActions.SET_SECONDARY_SEARCH_ITEMS]: (state, action) => ({
      secondarySearchItems: action.secondarySearchItems,
    }),
    [GlobalSearchStateActions.SET_URL_OFFER_ID]: (state, action) => ({
      urlOfferId: action.urlOfferId,
    }),
    [GlobalSearchStateActions.SET_URL_OFFER_TYPE]: (state, action) => ({
      urlOfferType: action.urlOfferType,
    }),
    [GlobalSearchStateActions.UNSET_SEARCH_ITEM]: () => ({
      searchItem: undefined,
    }),
    [GlobalSearchStateActions.UNSET_SECONDARY_SEARCH_ITEM]: () => ({
      secondarySearchItem: undefined,
    }),
    [GlobalSearchStateActions.SET_SEARCH_TYPE]: (state, action) => ({
      searchType: action.searchType,
    }),
    [GlobalSearchStateActions.SET_SEARCH_TARGET_LABEL]: (state, action) => ({
      searchTargetLabel: action.searchTargetLabel,
    }),
    [GlobalSearchStateActions.SET_OFFER_DISTANCE_FROM_SEARCH_TARGET]: (state, action) => ({
      offerDistanceFromSearchTarget: action.offerDistanceFromSearchTarget,
    }),
    [GlobalSearchStateActions.TOGGLE_ERRORS]: (state, action) => ({
      areErrorsShown: action.shown ?? !state.areErrorsShown,
    }),
    [GlobalSearchStateActions.TOGGLE_ROOM_ERRORS]: (state, action) => ({
      areRoomErrorsShown: action.shown ?? !state.areRoomErrorsShown,
    }),
    [GlobalSearchStateActions.SET_ACTIVE_MENU]: (state, action) => ({
      activeMenu: action.menu,
    }),
    [GlobalSearchStateActions.SET_EDIT_MODE]: (state, action) => ({
      isEditMode: action.isEditMode,
    }),
    [GlobalSearchStateActions.SET_OCCUPANCIES]: (state, action) => ({
      occupancies: action.occupancies,
    }),
    [GlobalSearchStateActions.UNSET_OCCUPANCIES]: () => ({
      occupancies: GLOBAL_SEARCH_INITIAL_STATE.occupancies,
    }),
    [GlobalSearchStateActions.SET_RECENT_OCCUPANCIES]: (state, action) => ({
      recentOccupancies: action.occupancies,
    }),
    [GlobalSearchStateActions.SET_SUGGESTED_SEARCH_ITEMS]: (state, action) => ({
      suggestedSearchItems: action.searchItems,
    }),
    [GlobalSearchStateActions.TOGGLE_ANYTIME_DATES_SELECTED]: (state, action) => ({
      isAnytimeDateSelected: action.selected ?? !state.isAnytimeDateSelected,
    }),
    [GlobalSearchStateActions.SET_DATE_SEARCH_OPTION]: (state, action) => ({
      dateSearchOptionId: action.optionId,
    }),
    [GlobalSearchStateActions.SET_EXPERIENCE_CATEGORIES]: (state, action) => ({
      experienceCategories: action.categories,
    }),
    [GlobalSearchStateActions.SET_CRUISE_LINES]: (state, action) => ({
      cruiseLines: action.cruiseLines,
    }),
    [GlobalSearchStateActions.SET_PICKUP_TIME]: (state, action) => ({
      pickUpTime: action.pickupTime,
    }),
    [GlobalSearchStateActions.SET_RETURN_TIME]: (state, action) => ({
      dropOffTime: action.dropOffTime,
    }),
    [GlobalSearchStateActions.UNSET_PICKUP_TIME]: () => ({
      pickUpTime: '',
    }),
    [GlobalSearchStateActions.UNSET_RETURN_TIME]: () => ({
      dropOffTime: '',
    }),
    [GlobalSearchStateActions.SET_DRIVERS_AGE_CATEGORY]: (state, action) => ({
      driversAgeCategory: action.driversAgeCategory,
    }),
    [GlobalSearchStateActions.SET_DRIVERS_AGE]: (state, action) => ({
      driversAge: action.driversAge,
    }),
    [GlobalSearchStateActions.SET_DURATION_RANGE]: (state, action) => ({
      durationMax: action.max,
      durationMin: action.min,
    }),
    [GlobalSearchStateActions.SET_TOUR_GROUP_TYPE]: (state, action) => ({
      tourGroupTypes: action.tourGroupTypes,
    }),
    [GlobalSearchStateActions.SELECT_ALL_TOUR_GROUP_TYPE]: () => ({
      tourGroupTypes: [],
    }),
  },
)
