import TextLink from 'components/Luxkit/TextLink'
import Caption from 'components/Luxkit/Typography/Caption'
import config from 'constants/config'
import GeoContext from 'contexts/geoContext'
import { useAppSelector } from 'hooks/reduxHooks'
import { addRegionToPath } from 'lib/url/searchUrlUtils'
import getLuxLoyaltyProgramDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyProgramDisplayName'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import * as React from 'react'

interface Props {
  modalType?: string
}

function AccountAccessLegalText({ modalType }: Props) {
  const { currentRegionCode } = React.useContext(GeoContext)
  const isPhonePromptModal = modalType === 'promptAddPhone'
  const termsURL = config.contentLinks.termsAndConditions.href || addRegionToPath(config.contentLinks.termsAndConditions.to!, currentRegionCode)
  const privacyURL = config.contentLinks.privacyPolicy.href || addRegionToPath(config.contentLinks.privacyPolicy.to!, currentRegionCode)
  const luxLoyaltyEnabled = useAppSelector(getIsLuxLoyaltyEnabled)

  return (
    <Caption colour="neutral-three" variant="medium">
      {isPhonePromptModal ? 'By adding your mobile number' : 'By continuing'}, you agree to our <TextLink href={termsURL} rel="noopener noreferrer" target="_blank" weight="regular">Terms & Conditions</TextLink> and{' '}
      <TextLink href={privacyURL} rel="noopener noreferrer" target="_blank" weight="regular">{config.brandConfig.privacyPolicySynonym},</TextLink>{' '}
      {luxLoyaltyEnabled && `join the ${getLuxLoyaltyProgramDisplayName()} program, `}and to subscribe to marketing communications for offers, alerts and services.
    </Caption>
  )
}

export default AccountAccessLegalText
