import React, { ComponentProps, MouseEventHandler, PropsWithChildren, RefObject, useCallback } from 'react'
import DropdownSheetBase from './DropdownSheetBase'
import DropdownSheetBody from './DropdownSheetBody'
import DropdownSheetFooter from './DropdownSheetFooter'
import DropdownSheetHeader from './DropdownSheetHeader'
import { _FloatingDropdownCloseHandler } from '../_FloatingDropdown'

type InheritedHeaderProps = Pick<ComponentProps<typeof DropdownSheetHeader>, 'title' | 'subtitle' | 'onBackClick'>
type InheritedFooterProps = Pick<ComponentProps<typeof DropdownSheetFooter>, 'primaryActionProps' | 'isPrimaryActionHidden' | 'secondaryActionProps' | 'isSecondaryActionHidden'>
type InheritedBaseProps = Pick<ComponentProps<typeof DropdownSheetBase>, 'anchorRef' | 'boundaryRef' | 'triggerRef' | 'placement' | 'size' | 'open' | 'portaled' | 'id' | 'data-testid' | 'crossAxisShift' | 'autoHeight'>

export type DropdownSheetCloseHandler = _FloatingDropdownCloseHandler

interface Props extends PropsWithChildren, InheritedHeaderProps, InheritedFooterProps, InheritedBaseProps {
  hasDismissButton?: boolean
  /**
   * @default S
   */
  headerSize?: ComponentProps<typeof DropdownSheetHeader>['size']
  /**
   * @default start
   */
  headerAlignment?: ComponentProps<typeof DropdownSheetHeader>['alignment']
  headerExtension?: ComponentProps<typeof DropdownSheetHeader>['extensionSlot']
  headerActions?: ComponentProps<typeof DropdownSheetHeader>['actionsSlot']
  footerStart?: ComponentProps<typeof DropdownSheetFooter>['startSlot']
  footerActions?: ComponentProps<typeof DropdownSheetFooter>['actionsSlot']
  /**
   * Reference to the body element wrapping the main content.
   */
  bodyRef?: RefObject<HTMLDivElement>
  onClose?: DropdownSheetCloseHandler
  ref?: React.Ref<HTMLDivElement>
}

function DropdownSheet(props: Props) {
  const {
    size,
    children,
    hasDismissButton,
    subtitle,
    title,
    headerSize = 'S',
    headerAlignment = 'start',
    headerExtension,
    headerActions,
    footerStart,
    footerActions,
    primaryActionProps,
    isPrimaryActionHidden,
    secondaryActionProps,
    isSecondaryActionHidden,
    anchorRef,
    boundaryRef,
    triggerRef,
    placement,
    open,
    bodyRef,
    onClose,
    onBackClick,
    'data-testid': dataTestId,
    id,
    crossAxisShift,
    portaled,
    ref,
    autoHeight,
  } = props

  const handleDismiss = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    onClose?.(event, 'dismiss-click')
  }, [onClose])

  const hasHeader = !!(title || subtitle || headerActions || headerExtension || hasDismissButton)
  const hasPrimaryAction = !isPrimaryActionHidden && !!primaryActionProps?.children
  const hasSecondaryAction = !isSecondaryActionHidden && !!secondaryActionProps?.children
  const hasFooter = !!(hasPrimaryAction || hasSecondaryAction || footerStart || footerActions)

  return <DropdownSheetBase
    ref={ref}
    size={size}
    anchorRef={anchorRef}
    boundaryRef={boundaryRef}
    triggerRef={triggerRef}
    placement={placement}
    open={open}
    data-testid={dataTestId}
    id={id}
    onClose={onClose}
    crossAxisShift={crossAxisShift}
    portaled={portaled}
    autoHeight={autoHeight}
  >
    <DropdownSheetBody ref={bodyRef}>
      {children}
    </DropdownSheetBody>
    {hasHeader && (
      <DropdownSheetHeader
        size={headerSize}
        alignment={headerAlignment}
        subtitle={subtitle}
        title={title}
        actionsSlot={headerActions}
        extensionSlot={headerExtension}
        onBackClick={onBackClick}
        onDismissClick={hasDismissButton ? handleDismiss : undefined}
      />
    )}
    {hasFooter && (
      <DropdownSheetFooter
        primaryActionProps={primaryActionProps}
        isPrimaryActionHidden={isPrimaryActionHidden}
        secondaryActionProps={secondaryActionProps}
        isSecondaryActionHidden={isSecondaryActionHidden}
        startSlot={footerStart}
        actionsSlot={footerActions}
      />
    )}
  </DropdownSheetBase>
}

export default DropdownSheet
