import GoogleTagManager from '@redux-beacon/google-tag-manager'
import { eventsMap } from 'analytics/eventsMap'
import { createGenesysMiddleware } from 'analytics/genesys'
import { routerMiddleware } from 'connected-react-router'
import config from 'constants/config'
import userMiddleware from 'middlewares/userMiddleware'
import { createMiddleware } from 'redux-beacon'
import buildStore from './store'
import * as interactionStudio from './analytics/interactionStudio'
import { analyticsListenerMiddleware } from 'middlewares/analyticsMiddleware'
import analyticsPageViewMiddleware from 'middlewares/analyticsPageViewMiddleware'

function initialiseStore(history: any, initialState: App.State) {
  const googleTagManagerMiddleware = createMiddleware(
    eventsMap,
    GoogleTagManager(),
  )

  const middlewares = [
    userMiddleware,
    routerMiddleware(history),
    googleTagManagerMiddleware,
    createGenesysMiddleware(),
    analyticsListenerMiddleware.middleware,
    analyticsPageViewMiddleware.middleware,
  ]

  if (config.analytics.interactionStudio) {
    const interactionStudioMiddleware = createMiddleware(
      interactionStudio.events(),
      interactionStudio.target(),
    )
    middlewares.push(interactionStudioMiddleware)
  }

  const store = buildStore(
    history,
    initialState,
    middlewares,
  )

  return store
}

export default initialiseStore
