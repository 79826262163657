import { isInsuranceItemView } from 'lib/checkout/checkoutUtils'

function getItemViewAppliedLoyaltyDiscount(itemView: App.Checkout.AnyItemView): number {
  if (isInsuranceItemView(itemView)) {
    return itemView.loyaltySavingsAmount
  }
  return 0
}

export default getItemViewAppliedLoyaltyDiscount
