import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { removeRegionFromPath } from 'lib/url/searchUrlUtils'

export interface SignupSuccessPage {
  type: comluxgroup.Page_1_0_0['type'];
  category: comluxgroup.Page_1_0_0['category'];
  url?: string
  startsWith?: string;
  endsWith?: string;
}
export const pages: Array<SignupSuccessPage> = [
  {
    url: '/', // This URL
    type: 'home', // Should be categorized as this type
    category: null, // and this category
  },
  {
    url: '/hotels',
    type: 'vertical',
    category: 'hotel',
  },
  {
    url: '/tours',
    type: 'vertical',
    category: 'tour',
  },
  {
    url: '/cruises',
    type: 'vertical',
    category: 'cruise',
  },
  {
    url: '/car-hire',
    type: 'vertical',
    category: 'car_hire',
  },
  {
    url: '/ultra-lux',
    type: 'vertical',
    category: 'ultralux',
  },
  {
    url: '/experiences',
    type: 'vertical',
    category: 'experience',
  },
  {
    url: '/flights',
    type: 'vertical',
    category: 'flight',
  },
  {
    url: '/trip-planner',
    startsWith: '/trip-planner',
    type: null,
    category: 'trip_planner',
  },
  {
    url: '/last-minute',
    type: 'vertical',
    category: 'last_minute_hotel',
  },
  {
    url: '/gift-cards',
    type: null,
    category: 'gift_card',
  },
  /** Should redirect */
  {
    url: '/insurance',
    type: null,
    category: 'travel_protection',
  },
  {
    url: '/travel-protection',
    type: null,
    category: 'travel_protection',
  },
  {
    url: '/support',
    type: null,
    category: 'support',
  },
  {
    startsWith: '/refer',
    type: null,
    category: 'refer',
  },
  {
    startsWith: '/search/tours',
    type: 'search',
    category: 'tour',
  },
  {
    startsWith: '/search/cruises',
    type: 'search',
    category: 'cruise',
  },
  {
    startsWith: '/search/car-hire',
    type: 'search',
    category: 'car_hire',
  },
  {
    startsWith: '/search/experiences',
    type: 'search',
    category: 'experience',
  },
  {
    startsWith: '/flights-search-results',
    type: 'search',
    category: 'flight',
  },
  {
    startsWith: '/search',
    type: 'search',
    category: 'hotel',
  },
  {
    startsWith: '/velocity-frequent-flyer',
    type: null,
    category: 'velocity',
  },
  {
    startsWith: '/account',
    type: 'account',
    category: null,
  },
  {
    startsWith: '/tv',
    type: null,
    category: 'tv',
  },
  {
    endsWith: '.p1',
    type: 'marketing',
    category: null,
  },
  {
    endsWith: '.d1',
    type: 'marketing',
    category: null,
  },
  {
    endsWith: '.h1',
    type: 'marketing',
    category: null,
  },
  {
    startsWith: '/destination',
    type: 'marketing',
    category: 'destination',
  },
  {
    startsWith: '/campaign',
    type: 'marketing',
    category: 'campaign',
  },
  {
    endsWith: '/purchase',
    type: 'checkout',
    category: null,
  },
  {
    startsWith: '/booking',
    type: 'inflow',
    category: null,
  },
  {
    startsWith: '/checkout',
    type: 'inflow',
    category: null,
  },
  {
    startsWith: '/cart',
    type: 'inflow',
    category: 'cart',
  },
]

export function getPageCategoryAndType(pathname: string): SignupSuccessPage {
  const pathWithoutRegion = removeRegionFromPath(pathname)
  const matchingPage = pages.find((page) => {
    if (page.endsWith) return pathWithoutRegion.endsWith(page.endsWith)
    if (page.startsWith) return pathWithoutRegion.startsWith(page.startsWith)
    if (page.url) return pathWithoutRegion === page.url
  })
  if (matchingPage) return matchingPage

  return { type: null, category: null, url: pathname }
}
