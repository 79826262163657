import React from 'react'
import classnames from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'

import { renderMarkdown } from 'lib/string/markdownUtils'
import { BodyTextLarge, BodyTextMedium, BodyTextSmall } from './Typography/BodyText'
import { Heading2Style, Heading3Style, Heading4Style } from './Typography/Heading'
import { TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from './Typography/Typography'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'

/** Our main theme font Suisse Int'l doesn't support `font-variant-numeric: tabular-nums` */
function filterNumericFont(fontFamily: string): string {
  return fontFamily.split(', ').filter(font => !font.includes('Suisse')).join(', ')
}

const Root = styled.div`
  /* Large blocks of text (as in a markdown renderer) should be a little lighter */
  color: ${props => props.theme.palette.neutral.default.two};
  line-height: var(${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR});

  &.body-font-small {
    ${BodyTextSmall}
  }

  &.body-font-medium {
    ${BodyTextMedium}
  }

  &.body-font-large {
    ${BodyTextLarge}

  }

  h2,
  h3,
  h4 {
    margin-top: ${rem(20)};
    color: ${props => props.theme.palette.neutral.default.one};
  }

  h2 {
    ${Heading2Style}
  }

  h3 {
    ${Heading3Style}
  }

  h4 {
    ${Heading4Style}
  }

  p {
    margin-top: ${rem(24)};
  }

  img {
    max-width: 100%;
  }

  & a:not(:disabled):focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 1px solid ${props => props.theme.palette.brand.primary.normal});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }

  a {
    transition: color 0.2s, background-color 0.2s, border-color 0.2s, opacity 0.2s;
    /* Same as the link default variant */
    color: ${props => props.theme.palette.brand.primary.normal};
    outline: 0px solid transparent;
    text-decoration: underline currentcolor;

    &:disabled {
      color:  ${props => props.theme.palette.neutral.default.four};
    }

    &:not(:disabled) {
      ${mediaHoverable} {
        &:hover {
          color: ${props => props.theme.palette.brand.primary.dark};
        }
      }

      &:focus {
        outline:  var(${KEYBOARD_MODE_CSS_VAR}, 1px solid ${props => props.theme.palette.brand.primary.normal});
        outline-offset:  var(${KEYBOARD_MODE_CSS_VAR}, 2px);
      }

      &:active, &.toggled {
        color: ${props => props.theme.palette.brand.primary.darkest};
      }
    }
  }

  li {
    margin-top: ${rem(20)};
  }

  ul {
    padding: 0;
    margin: ${rem(20)} 0 0;
    list-style: none;

    > li {
      padding-left: ${rem(20)};
      text-indent: ${rem(-2)};
      position: relative;

      &::before {
        position: absolute;
        pointer-events: none;
        left: ${rem(2)};
        top: 0;
        content: "•";
      }

      > p:first-child {
        margin-top: 0;
      }
    }
  }

  ol {
    margin-left: ${rem(32)};

    > li {
      padding-left: ${rem(12)};

      &::marker {
        font-variant-numeric: tabular-nums;
        font-family: ${props => filterNumericFont(props.theme.font.primary.family)};
      }
    }
  }

  ul, ol {
    > li {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  table {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin-top: ${rem(10)};

    th {
      text-align: left;
    }

    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          padding: ${rem(5)};
          vertical-align: top;
          border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
        }
      }
    }

    > thead > tr > th {
      vertical-align: bottom;
      border-bottom: 2px solid ${props => props.theme.palette.neutral.default.five};
    }

    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
      > tr:first-child {
        > th,
        > td {
          border-top: 0;
        }
      }
    }
  }

  > :first-child {
    margin-top: 0;
  }

  &.compact {
    ul > {
      li {
        margin-top: 0;
        padding-left: ${rem(16)};

        &::before {
          right: ${rem(8)};
        }
      }
    }
    ol > {
      li {
        margin-top: 0;
        padding-left: ${rem(8)};
      }
    }

    > p + ul,
    > p + li {
      margin-top: 0;
    }

    p + p {
      margin-top: ${rem(8)};
    }

    li {
      > ul, > ol {
        margin-top: 0;
      }
    }
  }

  &.normal {
    font-weight: ${props => props.theme.font.primary.weight.regular};
  }

  &.bold {
    font-weight: ${props => props.theme.font.primary.weight.bold};
  }

  &.semi-bold {
    font-weight: ${props => props.theme.font.primary.weight.semiBold};
  }
`

interface Props {
  className?: string;
  content: string;
  fontSize?: 'small' | 'medium' | 'large';
  type?: 'compact',
  fontWeight?: 'normal' | 'semi-bold' | 'bold';
}

function MarkdownRender(props: Props) {
  const { className, content, fontSize = 'large', type, fontWeight } = props

  if (!content) {
    return null
  }

  const html = renderMarkdown(content)

  return (
    <Root // nosemgrep
      className={classnames(
        className,
        type,
        fontWeight,
        `body-font-${fontSize}`,
      )}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default React.memo(MarkdownRender)
