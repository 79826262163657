import { BenefitCtaConfig, BENEFITS_CTA_CONFIG } from 'luxPlus/constants/benefits'

export const isActiveMemberWithSubscriptionTier = (result: App.MemberSubscriptionItem) => result.tier !== 'free_preview' && result.status !== 'CONFIRMED_AWAITING_PAYMENT'

/**
 */
export const minus10Percent = (value: number): number => value * 0.9

/**
 * Configuration for the benefits CTA links
 */
export function getBenefitCtaConfig(type: App.LuxPlusBenefitType): BenefitCtaConfig {
  return BENEFITS_CTA_CONFIG[type]
}

/**
  * @param offer - The offer to check
  * @returns Whether the offer is an early access offer and is restricted to members only
*/
export const earlyAccessRestrictedToMembers = (offer?: App.AnyOffer) => offer?.luxPlus.access === 'earlyAccess' && !!offer?.luxPlus.restrictPurchaseToMembers
