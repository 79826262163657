import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import LineDetectiveIcon from 'components/Luxkit/Icons/line/LineDetectiveIcon'
import useToggle from 'hooks/useToggle'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import CartModeModal from 'components/Common/Modals/AddToCartModal/AddToCartModal'
import { getSherlogFields } from 'selectors/sherlogSelectors'
import DropdownSheet from '../../../../Luxkit/Dropdown/Sheet/DropdownSheet'
import SherlogActionButtons from './SherlogActionButtons'
import Group from 'components/utils/Group'
import styled from 'styled-components'

const CodeRow = styled.code`
  white-space: nowrap;
`

interface MappedProps {
  sherlogFields: App.SherlogFields;
  sherLogData: App.SherLogState;
}

function SherlogMenu({ sherlogFields }: MappedProps) {
  const [isOpen, toggleModal] = useToggle()

  const triggerRef = useRef<HTMLButtonElement | null>(null)
  return <>
    <TextButton
      kind="secondary"
      startIcon={<LineDetectiveIcon/>}
      endIcon={<DropdownChevron open={isOpen}/>}
      ref={triggerRef}
      onClick={toggleModal}
    >
      Sherlog
    </TextButton>
    <DropdownSheet
      open={isOpen}
      anchorRef={triggerRef}
      triggerRef={triggerRef}
      onClose={toggleModal}
      size="M"
      title="Application Logs"
      footerActions={<SherlogActionButtons />}
    >
      <Group direction="vertical" gap={16}>
        {Object.entries(sherlogFields).map(([key, value], index) => <CodeRow key={`${key}-${index}`}>
          <b>{key}:</b> {value ?? 'N/A'}
        </CodeRow>)}
      </Group>
    </DropdownSheet>
    <CartModeModal/>
  </>
}

const mapStateToProps = (state: App.State) => ({
  sherlogFields: getSherlogFields(state),
  sherLogData: state.sherLog,
})

export default connect(mapStateToProps)(SherlogMenu)
