import React from 'react'
import styled from 'styled-components'
import LuxPlusLogo from './Logos/LuxPlusLogo'
import clsx from 'clsx'
import { themeClassName } from 'lib/theme/themeUtils'
import { rem } from 'polished'

const LogoWrapper = styled.div`
  background-color: ${props => props.theme.palette.product.luxPlus.background};
  color: ${props => props.theme.palette.product.luxPlus.contrast};
  display: grid;
  grid-template: auto / minmax(0, 1fr);
  place-items: center;
  padding: ${rem(8)};
`

const Logo = styled(LuxPlusLogo)`
  max-width: 100%;
`

interface Props {
  className?: string
}

function LuxPlusProductPlaceholder({ className }: Props) {
  return (
    <LogoWrapper className={clsx(className, themeClassName('inverse'))}>
      <Logo height={22} />
    </LogoWrapper>
  )
}

export default LuxPlusProductPlaceholder
