import { mediaQueryUp } from 'components/utils/breakpoint'
import { css } from 'styled-components'
import config from 'constants/config'
import { generateCSSVarEntries, themeClassName } from 'lib/theme/themeUtils'

const DefaultStyle = css(Object.fromEntries(generateCSSVarEntries(config.theme.defaultPalette, 'palette')))
const InverseStyle = css(Object.fromEntries(generateCSSVarEntries(config.theme.inversePalette, 'palette')))
const BorderRadiiStyle = css(Object.fromEntries(generateCSSVarEntries(config.theme.borderRadius, 'border-radius')))
const ShadowsStyle = css(Object.fromEntries(generateCSSVarEntries(config.theme.shadows, 'shadow')))

const ThemeStyles = css`
  :root {
    ${BorderRadiiStyle}
    ${ShadowsStyle}
  }
  :root,
  .${themeClassName('default')} {
    ${DefaultStyle}
    color: ${props => props.theme.palette.neutral.default.one};
  }
  .${themeClassName('inverse')} {
    ${InverseStyle}
    color: ${props => props.theme.palette.neutral.default.one};
  }
  ${mediaQueryUp.tablet} {
    .${themeClassName({ tabletAndUp: 'default' })} {
      ${DefaultStyle}
      color: ${props => props.theme.palette.neutral.default.one};
    }
    .${themeClassName({ tabletAndUp: 'inverse' })} {
      ${InverseStyle}
      color: ${props => props.theme.palette.neutral.default.one};
    }
  }
  ${mediaQueryUp.desktop} {
    .${themeClassName({ desktopAndUp: 'default' })} {
      ${DefaultStyle}
      color: ${props => props.theme.palette.neutral.default.one};
    }
    .${themeClassName({ desktopAndUp: 'inverse' })} {
      ${InverseStyle}
      color: ${props => props.theme.palette.neutral.default.one};
    }
  }
`

export default ThemeStyles
