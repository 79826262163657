import { createSelector } from 'reselect'
import getCheckoutInsuranceQuoteParams from '../request/getCheckoutInsuranceQuoteParams'
import getCheckoutInsuranceProducts from './getCheckoutInsuranceProducts'
import { getInsuranceQuoteKey, insuranceTypeToInsuranceProductType } from 'lib/insurance/insuranceUtils'

const getCheckoutInsuranceQuoteFetchStatus = createSelector(
  (state: App.State) => getCheckoutInsuranceQuoteParams(state),
  (state: App.State) => state.insurance.fetchQuotesErrors,
  (state: App.State) => state.insurance.fetchingQuotes,
  (_: App.State, type?: App.InsuranceProductType) => type,
  (state: App.State, type?: App.InsuranceProductType) => getCheckoutInsuranceProducts(state, type),
  (quoteParams, quoteErrors, quoteFetching, _, products): Array<{ error: string, fetching: boolean }> => {
    return products.map(product => {
      const key = getInsuranceQuoteKey(
        product.id,
        {
          type: insuranceTypeToInsuranceProductType(product.type),
          ...quoteParams,
        },
      )
      return {
        error: quoteErrors[key],
        fetching: quoteFetching[key],
      }
    })
  },
)

export default getCheckoutInsuranceQuoteFetchStatus
