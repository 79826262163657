import Clickable from 'components/Common/Clickable'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import React, { useState } from 'react'
import styled from 'styled-components'
import Typography from 'components/Luxkit/Typography/Typography'
import InputWrap from 'components/Common/Form/Input/InputWrap'
import cn from 'clsx'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'

const Trigger = styled(InputWrap)`
  width: 100%;
  position: relative;
  padding: ${rem(4)} ${rem(16)};
  border-radius: ${props => props.theme.borderRadius.XS};

  &.hidden {
    display: none;
  }
`

interface Props {
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  /**
   * The value of the input. Will populate the hidden input.
   */
  value?: string;
  /**
   * The value to be displayed, optional. Will not be used for the hidden input value
   * Use this when you want to display something different to the hidden input value
   * */
  displayValue?: string;
  hasError?: boolean;
  className?: string;
  hidden?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  required?: boolean;
  name?: string;
  requiredErrorMessage?: string;
}

const SearchFormField = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    disabled,
    label,
    placeholder,
    value,
    displayValue,
    hasError,
    hidden,
    onClick,
    className,
    required,
    name,
    requiredErrorMessage,
    ...buttonProps
  } = props

  const [error, setError] = useState<string>()
  const hasValue = !!(displayValue ?? value)

  return (<Trigger
    {...buttonProps}
    error={(hasError || error) ? 'error' : undefined}
    noValidationSpacing
    noValidationMessage
    forwardedAs={onClick ? Clickable : undefined}
    className={cn(className, { hidden })}
    onClick={onClick}
    ref={ref}
    disabled={!!disabled}
  >
    {required && <HiddenInput
      value={value}
      name={name}
      required={required}
      invalidMessage={requiredErrorMessage}
      onErrorUpdate={setError}
    />}
    <Typography wrap="truncate">
      <BodyText variant="medium" weight="bold" colour="neutral-one">
        {label}
      </BodyText>
      {(placeholder && !hasValue) &&
        <BodyText variant="medium" colour="neutral-three">{placeholder}</BodyText>
        }
      {hasValue &&
        <BodyText variant="medium" wrap="truncate">{displayValue ?? value}</BodyText>
        }
    </Typography>
  </Trigger>
  )
})

SearchFormField.displayName = 'SearchFormField'

export default SearchFormField
