import { OfferContextEvent } from 'analytics/snowplow/context'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_ALWAYS_ON_ANALYTICS, OFFER_TYPE_BED_BANK_ANALYTICS, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE_ANALYTICS, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from './offer'

export const RATINGS: Record<string, number> = {
  [OFFER_TYPE_HOTEL]: 136.5,
  [OFFER_TYPE_ALWAYS_ON_ANALYTICS]: 105,
  [OFFER_TYPE_LAST_MINUTE_ANALYTICS]: 84,
  last_minute: 84,
  [OFFER_TYPE_BED_BANK_ANALYTICS]: 84,
  bedbank: 84,
  flight: 14,
  travelInsurance: 301,
  carHire: 56,
  addon: 143.5,
  tourLE: 182,
  tourTTC: 105,
  [OFFER_TYPE_TOUR]: 140,
  [OFFER_TYPE_TOUR_V2]: 105,
  experienceLED: 140,
  experienceRezdy: 140,
  experiences: 140,
  cruiseFlash: 105,
  cruiseBank: 63,
  [OFFER_TYPE_ALWAYS_ON]: 105,
  bookingProtection: 385,
  subscription: 700,
}

export const CALENDAR_GA_EVENT_LABEL = {
  NextMonth: 'next',
  PreviousMonth: 'previous',

  CloseCross: 'cross',
  CloseOutside: 'outside',

  /* Origin of opening calendar */
  StickyBar: 'sticky-bar',
  OfferIntro: 'offer-intro',
  RouterLocation: 'router-location',
  PackagePaneHeader: 'package-pane-header',
  PackageOptionAvailability: 'package-option-availability',
  PackageOptionFlightAvailability: 'package-option-flight-availability',
  PackageOptionDuration: 'package-option-duration',
  PackageOptionDate: 'package-option-date',
  PackageOptionDateTile: 'package-option-date-tile',
  PackageOptionSoldOut: 'package-option-sold-out',

  /* Open sub-filters when calendar filter is open */
  OpenDuration: 'duration',
  OpenRoom: 'room',
  OpenPackage: 'package',
  OpenCapacity: 'capacity',

  /* Toggle Settings */
  SetHotel: 'hotel',
  SetHotelFlights: 'hotel-flights',

  /* Switching between filters */
  SwitchFilter: 'switch-filter',
}

export const CALENDAR_GA_EVENT_ACTION = {
  BookNow: 'book-now',
  Apply: 'apply',
  DateChanged: 'date-changed',
  Continue: 'continue',
  CheckOut: 'check-out',
  CheckIn: 'check-in',
  CloseFilter: 'close',
  HotelFlightToggle: 'toggle',
  MonthSelect: 'month-select',
  OpenFilter: 'open',
  OpenSubFilter: 'open-subfilter',
  CloseSubFilter: 'close-subfilter',
  ShiftMonth: 'shift-month',
  YearView: 'year-view',
}

export const GA_ACTION_TO_SNOWPLOW_MAP: Record<string, OfferContextEvent | undefined> = {
  [CALENDAR_GA_EVENT_ACTION.CheckIn]: 'CALENDAR_CHECK_IN_SELECTED',
  [CALENDAR_GA_EVENT_ACTION.Apply]: 'CALENDAR_FILTERS_APPLY',
  [CALENDAR_GA_EVENT_ACTION.BookNow]: 'BOOK_NOW_PRESSED',
  [CALENDAR_GA_EVENT_ACTION.CloseFilter]: 'CALENDAR_CLOSED',
  [CALENDAR_GA_EVENT_ACTION.HotelFlightToggle]: 'CALENDAR_HOTEL_TOGGLED',
  [CALENDAR_GA_EVENT_ACTION.OpenSubFilter]: 'CALENDAR_SUB_FILTER_OPENED',
  [CALENDAR_GA_EVENT_ACTION.CloseSubFilter]: 'CALENDAR_SUB_FILTER_CLOSED',
  [CALENDAR_GA_EVENT_ACTION.MonthSelect]: 'CALENDAR_MONTH_SELECTED',
  [CALENDAR_GA_EVENT_ACTION.DateChanged]: 'CALENDAR_DATE_CHANGED',
}

export const INTERACTION_STUDIO_EVENT_ACTION = {
  Login: 'login',
  CompletePurchase: 'complete-purchase',
  ViewOffer: 'view-offer',
  ViewOfferList: 'view-offer-list',
  ViewHotelOfferList: 'view-hotel-offer-list',
  ViewTourOfferList: 'view-tour-offer-list',
  SelectTravellers: 'select-travellers',
  SelectMonth: 'select-month',
  SelectDates: 'select-dates',
  SelectDepartingFlight: 'select-departing-flight',
  SelectReturningFlight: 'select-returning-flight',
  SelectAddOns: 'select-add-ons',
  ReviewPayment: 'review-payment',
  ViewExperienceOffer: 'view-experience-offer',
}

export const OFFER_PAGE_BOOKING_GA_EVENT_LABEL = {
  PackagePaneHeader: 'package-pane-header',
  PackageOptionAvailability: 'package-option-availability',
  PackageOptionFlightAvailability: 'package-option-flight-availability',
  PackageOptionBookNow: 'package-option-book-now',
  PackageOptionFlightBookNow: 'package-option-flight-book-now',
}

export const OFFER_PAGE_BOOKING_GA_EVENT_ACTION = {
  OpenFilter: 'open',
  BookNow: 'book-now',
  BookNowBookingFlowStarted: 'booking-flow-started',
  BookNowBookingFlowNotStarted: 'booking-flow-not-started',
}

// snowplow schemas
export const PAGE_VIEW_CONTEXT_SCHEMA = 'iglu:com.luxgroup/page_view_context/jsonschema/1-0-0'
export const APP_CONTEXT_SCHEMA = 'iglu:com.luxgroup/app_context/jsonschema/1-0-0'
export const CART_SCHEMA = 'iglu:com.luxgroup/cart/jsonschema/1-0-0'
