import React, { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'

import Heading from 'components/Luxkit/Typography/Heading'
import Caption from 'components/Luxkit/Typography/Caption'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { formatOccupantsAsGuests } from 'lib/offer/occupancyUtils'
import Clickable from 'components/Common/Clickable'
import TextButton from 'components/Luxkit/Button/TextButton'
import { DATE_SEARCH_OPTION_IDS } from 'constants/search'
import { prettyFlexibleDates } from 'components/Search/SearchForm/SearchDateInput/FlexibleDateSearchField'
import { getTotalRoomsString, prettyDates } from 'components/Search/utils'
import LineArrowLeftIcon from 'components/Luxkit/Icons/line/LineArrowLeftIcon'
import { useAppDispatch } from 'hooks/reduxHooks'
import { pushWithRegion } from '../../../actions/NavigationActions'
import { connect } from 'react-redux'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import { getBusinessTravellerSelectEnabled } from 'businessTraveller/utils/getBusinessTravellerSelectEnabled'
import { parseSearchString } from 'lib/url/searchUrlUtils'
import { useHistory } from 'react-router'

interface MappedStateProps {
  businessAccount?: App.BusinessTraveller.CurrentBusinessAccount
  travellerEmployees?: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
  windowSearch: string
  routeHistory: App.RouteHistoryState
}

interface Props {
  className?: string;
  onEdit: () => void;
  location?: string;
  isDestinationPage?: boolean;
  switchView?: {
    label: string,
    view: 'map' | 'list'
    URL: string
  }
}

const StyledContainer = styled(Clickable)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(8)} ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  width: 100%;

  &.landing-page-test {
    padding: ${rem(20)} ${rem(25)} ${rem(20)} ${rem(25)};
  }
`

const StyledDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${rem(2)};
  &.map-view {
    align-items: center;
  }
`

const StyledDescriptionsSection = styled.div`
  display: flex;
  column-gap: ${rem(8)};
`

// The end containers need to be the same width so that the text is centered
const EndContainers = styled.div`
  width: ${rem(40)};
  display: flex;
  justify-content: center;
  align-items: center;
`

function HotelSearchHeaderBar(props: Props & MappedStateProps) {
  const {
    onEdit,
    className,
    location,
    isDestinationPage,
    switchView,
    businessAccount,
    travellerEmployees,
    windowSearch,
    routeHistory,
  } = props

  const {
    checkinDate,
    checkoutDate,
    occupancies,
    dateSearchOptionId,
    flexibleMonths,
    flexibleNights,
    userSelectedFlexibleMonths,
  } = useContext(GlobalSearchStateContext)
  const dispatch = useAppDispatch()

  const { destinationName } = parseSearchString(windowSearch)

  const isBusinessTravellerSelectEnabled = getBusinessTravellerSelectEnabled(businessAccount)

  const searchBarDescription = useMemo(() => {
    const bulletPointSeparator = '\u2022'
    if (dateSearchOptionId === DATE_SEARCH_OPTION_IDS.ANYTIME) {
      return [
        'Anytime',
      ]
    }
    const datesDetails = dateSearchOptionId === DATE_SEARCH_OPTION_IDS.FLEXIBLE ?
      prettyFlexibleDates(userSelectedFlexibleMonths ? flexibleMonths : undefined, flexibleNights) :
      prettyDates(checkinDate, checkoutDate)
    const occupancyDetails = formatOccupantsAsGuests(...occupancies)
    const roomsDetails = getTotalRoomsString(occupancies)
    const occupancyWithRoomDetails = `${occupancyDetails} ${roomsDetails}`

    if (isBusinessTravellerSelectEnabled) {
      const travellerCount = travellerEmployees?.length ?? 1
      const travellers = travellerCount > 1 ? `${travellerCount} travellers` : '1 traveller'

      return [
        datesDetails,
        bulletPointSeparator,
        travellers,
        bulletPointSeparator,
        occupancyWithRoomDetails,
      ]
    }

    return [
      datesDetails,
      bulletPointSeparator,
      occupancyWithRoomDetails,
    ]
  }, [dateSearchOptionId, userSelectedFlexibleMonths, flexibleMonths, flexibleNights, checkinDate, checkoutDate, occupancies, isBusinessTravellerSelectEnabled, travellerEmployees?.length])

  const history = useHistory()

  const onListViewClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!switchView) return
    e.stopPropagation()

    // If you are navigating back from the map view you should be taken back to where you have scrolled
    if (routeHistory.currentPath === '/au/search/map' && routeHistory.prevPath === '/au/search') {
      history.goBack()
      return
    }

    dispatch(pushWithRegion(switchView.URL))
  }, [switchView, routeHistory.currentPath, routeHistory.prevPath, dispatch, history])

  return (
    <StyledContainer className={cn(className, { 'landing-page-test': isDestinationPage })} onClick={onEdit}>
      { switchView?.view === 'list' &&
        <EndContainers onClick={onListViewClick}>
          <LineArrowLeftIcon />
        </EndContainers>
      }
      <StyledDetailsSection className={cn({ 'map-view': switchView?.view === 'list' })}>
        <Heading variant="heading6" >{destinationName ?? location ?? 'Map area'}</Heading>
        <StyledDescriptionsSection>
          {searchBarDescription.map((description, index) => (
            <Caption key={index} variant="large">
              {description}
            </Caption>
          ))}
        </StyledDescriptionsSection>
      </StyledDetailsSection>
      <EndContainers>
        <TextButton nonInteractable kind="tertiary">Edit</TextButton>
      </EndContainers>
    </StyledContainer>
  )
}

export default connect<MappedStateProps, {}, Props, App.State>((state): MappedStateProps => {
  return {
    businessAccount: state.businessTraveller.currentBusinessAccount,
    travellerEmployees: selectSelectedTravellerEmployees(state),
    windowSearch: state.router.location.search,
    routeHistory: state.routeHistory,
  }
})(HotelSearchHeaderBar)
