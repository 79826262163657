import { createSelector } from 'reselect'
import getLuxLoyaltyLoungePassItemViews from './getLuxLoyaltyLoungePassItemViews'
import { getPlural } from 'lib/string/pluralize'
import { sum } from 'lib/array/arrayUtils'

const getLuxLoyaltyLoungePassBreakdownView = createSelector(
  (state: App.State) => getLuxLoyaltyLoungePassItemViews(state),
  (itemView): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    const ticketCount = itemView.data.length
    if (ticketCount === 0) {
      return {
        hasRequiredData: true,
        data: [],
      }
    }
    const total = sum(itemView.data, data => data.totals.price)

    return {
      data: [{
        title: 'Airport lounge pass',
        additionalInfoText: [],
        price: 0,
        memberPrice: 0,
        itemsAlwaysVisible: true,
        items: [{
          itemType: 'loungePassItem',
          title: '',
          additionalElements: [{
            id: 'tickets',
            left: {
              type: 'text',
              value: `${ticketCount}x ${getPlural('ticket', ticketCount)}`,
            },
            right: {
              type: 'price',
              value: total,
            },
          }],
          additionalInfo: [],
        }],
      }],
      hasRequiredData: itemView.hasRequiredData,
    }
  },
)

export default getLuxLoyaltyLoungePassBreakdownView
