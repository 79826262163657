import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import Heading from 'components/Luxkit/Typography/Heading'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import Tooltip from 'components/Luxkit/Tooltip'
import LineClockFiveIcon from 'components/Luxkit/Icons/line/LineClockFiveIcon'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import Divider from 'components/Luxkit/Divider'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import styled from 'styled-components'
import { rem } from 'polished'
import Caption from 'components/Luxkit/Typography/Caption'
import FormatCurrency from 'components/Common/FormatCurrency'
import Group from 'components/utils/Group'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import FakeInput from 'components/Common/Form/Input/FakeInput'
import useToggle from 'hooks/useToggle'
import ListItem from 'components/Luxkit/List/ListItem'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { checkTourExperienceTimeSlot } from 'lib/tours/tourUtils'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { EmptyArray } from 'lib/array/arrayUtils'
import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import { generateLuxLoyaltyPointsCalculatorTourExperienceOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'

const DividerExtend = styled(Divider)`
  margin: ${rem(16)} 0;
`

const BodyTextBlockExtend = styled(BodyTextBlock)`
  margin: ${rem(12)} 0;
`

const TimeSlotWrapper = styled.div`
  width: 100%;
  ${mediaQueryUp.tablet} {
    width: ${rem(300)};
  }
`

interface Props {
  tourExperience: App.Tours.TourExperience
  title: string
  date?: Date
  day?: number
  countOccupants?: number
  isCheckout?: boolean
  isForm?: boolean
  tourExperienceItems?: Array<App.Checkout.TourV2ExperienceItem>
  onSubmit?: (tourExperience: App.Tours.TourExperience, daySelected: number, timeSlot?: string) => void
}

function TourExperienceModal(props: Props) {
  const {
    tourExperience,
    title,
    date,
    day,
    tourExperienceItems = EmptyArray,
    countOccupants = 0,
    onSubmit,
    isCheckout = false,
  } = props

  const modalState = useModalElementContext<unknown>()
  const triggerRef = useRef<HTMLLabelElement | null>(null)
  const [open, toggleDropdown, , closeDropdown] = useToggle()
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>('')

  const selectTimeSlot = useCallback((timeSlot: string) => {
    return () => {
      setSelectedTimeSlot(timeSlot)
      closeDropdown()
    }
  }, [closeDropdown])

  const submitTimeSlot = useCallback(() => {
    if (onSubmit) {
      onSubmit(tourExperience, day ?? 0, selectedTimeSlot)
      modalState.resolve(true)
    }
  }, [onSubmit, tourExperience, day, selectedTimeSlot, modalState])

  const totalPrice = useMemo(() => (tourExperience?.price ?? 0) * (countOccupants ?? 0), [tourExperience.price, countOccupants])
  const loyaltyCalculationRequests = [generateLuxLoyaltyPointsCalculatorTourExperienceOptions(tourExperience)]

  return <ModalBase>
    <ModalHeader title={tourExperience.name} subtitle={title} />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={8}>
          <Heading variant="heading6">Overview</Heading>
          <MarkdownRender fontSize="medium" content={tourExperience.description ?? ''} />
          {onSubmit && <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
            <BodyTextBlock
              variant="medium"
              colour="highlight-secondary"
              weight="bold" endIcon={<Tooltip placement="right" description="Free Cancellation until 60 days of tour departure"><SolidInfoCircleIcon colour="neutral-two" /></Tooltip>}
            >
              Free cancellation
            </BodyTextBlock>
            <BodyTextBlockExtend variant="medium" startIcon={<LineClockFiveIcon />}>Duration {tourExperience.durationFormatted}</BodyTextBlockExtend>
          </Group>}
          {!onSubmit && <>
            <BodyTextBlock
              variant="medium"
              colour="highlight-secondary"
              weight="bold" endIcon={<Tooltip placement="right" description="Free Cancellation until 60 days of tour departure"><SolidInfoCircleIcon colour="neutral-two" /></Tooltip>}
            >
              Free cancellation
            </BodyTextBlock>
            {(!!tourExperience.timeSlots.length || tourExperience.durationFormatted) && <BodyTextBlockExtend variant="medium" startIcon={<LineClockFiveIcon />}>
              {`${tourExperience.timeSlots.join(' • ')} (${tourExperience.durationFormatted})`}
            </BodyTextBlockExtend>}
          </>}
          {onSubmit && <TimeSlotWrapper>
            <FakeInput
              required
              label="Select a preferred time"
              ref={triggerRef}
              noValidationSpacing
              value={selectedTimeSlot}
              onClick={toggleDropdown}
              endIcon={<DropdownChevron open={open}/>}
            />
            <DropdownList
              title=""
              anchorRef={triggerRef}
              triggerRef={triggerRef}
              open={open}
              onClose={closeDropdown}
              size="fill-anchor"
            >
              {tourExperience.timeSlots.map((timeSlot, index) =>
                (<ListItem
                  key={index}
                  disabled={!checkTourExperienceTimeSlot(tourExperienceItems, [timeSlot], date)}
                  selected={timeSlot === selectedTimeSlot}
                  onClick={selectTimeSlot(timeSlot)}
                  title={timeSlot}
                />),
              )}
            </DropdownList>
          </TimeSlotWrapper>}
          {!onSubmit &&
            <div>
              <LuxLoyaltyPoints calculationRequests={loyaltyCalculationRequests} calculationType="estimate"/>
              {totalPrice > 0 && <PriceRowPrice
                size="M"
                price={tourExperience.price}
                saleUnit="person"
                signDisplay="always"
              />}
              {totalPrice === 0 && <BodyText variant="medium" weight="bold">Included</BodyText>}
              {countOccupants > 1 && (totalPrice > 0) && <Caption variant="medium" colour="neutral-three">
                <FormatCurrency value={totalPrice} format="roundedDollar"/> total for all travellers
              </Caption>}
            </div>}
          {!isCheckout && <>
            <DividerExtend kind="primary" />
            <BodyText variant="small">This will be available to {tourExperience.price > 0 ? 'purchase' : 'add on'} when booking your tour.</BodyText>
          </>}
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
    {onSubmit && <ModalFooter>
      <Group direction="vertical" tabletDirection="horizontal" tabletHorizontalAlign="space-between" tabletVerticalAlign="center" gap={16}>
        <div>
          <PriceRowPrice
            size="M"
            price={tourExperience.price}
            saleUnit="person"
            signDisplay="always"
          />
          {countOccupants > 1 && <Caption variant="medium" colour="neutral-three">
            <FormatCurrency value={totalPrice} format="roundedDollar"/> total for all travellers
          </Caption>}
        </div>
        <TextButton kind="primary" disabled={!selectedTimeSlot} onClick={submitTimeSlot}>Add</TextButton>
      </Group>
    </ModalFooter>}
  </ModalBase>
}

export default TourExperienceModal
