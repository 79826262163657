import React, { useRef, useCallback, useContext } from 'react'
import CruiseWhenSelectContent from './CruiseWhenSelectContent'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import Group from 'components/utils/Group'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import CruiseFilterFooter from '../Common/CruiseFilterFooter'
import useToggle from 'hooks/useToggle'
import { CRUISE_FLEXIBLE_DURATION_RANGE } from 'constants/search'
import CruiseFilterInput from 'components/Cruises/SearchPage/Filters/Inputs/Common/CruiseFilterInput'

interface Props {
  inputValue: string
  isChipMode?: boolean
  initialFilters?: App.CruiseInitialFilters
  shouldIgnoreFlashOffers?: boolean
  onChange: (values: App.CruiseGlobalFilters) => void
}

function CruiseWhenSelectMobile({ inputValue, onChange, isChipMode, initialFilters, shouldIgnoreFlashOffers }: Props) {
  const dropdownTriggerRef = useRef<HTMLButtonElement>(null)
  const [isOpen, toggleModal] = useToggle(false)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const {
    flexibleMonths,
    durationMax,
    durationMin,
    durationRange,
    checkinDate,
    checkoutDate,
  } = useContext(GlobalSearchStateContext)

  const onClear = useCallback(() => {
    searchDispatch({ type: GlobalSearchStateActions.SET_DURATION_RANGE, min: undefined, max: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: '' })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION_RANGE, durationRange: [] })
  }, [searchDispatch])

  const onChangeWhen = useCallback((values: App.CruiseGlobalFilters) => {
    const { flexibleMonths, durationMax, durationMin, durationRange, checkinDate, checkoutDate } = values
    searchDispatch({ type: GlobalSearchStateActions.SET_DURATION_RANGE, min: durationMin, max: durationMax })
    searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: flexibleMonths! })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: checkinDate })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: checkoutDate })
    if (durationRange) {
      searchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION_RANGE, durationRange: durationRange as Array<CRUISE_FLEXIBLE_DURATION_RANGE> })
    }
  }, [searchDispatch])

  const handleOnApply = useCallback(() => {
    onChange({
      flexibleMonths,
      durationMax,
      durationMin,
      durationRange,
      checkinDate,
      checkoutDate,
    })
    toggleModal()
  }, [checkinDate, checkoutDate, durationMax, durationMin, durationRange, flexibleMonths, onChange, toggleModal])

  return <>
    <CruiseFilterInput
      isOpen={isOpen}
      testId="cruise-when-select-mobile-input"
      label="When"
      placeholder="Anytime"
      inputValue={inputValue}
      formFieldRef={dropdownTriggerRef}
      onClick={toggleModal}
      isChipMode={isChipMode}
    />

    <ModalBase
      mode="drawer"
      isOpen={isOpen}
      onClose={toggleModal}
    >
      <ModalHeader
        title="When"
        onCloseButtonClick={toggleModal}
      />
      <ModalBody>
        <Group direction="vertical" gap={24}>
          <CruiseWhenSelectContent onChange={onChangeWhen} initialFilters={initialFilters} drawerMode />
        </Group>
      </ModalBody>
      <ModalFooter>
        <CruiseFilterFooter
          onApply={handleOnApply}
          onClear={onClear}
          initialFilters={initialFilters}
          shouldIgnoreFlashOffers={shouldIgnoreFlashOffers}
        />
      </ModalFooter>
    </ModalBase>
  </>
}

export default CruiseWhenSelectMobile
