import { createSelector } from 'reselect'
import { EmptyArray } from 'lib/array/arrayUtils'
import { getVillaViews } from 'checkout/selectors/view/villa'
import getHotelEncryptedMargin from 'luxLoyalty/lib/margin/getHotelEncryptedMargin'

const getCheckoutVillasPointsCalculationRequests = createSelector(
  (state: App.State) => getVillaViews(state),
  (villaViews): Array<App.LuxLoyaltyPointsEarnCalculationRequest> => {
    if (!villaViews.hasRequiredData) return EmptyArray

    return villaViews.data.map((itemView) => ({
      price: itemView.totals.price,
      memberPrice: itemView.totals.memberPrice,
      luxLoyaltyProductType: 'rental',
      offerId: itemView.offer.id,
      packageId: itemView.package.id,
      mx: getHotelEncryptedMargin(itemView.offer.type, itemView.package),
      itemId: itemView.item.itemId,
    }))
  },
)

export default getCheckoutVillasPointsCalculationRequests
