import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FilterPanelRadioGroup from 'components/Common/FilterPanel/FilterPanelRadioGroup'
import { CABIN_CATEGORIES, CABIN_CATEGORIES_SORT_ORDER } from 'constants/cruise'
import { sortBy } from 'lib/array/arrayUtils'
import { buildPanelCheckItems } from 'components/Cruises/SearchPage/Filters/Inputs/CruiseExtraFiltersInputs'

interface Props {
  onChange: (nextFilters: { cabinTypes: string }) => void;
  facets: Array<App.CruiseSearchFacet>;
  cabinTypes: string | undefined;
}

function CruiseCabinTypeFilter({
  onChange,
  facets,
  cabinTypes,
}: Props) {
  const [cabinType, setCabinType] = useState(cabinTypes!)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const cabinItems = useMemo(() => {
    const filteredCabinType = facets.filter(facet => {
      return facet.category === 'cabin_types' && CABIN_CATEGORIES.some((item) => item.category === facet.name)
    })
    const cabinTypeFacets = sortBy(
      filteredCabinType.filter(facet => facet.category === 'cabin_types'),
      (facet) => {
        return CABIN_CATEGORIES_SORT_ORDER.findIndex((category) => category === facet.name)
      }, 'asc')
    return buildPanelCheckItems(cabinTypeFacets)
  }, [facets])

  const onCabinTypesDelayedFilterChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    setCabinType(e.target.value)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      onChange({ cabinTypes: e.target.value })
    }, 500)
  }, [onChange])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return <FilterPanelRadioGroup
    name="cabinTypes"
    items={cabinItems}
    onChange={onCabinTypesDelayedFilterChange}
    selected={cabinType}
    maxItems={5}
  />
}

export default CruiseCabinTypeFilter
