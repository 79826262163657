import React, { useContext } from 'react'
import FormatCurrency from 'components/Common/FormatCurrency/FormatCurrency'
import BodyText from 'components/Luxkit/Typography/BodyText'
import GeoContext from 'contexts/geoContext'
import { TOUR_ASIA_PROMO_REGIONS } from 'constants/tours'

function TourAsiaCampaignHeroCarouselSubtitle() {
  const { currentRegionCode, currentCurrency } = useContext(GeoContext)
  return <BodyText variant="medium" colour="neutral-one">Save up to <FormatCurrency format="dynamic" currency={currentCurrency} value={TOUR_ASIA_PROMO_REGIONS[currentRegionCode]} /> with code: <b>TOURASIA</b>. Limited time only.</BodyText>
}

export default TourAsiaCampaignHeroCarouselSubtitle
