import { ALL_GRAPH_RECOMMENDATION_OFFER_TYPES, ALL_GRAPH_RECOMMENDATION_OFFER_TYPES_NO_LPP } from 'constants/recommendations'
import { isLereEnabled } from 'lib/lere/isLereEnabled'
import { Recommendation, offerRecommendationMap } from './mappers/recommendationMap'
import { EmptyArray } from 'lib/array/arrayUtils'
import config from 'constants/config'
import debounce from 'lodash/debounce'
import qs from 'qs'
import request, { ObjectOptions } from 'api/requestUtils'
import { LERE_DEFAULT_USER_ID } from 'components/Recommendations/common/constant'

function getLereEnvParameters(): Record<string, string> {
  return config.LERE_USE_MOCKS ? { use_mocks: 'true' } : {}
}

export function getLereApiHostOverride(): string | undefined {
  return config.LERE_OVERRIDE_API_HOST || undefined
}

/**
 * A wrapper around the standard request such that it
 * 1. enforces a request timeout
 * 2. (optional) apply lere api host override (e.g. testing with local LERE server)
 * 3. pass user cred
 * 4. return a default response if LERE is disabled without actually calling the API (e.g. for whitelabel brands)
 */
async function do_request<R, D>(
  path: string,
  options: ObjectOptions = {},
  defaultResponse: R,
  data: D | undefined = undefined,
): Promise<App.ApiResponse<R>> {
  if (!isLereEnabled()) { return { result: defaultResponse, message: '' } }

  const controller = new AbortController()
  let timeoutId: NodeJS.Timeout | null = null
  if (options.timeout) {
    timeoutId = setTimeout(() => controller.abort(), options.timeout)
  }
  else if (config.LERE_API_ABORT_AFTER_MS) {
    timeoutId = setTimeout(() => controller.abort(), config.LERE_API_ABORT_AFTER_MS)
  }

  const request_options: ObjectOptions = {
    ...options,
    credentials: 'include',
    signal: controller.signal,
  }

  let response: App.ApiResponse<R>
  // currently we only use GET and POST, so it is ok to do a binary switch
  // if we need other methods we will need to further refactor this to find a better way
  if (data === undefined) {
    response = await request.get<App.ApiResponse<R>>(
      path,
      request_options,
      getLereApiHostOverride(),
    )
  } else {
    response = await request.post<App.ApiResponse<R>, D>(
      path,
      data,
      request_options,
      getLereApiHostOverride(),
    )
  }

  if (timeoutId) {
    clearTimeout(timeoutId)
  }

  return response
}

const lereAbortableRequest = {
  get: async function <R>(
    path: string,
    defaultResponse: R = [] as R,
    options: ObjectOptions = {},
  ) { return await do_request(path, options, defaultResponse) },
  post: async function <R, D>(
    path: string,
    data: D,
    defaultResponse: R = {} as R,
    options: ObjectOptions = {},
  ) { return await do_request(path, options, defaultResponse, data) },
}

interface RecommendationURLParams {
  check_in?: string;
  check_out?: string;
  available_month?: string;
  duration?: number;
  region?: string;
  allow_multi_offers_same_property?: boolean
  flex?: number;
}

class RecommendationsURLBuilder {
  // @ts-ignore
  private baseUrl: string
  private offerId: string
  private queryParams: Record<string, string | number | boolean> = {}

  constructor(offerId: string) {
    this.offerId = offerId
  }

  withCalendarBaseUrl() {
    this.baseUrl = `/api/recommendation/offers/${this.offerId}/calendar`
    return this
  }

  withBaseUrl() {
    this.baseUrl = `/api/recommendation/offers/${this.offerId}`
    return this
  }

  withQueryParams(queryParams: RecommendationURLParams) {
    this.queryParams = { ...this.queryParams, ...queryParams }
    return this
  }

  withLereEnvParameters() {
    this.queryParams = { ...this.queryParams, ...getLereEnvParameters() }
    return this
  }

  withRoomParams(rooms?: Array<App.Occupants>) {
    if (rooms?.length) {
      const occupancy = occupancyToStr(rooms)
      this.queryParams.occupancy = occupancy
    }
    return this
  }

  build() {
    return `${this.baseUrl}?${qs.stringify(this.queryParams)}`
  }
}

export function occupancyToStr(rooms: Array<App.Occupants>) {
  const occupancy = rooms.map(room => {
    let roomParam = `${room.adults}`
    if (room?.childrenAge?.length) {
      roomParam += `-${room.childrenAge.join(',')}`
    }
    return roomParam
  }).join(';')

  return occupancy
}

export function monthIndexToMonth(monthIndex: string) {
  const [monthStr, yearStr] = monthIndex.split('-')
  const month = parseInt(monthStr)
  const year = parseInt(yearStr)

  return `${month + 1}-${year}`
}

export interface Filters {
  checkIn?: string;
  availableMonth?: string;
  rooms?: Array<App.Occupants>;
  duration?: number;
  offerTypes?: Array<string>;
}

interface LereResult {
  recommendations: Array<App.Recommendation>;
  lereVersion: string | undefined;
}

export async function getRecommendations(offerId: string, region: string, isLuxPlusMember: boolean, allowMultiOffersSameProperty: boolean = false, checkIn?: string, checkOut?: string, occupancy?: string): Promise<LereResult> {
  const queryParams = {
    region,
    is_lux_plus_member: isLuxPlusMember,
    allow_multi_offers_same_property: allowMultiOffersSameProperty,
    occupancy: (occupancy && checkIn && checkOut) ? occupancy : undefined,
    check_in: (occupancy && checkIn && checkOut) ? checkIn : undefined,
    check_out: (occupancy && checkIn && checkOut) ? checkOut : undefined,
    offer_types: ALL_GRAPH_RECOMMENDATION_OFFER_TYPES.join(','),
  }
  const builder = new RecommendationsURLBuilder(offerId)
    .withLereEnvParameters()
    .withQueryParams(queryParams)
  const url = builder.withBaseUrl().build()
  const response = await lereAbortableRequest.get<Array<Recommendation>>(url)
  const { lere_version } = response.meta || {}

  const recommendations = response.result.map(recommendation => offerRecommendationMap(recommendation))
  return { recommendations, lereVersion: lere_version }
}

export function getChildrenAgesValid(rooms?: Array<App.Occupants>) {
  return (rooms || []).every((room) => {
    return (room.childrenAge || []).every((age) => age >= 0)
  })
}

export async function getCalendarRecommendations(
  offerId: string,
  region: string,
  isLuxPlusMember: boolean,
  filters: Filters,
): Promise<LereResult> {
  const { checkIn, rooms, availableMonth, duration } = filters

  if (!getChildrenAgesValid(rooms)) {
    throw new Error('Some children ages are < 0')
  }

  const useCalendarAPI = !checkIn

  const queryParams = {
    duration,
    region,
    is_lux_plus_member: isLuxPlusMember,
    allow_multi_offers_same_property: true,
    offer_types: ALL_GRAPH_RECOMMENDATION_OFFER_TYPES.join(','),
  }
  const builder = new RecommendationsURLBuilder(offerId)
    .withLereEnvParameters()
    .withQueryParams(queryParams)
    .withRoomParams(rooms)

  if (useCalendarAPI) {
    // call calendar reco API for flexible month
    builder.withCalendarBaseUrl().withQueryParams({ available_month: availableMonth })
  } else {
    // call offer seeded reco API for exact date
    builder.withBaseUrl().withQueryParams({ check_in: checkIn })
  }

  const url = builder.build()
  const response = await lereAbortableRequest.get<Array<Recommendation>>(url)
  const { lere_version } = response.meta || {}
  const recommendations = response.result.map(recommendation => offerRecommendationMap(recommendation))
  return { recommendations, lereVersion: lere_version }
}

export type PolygonMode = 'polygon_restricted' | 'polygon_excluded' | 'all_included'

interface LocationSeededParams {
  destinationId: string,
  polygonMode: PolygonMode,
  nearDestination: boolean,
  checkInDate?: string,
  checkOutDate?: string,
  duration?: number,
  flexibleDaysOnCheckIn?: number,
  useCentroid?: boolean,
  offerTypes?: Array<string>
}

export async function getLocationSeededRecommendations({
  destinationId,
  polygonMode,
  nearDestination,
  checkInDate,
  checkOutDate,
  duration,
  flexibleDaysOnCheckIn,
  useCentroid,
  offerTypes,
}: LocationSeededParams): Promise<LereResult> {
  const params = {
    ...getLereEnvParameters(),
    destination_id: destinationId,
    polygon_mode: polygonMode,
    near_destination: nearDestination,
    check_in: checkInDate,
    check_out: checkOutDate,
    duration,
    flex: flexibleDaysOnCheckIn,
    use_centroid: useCentroid,
    offer_types: offerTypes?.join(','),
  }

  const baseUrl = '/api/recommendation/locations'
  const url = [baseUrl, qs.stringify(params)].join('?')

  const response = await lereAbortableRequest.get<Array<Recommendation>>(url)
  const { lere_version } = response.meta || {}

  const recommendations = response.result.map(recommendation => offerRecommendationMap(recommendation))
  return { recommendations, lereVersion: lere_version }
}

/**
 * Get the list of abandoned carts (each time the user goes to checkout, we save the cart in BE)
 */
export async function getAbandonedCarts(region: string, domainUserId?: string, userId?: string) {
  const url = [
    '/api/recommendation/high_intent/abandoned_carts',
    qs.stringify({ region, le_user_id: userId, domain_user_id: domainUserId }),
  ].join('?')

  const response = await lereAbortableRequest.get<Array<LereHighIntent>>(url)

  const recommendations: Array<App.AbandonedCartRecommendation> = response.result.map(
    (lereOffer) => {
      const { offer_id, offer_type, creation_time, lere_version, ...rest } = lereOffer
      return {
        ...rest,
        offerId: offer_id,
        offerType: offer_type,
        creationTime: creation_time ?? 0,
        lereVersion: lere_version,
      } as App.AbandonedCartRecommendation
    },
  )

  const { lere_version } = response.meta || {}
  return { carts: recommendations, lereVersion: lere_version }
}

export async function saveAbanonedCarts(carts: Array<App.AbandonedCartRecommendation>, domainUserId?: string, userId?: string) {
  const lereCarts: Array<LereHighIntent> = carts
    .filter(cart => cart.items.length > 0)
    .map(cart => {
      const { offerId, offerType, creationTime, lereVersion, ...rest } = cart
      const lereCart: LereHighIntent = {
        ...rest,
        offer_id: offerId,
        offer_type: offerType,
        creation_time: creationTime,
        lere_version: lereVersion,
      }
      return lereCart
    })

  if (!lereCarts.length || (!domainUserId && !userId)) {
    return
  }

  // have to use without_brand=1 to disable the brand preprocessor since we are sending an array
  let url = '/api/recommendation/high_intent/abandoned_carts?without_brand=1'
  if (userId) {
    url += `&le_user_id=${userId}`
  }
  if (domainUserId) {
    url += `&domain_user_id=${domainUserId}`
  }
  return await lereAbortableRequest.post(
    url,
    lereCarts,
  ).catch(console.error)
}

async function saveAbandonedCart(cart: App.AbandonedCartRecommendation, domainUserId?: string, userId?: string) {
  return await saveAbanonedCarts([cart], domainUserId, userId)
}

/**
 * We use debounce to prevent frequent API calls when the cart is being updated rapidly during checkout
 */
export const saveCartInLereServiceWithDebounce = debounce(saveAbandonedCart, 500, { trailing: true })

export async function removeAbandonedCarts(offerIds: Array<string>, domainUserId?: string, userId?: string) {
  if (!offerIds.length || (!domainUserId && !userId)) {
    return
  }
  let url = '/api/recommendation/high_intent/abandoned_carts/remove?without_brand=1'
  if (userId) {
    url += `&le_user_id=${userId}`
  }
  if (domainUserId) {
    url += `&domain_user_id=${domainUserId}`
  }
  return await lereAbortableRequest.post(
    url,
    offerIds,
  ).catch(console.error)
}

// no bedbank
const plmSupportedOfferTypes: Array<App.OfferType> = [
  'hotel',
  'last_minute_hotel',
  'tactical_ao_hotel',
  'bundle_and_save',
  'tour',
  'direct_tour',
  'partner_tour',
  'connection_tour',
  'cruise',
]
const plmOfferTypeQuery = plmSupportedOfferTypes.join(',')

export async function getPeopleLikeMeRecommendations(userId: string, region: string, isLuxPlusMember: boolean = false) {
  const url = [
    `/api/recommendation/personalisation/${userId}/people_like_me`,
    qs.stringify({
      region,
      offer_types: plmOfferTypeQuery,
      is_lux_plus_member: isLuxPlusMember,
      ...getLereEnvParameters(),
    }),
  ].join('?')
  const response = await lereAbortableRequest.get<Array<LereRecommendation>>(url)

  let recommendations: Array<App.RecommendationOffer> = []
  if (response.result?.length) {
    recommendations = response.result.map(
      (lereOffer) => ({ offerId: lereOffer.offer_id, offerType: lereOffer.offer_type }),
    )
  }

  const { lere_version } = response.meta || {}
  return { offers: recommendations, lereVersion: lere_version }
}

export async function getTopDeals(region: string, isLuxPlusMember: boolean = false) {
  const url = ['/api/recommendation/top_deals', qs.stringify({ region, is_lux_plus_member: isLuxPlusMember, ...getLereEnvParameters() })].join('?')
  const response = await lereAbortableRequest.get<Array<LereRecommendation>>(url)

  const { lere_version } = response.meta || {}
  return { offers: response.result, lereVersion: lere_version }
}

export async function getHeroPlanner(region: string) {
  const url = ['/api/recommendation/hero_planner', qs.stringify({ region })].join('?')
  const response = await lereAbortableRequest.get<Array<string>>(url)

  const { lere_version } = response.meta || {}
  return { offerIds: response.result, lereVersion: lere_version }
}

interface LereHighIntent {
  offer_id: App.HighIntentOffer['offerId'];
  offerId?: App.HighIntentOffer['offerId'];
  offer_type: App.HighIntentOffer['offerType'];
  creation_time: App.HighIntentOffer['creationTime'];
  lere_version?: App.HighIntentOffer['lereVersion'];
  is_price_missing?: App.HighIntentOffer['isPriceMissing'];
}

// the order matters, front ones returned first, later ones get deduped
const HIGH_INTENT_FETCH_CATEGORIES = 'sticky_abandoned_carts,sticky_hot_leads,recently_viewed'
const HIGH_INTENT_FETCH_LIMIT = 15

export async function getHighIntentOffers(region: string, userId: string, domainUserId?: string) {
  const query = {
    region,
    limit: HIGH_INTENT_FETCH_LIMIT,
    categories: HIGH_INTENT_FETCH_CATEGORIES,
    aggregation_mode: true,
    domain_user_id: domainUserId,
  }
  const url = [`/api/recommendation/personalisation/${userId}`, qs.stringify(query)].join('?')

  const response = await lereAbortableRequest.get<Array<LereHighIntent>>(url)

  const { lere_version: requestLereVersion } = response.meta || {}

  const result = response.result ?? []
  const offers = result.map(lereOffer => {
    const { offer_id, offer_type, creation_time, lere_version, is_price_missing, ...rest } = lereOffer
    return {
      ...rest,
      offerId: offer_id ?? lereOffer.offerId, // backward compatibility
      offerType: offer_type,
      creationTime: creation_time ?? 0, // backward compatibility
      lereVersion: lere_version,
      isPriceMissing: is_price_missing ?? false,
    }
  })

  return { offers, lereVersion: requestLereVersion }
}

async function saveHotLeads(hotLeadOffers: Array<App.HotLeadOffer>, domainUserId?: string, userId?: string) {
  if (!hotLeadOffers.length || (!domainUserId && !userId)) { return }

  const lereHotLeadOffers = hotLeadOffers.map(hotLead => {
    const { offerId, offerType, creationTime, lereVersion, ...rest } = hotLead
    return {
      ...rest,
      offer_id: offerId,
      offer_type: offerType,
      creation_time: creationTime,
    }
  })
  let url = '/api/recommendation/high_intent/hot_leads?without_brand=1'
  if (userId) {
    url += `&le_user_id=${userId}`
  }
  if (domainUserId) {
    url += `&domain_user_id=${domainUserId}`
  }
  await lereAbortableRequest.post(
    url,
    lereHotLeadOffers,
  ).catch(console.error)
}

export const saveHotLeadsWithDebounce = debounce(saveHotLeads, 1000)

export async function removeHotLeads(offerIds: Array<string>, domainUserId?: string, userId?: string) {
  if (!offerIds.length || (!domainUserId && !userId)) { return }
  let url = '/api/recommendation/high_intent/hot_leads/remove?without_brand=1'
  if (userId) {
    url += `&le_user_id=${userId}`
  }
  if (domainUserId) {
    url += `&domain_user_id=${domainUserId}`
  }
  await lereAbortableRequest.post(
    url,
    offerIds,
  ).catch(console.error)
}

async function saveRecentlyViewedOffers(offers: Array<App.RecentlyViewedOffer>, region: string, isLuxPlusMember: boolean, domainUserId?: string, userId?: string) {
  if (!offers.length || (!domainUserId && !userId)) { return }

  const lereOffers: Array<LereHighIntent> = offers.map(offer => {
    const { offerId, offerType, creationTime, lereVersion, isPriceMissing, ...rest } = offer
    return {
      ...rest,
      offer_id: offerId,
      offer_type: offerType,
      creation_time: creationTime,
      is_price_missing: isPriceMissing,
    }
  })
  let url = `/api/recommendation/high_intent/recently_viewed?without_brand=1&region=${region}&is_lux_plus_member=${isLuxPlusMember}`
  if (userId) {
    url += `&le_user_id=${userId}`
  }
  if (domainUserId) {
    url += `&domain_user_id=${domainUserId}`
  }
  await lereAbortableRequest.post(
    // we need to post an array and have to disable the brand pre-processor
    // else it will turn the request body into an object
    url,
    lereOffers,
  ).catch(console.error)
}

export const saveRecentlyViewedOffersWithDebounce = debounce(saveRecentlyViewedOffers, 1000)

export async function removeRecentlyViewedOffers(offerIds: Array<string>, domainUserId?: string, userId?: string) {
  if (!offerIds.length || (!domainUserId && !userId)) { return }
  let url = '/api/recommendation/high_intent/recently_viewed/remove?without_brand=1'
  if (userId) {
    url += `&le_user_id=${userId}`
  }
  if (domainUserId) {
    url += `&domain_user_id=${domainUserId}`
  }
  await lereAbortableRequest.post(
    // we need to post an array and have to disable the brand pre-processor
    // else it will turn the request body into an object
    url,
    offerIds,
  ).catch(console.error)
}

interface LereRecommendation {
  offer_id: App.RecommendationOffer['offerId'];
  offer_type: App.RecommendationOffer['offerType'];
  distance?: App.RecommendationOffer['distance'];
}

function mapLereRecommendation(lereOffer: LereRecommendation): App.RecommendationOffer {
  const { offer_id, offer_type, distance } = lereOffer

  return {
    offerId: offer_id,
    offerType: offer_type,
    ...(distance ? { distance } : {}),
  }
}

interface LereDestination {
  destination_id: string;
  destination_type: string;
  score?: number
}

function mapLereDestination(lereDestination: LereDestination): App.RecommendationDestination {
  return ({
    destinationId: lereDestination.destination_id,
    destinationType: lereDestination.destination_type,
    score: lereDestination.score,
  })
}

export interface AlternativeOfferEnquiry {
  offer_id: App.RecommendationOffer['offerId'];
  check_in?: string;
  check_out?: string;
}

export interface AlternativeTourEnquiry {
  offer_id: App.RecommendationOffer['offerId'];
}

interface AlternativeEnquiryPayload {
  alternatives?: {
    hot_leads?: Array<AlternativeOfferEnquiry>;
    recently_viewed?: Array<AlternativeOfferEnquiry>;
  }
  tours?: {
    prioritize_signature: boolean,
    tour_inputs: Array<AlternativeTourEnquiry>;
  }
}

const ALTERNATIVE_FETCH_LIMIT = 18
const ALTERNATIVE_MODEL_VERSION = '2_0_0'

export async function getPersonalisedAlternativeRecommendations(userId: string, region: string, isLuxPlusMember: boolean, domainUserId?: string) {
  const query = {
    without_brand: 1,
    region,
    is_lux_plus_member: isLuxPlusMember,
    limit: ALTERNATIVE_FETCH_LIMIT,
    categories: 'alternatives',
    model_versions: ALTERNATIVE_MODEL_VERSION,
    offer_types: ALL_GRAPH_RECOMMENDATION_OFFER_TYPES_NO_LPP.join(','),
    domain_user_id: domainUserId,
    ...getLereEnvParameters(),
  }

  const url = [`/api/recommendation/personalisation/${userId}`, qs.stringify(query)].join('?')

  const response = await lereAbortableRequest.post(url, {}, { alternatives: [] })

  const { lere_version: lereVersion } = response.meta || {}

  const alternatives = response.result.alternatives ?? []
  const offers: Array<App.RecommendationOffer> = alternatives.map(mapLereRecommendation)

  return { offers, lereVersion }
}

interface LerePreference {
  preference: Array<LereRecommendation>
}
export async function getPreferenceRecommendations(region: string, userId: string) {
  const query = {
    region,
    categories: 'preference',
    ...getLereEnvParameters(),
  }
  const url = [`/api/recommendation/personalisation/${userId}`, qs.stringify(query)].join('?')
  const response = await lereAbortableRequest.get<LerePreference>(url)

  let recommendations: Array<App.RecommendationOffer> = []
  if (response.result?.preference?.length) {
    recommendations = response.result.preference.map(
      (lereOffer) => ({ offerId: lereOffer.offer_id, offerType: lereOffer.offer_type }),
    )
  }

  const { lere_version } = response.meta || {}
  return { offers: recommendations, lereVersion: lere_version }
}

interface LereOfferStats {
  offer_value: number | null;
}

export async function getOfferStats(offerId: string, region: string) {
  const response = await lereAbortableRequest.get<LereOfferStats>(
    `/api/recommendation/offers/${offerId}/stats?region=${region}`,
    { offer_value: null },
  )

  const lereStats = response.result
  const offerStats = {
    offerValue: lereStats.offer_value,
  }

  return { offerStats }
}

export const MAX_LERE_NEARBY_OFFERS = 30

export async function getNearbyRecommendations(placeId: string, region: string, isLuxPlusMember: boolean, occupancy?: Array<App.Occupants>, checkIn?: string, checkOut?: string) {
  const params = {
    ...getLereEnvParameters(),
    destination_id: placeId,
    region,
    is_lux_plus_member: isLuxPlusMember,
    occupancy: (occupancy && checkIn && checkOut) ? occupancyToStr(occupancy) : undefined,
    check_in: (occupancy && checkIn && checkOut) ? checkIn : undefined,
    check_out: (occupancy && checkIn && checkOut) ? checkOut : undefined,
    limit: MAX_LERE_NEARBY_OFFERS,
  }
  const baseUrl = '/api/recommendation/locations/nearby_high_value_offers'
  const url = [baseUrl, qs.stringify(params)].join('?')

  const response = await lereAbortableRequest.get<Array<LereRecommendation>>(url)

  const { lere_version: lereVersion } = response.meta || {}
  const offers = response.result.map(mapLereRecommendation)
  return { offers, lereVersion }
}

const TOUR_FETCH_LIMIT = 20

export async function getRecommendedTours(
  offerId: string,
  region: string,
  departureYear?: number,
  departureMonth?: number,
  offerTypes?: Array<App.Tours.V2ProductType>,
) {
  const query = {
    ...getLereEnvParameters(),
    limit: TOUR_FETCH_LIMIT,
    region,
    ...(!!departureYear && departureMonth !== undefined) ? {
      departure_year: departureYear,
      departure_month: departureMonth + 1, // LERE API use 1-12 for month
    } : {},
    ...(offerTypes) ? {
      offer_types: offerTypes.join(','),
    } : {},
  }
  const url = [`/api/recommendation/tours/${offerId}`, qs.stringify(query)].join('?')
  const response = await lereAbortableRequest.get<Array<LereRecommendation>>(url)

  const { lere_version: lereVersion } = response.meta || {}

  const offers = response.result.map(mapLereRecommendation)

  return { offers, lereVersion }
}

export async function enquirePersonalisedTourRecommendations(userId: string, region: string, isLuxPlusMember: boolean, tourEnquiryArray: Array<AlternativeTourEnquiry> = []) {
  if (userId !== LERE_DEFAULT_USER_ID && tourEnquiryArray.length === 0) {
    return { offers: EmptyArray, lereVersion: '' }
  }

  const query = {
    without_brand: 1,
    region,
    is_lux_plus_member: isLuxPlusMember,
    limit: ALTERNATIVE_FETCH_LIMIT,
    categories: 'tours',
    offer_types: 'direct_tour',
    ...getLereEnvParameters(),
  }

  const url = [`/api/recommendation/personalisation/${userId}`, qs.stringify(query)].join('?')

  // if the userId is using the default id, means the user does not loggin.
  // and we will use the tour enquiries from local storage to get the tour recommendations
  const payload: AlternativeEnquiryPayload = {
    tours: {
      prioritize_signature: false,
      tour_inputs: userId === LERE_DEFAULT_USER_ID ? tourEnquiryArray : [],
    },
  }

  const response = await lereAbortableRequest.post(url, payload, { tours: [] })

  const { lere_version: lereVersion } = response.meta || {}

  const tours = response.result.tours ?? []
  const offers: Array<App.RecommendationOffer> = tours.map(mapLereRecommendation)

  return { offers, lereVersion }
}

export async function getRegionRecommendations(region: string) {
  const query = {
    categories: 'region_reco',
    region,
    ...getLereEnvParameters(),
  }
  const url = `/api/recommendation/enquiry?${qs.stringify(query)}`
  const response = await lereAbortableRequest.post(url, { region_reco: {} }, { region_reco: { result: [] } })
  const lereVersion: string = response.meta.lere_version
  const offers: Array<App.RecommendationOffer> = response.result.region_reco.result.map(mapLereRecommendation)

  return { offers, lereVersion }
}

export async function getRecommendedDestinations(region: string, domainUserId?: string, leUserId?: string) {
  const categoryTitle = 'recommended_destinations'
  const query = {
    categories: categoryTitle,
    region,
    ...getLereEnvParameters(),
  }
  const body = {
    [categoryTitle]: {
      domain_user_id: domainUserId,
      le_user_id: leUserId,
    },
  }
  const url = `/api/recommendation/enquiry?${qs.stringify(query)}`
  const response = await lereAbortableRequest.post(url, body, { [categoryTitle]: { result: [] } }, { timeout: 1500 })
  const lereVersion: string = response.meta.lere_version
  const destinations: Array<App.RecommendationDestination> = response.result[categoryTitle].result.map(mapLereDestination)

  return { destinations, lereVersion }
}
