import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import SvgIcon from '../Luxkit/SvgIcon'
import { TYPOGRAPHY_ICON_SIZE_CSS_VAR, TypographyElement } from '../Luxkit/Typography/Typography'
import devOnlyCss, { InvalidElementStyle } from 'lib/styles/devOnlyCss'

const Container = styled.span`
  display: inline-grid;
  grid-template: 1ex / var(${TYPOGRAPHY_ICON_SIZE_CSS_VAR});
  place-items: center;
  vertical-align: middle;
  overflow: visible;

  ${devOnlyCss`
    &:not(${TypographyElement} &) { ${InvalidElementStyle('<InlineIcon> should be child of a typography')} }
  `}
`

interface Props extends Omit<ComponentProps<typeof SvgIcon>, 'size' | 'height' | 'width' | 'className'> {
  Icon: typeof SvgIcon
}

/**
 * Allows a given icon component to sit inline as a glyph with the rest of the text in typography.
 *
 * It supports all icon props except for `size`.
 *
 * `size` is dictated by the typography parent.
 */
function InlineIcon({ Icon, ...iconProps }: Props) {
  return <Container>
    <Icon {...iconProps} />
  </Container>
}

export default InlineIcon
