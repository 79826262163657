import FormatCurrency from 'components/Common/FormatCurrency'
import List from 'components/Common/List'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import Modal from 'components/Luxkit/Modal/Modal'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import ModalContext from 'contexts/ModalContext'
import React, { ComponentProps, MouseEventHandler, useCallback, useContext } from 'react'
import * as OFFER_TYPES from 'constants/offer'

interface Props {
  size: 'M' | 'L'
  commissionPercentage: number
  productType: App.ProductType
  offerTotal?: number
  multiplePackages?: boolean
  greatestCommission: number
  minBaseCommission?: number
  maxBaseCommission?: number
}

const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof Caption>['variant']> = {
  M: 'medium',
  L: 'large',
}

const displayProductTypeMap: Partial<Record<App.ProductType, string>> = {
  [OFFER_TYPES.OFFER_TYPE_BED_BANK]: 'hotel',
  [OFFER_TYPES.OFFER_TYPE_HOTEL]: 'hotel',
  [OFFER_TYPES.OFFER_TYPE_ALWAYS_ON]: 'hotel',
  [OFFER_TYPES.PRODUCT_TYPE_TOUR_ULTRA_LUX]: 'tour',
  [OFFER_TYPES.PRODUCT_TYPE_TOUR_PREMIUM]: 'tour',
  [OFFER_TYPES.PRODUCT_TYPE_TOUR_DELUXE]: 'tour',
  [OFFER_TYPES.PRODUCT_TYPE_TOUR_V2]: 'tour',
  [OFFER_TYPES.PRODUCT_TYPE_CRUISE_FLASH]: 'cruise',
}

function PriceRowCommission(props: Props) {
  const {
    size,
    commissionPercentage,
    productType,
    offerTotal = 0,
    multiplePackages = false,
    greatestCommission,
    minBaseCommission,
    maxBaseCommission,
  } = props

  const displayEstimate = multiplePackages || (offerTotal === 0 && productType === OFFER_TYPES.OFFER_TYPE_BED_BANK)

  const hasTotal = offerTotal > 0

  const showModal = useContext(ModalContext)
  const openModal = useCallback<MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()
    showModal(<Modal title="Commission details">
      <VerticalSpacer gap={8}>
        <BodyText variant="large">
          {displayEstimate && <>Earn up to {Math.max(maxBaseCommission || 0, greatestCommission)}% on this deal</>}
          {!displayEstimate && <>Earn a {commissionPercentage}% commission on this deal</>}
        </BodyText>
        <BodyText variant="large" weight="bold">
          How it works:
        </BodyText>
        <List>
          <BodyText variant="large" as="li">
            Your commission is calculated from the total booking amount for this {displayProductTypeMap[productType] || productType}.
          </BodyText>
          {productType === OFFER_TYPES.OFFER_TYPE_BED_BANK && <>
            <BodyText variant="large" as="li">
              Commission is variable for this offer and will only be calculated once dates have been selected and rates returned by our suppliers
            </BodyText>
            <BodyText variant="large" as="li">
              Once the rates are returned, we will calculate the commission which will range from {minBaseCommission}% to {maxBaseCommission}%
            </BodyText>
          </>}
          <BodyText variant="large" as="li">
            Make the net payment, which accounts for the deduction of the commission from your total booking cost.
          </BodyText>
          <BodyText variant="large" as="li">
            Easily monitor your commissions in real-time through your agent dashboard.
          </BodyText>
        </List>
      </VerticalSpacer>
    </Modal>)
  }, [commissionPercentage, productType, showModal, greatestCommission, minBaseCommission, maxBaseCommission, displayEstimate])

  if (productType === OFFER_TYPES.OFFER_TYPE_BED_BANK && commissionPercentage > 0) {
    return <Caption
      className={PriceRowCommission.name}
      variant={SIZES_CAPTION_VARIANTS[size]}
    >
      <TextLink
        variant="highlight"
        endIcon={<SolidInfoCircleIcon colour="neutral-one" />}
        onClick={openModal}
      >
        Earn up to {commissionPercentage}%+ commission
      </TextLink>
    </Caption>
  }

  return <Caption
    className={PriceRowCommission.name}
    variant={SIZES_CAPTION_VARIANTS[size]}
  >
    <TextLink
      variant="highlight"
      endIcon={<SolidInfoCircleIcon colour="neutral-one" />}
      onClick={openModal}
    >
      {multiplePackages ? 'Earn up to' : 'Earn'} {hasTotal ? <FormatCurrency value={offerTotal * (greatestCommission / 100)} /> : <>{greatestCommission}%</>} commission {hasTotal && `(${greatestCommission}%)`}
    </TextLink>
  </Caption>
}

export default PriceRowCommission
