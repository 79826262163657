import LuxLoyaltyTierIcon from 'components/Luxkit/Icons/luxLoyalty/LuxLoyaltyTierIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import React, { ComponentProps } from 'react'

interface Props {
  tier: App.LuxLoyaltyTier
  size: 'S' | 'M'
}

const SIZES_BODY_TEXT_VARIANTS: Record<Props['size'], ComponentProps<typeof BodyTextBlock>['variant']> = {
  S: 'small',
  M: 'medium',
}

function PriceRowLuxLoyaltyPointsLoadingSkeleton({ tier, size }: Props) {
  return <BodyTextBlock
    variant={SIZES_BODY_TEXT_VARIANTS[size]}
    colour="neutral-one"
    weight="bold"
    startIcon={<LuxLoyaltyTierIcon size="S" tier={tier} />}
  >
    <TextLoadingBox width="20ch" lines={1} />
  </BodyTextBlock>
}

export default PriceRowLuxLoyaltyPointsLoadingSkeleton
