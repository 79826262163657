import { createSelector } from 'reselect'
import { findPostPurchaseCheckout, isBedbankItem } from '../lib/checkout/checkoutUtils'

import { BEDBANK_CALENDAR_FUTURE_DAYS } from 'lib/datetime/calendarUtils'
import { addDays, pad } from 'lib/datetime/dateUtils'
import { MONTH_NAMES } from 'constants/dates'
import { getBedbankRateKey } from 'actions/BedbankOfferActions'
import { getBedbankItemView } from '../../checkout/lib/utils/bedbank/view'
import { groupBy, sum } from 'lib/array/arrayUtils'
import { getAllOffers } from 'selectors/offerSelectors'
import { getExistingOrderExperienceCalendarDates } from 'lib/experiences/experienceUtils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { getIsLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import { getAccommodationItems } from 'checkout/selectors/view/hotels'
import { OptimizelyFeatureFlags } from 'constants/optimizely'

interface ChangeDatesBaseCalendar {
  [monthKey: string]: App.BedbankCalendarMonth;
}

export const isBedbankOfferCached = createSelector(
  (state: App.State) => state.offer.offersLoading,
  (state: App.State) => state.offer.bedbankOffers,
  (state: App.State, offerId: string) => offerId,
  (offersLoading, offersLoaded, offerId) => !!(offersLoading[offerId] || offersLoaded[offerId]),
)

export const getPropertyIdIfBestOfferRedirectRequired = createSelector(
  (state: App.State, offerId: string) => state.offer.bedbankOffers[offerId],
  (offer) => offer?.property?.id,
)

export const getPropertyBestAlternateOfferIds = createSelector(
  (state: App.State, offerId: string) => getPropertyIdIfBestOfferRedirectRequired(state, offerId),
  (state: App.State) => state.offer.alternativeOffersForProperty,
  (propertyId, alternativeOffersForProperty) => {
    if (propertyId) {
      return alternativeOffersForProperty[propertyId]
    }
  },
)

export const getPropertyBestAlternateOffer = createSelector(
  (state: App.State, offerId: string) => getPropertyBestAlternateOfferIds(state, offerId),
  (state: App.State) => state.offer.offers,
  (alternatePropertyOffers, offers) => {
    if (alternatePropertyOffers?.le && alternatePropertyOffers.le.length > 0) {
      let alt = alternatePropertyOffers.le.find((offer:string) => offers[offer]?.type === 'hotel')
      if (!alt) {
        alt = alternatePropertyOffers.le[0]
      }
      if (offers[alt]) {
        return offers[alt]
      }
    }
  },
)

export const getPropertyBestAlternateURL = createSelector(
  (state: App.State, offerId: string) => getPropertyBestAlternateOffer(state, offerId),
  (state: App.State) => state.geo.currentRegionCode,
  (offer, regionCode) => {
    if (offer) {
      return `/${regionCode.toLowerCase()}/offer/${offer.slug}/${offer.id}`
    }
  },
)

export const getChangeDatesCalendar = createSelector(
  (state: App.State) => state.checkout.cart.items.filter(isBedbankItem),
  (state: App.State) => state.offer.bedbankOfferRates,
  (state: App.State) => state.businessTraveller.offersCredits,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.checkout.sessions,
  (state: App.State) => getAllOffers(state),
  (state: App.State) => getIsLuxPlusLPPEnabled(state),
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (bedbankCartItems, bedbankRatesByOffer, offersCredits, postPurchase, existingOrder, sessions, allOffers, luxPlusLPPEnabled, optimizelyFeatureFlags) => {
    const isFeatureEnabled = !!optimizelyFeatureFlags[OptimizelyFeatureFlags.opexDifferentRatesChangeDatesBedbankEnabled]
    const firstOrderItem = existingOrder?.bedbankItems.find(item => item.status !== 'cancelled')
    const firstCartItem = bedbankCartItems[0]
    const offerId = firstCartItem?.offerId
    const duration = firstCartItem?.duration ?? 1
    const occupancy = bedbankCartItems.map(item => item.occupancy)
    const offer = allOffers[offerId] as App.BedbankOffer
    const experiencesWithDate = getExistingOrderExperienceCalendarDates(existingOrder)

    const output: ChangeDatesBaseCalendar = {}

    for (let day = 0; day <= BEDBANK_CALENDAR_FUTURE_DAYS; day++) {
      const currentDay = addDays(new Date(), day)
      // use local date format to avoid timezone jump issues
      const month = currentDay.getMonth()
      const year = `${currentDay.getFullYear()}`
      const date = currentDay.getDate()
      const monthText = MONTH_NAMES[month]
      const monthKey = `${year}-${pad(month + 1)}`
      const checkIn = moment(currentDay).format(ISO_DATE_FORMAT)
      const checkOut = moment(addDays(currentDay, duration ?? 1)).format(ISO_DATE_FORMAT)
      let bedbankRatesLoaded = false

      // check if rates already loaded in state
      const rateKey = getBedbankRateKey(occupancy, checkIn, checkOut)
      const bedbankOfferRates = bedbankRatesByOffer[offerId]
      const bedbankOfferRatesByKey = bedbankOfferRates?.[rateKey]
      bedbankRatesLoaded = bedbankOfferRates && (rateKey in bedbankOfferRates)
      const bedbankOfferRate = bedbankOfferRatesByKey?.find(rate => rate.id === firstOrderItem?.roomRateId)
      let price
      let upgradePrice
      let upgradeRate: App.BedbankRate | undefined

      if (bedbankRatesLoaded && bedbankOfferRate) {
        const itemViews = bedbankCartItems.map(item => {
          return getBedbankItemView(item, luxPlusLPPEnabled, sessions[item.sessionId ?? 'none']?.data ?? null, offer, bedbankOfferRate, offersCredits, postPurchase, existingOrder, bedbankRatesLoaded)
        })
        price = sum(itemViews.map(item => item.data.totals.price))
      } else if (bedbankRatesLoaded && bedbankOfferRatesByKey.length) {
        const cheapestRate = bedbankOfferRatesByKey.sort((a, b) => a.totals.inclusive - b.totals.inclusive)[1]
        const ratePriceDiff = cheapestRate?.totals?.inclusive - (firstOrderItem?.total ?? 0)
        upgradePrice = ratePriceDiff >= 0 ? ratePriceDiff : undefined
        upgradeRate = ratePriceDiff >= 0 ? cheapestRate : undefined
      }
      // create month key object if it doesn't exist
      if (!output[monthKey]) {
        output[monthKey] = {
          key: monthKey,
          month: monthText,
          year,
          days: [] as Array<App.BedbankCalendarDay>,
          hasPromo: false,
        }
      }

      // update with current day
      output[monthKey].days.push({
        day: date,
        month: monthText,
        year: year.toString(),
        checkIn,
        checkOut,
        hasPromo: false,
        price,
        rate: bedbankOfferRate,
        availability: {
          loaded: bedbankRatesLoaded,
          hasAvailability: !!bedbankOfferRate,
          hasUpgradeAvailability: isFeatureEnabled && !bedbankOfferRate && !!upgradeRate,
        },
        upgradePrice,
        upgradeRate,
        hasExperiences: experiencesWithDate.has(checkIn),
        monthKey,
        isCorporate: false,
      } as App.BedbankCalendarDay)
    }

    return output
  },
)

export const getBedbankItemsTotalPricingPerOfferDates = createSelector(
  (state: App.State) => state.checkout.cart.items.filter(isBedbankItem),
  (state: App.State) => state.offer.bedbankOfferRates,
  (state: App.State) => state.businessTraveller.offersCredits,
  (state: App.State) => state.checkout.sessions,
  (state: App.State) => getAllOffers(state),
  (state: App.State) => getIsLuxPlusLPPEnabled(state),
  (bedbankCartItems, bedbankRatesByOffer, offersCredits, sessions, allOffers, luxPlusLPPEnabled): Map<string, number> => {
    const bedbankPricingPerOfferDates = new Map<string, number>()
    const bedBankItemPerDate = groupBy<string, App.Checkout.BedbankHotelItem>(
      bedbankCartItems,
      (item) => `${item.offerId}-${item.checkIn}-${item.checkOut}`,
    )
    bedBankItemPerDate.forEach((items, key) => {
      const firstItem = items[0]
      const offerId = firstItem?.offerId
      const offer = allOffers[offerId] as App.BedbankOffer
      const { checkIn, checkOut } = firstItem
      const occupancy = items.map(item => item.occupancy)
      const rateKey = getBedbankRateKey(occupancy, checkIn, checkOut)
      const bedbankOfferRates = bedbankRatesByOffer[offerId]?.[rateKey]
      const bedbankRatesLoaded = bedbankRatesByOffer[offerId] && (rateKey in bedbankRatesByOffer[offerId])
      const bedbankOfferRate = bedbankOfferRates?.find(rate => rate.id === firstItem.roomRateId)
      let price = 0
      if (bedbankOfferRate) {
        const itemViews = items.map(item => {
          return getBedbankItemView(item, luxPlusLPPEnabled, sessions[item.sessionId ?? 'none']?.data ?? null, offer, bedbankOfferRate, offersCredits, undefined, undefined, bedbankRatesLoaded)
        })
        price = sum(itemViews.map(item => item.data.totals.price - item.data.totals.taxesAndFees))
      }
      bedbankPricingPerOfferDates.set(key, price)
    })
    return bedbankPricingPerOfferDates
  },
)

export const isBedbankRefundable = createSelector(
  getAccommodationItems,
  (state: App.State) => state.offer.bedbankOfferRates,
  (accomodationItems, bedbankRatesByOffer) =>
  {
    const bedBankItems = accomodationItems.filter(isBedbankItem)
    // every of an empty array is true, hence check is not empty
    if (bedBankItems.length && bedBankItems.every(item => {
      const rateKey = getBedbankRateKey(bedBankItems.map(item => item.occupancy), item.checkIn, item.checkOut)
      const bedbankOfferRates = bedbankRatesByOffer[item.offerId]?.[rateKey]

      // get if roomrate is refundable which is a permutation of date range, occupancy and number of rooms
      return !!bedbankOfferRates?.some(rate => rate.id === item.roomRateId && rate.refundable)
    })) {
      return true
    }

    return false
  },
)
