import { objectKeys } from 'lib/object/objectUtils'

function getValidBusinessBudgetObjectKeys(businessBudget: App.BusinessTraveller.ToBeCalculatedBusinessBudget) {
  return objectKeys(businessBudget)
    .filter((key) => businessBudget[key] !== null && businessBudget[key] !== undefined)
    .sort()
}

function generateBusinessBudgetKey(
  toBeCalculatedBusinessBudget: App.BusinessTraveller.ToBeCalculatedBusinessBudget,
): string {
  const budgetKey = JSON.stringify(
    toBeCalculatedBusinessBudget,
    getValidBusinessBudgetObjectKeys(toBeCalculatedBusinessBudget),
  )
  return budgetKey
}

export default generateBusinessBudgetKey
