export interface BenefitCtaConfig {
  path?: string;
  /**
   * Whether the benefit has a scroll element to scroll to
   */
  hasScrollElement?: boolean;
}

export const SUPPORTED_BENEFIT_TYPES: Array<App.LuxPlusBenefitType> = [
  'discounted_flash',
  'discounted_insurance',
  'discounted_tours',
  'early_access',
  'exclusive_offers',
  'hotel_inclusions',
  'priority_support',
  'cruise_inclusions',
]

export const BENEFITS_CTA_CONFIG: Record<App.LuxPlusBenefitType, BenefitCtaConfig> = {
  hotel_inclusions: {
    hasScrollElement: true,
  },
  discounted_flash: {
    hasScrollElement: true,
  },
  exclusive_offers: {
    hasScrollElement: true,
  },
  priority_support: {},
  discounted_tours: {
    hasScrollElement: true,
  },
  early_access: {
    hasScrollElement: true,
  },
  cruise_inclusions: {
    hasScrollElement: true,
  },
  discounted_insurance: {
    path: '/travel-protection',
  },
}

export const SAVE_THOUSANDS_REGIONS = ['ZA', 'TH', 'KR', 'ID', 'JP', 'TW', 'PH', 'VN']
