import React, { useMemo } from 'react'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import { useSearchPrices } from 'hooks/Search/useSearchPrices'
import { getMinimumDurationFromFlexibleNights, isSearchStreamingSupported } from 'lib/search/searchUtils'
import { FLEXIBLE_DURATION_RANGE } from 'constants/search'
import useDefaultAirport from 'hooks/useDefaultAirport'
import { generateLuxLoyaltyPointsCalculatorBundledFlightOptions, generateLuxLoyaltyPointsCalculatorHotelOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'

interface Props {
  offer: App.HotelOffer | App.HotelOfferSummary
  bestPackage?: App.HotelPackage
  bestAvailableRate?: App.OfferAvailableRate
  filters?: App.OfferListFilters
}

function HotelSearchTileEarnablePoints(props: Props) {
  const { offer, bestPackage, bestAvailableRate, filters } = props
  const isSearchStreamingEnabled = isSearchStreamingSupported(filters)
  const duration = filters?.flexibleMonths && filters.flexibleNights ? getMinimumDurationFromFlexibleNights(filters.flexibleNights as FLEXIBLE_DURATION_RANGE) : offer.lowestPricePackage?.duration
  const defaultAirport = useDefaultAirport()
  const flightPrice = (offer.flightPrices[defaultAirport?.code ?? ''] || 0)

  const {
    defaultPricing,
    memberPricing,
  } = useSearchPrices({
    offer,
    pkg: bestPackage,
    rate: bestAvailableRate,
    duration,
    filters,
    useRealSearchPrices: isSearchStreamingEnabled,
  })

  const defaultPrice = defaultPricing.price
  const memberPrice = memberPricing?.price ?? 0

  const propertyFees = defaultPricing.fees?.find((fee) => fee.type === 'property')?.amount ?? 0

  const defaultPriceExcludingPropertyFees = defaultPrice - propertyFees
  const memberPriceExcludingPropertyFees = memberPrice > 0 ? memberPrice - propertyFees : 0

  const pointsEarnCalculationRequests = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>>(() => [
    generateLuxLoyaltyPointsCalculatorHotelOptions(offer, {
      pkg: bestPackage,
      price: defaultPriceExcludingPropertyFees,
      memberPrice: memberPriceExcludingPropertyFees,
    }),
    generateLuxLoyaltyPointsCalculatorBundledFlightOptions(offer, {
      flightPrice,
      skip: !offer.bundledWithFlightsOnly,
    }),
  ], [offer, bestPackage, defaultPriceExcludingPropertyFees, memberPriceExcludingPropertyFees, flightPrice])

  return <LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />
}

export default HotelSearchTileEarnablePoints
