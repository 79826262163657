import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK, OFFER_TYPE_HOTEL, OFFER_TYPE_VILLA } from 'constants/offer'

const supportedHolidayTypes = [
  'All-inclusive',
  'Family Friendly',
  'Ski',
  'Theme Park',
  'Honeymoon',
  'Adults-only',
  'Beach',
  undefined,
]

export function getDescription({ destinationLandingPageType, placeName, holidayType }: { destinationLandingPageType: DestinationLandingPageType, placeName: string, holidayType: string }) {
  const year = new Date().getFullYear()
  if (destinationLandingPageType === DestinationLandingPageType.HolidayPackages) {
    return (placeName ? placeName + ' ' : '') + (holidayType ? holidayType + ' ' : '') + 'Holiday Package deals for travel in ' + year + '/' + (year + 1) + '. Save on luxury hotel + flight bundles. Get exclusive inclusions.'
  } else if (destinationLandingPageType === DestinationLandingPageType.Resorts) {
    return 'Luxury ' + (placeName ? placeName + ' ' : '') + 'Resorts with exclusive inclusions. Book now for ' + year + '/' + (year + 1) + ' travel.'
  } else {
    throw new Error('getDescription:Invalid destinationLandingPageType')
  }
}

export function capitalizeWords(input: string) {
  return input
    .split(' ') // Split by spaces
    .map(word =>
      word
        .split('-') // Split hyphenated words
        .map((part, index) =>
          index === 0 ?
            part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() : // Capitalize only the first part
            part.toLowerCase(), // Keep subsequent parts lowercase
        )
        .join('-'), // Rejoin hyphenated words
    )
    .join(' ') // Rejoin the entire string
}

// e.g. `all+inclusive` -> `All-inclusive`
// '+' is used to represent ' ' in the url
export function decodeFromUrlString(urlString: string) {
  return capitalizeWords(urlString.replace(/\+/g, ' '))
}

export function validateHolidayType(holidayType: string) {
  if (supportedHolidayTypes.includes(holidayType)) {
    return true
  }
  return false
}

export enum DestinationLandingPageType {
  HolidayPackages = 'holiday-packages',
  Resorts = 'resorts',
}

export function getDestinationLandingPageType(path: string): DestinationLandingPageType {
  if (path.startsWith('/:regionCode/holiday-packages/')) {
    return DestinationLandingPageType.HolidayPackages
  } else if (path.startsWith('/:regionCode/resorts/')) {
    return DestinationLandingPageType.Resorts
  } else {
    throw new Error('getDestinationLandingPageType:Invalid path')
  }
}

interface getFiltersProps {
  filters: App.OfferListFilters,
  destinationLandingPageType: DestinationLandingPageType,
  hasHolidayType: boolean,
  urlHolidayType: string,
  place: { id?: string }
}

export function getFilters({
  filters,
  destinationLandingPageType,
  hasHolidayType,
  urlHolidayType,
  place,
}: getFiltersProps): App.OfferListFilters {
  const holidayTypes = hasHolidayType ? [decodeFromUrlString(urlHolidayType)] : []
  const destinationId = place?.id ?? ''
  if (destinationLandingPageType === DestinationLandingPageType.HolidayPackages) {
    return {
      ...filters,
      holidayTypes: [...(filters.holidayTypes ?? []), ...holidayTypes],
      destinationId,
      offerTypes: [OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_VILLA, OFFER_TYPE_HOTEL, OFFER_TYPE_BED_BANK],
    }
  } else if (destinationLandingPageType === DestinationLandingPageType.Resorts) {
    return {
      ...filters,
      destinationId,
      offerTypes: [OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_VILLA, OFFER_TYPE_HOTEL, OFFER_TYPE_BED_BANK],
      inclusions: ['Resort credit'],
    }
  } else {
    throw new Error('getFilters:Invalid destinationLandingPageType')
  }
}

export function getMoreResortsFilters({
  filters,
} : {
  filters: App.OfferListFilters
}): App.OfferListFilters {
  const { holidayTypes, limit, inclusions, ...restFilters } = filters
  return {
    ...restFilters,
    offerTypes: [OFFER_TYPE_HOTEL, OFFER_TYPE_BED_BANK],
    customerRatingGte: 8,
  }
}

interface getTitleAndSubTitleProps {
  destinationLandingPageType: DestinationLandingPageType,
  placeName: string,
  holidayType: string
}
export function getTitleAndSubTitle({ destinationLandingPageType, placeName, holidayType }: getTitleAndSubTitleProps) {
  if (destinationLandingPageType === DestinationLandingPageType.HolidayPackages) {
    const title = `${placeName} ${holidayType} Holiday Packages`
    const subtitle = `Explore our Holiday Package deals in ${placeName}`
    return { title, subtitle }
  } else if (destinationLandingPageType === DestinationLandingPageType.Resorts) {
    const title = `${placeName} Resorts`
    const subtitle = `Explore our Resorts in ${placeName}`
    return { title, subtitle }
  } else {
    throw new Error('getTitleAndSubTitle:Invalid destinationLandingPageType')
  }
}

/**
 * As in the landing page, the doesn't has search params
 * so the search params passed to the filter are not complete. This function will build the search params
 * that work for the filter. (?destinationName=Thailand&destinationId=le_8f85517967795eeef66c225f7883bdcb&adults=2&children=none&pages=1)
 **/
export function buildSearchParamsForFilterPane({
  originalSearch,
  destinationName,
  destinationId,
  adults = 2,
  children = 'none',
  pages = 1,
  holidayType,
}: {
  originalSearch: string,
  destinationName: string,
  destinationId: string,
  adults?: number,
  children?: string,
  pages?: number,
  holidayType?: string,
}) {
  const searchParams = new URLSearchParams(originalSearch)
  // Add destination name and ID
  if (holidayType) {
    searchParams.set('holidayTypes', holidayType)
  }
  searchParams.set('destinationName', destinationName)
  searchParams.set('destinationId', destinationId)
  searchParams.set('adults', String(adults))
  searchParams.set('children', children)
  searchParams.set('pages', String(pages))

  return searchParams.toString()
}
