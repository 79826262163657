import { OFFER_TYPE_HOTEL } from 'constants/offer'

export const SUPPORTED_CHANNEL_MARKUP_OFFER_TYPES = [OFFER_TYPE_HOTEL]
export const DEFAULT_CHANNEL_MARKUP_VALUE = 1
const ALLOWED_UTM_SOURCE = ['impact_radius', 'google']

export function getChannelMarkupValue(
  offerId: string,
  offerType?: App.OfferType,
  channelMarkup?: App.ChannelMarkup,
): number {
  if (channelMarkup?.channelMarkupBlacklist.includes(offerId)) {
    return DEFAULT_CHANNEL_MARKUP_VALUE
  }
  if (!offerType || !channelMarkup) {
    return DEFAULT_CHANNEL_MARKUP_VALUE
  }
  const enableChannelMarkup = channelMarkup?.channelMarkupValue && SUPPORTED_CHANNEL_MARKUP_OFFER_TYPES.includes(offerType)
  const markup = enableChannelMarkup ? channelMarkup?.channelMarkupValue : DEFAULT_CHANNEL_MARKUP_VALUE
  return markup
}

export function isAllowedChannelMarkupQueryParams(filters: App.ChannelMarkupFilters) {
  return ALLOWED_UTM_SOURCE.includes(filters.source)
}
