import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getDepositRemainingBeforeCreditApplied from './getDepositRemainingBeforeCreditApplied'
import { isSpoofed } from 'selectors/featuresSelectors'
import getPayableAfterModifiers from './getPayableAfterModifiers'
import getDepositSettings from './getDepositSettings'
import getDepositAmountBeforeCreditApplied from './getDepositAmountBeforeCreditApplied'
import { isUsingCredit } from '../view/generic'

/*
  This selector was created to solve the circular dependency issue between getDepositRemaining, getDepositServiceFee and getCreditPayableAmount.
  This is because service fee depends on the deposit remaining, which depends on the credit payable amount, which depends on the service fee.
  These figures are all calculated together in this selector and the original selectors were kept the same to avoid updating all references.
*/
const getDepositCalculations = createSelector(
  (state: App.State) => getDepositRemainingBeforeCreditApplied(state),
  (state: App.State) => getDepositSettings(state),
  (state: App.State) => state.auth.account.creditsByCurrency[state.checkout.cart.currencyCode]?.balance ?? 0,
  (state: App.State) => isSpoofed(state),
  (state: App.State) => state.checkout.payment.rebookingID,
  (state: App.State) => getPayableAfterModifiers(state),
  (state: App.State) => getDepositAmountBeforeCreditApplied(state),
  (state: App.State) => isUsingCredit(state),
  (depositRemaining, depositSettings, creditBalance, isSpoofed, rebookingID, payable, depositAmount, useCredit): {
    serviceFee: number;
    maxDepositCreditAmount: number;
    balanceRemaining: number;
  } => {
    const serviceFeeRate = depositSettings?.serviceFee?.isEnabled ? (depositSettings.serviceFee.percentage / 100) : 0

    // Calculate maximum credit amount for deposit scenario
    let maxCreditTotal = Math.min(creditBalance, payable)

    if (isSpoofed && rebookingID) {
      const rebookingPayable = depositAmount + (serviceFeeRate * depositRemaining / (1 - serviceFeeRate))
      maxCreditTotal = creditBalance < rebookingPayable ? creditBalance : rebookingPayable
    }

    const maxDepositCreditAmount = floatify(maxCreditTotal)

    // Calculate final remaining and service fee
    let balanceRemaining

    if (rebookingID) {
      balanceRemaining = depositRemaining
    } else {
      balanceRemaining = useCredit ? depositRemaining - maxDepositCreditAmount : depositRemaining
    }

    const serviceFee = serviceFeeRate * balanceRemaining

    return {
      serviceFee: floatify(serviceFee),
      maxDepositCreditAmount: floatify(maxDepositCreditAmount),
      balanceRemaining: floatify(balanceRemaining),
    }
  },
)

export default getDepositCalculations
