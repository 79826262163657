import { createSelector } from 'reselect'
import { isCruiseItem, isTourV2Item } from 'lib/checkout/checkoutUtils'
import config from 'constants/config'
import { OptimizelyExperiments } from 'constants/optimizely'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'

const DISABLE_KEY = 'disable_service_fee'

export const getDefaultDepositAmountPercentage = createSelector(
  (state: App.State) => state.system.depositConfigs,
  (state: App.State) => getOptimizelyExperimentVariation(state, OptimizelyExperiments.paymentsDepositOptionForHotelsAbove1999),
  (depositConfigs, depositVariation): number => {
    if (!depositConfigs) return 0
    switch (depositVariation) {
      case 'v1':
        return depositConfigs.V1_DEPOSIT_AMOUNT_PERCENTAGE_OVERRIDE
      case 'v2':
        return depositConfigs.V2_DEPOSIT_AMOUNT_PERCENTAGE_OVERRIDE
      default:
        return depositConfigs.DEPOSIT_AMOUNT_PERCENTAGE
    }
  },
)

export const getDepositServiceFee = createSelector(
  (state: App.State) => state.system.depositConfigs,
  (state: App.State) => getOptimizelyExperimentVariation(state, OptimizelyExperiments.paymentsDepositOptionForHotelsAbove1999),
  (depositConfigs, depositVariation): number => {
    if (!depositConfigs) return 0
    switch (depositVariation) {
      case 'v1':
        return depositConfigs.V1_SERVICE_FEE_PERCENTAGE_OVERRIDE
      case 'v2':
        return depositConfigs.V2_SERVICE_FEE_PERCENTAGE_OVERRIDE
      default:
        return depositConfigs.SERVICE_FEE_PERCENTAGE
    }
  },
)

export const getDepositServiceFeeConfig = createSelector(
  (state: App.State) => getDepositServiceFee(state),
  (state: App.State) => state.system.originalCanonicalURL,
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.offer.tourV2Offers,
  (state: App.State) => state.system.depositConfigs,
  (depositServiceFee, url = '', items, tourV2Offers, depositConfigs): App.Checkout.ServiceFeeSettings => {
    const queryString = url.slice(url.indexOf('?') + 1)
    const params = new URLSearchParams(queryString)
    const isEnabled = params.get(DISABLE_KEY) !== 'true'

    const tourItem = items.find(isTourV2Item)
    if (tourItem !== undefined && tourV2Offers?.[tourItem.offerId]?.productType === 'connection_tour') {
      return {
        isEnabled: false,
        percentage: 0,
      }
    }

    if (items.find(isCruiseItem)) {
      const cruiseServiceFeePercentage = depositConfigs?.CRUISE_SERVICE_FEE_PERCENTAGE ?? 0

      return {
        isEnabled: isEnabled && (cruiseServiceFeePercentage !== 0),
        percentage: cruiseServiceFeePercentage,
      }
    }

    if (config.agentHub.isEnabled) {
      const agentHubServiceFeePercentage = depositConfigs?.AGENTHUB_SERVICE_FEE_PERCENTAGE ?? 0

      return {
        isEnabled: isEnabled && (Number(agentHubServiceFeePercentage) !== 0),
        percentage: agentHubServiceFeePercentage,
      }
    }

    return {
      isEnabled: isEnabled && (Number(depositServiceFee) !== 0),
      percentage: depositServiceFee,
    }
  },
)
