import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getDepositCalculations from './getDepositCalculations'

const getDepositRemaining = createSelector(
  (state: App.State) => getDepositCalculations(state),
  (calculations): number => {
    return floatify(calculations.balanceRemaining)
  },
)

export default getDepositRemaining
