import { useEffect, useState } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { isPushNotificationsPermissionGranted, subscribeToPushNotifications, unsubscribeToPushNotifications } from 'api/braze'
import { selectLoggedIn } from 'selectors/accountSelectors'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import config from 'constants/config'
import { LUXURY_ESCAPES } from 'constants/brands'

function usePushNotificationSubscribe() {
  const loggedIn = useAppSelector(selectLoggedIn)
  const headlessMode = useAppSelector(state => state.system.headlessMode)
  const isWebPushNotificationPromptEnabled = !!useOptimizelyExperiment(OptimizelyFeatureFlags.croWebPushNotificationPrompt)
  const isEnabled = loggedIn && !headlessMode && isWebPushNotificationPromptEnabled && config.BRAND === LUXURY_ESCAPES

  const [canSubscribe, setCanSubscribe] = useState(false)

  useEffect(() => {
    const checkCanSubscribe = async() => {
      const isPermissionGranted = await isPushNotificationsPermissionGranted()
      setCanSubscribe(isPermissionGranted)
    }
    if (isEnabled) {
      checkCanSubscribe()
    }
  }, [isEnabled])

  useEffect(() => {
    if (canSubscribe) {
      subscribeToPushNotifications()
    }

    return () => {
      unsubscribeToPushNotifications()
    }
  }, [canSubscribe])
}

export default usePushNotificationSubscribe
