// skipLoginModal for gha traffic when utm_bp equals to '2'
export const skipLoginModal = (
  utmBp: string | undefined,
  utmContent: string | undefined,
): boolean => {
  if (utmBp && utmContent) {
    return (
      utmBp === '2' && (utmContent === 'gha-lpc' || utmContent === 'gha-lpp')
    )
  }
  return false
}
