import { checkoutAccommodationOfferView, getAccommodationEndDate, getAccommodationOccupantsFromCart, getAccommodationStartDate } from 'checkout/selectors/view/accommodation'
import getTransferItems from 'checkout/selectors/view/getTransferItems'
import { getFlightItemsView } from 'checkout/selectors/view/flights'
import ExperienceTransferBookingModal from 'components/Experiences/ExperienceTransfers/ExperienceTransferBookingModal'
import {
  getDistanceBetweenPropertyAndAirport,
} from 'components/Experiences/ExperienceTransfers/ExperienceTransferUtils'
import { last } from 'lib/array/arrayUtils'
import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

interface Props {
  experience: App.ExperienceBookingView;
  airportTransfer: App.ExperienceTransferView;
  hotelTransfer: App.ExperienceTransferView;
}

function ExperienceCheckoutTransferBookingModal(props: Props) {
  const { experience, airportTransfer, hotelTransfer } = props

  return <ExperienceTransferBookingModal
    experience={experience}
    defaultToAirportTransfer={airportTransfer}
    defaultToHotelTransfer={hotelTransfer}
  />
}

export const getDefaultTransfers = createSelector(
  getTransferItems,
  getAccommodationStartDate,
  getAccommodationEndDate,
  getAccommodationOccupantsFromCart,
  getFlightItemsView,
  checkoutAccommodationOfferView,
  (state: App.State, experience?: App.ExperienceBookingView) => experience?.originalExperience,
  (existingTransfers, startDate, endDate, totalOccupants, flightItems, offerView, experience): {
    toHotel: App.ExperienceTransferView,
    toAirport: App.ExperienceTransferView,
  } => {
    const existingToHotel = existingTransfers.find(transfer => transfer.experienceId === experience?.id && transfer.transfer.type === 'AIRPORT-TO-HOTEL')?.transfer
    const existingToAirport = existingTransfers.find(transfer => transfer.experienceId === experience?.id && transfer.transfer.type === 'HOTEL-TO-AIRPORT')?.transfer

    const departingFlight = last(flightItems.data[0]?.flights[0]?.journeyFlight.flights ?? [])
    const returningFlight = flightItems.data[0]?.flights[1]?.journeyFlight.flights[0]
    const property = (offerView.data[0]?.offer as any)?.property

    return {
      toHotel: existingToHotel ?? {
        type: 'AIRPORT-TO-HOTEL',
        date: departingFlight ? departingFlight.arrivalDate : startDate,
        time: departingFlight ? departingFlight.arrivalTime : undefined,
        travellers: {
          adults: totalOccupants?.adults || 0,
          children: totalOccupants?.children || 0,
        },
        hotel: {
          latitude: property?.latitude,
          longitude: property?.longitude,
          kmFromAirport: getDistanceBetweenPropertyAndAirport(property, experience?.airport),
          name: property?.name,
        },
        flightNumber: departingFlight ? `${departingFlight.carrier}${departingFlight.flightNumber}` : undefined,
        baggage: {
          bags: 0,
          oversizedBags: 0,
        },
      },
      toAirport: existingToAirport ?? {
        type: 'HOTEL-TO-AIRPORT',
        date: returningFlight ? returningFlight.departingDate : endDate,
        time: returningFlight ? returningFlight.departingTime : undefined,
        travellers: {
          adults: totalOccupants?.adults || 0,
          children: totalOccupants?.children || 0,
        },
        flightNumber: returningFlight ? `${returningFlight.carrier}${returningFlight.flightNumber}` : undefined,
        hotel: {
          latitude: property?.latitude,
          longitude: property?.longitude,
          kmFromAirport: getDistanceBetweenPropertyAndAirport(property, experience?.airport),
          name: property?.name,
        },
        baggage: {
          bags: 0,
          oversizedBags: 0,
        },
      },
    }
  },
)

function mapStateToProps(state: App.State, ownProps: Partial<Props>) {
  const defaultTransfers = getDefaultTransfers(state, ownProps.experience)

  return {
    airportTransfer: defaultTransfers.toAirport,
    hotelTransfer: defaultTransfers.toHotel,
  }
}

export default connect(mapStateToProps)(ExperienceCheckoutTransferBookingModal)
