import clsx from 'clsx'
import React, { forwardRef, HTMLAttributes } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.borderRadius.S};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
`

const TableContainer = styled.table`
  border-collapse: collapse; /* reset for cell borders */
  border-spacing: 0; /* reset for cell borders */
`

interface Props extends HTMLAttributes<HTMLTableElement> {
}

/**
 * Contains `<TableHeader>`s and `<TableBody>`s.
 *
 * It will be horizontally scrollable once columns cannot fit anymore.
 */
const Table = forwardRef<HTMLTableElement, Props>(function Table(props, ref) {
  const { children, className, ...rest } = props

  return <Container className={clsx(Table.name, className)}>
    <TableContainer {...rest} ref={ref}>
      {children}
    </TableContainer>
  </Container>
})

export default Table
