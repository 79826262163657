import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'
import offerTypeConfig from '../config/offer'
import imageMap from './imageMap'
import {
  mapHotelPackageOption,
  mapOfferSchedule,
  mapRoomRate,
  mapRoomType,
  propertyMap,
  packagePartnershipMap,
  mapTileInclusions,
  HotelMapperRoomInfo,
} from './hotelOfferMap'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import { trimArray } from 'lib/array/arrayUtils'
import { isOfferDomestic } from 'lib/offer/isDomestic'
import { getLowestPricePackage } from 'lib/offer/getLowestPricePackage'
import config from 'constants/config'
import { saleUnitLongReplacements } from 'constants/offer'
import { getOrderedAmenities } from 'lib/homesAndVillas/getAmenityPriority'
import offerUrgencyLabelMap from './offerUrgencyLabelMap'

function villaPropertyMap(serverOffer: PublicOfferV2.LeHotelOffer): App.VillaOfferProperty {
  const roomType = Object.values(serverOffer.roomTypes)[0]

  const property: App.VillaOfferProperty = {
    ...propertyMap(serverOffer.property),
    rooms: roomType.roomConfigurations.map<App.VillaRoom>(config => ({
      name: config.roomTypeDescription,
      beds: config.beds.map<App.VillaRoomBed>(bed => ({
        count: bed.count,
        type: bed.bedType as App.VillaRoomBedType,
        name: bed.bedTypeDescription,
      })),
    })),
    bathrooms: roomType.numberOfBathrooms ?? 0,
    bedrooms: roomType.numberOfBedrooms ?? 0,
    checkInContact: serverOffer.property.checkInContact ? {
      email: serverOffer.property.checkInContact.email,
      name: serverOffer.property.checkInContact.name,
      phone: serverOffer.property.checkInContact.phone,
    } : undefined,
    checkInFrom: serverOffer.property.checkInFrom,
    // 'end of day' or 1 minute before midnight doesn't really need to be stated
    checkInTo: serverOffer.property.checkInTo === '23:59' ? undefined : serverOffer.property.checkInTo,
    checkInPlace: serverOffer.property.checkInPlace,
    checkInType: serverOffer.property.checkInType,
    checkOutUntil: serverOffer.property.checkOutUntil,
    copy: {
      finePrint: serverOffer.property.finePrint,
      houseRules: serverOffer.property.houseRules,
      paymentDetails: serverOffer.property.deposits,
      arrivalInstructions: serverOffer.property.arrivalInstructions,
    },
    highlights: serverOffer.property.highlights.map<App.VillaPropertyHighlight>(highlight => ({
      id: highlight.id as string,
      image: { id: highlight.id_cloudinary_external as string },
      title: highlight.title as string,
      description: highlight.description as string,
    })),
  }

  return property
}

export function villaOfferMap(serverOffer: PublicOfferV2.LeHotelOffer, regionCode: string, channelMarkup?: App.ChannelMarkup): App.VillaOffer {
  const typeConfig = offerTypeConfig.rental!

  const offer: App.VillaOffer = {
    type: typeConfig.type,
    productType: typeConfig.productType,
    walledGarden: typeConfig.walledGarden,
    hasBuyNowBookLater: false,
    hasBuyNowBookLaterPackages: false,
    parentType: typeConfig.parentType,
    typeLabel: typeConfig.typeLabel,
    overlayImageLabel: typeConfig.overlayImageLabel,
    analyticsType: typeConfig.analyticsType,
    id: serverOffer.id,
    name: serverOffer.property.name,
    experiencesInFlow: serverOffer.experiencesInFlow,
    experiencesCurated: serverOffer.experiencesCurated,
    showOnlyExperiencesCurated: serverOffer.showOnlyExperiencesCurated,
    location: serverOffer.location.description,
    locations: serverOffer.tags.location ?? [],
    description: serverOffer.copy.description ?? '',
    highlights: serverOffer.copy.highlights ?? '',
    holidayTypes: serverOffer.tags.holidayTypes ?? [],
    whatWeLike: serverOffer.copy.whatWeLike,
    facilities: serverOffer.copy.facilities,
    finePrint: serverOffer.copy.finePrint,
    gettingThere: serverOffer.copy.gettingThere,
    insuranceCountries: serverOffer.insurance.countries,
    isSoldOut: serverOffer.isSoldOut ?? false,
    bookByDate: serverOffer.schedules?.bookBy?.end,
    travelToDate: serverOffer.schedules?.travelBy?.end,
    slug: serverOffer.slug,
    canEarnPartnershipPoints: serverOffer.partnerships.length > 0,
    durationLabel: serverOffer.durationLabel ?? '',
    tileDurationLabel: serverOffer.durationLabel ?? '',
    images: serverOffer.images.map(imageMap),
    image: imageMap(serverOffer.images[0]),
    visibilitySchedule: serverOffer.schedules?.listVisibility ? mapOfferSchedule(serverOffer.schedules.listVisibility) : undefined,
    onlinePurchaseSchedule: serverOffer.schedules?.onlinePurchase ? mapOfferSchedule(serverOffer.schedules.onlinePurchase) : undefined,
    availabilitySchedule: serverOffer.schedules?.availability ? mapOfferSchedule(serverOffer.schedules.availability) : undefined,
    vimeoVideoId: serverOffer.video?.id,
    isVideoVimeoHero: serverOffer.video?.isVideoVimeoHero,
    locationHeading: serverOffer.location.heading,
    locationSubheading: serverOffer.location.subheading,
    noIndex: serverOffer.noIndex,
    // temporary override for rentals sale unit
    saleUnit: 'total',
    saleUnitLong: saleUnitLongReplacements[serverOffer.saleUnit] ?? serverOffer.saleUnit,
    flightPrices: serverOffer.flights?.[0]?.prices ?? {},
    flightDestinationPort: serverOffer.flights?.[0]?.destinationCode,
    flightsMaxArrivalTime: serverOffer.flights?.[0]?.latestDestinationArrivalTime,
    flightsMinReturningDepartureTime: serverOffer.flights?.[0]?.earliestDestinationDepartureTime,
    flightCacheDisabled: serverOffer.flights?.[0]?.cacheDisabled,
    flightsWarningHeadline: serverOffer.flights?.[0]?.warning?.heading,
    flightsWarningPopupBody: serverOffer.flights?.[0]?.warning?.description,
    urgencyTags: offerUrgencyLabelMap(serverOffer),
    shouldDisplayValue: serverOffer.shouldDisplayValue,
    exclusiveExtrasLarge: serverOffer.inclusions.description,
    inclusions: serverOffer.inclusions.description,
    inclusionsHeading: serverOffer.inclusions.heading,
    additionalDescription: serverOffer.copy.additionalDescription ?? '',
    tileInclusions: mapTileInclusions(serverOffer.inclusions.tileInclusions),
    tileInclusionsHeading: serverOffer.inclusions.tileHeading,
    daysBeforeCheckInChangesDisallowed: serverOffer.daysBeforeCheckInChangesDisallowed ?? 0,
    flightsWarningEnabled: !!serverOffer.flights?.[0]?.warning,
    offerFlightsEnabled: config.FLIGHT_ENABLED && (serverOffer.flights?.length ?? 0) > 0,
    badge: serverOffer.badge,
    bundledWithFlightsOnly: serverOffer.bundledWithFlightsOnly,
    whitelistedCarrierCodes: serverOffer.whitelistedCarrierCodes ?? [],
    disableDeposit: !config.DEPOSITS_ENABLED || serverOffer.disableDeposit,
    depositThresholds: serverOffer.depositThresholds,
    packages: [],
    isPartnerProperty: serverOffer.isPartnerProperty,
    numberOfDateChangesAllowed: serverOffer.numberOfDateChanges ?? 'Unlimited',
    packageUpgradesAllowed: false,
    disableBestPriceGuarantee: serverOffer.disableBestPriceGuarantee,
    minDuration: Math.min(...serverOffer.options.map(o => o.duration)),
    reserveForZero: undefined,
    vendorName: serverOffer.vendorName,
    property: villaPropertyMap(serverOffer),
    hasHiddenCancellationPolicy: Object.values(serverOffer.ratePlans).some((r: any) => r.cancellationPolicy?.type === 'hidden-cancellation-policy'),
    defaultOptions: serverOffer.lowestOptions.map((o) => getPackageUniqueKey(o.fkPackageId, o.duration, o.fkRoomRateId)),
    isDiscountPillHidden: serverOffer.isDiscountPillHidden,
    discounts: Object.values(serverOffer.ratePlans)[0]?.discounts, // only one rate plan will exist per rentals offer
    luxPlus: {},
    isAgentHubExclusive: config.agentHub.isEnabled && Boolean(serverOffer.isAgentHubExclusive),
    stats: {
      booked: serverOffer.stats?.purchaseCount ?? 0,
      views: serverOffer.stats?.viewCount ?? 0,
    },
    luxLoyalty: {
      hasUpgradablePackages: false,
    },
  }

  const roomInfo = serverOffer.options.reduce<HotelMapperRoomInfo>((acc, serverOption) => {
    if (!acc.partnership[serverOption.fkPackageId]) {
      acc.partnership[serverOption.fkPackageId] = packagePartnershipMap(serverOffer.packages[serverOption.fkPackageId].partnerships)
    }
    if (!acc.roomType[serverOption.fkRoomTypeId]) {
      acc.roomType[serverOption.fkRoomTypeId] = mapRoomType(serverOffer.roomTypes[serverOption.fkRoomTypeId])
    }
    if (!acc.roomRate[serverOption.fkRoomRateId]) {
      acc.roomRate[serverOption.fkRoomRateId] = mapRoomRate(serverOffer.roomRates[serverOption.fkRoomRateId], serverOffer.ratePlans[serverOption.fkRatePlanId])
    }
    return acc
  }, {
    roomType: {},
    roomRate: {},
    partnership: {},
  })

  // villas has no formal concept of "minimum/maximum stay", so we always get all options for every duration
  // to try emulate min/max stay, we trim the array at both options for options without a price
  const trimmedOptions = trimArray<any>(serverOffer.options, option => !option.price)

  // if there are no options with a price, just use the original options
  const optionsToUse = trimmedOptions.length > 0 ? trimmedOptions : serverOffer.options
  offer.packages = optionsToUse.map(option => mapHotelPackageOption({
    serverOption: option,
    serverOffer,
    roomInfo,
    channelMarkup,
  }))

  // villas needs their amenities to be in a certain order, so re-process them
  offer.packages.forEach(pkg => {
    pkg.roomType.amenities = getOrderedAmenities(pkg.roomType.amenities)
  })

  offer.isDomestic = isOfferDomestic(offer, regionCode)
  offer.lowestPricePackage = getLowestPricePackage(offer, offer.packages) as App.HotelPackage
  // duration label of 1 to x nights is not very useful for users, just show a from price
  offer.tileDurationLabel = offer.packages.some(p => p.duration === 1) ? '' : offer.durationLabel

  return offer
}
