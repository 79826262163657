import { fetchAccumulatedOfferCommissionById } from 'actions/AgentHubActions'
import { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks'
import { max } from 'lib/array/arrayUtils'
import { getAgentHubOfferCommissionBody } from 'agentHub/selectors/agentHubSelectors'
import { fetchAccumulatedOfferById } from 'actions/OfferActions'
import config from 'constants/config'

function useAgentHubCommission(offerId: string) {
  const dispatch = useAppDispatch()

  const offerSummary = useAppSelector(state => state.offer.offerSummaries[offerId] || state.offer.bedbankOfferSummaries[offerId] || state.offer.tourV2Offers[offerId])
  const agentHubOfferCommissionBody = useAppSelector(state => getAgentHubOfferCommissionBody(state, offerId))

  const isFetchRequired = config.agentHub.isEnabled && offerSummary && !agentHubOfferCommissionBody

  const offerCommissionData = useAppSelector(state => state.agentHub.offersCommissionMap?.[offerId])

  const commissionPercentage = offerCommissionData?.commissionPercentage ?? 0
  const packagesCommission = offerCommissionData?.packages ?? []
  const minBaseCommission = offerCommissionData?.minBaseCommission || commissionPercentage
  const maxBaseCommission = offerCommissionData?.maxBaseCommission || commissionPercentage
  const greatestPackageCommission = max(packagesCommission, pkg => pkg.commissionPercentage)?.commissionPercentage ?? 0

  useEffect(() => {
    if (isFetchRequired) {
      dispatch(fetchAccumulatedOfferById(offerId))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, isFetchRequired])

  useEffect(() => {
    if (config.agentHub.isEnabled && agentHubOfferCommissionBody) {
      dispatch(fetchAccumulatedOfferCommissionById(offerId, agentHubOfferCommissionBody))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentHubOfferCommissionBody])

  return { commissionPercentage, packagesCommission, greatestPackageCommission, minBaseCommission, maxBaseCommission }
}

export default useAgentHubCommission
