import cn from 'clsx'
import BodyText from 'components/Luxkit/Typography/BodyText'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Clickable from '../Clickable'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const Day = styled(Clickable)`
  min-height: ${rem(50)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: background-color 0.2s;

  &.selected {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
  }

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
      }
    }
    &:focus {
      background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
    }
  }
`

interface Props {
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  date: Date;
  onClick?: (date: Date, e: React.MouseEvent<HTMLButtonElement>) => void;
}

function DatePickerDay(props: Props) {
  const { date, onClick = noop, selected, disabled, className } = props

  return <Day
    className={cn(className, { selected })}
    disabled={disabled}
    onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClick?.(date, e)}
  >
    <BodyText variant="large" weight="bold" data-date={moment(date).format(ISO_DATE_FORMAT)}>{date.getDate()}</BodyText>
  </Day>
}

export default DatePickerDay
