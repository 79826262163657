import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'
import { buildCancellationPolicies, buildPartiallyRefundableCancellationPolicies } from '@luxuryescapes/lib-refunds'
import { sum } from 'lib/array/arrayUtils'
import determineOfferFeatureSymbol from 'lib/offer/determineOfferFeatureSymbol'
import uuidV4 from 'lib/string/uuidV4Utils'

function bedGroupsMap(bedGroup: PublicOfferV2.BedGroup): App.RoomBedGroups {
  return {
    id: bedGroup.id,
    description: bedGroup.description,
    configuration: bedGroup.configuration.map(bedGroupsConfigurationMap),
  }
}

function bedGroupsConfigurationMap(configuration: PublicOfferV2.Configuration): App.BedGroupsConfiguration {
  return {
    type: configuration.type,
    size: configuration.size,
    quantity: configuration.quantity,
  }
}

// server code should be one of these 'RO' | 'BB' | 'HB' | 'FB' | 'AL'
function mapBedbankBoardCode(bedbankCode: string | undefined) : App.RateBoardCode {
  switch (bedbankCode) {
    case 'AL':
      return 'allinclusive'
    case 'BB':
      return 'breakfast'
    case 'FB':
      return 'fullboard'
    case 'HB':
      return 'halfboard'
    case 'RO':
      return 'roomonly'
    default:
      return 'LE'
  }
}

function getRateInclusions(serverRate: PublicOfferV2.BedbankRate): Array<App.OfferInclusion> {
  return serverRate.inclusions?.map(inclusion => {
    return {
      id: uuidV4(),
      description: inclusion.text,
      symbol: determineOfferFeatureSymbol(inclusion.icon || inclusion.text),
    }
  }) ?? []
}

export default function bedbankRateMap({
  checkIn,
  checkOut,
  timezone,
  rate,
  room,
} : {
  checkIn: string;
  checkOut: string;
  timezone: string;
  rate: PublicOfferV2.BedbankRate;
  room: App.BedbankPackage;
}): App.BedbankRate {
  return {
    bedGroups: rate.bedGroups.map(bedGroupsMap),
    id: rate.id,
    optionId: rate.optionId,
    roomId: room.roomId,
    room,
    refundable: rate.refundable,
    partiallyRefundable: rate.partiallyRefundable,
    isFlightBundle: rate.isFlightBundle,
    mobilePromotion: rate.mobilePromotion,
    groupId: rate.groupId ?? undefined,
    corporate: rate.corporate,
    occupancyPricing: rate.occupancyPricing.map(op => ({
      ...op,
      occupancy: op.occupancy,
      exclusive: op.exclusive,
      inclusive: op.inclusive,
      taxesAndFees: op.taxesAndFees,
      salesTax: op.salesTax,
      fees: op.fees,
      memberExclusive: op.luxPlusExclusive ?? 0,
      memberInclusive: op.luxPlusInclusive ?? 0,
      total: op.inclusive + sum(op.fees, fee => fee.amount),
      memberTotal: op.luxPlusInclusive ? op.luxPlusInclusive + sum(op.fees, fee => fee.amount) : undefined,
    })),
    totals: {
      exclusive: rate.totals.exclusive,
      inclusive: rate.totals.inclusive,
      propertyFees: rate.totals.propertyFees,
      taxesAndFees: rate.totals.taxesAndFees,
      memberExclusive: rate.totals.luxPlusExclusive ?? 0,
      memberInclusive: rate.totals.luxPlusInclusive ?? 0,
      total: rate.totals.inclusive + (rate.totals.propertyFees ?? 0),
      memberTotal: rate.totals.luxPlusInclusive ? rate.totals.luxPlusInclusive + (rate.totals.propertyFees ?? 0) : undefined,
    },
    currencyCode: rate.currencyCode,
    regionCode: rate.regionCode,
    nights: rate.duration,
    cancellationPolicies: rate.cancellationPolicies,
    nonRefundableDateRanges: rate.nonRefundableDateRanges,
    value: rate.value,
    discount: rate.discount,
    memberDiscount: rate.luxPlusDiscount ?? 0,
    cancellationPolicy: rate.partiallyRefundable ? buildPartiallyRefundableCancellationPolicies({ checkIn, checkOut, policies: rate.cancellationPolicies, nonRefundableDates: rate.nonRefundableDateRanges }, { timezone } as any) : buildCancellationPolicies(rate.cancellationPolicies, { timezone } as any),
    margin: rate.margin,
    marginAud: rate.marginAud,
    mx: rate.mx,
    supplier: rate.supplier,
    supplierCode: rate.supplierCode,
    boardCode: mapBedbankBoardCode(rate.boardCode),
    inclusions: getRateInclusions(rate),
  }
}
