import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import { dateDifference } from 'lib/datetime/dateUtils'
import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { filterDefinedProperties } from 'analytics/snowplow/helpers'
import { isBedbankOffer, isCarHireOffer, isFlightDeal, isJourneyV2 } from 'lib/offer/offerTypes'
import { OfferPageState } from 'contexts/OfferPage/offerPageStateReducer'
import moment from 'moment'

function getGeneralOfferItem(
  otherOffer: App.ExperienceOffer | App.Tours.TourV2Offer | App.CruiseOffer | App.FlightDeal | App.JourneyV2 | App.CarHireOffer,
  state: App.State,
  variationId: string | null = null,
  offerPageState?: OfferPageState,
) {
  let offerLeadPrice: number | null = null
  let offerLeadUnit: string | null = null
  let offerLeadDuration: string | null = null
  let destinationCity: string | null = null
  let destinationCountry: string | null = null
  let originCity: string | null = null
  let originCountry: string | null = null
  let travelStart: string | null = null

  if (isCarHireOffer(otherOffer)) {
    return filterDefinedProperties([comluxgroup.createItem_1_1_0({
      offerId: otherOffer.id,
      categoryId: otherOffer.type,
      productId: otherOffer.type,
      offerLeadPrice: otherOffer.price,
      currency: state.geo.currentCurrency,
      offerLeadDuration: String(otherOffer.duration),
    })])
  }

  if (isFlightDeal(otherOffer)) {
    return filterDefinedProperties([comluxgroup.createItem_1_1_0({
      offerId: otherOffer.id,
      categoryId: 'flight',
      productId: 'flight',
      offerLeadPrice: otherOffer.perAdultPrice,
      currency: state.geo.currentCurrency,
      offerLeadDuration: otherOffer.travelDays,
    })])
  }

  if (isJourneyV2(otherOffer)) {
    return filterDefinedProperties([comluxgroup.createItem_1_1_0({
      offerId: otherOffer.id,
      categoryId: 'flight',
      productId: 'flight',
      offerLeadPrice: otherOffer.price.all.baseFare,
      currency: state.geo.currentCurrency,
    })])
  }

  const { categoryId, productId } = getCategoryAndProductId(otherOffer.parentType, otherOffer)

  if (otherOffer.parentType === 'cruise') {
    offerLeadDuration = String(otherOffer.duration)
    offerLeadPrice = otherOffer.lowestDeparturePrice.total

    const destinationCityAndCountry = otherOffer.returnPort.split(', ')
    const originCityAndCountry = otherOffer.departurePort.split(', ')
    if (destinationCityAndCountry.length > 1) {
      destinationCity = destinationCityAndCountry[0]
      destinationCountry = destinationCityAndCountry[1]
    }
    if (originCityAndCountry.length > 1) {
      originCity = originCityAndCountry[0]
      originCountry = originCityAndCountry[1]
    }
  }

  if (otherOffer.parentType === 'tour_v2') {
    const purchasableOptions = otherOffer.purchasableOptions
    if (purchasableOptions.length > 0) {
      const offerLead = purchasableOptions[0]

      const departure = otherOffer.departures[offerLead.fkDepartureId]
      const startDate = departure.startDate
      const endDate = departure.endDate

      // Sum 1 on days result because tours include the last day as duration too
      offerLeadDuration = (dateDifference(new Date(endDate), new Date(startDate)).days + 1).toString()
      offerLeadPrice = offerLead.price

      const departureMonth = offerPageState?.departureMonth?.month
      const departureYear = offerPageState?.departureMonth?.year
      const travelStartDate = departureMonth !== undefined && departureYear !== undefined ? new Date(Date.UTC(departureYear, departureMonth, 1)) : null
      travelStart = travelStartDate?.toISOString()?.split('T')[0] ?? null
    }

    offerLeadUnit = otherOffer.saleUnit

    if (variationId) {
      const offerVariations = otherOffer.variations[variationId]

      destinationCity = offerVariations?.endLocation
      destinationCountry = offerVariations?.countriesVisited?.length > 0 ? offerVariations.countriesVisited[0] : null
      originCity = offerVariations?.startLocation
      originCountry = offerVariations?.countriesVisited?.length > 0 ? offerVariations.countriesVisited.at(offerVariations.countriesVisited.length - 1) ?? null : null
    }
  }

  if (otherOffer.parentType === 'experience') {
    // In some scenarios it could be flexible but analytics teams said to keep duration 1
    offerLeadDuration = '1'
    offerLeadPrice = otherOffer.price
    offerLeadUnit = otherOffer.ticketUnitLabel ?? null
    destinationCity = otherOffer.location.name
    originCity = otherOffer.location.name
  }

  const rooms = offerPageState?.rooms?.length ? offerPageState.rooms : [undefined]
  return filterDefinedProperties(rooms.map((room: App.RoomOccupants | undefined) => comluxgroup.createItem_1_1_0({
    offerId: otherOffer.id,
    categoryId,
    productId,
    offerLeadPrice,
    offerLeadUnit,
    offerLeadDuration,
    currency: state.geo.currentCurrency,
    destinationCity,
    destinationCountry,
    originCity,
    originCountry,

    /* Dynamic Data */
    travelStart,
    numberOfAdults: room?.adults ? room.adults : undefined,
    numberOfChildren: room?.children ? room.children : undefined,
    numberOfInfants: room?.infants ? room.infants : undefined,
    childrenAges: room?.childrenAge?.length ? room.childrenAge.filter(age => age >= 0) : undefined,
  })))
}

function getHotelOrTourOfferItem(
  hotelOrTourOffer: App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary,
  state: App.State,
  leadPrice?: number,
  price?: number,
  duration?: number,
  offerPageState?: OfferPageState,
  productAvailable?: boolean,
  roomRateId?: string,
  metadata?: string,
) {
  const offerId = hotelOrTourOffer.id

  const { categoryId, productId } = getCategoryAndProductId(hotelOrTourOffer.parentType, hotelOrTourOffer)

  let offerLowestPricePackage: App.Package | undefined
  let offerGeoData: App.OfferPropertyGeoData | undefined

  if (hotelOrTourOffer.type !== 'bedbank_hotel') {
    offerLowestPricePackage = hotelOrTourOffer.lowestPricePackage
    offerGeoData = hotelOrTourOffer.property?.geoData
  }

  const offerLeadPrice = productAvailable !== undefined ? leadPrice : offerLowestPricePackage?.price
  const offerLeadDuration = productAvailable !== undefined ? duration?.toString() : offerLowestPricePackage?.duration?.toString()
  const checkInDate = offerPageState?.checkInDate
  const checkOutDate = offerPageState?.checkOutDate
  let selectedDuration = offerPageState?.duration
  if (isBedbankOffer(hotelOrTourOffer)) {
    selectedDuration = checkInDate && checkOutDate ? moment(checkOutDate).diff(checkInDate, 'days') : undefined
  }
  const rooms = offerPageState?.rooms?.length ? offerPageState.rooms : [undefined]

  return filterDefinedProperties(rooms.map((room: App.RoomOccupants | undefined) => comluxgroup.createItem_1_1_0({
    offerId,
    rateId: roomRateId,
    categoryId,
    productId,
    price,
    offerLeadPrice,
    offerLeadDuration,
    offerLeadUnit: hotelOrTourOffer.saleUnit,
    currency: state.geo.currentCurrency,
    destinationCity: offerGeoData?.locality,
    destinationRegion: offerGeoData?.administrativeAreaLevel1,
    destinationCountry: offerGeoData?.country,

    /* Dynamic Data */
    duration: selectedDuration,
    travelStart: checkInDate,
    travelEnd: checkOutDate,
    numberOfAdults: room?.adults ? room.adults : undefined,
    numberOfChildren: room?.children ? room.children : undefined,
    numberOfInfants: room?.infants ? room.infants : undefined,
    childrenAges: room?.childrenAge?.length ? room.childrenAge.filter(age => age >= 0) : undefined,
    metadata,
  })))
}

export {
  getGeneralOfferItem,
  getHotelOrTourOfferItem,
}
