import React, { ComponentProps, ReactNode, forwardRef } from 'react'
import cn from 'clsx'
import Clickable from 'components/Common/Clickable'
import styled from 'styled-components'
import { rem } from 'polished'
import BodyText from './Typography/BodyText'
import Caption from './Typography/Caption'
import { SVG_ICON_SIZE_CSS_VAR, StyledSvgIcon } from './SvgIcon'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import CircleCheckboxInput from './Checkbox/CircleCheckboxInput'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const ReservedToggleButtonTitleSpace = styled(BodyText)`
  visibility: hidden;
  position: relative;
`

const ReservedToggleButtonSubtitleSpace = styled(Caption)`
  visibility: hidden;
  position: relative;
`

const ToggleButtonTitle = styled(BodyText)`
  visibility: visible;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const ToggleButtonSubtitle = styled(Caption)`
  visibility: visible;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const ClickableToggleButton = styled(Clickable)`
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.borderRadius.M};
  transition: 200ms background-color, 200ms border-color;
  border-width: ${rem(1)};
  border-style: solid;
  padding: ${rem(8)} ${rem(12)};
  outline: 2px solid transparent;
  outline-offset: 2px;

  &.kind-primary {
    background-color: transparent;
    border-color: transparent;

    &.is-selected {
      background-color: ${props => props.theme.palette.neutral.default.six};
    }

    ${mediaHoverable} {
      &:hover:not(:disabled) {
        background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }
  }
  &.kind-secondary {
    background-color: ${props => props.theme.palette.neutral.default.eight};
    border-color: ${props => props.theme.palette.neutral.default.six};

    &.is-selected {
      border-color: ${props => props.theme.palette.brand.primary.normal};
      box-shadow: 0 0 0 ${rem(1)} ${props => props.theme.palette.brand.primary.normal};
    }

    ${mediaHoverable} {
      &:hover:not(:disabled) {
        border-color: ${props => props.theme.palette.brand.primary.normal};
      }
    }
  }

  &:not(:disabled) {
    &:focus {
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid ${props => props.theme.palette.neutral.default.five});
    }
  }


  &.orientation-horizontal {
    flex-direction: row;
    gap: ${rem(8)};
    min-height: ${rem(40)};
    min-width: max(${rem(64)}, min-content);

    ${mediaQueryUp.tablet} {
      min-height: ${rem(40)};
      min-width: max(${rem(80)}, min-content);
    }
  }
  &.orientation-vertical {
    flex-direction: column;
    gap: ${rem(4)};
    min-height: ${rem(40)};
    min-width: max(${rem(64)}, min-content);

    ${mediaQueryUp.tablet} {
      min-height: ${rem(68)};
      min-width: max(${rem(64)}, min-content);
    }
  }

  > ${StyledSvgIcon} {
    ${SVG_ICON_SIZE_CSS_VAR}: ${rem(24)};
  }
`

interface Props extends Pick<ComponentProps<typeof Clickable>, 'onClick' | 'to' | 'disabled' | 'target'> {
  kind: 'primary' | 'secondary'
  /**
   * @default vertical
   */
  orientation?: 'horizontal' | 'vertical'
  /**
   * Puts the toggle button in the selected state.
   *
   * If the `endIcon` is set to radio, it'll control that too.
   *  */
  isSelected?: boolean
  /**
   * Used to provide some kind of status or urgency.
   *  */
  label?: ReactNode
  /**
   * Short succinct words to describe what to expect when selecting.
   *  */
  title: ReactNode
  /**
   * Sets the typography format of the title.
   *  */
  titleFormat?: ComponentProps<typeof BodyText>['format']
  /**
   * Provide secondary details.
   *
   */
  subtitle?: string
  /**
   * Sets the typography format of the subtitle.
   * */
  subtitleFormat?: ComponentProps<typeof Caption>['format']
  /**
   * Provide additional illustration alongside title & subtitle content.
   *
   * **There's no need to provide the icon size.**
   *  */
  startIcon?: ReactNode
  /**
   * Provide additional illustration alongside Title & Subtitle content.
   *
   * **There's no need to provide the icon size.**
   *  */
  endIcon?: ReactNode
  /**
   * When set to true, a radio input will be displayed as the end icon.
   */
  isMultiSelect?: boolean
  className?: string
}

const ToggleButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    isSelected,
    label,
    title,
    titleFormat,
    subtitle,
    subtitleFormat,
    startIcon,
    endIcon,
    orientation = 'vertical',
    kind,
    isMultiSelect,
    className,
    disabled,
    ...rest
  } = props

  return <ClickableToggleButton
    {...rest}
    ref={ref}
    disabled={disabled}
    className={cn(
      className,
      `kind-${kind}`,
      `orientation-${orientation}`,
      {
        'is-selected': isSelected,
      },
    )}
  >
    {startIcon}
    <Group direction="vertical" gap={2} horizontalAlign={orientation === 'horizontal' ? 'start' : 'center'}>
      {label}
      <ReservedToggleButtonTitleSpace
        variant="medium"
        colour="neutral-one"
        weight="bold"
        align="center"
        wrap="no-wrap"
        format={titleFormat}
      >
        {title}
        <ToggleButtonTitle
          variant="medium"
          colour="neutral-one"
          weight={isSelected ? 'bold' : 'normal'}
          align="center"
          format={titleFormat}
          aria-hidden="true"
        >
          {title}
        </ToggleButtonTitle>
      </ReservedToggleButtonTitleSpace>
      {subtitle && <ReservedToggleButtonSubtitleSpace
        variant="medium"
        colour="neutral-three"
        weight="bold"
        align="center"
        wrap="no-wrap"
        format={subtitleFormat}
      >
        {subtitle}
        <ToggleButtonSubtitle
          variant="medium"
          colour="neutral-three"
          weight={isSelected ? 'bold' : 'normal'}
          align="center"
          format={subtitleFormat}
          aria-hidden="true"
        >
          {subtitle}
        </ToggleButtonSubtitle>
      </ReservedToggleButtonSubtitleSpace>}
    </Group>
    {isMultiSelect && <CircleCheckboxInput checked={isSelected} disabled={disabled} />}
    {!isMultiSelect && endIcon}
  </ClickableToggleButton>
})

export default ToggleButton
