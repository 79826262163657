import { fetchExperienceById } from 'actions/ExperienceActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect } from 'react'

interface Options {
  disabled?: boolean;
}

function useExperience(
  experienceId: string = '',
  options: Options = {},
): [App.ExperienceOffer | undefined, boolean, any] {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!options.disabled && experienceId) {
      dispatch(fetchExperienceById(experienceId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId, options.disabled])

  const experience = useAppSelector(state => state.experience.experiences[experienceId])
  const fetching = useAppSelector(state => state.experience.fetchingExperiences[experienceId])
  const error = useAppSelector(state => state.experience.experienceErrors[experienceId])

  return [experience, fetching, error]
}

export default useExperience
