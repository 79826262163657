import { checkoutAccommodationGroupingKey } from 'checkout/selectors/view/accommodation'
import { createSelector } from 'reselect'
import { getInsuranceEnabledCheckoutItems } from '../view/accommodation'
import { getFlightItems } from '../view/flights'
import { getOccupantsFromItems } from 'lib/insurance/insuranceOccupants'

const getMultiItemOccupants = createSelector(
  (state: App.State) => getInsuranceEnabledCheckoutItems(state),
  (state: App.State) => getFlightItems(state),
  (items, flights) => {
    if (items.length === 0) return

    const accommodationOccupants = getOccupantsFromItems(
      items,
      checkoutAccommodationGroupingKey,
      item => item.occupancy,
    )

    const flightOccupants = getOccupantsFromItems(
      flights,
      item => item.itemId,
      item => item.occupants,
    )

    return [...accommodationOccupants, ...flightOccupants]
  },
)

export default getMultiItemOccupants
