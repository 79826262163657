import WalledContent from 'components/Common/WalledContent'
import { useAppSelector } from 'hooks/reduxHooks'
import { sum } from 'lib/array/arrayUtils'
import { buildSuggestedDatesParamsKey } from 'lib/search/searchUtils'
import React, { PropsWithChildren, ReactElement, useMemo } from 'react'
import { getSuggestedDates } from 'selectors/offerSelectors'

interface Props extends PropsWithChildren {
  wall: ReactElement
  offer: App.BedbankOffer | App.BedbankOfferSummary
  hidePricing?: boolean
  filters?: App.OfferListFilters
}

function BedbankSearchTileWalledContent({
  wall,
  children,
  offer,
  filters,
  hidePricing,
}: Props) {
  const flexibleSearchFilterKey = useMemo(() => {
    return buildSuggestedDatesParamsKey(filters?.flexibleMonths, filters?.flexibleNights, filters?.rooms)
  }, [filters])
  const suggestedDates = useAppSelector(state => getSuggestedDates(state, flexibleSearchFilterKey, offer.id))

  const checkIn = filters?.checkIn ?? suggestedDates?.checkIn
  const checkOut = filters?.checkOut ?? suggestedDates?.checkOut
  const hasDates = !!(checkIn && checkOut)

  const hasStandardOccupancy = useMemo(() => (sum(filters?.rooms || [], room => room.adults + (room.children ?? 0)) === 2), [filters?.rooms])
  const hasSellValueAndStandardOccupancy = !!offer.sell && hasStandardOccupancy

  return <WalledContent
    wall={wall}
    enforced={offer.walledGarden && (hasDates || hasSellValueAndStandardOccupancy)}
    hidePricing={hidePricing}
  >
    {children}
  </WalledContent>
}

export default BedbankSearchTileWalledContent
