import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import PriceRowLuxPlusPrice from './PriceRowLuxPlusPrice'
import LuxPlusLogoWrapper from 'luxPlus/components/LuxPlusLogoWrapper'
import clsx from 'clsx'
import { themeClassName } from 'lib/theme/themeUtils'

const Box = styled.div`
  display: inline-grid;
  grid-template-columns: [content-start] 1fr [content-end];
  grid-template-rows: [label-start] auto [border-start] auto [label-end content-start] 1fr [content-end border-end];

  /* fake the border as a grid area */
  &::before {
    content: "";
    grid-column: content;
    grid-row: border;
    background-color: ${props => props.theme.palette.neutral.default.eight};
    border: 1px solid ${props => props.theme.palette.product.luxPlus.background};
    border-radius: ${props => props.theme.borderRadius.S};
    pointer-events: none;
    justify-self: stretch;
  }

  &.h-align-start {
    justify-items: start;
  }
  &.h-align-center {
    justify-items: center;
  }
  &.h-align-end {
    justify-items: end;
  }
`

export const PRICE_ROW_LUX_PLUS_PRICE_BOX_LABEL_HEIGHT = 20
const LabelArea = styled.div`
  grid-column: content;
  grid-row: label;
  display: inline-grid;
  place-items: center;
  background-color: ${props => props.theme.palette.product.luxPlus.background};
  height: ${rem(PRICE_ROW_LUX_PLUS_PRICE_BOX_LABEL_HEIGHT)};
  padding: 0 ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.S};
`

const ContentArea = styled.div`
  grid-area: content;
  padding: ${rem(4)} ${rem(8)} ${rem(8)};
`

type InheritedPriceRowLuxPlusPriceProps = Pick<ComponentProps<typeof PriceRowLuxPlusPrice>, 'data-testid' | 'memberPrice' | 'saleUnit' | 'signDisplay' | 'size'>

interface Props extends InheritedPriceRowLuxPlusPriceProps {
  /**
   * @default start
   */
  horizontalAlign?: 'start' | 'center' | 'end',
}

function PriceRowLuxPlusPriceBox(props: Props) {
  const {
    horizontalAlign = 'start',
    ...rest
  } = props

  return <Box className={clsx(
    PriceRowLuxPlusPriceBox.name,
    themeClassName('default'),
    `h-align-${horizontalAlign}`,
  )}
  >
    <LabelArea className={themeClassName('inverse')}><LuxPlusLogoWrapper height={9} /></LabelArea>
    <ContentArea>
      <PriceRowLuxPlusPrice {...rest} />
    </ContentArea>
  </Box>
}

export default PriceRowLuxPlusPriceBox
