import { excludeNullOrUndefined } from 'checkout/utils'
import { DAY_MONTH_NAME_SHORT, SHORT_TIME_FORMAT_AM_PM } from 'constants/dateFormats'
import { sum } from 'lib/array/arrayUtils'
import moment from 'moment'
import { createSelector } from 'reselect'
import { getExperienceMobileAppDiscountTotal } from './experience'
import getExperienceItemsView from './getExperienceItemsView'

const getExperienceBreakdownView = createSelector(
  (state: App.State) => getExperienceItemsView(state),
  (state: App.State) => state.checkout.commissionData,
  (state: App.State) => getExperienceMobileAppDiscountTotal(state),
  (viewWithStatus, commissionData, experienceMobileAppDiscountTotals): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) { return { hasRequiredData: viewWithStatus.hasRequiredData, data: [] } }

    const price = sum(viewWithStatus.data.map(i => i?.totals.price ?? 0))
    const totalDiscount = !commissionData ? 0 : sum(viewWithStatus.data.map(expView => commissionData.itemsCommission[expView.id]?.itemDiscount ?? 0))

    const experienceBreakdownView: App.Checkout.PriceBreakdownView = {
      title: 'Experiences',
      price: price + experienceMobileAppDiscountTotals.amount,
      memberPrice: sum(viewWithStatus.data.map(i => i?.totals.memberPrice ?? 0)),
      additionalInfoText: [],
      ...(commissionData && {
        commission: {
          commissionPercentage: Number((totalDiscount / price * 100).toFixed(2)),
          itemDiscount: totalDiscount,
        },
      }),
      mobileAppDiscount: experienceMobileAppDiscountTotals,
      items: viewWithStatus.data.filter(excludeNullOrUndefined).map<App.Checkout.ExperienceItemBreakdownView>(expView => {
        const additionalInfo = expView.ticketSummaryViews
          .filter(ticketView => !ticketView.unavailable)
          .map(ticketView => ({ text: `${ticketView.count}x ${ticketView.name}` }))

        if (expView.pickupPoint) { additionalInfo.push({ text: `Pick up: ${expView.pickupPoint.name}` }) }

        const dateTimeLanguage = [
          expView.bookingDate && expView.bookingDate && moment.utc(expView.bookingDate).format(DAY_MONTH_NAME_SHORT),
          expView.bookingTime && expView.hasTimeslots && !expView.hideTimeSlots && moment.utc(`${expView.bookingDate}T${expView.bookingTime}`).format(SHORT_TIME_FORMAT_AM_PM),
          expView.language?.name,
        ].filter(excludeNullOrUndefined)

        if (dateTimeLanguage.length > 0) {
          additionalInfo.push({ text: dateTimeLanguage.join(' • ') })
        }

        return {
          title: expView.title,
          price: expView.totals.price + (expView.totals.mobileAppDiscount?.amount ?? 0),
          additionalInfo,
          itemType: 'experience',
          offerId: expView.experienceId,
          itemId: expView.item.itemId,
          additionalElements: [],
          taxesAndFees: expView.totals.taxesAndFees,
          itemView: expView,
        }
      }),
    }

    return {
      hasRequiredData: true,
      data: [experienceBreakdownView],
    }
  },
)

export default getExperienceBreakdownView
