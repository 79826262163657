import cn from 'clsx'
import React, { useMemo, useState, useCallback, useContext } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import LineArrowLeftIcon from 'components/Luxkit/Icons/line/LineArrowLeftIcon'
import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { without } from 'lib/array/arrayUtils'
import IconButton from 'components/Luxkit/Button/IconButton'
import CruiseWhenSelectMonthsGrid from './CruiseWhenSelectContentMonthsGrid'

const MonthGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const YearsGrid = styled.div`
  position: relative;
  margin-top: ${rem(12)};
  display: grid;
  grid-template-columns: 1fr;
  gap: ${rem(20)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  &.calendar-size-small,
  &.drawer {
    grid-template-columns: 1fr;
  }
`

const LeftButton = styled(IconButton)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-150%);
`

const RightButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(150%);
`

interface Props {
  onSelect?: () => void;
  onChange?: (value: App.CruiseGlobalFilters) => void;
  drawerMode?: boolean;
  calendarSize?: 'S' | 'M';
  filteredCruiseDatesMap?: Array<string>;
}

const currentYear = new Date().getFullYear()

function CruiseWhenSelectContentMonths({
  onSelect,
  drawerMode,
  calendarSize = 'M',
  filteredCruiseDatesMap,
}: Props) {
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const { flexibleMonths = '' } = useContext(GlobalSearchStateContext)
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const departureMonths = useMemo(() => flexibleMonths.split(','), [flexibleMonths])

  const availableYears = useMemo(() => {
    let years = [...new Set(filteredCruiseDatesMap!.map(date => date.slice(0, 4)))]
    if (years?.[0]) {
      setSelectedYear(parseInt(years[0], 10))
    }
    // if we have less than 3 years, we want to add the next year
    // to guarantee we have 3 years available
    if (years.length < 3) {
      const diff = 3 - years.length
      const lastYear = parseInt(years[years.length - 1], 10)
      years = [...years, ...Array.from({ length: diff }, (_, i) => (lastYear + (i + 1)).toString())]
    }
    return years
  }, [filteredCruiseDatesMap])

  // actions
  const nextYear = useCallback(() => {
    const nextYear = selectedYear + 1
    setSelectedYear(nextYear)
  }, [selectedYear])

  const prevYear = useCallback(() => {
    let lastYear = selectedYear - 1
    if (lastYear < currentYear) {
      lastYear = currentYear
    }
    setSelectedYear(lastYear)
  }, [selectedYear])

  const onMonthSelect = useCallback((month: string) => {
    let nextMonths: Array<string> = []
    const alreadyExists = departureMonths.includes(month)

    if (alreadyExists) {
      nextMonths = without(departureMonths, month)
    } else {
      nextMonths = [...departureMonths, month]
    }

    const flexibleMonths = nextMonths.filter(Boolean).join(',')

    searchDispatch({
      type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE,
      flexibleMonths,
    })

    // when selecting a month, we want to reset the date range
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: undefined })
    searchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: undefined })

    if (onSelect) onSelect()
  }, [departureMonths, searchDispatch, onSelect])

  const visibleYears = useMemo(() => {
    const yearsBySize = { S: 1, M: 3 }

    const years = drawerMode ? 4 : yearsBySize[calendarSize]
    return availableYears.filter((year) => parseInt(year, 10) >= selectedYear).slice(0, years)
  }, [drawerMode, availableYears, selectedYear, calendarSize])

  return <MonthGridWrapper data-testid="cruise-when-select-months">
    <YearsGrid
      className={cn({
        'calendar-size-small': calendarSize === 'S',
        drawer: drawerMode,
      })}
    >
      {visibleYears.map((year) => <CruiseWhenSelectMonthsGrid
        key={year}
        year={parseInt(year, 10)}
        departureMonths={departureMonths}
        onClick={onMonthSelect}
        cruiseDatesMap={filteredCruiseDatesMap!}
      />)}

      <CSSBreakpoint min="tablet">
        <LeftButton
          shape="circle"
          kind="secondary"
          variant="dark"
          disabled={selectedYear === currentYear}
          onClick={prevYear}
          data-testid="cruise-when-select-months-prev"
        >
          <LineArrowLeftIcon />
        </LeftButton>

        <RightButton
          shape="circle"
          kind="secondary"
          variant="dark"
          onClick={nextYear}
          disabled={selectedYear === (currentYear + 1)}
          data-testid="cruise-when-select-months-next"
        >
          <LineArrowRightIcon />
        </RightButton>
      </CSSBreakpoint>
    </YearsGrid>
  </MonthGridWrapper>
}
export default CruiseWhenSelectContentMonths
