import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cx from 'clsx'
import SolidExclamationCircleIcon from 'components/Luxkit/Icons/solid/SolidExclamationCircleIcon'
import AdditionalGuestsPopup from 'components/Common/AdditionalGuestsPopup/AdditionalGuestsPopup'
import { hasIncludedGuestsExceeded, getSumExtraGuestsSurcharge } from 'lib/checkout/cartReservationUtils'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import TextButton from 'components/Luxkit/Button/TextButton'
import { EmptyArray, sum, unique } from 'lib/array/arrayUtils'
import { getBundleValueUp } from 'lib/bundleAndSave/getBundleValueUp'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscount from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscount'
import { useOfferPrice } from 'hooks/Offers/useOfferPrice'
import OfferPriceDetailsRow from 'components/Common/PriceDetails/OfferPriceDetailsRow'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Root = styled.div`
  display: flex;
  flex-direction: column;

  &.align-left {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-right {
    align-items: flex-end;
  }
`

export const StyledExclamationIcon = styled(SolidExclamationCircleIcon)`
  position: relative;
  margin-left: ${rem(5)};
  top: ${rem(3)};
`

interface Props {
  offer: App.BundleOffer;
  className?: string;
  rooms?: Array<App.Occupants>;
  pkg: App.BundlePackageWithPrice;
  align?: 'left' | 'center' | 'right' | 'none';
  offerUrl: string;
  offerAvailable?: boolean;
}

function BundleOfferTilePricing(props: Props) {
  const {
    offer,
    className,
    pkg,
    align = 'none',
    rooms = EmptyArray,
    offerUrl,
    offerAvailable = true,
  } = props

  const priceMultiplier = rooms.length || 1
  const extraGuestSurcharges = useMemo(() => sum(pkg.packages.map(p => getSumExtraGuestsSurcharge(rooms, offer.bundledOffers[p.offerId], p))), [pkg, rooms, offer])
  const propertyFees = (pkg.propertyFees ?? 0) * priceMultiplier || 0

  const hotelPrice = ((pkg.price + (pkg.surcharge ?? 0)) * priceMultiplier) + extraGuestSurcharges

  const showIncludedGuestsExceeded = useMemo(() => {
    if (pkg.packages.some((p) => !p.roomType || (p.roomRate?.extraGuestSurcharges?.length || 0) > 0)) {
      return false
    }

    return rooms.some(room => pkg.packages.every(p => hasIncludedGuestsExceeded(room, p, offer.bundledOffers[p.offerId])))
  }, [pkg, rooms, offer])

  const { shouldShowDiscountPercent, hotelDiscountPercent, showPriceDetails } = useMemo(() => getBundleValueUp(offer, pkg), [offer, pkg])

  const {
    saleUnit: displaySaleUnit,
    total: displayTotalPrice,
  } = useOfferPrice({
    value: pkg.value,
    saleUnit: rooms.length === 0 ? offer.saleUnit : 'total',
    duration: pkg.duration,
    total: hotelPrice,
    propertyFees,
  })

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  useEffect(() => {
    dispatchOfferListEvent({
      type: OfferListEvents.pricingCalculated,
      availability: offerAvailable,
      leadPrice: hotelPrice,
      duration: pkg.duration,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Root className={cx(className, `align-${align}`)}>
      {shouldShowDiscountPercent && !!hotelDiscountPercent && <PriceRowValueDiscount
        type="estimate"
        discountPercentage={hotelDiscountPercent}
      />}
      <PriceRowPriceCaption>{pkg.durationLabel} from</PriceRowPriceCaption>
      <PriceRowPrice
        data-testid="search-price"
        size="L"
        price={displayTotalPrice}
        saleUnit={displaySaleUnit}
      />
      <PriceRowTaxesAndFees />
      {showIncludedGuestsExceeded && (
        <AdditionalGuestsPopup
          complex={false}
          description="+ Extra guest surcharge"
        />
      )}
      <PriceRowAgentHubCommission
        size="L"
        productType={offer.productType}
        offerId={offer.id}
        offerLocations={offerLocations}
        vendorName={vendorName}
      />
      {showPriceDetails && <OfferPriceDetailsRow
        trigger="price-row"
        triggerSize="M"
        offer={offer}
        duration={pkg.duration}
        propertyFees={propertyFees}
        hotelPrice={hotelPrice}
        rooms={rooms}
        dueAtPropertyMessage={offer.property?.taxesAndFeesContent ? offer.property.taxesAndFeesContent : ''}
        cta={
          <TextButton kind="primary" fit="flex" to={offerUrl}>
            View Offer
          </TextButton>
        }
      />}
    </Root>
  )
}

export default BundleOfferTilePricing
