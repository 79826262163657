import { computeCruiseDepositAmount, computeDepositAmount } from 'checkout/lib/utils/payments/deposit'
import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView } from '../view/accommodation'
import { getAccommodationPayable, getTourV2Total } from './accommodation'
import getDepositSettings from './getDepositSettings'
import { OFFER_TYPE_CRUISE, OFFER_TYPE_TOUR_V2 } from 'constants/offer'

const getAccommodationDepositAmount = createSelector(
  (state: App.State) => getAccommodationPayable(state),
  (state: App.State) => getDepositSettings(state),
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getTourV2Total(state),
  (accommodationPayable, depositSettings, viewWithStatus, tourV2Total): number => {
    const isCruise = viewWithStatus?.data[0]?.offerType === OFFER_TYPE_CRUISE
    if (isCruise) return computeCruiseDepositAmount(accommodationPayable, depositSettings)

    const tourV2BundleView = viewWithStatus.data.find(({ offerType, isBundled }) => offerType === OFFER_TYPE_TOUR_V2 && isBundled)
    if (tourV2BundleView) return computeDepositAmount(tourV2Total, depositSettings)

    return computeDepositAmount(accommodationPayable, depositSettings)
  },
)

export default getAccommodationDepositAmount
