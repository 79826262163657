import config from 'constants/config'
import { mapObject } from 'lib/object/objectUtils'

// Replace the packages array with a string, because our insanely huge package arrays badly affect the performance of Redux DevTools
function stubOfferPackages(offer: App.Offer) {
  if (Array.isArray(offer?.packages)) {
    return {
      ...offer,
      packages: `[${offer.packages.length} packages hidden for performance reasons. Disable SANITISE_LARGE_REDUX_DATA to see them here.]`,
    }
  }
  return offer
}

// Replace the calendar days with a string
function stubCalendar(calendar: App.Calendar) {
  if (!calendar) { return calendar }
  return {
    ...calendar,
    months: calendar.months.map(month => ({
      ...month,
      days: `[${month.days.length} calendar days hidden for performance reasons. Disable SANITISE_LARGE_REDUX_DATA to see them here.]`,
    })),
  }
}

export const actionSanitisers: Array<{ enabled: boolean, sanitise: (action: any, idNumber: number) => any }> = [
  {
    enabled: true,
    sanitise: (action) => {
      if (action.api) {
        return {
          ...action,
          type: `${action.api} - ${action.type.replace('API_CALL_', '')}`,
        }
      }
      return action
    },
  }, {
    enabled: !!config.SANITISE_LARGE_REDUX_DATA,
    sanitise: (action) => {
      if (action.type === 'api/FETCH_OFFER - SUCCESS') {
        return {
          ...action,
          data: stubOfferPackages(action.data),
        }
      }
      if (action.type === 'api/FETCH_OFFERS - SUCCESS') {
        return {
          ...action,
          data: action.data.map((offer: App.Offer) => stubOfferPackages(offer)),
        }
      }
      return action
    },
  },
]

export const stateSanitisers: Array<{ enabled: boolean, sanitise: (state: any, index: number) => any }> = [
  {
    enabled: !!config.SANITISE_LARGE_REDUX_DATA,
    sanitise: (state: App.State) => {
      const calendar = state?.calendar
      if (calendar) {
        return {
          ...state,
          calendar: {
            ...calendar,
            calendarsByPackageKey: mapObject(calendar.calendarsByPackageKey, stubCalendar) as any,
            calendarsByOccupancy: mapObject(calendar.calendarsByOccupancy, calendarsByOccupancy => (
              mapObject(calendarsByOccupancy, stubCalendar)
            )) as any,
          },
        }
      }

      return state
    },
  },
  {
    enabled: !!config.SANITISE_LARGE_REDUX_DATA,
    sanitise: (state: App.State) => {
      const geo = state?.geo
      if (geo?.airports.length) {
        return {
          ...state,
          geo: {
            ...state.geo,
            airports: `[${geo.airports.length} airports hidden for performance reasons. Disable SANITISE_LARGE_REDUX_DATA to see them here.]` as any,
          },
        }
      }

      return state
    },
  },
  {
    enabled: !!config.SANITISE_LARGE_REDUX_DATA,
    sanitise: (state: App.State) => {
      const offer = state?.offer
      if (offer?.offers && Object.values(offer.offers).some((offer: any) => Array.isArray(offer?.packages))) {
        return {
          ...state,
          offer: {
            ...offer,
            offers: mapObject(offer.offers, stubOfferPackages) as any,
          },
        }
      }

      return state
    },
  },
]
