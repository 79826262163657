import { SORTBY_HOME, SORTBY_SUBREGION_NSW, SORTBY_SUBREGION_VIC } from 'constants/offerList'
import { SORT_OPTION_HOME_PAGE } from 'constants/offerListFilters'
import { isManualSortingEnabledFor } from 'selectors/featuresSelectors'
import { DEFAULT_OFFER_LIST, NEW_DEFAULT_OFFER_LIST } from './constants'
import isAnyFilterApplied from 'lib/offer/filtersUtils'

interface GetHomeOfferListParamsProps {
  region: string,
  subregion: string | undefined,
  sortOption?: App.OfferListSortByOptions
  filters?: App.OfferListFilters,
}

export const getHomeOfferListParams = ({
  region,
  subregion,
  sortOption,
  filters,
}: GetHomeOfferListParamsProps): App.OfferListFilters => {
  let sortBy: App.OfferListSortByOptions = SORT_OPTION_HOME_PAGE.value
  if (sortOption || !isManualSortingEnabledFor('home') || isAnyFilterApplied(filters)) {
    return {
      ...filters,
      ...DEFAULT_OFFER_LIST,
      sortBy: sortOption || sortBy,
    }
  }

  sortBy = SORTBY_HOME

  if (isManualSortingEnabledFor('subregion') && region === 'AU') {
    if (subregion === 'NSW') {
      sortBy = SORTBY_SUBREGION_NSW
    } else if (subregion === 'VIC') {
      sortBy = SORTBY_SUBREGION_VIC
    }
  }

  return {
    ...NEW_DEFAULT_OFFER_LIST,
    sortBy,
  }
}

export const getAlternativesOfferListParams = (): App.OfferListFilters => ({ sortBy: 'lere.alternatives' })
