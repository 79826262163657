import React, { ComponentProps } from 'react'
import TourStyledNonSignatureProductTypeInfoModal, { TourStyledNonSignatureProductTypeInfoModalResult } from './TourStyledNonSignatureProductTypeInfoModal'
import TourStyledSignatureSeriesProductTypeInfoModal, { TourStyledSignatureSeriesProductTypeInfoModalResult } from './TourStyledSignatureSeriesProductTypeInfoModal'

export type TourStyledProductTypeInfoModalResult = TourStyledSignatureSeriesProductTypeInfoModalResult | TourStyledNonSignatureProductTypeInfoModalResult

interface Props extends ComponentProps<typeof TourStyledSignatureSeriesProductTypeInfoModal>, Omit<ComponentProps<typeof TourStyledNonSignatureProductTypeInfoModal>, 'productType'> {
  productType: App.Tours.V2ProductTypeStyled
}

function TourStyledProductTypeInfoModal({
  productType,
  ...rest
}: Props) {
  if (productType === 'signature_series_tour') {
    return <TourStyledSignatureSeriesProductTypeInfoModal {...rest} />
  }

  return <TourStyledNonSignatureProductTypeInfoModal {...rest} productType={productType} />
}

export default TourStyledProductTypeInfoModal
