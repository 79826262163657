import useStableObjectReference from 'hooks/useStableObjectReference'
import { EmptyObject } from 'lib/object/objectUtils'
import uuidV4 from 'lib/string/uuidV4Utils'
import { useMemo } from 'react'

interface TrackingOptions {
  ias?: App.OfferListTrackingConfig['ias'];
  snowplow?: App.OfferListTrackingConfig['snowplow'];
  ga?: App.OfferListTrackingConfig['ga'];
  source?: App.OfferListTrackingConfig['listSource'];
  offerSourceMapping?: { [key: string]: string }
  key?: string
}

function useOfferListTracking(
  listName: string,
  options: TrackingOptions = EmptyObject,
): App.OfferListTrackingConfig {
  const stableOptions = useStableObjectReference(options)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listId = useMemo(() => uuidV4(), [options.key])

  const trackingConfig = useMemo<App.OfferListTrackingConfig>(() => ({
    listId,
    listName,
    snowplow: stableOptions.snowplow ? stableOptions.snowplow : { enabled: true },
    ga: stableOptions.ga,
    ias: stableOptions.ias,
    listSource: stableOptions.source,
    offerSourceMapping: stableOptions.offerSourceMapping,
  }), [listId, listName, stableOptions])

  return trackingConfig
}

export default useOfferListTracking
