import OfferTilePricing from 'components/OfferList/OfferListTiles/Support/OfferTilePricing'
import { CruiseOfferView } from 'hooks/Cruise/useCruiseOfferView'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import { isCruiseOffer } from 'lib/offer/offerTypes'
import React from 'react'
import CruiseSearchTilePricing from './CruiseSearchTilePricing'
import CruiseSearchTilePricingInfo from './CruiseSearchTilePricingInfo'
import useCruiseSelectedCabinType from 'hooks/useCruiseSelectedCabinType'
import { getCheapestCruiseFlashOfferByCabin } from 'lib/cruises/getCheapestCruiseFlashOfferByCabin'

interface Props {
  view: CruiseOfferView;
}

function CruiseSearchTilePriceStack({ view }: Props) {
  const isPricePerNight = useCruisePriceByNight()
  const hasMoreThanOneAvailableCabinCategoriesPriceVariations = view.availableCabinCategoriesPriceVariations && view.availableCabinCategoriesPriceVariations.size > 1
  const selectedCabinType = useCruiseSelectedCabinType()

  if (!isCruiseOffer(view.offer)) {
    const cheapestPackage = getCheapestCruiseFlashOfferByCabin({ offer: view.offer, cabinType: selectedCabinType || '' })
    return <OfferTilePricing
      offer={view.offer}
      isPricePerNight={isPricePerNight}
      pkg={cheapestPackage || view.offer.lowestPricePackage}
    />
  }

  if (hasMoreThanOneAvailableCabinCategoriesPriceVariations && !selectedCabinType) {
    return <CruiseSearchTilePricingInfo view={view} />
  }

  return <CruiseSearchTilePricing view={view} />
}

export default CruiseSearchTilePriceStack
