import { createSelector } from 'reselect'
import { getBedbankItems, getLeHotelItems } from 'checkout/selectors/view/hotels'

/**
 * Gets a Set of item ids that are also bundled with any hotel items
 */
const getAccommodationBundledItemIds = createSelector(
  (state: App.State) => getLeHotelItems(state),
  (state: App.State) => getBedbankItems(state),
  (leHotelItems: Array<App.Checkout.LEHotelItem>, bedbankItems: Array<App.Checkout.BedbankHotelItem>): Set<string> => {
    const allBundledIds: Array<string> = [...leHotelItems, ...bedbankItems].flatMap(item => item.bundledItemIds ?? [])
    return new Set(allBundledIds)
  },
)

export default getAccommodationBundledItemIds
