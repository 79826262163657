/**
 * "Search" style tile. Not that it isn't just used on the search results page,
 * but also search-ish places such as destination product pages.
 */
import classnames from 'clsx'
import Clickable from 'components/Common/Clickable'
import BedbankSearchTile from 'components/SearchKit/SearchTiles/accommodations/BedbankSearchTile'
import HotelBundleSearchTile from 'components/SearchKit/SearchTiles/accommodations/HotelBundleSearchTile'
import HotelSearchTile from 'components/SearchKit/SearchTiles/accommodations/HotelSearchTile'
import VillaSearchTile from 'components/SearchKit/SearchTiles/accommodations/VillaSearchTile'
import CruiseOfferTile from 'components/SearchKit/SearchTiles/cruises/CruiseOfferTile'
import TourV2SearchTile from 'components/SearchKit/SearchTiles/tours/TourV2SearchTile'
import config from 'constants/config'
import {
  OFFER_TYPE_BED_BANK,
} from 'constants/offer'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import noop from 'lib/function/noop'
import { isBedbankOffer, isBundleOffer, isCruiseOffer, isCruiseV1Offer, isLEHotel, isTourV2Offer, isVillaOffer } from 'lib/offer/offerTypes'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import React, { forwardRef, useCallback } from 'react'
import styled from 'styled-components'
import { TopLevelTileProps } from '../OfferTileTypes'
import { useOfferBestPrice } from 'hooks/Offers/useOfferBestPrice'

const OfferLink = styled(Clickable)`
  position: relative;
`

function getTestId(offer: App.AnyOffer) {
  let flightsEnabled
  let onlinePurchaseSchedule

  if (offer.type !== OFFER_TYPE_BED_BANK) {
    const internalOffer = offer as App.Offer

    flightsEnabled = internalOffer.offerFlightsEnabled
    onlinePurchaseSchedule = internalOffer.onlinePurchaseSchedule
  }

  return classnames(
    `offer-${offer.type}`, {
      'offer-with-flights': flightsEnabled,
      [`Purchasable-${offer.type}`]: scheduleIsCurrent(onlinePurchaseSchedule),
    })
}

interface Props extends TopLevelTileProps {
  className?: string;
  position?: number;
}

const OfferSearchTile = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    offer,
    filters,
    productClick = noop,
    className,
  } = props

  const offerMetaData = useOfferMetaData(offer.id, filters)
  const offerUrl = useOfferUrl(offer, {
    filters,
    bundledOfferId: offerMetaData?.bundledOfferId,
    offerLinkIncludesFilters: true,
  })
  useOfferBestPrice(offer, filters, { disabled: !!filters?.isStream })

  const handleClick = useCallback(() => {
    productClick?.(offer)
  }, [offer, productClick])

  return <ProductPaletteProvider product={offer}>
    <OfferLink
      onClick={handleClick}
      to={offerUrl}
      data-testid={getTestId(offer)}
      target={config.OPEN_NEW_TAB_OFFER_CLICK ? '_blank' : undefined }
      className={className}
      ref={ref}
    >
      {isLEHotel(offer) && <HotelSearchTile
        offer={offer}
        filters={filters}
      />}
      {isTourV2Offer(offer) && <TourV2SearchTile
        offer={offer}
        filters={filters}
      />}
      {isBedbankOffer(offer) && <BedbankSearchTile
        offer={offer}
        filters={filters}
      />}
      {isVillaOffer(offer) && <VillaSearchTile
        offer={offer}
        filters={filters}
      />}
      {(isCruiseV1Offer(offer) || isCruiseOffer(offer)) && <CruiseOfferTile
        offer={offer}
        filters={filters}
      />}
      {isBundleOffer(offer) && <HotelBundleSearchTile
        offer={offer}
        filters={filters}
      />}
    </OfferLink>
  </ProductPaletteProvider>
})

export default OfferSearchTile
