import config from 'constants/config'
import {
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V2,
  OFFER_TYPE_VILLA,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
} from 'constants/offer'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'

function taoOnFrontPage(regionCode: string) {
  return config.TAO_ON_FRONT_PAGE && !isEnabledForFeature(config.TAO_ON_FRONT_PAGE_DISABLED_REGIONS, regionCode)
}

function includeRentalsIfEnabled(): Array<App.LEContractedOfferType> {
  return config.ENABLE_VILLA_RENTALS ? [OFFER_TYPE_VILLA] : []
}

function includeBundleSaveIfEnabled(): Array<App.LEContractedOfferType> {
  return config.ENABLE_BUNDLE_AND_SAVE ? [OFFER_TYPE_BUNDLE_AND_SAVE] : []
}

export const PAGE_SIZE = 4
export const OLD_HOME_PAGE_LIMIT = 1000
export const MAX_OFFERS_PER_REQUEST = 25
export const FLASH_OFFERS_TYPES: Array<App.LEContractedOfferType> = [OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR]
export const FLASH_AND_TAO_OFFERS_TYPES: Array<App.LEContractedOfferType> = [OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR, OFFER_TYPE_ALWAYS_ON]
export const FLASH_HOTELS_AND_TAO_OFFERS_TYPES: Array<App.LEContractedOfferType> = [OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON]
export const FLASH_HOTELS_AND_TAO_OFFERS_AND_BEDBANK_TYPES: Array<App.LEContractedOfferWithBedbankType> = [OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK]

// These need to be functions so that they're evaluated after the config is set.
// They can be turned back into normal constants once we launch TAO on front page for all regions.
export const FRONT_PAGE_OFFERS_TYPES =
  (regionCode: string) => [...(taoOnFrontPage(regionCode) ? FLASH_AND_TAO_OFFERS_TYPES.concat(includeBundleSaveIfEnabled(), includeRentalsIfEnabled()) : FLASH_OFFERS_TYPES.concat(includeBundleSaveIfEnabled())), OFFER_TYPE_TOUR_V2]

export const FRONT_PAGE_MAP_VIEW_OFFERS_TYPES =
  (regionCode: string) => taoOnFrontPage(regionCode) ? FLASH_AND_TAO_OFFERS_TYPES.concat(includeBundleSaveIfEnabled(), includeRentalsIfEnabled()) : FLASH_OFFERS_TYPES.concat(includeBundleSaveIfEnabled())

const FLASH_HOTEL_AND_TAO: Array<App.LEContractedOfferType> = [OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON].concat(includeBundleSaveIfEnabled(), includeRentalsIfEnabled()) as Array<App.LEContractedOfferType>
const FLASH_HOTEL: Array<App.LEContractedOfferType> = [OFFER_TYPE_HOTEL].concat(includeBundleSaveIfEnabled()) as Array<App.LEContractedOfferType>
export const HOTEL_PAGE_OFFER_TYPES: (regionCode: string) => Array<App.LEContractedOfferType> =
  (regionCode: string) => taoOnFrontPage(regionCode) ? FLASH_HOTEL_AND_TAO : FLASH_HOTEL

export const HOMES_AND_VILLAS_OFFER_TYPES: Array<App.LEContractedOfferType> = [OFFER_TYPE_VILLA, OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON]
export const HOMES_AND_VILLAS_FILTERS: App.OfferListFilters = {
  offerTypes: HOMES_AND_VILLAS_OFFER_TYPES,
  holidayTypesScoped: ['Homes & Villas'],
}

export const SORTBY_HOME = 'home'

export const SORTBY_SUBREGION_NSW = 'subregion-nsw'
export const SORTBY_SUBREGION_VIC = 'subregion-vic'

export const SORTBY_CAROUSEL_HOTEL = 'carousel-hotel'

export const SORTBY_VERTICAL_HOTEL = 'vertical-hotel'
export const SORTBY_VERTICAL_TOUR = 'vertical-tour'
export const SORTBY_VERTICAL_CRUISE = 'vertical-cruise'
export const SORTBY_VERTICAL_EXPERIENCE = 'vertical-experience'
export const SORTBY_VERTICAL_EXPERIENCE_SYDNEY = 'vertical-experience-sydney'
export const SORTBY_VERTICAL_EXPERIENCE_MELBOURNE = 'vertical-experience-melbourne'
export const SORTBY_VERTICAL_EXPERIENCE_BRISBANE = 'vertical-experience-brisbane'
export const SORTBY_VERTICAL_EXPERIENCE_ADELAIDE = 'vertical-experience-adelaide'
export const SORTBY_VERTICAL_EXPERIENCE_PERTH = 'vertical-experience-perth'

export const OFFER_TRACKING_IN_VIEW_THRESHOLD = 0.50
