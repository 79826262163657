import clsx from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React, { PropsWithChildren, forwardRef } from 'react'
import styled from 'styled-components'
import _DropdownSheet, { DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE, DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE, DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE, DROPDOWN_SHEET_WIDTH_CSS_VARIABLE } from '../_DropdownSheet'

const Container = styled(_DropdownSheet)`
  &:not(.auto-height) {
    ${DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE}: ${rem(480)};

    ${mediaQueryUp.tablet} {
      ${DROPDOWN_SHEET_MAX_HEIGHT_CSS_VARIABLE}: ${rem(600)};
    }
  }

  &.size-S {
    ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(200)};
    ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(335)};

    ${mediaQueryUp.tablet} {
      ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(220)};
      ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(335)};
    }
  }
  &.size-M {
    ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(335)};
    ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(335)};

    ${mediaQueryUp.tablet} {
      ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: ${rem(400)};
      ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: ${rem(600)};
    }
  }
  &.size-fill-anchor {
    ${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE}: 100%;
  }

  &:not(.size-fill-anchor) {
    &.width-auto {
      ${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE}: auto;
    }
    &.width-min {
      ${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE}: var(${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE});
      ${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE}: var(${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE});
    }
    &.width-max {
      ${DROPDOWN_SHEET_WIDTH_CSS_VARIABLE}: var(${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE});
      ${DROPDOWN_SHEET_MIN_WIDTH_CSS_VARIABLE}: var(${DROPDOWN_SHEET_MAX_WIDTH_CSS_VARIABLE});
    }
  }
`

interface Props extends PropsWithChildren {
  /**
   * Controls the width and height of the sheet
   */
  size: 'S' | 'M' | 'fill-anchor'
  /**
   * If set to min or max, the width of the sheet will be set to the specified boundary explicitly,
   * otherwise, the width will be clamped between the boundaries.
   *
   * _NOTE:_ This does not affect anything if the `size` is set to `fill-anchor`.
   *
   * @default auto
   */
  width?: 'auto' | 'min' | 'max'
  autoHeight?: boolean
}

const DropdownListSheet = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    size,
    width = 'auto',
    autoHeight,
  } = props

  return <Container ref={ref} className={clsx(`size-${size}`, `width-${width}`, { 'auto-height': autoHeight })}>
    {children}
  </Container>
})

export default DropdownListSheet
