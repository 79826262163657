import { syncUserCart } from 'actions/AuthActions'
import { LUX_BROADCAST_CHANNEL, canBroadcast } from 'lib/web/broadcastChannelUtils'
import watch from 'redux-watch'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { AppStore } from 'src/client/store'
import { saveUserCartDebounced } from 'storage/userCart'

export function watchUserCart(store: AppStore) {
  store.subscribe(
    watch(store.getState, 'auth.userCart')(async(newState: App.UserCartState) => {
      if (newState.synced || newState.fetching) return
      if (canBroadcast()) {
        const channel = new BroadcastChannel(LUX_BROADCAST_CHANNEL)
        // User cart changed sync it with other tabs
        channel.postMessage({ action: syncUserCart(newState) })
        channel.close()
      }
      const state = store.getState() as App.State
      if (!selectLoggedIn(state)) return
      saveUserCartDebounced(newState, state.auth.account.memberId)
    }),
  )
}
